import { auctionNoticeFilesRequests } from 'clients/manager/auction-notice-files.requests';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import UploadDocuments from '../upload-documents';
import { UploadedFile, UploadedFileStatusTypes } from '../upload-documents/props';

const AdditionalDocuments = () => {
    const { processForm } = useProcessFormContext();

    const listAuctionNoticeFiles = async () => {
        if (!processForm?.values?.id) {
            return [];
        }

        try {
            const response = await auctionNoticeFilesRequests.listAuctionNoticeFiles(
                processForm.values.id
            );

            return response?.data?.map((file) => ({
                file: undefined,
                name: file.description,
                preview: file.pathFile,
                status: UploadedFileStatusTypes.accepted,
                id: String(file.id),
                progress: 100,
                originalId: file.id,
            }));
        } catch (error) {
            return [];
        }
    };

    const handleDeleteFile = async (fileId: number | string) => {
        if (!processForm?.values?.id) {
            return;
        }

        await auctionNoticeFilesRequests.deleteAuctionNoticeFiles(
            processForm.values.id,
            Number(fileId)
        );
    };

    const uploadFile = (uploadedFile: UploadedFile, cbProgress: (progress: number) => void) => {
        if (!processForm?.values?.id) {
            return;
        }

        return auctionNoticeFilesRequests.uploadFileOnAuctionFiles(
            processForm.values.id,
            uploadedFile.file,
            cbProgress
        );
    };

    return (
        <div>
            <UploadDocuments
                listFiles={listAuctionNoticeFiles}
                onDeleteFile={handleDeleteFile}
                onUploadFile={uploadFile}
                options={{
                    multiple: true,
                }}
                skeletonRenderRows={3}
                canDelete
            />
        </div>
    );
};

export default AdditionalDocuments;
