import { memo, useEffect } from 'react';
import { Breadcrumbs, Typography, Box, Paper } from '@material-ui/core';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import { RiDownloadFill } from 'react-icons/ri';
import { providerRegistrationUpdateRequests } from 'clients/manager/provider-registration-update-request.requests';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { usePageContext } from 'common/components/base/pages/context';
import { addNotificationSuccess } from 'common/utils';
import RequestsTable from './components/requests-table';

const ProviderRegistrationUpdateRequests = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();

    async function ExportProviderRegistrationRequestsExcel() {
        const data =
            await providerRegistrationUpdateRequests.doExportProviderRegistrationRequestsExcel();

        const url = window.URL.createObjectURL(
            new Blob([data as any], { type: 'application/vnd.ms-excel' })
        );
        window.open(url, 'Download');

        addNotificationSuccess({
            title: t('term.success'),
            message: t('info.success', {
                action: 'Download',
            }),
        });
    }

    useEffect(() => {
        setTitle(t('term.updateRequests'));
    }, []);

    return (
        <PageWrapper>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Breadcrumbs color='primary'>
                    <Typography color='primary'>{t('Lista de pedidos')}</Typography>
                </Breadcrumbs>
                <Button
                    size='xsmall'
                    variant='contained'
                    color='primary'
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ExportProviderRegistrationRequestsExcel}
                >
                    <RiDownloadFill style={{ marginRight: 5 }} />
                    {t('term.export')}
                </Button>
            </Box>
            <Box mt={2} component={Paper} p={2}>
                <RequestsTable />
            </Box>
        </PageWrapper>
    );
};

export default memo(ProviderRegistrationUpdateRequests);
