import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import { ButtonBase } from '@material-ui/core';

export const Container = styled.div`
    padding: 0 20px;
`;

export const StyledAppBar = styled(AppBar)`
    background-color: transparent;
    height: 70px;
    max-width: 1300px;
    margin: 0 auto;
    box-shadow: unset;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
`;

export const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoContainer = styled.div`
    height: 70px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        margin: 0;
        padding: 0;
        height: calc(100% - 16px);
    }
`;

export const AuthButtonsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const HeaderAuthButton = styled(ButtonBase)`
    padding: 8px 16px;
    border-radius: 4px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
`;
