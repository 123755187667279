import { memo, useCallback } from 'react';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { ProcessStage, TypeCancel } from 'clients/manager/interfaces/auction-notice.interface';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { processUtils } from 'modules/process/process-utils';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { processStageToBiddingStageId } from 'common/utils';

const ProcessStatusInfo = () => {
    const { processForm } = useProcessFormContext();
    const { processLotsForm } = useProcessLotsFormContext();
    const { t } = useTranslation();

    const inBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processForm?.values?.biddingStageId &&
            biddingStage.includes(processForm.values.biddingStageId),
        [processForm?.values?.biddingStageId]
    );

    const isAllLotsFrustred = processUtils.isAllLotsFrustred(processLotsForm.values.lots);

    if (processForm?.values?.typeCancel) {
        const messages: { [typeCancel: string]: string } = {
            [TypeCancel.canceled]: t('term.canceled'),
            [TypeCancel.suspended]: t('term.suspended'),
            [TypeCancel.fail]: t('term.failed'),
            [TypeCancel.annulled]: t('term.annulled'),
            [TypeCancel.revoked]: t('term.revoked'),
            [TypeCancel.excluded]: t('term.excluded'),
        };

        const { typeCancel } = processForm.values;
        const message: string = messages[typeCancel];

        if (!message) {
            return null;
        }

        return (
            <Box>
                <Typography variant='body1' color='error'>
                    <b>{`${t('info.canceled-process-message', { value: message })}!`}</b>
                </Typography>
            </Box>
        );
    }

    if (
        isAllLotsFrustred &&
        inBiddingStage(
            processStageToBiddingStageId([
                ProcessStage.dispute,
                ProcessStage.decision,
                ProcessStage.contract,
            ])
        )
    ) {
        return (
            <Box>
                <Typography variant='body1' color='error'>
                    <b>{`${t('info.fail-lots-process-message')}`}</b>
                </Typography>
            </Box>
        );
    }

    if (processForm?.values?.auctionFinished) {
        return (
            <Box>
                <Typography variant='body1' color='secondary'>
                    <b>{`${t('info.canceled-process-message', { value: t('term.finished') })}!`}</b>
                </Typography>
            </Box>
        );
    }

    return null;
};

export default memo(ProcessStatusInfo);
