import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import NotFound from 'routes/not-found';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { usePageContext } from 'common/components/base/pages/context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import ContractPageView from './contract-page-view';

const ContractPage = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const { orderId } = useParams<{ orderId?: string }>();

    if (!orderId) {
        return <NotFound />;
    }

    const orderFilter = useMemo(
        () => ({
            orderId: Number(orderId),
        }),
        [orderId]
    );

    const { data: contractData, loading: loadingContract } = useQueryWithBody(
        marketplaceRequests.listContractsByOrderId,
        orderFilter
    );
    const contracts = contractData?.data;

    useEffect(() => {
        setTitle(t('marketplace.contract.title'));
    });

    return <ContractPageView contracts={contracts} loading={loadingContract} />;
};

export default ContractPage;
