import { Grid } from '@material-ui/core';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import ProfileGeneralData from './components/profile-general-data';
import ProfileLgpdLegalBasis from './components/profile-lgpd-legal-basis';
import { ProfileConnectAccountsView } from './components/profile-connect-accounts';

interface IProfileView {
    enableConnectProviders: boolean;
}

const ProfileView = ({ enableConnectProviders }: IProfileView) => (
    <PageWrapper>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ProfileGeneralData />
            </Grid>
            <Grid item xs={12}>
                <ProfileConnectAccountsView enableConnectProviders={enableConnectProviders} />
            </Grid>
            <Grid item xs={12}>
                <ProfileLgpdLegalBasis />
            </Grid>
        </Grid>
    </PageWrapper>
);

export default ProfileView;
