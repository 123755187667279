import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { useTranslation } from 'react-i18next';
import { HiringPlanResponse } from 'clients/manager/interfaces/hiring-plans.interfaces';

interface DialogSyncPcaPncpViewProps {
    isLoading: boolean;
    searchDetailsPcaUnit: (idPca: number) => HiringPlanResponse | undefined;
    countTotalItemsUnit: () => number;
    amountTotalItemsUnit: () => number;
    handlerSyncPcaWithPncp: () => Promise<void>;
}

const DialogSyncPcaPncpView: React.FC<DialogSyncPcaPncpViewProps> = (props) => {
    const {
        isLoading,
        searchDetailsPcaUnit,
        countTotalItemsUnit,
        amountTotalItemsUnit,
        handlerSyncPcaWithPncp,
    } = props;
    const { t } = useTranslation();
    const { pcaSyncId, isOpenDialogSyncPcaPncp, onCloseDialogSyncPcaPncp } =
        useHiringPlansContext();
    return (
        <Dialog
            disableEscapeKeyDown={false}
            maxWidth='sm'
            aria-labelledby='sync-pca-pncp-dialog'
            aria-describedby='sync-pca-pncp-dialog-description'
            open={isOpenDialogSyncPcaPncp}
            onClose={onCloseDialogSyncPcaPncp}
        >
            <DialogTitle
                id='sync-pca-pncp-dialog-title'
                style={{ backgroundColor: 'var(--primary-color)' }}
            >
                <Typography variant='h6' color='textSecondary'>
                    {t('hiring-plans.title.sync-pca-pncp')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='sync-pca-pncp-dialog-description' color='textPrimary'>
                    {t('hiring-plans.info.sync-pca-pncp')}
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            disabled
                            fullWidth
                            type='text'
                            variant='outlined'
                            name='description'
                            label={t('hiring-plans.labels.ano-pca')}
                            value={pcaSyncId && searchDetailsPcaUnit(pcaSyncId)?.year}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            disabled
                            fullWidth
                            type='text'
                            variant='outlined'
                            name='unitPurchasing'
                            label={t('hiring-plans.labels.unit-purchasing')}
                            value={
                                pcaSyncId &&
                                searchDetailsPcaUnit(pcaSyncId)?.organizationUnits
                                    .organizationUnitName
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            fullWidth
                            disabled
                            type='number'
                            variant='outlined'
                            name='quantity'
                            label={t('hiring-plans.title.quantity-amount')}
                            value={countTotalItemsUnit()}
                            InputProps={{ inputMode: 'numeric' }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            required
                            fullWidth
                            disabled
                            type='number'
                            variant='outlined'
                            name='totalValue'
                            label={t('hiring-plans.labels.total-value')}
                            value={amountTotalItemsUnit()}
                            InputProps={{
                                inputMode: 'numeric',
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Typography variant='button' color='primary'>
                                            R$
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    disabled={isLoading}
                    color='default'
                    size='medium'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={onCloseDialogSyncPcaPncp}
                >
                    {t('term.cancel')}
                </Button>
                <Button
                    disabled={isLoading}
                    color='primary'
                    size='medium'
                    variant='contained'
                    style={{
                        minWidth: '160px',
                        height: '100%',
                    }}
                    onClick={handlerSyncPcaWithPncp}
                >
                    {!isLoading ? (
                        t('term.confirm')
                    ) : (
                        <CircularProgress color='primary' size={25} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogSyncPcaPncpView;
