import React, { FC, useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import AccessLikeItem from 'modules/providers/pages/search-providers/components/access-like-item/access-like-item';

interface ProvidersActionsProps {
    provider: Provider;
    onEditContract: () => void;
    isSuperAdmin: boolean;
}

const ProvidersActions: FC<ProvidersActionsProps> = ({
    provider,
    onEditContract,
    isSuperAdmin,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label='more'
                size='small'
                aria-controls='long-menu'
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        onEditContract();
                    }}
                >
                    {t('info.view-edit-data')}
                </MenuItem>
                {isSuperAdmin && (
                    <>
                        <hr style={{ opacity: '0.3' }} />
                        <AccessLikeItem providerId={String(provider.id)} />
                    </>
                )}
            </Menu>
        </>
    );
};

export default ProvidersActions;
