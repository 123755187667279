import { SelectSegmentCategories } from './segment-categories-providers.interface';

interface ListSupplyCategoriesFilters {
    offset?: number;
    limit?: number;
    params?: {
        level?: number;
        q?: string;
    };
}

export enum SupplyCategoryTypeEnum {
    service = 'service',
    product = 'product',
}

interface SupplyCategory {
    id: number;
    categoryName: string;
    dateTimeInsert: Date;
    dateTimeUpdate?: Date;
    level: number;
    masterId: number;
    externalId?: string;
    supplyCategoryType?: SupplyCategoryTypeEnum;
}

interface SupplyCategoryCemig {
    technicalManagerName?: string;
    technicalManagerDocNumber?: string;
    manufacturerIdentifierNumber?: string;
    technicalManagerBoardNumber?: string;
    technicalManagerBoardType?: number;
    suppliesAs?: SelectSegmentCategories;
}

interface UpdateSupplyCategories {
    providerId: number;
    supplySegments?: string;
}

export type {
    ListSupplyCategoriesFilters,
    SupplyCategory,
    SupplyCategoryCemig,
    UpdateSupplyCategories,
};
