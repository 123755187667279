import { IoAdd } from 'react-icons/io5';
import { Scroll } from 'common/components/base/scroll';
import { BsListUl } from 'react-icons/bs';
import { styled } from 'common/theme/styled';
import { Modal } from 'common/components';

const PriceListNewItem = styled.div`
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
`;

const Identifier = styled.div`
    display: grid;
    margin: 0 0 0 20px;

    span {
        line-height: 1.5;
        color: ${(props) => props.theme.colors.textLight};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
    }

    span:nth-child(1) {
        font-weight: 600;
        font-size: 15px;
    }
`;

const Content = styled.div`
    display: flex;
    cursor: pointer;
    width: 100%;
    margin-bottom: 16px;

    &:hover {
        background: ${(props) => props.theme.colors.backgroundHoverLight};
    }

    .MuiCardContent-root:last-child {
        padding-bottom: 0;
    }
`;

const AddIcon = styled(IoAdd)`
    font-size: 25px;
    color: ${(props) => props.theme.colors.textLight};
    cursor: pointer;
`;

const ListIcon = styled(BsListUl)`
    font-size: 25px;
    color: ${(props) => props.theme.colors.textLight};
    cursor: pointer;
`;

const ScrollContent = styled(Scroll)`
    overflow-y: auto;
    height: 100%;
    padding: 15px;
`;

const CustomModal = styled(Modal)`
    .modal-content {
        height: calc(100vh - 48px);
    }
`;

const HeaderCreatePriceList = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px 0 10px 0;

    span {
        color: ${(props) => props.theme.colors.textLight};
        font-size: 16px;
        font-weight: 600;
    }
`;

const WrapperCreatePriceList = styled.div`
    margin: 0 0 15px 0;
`;

export {
    Identifier,
    Content,
    AddIcon,
    PriceListNewItem,
    ScrollContent,
    CustomModal,
    ListIcon,
    HeaderCreatePriceList,
    WrapperCreatePriceList,
};
