import React from 'react';
import { ListContractsByOrderIdResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './marketplace-contract-card-style';
import MarketplaceContractCardView from './marketplace-contract-card-view';

interface MarketplaceContractCardProps {
    contract?: ListContractsByOrderIdResponse;
    loading?: boolean;
}

const MarketplaceContractCard: React.FC<MarketplaceContractCardProps> = ({
    contract,
    loading = false,
}) => {
    const styles = useStyles();

    if (loading) {
        return <Skeleton className={styles.skeleton} />;
    }

    return <MarketplaceContractCardView contractWithDetails={contract} loading={loading} />;
};

export default MarketplaceContractCard;
