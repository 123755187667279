interface InstructionLevel {
    id: number;
    description: string;
}

const instructionLevelConstants:InstructionLevel[]  = [
    {
        id: 1,
        description: 'Analfabeto'
    },
    {
        id: 2,
        description: 'Primário incompleto'
    },
    {
        id: 3,
        description: 'Primário completo'
    },
    {
        id: 4,
        description: 'Ginasial incompleto'
    },
    {
        id: 5,
        description: 'Ginasial completo'
    },
    {
        id: 6,
        description: 'Colegial incompleto'
    },
    {
        id: 7,
        description: 'Colegial completo'
    },
    {
        id: 8,
        description: 'Superior incompleto'
    },
    {
        id: 9,
        description: 'Superior completo'
    },
    {
        id: 10,
        description: 'Pós grad.incompleto'
    },
    {
        id: 11,
        description: 'Pós grad.completo'
    },
    {
        id: 12,
        description: 'Mestrado incompleto'
    },
    {
        id: 13,
        description: 'Mestrado completo'
    },
    {
        id: 14,
        description: 'Doutorado incompleto'
    },
    {
        id: 15,
        description: 'Doutorado completo'
    },
    {
        id: 16,
        description: 'Pós dout.incompleto'
    },
    {
        id: 17,
        description: 'Pós dout completo'
    },
];









	
	
	
	
	
	
	
	

	
	
	
	
	
	
	

export { instructionLevelConstants };
