import { FC } from 'react';
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DocumentProcessType } from '../../../../../clients/manager/interfaces';
import { useStyles } from './styles';

export interface ProcessTypeListProps {
    selectedProcessType: DocumentProcessType | undefined;
    onSelectProcessType: (processType: DocumentProcessType | undefined) => void;
}

const ProcessTypeList: FC<ProcessTypeListProps> = ({
    selectedProcessType,
    onSelectProcessType,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const processTypeOptions = [
        {
            value: undefined,
            label: t('term.all'),
        },
        {
            value: DocumentProcessType.auction,
            label: t('term.electronic-auction'),
        },
        {
            value: DocumentProcessType.exemption,
            label: t('term.electronic-dispenser'),
        },
        {
            value: DocumentProcessType.accreditation,
            label: t('term.accreditation'),
        },
        {
            value: DocumentProcessType.publicSale,
            label: t('term.auction'),
        },
    ];

    return (
        <Box className={classes.root}>
            <List component='nav' disablePadding>
                <Box paddingLeft={1} marginBottom={1}>
                    <Typography variant='body2' color='textPrimary'>
                        <b>{t('term.process-types')}</b>
                    </Typography>
                </Box>
                <Divider variant='middle' />
                {processTypeOptions.map((option) => (
                    <Box key={option.value}>
                        <ListItem
                            title={option.label}
                            selected={selectedProcessType === option.value}
                            button
                            onClick={() => onSelectProcessType(option.value)}
                        >
                            <ListItemText
                                disableTypography
                                className={classes.listItemText}
                                primary={option.label}
                            />
                        </ListItem>
                        <Divider light />
                    </Box>
                ))}
            </List>
        </Box>
    );
};

export default ProcessTypeList;
