import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../table-header/styled';
import ValidateTerms from '../validate-terms';
import { TextSearchProps } from './props';

const TERMS_LIMIT = 20;

const TextSearch: FC<TextSearchProps> = ({
    searchByText,
    applyTextFilter,
    appliedFilters,
    loadingMore,
}) => {
    // serve apenas para setar um valor inicial quando o componente for montado
    // nao é atualizado o valor no context para não degradar a performance
    // ajustar futuramente para a lib receber uma ref do componente para substituir isso
    const [initialTextFilter, setInitialTextFilter] = useState<string>(appliedFilters.text);
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        setInitialTextFilter(appliedFilters.text);
    }, []);

    const getTerms = () => {
        const el: any = document.getElementById('search-items');
        if (!el) {
            return;
        }

        const { value } = el;
        return value.split(' ').length;
    };

    const handleSearchByText = (event: React.MouseEvent<HTMLButtonElement>) => {
        // desabilita busca se estiver realizando uma busca
        if (loadingMore) {
            return;
        }
        const terms = getTerms();
        if (terms > TERMS_LIMIT) {
            return setAnchorEl(event.currentTarget);
        }
        setAnchorEl(null);
        searchByText();
    };

    return (
        <>
            <ValidateTerms
                opened={!!anchorEl}
                onConfirm={() => setAnchorEl(null)}
                anchorEl={anchorEl}
            />

            <SearchInput
                id='search-items'
                type='search'
                value={initialTextFilter}
                name='textFilter'
                label={t('mkp.search.search-prices')}
                title={t('mkp.search.search-prices')}
                onKeyPress={(ev: any) => {
                    if (ev.which === 13 || ev.keyCode === 13) {
                        handleSearchByText(ev);
                    }
                }}
                onChange={(ev) => applyTextFilter(ev.target.value)}
                onClick={(ev) => handleSearchByText(ev)}
            />
        </>
    );
};

export default TextSearch;
