import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { styled } from 'common/theme/styled';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.backgroundPageDefault};
    padding: 15px 0 5px 0;
    position: sticky;
    top: 0px;
    z-index: 10;
`;

const EditArea = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`;

const AutoSaveInfo = styled.div`
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;

    span {
        color: ${(props) => props.theme.colors.textLight};
        font-size: 13px;
    }
`;

const Page = styled(PageWrapper)`
    padding-top: 0 !important;
`;

export { Header, EditArea, AutoSaveInfo, Page };
