import { styled } from 'common/theme/styled';
import { Scroll } from 'common/components/base/scroll';
import { IoMdTrash } from 'react-icons/io';
import { VscEdit } from 'react-icons/vsc';

const ContentScroll = styled(Scroll)`
    width: 100%;
    min-width: 350px;
    overflow-y: auto;
    height: calc(100% - 48px);
    padding: 15px;
`;

const VariableItem = styled.div`
    padding: 4px 8px;
    background: #f0f0f0a3;
    margin: 3px 0;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;

    div {
        display: grid;

        .description {
            color: ${(props) => props.theme.colors.textDefault};
            font-size: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .name {
            color: ${(props) => props.theme.colors.textLight};
            font-size: 13px;
            margin: 3px 0 0 5px;
        }
    }
`;

const SeparadorTitle = styled.div`
    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.textDefault};
    }

    margin: 0 0 5px 0;
    display: flex;
    height: 28px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
`;

const EmptyCustomVariable = styled.div`
    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.textDefault};
    }
`;

const DeleteIcon = styled(IoMdTrash)`
    font-size: 14px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.textLight};
`;

const EditIcon = styled(VscEdit)`
    font-size: 14px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.textLight};
`;

export { ContentScroll, VariableItem, SeparadorTitle, DeleteIcon, EditIcon, EmptyCustomVariable };
