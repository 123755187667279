import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { DetailedAutcionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import GenericError from 'common/components/generic-error';
import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { usePageContext } from 'common/components/base/pages/context';
import CheckoutView from './CheckoutView';

interface Params {
    [key: string]: string;
}

const Checkout = () => {
    const { setTitle } = usePageContext();
    const { step } = useCheckoutContext();
    const [process, setProcess] = useState<DetailedAutcionNotice | undefined>();
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const isStepDifferentFromConclusion = step !== 4;
    const { auctionId } = useParams<Params>();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchAvailablePlans = async () => {
            try {
                const { data } = await auctionNoticeRequests.getAuctionNotice(auctionId);
                setLoading(true);
                setProcess(data);
                setTitle(t('bidding.checkout.page_title'));
            } catch (error) {
                setHasError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchAvailablePlans();
    }, []);

    if (hasError) {
        return <GenericError />;
    }

    return (
        <CheckoutView {...{ auctionId, process, loading, step, isStepDifferentFromConclusion }} />
    );
};

export default Checkout;
