import { managerApiInstance } from 'clients/api';

const BASE_PATH = '/authorization-group';

const getAuthorizationGroup = (name) =>
    managerApiInstance.post(`${BASE_PATH}/getAuthorizationGroup`, name && { name });

const getAuthorizationGroupWithCategories = (groupId) =>
    managerApiInstance.post(`${BASE_PATH}/getAuthorizationGroupWithCategories`, {
        groupId,
    });

const createAuthorizationGroup = (values) =>
    managerApiInstance.post(`${BASE_PATH}/createAuthorizationGroup`, values);

const getActionByCategoryAndGroup = async (authorizationGroupId, categoryId) => {
    const { data } = await managerApiInstance.post(`${BASE_PATH}/getActionByCategoryAndGroup`, {
        categoryId,
        authorizationGroupId,
    });

    return data;
};

const doInsertActionInAuthorizationGroup = async (authorizationGroupId, categoryId, actionsIds) => {
    await managerApiInstance.post(`${BASE_PATH}/doInsertActionInAuthorizationGroup`, {
        authorizationGroupId,
        categoryId,
        actionsIds,
    });
};

const GroupAuthorizationService = {
    getAuthorizationGroup,
    getAuthorizationGroupWithCategories,
    doSaveAuthorizationGroup: createAuthorizationGroup,
    getActionByCategoryAndGroup,
    doInsertActionInAuthorizationGroup,
};

export default GroupAuthorizationService;
