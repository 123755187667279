import { FC } from 'react';
import { useFormik } from 'formik';
import { PriceMountType } from 'common/interfaces/price.interface';
import * as Yup from 'yup';
import { YupValidationMessages } from 'common/constants/yup-validation-messages.constant';
import { Grid, MenuItem } from '@material-ui/core';
import { Button, Modal, TextField } from 'common/components';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Actions } from './styled';
import { ModalAddItemsProps } from './props';

export interface CreateItemForm {
    description: string;
    unitMeasurement: string;
    priceMountType: PriceMountType;
    amount: number;
}

const ModalAddItems: FC<ModalAddItemsProps> = ({
    onClose,
    opened,
    onCreate,
    onEdit,
    firstItemSelected,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const queryItemId = query.get('itemId');
    const queryDescriptionItem = query.get('q');

    // se está na tela de edição de um item, o requisito é utiliza a descrição já existente
    const initialValues: CreateItemForm = {
        description:
            queryItemId && queryDescriptionItem
                ? queryDescriptionItem
                : firstItemSelected?.detailDescription ?? '',
        unitMeasurement: firstItemSelected?.unitMeasurement || '',
        priceMountType: PriceMountType.media,
        amount: 1,
    };

    const priceMountTypeOptions = [
        {
            label: t('term.average'),
            value: PriceMountType.media,
        },
        {
            label: t('term.median'),
            value: PriceMountType.median,
        },
        {
            label: t('term.lowest-price'),
            value: PriceMountType.lowerPrice,
        },
    ];

    const onCreateItem = (form: CreateItemForm) => (queryItemId ? onEdit(form) : onCreate(form));

    const form = useFormik({
        initialValues,
        onSubmit: (currForm) => {
            onCreateItem(currForm);
        },
        validationSchema: Yup.object({
            description: Yup.string().required(YupValidationMessages.REQUIRED),
            unitMeasurement: Yup.string().required(YupValidationMessages.REQUIRED),
            priceMountType: Yup.string().required(YupValidationMessages.REQUIRED),
        }),
    });

    return (
        <Modal
            key='modal'
            open={opened}
            onClose={onClose}
            header={
                <>
                    <span>
                        {queryItemId ? t('mkp.search.update-item') : t('mkp.search.create-item')}
                    </span>
                </>
            }
            style={{
                width: '500px',
                margin: '0 auto',
            }}
        >
            <form onSubmit={form.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type='number'
                            name='amount'
                            label={t('term.amount')}
                            value={form.values.amount}
                            onChange={(ev) =>
                                form.setFieldValue('amount', parseInt(ev.target.value, 10))
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type='text'
                            name='unitMeasurement'
                            label={t('term.unit-of-measurement')}
                            value={form.values.unitMeasurement}
                            onChange={(ev) =>
                                form.setFieldValue('unitMeasurement', ev.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{
                                margin: '0 0 15px 0',
                            }}
                            minRows={3}
                            multiline
                            fullWidth
                            disabled={!!queryDescriptionItem}
                            name='description'
                            label={t('term.item-description')}
                            value={form.values.description}
                            onChange={(ev) => form.setFieldValue('description', ev.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='priceMountType'
                            onChange={(event) => {
                                form.setFieldValue('priceMountType', event.target.value);
                            }}
                            fullWidth
                            select
                            label={t('term.price-montage')}
                            value={form.values.priceMountType}
                        >
                            {priceMountTypeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Actions>
                    <Button size='small' variant='outlined' type='button' onClick={onClose}>
                        {t('term.cancel')}
                    </Button>
                    <Button size='small' variant='contained' type='submit'>
                        {queryItemId ? t('term.update') : t('term.add')}
                    </Button>
                </Actions>
            </form>
        </Modal>
    );
};

export default ModalAddItems;
