import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    filesRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '30%',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        '&:hover': {
            boxShadow: theme.shadows[1],
        },
    },
}));

export default useStyles;
