import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse } from 'clients/interfaces/paginated.interface';
import { TypeInvoice } from 'common/enums';

const BASE_PATH_PLAN = '/plan';

interface PlanBasic {
    id: number;
    title: string;
    billing: {
        type: string;
        value: number;
    };
}
export interface Plan extends PlanBasic {
    priceDetails: {
        priceType: string;
        installmentsNumber: number;
    };
    benefits: string[];
    name?: string;
    durabiliyPlan: TypeInvoice;
}

export enum PlanTitleEnum {
    yearly = 'Plano Anual',
    payIfYouWin = 'Pague se vencer',
    perProcess = 'Por processo',
}

const planRequests = {
    listAvailablePlans: async (auctionId: number) =>
        doRequest<GenericResponse<Plan>>(
            managerApiInstance.post(`${BASE_PATH_PLAN}/listAvailablePlans`, {
                auctionId,
            })
        ),
};

export { planRequests };
