import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMessageCircle } from 'react-icons/fi';
import { formatDate } from 'common/utils';
import { ProcessMessageAvatar, ProcessMessageContainer } from './styled';

interface ProcessMessageProps {
    children: React.ReactElement | string;
    date: Date;
    answer?: boolean;
    avatar?: boolean;
    files?: string[];
    name?: string;
    maxwidth?: boolean;
}

const REMOVE_FROM_NAME_ARRAY = ['do', 'da', 'de', 'dos', 'das'];

const ProcessMessage = ({
    children,
    date,
    avatar = false,
    answer = false,
    name,
    maxwidth = false,
}: ProcessMessageProps) => {
    const { i18n } = useTranslation();
    const avatarName = useMemo(
        () =>
            name
                ?.split(' ')
                .filter((name) => !REMOVE_FROM_NAME_ARRAY.includes(name))
                .map((name) => name.split('').filter((_, idx) => idx === 0))
                .join(''),
        [name]
    );

    return (
        <ProcessMessageContainer elevation={0} variant='outlined' {...{ avatar, maxwidth, answer }}>
            {avatar && (
                <ProcessMessageAvatar>{avatarName || <FiMessageCircle />}</ProcessMessageAvatar>
            )}
            {typeof children === 'string' ? (
                // eslint-disable-next-line react/no-danger
                <p dangerouslySetInnerHTML={{ __html: children }} />
            ) : (
                <p>{children}</p>
            )}

            <span key={i18n.language}>
                {name && `${name} - `}
                {formatDate(date, 'L LT')}
            </span>
        </ProcessMessageContainer>
    );
};

export default ProcessMessage;
