import { FC } from 'react';
import { Modal } from '@licitar/ui-kit';
import { useTranslation } from 'react-i18next';
import { styled } from 'common/theme/styled';
import CreatePriceListForm from 'modules/priceBank/search/components/create-price-list-form';
import { ModalCreatePriceListProps } from './props';

const WrapperCreatePriceListForm = styled.div`
    padding: 6px;
`;

const ModalCreatePriceList: FC<ModalCreatePriceListProps> = ({
    opened,
    onClose,
    onPriceListChanged,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            width='360px'
            title={t('mkp.user-price-list.create-list-price')}
            onClickOutSide={onClose}
            opened={opened}
        >
            <WrapperCreatePriceListForm>
                <CreatePriceListForm
                    onCancel={onClose}
                    onPriceListChanged={(priceList) => {
                        if (!priceList) {
                            return;
                        }

                        onPriceListChanged(priceList);
                    }}
                />
            </WrapperCreatePriceListForm>
        </Modal>
    );
};

export default ModalCreatePriceList;
