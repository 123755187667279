import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RepresentativeUserProps {
    id: number;
    cpf: string;
    job: string;
    name: string;
    email: string;
    phone: string;
    active: number;
    country: string;
    language: string;
    nickname: null | string;
    password: string;
    typeUser: string;
    identityDoc: null | string;
    adminProfile: string;
    dateTimeInsert: string;
    dateTimeUpdate: null | string;
    lgpdLegalBasis: null | string;
    phoneCountryCode: string;
}

interface RepresentativeProps {
    id: number;
    job: string;
    user: RepresentativeUserProps;
    userId: number;
    providerId: number;
    identityDoc: string;
    dateTimeInsert: string;
    typeOfRepresentative: string;
    isItLegalRepresentative: number;
}

interface RepresentativeProps {
    data: RepresentativeProps;
    type: string;
}

interface TableCompareRepresentativeDataProps {
    representative: RepresentativeProps[];
}

const TableRepresentativesView: React.FC<TableCompareRepresentativeDataProps> = ({
    representative,
}) => {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper} style={{ marginTop: '15px' }}>
            <Table style={{ minWidth: 450 }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.action')}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('info.representatives')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {representative.map((item) => {
                        return (
                            <TableRow>
                                <TableCell
                                    style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}
                                >
                                    {t(`term.${item.type}`)}
                                </TableCell>
                                <TableCell>
                                    {item.data.user.name} - {item.data.user.job ?? item?.data?.job}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableRepresentativesView;
