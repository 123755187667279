import * as Yup from 'yup';
import i18n from 'i18next';

export const getPublicationPartialValidationSchema = () => ({
    auctionStartDate: Yup.string()
        .required(i18n.t('required-field', { ns: 'validation' }))
        .nullable(true),
    startDateTimeDispute: Yup.string()
        .required(i18n.t('required-field', { ns: 'validation' }))
        .nullable(true),
});

export const getPublicationFormLabels = () => ({
    auctionStartDate: i18n.t('term.official-publication'),
    startDateTimeDispute: i18n.t('term.dispute-date'),
});

export const getPublicationPartialUnenforceabilityValidationSchema = () => ({});

export const getPublicationFormLabelsUnenforceability = () => ({});
