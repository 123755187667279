import { Box, Button, Chip } from '@material-ui/core';
import InputFile from 'common/components/input-file';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCloudUpload, HiOutlineDocumentAdd } from 'react-icons/hi';
import useStyles from './information-files-style';
import ImagePreview from './components/image-preview';
import FilePreview from './components/file-preview';

interface InformationFilesViewProps {
    label?: string;
    type?: 'image' | 'file';
    mutiple?: boolean;
    loading?: boolean;
    extensions?: string;
    accept?: string;
    files: string[];
    selectFile: (value: File) => void;
    deleteFile: (index: number) => void;
}

const InformationFilesView: React.FC<InformationFilesViewProps> = ({
    type = 'image',
    mutiple = false,
    loading = false,
    label,
    files,
    extensions,
    accept,
    selectFile,
    deleteFile,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const hasFile = files?.length > 0;
    const disableInput = hasFile && !mutiple;

    return (
        <div className={classes.boxInformationFiles}>
            <InputFile
                onChange={selectFile}
                accept={accept}
                mutiple={mutiple}
                disabled={disableInput || loading}
            >
                <div className={classes.buttonUploadFile}>
                    <Button
                        component='span'
                        color='primary'
                        variant='outlined'
                        startIcon={
                            type === 'file' ? <HiOutlineDocumentAdd /> : <HiOutlineCloudUpload />
                        }
                        disabled={disableInput || loading}
                    >
                        {label ?? t('notices-information.labels.select-files')}
                        {` [${extensions}]`}
                    </Button>
                </div>
            </InputFile>
            {!hasFile && (
                <Box marginTop={4} textAlign='center'>
                    <Chip
                        className={classes.chipNoImageMessage}
                        disabled
                        color='default'
                        size='medium'
                        variant='outlined'
                        label={t('notices-information.labels.no-files-selected')}
                    />
                </Box>
            )}
            {hasFile && (
                <Box className={classes.boxContainerFiles}>
                    {files.map((file, index) => {
                        if (type === 'image') {
                            return <ImagePreview url={file} onDelete={() => deleteFile(index)} />;
                        }

                        if (type === 'file') {
                            return <FilePreview url={file} onDelete={() => deleteFile(index)} />;
                        }

                        return <></>;
                    })}
                </Box>
            )}
        </div>
    );
};

export default InformationFilesView;
