export const cardStyles = {
    height: '350px',
    border: 'solid 3px #1d1ce5',
    borderRadius: '0.3rem',
};

export const tableContainerStyles = {
    marginTop: '15px',
};

export const textStyles = {
    paddingLeft: 10,
    marginRight: '1rem',
};
