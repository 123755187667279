import i18n from 'i18next';
import { UserLanguages } from 'common/interfaces/user.interface';
import {
    EditorVariable,
    VARIABLE_ELEMENT_CLASSNAME,
    VARIABLE_TRIGGER,
} from 'modules/editor/components/editor';
import { externalVariables } from 'modules/editor/components/editor/editor-creator';

export const getTinyMceDefaultConfig = () => {
    const { language } = i18n;

    const config = {
        statusbar: false,
        extended_valid_elements: '*[class]|*[data-*]',
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
        paste_enable_default_filters: false,
        paste_filter_drop: false,
        toolbar_sticky: true,
        table_default_styles: {
            width: '100%',
            maxWidth: '100%',
        },
        table_responsive_width: true,
        table_sizing_mode: 'relative',
        menubar: false,
        language: 'en',
        spellchecker_language: 'pt-BR',
        inline: false,
        skin: 'oxide',
        content_css: '/assets/tinymce-content.css',
    };

    if (language === UserLanguages['pt-BR']) {
        config.language = 'pt_BR';
    }

    return config;
};

export const getTinyMceAutocompleteVariable = (
    editor: any,
    variables: EditorVariable[],
    useExternalVariables?: boolean
) => {
    const onAction = (autocompleteApi, rng, value) => {
        // deste modo para não perder referência na memória
        const selectedVariable = (useExternalVariables ? externalVariables : variables).find(
            (variable) => variable.name === value
        );
        if (selectedVariable) {
            const element = `<span 
                contenteditable='false'
                class='${VARIABLE_ELEMENT_CLASSNAME}'
                data-variable-id='${selectedVariable.id}'>${VARIABLE_TRIGGER}${selectedVariable.name}</span>`;
            editor.selection.setRng(rng);
            editor.insertContent(element);

            // elemento fake para conseguir adicionar duas variáveis em sequência
            const fakeElement = `<span>&nbsp;</span>`;
            editor.insertContent(fakeElement);
            autocompleteApi.hide();
        }
    };

    const getMatchedChars = (query: string) =>
        // deste modo para não perder referência na memória
        (useExternalVariables ? externalVariables : variables)
            .filter((variable) => variable.name.toLowerCase().search(query?.toLowerCase()) > -1)
            .slice(0, 10);

    editor.ui.registry.addAutocompleter('variables', {
        ch: '@',
        minChars: 0,
        columns: 1,
        onAction: onAction,
        maxResults: 10,
        type: 'autocompleter',
        highlightOn: ['_name'],
        fetch: (pattern: string) =>
            new Promise((resolve) => {
                const results: any[] = getMatchedChars(pattern)
                    .slice(0, 10)
                    .map((char) => ({
                        type: 'cardmenuitem',
                        value: char.name,
                        label: char.description,
                        items: [
                            {
                                type: 'cardcontainer',
                                direction: 'vertical',
                                items: [
                                    {
                                        type: 'cardtext',
                                        text: char.description,
                                        name: '_description',
                                        classes: ['autocompleter_cardtext_description'],
                                    },
                                    {
                                        type: 'cardtext',
                                        text: char.name,
                                        name: '_name',
                                        classes: ['autocompleter_cardtext_name'],
                                    },
                                ],
                            },
                        ],
                    }));
                resolve(results);
            }),
    });
};
