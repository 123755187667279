import { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import OrganizationBlockEditor from '../organization-block-editor';
import { DocumentBlocks } from 'clients/manager/interfaces/document-blocks.interface';
import { OrganizationVariables } from '../organization-block-editor/props';

export interface OrganizationDocumentBlockViewProps {
    editorId: string;
    initialData: string;
    handleEditorChange: (html: string) => void;
    variables: OrganizationVariables[];
    handleSubmit: () => Promise<void>;
    handleOnUploadFile: (file: File) => Promise<any>;
    block?: DocumentBlocks;
    t: (key: string) => string;
}

const OrganizationDocumentBlockView: FC<OrganizationDocumentBlockViewProps> = ({
    editorId,
    initialData,
    handleEditorChange,
    variables,
    handleSubmit,
    handleOnUploadFile,
    block,
}) => {
    const { t } = useTranslation();
    return (
        <Grid container>
            <Grid item md={12}>
                <OrganizationBlockEditor
                    editorId={editorId}
                    onUploadFile={handleOnUploadFile}
                    initialData={initialData}
                    onChange={handleEditorChange}
                    variables={variables}
                />
            </Grid>
            <Grid container item md={12} justifyContent='flex-end'>
                <Box display='flex' justifyContent='flex-end' mt={2}>
                    <Button
                        size='xsmall'
                        onClick={handleSubmit}
                        variant='contained'
                        color='primary'
                    >
                        {block?._id ? t('term.update') : t('term.save')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default OrganizationDocumentBlockView;
