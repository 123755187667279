import { Box, Grid, Link, Breadcrumbs, ButtonGroup, Typography } from '@material-ui/core';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionType, ProcessStage } from 'clients/manager/interfaces/auction-notice.interface';
import { useHistory, useParams } from 'react-router';
import {
    addNotificationWarning,
    getLabelFromProcessStage,
    getProcessStageByBiddingStageId,
    processStageToBiddingStageId,
} from 'common/utils';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { usePageContext } from 'common/components/base/pages/context';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import ButtonSendInvite from 'modules/process/components/button-send-invite';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { processUtils } from 'modules/process/process-utils';
import GeneralTabs from '../../components/general-tabs';
import { CreateProcessProps } from './props';
import { useProcessFormContext } from '../../context/process-form.context';
import ButtonPublishProcess from '../../components/button-publish-process';
import ButtonDisputeRoom from '../../components/button-dispute-room';
import ButtonProcessActions from '../../components/button-process-actions';
import { useProcessLotsFormContext } from '../../context/process-lots-form.context';
import ButtonProcessSession from '../../components/button-process-session';
import ButtonProcessChat from '../../components/button-process-chat';
import ButtonProcessHistory from '../../components/button-process-history';
import AppealPhaseActions from '../../components/appeal-phase-actions';
import TimeUntilDispute from '../../components/time-until-dispute';
import ProcessStatusInfo from '../../components/process-status-info';
import { useStyles } from './styled';

const CreateProcess: FC<CreateProcessProps> = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const { process, setProcess, processForm, setLoadingProcessData } = useProcessFormContext();
    const { processLotsForm, setProcessLotsAndItems } = useProcessLotsFormContext();
    const { auctionId } = useParams<{
        [key: string]: string;
    }>();
    const ShowButtonSendInvite =
        processForm &&
        processForm?.values?.biddingStageId === BiddingStageEnum.draft &&
        processForm?.values?.auctionType === AuctionType.unenforceability;
    useEffect(() => {
        setTitle(t('term.process'));
    }, []);

    const classes = useStyles();
    const history = useHistory();

    // quando sair da página de criação/edição, limpa o context
    const handleResetProcessForm = () => {
        setProcessLotsAndItems([]);
        setProcess(undefined);
        processLotsForm.resetForm();
        processForm?.resetForm();
    };
    useEffect(
        () => () => {
            handleResetProcessForm();
        },
        []
    );

    const getProcess = async () => {
        if (!auctionId || process?.id) {
            return;
        }

        setLoadingProcessData(true);

        try {
            const response = await auctionNoticeRequests.getProcessFromOrganization({
                auctionId: Number(auctionId),
            });

            if (!response?.data) {
                addNotificationWarning({
                    title: t('term.error'),
                    message: t('info.not-found-process'),
                });
                return history.push('/processos');
            }

            setProcess(response.data);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('process.components.error-list-data-process'),
            });
        } finally {
            setLoadingProcessData(false);
        }
    };

    useEffect(() => {
        getProcess();
    }, [auctionId]);

    const getProcessNumber = () => {
        if (!process) {
            return t('term.draft');
        }

        return process.accreditationNumber || process.auctionNumber || t('term.new');
    };

    const inBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processForm?.values?.biddingStageId &&
            biddingStage.includes(processForm.values.biddingStageId),
        [processForm?.values?.biddingStageId]
    );

    const visibleTimeUntilDispute =
        inBiddingStage(processStageToBiddingStageId([ProcessStage.proposals])) &&
        !processForm?.values?.auctionCanceled;

    return (
        <PageWrapper>
            <Box display='flex' justifyContent='space-between'>
                <Box>
                    <Breadcrumbs color='primary'>
                        <Link onClick={() => history.push('/processos')}>
                            <Typography color='textPrimary'>
                                {t('process.components.list-process')}
                            </Typography>
                        </Link>
                        <Typography color='textPrimary'>{getProcessNumber()}</Typography>
                    </Breadcrumbs>
                </Box>
                <ProcessStatusInfo />
                <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <Grid container alignItems='center'>
                        <Grid item>
                            <Box mr={1}>
                                <ButtonGroup disableElevation>
                                    <ButtonDisputeRoom />

                                    <ButtonProcessChat />
                                </ButtonGroup>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box mr={1}>
                                <ButtonProcessActions />
                            </Box>
                        </Grid>
                        <Grid item>
                            <ButtonProcessSession />
                        </Grid>
                        {ShowButtonSendInvite && (
                            <Grid item>
                                <Box ml={1}>
                                    <ButtonSendInvite />
                                </Box>
                            </Grid>
                        )}
                        <Grid item>
                            <Box ml={1}>
                                <ButtonPublishProcess />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box ml={1}>
                                <ButtonProcessHistory />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {process && (
                <Box display='flex' alignItems='center' height={22}>
                    <Typography variant='body2'>
                        ID: {process.id} -{' '}
                        {processForm?.values?.biddingStageId && (
                            <b className={classes.styleLabelProcess}>
                                {getLabelFromProcessStage(
                                    getProcessStageByBiddingStageId(
                                        processForm.values.biddingStageId
                                    ),
                                    processUtils.isDispenseWithoutDispute(processForm.values)
                                )}
                            </b>
                        )}
                    </Typography>
                    <AppealPhaseActions />
                </Box>
            )}
            {visibleTimeUntilDispute && <TimeUntilDispute />}
            <GeneralTabs />
        </PageWrapper>
    );
};

export default CreateProcess;
