import { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, TextField, Modal, ModalPosition } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { User } from 'common/interfaces/user.interface';
import { useHistory } from 'react-router';
import { ModalEditRequestProps } from './props';
import { ProviderRegistrationUpdateRequest } from '../../../../../clients/manager/interfaces/provider-registration-update-requests.interface';
import SelectPriority from '../select-priority';
import SelectOperator from '../select-operator';
import { providerRegistrationUpdateRequests } from '../../../../../clients/manager/provider-registration-update-request.requests';

type RequestForm = Pick<
    ProviderRegistrationUpdateRequest,
    'status' | 'priority' | 'operatorId' | 'user'
>;

const ModalEditRequest: FC<ModalEditRequestProps> = ({
    registrationUpdateRequest,
    onClose,
    onUpdateRequest,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const form = useFormik<RequestForm>({
        initialValues: {
            status: registrationUpdateRequest?.status,
            priority: registrationUpdateRequest?.priority,
            operatorId: registrationUpdateRequest?.operatorId,
            user: undefined,
        },
        onSubmit: async (values) => {
            try {
                const response =
                    await providerRegistrationUpdateRequests.updateRegistrationUpdateRequest({
                        id: registrationUpdateRequest.id,
                        ...omit(values, 'user'),
                    });

                if (!response?.data.ok) {
                    throw new Error('Err');
                }

                onUpdateRequest({
                    ...registrationUpdateRequest,
                    ...values,
                });
                onClose();
                return addNotificationSuccess();
            } catch (error) {
                addNotificationApiError(error);
            }
        },
    });

    const onClickReview = () => {
        history.push(`/empresa/editar/${registrationUpdateRequest.providerId}`);
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={(event: any) => {
                event.stopPropagation();
                onClose();
            }}
            header={<span>{t('info.update-request')}</span>}
        >
            <Box width='600px'>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={5}>
                        <TextField
                            fullWidth
                            label={t('ID')}
                            name='id'
                            value={registrationUpdateRequest.id}
                            disabled
                        />
                    </Grid>
                    <Grid item md={9} sm={5}>
                        <TextField
                            fullWidth
                            label={t('term.supplier')}
                            name='id'
                            value={registrationUpdateRequest.provider.companyName}
                            disabled
                        />
                    </Grid>
                    <Grid item md={4} sm={6}>
                        <SelectPriority
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                form.setFieldValue(event.target.name, event.target.value)
                            }
                            value={form.values.priority}
                            omitAllOption
                        />
                    </Grid>
                    <Grid item md={8} sm={6}>
                        <SelectOperator
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, user: User) => {
                                form.setFieldValue(event.target.name, event.target.value);
                                form.setFieldValue('user', {
                                    id: user.id,
                                    name: user.name,
                                });
                            }}
                            value={form.values.operatorId}
                            omitAllOption
                        />
                    </Grid>
                </Grid>
                <Box mt={2} width={1}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' size='small' onClick={onClickReview}>
                                {t('term.compare')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => form.submitForm()}
                            >
                                {t('term.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalEditRequest;
