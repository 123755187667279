import { MenuItem, Typography, Box, CircularProgress, Grid } from '@material-ui/core';
import { Button, Modal, ModalPosition, TextField, MaskedTextField } from 'common/components';
import { useFormik } from 'formik';
import { ChangeEvent, FC, FocusEvent, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ufValues } from 'common/constants/uf-values.constant';
import { DataViaCep } from 'common/interfaces/via-cep-data';
import { OrganizationUnit } from 'clients/manager/interfaces/organization.interface';
import { organizationUnitsRequests } from 'clients/manager/organization.requests';
import { addressRequest } from 'clients/manager/address.requests';
import { addNotificationError, addNotificationSuccess } from 'common/utils';

export interface ModalEditOrganizationUnitProps {
    organizationUnit?: OrganizationUnit;
    onClose: () => void;
    onCreateOrganizationUnit: (organizationUnit: OrganizationUnit) => void;
    onUpdateOrganizationUnit: (organizationUnit: OrganizationUnit) => void;
}

type OrganizationUnitForm = Omit<OrganizationUnit, 'id'>;

const ModalEditOrganizationUnit: FC<ModalEditOrganizationUnitProps> = ({
    organizationUnit,
    onClose,
    onCreateOrganizationUnit,
    onUpdateOrganizationUnit,
}) => {
    const [errorSearchCEP, setErrorSearchCEP] = useState(false);
    const [errorLenghtCEP, setErrorLenghtCEP] = useState(false);
    const [dataViaCep, setDataViaCep] = useState<DataViaCep>();
    const [fetchingCEP, setFetchingCEP] = useState<boolean>(false);

    const { t } = useTranslation();

    const updateOrganizationUnit = async (organizationUnitForm: OrganizationUnitForm) => {
        if (!organizationUnit) {
            return;
        }

        try {
            const response = await organizationUnitsRequests.updateOrganizationUnit({
                id: organizationUnit.id,
                ...organizationUnitForm,
                docNumber: organizationUnitForm.docNumber
                    .replaceAll('.', '')
                    .replaceAll('-', '')
                    .replaceAll('/', ''),
            });

            if (response?.data.ok) {
                onUpdateOrganizationUnit({
                    id: organizationUnit.id,
                    ...organizationUnitForm,
                });
                addNotificationSuccess({
                    message: t('info.updated.organization-unit-success'),
                });
            }
        } catch (err) {
            addNotificationError({
                message: t('info.updated.organization-unit-error'),
            });
        }
    };

    const createOrganizationUnit = async (organizationUnitForm: OrganizationUnitForm) => {
        try {
            const response = await organizationUnitsRequests.createOrganizationUnit({
                ...organizationUnitForm,
                docNumber: organizationUnitForm.docNumber
                    .replaceAll('.', '')
                    .replaceAll('-', '')
                    .replaceAll('/', ''),
            });

            onCreateOrganizationUnit(response);
            addNotificationSuccess({
                message: t('info.create.organization-unit-success'),
            });
        } catch (err) {
            addNotificationError({
                message: t('info.create.organization-unit-error'),
            });
        }
    };

    const form = useFormik<OrganizationUnitForm>({
        initialValues: {
            organizationUnitName: organizationUnit?.organizationUnitName || '',
            externalId: organizationUnit?.externalId || '',
            docNumber: organizationUnit?.docNumber || '',
            zipCode: organizationUnit?.zipCode || '',
            publicPlace: organizationUnit?.publicPlace || '',
            numberPlace: organizationUnit?.numberPlace || '',
            complementPlace: organizationUnit?.complementPlace || '',
            district: organizationUnit?.district || '',
            state: organizationUnit?.state || '',
            cityName: organizationUnit?.cityName || '',
        },
        validationSchema: yup.object({
            organizationUnitName: yup
                .string()
                .required(t('please-enter-a-name', { ns: 'validation' })),
            externalId: yup.string().optional(),
            docNumber: yup
                .string()
                .required(t('docNumber', { ns: 'validation' }))
                .cnpj({
                    message: t('cnpj-invalid', { ns: 'validation' }),
                })
                .min(14, t('docNumber-min-char', { ns: 'validation' })),
            zipCode: yup
                .string()
                .required(t('please-fill-in-the-cep', { ns: 'validation' }))
                .max(9, t('cep-min-number-character', { ns: 'validation' })),
            publicPlace: yup.string().required(t('address', { ns: 'validation' })),
            numberPlace: yup.string().required(t('number', { ns: 'validation' })),
            district: yup.string().required(t('district', { ns: 'validation' })),
            state: yup.string().required(t('state', { ns: 'validation' })),
            cityName: yup.string().required(t('city', { ns: 'validation' })),
        }),
        onSubmit: (values) => {
            if (organizationUnit) {
                return updateOrganizationUnit(values);
            }

            return createOrganizationUnit(values);
        },
    });

    const onBlurCep = async (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        setFetchingCEP(true);
        const cepValue = e.target.value;
        const cep = cepValue?.replace(/[^0-9]/g, '');

        if (cep?.length > 8) {
            setErrorLenghtCEP(true);
            setTimeout(() => {
                setErrorLenghtCEP(false);
            }, 3000);
        }

        if (cep?.length !== 8) {
            return;
        }

        const request = async () => {
            const data = await addressRequest.getAddress(cep);
            setDataViaCep(data);

            if (!data.localidade) {
                setErrorSearchCEP(true);
            } else {
                setErrorSearchCEP(false);
            }

            setFetchingCEP(false);
            form.setValues({
                ...form.values,
                publicPlace: data.logradouro,
                district: data.bairro,
                cityName: data.localidade,
                state: data.uf,
            });
        };

        request();
    };

    const editingOrganizationUnit = !!organizationUnit?.id;

    const inputProps = {
        endAdornment: fetchingCEP && <CircularProgress color='secondary' size={20} />,
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={onClose}
            header={
                <Typography style={{ color: 'white' }}>
                    {t('organization.organization-data.components.cadaster-unit-buy')}
                </Typography>
            }
        >
            <Box maxWidth='600px'>
                <Grid container spacing={2}>
                    <Grid container item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            autoFocus={editingOrganizationUnit}
                            label={t('organization.organization-data.components.external-id')}
                            name='externalId'
                            onChange={form.handleChange}
                            value={form.values.externalId}
                            error={form.touched.externalId && Boolean(form.errors.externalId)}
                            helperText={form.touched.externalId && form.errors.externalId}
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <MaskedTextField
                            fullWidth
                            label='CNPJ'
                            autoFocus={!editingOrganizationUnit}
                            mask='99.999.999/9999-99'
                            name='docNumber'
                            value={form.values.docNumber}
                            onChange={form.handleChange}
                            error={form.touched.docNumber && Boolean(form.errors.docNumber)}
                            helperText={form.touched.docNumber && form.errors.docNumber}
                        />
                    </Grid>
                    <Grid container item md={5} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            autoFocus={editingOrganizationUnit}
                            label={t('organization.organization-data.components.name-unit')}
                            name='organizationUnitName'
                            onChange={form.handleChange}
                            value={form.values.organizationUnitName}
                            error={
                                form.touched.organizationUnitName &&
                                Boolean(form.errors.organizationUnitName)
                            }
                            helperText={
                                form.touched.organizationUnitName &&
                                form.errors.organizationUnitName
                            }
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <MaskedTextField
                            mask='99999-999'
                            fullWidth
                            label='CEP'
                            name='zipCode'
                            value={form.values.zipCode}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                form.setFieldValue(
                                    'zipCode',
                                    e.target.value.replace(/[^0-9]/g, '')
                                );
                            }}
                            error={form.touched.zipCode && Boolean(form.errors.zipCode)}
                            helperText={form.touched.zipCode && form.errors.zipCode}
                            onBlur={(e: any) => onBlurCep(e)}
                            InputProps={inputProps}
                        />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={Boolean(dataViaCep?.logradouro && true)}
                            label={t('term.address')}
                            name='publicPlace'
                            value={form.values.publicPlace}
                            onChange={form.handleChange}
                            error={form.touched.publicPlace && Boolean(form.errors.publicPlace)}
                            helperText={form.touched.publicPlace && form.errors.publicPlace}
                            InputProps={inputProps}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.number')}
                            name='numberPlace'
                            value={form.values.numberPlace}
                            onChange={form.handleChange}
                            error={form.touched.numberPlace && Boolean(form.errors.numberPlace)}
                            helperText={form.touched.numberPlace && form.errors.numberPlace}
                            InputProps={inputProps}
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.complement')}
                            name='complementPlace'
                            value={form.values.complementPlace}
                            onChange={form.handleChange}
                            error={
                                form.touched.complementPlace && Boolean(form.errors.complementPlace)
                            }
                            helperText={form.touched.complementPlace && form.errors.complementPlace}
                            InputProps={inputProps}
                        />
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.district')}
                            name='district'
                            disabled={Boolean(dataViaCep?.bairro && true)}
                            value={form.values.district}
                            onChange={form.handleChange}
                            error={form.touched.district && Boolean(form.errors.district)}
                            helperText={form.touched.district && form.errors.district}
                            InputProps={inputProps}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            select
                            fullWidth
                            disabled={Boolean(dataViaCep?.uf && true)}
                            name='state'
                            label={t('term.select-state')}
                            onChange={form.handleChange}
                            error={form.touched.state && Boolean(form.errors.state)}
                            helperText={form.touched.state && form.errors.state}
                            value={form.values.state}
                        >
                            {ufValues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={Boolean(dataViaCep?.localidade && true)}
                            label={t('term.city')}
                            name='cityName'
                            value={form.values.cityName}
                            onChange={form.handleChange}
                            error={form.touched.cityName && Boolean(form.errors.cityName)}
                            helperText={form.touched.cityName && form.errors.cityName}
                            InputProps={inputProps}
                        />
                    </Grid>
                    <Box m={1}>
                        {errorSearchCEP && (
                            <Typography variant='body2' color='error'>
                                {t('sign-up.pages.stepper-organization.address.info-error-cep')}
                            </Typography>
                        )}
                        {errorLenghtCEP && (
                            <Typography variant='body2' color='error'>
                                {t(
                                    'sign-up.pages.stepper-organization.address.info-error-cep-min-char'
                                )}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid container justifyContent='flex-end'>
                    <Grid>
                        <Button
                            style={{ marginTop: 20 }}
                            className='btn-add'
                            size='small'
                            type='submit'
                            color='primary'
                            variant='contained'
                            onClick={() => form.submitForm()}
                        >
                            {organizationUnit ? t('term.update') : t('term.add')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalEditOrganizationUnit;
