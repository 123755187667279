import {
    CircularProgress,
    Link,
    TablePagination,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Chip,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { formatCurrency, formatDate, getTypeInvoice, getStatusPayment } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { ListProviderInvoicesResponse } from 'clients/manager/interfaces/invoice.interface';
import { AiOutlineBarcode } from 'react-icons/ai';
import { HiCreditCard, HiOutlineReceiptTax } from 'react-icons/hi';
import { TypeBilling } from 'common/enums';
import { useStyles } from './search-invoices-table-styles';

const rowsPerPageOptions = [10, 20];

interface SearchInvoicesTableViewProps {
    queryParams: UsePaginatedQueryResponse<ListProviderInvoicesResponse>;
    getProposalUrl: (id: number) => string;
}

const SearchInvoicesTableView: React.FC<SearchInvoicesTableViewProps> = ({
    queryParams,
    getProposalUrl,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { data, loading, totalCount, page, limit, setLimit, setPage } = queryParams;
    const invoices = data?.data;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>
                            {t(
                                'invoices.page.search-invoices.search-invoices-table.auctionNoticeId'
                            )}
                        </TableCell>
                        <TableCell>
                            {t('invoices.page.search-invoices.search-invoices-table.organization')}
                        </TableCell>
                        <TableCell>
                            {t('invoices.page.search-invoices.search-invoices-table.typeInvoice')}
                        </TableCell>
                        <TableCell>
                            {t('invoices.page.search-invoices.search-invoices-table.price')}
                        </TableCell>
                        <TableCell>
                            {t('invoices.page.search-invoices.search-invoices-table.statusPayment')}
                        </TableCell>
                        <TableCell>
                            {t('invoices.page.search-invoices.search-invoices-table.dueDate')}
                        </TableCell>
                        <TableCell>
                            {t('invoices.page.search-invoices.search-invoices-table.actions')}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {invoices?.map((invoice) => {
                        const payedWithCreditCard = invoice.typeBilling === TypeBilling.credit_card;

                        return (
                            <TableRow hover key={invoice?.id}>
                                <Tooltip
                                    disableHoverListener={
                                        !invoice?.auctionNoticeId?.[0]?.simpleDescription
                                    }
                                    title={invoice?.auctionNoticeId?.[0]?.simpleDescription}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{invoice?.id}</TableCell>
                                </Tooltip>
                                <TableCell>
                                    <Link href={getProposalUrl(invoice?.auctionNoticeId?.[0]?.id)}>
                                        <Typography variant='body2'>
                                            {invoice?.auctionNoticeId?.[0]?.auctionNumber}
                                        </Typography>
                                    </Link>
                                </TableCell>
                                <Tooltip
                                    disableHoverListener={
                                        !invoice?.auctionNoticeId?.[0]?.simpleDescription
                                    }
                                    title={invoice?.auctionNoticeId?.[0]?.simpleDescription}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        {
                                            invoice?.auctionNoticeId?.[0]?.organization
                                                ?.organizationName
                                        }
                                    </TableCell>
                                </Tooltip>
                                <TableCell>{getTypeInvoice(invoice?.typeInvoice)}</TableCell>
                                <TableCell>{formatCurrency(invoice?.price)}</TableCell>
                                <TableCell>
                                    {invoice?.statusPayment && (
                                        <Chip
                                            classes={{
                                                root: classes[invoice?.statusPayment],
                                            }}
                                            label={getStatusPayment(invoice?.statusPayment)}
                                            size='small'
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {invoice?.dueDate ? formatDate(invoice?.dueDate) : ''}
                                </TableCell>
                                <TableCell align='center' padding='none' style={{ width: 100 }}>
                                    <Grid>
                                        <Tooltip
                                            title={
                                                payedWithCreditCard
                                                    ? t(
                                                          'invoices.page.search-invoices.search-invoices-table.payed-with-credit-card'
                                                      )
                                                    : t(
                                                          'invoices.page.search-invoices.search-invoices-table.billet-link'
                                                      )
                                            }
                                        >
                                            <Link
                                                href={invoice?.bankSlipUrl}
                                                target='_blank'
                                                rel='noreferrer'
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <IconButton
                                                    disabled={
                                                        payedWithCreditCard || !invoice?.bankSlipUrl
                                                    }
                                                >
                                                    {payedWithCreditCard ? (
                                                        <HiCreditCard />
                                                    ) : (
                                                        <AiOutlineBarcode />
                                                    )}
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip
                                            title={`${t(
                                                'invoices.page.search-invoices.search-invoices-table.nfse-link'
                                            )} ${invoice?.nfseNumber ?? ''}`}
                                        >
                                            <Link
                                                href={invoice?.nfsePdfUrl}
                                                target='_blank'
                                                rel='noreferrer'
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <IconButton disabled={!invoice?.nfsePdfUrl}>
                                                    <HiOutlineReceiptTax />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {invoices && !loading && !invoices.length && (
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={8} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={totalCount}
                page={page}
                onPageChange={(_e, page) => setPage(page)}
                rowsPerPage={limit || rowsPerPageOptions[0]}
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                onRowsPerPageChange={(event) =>
                    setLimit(Number(event.target.value) ?? rowsPerPageOptions[0])
                }
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </>
    );
};

export default SearchInvoicesTableView;
