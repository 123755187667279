import { FC } from 'react';
import { Box, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { FaGavel, FaRobot } from 'react-icons/fa';
import { HiUser } from 'react-icons/hi';
import { formatDate } from 'common/utils';
import ReadMore from '../read-more';
import { Chat, SenderType } from '../../../../clients/manager/interfaces/process-chats.interface';

const useStyles = makeStyles(() => ({
    root: {
        padding: '10px 0',
    },
    textTimestamp: {
        fontSize: 12,
        color: '#888',
    },
    message: {
        fontSize: 13,
        color: '#444',
    },
    senderLabel: {
        fontSize: 13,
    },
    divider: {},
}));

interface ChatCardViewProps {
    chat: Chat;
}

const senderTypeLabel = {
    [SenderType.auction]: 'Pregoeiro(a)',
    [SenderType.provider]: 'Fornecedor',
    [SenderType.system]: 'Sistema',
};

const ChatCardView: FC<ChatCardViewProps> = ({ chat }) => {
    const classes = useStyles();

    const getSenderTypeLabel = () => {
        const label = senderTypeLabel[chat.senderType];

        if (chat.senderType === SenderType.provider) {
            return `${label} ${String(chat.providerAuctionCode).padStart(2, '0')}`;
        }

        return label;
    };

    const getSenderTypeIcon = () => {
        switch (chat.senderType) {
            case SenderType.auction:
                return <FaGavel />;

            case SenderType.system:
                return <FaRobot />;

            case SenderType.provider:
                return <HiUser />;

            default:
                return <HiUser />;
        }
    };

    return (
        <>
            <Box width={1} className={classes.root} pb={2} pt={2}>
                <Grid container>
                    <Grid xs={2}>
                        <IconButton style={{ background: '#ececec' }} color='primary'>
                            {getSenderTypeIcon()}
                        </IconButton>
                    </Grid>
                    <Grid xs={10}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography className={classes.senderLabel}>
                                    {getSenderTypeLabel()}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display='flex' justifyContent='flex-end'>
                                    <Typography className={classes.textTimestamp}>
                                        {formatDate(chat.createdAt, 'DD/MM/YYYY HH:mm:ss')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={2} width={1}>
                                    <ReadMore
                                        className={classes.message}
                                        id={String(chat.id)}
                                        size={300}
                                        text={chat.message ?? ''}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Divider className={classes.divider} />
        </>
    );
};

export default ChatCardView;
