import React from 'react';
import {
    Checkbox,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { OrganizationUnit } from 'clients/manager/interfaces/organization.interface';
import { UseFormikResponse } from 'common/interfaces/formik.interface';
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab';
import { UserAuthorizationGroup } from 'modules/organization-users/interfaces/authorization-group-users.interface';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { EditOrganizationUsersFormValues } from '../../edit-organization-users-form-schema';

interface UnitAuthorizationProfileTableFieldProps {
    form: UseFormikResponse<EditOrganizationUsersFormValues>;
    organizationUnits?: OrganizationUnit[];
    authorizationGroups?: UserAuthorizationGroup[];
    disabled?: boolean;
    loading?: boolean;
}

const UnitAuthorizationProfileTableSkeleton = () => (
    <TableRow hover>
        <TableCell>
            <Skeleton width={70} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={20} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={90} variant='text' />
        </TableCell>
    </TableRow>
);

const UnitAuthorizationProfileTableField: React.FC<UnitAuthorizationProfileTableFieldProps> = ({
    form,
    organizationUnits,
    authorizationGroups,
    disabled,
    loading,
}) => {
    const { t } = useTranslation();

    const hasError = form?.touched?.organizationUnits && !!form?.errors?.organizationUnits;
    const errorText = hasError ? form?.errors?.organizationUnits : '';

    return (
        <>
            {hasError && (
                <Alert severity='error' style={{ width: '100%' }}>
                    <strong>{t('term.err')}!</strong> {errorText}
                </Alert>
            )}
            <Table style={{ opacity: disabled ? 0.7 : 1 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('organization.organization-data.components.name-unit')}
                        </TableCell>
                        <TableCell>{t('term.administrator')}</TableCell>
                        <TableCell>
                            {t('group-auth.pages.edit-group.components.authorization')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <>
                            <UnitAuthorizationProfileTableSkeleton />
                            <UnitAuthorizationProfileTableSkeleton />
                        </>
                    )}
                    {organizationUnits &&
                        organizationUnits?.map((row, index) => (
                            <TableRow hover key={row.id}>
                                <TableCell>{row.organizationUnitName}</TableCell>
                                <TableCell>
                                    <Checkbox
                                        name={`organizationUnits[${index}].isAdmin`}
                                        checked={
                                            !!form?.values?.organizationUnits?.[index]?.isAdmin
                                        }
                                        disabled={disabled}
                                        onChange={(_, values) => {
                                            form.setFieldValue(
                                                `organizationUnits[${index}].isAdmin`,
                                                values
                                            );
                                            form.setFieldValue(
                                                `organizationUnits[${index}].organizationUnitId`,
                                                row.id
                                            );
                                        }}
                                        color='primary'
                                    />
                                </TableCell>
                                <TableCell style={{ maxWidth: '30vw' }}>
                                    <Autocomplete
                                        fullWidth
                                        disabled={disabled}
                                        hidden={form?.values?.organizationUnits?.[index]?.isAdmin}
                                        multiple
                                        value={
                                            form?.values?.organizationUnits?.[index]
                                                ?.authorizationGroups ?? []
                                        }
                                        onChange={(_, values) => {
                                            form.setFieldValue(
                                                `organizationUnits[${index}].authorizationGroups`,
                                                values
                                            );
                                            form.setFieldValue(
                                                `organizationUnits[${index}].organizationUnitId`,
                                                row.id
                                            );
                                            form.setFieldValue(
                                                `organizationUnits[${index}].isAdmin`,
                                                false
                                            );
                                        }}
                                        options={authorizationGroups ?? []}
                                        disableCloseOnSelect
                                        getOptionLabel={(option: any) => option?.name}
                                        getOptionSelected={(option, value) =>
                                            option?.name === value?.name
                                        }
                                        renderOption={(option, state) => (
                                            <>
                                                <Checkbox
                                                    checked={state?.selected}
                                                    color='primary'
                                                />
                                                {option?.name}
                                            </>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t(
                                                    'organization-users.pages.components.group-auth'
                                                )}
                                                placeholder={t(
                                                    'organization-users.pages.components.select-group-auth'
                                                )}
                                                error={hasError}
                                                InputProps={{
                                                    ...params?.InputProps,
                                                    endAdornment: loading && (
                                                        <CircularProgress
                                                            color='secondary'
                                                            size={20}
                                                        />
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    {!loading && !!organizationUnits && !organizationUnits.length && (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default UnitAuthorizationProfileTableField;
