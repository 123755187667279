import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from 'common/components';
import ReactDOM from 'react-dom';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { disableField } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../context/process-form.context';
import { AuctionTypeRule } from '../../../../clients/manager/interfaces/auction-type-rules.interface';
import { auctionTypeRulesRequests } from '../../../../clients/manager/auction-type-rules.requests';

interface SelectAuctionTypeRuleProps {
    onChange: (value: AuctionTypeRule) => void;
}

const SelectAuctionTypeRule: FC<SelectAuctionTypeRuleProps> = ({ onChange }) => {
    const { t } = useTranslation();
    const { processForm, setAuctionTypeRules, process } = useProcessFormContext();

    const [auctionTypes, setAuctionTypes] = useState<AuctionTypeRule[]>([]);
    const [fetching, setFetching] = useState(false);

    const getAuctionTypeRules = useCallback(async () => {
        try {
            setFetching(true);

            const response = await auctionTypeRulesRequests.listAuctionTypeRules();
            ReactDOM.unstable_batchedUpdates(() => {
                const currentAuctionRules = response.data.find(
                    (auctionTypeRule) => auctionTypeRule.id === processForm?.values.ruleId
                );

                if (currentAuctionRules) {
                    setAuctionTypeRules(currentAuctionRules);
                }

                setAuctionTypes(response.data);
            });
        } catch (error) {
            console.error(error);
        } finally {
            setFetching(false);
        }
    }, []);

    useEffect(() => {
        getAuctionTypeRules();
    }, []);

    const value = useMemo(() => {
        if (processForm?.values.ruleId) {
            return auctionTypes.find(
                (auctionType) => auctionType.id === processForm?.values.ruleId
            );
        }

        return undefined;
    }, [auctionTypes, processForm?.values.ruleId]);

    return (
        <Autocomplete
            key={auctionTypes.length}
            options={auctionTypes}
            loading={fetching}
            disabled={disableField('auctionType', process, fetching)}
            clearOnEscape
            disableClearable
            defaultValue={value}
            getOptionLabel={(option) => option.description}
            renderInput={(params) => (
                <TextField {...params} label={t('term.modality')} variant='outlined' />
            )}
            onChange={(_, auctionType) => {
                if (auctionType) {
                    onChange(auctionType);
                }
            }}
        />
    );
};

export default SelectAuctionTypeRule;
