import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PlayCircleFilled } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { getDisputeUrl } from 'common/utils/getters/get-url-redirect-dispute.utils';
import { usePlatformContext } from 'routes/platform.context';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { useStyles } from './styles';

interface ChipDisputeRoomProps {
    processId: number;
}

const ChipDisputeRoom: FC<ChipDisputeRoomProps> = ({ processId }) => {
    const { t } = useTranslation();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const classes = useStyles();
    const { platform } = usePlatformContext();

    const redirectDisputeRoom = async (ev) => {
        ev.stopPropagation();
        const { data } = await auctionNoticeRequests.getService(processId);
        return window.open(getDisputeUrl(processId, authUserTokenData, data, platform), '_blank');
    };

    return (
        <Chip
            onClick={redirectDisputeRoom}
            className={clsx(classes.chip, classes.clickable)}
            icon={<PlayCircleFilled />}
            color='default'
            size='small'
            classes={{ labelSmall: classes.withoutLabel }}
            title={t('info.go-to-dispute-room')}
        />
    );
};

export default ChipDisputeRoom;
