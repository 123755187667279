import { FC, useState } from 'react';
import VisibleMaskDocView from './visible-mask-doc-number-view';

interface VisibleMaskDocProps {
    cpf: string;
    rg: string;
    isForeignDocument?: boolean;
}

const VisibleMaskDoc: FC<VisibleMaskDocProps> = ({ cpf, rg, isForeignDocument }) => {
    const [showDoc, setShowDoc] = useState(false);

    const handleShowDoc = () => setShowDoc((state) => !state);

    return (
        <VisibleMaskDocView
            cpf={cpf}
            rg={rg}
            showDoc={showDoc}
            isForeignDocument={isForeignDocument}
            handleShowDoc={handleShowDoc}
        />
    );
};

export default VisibleMaskDoc;
