import { doRequest, managerApiInstance } from 'clients/api';
import { DocumentVariables } from '../interfaces/document-variables.interface';

const path = '/documents/variables';

const templateVariablesRequests = {
    getVariables: async () =>
        doRequest<DocumentVariables[]>(managerApiInstance.post(`${path}/listVariables`)),
    getVariable: async (documentVariableId: string) =>
        doRequest<DocumentVariables>(
            managerApiInstance.post(`${path}/getVariable`, { documentVariableId })
        ),
    createVariable: async (documentVariable: DocumentVariables) =>
        doRequest<any>(managerApiInstance.post(`${path}/createVariable`, documentVariable)),
    updateVariable: async (documentVariableId: string, documentVariable: DocumentVariables) =>
        doRequest<DocumentVariables>(
            managerApiInstance.post(`${path}/updateVariable`, {
                ...documentVariable,
                _id: documentVariableId,
            })
        ),
};

export { templateVariablesRequests };
