import jsPDF from 'jspdf';

const addTitle = (doc: jsPDF) => {
    doc.setFontSize(12);
    doc.setTextColor('#333');
    doc.setFont('Roboto', 'bold');
    const title = 'Relatório de Pesquisa de Preços';
    doc.text(title, 71, 30);
};

export { addTitle };
