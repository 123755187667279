import { CircularProgress } from '@material-ui/core';
import { auctionNoticeMessage } from 'clients/manager/auction-notice-message.requests';
import { addNotificationApiError, sleep } from 'common/utils';
import React, { ChangeEventHandler, Dispatch, SetStateAction, memo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { FaReplyAll } from 'react-icons/fa';
import { FiPaperclip } from 'react-icons/fi';
import { IoSend } from 'react-icons/io5';
import { RiArrowGoBackFill } from 'react-icons/ri';
import Chip from 'modules/bidding/components/process-comment-box/chip';
import {
    ProcessCommentBoxTextField,
    ProcessRequestButtonsContainer,
    ProcessRequestCommentAttachmentButton,
    ProcessRequestCommentChipsContainer,
    ProcessRequestCommentContainer,
    ProcessRequestCommentSendButton,
} from 'modules/bidding/components/process-comment-box/styled';
import { AuctionMessagesList } from 'clients/manager/interfaces/auction-notice.interface';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';

interface ProcessSolicitationsCommentBoxProps {
    id: any;
    messageId: any;
    getMessages?: (noLoading?: boolean) => Promise<AuctionMessagesList[]>;
    setMessages?: Dispatch<SetStateAction<AuctionMessagesList[]>>;
    onBack?: () => void;
}

const ProcessSolicitationsCommentBox: React.FC<ProcessSolicitationsCommentBoxProps> = ({
    id,
    messageId,
    setMessages,
    getMessages,
    onBack,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [files, setFiles] = useState<File[]>([]);

    const handleChangeFile: ChangeEventHandler<HTMLInputElement> = (event) => {
        const acceptFileTypes = ['application/pdf'];
        const inputFiles = event.target.files;
        const isAnyFileDenied =
            inputFiles &&
            Array.from(inputFiles).filter(({ type }) => !acceptFileTypes.includes(type)).length !==
                0;

        if (inputFiles?.length === 0 || inputFiles === null) return;
        if (isAnyFileDenied) return;

        const filteredFiles = (files: File[]) => [
            ...Array.from(inputFiles).filter(
                ({ name }) => !files.map(({ name }) => name).includes(name)
            ),
            ...files,
        ];

        setFiles((prev) => filteredFiles(prev));
    };

    const removeFile = (index: number) =>
        setFiles((prev) => prev.filter((_, fileIndex) => index !== fileIndex));

    const handleSubmit = async () => {
        if (!messageId || !getMessages || !setMessages) return;

        const formData = new FormData();
        formData.append('messageId', messageId);
        formData.append('answer', value);
        formData.append('auctionId', id);
        files.forEach((file) => {
            const newFileName = getNewFileName(file);
            formData.append('file', file, newFileName);
        });

        setLoading(true);

        try {
            await auctionNoticeMessage.answerMessage(formData);
            await sleep(1000 + 500 * files.length);
            const messages = await getMessages(true);

            unstable_batchedUpdates(() => {
                setValue('');
                setFiles([]);
                setMessages(messages);
            });
            onBack?.();
        } catch (e) {
            addNotificationApiError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ProcessRequestCommentContainer>
            <ProcessRequestCommentAttachmentButton disabled={loading} onClick={onBack}>
                <RiArrowGoBackFill title='Go back' />
            </ProcessRequestCommentAttachmentButton>
            <ProcessCommentBoxTextField
                minRows={1}
                maxRows={6}
                variant='outlined'
                value={value}
                onChange={({ target }) => setValue(target.value)}
                multiline
                InputProps={{
                    disabled: loading,
                    endAdornment: files.length > 0 && (
                        <ProcessRequestCommentChipsContainer>
                            {files.map((file, index) => (
                                <Chip
                                    key={file.name}
                                    title={file.name}
                                    onClose={() => removeFile(index)}
                                    disabled={loading}
                                />
                            ))}
                        </ProcessRequestCommentChipsContainer>
                    ),
                }}
            />
            <ProcessRequestButtonsContainer>
                <ProcessRequestCommentAttachmentButton disabled={loading} onClick={onBack}>
                    <FaReplyAll />
                </ProcessRequestCommentAttachmentButton>
                <section>
                    <ProcessRequestCommentAttachmentButton
                        disabled={loading}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <input
                            ref={fileInputRef}
                            onChange={handleChangeFile}
                            accept='application/pdf'
                            hidden
                            type='file'
                            multiple
                        />
                        <FiPaperclip />
                    </ProcessRequestCommentAttachmentButton>
                    <ProcessRequestCommentSendButton disabled={loading} onClick={handleSubmit}>
                        {loading ? (
                            <CircularProgress color='inherit' size={24} />
                        ) : (
                            <IoSend color='inherit' />
                        )}
                    </ProcessRequestCommentSendButton>
                </section>
            </ProcessRequestButtonsContainer>
        </ProcessRequestCommentContainer>
    );
};

export default memo(ProcessSolicitationsCommentBox);
