import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import ModalEditContract from '../../../../../../common/components/contracts/components/modal-edit-contract';
import ContractActions from '../../../../../../common/components/contracts/components/contract-actions';
import { ContractWithDetails, ListContractsFromProcessResponse } from 'clients/manager/interfaces/contract.interface';

export interface SearchContractsActionsViewProps {
    contractWithDetails: ContractWithDetails;
    icon?: React.ReactNode;
    setContracts: React.Dispatch<React.SetStateAction<ListContractsFromProcessResponse[]>>;
    deleteContract: (contractWithDetails: ContractWithDetails) => Promise<void>;
    deleteContractFromPncp: (contractId: string, justification: string) => Promise<void>;
}

const SearchContractsActionsView: FC<SearchContractsActionsViewProps> = ({
    contractWithDetails,
    icon,
    setContracts,
    deleteContract,
    deleteContractFromPncp,
}) => {
    const [modalEditVisible, setModalEditVisible] = useState(false);

    return (
        <>
            {!!modalEditVisible && (
                <ModalEditContract
                    onClose={() => setModalEditVisible(false)}
                    contractWithDetails={contractWithDetails}
                    setContracts={setContracts}
                />
            )}

            <Grid>
                <ContractActions
                    contractWithDetails={contractWithDetails}
                    onEditContract={() => setModalEditVisible(true)}
                    icon={icon}
                    deleteContract={deleteContract}
                    deleteContractFromPncp={deleteContractFromPncp}
                />
            </Grid>
        </>
    );
};

export default SearchContractsActionsView;
