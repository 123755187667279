import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TypeOfBenefitDialogProps } from 'modules/process/interfaces/type-of-benefit';

export const TypeBenefitDialog = ({
    open,
    title,
    message,
    onConfirm,
    onCancel,
}: TypeOfBenefitDialogProps) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth='xs'
        >
            <Box p={1}>
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent>
                    <DialogContentText color='textPrimary'>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color='primary' size='small'>
                        {t('term.cancel')}
                    </Button>
                    <Button variant='contained' color='primary' onClick={onConfirm} size='small'>
                        {t('term.confirm')}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
