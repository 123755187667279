import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const Content = styled(Grid)`
    display: flex;
    margin-bottom: 12px;

    .first-item {
        display: flex;
        gap: 25px;
        flex-direction: column;
    }

    .consent {
        display: flex;
        align-items: center;

        .MuiFormControlLabel-root {
            margin-right: 10px;
        }
    }

    .validation {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 6px;
        .validation-false {
            color: #dddddd;
        }
        .validation-true {
            color: #02ae51;
        }
    }
`;
