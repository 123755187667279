import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import {
    GetAccountJourneyResponse,
    GetImportantProcessResponse,
} from '../interfaces/provider-dashboard.interface';

const BASE_PATH = '/provider-dashboard';

const providerDashboardRequests = {
    getProviderSummary: auctionNoticeRequests.getSearchResume,
    getAccountJourney: async (config?: AxiosRequestConfig<any>) =>
        doRequest<GetAccountJourneyResponse>(
            managerApiInstance.post(`${BASE_PATH}/getAccountJourney`, undefined, config)
        ),
    getImportantProcess: async (config?: AxiosRequestConfig<any>) =>
        doRequest<GetImportantProcessResponse>(
            managerApiInstance.post(`${BASE_PATH}/getImportantProcess`, undefined, config)
        ),
};

export { providerDashboardRequests };
