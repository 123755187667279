import { FC } from 'react';
import { Extraction } from 'common/interfaces/extraction.interface';
import { Card, CardContent, Table } from '@licitar/ui-kit';
import moment from 'moment';
import { FcCancel } from 'react-icons/fc';
import { styled } from 'common/theme/styled';
import { getBoolToYesOrNot } from 'common/utils';
import { useTranslation } from 'react-i18next';

const CancelContainer = styled('div')`
    display: flex;
    justify-content: center;
`;

type ComponentType = FC<{
    extractions: Extraction[];
    onCancel: () => void;
}>;

const getFormattedDate = (date: any) => (date ? moment(date).format(' DD/MM HH:mm:ss') : '');

const ExtractionList: ComponentType = ({ extractions, onCancel }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent>
                <Table>
                    <thead>
                        <tr>
                            <th>{t('term.extracting')}</th>
                            <th>{t('term.extractor')}</th>
                            <th>{t('term.home')}</th>
                            <th>{t('term.start-data')}</th>
                            <th>{t('term.finished')}</th>
                            <th>{t('term.finalization-data')}</th>
                            <th>{t('term.err')}</th>
                            <th>{t('term.error-data')}</th>
                            <th>{t('term.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {extractions.map((extraction) => (
                            <tr>
                                <td>{getBoolToYesOrNot(extraction.isRunningExtraction)}</td>
                                <td> {extraction.extractor} </td>
                                <td>{getFormattedDate(extraction.startedAt)}</td>
                                <td>{JSON.stringify(extraction.startData)}</td>
                                <td>{getFormattedDate(extraction.endedAt)}</td>
                                <td>{JSON.stringify(extraction.endData)}</td>
                                <td>{getFormattedDate(extraction.errorAt)}</td>
                                <td>{JSON.stringify(extraction.errorData)}</td>
                                <td>
                                    {extraction.isRunningExtraction && (
                                        <CancelContainer>
                                            <FcCancel
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                title={t('term.cancel-extraction')}
                                                onClick={onCancel}
                                            />
                                        </CancelContainer>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default ExtractionList as ComponentType;
