import { styled } from 'common/theme/styled';

const Content = styled.div``;

const ActionsArea = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0 0;
`;

const OverLay = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000014;
    z-index: 5;
    pointer-events: none;
    cursor: default;
`;

export { Content, ActionsArea, OverLay };
