import { styled } from 'common/theme/styled';

export const InputWrapper = styled('div')`
    margin-top: 10px;
`;

export const BtnContainer = styled(InputWrapper)`
    display: flex;
    justify-content: space-between;
`;
