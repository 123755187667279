import { useEffect, useState } from 'react';
import { chatFavoriteMessageRequest } from 'clients/manager/chat-favorite-message.requests';
import {
    ChatFavoriteMessage,
    UpdateFavoriteMessage,
} from 'clients/manager/interfaces/chat-favorite-message.interface';
import FavoriteMessageView from './favorite-message-view';
import ModalEditOrCreateFavoriteMessage from '../modal-edit-or-create-favorite-message';

const FavoriteMessage = () => {
    const [messages, setMessages] = useState<ChatFavoriteMessage[]>([]);
    const [message, setMessage] = useState<ChatFavoriteMessage | undefined>();
    const [fetching, setFetching] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const listMessages = async () => {
            setFetching(true);
            const { data } = await chatFavoriteMessageRequest.list();
            setMessages(data);
            setFetching(false);
        };
        listMessages();
    }, []);

    const handleEditMessage = (favoriteMessage: ChatFavoriteMessage) => {
        setMessage(favoriteMessage);
        setModalVisible(true);
    };

    const handleCreateMessage = () => {
        setMessage(undefined);
        setModalVisible(true);
    };

    const onCreate = (favoriteMessage: ChatFavoriteMessage) => {
        setMessages((prev) => [...prev, favoriteMessage]);
    };

    const onUpdate = (favoriteMessage: UpdateFavoriteMessage) => {
        setMessages((prev) =>
            prev.map((message) =>
                message.id === favoriteMessage.id ? { ...message, ...favoriteMessage } : message
            )
        );
    };

    const onDelete = (id: number) => {
        const newMessages = messages.filter((message) => message.id !== id);
        setMessages(newMessages);
    };

    const deleteFavoriteMessage = async (id: number) => {
        setFetching(true);
        await chatFavoriteMessageRequest.delete({ id });
        onDelete(id);
        setFetching(false);
    };

    const onClose = () => {
        setModalVisible(false);
    };
    return (
        <>
            {modalVisible && (
                <ModalEditOrCreateFavoriteMessage
                    onClose={onClose}
                    favoriteMessage={message}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                />
            )}
            <FavoriteMessageView
                messages={messages}
                fetching={fetching}
                handleEditMessage={handleEditMessage}
                handleCreateMessage={handleCreateMessage}
                deleteFavoriteMessage={deleteFavoriteMessage}
            />
        </>
    );
};

export default FavoriteMessage;
