import { diff } from 'deep-object-diff';
import { FormikProps, useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import ReactDOM from 'react-dom';
import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { usePlatformContext } from 'routes/platform.context';
import { debounce } from 'lodash';
import { DeepPartial } from 'redux';
import { AuctionTypeEnum } from 'common/enums';
import { auctionNoticeLotRequests } from '../../../clients/manager/auction-notice-lot.requests';
import {
    AuctionNoticeLotWithItems,
    TypeOfBenefitValue,
    TypeOfItemsValue,
} from '../../../clients/manager/interfaces/auction-notice-lot.interface';
import {
    AuctionLotWithItemsForm,
    getProdValidationSchema,
    transformLotItemToCompareValues,
    transformLotToCompareValues,
} from './lots.form';
import { useProcessFormContext } from './process-form.context';

export type ProcessLotsFormContextType = {
    processLotsForm: FormikProps<AuctionLotWithItemsForm>;
    processLotsAndItems: AuctionNoticeLotWithItems[];
    setProcessLotsAndItems: React.Dispatch<React.SetStateAction<AuctionNoticeLotWithItems[]>>;
    formHasChanges: boolean;
    processLotsBkpBeforeSave?: AuctionLotWithItemsForm;
    setProcessLotsBkpBeforeSave?: React.Dispatch<
        React.SetStateAction<AuctionLotWithItemsForm | undefined>
    >;
    fetchingLots: boolean;
    processLotsResume: { count: number };
    setFieldValue: (lot: DeepPartial<AuctionNoticeLotWithItems>, field: string, value: any) => void;
    setDebouncedFieldValue: (
        lot: DeepPartial<AuctionNoticeLotWithItems>,
        field: string,
        value: any
    ) => void;
    updateFormsOnAuctionTypeChange: (auctionType: AuctionTypeEnum) => void;
};

export const ProcessLotsFormContext = createContext<ProcessLotsFormContextType>({
    processLotsForm: {
        errors: {},
        initialValues: {},
        submitCount: 0,
        isValidating: false,
        isSubmitting: false,
        values: {},
        touched: {},
    } as any,
    setProcessLotsAndItems: () => { },
    processLotsAndItems: [],
    formHasChanges: false,
    processLotsBkpBeforeSave: undefined,
    setProcessLotsBkpBeforeSave: () => { },
    fetchingLots: true,
    processLotsResume: { count: 0 },
    setFieldValue: () => { },
    setDebouncedFieldValue: () => { },
    updateFormsOnAuctionTypeChange: () => { },
});

export const useProcessLotsFormContext = () => useContext(ProcessLotsFormContext);

let fixedErrors: { [key: number]: any } = {};
let forceValidateAllLots = false;

export const ProcessLotsFormContextProvider = ({ children }: { children: ReactNode }) => {
    const [processLotsAndItems, setProcessLotsAndItems] = useState<AuctionNoticeLotWithItems[]>([]);
    // state que mantém ultima versão do documento pós salvamento para comparação de diferenças
    const [processLotsBkpBeforeSave, setProcessLotsBkpBeforeSave] = useState<
        AuctionLotWithItemsForm | undefined
    >();

    const [fetchingLots, setFetchingLots] = useState<boolean>(true);
    const [processLotsResume, setProcessLotsResume] = useState<{ count: number }>({ count: 0 });

    const { platform } = usePlatformContext();

    const processLotsBkpBeforeSaveRef =
        useRef<
            Pick<
                ProcessLotsFormContextType,
                'processLotsBkpBeforeSave' | 'setProcessLotsBkpBeforeSave'
            >
        >();
    processLotsBkpBeforeSaveRef.current = { processLotsBkpBeforeSave, setProcessLotsBkpBeforeSave };

    const processLotsAndItemsRef =
        useRef<
            Pick<ProcessLotsFormContextType, 'processLotsAndItems' | 'setProcessLotsAndItems'>
        >();
    processLotsAndItemsRef.current = { processLotsAndItems, setProcessLotsAndItems };

    const { process, processForm, auctionTypeRules } = useProcessFormContext();
    const [errors, setErrors] = useState<{ [key: number]: any }>({});

    const initialValues = {
        lots: processLotsAndItems,
    };

    const handleSetErrors = (errors: { [key: number]: any }) => {
        fixedErrors = errors;
        setErrors(errors);
    };

    const form = useFormik<AuctionLotWithItemsForm>({
        enableReinitialize: true,
        validateOnMount: true,
        isInitialValid: false,
        initialValues,
        validate: (values) => {
            if (!processForm?.values) {
                return {};
            }

            if (Object.keys(fixedErrors).length && !forceValidateAllLots) {
                const keysWithDiff = Object.keys(
                    diff(processLotsBkpBeforeSave ?? {}, values ?? {})
                );

                const newValues = {};

                keysWithDiff.forEach((lotId) => {
                    if (values[lotId]) {
                        newValues[lotId] = values[lotId];
                    }
                });

                const oldErrors = { ...errors };

                try {
                    validateYupSchema(
                        newValues,
                        getProdValidationSchema(
                            values.lots,
                            processForm.values,
                            auctionTypeRules,
                            platform
                        ),
                        true
                    );

                    keysWithDiff.forEach((lotId) => {
                        delete oldErrors[lotId];
                    });
                    handleSetErrors(oldErrors);
                    return oldErrors;
                } catch (err) {
                    const newErrors = yupToFormErrors(err);

                    Object.keys(newErrors).forEach((lotId) => {
                        oldErrors[lotId] = newErrors[lotId];
                    });

                    handleSetErrors(oldErrors);
                    return oldErrors;
                }
            }

            try {
                validateYupSchema(
                    values,
                    getProdValidationSchema(
                        values.lots,
                        processForm.values,
                        auctionTypeRules,
                        platform
                    ),
                    true
                );
                forceValidateAllLots = false;
            } catch (err) {
                const errors = yupToFormErrors(err);
                forceValidateAllLots = false;
                setErrors(errors);
                return errors;
            }

            return {};
        },
        onSubmit: () => { },
    });

    // todos os campos utilizados dentro do getProdValidationSchema
    // devem ser usados aqui para atualizar novamente os erros do form
    useEffect(() => {
        fixedErrors = {};
        // forceValidateAllLots = true;
        form.validateForm();
    }, [
        processForm?.values.auctionType,
        processForm?.values.typeValueBid,
        processForm?.values.judgmentCriterion,
        processForm?.values.rateTypeBid,
    ]);

    // seta estado inicial do backup para comparações de diferenças
    useEffect(() => {
        setProcessLotsBkpBeforeSave(initialValues);
        handleSetErrors({});
    }, [processLotsAndItems]);

    useEffect(() => {
        setProcessLotsResume((prevState) => ({
            ...prevState,
            count: Object.keys(form.values).length,
        }));
    }, [Object.keys(form.values).length]);

    function updateFormsOnAuctionTypeChange(auctionType: AuctionTypeEnum) {
        form.setValues((formValues) => {
            let newValues: DeepPartial<AuctionNoticeLotWithItems>[] = formValues.lots;

            function getDefaultValues(item: DeepPartial<AuctionNoticeLotWithItems>) {
                return {
                    ...item,
                    propertyOrFurnitureCode: undefined,
                    itemsKits: undefined,
                    // eslint-disable-next-line no-nested-ternary
                    typeOfBenefit: [
                        AuctionTypeEnum.accreditation,
                        AuctionTypeEnum.auction,
                    ].includes(auctionType)
                        ? undefined
                        : item?.typeOfBenefit
                            ? item.typeOfBenefit
                            : TypeOfBenefitValue.noBenefit,
                    markIsRequired: 0,
                    forceSameDescription: [
                        AuctionTypeEnum.accreditation,
                        AuctionTypeEnum.auction,
                    ].includes(auctionType)
                        ? 1
                        : 0,
                    requireDocuments: 0,
                    showReferenceValue: 0,
                    allowMultipleWinner: false,
                    typeOfItems:
                        item.typeOfItems === TypeOfItemsValue.product ||
                            item.typeOfItems === TypeOfItemsValue.service
                            ? item.typeOfItems
                            : TypeOfItemsValue.product,
                };
            }

            newValues = newValues.map((item) => getDefaultValues(item));

            if (auctionType === AuctionTypeEnum.auction) {
                newValues = newValues.map((item) => ({
                    ...item,
                    typeOfItems:
                        item.typeOfItems === TypeOfItemsValue.properties ||
                            item.typeOfItems === TypeOfItemsValue.furniture
                            ? item.typeOfItems
                            : TypeOfItemsValue.properties,
                }));
            }

            return { lots: newValues as any };
        });
    }

    const getLotsFromProcess = async () => {
        if (!process?.id) {
            return setFetchingLots(false);
        }

        try {
            const response = await auctionNoticeLotRequests.listLotsFromProcess({
                params: {
                    auctionId: process.id,
                },
            });

            ReactDOM.unstable_batchedUpdates(() => {
                setFetchingLots(false);
                setProcessLotsAndItems(response.data);
                setProcessLotsResume((prevState) => ({
                    ...prevState,
                    count: response.meta?.count || 0,
                }));
            });
            // eslint-disable-next-line no-empty
        } catch (error) {
            setFetchingLots(false);
        }
    };

    useEffect(() => {
        if (!processLotsAndItems.length) {
            getLotsFromProcess();
        }
    }, [process?.id]);

    const hasChanges = useMemo(
        () =>
            !!form?.values?.lots.find((lotItem) => {
                const foundBkpLot = processLotsBkpBeforeSave?.lots.find(
                    (bkpItem) => bkpItem.item === lotItem.item && !bkpItem.quotaId
                );
                
                if (lotItem.id && !foundBkpLot?.id) return false;
                if (foundBkpLot?.quotaId || lotItem.quotaId) return false;
                if (!foundBkpLot) return true;


                const hasLotsChanges =
                    Object.keys(
                        diff(
                            transformLotToCompareValues(lotItem),
                            transformLotToCompareValues(foundBkpLot)
                        )
                    ).length > 0;

                const hasLotItemsChanges = !!lotItem.items.find((newValue) => {
                    const initialValue = foundBkpLot?.items.find(
                        (oldValues) => oldValues.item === newValue.item
                    );

                    return (
                        Object.keys(
                            diff(
                                transformLotItemToCompareValues(newValue),
                                transformLotItemToCompareValues(initialValue)
                            )
                        ).length > 0
                    );
                })?.item;

                return hasLotsChanges || hasLotItemsChanges;
            })?.item,
        [form.values, processLotsBkpBeforeSave?.lots]
    );

    const setFieldValue = (
        lot: DeepPartial<AuctionNoticeLotWithItems>,
        field: string,
        value: any
    ) => {
        const foundFormLotIndex = form.values.lots.findIndex((formLot) =>
            lot.id ? formLot.id === lot.id : formLot.item === lot.item
        );
    
        if (foundFormLotIndex !== -1) {
            form.setFieldValue(`lots[${foundFormLotIndex}].${field}`, value);
        }
    };
    

    const setDebouncedFieldValue = debounce(
        (lot: DeepPartial<AuctionNoticeLotWithItems>, field: string, value: any) =>
            setFieldValue(lot, field, value),
        300
    );

    return (
        <ProcessLotsFormContext.Provider
            value={{
                processLotsForm: form,
                processLotsAndItems,
                setProcessLotsAndItems,
                formHasChanges: hasChanges,
                processLotsBkpBeforeSave:
                    processLotsBkpBeforeSaveRef?.current?.processLotsBkpBeforeSave,
                setProcessLotsBkpBeforeSave:
                    processLotsBkpBeforeSaveRef?.current?.setProcessLotsBkpBeforeSave,
                fetchingLots,
                processLotsResume,
                setFieldValue,
                setDebouncedFieldValue,
                updateFormsOnAuctionTypeChange,
            }}
        >
            {children}
        </ProcessLotsFormContext.Provider>
    );
};
