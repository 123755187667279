import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
    Avatar,
    Card,
    Typography,
} from '@material-ui/core';
import { Button } from 'common/components';
import ProcessTypeIcon from 'common/components/ProcessTypeIcon';
import { AuctionTypeEnum } from 'common/enums/auction-type.enum';
import { getMd5Hash, formatDate, apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { auctionTypeToLabel } from 'modules/process/components/auction-type-label';
import moment from 'moment';
import {
    MdAccessTime,
    MdOutlineCreate,
    MdOutlineHourglassBottom,
    MdPlayCircleFilled,
} from 'react-icons/md';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { getDisputeUrl } from 'common/utils/getters/get-url-redirect-dispute.utils';
import { usePlatformContext } from 'routes/platform.context';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { useCallback } from 'react';

const ProcessIcon = ({ auctionType }) => {
    const theme = useTheme();

    return (
        <Grid container direction='column' alignItems='center'>
            <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
                <ProcessTypeIcon
                    auctionType={auctionType}
                    color={theme.palette.primary.contrastText}
                />
            </Avatar>
            <Typography variant='body2'>{auctionTypeToLabel(auctionType).short}</Typography>
        </Grid>
    );
};

const ProcessActionIcon = ({ icon, label, onClick }) => (
    <Button disabled={!onClick} {...{ onClick }} variant='text' startIcon={icon} fullWidth>
        {label}
    </Button>
);

const ProcessAction = ({ process }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { platform } = usePlatformContext();
    const backofficeRedirectUrlPath = getBackofficeRedirectUrl();

    const auctionHash = getMd5Hash(String(process.id));
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const handleDisputeClick = useCallback(async () => {
        const { data } = await auctionNoticeRequests.getService(process.id);
        window.open(getDisputeUrl(process.id, authUserTokenData, data, platform), '_blank');
    }, []);

    const actions = {
        dispute: (
            <ProcessActionIcon
                icon={<MdPlayCircleFilled color={theme.palette.secondary.main} />}
                label='Disputa'
                onClick={handleDisputeClick}
            />
        ),
        await: (
            <ProcessActionIcon
                icon={<MdAccessTime color={theme.palette.warning.main} />}
                label='Aguarde'
                onClick={undefined}
            />
        ),
        draft: (
            <ProcessActionIcon
                icon={<MdOutlineCreate color={theme.palette.primary.main} />}
                label={t('term.draft')}
                onClick={() =>
                    apiRedirectTo(backofficeRedirectUrlPath, `/proposta/?idE=${auctionHash}`)
                }
            />
        ),
        inProgress: (
            <ProcessActionIcon
                icon={<MdOutlineHourglassBottom color={theme.palette.grey[600]} />}
                label='Andamento'
                onClick={() =>
                    apiRedirectTo(
                        backofficeRedirectUrlPath,
                        `/pesquisa/?idE=${auctionHash}&proposal`
                    )
                }
            />
        ),
    };

    if (process.auctionType !== AuctionTypeEnum.accreditation) {
        if (process.proposalSending > 0) {
            if (
                process.startDateTimeDispute &&
                new Date(process.startDateTimeDispute) <= new Date()
            ) {
                return actions.dispute;
            }
            return actions.await;
        }

        return actions.draft;
    }
    return actions.inProgress;
};

const ProcessSkeleton = () => (
    <TableRow>
        <TableCell>
            <Grid container direction='column' alignItems='center'>
                <Skeleton width={45} height={45} variant='circle' />
                <Skeleton width={40} variant='text' />
            </Grid>
        </TableCell>
        <TableCell>
            <Skeleton width={40} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={220} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={80} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={130} variant='text' />
        </TableCell>
    </TableRow>
);

const ImportantProcessView = ({ process, loading }) => {
    const { t } = useTranslation();

    const calculateTime = (process) => {
        if (process.auctionType === AuctionTypeEnum.accreditation) {
            return `Até ${formatDate(process.auctionEndDate, 'DD/MM/YY')} (${moment
                .utc(process.auctionEndDate, 'YYYY-MM-DDTHH:mm')
                .fromNow()})`;
        }

        return `${formatDate(process.startDateTimeDispute, 'DD/MM HH:mm')} (${moment
            .utc(process.startDateTimeDispute, 'YYYY-MM-DDTHH:mm')
            .fromNow()})`;
    };

    return (
        <>
            <Card style={{ minHeight: 100 }}>
                <Typography variant='h6'>
                    {t('provider-dashboard.components.important-proccess.title')}
                </Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={130} align='center'>
                                {t('term.modality')}
                            </TableCell>
                            <TableCell width={120}>
                                {t(
                                    'provider-dashboard.components.important-proccess.proccess-number'
                                )}
                            </TableCell>
                            <TableCell>{t('term.object')}</TableCell>
                            <TableCell width={130}>{t('term.time')}</TableCell>
                            <TableCell width={140} align='center'>
                                {t('term.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <>
                                <ProcessSkeleton />
                                <ProcessSkeleton />
                            </>
                        )}
                        {!loading &&
                            process &&
                            process.map((row) => (
                                <TableRow>
                                    <TableCell>
                                        <ProcessIcon auctionType={row.auctionType} />
                                    </TableCell>
                                    <TableCell>{row.auctionNumber}</TableCell>
                                    <TableCell>{row.simpleDescription}</TableCell>
                                    <TableCell>{calculateTime(row)}</TableCell>
                                    <TableCell padding='checkbox'>
                                        <ProcessAction process={row} />
                                    </TableCell>
                                </TableRow>
                            ))}

                        {!loading && process && !process.length && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography gutterBottom align='center'>
                                        {t('info.none-data')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Card>
        </>
    );
};

export default ImportantProcessView;
