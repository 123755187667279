import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { providerDashboardRequests } from 'modules/provider-dashboard/services/provider-dashboard.requests';
import { useQuery } from 'common/hooks/use-query.hook';
import { usePlatformContext } from 'routes/platform.context';
import AccountJourneyView from './account-journey-view';

const AccountJourney = () => {
    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const { platform } = usePlatformContext();

    const { data: accountJourneyStatus } = useQuery(providerDashboardRequests.getAccountJourney);

    return (
        <AccountJourneyView
            {...{
                accountJourneyStatus,
                name: authUser?.name,
                imgSrc: platform?.generalSettings?.images?.providerUrlImage,
                imgHref: platform?.generalSettings?.images?.providerUrlLink,
            }}
        />
    );
};

export default AccountJourney;
