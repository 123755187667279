import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    noneFilterItem: {
        color: '#888888',
        paddingBottom: '3px',
        paddingLeft: '15px',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        width: '260px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    filterItem: {
        color: '#888888',
        paddingBottom: '3px',
        paddingLeft: '15px',
        cursor: 'pointer',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        width: '260px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            color: '#1d1ce5',
        },
    },
    filterItemColor: {
        color: '#888888',
    },
    filterItemColorSelected: {
        color: '#1d1ce5',
    },
    skeleton: {
        borderRadius: '2px',
        transform: 'scale(1)',
        marginLeft: '15px',
        marginBottom: '3px',
    },
}));

export { useStyles };
