import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    Chip,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip,
    IconButton,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18n from 'common/i18n';
import {
    CompleteSupplyCategoriesProviders,
    CompleteSupplyCategoriesProvidersSettings,
    StatusApprovedSupplyCategories,
} from 'clients/manager/interfaces/supply-categories-providers.interface';
import { supplyCategoriesProvidersRequests } from 'clients/manager/supply-categories-providers.requests';
import { SupplyCategory } from 'clients/manager/interfaces/supply-categories.interface';
import { addNotificationSuccess } from 'common/utils';
import { Button } from 'common/components';
import { DeleteIcon } from 'common/icons';
import { useParams } from 'react-router';
import { TypeAccess } from 'common/interfaces/user.interface';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { Settings } from '@material-ui/icons';
import { usePlatformContext } from 'routes/platform.context';
import { SelectSegmentCategories } from 'clients/manager/interfaces/segment-categories-providers.interface';
import ModalProviderAddSegment from '../modal-provider-add-segment';
import { ConfirmDialog } from '../confirm-dialog';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';
import ModalSettingsSegments from '../modal-provider-segments-setting';
import ModalProviderAddSegmentWithFields from '../modal-provider-add-segment-with-fields';

interface SegmentsSelector2Props {
    disabled?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
    chipPending: {
        backgroundColor: theme.palette.grey[400],
        padding: theme.spacing(1.6),
    },
    chipApproved: {
        backgroundColor: theme.palette.success.main,
        padding: theme.spacing(1.6),
    },
    chipReproved: {
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(1.6),
    },
    chipExpired: {
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(1.6),
        color: theme.palette.common.white,
    },
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',
    },
}));

const rowsPerPageOptions = [10, 20];

const getStatusLabel = (priority: StatusApprovedSupplyCategories) => {
    const labels: { [key in StatusApprovedSupplyCategories]: string } = {
        [StatusApprovedSupplyCategories.approved]: i18n.t('term.approved'),
        [StatusApprovedSupplyCategories.pending]: i18n.t('term.pending'),
        [StatusApprovedSupplyCategories.reproved]: i18n.t('term.reproved'),
        [StatusApprovedSupplyCategories.expired]: i18n.t('term.expired'),
    };

    return labels[priority] || i18n.t('term.approved');
};

const getStatusClassName = (priority: StatusApprovedSupplyCategories) => {
    const labels: { [key in StatusApprovedSupplyCategories]: string } = {
        [StatusApprovedSupplyCategories.approved]: 'chipApproved',
        [StatusApprovedSupplyCategories.pending]: 'chipPending',
        [StatusApprovedSupplyCategories.reproved]: 'chipReproved',
        [StatusApprovedSupplyCategories.expired]: 'chipExpired',
    };

    return labels[priority] || labels[StatusApprovedSupplyCategories.approved];
};

const SegmentsSelector2: FC<SegmentsSelector2Props> = ({ disabled = false }) => {
    const [supplyCategoriesProvider, setSupplyCategoriesProvider] = useState<
        CompleteSupplyCategoriesProviders[]
    >([]);
    const [page, setPage] = useState(0);
    const { platform } = usePlatformContext();
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [visibleModalUpdateSegment, setVisibleModalUpdateSegment] = useState(false);
    const [openActionsModal, setOpenActionsModal] = useState<boolean>(false);
    const [supplyCategoriesSetting, setSupplyCategoriesSetting] =
        useState<CompleteSupplyCategoriesProvidersSettings>({
            id: 0,
            providerId: 0,
            statusApproved: StatusApprovedSupplyCategories.pending,
            supplyCategoryId: 0,
        });
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const classes = useStyles();
    const { t } = useTranslation();
    const { idProvider } = useParams<{ defaultTab: string; idProvider: string }>();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const supplyCategories =
            await supplyCategoriesProvidersRequests.listSupplyCategoriesProviders(
                Number(idProvider)
            );
        const { data } = supplyCategories;
        setSupplyCategoriesProvider(data);
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const deleteSupplyCategory = async (supplyCategoryId: number, providerId: number) => {
        const result = await supplyCategoriesProvidersRequests.deleteSupplyCategoriesProviders(
            supplyCategoryId,
            providerId
        );

        ReactDOM.unstable_batchedUpdates(() => {
            setSupplyCategoriesProvider((prevState) => [
                ...prevState.filter((category) => category.supplyCategoryId !== supplyCategoryId),
            ]);
            if (authUserTokenData?.typeAccess !== TypeAccess.management) {
                setForceHasChanges(true);
            }
            addNotificationSuccess();
        });

        return result;
    };

    const handleClickDelete = (supplyCategoryId: number, providerId: number) => {
        deleteSupplyCategory(supplyCategoryId, providerId);
    };

    const handleClickNew = () => setVisibleModalUpdateSegment(true);
    const handleClickActions = (supplyCategory: CompleteSupplyCategoriesProviders) => {
        setOpenActionsModal(true);
        setSupplyCategoriesSetting({
            id: supplyCategory.id,
            providerId: supplyCategory.providerId,
            statusApproved: supplyCategory.statusApproved,
            supplyCategoryId: supplyCategory.supplyCategoryId,
            name: supplyCategory.masterSupplyCategory.categoryName,
            externalId: supplyCategory.masterSupplyCategory.externalId,
            dueDate: supplyCategory.dueDate,
            dateTimeUpdate: supplyCategory.dateTimeUpdate,
            suppliesAs: supplyCategory.suppliesAs,
            supplyCategoryType: supplyCategory.supplyCategory.supplyCategoryType,
            manufacturerIdentifierNumber: supplyCategory.manufacturerIdentifierNumber,
            technicalManagerBoardNumber: supplyCategory.technicalManagerBoardNumber,
            technicalManagerBoardType: supplyCategory.technicalManagerBoardType,
            technicalManagerDocNumber: supplyCategory.technicalManagerDocNumber,
            technicalManagerName: supplyCategory.technicalManagerName,
        });
    };

    const getSupplyCategoryLabel = (supplyCategory: SupplyCategory) => {
        if (supplyCategory.externalId) {
            return `${supplyCategory.externalId}. ${supplyCategory.categoryName}`;
        }

        return supplyCategory.categoryName;
    };

    const getTypeSupplyCategoryLabel = (
        supplyCategoryProvider: SelectSegmentCategories | undefined
    ): string => {
        if (!supplyCategoryProvider) return '';
        return t(`term.${supplyCategoryProvider}`);
    };

    return (
        <Box>
            {loading ? (
                <div className={classes.spinnerContainer}>
                    <CircularProgress color='secondary' size={24} />
                </div>
            ) : (
                <>
                    {visibleModalUpdateSegment &&
                        (!platform?.generalSettings.general.requiredTecnicalResponsibleToSegment ? (
                            <ModalProviderAddSegment
                                onClose={() => {
                                    setVisibleModalUpdateSegment(false);
                                    getData();
                                }}
                                idProvider={Number(idProvider)}
                                initialCheckedCategories={supplyCategoriesProvider.map(
                                    (category) => category.supplyCategory
                                )}
                            />
                        ) : (
                            <ModalProviderAddSegmentWithFields
                                onClose={() => {
                                    setVisibleModalUpdateSegment(false);
                                    getData();
                                }}
                                idProvider={Number(idProvider)}
                            />
                        ))}
                    {openActionsModal && (
                        <ModalSettingsSegments
                            onClose={() => {
                                setOpenActionsModal(false);
                                getData();
                            }}
                            supplyCategories={supplyCategoriesSetting}
                        />
                    )}
                    <Box mb={1} width={1} display='flex' justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => handleClickNew()}
                            disabled={disabled}
                        >
                            {t('term.new')}
                        </Button>
                    </Box>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('term.group')}</TableCell>
                                    <TableCell>{t('term.segment')}</TableCell>
                                    <TableCell>{t('term.segment-type')}</TableCell>
                                    <TableCell>{t('term.due-date')}</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                    <TableCell width='80px' />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {supplyCategoriesProvider
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((category) => {
                                        const { masterSupplyCategory, supplyCategory, suppliesAs } =
                                            category;

                                        return (
                                            <TableRow key={category.id}>
                                                <TableCell component='th' scope='row'>
                                                    {getSupplyCategoryLabel(masterSupplyCategory)}
                                                </TableCell>
                                                <TableCell>
                                                    {getSupplyCategoryLabel(supplyCategory)}
                                                </TableCell>
                                                <TableCell>
                                                    {getTypeSupplyCategoryLabel(suppliesAs)}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        style={{
                                                            fontSize: 12,
                                                        }}
                                                        variant='body2'
                                                    >
                                                        {category?.dueDate
                                                            ? moment(category?.dueDate).format(
                                                                  'DD/MM/YYYY'
                                                              )
                                                            : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Box
                                                        display='flex'
                                                        flexDirection='column'
                                                        alignItems='center'
                                                    >
                                                        <Tooltip
                                                            disableTouchListener
                                                            disableFocusListener
                                                            disableHoverListener={
                                                                !category?.dueDate
                                                            }
                                                            title={moment(category?.dueDate)
                                                                .utcOffset('-03:00')
                                                                .format('DD/MM/YYYY')}
                                                        >
                                                            <Chip
                                                                classes={{
                                                                    root: classes[
                                                                        getStatusClassName(
                                                                            category.statusApproved
                                                                        )
                                                                    ],
                                                                }}
                                                                size='small'
                                                                color='primary'
                                                                label={getStatusLabel(
                                                                    category.statusApproved
                                                                )}
                                                                style={{
                                                                    marginBottom: 8,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                        <Typography
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                            variant='body2'
                                                        >
                                                            {moment(category.dateTimeInsert).format(
                                                                'DD/MM/YYYY HH:mm'
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    {authUserTokenData?.typeAccess ===
                                                        TypeAccess.management && (
                                                        <IconButton
                                                            onClick={() =>
                                                                handleClickActions(category)
                                                            }
                                                            disabled={disabled}
                                                        >
                                                            <Settings />
                                                        </IconButton>
                                                    )}
                                                    <IconButton disabled={disabled}>
                                                        {category.statusApproved ===
                                                        StatusApprovedSupplyCategories.approved ? (
                                                            <ConfirmDialog
                                                                title={t('Excluir segmento?')}
                                                                message={t(
                                                                    'info.finish-process-message'
                                                                )}
                                                                onConfirm={() =>
                                                                    handleClickDelete(
                                                                        category.supplyCategoryId,
                                                                        Number(idProvider)
                                                                    )
                                                                }
                                                                disabled={disabled}
                                                            >
                                                                <DeleteIcon fontSize='small' />
                                                            </ConfirmDialog>
                                                        ) : (
                                                            <DeleteIcon
                                                                onClick={() =>
                                                                    handleClickDelete(
                                                                        category.supplyCategoryId,
                                                                        Number(idProvider)
                                                                    )
                                                                }
                                                                fontSize='small'
                                                            />
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box width={1} justifyContent='flex-end' display='flex'>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component='div'
                            count={supplyCategoriesProvider.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t('text.lines-per-page')}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default SegmentsSelector2;
