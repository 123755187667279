import { getAuthUserToken } from 'modules/auth/session.utils';
import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import { useEffect, useState } from 'react';
import { IdentityProvider, useIdentityProviders } from 'common/amplify';
import ProfileConnectAccounts from './profile-connect-accounts';
import ProfileUpdatePassword from '../profile-update-password';

interface IProfileConnectAccountsView {
    enableConnectProviders: boolean;
}

export interface IdentityProvidersT extends IdentityProvider {
    isConnected?: boolean;
}

export const ProfileConnectAccountsView = ({
    enableConnectProviders,
}: IProfileConnectAccountsView) => {
    const user = getAuthUserToken();
    const { checkConnections, disconnectUser } = useUserConnectedProviders();
    const { identityProviders } = useIdentityProviders();

    const enabledIdentityProviders = identityProviders.filter((provider) => provider.enabled);

    const [connectAccounts, setConnectAccounts] =
        useState<IdentityProvidersT[]>(enabledIdentityProviders);
    const [showModalDialogChangePassword, setShowModalDialogChangePassword] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const disconnectUserProvider = async (userId: number, provider: string) => {
        setIsLoading(true);

        const disconnectProvider = connectAccounts.findIndex(
            (connectProvider) => connectProvider.name === provider
        );

        setConnectAccounts((value) => {
            value[disconnectProvider].isConnected = false;
            return [...value];
        });

        try {
            await disconnectUser(userId, provider);
        } finally {
            setIsLoading(false);
        }
    };

    const getUserConnectionProviders = async () => {
        if (!user) return;

        setIsLoading(true);

        try {
            const connectedProviders = await checkConnections(user.userId);

            const userConnectedProviders = enabledIdentityProviders.map((provider) => ({
                ...provider,
                isConnected: !!connectedProviders.find(
                    (connectedProvider) =>
                        connectedProvider.provider === provider.name.toLowerCase()
                )?.id,
            }));

            setConnectAccounts(userConnectedProviders);
        } finally {
            setIsLoading(false);
        }
    };

    const onOpenShowModalDialogChangePassword = () => setShowModalDialogChangePassword(true);

    const onCloseShowModalDialogChangePassword = () => setShowModalDialogChangePassword(false);

    useEffect(() => {
        getUserConnectionProviders();
    }, []);

    return (
        <>
            <ProfileUpdatePassword
                isOpenDialog={showModalDialogChangePassword}
                onCloseModalDialog={onCloseShowModalDialogChangePassword}
            />
            <ProfileConnectAccounts
                identityProviders={connectAccounts}
                disconnectUser={disconnectUserProvider}
                userId={user?.userId}
                isLoading={isLoading}
                enableConnectProviders={enableConnectProviders}
                onOpenShowModalDialogChangePassword={onOpenShowModalDialogChangePassword}
            />
        </>
    );
};
