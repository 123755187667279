import * as Yup from 'yup';
import i18n from 'i18next';
import { AuctionNotice } from '../../../../clients/manager/interfaces/auction-notice.interface';

export const getSourceResourcesPartialValidationSchema = (values?: AuctionNotice) => {
    if (values?.itsAgreementOrTransfer === 1) {
        return {
            originOfFinancialResource: Yup.string()
                .nullable(true)
                .required(i18n.t('required-field', { ns: 'validation' })),
            agreementNumber: Yup.string()
                .nullable(true)
                .required(i18n.t('required-field', { ns: 'validation' })),
        };
    }

    return {};
};

export const getSourceResourcesFormLabels = () => ({
    originOfFinancialResource: i18n.t('term.resource-origin'),
    agreementNumber: i18n.t('process.components.agreement-no'),
});
