import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProviderSignupExpirationWarning from 'common/components/provider-signup-expiration-warning';
import ProviderUpdateRequestWarning from 'common/components/provider-update-request-warning';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { usePageContext } from 'common/components/base/pages/context';
import CompanyTabs from './components/company-tabs';
import { EditCompanyProps } from './props';

const EditCompany: FC<EditCompanyProps> = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        setTitle(t('company.edit.provider-data'));
    }, []);

    return (
        <PageWrapper>
            <ProviderSignupExpirationWarning location='edit-provider' />
            <ProviderUpdateRequestWarning setHasUpdate={setReload} />
            <CompanyTabs reload={reload} setHasUpdate={setReload} />
        </PageWrapper>
    );
};

export default EditCompany;
