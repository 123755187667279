import {
    Box,
    debounce,
    Grid,
    MenuItem,
    Paper,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { TextField } from 'common/components';
import React, { FC } from 'react';
import { usePlatformContext } from 'routes/platform.context';
import { useTranslation } from 'react-i18next';
import {
    RuleRestrictSegments,
    TypeOfBenefitValue,
    TypeOfItemsValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import { formatCurrency } from 'common/utils';
import { CurrencyInput } from 'common/components/currency';
import {
    disableField,
    getDecimalPlaces,
    processRules,
    processUtils,
} from '../../../../process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';
import QuotaSelectReference from '../quota-select-reference';
import QuotaTypeOfBenefit from '../quota-type-of-benefit';
import { ProcessLotAdvancedInfoProps } from './props';
import RuleRestrictSegmentField from '../rule-restrict-segments-field';
import SelectSupplyCategories from '../select-supply-categories';

const ProcessLotAdvancedInfo: FC<ProcessLotAdvancedInfoProps> = ({
    lot,
    disabled,
    onClickUpdateLotQuota,
}) => {
    const { t } = useTranslation();
    const { processForm, auctionTypeRules } = useProcessFormContext();
    const { processLotsForm, setFieldValue, setDebouncedFieldValue } = useProcessLotsFormContext();
    const { platform } = usePlatformContext();
    const foundLotById = lot.id
        ? processLotsForm.values.lots.find((lotForm) => lotForm.id === lot.id)
        : null;
    const foundLotByItem = processLotsForm.values.lots.find((lotForm) => lotForm.item === lot.item);
    const values = foundLotById || foundLotByItem || ({ items: [] } as any);
    const decimalPlaces = processForm?.values.decimalPlaces ?? 2;
    const bidAmountDifference = formatCurrency(
        values.bidAmountDifference ||
            auctionTypeRules?.generalSettings?.cadastro?.intervaloEntreLances ||
            0,
        getDecimalPlaces(decimalPlaces),
        true
    );

    const getSupplyCategories = () => {
        if (!values.listRestrictSegments) {
            return [];
        }

        return values.listRestrictSegments?.split(',').map(Number);
    };

    const handleChangeListRestrictSegments = debounce((supplyCategories: number[]) => {
        setFieldValue(lot, 'listRestrictSegments', supplyCategories.join(','));
    }, 0);

    const getMenuTypeOfItems = () => {
        if (processUtils.isPublicSale(processForm?.values)) {
            return [
                <MenuItem value={TypeOfItemsValue.properties}>{t('term.properties')}</MenuItem>,
                <MenuItem value={TypeOfItemsValue.furniture}>{t('term.furniture')}</MenuItem>,
            ];
        }
        return [
            <MenuItem value={TypeOfItemsValue.product}>{t('term.products')}</MenuItem>,
            <MenuItem value={TypeOfItemsValue.service}>{t('term.services')}</MenuItem>,
        ];
    };

    const visibleSupplyCategories =
        (processRules.visibleSegmentsByLot(platform, processForm?.values) &&
            (values.ruleRestrictBySegments === RuleRestrictSegments.and ||
                values.ruleRestrictBySegments === RuleRestrictSegments.or) &&
            !processUtils.isUnenforceabilityProcess(processForm?.values)) ||
        processUtils.isMarketplace(processForm?.values);

    return (
        <Box onClick={(ev) => ev.stopPropagation()} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <Box p={2}>
                    <Grid container spacing={2}>
                        {processRules.visibleLotTypeOfItems(processForm?.values) && (
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    id='select'
                                    color='primary'
                                    disabled={disabled}
                                    label={t('term.type-itens')}
                                    defaultValue={values.typeOfItems}
                                    value={values.typeOfItems}
                                    onChange={(event) =>
                                        setFieldValue(
                                            values,
                                            'typeOfItems',
                                            event.target.value as TypeOfItemsValue
                                        )
                                    }
                                    variant='outlined'
                                    select
                                >
                                    {getMenuTypeOfItems()}
                                </TextField>
                            </Grid>
                        )}
                        {(values.typeOfItems === TypeOfItemsValue.properties ||
                            values.typeOfItems === TypeOfItemsValue.furniture) && (
                            <Grid item xs={12} md={2}>
                                <TextField
                                    fullWidth
                                    color='primary'
                                    disabled={disabled}
                                    defaultValue={values.propertyOrFurnitureCode}
                                    label={
                                        values.typeOfItems === TypeOfItemsValue.properties
                                            ? t('term.estate-properties-code')
                                            : t('term.heritage-code')
                                    }
                                    onChange={(event) =>
                                        setFieldValue(
                                            values,
                                            'propertyOrFurnitureCode',
                                            event.target.value
                                        )
                                    }
                                    variant='outlined'
                                    multiline
                                >
                                    {getMenuTypeOfItems()}
                                </TextField>
                            </Grid>
                        )}
                        {processRules.visibleLotTypeOfBenefit(
                            auctionTypeRules,
                            processForm?.values
                        ) && (
                            <Grid item xs={12} md={4}>
                                <QuotaTypeOfBenefit
                                    lot={lot}
                                    disabled={disabled}
                                    values={values}
                                    onClickUpdateLotQuota={onClickUpdateLotQuota}
                                />
                            </Grid>
                        )}
                        {processRules.visibleLotReservedQuotaSelection(processForm?.values) &&
                            lot.typeOfBenefit === TypeOfBenefitValue.reservedQuota && (
                                <Grid item xs={12} md={2}>
                                    <QuotaSelectReference
                                        lotId={lot.id}
                                        values={values}
                                        disabled={disabled}
                                    />
                                </Grid>
                            )}
                        {processRules.visibleLotBidAmountDifference(processForm?.values) && (
                            <Grid item xs={12} md={3}>
                                <CurrencyInput
                                    fullWidth
                                    allowNegative={false}
                                    prefix='R$ '
                                    disabled={
                                        disabled ||
                                        disableField('bidAmountDifference', processForm?.values)
                                    }
                                    decimalSeparator=','
                                    precision={getDecimalPlaces(decimalPlaces)}
                                    thousandSeparator='.'
                                    label={t('process.components.value-between-bids')}
                                    variant='outlined'
                                    value={bidAmountDifference}
                                    defaultValue={bidAmountDifference}
                                    color='primary'
                                    onChange={(_, value: number) =>
                                        setDebouncedFieldValue(values, 'bidAmountDifference', value)
                                    }
                                    component={TextField}
                                />
                            </Grid>
                        )}
                        {processRules.visibleLotItemsKits(
                            auctionTypeRules,
                            processForm?.values
                        ) && (
                            <Grid item xs={12} md={2}>
                                <TextField
                                    fullWidth
                                    color='primary'
                                    disabled={disabled}
                                    label={t('term.lot-quantity')}
                                    defaultValue={values.itemsKits}
                                    type='number'
                                    onChange={(event) =>
                                        setDebouncedFieldValue(
                                            values,
                                            'itemsKits',
                                            Number(event.target.value)
                                        )
                                    }
                                    variant='outlined'
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                color='primary'
                                disabled={disabled}
                                defaultValue={values.lotDescription}
                                value={values.lotDescription}
                                label={t('term.lot-description')}
                                onChange={(event) =>
                                    setFieldValue(values, 'lotDescription', event.target.value)
                                }
                                variant='outlined'
                                multiline
                                minRows={2}
                                maxRows={8}
                            />
                        </Grid>
                        {processRules.visibleSegmentsByLot(platform) && (
                            <Grid item xs={12} lg={4}>
                                <RuleRestrictSegmentField values={values} disabled={disabled} />
                            </Grid>
                        )}
                        <Grid item xs={12} lg={12}>
                            <Box display='flex' alignItems='center' height={1}>
                                {processRules.visibleLotMarkIsRequired(processForm?.values) && (
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={t('process.components.require-brand-manufacturer')}
                                        disabled={disabled}
                                        checked={Boolean(values.markIsRequired)}
                                        onChange={(event: React.ChangeEvent<any>) =>
                                            setFieldValue(
                                                values,
                                                'markIsRequired',
                                                Number(event.target.checked)
                                            )
                                        }
                                    />
                                )}
                                {processRules.visibleLotForceSameDescription(
                                    processForm?.values
                                ) && (
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={t('info.force-same-description')}
                                        disabled={disabled}
                                        checked={Boolean(values.forceSameDescription)}
                                        onChange={(event: React.ChangeEvent<any>) =>
                                            setFieldValue(
                                                values,
                                                'forceSameDescription',
                                                Number(event.target.checked)
                                            )
                                        }
                                    />
                                )}
                                {processRules.visibleLotForceSameDescription(
                                    processForm?.values
                                ) && (
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={t('info.require-documents')}
                                        disabled={disabled}
                                        checked={Boolean(values.requireDocuments)}
                                        onChange={(event: React.ChangeEvent<any>) =>
                                            setFieldValue(
                                                values,
                                                'requireDocuments',
                                                Number(event.target.checked)
                                            )
                                        }
                                    />
                                )}
                                {processRules.visibleLotConfidentialValue(
                                    auctionTypeRules,
                                    processForm?.values
                                ) && (
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={t('process.components.confidential-value')}
                                        disabled={disabled}
                                        checked={Boolean(values.showReferenceValue)}
                                        onChange={(event: React.ChangeEvent<any>) =>
                                            setFieldValue(
                                                values,
                                                'showReferenceValue',
                                                Number(event.target.checked)
                                            )
                                        }
                                    />
                                )}
                                {processRules.visibleAllowMultipleWinners(
                                    processForm?.values,
                                    auctionTypeRules,
                                    lot
                                ) && (
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={t('process.components.allow-multiple-winners')}
                                        disabled={disabled}
                                        checked={Boolean(values.allowMultipleWinner)}
                                        onChange={(event: React.ChangeEvent<any>) =>
                                            setFieldValue(
                                                values,
                                                'allowMultipleWinner',
                                                Number(event.target.checked)
                                            )
                                        }
                                    />
                                )}
                            </Box>
                        </Grid>
                        {visibleSupplyCategories && (
                            <Grid item xs={12}>
                                <SelectSupplyCategories
                                    value={getSupplyCategories()}
                                    onChange={(supplyCategories) =>
                                        handleChangeListRestrictSegments(supplyCategories)
                                    }
                                    disabled={disabled}
                                    label={t('term.supply-segments')}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
};

export default ProcessLotAdvancedInfo;
