import { apiRedirectTo, getBackofficeRedirectUrl, getMd5Hash } from 'common/utils';
import ProcessProposalsView from './process-proposals-view';

const ProcessProposals = ({ invites }) => {
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const handleClick = (processId: number) => {
        const hash = getMd5Hash(String(processId));
        apiRedirectTo(backofficeRedirectUrl, `proposta/?idE=${hash}`);
    };

    return <ProcessProposalsView data={invites} onClick={handleClick} />;
};

export default ProcessProposals;
