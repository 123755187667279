import { JwtContent } from 'store/user/user.reducer';
import { getAuthToken } from 'modules/auth/session.utils';
import { Platform } from 'clients/manager/platforms.requests';
import { getDisputeHash } from './get-dispute-hash.utils';

export const getDisputeUrl = (
    processId: number | string,
    tokenContent: JwtContent | undefined,
    service: string,
    platform: Platform | undefined,
    token?: string
) => {
    if (!platform?.apps?.dispute) return '';
    const authCookies = getAuthToken();
    const hash = getDisputeHash(processId, tokenContent);
    const sufix = `/redirect?token=${
        token ?? authCookies?.token
    }&auction=${processId}&hash=${hash}&service=${service}`;

    return `${platform.apps?.dispute}${sufix}`;
};
