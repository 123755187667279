import { authRequests } from 'clients/manager/auth.requests';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import { providerRequests } from 'clients/manager/provider.requests';
import { TypeAccess } from 'common/interfaces/user.interface';
import { setAuthToken } from 'modules/auth/session.utils';
import {
    ApiCnpjWsProviderInfo,
    ISignUpContextProviderValue,
    LegalRepresentative,
} from 'modules/sign-up/pages/sign-up-stepper-provider/props';
import { createContext, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppState } from 'store';
import { CONSTANTS } from 'common/constants';
import { FinancialProviderData } from 'clients/manager/interfaces/financial-provider-data.interface';
import { FinancialProviderDataRequests } from 'clients/manager/financial-provider-data.requests';

export const SignUpProviderContext = createContext<ISignUpContextProviderValue>({
    activeStep: 0,
    providerId: '',
});

export const SignUpProviderContextProvider = memo(({ children }) => {
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const [activeStep, setActiveStep] = useState(0);
    const [provider, setProvider] = useState<Provider>();
    const [financialProvider, setFianancialProvider] = useState<FinancialProviderData>();
    const [providerId, setProviderId] = useState('');
    const [segmentsCategories, setSegmentsCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [legalRepresentative, setLegalRepresentative] = useState<LegalRepresentative>();
    const [listLegalRepresentative, setListLegalRepresentative] = useState([]);
    const [userProviderExist, setUserProviderExist] = useState<boolean>(false);
    const [docNumberTextError, setDocNumberTextError] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [hasFoundedCnpjWsInfo, setHasFoundedCnpjWsInfo] = useState<boolean>(false);
    const [cnpjWsProviderInfo, setCnpjWsProviderInfo] = useState<ApiCnpjWsProviderInfo>({
        companyName: '',
        tradingName: '',
        complementPlace: '',
        stateCompanyRegistrationNumber: '',
        taxRegime: '',
        numberPlace: '',
        zipCode: '',
        street: '',
        district: '',
        state: '',
        city: '',
        country: '',
        ibgecode: '',
    });

    const handleNext = (step?: number) => {
        if (typeof step === 'number' && step !== undefined) {
            return setActiveStep(step);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setErrorMessage('');
    };

    const { t } = useTranslation();

    const updateFinancialData = async () => {
        try {
            setLoading(true);
            if (authUserTokenData?.typeAccess === TypeAccess.management) {
                await FinancialProviderDataRequests.doUpdateFinancialProviderDataByAdmin(
                    { ...financialProvider, providerId: Number(providerId) } || {}
                );
            } else {
                await FinancialProviderDataRequests.updateFinancialProviderData(
                    { ...financialProvider, providerId: Number(providerId) } || {}
                );
            }

            handleNext();
        } catch (e: any) {
            setErrorMessage(
                `${t('sign-up.pages.stepper-provider.context.info-response-error-registration')}, ${
                    e.response.data.message
                }`
            );
        } finally {
            setLoading(false);
        }
    };

    const postCompanyAndAdress = async () => {
        try {
            setLoading(true);
            if (provider) {
                const isBrazillian = provider.country === CONSTANTS.ibgeCodes.BRAZIL;
                const result = await providerRequests.createProvider({
                    ...provider,
                    stateCompanyRegistrationNumber: isBrazillian
                        ? provider?.stateCompanyRegistrationNumber
                        : 'Isento',
                });
                const { id } = result;
                setProviderId(id);

                const { data } = await authRequests.doLoginTypeAccess({
                    type: TypeAccess.provider,
                    resourceId: `${id}`,
                });

                if (data.token) {
                    setAuthToken(data.token);
                    history.push(`/auth/?token=${data.token}&redirectUrl=/cadastro-fornecedor`);
                }
                handleNext();
            }
        } catch (e: any) {
            setErrorMessage(
                `${t('sign-up.pages.stepper-provider.context.info-response-error-registration')}, ${
                    e.response.data.message
                }`
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const rawProviderId = authUserTokenData?.providerId;
        if (rawProviderId !== undefined && rawProviderId !== null) {
            setProviderId(rawProviderId.toString());
        }

        // eslint-disable-next-line no-prototype-builtins
        if (provider?.hasOwnProperty('zipCode')) {
            if (activeStep === 1) {
                postCompanyAndAdress();
            }
        }
        if (activeStep === 3) {
            updateFinancialData();
        }
    }, [provider, financialProvider, authUserTokenData]);

    return (
        <SignUpProviderContext.Provider
            value={{
                provider,
                setProvider,
                setFianancialProvider,
                activeStep,
                setActiveStep,
                handleNext,
                handleBack,
                providerId,
                errorMessage,
                segmentsCategories,
                setSegmentsCategories,
                listLegalRepresentative,
                setListLegalRepresentative,
                setCnpjWsProviderInfo,
                setHasFoundedCnpjWsInfo,
                hasFoundedCnpjWsInfo,
                cnpjWsProviderInfo,
                legalRepresentative,
                setLegalRepresentative,
                userProviderExist,
                setUserProviderExist,
                docNumberTextError,
                setDocNumberTextError,
                loading,
            }}
        >
            {children}
        </SignUpProviderContext.Provider>
    );
});
