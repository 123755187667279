import React from 'react';
import { Box, Chip, Grid, Paper, Typography } from '@material-ui/core';
import {
    ListMarketplaceItemsResponse,
    MarketplaceCartItem,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { formatCurrency } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { OrderItemStatusEnum } from 'common/enums';
import { useStyles } from './marketplace-item-card-style';
import ActionButtons from './components/action-buttons';
import ProvidersTable from './components/providers-table';
import OrderItemStatusChip from './components/order-item-status-chip';

interface MarketplaceItemCardViewProps {
    item: ListMarketplaceItemsResponse;
    orderItemStatus?: OrderItemStatusEnum;
    cartItem?: MarketplaceCartItem;
    type: 'cart' | 'order' | 'judgment';
    quantity?: number;
    hasOnCart: boolean;
    isActionLoading?: boolean;
    onAddToCart: () => void;
    onRemoveFromCart: () => void;
    onItemQuantityChange: (value: number) => void;
    onDeclassify?: (providerId: number) => void;
    isDisqualifying?: boolean;
    isJudged?: boolean;
}

const MarketplaceItemCardView: React.FC<MarketplaceItemCardViewProps> = ({
    item,
    orderItemStatus,
    cartItem,
    type,
    quantity,
    hasOnCart,
    isActionLoading,
    onAddToCart,
    onRemoveFromCart,
    onItemQuantityChange,
    onDeclassify,
    isDisqualifying = false,
    isJudged = false,
}) => {
    const { t } = useTranslation();

    const styles = useStyles();

    return (
        <Paper variant='outlined' key={item.id} className={styles.cardContainer}>
            <Grid container spacing={2} direction='column' className={styles.cardContentContainer}>
                <Grid item>
                    <Box className={styles.processInfoContainer}>
                        <Typography variant='body2' className={styles.textProcessInfo}>
                            {item.auctionNumber} - {item.processName}
                        </Typography>
                        {orderItemStatus && <OrderItemStatusChip status={orderItemStatus} />}
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography
                                        className={styles.title}
                                        key={`${item.id}-typo`}
                                        variant='body1'
                                    >
                                        {item.itemName}
                                    </Typography>
                                    {item.categories.map((category) => (
                                        <Chip
                                            key={category.id}
                                            className={styles.categoriesChip}
                                            label={category?.categoryName?.trim()}
                                            size='small'
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={styles.titlePriceLabel} variant='body2'>
                                {isJudged
                                    ? t('term.marketplace-best-price')
                                    : t('term.marketplace-average-price')}
                            </Typography>
                            <Typography className={styles.titlePrice} variant='body1'>
                                {item.prices.mid ? formatCurrency(item.prices.mid) : '--'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} className={styles.listInfoContainer}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('term.marketplace-estimated-price')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {formatCurrency(item.prices.estimated)}
                                    </Typography>
                                </Grid>
                                {item?.amountOrdered ? (
                                    <Grid item className={styles.listInfoItem}>
                                        <Typography
                                            className={styles.infoItemLabel}
                                            variant='body2'
                                        >
                                            <Typography
                                                className={styles.infoItemLabel}
                                                variant='body2'
                                            >
                                                {t('term.marketplace-acquired-quantity')}
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            className={styles.infoItemValue}
                                            variant='body2'
                                        >
                                            {item.amountOrdered}
                                        </Typography>
                                    </Grid>
                                ) : null}
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('term.marketplace-planned-quantity')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {item.amount}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('term.marketplace-accredited-providers')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {item.providersCount}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ActionButtons
                                cartItem={cartItem}
                                type={type}
                                quantity={quantity}
                                hasOnCart={hasOnCart}
                                loading={isActionLoading}
                                onAddToCart={onAddToCart}
                                onRemoveFromCart={onRemoveFromCart}
                                onItemQuantityChange={onItemQuantityChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {type === 'judgment' && (
                <ProvidersTable
                    providers={item.providers}
                    loading={false}
                    estimatedValue={item.prices.estimated}
                    onDeclassify={onDeclassify}
                    isDisqualifying={isDisqualifying}
                />
            )}
        </Paper>
    );
};

export default MarketplaceItemCardView;
