import { AudienceType } from 'clients/manager/interfaces/notice-information.interfaces';
import { TFunction } from 'i18next';

const getLabelChip = (t: TFunction, audience: AudienceType | '') => {
    switch (audience) {
        case 'public': {
            return t('term.public');
        }
        case 'provider': {
            return t('term.providers');
        }
        case 'citizen': {
            return t('term.citizen');
        }
        case 'management': {
            return t('term.management');
        }
        case 'organization': {
            return t('term.organization');
        }
        default: {
            return '';
        }
    }
};

export { getLabelChip };
