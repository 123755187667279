import { TextField } from 'common/components';
import { forwardRef } from 'react';

const PhoneInput = (props, ref) => (
    <TextField
        {...props}
        inputRef={ref}
        fullWidth
        size='medium'
        label={props.placeholder}
        variant='outlined'
        name='phone'
    />
);

export default forwardRef(PhoneInput);
