import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from 'clients/interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { DocumentsProposal } from './interfaces/documents-proposal.interface';

const path = 'documents-proposal';

interface CreateDocumentsProposal {
    auctionId: number;
    documentTypesIds: number[];
}

const documentsProposalRequests = {
    listDocumentsProposal: async () =>
        doRequest<GenericResponse<DocumentsProposal>>(
            managerApiInstance.post(`/${path}/listDocumentsProposal`)
        ),
    createDocumentsProposal: async (data: CreateDocumentsProposal) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/createDocumentsProposal`, data)
        ),
};

export { documentsProposalRequests };
