import { FC, useEffect, useState } from 'react';
import {
    ListSupplyCategoriesFilters,
    SupplyCategory,
    SupplyCategoryCemig,
    SupplyCategoryTypeEnum,
} from 'clients/manager/interfaces/supply-categories.interface';
import { supplyCategoriesRequests } from 'clients/manager/supply-categories.requests';
import { supplyCategoriesProvidersRequests } from 'clients/manager/supply-categories-providers.requests';
import Yup, { addNotificationApiError, addNotificationSuccess, sanitizeCnpj } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SelectSegmentCategories } from 'clients/manager/interfaces/segment-categories-providers.interface';
import { providerRequests } from 'clients/manager/provider.requests';
import { ModalProviderAddSegmentWithFieldsProps } from './props';
import ModalProviderAddSegmentWithFieldsView from './ModalProviderAddSegmentWithFieldsView';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';

const ModalProviderAddSegmentWithFields: FC<ModalProviderAddSegmentWithFieldsProps> = ({
    onClose,
    idProvider = 0,
}) => {
    const [supplyCategories, setSupplyCategories] = useState<SupplyCategory[]>([]);
    const [checkedCategories, setCheckedCategories] = useState<SupplyCategory[]>([]);
    const [segmentCategory, setSegmentCategory] = useState('manufacturer');
    const [providerNotFounded, setProviderNotFounded] = useState<boolean>(false);
    const [newTecnicalResponsible, setNewTecnicalResponsible] = useState<boolean>(false);
    const [isRequiredIdenrification, setIsRequiredIdenrification] = useState<boolean | undefined>(
        false
    );
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const { t } = useTranslation();
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const [showCpfFields, setShowCpfFields] = useState<boolean>(false);

    const getValidationSchema = () => {
        if (
            checkedCategories.length > 0 &&
            checkedCategories[0].supplyCategoryType === SupplyCategoryTypeEnum.product
        )
            return Yup.object().shape({
                suppliesAs: Yup.string(),
                technicalManagerBoardNumber: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
                manufacturerIdentifierNumber: Yup.string().when('suppliesAs', {
                    is: SelectSegmentCategories.manufacturer,
                    then: Yup.string().notRequired(),
                    otherwise: Yup.string().required(t('required-field', { ns: 'validation' })),
                }),
                technicalManagerBoardType: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
                technicalManagerDocNumber: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
                technicalManagerName: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
            });
    };

    const showModalAddTecnicalResponsible = (open) => setNewTecnicalResponsible(open);

    const updateSupplyCategories = async (values) => {
        try {
            await supplyCategoriesProvidersRequests.createSupplyCategoriesProvidersCemig(values);
            addNotificationSuccess();
            if (authUserTokenData?.typeAccess !== TypeAccess.management) {
                setForceHasChanges(true);
            }
            onClose();
        } catch (err) {
            addNotificationApiError(err);
        }
    };

    const form = useFormik<SupplyCategoryCemig>({
        initialValues: {
            suppliesAs: SelectSegmentCategories.manufacturer,
            technicalManagerBoardNumber: '',
            technicalManagerBoardType: undefined,
            technicalManagerDocNumber: '',
            technicalManagerName: '',
            manufacturerIdentifierNumber: undefined,
        },
        enableReinitialize: true,
        validationSchema: getValidationSchema(),
        onSubmit: async (values) => {
            if (
                checkedCategories.length > 0 &&
                checkedCategories[0].supplyCategoryType !== SupplyCategoryTypeEnum.product
            ) {
                await supplyCategoriesProvidersRequests.createSupplyCategoriesProviders(
                    idProvider,
                    checkedCategories.map((category) => category.id)
                );

                addNotificationSuccess();
                if (authUserTokenData?.typeAccess !== TypeAccess.management) {
                    setForceHasChanges(true);
                }
                onClose();
            } else if (values.suppliesAs !== SelectSegmentCategories.manufacturer) {
                const provider = await providerRequests.getProviderByDocNumber(
                    sanitizeCnpj(values.manufacturerIdentifierNumber) || ''
                );

                if (!provider.data) setProviderNotFounded(true);
                else
                    await updateSupplyCategories({
                        ...values,
                        manufacturerIdentifierNumber: provider.data.id,
                        providerId: idProvider,
                        supplyCategoryId: checkedCategories[0].id,
                    });
            } else {
                await updateSupplyCategories({
                    ...values,
                    providerId: idProvider,
                    supplyCategoryId: checkedCategories[0].id,
                });
            }
        },
    });

    useEffect(() => {
        const request = async () => {
            try {
                const filters: ListSupplyCategoriesFilters = {
                    params: {
                        level: 2,
                    },
                };

                const response = await supplyCategoriesRequests.listSupplyCategories(filters);
                setSupplyCategories(response?.data || []);
                // eslint-disable-next-line no-empty
            } catch (error) {}
        };

        request();
    }, []);

    const isRequiredManufacturerIdentification = async (supplyCategoryId: number) => {
        try {
            const isRequiredRequest =
                await supplyCategoriesProvidersRequests.isRequiredManufacturerIdentification(
                    supplyCategoryId
                );
            return isRequiredRequest.data.ok;
        } catch (err) {
            addNotificationApiError(err);
        }
    };

    const handleToggle = async (category: SupplyCategory) => {
        const isRequired = await isRequiredManufacturerIdentification(category.id);
        setIsRequiredIdenrification(isRequired);
        setCheckedCategories([category]);
    };

    return (
        <ModalProviderAddSegmentWithFieldsView
            onClose={onClose}
            supplyCategories={supplyCategories}
            isRequiredIdentification={isRequiredIdenrification}
            handleToggle={handleToggle}
            segmentCategory={segmentCategory}
            checkedCategories={checkedCategories}
            setSegmentCategory={setSegmentCategory}
            providerNotFoundedError={providerNotFounded}
            form={form}
            newTecnicalResponsible={newTecnicalResponsible}
            showModalAddTecnicalResponsible={showModalAddTecnicalResponsible}
            setShowCpfFields={setShowCpfFields}
            showCpfFields={showCpfFields}
        />
    );
};

export default ModalProviderAddSegmentWithFields;
