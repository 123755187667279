import { doRequest, managerApiInstance } from '../api';
import { OkResponse } from '../interfaces/ok.interface';
import { PlainGenericResponse } from '../interfaces/paginated.interface';
import { AuctionNoticeLotItem } from './interfaces/auction-notice-lot.interface';

interface DeleteProcessLotItems {
    auctionNoticeId: number;
    lotId: number;
    itemsIds: number[];
}

interface CreateProcessLotItem {
    auctionNoticeId: number;
    lotId: number;
    item?: number;
    externalItemId?: string;
    itemDescription?: string;
    unitMeasurement?: string;
    amount?: number;
    attachDatasheet?: number;
    referenceValue?: number;
    specificDocuments?: string;
    susCode?: string;
    susText?: string;
}

export type ProcessLotItem = CreateProcessLotItem & { id: number };

interface UpdateProcessLotItem {
    auctionNoticeId: number;
    lotId: number;
    items: ProcessLotItem[];
}

const actionLotItemRequests = {
    createLotItem: async (data: CreateProcessLotItem) =>
        doRequest<PlainGenericResponse<AuctionNoticeLotItem>>(
            managerApiInstance.post('/auction-notice-lot-item/createProcessLotItem', data)
        ),
    deleteProcessLotItems: async (data: DeleteProcessLotItems) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post('/auction-notice-lot-item/deleteProcessLotItems', data)
        ),
    updateProcessLotItems: async (data: UpdateProcessLotItem[]) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post('/auction-notice-lot-item/updateProcessLotItems', data)
        ),
};

export { actionLotItemRequests };
