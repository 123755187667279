import { Extraction } from 'common/interfaces/extraction.interface';
import { OkDto } from 'clients/interfaces/ok.interface';
import { doRequest, priceBaseInstance } from '../api';

export interface StartExtractionDto {
    extractor: string;
    data: any;
    force: boolean;
}

const extractorRequests = {
    getExtractions: async (skip: number = 0, limit: number = 20): Promise<Extraction[]> =>
        doRequest<Extraction[]>(
            priceBaseInstance.get('/extractions', {
                params: {
                    skip,
                    limit,
                },
            })
        ),
    startExtraction: async (startDto: StartExtractionDto): Promise<OkDto> =>
        doRequest<OkDto>(priceBaseInstance.post('/extractions', startDto)),
    cancelExtraction: async (extractionId: string): Promise<OkDto> =>
        doRequest<OkDto>(priceBaseInstance.delete(`/extractions/${extractionId}`)),
};

export { extractorRequests };
