import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { useSearchProviderUsersContext } from './context/SearchProviderUsersContext';
import SearchProviderUsersView from './SearchProviderUsersView';

const SearchProviderUsers = () => {
    const { providerUsersDialog, setProviderUsersDialog } = useSearchProviderUsersContext();

    const { t } = useTranslation();
    const { setTitle } = usePageContext();

    useEffect(() => {
        setTitle(t('provider-users.pages.components.users-provider'));
    }, []);

    const handleNew = () => setProviderUsersDialog({ open: true });

    return <SearchProviderUsersView {...{ handleNew, providerUsersDialog }} />;
};

export default SearchProviderUsers;
