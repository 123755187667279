import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    card: {
        '&:hover': {
            boxShadow: `1px 1px 1px 1.5px ${theme.palette.secondary.main}`,
        },
        cursor: 'pointer',
        boxShadow: (selected) => selected && `1px 1px 1px 1.5px ${theme.palette.secondary.main}`,
        padding: 0,
        width: '100%',
    },
    checkIcon: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        right: 2,
        top: 2,
    },
    paymentIcon: {
        fontSize: theme.spacing(6),
    },
    cardContent: {
        position: 'relative',
        padding: theme.spacing(1, 4),
    },
    boxTitle: {
        display: 'flex',
        flexDirection: 'column',
    },
    boxIconAndTitle: {
        display: 'flex',
        columnGap: theme.spacing(1),
    },
}));

export default useStyles;
