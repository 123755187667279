import { FC } from 'react';
import {
    makeStyles,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Box,
    IconButton,
} from '@material-ui/core';
import { AspectRatio } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { GenericResponse } from 'clients/interfaces/paginated.interface';
import { Audit } from '../../interfaces/audit.interface';
import { getProjectOptions } from '../../project-options';

export interface AuditTableProps {
    audits: Audit[];
    onSelectAudit: (audit: Audit) => void;
    paginationParams: Omit<UsePaginatedQueryResponse<GenericResponse<Audit>>, 'data' | 'loading'>;
}

const useStyles = makeStyles({
    root: {
        marginTop: '16px',
    },
});

const AuditTable: FC<AuditTableProps> = ({ audits, onSelectAudit, paginationParams }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { totalCount, page, limit, setPage } = paginationParams;

    return (
        <Paper>
            <TableContainer className={classes.root}>
                <Table aria-label='a dense table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('term.user')}</TableCell>
                            <TableCell>{t('term.date')}</TableCell>
                            <TableCell>{t('term.project')}</TableCell>
                            <TableCell>{t('term.action')}</TableCell>
                            <TableCell width='60px' />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {audits.map((audit) => (
                            <TableRow key={audit._id}>
                                <TableCell component='th' scope='row'>
                                    {audit.userId}
                                </TableCell>
                                <TableCell>
                                    {moment(audit.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                </TableCell>
                                <TableCell>
                                    {
                                        getProjectOptions().find(
                                            (option) => option.value === audit.project
                                        )?.label
                                    }
                                </TableCell>
                                <TableCell>{audit.actionName}</TableCell>
                                <TableCell width='60px'>
                                    <IconButton size='small' onClick={() => onSelectAudit(audit)}>
                                        <AspectRatio />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalCount > 0 && (
                <Box display='flex' justifyContent='flex-end' alignItems='center' padding='16px 0'>
                    <Pagination
                        shape='rounded'
                        count={Math.floor(totalCount / limit)}
                        page={page + 1}
                        onChange={(_e, page) => setPage(page - 1)}
                    />
                </Box>
            )}
        </Paper>
    );
};

export default AuditTable;
