import { FC } from 'react';
import { styled } from 'common/theme/styled';
import { Skeleton } from '@licitar/ui-kit';

const Content = styled.div`
    width: 793px;
    min-width: 793px;
    min-height: 400px;
    box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
    background: #fff;
    padding: 20px;
    max-height: 600px;
`;

const SkeletonLine = styled.div`
    width: 100%;
`;

const EditorLoader: FC = () => (
    <Content>
        {new Array(6).fill(null).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SkeletonLine key={index}>
                <Skeleton width='100%' height='24px' count={3} color='#e7e7e7' />
            </SkeletonLine>
        ))}
    </Content>
);

export default EditorLoader;
