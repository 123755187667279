import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PriceListItem from '../price-list-item';
import { TableListProps } from './props';
import {
    Content,
    Header,
    PriceMountHeader,
    MeasureHeader,
    DescriptionHeader,
    PriceHeader,
    EmptyList,
} from './styled';

const TableList: FC<TableListProps> = ({ priceList, onItemDeleted }) => {
    const { t } = useTranslation();

    return (
        <Content>
            <Header>
                <MeasureHeader>
                    <span>{t('term.measure')}</span>
                </MeasureHeader>
                <DescriptionHeader>
                    <span>{t('term.description')}</span>
                </DescriptionHeader>
                <PriceMountHeader>
                    <span>{t('term.model')}</span>
                </PriceMountHeader>
                <PriceHeader>
                    <span>{t('term.reference-value')}</span>
                </PriceHeader>
            </Header>
            {priceList.priceListItems?.length === 0 ? (
                <EmptyList>
                    <span>{t('term.no-items-added')}</span>
                </EmptyList>
            ) : (
                priceList.priceListItems?.map((priceListItem) => (
                    <PriceListItem
                        key={priceListItem._id}
                        priceListItem={priceListItem}
                        onItemDeleted={() => onItemDeleted(priceListItem)}
                    />
                ))
            )}
        </Content>
    );
};

export default TableList;
