import { FC, useState } from 'react';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { organizationUnitsRequests } from 'clients/manager/organization.requests';
import { addNotificationSuccess, addNotificationApiError } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { integrationsV2Requests } from '../../services/integrations-v2.requests';
import ImportIntegrationCardView from './import-integration-card-view';
import { OrganizationsApps } from '../../interfaces/organization-apps.interfaces';

interface ImportIntegrationCardProps {
    item: OrganizationsApps;
}

const ImportIntegrationCard: FC<ImportIntegrationCardProps> = ({ item }) => {
    const [isActive, setIsActive] = useState(false);

    const fields = item?.integrationSettings?.metadata?.requestDataProcess;
    const hasOrganizationUnitsField = !!fields?.find((field) => field.type === 'organizationUnits');

    const { t } = useTranslation();
    const history = useHistory();

    const { data: organizationUnits, loading: loadingOrganizationUnits } = useQueryWithBody(
        organizationUnitsRequests.listOrganizationUnit,
        undefined,
        {
            skip: !hasOrganizationUnitsField,
        }
    );

    if (item?.integrationSettings?.metadata?.settings?.typeIntegration !== 'formBeforeInsert')
        return <></>;

    const handleSubmit = async (values: any) => {
        if (!isActive) return setIsActive(true);

        if (item.integration === 'ipm') {
            const city =
                process.env.REACT_APP_NODE_ENV === 'development' ? 'presidentegetulio' : undefined;
            try {
                const { data } = await integrationsV2Requests.createProcess({
                    year: Number(values?.ipm_processYear),
                    modality: values?.ipm_processType,
                    number: Number(values?.ipm_processNumber),
                    organizationUnitId: values?.ipm_unitId,
                    city,
                });

                if (data?.processId) {
                    history.push({
                        pathname: `/processos/${data.processId}`,
                        search: `?aba=dados`,
                    });

                    addNotificationSuccess({
                        title: t('process.components.process-created'),
                        message: t('process.components.process-created-success'),
                    });
                    return;
                }

                throw new Error(t('process.components.process-not-created'));
            } catch (err) {
                addNotificationApiError(err);
            }
        }
    };

    return (
        <ImportIntegrationCardView
            title={item.integrationSettings.description}
            description={item.integrationSettings.fullDescription}
            onSubmit={handleSubmit}
            {...{ fields, isActive, organizationUnits, loadingOrganizationUnits }}
        />
    );
};

export default ImportIntegrationCard;
