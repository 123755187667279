import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MenuItem, Menu } from '@material-ui/core';
import { Button } from 'common/components';
import { processUtils } from 'modules/process/process-utils';
import { ArrowDropDown } from '@material-ui/icons';
import {
    addNotificationApiError,
    addNotificationSuccess,
    processStageToBiddingStageId,
} from 'common/utils';
import { MessageDialog } from 'common/components/message-dialog';
import {
    AppealStatusType,
    ProcessStage,
    TypeCancel,
} from 'clients/manager/interfaces/auction-notice.interface';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useProcessFormContext } from '../../context/process-form.context';
import OpenResourceIntentDialog from '../appeal-phase-actions/open-resource-intent-dialog';
import FinishResourceIntentDialog from '../appeal-phase-actions/finish-resource-intent-dialog';
import ModalProcessInvites from '../modal-process-invites';

const ButtonProcessActions = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [visibleModalInvites, setVisibleModalInvites] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const { process, processForm, auctionTypeRules, setProcessBkpBeforeSave } =
        useProcessFormContext();
    const { processLotsForm } = useProcessLotsFormContext();
    const { t } = useTranslation();

    const isCompetentAuthority = processForm?.values.competentAuthorityId === authUser?.id;

    const handleDoAction = async (typeCancel: TypeCancel, message: string) => {
        if (!process?.id || !processForm) {
            return;
        }

        try {
            const response = await auctionNoticeRequests.cancelProcess({
                auctionId: process.id,
                message,
                typeCancel,
            });

            if (response?.data?.ok) {
                const newValues = {
                    ...processForm?.values,
                    auctionFinished: 1,
                    auctionCanceled: 1,
                    typeCancel: typeCancel,
                    dateTimeUpdate: moment().toISOString(),
                };
                setProcessBkpBeforeSave(newValues);
                processForm?.setValues(newValues);
                return addNotificationSuccess();
            }

            throw new Error('Error');
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const reopenProcess = async (message: string) => {
        if (!process?.id || !processForm) {
            return;
        }

        try {
            const response = await auctionNoticeRequests.reopenProcess({
                auctionId: process.id,
                message,
            });

            if (response?.data?.ok) {
                const newValues = {
                    ...processForm?.values,
                    auctionFinished: 0,
                    auctionCanceled: 0,
                    typeCancel: null,
                    dateTimeUpdate: moment().toISOString(),
                };
                setProcessBkpBeforeSave(newValues);
                processForm?.setValues(newValues);
                return addNotificationSuccess();
            }

            throw new Error('Error');
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const handleHomologateProcess = async () => {
        if (!process?.id || !processForm || !isCompetentAuthority) {
            return;
        }

        try {
            const response = await auctionNoticeRequests.doApproveSuppliers({
                processId: process.id,
            });

            if (response?.data?.approved) {
                const newValues = {
                    ...processForm?.values,
                    approveSuppliers: 1,
                    dateTimeUpdate: moment().toISOString(),
                    biddingStageId: 11,
                };
                setProcessBkpBeforeSave(newValues);
                processForm?.setValues(newValues);
                return addNotificationSuccess();
            }

            throw new Error('Error');
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            handleClose();
        }
    };

    const handleClickFinish = async () => {
        if (!process?.id) {
            return;
        }

        try {
            const response = await auctionNoticeRequests.finishProcess(process.id);

            if (response?.data?.ok) {
                processForm?.setFieldValue('auctionFinished', 1);
                return addNotificationSuccess();
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const inBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processForm?.values?.biddingStageId &&
            biddingStage.includes(processForm.values.biddingStageId),
        [processForm?.values?.biddingStageId]
    );

    const defaultConfirmMessage = t('info.cancel-process-info');

    if (!process) {
        return null;
    }

    if (
        processUtils.disabledProcess(processForm?.values) ||
        (processUtils.finishedProcess(processForm?.values) &&
            processForm?.values.typeCancel !== TypeCancel.suspended)
    ) {
        return null;
    }

    const visibleFinishButton =
        inBiddingStage(processStageToBiddingStageId([ProcessStage.contract])) &&
        !processForm?.values.auctionFinished;

    const visibleOpenResourceIntent =
        auctionTypeRules &&
        inBiddingStage(
            processStageToBiddingStageId([
                ProcessStage.contract,
                ProcessStage.decision,
                ProcessStage.dispute,
            ])
        ) &&
        processForm?.values.isPhaseReversal === 1 &&
        processForm?.values.appealStatus === AppealStatusType.closed &&
        processUtils.openProcess(processForm?.values);

    const visibleFinishResourceIntent =
        auctionTypeRules &&
        inBiddingStage(
            processStageToBiddingStageId([
                ProcessStage.contract,
                ProcessStage.decision,
                ProcessStage.dispute,
                ProcessStage.proposals,
            ])
        ) &&
        processForm?.values.isPhaseReversal === 1 &&
        processForm?.values.appealStatus !== AppealStatusType.closed;

    const processIsHomologated = processForm?.values.approveSuppliers === 1;
    const visibleHomologation =
        inBiddingStage(
            processStageToBiddingStageId([ProcessStage.decision, ProcessStage.contract])
        ) && processUtils.isAccreditationProcess(processForm?.values);

    const visibleProcessInvites = inBiddingStage(
        processStageToBiddingStageId([
            ProcessStage.proposals,
            ProcessStage.dispute,
            ProcessStage.decision,
            ProcessStage.contract,
        ])
    );

    const visibleDeleteProcess = inBiddingStage(
        processStageToBiddingStageId(ProcessStage.internal)
    );

    const visibleFailProcess = processUtils.isAllLotsFrustred(processLotsForm.values.lots);

    const visibleRevokeProcess =
        inBiddingStage(
            processStageToBiddingStageId([
                ProcessStage.proposals,
                ProcessStage.contract,
                ProcessStage.decision,
                ProcessStage.dispute,
            ])
        ) && processUtils.openProcess(processForm?.values);

    const visibleNullifyProcess =
        inBiddingStage(
            processStageToBiddingStageId([
                ProcessStage.proposals,
                ProcessStage.contract,
                ProcessStage.decision,
                ProcessStage.dispute,
            ])
        ) && processUtils.openProcess(processForm?.values);

    const visibleSuspendProcess =
        inBiddingStage(processStageToBiddingStageId(ProcessStage.proposals)) &&
        processUtils.openProcess(processForm?.values);

    const visibleReopenProcess = processForm?.values?.typeCancel === TypeCancel.suspended;

    return (
        <>
            {visibleModalInvites && (
                <ModalProcessInvites onClose={() => setVisibleModalInvites(false)} />
            )}
            <Button
                color='primary'
                variant='contained'
                disabled={false}
                size='xsmall'
                aria-haspopup='true'
                title={t('term.actions')}
                onClick={handleClick}
                endIcon={<ArrowDropDown />}
            >
                {t('term.actions')}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {visibleProcessInvites && (
                    <MenuItem
                        onClick={() => {
                            setVisibleModalInvites(true);
                            handleClose();
                        }}
                    >
                        {inBiddingStage(processStageToBiddingStageId([ProcessStage.proposals]))
                            ? t('info.send-invitations')
                            : t('info.view-sent-invitations')}
                    </MenuItem>
                )}
                {visibleHomologation && (
                    <ConfirmDialog
                        title={t('process-actions.finish-homologation')}
                        message={t('process-actions.finish-homologation-description')}
                        onConfirm={handleHomologateProcess}
                    >
                        <MenuItem disabled={!isCompetentAuthority || processIsHomologated}>
                            {processIsHomologated && t('term.homologated')}
                            {!isCompetentAuthority &&
                                !processIsHomologated &&
                                t('process-actions.wating-homologation')}
                            {isCompetentAuthority && !processIsHomologated && t('term.homologate')}
                        </MenuItem>
                    </ConfirmDialog>
                )}
                {visibleOpenResourceIntent && (
                    <OpenResourceIntentDialog>
                        <MenuItem onClick={handleClose}>{t('info.open-appeal-phase')}</MenuItem>
                    </OpenResourceIntentDialog>
                )}
                {visibleFinishResourceIntent && (
                    <FinishResourceIntentDialog>
                        <MenuItem onClick={handleClose}>{t('info.close-appeal-phase')}</MenuItem>
                    </FinishResourceIntentDialog>
                )}
                {visibleFinishButton && (
                    <ConfirmDialog
                        title={t('info.finish-process-title')}
                        message={t('info.finish-process-message')}
                        onConfirm={handleClickFinish}
                    >
                        <MenuItem onClick={handleClose}>{t('term.finish')}</MenuItem>
                    </ConfirmDialog>
                )}
                {visibleDeleteProcess && (
                    <MessageDialog
                        title={t('info.cancel-confirmation', { value: t('term.delete') })}
                        onConfirm={(message) => handleDoAction(TypeCancel.excluded, message)}
                        message={defaultConfirmMessage}
                    >
                        <MenuItem onClick={handleClose}>{t('term.delete')}</MenuItem>
                    </MessageDialog>
                )}
                {visibleReopenProcess && (
                    <MessageDialog
                        title={t('info.cancel-confirmation', { value: t('term.reopen') })}
                        onConfirm={(message) => reopenProcess(message)}
                        message={defaultConfirmMessage}
                    >
                        <MenuItem onClick={handleClose}>{t('term.reopen')}</MenuItem>
                    </MessageDialog>
                )}
                {visibleFailProcess && (
                    <MessageDialog
                        title={t('info.cancel-confirmation', { value: t('term.fail') })}
                        onConfirm={(message) => handleDoAction(TypeCancel.fail, message)}
                        message={defaultConfirmMessage}
                    >
                        <MenuItem onClick={handleClose}>{t('term.fail')}</MenuItem>
                    </MessageDialog>
                )}
                {visibleRevokeProcess && (
                    <MessageDialog
                        title={t('info.cancel-confirmation', { value: t('term.revoke') })}
                        onConfirm={(message) => handleDoAction(TypeCancel.revoked, message)}
                        message={defaultConfirmMessage}
                    >
                        <MenuItem onClick={handleClose}>{t('term.revoke')}</MenuItem>
                    </MessageDialog>
                )}
                {visibleNullifyProcess && (
                    <MessageDialog
                        title={t('info.cancel-confirmation', { value: t('term.nullify') })}
                        onConfirm={(message) => handleDoAction(TypeCancel.annulled, message)}
                        message={defaultConfirmMessage}
                    >
                        <MenuItem onClick={handleClose}>{t('term.nullify')}</MenuItem>
                    </MessageDialog>
                )}
                {visibleSuspendProcess && (
                    <MessageDialog
                        title={t('info.cancel-confirmation', { value: t('term.suspend') })}
                        onConfirm={(message) => handleDoAction(TypeCancel.suspended, message)}
                        message={defaultConfirmMessage}
                    >
                        <MenuItem onClick={handleClose}>{t('term.suspend')}</MenuItem>
                    </MessageDialog>
                )}
                <MessageDialog
                    title={t('info.cancel-confirmation', { value: t('term.cancel') })}
                    onConfirm={(message) => handleDoAction(TypeCancel.canceled, message)}
                    message={defaultConfirmMessage}
                >
                    <MenuItem onClick={handleClose}>{t('term.cancel')}</MenuItem>
                </MessageDialog>
            </Menu>
        </>
    );
};

export default memo(ButtonProcessActions);
