import { SaleSourceTypes } from 'common/interfaces/sale-source.interface';

const getExtractionType = (saleType: SaleSourceTypes) => {
    switch (saleType) {
        case SaleSourceTypes.LICITAR_DIGITAL:
            return 'licitardigital';

        case SaleSourceTypes.COMPRAS_NET:
            return 'comprasnet';

        case SaleSourceTypes.PNCP:
            return 'pncp';

        default:
            return '';
    }
};

const getExtractionTypeLabel = (saleType: SaleSourceTypes) => {
    switch (saleType) {
        case SaleSourceTypes.COMPRAS_NET:
            return 'Compras net';

        case SaleSourceTypes.LICITAR_DIGITAL:
            return 'Licitar digital';

        case SaleSourceTypes.PNCP:
            return 'PNCP';

        default:
            return '';
    }
};

export { getExtractionType, getExtractionTypeLabel };
