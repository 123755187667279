import i18n from 'i18next';
import { getTinyMceAutocompleteVariable, getTinyMceDefaultConfig } from 'common/components/editor';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { EditorVariable } from '.';
import { externalVariables } from './editor-creator';

export const EditorConfigs = {
    init: (
        canCreateVariable: boolean,
        onUploadFile: (blob: any) => Promise<PlainGenericResponse<{ url: string }> | undefined>,
        onVariableSelected?: ({ id, rng, element }: { id: string; rng: any; element: any }) => void,
        variables?: EditorVariable[],
        useExternalVariables?: boolean
    ) =>
        ({
            ...getTinyMceDefaultConfig(),
            images_upload_handler: (blobInfo, success, failure) =>
                onUploadFile(blobInfo.blob())
                    .then((response) => success(response?.data?.url))
                    .catch(() => failure(i18n.t('editor.components.editor.config.error-message'))),
            plugins: [
                'autoresize',
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks powerpaste searchreplace',
                'insertdatetime table code advtable hr',
            ],
            setup: (editor) => {
                if (onVariableSelected) {
                    editor.on('click', (e) => {
                        const isVariable = e.target.getAttribute('data-variable-id');
                        if (isVariable) {
                            return onVariableSelected({
                                id: e.target.getAttribute('data-variable-id'),
                                rng: editor.selection.getRng(),
                                element: e.target,
                            });
                        }
                    });
                }

                if (canCreateVariable) {
                    getTinyMceAutocompleteVariable(
                        editor,
                        variables ?? externalVariables,
                        useExternalVariables
                    );
                }
            },
            toolbar: [
                'undo redo | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | hr',
                'numlist bullist checklist | bold italic underline strikethrough blockquote | forecolor backcolor casechange permanentpen formatpainter | pagebreak | emoticons | insertfile image pageembed template link | table format | searchreplace',
            ],
            content_css: '/assets/tinymce-content.css',
        } as any),
};
