import { memo } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const ProcessLotLoading = () => (
    <Box mt={2}>
        <Grid component={Paper} variant='outlined' elevation={2} container>
            <Box width={1} display='flex' justifyContent='space-between'>
                <Skeleton
                    width='100%'
                    height='48px'
                    style={{
                        margin: 8,
                    }}
                />
                <Skeleton
                    width='48px'
                    height='48px'
                    style={{
                        margin: 8,
                    }}
                />
            </Box>
        </Grid>
    </Box>
);

export default memo(ProcessLotLoading);
