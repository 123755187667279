import {
    JudgmentCriterion,
    MethodDispute,
    TypeValueBidTypes,
} from 'clients/manager/interfaces/auction-notice.interface';
import { AuctionTypeEnum } from 'common/enums';
import { ProcessForm } from './process-form.context';
import { LegalSupport } from '../components/process-data/enum/legal-support.enum';

const defaultProcessFormValues: Partial<ProcessForm> = {
    legalSupportId: undefined,
    auctionNumber: undefined,
    accreditationNumber: undefined,
    typeValueBid: TypeValueBidTypes.unit,
    methodDispute: MethodDispute.aberto,
    disputeDuration: undefined,
    judgmentCriterion: undefined,
    unenforceableNumber: undefined,
    auctionStartDate: undefined,
    auctionEndDate: undefined,
    expirationDatePriceRegistration: undefined,
    isRideAllowed: undefined,
    originOfFinancialResource: undefined,
    agreementNumber: undefined,
    itsAgreementOrTransfer: 0,
    rangeRegionParticipation: 0,
    isPhaseReversal: 0,
    itsPriceRegistration: 0,
    decimalPlaces: 2,
};

const defaultTradingValues: Partial<ProcessForm> = {
    ...defaultProcessFormValues,
    legalSupportId: LegalSupport._14133_2021_Art28_I,
    judgmentCriterion: +JudgmentCriterion.lowestPrice,
};

const defaultDispenseValues: Partial<ProcessForm> = {
    ...defaultProcessFormValues,
    disputeDuration: 28800,
    methodDispute: MethodDispute.notApplicable,
    judgmentCriterion: +JudgmentCriterion.lowestPrice,
};

const defaultAuctionValues: Partial<ProcessForm> = {
    ...defaultProcessFormValues,
    legalSupportId: LegalSupport._14133_2021_Art28_IV,
    judgmentCriterion: +JudgmentCriterion.highestPrice,
};

const defaultConcurrenceValues: Partial<ProcessForm> = {
    ...defaultProcessFormValues,
    legalSupportId: LegalSupport._14133_2021_Art28_II,
    judgmentCriterion: +JudgmentCriterion.lowestPrice,
};

const defaultAccreditationValues: Partial<ProcessForm> = {
    ...defaultProcessFormValues,
    typeValueBid: TypeValueBidTypes.kit,
};

const defaultUnenforceabilityValues: Partial<ProcessForm> = {
    ...defaultProcessFormValues,
    typeValueBid: TypeValueBidTypes.kit,
};

const defaultMarketplaceValues: Partial<ProcessForm> = {
    ...defaultProcessFormValues,
    typeValueBid: TypeValueBidTypes.unit,
};

function getDefaultFormValuesByAuctionType(auctionType: AuctionTypeEnum, isMarketplace?: boolean) {
    if (isMarketplace && auctionType === AuctionTypeEnum.accreditation)
        return defaultMarketplaceValues;
    if (auctionType === AuctionTypeEnum.trading) return defaultTradingValues;
    if (auctionType === AuctionTypeEnum.auction) return defaultAuctionValues;
    if (auctionType === AuctionTypeEnum.dispense) return defaultDispenseValues;
    if (auctionType === AuctionTypeEnum.concurrence) return defaultConcurrenceValues;
    if (auctionType === AuctionTypeEnum.accreditation) return defaultAccreditationValues;
    if (auctionType === AuctionTypeEnum.unenforceability) return defaultUnenforceabilityValues;
    return defaultProcessFormValues;
}

export {
    defaultProcessFormValues,
    defaultTradingValues,
    defaultAuctionValues,
    defaultDispenseValues,
    defaultConcurrenceValues,
    defaultAccreditationValues,
    defaultUnenforceabilityValues,
    defaultMarketplaceValues,
    getDefaultFormValuesByAuctionType,
};
