import { FC, useState } from 'react';
import { IconButton, Typography, InputAdornment } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useTranslation } from 'react-i18next';
import { processChatsRequests } from 'clients/manager/process-chats.requests';
import { Chat } from 'clients/manager/interfaces/process-chats.interface';
import { addNotificationApiError } from 'common/utils';
import { TextField } from 'common/components';

interface CreateChatProps {
    processId: number;
    onChatCreated: (chat: Chat) => void;
}

export const MESSAGE_LENGTH_LIMIT = 1000;

const CreateChat: FC<CreateChatProps> = ({ processId, onChatCreated }) => {
    const [message, setMessage] = useState<string | undefined>();
    const { t } = useTranslation();

    const handleSetMessage = (text: string) => {
        if (text?.length > MESSAGE_LENGTH_LIMIT) {
            return setMessage(text.slice(0, MESSAGE_LENGTH_LIMIT));
        }
        return setMessage(text);
    };

    const handleCreateChat = async () => {
        if (!message) {
            return;
        }

        try {
            const response = await processChatsRequests.createProcessChat({
                message,
                processId,
            });

            if (response?.data) {
                setMessage('');
                const input = document.getElementById('chat-message');
                input?.focus();
                onChatCreated(response.data);
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    return (
        <div>
            <TextField
                style={{
                    margin: '0 0 2px 0',
                }}
                minRows={3}
                maxRows={3}
                id='chat-message'
                multiline
                fullWidth
                autoFocus
                name='message'
                label={t('term.message')}
                value={message}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={handleCreateChat} color='primary' size='small'>
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onChange={(ev) => handleSetMessage(ev.target.value)}
            />
            <Typography variant='body2' style={{ fontSize: 12, textAlign: 'right' }}>
                <span>{`${message?.length ?? 0}/${MESSAGE_LENGTH_LIMIT} ${t(
                    'info.characters'
                )}`}</span>
            </Typography>
        </div>
    );
};

export default CreateChat;
