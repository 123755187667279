import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        paddingRight: '10px',
        height: '75vh',
        flex: 1,
        overflowY: 'auto',
    },
}));

export { useStyles };
