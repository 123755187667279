import React from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress } from '@material-ui/core';
import { LoadingModalProps } from 'common/interfaces/loading-modal.interface';
import { useStyles } from './styles';

const LoadingModal: React.FC<LoadingModalProps> = ({ open, message }) => {
    const classes = useStyles();

    const handleClose = (event: React.SyntheticEvent<any, Event>, reason: string) => {
        if (reason === 'backdropClick') {
            event.stopPropagation();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} className={classes.dialog} fullWidth>
            <DialogTitle className={classes.title}>{message}</DialogTitle>
            <DialogContent className={classes.content}>
                <div className={classes.progressContainer}>
                    <CircularProgress />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LoadingModal;
