import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import {
    GetByUserBody,
    GetByUserResponse,
} from '../interfaces/authorization-group-users.interface';

const BASE_PATH = '/authorization-group-users';

const AuthorizationGroupUsersRequests = {
    getByUser: async (body: GetByUserBody, config?: AxiosRequestConfig<any>) =>
        doRequest<GetByUserResponse>(
            managerApiInstance.post(`${BASE_PATH}/getByUser`, body, config)
        ),
};

export { AuthorizationGroupUsersRequests };
