import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { usePlatformContext } from 'routes/platform.context';
import { useStyles } from './styles';

interface IProps {
    setUserType: (element: string) => void;
}

const SelectTypeUser = ({ setUserType }: IProps) => {
    const { platform } = usePlatformContext();
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const hideSignupOrganization = platform?.generalSettings?.general?.hideSignupOrganization;
    const hideSignupProvider = platform?.generalSettings?.general?.hideSignupProvider;

    const { t } = useTranslation();

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ) => {
        setSelectedIndex(index);
        switch (index) {
            case 0:
                setUserType('Cidadão');
                break;
            case 1:
                setUserType('Comprador');
                break;
            case 2:
                setUserType('Fornecedor');
                break;
            default:
                setUserType('Cidadão');
        }
    };

    return (
        <>
            <div className={classes.root}>
                <List component='nav' aria-label='main mailbox folders'>
                    <ListItem
                        button
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0)}
                    >
                        <ListItemIcon className='leftIcon'>
                            {selectedIndex === 0 ? (
                                <img src='/assets/icons/icon_citizen_active.png' alt='' />
                            ) : (
                                <img src='/assets/icons/icon_citizen.png' alt='' />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={t('term.citizen')}
                            secondary={t('sign-up.select-type-user.government-processes')}
                        />
                        <ListItemIcon className='checkIcon'>
                            {selectedIndex === 0 && <CheckCircleRoundedIcon color='secondary' />}
                        </ListItemIcon>
                    </ListItem>
                    {!hideSignupOrganization && (
                        <ListItem
                            button
                            selected={selectedIndex === 1}
                            onClick={(event) => handleListItemClick(event, 1)}
                        >
                            <ListItemIcon className='leftIcon'>
                                {selectedIndex === 1 ? (
                                    <img src='/assets/icons/icon_buyer_active.png' alt='' />
                                ) : (
                                    <img src='/assets/icons/icon_buyer.png' alt='' />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={t('term.public-entity')}
                                secondary={t('sign-up.select-type-user.add-public-entity')}
                            />
                            <ListItemIcon className='checkIcon'>
                                {selectedIndex === 1 && (
                                    <CheckCircleRoundedIcon color='secondary' />
                                )}
                            </ListItemIcon>
                        </ListItem>
                    )}
                    {!hideSignupProvider && (
                        <ListItem
                            button
                            selected={selectedIndex === 2}
                            onClick={(event) => handleListItemClick(event, 2)}
                        >
                            <ListItemIcon className='leftIcon'>
                                {selectedIndex === 2 ? (
                                    <img src='/assets/icons/icon_provider_active.png' alt='' />
                                ) : (
                                    <img src='/assets/icons/icon_provider.png' alt='' />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={t('term.supplier')}
                                secondary={t('sign-up.select-type-user.add-supplier')}
                            />
                            <ListItemIcon className='checkIcon'>
                                {selectedIndex === 2 && (
                                    <CheckCircleRoundedIcon color='secondary' />
                                )}
                            </ListItemIcon>
                        </ListItem>
                    )}
                </List>
                <Divider />
            </div>
        </>
    );
};

export default SelectTypeUser;
