import {
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    AuctionNoticeLotPartialItems,
    LotDesert,
    LotFrustated,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import { DetailedAutcionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { FaBoxOpen } from 'react-icons/fa';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { AuctionTypeEnum } from 'common/enums';
import {
    CleanContainer,
    LotsLoadingContainer,
    LotTableBodyCell,
    LotTableHeaderCell,
    ProcessDescriptionCard,
} from '../../styled';
import LotDialog from './LotDialog';

type LotsConfig = { limit: number; offset: number; loading: boolean; count: number };

interface LotsContainerProps {
    data: AuctionNoticeLotPartialItems[];
    loading?: boolean;
    lotsConfig: LotsConfig;
    setLotsConfig: Dispatch<SetStateAction<LotsConfig>>;
    generalData?: DetailedAutcionNotice;
}

const LotsContainer = ({
    data = [],
    generalData,
    loading,
    lotsConfig,
    setLotsConfig,
}: LotsContainerProps) => {
    const { t } = useTranslation();
    const [providerDialogData, setProviderDialogData] = useState({
        open: false,
        processId: 0,
        providerId: 0,
    });

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: generalData?.decimalPlaces || 2,
    });

    const enableShowLotInformations =
        !!generalData &&
        (generalData?.isPhaseReversal === 1 ||
            generalData.biddingStageId >= BiddingStageEnum.decision);

    const handleShowLotInformations = async (id: number, providerId: number) => {
        setProviderDialogData({
            processId: id,
            providerId,
            open: true,
        });
    };

    function getAccreditationStatus(): string {
        switch (generalData?.biddingStageId) {
            case BiddingStageEnum.published:
                return t(`process.components.receiving-proposals`);
            case BiddingStageEnum.decision:
                return t(`bidding.process.status.decision`);
            case BiddingStageEnum.finishing:
                return t(`bidding.process.status.contract`);
            default:
                return '-';
        }
    }

    function getSituationLots(lot: AuctionNoticeLotPartialItems): string {
        if (lot.isItDesert === LotDesert.isDesert) {
            return t(`bidding.process.lot.stage.desert`);
        }
        if (lot.isItFrustrated === LotFrustated.isFrustrated) {
            return t(`bidding.process.status.fail`);
        }
        if (generalData?.processType === AuctionTypeEnum.accreditation) {
            return getAccreditationStatus();
        }
        if (generalData?.biddingStageId === BiddingStageEnum.published) {
            return t(`process.components.receiving-proposals`);
        }
        return t(`bidding.process.lot.stage.${lot.lotStage}`);
    }

    return (
        <>
            <ProcessDescriptionCard elevation={0} variant='outlined'>
                <header>
                    <FaBoxOpen size='1.5rem' />
                    <h1>{t('bidding.process.section.lots')}</h1>
                </header>
                <section>
                    {!loading && data.length === 0 && (
                        <CleanContainer>
                            <h3>{t('bidding.process.section.lots.blank')}</h3>
                        </CleanContainer>
                    )}
                    {(loading || data.length > 0) && (
                        <Paper elevation={0} variant='outlined' style={{ position: 'relative' }}>
                            {lotsConfig.loading && (
                                <LotsLoadingContainer>
                                    <CircularProgress />
                                </LotsLoadingContainer>
                            )}
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <LotTableHeaderCell>
                                                {t('bidding.process.lot.table.label.lot')}
                                            </LotTableHeaderCell>
                                            <LotTableHeaderCell>
                                                {t('bidding.process.lot.table.label.description')}
                                            </LotTableHeaderCell>
                                            <LotTableHeaderCell>
                                                {t('bidding.process.lot.table.label.value')}
                                            </LotTableHeaderCell>
                                            <LotTableHeaderCell>
                                                {t('bidding.process.lot.table.label.situation')}
                                            </LotTableHeaderCell>
                                            <LotTableHeaderCell width={198} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading && (
                                            <TableRow>
                                                <LotTableBodyCell>
                                                    <Skeleton width='100%' variant='text' />
                                                </LotTableBodyCell>
                                                <LotTableBodyCell>
                                                    <Skeleton width='100%' variant='text' />
                                                </LotTableBodyCell>
                                                <LotTableBodyCell>
                                                    <Skeleton width='100%' variant='text' />
                                                </LotTableBodyCell>
                                                <LotTableBodyCell>
                                                    <Skeleton width='100%' variant='text' />
                                                </LotTableBodyCell>
                                                <LotTableBodyCell>
                                                    <div
                                                        style={{
                                                            marginLeft: 'auto',
                                                            width: 'fit-content',
                                                        }}
                                                    >
                                                        <IconButton disabled color='primary'>
                                                            <AiOutlineFileSearch />
                                                        </IconButton>
                                                    </div>
                                                </LotTableBodyCell>
                                            </TableRow>
                                        )}
                                        {!loading &&
                                            data.map((lot) => (
                                                <TableRow key={lot.id}>
                                                    <LotTableBodyCell>{lot.item}</LotTableBodyCell>
                                                    <LotTableBodyCell>
                                                        {lot.lotDescription}
                                                    </LotTableBodyCell>
                                                    <LotTableBodyCell>
                                                        {lot.showReferenceValue
                                                            ? '****'
                                                            : formatter.format(lot?.referenceValue)}
                                                    </LotTableBodyCell>
                                                    <LotTableBodyCell>
                                                        {getSituationLots(lot)}
                                                    </LotTableBodyCell>
                                                    <LotTableBodyCell>
                                                        <div
                                                            style={{
                                                                marginLeft: 'auto',
                                                                width: 'fit-content',
                                                            }}
                                                        >
                                                            <IconButton
                                                                disabled={
                                                                    !lot.winnerProviderId ||
                                                                    !enableShowLotInformations
                                                                }
                                                                onClick={() =>
                                                                    lot.winnerProviderId &&
                                                                    handleShowLotInformations(
                                                                        lot.auctionNoticeId,
                                                                        lot.winnerProviderId
                                                                    )
                                                                }
                                                                color='primary'
                                                            >
                                                                <AiOutlineFileSearch />
                                                            </IconButton>
                                                        </div>
                                                    </LotTableBodyCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 40]}
                                                count={lotsConfig.count}
                                                rowsPerPage={lotsConfig.limit}
                                                page={lotsConfig.offset / lotsConfig.limit}
                                                labelRowsPerPage={t(
                                                    'bidding.process.lot.table.action.rows-per-page'
                                                )}
                                                SelectProps={{
                                                    inputProps: {
                                                        'aria-label': t(
                                                            'bidding.process.lot.table.action.rows-per-page'
                                                        ),
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={(_, page) => {
                                                    setLotsConfig((prev) => ({
                                                        ...prev,
                                                        offset:
                                                            page * prev.limit < 0
                                                                ? 0
                                                                : page * prev.limit,
                                                    }));
                                                }}
                                                onRowsPerPageChange={({ target }) => {
                                                    setLotsConfig((prev) => ({
                                                        ...prev,
                                                        limit: Number(target.value),
                                                    }));
                                                }}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}
                </section>
            </ProcessDescriptionCard>
            <LotDialog
                onClose={() => setProviderDialogData((prev) => ({ ...prev, open: false }))}
                {...providerDialogData}
            />
        </>
    );
};

export default LotsContainer;
