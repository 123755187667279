import { styled } from 'common/theme/styled';
import { RiArrowDropDownLine, RiArrowDropRightLine, RiMore2Fill } from 'react-icons/ri';

const Card = styled.div`
    display: flex;
    height: 67px;
    background: #fafafa;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    }
`;

const Details = styled.div`
    justify-content: space-between;
    display: flex;
    flex: 1;
`;

const Template = styled.div`
    display: flex;
    align-items: center;

    span {
        color: ${({ theme }) => theme.colors.textLight};
        font-size: 14px;
    }
`;

const PricesAmountDetails = styled(Template)`
    width: 60px;
    display: flex;
    align-items: center;
`;

const MeasureDetails = styled(Template)`
    width: 20%;
`;

const DescriptionDetails = styled(Template)`
    width: 40%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
`;

const PriceMountDetails = styled(Template)`
    width: 15%;
`;

const PriceDetails = styled(Template)`
    width: 15%;
`;

const IconArrowRight = styled(RiArrowDropRightLine)`
    font-size: 24px;
    color: ${({ theme }) => theme.colors.iconColorDefault};
`;

const IconArrowDown = styled(RiArrowDropDownLine)`
    font-size: 24px;
    color: ${({ theme }) => theme.colors.iconColorDefault};
`;

const IconMoreOptions = styled(RiMore2Fill)`
    font-size: 22px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.iconColorDefault};
`;

const SkeletonLine = styled.div`
    height: 67px;
    padding: 7px 25px;
    background: #fff;
    border-top: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
`;

export {
    Card,
    Details,
    PricesAmountDetails,
    MeasureDetails,
    DescriptionDetails,
    PriceMountDetails,
    PriceDetails,
    IconArrowRight,
    IconArrowDown,
    IconMoreOptions,
    SkeletonLine,
};
