const globalConstants = {
    storageMap: {
        EDITOR: '@editor',
    },
    cookies: {
        AUTH_USER: 'authUserToken',
        TOKEN: '_LDToken',
        ENABLE_HTTP_TOKEN: 'enableHttpToken',
        EXPIRES_TOKEN: 120,
    },
    mobileWidth: 600,
    basePX: 16,
    ibgeCodes: {
        BRAZIL: '1058',
    },
    defaultFileMaxSize: 5 * 1024 * 1024,
};

export { globalConstants };
