import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: theme.spacing(1),
        },
        textProcess: {
            color: '#C4C4C4',
        },
        textInfoArea: {
            fontSize: '14px',
        },
        chip: {
            fontSize: 10,
            margin: theme.spacing(0.2),
        },
        clickable: {
            cursor: 'pointer',
        },
        withoutLabel: {
            paddingLeft: 0,
        },
        warningNotification: {
            color: theme.palette.error.light,
        },
        alertBackgroud: {
            background: theme.palette.warning.main,
            color: theme.palette.common.white,
        },
    })
);

export { useStyles };
