import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { EvaluatingStatusType } from 'common/interfaces/document-type-provider.interface';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectDocumentsStatusProps {
    value: EvaluatingStatusType | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    omitAllOption?: boolean;
}

const SelectDocumentsStatus: FC<SelectDocumentsStatusProps> = ({
    value,
    onChange,
    omitAllOption,
}) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='evaluatingStatus'
            label={t('hiring-plans.labels.status')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            variant='outlined'
            select
            value={value}
        >
            {!omitAllOption && <MenuItem value={value}>{t('term.all')}</MenuItem>}
            <MenuItem value={EvaluatingStatusType.APROVADO}>{t('term.approved')}</MenuItem>
            <MenuItem value={EvaluatingStatusType.EXPIRADO}>{t('term.expired')}</MenuItem>
            <MenuItem value={EvaluatingStatusType.REPROVADO}>{t('term.reproved')}</MenuItem>
            <MenuItem value={EvaluatingStatusType.AGUARDANDO_ATI_VCTE}>
                {t('info.provider-document-reference')}
            </MenuItem>
            <MenuItem value={EvaluatingStatusType.AGUARDANDO_AVALIACAO}>
                {t('info.awaiting-validation')}
            </MenuItem>
        </TextField>
    );
};

export default SelectDocumentsStatus;
