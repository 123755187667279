import { ufValues } from './uf-values.constant';
import { envConstants } from './env.constant';
import { globalConstants } from './global.constant';
import { YupValidationMessages } from './yup-validation-messages.constant';
import { defaultPrivacyPolicyUrl, defaultTermsOfServiceUrl } from './redirect-urls.constant';
import { instructionLevelConstants } from './instructionLevel.constants';
import { professionConstants } from './profession.constants';
import { formatExport } from './format-export.static';
import { municipalityConstants } from './municipality.constants';

const variables = {
    ...globalConstants,
    ...envConstants,
    instructionLevelConstants,
    professionConstants,
    municipalityConstants,
    defaultPrivacyPolicyUrl,
    defaultTermsOfServiceUrl,
    YupValidationMessages,
    ufValues,
    formatExport,
};

export const CONSTANTS = variables;
