import { FC } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField } from 'common/components';
import { SelectSegmentCategories } from 'clients/manager/interfaces/segment-categories-providers.interface';
import SelectStatusSegmentsCategory from '../select-status-segments-settings-category';
import IdentificationNumberTextField from '../identification-number-text-field';
import { FormTechManagerViewProps } from './props';
import { useStyles } from './styles';

const FormTechManagerView: FC<FormTechManagerViewProps> = ({
    handleOnStatusSegmentChange,
    form,
    segmentCategory,
    providerNotFoundedError,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Grid container item xs={12} spacing={2}>
                <Box mt={3} mb={1} width={1}>
                    <Typography className={classes.title}>
                        {t('term.responsible-tecnical')}
                    </Typography>
                    <Divider />
                </Box>

                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        label={t('term.full-name')}
                        name='technicalManagerName'
                        value={form.values.technicalManagerName}
                        onChange={form.handleChange}
                        error={
                            form.touched.technicalManagerName && !!form.errors.technicalManagerName
                        }
                        helperText={
                            form.touched.technicalManagerName && form.errors.technicalManagerName
                        }
                    />
                </Grid>

                <Grid item xs={4}>
                    <SelectStatusSegmentsCategory
                        onChange={(segmentCategory) => {
                            handleOnStatusSegmentChange(segmentCategory.target.value);
                            form.setFieldValue('suppliesAs', segmentCategory.target.value);
                        }}
                        value={segmentCategory}
                    />
                </Grid>

                <Grid item xs={4}>
                    <IdentificationNumberTextField
                        identificationNumber={form.values.technicalManagerDocNumber}
                        country='1058'
                        fieldName='technicalManagerDocNumber'
                        type='cpf'
                        {...form}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label={t('info.board-type')}
                        name='technicalManagerBoardType'
                        value={form.values.technicalManagerBoardType}
                        onChange={form.handleChange}
                        error={
                            form.touched.technicalManagerBoardType &&
                            !!form.errors.technicalManagerBoardType
                        }
                        helperText={
                            form.touched.technicalManagerBoardType &&
                            form.errors.technicalManagerBoardType
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label={t('term.board-number')}
                        name='technicalManagerBoardNumber'
                        value={form.values.technicalManagerBoardNumber}
                        onChange={form.handleChange}
                        error={
                            form.touched.technicalManagerBoardNumber &&
                            !!form.errors.technicalManagerBoardNumber
                        }
                        helperText={
                            form.touched.technicalManagerBoardNumber &&
                            form.errors.technicalManagerBoardNumber
                        }
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                    {segmentCategory !== SelectSegmentCategories.manufacturer && (
                        <TextField
                            fullWidth
                            label='CNPJ/NIF'
                            name='manufacturerIdentifierNumber'
                            value={form.values.manufacturerIdentifierNumber}
                            onChange={form.handleChange}
                            error={
                                providerNotFoundedError ||
                                (form.touched.manufacturerIdentifierNumber &&
                                    !!form.errors.manufacturerIdentifierNumber)
                            }
                            helperText={
                                providerNotFoundedError
                                    ? t('term.provider-not-found')
                                    : form.touched.manufacturerIdentifierNumber &&
                                      form.errors.manufacturerIdentifierNumber
                            }
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default FormTechManagerView;
