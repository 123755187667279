import { doRequest, priceBaseInstance } from 'clients/api';
import { ProvidersResponse } from './interfaces/providers-response.interface';

const providerRequests = {
    getProvider: async (id: string): Promise<ProvidersResponse> =>
        doRequest<ProvidersResponse>(priceBaseInstance.get(`/providers/${id}`)),
    getProviders: async (ids: string[]): Promise<ProvidersResponse[]> => {
        const formattedIds = ids.join(',');
        return doRequest<ProvidersResponse[]>(
            priceBaseInstance.get(`/providers`, {
                params: {
                    ids: formattedIds,
                },
            })
        );
    },
};

export { providerRequests };
