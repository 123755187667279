import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import { FC, useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import UploadFile from 'common/components/upload-file';
import { addNotificationError, timeout } from 'common/utils';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from 'common/constants';
import mime from 'mime';
import { UploadDocumentsProps, UploadedFile, UploadedFileStatusTypes } from './props';
import 'react-circular-progressbar/dist/styles.css';
import { usePlatformContext } from '../../../../../../routes/platform.context';
import FileListView from './file-list-view';

const UploadDocuments: FC<UploadDocumentsProps> = ({
    listFiles,
    onDeleteFile,
    onUploadFile,
    options,
    skeletonRenderRows,
    acceptFilesInfo = true,
    uploadType = 'multiple',
    viewMode = false,
    canDelete = false,
    showMessage = true,
}) => {
    const { platform } = usePlatformContext();
    const { t } = useTranslation();

    const maxUploadSize = Number(platform?.fileSettings?.maxUploadSizeOrganization) * 1024 * 1024;

    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

    const getDropZoneSettings = useCallback(
        () =>
            ({
                multiple: true,
                maxSize: platform?.fileSettings?.maxUploadSizeOrganization
                    ? maxUploadSize ?? CONSTANTS.defaultFileMaxSize
                    : CONSTANTS.defaultFileMaxSize,
                accept:
                    platform?.fileSettings?.extensions.map((extension) =>
                        mime.getType(extension)
                    ) ?? '*',
                onDropRejected: (files) => {
                    addNotificationError({
                        title: t('info.invalid-files'),
                        message: t('info.files-were-not-accepted', {
                            value: String(files.length).padStart(2, '0'),
                        }),
                        duration: 5000,
                    });
                },
                ...options,
                disabled: options.disabled ?? (uploadType === 'single' && !!uploadedFiles.length),
            } as DropzoneOptions),
        [platform, uploadedFiles.length, uploadType, options]
    );

    const dropzoneProps = useDropzone(getDropZoneSettings());
    const { acceptedFiles } = dropzoneProps;

    const [fetchingFiles, setFetchingFiles] = useState(true);

    useEffect(() => {
        const getData = async () => {
            const files = await listFiles();
            setUploadedFiles(files || []);
            setFetchingFiles(false);
        };

        getData();
    }, []);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            const newFiles = acceptedFiles.map((file: File) => ({
                file,
                name: file.name,
                preview: URL.createObjectURL(file),
                id: nanoid(),
                status: UploadedFileStatusTypes.pending,
                progress: 0,
            }));

            if (uploadType === 'single') {
                return setUploadedFiles(newFiles);
            }

            setUploadedFiles(uploadedFiles.concat(newFiles));
        }
    }, [acceptedFiles]);

    const handleDeleteFile = async (uploadedFileId: string) => {
        const uploadedFile = uploadedFiles.find((file) => file.id === uploadedFileId);

        if (!uploadedFile) {
            return;
        }

        setUploadedFiles((prevState) => [
            ...prevState.filter((file) => file.id !== uploadedFile.id),
        ]);

        if (!uploadedFile?.originalId) {
            return;
        }

        onDeleteFile(uploadedFile.originalId);
    };

    const processFile = (uploadedFile: UploadedFile) => {
        onUploadFile(uploadedFile, (progress: number) => {
            setUploadedFiles((prevState) => [
                ...prevState.map((file) => {
                    if (file.id === uploadedFile.id) {
                        return {
                            ...uploadedFile,
                            progress,
                        };
                    }
                    return file;
                }),
            ]);
        })
            ?.then((response) => {
                timeout(
                    () =>
                        setUploadedFiles((prevState) => [
                            ...prevState.map((file) => {
                                if (file.id === uploadedFile.id) {
                                    return {
                                        ...uploadedFile,
                                        status: UploadedFileStatusTypes.accepted,
                                        originalId: response.data.id,
                                    };
                                }
                                return file;
                            }),
                        ]),
                    200
                );
            })
            .catch(() => {
                setUploadedFiles((prevState) => [
                    ...prevState.map((file) => {
                        if (file.id === uploadedFile.id) {
                            return {
                                ...uploadedFile,
                                status: UploadedFileStatusTypes.rejected,
                            };
                        }
                        return file;
                    }),
                ]);
            });
    };

    const handleClickSendFiles = () => {
        uploadedFiles
            .filter((uploadedFile) => uploadedFile.status === UploadedFileStatusTypes.pending)
            .forEach((uploadedFile: UploadedFile) => processFile(uploadedFile));
    };

    const showSendFilesButton =
        uploadedFiles.filter((uploadedFile) =>
            [UploadedFileStatusTypes.pending, UploadedFileStatusTypes.rejected].includes(
                uploadedFile.status
            )
        ).length > 0;

    const acceptedFormatsLabel = platform?.fileSettings.extensions
        .map((extension) => `.${extension}`)
        .join(', ');
    const maxUploadSizeLabel = `${platform?.fileSettings.maxUploadSizeOrganization} MB`;

    return (
        <Box width={1} mt={acceptFilesInfo ? 2 : 0}>
            <Grid container>
                {acceptFilesInfo && (
                    <Grid item xs={12}>
                        <Alert severity='warning'>
                            <Typography variant='body2'>
                                {t('info-send-files-requirements', {
                                    acceptedFormatsLabel,
                                    maxUploadSizeLabel,
                                })}
                            </Typography>
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box mt={acceptFilesInfo ? 2 : 0} className='container'>
                        {!viewMode && (
                            <Box mb={2}>
                                <UploadFile {...dropzoneProps} />
                            </Box>
                        )}
                        <FileListView
                            uploadedFiles={uploadedFiles}
                            fetchingFiles={fetchingFiles}
                            onDeleteFile={handleDeleteFile}
                            skeletonRenderRows={skeletonRenderRows}
                            canDelete={canDelete}
                            showMessage={showMessage}
                        />
                        {showSendFilesButton && (
                            <Box mt={1} display='flex' justifyContent='flex-end'>
                                <Button
                                    variant='contained'
                                    size='xsmall'
                                    onClick={handleClickSendFiles}
                                >
                                    {t('text.send-files')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UploadDocuments;
