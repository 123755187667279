import { doRequest, managerApiInstance } from '../api';
import { PlainGenericResponse } from '../interfaces/paginated.interface';
import { DoLoginTypeAccess } from './interfaces/auth.interface';

const authRequests = {
    doLoginTypeAccess: async (data: DoLoginTypeAccess) =>
        doRequest<PlainGenericResponse<{ token: string }>>(
            managerApiInstance.post(`/auth/doLoginTypeAccess`, data)
        ),
};

export { authRequests };
