enum DocumentBlockType {
    header = 0,
    footer = 1,
    content = 2,
    other = 3,
}

interface DocumentBlocks {
    _id: string;
    organizationId: number;
    type: DocumentBlockType;
    name: string;
    description?: string;
    content?: string;
    createdByUserId?: number;
    createdAt?: number;
    updatedAt?: number;
    updatedByUserId?: number;
}

export type { DocumentBlocks };
export { DocumentBlockType };
