import { useEffect } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { useMarketplaceContext } from 'modules/marketplace/context/marketplace.context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import { addNotificationApiError } from 'common/utils';
import { useQuery } from 'common/hooks/use-query.hook';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import HomePageView from './home-page-view';

const HomePage = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const {
        filters,
        marketplaceCart,
        onAddToCart,
        onRemoveFromCart,
        onItemQuantityChange,
        actionsLoading,
    } = useMarketplaceContext();

    const quantityOfItemsOnCart = marketplaceCart?.items.length;

    const {
        data: marketplaceItemsResponse,
        loading: loadingMarketplaceItems,
        ...paginationParams
    } = usePaginatedQuery(marketplaceRequests.listMarketplaceItems, filters);

    const { data: filterProcessesResponse, loading: loadingFilterProcesses } = useQuery(
        marketplaceRequests.listMarketplaceProcesses,
        {
            onError: (error) => {
                addNotificationApiError(error);
            },
        }
    );

    const { data: filterCategoriesResponse, loading: loadingFilterCategories } = useQuery(
        marketplaceRequests.listSupplyCategoriesByOrganization,
        {
            onError: (error) => {
                addNotificationApiError(error);
            },
        }
    );

    const filtersLoading = loadingFilterCategories || loadingFilterProcesses;

    useEffect(() => {
        setTitle(t('term.marketplace'));
    });

    return (
        <HomePageView
            filterProcesses={filterProcessesResponse?.data}
            filterCategories={filterCategoriesResponse?.data}
            filtersLoading={filtersLoading}
            quantityOfItemsOnCart={quantityOfItemsOnCart}
            marketplaceItems={marketplaceItemsResponse?.data}
            loadingMarketplaceItems={loadingMarketplaceItems}
            actionsLoading={actionsLoading}
            onAddToCart={onAddToCart}
            onRemoveFromCart={onRemoveFromCart}
            onItemQuantityChange={onItemQuantityChange}
            paginationParams={paginationParams}
        />
    );
};

export default HomePage;
