import {
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    MenuItem,
} from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { userRequests } from 'clients/manager/user.requests';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import HelpIcon from '@material-ui/icons/Help';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import { UserLanguages } from 'common/interfaces/user.interface';
import { usePlatformContext } from 'routes/platform.context';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import { CONSTANTS } from 'common/constants';
import { InfoIcon } from 'common/icons';
import { SignUpContext } from '../context/SignUpContext';

const TaxpayerIdentificationNumber = () => {
    const { newUser, setNewUser, setCurrentStep } = useContext(SignUpContext);
    const { platform } = usePlatformContext();

    const [tinExist, setTinExist] = useState(false);
    const [country, setCountry] = useState(CONSTANTS.ibgeCodes.BRAZIL);
    const [loading, setLoading] = useState<boolean>(false);
    const [disableText, setDisableText] = useState(false);
    const [invalidTinText, setInvalidTinText] = useState(false);

    const {
        t,
        i18n: { language },
    } = useTranslation();

    const { IBGEcountries } = useIBGECountries();

    const privacyPolicyNoticeResumeHTML =
        platform?.generalSettings?.privacyPolicyNotice?.language?.[language ?? 'pt-BR'];

    const onSubmit = async ({ tin, country }) => {
        setTinExist(false);

        const tinFormated = tin.replaceAll('.', '').replace('-', '');
        try {
            setLoading(true);
            const result = await userRequests.checkUserExist({
                cpf: tinFormated,
                countryCode: country,
            });

            if (result.ok === true) {
                setTinExist(true);
            } else {
                ReactDOM.unstable_batchedUpdates(() => {
                    setNewUser((prevNewUser: any) => ({
                        ...prevNewUser,
                        cpf: tinFormated,
                        country,
                        language:
                            country === CONSTANTS.ibgeCodes.BRAZIL
                                ? UserLanguages['pt-BR']
                                : UserLanguages.en,
                    }));
                    setCurrentStep(1);
                    setTinExist(false);
                });
            }
            setLoading(false);
        } catch (e: any) {
            setInvalidTinText(true);
            setLoading(false);
        }
    };

    const form1 = useFormik({
        initialValues: {
            tin: newUser?.cpf,
            country,
        },
        validationSchema: yup.object({
            tin:
                country === CONSTANTS.ibgeCodes.BRAZIL
                    ? yup
                          .string()
                          .required(t('please-fill-in-the-data', { ns: 'validation' }))
                          .min(
                              11,
                              t('the-CPF-must-contain-at-least-11-digits', { ns: 'validation' })
                          )
                    : yup.string().required(t('please-fill-in-the-data', { ns: 'validation' })),
            country: yup.string().required(t('please-fill-in-the-data', { ns: 'validation' })),
        }),
        onSubmit,
    });

    useEffect(() => {
        if (tinExist) {
            setDisableText(true);
            setTimeout(() => {
                setDisableText(false);
            }, 2000);
        }
    }, [tinExist]);

    const countryOnChange = (e) => {
        if (e.target.value === CONSTANTS.ibgeCodes.BRAZIL) {
            form1.setFieldValue('tin', form1.values.tin?.slice(0, 11));
        }
        setCountry(e.target.value);
        setLoading(false);
        setInvalidTinText(false);
        form1.setFieldValue('country', e.target.value);
    };
    return (
        <Grid item className='step-1'>
            <Typography variant='body2'>
                {t('sign-up.pages.stepps.info-part1')}{' '}
                <strong>{t('sign-up.pages.stepps.info-part2')}</strong>{' '}
                {t('sign-up.pages.stepps.info-part3')}
            </Typography>
            <form onSubmit={form1.handleSubmit}>
                <div className='first-item'>
                    <Grid item xs={4}>
                        <TextField
                            select
                            label={t('term.country')}
                            fullWidth
                            name='country'
                            value={form1.values.country}
                            onChange={countryOnChange}
                            error={form1.touched.country && !!form1.errors.country}
                            helperText={form1.touched.country && form1.errors.country}
                        >
                            {IBGEcountries.map(({ code, name }) => (
                                <MenuItem key={code} value={code}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={8}>
                        <IdentificationNumberTextField
                            identificationNumber={form1.values.tin}
                            country={form1.values.country}
                            fieldName='tin'
                            type='cpf'
                            InputProps={{
                                endAdornment: loading && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                            {...form1}
                        />
                        {tinExist && disableText && (
                            <Typography style={{ paddingTop: 5 }} color='secondary'>
                                {t('sign-up.pages.stepps.info-cpf-exist')}
                            </Typography>
                        )}
                        {invalidTinText && (
                            <Typography style={{ paddingTop: 5 }} color='error'>
                                {t(
                                    `sign-up.pages.stepps.error-${
                                        country === CONSTANTS.ibgeCodes.BRAZIL ? 'cpf' : 'tin'
                                    }-invalid`
                                )}
                            </Typography>
                        )}
                    </Grid>
                </div>

                <div>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={form1.isSubmitting}
                    >
                        {t('term.advance')}
                    </Button>
                </div>
            </form>
            <Grid className='consents-content' container direction='column' spacing={1}>
                <Grid item>
                    <Grid container alignItems='center'>
                        <Typography variant='body2' gutterBottom>
                            {t('sign-up.pages.stepps.info-get-data')}
                        </Typography>
                        {platform?.generalSettings?.privacyPolicyNotice.showTextFormat ? (
                            <Typography
                                variant='caption'
                                style={{ color: 'gray' }}
                                dangerouslySetInnerHTML={{
                                    __html: privacyPolicyNoticeResumeHTML,
                                }}
                            />
                        ) : (
                            <Tooltip
                                interactive
                                title={
                                    <span
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: privacyPolicyNoticeResumeHTML,
                                        }}
                                    />
                                }
                                aria-label='info'
                            >
                                <div>
                                    <InfoIcon color='secondary' />
                                </div>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' alignItems='center'>
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.help-add-info')}
                        </Typography>
                        <IconButton
                            className='icon-btn'
                            onClick={() => {
                                window.open(
                                    'https://licitardigital.tawk.help/article/como-cadastrar-um-cidadao',
                                    '_blank'
                                );
                            }}
                            color='secondary'
                            aria-label='Manual de cadastro'
                            title={t('sign-up.pages.stepps.title-click-here')}
                        >
                            <HelpIcon color='primary' />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TaxpayerIdentificationNumber;
