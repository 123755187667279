import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import Breadcrumb from 'common/components/base/breadcrumb';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';

import { Skeleton } from '@material-ui/lab';
import { TablePagination } from '@material-ui/core';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import OrderCard from './components/order-card';
import { useStyles } from './order-page-style';

interface OrderPageViewProps {
    orders: OrderResponse[];
    loading?: boolean;
    paginationParams: Omit<
        UsePaginatedQueryResponse<OrderResponse>,
        'data' | 'loading' | 'setData'
    >;
}

const ROWS_PER_PAGE_OPTIONS = [5, 10];

const OrderPageView: React.FC<OrderPageViewProps> = ({
    orders,
    loading = false,
    paginationParams,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const history = useHistory();

    const { totalCount, page, limit, setLimit, setPage } = paginationParams;

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='mercado/pedidos'
                paths={{
                    mercado: {
                        value: t('term.marketplace'),
                        onClick: () => history.replace('/mercado'),
                    },
                    pedidos: { value: t('term.orders'), disabled: true },
                }}
            />
            <section className={styles.container}>
                {orders.map((order) => (
                    <OrderCard key={order.id} order={order} />
                ))}
                {loading && (
                    <>
                        <Skeleton className={styles.skeleton} />
                        <Skeleton className={styles.skeleton} />
                    </>
                )}
            </section>
            <TablePagination
                page={page}
                count={totalCount}
                onPageChange={(_e, page) => setPage(page)}
                rowsPerPage={limit || ROWS_PER_PAGE_OPTIONS[0]}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onRowsPerPageChange={(event) =>
                    setLimit(Number(event.target.value) ?? ROWS_PER_PAGE_OPTIONS[0])
                }
                component='div'
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </PageWrapper>
    );
};

export default OrderPageView;
