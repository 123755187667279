import { IconButton, Typography } from '@material-ui/core';
import { apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router';
import { usePlatformContext } from 'routes/platform.context';
import { CONSTANTS } from 'common/constants';
import { usePageContext } from 'common/components/base/pages/context';
import LanguagesButtons from './components/languages-buttons';
import MobileMenuDropDown from './components/mobile-menu-dropdown';
import {
    AuthButtonsContainer,
    Container,
    ContentContainer,
    HeaderAuthButton,
    LogoContainer,
    StyledAppBar,
} from './styles';

const UnauthenticatedHeader = () => {
    const { t } = useTranslation();
    const { title } = usePageContext();
    const { platform } = usePlatformContext();
    const history = useHistory();
    const { pathname } = useLocation();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const [mobile, setMobile] = useState(window.innerWidth < CONSTANTS.mobileWidth);

    const resize = useCallback(() => {
        if (!mobile && window.innerWidth < CONSTANTS.mobileWidth) setMobile(true);
        else if (mobile && window.innerWidth > CONSTANTS.mobileWidth) setMobile(false);
    }, [mobile]);

    UseWindowEvent('resize', () => resize(), [resize]);

    const defaultImageLogo = platform?.urlOriginalLogo;

    const showSignupCitizen = !platform?.generalSettings?.general?.hideSignupCitizen;
    const isCognitoEnabled = Boolean(platform?.generalSettings?.cognito?.enableModule);

    const handleSignUp = () => history.push('/cadastro');
    const handleSearch = () => history.push('/pesquisa');
    const handleInfo = () => history.push('/avisos-informacoes');
    const handleSignIn = () => {
        if (isCognitoEnabled) {
            return history.push('/login');
        }

        apiRedirectTo(backofficeRedirectUrl, '/login', true);
    };

    return (
        <Container>
            <StyledAppBar position='static'>
                <LogoContainer>
                    <img src={defaultImageLogo} alt='logo' />
                </LogoContainer>
                <ContentContainer>
                    <Typography
                        id='titleApplication'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        variant='h6'
                    >
                        {title}
                    </Typography>
                </ContentContainer>
                {mobile ? (
                    <MobileMenuDropDown
                        handleSignUp={showSignupCitizen ? handleSignUp : undefined}
                        handleSignIn={handleSignIn}
                        handleInfo={handleInfo}
                    />
                ) : (
                    <>
                        <IconButton onClick={handleInfo}>
                            <HiOutlineNewspaper size={26} color='var(--icon-color)' />
                        </IconButton>
                        <LanguagesButtons />
                        <AuthButtonsContainer>
                            {showSignupCitizen && !pathname.includes('/cadastro') && (
                                <HeaderAuthButton onClick={handleSignUp}>
                                    {t('auth.general.sign-up')}
                                </HeaderAuthButton>
                            )}
                            {pathname.includes('/cadastro') && (
                                <HeaderAuthButton onClick={handleSearch}>
                                    {t('shared.pages.menu-header.option.search')}
                                </HeaderAuthButton>
                            )}
                            <HeaderAuthButton onClick={handleSignIn}>
                                {t('auth.general.sign-in')}
                            </HeaderAuthButton>
                        </AuthButtonsContainer>
                    </>
                )}
            </StyledAppBar>
        </Container>
    );
};

export default UnauthenticatedHeader;
