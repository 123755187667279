import { CircularProgress, Typography } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { formatCpfOrCnpj } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { CHECKOUT } from '../../CheckoutConstants';
import CheckoutPaper from '../../components/CheckoutPaper';
import useStyles from './ConfirmationStyle';

export default function ConfirmationView({
    planTitle,
    planDetails,
    benefits,
    paymentTitle,
    paymentDetails,
    hasProvider,
    provider,
    processPayment,
    loading,
    changeStringToAsteriskBetween,
    paymentCondition,
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    return loading || !hasProvider ? (
        <div className={classes.loader}>
            <CircularProgress color='secondary' size={60} />
        </div>
    ) : (
        <CheckoutPaper title={t('bidding.checkout.confirmation.title')}>
            <div className={classes.boxBorder}>
                <div className={classes.provider}>
                    <div className={classes.boxGrey}>
                        <Typography variant='h5'>
                            <strong>{t('bidding.checkout.confirmation.provider')}</strong>
                        </Typography>
                    </div>
                    <div className={classes.planText}>
                        <Typography>
                            <strong>{t('bidding.checkout.confirmation.company_name')}</strong>
                            {provider.companyName}
                        </Typography>
                        <Typography>
                            <strong>{t('bidding.checkout.confirmation.cnpj')}</strong>
                            {formatCpfOrCnpj(provider.docNumber)}
                        </Typography>
                        <Typography>
                            <strong>{t('bidding.checkout.confirmation.address')}</strong>
                            {`${provider.state}, ${provider.district}, nº ${provider.numberPlace}`}
                        </Typography>
                    </div>
                </div>

                <div className={classes.boxGrey}>
                    <Typography variant='h5'>
                        <strong>{planTitle}</strong>
                    </Typography>
                </div>
                <div className={classes.planText}>
                    {benefits.map((detail) => (
                        <div key={`${detail.title}`} className={classes.boxBenefit}>
                            <div className={classes.iconBenefit}>
                                <Done />
                            </div>
                            <Typography>{detail.description}</Typography>
                        </div>
                    ))}
                </div>
                <div className={classes.boxGrey}>
                    <Typography variant='h5'>
                        <strong>
                            {t('bidding.checkout.confirmation.payment_method')}
                            {paymentCondition === 'pós-pago' ? 'pós-pago' : paymentTitle}
                        </strong>
                    </Typography>
                </div>
                <div className={classes.planText}>
                    {paymentCondition === 'pós-pago' ? (
                        <>
                            <Typography>
                                {t('bidding.benefits.aviso_bloqueio_caso_nao_pagamento')}
                            </Typography>
                        </>
                    ) : paymentTitle === CHECKOUT.CREDIT_CARD ? (
                        <>
                            <Typography>
                                {t('bidding.checkout.confirmation.credit_card.owner')}
                                {paymentDetails.name}
                            </Typography>
                            <Typography>
                                {t('bidding.checkout.confirmation.credit_card.number')}
                                {changeStringToAsteriskBetween(paymentDetails.number, 1, 14)}
                            </Typography>
                            <Typography>
                                {t('bidding.checkout.confirmation.credit_card.due_date')}
                                {changeStringToAsteriskBetween(paymentDetails.expiry, 1, 2)}
                            </Typography>
                            <Typography>
                                {t('bidding.checkout.confirmation.credit_card.cvv')}
                                {changeStringToAsteriskBetween(paymentDetails.cvc, 1, 3)}
                            </Typography>
                            <Typography>
                                {t('bidding.checkout.confirmation.credit_card.text')}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography>
                                {t('bidding.checkout.confirmation.invoice.due_date')}
                            </Typography>
                            <Typography>
                                {t('bidding.checkout.confirmation.invoice.institute')}
                            </Typography>
                            <Typography>
                                {t('bidding.checkout.confirmation.invoice.text')}
                            </Typography>
                        </>
                    )}
                    <Typography variant='h5'>
                        <strong>
                            {t('bidding.checkout.confirmation.total')}
                            {planDetails.priceDetails.priceType === 'monetary'
                                ? `R$ ${planDetails.billing.value}`
                                : `${planDetails.billing.value}%`}
                        </strong>
                    </Typography>
                </div>
            </div>

            <div className={classes.boxButton}>
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={() => processPayment()}
                >
                    {paymentCondition === 'pós-pago'
                        ? t('term.confirm')
                        : t('bidding.checkout.confirmation.process_payment')}
                </Button>
            </div>
        </CheckoutPaper>
    );
}
