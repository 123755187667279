import { FC, useEffect, useState } from 'react';
import { styled } from 'common/theme/styled';
import { AsyncSelect, Select } from '@licitar/ui-kit';
import { debounce, orderBy } from 'lodash';
import { regionsRequests } from 'clients/price-base/regions.requests';
import { useTranslation } from 'react-i18next';
import { addNotificationWarning } from 'common/utils';
import { IAdvancedFilters } from 'modules/priceBank/context/interfaces';

const RegionOptions = styled.div`
    font-size: 14px;
    margin: 0 0 4px 0;
`;

const RegionOption = styled.span<{ selected: boolean }>`
    color: ${(props) => props.theme.colors.tertiary};
    cursor: pointer;

    ${(props) =>
        props.selected &&
        `
        text-decoration: underline;
    `}
`;

const ProviderCities = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

interface RegionFilterProps {
    advancedFilters: IAdvancedFilters | undefined;
    handleChange: (field: string, value: any) => void;
    handleFieldChanges: (fields: Partial<IAdvancedFilters>) => void;
}

enum RegionTypes {
    uf = 'uf',
    mesoRegion = 'mesoRegion',
    microRegion = 'microRegion',
    city = 'city',
}

interface SelectOption {
    label: string;
    value: string;
}

const RegionFilter: FC<RegionFilterProps> = ({
    advancedFilters,
    handleChange,
    handleFieldChanges,
}) => {
    const { t } = useTranslation();

    const defineInitialType = () => {
        if (advancedFilters?.providerUf) {
            return RegionTypes.uf;
        }
        if (advancedFilters?.providerCities) {
            return RegionTypes.city;
        }
        if (advancedFilters?.microRegion) {
            return RegionTypes.microRegion;
        }
        if (advancedFilters?.mesoRegion) {
            return RegionTypes.mesoRegion;
        }
        return RegionTypes.city;
    };

    const [type, setType] = useState<RegionTypes>(defineInitialType());
    const [ufList, setUfList] = useState<SelectOption[]>([]);

    const getLabel = (value: RegionTypes) => {
        switch (value) {
            case RegionTypes.uf:
                return t('term.state');

            case RegionTypes.microRegion:
                return t('term.micro-region');

            case RegionTypes.mesoRegion:
                return t('term.meso-region');

            case RegionTypes.city:
                return t('term.city');

            default:
                return '';
        }
    };

    const getUf = async () => {
        try {
            return await regionsRequests.getRegionUf();
        } catch (error) {
            addNotificationWarning({
                title: t('term.err'),
                message: t('mkp.search.not-found-state'),
            });
        }
    };

    const replaceAndSetUfList = async () => {
        const ufs = await getUf();
        setUfList(
            orderBy(ufs ?? [], 'uf', 'asc').map((region) => ({
                label: region.uf.toUpperCase(),
                value: region.uf,
            })) ?? []
        );
    };

    useEffect(() => {
        if (type === RegionTypes.uf && ufList.length === 0) {
            replaceAndSetUfList();
        }
    }, [type]);

    const getCities = (value: string, callback: Function) => {
        regionsRequests
            .getRegionCities(value)
            .then((data) => {
                callback(
                    orderBy(data, 'name', 'asc').map((region) => ({
                        label: region.name,
                        value: region.name,
                    }))
                );
            })
            .catch(() => {
                addNotificationWarning({
                    title: t('term.err'),
                    message: t('mkp.search.not-found-city'),
                });
            });
    };

    const getMicroRegions = (value: string, callback: Function) => {
        regionsRequests
            .getMicroRegion(value)
            .then((data) => {
                callback(
                    orderBy(data, 'microRegion', 'asc').map((region) => ({
                        label: region.microRegion,
                        value: region.microRegion,
                    }))
                );
            })
            .catch(() => {
                addNotificationWarning({
                    title: t('term.err'),
                    message: t('mkp.search.not-found-micro-region'),
                });
            });
    };

    const getMesoRegions = (value: string, callback: Function) => {
        regionsRequests
            .getMesoRegion(value)
            .then((data) => {
                callback(
                    orderBy(data, 'mesoRegion', 'asc').map((region) => ({
                        label: region.mesoRegion,
                        value: region.mesoRegion,
                    }))
                );
            })
            .catch(() => {
                addNotificationWarning({
                    title: t('term.err'),
                    message: t('mkp.search.not-found-meso-region'),
                });
            });
    };

    const getData = (inputValue: string, callback: Function) => {
        switch (type) {
            case RegionTypes.city:
                return getCities(inputValue, callback);

            case RegionTypes.microRegion:
                return getMicroRegions(inputValue, callback);

            case RegionTypes.mesoRegion:
                return getMesoRegions(inputValue, callback);

            default:
                break;
        }
    };

    const getRegions = debounce(getData, 600);

    const handleChangeInput = (currType: RegionTypes) => {
        handleFieldChanges({
            providerUf: undefined,
            providerCities: undefined,
            microRegion: undefined,
            mesoRegion: undefined,
        });
        setType(currType);
    };

    const replaceUfToOption = (uf?: string): SelectOption | undefined => {
        if (!uf) {
            return undefined;
        }

        return {
            label: uf?.toUpperCase(),
            value: uf,
        };
    };

    const replaceCitiesToOption = (cities: string[]): SelectOption[] =>
        cities.map((city) => ({
            label: city,
            value: city,
        }));

    const replaceToOption = (string?: string): SelectOption | undefined => {
        if (!string) {
            return undefined;
        }

        return {
            label: string,
            value: string,
        };
    };

    return (
        <>
            <RegionOptions>
                <RegionOption
                    onClick={() => handleChangeInput(RegionTypes.city)}
                    selected={type === RegionTypes.city}
                >
                    {t('term.city').toLowerCase()}
                </RegionOption>
                {' / '}
                <RegionOption
                    onClick={() => handleChangeInput(RegionTypes.uf)}
                    selected={type === RegionTypes.uf}
                >
                    {t('term.state').toLowerCase()}
                </RegionOption>
                {' / '}
                <RegionOption
                    onClick={() => handleChangeInput(RegionTypes.microRegion)}
                    selected={type === RegionTypes.microRegion}
                >
                    {t('term.micro-region2')}
                </RegionOption>
                {' / '}
                <RegionOption
                    onClick={() => handleChangeInput(RegionTypes.mesoRegion)}
                    selected={type === RegionTypes.mesoRegion}
                >
                    {t('term.meso-region2')}
                </RegionOption>
            </RegionOptions>
            <ProviderCities>
                {type === RegionTypes.uf && (
                    <Select
                        name='providerUf'
                        options={ufList}
                        isMulti={false}
                        value={replaceUfToOption(advancedFilters?.providerUf)}
                        label={getLabel(type)}
                        onChange={(option: SelectOption) => {
                            if (!option) {
                                return handleChange('providerUf', undefined);
                            }
                            handleChange('providerUf', option.value);
                        }}
                    />
                )}
                {type === RegionTypes.city && (
                    <AsyncSelect
                        name='providerCities'
                        label={getLabel(type)}
                        isMulti
                        loadOptions={getRegions}
                        cacheOptions
                        value={replaceCitiesToOption(advancedFilters?.providerCities ?? [])}
                        onChange={(options: SelectOption[]) => {
                            if (!options || options?.length === 0) {
                                return handleChange('providerCities', undefined);
                            }
                            handleChange(
                                'providerCities',
                                options.map((option) => option.value)
                            );
                        }}
                    />
                )}
                {type === RegionTypes.microRegion && (
                    <AsyncSelect
                        name='microRegion'
                        label={getLabel(type)}
                        isMulti={false}
                        loadOptions={getRegions}
                        cacheOptions
                        value={replaceToOption(advancedFilters?.microRegion)}
                        onChange={(option: SelectOption) => {
                            if (!option) {
                                return handleChange('microRegion', undefined);
                            }
                            handleChange('microRegion', option.value);
                        }}
                    />
                )}
                {type === RegionTypes.mesoRegion && (
                    <AsyncSelect
                        name='mesoRegion'
                        label={getLabel(type)}
                        isMulti={false}
                        loadOptions={getRegions}
                        cacheOptions
                        value={replaceToOption(advancedFilters?.mesoRegion)}
                        onChange={(option: SelectOption) => {
                            if (!option) {
                                return handleChange('mesoRegion', undefined);
                            }
                            handleChange('mesoRegion', option.value);
                        }}
                    />
                )}
            </ProviderCities>
        </>
    );
};

export default RegionFilter;
