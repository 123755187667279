import { DocumentVariables } from 'clients/manager/interfaces/document-variables.interface';
import { VARIABLE_ELEMENT_CLASSNAME, VARIABLE_TRIGGER } from 'modules/editor/components/editor';
import { ProcessVariable, DefaultVariable } from 'modules/editor/interfaces/variables.interface';

export type PendentVariables = {
    [key: string]: string[];
};

export const variablesToReplace = (
    html: string,
    variables: {
        defaults: DefaultVariable[];
        customs: DocumentVariables[];
        auction: ProcessVariable[];
    }
): PendentVariables => {
    const findedVariables: PendentVariables = { defaults: [], customs: [], auction: [] };
    const rawVariables: string[] = [];

    html?.replace(
        new RegExp(`<span class="${VARIABLE_ELEMENT_CLASSNAME}"[^>]*>[^>]*</span>`, 'g'),
        (match: any) => {
            const el: any = document.createElement('div');
            el.innerHTML = match;

            if (el.firstChild) {
                const value = el.firstChild.innerHTML?.replace(VARIABLE_TRIGGER, '');

                if (!value) {
                    return;
                }
                rawVariables.push(value?.trim() ?? value);
            }
            return match;
        }
    );

    Object.keys(variables).forEach((key) => {
        const variablesSet = new Set<string>();

        (variables[key] ?? []).forEach(({ name: variableName }) => {
            if (rawVariables.includes(variableName)) {
                variablesSet.add(variableName);
            }
        });
        findedVariables[key].push(...Array.from(variablesSet));
    });

    return findedVariables;
};
