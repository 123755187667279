import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;

    @media (max-width: 1024px) {
        padding-top: 70px;
        overflow-y: auto;
    }
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: calc(100vh - 250px);
    margin: 0 auto;
    gap: 35px;

    .MuiTextField-root {
        width: 100%;
    }

    .terms {
        padding: 0;

        button {
            margin: 0;
        }
    }

    .step-1 form .first-item {
        display: flex;
        gap: 10px;
    }

    .step-1 .MuiTextField-root {
        max-width: 370px;
    }

    .MuiFilledInput-input {
        padding: 27px 12px 10px 12px;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        gap: 10px;
        height: auto;
    }

    .side-image {
        display: block;
        width: 100%;
        max-width: 650px;
    }

    h3 {
        padding-bottom: 15px;
    }

    form {
        margin-top: 20px;
        margin-bottom: 20px;

        @media (max-width: 1024px) {
            margin-bottom: 0;
        }
    }

    .icon-btn {
        margin: 10px 0;
        padding: 0 3px;
    }

    button {
        margin: 20px 0;
        span {
            font-family: 'Roboto';
        }
    }

    .MuiFormHelperText-root.Mui-error {
        margin-bottom: 0;
        color: #f44336;
    }

    .MuiFormHelperText-contained {
        margin-left: 0;
    }

    .consents-content {
        p {
            margin-right: 5px;
        }
    }

    .step-2 form {
        display: flex;
        gap: 25px;
        flex-direction: column;
    }

    .step-2 .errors-message {
        display: flex;
        gap: 52px;
        margin-top: -20px;

        p {
            margin-bottom: 5px;
        }
    }

    .step-2 form .first-item {
        display: flex;
        gap: 10px;
    }

    @media (max-width: 1024px) {
        .step-2 form .fist-item {
            flex-direction: column;
            gap: 25px;
        }
    }

    .step-3 form {
        display: flex;
        gap: 25px;
        flex-direction: column;

        .first-item {
            display: flex;
            gap: 25px;
            flex-direction: column;

            .MuiTextField-root {
                max-width: 300px;
            }
        }

        .consent {
            display: flex;
            align-items: center;

            .MuiFormControlLabel-root {
                margin-right: 10px;
            }
        }

        .formContent {
            display: flex;
            gap: 30px;
        }

        .validation {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 8px;
            .validation-false {
                color: #dddddd;
            }
            .validation-true {
                color: #02ae51;
            }
        }
    }
`;
