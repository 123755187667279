import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { TypeOfBenefitValue } from '../../clients/manager/interfaces/auction-notice-lot.interface';
import {
    AuctionNotice,
    IntegrationTypeFile,
    TypeValueBidTypes,
} from '../../clients/manager/interfaces/auction-notice.interface';
import { AuctionLotFormValues } from './context/lots.form';
import { inInternalPhase } from './process-utils';

const stagesEnabledToEditUntilDispute = [
    BiddingStageEnum.draft,
    BiddingStageEnum.published,
    BiddingStageEnum.dispute,
];

const processActions = {
    canEditUntilDispute: (biddingStageId?: number): boolean =>
        !!(biddingStageId && stagesEnabledToEditUntilDispute.includes(biddingStageId)),
    cannotEditUntilDispute: (process?: AuctionNotice): boolean => {
        if (!process?.biddingStageId) return false;
        if (stagesEnabledToEditUntilDispute.includes(process.biddingStageId)) {
            return false;
        }
        return true;
    },
    // regras para deletar um lote
    canDeleteLot: (process?: AuctionNotice): boolean =>
        inInternalPhase(process?.biddingStageId) &&
        [IntegrationTypeFile.excel, IntegrationTypeFile.manual].includes(
            process?.integrationFileModel as IntegrationTypeFile
        ),
    // regras para deletar itens de um lote
    // @ só pode deletar tipo de importação excel ou manual
    canDeleteLotItems: (process?: AuctionNotice): boolean =>
        inInternalPhase(process?.biddingStageId) &&
        [IntegrationTypeFile.excel, IntegrationTypeFile.manual].includes(
            process?.integrationFileModel as IntegrationTypeFile
        ),
    // regras para criar um lote
    canCreateLot: (process?: AuctionNotice): boolean =>
        inInternalPhase(process?.biddingStageId) &&
        [IntegrationTypeFile.excel, IntegrationTypeFile.manual].includes(
            process?.integrationFileModel as IntegrationTypeFile
        ),
    // regras para editar campos de um lote (sem itens)
    canEditLot: (biddingStageId?: number): boolean => inInternalPhase(biddingStageId),
    // regras para criar itens em um lote
    canCreateLotItem: (process?: AuctionNotice): boolean =>
        inInternalPhase(process?.biddingStageId) &&
        [IntegrationTypeFile.excel, IntegrationTypeFile.manual].includes(
            process?.integrationFileModel as IntegrationTypeFile
        ),
    // regras para editar itens de um lote,
    // @ só pode editar se for manual ou excel, outros tipos é bloqueado
    canEditLotItems: (process: AuctionNotice | undefined): boolean =>
        inInternalPhase(process?.biddingStageId) &&
        !![IntegrationTypeFile.excel, IntegrationTypeFile.manual].includes(
            process?.integrationFileModel as IntegrationTypeFile
        ),
    visibleMasterQuotaOption: (process: AuctionNotice | undefined): boolean =>
        process?.typeValueBid === TypeValueBidTypes.unit,
    // se o campo cota reservada irá aparecer nos selects de tipo de beneficio
    visibleReservedQuotaOption: (process: AuctionNotice | undefined): boolean =>
        !!process?.typeValueBid && inInternalPhase(process?.biddingStageId),
    inDraft: (biddingStageId: number): boolean => biddingStageId === BiddingStageEnum.draft,
    notInDraft: (biddingStageId: number): boolean => biddingStageId > BiddingStageEnum.draft,
};

const lotActions = {
    canEditLotQuota: (lot: AuctionLotFormValues, lots?: AuctionLotFormValues[]) => {
        if (lot.typeOfBenefit === TypeOfBenefitValue.reservedQuota && !!lot.quotaId) {
            const foundQuotaLot = lots?.find((formLot) => formLot.id === lot.quotaId);

            if (foundQuotaLot?.typeOfBenefit === TypeOfBenefitValue.masterQuota) {
                return false;
            }
        }

        return true;
    },
    canCreateLotQuota: (
        lot: AuctionLotFormValues,
        isLotValid: boolean,
        process: AuctionNotice | undefined
    ) =>
        isLotValid &&
        lot.typeOfBenefit === TypeOfBenefitValue.masterQuota &&
        (process?.typeValueBid === TypeValueBidTypes.unit ||
            (lot.itemsKits && lot.itemsKits >= 4)) &&
        !lot.hasQuotaCreated &&
        !lot.quotaId &&
        // só habilita o botão de criar e salvar cota quando não é importação
        [IntegrationTypeFile.manual].includes(process?.integrationFileModel as IntegrationTypeFile),
};

export { processActions, lotActions };
