import { templateRequests } from 'clients/manager/documents/templates.requests';
import { VARIABLE_ELEMENT_CLASSNAME, VARIABLE_TRIGGER } from 'modules/editor/components/editor';

interface AuctionVariableValues {
    [key: string]: any;
}

const getDocumentVariables = (html: string) => {
    const macthes = html?.match(
        new RegExp(new RegExp(`<span class="${VARIABLE_ELEMENT_CLASSNAME}"[^>]*>[^>]*</span>`, 'g'))
    );

    const variablesSet = new Set<string>();

    macthes?.forEach((match: any) => {
        const el: any = document.createElement('div');
        el.innerHTML = match;

        if (!el.firstChild) {
            return;
        }

        const value = el.firstChild.innerHTML?.replace(VARIABLE_TRIGGER, '') ?? '';
        if (!value) {
            return;
        }
        variablesSet.add(value?.trim() ?? value);
    });

    return Array.from(variablesSet);
};

const getReplacedVariables = async (
    auctionId: number,
    variables: string[],
    providerId?: number
) => {
    try {
        const { data: response } = await templateRequests.getReplacedVariables({
            auctionId,
            providerId,
            variables,
        });

        return response.replacedVariables ?? {};
    } catch (error) {
        return {};
    }
};

export { getReplacedVariables, getDocumentVariables };
export type { AuctionVariableValues };
