const TopLine = (data: any) =>
    data.doc.line(data.cursor.x, data.cursor.y, data.cursor.x + data.cell.width, data.cursor.y);

const BottomLine = (data: any) =>
    data.doc.line(
        data.cursor.x,
        data.cursor.y + data.cell.height,
        data.cursor.x + data.cell.width,
        data.cursor.y + data.cell.height
    );

const TopBottomRightLine = (data: any) =>
    data.doc.line(
        data.cursor.x + data.cell.width,
        data.cursor.y,
        data.cursor.x + data.cell.width,
        data.cursor.y + data.cell.height
    );

const TopBottomLeftLine = (data: any) =>
    data.doc.line(data.cursor.x, data.cursor.y, data.cursor.x, data.cursor.y + data.cell.height);

export { TopBottomLeftLine, TopBottomRightLine, BottomLine, TopLine };
