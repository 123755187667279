import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@material-ui/core';
import { Button, ButtonProps } from 'common/components';
import ReactDOM from 'react-dom';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { addNotificationApiError, addNotificationSuccess, addNotificationInfo } from 'common/utils';
import { PublicSessionState } from 'common/enums/public-session-state.enum';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { auctionTypeRulesRequests } from 'clients/manager/auction-type-rules.requests';
import { useProcessFormContext } from '../../context/process-form.context';

interface SessionTitlesParams {
    titleButton?: string;
    titleDialog?: string;
}

const ButtonProcessSession = (props?: ButtonProps) => {
    const { process, processForm, auctionTypeRules, setAuctionTypeRules } = useProcessFormContext();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickEnableSession = async () => {
        if (!process) {
            return;
        }

        if (processForm?.values?.sessionOpened === PublicSessionState.closed) {
            addNotificationInfo({
                title: t('Info.session-closed-process-title'),
                message: t('Info.session-requires-open-session'),
            });
            return;
        }

        try {
            const isCloseOrReopenSession = false;
            const response = await auctionNoticeRequests.manageSessionProcess(
                process.id,
                isCloseOrReopenSession
            );
            if (response?.data?.ok) {
                processForm?.setFieldValue(
                    'sessionOpened',
                    Number(!processForm?.values.sessionOpened)
                );
                addNotificationSuccess();
                handleClose();
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const getAuctionTypeRules = useCallback(async () => {
        try {
            const response = await auctionTypeRulesRequests.listAuctionTypeRules();
            ReactDOM.unstable_batchedUpdates(() => {
                const currentAuctionRules = response.data.find(
                    (auctionTypeRule) => auctionTypeRule.id === processForm?.values.ruleId
                );

                if (currentAuctionRules) {
                    setAuctionTypeRules(currentAuctionRules);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [processForm?.values.ruleId]);

    useEffect(() => {
        if (processForm?.values.ruleId) {
            getAuctionTypeRules();
        }
    }, [processForm?.values.ruleId]);

    const handleClickCloseSession = async () => {
        if (!process) {
            return;
        }

        try {
            const isCloseOrReopenSession = true;
            const response = await auctionNoticeRequests.manageSessionProcess(
                process.id,
                isCloseOrReopenSession
            );
            if (response?.data?.ok) {
                processForm?.setFieldValue(
                    'sessionOpened',
                    Number(
                        processForm?.values?.sessionOpened === PublicSessionState.closed
                            ? PublicSessionState.enabled
                            : PublicSessionState.closed
                    )
                );
                addNotificationSuccess();
                handleClose();
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const getSessionParams = (isCloseOrReopenSession: boolean): SessionTitlesParams => {
        const titlesParams: SessionTitlesParams = {};
        const sessionOpened = processForm?.values?.sessionOpened;

        if (isCloseOrReopenSession) {
            if (sessionOpened === PublicSessionState.closed) {
                titlesParams.titleButton = t('info.session-restart');
                titlesParams.titleDialog = t('info.session-restart-process-title');
                return titlesParams;
            }
            if (
                sessionOpened === PublicSessionState.enabled ||
                sessionOpened === PublicSessionState.suspended
            ) {
                titlesParams.titleButton = t('info.session-close');
                titlesParams.titleDialog = t('info.session-close-process-title');
                return titlesParams;
            }
        }
        if (sessionOpened === PublicSessionState.enabled) {
            titlesParams.titleButton = t('info.session-suspend');
            titlesParams.titleDialog = t('info.session-suspend-process-title');
            return titlesParams;
        }
        if (
            sessionOpened === PublicSessionState.suspended ||
            sessionOpened === PublicSessionState.closed
        ) {
            titlesParams.titleButton = t('info.session-enable');
            titlesParams.titleDialog = t('info.session-enable-process-title');
            return titlesParams;
        }
        return titlesParams;
    };
    const enableOrSuspendSessionParams = getSessionParams(false);
    const closeOrReopenSessionParams = getSessionParams(true);

    if (
        !process ||
        !auctionTypeRules?.generalSettings?.processo?.habilitarGerenciamentoDeSessao ||
        (processForm && processForm.values?.biddingStageId < 9)
    ) {
        return null;
    }

    return (
        <>
            <Button
                {...props}
                color='primary'
                variant='contained'
                size='xsmall'
                aria-haspopup='true'
                title={t('term.session')}
                onClick={handleClick}
                endIcon={<ArrowDropDown />}
            >
                {t('term.session')}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <ConfirmDialog
                    title={enableOrSuspendSessionParams.titleDialog}
                    message={t('info.session-process-message')}
                    onConfirm={handleClickEnableSession}
                >
                    <MenuItem onClick={handleClose}>
                        {enableOrSuspendSessionParams.titleButton}
                    </MenuItem>
                </ConfirmDialog>
                <ConfirmDialog
                    title={closeOrReopenSessionParams.titleDialog}
                    message={t('info.session-process-message')}
                    onConfirm={handleClickCloseSession}
                >
                    <MenuItem onClick={handleClose}>
                        {closeOrReopenSessionParams.titleButton}
                    </MenuItem>
                </ConfirmDialog>
            </Menu>
        </>
    );
};

export default memo(ButtonProcessSession);
