import { TypeUser } from '../../interfaces/user.interface';

const internalRedirect = (
    backofficeRedirectUrl: string,
    to: string,
    currentAccessType?: TypeUser,
    custom?: boolean,
    newTab?: boolean
) => {
    const getUrl = (url: string) => {
        if (currentAccessType) {
            // envia para o php qual context de menu o usuário estava
            url = `${url}?method=${currentAccessType}`;
        }

        return url;
    };

    if (custom) {
        if (newTab) {
            const win = window.open(to, '_blank');
            return win?.focus();
        }
        window.location.href = to;
        return;
    }

    const url = getUrl(`${backofficeRedirectUrl}/${to}`);

    if (newTab) {
        const win = window.open(url, '_blank');
        return win?.focus();
    }

    window.location.replace(url);
};

export { internalRedirect };
