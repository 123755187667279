import { FC, useCallback } from 'react';
import { sanitizeText } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { SaleSourceTypes } from 'common/interfaces/sale-source.interface';
import ItemPrice from '../item-price';
import { ItemProps } from './props';
import { Content, Card, Measure, Description, Details, SourceType } from './styled';

const Item: FC<ItemProps> = ({ item, onPriceSelected, providers, pricesSelected }) => {
    const { t } = useTranslation();

    const getProvider = useCallback(
        (providerId: string) => providers.find((provider) => provider._id === providerId),
        [item.itemId, providers.length]
    );

    const getSourceTypeData = useCallback(() => {
        switch (item.sourceType) {
            case SaleSourceTypes.COMPRAS_NET:
                return {
                    src: '/assets/images/compras_net_logo.png',
                    title: 'Compras net',
                };
            case SaleSourceTypes.LICITAR_DIGITAL:
                return {
                    src: '/assets/images/icon_licitardigital_original.png',
                    title: 'Licitar digital',
                };
            case SaleSourceTypes.PNCP:
                return {
                    src: '/assets/images/pncp_logo.png',
                    title: 'PNCP',
                };
            default:
                return {
                    src: '',
                    title: '',
                };
        }
    }, [item.sourceType]);

    const source = getSourceTypeData();

    const getPublicCompanyName = () => {
        if (!item.saleSourcePublicCompanyName || item.saleSourcePublicCompanyName === '-') {
            return item.saleSourceCompanyName;
        }
        return item.saleSourcePublicCompanyName;
    };

    return (
        <Content>
            <Card>
                <SourceType src={source.src} title={source.title} />
                <Measure>
                    <span>{t('term.measure')}:</span>
                    <span title={item.unitMeasurement}>
                        {sanitizeText(item.unitMeasurement) || '-'}
                    </span>
                </Measure>
                <Description>
                    <p>
                        <span>Item: </span>
                        <span title={item.description}>{item.description || '-'}</span>
                    </p>
                    <p>
                        <span title={item.detailDescription}>
                            {sanitizeText(item.detailDescription) || '-'}
                        </span>
                    </p>
                </Description>
                <Details>
                    <p>
                        <span>{t('term.notice')}: </span>
                        <span title={item.saleSourceProcessNumber}>
                            {sanitizeText(item.saleSourceProcessNumber) || '-'}
                        </span>
                    </p>
                    <p>
                        <span>{t('term.organ')}: </span>
                        <span title={getPublicCompanyName()}>
                            {sanitizeText(getPublicCompanyName()) || '-'}
                        </span>
                    </p>
                </Details>
            </Card>
            {item?.prices.map((price, index) => (
                <ItemPrice
                    pricesSelected={pricesSelected}
                    provider={getProvider(price.providerId)}
                    onSelected={() => onPriceSelected(index)}
                    key={price._id}
                    price={price}
                />
            ))}
        </Content>
    );
};

export default Item;
