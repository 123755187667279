import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Scroll } from 'common/components/base/scroll';
import { styled } from 'common/theme/styled';

const Content = styled.div`
    width: 240px;
    max-width: 240px;
    min-width: 210px;
    background: #fff;
    position: sticky;
    top: 62px;
    height: fit-content;
    margin: 0 0 0 10px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
`;

const ContentVariables = styled(Scroll)`
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    padding: 5px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    background: ${(props) => props.theme.colors.primary};
    height: 46px;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px 4px 0 0;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #fff;
        margin: 0 5px;
    }
`;

const SeparadorTitle = styled.div`
    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.textDefault};
    }

    &:not(:first-child) {
        margin: 10px 0 0 0;
    }

    display: flex;
    height: 28px;
    align-items: center;
    border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
`;

const VariableItem = styled.div`
    padding: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    display: flex;

    .name {
        color: ${(props) => props.theme.colors.textDefault};
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
    }
`;

const WarningIcon = styled(RiErrorWarningLine)`
    font-size: 18px;
    min-width: 20px;
    color: ${(props) => props.theme.colors.warning};
`;

const CheckIcon = styled(AiOutlineCheckCircle)`
    font-size: 18px;
    min-width: 20px;
    color: ${(props) => props.theme.colors.success};
`;

export { Content, Header, SeparadorTitle, VariableItem, WarningIcon, CheckIcon, ContentVariables };
