import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import useStyles from './PaperFooterStyle';

export default function PaperFooterView({ handleClick, disabled }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.paperFooter}>
            <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={handleClick}
                {...{ disabled }}
            >
                {t('bidding.checkout.steps.next')}
            </Button>
        </div>
    );
}
