import styled from 'styled-components';

export const StyledSearchContainer = styled.div`
    background-color: var(--background-color);
    border: 1px var(--border-color) solid;
    align-items: center;
    display: flex;
    padding: 16px;
    border-radius: 4px;
    gap: 16px;

    > div {
        display: flex;

        > div {
            flex: 1;
        }
    }
`;
