import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { FC, useEffect, useState } from 'react';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { AuctionNoticeMembers } from 'clients/manager/interfaces/auction-notice.interface';
import { useStyles } from './styles';
import { ModalPreviousTeamProps } from './props';

const ModalPreviousTeam: FC<ModalPreviousTeamProps> = ({ onConfirm }) => {
    const [previousProcessesTeam, setPreviousProcessesTeam] = useState<AuctionNoticeMembers>();
    const [fetchingPrevious, setFetchingPrevious] = useState(true);

    const classes = useStyles();
    const { t } = useTranslation();

    const [processIdSelected, setProcessIdSelected] = useState<number | undefined>(undefined);
    const [visibleModal, setVisibleModal] = useState(false);

    const listProcessMembers = async () => {
        try {
            const response =
                await auctionNoticeRequests.listLatestProcessesMembersFromOrganization();
            ReactDOM.unstable_batchedUpdates(() => {
                setPreviousProcessesTeam(response.data);
                setFetchingPrevious(false);
            });
        } catch (error) {
            ReactDOM.unstable_batchedUpdates(() => {
                setPreviousProcessesTeam(undefined);
                setFetchingPrevious(false);
            });
        }
    };

    useEffect(() => {
        if (visibleModal && !previousProcessesTeam?.processes.length) {
            listProcessMembers();
            return;
        }

        if (!visibleModal) {
            setProcessIdSelected(undefined);
        }
    }, [visibleModal]);

    const handleClickConfirm = () => {
        if (!processIdSelected || !previousProcessesTeam?.processes.length) {
            return;
        }

        const process = previousProcessesTeam.processes.find(
            (process) => process.id === processIdSelected
        );

        if (!process) {
            return;
        }
        const { presidentUserId, competentAuthorityId, committeeMembers } = process;

        onConfirm({
            presidentUserId,
            competentAuthorityId,
            committeeMembers: [...committeeMembers.split(',').map(Number)],
        });
        setVisibleModal(false);
    };

    const formatProcessMembers = (processId: number) => {
        if (!previousProcessesTeam?.processes.length) {
            return '';
        }

        const getFormattedName = (userId: number) =>
            previousProcessesTeam.users.find((user) => user.id === userId)?.name.split(' ')[0];

        const process = previousProcessesTeam.processes.find((process) => process.id === processId);

        if (!process) {
            return '';
        }

        const { presidentUserId, committeeMembers } = process;

        return `${getFormattedName(presidentUserId)} -  (${committeeMembers
            .split(',')
            .map((userId) => getFormattedName(Number(userId)))
            .join(', ')})`;
    };

    return (
        <>
            {visibleModal && (
                <Modal
                    position={ModalPosition.center}
                    open={visibleModal}
                    onClose={(event: any) => {
                        event.stopPropagation();
                        ReactDOM.unstable_batchedUpdates(() => {
                            setProcessIdSelected(undefined);
                            setVisibleModal(false);
                        });
                    }}
                    header={<span>{t('text.choose-previous-team')}</span>}
                >
                    <Box minWidth='400px'>
                        <List
                            style={{
                                padding: 0,
                            }}
                            component='nav'
                            aria-label='main'
                        >
                            {!fetchingPrevious && !previousProcessesTeam?.processes?.length ? (
                                <>
                                    <Typography variant='body2' gutterBottom>
                                        Nenhum processo anterior encontrado.
                                    </Typography>
                                </>
                            ) : null}
                            {previousProcessesTeam?.processes.length
                                ? previousProcessesTeam?.processes.map((process) => (
                                      <Box mb={1} className={classes.root}>
                                          <ListItem
                                              button
                                              selected={processIdSelected === process.id}
                                              onClick={(event) => {
                                                  event.stopPropagation();
                                                  setProcessIdSelected(process.id);
                                              }}
                                          >
                                              <ListItemText
                                                  primary={process.auctionNumber}
                                                  secondary={formatProcessMembers(process.id)}
                                              />
                                              <ListItemIcon className='checkIcon'>
                                                  {processIdSelected === process.id && (
                                                      <CheckCircleRoundedIcon color='secondary' />
                                                  )}
                                              </ListItemIcon>
                                          </ListItem>
                                      </Box>
                                  ))
                                : null}
                            <Box display='flex' justifyContent='flex-end'>
                                <Button
                                    size='small'
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        ReactDOM.unstable_batchedUpdates(() => {
                                            setProcessIdSelected(undefined);
                                            setVisibleModal(false);
                                        });
                                    }}
                                >
                                    {t('term.cancel')}
                                </Button>
                                <Button
                                    size='small'
                                    variant='contained'
                                    disabled={processIdSelected === undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleClickConfirm();
                                    }}
                                >
                                    {t('term.confirm')}
                                </Button>
                            </Box>
                        </List>
                    </Box>
                </Modal>
            )}
            <Button
                style={{ textTransform: 'none', fontSize: 13 }}
                variant='text'
                color='link'
                size='small'
                onClick={(event) => {
                    event.stopPropagation();
                    setVisibleModal(true);
                }}
            >
                <b>{t('text.choose-previous-team')}</b> &nbsp;
            </Button>
        </>
    );
};

export default ModalPreviousTeam;
