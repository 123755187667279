import { Box, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';

const Image = styled.img`
    height: 384px;
`;

interface ApiOfflineProps {}

const ApiOffline: FC<ApiOfflineProps> = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
                <Grid container direction='column' alignItems='center' justify='center'>
                    <Grid item xs={12}>
                        <Image
                            src='/assets/images/svgs/internal_error.svg'
                            alt={t('info.not-found')}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Typography
                            variant='h2'
                            style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                fontSize: '85px',
                                lineHeight: '100px',
                                color: '#EFBE0E',
                            }}
                            gutterBottom
                        >
                            Oops!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='body1'
                            style={{
                                fontFamily: 'Roboto',
                                fontWeight: 300,
                                fontSize: '28px',
                                lineHeight: '32.81px',
                                textAlign: 'center',
                                color: '#444444',
                            }}
                        >
                            {t('info.manager-api-offline-problem')}
                            <br />
                            {t('info.manager-api-offline-available-again')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </PageWrapper>
    );
};

export default ApiOffline;
