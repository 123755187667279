import {
    TableContainer,
    Paper,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Box,
    Button,
    Grid,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { ChatFavoriteMessage } from 'clients/manager/interfaces/chat-favorite-message.interface';
import { AddIcon } from 'common/icons';
import { t } from 'i18next';
import { FC } from 'react';

interface FavoriteMessageViewṔrops {
    messages: ChatFavoriteMessage[];
    fetching: boolean;
    handleEditMessage: (message: ChatFavoriteMessage) => void;
    handleCreateMessage: () => void;
    deleteFavoriteMessage: (id: number) => Promise<void>;
}

const FavoriteMessageView: FC<FavoriteMessageViewṔrops> = ({
    messages,
    fetching,
    handleEditMessage,
    handleCreateMessage,
    deleteFavoriteMessage,
}) => {
    return (
        <Grid container item md={12}>
            <TableContainer component={Paper} variant='outlined'>
                <Table size='small' aria-label='a dense table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titulo</TableCell>
                            <TableCell align='right'>{t('term.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.length ? (
                            messages.map((message) => (
                                <TableRow key={message.id}>
                                    <TableCell component='th' scope='row'>
                                        {message.title}
                                    </TableCell>
                                    <TableCell align='right'>
                                        <IconButton
                                            aria-label={t('term.edit')}
                                            size='small'
                                            onClick={() => handleEditMessage(message)}
                                        >
                                            <Edit fontSize='small' />
                                        </IconButton>
                                        <IconButton
                                            aria-label={t('term.delete')}
                                            size='small'
                                            onClick={() => deleteFavoriteMessage(message.id)}
                                            disabled={fetching}
                                        >
                                            <Delete fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <>
                                {fetching &&
                                    new Array(5).fill(0).map((_, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRow key={index}>
                                            <TableCell component='th' scope='row'>
                                                <Skeleton width='100%' />
                                            </TableCell>
                                            <TableCell align='right'>
                                                <Skeleton width='100%' />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container item md={12} justifyContent='flex-end'>
                <Box mt={2}>
                    <Button
                        className='btn-add'
                        size='small'
                        variant='outlined'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={() => handleCreateMessage()}
                    >
                        {t('term.add')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FavoriteMessageView;
