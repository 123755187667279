import { styled } from 'common/theme/styled';

const Content = styled.div`
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export { Content };
