import { ButtonBase, IconButton, TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const ProcessRequestCommentContainer = styled.div<{ hide?: boolean }>`
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    gap: 1rem;
    align-items: flex-end;
    position: relative;

    @media (max-width: 900px) {
        > button:first-child {
            display: none;
        }

        flex-direction: column;
    }
`;

export const ProcessCommentBoxTextFieldContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
`;

export const ProcessRequestButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;

    > button:first-child {
        display: none;
        @media (max-width: 900px) {
            display: block;
        }
    }

    > section {
        display: flex;
        gap: 1rem;
    }

    @media (max-width: 900px) {
        width: 100%;
        justify-content: space-between;
    }
`;

export const InputFile = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 0.25rem;

    &::before {
        content: 'Soltar arquivo aqui';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--background-color);
        border-radius: 0.25rem;
        border: 2px dashed var(--primary-color);
        color: var(--primary-color);
        font-weight: 600;
        font-size: 1rem;
    }
`;

export const ProcessCommentBoxTextField = styled(TextField)`
    width: 100%;
    align-self: center;

    > div {
        flex-direction: column;
        align-items: flex-start;

        > div {
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }

    textarea {
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #0001;
            border-radius: 2px;
            transition: 0.4s;
        }
    }
`;

export const ProcessRequestCommentChipsContainer = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    max-width: 100%;

    > div {
        color: var(--secondary-color);
        padding: 0.5rem;

        > svg {
            width: 0.8rem;
        }
    }
`;

export const ProcessRequestCommentAttachmentButton = styled(IconButton)`
    background-color: transparent;
    aspect-ratio: 1/1;
    margin-bottom: 0.25rem;
    color: var(--primary-color);
`;

export const ProcessRequestCommentSendButton = styled(IconButton)`
    background-color: var(--primary-color);
    aspect-ratio: 1/1;
    margin-bottom: 0.25rem;
    color: var(--background-color);

    &:disabled {
        background-color: var(--primary-color);
        color: var(--background-color);
        filter: saturate(0.8);
    }

    &:hover {
        background-color: var(--primary-color);
    }
`;

export const ProcessRequestTypeContainer = styled.div<{ hide?: boolean }>`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    color: var(--background-color);
    background: var(--primary-color);
    flex-wrap: wrap;
    border-radius: 4px;
    gap: 0.5rem;

    > p {
        margin: 0;
    }

    ${({ hide }) =>
        hide &&
        css`
            transition: width 0.6s, border-radius 0.4s, opacity 0.4s 0.6s, bottom 0.6s, height 0.6s;
            width: 3rem;
            height: 3rem;
            border-radius: 1.5rem;
            opacity: 0;
            pointer-events: none;
            bottom: 0.25rem;
            color: transparent;

            > * {
                opacity: 0;
            }
        `}
`;

export const ProcessRequestMessageTypeButtonsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const ProcessRequestMessageTypeButton = styled(ButtonBase)`
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid var(--background-color);
    display: flex;
    gap: 0.5rem;
`;
