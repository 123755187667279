/* eslint-disable no-bitwise */
export const stringToRandomColor = (string: string): string => {
    let hash = 0;
    let i: number;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
};

export const stringToColor = (string: string): string => {
    const colors = [
        '#e51c23',
        '#e91e63',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#5677fc',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#259b24',
        '#8bc34a',
        '#afb42b',
        '#ff9800',
        '#ff5722',
        '#795548',
        '#607d8b',
        '#40407a',
        '#706fd3',
        '#34ace0',
        '#33d9b2',
        '#2c2c54',
        '#474787',
        '#aaa69d',
        '#227093',
        '#218c74',
        '#ff5252',
        '#ff793f',
        '#ffb142',
        '#ffda79',
        '#b33939',
        '#cd6133',
        '#84817a',
        '#cc8e35',
        '#ccae62',
    ];

    let hash = 0;
    if (string.length === 0) {
        return String(hash);
    }
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash &= hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
};
