import { createContext, useContext, useState } from 'react';

export const SearchOrganizationUsersContext = createContext<any>(null);

export const SearchOrganizationUsersContextProvider = ({ children }) => {
    const [organizationUsersDialog, setOrganizationUsersDialog] = useState({ open: false });

    return (
        <SearchOrganizationUsersContext.Provider
            value={{ organizationUsersDialog, setOrganizationUsersDialog }}
        >
            {children}
        </SearchOrganizationUsersContext.Provider>
    );
};

export const useSearchOrganizationUsersContext = () => useContext(SearchOrganizationUsersContext);
