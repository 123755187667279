import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { debounce } from 'lodash';
import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isTestOrganization } from 'common/utils';
import { disableField } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface DisputeDurationFieldProps {}

const DisputeDurationField: FC<DisputeDurationFieldProps> = () => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    const testOrganization = isTestOrganization();

    const handleChangeDisputeDuration = debounce(
        (event: React.ChangeEvent<any>) =>
            processForm?.setFieldValue('disputeDuration', Number(event.target.value)),
        0
    );

    const disputeDurations = useMemo(() => {
        const adminOptions = [
            { value: undefined, label: 'Administração', isTitle: true },
            { value: 300, label: `05 ${t('term.minutes')}` },
            { value: 600, label: `10 ${t('term.minutes')}` },
            { value: 900, label: `15 ${t('term.minutes')}` },
        ];

        const options = [
            ...(testOrganization ? adminOptions : []),
            {
                value: undefined,
                label: t('process.components.dispute-duration-field.according-to-in'),
                isTitle: true,
            },
            { value: 21600, label: `06 ${t('term.hours')}` },
            { value: 25200, label: `07 ${t('term.hours')}` },
            { value: 28800, label: `08 ${t('term.hours')}` },
            { value: 32400, label: `09 ${t('term.hours')}` },
            { value: 36000, label: `10 ${t('term.hours')}` },

            {
                value: undefined,
                label: t('process.components.dispute-duration-field.specific-regulations'),
                isTitle: true,
            },
            { value: 3600, label: `01 ${t('term.hours')}` },
            { value: 7200, label: `02 ${t('term.hours')}` },
            { value: 10800, label: `03 ${t('term.hours')}` },
            { value: 14400, label: `04 ${t('term.hours')}` },
            { value: 18000, label: `05 ${t('term.hours')}` },
        ];

        return options.map((option, index) => (
            <MenuItem
                key={option.value ?? `title-${index}`}
                disabled={option.isTitle || !option.value}
                value={option.value}
            >
                {option.label}
            </MenuItem>
        ));
    }, [testOrganization]);

    return (
        <TextField
            fullWidth
            color='primary'
            label={t('term.dispute-duration')}
            name='disputeDuration'
            onChange={handleChangeDisputeDuration}
            variant='outlined'
            select
            defaultValue={processForm?.values.disputeDuration}
            disabled={disableField('disputeDuration', process)}
        >
            {disputeDurations}
        </TextField>
    );
};

export default memo(DisputeDurationField);
