import { ListProviderBodyData, Provider } from 'clients/manager/interfaces/provider.interface';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ProviderSearchTypes } from '../components/select-search-type/enums/searchType.enum';

type ProvidersContextType = {
    providers: Provider[] | [];
    setProviders: React.Dispatch<React.SetStateAction<Provider[] | []>>;
    requestsCount: number;
    setRequestsCount: React.Dispatch<React.SetStateAction<number>>;
    requestFilters: ListProviderBodyData;
    setRequestFilters: React.Dispatch<React.SetStateAction<ListProviderBodyData>>;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    handleChangePage: any;
    handleChangeProvidersPerPage: any;
    handleChangeFilter: any;
    handleChangeSearch: any;
};

const initialBody: ListProviderBodyData = {
    limit: 10,
    offset: 0,
    params: {
        searchedText: '',
        searchType: ProviderSearchTypes.companyOrTradingName,
    },
};

export const SearchProvidersContext = createContext<ProvidersContextType>({
    providers: [],
    setProviders: () => {},
    requestsCount: 0,
    setRequestsCount: () => {},
    requestFilters: initialBody,
    setRequestFilters: () => {},
    page: 0,
    setPage: () => {},
    handleChangePage: () => {},
    handleChangeProvidersPerPage: () => {},
    handleChangeFilter: () => {},
    handleChangeSearch: () => {},
});

export const SearchProvidersContextProvider = ({ children }: { children: ReactNode }) => {
    const [providers, setProviders] = useState<Provider[] | []>([]);
    const [requestsCount, setRequestsCount] = useState<number>(0);
    const [requestFilters, setRequestFilters] = useState<ListProviderBodyData>(initialBody);
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
        setRequestFilters((prevState) => ({
            ...prevState,
            offset: (requestFilters.limit || 0) * newPage,
        }));
    };

    const handleChangeProvidersPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequestFilters((prevState) => ({
            ...prevState,
            limit: parseInt(event.target.value, 10),
        }));
        setPage(0);
    };

    const handleChangeSearch = (event, searchType: string) => {
        if (event.key === 'Enter') {
            setRequestFilters((prev) => ({
                ...prev,
                limit: 10,
                offset: 0,
                params: {
                    ...prev.params,
                    searchedText: event.target.value,
                    searchType: searchType,
                },
            }));
        }
    };

    const handleChangeFilter = (event, field: string) => {
        setRequestFilters((prev) => ({
            ...prev,
            limit: 10,
            offset: 0,
            params: {
                ...prev.params,
                [field]: event.target.value,
            },
        }));
    };

    return (
        <SearchProvidersContext.Provider
            value={{
                providers,
                setProviders,
                requestsCount,
                setRequestsCount,
                requestFilters,
                setRequestFilters,
                page,
                setPage,
                handleChangePage,
                handleChangeProvidersPerPage,
                handleChangeFilter,
                handleChangeSearch,
            }}
        >
            {children}
        </SearchProvidersContext.Provider>
    );
};

export const useSearchProvidersContext = () => useContext(SearchProvidersContext);
