import { CONSTANTS } from 'common/constants';
import { AuthorizationProfile } from 'common/interfaces/authorization-profile.interface';
import { validateCpf } from 'common/utils';
import Yup from 'common/utils/yup-extended.utils';
import { i18n } from 'i18next';

import { UserAuthorizationGroup } from 'modules/organization-users/interfaces/authorization-group-users.interface';

interface EditOrganizationUsersFormValues {
    id?: number;
    cpf: string;
    name?: string;
    countryCode: string;
    authorizationProfile: AuthorizationProfile;
    organizationUnits?: Array<{
        organizationUnitId: string;
        isAdmin: boolean;
        authorizationGroups?: Array<UserAuthorizationGroup>;
    }>;
    organization?: {
        isAdmin: boolean;
        authorizationGroups?: Array<UserAuthorizationGroup>;
    };
}

const EditOrganizationUsersFormSchema = (t: i18n['t']) =>
    Yup.object().shape({
        cpf: Yup.string()
            .required(t('term.required-field'))
            .test('cpf-test', t('cpf-or-cnpj-invalid', { ns: 'validation' }), (value, ctx) => {
                if (CONSTANTS.ibgeCodes.BRAZIL === ctx.parent.countryCode) {
                    return validateCpf(value ?? '');
                }
                return true;
            }),
        countryCode: Yup.string().required(t('term.required-field')),
        authorizationProfile: Yup.string()
            .oneOf(['unit', 'organization'])
            .required(t('term.required-field')),
        organization: Yup.object().when('authorizationProfile', {
            is: 'organization',
            then: (schema) =>
                schema.shape({
                    isAdmin: Yup.boolean(),
                    authorizationGroups: Yup.array().test('isOrganizationAdmin', (_, ctx) => {
                        const { createError } = ctx;

                        if (ctx?.parent?.authorizationGroups?.length > 0 || ctx?.parent?.isAdmin) {
                            return true;
                        }

                        return createError({
                            path: 'organization',
                            message: t(
                                'organization-users.pages.components.authorization-organization-error'
                            ),
                        });
                    }),
                }),
            otherwise: (schema) =>
                schema.shape({
                    isAdmin: Yup.boolean(),
                    authorizationGroups: Yup.array().min(0),
                }),
        }),
        organizationUnits: Yup.array().test('validateOrganizationUnits', (value, ctx) => {
            const { createError } = ctx;

            const hasAtLeastOneCorrectUnit = value?.find(
                (orgUnit) => orgUnit?.authorizationGroups?.length > 0 || orgUnit?.isAdmin
            );

            if (hasAtLeastOneCorrectUnit || ctx?.parent?.authorizationProfile === 'organization') {
                return true;
            }

            return createError({
                path: 'organizationUnits',
                message: t(
                    'organization-users.pages.components.authorization-organization-units-error'
                ),
            });
        }),
    });

export type { EditOrganizationUsersFormValues };
export { EditOrganizationUsersFormSchema };
