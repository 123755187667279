import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import NotFound from 'routes/not-found';
import { usePlatformContext } from 'routes/platform.context';
import { PriceBankProvider } from './context/price-list.context';
import SearchItems from './search/pages/search-items';
import Extractor from './settings/extractor';
import Purge from './settings/purge';
import UserPriceList from './user-price-list/pages/user-price-list';
import UserPriceLists from './user-price-lists/pages/user-price-lists';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    const { platform } = usePlatformContext();

    if (!platform?.generalSettings?.marketplace?.enableModule) {
        return <PrivateRoute path='*' key='*' component={() => <NotFound />} />;
    }

    return (
        <PriceBankProvider>
            <Switch>
                <PrivateRoute path={`${match.path}/`} exact component={() => null} />
                <PrivateRoute
                    path={`${match.path}/pesquisa`}
                    exact
                    component={() => <SearchItems />}
                />
                <PrivateRoute
                    path={`${match.path}/minhas-listas`}
                    exact
                    component={() => <UserPriceLists />}
                />
                <PrivateRoute
                    path={`${match.path}/minhas-listas/:priceListId`}
                    exact
                    component={() => <UserPriceList />}
                />
                <PrivateRoute
                    path={`${match.path}/minhas-listas/:priceListId/pesquisa`}
                    exact
                    component={() => <SearchItems />}
                />
                <PrivateRoute
                    path={`${match.path}/minhas-listas/:priceListId/pesquisa/editar`}
                    exact
                    component={() => <SearchItems />}
                />
                <PrivateRoute
                    path={`${match.path}/configuracoes/extrator`}
                    exact
                    component={() => <Extractor />}
                />
                <PrivateRoute
                    path={`${match.path}/configuracoes/expurgo`}
                    exact
                    component={() => <Purge />}
                />
                <PrivateRoute path='*' key='*' component={() => <NotFound />} />
            </Switch>
        </PriceBankProvider>
    );
};

const PriceBankRoutes: JSX.Element[] = [
    <Route path='/banco-precos' key='/banco-precos' render={() => <ChildRoutes />} />,
];

export default PriceBankRoutes;
