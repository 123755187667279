import { StyledSearchContainer } from './styled';

interface SearchContainerProps {
    children: JSX.Element[] | JSX.Element;
}

const SearchContainer = ({ children }: SearchContainerProps) => (
    <StyledSearchContainer>{children}</StyledSearchContainer>
);

export default SearchContainer;
