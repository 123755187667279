import * as Yup from 'yup';
import i18n from 'i18next';

export const getModalityPartialValidationSchema = () => ({
    ruleId: Yup.string()
        .nullable(true)
        .required(i18n.t('required-field', { ns: 'validation' })),
    legalSupportId: Yup.number()
        .nullable(true)
        .min(0, i18n.t('required-field', { ns: 'validation' }))
        .required(i18n.t('required-field', { ns: 'validation' })),
});

export const getModalityFormLabels = () => ({
    ruleId: i18n.t('term.modality'),
    legalSupportId: i18n.t('term.legal-support'),
});
