import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { styled } from 'common/theme/styled';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.backgroundPageDefault};
    padding: 15px 0 5px 0;
    position: sticky;
    top: 0px;
    z-index: 10;
`;

const EditArea = styled.div`
    margin: 0;
    display: flex;
    justify-content: center;

    .tox-editor-header {
        position: fixed !important;
        width: 793px !important;
        top: 126px !important;
    }
`;

const Page = styled(PageWrapper)`
    padding-top: 0 !important;
`;

export { Header, EditArea, Page };
