import { styled } from 'common/theme/styled';

const Content = styled.div``;

const ActionsArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0 0;
`;

export { Content, ActionsArea };
