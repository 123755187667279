import { PriceList, PriceListItem } from 'common/interfaces/price-list.interface';
import { Button, ButtonSize } from '@licitar/ui-kit';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'common/components/base/breadcrumb';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { usePriceBankContext } from 'modules/priceBank/context/price-list.context';
import ImportXlsxItems from '../../components/import-xlsx-items';
import ModalGenerateDoc from '../../components/modal-generate-doc';
import TableHeader from '../../components/table-header';
import TableList from '../../components/table-list';
import { UserPriceListProps } from './props';
import { Header } from './styled';

interface Params {
    [key: string]: string;
}

const UserPriceList: FC<UserPriceListProps> = () => {
    const { t } = useTranslation();
    const { priceListSelected } = usePriceBankContext();
    const [priceList, setPriceList] = useState<PriceList | undefined>(priceListSelected);
    const [generatingDoc, setGeneratingDoc] = useState(false);

    const { priceListId } = useParams<Params>();

    const getPriceList = async () => {
        try {
            const priceListData = await priceListRequests.getPriceList(priceListId);
            setPriceList(priceListData);
            return priceListData;
        } catch (error) {
            addNotificationError({
                message: t('mkp.user-price-list.error-get-list'),
            });
        }
    };

    useEffect(() => {
        getPriceList();
    }, []);

    const handleItemDeleted = (priceListItem: PriceListItem) => {
        if (!priceList) {
            return;
        }
        setPriceList((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    priceListItems: prevState.priceListItems?.filter(
                        (currItem) => currItem._id !== priceListItem._id
                    ),
                };
            }
            return prevState;
        });
    };

    const onItemsCreated = (length: number) => {
        addNotificationSuccess({
            message: `${length} ${t('mkp.user-price-list.success-created-list')}`,
        });
        getPriceList();
    };

    return (
        <PageWrapper>
            <Header>
                <Breadcrumb
                    paths={{
                        'banco-precos': {
                            value: t('shared.pages.menu-header.option.price-bank'),
                            disabled: true,
                        },
                        'minhas-listas': t('mkp.search.my-list-price'),
                    }}
                    params={{
                        priceListId: priceList?.name || '',
                    }}
                />
                <div>
                    {priceList && (
                        <ImportXlsxItems
                            onItemsCreated={onItemsCreated}
                            priceListId={priceList._id}
                        />
                    )}
                    <Button
                        style={{ margin: '0 0 0 10px' }}
                        onClick={() => {
                            setGeneratingDoc(true);
                        }}
                        size={ButtonSize.sm}
                    >
                        Imprimir lista
                    </Button>
                </div>
            </Header>
            {generatingDoc && priceList && (
                <ModalGenerateDoc
                    priceList={priceList}
                    type='pdf'
                    onClose={() => setGeneratingDoc(false)}
                />
            )}
            {priceList && (
                <>
                    <TableHeader priceList={priceList} />
                    <TableList priceList={priceList} onItemDeleted={handleItemDeleted} />
                </>
            )}
        </PageWrapper>
    );
};

export default UserPriceList;
