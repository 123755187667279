import { FC } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { DocumentBlockType, DocumentBlocks } from 'clients/manager/interfaces/document-blocks.interface';
import { useTranslation } from 'react-i18next';
import OrganizationDocumentBlock from '../organization-document-block';
import { Content } from './styles';

export interface OrganizationDocumentBlocksViewProps {
    headerBlock: DocumentBlocks | undefined;
    footerBlock: DocumentBlocks | undefined;
    onDocumentBlockCreated: (createdBlock: DocumentBlocks) => void;
}

const OrganizationDocumentBlocksView: FC<OrganizationDocumentBlocksViewProps> = ({
    headerBlock,
    footerBlock,
    onDocumentBlockCreated,
}) => {
    const { t } = useTranslation();

    return (
        <Content>
            <Box mb={1}>
                <Box mb={2}>
                    <Typography variant='h6'>{t('term.document.header')}</Typography>
                    <Divider />
                </Box>
                <OrganizationDocumentBlock
                    onBlockCreated={onDocumentBlockCreated}
                    blockType={DocumentBlockType.header}
                    block={headerBlock}
                />
            </Box>
            <Box mt={2}>
                <Box mb={2}>
                    <Typography variant='h6'>{t('term.document.footer')}</Typography>
                    <Divider />
                </Box>
                <OrganizationDocumentBlock
                    onBlockCreated={onDocumentBlockCreated}
                    blockType={DocumentBlockType.footer}
                    block={footerBlock}
                />
            </Box>
        </Content>
    );
};

export default OrganizationDocumentBlocksView;
