import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import { Card, IconButton, Typography } from '@material-ui/core';
import useStyles from './SelectedStepStyle';

export default function SelectedStepView({ selectedStep, index, handleClick }) {
    const classes = useStyles();

    return (
        <>
            {selectedStep ? (
                <Card key={selectedStep} className={classes.cardSelected}>
                    <Typography variant='subtitle1'>
                        <strong>{selectedStep}</strong>
                    </Typography>
                    <div className={classes.boxIcons}>
                        <IconButton onClick={() => handleClick(index)} aria-label='delete'>
                            <EditIcon />
                        </IconButton>
                        <CheckIcon />
                    </div>
                </Card>
            ) : (
                <></>
            )}
        </>
    );
}
