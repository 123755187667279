import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthUser } from 'store/user/user.actions';
import Authenticating from 'routes/components/authenticating';
import { userRequests } from 'clients/manager/user.requests';
import { setAuthToken, setAuthUserToken } from 'modules/auth/session.utils';
import { ValidateTokenProps } from './props';

interface AuthData {
    redirectUrl: string;
}

const ValidateToken: FC<ValidateTokenProps> = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();

    const query = useQuery();
    const history = useHistory();

    const [authData, setAuthData] = useState<AuthData | undefined>(undefined);
    const [authenticating, setAuthenticating] = useState(true);

    const fetchAndSetUser = async () => {
        const user = await userRequests.getAuthenticatedUser();
        if (user) {
            return dispatch(setAuthUser({ user }));
        }

        setAuthenticating(false);
        return user;
    };

    const getValidRedirect = (redirectUrl) => {
        const regex = /^(?!https?:\/\/)(?!.*www).*$/;
        if (regex.test(redirectUrl)) {
            return redirectUrl;
        }
        return '/login';
    };

    const redirectToUrl = () => {
        let redirectUrl = query.get('redirectUrl') || '/';
        redirectUrl = getValidRedirect(redirectUrl);
        const decodedRedirectUrl = decodeURIComponent(redirectUrl);
        setAuthData({
            redirectUrl: decodedRedirectUrl,
        });
    };

    const authenticate = async (token: string | null) => {
        if (!token) {
            return redirectToUrl();
        }

        setAuthUserToken(token);
        setAuthToken(token);
        const userFromToken = await fetchAndSetUser();

        if (!userFromToken) {
            window.location.pathname = '/login';
            return;
        }

        return redirectToUrl();
    };

    const validate = useCallback(() => {
        authenticate(query.get('token'));
    }, []);

    useEffect(() => {
        validate();
    }, [validate]);

    useEffect(() => {
        if (authData?.redirectUrl) {
            if (authData.redirectUrl.startsWith('http')) {
                window.location.href = authData.redirectUrl;
                return;
            }
            history.push(
                authData?.redirectUrl?.startsWith('/')
                    ? (authData?.redirectUrl as string)
                    : `/${authData?.redirectUrl}`
            );
        }
    }, [authData, history]);

    return <>{authenticating && <Authenticating />}</>;
};

export default ValidateToken;
