import React from 'react';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import { LegendContainer, LegendItem, LegendSquare } from './styled';

type PieChartLegendProps = {
    payload?: Array<{
        type: string;
        value: string;
        color: string;
        payload: {
            name: string;
            fill: string;
            percent: number;
            categoriaItemId: number;
            categoriaItemNome: string;
        };
    }>;
};

const PieChartCustomLegend: React.FC<PieChartLegendProps> = ({ payload }) => {
    const listaTodasCategorias = payload && payload.map((item) => item.payload);
    const categorias = _.orderBy(
        _.uniqBy(listaTodasCategorias, 'categoriaItemId'),
        'percent',
        'desc'
    );

    return (
        <LegendContainer>
            {categorias.map((item) => (
                <LegendItem>
                    <LegendSquare squareColor={item.fill} />
                    <Typography variant='subtitle2'>{item.categoriaItemNome}</Typography>
                </LegendItem>
            ))}
        </LegendContainer>
    );
};

export default PieChartCustomLegend;
