import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    modalMargin: {
        margin: 'auto',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    cardStyle: {
        padding: '8px 16px',
        position: 'relative',
        marginBottom: '8px',
        borderRadius: '0.25rem',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
    },
    gridContainer: {
        minHeight: '45px',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    gridItemFlex: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexStartBox: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '5px',
        alignContent: 'center',
        justifyContent: 'flex-end',
    },
    linkDecoration: {
        textDecoration: 'none',
    },
    typographyFlex: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        color: '#317EC5',
        fontSize: '12px',
    },
    cloudDownloadIcon: {
        marginRight: '5px',
    },
    flexCenterBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
    },
    typographyBold: {
        fontSize: '12px',
        marginLeft: '4px',
        fontWeight: 'bold',
        marginRight: '5px',
    },
    typographyBody2: {
        display: 'flex',
        gap: 4,
        fontSize: 12,
    },
    typographyBold3: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginRight: '5px',
    },
    overflowYScroll: {
        overflowY: 'scroll',
    },
    box: {
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        width: '600px',
        maxHeight: '600px',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));
