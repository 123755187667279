import {
    ChatFavoriteMessage,
    CreateFavoriteMessage,
    UpdateFavoriteMessage,
} from 'clients/manager/interfaces/chat-favorite-message.interface';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { chatFavoriteMessageRequest } from 'clients/manager/chat-favorite-message.requests';
import ModalEditOrCreateFavoriteMessageView from './modal-edit-or-create-favorite-message-view';

interface ModalEditOrCreateFavoriteMessageṔrops {
    onClose: () => void;
    onCreate: (favoriteMessage: ChatFavoriteMessage) => void;
    onUpdate: (favoriteMessage: UpdateFavoriteMessage) => void;
    favoriteMessage?: ChatFavoriteMessage;
}

export interface FavoriteMessageForm {
    title: string;
    message: string;
}

const ModalEditOrCreateFavoriteMessage: FC<ModalEditOrCreateFavoriteMessageṔrops> = ({
    onClose,
    onCreate,
    onUpdate,
    favoriteMessage,
}) => {
    const [fetching, setFetching] = useState<boolean>(false);

    const updateFavoriteMessage = async (data: UpdateFavoriteMessage) => {
        setFetching(true);
        await chatFavoriteMessageRequest.update(data);
        onUpdate(data);
        setFetching(false);
        onClose();
    };

    const createFavoriteMessage = async (dataToCreate: CreateFavoriteMessage) => {
        setFetching(true);
        const { data } = await chatFavoriteMessageRequest.create(dataToCreate);
        onCreate(data);
        setFetching(false);
        onClose();
    };

    const form = useFormik<FavoriteMessageForm>({
        initialValues: {
            title: favoriteMessage?.title || '',
            message: favoriteMessage?.message || '',
        },
        validationSchema: yup.object({
            title: yup.string().required(t('required-field', { ns: 'validation' })),
            message: yup.string().required(t('required-field', { ns: 'validation' })),
        }),
        onSubmit: (values) => {
            if (favoriteMessage) {
                return updateFavoriteMessage({ id: favoriteMessage.id, ...values });
            }
            return createFavoriteMessage(values);
        },
    });
    return (
        <ModalEditOrCreateFavoriteMessageView
            onClose={onClose}
            messageId={favoriteMessage?.id}
            form={form}
            fetching={fetching}
        />
    );
};

export default ModalEditOrCreateFavoriteMessage;
