import { Box, BoxProps, Card, CardContent, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { auctionTypeToLabel } from 'modules/process/components/auction-type-label';
import { FC } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    AuctionNotice,
    AuctionNoticeOrganizationResume,
    AuctionType,
    ProcessStage,
} from '../../../../clients/manager/interfaces/auction-notice.interface';
import {
    apiRedirectTo,
    formatDateDistance,
    getBackofficeRedirectUrl,
    getMd5Hash,
} from '../../../../common/utils';
import { featureFlagEnabled } from '../../../../common/utils/can-access';
import { processRules } from '../../process-utils';
import ChipDisputeRoom from '../chips/chip-dispute-room';
import ChipLotsCount from '../chips/chip-lots-count';
import ChipProvidersCount from '../chips/chip-providers';
import ChipSolicitationsCount from '../chips/chip-solicitations';
import ChipSuspendedStatus from '../chips/chip-suspended';
import { useStyles } from './styles';

interface ProcessListGridCardProps {
    process: AuctionNoticeOrganizationResume;
    type: ProcessStage;
}

const ProcessListGridCard: FC<ProcessListGridCardProps> = ({ type, process }) => {
    const classes = useStyles();
    const history = useHistory();

    const backofficeRedirectUrlPath = getBackofficeRedirectUrl();

    const {
        i18n: { language },
    } = useTranslation();

    moment.locale(language);

    const handleCardClick = () => {
        const showNewVersionProcess = featureFlagEnabled('novaTelaProcessos');
        const auctionHash = getMd5Hash(String(process.id));
        const path = encodeURIComponent(`processos/?ver&idE=${auctionHash}&aba=dados`);

        if (showNewVersionProcess) {
            return history.push({
                pathname: `/processos/${process.id}`,
                search: `?aba=dados`,
            });
        }

        return apiRedirectTo(backofficeRedirectUrlPath, path);
    };

    const getFormattedDisputeDate = () => {
        const str = formatDateDistance(process.startDateTimeDispute);
        const disputeDate = moment(process.startDateTimeDispute);
        const title = disputeDate.format('LLL');

        if (
            moment().add(7, 'days').valueOf() < disputeDate.valueOf() ||
            moment().subtract(7, 'days').valueOf() > disputeDate.valueOf() ||
            process.auctionType === AuctionType.accreditation ||
            !process.startDateTimeDispute
        ) {
            return null;
        }

        const boxProps: BoxProps = {};

        if (
            moment().startOf('day').valueOf() ===
            moment(process.startDateTimeDispute).startOf('day').valueOf()
        ) {
            boxProps.style = {
                background: 'var(--warning-color)',
            };
        }

        return (
            <Box className={classes.badgeDisputeRoom} {...boxProps} title={title}>
                {str}
            </Box>
        );
    };

    return (
        <Box className={classes.root}>
            {getFormattedDisputeDate()}
            <Card className={classes.card} variant='elevation' onClick={handleCardClick}>
                <CardContent>
                    <Typography
                        variant='body1'
                        color='textPrimary'
                        gutterBottom
                        style={{ fontSize: 15 }}
                    >
                        <b>{auctionTypeToLabel(process.auctionType).long}</b>
                    </Typography>
                    {process.simpleDescription && (
                        <Typography
                            title={process.simpleDescription}
                            className={classes.title}
                            variant='body1'
                        >
                            {process.simpleDescription}
                        </Typography>
                    )}
                    <Box display='flex'>
                        <Typography
                            variant='subtitle2'
                            className={clsx(classes.textProcess, classes.textInfoArea)}
                        >
                            {process.id}
                        </Typography>
                        &nbsp;
                        <Typography
                            variant='subtitle2'
                            className={classes.textInfoArea}
                            color='secondary'
                        >
                            {process.accreditationNumber}
                        </Typography>
                    </Box>

                    <Box className={classes.chipRoot} component='ul'>
                        <li>
                            <ChipLotsCount count={process?.lotsCount ?? '--'} />
                        </li>
                        <li>
                            <ChipSolicitationsCount count={process?.requestsCount ?? '-'} />
                        </li>
                        <li>
                            <ChipProvidersCount process={process} />
                        </li>
                        <li>
                            <ChipSuspendedStatus typeCancel={process.typeCancel} />
                        </li>
                        {processRules.visibleDisputeRoomRedirectIcon(
                            process as unknown as AuctionNotice
                        ) && (
                            <li>
                                <ChipDisputeRoom processId={process.id} />
                            </li>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default ProcessListGridCard;
