import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    root: {
        '& .MuiAccordionSummary-content': {
            margin: '8px 0',
            height: '34px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '8px 0',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '40px',
        },
    },
    header: {
        backgroundColor: '#4b4b4b08',
    },
}));
