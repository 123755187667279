import { useState } from 'react';
import EditGroupAuthorizationTableView from './EditGroupAuthorizationTableView';

const EditGroupAuthorizationTable = ({ categories, authorizationGroupId, readOnly }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);

    return (
        <EditGroupAuthorizationTableView
            {...{
                categories,
                selectedCategory,
                setSelectedCategory,
                authorizationGroupId,
                readOnly,
            }}
        />
    );
};

export default EditGroupAuthorizationTable;
