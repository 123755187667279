import { Grid } from '@material-ui/core';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import ProviderSignupExpirationWarning from 'common/components/provider-signup-expiration-warning';
import ProviderUpdateRequestWarning from 'common/components/provider-update-request-warning';
import { Platform } from 'clients/manager/platforms.requests';
import AccountJourney from './components/account-journey';
import ImportantProccess from './components/important-proccess';
import NeedHelp from './components/need-help';
import ProviderSummary from './components/provider-summary';
import ExpiredDocuments from './components/expired-documents';
import ProcessProposals from './components/process-proposals';

interface ProviderDashboardViewProps {
    platform?: Platform;
}

const ProviderDashboardView = ({ invites }) => {
    return (
        <PageWrapper>
            <ProviderSignupExpirationWarning location='dashboard' />
            <ProviderUpdateRequestWarning />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <ProviderSummary />
                </Grid>
                <Grid item xs={12} md={8}>
                    <AccountJourney />
                </Grid>
                {!invites ? (
                    <Grid item xs={12} md={4}>
                        <ExpiredDocuments />
                    </Grid>
                ) : (
                    <Grid item xs={12} md={4}>
                        <ProcessProposals invites={invites} />
                    </Grid>
                )}
                <Grid item xs={12} md={8}>
                    <ImportantProccess />
                </Grid>
                {!invites ? (
                    <Grid item xs={12} md={4}>
                        <NeedHelp />
                    </Grid>
                ) : (
                    <Grid item xs={12} md={4}>
                        <ExpiredDocuments />
                        <br />
                        <NeedHelp />
                    </Grid>
                )}
            </Grid>
        </PageWrapper>
    );
};

export default ProviderDashboardView;
