import { useFormik } from 'formik';
import YupValidator from 'common/utils/yup-extended.utils';
import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHECKOUT } from '../../CheckoutConstants';
import PaymentView from './PaymentView';

const Payment = () => {
    const [selected, setSelected] = useState(CHECKOUT.INVOICE);
    const [focus, setFocus] = useState('');
    const { setStep, step, setSelectedSteps, selectedSteps } = useCheckoutContext();
    const planDetails = selectedSteps.plan.details;
    const { installmentsNumber } = planDetails.priceDetails;
    const { t } = useTranslation();
    const handleClick = (selected) => setSelected(selected);
    const getInstallmentOption = (price, installment) =>
        `${installment}x de R$ ${(price / installment).toFixed(2)}`;

    const planInstallments = new Array(installmentsNumber)
        .fill(null)
        .map((_, index) => getInstallmentOption(planDetails.billing.value, index + 1));

    const initialValues = {
        name: '',
        number: '',
        expiry: '',
        cvc: '',
        installments: '',
    };

    const validationSchema = YupValidator.object().shape({
        name: YupValidator.string().required(t('term.required-field')).max(20),
        number: YupValidator.string()
            .required(t('term.required-field'))
            .max(20, t('bidding.checkout.payment.max_16_characters'))
            .min(16, t('bidding.checkout.payment.max_14_characters')),
        expiry: YupValidator.string()
            .required(t('term.required-field'))
            .max(5, t('bidding.checkout.payment.max_4_characters'))
            .min(5, t('bidding.checkout.payment.min_4_characters')),
        cvc: YupValidator.string().required(t('term.required-field')).max(3).min(3),
        installments: YupValidator.string().required(t('term.required-field')),
    });

    const onSubmit = (values) => {
        setSelectedSteps((prevState) => ({
            ...prevState,
            payment: { selected, details: values },
        }));
        setStep(step + 1);
    };

    const handleInputFocus = (e) => setFocus(e.target.name);

    const form = useFormik({ initialValues, validationSchema, onSubmit });

    return (
        <PaymentView
            {...{
                selected,
                form,
                setSelectedSteps,
                handleClick,
                handleInputFocus,
                focus,
                planInstallments,
            }}
        />
    );
};

export default Payment;
