import { doRequest, managerApiInstance } from 'clients/api';
import { AxiosRequestConfig } from 'axios';
import { GenericResponse } from 'clients/interfaces/paginated.interface';
import {
    LinkUserInOrganizationOrUnitBody,
    LinkUserInOrganizationOrUnitResponse,
    ListUsersBelongToOrganizationBody,
    ListUsersBelongToOrganizationResponse,
} from '../interfaces/organization-users.interface';

const BASE_PATH = '/organizations-users';

const OrganizationsUsersRequests = {
    listUsersBelongToOrganization: async (
        body?: ListUsersBelongToOrganizationBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<ListUsersBelongToOrganizationResponse>(
            managerApiInstance.post(`${BASE_PATH}/listUsersBelongToOrganization`, body, config)
        ),
    doLinkUserInOrganizationOrUnit: async (
        body: LinkUserInOrganizationOrUnitBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<LinkUserInOrganizationOrUnitResponse>(
            managerApiInstance.post(`${BASE_PATH}/doLinkUserInOrganizationOrUnit`, body, config)
        ),
    doUnlinkUserAndOrganization: async (userId: number, config?: AxiosRequestConfig<any>) =>
        doRequest<GenericResponse<null>>(
            managerApiInstance.post(`${BASE_PATH}/doUnlinkUserAndOrganization`, { userId }, config)
        ),
};

export { OrganizationsUsersRequests };
