import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { OkResponse } from '../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { AuctionNoticeFiles } from './interfaces/auction-notice-files.interface';

const auctionNoticeFilesRequests = {
    uploadFileOnAuctionFiles: async (
        auctionNoticeId: number,
        file: File,
        onUploadProgress: (progress: number) => void
    ) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);

        return doRequest<PlainGenericResponse<AuctionNoticeFiles>>(
            managerApiUploadFileInstance.post(
                '/auction-notice-files/uploadFileOnAuctionFiles',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        auctionNoticeId,
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        const percent = Math.floor((loaded * 100) / total);
                        onUploadProgress(percent);
                    },
                }
            )
        );
    },
    listAuctionNoticeFiles: async (auctionNoticeId: number) =>
        doRequest<GenericResponse<AuctionNoticeFiles>>(
            managerApiInstance.post('/auction-notice-files/listAuctionNoticeFiles', {
                auctionNoticeId,
            })
        ),
    deleteAuctionNoticeFiles: async (auctionNoticeId: number, auctionFileId: number) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post('/auction-notice-files/deleteAuctionNoticeFiles', {
                auctionNoticeId,
                auctionFileId,
            })
        ),
};

export { auctionNoticeFilesRequests };
