import GenericErrorView from './generic-error-view';

type GenericErrorInterface = {
    message?: string;
};

const GenericError: React.FC<GenericErrorInterface> = ({ message }) => (
    <GenericErrorView {...{ message }} />
);

export default GenericError;
