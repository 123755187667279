import {
    Box,
    Grid,
    makeStyles,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { Button, Modal, TextField } from 'common/components';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';

const useStyles = makeStyles((theme) => ({
    modal: {
        padding: theme.spacing(2),
        width: theme.spacing(80),
    },
    buttons: {
        padding: theme.spacing(2),
        gap: theme.spacing(2),
    },
}));

const SearchProviderUsersDialogView = ({ form, handleClose }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const { IBGEcountries } = useIBGECountries();

    const header = <span>{t('provider-users.pages.components.relation-user-provider')}</span>;

    return (
        <Modal open onClose={handleClose} {...{ header }}>
            <div className={classes.modal}>
                <form onSubmit={form.handleSubmit} noValidate>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            <Alert severity='warning'>
                                <Typography>
                                    <strong>{t('provider-users.pages.components.alert')}</strong>{' '}
                                    {t('provider-users.pages.components.alert-info')}
                                </Typography>
                            </Alert>
                        </Grid>
                        <Box display='flex' justifyContent='space-evenly'>
                            <Grid item xs={4}>
                                <TextField
                                    select
                                    label={t('term.country')}
                                    fullWidth
                                    name='country'
                                    value={form.values.country}
                                    onChange={form.handleChange}
                                    error={form.touched.country && !!form.errors.country}
                                    helperText={form.touched.country && form.errors.country}
                                >
                                    {IBGEcountries.map(({ code, name }) => (
                                        <MenuItem key={code} value={code}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={7}>
                                <IdentificationNumberTextField
                                    identificationNumber={form.values.cpf}
                                    country={form.values.country}
                                    fieldName='cpf'
                                    type='cpf'
                                    {...form}
                                />
                            </Grid>
                        </Box>

                        {form.values.userId !== form.values.providerUserId && (
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox checked={form.values.isAdmin} />}
                                    label={t('term.super-administrator')}
                                    onChange={(event: React.ChangeEvent<any>) =>
                                        form.setFieldValue('isAdmin', event.target.checked)
                                    }
                                />
                            </Grid>
                        )}

                        <Grid container justifyContent='flex-end' className={classes.buttons}>
                            <Button size='small' onClick={handleClose}>
                                {t('term.cancel')}
                            </Button>

                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                size='small'
                                disabled={form.isSubmitting}
                            >
                                {t('term.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
};

export default SearchProviderUsersDialogView;
