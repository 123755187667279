import { Breadcrumbs, Card, Grid, MenuItem, Typography } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SupplyCategoryAutocomplete from 'common/components/supply-category-autocomplete';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ModalCatalogs from '../modal-catalogs';

interface EditMaterialViewProps {
    form: any;
    catalogs: any[];
    modalCatalogsOpen: boolean;
    setModalCatalogsOpen: (open: boolean) => void;
}

const EditMaterialView: React.FC<EditMaterialViewProps> = ({
    form,
    catalogs,
    modalCatalogsOpen,
    setModalCatalogsOpen,
}) => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Breadcrumbs color='primary'>
                    <Link to='/materiais' style={{ textDecoration: 'none' }}>
                        <Typography>{t('material.pages.title')}</Typography>
                    </Link>
                    {form.values.id && <Typography>{form.values.name || '...'}</Typography>}
                    {!form.values.id && <Typography>{t('term.new')}</Typography>}
                </Breadcrumbs>

                <Grid item>
                    <Button
                        size='small'
                        variant='text'
                        color='primary'
                        disableRipple
                        onClick={() => setModalCatalogsOpen(true)}
                    >
                        {`${t('material.pages.edit.material-catalog')}s`}
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                        form='formGeneralData'
                        disabled={form.isSubmitting}
                    >
                        {t('term.save')}
                    </Button>
                </Grid>
            </Grid>

            <br />

            <Card>
                <form id='formGeneralData' onSubmit={form.handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <Typography variant='h6'>
                                {t(
                                    'group-auth.pages.edit-group.components.edit-group-auth.general-data'
                                )}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label={t('material.pages.edit.material-code')}
                                fullWidth
                                name='code'
                                value={form.values.code}
                                onChange={form.handleChange}
                                error={form.touched.code && !!form.errors.code}
                                helperText={form.touched.code && form.errors.code}
                                required
                            />
                        </Grid>
                        <Grid item md={9}>
                            <TextField
                                label={t('material.pages.edit.material-name')}
                                fullWidth
                                name='name'
                                value={form.values.name}
                                onChange={form.handleChange}
                                error={form.touched.name && !!form.errors.name}
                                helperText={form.touched.name && form.errors.name}
                                required
                            />
                        </Grid>

                        <Grid item md={6}>
                            <TextField
                                label={t('material.pages.edit.material-catalog')}
                                fullWidth
                                select
                                name='catalogId'
                                value={form.values.catalogId}
                                onChange={form.handleChange}
                                error={form.touched.catalogId && !!form.errors.catalogId}
                                helperText={form.touched.catalogId && form.errors.catalogId}
                                required
                            >
                                {catalogs.map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={6}>
                            <SupplyCategoryAutocomplete
                                label={t('material.pages.edit.material-category')}
                                handleOnChange={(category) => {
                                    form.setFieldValue('supplyCategory', category);
                                    form.setFieldValue('supplyCategoryId', category.id);
                                }}
                                value={form.values.supplyCategory || null}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <TextField
                                label={t('material.pages.edit.material-description')}
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={3}
                                name='description'
                                value={form.values.description}
                                onChange={form.handleChange}
                                error={form.touched.description && !!form.errors.description}
                                helperText={form.touched.description && form.errors.description}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Card>
            {modalCatalogsOpen && <ModalCatalogs handleClose={() => setModalCatalogsOpen(false)} />}
        </PageWrapper>
    );
};

export default EditMaterialView;
