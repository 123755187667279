import {
    Box,
    List,
    ListItem,
    ListItemSecondaryAction,
    IconButton,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FC, memo } from 'react';
import { CheckCircleIcon, DeleteIcon } from 'common/icons';
import { useStyles } from './styles';
import { UploadedFile, UploadedFileStatusTypes } from './props';

interface FileListViewProps {
    uploadedFiles: UploadedFile[];
    fetchingFiles: boolean;
    onDeleteFile: (fileId: string) => void;
    skeletonRenderRows: number;
    canDelete?: boolean;
    showMessage: boolean;
}

const FileListView: FC<FileListViewProps> = ({
    uploadedFiles,
    fetchingFiles,
    onDeleteFile,
    skeletonRenderRows,
    canDelete,
    showMessage,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (fetchingFiles) {
        return (
            <List dense>
                {new Array(skeletonRenderRows).fill(null).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem key={index}>
                        <Skeleton variant='rect' width='100%' height={20} />
                    </ListItem>
                ))}
            </List>
        );
    }

    if (!uploadedFiles.length && showMessage) {
        return <Typography variant='body2'>Nenhum arquivo vinculado até o momento</Typography>;
    }

    if (!uploadedFiles.length && !showMessage) {
        return <></>;
    }

    return (
        <List
            dense
            style={
                uploadedFiles?.length
                    ? {
                          border: '1px #e0e0e0 solid',
                          borderRadius: '6px',
                      }
                    : {}
            }
        >
            {uploadedFiles.map((uploadedFile) => (
                <ListItem key={uploadedFile.id} className={classes.listItem}>
                    <ListItemText
                        title={uploadedFile.name}
                        className={classes.secondaryAction}
                        primary={uploadedFile.name}
                    />
                    <ListItemSecondaryAction>
                        <Box>
                            <IconButton
                                size='small'
                                edge='end'
                                style={{
                                    marginRight: '8px',
                                }}
                                title={t('info.preview-file')}
                                onClick={() => window.open(uploadedFile.preview, '_blank')}
                            >
                                <LinkIcon className={classes.icon} />
                            </IconButton>
                            {uploadedFile.status === UploadedFileStatusTypes.pending && (
                                <CircularProgressbar
                                    styles={{
                                        root: { width: 20 },
                                        path: { stroke: '#676767' },
                                    }}
                                    strokeWidth={10}
                                    value={uploadedFile.progress}
                                />
                            )}
                            {canDelete && (
                                <>
                                    <IconButton size='small' edge='end' title='Enviado'>
                                        <CheckCircleIcon
                                            className={clsx(classes.iconValid, classes.icon)}
                                        />
                                    </IconButton>
                                    {canDelete && (
                                        <IconButton
                                            size='small'
                                            edge='end'
                                            title={t('info.delete-file')}
                                            onClick={() => onDeleteFile(uploadedFile.id)}
                                        >
                                            <DeleteIcon className={classes.icon} />
                                        </IconButton>
                                    )}
                                </>
                            )}
                            {uploadedFile.status === UploadedFileStatusTypes.rejected && (
                                <IconButton size='small' edge='end' title={t('error.send-file')}>
                                    <ErrorOutlineIcon
                                        className={clsx(classes.iconInvalid, classes.icon)}
                                    />
                                </IconButton>
                            )}
                        </Box>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

export default memo(FileListView);
