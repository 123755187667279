import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { styled } from 'common/theme/styled';

const Spin = styled(AiOutlineLoading3Quarters)`
    @keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
        }
    }

    font-size: 40px;
    animation: rotation 800ms infinite linear;
    color: ${({ theme }) => theme.colors.primary};
`;

const SpinnerArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
`;

const OverLay = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000014;
    z-index: 5;
    pointer-events: none;
    cursor: default;
`;

export { Spin, SpinnerArea, OverLay };
