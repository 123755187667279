import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import { SearchContractsContextProvider } from './pages/search-contracts/context/search-contracts-context';
import SearchContracts from './pages/search-contracts/search-contracts';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <SearchContractsContextProvider>
            <Switch>
                <PrivateRoute
                    exact
                    path={`${match.path}/`}
                    key='/'
                    component={() => <SearchContracts />}
                />
            </Switch>
        </SearchContractsContextProvider>
    );
};

const ContractRoutes: JSX.Element[] = [
    <Route path='/contratos' key='/contratos' render={() => <ChildRoutes />} />,
];

export default ContractRoutes;
