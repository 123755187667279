const palette = {
    primary: {
        main: '#1D1CE5',
        light: '#4544ff',
        dark: '#0000bd',
        contrastText: '#FFFFFF',
    },
    secondary: {
        main: '#000095',
        light: '#2828bd',
        dark: '#00006d',
        contrastText: '#FFFFFF',
    },
    tertiary: {
        main: '#72FFFF',
        light: '#9affff',
        dark: '#4ad7d7',
        contrastText: '#000000',
    },
    text: {
        primary: '#444444',
        hint: '#777777',
        secondary: '#ffffff',
    },
    success: {
        main: '#02AE51',
        light: '#97F1C0',
        dark: '#02AE51',
        contrastText: '#FFFFFF',
    },
    info: {
        main: '#317EC5',
        light: '#29b6f6',
        dark: '#0288d1',
        contrastText: '#FFFFFF',
    },
    warning: {
        main: '#EFBE0E',
        light: '#F8E39C',
        dark: '#EFBE0E',
        contrastText: '#FFFFFF',
    },
    danger: {
        main: '#E74C3C',
        light: '#F8C0BB',
        dark: '#E74C3C',
        contrastText: '#FFFFFF',
    },
    grey: {
        100: '#F7F7F7',
        300: '#DDDDDD',
        500: '#BBBBBB',
        700: '#777777',
        900: '#444444',
    },
    blue: {
        300: '#B9D2EA',
        500: '#317EC5',
        700: '#626F97',
        800: '#47516F',
        900: '#3B435C',
    },
    white: {
        1000: '#ffffff',
    },
};

export default palette;
