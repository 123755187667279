import NeedHelpView from './need-help-view';

const helps = [
    {
        category: 'Gerais',
        name: 'Como Enviar Proposta',
        url: 'https://licitardigital.tawk.help/article/passo-a-passo-para-o-envio-de-proposta',
    },
    {
        category: 'Gerais',
        name: 'Como Acessar a Sala de Disputa',
        url: 'https://licitardigital.tawk.help/article/como-acessar-a-sala-de-disputa',
    },
    {
        category: 'Gerais',
        name: 'Como Funciona a Sala de Disputa',
        url: 'https://licitardigital.tawk.help/article/a-sala-de-disputa',
    },
    {
        category: 'Gerais',
        name: 'Como Acessar os Documentos das Empresas Vencedoras',
        url: 'https://licitardigital.tawk.help/article/como-conferir-os-documentos-de-habilita%C3%A7%C3%A3o-dos-vencedores-dos-processos',
    },
    {
        category: 'Gerais',
        name: 'Como Enviar Proposta Atualizada',
        url: 'https://licitardigital.tawk.help/article/envio-de-proposta-atualizada',
    },
    {
        category: 'Gerais',
        name: 'Como Manifestar Intenção de Recurso',
        url: 'https://licitardigital.tawk.help/article/como-manifestar-a-inten%C3%A7%C3%A3o-de-recorrer',
    },

    {
        category: 'Gerais',
        name: 'Como Enviar Impugnações, Esclarecimentos, Recurso ou Contrarrazões',
        url: 'https://licitardigital.tawk.help/article/como-enviar-um-pedido-de-impugna%C3%A7%C3%B5es-esclarecimentos-ou-recurso',
    },
];

const NeedHelp = () => <NeedHelpView {...{ helps }} />;

export default NeedHelp;
