import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    dialogBox: {
        padding: theme.spacing(1),
    },
    dialogContentText: {
        color: theme.palette.text.primary,
    },
    textField: {
        margin: theme.spacing(1, 0),
    },
    button: {
        margin: theme.spacing(0, 1),
    },
}));
