import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse } from '../interfaces/paginated.interface';
import { SupplyCategoriesProviders } from './interfaces/supply-categories-providers.interface';
import {
    ListSupplyCategoriesFilters,
    SupplyCategory,
    UpdateSupplyCategories,
} from './interfaces/supply-categories.interface';

const supplyCategoriesRequests = {
    listSupplyCategories: async (filters?: ListSupplyCategoriesFilters) =>
        doRequest<GenericResponse<SupplyCategory>>(
            managerApiInstance.post('/supply-categories/listSupplyCategories', filters)
        ),
    updateSupplyCategories: async (data: UpdateSupplyCategories) =>
        doRequest<any>(managerApiInstance.post('/providers/updateProviderSupplySegments', data)),
    listProviderSupplyCategories: async () =>
        doRequest<GenericResponse<SupplyCategoriesProviders>>(
            managerApiInstance.post(`/providers/listProviderSupplySegments`)
        ),
};

export { supplyCategoriesRequests };
