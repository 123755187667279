import { FC } from 'react';
import { Box, Grid, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import {
    DocumentTemplate,
    DocumentTemplateType,
} from '../../../../../clients/manager/interfaces/document-templates.interface';
import { useStyles, VisualizeIcon } from './styles';

export interface TemplateLibraryCardProps {
    template?: DocumentTemplate;
    onPreviewTemplate: () => void;
    onCreatingTemplate: () => void;
}

const TemplateLibraryCard: FC<TemplateLibraryCardProps> = ({
    template,
    onPreviewTemplate,
    onCreatingTemplate,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const documentTypesName: { [key in DocumentTemplateType]: string } = {
        [DocumentTemplateType.contract]: t('term.contract'),
        [DocumentTemplateType.receipt]: t('term.receipt'),
        [DocumentTemplateType.auction]: t('term.public-notices'),
        [DocumentTemplateType.protocol]: 'Ata',
        [DocumentTemplateType.referenceTerms]: t('term.terms-of-reference'),
        [DocumentTemplateType.hiringNotice]: t('term.direct-hiring-notice'),
        [DocumentTemplateType.contractDraft]: t('term.contract-draft'),
        [DocumentTemplateType.preliminaryDesign]: t('term.preliminary-design'),
        [DocumentTemplateType.preliminaryTechnicalStudy]: t('term.preliminary-technical-study'),
        [DocumentTemplateType.executiveProject]: t('term.executive-project'),
        [DocumentTemplateType.riskMap]: t('term.risk-map'),
        [DocumentTemplateType.dod]: t('term.dod'),
        [DocumentTemplateType.terminationNotice]: t('term.termination-notice'),
        [DocumentTemplateType.addendum]: t('term.addendum'),
        [DocumentTemplateType.apostilmentTerm]: t('term.apostilment-term'),
        // [DocumentTemplateType.commitmentNote]: t('term.commitment-note'),
        [DocumentTemplateType.minutesOfPriceRegistration]: t('term.minutes-of-price-registration'),
        [DocumentTemplateType.actAuthorizingDirectContracting]: t(
            'term.act-authorizing-direct-contracting'
        ),
        [DocumentTemplateType.others]: t('term.others'),
    };

    return (
        <Grid
            key={template?._id || nanoid()}
            item
            xs={3}
            lg={4}
            sm={6}
            md={4}
            className={classes.card}
        >
            <Grid item component={Card} xs={12} className={clsx(classes.cardSpacing, classes.card)}>
                {template ? (
                    <>
                        <CardContent>
                            <Typography title={template.name} noWrap gutterBottom>
                                <b>{template.name}</b>
                            </Typography>
                            <Typography
                                title={documentTypesName[template.type]}
                                gutterBottom
                                variant='body2'
                                color='textPrimary'
                            >
                                <b>{documentTypesName[template.type]}</b>
                            </Typography>
                            <Box marginTop={2}>
                                <Typography
                                    title={template.description}
                                    variant='body2'
                                    color='textPrimary'
                                >
                                    {template.description}
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                size='small'
                                color='basicLink'
                                onClick={onCreatingTemplate}
                                title={t('term.select-model')}
                            >
                                <b>{t('term.select')}</b>
                            </Button>
                            <VisualizeIcon
                                onClick={onPreviewTemplate}
                                title={t('term.view-model')}
                            />
                        </CardActions>
                    </>
                ) : (
                    <>
                        <CardContent>
                            <Skeleton />
                            <Skeleton width='60%' />
                            <Skeleton width='100%' />
                            <Skeleton width='100%' />
                        </CardContent>
                        <CardActions>
                            <Skeleton width='70%' />
                        </CardActions>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default TemplateLibraryCard;
