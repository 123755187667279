import { styled } from 'common/theme/styled';

const MenuOptions = styled.div`
    position: absolute;
    background: #fff;
    right: 0;
    box-shadow: ${({ theme }) => theme.colors.modalShadow};
    border: 1px solid ${({ theme }) => theme.colors.borderColorDefault};
    z-index: 99;
    min-width: 180px;
    width: 180px;
`;

const Content = styled.div`
    position: relative;
`;

const Option = styled.div`
    height: 44px;
    padding: 7px 14px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorDefault};
    }

    &:hover {
        background: ${({ theme }) => theme.colors.backgroundHoverLight};
    }

    span {
        color: ${({ theme }) => theme.colors.textLight};
        font-size: 14px;
    }
`;

export { Content, Option, MenuOptions };
