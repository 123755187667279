import { FC, useCallback, useState } from 'react';
import { Box, Grid, TablePagination, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import LoadingButton from 'common/components/loading-button';
import EmptyData from 'common/components/empty-data';
import { addNotificationError, addNotificationWarning, formatCurrency } from 'common/utils';
import moment from 'moment';
import ContentTabs from 'modules/process/components/content-tabs';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { RuleRestrictSegments } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { processRules } from 'modules/process/process-utils';
import { TypeValueBidTypes } from 'clients/manager/interfaces/auction-notice.interface';
import ProcessLot from '../process-lot';
import { ProcessLotsProps } from './props';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { processActions } from '../../../../process-actions';
import ProcessLotLoading from '../process-lot/process-lot-loading';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';
import ProcessLotsActions from '../process-lots-actions';
import { integrationsPagesusRequests } from '../process-lot/services/integrations-pagesaude.requests';

const rowsPerPageOptions = [8, 16];

const ProcessLots: FC<ProcessLotsProps> = ({ currentTab, tabs, onTabChanged }) => {
    const { process, processForm, auctionTypeRules } = useProcessFormContext();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
    const [creatingLot, setCreatingLot] = useState(false);
    const { processLotsForm, fetchingLots } = useProcessLotsFormContext();
    const { t } = useTranslation();
    const { data: listOrganizationApps } = useQueryWithBody(
        integrationsPagesusRequests.listOrganizationApps
    );
    const hasSusIntegration = !!listOrganizationApps?.data?.find(
        (organizationApp) => organizationApp.integration === 'pagesaude'
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleDefaultPage = (values, keepActualPage = false) => {
        if (values.length >= rowsPerPage * page) {
            if (values.length === rowsPerPage * page || keepActualPage) return;
            const nextPage = Math.ceil(values.length / rowsPerPage) - 1;
            setPage(nextPage);
        } else {
            const previousPage = Math.floor(values.length / rowsPerPage) - 1;
            setPage(previousPage);
        }
    };

    const handleCreateLotClick = useCallback(async () => {
        if (!processForm) {
            return;
        }

        const countDraftLots = processLotsForm?.values?.lots.filter((item) => !item.id).length;

        if (countDraftLots >= 5) {
            return addNotificationWarning({
                title: t('process.components.error-add-lot'),
                message: t('process.components.error-add-lot-max'),
            });
        }

        setCreatingLot(true);

        try {
            const draftLot = {
                id: null,
                item:
                    processLotsForm?.values?.lots?.reduce(
                        (higherLotItemValue, actualLotItem) => {
                            return actualLotItem.item > higherLotItemValue.item
                                ? actualLotItem
                                : higherLotItemValue;
                        },
                        { item: 0 }
                    )?.item + 1,
                auctionNoticeId: processForm.values.id,
                markIsRequired: 0,
                showReferenceValue: 0,
                requireDocuments: 0,
                ruleRestrictBySegments: RuleRestrictSegments.free,
                forceSameDescription: 0,
                bidAmountDifference: 0.01,
                typeOfBenefit: 'noBenefit',
                itemsKits:
                    processForm.values.typeValueBid === TypeValueBidTypes.kit ? 1 : undefined,
                dateTimeInsert: '2023-07-24T13:31:00.396Z',
                lotStage: 'dispute',
                winnerProviderId: null,
                quotaId: null,
                isQuota: null,
                externalItemId: null,
                propertyOrFurnitureCode: null,
                isExclusiveToSimpleCompany: null,
                openProposals: null,
                lotDescription: null,
                status: null,
                globalValueFinal: null,
                referenceValue: null,
                justificationHideValueReferences: null,
                bidStartDate: null,
                bidRenewDate: null,
                lastBidDate: null,
                extensionTime: null,
                dateTimeUpdate: null,
                adjudicationUserId: null,
                adjudicationDateTime: null,
                homologationUserId: null,
                homologationDateTime: null,
                frustratedDataTime: null,
                frustratedUserId: null,
                desertedUserId: null,
                desertedDateTime: null,
                listRestrictSegments: null,
                isItFrustrated: null,
                isItDesert: 0,
                allowMultipleWinner: false,
                items: [
                    {
                        draftItem: processLotsForm.values.lots.length + 1,
                        auctionNoticeId: processForm.values.id,
                        id: null,
                        lotId: null,
                        item: 1,
                        dateTimeInsert: new Date().toISOString(),
                        externalItemId: null,
                        itemDescription: null,
                        unitMeasurement: null,
                        amount: null,
                        attachDatasheet: null,
                        referenceValue: null,
                        specificDocuments: null,
                        dateTimeUpdate: null,
                        pncpLink: null,
                        susCode: null,
                        susText: null,
                    },
                ],
            };

            const newValues = [...processLotsForm.values.lots];
            newValues.push(draftLot as any);
            processLotsForm.setValues({ lots: newValues });
            setCreatingLot(false);
            handleDefaultPage(newValues);
        } catch (error) {
            setCreatingLot(false);
            addNotificationError({
                title: t('process.components.error-create-lot'),
                message: t('process.components.error-create-lot-try-again'),
            });
        }
    }, [process, processForm, processLotsForm]);

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        });
    };

    const canExecuteActions = processActions.canEditUntilDispute(
        processForm?.values?.biddingStageId
    );
    const lotsValues = processLotsForm.values.lots.filter(
        (lot) => lot?.auctionNoticeId === process?.id
    );

    const canCreateLot = processActions.canCreateLot(processForm?.values);

    const RenderProcessTotalValue = () => {
        const hasItemsKits = processRules.visibleLotItemsKits(
            auctionTypeRules,
            processForm?.values
        );

        const processTotalValue = lotsValues.reduce((total, actual) => {
            const totalLotValue = actual.items.reduce(
                (sum, item) => sum + (item.amount * item.referenceValue || 0),
                0
            );

            if (hasItemsKits) {
                return total + totalLotValue * (actual?.itemsKits || 1);
            }

            return total + totalLotValue;
        }, 0);

        return (
            <Box display='flex'>
                <Typography variant='h6' style={{ fontSize: '0.875rem' }}>
                    {`${t('term.total-process-value-lote')}:`}
                </Typography>
                <Typography variant='subtitle1' style={{ fontSize: '0.875rem', marginLeft: '4px' }}>
                    {formatCurrency(processTotalValue, processForm?.values?.decimalPlaces ?? 2)}
                </Typography>
            </Box>
        );
    };

    const RenderLotView = () =>
        canCreateLot ? (
            <Box display='flex' alignItems='center'>
                <LoadingButton
                    style={{ marginRight: 16 }}
                    onClick={handleCreateLotClick}
                    color='primary'
                    size='xsmall'
                    variant='outlined'
                    {...(creatingLot
                        ? {
                              loading: {
                                  text: `${t('info.creating-lot')}..`,
                              },
                          }
                        : {})}
                >
                    <AddIcon />
                    &nbsp; {t('term.add-lot')}
                </LoadingButton>
            </Box>
        ) : null;

    const renderLotsView = () => {
        if (!process || fetchingLots) {
            // eslint-disable-next-line react/no-array-index-key
            return new Array(3).fill(null).map((_, index) => <ProcessLotLoading key={index} />);
        }

        if (!lotsValues.length) {
            return (
                <Box display='flex' justifyContent='center'>
                    <Box>
                        <EmptyData />
                        <Box display='flex' justifyContent='center'>
                            <RenderLotView />
                        </Box>
                    </Box>
                </Box>
            );
        }

        const sliced = orderBy(lotsValues, ['item', 'dateTimeInsert']).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        return (
            <>
                {sliced.map((lot, index) => {
                    const now = moment();
                    const tenSeconds = 10;
                    const secondsDiff = Math.abs(now.diff(lot.dateTimeInsert, 'seconds'));
                    const nextLot = sliced[index + 1];
                    const isLotRecentlyCreated = !nextLot && secondsDiff <= tenSeconds;
                    if (isLotRecentlyCreated) {
                        return (
                            <ProcessLot
                                key={lot.id ?? lot?.item}
                                lot={lot as any}
                                isVisible
                                handleDefaultPage={handleDefaultPage}
                                lotItem={index + 1 + page * rowsPerPage}
                                hasSusIntegration={hasSusIntegration}
                            />
                        );
                    }
                    return (
                        <ProcessLot
                            key={lot.id ?? lot?.item}
                            lot={lot as any}
                            handleDefaultPage={handleDefaultPage}
                            lotItem={index + 1 + page * rowsPerPage}
                            hasSusIntegration={hasSusIntegration}
                        />
                    );
                })}
                <Box
                    mt={2}
                    display='flex'
                    alignItems='center'
                    justifyContent={
                        // eslint-disable-next-line no-nested-ternary
                        canCreateLot
                            ? 'space-between'
                            : lotsValues.length > rowsPerPageOptions[0]
                            ? 'space-between'
                            : 'center'
                    }
                >
                    <RenderLotView />
                    <RenderProcessTotalValue />
                    {lotsValues.length > rowsPerPageOptions[0] ? (
                        <TablePagination
                            component='div'
                            rowsPerPageOptions={rowsPerPageOptions}
                            count={lotsValues.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t('text.lines-per-page')}
                        />
                    ) : null}
                </Box>
            </>
        );
    };

    return (
        <>
            <Box
                width={1}
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                style={{
                    position: 'sticky',
                    top: '-16px',
                    zIndex: 99,
                    backgroundColor: '#FFF',
                }}
            >
                <Grid container>
                    <Grid item xs={9}>
                        <ContentTabs
                            tabs={tabs}
                            currentTab={currentTab}
                            onTabChanged={onTabChanged}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {canExecuteActions && (
                            <ProcessLotsActions handleDefaultPage={handleDefaultPage} />
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box>{renderLotsView()}</Box>
        </>
    );
};

export default ProcessLots;
