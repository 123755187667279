import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { HiPause } from 'react-icons/hi';
import { TypeCancelEnum } from 'common/enums/type-cancel.enum';
import { Chip } from '@material-ui/core';
import { useStyles } from './styles';

interface ChipSuspendedStatusProps {
    typeCancel: string;
}

const ChipSuspendedStatus: FC<ChipSuspendedStatusProps> = ({ typeCancel }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            {typeCancel === TypeCancelEnum.suspended ? (
                <Chip
                    onClick={(event) => event.stopPropagation()}
                    className={clsx(classes.chip, classes.alertBackgroud)}
                    icon={<HiPause />}
                    size='small'
                    color='primary'
                    classes={{ labelSmall: classes.withoutLabel }}
                    title={`${t('term.suspended')}`}
                />
            ) : undefined}
        </>
    );
};

export default ChipSuspendedStatus;
