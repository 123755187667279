import styled from 'styled-components';

type SearchFiltersAsideContainerProps = {
    showAdvancedFilters?: boolean;
};

type AdvancedFiltersContainerProps = {
    disabled?: boolean;
};

export const SearchFiltersAsideContainer = styled.aside<SearchFiltersAsideContainerProps>`
    display: ${({ showAdvancedFilters }) => (showAdvancedFilters ? 'block' : 'none')};
    width: 336px;
    height: fit-content;
    max-height: 80vh;
    top: 122px;
    z-index: 99;
    padding: 16px 12px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    overflow-y: auto;
    position: sticky;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #0001;
        border-radius: 2px;
        transition: 0.4s;
    }

    @media (max-width: 1100px) {
        width: 100%;

        > div {
            width: 100%;
        }
    }
`;

export const AdvancedFiltersContainer = styled.div<AdvancedFiltersContainerProps>`
    padding: 16px 0;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
    transition: opacity 0.4s;
    flex-direction: column;
    user-select: none;
    display: flex;
    gap: 16px;
`;
