import { FC, useEffect, useState } from 'react';
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { SupplyCategory } from '../../../../../clients/manager/interfaces/supply-categories.interface';
import { supplyCategoriesRequests } from '../../../../../clients/manager/supply-categories.requests';

export interface SupplyCategoriesListProps {
    selectedSupplyCategoryId: number | undefined;
    onSelectSupplyCategory: (supplyCategory: SupplyCategory | undefined) => void;
}

const SupplyCategoriesList: FC<SupplyCategoriesListProps> = ({
    selectedSupplyCategoryId,
    onSelectSupplyCategory,
}) => {
    const [supplyCategories, setSupplyCategories] = useState<SupplyCategory[]>([]);
    const classes = useStyles();
    const { t } = useTranslation();

    const getSupplyCategories = async () => {
        try {
            const response = await supplyCategoriesRequests.listSupplyCategories({
                params: {
                    level: 1,
                },
            });
            setSupplyCategories(response?.data ?? []);
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    useEffect(() => {
        getSupplyCategories();
    }, []);

    return (
        <Box className={classes.root}>
            <List component='nav' disablePadding>
                <Box paddingLeft={1} marginBottom={1}>
                    <Typography variant='body2' color='textPrimary'>
                        <b>{t('term.supply-lines')}</b>
                    </Typography>
                </Box>
                <Divider variant='middle' />
                <ListItem
                    button
                    title={t('term.all')}
                    selected={!selectedSupplyCategoryId}
                    onClick={() => onSelectSupplyCategory(undefined)}
                >
                    <ListItemText
                        disableTypography
                        className={classes.listItemText}
                        primary={t('term.all')}
                    />
                </ListItem>
                {supplyCategories?.length
                    ? supplyCategories.map((option) => (
                          <Box key={option.id}>
                              <ListItem
                                  button
                                  title={option.categoryName}
                                  selected={selectedSupplyCategoryId === option.id}
                                  onClick={() => onSelectSupplyCategory(option)}
                              >
                                  <ListItemText
                                      disableTypography
                                      className={classes.listItemText}
                                      primary={option.categoryName}
                                  />
                              </ListItem>
                              <Divider light />
                          </Box>
                      ))
                    : Array.from(new Array(5)).map((_, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <ListItem button key={index}>
                              <Skeleton width='100%' />
                          </ListItem>
                      ))}
            </List>
        </Box>
    );
};

export default SupplyCategoriesList;
