import { managerApiInstance } from 'clients/api';

const BASE_PATH = '/holidays';

const listHolidaysOrganization = (name) =>
    managerApiInstance.post(`${BASE_PATH}/listHolidaysOrganization`, { name });

const doSave = (values) => managerApiInstance.post(`${BASE_PATH}/doSave`, values);

const doDelete = (id) => managerApiInstance.post(`${BASE_PATH}/doDelete`, { id });

const HolidaysService = { listHolidaysOrganization, doSave, doDelete };

export default HolidaysService;
