import { Grid, FormControlLabel, MenuItem, RadioGroup, Typography, Radio } from '@material-ui/core';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import GenericError from 'common/components/generic-error';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import PersonPhoneInput from 'common/components/phone-input';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import { CONSTANTS } from 'common/constants';
import { Container } from './styles';

export default function ProviderAddModalView({
    modalOpen,
    setModalAsInitialState,
    legalRepresentativeForm,
    loading,
    hasAlreadySearchedCpf,
    cpfExist,
    showModalAllFields,
    showCpfFields,
    verifyIfCpfExist,
    hasCpfCorrectLength,
    hasError,
    cpfMessageError,
    setCountry,
    setLoading,
    setHasError,
    setCpfMessageError,
    resetSearch,
    country,
    provider,
}) {
    const { t } = useTranslation();
    const { IBGEcountries } = useIBGECountries();

    const countryOnChange = (e) => {
        if (e.target.value === CONSTANTS.ibgeCodes.BRAZIL) {
            legalRepresentativeForm.setFieldValue(
                'cpf',
                legalRepresentativeForm.values.cpf?.slice(0, 11)
            );
        }
        setCountry(e.target.value);
        setLoading(false);
        setHasError(false);
        setCpfMessageError('');
        resetSearch();
        legalRepresentativeForm.setFieldValue('country', e.target.value);
    };

    return (
        <Container>
            <Modal
                style={{ maxWidth: 485, margin: '0 auto' }}
                position={ModalPosition.center}
                open={modalOpen}
                onClose={() => {
                    setModalAsInitialState();
                    legalRepresentativeForm.resetForm();
                }}
                header={
                    <Typography style={{ color: 'white' }} variant='h5'>
                        {t('sign-up.provider.registration-of-legal-responsible')}
                    </Typography>
                }
            >
                {hasError ? (
                    <GenericError />
                ) : (
                    <form onSubmit={legalRepresentativeForm.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label={t('term.country')}
                                    fullWidth
                                    name='country'
                                    value={legalRepresentativeForm.values.country}
                                    onChange={countryOnChange}
                                    error={
                                        legalRepresentativeForm.touched.country &&
                                        !!legalRepresentativeForm.errors.country
                                    }
                                    helperText={
                                        legalRepresentativeForm.touched.country &&
                                        legalRepresentativeForm.errors.country
                                    }
                                >
                                    {IBGEcountries.map(({ code, name }) => (
                                        <MenuItem key={code} value={code}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <IdentificationNumberTextField
                                    identificationNumber={legalRepresentativeForm.values.cpf}
                                    country={legalRepresentativeForm.values.country}
                                    resetSearch={resetSearch}
                                    fieldName='cpf'
                                    type='cpf'
                                    {...legalRepresentativeForm}
                                />
                                {!!cpfMessageError.length && (
                                    <Typography color='error' style={{ marginTop: '8px' }}>
                                        {t('sign-up.provider.registration-error-invalid-document')}
                                    </Typography>
                                )}
                                {cpfExist && (
                                    <Typography color='secondary'>
                                        {t('sign-up.provider.registration-sucess')}
                                    </Typography>
                                )}
                            </Grid>

                            {hasAlreadySearchedCpf && (
                                <>
                                    {!showCpfFields && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label={t('term.full-name')}
                                                    name='name'
                                                    onChange={legalRepresentativeForm.handleChange}
                                                    value={legalRepresentativeForm.values.name}
                                                    error={
                                                        legalRepresentativeForm.touched.name &&
                                                        Boolean(legalRepresentativeForm.errors.name)
                                                    }
                                                    helperText={
                                                        legalRepresentativeForm.touched.name &&
                                                        legalRepresentativeForm.errors.name
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <PhoneInput
                                                    flags={flags}
                                                    placeholder={t('term.phone')}
                                                    value={legalRepresentativeForm.values.phone}
                                                    name='phone'
                                                    defaultCountry='BR'
                                                    onChange={(e) => {
                                                        legalRepresentativeForm.setFieldValue(
                                                            'phone',
                                                            e
                                                        );
                                                    }}
                                                    error={
                                                        legalRepresentativeForm.touched.phone &&
                                                        Boolean(
                                                            legalRepresentativeForm.errors.phone
                                                        )
                                                    }
                                                    helperText={
                                                        legalRepresentativeForm.touched.phone &&
                                                        legalRepresentativeForm.errors.phone
                                                    }
                                                    inputComponent={PersonPhoneInput}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label={t('term.email')}
                                                    name='email'
                                                    onChange={legalRepresentativeForm.handleChange}
                                                    value={legalRepresentativeForm.values.email}
                                                    error={
                                                        legalRepresentativeForm.touched.email &&
                                                        Boolean(
                                                            legalRepresentativeForm.errors.email
                                                        )
                                                    }
                                                    helperText={
                                                        legalRepresentativeForm.touched.email &&
                                                        legalRepresentativeForm.errors.email
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {(showCpfFields || showModalAllFields) && (
                                        <>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label={t('term.responsibility')}
                                                    name='job'
                                                    onChange={legalRepresentativeForm.handleChange}
                                                    value={legalRepresentativeForm.values.job}
                                                    error={
                                                        legalRepresentativeForm.touched.job &&
                                                        Boolean(legalRepresentativeForm.errors.job)
                                                    }
                                                    helperText={
                                                        legalRepresentativeForm.touched.job &&
                                                        legalRepresentativeForm.errors.job
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label='RG'
                                                    name='identityDoc'
                                                    onChange={legalRepresentativeForm.handleChange}
                                                    value={
                                                        legalRepresentativeForm.values.identityDoc
                                                    }
                                                    error={
                                                        legalRepresentativeForm.touched
                                                            .identityDoc &&
                                                        Boolean(
                                                            legalRepresentativeForm.errors
                                                                .identityDoc
                                                        )
                                                    }
                                                    helperText={
                                                        legalRepresentativeForm.touched
                                                            .identityDoc &&
                                                        legalRepresentativeForm.errors.identityDoc
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    row
                                                    aria-label='position'
                                                    name='originOfFinancialResource'
                                                    onChange={(e) =>
                                                        legalRepresentativeForm.setFieldValue(
                                                            'typeOfRepresentative',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                value='administrator'
                                                                color='primary'
                                                            />
                                                        }
                                                        label={t('term.administrator')}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Radio value='proxy' color='primary' />
                                                        }
                                                        label={t('term.proxy')}
                                                    />
                                                    {(country !== CONSTANTS?.ibgeCodes?.BRAZIL ||
                                                        (provider &&
                                                            provider?.country !==
                                                                CONSTANTS?.ibgeCodes?.BRAZIL)) && (
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    value='nationalRepresentative'
                                                                    color='primary'
                                                                />
                                                            }
                                                            label={t('term.nationalRepresentative')}
                                                        />
                                                    )}
                                                </RadioGroup>
                                                <Typography color='error'>
                                                    {
                                                        legalRepresentativeForm.errors
                                                            .typeOfRepresentative
                                                    }
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid container justifyContent='flex-end'>
                            <Grid>
                                {hasAlreadySearchedCpf ? (
                                    <Button
                                        style={{ marginTop: 20 }}
                                        className='btn-add'
                                        size='small'
                                        type='submit'
                                        color='primary'
                                        variant='contained'
                                        disabled={loading}
                                    >
                                        {t('term.add')}
                                    </Button>
                                ) : (
                                    <Button
                                        style={{ marginTop: 20 }}
                                        className='btn-add'
                                        size='small'
                                        color='primary'
                                        variant='contained'
                                        onClick={verifyIfCpfExist}
                                        disabled={hasCpfCorrectLength || loading}
                                    >
                                        {t('term.search-representative')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Modal>
        </Container>
    );
}
