import React from 'react';
import { Grid, Card, CardActions, MenuItem, Typography } from '@material-ui/core';
import { MaskedTextField, TextField } from 'common/components';
import CodeValidator from 'common/components/code-validator';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import LoadingButton from 'common/components/loading-button';
import { ProfileGeneralDataValues } from './profile-general-data';

interface ProfileGeneralDataViewProps {
    form: FormikProps<ProfileGeneralDataValues>;
    openCodeValidator: boolean;
    onCloseValidationModal: () => void;
}

const ProfileGeneralDataView: React.FC<ProfileGeneralDataViewProps> = ({
    form,
    onCloseValidationModal,
    openCodeValidator = false,
}) => {
    const { t } = useTranslation();

    const hideSomeValuesToMask = (value: string) => {
        return `${value.substring(0, 3)}${value.substring(9)}`;
    };

    return (
        <Card>
            <CodeValidator
                email={form.values.email}
                open={openCodeValidator}
                onSubmit={async (values) => {
                    await form.setFieldValue('verificationCode', values.verificationCode, false);
                    onCloseValidationModal();
                    form.handleSubmit();
                }}
                onClose={onCloseValidationModal}
            />
            <form onSubmit={form.handleSubmit} noValidate>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Typography variant='h6'>{t('process.components.general-data')}</Typography>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <MaskedTextField
                                disabled
                                fullWidth
                                mask='999.XXX.XXX-99'
                                label={t('term.cpf')}
                                value={hideSomeValuesToMask(form.values.cpf)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                label={t('term.name')}
                                name='name'
                                value={form.values.name}
                                onChange={form.handleChange}
                                error={form.touched.name && !!form.errors.name}
                                helperText={form.touched.name && form.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label={t('term.email')}
                                name='email'
                                value={form.values.email}
                                onChange={form.handleChange}
                                error={form.touched.email && !!form.errors.email}
                                helperText={form.touched.email && form.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MaskedTextField
                                fullWidth
                                label={t('term.phone')}
                                mask='(99) 99999-9999'
                                name='phone'
                                value={form.values.phone}
                                onChange={form.handleChange}
                                error={form.touched.phone && !!form.errors.phone}
                                helperText={form.touched.phone && form.errors.phone}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                label={t('system.i18n.language')}
                                name='language'
                                value={form.values.language}
                                onChange={form.handleChange}
                                error={form.touched.language && !!form.errors.language}
                                helperText={form.touched.language && form.errors.language}
                            >
                                <MenuItem value='pt-BR'>{t('system.i18n.language.pt')}</MenuItem>
                                <MenuItem value='en'>{t('system.i18n.language.en')}</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    <CardActions style={{ padding: 8, justifyContent: 'flex-end' }}>
                        <LoadingButton
                            type='submit'
                            size='small'
                            variant='contained'
                            disabled={form.isSubmitting}
                            loading={
                                form.isSubmitting ? { text: `${t('term.loading')}...` } : undefined
                            }
                        >
                            {t('term.save')}
                        </LoadingButton>
                    </CardActions>
                </Grid>
            </form>
        </Card>
    );
};

export default ProfileGeneralDataView;
