import { OrderItemStatusEnum, OrderStatusEnum } from 'common/enums';
import i18n from 'common/i18n';

export function getOrderStatusLabel(orderStatus: string): string {
    const mapper = {
        [OrderStatusEnum.OPENED]: i18n.t('term.open'),
        [OrderStatusEnum.CANCELED]: i18n.t('term.canceled'),
        [OrderStatusEnum.FINISHED]: i18n.t('term.finished'),
        [OrderStatusEnum.JUDGED]: i18n.t('term.judged'),
    };

    return mapper[orderStatus] ?? '--';
}

export function getOrderItemStatusLabel(orderStatus: string): string {
    const mapper = {
        [OrderItemStatusEnum.PENDING]: i18n.t('term.pending'),
        [OrderItemStatusEnum.STARTED]: i18n.t('term.started'),
        [OrderItemStatusEnum.FINISHED]: i18n.t('term.judged'),
        [OrderItemStatusEnum.FRUSTRATED]: i18n.t('term.failed'),
    };

    return mapper[orderStatus] ?? '--';
}
