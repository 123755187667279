import { FC } from 'react';
import { RuleRestrictSegments } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { AuctionLotFormValues } from 'modules/process/context/lots.form';
import { useTranslation } from 'react-i18next';
import HelpIconLabel from 'modules/process/components/help-icon-label';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';

interface RuleRestrictSegmentFieldProps {
    values: AuctionLotFormValues;
    disabled?: boolean;
}

const RuleRestrictSegmentField: FC<RuleRestrictSegmentFieldProps> = ({ values, disabled }) => {
    const { setFieldValue } = useProcessLotsFormContext();
    const { t } = useTranslation();

    return (
        <HelpIconLabel
            title={
                <>
                    <p>{t('info.restrict-segments-label')}</p>
                </>
            }
        >
            <TextField
                fullWidth
                color='primary'
                label={t('info.participation-restriction')}
                name='ruleRestrictBySegments'
                onChange={(event) =>
                    setFieldValue(values, 'ruleRestrictBySegments', event.target.value)
                }
                variant='outlined'
                select
                disabled={disabled}
                defaultValue={values.ruleRestrictBySegments}
            >
                <MenuItem value={RuleRestrictSegments.free}>{t('info.no-retriction')}</MenuItem>
                <MenuItem value={RuleRestrictSegments.and}>
                    {t('info.require-all-segments')}
                </MenuItem>
                <MenuItem value={RuleRestrictSegments.or}>
                    {t('info.require-at-least-segments')}
                </MenuItem>
            </TextField>
        </HelpIconLabel>
    );
};

export default RuleRestrictSegmentField;
