import i18n from 'i18next';
import { FormikProps } from 'formik';
import { AuctionLotWithItemsForm } from '../../../../context/lots.form';

const getFieldLabel = (field: string): string | undefined =>
    ({
        itemDescription: i18n.t('term.item-description'),
        unitMeasurement: i18n.t('term.unit-of-measurement'),
        amount: i18n.t('term.amount'),
        referenceValue: i18n.t('term.unit-value-2'),
        typeOfBenefit: i18n.t('term.benefit-type'),
        quotaId: i18n.t('term.master-lot'),
        ruleRestrictBySegments: i18n.t('info.participation-restriction'),
        listRestrictSegments: i18n.t('term.supply-segments'),
        typeOfItems: i18n.t('term.itens-type'),
        bidAmountDifference: i18n.t('process.components.value-between-bids'),
        lotDescription: i18n.t('term.lot-description'),
        items: i18n.t('term.items'),
        propertyOrFurnitureCode: i18n.t('term.estate-or-heritage-code'),
        itemsKits: i18n.t('term.lot-quantity'),
    }[field]);

const getFormErrorsLabels = (form: FormikProps<AuctionLotWithItemsForm>, lotId: number) => {
    const foundLotIndex = form.values.lots.findIndex((formLot) => formLot.id === lotId);
    const errors = form.errors?.lots?.[foundLotIndex];

    if (!errors) {
        return <></>;
    }

    const toRender: JSX.Element[] = [];
    const toRenderItems: JSX.Element[] = [];

    Object.keys(errors).forEach((key) => {
        if (key === 'items' && Array.isArray(errors[key])) {
            (errors?.[key] as any)?.forEach((item, index) => {
                if (item) {
                    toRenderItems.push(
                        <p>
                            <span>{`Item ${String(index + 1).padStart(2, '0')}:`}</span>
                        </p>
                    );
                    Object.keys(item)?.forEach((itemKey) => {
                        toRenderItems.push(
                            <div>
                                <span>{getFieldLabel(itemKey)}</span>
                                {' : '}
                                <span>{item[itemKey]}</span>
                            </div>
                        );
                    });
                }
            });
        } else {
            toRender.push(
                <div>
                    <span>
                        {getFieldLabel(key)}
                        {' : '}
                        {errors[key]}
                    </span>
                </div>
            );
        }
    });

    return (
        <>
            {toRender}
            <p>{toRenderItems}</p>
        </>
    );
};

export { getFormErrorsLabels };
