/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { BreadcrumbProps } from './props';
import { Content, Link, Separator } from './styled';

export type BreadcrumbPath = { value: string; onClick?: () => void; disabled?: boolean };

const Breadcrumb: FC<BreadcrumbProps> = ({ params, paths, customPath }) => {
    const [parts, setParts] = useState<{ raw: string[]; custom: string[] }>({
        raw: [],
        custom: [],
    });

    const { path } = useRouteMatch();
    const history = useHistory();
    const routeParams: { [key: string]: string } = useParams();

    const generate = () => {
        const raw = path.split('/').filter((part) => part !== '');
        setParts({
            custom: (customPath ?? path).split('/').filter((part) => part !== ''),
            raw,
        });
    };

    useEffect(() => {
        generate();
    }, [params, paths, customPath]);

    const redirect = (position: number) => {
        let currPath: string = '/';
        parts.raw.forEach((part, index) => {
            if (position >= index) {
                if (part.startsWith(':')) {
                    const text = routeParams?.[part.split(':')[1]] || '..';
                    currPath += `${text}/`;
                    return;
                }
                currPath += `${part}/`;
            }
        });
        history.push({
            pathname: currPath,
        });
    };

    const getPartValue = (part: string | BreadcrumbPath | undefined) =>
        typeof part === 'object' ? part.value : part;

    const getPartAction = (part: string | BreadcrumbPath | undefined) =>
        typeof part === 'object' ? part.onClick : undefined;

    const mount = useCallback(() => {
        const els: any[] = [];

        parts.custom.forEach((part, index) => {
            if (part.startsWith(':')) {
                const param = part.split(':')[1];
                const text = getPartValue(params?.[param]) || '..';
                let action: any = getPartAction(params?.[param]) || redirect;

                if ((params?.[param] as BreadcrumbPath)?.disabled) {
                    action = undefined;
                }

                els.push(
                    <Link
                        key={index}
                        disabled={!action}
                        title={text}
                        onClick={() => action?.(index)}
                    >
                        {text}
                    </Link>
                );
            } else {
                const text = getPartValue(paths[part]) || '..';
                let action: any = getPartAction(paths[part]) ?? redirect;

                if ((paths[part] as BreadcrumbPath)?.disabled) {
                    action = undefined;
                }
                els.push(
                    <Link
                        key={index}
                        disabled={!action}
                        title={text}
                        onClick={() => action?.(index)}
                    >
                        {text}
                    </Link>
                );
            }

            if (index !== parts.custom.length - 1) {
                els.push(<Separator key={nanoid()}> / </Separator>);
            }
        });
        return els;
    }, [params, parts, paths, routeParams]);

    return (
        <Content>
            <p>{mount()}</p>
        </Content>
    );
};

export default Breadcrumb;
