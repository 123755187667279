export enum ProcessRelatoryType {
    minutePublicationExtract = 1,
    minuteDisputeRoom = 2,
    minuteOfProposals = 3,
    enableSuppliers = 4,
    adjudicationTerm = 5,
    homologationTerm = 6,
    minuteAccreditation = 7,
    minuteDesert = 8,
    minuteFrustrated = 9,
}
