import { IconButton, TableCell } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const TableCellHiddenView = ({ isCpfVisible, cpf, toggleCpfVisibility, ...props }) => {
  return (
    <TableCell {...props}>
      {isCpfVisible ? cpf : '***.***.***-**'}
      <IconButton onClick={toggleCpfVisibility}>
        {isCpfVisible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </TableCell>
  );
};

export default TableCellHiddenView;
