import { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Button, ButtonProps } from 'common/components';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            color: '#a1a1a1',
        },
        xsmall: {
            width: '22px !important',
            height: '22px !important',
        },
        small: {
            width: '28px !important',
            height: '28px !important',
        },
        medium: {
            width: '32px',
            height: '32px',
        },
    })
);

const LoadingButton: FC<ButtonProps & { loading?: { text: string } }> = ({ loading, ...rest }) => {
    const classes = useStyles();

    if (loading) {
        return (
            <Button {...rest} disabled>
                {loading.text} &nbsp;
                <CircularProgress
                    className={clsx(classes.root, classes[`${rest.size ?? 'small'}`])}
                />
            </Button>
        );
    }
    return <Button {...rest} />;
};

export default LoadingButton;
