export enum AuctionTypeEnum {
    trading = 'E', // Pregão
    dispense = 'D', // Dispensa
    accreditation = 'C', // Credenciamento
    auction = 'L', // Leilão
    concurrence = 'R', // Concorrência
    unenforceability = 'I', // Inexigibilidade
    competitiveDialogue = 'T', // Diálogo Competitivo
    contest = 'U', // Concurso
    inPersonConcurrence = 'P', // Concorrência Presencial
    inPersonTrading = 'A', // Pregão Presencial
    expressionOfInterest = 'M', // Manifestação de Interesse
    preQualification = 'Q', // Pré-Qualificação
    inPersonAuction = 'N', // Leilão Presencial
    biddingInapplicability = 'B', // Inaplicabilidade da Licitação
}
