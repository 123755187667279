import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { useHistory } from 'react-router';
import SearchOrganizationUsersView from './search-organization-users-view';

const SearchOrganizationUsers = () => {
    const { setTitle } = usePageContext();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        setTitle(t('organization-users.pages.components.users-unit'));
    }, []);

    const handleNew = () => {
        history.push('/usuarios-organizacao/novo');
    };

    return <SearchOrganizationUsersView {...{ handleNew }} />;
};

export default SearchOrganizationUsers;
