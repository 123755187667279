import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from '../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { CompleteSupplyCategoriesProviders } from './interfaces/supply-categories-providers.interface';

const path = '/supply-categories-providers';

const supplyCategoriesProvidersRequests = {
    isRequiredManufacturerIdentification: async (supplyCategoryId: number) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/isRequiredManufacturerIdentification`, {
                supplyCategoryId: supplyCategoryId,
            })
        ),
    listSupplyCategoriesProviders: async (idProvider: number) =>
        doRequest<GenericResponse<CompleteSupplyCategoriesProviders>>(
            managerApiInstance.post(`${path}/listSupplyCategoriesProviders`, {
                providerId: idProvider,
            })
        ),
    deleteSupplyCategoriesProviders: async (supplyCategoryId: number, providerId: number) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/deleteSupplyCategoriesProviders`, {
                supplyCategoryId,
                providerId,
            })
        ),
    createSupplyCategoriesProviders: async (providerId: number, supplyCategoryIds: number[]) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/createSupplyCategoriesProviders`, {
                providerId,
                supplyCategoryIds,
            })
        ),
    createSupplyCategoriesProvidersCemig: async (body: any) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/createSupplyCategoriesProvidersCemig`, body)
        ),
};

export { supplyCategoriesProvidersRequests };
