import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    input: {
        width: '100%',
        padding: theme.spacing(0.7),
        fontSize: '14px',
        border: `1px ${theme.palette.grey[400]} solid`,
    },
    textArea: {
        width: '100%',
        padding: theme.spacing(1.3),
        fontSize: '14px',
        border: `1px ${theme.palette.grey[400]} solid`,
    },
    table: {
        '& .MuiTableCell-body': {
            padding: theme.spacing(1),
        },
        '& .MuiTableCell-root': {
            padding: theme.spacing(1),
        },
    },
}));
