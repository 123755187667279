import moment from 'moment';
import { useState } from 'react';
import { citizenDashboardRequests } from 'modules/citizen-dashboard/services/citizen-dashboard.requests';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { GetNextDisputesBody } from 'modules/citizen-dashboard/interfaces/next-disputes.interface';
import NextDisputesView from './next-disputes-view';

const NextDisputes = () => {
    const [nextDisputesBody, setNextDisputesBody] = useState<GetNextDisputesBody>({
        date: moment().format('yyyy-MM-DD'),
    });

    const { data: processData, loading } = useQueryWithBody(
        citizenDashboardRequests.getNextDisputes,
        nextDisputesBody
    );
    const process = processData?.data;

    return (
        <NextDisputesView
            {...{ process, date: nextDisputesBody?.date, setNextDisputesBody, loading }}
        />
    );
};

export default NextDisputes;
