import i18n from 'i18next';
import moment from 'moment';

type DateInput = string | number | Date | moment.MomentInput;
type DateFormatOptions =
    | 'LLL' // February 17, 2023 10:46 AM
    | 'L LT' // 02/17/2023 10:47 AM
    | 'L [-] LT' // 02/17/2023 10:47 AM
    | 'L' // 02/17/2023
    | 'LT' // 10:47 AM
    | 'YYYY-MM-DD' // 2023-02-17
    | 'YYYY/MM/DD' // 2023/02/17
    | 'DD/MM/YYYY' // 17/02/2023
    | 'DD/MM/YY' // 17/02/23
    | 'DD/MM/YY HH:mm' // 17/02/24 10:47
    | 'DD/MM HH:mm' // 17/02 10:47
    | 'DD/MM/YYYY HH:mm:ss'; // 17/02/24 10:47:22

moment.updateLocale('en', {
    longDateFormat: {
        LTS: 'h:mm:ss A',
        LT: 'h:mm A',
        L: 'MM/DD/YYYY',
        LL: 'MMMM D, YYYY',
        LLL: 'MMMM D, YYYY h:mm A',
        LLLL: 'dddd, MMMM D, YYYY h:mm A',
    },
});

const UTC_OFFSET_IN_HOURS = -3;

function formatDate(date: DateInput, format: DateFormatOptions = 'L') {
    moment.locale(i18n.language);
    return moment(date).utcOffset(UTC_OFFSET_IN_HOURS).format(format);
}

function formatDateDistance(date: DateInput) {
    moment.locale(i18n.language);
    return moment(date).utcOffset(UTC_OFFSET_IN_HOURS).fromNow();
}

export { formatDate, formatDateDistance };
