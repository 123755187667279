import { useMarketplaceContext } from 'modules/marketplace/context/marketplace.context';
import React from 'react';
import { useHistory } from 'react-router';
import HomeHeaderView from './home-header-view';

interface HomeHeaderProps {
    quantityOfItemsOnCart?: number;
}

const HomeHeader: React.FC<HomeHeaderProps> = ({ quantityOfItemsOnCart = 0 }) => {
    const { setFilters } = useMarketplaceContext();
    const history = useHistory();

    const handleOnSearch = (searchTerm: string) => {
        setFilters((state) => ({ ...state, searchTerm }));
    };

    const handleOnCartButtonClick = () => {
        history.push('/mercado/carrinho');
    };

    return (
        <HomeHeaderView
            onSearch={handleOnSearch}
            onCartButtonClick={handleOnCartButtonClick}
            quantityOfItemsOnCart={quantityOfItemsOnCart}
        />
    );
};

export default HomeHeader;
