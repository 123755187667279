import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Notifications } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import { useStyles } from './styles';

interface ChipSolicitationsCountProps {
    count: number | string;
}

const ChipSolicitationsCount: FC<ChipSolicitationsCountProps> = ({ count }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            {count ? (
                <Chip
                    onClick={(event) => event.stopPropagation()}
                    className={classes.chip}
                    icon={<Notifications className={classes.warningNotification} />}
                    label={count}
                    color='default'
                    size='small'
                    title={`${count} ${t('term.solicitations')}`}
                />
            ) : undefined}
        </>
    );
};

export default ChipSolicitationsCount;
