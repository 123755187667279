import {
    memo,
    createContext,
    ReactNode,
    useState,
    SetStateAction,
    Dispatch,
    useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    NoticeInformation,
    UploadFileNoticeInformationResponse,
} from 'clients/manager/interfaces/notice-information.interfaces';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { TypeAccess } from 'common/interfaces/user.interface';

enum AudienceEnum {
    'public' = 'public',
    'provider' = 'provider',
    'citizen' = 'citizen',
    'management' = 'management',
    'organization' = 'organization',
}

type AudienceEnumType = {
    id: string;
    name: string;
};

export interface NoticeInformationForm extends NoticeInformation {
    informationFiles?: UploadFileNoticeInformationResponse[];
}

interface NoticesInformationContextProps {
    audienceEnum: AudienceEnumType[];
    noticesInformation: NoticeInformation[];
    setNoticesInformation: Dispatch<SetStateAction<NoticeInformation[]>>;
    authenticatedUserIsAdmin: boolean;
    noticeInformationIdForEdit: number | undefined;
    setNoticeInformationIdForEdit: Dispatch<SetStateAction<number | undefined>>;
    noticeInformationSelectedForEdit: NoticeInformation | undefined;
}

export const NoticesInformationContext = createContext({} as NoticesInformationContextProps);

const NoticesInformationProvider = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation();
    const [noticesInformation, setNoticesInformation] = useState<NoticeInformation[]>([]);
    const [noticeInformationIdForEdit, setNoticeInformationIdForEdit] = useState<number>();
    const [noticeInformationSelectedForEdit, setNoticeInformationSelectedForEdit] =
        useState<NoticeInformation>();

    const authenticatedUserIsAdmin: boolean = useSelector(
        (state: AppState) =>
            state.authUserState.authUserTokenData?.typeAccess === TypeAccess.management || false
    );

    const audienceEnum = [
        {
            id: AudienceEnum.public,
            name: t('term.public'),
        },
        {
            id: AudienceEnum.provider,
            name: t('term.providers'),
        },
        {
            id: AudienceEnum.citizen,
            name: t('term.citizen'),
        },
        {
            id: AudienceEnum.management,
            name: t('term.management'),
        },
        {
            id: AudienceEnum.organization,
            name: t('term.organization'),
        },
    ];

    useEffect(() => {
        if (noticeInformationIdForEdit) {
            setNoticeInformationSelectedForEdit(
                noticesInformation.find(
                    (noticeInformation) => noticeInformation.id === noticeInformationIdForEdit
                )
            );
        } else {
            setNoticeInformationSelectedForEdit(undefined);
        }
    }, [noticeInformationIdForEdit, noticesInformation]);

    return (
        <NoticesInformationContext.Provider
            value={{
                audienceEnum,
                noticesInformation,
                setNoticesInformation,
                authenticatedUserIsAdmin,
                noticeInformationIdForEdit,
                setNoticeInformationIdForEdit,
                noticeInformationSelectedForEdit,
            }}
        >
            {children}
        </NoticesInformationContext.Provider>
    );
};

export default memo(NoticesInformationProvider);
