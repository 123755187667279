import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Modal, ModalPosition, TextField } from 'common/components';
import { DebouncedFunc } from 'lodash';
import { ListSusCodeResponse } from '../../interfaces/integrations-pagesaude.interface';

interface SearchSusCodeModalViewProps {
    loading: boolean;
    value?: ListSusCodeResponse['data'][0] | null;
    options: ListSusCodeResponse['data'];
    onChange: (value: ListSusCodeResponse['data'][0] | null) => Promise<void>;
    open?: boolean;
    onClose?: () => void;
    debounceSearchSus: DebouncedFunc<(text: string) => void>;
}

const SearchSusCodeModalView: React.FC<SearchSusCodeModalViewProps> = ({
    options,
    loading,
    onChange,
    value,
    open = false,
    onClose,
    debounceSearchSus,
}) => {
    return (
        <Modal
            position={ModalPosition.center}
            open={open}
            onClose={(event: any) => {
                event.stopPropagation();
                onClose?.();
            }}
            header={<span style={{ width: '500px' }}>Códigos de Serviços do SUS</span>}
        >
            <div style={{ height: '200px' }}>
                <Autocomplete
                    fullWidth
                    disabled={loading}
                    value={value}
                    options={options ?? []}
                    onChange={async (_, value) => {
                        await onChange(value);
                    }}
                    getOptionLabel={(option) => `${option?.susCode} - ${option?.susText}`}
                    renderInput={(params) => (
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                debounceSearchSus(event.target.value?.toLowerCase())
                            }
                            {...params}
                            label='Pesquisa por descrição'
                            placeholder='Digite aqui'
                            InputProps={{
                                ...params?.InputProps,
                                endAdornment: loading && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                        />
                    )}
                />
            </div>
        </Modal>
    );
};

export default SearchSusCodeModalView;
