import React from 'react';
import { Typography } from '@material-ui/core';
import { formatCountdown } from 'common/utils';
import { useTranslation } from 'react-i18next';
import useStyles from './resend-code-countdown-style';

interface ResendCodeCountdownViewProps {
    countdown?: number;
    onResendCode?: () => void;
}

const ResendCodeCountdownView: React.FC<ResendCodeCountdownViewProps> = ({
    countdown = 0,
    onResendCode,
}) => {
    const style = useStyles();
    const { t } = useTranslation();

    return (
        <div className={style.countdownContainer}>
            {countdown > 0 && (
                <Typography className={style.countdownText}>
                    {t('code-validator.resend-code-countdown.code-expires-in', {
                        countdown: formatCountdown(countdown ?? 0),
                    })}
                </Typography>
            )}
            {countdown <= 0 && (
                <Typography className={style.resendText}>
                    {t('code-validator.resend-code-countdown.code-expired')}
                    <Typography onClick={onResendCode} className={style.resendLink}>
                        {t('code-validator.resend-code-countdown.resend-code')}
                    </Typography>
                </Typography>
            )}
        </div>
    );
};

export default ResendCodeCountdownView;
