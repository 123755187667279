import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { OrganizationMinuteDetailsResponse } from '../../../../../clients/manager/interfaces/organization-minutes.interface';
import { organizationMinutesRequests } from '../../../../../clients/manager/organization-minutes.requests';
import { addNotificationError, addNotificationSuccess } from '../../../../../common/utils';
import ModalAddMinute from '../modal-add-minute';
import OrganizationMinutesView from './organization-minutes-view';

const OrganizationMinutes: FC<any> = () => {
    const [organizationMinutes, setOrganizationMinutes] = useState<
        OrganizationMinuteDetailsResponse[]
    >([]);
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    const [fetching, setFetching] = useState(true);

    const listOrganizationMinutes = async () => {
        try {
            const result = await organizationMinutesRequests.listOrganizationMinutes();
            setOrganizationMinutes(result?.data);
        } catch (err) {
            addNotificationError();
            throw err;
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        listOrganizationMinutes();
    }, []);

    const handleCreateMinute = () => {
        setModalVisible(true);
    };

    const deleteOrganizationMinute = async (documentTemplateId: string) => {
        try {
            const response = await organizationMinutesRequests.deleteOrganizationMinute({
                documentTemplateId,
            });

            if (response?.data.ok) {
                addNotificationSuccess();
                setOrganizationMinutes((prevState) => [
                    ...prevState.filter(
                        (minute) =>
                            minute.organizationMinute.documentTemplateId !== documentTemplateId
                    ),
                ]);
            }
        } catch (err) {
            addNotificationError();
        }
    };

    return (
        <>
            {modalVisible && (
                <ModalAddMinute
                    onClose={() => {
                        ReactDOM.unstable_batchedUpdates(() => {
                            setModalVisible(false);
                        });
                    }}
                    onCreateMinute={() => {
                        setModalVisible(false);
                        listOrganizationMinutes();
                    }}
                />
            )}
            <Grid container item md={12}>
                <OrganizationMinutesView
                    organizationMinutes={organizationMinutes}
                    fetching={fetching}
                    deleteOrganizationMinute={deleteOrganizationMinute}
                    handleCreateMinute={handleCreateMinute}
                    t={t}
                />
            </Grid>
        </>
    );
};

export default OrganizationMinutes;
