import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import CitizenDashboard from './pages/citizen-dashboard';

const CitizenDashboardRoutes: JSX.Element[] = [
    <PrivateRoute
        exact
        path='/painel-cidadao'
        key='/painel-cidadao'
        canAccess={() => hasCurrentAccessType([TypeAccess.citizen])}
        component={() => <CitizenDashboard />}
    />,
];

export default CitizenDashboardRoutes;
