import { Grid, IconButton, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import HelpIcon from '@material-ui/icons/Help';
import SelectTypeUser from 'modules/sign-up/components/SelectTypeUser';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { usePlatformContext } from 'routes/platform.context';
import { SignUpContext } from '../context/SignUpContext';

const ChoseUserType = () => {
    const { userType, setUserType } = useContext(SignUpContext);
    const history = useHistory();

    const form4 = () => {
        switch (userType) {
            case 'Fornecedor':
                history.push('/cadastro-fornecedor');
                break;
            case 'Comprador':
                history.push('/cadastro-organizacao');
                break;
            case 'Cidadão':
                history.push('/painel-cidadao');
                break;
            default:
                history.push('/painel-cidadao');
        }
    };

    const { t } = useTranslation();
    const { platform } = usePlatformContext();
    const hideSignupOrganization = platform?.generalSettings?.general?.hideSignupOrganization;
    const hideSignupProvider = platform?.generalSettings?.general?.hideSignupProvider;

    return (
        <Grid container className='step-4'>
            <Grid item lg={12}>
                <Typography variant='h5' color='primary'>
                    {t('sign-up.pages.stepps.continue-as')}
                </Typography>
            </Grid>
            <Grid item lg={12}>
                <form onSubmit={form4}>
                    <SelectTypeUser setUserType={setUserType} />
                    <Button size='small' type='submit' variant='contained' color='primary'>
                        {t('term.start')}
                    </Button>
                </form>
            </Grid>
            <Grid item md={12}>
                <Grid container alignItems='center'>
                    {!hideSignupOrganization && (
                        <Grid container item md={6} sm={12} direction='row' alignItems='center'>
                            <Typography variant='body2'>
                                {t('sign-up.pages.stepps.info-add-public-entity')}
                            </Typography>
                            <IconButton
                                className='icon-btn'
                                onClick={() => {
                                    window.open(
                                        'https://licitardigital.tawk.help/article/como-cadastrar-um-ente-publico',
                                        '_blank'
                                    );
                                }}
                                color='secondary'
                                aria-label='Manual de cadastro'
                                title={t('sign-up.pages.stepps.title-click-here')}
                            >
                                <HelpIcon color='primary' />
                            </IconButton>
                        </Grid>
                    )}
                    {!hideSignupProvider && (
                        <Grid container item md={6} sm={12} direction='row' alignItems='center'>
                            <Typography variant='body2'>
                                {t('sign-up.pages.stepps.info-add-supplier')}
                            </Typography>
                            <IconButton
                                className='icon-btn'
                                onClick={() => {
                                    window.open(
                                        'https://licitardigital.tawk.help/article/como-cadastrar-um-fornecedor',
                                        '_blank'
                                    );
                                }}
                                color='secondary'
                                aria-label='Manual de cadastro'
                                title={t('sign-up.pages.stepps.title-click-here')}
                            >
                                <HelpIcon color='primary' />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChoseUserType;
