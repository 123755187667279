import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import styled from 'styled-components';

export const HiringPlansPageWrapper = styled(PageWrapper)<{ active: boolean }>`
    width: 100%;
    height: 100vh;
    position: relative;
    padding-top: 0;
    overflow-x: hidden;
    overflow-y: auto;

    > div {
        display: flex;
        flex-direction: column;
        position: relative;
        > section {
            display: flex;
            gap: 16px;

            @media (max-width: 1100px) {
                flex-direction: column;
            }

            > section {
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    @media (max-width: 1100px) {
        overflow-y: ${({ active }) => active && 'hidden'};
    }
`;

export const SearchHeader = styled.div`
    position: sticky;
    top: -1px;
    background-color: var(--background-page-default-color);
    padding: 0 16px;
    padding-top: 16px;
    margin-left: -16px;
    width: calc(100% + 32px);
    z-index: 99;
    display: flex;
    flex-direction: column;
`;

export const HeaderPage = styled.div`
    height: 7rem;
    position: relative;
    z-index: -10;
    padding: 16px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const FastFiltersContainer = styled.div`
    position: relative;
    padding: 6px 0px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: -10;

    > p {
        margin: 0;
    }
`;

export const AdvancedFiltersBackgroundBlock = styled.div<{ active: boolean }>`
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;

    @media (max-width: 1100px) {
        display: ${({ active }) => active && 'block'};
    }
`;

export const ActionButtonsSection = styled.div`
    display: flex;
    gap: 16px;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
`;

export const SectionContent = styled.section`
    flex: 1;
    margin: 24px 0;
`;

export const SectionViewContent = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const BlankArea = styled.div<{ border?: boolean; minHeight?: boolean }>`
    border: ${({ border }) => border && '1px dashed var(--border-color)'};
    border-radius: ${({ border }) => border && '4px'};
    flex: ${({ minHeight }) => (minHeight ? 0 : 1)} 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    > h1,
    h2,
    h3 {
        line-height: normal;
        margin: 0;
    }

    > h1 {
        color: var(--primary-color);
    }

    > h2 {
        color: var(--text-light-color);
        font-weight: 300;
    }

    > h3 {
        color: var(--text-light-color);
        font-weight: 400;
    }
`;

export const LoadingIndicatorContainer = styled.div`
    width: 100%;
    height: 100%;
    margin: 24px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
`;
