import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useStyles from './empty-list-style';

interface EmptyListViewProps {
    title: string;
    subtitle: string;
    href?: any;
}

const EmptyListView: React.FC<EmptyListViewProps> = ({ title, subtitle, href }) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <header className={styles.emptyCartContainer}>
            <Typography variant='body1'>{title}</Typography>
            <Typography variant='body1'>
                {subtitle}
                {href && <Link to={href}>{t('marketplace.cart.clicking-here')}</Link>}
            </Typography>
        </header>
    );
};

export default EmptyListView;
