import { FC } from 'react';
import { styled } from 'common/theme/styled';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    font-size: 14px;
    flex: 1;

    span {
        color: ${(props) => props.theme.colors.textLight};
        padding-top: 30px;
    }
`;

const EmptyImage = styled.img`
    height: 120px;
    cursor: default;
    margin-bottom: 10px;
`;

interface EmptyDataProps {}

const EmptyData: FC<EmptyDataProps> = ({ children }) => (
    <Content>
        <EmptyImage src='/assets/images/svgs/empty.svg' alt='Nenhum resultado' />
        {children}
    </Content>
);

export default EmptyData;
