import { IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@material-ui/icons/Cancel';
import { getFilenameFromUrl } from 'common/utils';
import useStyles from './image-preview-style';

interface ImagePreviewProps {
    url: string;
    onDelete: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ url, onDelete }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const filename = getFilenameFromUrl(url);

    return (
        <Paper className={classes.imagesRoot}>
            <img
                src={url}
                alt={t('notices-information.labels.cover-image')}
                className={classes.image}
            />
            <Typography variant='caption'>
                {getNewFileName({ name: filename } as File, 15)}
            </Typography>
            <IconButton className={classes.deleteButton} onClick={onDelete}>
                <Tooltip title={t('term.remove')}>
                    <DeleteForeverIcon fontSize='default' color='inherit' />
                </Tooltip>
            </IconButton>
        </Paper>
    );
};

export default ImagePreview;
