import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationUpdateRequestStatus } from '../../../../../clients/manager/interfaces/provider-registration-update-requests.interface';

interface SelectStatusProps {
    value: RegistrationUpdateRequestStatus | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    omitAllOption?: boolean;
}

const SelectStatus: FC<SelectStatusProps> = ({ value, onChange, omitAllOption }) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='status'
            label='Status'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            variant='outlined'
            select
            value={value}
        >
            {!omitAllOption && <MenuItem value={undefined}>{t('term.all')}</MenuItem>}
            <MenuItem value={RegistrationUpdateRequestStatus.approved}>
                {t('term.approved')}
            </MenuItem>
            <MenuItem value={RegistrationUpdateRequestStatus.reproved}>
                {t('term.reproved')}
            </MenuItem>
            <MenuItem value={RegistrationUpdateRequestStatus.pending}>{t('term.pending')}</MenuItem>
        </TextField>
    );
};

export default SelectStatus;
