import { FC, useCallback, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'common/components';
import ReactDOM from 'react-dom';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { userRequests } from '../../../../clients/manager/user.requests';
import { User } from '../../../../common/interfaces/user.interface';

interface SelectOrganizationUserProps {
    onChange: (userIds: number[]) => void;
    label: string;
    value: number[];
    users?: User[];
}

const MultipleSelectOrganizationUser: FC<SelectOrganizationUserProps> = ({
    onChange,
    label,
    value,
    users: defaultUsers,
}) => {
    const [users, setUsers] = useState<User[]>(defaultUsers ?? []);
    const [fetchingUsers, setFetchingUsers] = useState(!defaultUsers?.length);
    const { processForm, process } = useProcessFormContext();
    const usersFiltred = users.filter(
        ({ id }) =>
            id !== processForm?.values.competentAuthorityId &&
            id !== processForm?.values.presidentUserId
    );

    const getOrganizationUsers = async (organizationUnitId: number) => {
        try {
            const response = await userRequests.listUsersBelongToOrganizationUnit({
                params: { organizationUnitId },
            });
            ReactDOM.unstable_batchedUpdates(() => {
                setUsers(response.data ?? []);
                setFetchingUsers(false);
            });
        } catch (error) {
            setFetchingUsers(false);
        }
    };

    useEffect(() => {
        if (processForm?.values?.organizationUnitId || process?.organizationUnitId) {
            const unitId = processForm?.values?.organizationUnitId || process?.organizationUnitId;
            getOrganizationUsers(unitId as number);
        }
    }, [processForm?.values?.organizationUnitId]);

    const handleChangeUsers = (event: React.ChangeEvent<any>, options: User[]) => {
        onChange(options?.map((user) => user.id) ?? []);
    };

    const getFilteredUsers = useCallback(
        () => users.filter((user) => value.includes(user.id)),
        [users.length, value]
    );

    const selectedUsers = getFilteredUsers();

    return (
        <Autocomplete
            multiple
            disabled={
                fetchingUsers ||
                !processForm?.values?.competentAuthorityId ||
                !processForm?.values?.presidentUserId
            }
            defaultValue={selectedUsers}
            value={selectedUsers}
            fullWidth
            options={usersFiltred}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            onChange={handleChangeUsers}
            renderInput={(params) => <TextField {...params} variant='outlined' label={label} />}
        />
    );
};

export default MultipleSelectOrganizationUser;
