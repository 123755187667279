import React from 'react';
import {
    Box,
    DialogContent,
    DialogContentText,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { Button } from 'common/components';
import { useStyles } from './confirm-dialog-with-justification-style';

export interface ConfirmDialogWithJustificationViewProps {
    open: boolean;
    handleClose: (event: React.MouseEvent<HTMLElement>) => void;
    handleConfirm: (event: React.MouseEvent<HTMLElement>) => void;
    title?: string;
    message?: string;
    justification: string;
    setJustification: React.Dispatch<React.SetStateAction<string>>;
    cloneChildren: React.ReactElement;
    t: (key: string) => string;
}

const ConfirmDialogWithJustificationView: React.FC<ConfirmDialogWithJustificationViewProps> = ({
    open,
    handleClose,
    handleConfirm,
    title,
    message,
    justification,
    setJustification,
    cloneChildren,
    t,
}) => {
    const classes = useStyles();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <>
            {cloneChildren}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                maxWidth='xs'
            >
                <Box className={classes.dialogBox}>
                    {title && <DialogTitle>{title}</DialogTitle>}
                    {message && (
                        <DialogContent>
                            <DialogContentText className={classes.dialogContentText}>
                                {message}
                            </DialogContentText>
                            <TextField
                                label={t('term.justification')}
                                multiline
                                rows={4}
                                variant='outlined'
                                defaultValue={justification}
                                fullWidth
                                value={justification}
                                onChange={(e) => setJustification(e.target.value)}
                                margin='normal'
                                className={classes.textField}
                                onKeyDown={handleKeyDown}
                            />
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color='primary'
                            size='small'
                            className={classes.button}
                        >
                            {t('term.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleConfirm}
                            size='small'
                            disabled={!justification.trim()}
                            className={classes.button}
                        >
                            {t('term.confirm')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default ConfirmDialogWithJustificationView;
