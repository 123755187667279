import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import {
    auctionNoticeLotRequests,
    BulkLotsUpdate,
} from 'clients/manager/auction-notice-lot.requests';
import { addNotificationError, addNotificationSuccess, addNotificationWarning } from 'common/utils';
import { AuctionLotWithItemsForm } from 'modules/process/context/lots.form';
import { FC, useState } from 'react';
import { processActions } from 'modules/process/process-actions';
import { TypeOfItemsValue } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';
import { useProcessFormContext } from '../../../../context/process-form.context';
import ModalBulkChangesLotsView from './modal-bulk-changes-lots-view';
import {
    disableField,
    getDecimalPlaces,
    processRules,
    processUtils,
} from '../../../../process-utils';

export interface ModalBulkChangesLotsProps {
    auctionId: number;
    onClose: () => void;
    handleDefaultPage: (
        newValues: AuctionLotWithItemsForm['lots'],
        keepActualPage?: boolean
    ) => void;
}

type BulkLotsUpdateForm = Omit<BulkLotsUpdate, 'auctionNoticeId'>;

const ModalBulkChangesLots: FC<ModalBulkChangesLotsProps> = ({
    auctionId,
    onClose,
    handleDefaultPage,
}) => {
    const [updating, setUpdating] = useState(false);
    const { process, processForm, auctionTypeRules } = useProcessFormContext();
    const { formHasChanges, setProcessLotsAndItems } = useProcessLotsFormContext();
    const { t } = useTranslation();

    const getLotsFromProcess = async () => {
        if (!process?.id) {
            return;
        }

        try {
            const response = await auctionNoticeLotRequests.listLotsFromProcess({
                params: {
                    auctionId: process.id,
                },
            });
            setProcessLotsAndItems([...response.data]);
            setUpdating(false);
            onClose();
            handleDefaultPage([...response.data]);
        } catch (error) {}
    };

    const bulkLotsUpdate = async (form: BulkLotsUpdateForm) => {
        for (const key in form) {
            if (isUndefined(form[key]) || form[key] === -1) {
                delete form[key];
            } else if (key === 'bidAmountDifference' && form[key] === 0) {
                delete form[key];
            }
        }

        if (!Object.keys(form).length) {
            onClose();
            addNotificationWarning({
                title: t('term.err'),
                message: t('process.components.error-no-updates'),
            });
        }

        try {
            setUpdating(true);
            const response = await auctionNoticeLotRequests.bulkLotsUpdate({
                ...form,
                auctionNoticeId: auctionId,
            });

            if (response.data?.ok) {
                await getLotsFromProcess();
                return addNotificationSuccess({
                    title: t('term.success'),
                    message: t('process.components.notification-all-fields-updates'),
                });
            }

            setUpdating(false);
            throw new Error();
        } catch (error) {
            setUpdating(false);
            addNotificationError({
                message: t('process.components.error-updates-fields-try-again'),
            });
        }
    };

    const form = useFormik<BulkLotsUpdateForm>({
        initialValues: {
            bidAmountDifference: undefined,
            typeOfItems: undefined,
            markIsRequired: undefined,
            showReferenceValue: undefined,
            typeOfBenefit: undefined,
            requireDocuments: undefined,
            forceSameDescription: undefined,
            propertyOrFurnitureCode: undefined,
        },
        onSubmit: (values) => {
            bulkLotsUpdate(values);
        },
    });

    const handleClickConfirmChanges = () => {
        form.submitForm();
    };

    const handleFieldChanged = (field: string, value: any) => form.setFieldValue(field, value);

    const getDefaultChecked = (field: string) =>
        form.values[field] === undefined ? -1 : form.values[field];

    const disabledFields =
        formHasChanges || !processActions.canEditLot(processForm?.values?.biddingStageId);

    const getMenuTypeOfItems = (): JSX.Element[] => {
        if (processUtils.isPublicSale(processForm?.values)) {
            return [
                <option key={TypeOfItemsValue.properties} value={TypeOfItemsValue.properties}>
                    {t('term.properties')}
                </option>,
                <option key={TypeOfItemsValue.furniture} value={TypeOfItemsValue.furniture}>
                    {t('term.furniture')}
                </option>,
            ];
        }
        return [
            <option key={TypeOfItemsValue.product} value={TypeOfItemsValue.product}>
                {t('term.products')}
            </option>,
            <option key={TypeOfItemsValue.service} value={TypeOfItemsValue.service}>
                {t('term.services')}
            </option>,
        ];
    };

    return (
        <ModalBulkChangesLotsView
            form={form}
            updating={updating}
            process={process}
            processForm={processForm}
            auctionTypeRules={auctionTypeRules}
            formHasChanges={formHasChanges}
            handleClickCancel={onClose}
            handleClickConfirmChanges={handleClickConfirmChanges}
            handleFieldChanged={handleFieldChanged}
            getDefaultChecked={getDefaultChecked}
            disabledFields={disabledFields}
            getMenuTypeOfItems={getMenuTypeOfItems}
            onClose={onClose}
            processRules={processRules}
            disableField={disableField}
            getDecimalPlaces={getDecimalPlaces}
        />
    );
};

export default ModalBulkChangesLots;
