import React, { useState } from 'react';

import { ListMaterialData } from 'modules/material/interfaces/material.interface';
import MaterialsInputView from './materials-input-view';

interface MaterialsInputProps {
    visible?: boolean;
    defaultValue?: string;
    onChange: (value: ListMaterialData | null) => void;
}

const MaterialsInput: React.FC<MaterialsInputProps> = ({
    visible = false,
    defaultValue = '',
    onChange,
}) => {
    if (!visible) return <></>;

    const [isMaterialsModalOpen, setIsMaterialsModalOpen] = useState(false);

    const label = defaultValue?.length > 6 ? `${defaultValue?.slice(0, 6)}...` : defaultValue;
    const badgeContent = defaultValue ? `ID: ${label}` : undefined;

    const handleOpenMaterialsModal = () => {
        setIsMaterialsModalOpen(true);
    };

    const handleOnCloseMaterialsModal = () => {
        setIsMaterialsModalOpen(false);
    };

    const handleOnChange = (value: ListMaterialData | null) => {
        onChange?.(value);
        handleOnCloseMaterialsModal();
    };

    return (
        <MaterialsInputView
            isOpen={isMaterialsModalOpen}
            defaultValue={defaultValue}
            badgeContent={badgeContent}
            onChange={handleOnChange}
            onOpenModal={handleOpenMaterialsModal}
            onCloseModal={handleOnCloseMaterialsModal}
        />
    );
};

export default MaterialsInput;
