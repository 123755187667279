import { FC, useEffect, useState } from 'react';
import { EditorReplacer } from 'modules/editor/components/editor';
import {
    ProcessVariable,
    CustomVariable,
    DefaultVariable,
} from 'modules/editor/interfaces/variables.interface';
import styled from 'styled-components';
import DocumentVariableReplacer from '../../components/document-variable-replacer';
import { processDocumentsTemplatesRequests } from '../../../../../clients/manager/documents/process-documents-templates.requests';

const Content = styled.div`
    .tox-editor-header {
        position: fixed !important;
        width: 793px !important;
    }
`;

interface DynamicEditorProps {
    initialData: string;
    handleEditorChange: (html: string) => void;
    variables: {
        defaults: DefaultVariable[];
        customs: CustomVariable[];
        auction: ProcessVariable[];
    };
    onProcessPendentVariables: (html: string) => void;
    editing: boolean;
    processDocumentTemplateId: string;
}

const DynamicEditor: FC<DynamicEditorProps> = ({
    initialData,
    handleEditorChange,
    variables,
    onProcessPendentVariables,
    editing,
    processDocumentTemplateId,
}) => {
    const [editorAlreadyLoaded, setEditorAlreadyLoaded] = useState(false);
    const [editorReplacerState, setEditorReplacerState] = useState(initialData);

    // se o tinymce já foi carregado não remove ele do dom só omite para não ter que carregar novamente
    // os plugins e dependencias
    useEffect(() => {
        if (editing && !editorAlreadyLoaded) {
            setEditorAlreadyLoaded(true);
        }
    }, [editing]);

    useEffect(() => {
        setEditorReplacerState(initialData);
    }, [initialData]);

    const handleReplacerEditorChange = (html: string) => {
        handleEditorChange(html);
        setEditorReplacerState(html);
    };

    return (
        <Content>
            {editorAlreadyLoaded ? (
                <EditorReplacer
                    onUploadFile={(blob) =>
                        processDocumentsTemplatesRequests.uploadImage(
                            processDocumentTemplateId,
                            blob
                        )
                    }
                    variables={variables}
                    initialData={editorReplacerState}
                    onChange={handleEditorChange}
                    onKeyPress={onProcessPendentVariables}
                    onUndo={onProcessPendentVariables}
                    visible={editing}
                />
            ) : null}
            {!editing && (
                <DocumentVariableReplacer
                    onChange={handleReplacerEditorChange}
                    variables={variables}
                    loading={!initialData}
                    visible={!editing}
                    html={initialData}
                />
            )}
        </Content>
    );
};

export default DynamicEditor;
