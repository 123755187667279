import { FormikProps } from 'formik';
import { pick } from 'lodash';
import { AuctionNotice } from '../../../clients/manager/interfaces/auction-notice.interface';

const isValidProcessForm = (
    form: FormikProps<AuctionNotice> | undefined,
    partialProcessFormLabels?: { [key: string]: string }
): boolean => {
    if (!form) {
        return true;
    }

    if (partialProcessFormLabels) {
        return !Object.keys(partialProcessFormLabels).some((key) => form.errors[key]);
    }

    return !Object.keys(form.errors).some((key) => form.errors[key]);
};

const getProcessFormErrorsLabels = (
    form: FormikProps<AuctionNotice> | undefined,
    partialProcessFormLabels: { [key: string]: string }
) => {
    const errors = pick(form?.errors ?? {}, ...Object.keys(partialProcessFormLabels));

    if (!Object.keys(errors ?? {}).length) {
        return null;
    }

    const toRender: JSX.Element[] = [];
    const toRenderItems: JSX.Element[] = [];

    Object.keys(errors).forEach((key) => {
        toRender.push(
            <div key={key}>
                <span>
                    {partialProcessFormLabels[key]}
                    {' : '}
                    {errors[key]}
                </span>
            </div>
        );
    });

    return (
        <>
            {toRender}
            <p>{toRenderItems}</p>
        </>
    );
};

export { getProcessFormErrorsLabels, isValidProcessForm };
