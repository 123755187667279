import React from 'react';
import { Theme } from '@material-ui/core';
import MarkdownView from './markdown-view';

export interface MarkdownProps {
    value: string;
    variant?: keyof Theme['typography'];
}
const Markdown: React.FC<MarkdownProps> = (props) => <MarkdownView {...props} />;

export default Markdown;
