import { Route, RouteComponentProps } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import ValidateToken from './components/validate-token';

import Profile from './pages/profile';
import SignIn from './pages/sign-in';
import ProviderAuth from './components/validate-oauth';

const AuthRoutes: JSX.Element[] = [
    <Route
        path='/auth'
        key='/auth'
        exact
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props: RouteComponentProps) => <ValidateToken {...props} />}
    />,
    <Route path='/auth/sign-in' key='/auth/sign-in' exact component={SignIn} />,
    <Route path='/oauth' key='/oauth' exact component={ProviderAuth} />,
    <PrivateRoute path='/meu-perfil' key='/meu-perfi' exact component={Profile} />,
];

export default AuthRoutes;
