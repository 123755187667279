import { Grid, Breadcrumbs, Card, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SearchGroupAuthorizationTable from './components/SearchGroupAuthorizationTable';

const SearchGroupAuthorizationView = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleNew = () => {
        history.push('/grupo-autorizacao/novo');
    };

    return (
        <>
            <PageWrapper>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Breadcrumbs>
                        <Typography>
                            {t('group-auth.pages.edit-group.components.edit-group-auth.group-auth')}
                        </Typography>
                    </Breadcrumbs>

                    <Button size='small' variant='contained' color='primary' onClick={handleNew}>
                        {t('term.new')}
                    </Button>
                </Grid>

                <br />

                <Card>
                    <SearchGroupAuthorizationTable />
                </Card>
            </PageWrapper>
        </>
    );
};
export default SearchGroupAuthorizationView;
