import React, { FC, useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { processActions } from 'modules/process/process-actions';

interface ExpirationDateRegistrationProps {
    handleChangeDefault: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ExpirationDateRegistration: FC<ExpirationDateRegistrationProps> = ({
    handleChangeDefault,
}) => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    const expirationDatePriceRegistration = useMemo(
        () =>
            new Array(24).fill(null).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={index + 1}>
                    {`${String(index + 1).padStart(2, '0')} ${t('term.months')}`}
                </MenuItem>
            )),
        []
    );

    return (
        <TextField
            fullWidth
            color='primary'
            label={t('term.expiration-date')}
            onChange={handleChangeDefault}
            variant='outlined'
            name='expirationDatePriceRegistration'
            select
            defaultValue={processForm?.values.expirationDatePriceRegistration}
            disabled={processActions.cannotEditUntilDispute(process)}
        >
            {expirationDatePriceRegistration}
        </TextField>
    );
};

export default ExpirationDateRegistration;
