import { createTheme, ThemeOptions, ThemeProvider } from '@material-ui/core';
import { ColorPartial, SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { adjustBrightness, mountPalette } from 'common/utils';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

export interface Theme extends ThemeOptions {
    palette: ThemeOptions['palette'] & {
        tertiary: SimplePaletteColorOptions;
        blue: ColorPartial;
        white: ColorPartial;
        danger: SimplePaletteColorOptions;
    };
}

interface ICustomWindow extends Window {
    theme?: any;
}

const customWindow: ICustomWindow = window;

export const theme = createTheme({
    palette,
    typography,
    overrides,
} as Theme);

customWindow.theme = theme;

export interface ThemeColors {
    primary: string;
    secondary: string;
    terciary: string;
    info: string;
    warning: string;
    danger: string;
    success: string;
}

export type MUIThemeContextType = {
    platformColor?: ThemeColors;
    setPlatformColor: Dispatch<SetStateAction<ThemeColors>>;
};

export const MUIThemeContext = createContext<MUIThemeContextType>({
    platformColor: undefined,
    setPlatformColor: () => {},
});

export const useMUIThemeContext = () => useContext(MUIThemeContext);

export const MUIThemeProvider = ({ children }) => {
    const [platformColor, setPlatformColor] = useState<ThemeColors>({
        primary: palette.primary.main,
        secondary: adjustBrightness(palette.primary.main, -80),
        terciary: palette.tertiary.main,
        danger: palette.danger.main,
        success: palette.success.main,
        warning: palette.warning.main,
        info: palette.info.main,
    });

    const colors = platformColor?.primary
        ? {
              primary: mountPalette(platformColor.primary),
              secondary: mountPalette(adjustBrightness(platformColor.primary, -80)),
              terciary: mountPalette(platformColor.terciary),
              danger: mountPalette(platformColor.danger),
              success: mountPalette(platformColor.success),
              warning: mountPalette(platformColor.warning),
              info: mountPalette(platformColor.info),
          }
        : {};

    const customPalette = {
        ...palette,
        ...colors,
    };

    const customTheme = createTheme({
        palette: customPalette,
        typography,
        overrides,
    } as Theme);

    return (
        <MUIThemeContext.Provider value={{ platformColor, setPlatformColor }}>
            <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
        </MUIThemeContext.Provider>
    );
};
