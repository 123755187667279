import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { JudgmentCriterion } from 'clients/manager/interfaces/auction-notice.interface';
import { addNotificationApiError, addNotificationError } from 'common/utils';
import { auctionNoticeLotRequests } from 'clients/manager/auction-notice-lot.requests';
import { MenuItem } from '@material-ui/core';
import { useProcessFormContext } from '../../../../context/process-form.context';
import TypeValueBidFieldView from './type-valeu-bid-field-view';

interface TypeValueBidFieldProps {
    handleChangeDefault: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TypeValueBidField: FC<TypeValueBidFieldProps> = ({ handleChangeDefault }) => {
    const { processForm } = useProcessFormContext();
    const { t } = useTranslation();

    const typeValueBid = useMemo(() => {
        const opts: ReactNode[] = [<MenuItem value='unit'>{t('term.unitary')}</MenuItem>];

        if (
            processForm?.values.judgmentCriterion !== JudgmentCriterion.highestDiscount &&
            !(
                processForm?.values.judgmentCriterion === JudgmentCriterion.lowestPrice &&
                processForm?.values.rateTypeBid === 1
            )
        ) {
            opts.push(<MenuItem value='kit'>{t('term.global')}</MenuItem>);
        }
        return opts;
    }, [processForm?.values.judgmentCriterion, processForm?.values.rateTypeBid, t]);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const field = e.target;
            const formValue = processForm?.values.typeValueBid;
            const newValue = e.target.value;

            if (field.name === 'typeValueBid' && newValue !== formValue && processForm?.values.id) {
                const response = await auctionNoticeLotRequests.listLotsFromProcess({
                    params: {
                        auctionId: processForm?.values?.id,
                        onlyQuotas: true,
                    },
                });
                const hasLotsQuota: boolean = response.data.length > 0;

                if (hasLotsQuota) {
                    addNotificationError({
                        message: t('error.cannot-change-typeValueBid-with-quota'),
                        title: t('term.error'),
                    });
                    return;
                }
            }

            handleChangeDefault(e);
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    return <TypeValueBidFieldView handleChange={handleChange} typeValueBid={typeValueBid} />;
};

export default TypeValueBidField;
