import { styled } from 'common/theme/styled';

const Content = styled.div``;

const Card = styled.div`
    display: flex;
    height: 67px;
    background: #fafafa;
    align-items: center;
    padding: 0 30px 0 20px;
    justify-content: space-between;
    border-top: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    position: relative;
`;

const Template = styled.div`
    display: grid;

    span {
        color: ${(props) => props.theme.colors.textLight};
    }
`;

const Details = styled(Template)`
    margin: 0 0 0 15px;
    width: 25%;

    p {
        margin: 0;
    }

    span {
        font-size: 14px;
    }

    span:nth-child(1) {
        font-weight: 600;
    }

    p:nth-child(2) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
`;

const Description = styled(Template)`
    width: 60%;

    p {
        margin: 0;
    }

    span {
        font-size: 14px;
    }

    p:first-child {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        span:nth-child(1) {
            font-weight: 600;
        }
    }

    p:nth-child(2) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
`;

const Measure = styled(Template)`
    width: 15%;
    margin: 0 15px 0 0;

    span:nth-child(1) {
        font-size: 14px;
        font-weight: 600;
    }

    span:nth-child(2) {
        font-size: 14px;
    }
`;

const SourceType = styled.img`
    height: 15px;
    right: 4px;
    position: absolute;
`;

export { Content, Card, Measure, Description, Details, SourceType };
