import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { AppealStatusType } from 'clients/manager/interfaces/auction-notice.interface';
import ActionsIntent from './actions-intent';
import ActionsRequest from './actions-request';
import ActionsCounterReason from './actions-counter-reason';

const AppealPhaseActions = () => {
    const { processForm, auctionTypeRules } = useProcessFormContext();

    if (
        !processForm?.values ||
        processForm.values?.isPhaseReversal !== 1 ||
        !auctionTypeRules ||
        processForm?.values.auctionCanceled ||
        processForm?.values.auctionFinished
    ) {
        return null;
    }

    return (
        <>
            {processForm.values.appealStatus === AppealStatusType.intent && <ActionsIntent />}
            {processForm.values.appealStatus === AppealStatusType.request && <ActionsRequest />}
            {processForm.values.appealStatus === AppealStatusType.counterReason && (
                <ActionsCounterReason />
            )}
        </>
    );
};

export default AppealPhaseActions;
