import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import NotFound from 'routes/not-found';
import EditOrganization from './edit-organization';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                canAccess={() => hasCurrentAccessType([TypeAccess.organization])}
                path={`${match.path}/`}
                exact
                component={() => null}
            />
            <PrivateRoute
                canAccess={() => hasCurrentAccessType([TypeAccess.organization])}
                path={`${match.path}/editar`}
                exact
                component={() => <EditOrganization />}
            />
            <PrivateRoute path='*' key='*' component={() => <NotFound />} />
        </Switch>
    );
};

const OrganizationRoutes: JSX.Element[] = [
    <Route path='/organizacao' key='/organizacao' render={() => <ChildRoutes />} />,
];

export default OrganizationRoutes;
