import { FC, useEffect, useState } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import {
    DocumentTemplate,
    DocumentTemplateType,
} from 'clients/manager/interfaces/document-templates.interface';
import { addNotificationError } from 'common/utils';
import { SelectDocumentTemplateProps } from './props';

export interface SelectDocumentTemplateFilters {
    type: DocumentTemplateType;
}

const SelectDocumentTemplate: FC<SelectDocumentTemplateProps> = ({
    onDocumentTemplateSelected,
    initialValue,
}) => {
    const { t } = useTranslation();
    const [documentTemplates, setDocumentTemplates] = useState<DocumentTemplate[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getTemplates = async () => {
            try {
                const response = await templateRequests.getTemplates({
                    params: { justNotSelected: true },
                });
                setDocumentTemplates(response?.data ?? []);
                setIsLoading(false);
            } catch (error) {
                addNotificationError();
                setIsLoading(false);
            }
        };

        getTemplates();
    }, []);

    const handleChange = (value: DocumentTemplate | null) => {
        if (!value) {
            return;
        }

        const selectedTemplate = documentTemplates.find((template) => template._id === value._id);
        onDocumentTemplateSelected(selectedTemplate);
    };

    const getInitialValue = (): DocumentTemplate | undefined => {
        if (initialValue) {
            return documentTemplates.find((template) => template._id === initialValue);
        }
        return undefined;
    };

    return (
        <Autocomplete
            fullWidth
            value={getInitialValue()}
            defaultValue={getInitialValue()}
            options={documentTemplates}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => handleChange(value as DocumentTemplate)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name='template'
                    variant='outlined'
                    label={t('process.components.select-model-document')}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default SelectDocumentTemplate;
