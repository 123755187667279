const contractType = {
    contrato_termo_inicial: 'Termo Inicial de Contrato',
    comodato: 'Comodato',
    arrendamento: 'Arrendamento',
    concessao: 'Concessão',
    termo_adesao: 'Termo de Adesão',
    convenio: 'Convênio',
    empenho: 'Empenho',
    termo_execucao_descentralizada: 'Termo de Execução Descentralizada',
    acordo_cooperacao_tecnica: 'Acordo de Cooperação Técnica',
    termo_compromisso: 'Termo de Compromisso',
    carta_contrato: 'Carta Contrato',
    outros: 'Outros',
};

const termType = {
    termo_rescisao: 'Termo de Rescisão',
    termo_aditivo: 'Termo Aditivo',
    termo_apostilamento: 'Termo de Apostilamento',
};

const processCategory = {
    cessao: 'Cessão',
    compras: 'Compras',
    informatica: 'Informática',
    internacional: 'Internacional',
    locacao_imoveis: 'Locação de Imóveis',
    mao_de_obra: 'Mão de Obra',
    obras: 'Obras',
    servicos: 'Serviços',
    servicos_engenharia: 'Serviços de Engenharia',
    servicos_saude: 'Serviços da Saúde',
};

export { contractType, processCategory, termType };
