import React, { useEffect, useState } from 'react';
import {
    Breadcrumbs,
    Button,
    Card,
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { TextField } from 'common/components';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { addNotificationError, getTemplateTypesLabel } from 'common/utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { Skeleton } from '@material-ui/lab';
import { GenericResponse } from 'clients/interfaces/paginated.interface';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { useHistory } from 'react-router';
import { Visibility } from '@material-ui/icons';
import { CopyIcon } from '../../pages/template-list/styles';

const rowsPerPageOptions = [10, 20];

interface SearchTemplateTableViewProps {
    handleChangeFilter: (event: any) => void;
    handleEdit: (templateId: string) => void;
    handleView: (templateId: string) => void;
    handleCopy: (templateId: string) => void;
    handleNewTemplate: () => void;
    queryParams: UsePaginatedQueryResponse<GenericResponse<DocumentTemplate>>;
    templateUpdated;
}

const SearchTemplateTableView: React.FC<SearchTemplateTableViewProps> = ({
    queryParams,
    handleChangeFilter,
    handleEdit,
    handleView,
    handleCopy,
    handleNewTemplate,
    templateUpdated,
}) => {
    const [templates, setTemplates] = useState<DocumentTemplate[] | undefined>();
    const { data, loading, totalCount, page, limit, setLimit, setPage, error } = queryParams;
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        setTemplates(data?.data);
    }, [data?.data]);

    useEffect(() => {
        const templateExists = templates?.some((template) => template._id === templateUpdated._id);
        if (templateExists) {
            setTemplates((prevTemplates) => {
                if (!prevTemplates) return prevTemplates;
                return prevTemplates.map((template) =>
                    template._id === templateUpdated._id ? templateUpdated : template
                );
            });
        }
    }, [templateUpdated]);

    const header = (
        <Grid container justifyContent='space-between' alignItems='center'>
            <Breadcrumbs>
                <Typography>
                    {t('editor.templates.components.templates.pages.list-model')}
                </Typography>
            </Breadcrumbs>
            <Grid>
                <Button
                    title={t('editor.templates.components.templates.pages.open-library')}
                    style={{ marginRight: '10px' }}
                    color='default'
                    variant='contained'
                    size='small'
                    onClick={() => history.push('/processos/editor/modelos/biblioteca')}
                >
                    {t('term.library')}
                </Button>
                <Button
                    title={t('editor.templates.components.templates.pages.create-new-model-doc')}
                    onClick={handleNewTemplate}
                    size='small'
                    variant='contained'
                    color='primary'
                >
                    {t('editor.templates.components.templates.pages.new-model')}
                </Button>
            </Grid>
        </Grid>
    );

    if (error) {
        addNotificationError({
            message: t('editor.templates.components.templates.pages.error-onload-model'),
        });
    }

    return (
        <>
            {header}
            <br />
            <Card>
                <TextField
                    label={t('term.search-model')}
                    placeholder={t(
                        'group-auth.pages.search-group.components.search-group-press-enter'
                    )}
                    fullWidth
                    onKeyPress={handleChangeFilter}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <br />
                <br />

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('term.document-type')}</TableCell>
                            <TableCell>{t('term.model-name')}</TableCell>
                            <TableCell>{t('term.last-update')}</TableCell>
                            <TableCell>{t('term.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates &&
                            templates.map((template) => (
                                <TableRow hover key={template._id}>
                                    <TableCell>{getTemplateTypesLabel(template.type)}</TableCell>
                                    <TableCell>{template.name}</TableCell>
                                    <TableCell>{moment(template.createdAt).format('LL')}</TableCell>
                                    <TableCell align='center' padding='none' style={{ width: 150 }}>
                                        <Grid>
                                            <Tooltip
                                                title={t(
                                                    'editor.templates.components.templates.pages.view-model'
                                                )}
                                            >
                                                <IconButton
                                                    onClick={() => handleView(template._id)}
                                                >
                                                    <Visibility fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                                title={t(
                                                    'editor.templates.components.templates.pages.edit-model'
                                                )}
                                            >
                                                <IconButton
                                                    onClick={() => handleEdit(template._id)}
                                                >
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                                title={t(
                                                    'editor.templates.components.templates.pages.copy-model'
                                                )}
                                            >
                                                <IconButton
                                                    onClick={() => handleCopy(template._id)}
                                                >
                                                    <CopyIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}

                        {!loading && templates && !templates?.length && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography gutterBottom align='center'>
                                        {t(
                                            'editor.templates.components.templates.pages.none-model'
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {loading && (
                            <TableRow>
                                {new Array(5).fill(null).map((_, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <TableCell key={index} style={{ margin: '0 0 5px 0' }}>
                                        <Skeleton width='100%' height='30px' color='#e7e7e7' />
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    count={totalCount}
                    page={page}
                    onPageChange={(_e, page) => setPage(page)}
                    rowsPerPage={limit || rowsPerPageOptions[0]}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component='div'
                    onRowsPerPageChange={(event) =>
                        setLimit(Number(event.target.value) ?? rowsPerPageOptions[0])
                    }
                    labelRowsPerPage={t('text.lines-per-page')}
                />
            </Card>
        </>
    );
};

export default SearchTemplateTableView;
