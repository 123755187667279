import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    boxBorder: {
        border: `1px solid ${theme.palette.background.default}`,
    },
    provider: {
        padding: theme.spacing(0),
    },
    boxGrey: {
        background: theme.palette.background.default,
        padding: theme.spacing(1, 2),
    },
    planText: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
    },
    iconBenefit: { marginRight: theme.spacing(1) },
    boxBenefit: { display: 'flex' },
    boxButton: {
        display: 'flex',
        marginTop: theme.spacing(3),
        justifyContent: 'flex-end',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6),
        width: '100%',
    },
}));

export default useStyles;
