enum MarketplaceWindowEvents {
    ADVANCED_FILTERS_APPLIED = 'ADVANCED_FILTERS_APPLIED',
    ADVANCED_FILTERS_CHANGED = 'ADVANCED_FILTERS_CHANGED',
    DO_ADVANCED_FILTERS = 'DO_ADVANCED_FILTERS',
    ENABLE_DISABLE_ADVANCED_FILTERS = 'ENABLE_DISABLE_ADVANCED_FILTERS',

    REQUEST_CREATE_ITEM = 'REQUEST_CREATE_ITEM',
}

export { MarketplaceWindowEvents };
