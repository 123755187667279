import { FC } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { SelectDocumentTemplateViewProps } from './props';

const SelectDocumentTemplateView: FC<SelectDocumentTemplateViewProps> = ({
    disabled,
    documentTemplates,
    getInitialValue,
    handleChange,
    t,
}) => {
    return (
        <Autocomplete
            disabled={disabled}
            fullWidth
            value={getInitialValue()}
            defaultValue={getInitialValue()}
            options={documentTemplates}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => handleChange(value as DocumentTemplate)}
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    name='template'
                    variant='outlined'
                    label={t('process.components.select-model-document')}
                />
            )}
        />
    );
};

export default SelectDocumentTemplateView;
