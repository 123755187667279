import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            '& .MuiListItemIcon-root': {
                minWidth: 'initial',
            },
            '& .Mui-selected': {
                '& .MuiListItemIcon-root': {
                    color: '#02AE81',
                },
            },
            '& .MuiTypography-colorTextSecondary': {
                color: '#777777',
            },
            '& .MuiListItemText-primary': {
                fontWeight: 500,
                fontSize: 18,
            },
            '& .Mui-selected .MuiListItemText-primary': {
                color: '#02AE81',
            },
            '& .Mui-selected .MuiTypography-colorTextSecondary': {
                color: '#02AE81',
            },
            '& .MuiListItem-root': {
                border: '1px solid #dddddd',
                '& :hover': {
                    '& .MuiListItemText-primary': {
                        color: '#02AE81',
                    },
                    '& .MuiTypography-colorTextSecondary': {
                        color: '#02AE81',
                    },
                },
                '&.Mui-selected': {
                    border: '1px solid #02AE81',
                },
            },
            '& .checkIcon': {
                minMidth: 'initial',
                justifyContent: 'flex-end',
            },
        },
    })
);

export { useStyles };
