import i18n from 'i18next';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';

type DocumentTypeLabel = { [key in DocumentTemplateType]: string };

export const getTemplateTypesLabel = (type: DocumentTemplateType): string => {
    const documentTypeName = (
        {
            [DocumentTemplateType.contract]: `${i18n.t('term.contract')} / ${i18n.t(
                'term.ata-price-registration'
            )}`,
            [DocumentTemplateType.receipt]: i18n.t('term.receipt'),
            [DocumentTemplateType.auction]: `${i18n.t('term.public-notices')} / ${i18n.t(
                'term.basic-project'
            )}`,
            [DocumentTemplateType.protocol]: 'Ata',
            [DocumentTemplateType.referenceTerms]: i18n.t('term.terms-of-reference'),
            [DocumentTemplateType.hiringNotice]: i18n.t('term.direct-hiring-notice'),
            [DocumentTemplateType.contractDraft]: i18n.t('term.contract-draft'),
            [DocumentTemplateType.preliminaryDesign]: i18n.t('term.preliminary-design'),
            [DocumentTemplateType.preliminaryTechnicalStudy]: i18n.t(
                'term.preliminary-technical-study'
            ),
            [DocumentTemplateType.executiveProject]: i18n.t('term.executive-project'),
            [DocumentTemplateType.riskMap]: i18n.t('term.risk-map'),
            [DocumentTemplateType.dod]: i18n.t('term.dod'),
            [DocumentTemplateType.terminationNotice]: i18n.t('term.termination-notice'),
            [DocumentTemplateType.addendum]: i18n.t('term.addendum'),
            [DocumentTemplateType.apostilmentTerm]: i18n.t('term.apostilment-term'),
            // [DocumentTemplateType.commitmentNote]: i18n.t('term.commitment-note'),
            [DocumentTemplateType.minutesOfPriceRegistration]: i18n.t(
                'term.minutes-of-price-registration'
            ),
            [DocumentTemplateType.actAuthorizingDirectContracting]: i18n.t(
                'term.act-authorizing-direct-contracting'
            ),
            [DocumentTemplateType.others]: i18n.t('term.others'),
        } as DocumentTypeLabel
    )[type];
    return documentTypeName || '-';
};
