import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'common/components';
import { FcKey } from 'react-icons/fc';
import { providerSignIn } from 'common/amplify';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'common/components/loading-button';
import { nanoid } from 'nanoid';
import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import { addNotificationError, featureFlagEnabled } from 'common/utils';
import { IdentityProvidersT } from './profile-connect-accounts-view';

interface IProfileConnectAccounts {
    identityProviders: IdentityProvidersT[];
    userId?: number;
    disconnectUser: (userId: number, provider: string) => void;
    isLoading: boolean;
    enableConnectProviders?: boolean;
    onOpenShowModalDialogChangePassword: () => void;
}

const ProfileConnectAccounts = ({
    identityProviders,
    userId,
    disconnectUser,
    isLoading,
    enableConnectProviders,
    onOpenShowModalDialogChangePassword,
}: IProfileConnectAccounts) => {
    const [onOpenChangePassword, setOnOpenChangePassword] = useState(false);
    const [isLoadingSubmitCode, setIsLoadingSubmitCode] = useState(false);
    const cognitoIsEnabled = featureFlagEnabled('enableCognitoAccess');
    const { sendPasswordResetCode } = useUserConnectedProviders();
    const isLast = (index: number) => index === identityProviders.length - 1;
    const { t } = useTranslation();

    const handleSendPasswordCode = async () => {
        setIsLoadingSubmitCode(true);

        try {
            await sendPasswordResetCode();
            setOnOpenChangePassword(false);
            onOpenShowModalDialogChangePassword();
        } catch {
            setOnOpenChangePassword(false);
            addNotificationError({
                title: t('term.error'),
                message: t('info.notification-error-submit-code-change-password'),
            });
        } finally {
            setIsLoadingSubmitCode(false);
        }
    };

    const handleChangePassword = () => {
        handleSendPasswordCode();
    };

    return (
        <>
            <Dialog
                maxWidth='md'
                open={onOpenChangePassword}
                onClose={() => setOnOpenChangePassword(false)}
                aria-labelledby='alert-dialog-confirm-change-password'
                aria-describedby='alert-dialog-description-confirm-change-password'
            >
                <DialogTitle id='alert-dialog-confirm-change-password'>
                    {t('info.title-update-password-confirm')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        align='justify'
                        color='textPrimary'
                        id='alert-dialog-description-confirm-change-password'
                    >
                        {t('info.description-update-password-confirm')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoadingSubmitCode || isLoading}
                        color='default'
                        size='small'
                        variant='contained'
                        onClick={() => setOnOpenChangePassword(false)}
                    >
                        {t('term.cancel')}
                    </Button>
                    <LoadingButton
                        type='submit'
                        size='small'
                        variant='contained'
                        onClick={handleChangePassword}
                        disabled={isLoadingSubmitCode || isLoading}
                        {...(isLoadingSubmitCode && { loading: { text: '' } })}
                    >
                        {t('term.confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Card>
                <Grid container direction='column'>
                    <Grid item>
                        <Typography variant='h6'>{t('auth.external-providers')}</Typography>
                    </Grid>
                    <Grid
                        key={nanoid()}
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        style={{ padding: '2rem 2rem' }}
                    >
                        <Grid item>
                            <FcKey />
                            <Typography variant='subtitle1'>Senha</Typography>
                            <Typography variant='caption'>{t('title.update-password')}</Typography>
                        </Grid>

                        <Grid item>
                            <LoadingButton
                                type='submit'
                                size='small'
                                variant='contained'
                                onClick={() =>
                                    cognitoIsEnabled
                                        ? setOnOpenChangePassword(true)
                                        : onOpenShowModalDialogChangePassword()
                                }
                                disabled={isLoading || isLoadingSubmitCode}
                                {...(isLoading && { loading: { text: '' } })}
                            >
                                {t('term.change-password')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    {enableConnectProviders && identityProviders.length > 0 && <Divider />}
                    {enableConnectProviders &&
                        identityProviders.map(({ name, icon, isConnected }, index) => (
                            <>
                                <Grid
                                    key={nanoid()}
                                    container
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    style={{ padding: '2rem 2rem' }}
                                >
                                    <Grid item>
                                        {icon}
                                        <Typography variant='subtitle1'>{name}</Typography>
                                        <Typography variant='caption'>
                                            {!isConnected
                                                ? t('auth.external-providers-disconnected')
                                                : t('auth.external-providers-connected')}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        {isConnected ? (
                                            <LoadingButton
                                                type='button'
                                                onClick={() => {
                                                    if (userId) disconnectUser(userId, name);
                                                }}
                                                size='small'
                                                variant='contained'
                                                color='default'
                                                disabled={isLoading || isLoadingSubmitCode}
                                                {...(isLoading && { loading: { text: '' } })}
                                            >
                                                {t('auth.external-providers-disconnect')}
                                            </LoadingButton>
                                        ) : (
                                            <LoadingButton
                                                type='submit'
                                                size='small'
                                                variant='contained'
                                                onClick={() => providerSignIn(name)}
                                                disabled={isLoading || isLoadingSubmitCode}
                                                {...(isLoading && { loading: { text: '' } })}
                                            >
                                                {t('auth.external-providers-connect')}
                                            </LoadingButton>
                                        )}
                                    </Grid>
                                </Grid>
                                {!isLast(index) && <Divider />}
                            </>
                        ))}
                </Grid>
            </Card>
        </>
    );
};

export default ProfileConnectAccounts;
