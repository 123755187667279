import { useTranslation } from 'react-i18next';
import { getBackofficeRedirectUrl } from 'common/utils';
import {
    defaultPrivacyPolicyUrl,
    defaultTermsOfServiceUrl,
} from 'common/constants/redirect-urls.constant';
import { usePlatformContext } from 'routes/platform.context';
import { Button } from 'common/components';
import { Container } from './styles';

const Footer = () => {
    const backofficeRedirectUrl = getBackofficeRedirectUrl();
    const { t } = useTranslation();
    const { platform } = usePlatformContext();

    return (
        <Container>
            <Button
                onClick={() => {
                    if (
                        backofficeRedirectUrl.includes('licitar') ||
                        backofficeRedirectUrl.includes('localhost')
                    ) {
                        window.open(
                            platform?.termsOfService
                                ? platform?.termsOfService
                                : defaultTermsOfServiceUrl,
                            '_blank'
                        );
                    }
                }}
                size='small'
                variant='text'
                color='primary'
            >
                {t('term.use-terms')}
            </Button>
            <Button
                onClick={() =>
                    window.open(
                        platform?.privacyPolicyUrl
                            ? platform?.privacyPolicyUrl
                            : defaultPrivacyPolicyUrl,
                        '_blank'
                    )
                }
                size='small'
                variant='text'
                color='primary'
            >
                {t('shared.pages.menu-header.option.privacy-policy')}
            </Button>
        </Container>
    );
};

export default Footer;
