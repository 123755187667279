import i18n from 'i18next';
import { AuctionType } from '../../../clients/manager/interfaces/auction-notice.interface';

export const auctionTypeToLabel = (auctionType?: AuctionType): { short: string; long: string } => {
    switch (auctionType) {
        case 'E':
            return {
                short: i18n.t('term.trading'),
                long: i18n.t('term.electronic-auction'),
            };

        case 'D':
            return {
                short: i18n.t('term.waiver'),
                long: i18n.t('term.waiver'),
            };

        case 'C':
            return {
                short: i18n.t('term.accreditation-abrev'),
                long: i18n.t('term.accreditation'),
            };

        case 'I':
            return {
                short: i18n.t('term.unenforceability-abrev'),
                long: i18n.t('term.unenforceability'),
            };

        case 'L':
            return {
                short: i18n.t('term.auction'),
                long: i18n.t('term.auction'),
            };

        case 'U':
            return {
                short: i18n.t('term.contest'),
                long: i18n.t('term.contest'),
            };

        case 'R':
            return {
                short: i18n.t('term.competition-abrev'),
                long: i18n.t('term.competition'),
            };

        default:
            return {
                short: '--',
                long: '--',
            };
    }
};
