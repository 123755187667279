import React from 'react';
import { Badge, Button, InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import { debounce } from 'lodash';
import { RiShoppingCart2Line } from 'react-icons/ri';
import { useStyles } from './home-header-style';

interface HomeHeaderViewProps {
    onSearch: (value: string) => void;
    onCartButtonClick?: () => void;
    quantityOfItemsOnCart: number;
}

const HomeHeaderView: React.FC<HomeHeaderViewProps> = ({
    onSearch,
    onCartButtonClick,
    quantityOfItemsOnCart,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <header className={styles.container}>
            <div className={styles.searchContainer}>
                <TextField
                    fullWidth
                    label={t('term.marketplace-search')}
                    placeholder={t('term.marketplace-search-placeholder')}
                    variant='outlined'
                    role='search'
                    onChange={debounce((e) => onSearch(e.target.value), 500)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <AiOutlineSearch size={20} />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Badge badgeContent={quantityOfItemsOnCart} color='error'>
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={onCartButtonClick}
                >
                    <RiShoppingCart2Line size={30} className={styles.icon} />
                </Button>
            </Badge>
        </header>
    );
};

export default HomeHeaderView;
