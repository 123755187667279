import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6),
        width: '100%',
    },
}));

export default useStyles;
