import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import yup from 'common/utils/yup-extended.utils';
import { addNotificationError, addNotificationSuccess, featureFlagEnabled } from 'common/utils';
import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import { ChangePasswordView } from './ChangePasswordView';

type UpdatePassword = {
    code?: string;
    password?: string;
    confirmPassword?: string;
};

export const ChangePassword = () => {
    const { t } = useTranslation();
    const { forgotPasswordSubmit } = useUserConnectedProviders();
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const queryUsername = query.get('username') ?? '';
    const queryCountry = query.get('country') ?? '';
    const isWeakPassword = query.get('weakPassword') ?? '';
    const cognitoIsEnabled = featureFlagEnabled('enableCognitoAccess');

    const regexUppercase = /^(?=.*[A-Z]).+$/;
    const regexLowercase = /^(?=.*[a-z]).+$/;
    const regexNumber = /^(?=.*[0-9]).+$/;
    const regexSpecial = /[!@#$%^&*(),.?":{}|<>]/;

    const cognitoUpdatePassword = async (
        values: UpdatePassword,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        const { code, confirmPassword } = values;
        try {
            if (queryUsername && code && confirmPassword) {
                await forgotPasswordSubmit(
                    queryUsername,
                    queryCountry,
                    confirmPassword,
                    String(code)
                );
                history.push('/login');
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('password-reset.success'),
                });
            }
        } catch (error: any) {
            let message = error?.response?.data?.message || error?.message;
            const code = error?.response?.data?.token || error?.code;
            if (code === 'COGNITO_LIMIT_EXCEEDED') {
                message = t('cognito.error-limit-exceeded');
            }
            if (code === 'COGNITO_INVALID_CODE') {
                message = t('cognito.error-invalid-code');
            }
            addNotificationError({
                message,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const form = useFormik({
        initialValues: {
            code: undefined,
            password: '',
            confirmPassword: '',
        },
        validationSchema: yup.object().shape({
            code: cognitoIsEnabled
                ? yup.string().required(t('term.required-field')).min(6).max(6)
                : yup.string(),
            password: yup
                .string()
                .matches(regexLowercase, t('password-lowercase', { ns: 'validation' }))
                .matches(regexUppercase, t('password-upercase', { ns: 'validation' }))
                .matches(regexNumber, t('password-number', { ns: 'validation' }))
                .matches(regexSpecial, t('password-special-character', { ns: 'validation' }))
                .min(8, t('password-min-number-character', { ns: 'validation' }))
                .required(t('password', { ns: 'validation' })),
            confirmPassword: yup
                .string()
                .oneOf([yup.ref('password'), null], t('password-equals', { ns: 'validation' }))
                .required(t('password-confirm', { ns: 'validation' })),
        }),
        onSubmit: async (values: UpdatePassword, { setSubmitting }) => {
            await cognitoUpdatePassword(values, setSubmitting);
        },
    });

    return <ChangePasswordView form={form} isWeakPassword={Boolean(isWeakPassword)} />;
};

export type { UpdatePassword };
