import React, { memo, ReactElement, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, DialogContent, DialogContentText } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { AppealStatusType } from 'clients/manager/interfaces/auction-notice.interface';
import { addNotificationApiError } from 'common/utils';

const StartResourceIntentDialog = ({ children }) => {
    const { process, auctionTypeRules, processForm, setProcessBkpBeforeSave } =
        useProcessFormContext();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const visibleTimeDefinition =
        auctionTypeRules?.generalSettings?.solicitacoes?.recurso?.permiteRedefinirPrazos;

    const defaultDaysDefinition =
        auctionTypeRules?.generalSettings?.solicitacoes?.recurso?.diasRecurso || 0;

    const [daysDefinition, setDaysDefinition] = useState<number>(Number(defaultDaysDefinition));

    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onClick: (ev: any) => {
            (children as any)?.props?.onClick?.(ev);
            ev.stopPropagation();
            setOpen(true);
        },
    });

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);
    };

    const handleConfirm = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);

        if (!process?.id || !processForm) {
            return;
        }

        try {
            const response = await auctionNoticeRequests.startProcessAppealPhase(
                process.id,
                daysDefinition
            );

            if (response?.data?.deadlineAppeal) {
                const newValues = {
                    ...processForm.values,
                    appealStatus: AppealStatusType.request,
                    deadlineAppeal: response.data.deadlineAppeal,
                };
                setProcessBkpBeforeSave(newValues);
                processForm.setValues(newValues);
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    return (
        <>
            {cloneChildren}
            <Dialog open={open} onClose={handleClose} maxWidth='xs'>
                <Box p={1}>
                    <DialogTitle>{t('info.start-resource-intent-title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color='textPrimary'>
                            {t('info.start-resource-intent-message')}
                        </DialogContentText>
                        <Box mt={2} mb={1} width={1} display='flex' justifyContent='center'>
                            <TextField
                                disabled={!visibleTimeDefinition}
                                label={t('info.time-in-days')}
                                name='days'
                                value={daysDefinition}
                                onChange={(event) => setDaysDefinition(Number(event.target.value))}
                                type='number'
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                    },
                                }}
                                autoFocus={!!visibleTimeDefinition}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color='primary' size='small'>
                            {t('term.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleConfirm}
                            autoFocus
                            size='small'
                        >
                            {t('term.open-up')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default memo(StartResourceIntentDialog);
