import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import {
    ListSusCodeBody,
    ListSusCodeResponse,
} from '../interfaces/integrations-pagesaude.interface';
import { organizationAppsRequests } from '../../process-integrations/services/organization-apps.requests';

const BASE_PATH = '/integration/pagesaude';

const integrationsPagesusRequests = {
    listOrganizationApps: organizationAppsRequests.listOrganizationApps,
    listSusCode: async (body?: ListSusCodeBody, config?: AxiosRequestConfig<any>) =>
        doRequest<ListSusCodeResponse>(
            managerApiInstance.post(`${BASE_PATH}/listSusCode`, body, config)
        ),
};

export { integrationsPagesusRequests };
