import { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import {
    HiExternalLink,
    HiOutlineCalendar,
    HiGlobeAlt,
    HiOutlineLockClosed,
    HiBookmark,
    HiOutlineShare,
} from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { NoticeInformation } from 'clients/manager/interfaces/notice-information.interfaces';
import { noticesInformationRequests } from 'clients/manager/notice-information.requests';
import { addNotificationError, formatDate } from 'common/utils';
import { usePageContext } from 'common/components/base/pages/context';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { getLabelChip } from 'modules/notices-information/utils';
import Breadcrumb from 'common/components/base/breadcrumb';
import { Container } from '@material-ui/core';
import moment from 'moment';
import InformationFileChip from './components/information-file-chip';

const DetailsNoticesInformationPage = () => {
    const { t } = useTranslation();
    const navigate = useHistory();
    const { setTitle } = usePageContext();
    const { id: noticeInformationId } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [noticeInformationDetails, setNoticeInformationDetails] = useState<NoticeInformation>();

    const handleShareNoticeInformation = () => {
        setCopiedToClipboard(true);
        navigator.clipboard.writeText(location.href);
    };

    const getChipExpirationAt = (expirationDate?: string | null) => {
        if (!expirationDate) return null;
        if (moment(expirationDate).diff(moment(new Date()), 'days') < 0) {
            return (
                <Chip
                    style={{
                        color: 'var(--danger-color',
                        borderColor: 'var(--danger-color',
                    }}
                    variant='outlined'
                    label={t('notices-information.labels.expired', {
                        dateExpire: formatDate(expirationDate),
                    })}
                />
            );
        }
        return (
            <Chip
                variant='outlined'
                label={t('notices-information.labels.expires-in', {
                    dateExpire: formatDate(expirationDate),
                })}
                color='primary'
            />
        );
    };

    const getUniqueNoticeInformation = async () => {
        setIsLoading(true);
        try {
            if (!noticeInformationId) {
                addNotificationError({
                    title: t('term.error'),
                    message: t('notices-information.info.empty-id-selected-notices-information'),
                });
            }
            await noticesInformationRequests
                .getUniqueNoticeInformation(Number(noticeInformationId))
                .then(({ data, status }) => {
                    if (status === 'error' && !data) {
                        addNotificationError({
                            title: 'Publicação não encotrada',
                            message: 'A publicação não está disponível ou expirou',
                        });
                        navigate.push('/avisos-informacoes');
                    } else {
                        setNoticeInformationDetails(data);
                    }
                })
                .catch(() => {
                    navigate.push('/avisos-informacoes');
                });
        } catch (err) {
            addNotificationError({
                title: t('term.error'),
                message: err,
            });
            navigate.push('/avisos-informacoes');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setTitle(t('term.notice-information'));
        getUniqueNoticeInformation();

        return () => {
            setNoticeInformationDetails(undefined);
        };
    }, []);

    if (!noticeInformationId) {
        navigate.push('/avisos-informacoes');
    }

    return (
        <PageWrapper>
            <Container fixed maxWidth='xl' style={{ padding: 0 }}>
                <Box marginBottom={3}>
                    <Breadcrumb
                        customPath='/avisos-informacoes/:noticeInformation'
                        paths={{
                            'avisos-informacoes': {
                                value: t('term.notices-information'),
                                onClick: () => navigate.push('/avisos-informacoes'),
                            },
                        }}
                        params={{
                            noticeInformation: {
                                value: noticeInformationDetails?.title || '-',
                                disabled: true,
                            },
                        }}
                    />
                </Box>
                <Box
                    style={{
                        padding: '12px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {isLoading && !noticeInformationDetails ? (
                        <>
                            <Skeleton variant='text' height={52} width='80%' />
                            <Skeleton variant='text' height={52} width='15%' />
                        </>
                    ) : (
                        <>
                            <Typography variant='h6' color='secondary'>
                                {noticeInformationDetails?.title}
                            </Typography>
                            <Button
                                disabled={copiedToClipboard}
                                title={t('term.share')}
                                variant='contained'
                                color='primary'
                                startIcon={<HiOutlineShare size={18} />}
                                onClick={handleShareNoticeInformation}
                            >
                                {!copiedToClipboard
                                    ? t('term.share')
                                    : t('notices-information.button.link-copied')}
                            </Button>
                        </>
                    )}
                </Box>
                {isLoading && !noticeInformationDetails ? (
                    <>
                        <Skeleton variant='text' height={32} width='100%' />
                        <Skeleton variant='text' height={32} width='75%' />
                    </>
                ) : (
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        style={{ textAlign: 'justify' }}
                    >
                        {noticeInformationDetails?.resume}
                    </Typography>
                )}
                <Box
                    sx={{
                        paddingTop: 16,
                        paddingBottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {isLoading && !noticeInformationDetails ? (
                        <Skeleton
                            variant='rect'
                            height={32}
                            width={80}
                            style={{ borderRadius: 16 }}
                        />
                    ) : (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                gap: '8px',
                            }}
                        >
                            <Chip
                                label={getLabelChip(
                                    t,
                                    noticeInformationDetails?.audience || ''
                                ).toLowerCase()}
                                color='primary'
                            />
                            {getChipExpirationAt(noticeInformationDetails?.expirationAt)}
                            <Chip
                                icon={
                                    noticeInformationDetails?.isPublic ? (
                                        <HiGlobeAlt />
                                    ) : (
                                        <HiOutlineLockClosed />
                                    )
                                }
                                variant='outlined'
                                label={
                                    noticeInformationDetails?.isPublic
                                        ? t('notices-information.labels.published')
                                        : t('notices-information.labels.not-published')
                                }
                                color='primary'
                            />
                            {noticeInformationDetails?.isFixedOnTop && (
                                <Chip
                                    icon={<HiBookmark />}
                                    variant='outlined'
                                    label={t('notices-information.labels.fixed-at-the-top')}
                                    color='primary'
                                />
                            )}
                        </Box>
                    )}
                    {isLoading && !noticeInformationDetails ? (
                        <Skeleton variant='text' height={56} width={144} />
                    ) : (
                        <Button
                            disabled
                            style={{
                                userSelect: 'none',
                                opacity: 1,
                                color: 'var(--primary-color)',
                            }}
                            variant='text'
                            color='primary'
                            startIcon={<HiOutlineCalendar size={24} />}
                        >
                            {formatDate(noticeInformationDetails?.updateAt)}
                        </Button>
                    )}
                </Box>
                <Box
                    style={{
                        paddingTop: 16,
                        paddingBottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box style={{ display: 'flex', gap: 6 }}>
                        {isLoading && !noticeInformationDetails ? (
                            <>
                                <Skeleton
                                    variant='rect'
                                    height={32}
                                    width={128}
                                    style={{ borderRadius: 16 }}
                                />
                                <Skeleton
                                    variant='rect'
                                    height={32}
                                    width={128}
                                    style={{ borderRadius: 16 }}
                                />
                            </>
                        ) : (
                            <>
                                {noticeInformationDetails?.textLink && (
                                    <Chip
                                        clickable
                                        color='default'
                                        size='medium'
                                        variant='outlined'
                                        label={String(noticeInformationDetails?.textLink)}
                                        component='a'
                                        target='_blank'
                                        href={String(noticeInformationDetails?.textLink)}
                                        icon={<HiExternalLink color='var(--primary-color)' />}
                                    />
                                )}
                                {noticeInformationDetails?.urlLink && (
                                    <Chip
                                        clickable
                                        color='default'
                                        size='medium'
                                        variant='outlined'
                                        label={String(noticeInformationDetails?.urlLink)}
                                        component='a'
                                        target='_blank'
                                        href={String(noticeInformationDetails?.urlLink)}
                                        icon={<HiExternalLink color='var(--primary-color)' />}
                                    />
                                )}
                            </>
                        )}
                    </Box>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 6, marginTop: 12 }}>
                        {isLoading && !noticeInformationDetails ? (
                            <Skeleton
                                variant='rect'
                                height={32}
                                width={192}
                                style={{ borderRadius: 16 }}
                            />
                        ) : (
                            <>
                                {noticeInformationDetails?.informationFiles &&
                                    noticeInformationDetails?.informationFiles?.map(
                                        (informationFile, index) => (
                                            <InformationFileChip
                                                url={informationFile.url}
                                                key={informationFile.url + index}
                                            />
                                        )
                                    )}
                            </>
                        )}
                    </Box>
                </Box>
                {isLoading && !noticeInformationDetails ? (
                    <Box
                        component='article'
                        style={{
                            paddingTop: 6,
                            paddingBottom: 12,
                            textAlign: 'justify',
                        }}
                    >
                        {[...Array(9)].map((_, index) => {
                            if (index + 1 === 9) {
                                return <Skeleton variant='text' height={22} width='60%' />;
                            }
                            return <Skeleton variant='text' height={22} width='100%' />;
                        })}
                        <br />
                        {[...Array(9)].map((_, index) => {
                            if (index + 1 === 9) {
                                return <Skeleton variant='text' height={22} width='45%' />;
                            }
                            return <Skeleton variant='text' height={22} width='100%' />;
                        })}
                        <br />
                        {[...Array(9)].map((_, index) => {
                            if (index + 1 === 9) {
                                return <Skeleton variant='text' height={22} width='75%' />;
                            }
                            return <Skeleton variant='text' height={22} width='100%' />;
                        })}
                    </Box>
                ) : (
                    <Box
                        component='article'
                        style={{
                            paddingTop: 6,
                            paddingBottom: 12,
                            textAlign: 'justify',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: String(noticeInformationDetails?.description),
                        }}
                    />
                )}
            </Container>
        </PageWrapper>
    );
};

export default memo(DetailsNoticesInformationPage);
