import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@licitar/ui-kit';
import moment from 'moment';
import { InputWrapper } from './styled';

interface Props {
    values: any;
    setFieldValue: (key: string, value: any) => any;
}

const DataComprasNet: FC<Props> = ({ values, setFieldValue }) => {
    const { t } = useTranslation();

    return (
        <>
            <InputWrapper>
                <Input
                    label='Offset'
                    type='number'
                    name='data.offset'
                    value={values.data.offset}
                    onChange={(ev) => {
                        setFieldValue('data.offset', parseInt(ev.target.value, 10));
                    }}
                />
            </InputWrapper>
            <InputWrapper>
                <Input
                    label={t('mkp.search.extract-after')}
                    name='data.extractAfter'
                    type='date'
                    value={values.data.extractAfter}
                    onChange={(value) => {
                        setFieldValue('data.extractAfter', moment(value).valueOf());
                    }}
                />
            </InputWrapper>
        </>
    );
};

export default DataComprasNet;
