import { PurgeResume } from 'common/interfaces/purge.interface';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IoReload } from 'react-icons/io5';

interface Props {
    resume: PurgeResume;
    onReload: () => void;
}
const Resume: FC<Props> = ({ resume, onReload }) => {
    const { t } = useTranslation();

    return (
        <div>
            <p>
                <h3>
                    {t('mkp.settings.expurg-data')}
                    <IoReload
                        onClick={onReload}
                        style={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                        }}
                    />
                </h3>
            </p>
            <div>
                {' '}
                {t('term.purging')}: {resume?.purge?.status || <b>N/A</b>}{' '}
            </div>
            <div>
                Elastic(item):
                <b>{resume.elasticItemCount}</b>
            </div>
            <div>
                Mongo(item): <b>{resume.mongoItemCount}</b>
            </div>
            <div>
                Mongo(priceItem):
                <b>{resume.mongoItemPricesCount}</b>
            </div>
        </div>
    );
};

export default Resume as FC<Props>;
