import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const LogoContainer = styled.div`
    height: 6rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    > img {
        margin: 0;
        padding: 0;
        height: calc(100% - 16px);
    }
`;

export const ContentValidation = styled(Grid)`
    .validation {
        display: flex;
        align-items: center;
        border-radius: 1rem;
        margin: 16px;
        gap: 10px;
        .validation-false {
            color: var(--danger-color);
        }
        .validation-true {
            color: var(--success-color);
        }
    }
`;
