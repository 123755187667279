import styled from 'styled-components';

export const PcaItemContainer = styled.div`
    min-height: 96px;
    background-color: var(--background-color);
    border: 1px var(--border-color) solid;
    border-radius: 4px;
    display: flex;
`;

export const PcaItemContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
`;

export const PcaItemData = styled.div`
    flex: 1;
    padding: 16px;

    display: flex;
    align-items: center;
    flex-direction: row;

    h1 {
        font-size: 18px;
        text-align: center;
        line-height: normal;
        color: inherit;
    }

    h2 {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-light-secondary-color);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        margin: 0;
    }
`;

export const DataLeftItens = styled.div`
    flex: 5;
    display: flex;
    flex-direction: column;

    > h2 {
        padding-bottom: 8px;
    }

    > span {
        h1 {
            text-align: start;
            margin: 0;
        }
    }

    > p {
        display: block;
        margin: 0;
        font-size: 10px;
        color: var(--tertiary-color);
        margin-top: auto;
        padding-top: 8px;
    }
`;

export const DataMiddleItens = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const DataRightItens = styled.div`
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const AmountBox = styled.div`
    /* margin-right: 1.5rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    h1 {
        font-size: 20px;
        margin: 0;
    }
`;

export const StatusBox = styled.div`
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    h1 {
        font-size: 16px;
        margin: 0;
    }
`;

export const PcaItemButtonDetails = styled.div`
    padding: 4px;
    width: 3.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 24px;
    color: var(--background-color);
    background-color: var(--secondary-color);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    cursor: pointer;

    &:hover {
        filter: brightness(0.91);
        transition: filter 250ms;
    }
`;
