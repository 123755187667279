import { contractRequests } from 'clients/manager/contract.requests';
import { ListContractsBodyData } from 'clients/manager/interfaces/contract.interface';
import { addNotificationApiError } from 'common/utils';
import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePageContext } from 'common/components/base/pages/context';
import SearchContractsTableView from './search-contracts-table-view';
import { useSearchContractsContext } from '../../context/search-contracts-context';

const SearchContractsTable = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const { setContracts, setRequestsCount, requestFilters } = useSearchContractsContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const listContracts = async (requestFilters: ListContractsBodyData) => {
        setLoading(true);
        setError('');

        try {
            const response = await contractRequests.listContracts(requestFilters);
            setContracts(response.data || []);
            setRequestsCount(response?.meta?.count || 0);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            addNotificationApiError(error);
            setError(t('error.response.try-again'));
        }
    };

    useEffect(() => {
        listContracts(requestFilters);
        setTitle(t('term.contracts'));
    }, [requestFilters]);

    return (
        <>
            {error && <Typography color='error'>{error}</Typography>}
            {!error && (
                <SearchContractsTableView
                    {...{
                        loading,
                    }}
                />
            )}
        </>
    );
};

export default SearchContractsTable;
