import { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ExpandMoreIcon } from 'common/icons';

export const useStyles = makeStyles(() => ({
    root: {
        '& .MuiAccordionSummary-content': {
            margin: '12px 0',
            height: '40px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
    },
    disabled: {
        backgroundColor: '#00000012 !important',
    },
    expanded: {},
    header: {
        backgroundColor: '#4b4b4b08',
        // borderBottom: '1px solid #e0e0e0',
    },
}));

interface ExpandedContentCardProps {
    title?: string;
    header?: React.ReactNode;
    defaultExpanded?: boolean;
    onExpanded?: (expanded: boolean) => void;
    expanded?: boolean;
    omitExpandIcon?: boolean;
    disabled?: boolean;
}

const ExpandedContentCard: FC<ExpandedContentCardProps> = ({
    title,
    children,
    header,
    defaultExpanded,
    expanded,
    onExpanded,
    omitExpandIcon,
    disabled = false,
}) => {
    const classes = useStyles();

    return (
        <Accordion
            classes={{ root: classes.root, disabled: classes.disabled, expanded: classes.expanded }}
            defaultExpanded={defaultExpanded ?? true}
            expanded={expanded}
            elevation={0}
            disabled={disabled}
            TransitionProps={{ timeout: 200, mountOnEnter: true }}
            onClick={() => onExpanded?.(!expanded)}
        >
            <AccordionSummary
                classes={{
                    root: classes.header,
                }}
                {...(omitExpandIcon ? {} : { expandIcon: <ExpandMoreIcon /> })}
            >
                {header ?? (
                    <Box display='flex' alignItems='center'>
                        <Typography variant='body1'>
                            <b>{title}</b>
                        </Typography>
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default ExpandedContentCard;
