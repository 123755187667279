import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Card, Typography } from '@material-ui/core';
import useStyles from './PaymentCardStyle';

export default function PaymentCardView({
    title,
    subtitle,
    IconComponent,
    handleSelected,
    selected,
}) {
    const classes = useStyles(selected);

    return (
        <Card className={classes.card} onClick={handleSelected}>
            <div className={classes.cardContent}>
                <div className={classes.boxIconAndTitle}>
                    <IconComponent className={classes.paymentIcon} />
                    <div className={classes.boxTitle}>
                        <Typography variant='body1'>
                            <strong>{title}</strong>
                        </Typography>
                        <Typography variant='body2'>
                            <strong>{subtitle}</strong>
                        </Typography>
                    </div>
                </div>

                {selected && <CheckCircleIcon fontSize='small' className={classes.checkIcon} />}
            </div>
        </Card>
    );
}
