import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Alert, AlertProps } from '@material-ui/lab';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { providerRequests } from 'clients/manager/provider.requests';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import { timeout } from 'common/utils/timer.utils';
import { apiRedirectTo, formatDate, getBackofficeRedirectUrl } from 'common/utils';
import { StatusCrc } from 'common/enums';
import { usePlatformContext } from 'routes/platform.context';
import { TypeUser } from '../../interfaces/user.interface';

const useStyles = makeStyles(() => ({
    message: {
        width: '100%;',
    },
}));

interface ProviderSignupExpirationProps {
    location: 'dashboard' | 'edit-provider';
}

const ProviderSignupExpiration: FC<ProviderSignupExpirationProps> = ({ location }) => {
    const backofficeRedirectUrl = getBackofficeRedirectUrl();
    const { platform } = usePlatformContext();

    const { currentAccessType } = useSelector((state: AppState) => state.authUserState);
    const [provider, setProvider] = useState<Provider | undefined>(undefined);

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        if (currentAccessType !== TypeUser.provider) {
            return;
        }

        const request = async () => {
            const provider = await providerRequests.getProvider();
            setProvider(provider);
        };

        timeout(request, 500);
    }, [currentAccessType]);

    function getLinkCertificateProvider(provider: Provider) {
        return (
            <a
                href={
                    apiRedirectTo(
                        backofficeRedirectUrl,
                        `/crc/?providerId=${provider.id}`,
                        false,
                        true
                    ) as string
                }
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: 'none' }}
            >
                {t('term.here')}.
            </a>
        );
    }

    const providerMessage = useMemo(() => {
        if (
            provider?.signupValid === StatusCrc.isValid &&
            provider?.signupValidExpiration === null
        ) {
            return (
                <>
                    <Typography variant='body2'>
                        {t('info.crc-expiration-no-date')} {getLinkCertificateProvider(provider)}
                    </Typography>
                </>
            );
        }
        if (
            provider?.signupValid === StatusCrc.isValid &&
            provider?.signupValidExpiration !== null
        ) {
            return (
                <>
                    <Typography variant='body2'>
                        {t('info.crc-expiration', {
                            value: moment(provider.signupValidExpiration || new Date()).format(
                                'DD/MM/YYYY'
                            ),
                        })}{' '}
                        {getLinkCertificateProvider(provider)}
                    </Typography>
                </>
            );
        }

        if (provider?.signupValid === StatusCrc.pending) {
            const message =
                platform?.generalSettings?.provider?.pendingRegisterRequestMessage ??
                'info.pending-request-dont-send-proposal';
            return (
                <Typography variant='body2'>{`${t(message)} ${t(
                    'info.waiting-for-approval'
                )}`}</Typography>
            );
        }

        if (provider?.signupValid === StatusCrc.reproved) {
            return (
                <>
                    <Typography variant='body2'>
                        {t('info.reproved-request')}.{' '}
                        <Link
                            to='/empresa/editar/documentos'
                            rel='noreferrer'
                            style={{ textDecoration: 'none' }}
                        >
                            {t('term.view')}.
                        </Link>
                    </Typography>
                </>
            );
        }

        if (provider?.signupValid === StatusCrc.suspended && provider.suspendedUntil) {
            return (
                <>
                    <Typography variant='body2'>
                        {t('info.crc-suspended', {
                            suspendedDate: formatDate(provider.suspendedUntil),
                        })}
                    </Typography>
                </>
            );
        }

        return null;
    }, [provider?.signupValid]);

    const getAlertProps = (): AlertProps => {
        if (provider?.signupValid === StatusCrc.isValid) {
            return {
                severity: 'success',
                style: {
                    border: '1px solid #02AE51',
                },
            };
        }

        if (provider?.signupValid === StatusCrc.pending) {
            return {
                severity: 'warning',
                style: {
                    border: '1px solid #EFBE0E',
                },
            };
        }

        if (
            provider?.signupValid === StatusCrc.reproved ||
            provider?.signupValid === StatusCrc.suspended
        ) {
            return {
                severity: 'error',
                style: {
                    border: '1px solid #f44336',
                },
            };
        }

        return {};
    };

    if (!provider || (provider.signupValid === StatusCrc.isValid && location === 'dashboard')) {
        return null;
    }

    return (
        <Box mb={1}>
            <Alert
                variant='standard'
                classes={{
                    message: classes.message,
                }}
                {...getAlertProps()}
            >
                <Box width={1}>{providerMessage}</Box>
            </Alert>
        </Box>
    );
};

export default memo(ProviderSignupExpiration);
