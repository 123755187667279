import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { userRequests } from 'clients/manager/user.requests';
import CodeValidatorView from './code-validator-view';

export interface CodeValidatorFormValues {
    verificationCode: string;
}

export interface CodeValidatorProps {
    open: boolean;
    email?: string;
    onSubmit?: (values: CodeValidatorFormValues) => void;
    onClose?: () => void;
}

const CodeValidator: React.FC<CodeValidatorProps> = ({ open, email, onSubmit, onClose }) => {
    const { t } = useTranslation();

    const form = useFormik({
        initialValues: {
            verificationCode: '',
        },
        validationSchema: yup.object().shape({
            verificationCode: yup.string().required(t('term.required-field')).min(7).max(7),
        }),
        validateOnBlur: false,
        onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
            try {
                setSubmitting(true);

                if (!email) {
                    setErrors({ verificationCode: t('code-validator.invalid-code') });
                    setSubmitting(false);
                    return;
                }

                await userRequests.validateVerificationCode({
                    verificationCode: values.verificationCode,
                    email,
                });

                resetForm();
                onSubmit?.(values);
                return;
            } catch (err) {
                setErrors({ verificationCode: t('code-validator.invalid-code') });
            } finally {
                setSubmitting(false);
            }
        },
    });

    const handleSendEmailVerificationCode = useCallback(async () => {
        if (!email) return;

        await userRequests.sendEmailVerificationCode({ email });
    }, [email]);

    useEffect(() => {
        if (!form.values.verificationCode && open) {
            handleSendEmailVerificationCode();
        }

        return () => form.resetForm();
    }, [open]);

    return (
        <CodeValidatorView
            form={form}
            open={open}
            onClose={onClose}
            onResendCode={handleSendEmailVerificationCode}
        />
    );
};

export default CodeValidator;
