/* eslint-disable no-prototype-builtins */
import { OrganizationData } from 'clients/manager/interfaces/organization.interface';
import { createContext, memo, useEffect, useState } from 'react';
import { usePlatformContext } from 'routes/platform.context';
import { useHistory } from 'react-router';
import { ISignUpOrganizationContext } from '../props';

export const SignUpOrganizationContext = createContext<ISignUpOrganizationContext>({
    activeStep: 0,
});

export const SignUpOrganizationContextProvider = memo(({ children }) => {
    const { platform } = usePlatformContext();
    const history = useHistory();
    const [organization, setOrganization] = useState<OrganizationData>();
    const [activeStep, setActiveStep] = useState(0);
    const [regionOrganization, setRegionOrganization] = useState([]);
    const [userOrganizationExist, setUserOrganizationExist] = useState<boolean>(false);
    const [organizationId, setOrganizationId] = useState<string>('');

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        setRegionOrganization([
            {
                name: organization?.cityName,
                ibgeCode: organization?.ibgeCode,
                uf: organization?.state,
            },
        ] as any);
    }, [organization]);

    useEffect(() => {
        if (platform?.generalSettings?.general?.hideSignupOrganization) {
            return history.push('/sem-permissao');
        }
    }, []);

    return (
        <SignUpOrganizationContext.Provider
            value={{
                organization,
                setOrganization,
                activeStep,
                setActiveStep,
                handleNext,
                handleBack,
                regionOrganization,
                setRegionOrganization,
                userOrganizationExist,
                setUserOrganizationExist,
                organizationId,
                setOrganizationId,
            }}
        >
            {children}
        </SignUpOrganizationContext.Provider>
    );
});
