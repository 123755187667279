import {
    AuctionNoticeLotWithItems,
    TypeOfBenefitValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';

export function orderLots(lots: AuctionNoticeLotWithItems[]) {
    lots.sort((a, b) => {
        if (a.item < b.item) {
            return -1;
        }
        if (a.item > b.item) {
            return 1;
        }
        return 0;
    });

    let item = 1;
    lots.forEach((lot) => {
        lot.item = item;
        if (lot.typeOfBenefit !== TypeOfBenefitValue.masterQuota) {
            item++;
        }
    });
}
