import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Input, TextArea } from '@licitar/ui-kit';
import { YupValidationMessages } from 'common/constants/yup-validation-messages.constant';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { Box } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import { useTranslation } from 'react-i18next';
import { addNotificationError, addNotificationSuccess, addNotificationWarning } from 'common/utils';
import { validateOnlyBlank } from 'common/utils/validators/validate-only-blank.utils';
import LoadingButton from 'common/components/loading-button';
import { ModalEditTemplateProps } from './props';
import { ActionsArea } from '../modal-create-variable/styles';
import { Content } from '../modal-create-template/styles';

const ModalEditTemplate: FC<ModalEditTemplateProps> = ({
    onCancel,
    fromTemplateId,
    setTemplateUpdated,
}) => {
    const { t } = useTranslation();
    const [template, setTemplate] = useState<DocumentTemplate | undefined>();
    const [updatingTemplate, setUpdatingTemplate] = useState<boolean>(false);

    const getTemplate = async () => {
        try {
            const { data: templateDocument } = await templateRequests.getTemplate(fromTemplateId);
            setTemplate(templateDocument);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.templates.components.modal-create-template.notification-error-search-template'
                ),
            });
            onCancel();
        }
    };

    useEffect(() => {
        getTemplate();
    }, []);

    const updateTemplate = async (values: { name: string; description?: string }) => {
        try {
            if (
                !template?._id ||
                !template?.type ||
                !template?.processTypes ||
                !template?.name ||
                !template?.supplyCategories
            ) {
                return;
            }
            const newTemplate = { ...template, ...values };
            setUpdatingTemplate(true);
            await templateRequests.updateTemplate(newTemplate);
            setTemplateUpdated(newTemplate);
            addNotificationSuccess();
            onCancel();
        } catch (error) {
            setUpdatingTemplate(false);
            addNotificationError();
        }
    };

    const form = useFormik<{ name: string; description?: string }>({
        initialValues: {
            name: template?.name || '',
            description: template?.description ?? '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required(YupValidationMessages.REQUIRED),
            description: Yup.string(),
        }),
        onSubmit: (values) => updateTemplate(values),
    });
    const isNotBlank = validateOnlyBlank(form.values);

    return (
        <Content>
            <Modal
                position={ModalPosition.center}
                open
                onClose={onCancel}
                header={
                    <span>
                        {`${t(
                            'editor.templates.components.modal-update-template.msg-modal-header'
                        )}`}
                    </span>
                }
            >
                <Box width='450px' flex>
                    <Input
                        style={{ margin: '10px 0 0 0' }}
                        value={form.values.name}
                        name='name'
                        autoFocus
                        label={t('term.model-name')}
                        onChange={(ev) => form.setFieldValue('name', ev.target.value)}
                    />
                    <TextArea
                        style={{ margin: '10px 0 0 0' }}
                        label={t('term.description')}
                        name='description'
                        value={form.values.description}
                        onChange={(ev) => {
                            form.setFieldValue('description', ev.target.value);
                        }}
                    />
                    <ActionsArea>
                        <Button
                            title={t('term.cancel')}
                            size='small'
                            variant='outlined'
                            color='default'
                            onClick={() => onCancel()}
                        >
                            {t('term.cancel')}
                        </Button>
                        <LoadingButton
                            disabled={
                                !isNotBlank ||
                                !form.dirty ||
                                !!Object.keys(form.errors).length ||
                                (!!fromTemplateId && !template)
                            }
                            title={t(
                                'editor.templates.components.modal-update-template.msg-modal-header'
                            )}
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={() => form.submitForm()}
                            {...(updatingTemplate
                                ? {
                                      loading: {
                                          text: `${t('term.updating')}..`,
                                      },
                                  }
                                : {})}
                        >
                            {t('term.update')}
                        </LoadingButton>
                    </ActionsArea>
                </Box>
            </Modal>
        </Content>
    );
};

export default ModalEditTemplate;
