import styled from 'styled-components';

export const StyledProcessCommentAnonymizedComponent = styled.div`
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    top: -4px;

    > svg {
        position: absolute;
        transform: translateY(1px);
    }
`;
