import { FC, useEffect, useState } from 'react';
import {
    Box,
    Chip,
    createStyles,
    debounce,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    makeStyles,
    Theme,
    Checkbox,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@material-ui/icons';
import {
    ListSupplyCategoriesFilters,
    SupplyCategory,
} from 'clients/manager/interfaces/supply-categories.interface';
import { supplyCategoriesRequests } from 'clients/manager/supply-categories.requests';
import { supplyCategoriesProvidersRequests } from 'clients/manager/supply-categories-providers.requests';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import { TypeAccess } from 'common/interfaces/user.interface';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    })
);

interface ModalProviderAddSegmentProps {
    onClose: () => void;
    idProvider?: number;
    initialCheckedCategories: SupplyCategory[];
}

const limitChips = 5;

const ModalProviderAddSegment: FC<ModalProviderAddSegmentProps> = ({
    onClose,
    idProvider = 0,
    initialCheckedCategories,
}) => {
    const [supplyCategories, setSupplyCategories] = useState<SupplyCategory[]>([]);
    const [checkedCategories, setCheckedCategories] = useState<SupplyCategory[]>([]);
    const [textSearch, setTextSearch] = useState('');
    const [updateCount, setUpdateCount] = useState(0);
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const classes = useStyles();
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();

    const { t } = useTranslation();

    const getSupplyCategoryLabel = (supplyCategory: SupplyCategory) => {
        if (supplyCategory.externalId) {
            return `${supplyCategory.externalId}. ${supplyCategory.categoryName}`;
        }

        return supplyCategory.categoryName;
    };

    useEffect(() => {
        const request = async () => {
            try {
                const filters: ListSupplyCategoriesFilters = {
                    limit: textSearch ? 10 : 6,
                    params: {
                        level: 2,
                    },
                };

                if (textSearch) {
                    filters.params = { ...filters.params, q: textSearch };
                }

                const response = await supplyCategoriesRequests.listSupplyCategories(filters);
                setSupplyCategories(response?.data ?? []);
                // eslint-disable-next-line no-empty
            } catch (error) {}
        };

        request();
    }, [textSearch]);

    const handleToggle = (category: SupplyCategory) => () => {
        const index = checkedCategories.findIndex((item) => item.id === category.id);
        const newCheckedCategories = [...checkedCategories];

        if (index === -1) {
            newCheckedCategories.push(category);
        } else {
            newCheckedCategories.splice(index, 1);
        }

        setCheckedCategories(newCheckedCategories);
    };

    const debounceSearchText = debounce((text: string) => {
        setTextSearch(text);
    }, 500);

    const handleDelete = (category: SupplyCategory) => () => {
        setCheckedCategories((categories) => categories.filter((chip) => chip.id !== category.id));
    };

    const updateSupplyCategories = async () => {
        try {
            if (updateCount > 0) return;
            setUpdateCount(1);
            await supplyCategoriesProvidersRequests.createSupplyCategoriesProviders(
                idProvider,
                checkedCategories.map((category) => category.id)
            );

            addNotificationSuccess();
            if (authUserTokenData?.typeAccess !== TypeAccess.management) {
                setForceHasChanges(true);
            }
            setUpdateCount(1);
            onClose();
        } catch (err) {
            setUpdateCount(0);
            addNotificationApiError(err);
        }
    };

    const segmentAlreadyChecked = (segmentId: number) =>
        !!initialCheckedCategories.find((segment) => segment.id === segmentId);

    const maxLengthChipLabel = 60;

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={(event: any) => {
                event.stopPropagation();
                onClose();
            }}
            header={<span>{t('info.add-segments')}</span>}
        >
            <Box width='500px'>
                <Grid container>
                    {checkedCategories.length ? (
                        <Grid item xs={12}>
                            <Box className={classes.root} mb={2}>
                                {checkedCategories.slice(0, limitChips).map((data) => (
                                    <Chip
                                        label={
                                            getSupplyCategoryLabel(data).length > maxLengthChipLabel
                                                ? `${getSupplyCategoryLabel(data).slice(
                                                      0,
                                                      maxLengthChipLabel
                                                  )}...`
                                                : getSupplyCategoryLabel(data)
                                        }
                                        onDelete={handleDelete(data)}
                                    />
                                ))}
                                {checkedCategories.length > limitChips ? (
                                    <Chip label={`+ ${checkedCategories.length - limitChips}`} />
                                ) : null}
                            </Box>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            autoFocus
                            label={t('info.search-segments')}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                debounceSearchText(event.target.value?.toLowerCase())
                            }
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title={t('term.search') as string} aria-label='info'>
                                        <SearchOutlined />
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={1}>
                            {!textSearch ? (
                                <Typography variant='body2'>{t('term.suggestions')}:</Typography>
                            ) : null}
                            <List dense>
                                {!supplyCategories.length ? (
                                    <Typography variant='body2'>{t('term.not-found')}</Typography>
                                ) : null}
                                {supplyCategories.map((supplyCategory) => {
                                    const labelId = `checkbox-list-secondary-label-${supplyCategory.id}`;
                                    const disabledItem = segmentAlreadyChecked(supplyCategory.id);

                                    return (
                                        <ListItem key={supplyCategory.id} disabled={disabledItem}>
                                            <ListItemText
                                                id={labelId}
                                                primary={getSupplyCategoryLabel(supplyCategory)}
                                            />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    disabled={disabledItem}
                                                    edge='end'
                                                    onChange={handleToggle(supplyCategory)}
                                                    checked={
                                                        disabledItem ||
                                                        !!checkedCategories.find(
                                                            (category) =>
                                                                category.id === supplyCategory.id
                                                        )
                                                    }
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    </Grid>
                    {checkedCategories.length ? (
                        <Grid item xs={12}>
                            <Box mt={1} width={1} display='flex' justifyContent='flex-end'>
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={() => updateSupplyCategories()}
                                >
                                    {t('term.save')}
                                </Button>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalProviderAddSegment;
