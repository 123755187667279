import { FC, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useContractsContext } from '../../context/contracts.context';

interface AlertContractProps {
    processId: number;
}

const AlertContract: FC<AlertContractProps> = ({ processId }) => {
    const { steps, reFetchSteps, fetchedData } = useContractsContext();
    const { t } = useTranslation();

    useEffect(() => {
        reFetchSteps();
    }, [processId]);

    if (fetchedData && !steps.length) {
        return <Alert severity='error'>{t('info.create-contract-require-step')}</Alert>;
    }

    return null;
};

export default AlertContract;
