import { PlanTitleEnum } from 'clients/manager/plan.request';
import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import { CHECKOUT } from '../../CheckoutConstants';
import PostPaid from '../../components/Confirmation/PostPaid';
import ConclusionView from './ConclusionView';

export default function Conclusion({ auctionId }) {
    const { selectedSteps } = useCheckoutContext();

    const isCreditCardSelected = selectedSteps.payment.selected === CHECKOUT.CREDIT_CARD;
    const invoiceUrl = selectedSteps.confirmation.selected;

    const downloadInvoice = () => {
        window.open(invoiceUrl, '_blank');
    };

    const conclusionType = () => {
        if (selectedSteps.plan.selected !== PlanTitleEnum.payIfYouWin) {
            return <ConclusionView {...{ isCreditCardSelected, downloadInvoice }} />;
        }

        return <PostPaid auctionId={auctionId} />;
    };

    return conclusionType();
}
