import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from 'common/theme';

const useStyles = makeStyles((theme: Theme | any) => ({
    ratingInfo: {
        paddingTop: theme.spacing(0.5),
    },
    actions: { width: 100 },
    star: {
        color: '#FFB400',
    },
}));

export default useStyles;
