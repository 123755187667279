import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
} from '@material-ui/core';
import { Button } from 'common/components';
import { Edit } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AddIcon } from 'common/icons';
import { OrganizationUnit } from '../../../../../clients/manager/interfaces/organization.interface';
import { organizationUnitsRequests } from '../../../../../clients/manager/organization.requests';
import ModalEditOrganizationUnit from '../modal-edit-organization-unit';
import { OrganizationUnitsProps } from './props';

const OrganizationUnits: FC<OrganizationUnitsProps> = () => {
    const [organizationUnits, setOrganizationUnits] = useState<OrganizationUnit[]>([]);
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    const [organizationUnit, setOrganizationUnit] = useState<OrganizationUnit>();
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        const getListOrganizationUnit = async () => {
            try {
                const result = await organizationUnitsRequests.listOrganizationUnit();
                setOrganizationUnits(result);
                setFetching(false);
            } catch (err) {
                setFetching(false);
                throw err;
            }
        };
        getListOrganizationUnit();
        return () => {};
    }, []);

    const handleAddOrganizationUnit = () => {
        setModalVisible(true);
    };

    const handleEditOrganizationUnit = (organizationUnit: OrganizationUnit) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setOrganizationUnit(organizationUnit);
            setModalVisible(true);
        });
    };

    return (
        <>
            {modalVisible && (
                <ModalEditOrganizationUnit
                    organizationUnit={organizationUnit}
                    onClose={() => {
                        ReactDOM.unstable_batchedUpdates(() => {
                            setOrganizationUnit(undefined);
                            setModalVisible(false);
                        });
                    }}
                    onCreateOrganizationUnit={(organizationUnit: OrganizationUnit) => {
                        ReactDOM.unstable_batchedUpdates(() => {
                            setOrganizationUnits([...organizationUnits, organizationUnit]);
                            setModalVisible(false);
                        });
                    }}
                    onUpdateOrganizationUnit={(organizationUnit: OrganizationUnit) => {
                        ReactDOM.unstable_batchedUpdates(() => {
                            setOrganizationUnits(
                                organizationUnits.map((item: OrganizationUnit) => {
                                    if (item.id === organizationUnit.id) {
                                        return organizationUnit;
                                    }
                                    return item;
                                })
                            );
                            setModalVisible(false);
                        });
                    }}
                />
            )}
            <Grid container item md={12}>
                <TableContainer component={Paper} variant='outlined'>
                    <Table size='small' aria-label='a dense table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('term.name')}</TableCell>
                                <TableCell align='right'>{t('term.actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizationUnits.length ? (
                                organizationUnits.map((organizationUnit) => (
                                    <TableRow key={organizationUnit.id}>
                                        <TableCell component='th' scope='row'>
                                            {organizationUnit.organizationUnitName}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <IconButton
                                                aria-label='editar'
                                                size='small'
                                                onClick={() =>
                                                    handleEditOrganizationUnit(organizationUnit)
                                                }
                                            >
                                                <Edit fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <>
                                    {fetching &&
                                        new Array(5).fill(0).map((_, index) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <TableRow key={index}>
                                                <TableCell component='th' scope='row'>
                                                    <Skeleton width='100%' />
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Skeleton width='100%' />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container item md={12} justifyContent='flex-end'>
                    <Box mt={2}>
                        <Button
                            className='btn-add'
                            size='small'
                            variant='outlined'
                            color='primary'
                            startIcon={<AddIcon />}
                            onClick={() => handleAddOrganizationUnit()}
                        >
                            {t('term.add')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default OrganizationUnits;
