import { styled } from 'common/theme/styled';

const Scroll = styled.div`
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: #e8e8e8;
    }

    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.scrollbar};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.colors.scrollbar};
    }

    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.colors.scrollbar};
`;

export { Scroll };
