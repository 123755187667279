import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    MenuItem,
    RadioGroup,
    Typography,
    Radio,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TextField } from 'common/components';
import { useFormik } from 'formik';
import { CurrencyInput } from 'common/components/currency';
import moment from 'moment';
import {
    Contract,
    SignatureMethod,
    TermTypeIdEnum,
} from 'clients/manager/interfaces/contract.interface';
import Yup from 'common/utils/yup-extended.utils';
import LoadingButton from 'common/components/loading-button';
import { ProcessDocumentsStepsType } from 'clients/manager/interfaces/process-documents-steps.interface';
import { contractRequests } from 'clients/manager/contract.requests';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
} from 'common/utils';
import { uniqueId } from 'lodash';
import { ExpandMoreIcon } from 'common/icons';
import { contractType, processCategory, termType } from 'common/components/contracts/data';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';
import { contractActions, contractConcluded, contractUtils } from 'modules/process/document-utils';
import { TypeRegistrationEnum } from 'clients/manager/interfaces/auction-notice.interface';
import { EditContractFormProps } from './props';
import UploadContract from '../../../../../modules/process/components/process-contracts-data/components/upload-contract';
import { useStyles } from './styles';
import ButtonEditContract from './button-edit-contract-view';
import ContractActions from './contract-actions';
import ContractTerms from './contract-term';

const defaultNs = { ns: 'validation' };

export type TermSelected =
    | DocumentTemplateType.terminationNotice
    | DocumentTemplateType.addendum
    | DocumentTemplateType.apostilmentTerm;

const termSelectedTypes: Record<TermTypeIdEnum, TermSelected> = {
    [TermTypeIdEnum.termination_notice]: DocumentTemplateType.terminationNotice,
    [TermTypeIdEnum.addendum]: DocumentTemplateType.addendum,
    [TermTypeIdEnum.apostilment_term]: DocumentTemplateType.apostilmentTerm,
};

const EditContractForm: FC<EditContractFormProps> = ({
    contractWithDetails,
    onContractUpdated,
    onContractUploaded,
    onContractAdded,
    onDeletedGeneratedDocument,
    contractTerm,
    onClose,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { contract, generatedDocument, processDocumentsStep, documentSigners, process } =
        contractWithDetails;
    const {
        isContractSentPncp,
        isNotDraft,
        isNotConcluded,
        inProgress,
        isConcluded,
        isNotSentPncp,
    } = contractUtils;

    const [updatingContract, setUpdatingContract] = useState(false);
    const [uploadFileKey, setUploadFileKey] = useState(uniqueId());
    const [editingContract, setEditingContrat] = useState(false);
    const [termSelected, setTermSelected] = useState<TermSelected | undefined>();
    const isTerminationNotice = termSelected === DocumentTemplateType.terminationNotice;
    const isApostilmentTerm = termSelected === DocumentTemplateType.apostilmentTerm;
    const isAddendum = termSelected === DocumentTemplateType.addendum;
    const haveGeneratedDocument = !!generatedDocument?.file.location;
    const noGeneratedDocument = !generatedDocument?.file.location;

    useEffect(() => {
        const { termTypeId } = contract;
        setTermSelected(
            termTypeId in termSelectedTypes ? termSelectedTypes[termTypeId] : undefined
        );
    }, [contract.termTypeId]);

    const disabledFields =
        !!termSelected || (!editingContract && (isNotDraft(contract) || haveGeneratedDocument));

    const visibleStepCreateTerm = isContractSentPncp(contract);
    const visibleEditButton =
        isNotSentPncp(contract) && (inProgress(contract) || haveGeneratedDocument);

    // mostra o upload de documento quando o step é do tipo upload ou não tem um step criado
    const visibleContractUpload =
        isNotConcluded(contract) &&
        (processDocumentsStep?.type === ProcessDocumentsStepsType.upload ||
            process.typeRegistration === TypeRegistrationEnum.finish) &&
        noGeneratedDocument;

    const handleCreateContractTerm = async (contractForm: Contract) => {
        if (!termSelected) {
            return;
        }

        try {
            if (contractConcluded.includes(contract.status) && isTerminationNotice) {
                await contractRequests.createContractTerm({
                    ...contract,
                    termTypeId: TermTypeIdEnum.termination_notice,
                    contractNumber: contractForm.contractNumber,
                    endDate: contractForm.endDate,
                    params: {
                        contractId: contract.id,
                    },
                });
            } else if (contractConcluded.includes(contract.status) && isApostilmentTerm) {
                await contractRequests.createContractTerm({
                    ...contract,
                    termTypeId: TermTypeIdEnum.apostilment_term,
                    initialValue: contractForm.initialValue,
                    contractNumber: contractForm.contractNumber,
                    accumulatedValue: contractForm.accumulatedValue,
                    installments: Number(contractForm.installments),
                    params: {
                        contractId: contract.id,
                    },
                });
            } else if (contractConcluded.includes(contract.status) && isAddendum) {
                await contractRequests.createContractTerm({
                    ...contractForm,
                    termTypeId: TermTypeIdEnum.addendum,
                    installments: Number(contractForm.installments),
                    params: {
                        contractId: contract.id,
                    },
                });
            }
            onContractAdded();
            onClose();
            addNotificationSuccess({
                title: t('term.success'),
                message: 'Termo criado com sucesso.',
            });
        } catch (error) {
            addNotificationError({
                title: t('term.error'),
                message: t('info.action-error'),
            });
        }
    };
    const handleUpdateContract = async (contractForm: Contract) => {
        if (termSelected ? false : disabledFields) {
            return;
        }
        setUpdatingContract(true);

        try {
            const response = await contractRequests.updateContract({
                params: { contractId: contract.id },
                ...contractForm,
                installments: Number(contractForm.installments),
            });

            onContractUpdated({
                ...contractWithDetails,
                contract: {
                    ...contractForm,
                    ...(response?.data?.contract || contract),
                },
            });
            setEditingContrat(false);
            addNotificationSuccess({
                title: t('term.success'),
                message: t('info.action-success'),
            });
        } catch (error) {
            addNotificationApiError(error);
        }

        setUpdatingContract(false);
    };
    const getValidationSchema = () => {
        const baseSchema = {
            processCategoryId: Yup.string().required(t('required-field', defaultNs)),
            contractNumber: Yup.string().required(t('required-field', defaultNs)),
            objectContract: Yup.string().required(t('required-field', defaultNs)),
            startDate: Yup.string().required(t('required-field', defaultNs)),
            endDate: Yup.string().required(t('required-field', defaultNs)),
            initialValue: Yup.number().required(t('required-field', defaultNs)),
            accumulatedValue: Yup.number().required(t('required-field', defaultNs)),
            installments: Yup.number().required(t('required-field', defaultNs)),
            signtureMethod: Yup.string().required(t('required-field', defaultNs)),
        };

        if (termSelected) {
            return Yup.object({
                ...baseSchema,
                termTypeId: Yup.string().required(t('required-field', defaultNs)),
            });
        }
        return Yup.object({
            ...baseSchema,
            contractTypeId: Yup.string().required(t('required-field', defaultNs)),
        });
    };

    const form = useFormik({
        initialValues: { ...contract },
        enableReinitialize: true,
        validationSchema: getValidationSchema(),
        onSubmit: (values) => {
            if (visibleStepCreateTerm) {
                handleCreateContractTerm(values);
            } else {
                handleUpdateContract(values);
            }
        },
    });

    const handleSelectTerm = (term: TermSelected) => {
        if (!contractConcluded.includes(contract.status)) {
            addNotificationError({
                title: t('term.error'),
                message:
                    'Termo de contrato só pode ser selecionado se contrato já estiver sido enviado para PNCP (concluído ou obsoleto)',
            });
            return;
        }
        if (term === DocumentTemplateType.terminationNotice) {
            form.setFieldValue('termTypeId', TermTypeIdEnum.termination_notice);
            setTermSelected(DocumentTemplateType.terminationNotice);
            return;
        }
        if (term === DocumentTemplateType.addendum) {
            form.setFieldValue('termTypeId', TermTypeIdEnum.addendum);
            setTermSelected(DocumentTemplateType.addendum);
            return;
        }
        if (term === DocumentTemplateType.apostilmentTerm) {
            form.setFieldValue('termTypeId', TermTypeIdEnum.apostilment_term);
            setTermSelected(DocumentTemplateType.apostilmentTerm);
            return;
        }
        setTermSelected(undefined);
        addNotificationError({
            title: t('term.error'),
            message: 'Erro ao atualizar tipo do termo',
        });
    };

    const handleChangeDefault = (event: React.ChangeEvent<HTMLInputElement>) =>
        form?.setFieldValue(event.target.name, event.target.value);

    const handleGeneratedDocumentUploaded = (data: {
        location: string;
        createdAt: number;
        name: string;
    }) => {
        onContractUploaded(contract, {
            file: data,
        });
    };

    const getGeneratedDocumentToView = () => {
        if (documentSigners?.signDocLicitarUrl && generatedDocument?.file) {
            return {
                ...generatedDocument,
                file: {
                    ...generatedDocument?.file,
                    location: documentSigners?.signDocLicitarUrl,
                },
            };
        }

        return generatedDocument;
    };

    const onClickEditContract = (edit: boolean) => setEditingContrat(edit);
    const canEditSigntureMethod = isAddendum
        ? false
        : !contractActions.canEditSigntureMethod(contract) || disabledFields;

    const defaultFormProps = {
        disabled: disabledFields,
    };

    return (
        <Box>
            {visibleStepCreateTerm && (
                <Paper variant='outlined' elevation={2}>
                    <Accordion
                        classes={{ root: classes.root }}
                        elevation={0}
                        defaultExpanded
                        TransitionProps={{ timeout: 200, mountOnEnter: true }}
                    >
                        <AccordionSummary
                            classes={{
                                root: classes.header,
                            }}
                            {...{ expandIcon: <ExpandMoreIcon /> }}
                        >
                            <Box display='flex' alignItems='center'>
                                <Typography variant='body1'>
                                    <b>Criar termo a partir de contrato</b>
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ContractTerms
                                handleSelectTerm={handleSelectTerm}
                                contractTerm={contractTerm}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            )}
            <Paper style={{ marginTop: 16 }} variant='outlined' elevation={2}>
                <Accordion
                    classes={{ root: classes.root }}
                    elevation={0}
                    defaultExpanded
                    TransitionProps={{ timeout: 200, mountOnEnter: true }}
                >
                    <AccordionSummary
                        classes={{
                            root: classes.header,
                        }}
                        {...{ expandIcon: <ExpandMoreIcon /> }}
                    >
                        <Box display='flex' alignItems='center'>
                            <Typography variant='body1'>
                                <b>{t('term.general-data')}</b>
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box mt={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        select
                                        label={t('term.category')}
                                        fullWidth
                                        {...defaultFormProps}
                                        name='processCategoryId'
                                        value={form.values.processCategoryId}
                                        onChange={form.handleChange}
                                    >
                                        {Object.keys(processCategory).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {processCategory[key]}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {termSelected ? (
                                        <TextField
                                            select
                                            label={t('term.type')}
                                            fullWidth
                                            name='termTypeId'
                                            {...defaultFormProps}
                                            value={form.values.termTypeId}
                                            onChange={form.handleChange}
                                        >
                                            {Object.keys(termType).map((key) => (
                                                <MenuItem key={key} value={key}>
                                                    {termType[key]}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    ) : (
                                        <TextField
                                            select
                                            label={t('term.type')}
                                            fullWidth
                                            name='contractTypeId'
                                            {...defaultFormProps}
                                            value={form.values.contractTypeId}
                                            onChange={form.handleChange}
                                        >
                                            {Object.keys(contractType).map((key) => (
                                                <MenuItem key={key} value={key}>
                                                    {contractType[key]}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        name='contractNumber'
                                        color='primary'
                                        disabled={termSelected ? false : disabledFields}
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        defaultValue={form?.values.contractNumber}
                                        label={
                                            termSelected
                                                ? 'Número do termo'
                                                : t('info.contract-number')
                                        }
                                        onChange={handleChangeDefault}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name='objectContract'
                                        color='primary'
                                        disabled={isAddendum ? false : disabledFields}
                                        inputProps={{
                                            maxLength: 800,
                                        }}
                                        multiline
                                        minRows={4}
                                        defaultValue={form?.values.objectContract}
                                        label={t('term.description')}
                                        onChange={handleChangeDefault}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name='additionalInformation'
                                        color='primary'
                                        disabled={isAddendum ? false : disabledFields}
                                        inputProps={{
                                            maxLength: 800,
                                        }}
                                        multiline
                                        minRows={3}
                                        defaultValue={form?.values.additionalInformation}
                                        label={`${t('term.complementary-information')} (${t(
                                            'term.optional'
                                        )})`}
                                        onChange={handleChangeDefault}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        disabled={isAddendum ? false : disabledFields}
                                        autoOk
                                        variant='inline'
                                        inputVariant='outlined'
                                        label={t('term.initial-date')}
                                        name='startDate'
                                        format='DD/MM/yyyy'
                                        value={form.values.startDate}
                                        InputAdornmentProps={{ position: 'end' }}
                                        invalidDateMessage={t('info.invalid-format', {
                                            ns: 'validation',
                                        })}
                                        invalidLabel={t('info.invalid-date', defaultNs)}
                                        onChange={(date) => {
                                            form.setFieldValue(
                                                'startDate',
                                                moment(date).format('YYYY/MM/DD')
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        autoOk
                                        variant='inline'
                                        disabled={
                                            isTerminationNotice || isAddendum
                                                ? false
                                                : disabledFields
                                        }
                                        inputVariant='outlined'
                                        label={t('term.final-date')}
                                        name='endDate'
                                        format='DD/MM/yyyy'
                                        value={form.values.endDate}
                                        minDate={form.values.startDate}
                                        InputAdornmentProps={{ position: 'end' }}
                                        invalidDateMessage={t('info.invalid-format', {
                                            ns: 'validation',
                                        })}
                                        invalidLabel={t('info.invalid-date', defaultNs)}
                                        onChange={(date) => {
                                            form.setFieldValue(
                                                'endDate',
                                                moment(date).format('YYYY/MM/DD')
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        disabled={isAddendum ? false : disabledFields}
                                        name='deadline'
                                        color='primary'
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        defaultValue={form?.values.deadline}
                                        label={`${t('term.deadline')} (${t('term.optional')})`}
                                        onChange={handleChangeDefault}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        name='contractInspector'
                                        color='primary'
                                        disabled={isAddendum ? false : disabledFields}
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        defaultValue={form?.values.contractInspector}
                                        label={`${t('term.contract-inspector')} (${t(
                                            'term.optional'
                                        )})`}
                                        onChange={handleChangeDefault}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        color='primary'
                                        disabled={isAddendum ? false : disabledFields}
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                        defaultValue={form?.values.deliveryWaysAndAddress}
                                        name='deliveryWaysAndAddress'
                                        label={`${t('term.process-delivery-address')} (${t(
                                            'term.optional'
                                        )})`}
                                        onChange={handleChangeDefault}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CurrencyInput
                                        allowNegative={false}
                                        value={form.values.initialValue ?? 0}
                                        prefix='R$ '
                                        decimalSeparator=','
                                        disabled={
                                            isApostilmentTerm || isAddendum ? false : disabledFields
                                        }
                                        precision={2}
                                        thousandSeparator='.'
                                        label={t('term.initial-value')}
                                        variant='outlined'
                                        defaultValue={form.values.initialValue}
                                        color='primary'
                                        onChange={(_, value: number) =>
                                            form.setFieldValue('initialValue', value)
                                        }
                                        component={TextField}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        fullWidth
                                        color='primary'
                                        type='number'
                                        disabled={
                                            isApostilmentTerm || isAddendum ? false : disabledFields
                                        }
                                        defaultValue={form?.values.installments}
                                        name='installments'
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label={t('term.parcels')}
                                        onChange={handleChangeDefault}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CurrencyInput
                                        allowNegative={false}
                                        value={form.values.accumulatedValue ?? 0}
                                        prefix='R$ '
                                        decimalSeparator=','
                                        disabled={
                                            isApostilmentTerm || isAddendum ? false : disabledFields
                                        }
                                        precision={2}
                                        thousandSeparator='.'
                                        label={t('term.accumulated-value')}
                                        variant='outlined'
                                        defaultValue={form.values.accumulatedValue}
                                        color='primary'
                                        onChange={(_, value: number) =>
                                            form.setFieldValue('accumulatedValue', value)
                                        }
                                        component={TextField}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <RadioGroup
                                        row
                                        aria-label='position'
                                        name='signtureMethod'
                                        value={form?.values.signtureMethod as string}
                                        onChange={handleChangeDefault}
                                    >
                                        <FormControlLabel
                                            disabled={canEditSigntureMethod}
                                            control={
                                                <Radio
                                                    value={SignatureMethod.internal}
                                                    color='primary'
                                                />
                                            }
                                            label={t('info.electronically-sign')}
                                        />
                                        <FormControlLabel
                                            disabled={canEditSigntureMethod}
                                            control={
                                                <Radio
                                                    value={SignatureMethod.external}
                                                    color='primary'
                                                />
                                            }
                                            label={t('info.sign-externally')}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box
                                        width={1}
                                        height={1}
                                        display='flex'
                                        justifyContent='flex-end'
                                        alignItems='center'
                                    >
                                        {visibleEditButton && (
                                            <ButtonEditContract
                                                editingContract={editingContract}
                                                onClickEditContract={onClickEditContract}
                                            />
                                        )}
                                        <LoadingButton
                                            size='small'
                                            disabled={
                                                !!Object.keys(form.errors).length ||
                                                (termSelected ? false : disabledFields) ||
                                                !form.dirty
                                            }
                                            variant='contained'
                                            onClick={form.submitForm}
                                            {...(updatingContract
                                                ? {
                                                      loading: {
                                                          text:
                                                              !!termSelected &&
                                                              !contract.contractOriginId
                                                                  ? `${t('term.creating')}..`
                                                                  : `${t('term.updating')}..`,
                                                      },
                                                  }
                                                : {})}
                                        >
                                            {!!termSelected && !contract.contractOriginId
                                                ? 'Criar termo'
                                                : t('term.save')}
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            {!disabledFields && (
                                <Box width={1} mt={2}>
                                    <Alert severity='warning'>
                                        Só é permitido salvar o contrato se todos os campos
                                        estiverem preenchidos.
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Paper>
            {!!((!contract.contractOriginId && !termSelected) || contract.contractOriginId) && (
                <Paper style={{ marginTop: 16 }} variant='outlined' elevation={2}>
                    <Accordion
                        classes={{ root: classes.root }}
                        elevation={0}
                        defaultExpanded
                        TransitionProps={{ timeout: 200, mountOnEnter: true }}
                    >
                        <AccordionSummary
                            classes={{
                                root: classes.header,
                            }}
                            {...{ expandIcon: <ExpandMoreIcon /> }}
                        >
                            <Box display='flex' alignItems='center'>
                                <Typography variant='body1'>
                                    <b>{t('info.document-information')}</b>
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <UploadContract
                                        key={uploadFileKey}
                                        contractId={contract.id}
                                        generatedDocument={getGeneratedDocumentToView()}
                                        disabled={haveGeneratedDocument || isConcluded(contract)}
                                        viewMode={!visibleContractUpload}
                                        canDelete={isNotConcluded(contract)}
                                        onUploadedGeneratedDocument={
                                            handleGeneratedDocumentUploaded
                                        }
                                        onDeletedGeneratedDocument={onDeletedGeneratedDocument}
                                        showMessage={isNotConcluded(contract)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContractActions
                                        {...{
                                            contractWithDetails,
                                            onContractUpdated: (contract) => {
                                                onContractUpdated(contract);
                                                setUploadFileKey(uniqueId());
                                            },
                                            formIsValid: form.isValid,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            )}
        </Box>
    );
};

export default EditContractForm;
