import { createContext, useContext, useState } from 'react';

export const SearchProviderUsersContext = createContext<any>(null);

export const SearchProviderUsersContextProvider = ({ children }) => {
    const [providerUsersDialog, setProviderUsersDialog] = useState({ open: false });

    return (
        <SearchProviderUsersContext.Provider
            value={{
                providerUsersDialog,
                setProviderUsersDialog,
            }}
        >
            {children}
        </SearchProviderUsersContext.Provider>
    );
};

export const useSearchProviderUsersContext = () => useContext(SearchProviderUsersContext);
