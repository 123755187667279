import { Button, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { formatCountdown } from 'common/utils';
import { useStyles } from './summary-deadline-input-style';

interface SummaryDeadlineInputViewProps {
    deadlineCountdownIsEnabled?: boolean;
    deadlineCountdown?: number;
    deadline: string;
    inputIsEnabled?: boolean;
    onDeadlineChange: (value: string) => any;
    handleActiveInput: () => void;
}

const SummaryDeadlineInputView: React.FC<SummaryDeadlineInputViewProps> = ({
    deadlineCountdownIsEnabled,
    deadlineCountdown,
    onDeadlineChange,
    handleActiveInput,
    deadline,
    inputIsEnabled = false,
}) => {
    const { t } = useTranslation();

    const styles = useStyles();

    if (deadlineCountdownIsEnabled) {
        return (
            <Button
                variant='outlined'
                size='medium'
                disabled
                className={styles.deadlineButton}
                startIcon={<FaRegClock size={15} />}
            >
                <Typography variant='button' className={styles.deadlineText}>
                    Prazo restante: {formatCountdown(deadlineCountdown ?? 0)}
                </Typography>
            </Button>
        );
    }

    if (inputIsEnabled) {
        return (
            <TextField
                fullWidth
                variant='outlined'
                label={t('term.marketplace-deadline')}
                type='datetime-local'
                value={deadline}
                onChange={(event) => onDeadlineChange(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        );
    }

    return (
        <Button
            variant='outlined'
            size='large'
            startIcon={<FaRegCalendarAlt size={12} />}
            onClick={handleActiveInput}
        >
            <Typography variant='button'>{t('term.marketplace-add-deadline')}</Typography>
        </Button>
    );
};

export default SummaryDeadlineInputView;
