enum SenderType {
    system = 1,
    provider = 2,
    auction = 3,
}

interface Chat {
    id: number;
    message: string | null;
    senderType: number;
    senderId: number;
    providerId?: number;
    createdAt: Date;
    auctionNoticeId: number;
    providerAuctionCode: number;
}

export { SenderType };
export type { Chat };
