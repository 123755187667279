import { createContext, ReactNode, useContext, useState } from 'react';
import { FilterType, MarketplaceCartResponse } from '../interfaces/marketplace.interface';
import { useMarketplaceCart } from '../hooks/use-marketplace-cart.hook';

interface MarketplaceContextType {
    filters: FilterType;
    setFilters: React.Dispatch<React.SetStateAction<FilterType>>;
    marketplaceCart?: MarketplaceCartResponse;
    actionsLoading: boolean;
    marketplaceCartLoading: boolean;
    onAddToCart: (id: number) => Promise<void>;
    clearCart: () => void;
    onRemoveFromCart: (id: number) => Promise<void>;
    onItemQuantityChange: (params: { id: number; quantity: number }) => Promise<void>;
}

export const MarketplaceContext = createContext<MarketplaceContextType>({
    filters: {
        categoriesIds: [],
        processesIds: [],
        searchTerm: '',
    },
    setFilters: () => {},
    marketplaceCart: undefined,
    actionsLoading: false,
    marketplaceCartLoading: false,
    onAddToCart: async () => undefined,
    clearCart: () => undefined,
    onRemoveFromCart: async () => undefined,
    onItemQuantityChange: async () => undefined,
});

export const useMarketplaceContext = () => useContext(MarketplaceContext);

export const MarketplaceProvider = ({ children }: { children: ReactNode }) => {
    const [filters, setFilters] = useState<FilterType>({
        categoriesIds: [],
        processesIds: [],
        searchTerm: '',
    });

    const {
        marketplaceCartResponse,
        marketplaceCartLoading,
        actionsLoading,
        onItemQuantityChange,
        onAddToCart,
        onRemoveFromCart,
        clearCart,
    } = useMarketplaceCart();

    return (
        <MarketplaceContext.Provider
            value={{
                filters,
                setFilters,
                marketplaceCart: marketplaceCartResponse?.data,
                marketplaceCartLoading,
                actionsLoading,
                onItemQuantityChange,
                onAddToCart,
                onRemoveFromCart,
                clearCart,
            }}
        >
            {children}
        </MarketplaceContext.Provider>
    );
};
