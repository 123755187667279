import { useFormik } from 'formik';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import { MenuItem, Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from 'common/components';
import { ListAuditParams } from 'clients/manager/interfaces/audit.interface';
import { FilterFormProps } from './props';
import { getProjectOptions } from '../../project-options';

const FilterForm = (props: FilterFormProps) => {
    const { formData, onChange } = props;
    const formik = useFormik<Partial<ListAuditParams>>({
        initialValues: {
            ...formData,
        },
        onSubmit: onChange,
    });

    const { t } = useTranslation();

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={t('audit.filter.start-date')}
                        type='datetime-local'
                        fullWidth
                        value={moment(formik.values.startDate).format('YYYY-MM-DDTHH:mm')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(value) => {
                            formik.setFieldValue('startDate', moment(value.target.value).valueOf());
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={t('audit.filter.end-date')}
                        fullWidth
                        type='datetime-local'
                        value={moment(formik.values.endDate).format('YYYY-MM-DDTHH:mm')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(ev) => {
                            formik.setFieldValue('endDate', moment(ev.target.value).valueOf());
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        id='select'
                        color='primary'
                        label={t('term.project')}
                        value={formik.values.project}
                        onChange={(event) => {
                            formik.setFieldValue('project', event.target.value);
                        }}
                        variant='outlined'
                        select
                    >
                        {getProjectOptions().map((project) => (
                            <MenuItem key={project.value} value={project.value}>
                                {project.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={t('term.action')}
                        fullWidth
                        name='action'
                        value={formik.values.action}
                        onChange={(ev) => formik.setFieldValue('action', ev.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label={t('audit.filter.user-code')}
                        fullWidth
                        name='user'
                        value={formik.values.userId}
                        onChange={(ev) => formik.setFieldValue('userId', ev.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    component={Box}
                    display='flex'
                    style={{ justifyContent: 'flex-end', alignItems: 'center' }}
                >
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => formik.submitForm()}
                    >
                        <SearchIcon style={{ cursor: 'pointer' }} /> {t('term.filter')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FilterForm;
