import { Grid, Breadcrumbs, Card, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SearchHolidaysDialog from './components/SearchHolidaysDialog';
import SearchHolidaysTable from './components/SearchHolidaysTable';

const SearchHolidaysView = ({ handleNew, holidayDialog }) => {
    const { t } = useTranslation();

    return (
        <>
            <PageWrapper>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Breadcrumbs>
                        <Typography>{t('term.holidays')}</Typography>
                    </Breadcrumbs>

                    <Button size='small' variant='contained' color='primary' onClick={handleNew}>
                        {t('term.add')}
                    </Button>
                </Grid>

                <br />
                <Card>
                    <SearchHolidaysTable />
                </Card>
            </PageWrapper>

            {holidayDialog.open && <SearchHolidaysDialog />}
        </>
    );
};
export default SearchHolidaysView;
