import { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Colors, Select, Switch } from '@licitar/ui-kit';
import { useTranslation } from 'react-i18next';
import { StartExtractionDto } from 'clients/price-base/extractor.requests';
import DataComprasNet from './dataComprasNet';
import { InputWrapper, BtnContainer } from './styled';

interface Props {
    onExtract: (values: StartExtractionDto) => any;
}

const options = [
    {
        label: 'ComprasNet',
        value: 'compras_net',
    },
    {
        id: 2,
        label: 'Licitar digital',
        value: 'licitar_digital',
    },
];

const ExtractorForm: FC<Props> = ({ onExtract }) => {
    const formik = useFormik<StartExtractionDto>({
        initialValues: {
            extractor: 'compras_net',
            data: {},
            force: false,
        },
        validationSchema: Yup.object({
            extractor: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            onExtract(values);
        },
    });

    const { t } = useTranslation();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Select
                label={t('term.extractor')}
                onChange={(option: any) => {
                    formik.setFieldValue('extractor', option.value);
                }}
                options={options}
                value={options.find((op) => op.value === formik.values.extractor)}
            />
            <InputWrapper>
                <Switch
                    label={t('term.to-force')}
                    name='forcee'
                    onChange={() => {
                        formik.setFieldValue('force', !formik.values.force);
                    }}
                    checked={formik.values.force}
                />
            </InputWrapper>
            {(formik.values.extractor === 'compras_net' ||
                formik.values.extractor === 'licitar_digital') && (
                <>
                    <p>{t('term.data')} ComprasNet</p>
                    <DataComprasNet values={formik.values} setFieldValue={formik.setFieldValue} />
                </>
            )}
            {formik.values.extractor === 'compras_net2' && 'AAAA'}
            <BtnContainer>
                <Button backgroundColor={Colors.DANGER}> {t('term.cancel')} </Button>
                <Button> {t('term.extract')} </Button>
            </BtnContainer>
        </form>
    );
};

export default ExtractorForm;
