import { MethodDispute } from 'clients/manager/interfaces/auction-notice.interface';
import i18n from 'common/i18n';

const getMethodDispute = (method?: MethodDispute): string => {
    const mapper = {
        [MethodDispute.aberto]: i18n.t('term.open'),
        [MethodDispute.abertoFechado]: `${i18n.t('term.open')} ${i18n.t('term.closed')}`,
        [MethodDispute.fechadoAberto]: `${i18n.t('term.closed')} ${i18n.t('term.open')}`,
        [MethodDispute.fechado]: i18n.t('term.closed'),
        [MethodDispute.dispenseWithSession]: i18n.t('term.dispense-with-session'),
        [MethodDispute.notApplicable]: i18n.t('term.not-applicable'),
    };

    return method ? mapper?.[method] : '';
};

export { getMethodDispute };
