import React from 'react';
import {
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FaUserCheck } from 'react-icons/fa';
import { Skeleton } from '@material-ui/lab';
import { AiFillEyeInvisible, AiOutlineFileSearch } from 'react-icons/ai';
import {
    GetProcessEnabledProvidersData,
    GetProcessEnabledProvidersResponse,
} from 'clients/manager/interfaces/auction-notice-participate.interface';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { formatCpfOrCnpj } from 'common/utils';
import {
    CleanContainer,
    LotTableBodyCell,
    LotTableHeaderCell,
    LotsLoadingContainer,
    ProcessDescriptionCard,
} from '../../styled';
import { StyledLotInfoAnonymizedComponent } from '../LotsContainer/LotDialog/styled';
import ChipGlobalEnableStatus from './components/chip-global-enable-status';
import LotDialog from '../LotsContainer/LotDialog';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 30, 40];

const AnonymizedComponent = () => (
    <StyledLotInfoAnonymizedComponent>
        <Skeleton width={100} height={30} />
        <AiFillEyeInvisible color='var(--text-light-color)' />
    </StyledLotInfoAnonymizedComponent>
);

interface EnabledProvidersContainerViewProps {
    processId: number;
    loading: boolean;
    data: GetProcessEnabledProvidersData[];
    paginationParams: Omit<
        UsePaginatedQueryResponse<GetProcessEnabledProvidersResponse>,
        'data' | 'loading'
    >;
    providerDialogData: {
        open: boolean;
        processId: number;
        providerId: number;
    };
    handleShowProviderInformations(id: number, providerId: number): void;
    handleHideProviderInformations(): void;
}

const EnabledProvidersContainerView: React.FC<EnabledProvidersContainerViewProps> = ({
    loading,
    data,
    paginationParams,
    processId,
    providerDialogData,
    handleShowProviderInformations,
    handleHideProviderInformations,
}) => {
    const { t } = useTranslation();

    const { totalCount, page, limit, setLimit, setPage } = paginationParams;

    return (
        <>
            <ProcessDescriptionCard elevation={0} variant='outlined'>
                <header>
                    <FaUserCheck size='1.5rem' />
                    <h1>{t('bidding.process.section.proponents')}</h1>
                </header>
                <section>
                    {!loading && data.length === 0 && (
                        <CleanContainer>
                            <h3>{t('bidding.process.section.proponents.blank')}</h3>
                        </CleanContainer>
                    )}
                    {(loading || data.length > 0) && (
                        <Paper elevation={0} variant='outlined' style={{ position: 'relative' }}>
                            {loading && (
                                <LotsLoadingContainer>
                                    <CircularProgress />
                                </LotsLoadingContainer>
                            )}
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <LotTableHeaderCell>
                                                {t('bidding.process.proponent.table.name')}
                                            </LotTableHeaderCell>
                                            <LotTableHeaderCell>
                                                {t('bidding.process.proponent.table.status')}
                                            </LotTableHeaderCell>
                                            <LotTableHeaderCell>
                                                {t('bidding.process.proponent.table.document')}
                                            </LotTableHeaderCell>
                                            <LotTableHeaderCell width={198} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading && (
                                            <TableRow>
                                                <LotTableBodyCell>
                                                    <Skeleton width='100%' variant='text' />
                                                </LotTableBodyCell>
                                                <LotTableBodyCell>
                                                    <Skeleton width='100%' variant='text' />
                                                </LotTableBodyCell>
                                                <LotTableBodyCell>
                                                    <div
                                                        style={{
                                                            marginLeft: 'auto',
                                                            width: 'fit-content',
                                                        }}
                                                    >
                                                        <IconButton disabled color='primary'>
                                                            <AiOutlineFileSearch />
                                                        </IconButton>
                                                    </div>
                                                </LotTableBodyCell>
                                            </TableRow>
                                        )}
                                        {!loading &&
                                            data.map((item) => (
                                                <TableRow key={item.id}>
                                                    <LotTableBodyCell>
                                                        {item.provider.companyName}
                                                    </LotTableBodyCell>
                                                    <LotTableBodyCell>
                                                        <ChipGlobalEnableStatus
                                                            status={item.globalEnabledStatus}
                                                        />
                                                    </LotTableBodyCell>
                                                    <LotTableBodyCell>
                                                        {item?.provider?.docType === 'F' ? (
                                                            <AnonymizedComponent />
                                                        ) : (
                                                            <Typography>
                                                                {formatCpfOrCnpj(
                                                                    item?.provider?.docNumber
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </LotTableBodyCell>
                                                    <LotTableBodyCell>
                                                        <div
                                                            style={{
                                                                marginLeft: 'auto',
                                                                width: 'fit-content',
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleShowProviderInformations(
                                                                        processId,
                                                                        item.providerId
                                                                    )
                                                                }
                                                                color='primary'
                                                            >
                                                                <AiOutlineFileSearch />
                                                            </IconButton>
                                                        </div>
                                                    </LotTableBodyCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                page={page}
                                                count={totalCount}
                                                onPageChange={(_e, page) => setPage(page)}
                                                rowsPerPage={limit || ROWS_PER_PAGE_OPTIONS[0]}
                                                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                                onRowsPerPageChange={(event) =>
                                                    setLimit(
                                                        Number(event.target.value) ??
                                                            ROWS_PER_PAGE_OPTIONS[0]
                                                    )
                                                }
                                                labelRowsPerPage={t('text.lines-per-page')}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}
                </section>
            </ProcessDescriptionCard>
            <LotDialog onClose={handleHideProviderInformations} {...providerDialogData} />
        </>
    );
};

export default EnabledProvidersContainerView;
