import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from '../interfaces/ok.interface';
import {
    GenericResponse,
    NoDataGenericResponse,
    PlainGenericResponse,
} from '../interfaces/paginated.interface';
import {
    AuctionNoticeLotPartialItems,
    AuctionNoticeLotWithItems,
    BulkLotsUpdate,
    CreateProcessLot,
    CreateProcessLotQuota,
    UpdateLotQuotaResponse,
    DeleteProcessLot,
    UpdateProcessLotQuota,
    ListLotsFromProcessFilters,
    UpdateProcessLot,
} from './interfaces/auction-notice-lot.interface';

const auctionNoticeLotRequests = {
    listLotsFromProcess: async (filters: ListLotsFromProcessFilters) =>
        doRequest<GenericResponse<AuctionNoticeLotWithItems>>(
            managerApiInstance.post(`/auction-notice-lot/listLotsFromProcess`, filters)
        ),
    deleteLot: async (data: DeleteProcessLot) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/auction-notice-lot/deleteProcessLot`, data)
        ),
    updateLotQuota: async (data: UpdateProcessLotQuota) =>
        doRequest<PlainGenericResponse<UpdateLotQuotaResponse>>(
            managerApiInstance.put(`/auction-notice-lot/lotQuota/${data.auctionLotId}`, data)
        ),
    bulkLotsUpdate: async (data: BulkLotsUpdate) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/auction-notice-lot/bulkLotsUpdate`, data)
        ),
    createProcessLot: async (data: CreateProcessLot) =>
        doRequest<GenericResponse<AuctionNoticeLotWithItems>>(
            managerApiInstance.post(`/auction-notice-lot/createProcessLot`, data)
        ),
    createProcessLotQuota: async (data: CreateProcessLotQuota) =>
        doRequest<PlainGenericResponse<Omit<AuctionNoticeLotWithItems, 'items'>>>(
            managerApiInstance.post(`/auction-notice-lot/createProcessLotQuota`, data)
        ),
    updateProcessLot: async (data: UpdateProcessLot) =>
        doRequest<NoDataGenericResponse>(
            managerApiInstance.post(`/auction-notice-lot/updateProcessLots`, data)
        ),
    listLotsbyAuctionId: async (filters: ListLotsFromProcessFilters) =>
        doRequest<GenericResponse<AuctionNoticeLotPartialItems>>(
            managerApiInstance.post(`/auction-notice-lot/listLotsbyAuctionId`, filters)
        ),
};

export { auctionNoticeLotRequests };
export type { ListLotsFromProcessFilters, DeleteProcessLot, BulkLotsUpdate };
