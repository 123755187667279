import { Grid } from '@material-ui/core';
import { Button } from 'common/components';
import AdditionalDocuments from 'common/components/provider-documents/components/additional-documents';
import BasicDocuments from 'common/components/provider-documents/components/basic-documents';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';

const Documents = () => {
    const { handleNext } = useContext(SignUpProviderContext);
    const { t } = useTranslation();

    const handleFinish = async () => handleNext();

    return (
        <Grid container>
            <Grid item xs={12}>
                <BasicDocuments />
                <AdditionalDocuments />
            </Grid>
            <Grid item xs={12}>
                <div className='stepper-nav'>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                        onClick={handleFinish}
                    >
                        {t('term.finish')}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default Documents;
