import { Grid } from '@material-ui/core';
import { memo } from 'react';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import ContractsList from './pages/contracts-list';

const ProcessContractsData = () => {
    const { process } = useProcessFormContext();

    return (
        <Grid container>
            <Grid item xs={12}>
                {process?.id ? <ContractsList processId={process.id} /> : <></>}
            </Grid>
        </Grid>
    );
};

export default memo(ProcessContractsData);
