import {
    Category,
    ListMarketplaceProcessResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import FilterItemView from './filter-item-view';

interface FilterItemProps {
    loading: boolean;
    items: Category[] | ListMarketplaceProcessResponse[];
    isFilterSelected: (type: 'process' | 'category', id: number) => boolean;
    onSelectFilter: (type: 'process' | 'category', id: number) => void;
    type: 'process' | 'category';
}

const FilterItem: React.FC<FilterItemProps> = ({
    loading,
    items,
    isFilterSelected,
    onSelectFilter,
    type,
}) => {
    const handleOnClick = (item) => {
        if (type === 'process') {
            return onSelectFilter(type, item.processId);
        }
        return onSelectFilter(type, item.id);
    };

    const getDescription = (item) => {
        if (type === 'process') {
            return `${item.processNumber} - ${item.simpleDescription}`;
        }
        return item.description;
    };

    return (
        <FilterItemView
            type={type}
            loading={loading}
            items={items}
            isFilterSelected={isFilterSelected}
            onClick={handleOnClick}
            getDescription={getDescription}
        />
    );
};

export default FilterItem;
