import { templateRequests } from 'clients/manager/documents/templates.requests';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { Skeleton, Table } from '@licitar/ui-kit';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'common/components/base/breadcrumb';
import PageErrorFetchData from 'common/components/page-error-fetch-data';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { addNotificationWarning, getTemplateTypesLabel } from 'common/utils';
import ModalCreateTemplate from '../../components/modal-create-template';
import SearchTemplates from '../../components/search-templates';
import { Header, ListArea, ActionsArea, InfoArea, ViewIcon, CopyIcon } from './styles';
import SearchTemplateTable from '../../components/search-template-table/search-template-table';

const TemplateList = () => <SearchTemplateTable />;

export default TemplateList;
