import { ChipProps } from '@material-ui/core';
import { getOrderItemStatusLabel } from 'modules/marketplace/utils/get-order-status-label.utils';
import { OrderItemStatusEnum } from 'common/enums';
import { useStyles } from './order-item-status-chip=style';
import OrderItemStatusChipView from './order-item-status-chip-view';

interface OrderItemStatusChipProps extends ChipProps {
    status: string;
}

const OrderItemStatusChip: React.FC<OrderItemStatusChipProps> = ({ status }) => {
    const styles = useStyles();

    const mapper = {
        [OrderItemStatusEnum.PENDING]: styles.chipStatusPending,
        [OrderItemStatusEnum.STARTED]: styles.chipStatusStarted,
        [OrderItemStatusEnum.FINISHED]: styles.chipStatusFinished,
        [OrderItemStatusEnum.FRUSTRATED]: styles.chipStatusFrustrated,
    };

    const defaultProps: ChipProps = {
        label: getOrderItemStatusLabel(status),
        className: mapper[status] ?? styles.chipStatusFinished,
    };

    return <OrderItemStatusChipView {...defaultProps} />;
};

export default OrderItemStatusChip;
