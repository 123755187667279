import React from 'react';

import DiscountLabelView from './discount-label-view';

interface DiscountLabelProps {
    value: number;
    initialValue: number;
    size?: 'lg' | 'md' | 'sm';
}

const DiscountLabel: React.FC<DiscountLabelProps> = ({ value, initialValue, size = 'md' }) => {
    const porcentage = (value * 100) / initialValue;
    const discount = Math.round(100 - porcentage);

    return <DiscountLabelView isPositive={discount > 0} value={Math.abs(discount)} size={size} />;
};

export default DiscountLabel;
