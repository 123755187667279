import { ItemPriceType } from 'common/interfaces/price.interface';

const getPriceOriginType = (priceType: ItemPriceType): string => {
    switch (priceType) {
        case ItemPriceType.BEST_BID:
            return 'Melhor lance';

        case ItemPriceType.PROPOSAL:
            return 'Proposta';

        default:
            return '';
    }
};

export { getPriceOriginType };
