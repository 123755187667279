import { Box, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';

const Image = styled.img`
    height: 180px;
`;

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='60vh'>
                <Grid container direction='column' alignItems='center' justify='center'>
                    <Grid item xs={12}>
                        <Image
                            src='/assets/images/svgs/undraw_not_found.svg'
                            alt={t('info.not-found')}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Typography variant='h5' gutterBottom>
                            {t('info.not-found')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>{t('info.not-found-message')}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </PageWrapper>
    );
};

export default NotFound;
