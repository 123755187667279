import { createContext, useContext, useState } from 'react';

export const SearchDocumentTypesContext = createContext<any>(null);

export const SearchDocumentTypesContextProvider = ({ children }) => {
    const [documentTypeDialog, setDocumentTypeDialog] = useState({ open: false });

    return (
        <SearchDocumentTypesContext.Provider
            value={{
                documentTypeDialog,
                setDocumentTypeDialog,
            }}
        >
            {children}
        </SearchDocumentTypesContext.Provider>
    );
};

export const useSearchDocumentTypesContext = () => useContext(SearchDocumentTypesContext);
