import { StatusPayment } from 'common/enums';
import i18n from 'common/i18n';

const getStatusPayment = (type: StatusPayment): string => {
    const obj = {
        [StatusPayment.canceled]: i18n.t('payment.canceled'),
        [StatusPayment.free]: i18n.t('payment.free'),
        [StatusPayment.paid]: i18n.t('payment.paid'),
        [StatusPayment.pending]: i18n.t('payment.pending'),
    };

    return obj?.[type] ?? type;
};

export { getStatusPayment };
