import { addNotificationSuccess } from 'common/utils';
import { OrganizationsUsersRequests } from 'modules/organization-users/services/organizations-users.requests';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { ListUsersBelongToOrganizationParams } from 'modules/organization-users/interfaces/organization-users.interface';
import { useHistory } from 'react-router';
import SearchOrganizationUsersTableView from './search-organization-users-table-view';

const SearchOrganizationUsersTable = () => {
    const [invoicesParams, setInvoicesParams] = useState<ListUsersBelongToOrganizationParams>();

    const { t } = useTranslation();
    const history = useHistory();

    const queryParams = usePaginatedQuery(
        OrganizationsUsersRequests.listUsersBelongToOrganization,
        invoicesParams
    );

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            setInvoicesParams({ q: event.target.value });
        }
    };

    const handleEdit = ({ cpf, country }) =>
        history.push(`/usuarios-organizacao/${country}/${cpf}`);

    const handleBlock = async (row) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(`${t('organization-users.pages.components.block-user')} ${row.name}?`)) {
            await OrganizationsUsersRequests.doUnlinkUserAndOrganization(row.userId);
            setInvoicesParams({ q: '' });
            addNotificationSuccess();
        }
    };

    return (
        <SearchOrganizationUsersTableView
            {...{ queryParams, handleChangeFilter, handleEdit, handleBlock }}
        />
    );
};

export default SearchOrganizationUsersTable;
