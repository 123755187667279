import { Button, Divider, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'common/utils';
import MarketplaceItemCard from 'modules/marketplace/components/marketplace-item-card/marketplace-item-card';
import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { OrderStatusEnum } from 'common/enums';
import OrderStatusChip from './components/order-status-chip';
import OrderDeadlineCountdown from './components/order-deadline-countdown';
import MoreOrderButton from './components/more-order-button';
import { useStyles } from './order-card-style';

interface OrderCartViewProps {
    order: OrderResponse;
    redirectToJudgmentPage: () => void;
    totalEstimatedValue: number;
    totalAverageValue: number;
    totalDiscount: number;
    totalValue: number;
    confirmButtonLabel?: string;
    isConfirmButtonDisabled?: boolean;
    actionButtonLabel?;
}

const OrderCartView: React.FC<OrderCartViewProps> = ({
    order,
    redirectToJudgmentPage,
    totalEstimatedValue,
    totalAverageValue,
    totalDiscount,
    totalValue,
    confirmButtonLabel,
    isConfirmButtonDisabled = false,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Paper variant='outlined' key={order.id} className={styles.cardContainer}>
            <header>
                <section className={styles.headerTitleContainer}>
                    <Typography variant='h6'>
                        {t('order.term.order-id', { value: order.id })}
                    </Typography>
                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={isConfirmButtonDisabled}
                            onClick={redirectToJudgmentPage}
                        >
                            <Typography variant='button' color='textSecondary'>
                                {confirmButtonLabel}
                            </Typography>
                        </Button>
                    </div>
                </section>
                <section className={styles.headerSubtitleContainer}>
                    <OrderStatusChip status={order.status} />
                    <OrderDeadlineCountdown deadline={order.deadlineToSendProposal} />
                </section>
            </header>
            <Divider className={styles.divider} />
            {order.items.map((orderItem) => (
                <MarketplaceItemCard
                    key={orderItem.id}
                    item={orderItem.item}
                    orderItemStatus={orderItem.status}
                    quantity={orderItem.itemAmount}
                    type='order'
                    isJudged={order.status !== OrderStatusEnum.OPENED}
                />
            ))}
            <MoreOrderButton active={order.items.length > 1} onClick={redirectToJudgmentPage} />
            <footer className={styles.footerContainer}>
                <section className={styles.footerPriceListContainer}>
                    <div className={styles.footerPriceItem}>
                        <Typography variant='body1'>
                            {t('term.marketplace-estimated-price-total')}
                        </Typography>
                        <Typography variant='body1' className={styles.footerPriceItemValue}>
                            {formatCurrency(totalEstimatedValue)}
                        </Typography>
                    </div>
                    <div className={styles.footerPriceItem}>
                        <Typography variant='body1'>
                            {order.status !== OrderStatusEnum.OPENED
                                ? t('term.marketplace-best-price')
                                : t('term.marketplace-average-price')}
                        </Typography>
                        <Typography variant='body1' className={styles.footerPriceItemValue}>
                            {totalAverageValue > 0 ? formatCurrency(totalAverageValue) : '--'}
                        </Typography>
                    </div>
                    <div className={styles.footerPriceItem}>
                        <Typography variant='body1'>
                            {totalDiscount > 0
                                ? t('term.marketplace-estimated-overprice')
                                : t('term.marketplace-estimated-discount')}
                        </Typography>
                        <Typography variant='body1' className={styles.footerPriceItemValue}>
                            {totalAverageValue > 0 && Math.abs(totalDiscount) > 0
                                ? formatCurrency(Math.abs(totalDiscount))
                                : '--'}
                        </Typography>
                    </div>
                </section>
                <section className={styles.footerPriceItem}>
                    <Typography variant='h6' className={styles.footerPriceItemValue}>
                        {t('term.total')}
                    </Typography>
                    <Typography variant='h6' className={styles.footerPriceItemValue}>
                        {formatCurrency(totalValue)}
                    </Typography>
                </section>
            </footer>
        </Paper>
    );
};

export default OrderCartView;
