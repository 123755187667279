import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BiDetail } from 'react-icons/bi';
import { FiCheckCircle, FiEdit3 } from 'react-icons/fi';
import { HiringPlanResponse } from 'clients/manager/interfaces/hiring-plans.interfaces';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { formatCurrency, formatDate } from 'common/utils';
import {
    PcaItemContainer,
    PcaItemContent,
    PcaItemData,
    PcaItemButtonDetails,
    DataLeftItens,
    DataRightItens,
    StatusBox,
    AmountBox,
    DataMiddleItens,
} from './pca-item-style';

interface PcaItemViewProps extends HiringPlanResponse {}

const PcaItemView: React.FC<PcaItemViewProps> = (props) => {
    const {
        id,
        year,
        status,
        createdAt,
        organizationId,
        totalValue,
        organizationUnitId,
        organizationUnits,
    } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const { pcaStatusEnum } = useHiringPlansContext();

    return (
        <PcaItemContainer>
            <PcaItemContent>
                <PcaItemData>
                    <DataLeftItens>
                        <span>
                            <h1>
                                PCA {year} - {organizationUnits.organizationUnitName.toUpperCase()}
                            </h1>
                        </span>
                        <p>
                            {t('hiring-plans.labels.orgaos-id')} {organizationId} |{' '}
                            {t('hiring-plans.labels.last-created-at')}{' '}
                            {formatDate(createdAt, 'L LT')}
                        </p>
                    </DataLeftItens>
                    <DataMiddleItens>
                        <StatusBox>
                            {status === pcaStatusEnum.draft && (
                                <>
                                    <FiEdit3 size={28} style={{ color: 'var(--tertiary-color)' }} />
                                    <h1>{t('term.draft')}</h1>
                                </>
                            )}
                            {status === pcaStatusEnum.done && (
                                <>
                                    <FiCheckCircle
                                        size={28}
                                        style={{ color: 'var(--secondary-color)' }}
                                    />
                                    <h1>{t('hiring-plans.title.concluded-title')}</h1>
                                </>
                            )}
                        </StatusBox>
                    </DataMiddleItens>
                    <DataRightItens>
                        <AmountBox>
                            <h2>{t('hiring-plans.title.value-estimated-title')}</h2>
                            <h1>{formatCurrency(totalValue)}</h1>
                        </AmountBox>
                    </DataRightItens>
                </PcaItemData>
                <PcaItemButtonDetails
                    title={t('hiring-plans.title.view-details-pca-title')}
                    onClick={() => history.push(`planos-contratacao/${id}/${organizationUnitId}`)}
                >
                    <BiDetail />
                </PcaItemButtonDetails>
            </PcaItemContent>
        </PcaItemContainer>
    );
};

export default PcaItemView;
