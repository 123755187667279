import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    iconValid: {
        color: '#02AE51',
    },
    icon: {
        width: '20px',
        height: '20px',
    },
    iconInvalid: {
        color: '#E74C3C',
    },
    secondaryAction: {
        marginRight: 50,

        '& .MuiTypography-body2': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    listItem: {
        '&:hover': {
            backgroundColor: '#fafafa',
        },
    },
}));

export { useStyles };
