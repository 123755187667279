import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatPxToRem } from 'common/utils';

function handleFontSize(size: 'sm' | 'md' | 'lg') {
    if (size === 'sm') return formatPxToRem(10);
    if (size === 'md') return formatPxToRem(16);
    if (size === 'lg') return formatPxToRem(24);
}

const label = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 600,
    gap: formatPxToRem(3),
};
const useStyles = makeStyles<Theme, { size: 'sm' | 'md' | 'lg' }>((theme) => ({
    zeroValueLabel: {
        ...label,
        color: theme.palette.grey[400],
        fontSize: (props) => handleFontSize(props.size),
    },
    positiveValueLabel: {
        ...label,
        color: theme.palette.success.main,
        fontSize: (props) => handleFontSize(props.size),
    },
    negativeValueLabel: {
        ...label,
        color: theme.palette.error.main,
        fontSize: (props) => handleFontSize(props.size),
    },
}));

export default useStyles;
