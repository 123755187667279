import { TypeUser, User } from '../../common/interfaces/user.interface';
import { UserReducerActions, UserActionTypes } from './user.types';

const setAuthUser = ({ user }: { user: User }): UserReducerActions => ({
    type: UserActionTypes.SET_AUTH_USER,
    payload: {
        user,
    },
});

const setAuthUserCurrentAccessType = (accessType: TypeUser): UserReducerActions => ({
    type: UserActionTypes.SET_AUTH_USER_CURRENT_ACCESS_TYPE,
    payload: {
        accessType,
    },
});

const removeAuthUser = (): UserReducerActions => ({
    type: UserActionTypes.REMOVE_AUTH_USER,
});

export { removeAuthUser, setAuthUser, setAuthUserCurrentAccessType };
