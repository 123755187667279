import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { ListProviderInvoicesResponse } from 'clients/manager/interfaces/invoice.interface';
import { getBackofficeRedirectUrl, getMd5Hash } from 'common/utils';
import SearchInvoicesTableView from './search-invoices-table-view';

interface SearchInvoicesTableProps {
    queryParams: UsePaginatedQueryResponse<ListProviderInvoicesResponse>;
}

const SearchInvoicesTable: React.FC<SearchInvoicesTableProps> = ({ queryParams }) => {
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const getProposalUrl = (id: number) => {
        const hash = getMd5Hash(String(id));

        return `${backofficeRedirectUrl}/pesquisa/?idE=${hash}`;
    };

    return <SearchInvoicesTableView queryParams={queryParams} getProposalUrl={getProposalUrl} />;
};

export default SearchInvoicesTable;
