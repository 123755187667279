import { styled } from 'common/theme/styled';

const Content = styled.div``;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
`;

export { Content, Actions };
