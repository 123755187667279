import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
        '&:hover': {
            border: `solid 2px ${theme.palette.secondary.main}`,
        },
        border: (selected) =>
            selected ? `solid 2px ${theme.palette.secondary.main}` : 'solid 2px transparent',
        width: '100%',
        minHeight: '25rem',
        cursor: 'pointer',
        transition: 'all 0.5s',
    },
    checkIcon: {
        color: theme.palette.secondary.main,
    },
    cardHeaderText: {
        color: 'white',
        fontSize: '0.9rem',
        fontWeight: 100,
    },
    cardHeaderTextSmallPaymentCondition: {
        color: 'white',
        fontSize: '1.3rem',
    },
    cardHeaderTextSmall: {
        color: 'white',
        fontSize: '0.8rem',
    },
    cardHeaderTextSmallTag: {
        fontSize: '0.8rem',
        background: '#72ffff',
        borderRadius: '0.2rem',
        padding: '0rem 0.5rem',
        color: '#1d1ce5',
    },
    cardHeaderTextLarge: {
        color: 'white',
        fontSize: '3rem',
    },
    cardHeaderDivTextLarge: {
        display: 'flex',
        flexDirection: 'row',
        width: '98%',
        alignItems: 'flex-end',
    },
    cardHeaderDivTextSmall: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        width: '98%',
    },
    cardHeaderDivTag: {
        display: 'flex',
        padding: '0rem 0.5rem',
        borderRadius: '1rem',
        marginTop: '1rem',
        border: 'solid 1px white',
    },
    cardPrice: {
        color: 'white',
        fontSize: '4rem',
    },
    cardPriceCents: {
        fontSize: '1.5rem',
        marginBottom: '10px',
        color: 'white',
    },
    cardHeaderBlue: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        background: '#1d1ce5',
        paddingRight: theme.spacing(1),
        minHeight: '13rem',
        width: '100%',
    },
    cardHeaderGray: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        background: '#00000070',
        paddingRight: theme.spacing(1),
        minHeight: '13rem',
        width: '100%',
    },
    cardContent: {
        padding: theme.spacing(2),
        minHeight: '23rem',
    },
    benefit: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '0.5rem',
    },
    benefitText: {
        fontSize: '0.8rem',
    },
    benefitTextBlue: {
        fontSize: '1rem',
        color: '#1d1ce5',
        marginLeft: '0.5rem',
        fontWeight: 700,
    },
    benefitTextGray: {
        fontSize: '1rem',
        color: '#00000070',
        marginLeft: '0.5rem',
        fontWeight: 700,
    },
    iconBenefit: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        color: '#1d1ce5',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    planFooter: {
        display: 'flex',
        columnGap: theme.spacing(0.5),
    },
    benefitIconBlue: {
        color: '#1d1ce5',
    },
    benefitIconGray: {
        color: '#00000070',
    },
    selectButtonBlue: {
        width: '90%',
        background: '#1d1ce5',
    },
    selectButtonGray: {
        width: '90%',
        background: '#00000070',
    },
    divSelectButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1rem',
    },
}));

export default useStyles;
