import React, { useState } from 'react';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { debounce } from 'lodash';
import SearchSusCodeModalView from './search-sus-code-modal-view';
import { integrationsPagesusRequests } from '../../services/integrations-pagesaude.requests';

interface SusOption {
    susText: string;
    susCode: string;
}

interface SearchSusCodeModalProps {
    open: boolean;
    value: SusOption | null;
    onChange: (value: SusOption | null) => Promise<any>;
    onClose: () => void;
}

const SearchSusCodeModal: React.FC<SearchSusCodeModalProps> = ({
    onClose,
    onChange,
    value,
    open,
}) => {
    const [search, setSearch] = useState({ params: { name: '' } });
    const { data: susCodeList, loading } = useQueryWithBody(
        integrationsPagesusRequests.listSusCode,
        search
    );
    const options = susCodeList?.data ?? [];

    const debounceSearchSus = debounce((text: string) => {
        setSearch({
            params: {
                name: text,
            },
        });
    }, 500);

    return (
        <SearchSusCodeModalView
            {...{ loading, options, value, onChange, open, onClose, debounceSearchSus }}
        />
    );
};

export default SearchSusCodeModal;
