import { addNotificationSuccess } from 'common/utils';
import { useFormik } from 'formik';
import HolidaysService from 'modules/holidays/services/HolidaysService';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSearchHolidaysContext } from '../../context/SearchHolidaysContext';
import SearchHolidaysDialogView from './SearchHolidaysDialogView';

const SearchHolidaysDialog = () => {
    const { holidayDialog, setHolidayDialog } = useSearchHolidaysContext();
    const { t } = useTranslation();

    const handleClose = () => setHolidayDialog({ open: false });

    const initialValues = {
        typeHoliday: '',
        dateHoliday: '',
        description: '',
    };

    const validationSchema = Yup.object().shape({
        typeHoliday: Yup.string().required(t('term.required-field')),
        dateHoliday: Yup.string().required(t('term.required-field')),
        description: Yup.string().required(t('term.required-field')),
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await HolidaysService.doSave(values);

            addNotificationSuccess();
            handleClose();
        } finally {
            setSubmitting(false);
        }
    };

    const form = useFormik({
        initialValues:
            (holidayDialog.holiday && {
                ...holidayDialog.holiday,
                dateHoliday: moment.utc(holidayDialog.holiday.dateHoliday).format('YYYY-MM-DD'),
            }) ||
            initialValues,
        validationSchema,
        onSubmit,
    });

    return <SearchHolidaysDialogView {...{ form, handleClose }} />;
};

export default SearchHolidaysDialog;
