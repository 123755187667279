import { Plan } from 'clients/manager/plan.request';
import { TypeInvoice } from 'common/enums';
import { createContext, useContext, useState } from 'react';

type CheckoutContextType = {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    selectedSteps: SelectedSteps;
    setSelectedSteps: React.Dispatch<React.SetStateAction<SelectedSteps>>;
};

const initialSelectedSteps: SelectedSteps = {
    plan: {
        selected: '',
        details: {
            id: 0,
            title: '',
            billing: {
                type: '',
                value: 0,
            },
            priceDetails: {
                priceType: '',
                installmentsNumber: 0,
            },
            benefits: [],
            durabiliyPlan: TypeInvoice.yearly,
        },
    },
    payment: {
        selected: '',
        details: {},
    },
    confirmation: {
        selected: '',
        details: {},
    },
};

export const CheckoutContext = createContext<CheckoutContextType>({
    step: 1,
    setStep: () => {},
    selectedSteps: initialSelectedSteps,
    setSelectedSteps: () => {},
});

export interface SelectedStepsObject {
    selected: string;
    details: any;
}

export interface SelectedSteps {
    plan: {
        selected: string;
        details: Plan;
    };
    payment: SelectedStepsObject;
    confirmation: SelectedStepsObject;
}

export const CheckoutContextProvider = ({ children }) => {
    const [step, setStep] = useState(1);
    const [selectedSteps, setSelectedSteps] = useState<SelectedSteps>(initialSelectedSteps);

    return (
        <CheckoutContext.Provider
            value={{
                step,
                setStep,
                selectedSteps,
                setSelectedSteps,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

export const useCheckoutContext = () => useContext(CheckoutContext);
