import {
    Box,
    CircularProgress,
    Grid,
    debounce,
    FormControlLabel,
    Radio as RadioButton,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AuctionNoticeForProvider,
    auctionNoticeRequests,
} from 'clients/manager/auction-notice.requests';
import { TypeCancel } from 'clients/manager/interfaces/auction-notice.interface';
import ProcessSearchItem from 'modules/bidding/components/search-item';
import {
    PROCESS_ENUMS,
    PROCESS_STATUS_CANCELED_ENUMS,
    PROCESS_STATUS_ENUMS,
} from 'modules/bidding/pages/search';
import { providerRegistrationUpdateRequests } from 'clients/manager/provider-registration-update-request.requests';
import { addNotificationSuccess } from 'common/utils';
import { Button, Modal, ModalPosition, TextField } from 'common/components';

interface ModalSendRequestProps {
    onClose: () => void;
    onSendRequest: () => void;
    sendDocumentation?: any;
}

const ModalSendRequest: FC<ModalSendRequestProps> = ({
    onClose,
    onSendRequest,
    sendDocumentation,
}) => {
    const { t } = useTranslation();
    const [processSearch, setProcessSearch] = useState<string>('');
    const [processOptions, setProcessOptions] = useState<AuctionNoticeForProvider[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [visibleProcess, setVisibleProcess] = useState<number>(1);
    const [processNumber, setProcessNumber] = useState<number | undefined>();

    const getProcessStatus = (isCanceled: number, typeCancel: TypeCancel, status: number) => {
        if (isCanceled) {
            return PROCESS_STATUS_CANCELED_ENUMS[typeCancel] === undefined
                ? 'canceled'
                : PROCESS_STATUS_CANCELED_ENUMS[typeCancel];
        }

        return PROCESS_STATUS_ENUMS[status];
    };

    function formatOptionLabel(
        id?: number,
        auctionNumber?: string,
        organizationName?: string,
        organizationUnitName?: string
    ) {
        let text = '';
        if (id) text += `ID ${id}  | `;
        if (auctionNumber) text += `${auctionNumber}  | `;
        if (organizationName) text += `${organizationName}`;
        if (organizationUnitName) text += ` - ${organizationUnitName}`;
        return text;
    }

    const debounceProcessSearchText = debounce((text: string) => setProcessSearch(text), 500);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVisibleProcess(Number((event.target as HTMLInputElement).value));
        setProcessNumber(undefined);
    };

    const sendRequest = () => {
        if (sendDocumentation) {
            sendDocumentation(processNumber);
            return;
        }
        onSendRequest();
        providerRegistrationUpdateRequests.sendRegistrationUpdateRequestToReview(processNumber);

        addNotificationSuccess({
            title: t('info.provider-request-sended.title'),
            message: t('info.provider-request-sended.description'),
            duration: 3000,
        });
        onClose();
    };

    useEffect(() => {
        async function fetchProcess() {
            setLoading(true);
            const { data } = await auctionNoticeRequests.listAvailableProcessesForProvider({
                params: {
                    search: processSearch,
                },
            });

            setProcessOptions(data);
            setLoading(false);
        }
        fetchProcess();
    }, [processSearch]);

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={() => onClose()}
            header={<span>{t('info.provider-send-request-title')}</span>}
            style={{ padding: '20px' }}
        >
            <Box minWidth='480px' maxWidth='800px'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity='info' variant='standard'>
                            {t('info.provider-send-request')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={1} display='flex' alignItems='center'>
                            <RadioGroup row value={visibleProcess} onChange={handleChange}>
                                <FormControlLabel
                                    value={1}
                                    control={<RadioButton color='primary' />}
                                    label={t('term.yes')}
                                />
                                <FormControlLabel
                                    value={0}
                                    control={<RadioButton color='primary' />}
                                    label={t('term.no')}
                                />
                            </RadioGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {visibleProcess === 1 ? (
                            <Autocomplete
                                autoComplete
                                fullWidth
                                loading={loading}
                                loadingText={<Typography>{`${t('term.loading')}...`}</Typography>}
                                options={processOptions}
                                noOptionsText={
                                    <Typography>{t('info.not-found-process')}</Typography>
                                }
                                onChange={(event, value) => {
                                    if (value && typeof value !== 'string') {
                                        setProcessNumber(value.id);
                                    }
                                }}
                                onInputChange={(event, value) => debounceProcessSearchText(value)}
                                filterOptions={(options) => options}
                                getOptionLabel={({
                                    id,
                                    auctionNumber,
                                    organization,
                                    organizationUnit,
                                }) =>
                                    formatOptionLabel(
                                        id,
                                        auctionNumber,
                                        organization?.organizationName,
                                        organizationUnit?.organizationUnitName
                                    )
                                }
                                renderOption={({
                                    id,
                                    organization,
                                    organizationUnit,
                                    urlOriginalIcon,
                                    hasProposal,
                                    isFavorite,
                                    isSuggestion,
                                    simpleDescription: description,
                                    accreditationNumber: accreditation,
                                    auctionStartDate: startDate,
                                    biddingStageId: status,
                                    auctionNumber: auction,
                                    auctionType: type,
                                    typeCancel,
                                    auctionCanceled: isCanceled,
                                    auctionEndDate: endDate,
                                    startDateTimeDispute: startDisputeDate,
                                }) => (
                                    <Box flex={1}>
                                        <ProcessSearchItem
                                            key={id}
                                            title={`${organization?.organizationName} ${
                                                organizationUnit?.organizationUnitName
                                                    ? `- ${organizationUnit.organizationUnitName}`
                                                    : ''
                                            }`}
                                            status={getProcessStatus(
                                                isCanceled,
                                                typeCancel as TypeCancel,
                                                status
                                            )}
                                            type={PROCESS_ENUMS[type]}
                                            icon={urlOriginalIcon}
                                            canPropose={!!Number(hasProposal)}
                                            favorited={!!Number(isFavorite)}
                                            suggested={!!Number(isSuggestion)}
                                            {...{
                                                id,
                                                description,
                                                auction,
                                                accreditation,
                                                startDate,
                                                endDate,
                                                startDisputeDate,
                                            }}
                                        />
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('shared.pages.menu-header.option.search-biddings')}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box display='flex' alignItems='center'>
                                                    {loading ? (
                                                        <CircularProgress
                                                            color='inherit'
                                                            size={24}
                                                        />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </Box>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='flex-end'>
                            <Button size='small' variant='outlined' onClick={onClose}>
                                {t('term.cancel')}
                            </Button>
                            <Button
                                disabled={visibleProcess === 1 && !processNumber}
                                size='small'
                                variant='contained'
                                onClick={sendRequest}
                                style={{
                                    marginLeft: '8px',
                                }}
                            >
                                {t('term.send')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalSendRequest;
