import ProcessStatusIcon from 'modules/bidding/components/process-status-icon';

export default [
    {
        value: 'all',
    },
    {
        value: 'dispute',
        icon: <ProcessStatusIcon icon='dispute' />,
    },
    {
        value: 'decision',
        icon: <ProcessStatusIcon icon='decision' />,
    },
    {
        value: 'contract',
        icon: <ProcessStatusIcon icon='contract' />,
    },
    {
        value: 'published',
        icon: <ProcessStatusIcon icon='published' />,
    },
];
