import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import { ListOrganizationAppResponse } from '../interfaces/organization-apps.interfaces';

const BASE_PATH = '/organization-apps';

const organizationAppsRequests = {
    listOrganizationApps: async (config?: AxiosRequestConfig<any>) =>
        doRequest<ListOrganizationAppResponse>(
            managerApiInstance.post(`${BASE_PATH}/listOrganizationApps`, undefined, config)
        ),
};

export { organizationAppsRequests };
