import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType, hasPermissionToExecute } from 'common/utils';
import { TypeAccess } from '../../common/interfaces/user.interface';
import EditGroupAuthorization from './pages/edit-group-authorization';
import SearchGroupAuthorization from './pages/search-group-authorization';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.organization]) &&
                    hasPermissionToExecute('listarGruposDeAutorizacoes')
                }
                exact
                path={`${match.path}/novo`}
                component={() => <EditGroupAuthorization />}
            />
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.organization]) &&
                    hasPermissionToExecute('listarGruposDeAutorizacoes')
                }
                exact
                path={`${match.path}/detalhes/:id`}
                component={() => <EditGroupAuthorization readOnly />}
            />
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.organization]) &&
                    hasPermissionToExecute('listarGruposDeAutorizacoes')
                }
                exact
                path={`${match.path}/:id`}
                component={() => <EditGroupAuthorization />}
            />
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.organization]) &&
                    hasPermissionToExecute('listarGruposDeAutorizacoes')
                }
                exact
                path={`${match.path}/`}
                component={() => <SearchGroupAuthorization />}
            />
        </Switch>
    );
};

const GroupAuthorizationRoutes: JSX.Element[] = [
    <Route path='/grupo-autorizacao' key='/grupo-autorizacao' render={() => <ChildRoutes />} />,
];

export default GroupAuthorizationRoutes;
