import { doRequest, managerApiInstance } from 'clients/api';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { PlainGenericResponse } from '../../interfaces/paginated.interface';
import { ProcessDocumentsStepsVisibility } from '../interfaces/process-documents-steps.interface';
import { GeneratedDocumentSigner } from '../interfaces/generated-documents.interface';
import { UploadDocumentResponse } from '../interfaces/document.interface';
import { DocumentTemplateType } from '../interfaces/document-templates.interface';

interface UploadDocument {
    processId: number;
    name: string;
    signers?: GeneratedDocumentSigner[];
    step: {
        order: number;
        description?: string;
        visibility: ProcessDocumentsStepsVisibility;
        documentType: DocumentTemplateType;
    };
}

const documentRequests = {
    uploadDocument: async (body: UploadDocument, file: File) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);
        formData.append('processId', String(body.processId));
        formData.append('name', body.name);
        formData.append('order', String(body.step.order));
        formData.append('description', body.step.description ?? '');
        formData.append('visibility', body.step.visibility);
        formData.append('documentType', String(body.step.documentType));
        formData.append('signers', JSON.stringify(body.signers ?? []));
        return doRequest<PlainGenericResponse<UploadDocumentResponse>>(
            managerApiInstance.post('documents/uploadDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        );
    },
};

export type { UploadDocument };
export { documentRequests };
