import { styled } from 'common/theme/styled';

const Content = styled.div`
    p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 14px;
    }
`;

const Link = styled.span<{ disabled?: boolean }>`
    ${(props) =>
        !props.disabled &&
        `
    cursor: pointer;
    &:hover {
        color: ${props.theme.colors.primary};
    }
    `}
    color: ${({ theme }) => theme.colors.textLight};
`;

const Separator = styled.span`
    color: ${({ theme }) => theme.colors.textLight};
`;

export { Content, Link, Separator };
