import { Badge, CircularProgress, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { TextField } from 'common/components';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { formatCpfOrCnpj } from 'common/utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TableCellHidden from 'common/components/table-cell-hidden';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: theme.spacing(-4),
        top: theme.spacing(-0.5),
    },
}))(Badge);

const SearchProviderUsersTableView = ({ users, handleChangeFilter, handleRemove, handleEdit }) => {
    const { t } = useTranslation();

    return (
        <>
            <TextField
                label={t('organization-users.pages.components.search-user')}
                placeholder={t('group-auth.pages.search-group.components.search-group-press-enter')}
                fullWidth
                onKeyPress={handleChangeFilter}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />

            <br />
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.name')}</TableCell>
                        <TableCell>CPF</TableCell>
                        <TableCell colSpan={2}>{t('term.start-date')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {users &&
                        users.map((row) => (
                            <TableRow hover key={row.id}>
                                <TableCell>
                                    <StyledBadge
                                        color='secondary'
                                        badgeContent='Super Admin'
                                        invisible={row.userId !== row.providerUserId}
                                    >
                                        <Typography>{row.name}</Typography>
                                    </StyledBadge>
                                </TableCell>
                                <TableCellHidden cpf={formatCpfOrCnpj(row.cpf)} />
                                <TableCell>
                                    {moment(row.dateTimeInsert).format('DD/MM/YYYY HH:mm:ss')}
                                </TableCell>
                                <TableCell align='center' padding='none' style={{ width: 120 }}>
                                    <Grid>
                                        <>
                                            <Tooltip title={t('term.edit') as string}>
                                                <IconButton onClick={() => handleEdit(row)}>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            {row.userId !== row.providerUserId && (
                                                <Tooltip title={t('term.remove') as string}>
                                                    <IconButton onClick={() => handleRemove(row)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    {users && !users.length && (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {!users && (
                        <TableRow>
                            <TableCell colSpan={5} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default SearchProviderUsersTableView;
