import React, { FC, ReactElement, useState } from 'react';
import { ClickOutside } from '@licitar/ui-kit';
import { Content, Option, MenuOptions } from './styled';
import { DropDownProps } from './props';

export interface DropDownOption {
    label: string;
    action: () => any;
}

const DropDownV2: FC<DropDownProps> = ({ options, children }) => {
    const [active, setActive] = useState(false);

    const handleClick = () => {
        setActive(!active);
    };

    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onClick: (ev: any) => {
            ev.stopPropagation();
            handleClick();
        },
    });

    return (
        <Content>
            {cloneChildren}
            {active && (
                <ClickOutside onClickOutside={() => setActive(false)}>
                    <MenuOptions>
                        {options.map((option: DropDownOption, index: number) => (
                            <Option
                                title={option.label}
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    option.action();
                                    setActive(false);
                                }}
                            >
                                <span>{option.label}</span>
                            </Option>
                        ))}
                    </MenuOptions>
                </ClickOutside>
            )}
        </Content>
    );
};

export default DropDownV2;
