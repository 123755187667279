import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCompareSegmentDataProps } from '../interfaces';

const TableSegmentsView: React.FC<TableCompareSegmentDataProps> = ({ segments }) => {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper} style={{ marginTop: '15px' }}>
            <Table style={{ minWidth: 450 }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.action')}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.segment')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {segments.map((item) => {
                        return (
                            <TableRow>
                                <TableCell
                                    style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}
                                >
                                    {t('term.' + item.type)}
                                </TableCell>
                                <TableCell>{item.data.supplyCategoryName}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableSegmentsView;
