import * as Yup from 'yup';
import i18n from 'i18next';
import { processRules } from 'modules/process/process-utils';
import { Platform } from 'clients/manager/platforms.requests';
import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { AuctionTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';

export const getObjectDefinitionPartialValidationSchema = (
    values: AuctionNotice | undefined,
    platform?: Platform,
    auctionTypeRules?: AuctionTypeRule | undefined
) => {
    const objValidation = {
        simpleDescription: Yup.string()
            .nullable(true)
            .min(10, i18n.t('required-field', { ns: 'validation' }))
            .required(i18n.t('info.min-chars', { ns: 'validation', value: 10 })),
    };

    if (
        !processRules.visibleSegmentsByLot(platform) &&
        !processRules.visibleSegmentsByLotToMarketplace(values) &&
        !auctionTypeRules?.generalSettings?.cadastro.permitirPublicarSemSegmento
    ) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        objValidation['supliesProviders'] = Yup.string()
            .nullable(true)
            .moreThanArraySplit({
                min: 1,
                message: i18n.t('min-required', { ns: 'validation', value: 1 }),
            });
    }

    return objValidation;
};

export const getObjectDefinitionFormLabels = () => ({
    supliesProviders: i18n.t('term.supply-segments'),
    simpleDescription: i18n.t('term.object-notice'),
});
