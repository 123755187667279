import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
    createStyles({
        dialog: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            minWidth: '300px',
        },
        title: {
            textAlign: 'justify',
        },
        content: {
            height: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        progressContainer: {
            textAlign: 'center',
        },
    })
);
