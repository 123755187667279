import { makeStyles } from '@material-ui/core';
import { Theme } from 'common/theme';

const useStyles = makeStyles((theme: Theme | any) => ({
    root: {
        '& .MuiFilledInput-root': {
            background: 'white',
            borderRadius: 5,
            border: `2px solid ${theme.palette.grey[200]}`,
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
            '&.Mui-focused': {
                border: `2px solid ${theme.palette.grey[900]}`,
            },
            '&.Mui-error': {
                border: '2px solid #E74C3C',
            },
        },
    },
    success: {
        '& .MuiInputLabel-filled': {
            color: theme.palette.success.main,
        },
        '& .MuiFilledInput-root': {
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
            '&.Mui-focused': {
                border: `2px solid ${theme.palette.success.main}`,
            },
        },
    },
    warning: {
        '& .MuiInputLabel-filled': {
            color: theme.palette.warning.main,
        },
        '& .MuiFilledInput-root': {
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
            '&.Mui-focused': {
                border: `2px solid ${theme.palette.warning.main}`,
            },
        },
    },
    danger: {
        '& .MuiInputLabel-filled': {
            color: theme.palette.danger.main,
        },
        '& .MuiFilledInput-root': {
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
            '&.Mui-focused': {
                border: `2px solid ${theme.palette.danger.main}`,
            },
        },
    },
}));

export default useStyles;
