import { Route, RouteComponentProps } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import SignUp from './pages/sign-up';
import SignUpStepperOrganization from './pages/sign-up-stepper-organization';
import { SignUpOrganizationContextProvider } from './pages/sign-up-stepper-organization/context/SignUpOrganizationContext';
import SignUpStepperProvider from './pages/sign-up-stepper-provider';
import { SignUpContextProvider } from './pages/sign-up/context/SignUpContext';

const SignUpRoutes: JSX.Element[] = [
    <Route
        path='/cadastro'
        key='/cadastro'
        exact
        render={(props: RouteComponentProps) => (
            <SignUpContextProvider>
                <SignUp {...props} />
            </SignUpContextProvider>
        )}
    />,
    <PrivateRoute
        path='/cadastro-organizacao'
        key='/cadastro-organizacao'
        exact
        component={(props: RouteComponentProps) => (
            <SignUpOrganizationContextProvider>
                <SignUpStepperOrganization {...props} />
            </SignUpOrganizationContextProvider>
        )}
    />,
    <PrivateRoute
        path='/cadastro-fornecedor'
        key='/cadastro-fornecedor'
        exact
        component={(props: RouteComponentProps) => <SignUpStepperProvider {...props} />}
    />,
];

export default SignUpRoutes;
