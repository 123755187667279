import React, { FC, ReactElement } from 'react';
import {
    Box,
    DialogContent,
    DialogContentText,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';

interface ConfirmDialogProps {
    message?: string;
    title?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    disabled?: boolean;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
    children,
    message,
    title,
    onConfirm,
    onCancel,
    disabled = false,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);
        onCancel?.();
    };

    const handleConfirm = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onConfirm();
        setOpen(false);
    };

    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onClick: (ev: any) => {
            (children as any)?.props?.onClick?.(ev);
            ev.stopPropagation();
            if (disabled) {
                return onConfirm();
            }
            setOpen(true);
        },
    });

    return (
        <>
            {cloneChildren}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                maxWidth='xs'
            >
                <Box p={1}>
                    {title && <DialogTitle>{title}</DialogTitle>}
                    {message && (
                        <DialogContent>
                            <DialogContentText color='textPrimary'>{message}</DialogContentText>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button onClick={handleClose} color='primary' size='small'>
                            {t('term.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleConfirm}
                            size='small'
                        >
                            {t('term.confirm')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};
