import { FC } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import NotFound from 'routes/not-found';
import CreateProcessDocumentTemplate from './process-document-templates/pages/create-process-document-template';
import EditProcessDocumentTemplate from './process-document-templates/pages/edit-process-document-template';
import EditTemplate from './templates/pages/edit-template';
import TemplateLibrary from './templates/pages/template-library';
import TemplateList from './templates/pages/template-list';
import ViewTemplate from './templates/pages/view-template';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                path={`${match.path}/`}
                exact
                component={() => <Redirect to={`${match.path}/modelos`} />}
            />
            <PrivateRoute path={`${match.path}/modelos`} exact component={() => <TemplateList />} />
            <PrivateRoute
                path={`${match.path}/modelos/:templateId/edit`}
                component={() => <EditTemplate />}
            />
            <PrivateRoute
                path={`${match.path}/modelos/biblioteca`}
                component={() => <TemplateLibrary />}
            />
            <PrivateRoute
                path={`${match.path}/modelos/:templateId`}
                component={() => <ViewTemplate />}
            />
            <PrivateRoute
                path={`${match.path}/modelo-documento`}
                exact
                component={() => <CreateProcessDocumentTemplate />}
            />
            <PrivateRoute
                path={`${match.path}/modelo-documento/:processDocumentTemplateId`}
                component={() => <EditProcessDocumentTemplate />}
            />
            <PrivateRoute path='*' key='*' component={() => <NotFound />} />
        </Switch>
    );
};

const EditorRoutes: JSX.Element[] = [
    <Route path='/processos/editor' key='/processos/editor' render={() => <ChildRoutes />} />,
];

export default EditorRoutes;
