import { AuctionTypeEnum } from 'common/enums/auction-type.enum';
import { IconBaseProps } from 'react-icons';
import { FaBullhorn, FaGavel, FaHandshake, FaIdCard, FaMedal, FaPortrait } from 'react-icons/fa';
import { MdGavel } from 'react-icons/md';
import {
    RiInboxUnarchiveFill,
    RiMoneyDollarCircleFill,
    RiSwordFill,
    RiTrophyFill,
} from 'react-icons/ri';
import { GiMoneyStack } from 'react-icons/gi';

interface ProcessTypeIconProps extends IconBaseProps {
    auctionType: AuctionTypeEnum;
}

const ProcessTypeIcon = ({ auctionType, ...props }: ProcessTypeIconProps) => (
    <>
        {auctionType === AuctionTypeEnum.trading && <MdGavel {...props} />}
        {auctionType === AuctionTypeEnum.auction && <RiMoneyDollarCircleFill {...props} />}
        {auctionType === AuctionTypeEnum.dispense && <RiInboxUnarchiveFill {...props} />}
        {auctionType === AuctionTypeEnum.accreditation && <FaIdCard {...props} />}
        {auctionType === AuctionTypeEnum.concurrence && <RiSwordFill {...props} />}
        {auctionType === AuctionTypeEnum.unenforceability && <FaHandshake {...props} />}
        {auctionType === AuctionTypeEnum.competitiveDialogue && <RiSwordFill {...props} />}
        {auctionType === AuctionTypeEnum.contest && <RiTrophyFill {...props} />}
        {auctionType === AuctionTypeEnum.inPersonConcurrence && <FaPortrait {...props} />}
        {auctionType === AuctionTypeEnum.inPersonTrading && <FaGavel {...props} />}
        {auctionType === AuctionTypeEnum.expressionOfInterest && <FaBullhorn {...props} />}
        {auctionType === AuctionTypeEnum.preQualification && <FaMedal {...props} />}
        {auctionType === AuctionTypeEnum.inPersonAuction && <GiMoneyStack {...props} />}
        {auctionType === AuctionTypeEnum.biddingInapplicability && (
            <RiInboxUnarchiveFill {...props} />
        )}
    </>
);

export default ProcessTypeIcon;
