import i18n from 'common/i18n';
import {
    RegistrationUpdateRequestPriority,
    RegistrationUpdateRequestStatus,
    RegistrationUpdateRequestType,
} from '../../../../../clients/manager/interfaces/provider-registration-update-requests.interface';

const getPriorityClassName = (priority: RegistrationUpdateRequestPriority) => {
    const className: { [key in RegistrationUpdateRequestPriority]: string } = {
        [RegistrationUpdateRequestPriority.high]: 'chipHigh',
        [RegistrationUpdateRequestPriority.medium]: 'chipMedium',
        [RegistrationUpdateRequestPriority.low]: 'chipLow',
    };

    return className[priority];
};

const getPriorityLabel = (priority: RegistrationUpdateRequestPriority) => {
    const labels: { [key in RegistrationUpdateRequestPriority]: string } = {
        [RegistrationUpdateRequestPriority.high]: i18n.t('term.high'),
        [RegistrationUpdateRequestPriority.medium]: i18n.t('term.medium'),
        [RegistrationUpdateRequestPriority.low]: i18n.t('term.low'),
    };

    return labels[priority];
};

const getStatusClassName = (status: RegistrationUpdateRequestStatus) => {
    const className: { [key in RegistrationUpdateRequestStatus]: string } = {
        [RegistrationUpdateRequestStatus.approved]: 'chipStatusApproved',
        [RegistrationUpdateRequestStatus.draft]: '',
        [RegistrationUpdateRequestStatus.pending]: 'chipStatusPending',
        [RegistrationUpdateRequestStatus.reproved]: 'chipStatusReproved',
    };

    return className[status];
};

const getStatusLabel = (status: RegistrationUpdateRequestStatus) => {
    const labels: { [key in RegistrationUpdateRequestStatus]: string } = {
        [RegistrationUpdateRequestStatus.approved]: i18n.t('term.approved'),
        [RegistrationUpdateRequestStatus.draft]: i18n.t('term.draft'),
        [RegistrationUpdateRequestStatus.pending]: i18n.t('term.pending'),
        [RegistrationUpdateRequestStatus.reproved]: i18n.t('term.reproved'),
    };

    return labels[status];
};

const getTypeLabel = (type: RegistrationUpdateRequestType) => {
    const labels: { [key in RegistrationUpdateRequestType]: string } = {
        [RegistrationUpdateRequestType.insert]: i18n.t('term.inclusion'),
        [RegistrationUpdateRequestType.update]: i18n.t('term.update2'),
    };

    return labels[type];
};

export { getPriorityClassName, getPriorityLabel, getStatusClassName, getStatusLabel, getTypeLabel };
