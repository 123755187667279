import i18n from 'common/i18n';

export enum EvaluatingStatusType {
    REPROVADO = -1,
    NAO_ENVIADO = 0,
    AGUARDANDO_AVALIACAO = 1,
    APROVADO = 2,
    AGUARDANDO_ATI_VCTE = 3,
    EXPIRADO = 4,
}

export const getEvaluatingStatusTypeDescription = () => ({
    [EvaluatingStatusType.REPROVADO]: i18n.t('term.reproved'),
    [EvaluatingStatusType.NAO_ENVIADO]: i18n.t('info.not-sent'),
    [EvaluatingStatusType.AGUARDANDO_AVALIACAO]: i18n.t('info.awaiting-validation'),
    [EvaluatingStatusType.APROVADO]: i18n.t('term.approved'),
    [EvaluatingStatusType.AGUARDANDO_ATI_VCTE]: i18n.t('info.provider-document-reference'),
    [EvaluatingStatusType.EXPIRADO]: i18n.t('term.expired'),
});

export enum DocumentType {
    basic = 'basic',
    additional = 'additional',
}

export enum DocumentTypeDocument {
    upload = 'upload',
    reference = 'reference',
    declaration = 'declaration',
}

export enum SegmentationTypeEnum {
    allAndNews = 'allAndNews',
    single = 'single',
    divers = 'divers',
}

export interface DocumentTypesProviders {
    id: number;
    documentTypeId?: number;
    providerId?: number;
    fileName?: any;
    url?: string;
    evaluatingStatus: EvaluatingStatusType;
    evaluatingUserId?: number;
    evaluatingDateTime?: Date;
    justificationOfFailure?: any;
    dueDate?: string | null;
    isDocumentProtected?: boolean;
    declarationAccepted?: boolean;
    dateTimeInsert?: Date;
    dateTimeUpdate?: Date;
}

export interface DocumentTypesProvidersHistoric {
    id: number;
    documentTypeId?: number;
    documentTypeProviderId?: number;
    providerId?: number;
    fileNameOld?: any;
    evaluatingStatusOld: EvaluatingStatusType;
    evaluatingUserIdOld?: number;
    evaluatingDateTimeOld?: Date;
    justificationOfFailureOld?: any;
    dueDateOld?: string;
    isDocumentProtected?: boolean;
    declarationAccepted: boolean;
    dateTimeInsertOld?: Date;
    dateTimeUpdateOld?: Date;
    createdAt?: Date;
    url?: string;
}

export interface CategoryDocument {
    id: number;
    name: string;
    description: string;
}

export interface DocumentsAndCategoryFormated {
    id: number;
    name: string;
    segmentationType: SegmentationTypeEnum;
    description: string;
    documentRequired: number;
    typeDocument: string;
    referenceDescription: string;
    documentStage: string;
    canItContainPersonalData: number;
    documentTypesProviders: DocumentTypesProviders;
    categoryDocument: CategoryDocument;
    segmentsId?: number[];
    dateTimeInsert?: Date;
    dateTimeUpdate?: Date;
}

export interface DocumentTypeProviderSegments {
    id: number;
    externalId: number;
    segmentName: string;
    documentTypeId: number;
    dueDate: string;
}
