import { Input, TableCell } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { featureFlagEnabled, formatCurrency } from 'common/utils';
import { CurrencyInput } from 'common/components/currency';
import { ListMaterialData } from 'modules/material/interfaces/material.interface';
import { getDecimalPlaces } from '../../../../process-utils';
import { AuctionLotFormItem } from '../../../../context/lots.form';
import { Column } from './columns';
import { useStyles } from './styles';
import MaterialsInput from './components/materials-input';

interface RenderCellProps {
    column: Column;
    item: AuctionLotFormItem;
    index: number;
    onChange: (value: any) => void;
    onExternalIdChange?: (value: any) => void;
    decimalPlaces: number;
    canEditFields: boolean;
    showSus: boolean;
}

const RenderCell: FC<RenderCellProps> = ({
    column,
    item,
    index,
    onChange,
    onExternalIdChange,
    decimalPlaces,
    canEditFields,
    showSus,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const field = column.id;
    const id = `${field}-${index}`;

    const [value, setValue] = useState(item[column.id]);

    const activeFields = !canEditFields;

    const handleOnChange = (value: any) => {
        setValue(value);
        onChange(value);
    };

    useEffect(() => {
        setValue(item[column.id]);
    }, [item[column.id]]);

    if (field === 'totalValue') {
        return (
            <TableCell key={field} align={column.align} style={showSus ? { border: 'none' } : {}}>
                {formatCurrency(item.referenceValue * item.amount, getDecimalPlaces(decimalPlaces))}
            </TableCell>
        );
    }

    if (!canEditFields) {
        if (field === 'referenceValue') {
            return (
                <TableCell
                    key={field}
                    align={column.align}
                    style={showSus ? { border: 'none' } : {}}
                >
                    {formatCurrency(value, getDecimalPlaces(decimalPlaces))}
                </TableCell>
            );
        }

        if (field === 'amount') {
            return (
                <TableCell
                    key={field}
                    align={column.align}
                    style={showSus ? { border: 'none' } : {}}
                >
                    {formatCurrency(value, getDecimalPlaces(decimalPlaces), true)}
                </TableCell>
            );
        }

        return (
            <TableCell key={field} align={column.align} style={showSus ? { border: 'none' } : {}}>
                {value}
            </TableCell>
        );
    }

    if (field === 'referenceValue') {
        return (
            <TableCell key={field} align={column.align} style={showSus ? { border: 'none' } : {}}>
                <CurrencyInput
                    id={id}
                    disabled={activeFields}
                    className={classes.input}
                    allowNegative={false}
                    value={Number(value)}
                    prefix='R$ '
                    decimalSeparator=','
                    precision={getDecimalPlaces(decimalPlaces)}
                    thousandSeparator='.'
                    onChange={(_, value: number) => handleOnChange(value)}
                    inputProps={{ style: { textAlign: column.align } }}
                    component={Input}
                />
            </TableCell>
        );
    }

    if (field === 'itemDescription') {
        const isMaterialsEnabled = featureFlagEnabled('habilitarCadastroDeMateriais');

        const handleOnChangeMaterialsModal = (value: ListMaterialData | null) => {
            if (!value) return;

            handleOnChange(value.name);
            onExternalIdChange?.(value.code);
        };

        return (
            <TableCell key={field} align={column.align} style={showSus ? { border: 'none' } : {}}>
                <Input
                    id={id}
                    value={value}
                    disabled={activeFields}
                    className={classes.textArea}
                    placeholder={t('term.description')}
                    fullWidth
                    color='primary'
                    defaultValue={value}
                    multiline
                    minRows={2}
                    maxRows={8}
                    autoFocus
                    onChange={(ev) => {
                        handleOnChange(ev.target.value);
                        onExternalIdChange?.(undefined);
                    }}
                    inputProps={{ style: { textAlign: column.align } }}
                    endAdornment={
                        <MaterialsInput
                            visible={isMaterialsEnabled}
                            defaultValue={item.externalItemId}
                            onChange={handleOnChangeMaterialsModal}
                        />
                    }
                />
            </TableCell>
        );
    }

    if (field === 'amount') {
        return (
            <TableCell key={field} align={column.align} style={showSus ? { border: 'none' } : {}}>
                <CurrencyInput
                    id={id}
                    disabled={activeFields}
                    className={classes.input}
                    allowNegative={false}
                    value={Number(value)}
                    prefix=''
                    decimalSeparator=','
                    precision={getDecimalPlaces(decimalPlaces)}
                    thousandSeparator='.'
                    onChange={(_, value: number) => handleOnChange(value)}
                    inputProps={{ style: { textAlign: column.align } }}
                    component={Input}
                />
            </TableCell>
        );
    }

    if (field === 'unitMeasurement') {
        return (
            <TableCell key={field} align={column.align} style={showSus ? { border: 'none' } : {}}>
                <Input
                    id={id}
                    disabled={activeFields}
                    className={classes.input}
                    placeholder={t('term.unit-measure')}
                    color='primary'
                    defaultValue={value}
                    onChange={(ev) => handleOnChange(ev.target.value)}
                    inputProps={{ style: { textAlign: column.align } }}
                />
            </TableCell>
        );
    }

    return (
        <TableCell key={field} align={column.align} style={showSus ? { border: 'none' } : {}}>
            {value}
        </TableCell>
    );
};

export default RenderCell;
