import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    skeleton: {
        height: '420px',
        marginBottom: '10px',
        borderRadius: '8px',
        transform: 'scale(1)',
    },
    container: {
        paddingRight: '10px',
        height: '80vh',
        flex: 1,
        overflowY: 'auto',
    },
}));

export { useStyles };
