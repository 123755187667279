import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePageContext } from 'common/components/base/pages/context';
import { AppState } from 'store';
import CitizenDashboardView from './citizen-dashboard-view';

const CitizenDashboard = () => {
    const { setTitle } = usePageContext();
    const { authUser } = useSelector((state: AppState) => state.authUserState);

    useEffect(() => {
        setTitle('Painel');
    }, []);

    return <CitizenDashboardView {...{ authUser }} />;
};

export default CitizenDashboard;
