export const getNewFileName = (file: File, max?: number): string => {
    const extension = file.name.split('.')?.pop();
    const fileName = file.name.split('.')[0];

    if (!extension || !fileName) return file.name;

    let newFileName = fileName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '_');

    if (max && fileName.length > max) {
        newFileName = newFileName.slice(0, max);
    }

    return `${newFileName}.${extension}`;
};
