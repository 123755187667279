import React from 'react';
import { ContractWithDetails } from 'clients/manager/interfaces/contract.interface';
import ContractItemsTableView from './contract-items-table-view';

interface ContractItemsTableProps {
    contractItems: ContractWithDetails['contract']['items'];
    loading?: boolean;
}

const ContractItemsTable: React.FC<ContractItemsTableProps> = ({
    contractItems = [],
    loading = false,
}) => {
    return <ContractItemsTableView contractItems={contractItems} loading={loading} />;
};

export default ContractItemsTable;
