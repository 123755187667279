import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    filterItemTop: {
        color: '#888888',
        paddingBottom: '3px',
        paddingLeft: '15px',
        fontSize: '13px',
    },
    filterItemHeader: {
        fontSize: '16px',
        paddingBottom: '8px',
    },
    filterProcessContainer: {
        maxHeight: '20vh',
        overflowY: 'auto',
    },
    filterProcessCategories: {
        maxHeight: '35vh',
        overflowY: 'auto',
    },
}));

export { useStyles };
