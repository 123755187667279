import { FC, useState } from 'react';
import { Button, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { GenerateDataContainer } from './styles';

interface Props {
    onGeneratePurgeData: (beforeAt: number) => void;
}
const Resume: FC<Props> = ({ onGeneratePurgeData }) => {
    const { t } = useTranslation();

    const [beforeAt, setBeforeAt] = useState<number>(
        moment().subtract(1, 'year').startOf('day').valueOf()
    );
    return (
        <div>
            <p>
                <h3> {t('mkp.settings.create-expurgo')}</h3>
            </p>
            <div>
                <TextField
                    label={t('mkp.settings.date-last-in')}
                    name='date'
                    onChange={(event) => {
                        setBeforeAt(moment(event.target.value).valueOf());
                    }}
                    type='date'
                    value={moment(beforeAt).format('YYYY-MM-DD')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <GenerateDataContainer>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => onGeneratePurgeData(beforeAt)}
                >
                    Ok
                </Button>
            </GenerateDataContainer>
        </div>
    );
};

export default Resume as FC<Props>;
