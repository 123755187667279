import { ChipProps } from '@material-ui/core';
import { getOrderStatusLabel } from 'modules/marketplace/utils/get-order-status-label.utils';
import { OrderStatusEnum } from 'common/enums';
import { useStyles } from './order-status-chip-style';
import OrderStatusChipView from './order-status-chip-view';

interface OrderStatusChipProps extends ChipProps {
    status: string;
}

const OrderStatusChip: React.FC<OrderStatusChipProps> = ({ status }) => {
    const styles = useStyles();

    const mapper = {
        [OrderStatusEnum.OPENED]: styles.chipStatusOpened,
        [OrderStatusEnum.CANCELED]: styles.chipStatusCanceled,
        [OrderStatusEnum.FINISHED]: styles.chipStatusFinished,
        [OrderStatusEnum.JUDGED]: styles.chipStatusJudged,
    };

    const defaultProps: ChipProps = {
        label: getOrderStatusLabel(status),
        className: mapper[status] ?? styles.chipStatusFinished,
    };

    return <OrderStatusChipView {...defaultProps} />;
};

export default OrderStatusChip;
