import { Grid, MenuItem, Typography } from '@material-ui/core';
import { TextField, MaskedTextField, Button } from 'common/components';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles.scss';
import { useTranslation } from 'react-i18next';
import { AiOutlineBarcode } from 'react-icons/ai';
import { CHECKOUT } from '../../CheckoutConstants';
import CheckoutPaper from '../../components/CheckoutPaper';
import PaperFooter from '../../components/PaperFooter';
import PaymentCard from '../../components/PaymentCard';
import useStyles from './PaymentStyle';

export default function PaymentView({
    selected,
    form,
    handleClick,
    handleInputFocus,
    focus,
    planInstallments,
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { selectedSteps } = useCheckoutContext();
    return (
        <CheckoutPaper title={t('bidding.checkout.payment.title')}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <PaymentCard
                        title={t('bidding.checkout.payment.pix.card.title')}
                        subtitle={t('bidding.checkout.payment.pix.card.subtitle')}
                        handleSelected={() =>
                            handleClick(t('bidding.checkout.payment.pix.card.title'))
                        }
                        selected={selected === t('bidding.checkout.payment.pix.card.title')}
                        IconComponent={AiOutlineBarcode}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PaymentCard
                        title={t('bidding.checkout.payment.credit_card.card.title')}
                        subtitle={
                            selectedSteps.plan.details.priceDetails.installmentsNumber === 1
                                ? ''
                                : t('bidding.checkout.payment.credit_card.card.subtitle')
                        }
                        handleSelected={() =>
                            handleClick(t('bidding.checkout.payment.credit_card.card.title'))
                        }
                        selected={selected === t('bidding.checkout.payment.credit_card.card.title')}
                        IconComponent={CreditCardIcon}
                    />
                </Grid>
            </Grid>

            <div>
                {selected === CHECKOUT.INVOICE ? (
                    <>
                        <Typography className={classes.creditCardTitle}>
                            {t('bidding.checkout.invoice.content.one')}
                        </Typography>
                        <Typography className={classes.creditCardTitle}>
                            {t('bidding.checkout.invoice.content.two')}
                        </Typography>
                        <PaperFooter
                            disabled={!selected.length}
                            selected={selected}
                            details={form.values}
                        />
                    </>
                ) : (
                    <>
                        <Typography className={classes.creditCardTitle}>
                            {t('bidding.checkout.credit_card.content')}
                        </Typography>

                        <form noValidate onSubmit={form.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('bidding.checkout.credit_card.name')}
                                                name='name'
                                                inputProps={{ maxLength: 20 }}
                                                value={form.values.name}
                                                onChange={form.handleChange}
                                                onFocus={handleInputFocus}
                                                error={form.touched.name && !!form.errors.name}
                                                helperText={form.touched.name && form.errors.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MaskedTextField
                                                fullWidth
                                                mask='9999 9999 9999 9999'
                                                label={t('bidding.checkout.credit_card.number')}
                                                maskChar=' '
                                                inputProps={{ maxLength: 20 }}
                                                name='number'
                                                value={form.values.number}
                                                onChange={(e) => {
                                                    const rawNumber = e.target.value.trimEnd();
                                                    return form.setFieldValue('number', rawNumber);
                                                }}
                                                onFocus={handleInputFocus}
                                                error={form.touched.number && !!form.errors.number}
                                                helperText={
                                                    form.touched.number && form.errors.number
                                                }
                                            />
                                        </Grid>
                                        <Grid container item spacing={2}>
                                            <Grid item xs={6}>
                                                <MaskedTextField
                                                    fullWidth
                                                    mask='99/99'
                                                    label={t(
                                                        'bidding.checkout.credit_card.due_date'
                                                    )}
                                                    maskChar=' '
                                                    name='expiry'
                                                    value={form.values.expiry}
                                                    onChange={(e) => {
                                                        const rawNumber = e.target.value.trimEnd();
                                                        return form.setFieldValue(
                                                            'expiry',
                                                            rawNumber
                                                        );
                                                    }}
                                                    onFocus={handleInputFocus}
                                                    error={
                                                        form.touched.expiry && !!form.errors.expiry
                                                    }
                                                    helperText={
                                                        form.touched.expiry && form.errors.expiry
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MaskedTextField
                                                    fullWidth
                                                    mask='999'
                                                    label={t('bidding.checkout.credit_card.cvv')}
                                                    maskChar=' '
                                                    name='cvc'
                                                    value={form.values.cvc}
                                                    onChange={(e) => {
                                                        const rawNumber = e.target.value.trimEnd();
                                                        return form.setFieldValue('cvc', rawNumber);
                                                    }}
                                                    onFocus={handleInputFocus}
                                                    error={form.touched.cvc && !!form.errors.cvc}
                                                    helperText={form.touched.cvc && form.errors.cvc}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id='installments'
                                                color='primary'
                                                label={t(
                                                    'bidding.checkout.credit_card.installments'
                                                )}
                                                value={form.values.installments}
                                                onChange={(event) => {
                                                    form.setFieldValue(
                                                        'installments',
                                                        event.target.value
                                                    );
                                                }}
                                                variant='outlined'
                                                select
                                                error={
                                                    form.touched.installments &&
                                                    !!form.errors.installments
                                                }
                                                helperText={
                                                    form.touched.installments &&
                                                    form.errors.installments
                                                }
                                            >
                                                {planInstallments.map((planInstallment, index) => (
                                                    <MenuItem
                                                        key={`${planInstallment + index}`}
                                                        value={`${index + 1}`}
                                                    >
                                                        {planInstallment}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.creditCardImg}>
                                    <Cards
                                        cvc={form.values.cvc}
                                        expiry={form.values.expiry}
                                        focused={focus}
                                        name={form.values.name}
                                        number={form.values.number}
                                    />
                                </Grid>
                            </Grid>
                            <div className={classes.boxButton}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    type='submit'
                                >
                                    {t('bidding.checkout.steps.next')}
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </CheckoutPaper>
    );
}
