import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import SelectedStepView from './SelectedStepView';

export default function SelectedStep({ selectedStep, index }) {
    const { setStep } = useCheckoutContext();

    const handleClick = (itemClicked) => {
        setStep(itemClicked + 1);
    };

    return <SelectedStepView {...{ selectedStep, index, handleClick }} />;
}
