import { MouseEvent, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormikProps } from 'formik';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import FormControl from '@material-ui/core/FormControl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoadingButton from 'common/components/loading-button';
import { TextField } from 'common/components';
import { usePlatformContext } from 'routes/platform.context';
import { useTranslation } from 'react-i18next';
import { ContentValidation, LogoContainer } from './styles';
import { RegisterLink } from '../user-auth/styles';
import { UpdatePassword } from './ChangePassword';

interface IChangePasswordView {
    form: FormikProps<UpdatePassword>;
    isWeakPassword: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh;',
        backgroundColor: '#f2f2f2',
    },
    paper: {
        padding: '3rem',
        borderRadius: '1rem',
        width: '32rem',
        margin: '1rem',
    },
    popover: {
        pointerEvents: 'none',
        marginLeft: 12,
    },
    validationFalse: {
        color: 'var(--danger-color)',
    },
    validationTrue: {
        color: 'var(--success-color)',
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        },
    },
}));

export const ChangePasswordView = (props: IChangePasswordView) => {
    const { form, isWeakPassword } = props;
    const classes = useStyles();
    const { platform } = usePlatformContext();
    const defaultImageLogo = platform?.urlOriginalLogo;
    const { t } = useTranslation();

    const firstInputRef = useRef<HTMLInputElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [validatePassword, setValidadePassword] = useState({
        uppercase: false,
        lowercase: false,
        number: false,
        length: false,
        special: false,
    });

    const secureText = (password: string) => {
        const regexUppercase = RegExp(/^(?=.*[A-Z]).+$/);
        const regexLowercase = RegExp(/^(?=.*[a-z]).+$/);
        const regexNumber = RegExp(/^(?=.*[0-9]).+$/);
        const regexSpecial = RegExp(/[!@#$%^&*(),.?":{}|<>]/g);
        const length = password.length >= 8;

        ReactDOM.unstable_batchedUpdates(() => {
            setValidadePassword({
                uppercase: regexUppercase.test(password),
                lowercase: regexLowercase.test(password),
                number: regexNumber.test(password),
                special: regexSpecial.test(password),
                length,
            });
        });
    };

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover-validation-password' : undefined;

    useEffect(() => {
        setTimeout(() => {
            if (firstInputRef && !!firstInputRef.current) firstInputRef.current.focus();
            form.resetForm();
        }, 100);
    }, []);

    return (
        <Grid container justifyContent='center' alignItems='center' className={classes.root}>
            <Grid item>
                <LogoContainer>
                    <img src={defaultImageLogo} alt='logo' />
                </LogoContainer>
                <Paper className={classes.paper}>
                    <FormControl fullWidth>
                        <Grid container direction='column' spacing={2}>
                            {isWeakPassword && (
                                <Grid item>
                                    <Alert severity='warning'>
                                        {t('cognito.info-weak-password')}
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='body2'>
                                    {t('auth.change-password-view')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    inputRef={firstInputRef}
                                    className={classes.input}
                                    type='string'
                                    label={t('term.code')}
                                    name='code'
                                    value={form.values.code}
                                    onChange={form.handleChange}
                                    error={form.touched.code && !!form.errors.code}
                                    helperText={
                                        (form.touched.code && form.errors.code) ||
                                        t('info.code-instruction-password-confirm')
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup='true'
                                    aria-describedby={id}
                                    autoComplete='off'
                                    label={t('term.password')}
                                    name='password'
                                    type='password'
                                    value={form.values.password}
                                    onChange={(e) => {
                                        form.handleChange(e);
                                        secureText(e.target.value);
                                    }}
                                    error={form.touched.password && Boolean(form.errors.password)}
                                    helperText={form.touched.password && form.errors.password}
                                    extraEndAdornment={
                                        <Icon
                                            tabIndex={-1}
                                            aria-label='toggle validation password visibility'
                                            onMouseEnter={handlePopoverOpen}
                                            onMouseLeave={handlePopoverClose}
                                        >
                                            {Object.values(validatePassword).every(Boolean) ? (
                                                <CheckCircleIcon
                                                    className={classes.validationTrue}
                                                />
                                            ) : (
                                                <CancelIcon className={classes.validationFalse} />
                                            )}
                                        </Icon>
                                    }
                                />
                            </Grid>
                            <Popover
                                id={id}
                                className={classes.popover}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <ContentValidation item>
                                    <div className='passwordValidation'>
                                        <div className='validation'>
                                            {validatePassword.length ? (
                                                <CheckCircleIcon className='validation-true' />
                                            ) : (
                                                <CancelIcon className='validation-false' />
                                            )}
                                            <Typography variant='body2'>
                                                {t(
                                                    'sign-up.pages.stepps.info-password-min-character'
                                                )}
                                            </Typography>
                                        </div>
                                        <div className='validation'>
                                            {validatePassword.uppercase ? (
                                                <CheckCircleIcon className='validation-true' />
                                            ) : (
                                                <CancelIcon className='validation-false' />
                                            )}
                                            <Typography variant='body2'>
                                                {t('sign-up.pages.stepps.info-password-uppercase')}
                                            </Typography>
                                        </div>
                                        <div className='validation'>
                                            {validatePassword.lowercase ? (
                                                <CheckCircleIcon className='validation-true' />
                                            ) : (
                                                <CancelIcon className='validation-false' />
                                            )}
                                            <Typography variant='body2'>
                                                {t('sign-up.pages.stepps.info-password-lowercase')}
                                            </Typography>
                                        </div>
                                        <div className='validation'>
                                            {validatePassword.special ? (
                                                <CheckCircleIcon className='validation-true' />
                                            ) : (
                                                <CancelIcon className='validation-false' />
                                            )}
                                            <Typography variant='body2'>
                                                {t(
                                                    'sign-up.pages.stepps.info-password-special-char'
                                                )}
                                            </Typography>
                                        </div>
                                        <div className='validation'>
                                            {validatePassword.number ? (
                                                <CheckCircleIcon className='validation-true' />
                                            ) : (
                                                <CancelIcon className='validation-false' />
                                            )}
                                            <Typography variant='body2'>
                                                {t(
                                                    'sign-up.pages.stepps.info-password-number-char'
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                </ContentValidation>
                            </Popover>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    label={t('term.confirm-password')}
                                    name='confirmPassword'
                                    type='password'
                                    value={form.values.confirmPassword}
                                    onChange={form.handleChange}
                                    error={
                                        form.touched.confirmPassword &&
                                        !!form.errors.confirmPassword
                                    }
                                    helperText={
                                        form.touched.confirmPassword && form.errors.confirmPassword
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle1'>
                                    <RegisterLink to='/login'>
                                        {t('password-reset.back-to-login')}
                                    </RegisterLink>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    color='primary'
                                    size='small'
                                    variant='contained'
                                    onClick={() => form.handleSubmit()}
                                    disabled={form.isSubmitting || !form.isValid}
                                    {...(form.isSubmitting && { loading: { text: '' } })}
                                >
                                    {t('term.save')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Paper>
            </Grid>
        </Grid>
    );
};
