import {
    Box,
    Grid,
    Typography,
    Card,
    Divider,
    MenuItem,
    CircularProgress,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { providerRegistrationUpdateRequests } from 'clients/manager/provider-registration-update-request.requests';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import { CardContent, CardHeader } from '@licitar/ui-kit';
import { useFormik } from 'formik';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
} from 'common/utils';
import { omit } from 'lodash';
import TableDatafieldsView from './componentsModal/table-datafields-view';
import TableFinancialDataView from './componentsModal/table-financialData-view';
import TableRepresentativesView from './componentsModal/table-representatives-view';
import TableSegmentsView from './componentsModal/table-segments-view';
import TableDocumentsView from './componentsModal/table-documents-view';
import LoadingButton from '../loading-button';

interface ModalCompareRequestProps {
    onClose: () => void;
    setHasUpdateRequestsPending: (open: boolean) => void;
    setHasUpdate?: (open: boolean) => void;
    requestUpdate: object;
}

const ModalCompareRequest: FC<ModalCompareRequestProps> = ({
    onClose,
    requestUpdate,
    setHasUpdateRequestsPending,
    setHasUpdate,
}) => {
    const { t } = useTranslation();
    const [listChangesToCompare, setListChangesToCompare] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const requestData = requestUpdate[0];

    if (requestData?.dataFields?.providerId) delete requestData.dataFields.providerId;

    const form = useFormik({
        initialValues: {
            status: requestData.status,
            message: '',
        },
        onSubmit: () => {
            sendRequestUpdate();
        },
    });

    useEffect(() => {
        async function fetchProcess() {
            setLoading(true);
            try {
                const { data } = await providerRegistrationUpdateRequests.listChangesToCompare({
                    providerId: requestData.providerId,
                    requestId: requestData.id,
                });
                setListChangesToCompare(data);
            } catch (error) {
                addNotificationApiError(error);
            } finally {
                setLoading(false);
            }
        }
        fetchProcess();
    }, []);

    const sendRequestUpdate = async () => {
        try {
            setLoading(true);

            let updateRequestValid;
            let updateRequest;

            if (form.values.status === 'approved') {
                updateRequestValid = { ...requestData.dataFields, signupValid: 1 };
                updateRequest = { ...requestData, dataFields: updateRequestValid };
            } else {
                updateRequest = requestData;
            }

            await providerRegistrationUpdateRequests
                .updateRegistrationUpdateRequest({
                    ...omit(updateRequest, [
                        'dataFields.providerId',
                        'dataFields.gender',
                        'dataFields.birthdate',
                        'dataFields.birthplace',
                        'dataFields.profession',
                        'dataFields.intructionLevel',
                        'dataFields.socialProvider',
                    ]),
                    status: form.values.status,
                    message: form.values.message,
                })
                .then(() => {
                    onClose();
                    setHasUpdateRequestsPending(false);
                })
                .then(() =>
                    addNotificationSuccess({
                        title: t('term.success'),
                        message: t('info.action-success'),
                    })
                )
                .then(() => setHasUpdate && setHasUpdate(true));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            addNotificationError({
                title: t('term.error'),
                message: t('info.action-error'),
            });
        }
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={() => onClose()}
            header={
                <span>
                    {t('term.open-request')} #{requestData.id}
                </span>
            }
            style={{ padding: '20px' }}
        >
            <Box
                minWidth='480px'
                maxWidth='600px'
                maxHeight='600px'
                style={{ overflowY: 'scroll' }}
            >
                {loading ? (
                    <Grid container spacing={2} justifyContent='center'>
                        <CircularProgress color='inherit' size={24} />
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                }}
                            >
                                <CardHeader>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        {t('term.general-data')}
                                    </Typography>
                                </CardHeader>
                                <Divider />
                                {listChangesToCompare?.dataFinancialFields &&
                                Object.keys(listChangesToCompare?.dataFields).length === 0 ? (
                                    <CardContent>
                                        <Typography variant='body2'>
                                            {t('info.no-update')}
                                        </Typography>
                                    </CardContent>
                                ) : (
                                    <TableDatafieldsView
                                        datafields={listChangesToCompare?.dataFields}
                                    />
                                )}
                            </Card>
                        </Grid>

                        <Grid item md={12}>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                }}
                            >
                                <CardHeader>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        {t('info.financial-data')}
                                    </Typography>
                                </CardHeader>
                                <Divider />
                                {listChangesToCompare?.dataFinancialFields &&
                                Object.keys(listChangesToCompare?.dataFinancialFields).length ===
                                    0 ? (
                                    <CardContent>
                                        <Typography variant='body2'>
                                            {t('info.no-update')}
                                        </Typography>
                                    </CardContent>
                                ) : (
                                    <TableDatafieldsView
                                        datafields={listChangesToCompare?.dataFinancialFields}
                                    />
                                )}
                            </Card>
                        </Grid>

                        <Grid item md={12}>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                }}
                            >
                                <CardHeader>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        {t('info.bank-data')}
                                    </Typography>
                                </CardHeader>
                                <Divider />
                                {!listChangesToCompare?.financialData ? (
                                    <CardContent>
                                        <Typography variant='body2'>
                                            {t('info.no-update')}
                                        </Typography>
                                    </CardContent>
                                ) : (
                                    <TableFinancialDataView
                                        financialData={listChangesToCompare?.financialData}
                                    />
                                )}
                            </Card>
                        </Grid>

                        <Grid item md={12}>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                }}
                            >
                                <CardHeader>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        {t('info.representatives')}
                                    </Typography>
                                </CardHeader>
                                <Divider />
                                {!listChangesToCompare?.representatives ? (
                                    <CardContent>
                                        <Typography variant='body2'>
                                            {t('info.no-update')}
                                        </Typography>
                                    </CardContent>
                                ) : (
                                    <TableRepresentativesView
                                        representative={listChangesToCompare?.representatives}
                                    />
                                )}
                            </Card>
                        </Grid>

                        <Grid item md={12}>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                }}
                            >
                                <CardHeader>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        {t('term.documents')}
                                    </Typography>
                                </CardHeader>
                                <Divider />
                                {!listChangesToCompare?.documens ? (
                                    <CardContent>
                                        <Typography variant='body2'>
                                            {t('info.no-update')}
                                        </Typography>
                                    </CardContent>
                                ) : (
                                    <TableDocumentsView
                                        documents={listChangesToCompare?.documens}
                                    />
                                )}
                            </Card>
                        </Grid>

                        <Grid item md={12}>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                }}
                            >
                                <CardHeader>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        {t('term.segments')}
                                    </Typography>
                                </CardHeader>
                                <Divider />
                                {!listChangesToCompare?.segments ? (
                                    <CardContent>
                                        <Typography variant='body2'>
                                            {t('info.no-update')}
                                        </Typography>
                                    </CardContent>
                                ) : (
                                    <TableSegmentsView segments={listChangesToCompare?.segments} />
                                )}
                            </Card>
                        </Grid>

                        <Grid item md={12}>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                }}
                            >
                                <CardHeader>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        {t('term.request-done')}
                                    </Typography>
                                </CardHeader>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <TextField
                                                select
                                                label={t('term.status-request')}
                                                fullWidth
                                                name='requestStatus'
                                                value={form.values.status}
                                                onChange={(ev) =>
                                                    form.setFieldValue('status', ev.target.value)
                                                }
                                            >
                                                {['pending', 'approved', 'reproved'].map(
                                                    (value) => (
                                                        <MenuItem key={value} value={value}>
                                                            {t(`term.${value}`)}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={12}>
                                            <TextField
                                                fullWidth
                                                name='message'
                                                color='primary'
                                                inputProps={{
                                                    maxLength: 3000,
                                                }}
                                                multiline
                                                minRows={4}
                                                label={t('term.message')}
                                                onChange={(ev) =>
                                                    form.setFieldValue('message', ev.target.value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='flex-end'>
                                <Button size='small' variant='outlined' onClick={onClose}>
                                    {t('term.cancel')}
                                </Button>
                                <LoadingButton
                                    size='small'
                                    variant='contained'
                                    onClick={form.submitForm}
                                    style={{
                                        marginLeft: '8px',
                                    }}
                                    {...(loading
                                        ? {
                                              loading: {
                                                  text: `${t('term.saving')}..`,
                                              },
                                          }
                                        : {})}
                                >
                                    {t('term.save')}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Modal>
    );
};

export default ModalCompareRequest;
