import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { OkResponse } from 'clients/interfaces/ok.interface';
import { AxiosRequestConfig } from 'axios';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import {
    Contract,
    ContractStatus,
    ContractWithDetails,
    CreateContractData,
    CreateContractTermData,
    ListContractsBodyData,
    ListContractsFromProcessFilters,
    ListContractsFromProcessResponse,
    UpdateContractData,
    RatingContractRequestBody,
} from './interfaces/contract.interface';
import { GeneratedDocuments } from './interfaces/generated-documents.interface';
import { DocumentSigners } from './interfaces/document-signers.interface';

const path = 'contract';

const contractRequests = {
    listContracts: async (body: ListContractsBodyData) =>
        doRequest<GenericResponse<ContractWithDetails>>(
            managerApiInstance.post(`/${path}/listContracts`, body)
        ),
    listContractsFromProcess: async (
        filters?: ListContractsFromProcessFilters,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<ListContractsFromProcessResponse>>(
            managerApiInstance.post(`/${path}/listContractsFromProcess`, filters, config)
        ),
    getContract: async (contractId: string) =>
        doRequest<PlainGenericResponse<ContractWithDetails>>(
            managerApiInstance.post(`/${path}/getContract`, {
                params: { contractId },
            })
        ),
    getByFilterRatingContract: async (contractId: string) =>
        doRequest<PlainGenericResponse<RatingContractRequestBody>>(
            managerApiInstance.get(`/assessment`, {
                params: {
                    contractId,
                },
            })
        ),
    createRatingContract: async (body: RatingContractRequestBody) =>
        doRequest<PlainGenericResponse<RatingContractRequestBody>>(
            managerApiInstance.post(`/assessment`, body)
        ),
    updateRatingContract: async (id: string, body: RatingContractRequestBody) =>
        doRequest<PlainGenericResponse<RatingContractRequestBody>>(
            managerApiInstance.put(`/assessment/${id}`, body)
        ),
    deleteRatingContract: async (id: string) =>
        doRequest<PlainGenericResponse<RatingContractRequestBody>>(
            managerApiInstance.delete(`/assessment/${id}`)
        ),
    createContract: async (body: CreateContractData) =>
        doRequest<PlainGenericResponse<Contract>>(
            managerApiInstance.post(`/${path}/createContract`, body)
        ),
    createContractTerm: async (body: CreateContractTermData) =>
        doRequest<PlainGenericResponse<Contract>>(
            managerApiInstance.post(`/${path}/createContractTerm`, body)
        ),
    updateContract: async (body: UpdateContractData) =>
        doRequest<PlainGenericResponse<{ contract: Contract }>>(
            managerApiInstance.post(`/${path}/updateContract`, body)
        ),
    deleteContractFile: async (contractId: string) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/deleteContractFile`, {
                params: { contractId },
            })
        ),
    deleteContract: async (contractId: string) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.delete(`/${path}/${contractId}`)
        ),
    doSendContractToSignatures: async (contractId: string) =>
        doRequest<
            PlainGenericResponse<{
                documentSigners: DocumentSigners;
                contract: Contract;
                generatedDocument: GeneratedDocuments;
            }>
        >(
            managerApiInstance.post(`/${path}/doSendContractToSignatures`, {
                params: { contractId },
            })
        ),
    doSendContractToPncp: async (contractId: string) =>
        doRequest<PlainGenericResponse<{ pncpLink: string; status: ContractStatus }>>(
            managerApiInstance.post(`/${path}/doSendContractToPncp`, {
                params: { contractId },
            })
        ),
    doSendContractTermToPncp: async (contractId: string) =>
        doRequest<PlainGenericResponse<{ pncpLink: string; status: ContractStatus }>>(
            managerApiInstance.post(`/${path}/doSendContractTermToPncp`, {
                params: { contractId },
            })
        ),
    doGenerateContract: async (contractId: string) =>
        doRequest<PlainGenericResponse<{ path: string }>>(
            managerApiInstance.post(`/${path}/doGenerateContract`, {
                params: { contractId },
            })
        ),
    doReGenerateContract: async (contractId: string) =>
        doRequest<PlainGenericResponse<{ path: string }>>(
            managerApiInstance.post(`/${path}/doReGenerateContract`, {
                params: { contractId },
            })
        ),
    doBackToEditStep: async (contractId: string) =>
        doRequest<PlainGenericResponse<ContractWithDetails>>(
            managerApiInstance.post(`/${path}/doBackToEditStep`, {
                params: { contractId },
            })
        ),
    uploadContract: async (
        contractId: string,
        file: File,
        onUploadProgress: (progress: number) => void
    ) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);

        const response = await doRequest<
            PlainGenericResponse<{ location: string; createdAt: number; name: string }>
        >(
            managerApiUploadFileInstance.post(`${path}/uploadContract`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    contractId,
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percent = Math.floor((loaded * 100) / total);
                    onUploadProgress(percent);
                },
            })
        );

        return Promise.resolve({
            ...response,
            data: {
                ...response?.data,
                id: contractId,
            },
        });
    },
};

export { contractRequests };
