import { FC } from 'react';
import { Box, List, ListItem, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Scroll } from 'common/components/base/scroll';
import ProcessListGridCard from '../process-list-grid-card';
import { ProcessListGridColumnProps } from './props';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            backgroundColor: '#E2E4E6',
            borderRadius: '3px',
            minWidth: 210,
            width: '20%',
            minHeight: 400,

            '&:not(:last-child)': {
                marginRight: theme.spacing(1),
            },
        },
    })
);

const ContentScroll = styled(Scroll)`
    overflow-y: auto;
    height: calc(100vh - 317px);
`;

const ProcessListGridColumn: FC<ProcessListGridColumnProps> = ({ type, title, processes }) => {
    const classes = useStyles();

    return (
        <Box className={classes.card}>
            <Box
                p={1}
                pr={2}
                pl={2}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
            >
                <Typography noWrap variant='subtitle1'>
                    <b>{title}</b>
                </Typography>
            </Box>
            <List disablePadding>
                <ContentScroll>
                    {processes.map((process) => (
                        <ListItem key={process.id}>
                            <ProcessListGridCard process={process} type={type} />
                        </ListItem>
                    ))}
                </ContentScroll>
            </List>
        </Box>
    );
};

export default ProcessListGridColumn;
