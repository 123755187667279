import { Box, makeStyles } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { FC, useState } from 'react';
import { StepSignersProps } from './props';
import ModalSignersList from './modal-signers-view';
import SignerAvatarView from './signer-avatar-view';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiAvatar-root': {
            fontSize: '1rem',
            width: '32px',
            height: '32px',
        },
        '& .MuiAvatar-img': {
            borderRadius: '50%',
        },
    },
}));

const StepSigners: FC<StepSignersProps> = ({ signers }) => {
    const classes = useStyles();
    const [visibleSignersModal, setVisibleSignersModal] = useState(false);

    return (
        <>
            {visibleSignersModal && (
                <ModalSignersList signers={signers} onClose={() => setVisibleSignersModal(false)} />
            )}
            <Box>
                <AvatarGroup
                    classes={{ root: classes.root }}
                    max={5}
                    onClick={() => setVisibleSignersModal(true)}
                >
                    {signers?.map((signer) => (
                        <SignerAvatarView signer={signer} />
                    ))}
                </AvatarGroup>
            </Box>
        </>
    );
};

export default StepSigners;
