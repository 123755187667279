import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    expandedCard: {
        display: 'flex',
        flexDirection: 'column',
    },
    centeredCell: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    textField: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    tooltipIcon: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    searchButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    emailButton: {
        marginLeft: theme.spacing(2),
    },
    libraryDocumentsHeader: {
        marginLeft: '0.5rem',
        fontSize: '16px',
    },
    tableContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    gridContainer: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    descriptionIcon: {
        marginRight: theme.spacing(1),
    },
    alert: {
        border: '1px solid #349ff3',
        width: '100%',
        marginBottom: '15px',
    },
}));
