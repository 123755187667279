import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType, hasPermissionToExecute } from 'common/utils';
import NotFound from 'routes/not-found';
import { TypeAccess } from 'common/interfaces/user.interface';
import ProviderRegistrationUpdateRequests from './provider-registration-update-requests';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                exact
                path={`${match.path}/pedidos-atualizacao-cadastral`}
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.management]) &&
                    hasPermissionToExecute('verListaDePedidosDeAtualizacaoCadastral')
                }
                component={() => <ProviderRegistrationUpdateRequests />}
            />
            ,
            <PrivateRoute path='*' key='*' component={() => <NotFound />} />
        </Switch>
    );
};

const ManagementRoutes: JSX.Element[] = [
    <Route path='/administracao' key='/administracao' render={() => <ChildRoutes />} />,
];

export default ManagementRoutes;
