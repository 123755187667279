import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    sleep,
} from 'common/utils';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import LoadingButton from 'common/components/loading-button';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { WITHOUT_PERMISSION_TO_PUBLISH_AUCTION } from 'clients/manager/exceptions';
import { processUtils } from 'modules/process/process-utils';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { useProcessFormContext } from '../../context/process-form.context';
import { isValidProcessForm } from '../../context/process-render-errors';
import ModalPublicationErrors from './modal-publication-errors';
import { useProcessLotsFormContext } from '../../context/process-lots-form.context';
import ModalPermissionPublish from './modal-permission-publish';

const ButtonPublishProcess = () => {
    const [publishingProcess, setPublishingProcess] = useState(false);
    const [exceptionPublish, setExceptionPublish] = useState<string | undefined>();
    const [publicationErrors, setPublicationErrors] = useState<{ [key: string]: boolean }>();

    const {
        process,
        processForm,
        setProcessBkpBeforeSave,
        setProcess,
        publishDateError,
        accreditationDocumentsError,
    } = useProcessFormContext();
    const { processLotsForm, formHasChanges: formLotsHasChanges } = useProcessLotsFormContext();
    const { t } = useTranslation();

    const isProcessFormValid = isValidProcessForm(processForm);
    const disabledPublishProcess = !!processForm?.values.auctionPublishedDate;

    const handlePublishError = (error) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setPublishingProcess(false);
            if (error?.response?.data?.warnings) {
                setPublicationErrors(error?.response?.data?.warnings);
            } else if (error?.response?.data?.token === WITHOUT_PERMISSION_TO_PUBLISH_AUCTION) {
                setExceptionPublish(WITHOUT_PERMISSION_TO_PUBLISH_AUCTION);
            } else if (error?.response?.data?.token === 'PROPONENT_DIDNT_SEND_PROPOSAL') {
                addNotificationError({
                    title: t('error.proposal-didnt-sended'),
                    message: error?.response?.data?.message,
                });
                return;
            } else if (error?.response?.data?.token === 'PROCESS_INVITES_NOT_SENDED') {
                addNotificationError({
                    title: t('error.invites-didnt-sended'),
                    message: error?.response?.data?.message,
                });
                return;
            } else if (
                error?.response?.data?.token ===
                'CANNOT_PUBLISH_UNENFORCEABILITY_PROCESS_WITHOUT_LOT'
            ) {
                addNotificationError({
                    title: t('error.process-without-registered'),
                    message: error?.response?.data?.message,
                });
                return;
            }
            addNotificationApiError(error);
        });
    };

    const handleClickPublishProcess = async () => {
        if (!process) {
            return;
        }
        setPublishingProcess(true);
        await sleep(300);

        try {
            await auctionNoticeRequests.publishProcess(
                process.id,
                processUtils.isUnenforceabilityProcess(process)
                    ? processForm?.values.participantDocument
                    : undefined
            );

            const newValues: AuctionNotice = {
                ...(processForm?.values as AuctionNotice),
                biddingStageId: processUtils.isUnenforceabilityProcess(process)
                    ? BiddingStageEnum.decision
                    : BiddingStageEnum.published,
                auctionPurchaseYear: moment().year().toString(),
                sessionOpened: 0,
                auctionPublishedDate: moment().toDate().toString(),
            };
            setProcessBkpBeforeSave(newValues);
            processForm?.setValues(newValues);

            const response = await auctionNoticeRequests.getProcessFromOrganization({
                auctionId: Number(process.id),
            });
            setProcess(response.data);

            addNotificationSuccess({
                message: t('info.published-process'),
            });

            setPublishingProcess(false);
        } catch (error: any) {
            handlePublishError(error);
        }
    };

    if (disabledPublishProcess || !process) {
        return null;
    }

    return (
        <>
            {publicationErrors && (
                <ModalPublicationErrors
                    warnings={publicationErrors}
                    onClose={() => setPublicationErrors(undefined)}
                />
            )}
            {exceptionPublish === WITHOUT_PERMISSION_TO_PUBLISH_AUCTION && (
                <ModalPermissionPublish onClose={() => setExceptionPublish(undefined)} />
            )}
            <ConfirmDialog
                title={t('info.publish-process-title')}
                message={t('info.publish-process-description')}
                onConfirm={handleClickPublishProcess}
            >
                <LoadingButton
                    color='primary'
                    variant='contained'
                    disabled={
                        publishDateError ||
                        accreditationDocumentsError ||
                        !isProcessFormValid ||
                        formLotsHasChanges ||
                        !!Object.keys(processLotsForm.errors).length ||
                        !processLotsForm.values?.lots?.length
                    }
                    size='xsmall'
                    title={t('info.publish-process')}
                    {...(publishingProcess
                        ? {
                              loading: {
                                  text: `${t('term.publishing')}..`,
                              },
                          }
                        : {})}
                >
                    {t('term.puslish')}
                </LoadingButton>
            </ConfirmDialog>
        </>
    );
};

export default memo(ButtonPublishProcess);
