import { FC, memo } from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import _ from 'lodash';
import UploadFile from 'common/components/upload-file';
import HelpIconLabel from 'modules/process/components/help-icon-label';
import { ModalImportLotsAndItensProps } from './props';

const ModalImportLotsAndItens: FC<ModalImportLotsAndItensProps> = ({
    erpType,
    dropzoneProps,
    selectedFile,
    setSelectedFile,
    setAsync,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {erpType === 1 && (
                <span>
                    <a
                        title={t('process.components.model-plane')}
                        href='/assets/samples/modelo_importacao_lotes_itens.xls'
                        download
                        style={{ textDecoration: 'none' }}
                    >
                        {t('term.model')}
                    </a>{' '}
                    {t('process.components.msg-info-download-model')}
                </span>
            )}
            <Box display='flex' justifyContent='center' mt={2} mb={2}>
                <UploadFile {...dropzoneProps} />
            </Box>
            <Box display='flex' justifyContent='flex-start'>
                {selectedFile && (
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant='body2'>{selectedFile.name}</Typography>
                        <IconButton
                            title={t('term.delete-file')}
                            style={{ marginLeft: '8px' }}
                            size='small'
                            aria-label='delete'
                            onClick={() => {
                                setSelectedFile(undefined);
                            }}
                        >
                            <MdDelete />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <Box display='flex' alignItems='center' justifyContent='flex-start'>
                <HelpIconLabel title={t('process.export.components.async-help')}>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={t('process.import.components.async')}
                        onChange={(event: React.ChangeEvent<any>) => setAsync(event.target.checked)}
                    />
                </HelpIconLabel>
            </Box>
        </>
    );
};

export default memo(ModalImportLotsAndItens);
