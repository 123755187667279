import React from 'react';
import { Button, List, ListItem, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Not, formatCurrency } from 'common/utils';
import { Link } from 'react-router-dom';
import { useStyles } from './marketplace-summary-card-style';
import SummaryDeadlineInput from './components/summary-deadline-input';
import DiscountLabel from '../discount-label';

interface SummaryCardViewProps {
    title: string;
    handleCreateOrder?: () => any;
    onDeadlineChange: (value: string) => any;
    deadlineToSendProposal: string;
    initialDeadlineToSendProposal?: string;
    totalAverangeValue: number;
    totalEstimatedValue: number;
    totalDiscount: number;
    totalValue: number;
    hideBackToCartLink?: boolean;
    disableConfirmButton?: boolean;
    isJudged?: boolean;
    isConfirming?: boolean;
    confirmLabel?: string;
}

const MarketplaceSummaryCardView: React.FC<SummaryCardViewProps> = ({
    title,
    handleCreateOrder,
    onDeadlineChange,
    deadlineToSendProposal,
    initialDeadlineToSendProposal,
    totalAverangeValue,
    totalEstimatedValue,
    totalDiscount,
    totalValue,
    confirmLabel,
    isConfirming = false,
    isJudged = false,
    hideBackToCartLink = false,
    disableConfirmButton = false,
}) => {
    const { t } = useTranslation();

    const styles = useStyles();

    return (
        <Paper variant='outlined' className={styles.container}>
            <Typography variant='h6' className={styles.title}>
                {title}
            </Typography>
            <List disablePadding>
                <ListItem disableGutters className={styles.infoListItem}>
                    <Typography variant='body1'>
                        {t('term.marketplace-estimated-price-total')}
                    </Typography>
                    <Typography variant='body1' className={styles.infoPriceItem}>
                        {totalEstimatedValue ? formatCurrency(totalEstimatedValue) : '--'}
                    </Typography>
                </ListItem>
                <ListItem disableGutters className={styles.infoListItem}>
                    <Typography variant='body1'>
                        {isJudged
                            ? t('term.marketplace-best-price')
                            : t('term.marketplace-average-price')}
                    </Typography>
                    <Typography variant='body1' className={styles.infoPriceItem}>
                        {totalAverangeValue ? formatCurrency(totalAverangeValue) : '--'}
                    </Typography>
                </ListItem>
                <ListItem disableGutters className={styles.infoListItem}>
                    <Typography variant='body1'>
                        {totalAverangeValue > totalEstimatedValue
                            ? t('term.marketplace-estimated-overprice')
                            : t('term.marketplace-estimated-discount')}
                    </Typography>
                    <Typography variant='body1' className={styles.infoDiscountItem}>
                        {totalEstimatedValue > 0 && totalAverangeValue > 0 && (
                            <DiscountLabel
                                value={totalAverangeValue}
                                initialValue={totalEstimatedValue}
                                size='sm'
                            />
                        )}
                        {totalEstimatedValue > 0 && totalAverangeValue > 0
                            ? formatCurrency(Math.abs(totalDiscount))
                            : '--'}
                    </Typography>
                </ListItem>
            </List>
            <div className={styles.totalPriceContainer}>
                <Typography variant='h6' className={styles.infoTotalPrice}>
                    {t('term.total')}
                </Typography>
                <Typography variant='h6' className={styles.infoTotalPrice}>
                    {totalValue ? formatCurrency(totalValue) : '--'}
                </Typography>
            </div>
            <footer className={styles.footerContainer}>
                <SummaryDeadlineInput
                    initialDeadline={initialDeadlineToSendProposal}
                    deadline={deadlineToSendProposal}
                    onDeadlineChange={onDeadlineChange}
                />
                <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={isConfirming || disableConfirmButton}
                    onClick={handleCreateOrder}
                >
                    <Typography variant='button' color='textSecondary'>
                        {confirmLabel ?? t('term.marketplace-button-confirm-shopping')}
                    </Typography>
                </Button>
                {Not(hideBackToCartLink) && (
                    <Link to='/mercado' className={styles.footerLink}>
                        {t('term.marketplace-button-continue-shopping')}
                    </Link>
                )}
            </footer>
        </Paper>
    );
};

export default MarketplaceSummaryCardView;
