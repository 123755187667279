import { OrderItem, OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useHistory } from 'react-router';
import moment from 'moment';
import { OrderItemStatusEnum, OrderStatusEnum } from 'common/enums';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrderCartView from './order-card-view';

interface OrderCardProps {
    order: OrderResponse;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const redirectToJudgmentPage = () => {
        if (order.status === OrderStatusEnum.FINISHED) {
            return history.push(`/mercado/pedidos/empenho/${order.id}`);
        }
        return history.push(`/mercado/pedidos/julgamento/${order.id}`);
    };

    const calculateAveragePrice = (orderItem: OrderItem) => {
        return orderItem.item.prices.mid * (orderItem.itemAmount ?? 1);
    };
    const calculateEstimatedValue = (orderItem: OrderItem) => {
        return orderItem.item.prices.estimated * (orderItem.itemAmount ?? 1);
    };
    const calculateDiscount = (orderItem: OrderItem) => {
        return calculateAveragePrice(orderItem) - calculateEstimatedValue(orderItem);
    };

    const items = order?.items.filter((item) => item.status !== OrderItemStatusEnum.FRUSTRATED);

    const totalAverageValue =
        items?.reduce((acc, orderItem) => acc + calculateAveragePrice(orderItem), 0) ?? 0;
    const totalEstimatedValue =
        items?.reduce((acc, orderItem) => acc + calculateEstimatedValue(orderItem), 0) ?? 0;
    const totalDiscount =
        items?.reduce((acc, orderItem) => acc + calculateDiscount(orderItem), 0) ?? 0;
    const totalValue =
        items?.reduce((acc, orderItem) => acc + calculateAveragePrice(orderItem), 0) ?? 0;

    const isConfirmButtonDisabled =
        moment(order.deadlineToSendProposal).diff(moment(), 'seconds') > 0;

    const confirmButtonLabel = useMemo(() => {
        if (order.status === OrderStatusEnum.CANCELED) {
            return t('term.marketplace-button-see-judgment');
        }
        if (order.status === OrderStatusEnum.FINISHED) {
            return t('term.marketplace-button-see-contracts');
        }

        return t('term.marketplace-button-continue-shopping');
    }, [order]);

    return (
        <OrderCartView
            {...{
                order,
                totalEstimatedValue,
                totalAverageValue,
                totalValue,
                totalDiscount,
                redirectToJudgmentPage,
                confirmButtonLabel,
                isConfirmButtonDisabled,
            }}
        />
    );
};

export default OrderCard;
