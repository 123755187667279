import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles<Theme>((_theme) => ({
    container: {
        width: formatPxToRem(520),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${formatPxToRem(48)} !important`,
    },
    icon: {
        height: formatPxToRem(40),
        marginBottom: formatPxToRem(20),
        userSelect: 'none',
        pointerEvents: 'none',
    },
    title: {
        fontFamily: 'Bai Jamjuree',
        fontSize: formatPxToRem(24),
        fontWeight: 600,
        lineHeight: formatPxToRem(32),
        marginBottom: formatPxToRem(4),
        letterSpacing: 0,
        color: '#15151A',
    },
    subTitle: {
        fontFamily: 'Public Sans Variable',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: formatPxToRem(16),
        lineHeight: formatPxToRem(24),
        marginBottom: formatPxToRem(40),
        color: '#4E4E59',
    },
}));

export default useStyles;
