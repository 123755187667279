import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: 'white',
    },
    button: {
        marginTop: 20,
    },
}));
