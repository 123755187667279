import React, { useCallback, useEffect, useState } from 'react';
import { useIsMount } from 'common/hooks/use-is-mount.hook';
import QuantityCounterView from './quantity-counter-view';

interface QuantityCounterProps {
    initialQuantity?: number;
    max?: number;
    isRemoving?: boolean;
    onChange: (value: number) => any;
    onRemove: () => void;
}

const QuantityCounter: React.FC<QuantityCounterProps> = ({
    onChange,
    onRemove,
    max,
    isRemoving = false,
    initialQuantity,
}) => {
    const isFirstRender = useIsMount();

    const [quantity, setQuantity] = useState(initialQuantity ?? 0);

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = Number(event.target.value);

            if (Number.isInteger(value) && quantity !== 1 && value <= 0) {
                return setQuantity(1);
            }

            if (Number.isInteger(value) && value >= 1) {
                return setQuantity(value);
            }
        },
        [quantity]
    );

    const handleOnIncrease = useCallback(() => {
        setQuantity((state) => state + 1);
    }, [quantity]);

    const handleOnDecrease = useCallback(() => {
        if (quantity > 1) {
            setQuantity((state) => state - 1);
        }
    }, [quantity]);

    useEffect(() => {
        if (isFirstRender) return;

        const timeoutId = setTimeout(() => {
            if (quantity > 0) {
                onChange(quantity);
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [quantity]);

    return (
        <QuantityCounterView
            quantity={quantity}
            max={max}
            enableMax={max !== undefined}
            onRemove={onRemove}
            isRemoving={isRemoving}
            onChange={handleOnChange}
            onIncrease={handleOnIncrease}
            onDecrease={handleOnDecrease}
        />
    );
};

export default QuantityCounter;
