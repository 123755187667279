import { Grid, Typography } from '@material-ui/core';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import NextDisputes from './components/next-disputes';
import Shortcuts from './components/Shortcuts';

const CitizenDashboardView = ({ authUser }) => {
    const { t } = useTranslation();

    return (
        <>
            <PageWrapper>
                <Typography variant='h5'>
                    {t('term.hello')} {authUser?.name}!
                </Typography>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <NextDisputes />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Shortcuts />
                    </Grid>
                </Grid>
            </PageWrapper>
        </>
    );
};

export default CitizenDashboardView;
