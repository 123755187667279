import { FC, useEffect, useState } from 'react';
import { PriceList } from 'common/interfaces/price-list.interface';
import { orderBy } from 'lodash';
import { Card, CardContent, Box } from '@material-ui/core';
import { ModalPosition } from 'common/components';
import { useTranslation } from 'react-i18next';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import CreatePriceListForm from '../create-price-list-form';
import { ModalSelectPriceListProps } from './props';
import {
    Identifier,
    ListIcon,
    AddIcon,
    PriceListNewItem,
    ScrollContent,
    CustomModal,
    HeaderCreatePriceList,
    WrapperCreatePriceList,
    Content,
} from './styled';

const ModalSelectPriceList: FC<ModalSelectPriceListProps> = ({
    opened,
    onClose,
    onPriceListSelected,
}) => {
    const { t } = useTranslation();
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);
    const [creatingPriceList, setCreatingPriceList] = useState(false);

    const getPriceLists = async () => {
        const lists = await priceListRequests.getPriceLists();
        setPriceLists(lists);
    };

    useEffect(() => {
        getPriceLists();
    }, []);

    return (
        <CustomModal
            open={opened}
            header={
                <>
                    <span>{t('mkp.search.add-item-list')}</span>
                </>
            }
            position={ModalPosition.right}
            onClose={onClose}
            style={{
                width: 370,
                position: 'absolute',
                left: 'calc(100% - 370px)',
            }}
        >
            <ScrollContent>
                {!creatingPriceList && (
                    <PriceListNewItem
                        title={t('mkp.search.new-list')}
                        onClick={() => setCreatingPriceList(true)}
                    >
                        <AddIcon />
                    </PriceListNewItem>
                )}
                {creatingPriceList && (
                    <WrapperCreatePriceList>
                        <Card>
                            <HeaderCreatePriceList>
                                <span>{t('mkp.search.new-list-price')}</span>
                            </HeaderCreatePriceList>
                            <CreatePriceListForm
                                onCancel={() => setCreatingPriceList(false)}
                                onPriceListChanged={(priceList) => {
                                    if (!priceList) {
                                        return;
                                    }
                                    const existPriceList = priceLists.find(
                                        (currPriceList) => currPriceList._id === priceList._id
                                    );
                                    setCreatingPriceList(false);

                                    if (existPriceList) {
                                        return setPriceLists((prevState) => [
                                            ...prevState.map((currPriceList) => {
                                                if (currPriceList._id === priceList._id) {
                                                    return {
                                                        ...currPriceList,
                                                        ...priceList,
                                                    };
                                                }
                                                return currPriceList;
                                            }),
                                        ]);
                                    }
                                    setPriceLists((prevState) => [...prevState, priceList]);
                                }}
                            />
                        </Card>
                    </WrapperCreatePriceList>
                )}
                {orderBy(priceLists, 'createdAt', 'desc').map((priceList) => (
                    <Content
                        key={priceList._id}
                        title={`${t('mkp.search.add-at')} ${priceList.name}`}
                    >
                        <Box width={1}>
                            <Card
                                onClick={() => {
                                    onPriceListSelected(priceList);
                                }}
                            >
                                <CardContent
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <ListIcon />
                                    <Identifier>
                                        <span>{priceList.name}</span>
                                        <span>{priceList.description}</span>
                                    </Identifier>
                                </CardContent>
                            </Card>
                        </Box>
                    </Content>
                ))}
            </ScrollContent>
        </CustomModal>
    );
};

export default ModalSelectPriceList;
