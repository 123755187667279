import { FC } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18n from 'common/i18n';
import {
    MethodType,
    UserFinancialData,
} from 'clients/manager/interfaces/user-financial-data.interface';
import { Button } from 'common/components';
import { DeleteIcon } from 'common/icons';
import { formatCnpj } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import banks from './banks.json';
import { ConfirmDialog } from '../confirm-dialog';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';
import ModalProviderBankData from '../modal-provider-bank-data';

export interface ProviderBankDataProps {
    idProvider?: number;
    visibleModalFinancialData: boolean;
    page: number;
    rowsPerPage: number;
    rowsPerPageOptions: number[];
    financialData: UserFinancialData[];
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleClickDelete: (data: UserFinancialData) => Promise<void>;
    getData: () => Promise<void>;
    handleClickNew: () => void;
    setVisibleModalFinancialData: (visible: boolean) => void;
    disabled?: boolean;
}

const getMethodTypeLabel = (priority: MethodType) => {
    const labels: { [key in MethodType]: string } = {
        [MethodType.PIX]: i18n.t('term.pix-key'),
        [MethodType.BANK_ACCOUNT]: i18n.t('term.bank-account'),
    };

    return labels[priority];
};

const ProviderBankDataView: FC<ProviderBankDataProps> = ({
    idProvider,
    page,
    visibleModalFinancialData,
    rowsPerPageOptions,
    financialData,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClickDelete,
    getData,
    handleClickNew,
    setVisibleModalFinancialData,
    disabled = false,
}) => {
    const { t } = useTranslation();
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const formatData = (resource: UserFinancialData) => {
        if (resource.methodType === MethodType.BANK_ACCOUNT) {
            const bank = banks.find((bank) => bank.code === resource.bankCode);
            return `${resource.bankCode} - ${bank?.name || '--'} ${t('term.agency')} ${
                resource.accountAgency
            }, ${resource.accountType} ${resource.accountNumber}`;
        }

        return resource.pixValue;
    };

    return (
        <Box>
            {visibleModalFinancialData && (
                <ModalProviderBankData
                    onClose={() => {
                        setVisibleModalFinancialData(false);
                        getData();
                        if (authUserTokenData?.typeAccess !== TypeAccess.management)
                            setForceHasChanges(true);
                    }}
                    idProvider={idProvider}
                />
            )}
            <Box mb={1} width={1} display='flex' justifyContent='flex-end'>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => handleClickNew()}
                    disabled={disabled}
                >
                    {t('term.new')}
                </Button>
            </Box>
            {financialData.length ? (
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('term.type')}</TableCell>
                                <TableCell>{t('term.data')}</TableCell>
                                <TableCell>
                                    {t(
                                        'sign-up.pages.stepper-provider.steps.financial.docNumberReceiver'
                                    )}
                                </TableCell>
                                <TableCell width='40px' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {financialData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell component='th' scope='row'>
                                            {getMethodTypeLabel(item.methodType)}
                                        </TableCell>
                                        <TableCell>{formatData(item)}</TableCell>
                                        <TableCell colSpan={3}>
                                            {formatCnpj(item.docNumberReceiver || '')}
                                        </TableCell>
                                        <TableCell>
                                            <ConfirmDialog
                                                title={t('info.title-confirm-delete')}
                                                message={t('info.message-confirm-delete')}
                                                onConfirm={() => handleClickDelete(item)}
                                            >
                                                <IconButton disabled={disabled}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </ConfirmDialog>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box display='flex' justifyContent='center'>
                    <Typography variant='body2'>{t('info.no-financial-data')}</Typography>
                </Box>
            )}
            {financialData.length ? (
                <Box width={1} justifyContent='flex-end' display='flex'>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component='div'
                        count={financialData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={t('text.lines-per-page')}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default ProviderBankDataView;
