import { Skeleton } from '@licitar/ui-kit';
import React, { FC } from 'react';
import { SkeletonLine } from './styled';

const SkeletonLines: FC<{ size: number }> = React.memo(
    ({ size }) => (
        <>
            {new Array(size).fill(null).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonLine key={index}>
                    <Skeleton width='100%' height='24px' count={2} color='#e7e7e7' />
                </SkeletonLine>
            ))}
        </>
    ),
    () => true
);

export { SkeletonLines };
