import React from 'react';

import VerificationFieldView from './verification-field-view';

import './verification-field-style.css';

interface VerificationFieldProps {
    errorText?: string;
    loadingText?: string;
    hasError?: boolean;
    isLoading?: boolean;
    length?: number;
    value?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
}

const VerificationField: React.FC<VerificationFieldProps> = (props) => {
    return <VerificationFieldView {...props} />;
};

export default VerificationField;
