import { Box } from '@material-ui/core';
import { FC } from 'react';
import {
    getProcessFormErrorsLabels,
    isValidProcessForm,
} from '../../../../context/process-render-errors';
import { useProcessFormContext } from '../../../../context/process-form.context';
import ErrorStatus from '../../../error-status';

interface ProcessFormErrorsProps {
    partialProcessFormLabels: { [key: string]: string };
}

const ProcessFormErrors: FC<ProcessFormErrorsProps> = ({ partialProcessFormLabels }) => {
    const { processForm, publishDateError } = useProcessFormContext();
    const isValidForm = isValidProcessForm(processForm, partialProcessFormLabels);
    const isValid = partialProcessFormLabels?.startDateTimeDispute
        ? !publishDateError && isValidForm
        : isValidForm;

    return (
        <Box ml={1} display='flex' alignItems='center'>
            <ErrorStatus
                isValid={isValid}
                title={getProcessFormErrorsLabels(processForm, partialProcessFormLabels)}
            />
        </Box>
    );
};

export default ProcessFormErrors;
