import { createGlobalStyle } from 'styled-components';
import { BackOfficeTheme } from '.';

export default createGlobalStyle`

    :root{
        --primary-color: ${({ theme }) => theme.colors.primary};
        --secondary-color: ${({ theme }) => theme.colors.secondary};
        --tertiary-color: ${({ theme }) => theme.colors.tertiary};
        --background-color: ${({ theme }) => theme.colors.background};
        --background-page-default-color: ${({ theme }) => theme.colors.backgroundPageDefault};
        --text-color: ${({ theme }) => theme.colors.textDefault};
        --text-light-color: ${({ theme }) => theme.colors.textLight};
        --text-light-secondary-color: ${({ theme }) => theme.colors.textLight2};
        --text-dark-color: ${({ theme }) => theme.colors.textDark};
        --info-color: ${({ theme }) => theme.colors.info};
        --warning-color: ${({ theme }) => theme.colors.warning};
        --danger-color: ${({ theme }) => theme.colors.danger};
        --success-color: ${({ theme }) => theme.colors.success};
        --icon-color: ${({ theme }) => theme.colors.iconColorDefault};
        --border-color: ${({ theme }) => theme.colors.borderColorDefault};
        --background-hover-color: ${({ theme }) => theme.colors.backgroundHoverLight};
        --scrollbar-color: ${({ theme }) => theme.colors.scrollbar};
        --table-header-color: ${({ theme }) => theme.colors.tableHeader};

        --modal-shadow: ${({ theme }) => theme.colors.modalShadow};
    }

    body {
        background: ${({ theme }: { theme: BackOfficeTheme }) => theme.colors.background};
        margin: 0;
        font-family: 'Nunito', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1em;
        overflow: hidden;
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    html {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2;
        color: ${({ theme }) => theme.colors.textDefault};
    }

    * {
        font-family: 'Nunito', sans-serif;
    }

    // borda do modal de variáveis do tinyMCE
    .tox-autocompleter {
        box-shadow: 10px 10px 23px -14px rgba(0, 0, 0, 0.62) !important;
        -webkit-box-shadow: 10px 10px 23px -14px rgba(0, 0, 0, 0.62) !important;
        -moz-box-shadow: 10px 10px 23px -14px rgba(0, 0, 0, 0.62) !important;
    }

    .notifications-component {
        z-index: 999999999999 !important;
    }

    /** customização popover de variáveis tinymce */
    .autocompleter_cardtext_description {
        font-size: 14px !important;
        margin: 0 0 3px 0 !important;
        color: #333 !important;
    };
    
    .autocompleter_cardtext_name {
        color: #666 !important;
        margin: 0 0 0 3px !important;
        font-size: 13px !important;
    };
    /**---   */
`;
