import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            minWidth: '240px !important',
            width: '100%',
            display: 'flex',
            overflow: 'auto',

            '&::-webkit-scrollbar': {
                height: '0.8em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px #eaeaea',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#c4c4c4',
                borderRadius: '0.4em',
            },
        },
    })
);
