import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const LogoContainer = styled.div`
    height: 4rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        margin: 0;
        padding: 0;
        height: calc(100% - 16px);
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 0.5rem 0;
    margin-bottom: 1rem;

    padding: 0 3rem;

    hr {
        width: 40%;
    }
`;

export const RegisterLink = styled(Link)`
    color: #777777;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const SocialBtn = styled.div`
    cursor: pointer;
    border: 1px #777777 solid;
    border-radius: 50%;
    width: fit-content;
    display: flex;
    padding: 0.8rem;
    margin: 0 0.7rem;
`;

export const NewTypography = styled(Typography)`
    color: #777777;
`;

export const LoginLink = styled(Link)`
    color: var(--primary-color);
    text-decoration: underline;

    &:hover {
        opacity: 0.8;
    }
`;

export const LogoPaper = styled.div`
    height: 4rem;
    overflow: hidden;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    margin-bottom: 1rem;

    > img {
        margin: 0;
        padding: 0;
        height: calc(100% - 16px);
    }
`;
