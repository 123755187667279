import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import {
    GenericBody,
    GenericResponse,
    PlainGenericResponse,
} from 'clients/interfaces/paginated.interface';
import {
    CreateMarketplaceOrderBody,
    CreateMarketplaceOrderResponse,
    ListContractsByOrderIdBody,
    ListContractsByOrderIdResponse,
    OrderResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';

const BASE_URL = '/orders';

const ordersRequests = {
    listOrdersByOrganization: async (
        body?: GenericBody<{ orderId: number }>,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<OrderResponse>>(
            managerApiInstance.post(`${BASE_URL}/listOrdersByOrganization`, body, config)
        ),
    listContractsByOrderId: async (
        body?: ListContractsByOrderIdBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<ListContractsByOrderIdResponse>>(
            managerApiInstance.post(`${BASE_URL}/listContractsByOrderId`, body, config)
        ),
    doJudgeOrder: async (body: { orderId: number }, config?: AxiosRequestConfig<any>) =>
        doRequest<PlainGenericResponse<any>>(
            managerApiInstance.post(`${BASE_URL}/doJudgeOrder`, body, config)
        ),
    doDeclassifyProvider: async (
        body: { orderId: number; lotId: number; providerId: number },
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<OrderResponse>>(
            managerApiInstance.post(`${BASE_URL}/doDeclassifyProvider`, body, config)
        ),
    createMarketplaceOrder: async (
        body: CreateMarketplaceOrderBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<PlainGenericResponse<CreateMarketplaceOrderResponse>>(
            managerApiInstance.post(`${BASE_URL}/createOrder`, body, config)
        ),
    createContract: async (body: { orderId: number }, config?: AxiosRequestConfig<any>) =>
        doRequest<GenericResponse<any>>(
            managerApiInstance.post(`${BASE_URL}/createContract`, body, config)
        ),
};

export { ordersRequests };
