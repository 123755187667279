import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';

interface UseQueryProps<T> {
    axiosRequest: (config?: AxiosRequestConfig<any>) => Promise<T>;
    config?: {
        skip?: boolean;
    };
}

export interface UseQueryConfigOptions<TData = any> {
    skip?: boolean;
    onSuccess?: (data: TData) => void;
    onError?: (err: any) => void;
}

export function useQuery<T>(
    axiosRequest: UseQueryProps<T>['axiosRequest'],
    config?: UseQueryConfigOptions<T>
) {
    const [data, setData] = useState<T>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        const fetchAPI = async () => {
            try {
                setLoading(true);
                setData(undefined);
                const data = await axiosRequest({
                    signal: abortController.signal,
                });
                setData(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (!config?.skip) fetchAPI();
        return () => abortController.abort();
    }, []);

    return {
        data,
        setData,
        loading,
        error,
    };
}
