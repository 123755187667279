import { FC, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { processActions } from 'modules/process/process-actions';

interface CreatableTagsProps {
    label: string;
    onChange: (values: string[]) => void;
    value: string[] | any;
}

const CreatableTags: FC<CreatableTagsProps> = ({ label, onChange, value: initialValue }) => {
    const [values, setValues] = useState<string[] | any>(initialValue);
    const { process } = useProcessFormContext();
    const { t } = useTranslation();

    useEffect(() => {
        onChange(values);
    }, [values]);

    return (
        <Autocomplete
            value={values}
            fullWidth
            onChange={(_, newValues) => {
                setValues(
                    newValues?.map((item) => {
                        if (item && typeof item === 'object') {
                            return item.value;
                        }

                        return item;
                    })
                );
            }}
            filterOptions={(options, params) => {
                if (!params.inputValue) {
                    return [];
                }

                options.push({
                    value: params.inputValue,
                    title: `${t('term.add')} ${params.inputValue}`,
                });

                return options;
            }}
            clearOnBlur
            options={[]}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }

                if (option.value) {
                    return option.value;
                }

                return option.title;
            }}
            multiple
            renderOption={(option) => option.title}
            freeSolo
            renderInput={(params) => <TextField {...params} label={label} variant='outlined' />}
            disabled={processActions.cannotEditUntilDispute(process)}
        />
    );
};

export default CreatableTags;
