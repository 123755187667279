import { FC, ReactNode } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';

export const useStyles = makeStyles(() => ({
    root: {
        fontSize: '14px',
    },
    icon: {
        cursor: 'help',
        color: '#757575',
        fontSize: '13px',
        position: 'absolute',
        top: 2,
        right: 2,
        zIndex: 1,
    },
    iconCheckbox: {
        cursor: 'help',
        color: '#757575',
        fontSize: '13px',
        position: 'absolute',
        top: 0,
        left: 30,
        zIndex: 1,
    },
}));

interface HelpIconLabelProps {
    title: ReactNode | string;
    type?: 'checkbox' | 'default';
}

const HelpIconLabel: FC<HelpIconLabelProps> = ({ title, children, type }) => {
    const classes = useStyles();

    return (
        <div style={{ position: 'relative' }}>
            <Tooltip classes={{ tooltip: classes.root }} title={<p>{title}</p>}>
                <Help className={type === 'checkbox' ? classes.iconCheckbox : classes.icon} />
            </Tooltip>
            {children}
        </div>
    );
};

export default HelpIconLabel;
