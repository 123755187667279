import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { FC } from 'react';

const AddIcon: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z' />{' '}
    </SvgIcon>
);

export default AddIcon;
