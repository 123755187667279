import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const Container = styled.div`
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
    overflow: auto;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    min-height: calc(100vh - 120px);
    overflow: auto;
    margin: 0 auto;

    .MuiBreadcrumbs-ol {
        margin: 20px 0;
    }

    .MuiBreadcrumbs-separator {
        color: #777777;
    }

    button {
        margin: 20px 0;
        span {
            font-family: 'Roboto';
        }
    }

    .MuiFormHelperText-root.Mui-error {
        margin-bottom: 0;
    }

    .MuiFormHelperText-contained {
        margin-left: 0;
    }

    h5 {
        margin-bottom: 20px;
    }

    .content-steppers {
        background: #ffffff;
        padding: 25px;

        form {
            width: 100%;
        }
    }

    .MuiTableHead-root {
        border-bottom: 1px solid #dddddd;
    }

    .MuiTableCell-head {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .MuiTableCell-root {
        button {
            margin: 8px 0;
        }

        .MuiSvgIcon-root {
            font-size: 1.4rem;
        }
    }

    .stepper-nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .btn-upload {
        margin: 20px 0;
        span {
            font-family: 'Roboto';
        }
        input {
            display: none;
        }
    }

    .legend {
        padding: 10px;
        margin-left: 10px;
    }

    .doc-name {
        text-transform: initial;
        margin: 5px 0;
    }

    .sign-up-sucess {
        max-width: 585px;
        margin: 0 auto;
        text-align: center;

        h4 {
            margin-bottom: 20px;
        }

        .image-sucess {
            margin: 30px auto 10px auto;
            width: 100%;
            max-width: 345px;
        }
    }
`;

export const BoxItem = styled.div`
    padding: 20px 10px 0 10px;
    width: 210px;
    height: 250px;
    border 1px solid #DDDDDD;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    p {
        text-transform: uppercase;
        span {
            color: #E74C3C;
        }
    }
`;

export const BoxEmpty = styled.div`
    border 1px solid #DDDDDD;
    border-radius: 2px;
    display : flex;
    justify-content: center;
    padding: 8px;
`;

export const useStyles = makeStyles((theme) => ({
    stepper: {
        '& .MuiStep-completed': {
            '& span': {
                color: theme.palette.success.main,
            },
        },
    },
    step: {
        '& span': {
            color: theme.palette.text.primary,
        },
    },
    tableCell: {
        display: 'flex',
    },
}));
