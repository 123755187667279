import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';
import { useStyles } from './styles';

interface ChipProviderStatusProps {
    status: number | undefined;
}

interface ChipProviderProps {
    label: string;
    color: 'default' | 'secondary' | 'primary' | undefined;
}

const ChipProviderStatus: FC<ChipProviderStatusProps> = ({ status }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getLabel = useCallback((): ChipProviderProps => {
        switch (status) {
            case 1:
                return { label: t('term.approved'), color: 'secondary' };

            case -1:
                return { label: t('term.reproved'), color: 'default' };

            default:
                return { label: t('term.pending'), color: 'primary' };
        }
    }, [status]);

    const providerProps = getLabel();

    return (
        <Chip
            className={classes.chip}
            label={providerProps.label}
            color={providerProps.color}
            size='small'
            title={providerProps.label}
        />
    );
};

export default ChipProviderStatus;
