import { doRequest, managerApiInstance } from 'clients/api';
import { AxiosRequestConfig } from 'axios';
import {
    GetProcessEnabledProvidersBody,
    GetProcessEnabledProvidersResponse,
    ProcessParticipateActionsBody,
    ProcessParticipateActionsResponse,
} from './interfaces/auction-notice-participate.interface';

const BASE_URL = '/auction-notice-participate';

const auctionNoticeParticipate = {
    getProcessParticipationActions: async (body: ProcessParticipateActionsBody) =>
        doRequest<ProcessParticipateActionsResponse>(
            managerApiInstance.post(`${BASE_URL}/getProcessParticipationActions`, body)
        ),
    createParticipate: async (id: number | string) =>
        doRequest(
            managerApiInstance.post(`${BASE_URL}/createParticipate`, {
                params: { auctionId: Number(id) },
            })
        ),
    getProcessEnabledProviders: async (
        body?: GetProcessEnabledProvidersBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GetProcessEnabledProvidersResponse>(
            managerApiInstance.post(`${BASE_URL}/getProcessEnabledProviders`, body, config)
        ),
};

export { auctionNoticeParticipate };
