import React from 'react';
import { Typography } from '@material-ui/core';
import { FaChevronDown, FaChevronUp, FaMinus } from 'react-icons/fa';
import useStyles from './discount-label-style';

interface DiscountLabelViewProps {
    value: number;
    isPositive: boolean;
    size?: 'lg' | 'md' | 'sm';
}

const DiscountLabelView: React.FC<DiscountLabelViewProps> = ({
    isPositive,
    value,
    size = 'md',
}) => {
    const styles = useStyles({ size });

    if (value === 0) {
        return (
            <Typography variant='body2' className={styles.zeroValueLabel}>
                <FaMinus size={10} />
                {value}%
            </Typography>
        );
    }

    return (
        <Typography
            variant='body2'
            className={isPositive ? styles.positiveValueLabel : styles.negativeValueLabel}
        >
            {isPositive ? <FaChevronUp size={10} /> : <FaChevronDown size={10} />}
            {value}%
        </Typography>
    );
};

export default DiscountLabelView;
