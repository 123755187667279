import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './code-validator-actions-style';

interface CodeValidatorActionsViewProps {
    onClose?: () => void;
    onConfirm?: () => void;
    confirmButtonText?: string;
    closeButtonText?: string;
    disabled?: boolean;
}

const CodeValidatorActionsView: React.FC<CodeValidatorActionsViewProps> = ({
    onClose,
    onConfirm,
    confirmButtonText,
    closeButtonText,
    disabled = false,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <footer className={styles.container}>
            <Button className={styles.cancelButton} onClick={onClose}>
                <Typography className={styles.buttonText}>
                    {closeButtonText ?? t('code-validator.action-button.cancel')}
                </Typography>
            </Button>
            <div className={styles.divider} />
            <Button
                type='button'
                className={styles.confirmButton}
                onClick={onConfirm}
                disabled={disabled}
            >
                <Typography className={styles.buttonText}>
                    {confirmButtonText ?? t('term.continue')}
                </Typography>
            </Button>
        </footer>
    );
};

export default CodeValidatorActionsView;
