import { styled } from 'common/theme/styled';

const ReplacerPage = styled.div`
    width: 793px;
    max-width: 793px;
    font-family: Arial, Helvetica, sans-serif !important;
    outline: none;
    flex: 1;
    cursor: text;
    overflow: hidden;
    padding: 16px;
    border: 1px #ccc solid;
    min-height: 1123px;
    background: #fff;
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.textDefault};
    word-break: break-word;

    .variable {
        color: #2866d8;
        cursor: pointer;
    }

    .replaced-variable {
        color: #316fe2 !important;
    }

    table {
        border-collapse: collapse;

        td {
            border: 1px #ccc solid;
            padding: 0.4rem;
        }
    }
`;

const EditArea = styled.div<{ visible: boolean }>`
    display: flex;
    justify-content: center;

    ${(props) => !props.visible && `display: none;`}
`;

const SkeletonLine = styled.div`
    width: 100%;
`;

export { EditArea, ReplacerPage, SkeletonLine };
