import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    Category,
    ListMarketplaceProcessResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { useStyles } from './marketplace-filters-style';
import FilterItem from './components/filter-item';

interface MarketplaceViewProps {
    processes: ListMarketplaceProcessResponse[];
    categories: Category[];
    loading?: boolean;
    totalItemsFound?: number;
    totalSelectedFilters?: number;
    isFilterSelected: (type: 'process' | 'category', id: number) => boolean;
    onSelectFilter: (type: 'process' | 'category', id: number) => void;
}

const MarketplaceFiltersView: React.FC<MarketplaceViewProps> = ({
    processes,
    categories,
    loading = false,
    totalItemsFound = 0,
    totalSelectedFilters = 0,
    isFilterSelected,
    onSelectFilter,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Typography variant='h6' className={styles.filterItemHeader}>
                    {t('term.marketplace-filter-filters')}
                </Typography>
                <Typography className={styles.filterItemTop}>
                    {totalItemsFound} {t('term.results')}
                </Typography>
                <Typography className={styles.filterItemTop}>
                    {totalSelectedFilters} {t('term.activate-filters')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h6' className={styles.filterItemHeader}>
                    {t('term.marketplace-filter-process')}
                </Typography>
                <section className={styles.filterProcessContainer}>
                    <FilterItem
                        loading={loading}
                        items={processes}
                        isFilterSelected={isFilterSelected}
                        onSelectFilter={onSelectFilter}
                        type='process'
                    />
                </section>
            </Grid>
            <Grid item>
                <Typography variant='h6' className={styles.filterItemHeader}>
                    {t('term.marketplace-filter-categories')}
                </Typography>
                <section className={styles.filterProcessCategories}>
                    <FilterItem
                        loading={loading}
                        items={categories}
                        isFilterSelected={isFilterSelected}
                        onSelectFilter={onSelectFilter}
                        type='category'
                    />
                </section>
            </Grid>
        </Grid>
    );
};

export default MarketplaceFiltersView;
