import { getMd5Hash } from 'common/utils';

export const getProccessDocumentRedirectUrl = (
    backofficeRedirectUrl: string,
    auctionId: number,
    auctionType: string
): string => {
    let path: string;

    switch (auctionType) {
        case 'E':
            path = 'processos';
            break;

        case 'D':
            path = 'processos';
            break;

        case 'C':
            path = 'credenciamento';
            break;

        default:
            path = 'painel';
            break;
    }

    const auctionHash = getMd5Hash(String(auctionId));
    const urlEncode = encodeURIComponent(`${path}/?ver&idE=${auctionHash}`);

    if (!auctionHash) {
        return `${backofficeRedirectUrl}${path}`;
    }

    return `${backofficeRedirectUrl}${urlEncode}`;
};
