import { IconBaseProps } from 'react-icons';
import { IoWallet } from 'react-icons/io5';
import {
    MdAssignment,
    MdBlock,
    MdLibraryAddCheck,
    MdPauseCircle,
    MdPlayCircleFilled,
    MdQuestionAnswer,
} from 'react-icons/md';

interface ProcessStatusIconProps extends IconBaseProps {
    icon:
        | 'decision'
        | 'contract'
        | 'dispute'
        | 'session'
        | 'finished'
        | 'published'
        | 'fail'
        | 'excluded'
        | 'canceled'
        | 'revoked'
        | 'annulled'
        | 'suspended';
}

const ProcessStatusIcon = ({ icon, ...props }: ProcessStatusIconProps) => (
    <>
        {icon === 'decision' && <MdQuestionAnswer {...props} />}
        {icon === 'contract' && <MdAssignment {...props} />}
        {icon === 'dispute' && <MdPlayCircleFilled {...props} />}
        {icon === 'session' && <MdPlayCircleFilled {...props} />}
        {icon === 'finished' && <MdLibraryAddCheck {...props} />}
        {icon === 'fail' && <MdBlock {...props} />}
        {icon === 'excluded' && <MdBlock {...props} />}
        {icon === 'canceled' && <MdBlock {...props} />}
        {icon === 'revoked' && <MdBlock {...props} />}
        {icon === 'annulled' && <MdBlock {...props} />}
        {icon === 'suspended' && <MdPauseCircle {...props} />}
        {icon === undefined && <MdBlock {...props} />}
        {['published'].includes(icon) && <IoWallet {...props} />}
    </>
);

export default ProcessStatusIcon;
