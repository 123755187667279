interface OutBorderRadiusProps {
    size?: number;
    className?: string;
}

const OutBorderRadius = ({ size = 16, className }: OutBorderRadiusProps) => (
    <svg width={size} height={size} viewBox='0 0 1 1' {...{ className }}>
        <defs>
            <clipPath id='_clipPath_RzbIqd73Axo4SuMXmCeyWYf3Nm2IXSYQ'>
                <path d='M0 0H1V1H0z' />
            </clipPath>
        </defs>
        <g clipPath='url(#_clipPath_RzbIqd73Axo4SuMXmCeyWYf3Nm2IXSYQ)'>
            <path fill='#FFF' fillRule='evenodd' d='M0 0h1v1a1 1 0 00-1-1z' />
        </g>
    </svg>
);

export default OutBorderRadius;
