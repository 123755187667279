import { FC, useEffect, useRef, useState } from 'react';
import { Skeleton } from '@licitar/ui-kit';
import { CustomVariable } from 'modules/editor/interfaces/variables.interface';
import { VARIABLE_TRIGGER } from 'modules/editor/components/editor';
import { useTranslation } from 'react-i18next';
import { addNotificationWarning } from 'common/utils';
import { EditArea, ReplacerPage, SkeletonLine } from './styles';
import { DocumentVariableReplacerProps } from './props';
import ModalSelectVariableValue from '../modal-select-variable-value';

const SkeletonLoader: FC<any> = () => (
    <>
        {new Array(5).fill(null).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SkeletonLine key={index}>
                <Skeleton width='100%' height='24px' count={5} color='#e7e7e7' />
            </SkeletonLine>
        ))}
    </>
);

const DocumentVariableReplacer: FC<DocumentVariableReplacerProps> = ({
    html,
    visible,
    loading,
    variables,
    onChange,
}) => {
    const replacerRef: any = useRef(null);
    const [variableSelected, setVariableSelected] = useState<
        | {
              variable: CustomVariable;
              element: Element;
          }
        | undefined
    >();

    const { t } = useTranslation();

    const handleVariableElementClick = (element: Element) => {
        const id = element.getAttribute('data-variable-id');
        if (!id) {
            return;
        }

        const variable = variables.customs.find((v) => v.id === id);
        if (!variable) {
            return;
        }
        return setVariableSelected({ variable, element });
    };

    const createVariablesListener = () => {
        const templateVariables: Element[] =
            replacerRef.current?.getElementsByClassName('variable');

        if (!templateVariables?.length) {
            return;
        }

        Array.from(templateVariables).forEach((element) => {
            element.addEventListener('click', () => handleVariableElementClick(element));
        });
    };

    useEffect(() => {
        if (html) {
            replacerRef.current.innerHTML = html;
            createVariablesListener();
        }
    }, [html]);

    const replaceChild = (text: string) => {
        if (!variableSelected) {
            return;
        }

        try {
            const { element, variable } = variableSelected;

            const replacedElement = document.createElement('span');
            replacedElement.setAttribute(
                'data-replaced-custom-variable',
                `${VARIABLE_TRIGGER}${variable.name}`
            );

            const content = document.createTextNode(text);
            replacedElement.appendChild(content);

            element?.parentNode?.replaceChild(replacedElement, element);
            // assim que trocar o valor de alguma variável já atualiza state compartilhado
            onChange(replacerRef.current.innerHTML);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.process.components.doc-var-replacer.notification-error-variable'
                ),
            });
        }
    };

    return (
        <>
            {variableSelected && (
                <ModalSelectVariableValue
                    variableName={variableSelected.variable.name}
                    onClose={() => setVariableSelected(undefined)}
                    variableId={variableSelected.variable.id}
                    setText={(text: string) => {
                        replaceChild(text);
                        setVariableSelected(undefined);
                    }}
                />
            )}
            <EditArea visible={visible}>
                <ReplacerPage ref={replacerRef}>
                    {loading && !html ? <SkeletonLoader /> : null}
                </ReplacerPage>
            </EditArea>
        </>
    );
};

export default DocumentVariableReplacer;
