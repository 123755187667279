import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    finished: {
        fontWeight: 600,
        color: theme.palette.success.main,
    },
    running: {
        fontWeight: 600,
        color: theme.palette.warning.main,
    },
}));
