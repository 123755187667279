import { Box, Typography } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import documentTypeProviderRequests from 'clients/manager/document-type-provider.request';
import { useParams } from 'react-router';
import {
    DocumentType,
    DocumentsAndCategoryFormated,
} from '../../../../interfaces/document-type-provider.interface';
import DocumentsGroupProps from '../documents-group';

interface BasicDocumentsProps {
    disabled?: boolean;
}

const BasicDocuments: React.FC<BasicDocumentsProps> = ({ disabled = false }) => {
    const [groupedDocuments, setGroupedDocuments] = useState<{
        [key: number]: DocumentsAndCategoryFormated[];
    }>({});

    const { t } = useTranslation();
    const { idProvider } = useParams<{ idProvider: string }>();

    useEffect(() => {
        const request = async () => {
            try {
                const { data } = await documentTypeProviderRequests.listDocumentTypesProvider(
                    DocumentType.basic,
                    Number(idProvider)
                );

                const { documents = [] } = data;

                ReactDOM.unstable_batchedUpdates(() => {
                    setGroupedDocuments(
                        (documents || []).reduce((obj, doc) => {
                            if (!obj[doc.categoryDocument.id]) {
                                obj[doc.categoryDocument.id] = [];
                            }

                            obj[doc.categoryDocument.id].push(doc);
                            return obj;
                        }, {})
                    );
                });
                // eslint-disable-next-line no-empty
            } catch (error) {}
        };

        request();
    }, []);

    return (
        <Box>
            <Typography variant='h5' gutterBottom>
                {t('info.basic-register')}
            </Typography>
            {Object.keys(groupedDocuments || {}).map((categoryId) => {
                const { categoryDocument } = groupedDocuments[categoryId][0];

                return (
                    <DocumentsGroupProps
                        disabled={disabled}
                        categoryName={categoryDocument.name}
                        categoryDescription={categoryDocument.description}
                        documents={groupedDocuments[categoryId] || []}
                    />
                );
            })}
        </Box>
    );
};

export default memo(BasicDocuments);
