import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { disableField } from 'modules/process/process-utils';
import { TextField } from 'common/components';
import HelpIconLabel from '../../../help-icon-label';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface TypeValueBidFieldViewProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    typeValueBid: ReactNode[];
}

const TypeValueBidFieldView: FC<TypeValueBidFieldViewProps> = ({ handleChange, typeValueBid }) => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    return (
        <HelpIconLabel
            title={
                <>
                    <p>
                        <b>{t('term.unitary')}: </b>
                        {t('info.unitary-field')}
                    </p>
                    <p>
                        <b>{t('term.global')}: </b>
                        {t('info.global-field')}
                    </p>
                </>
            }
        >
            <TextField
                fullWidth
                color='primary'
                label={t('term.bid-format')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                variant='outlined'
                name='typeValueBid'
                select
                value={processForm?.values.typeValueBid}
                defaultValue={processForm?.values.typeValueBid}
                disabled={disableField('typeValueBid', process)}
            >
                {typeValueBid}
            </TextField>
        </HelpIconLabel>
    );
};

export default TypeValueBidFieldView;
