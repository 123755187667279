import {
    Box,
    Button,
    Grid,
    IconButton,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from 'common/icons';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { CurrencyInput } from 'common/components/currency';
import { getDecimalPlaces } from 'modules/process/process-utils';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { MultipleWinnersProps } from './props';
import { useStyles } from './styles';

const MultipleWinners: FC<MultipleWinnersProps> = ({ lot }) => {
    const classes = useStyles();
    const [multipleWinners, setMultipleWinners] = useState(lot.multipleWinners || []);
    const { processForm } = useProcessFormContext();
    const { setFieldValue } = useProcessLotsFormContext();
    const { t } = useTranslation();

    const handleAddPosition = () => {
        setMultipleWinners([
            ...multipleWinners,
            { position: multipleWinners.length + 1, percent: '0' },
        ]);
    };

    const updatePositions = (winners: { position: number; percent: string }[]) => {
        const updatedWinners = winners.map((winner, index) => ({
            ...winner,
            position: index + 1,
        }));

        setMultipleWinners(updatedWinners);
    };

    const handleDeletePosition = (position: number): void => {
        setMultipleWinners((prevWinners) => {
            const updatedWinners = prevWinners.filter((winner) => winner.position !== position);
            updatePositions(updatedWinners);
            return updatedWinners;
        });
    };

    useEffect(() => {
        setFieldValue(lot, 'multipleWinners', multipleWinners);
    }, [multipleWinners]);

    return (
        <Box width={1}>
            <h3>{t('process.components.multiple-winners')}</h3>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('term.position')}</TableCell>
                            <TableCell>{`% ${t('term.of-lot')}`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {multipleWinners.map((row) => (
                            <TableRow>
                                <TableCell key={row.position} align='left'>
                                    <Input
                                        disabled
                                        className={classes.textArea}
                                        fullWidth
                                        color='primary'
                                        defaultValue={row.position}
                                        multiline={false}
                                        inputProps={{ style: { textAlign: 'left' } }}
                                    />
                                </TableCell>
                                <TableCell key={row.percent} align='left'>
                                    <CurrencyInput
                                        disabled={false}
                                        className={classes.input}
                                        allowNegative={false}
                                        value={row.percent}
                                        prefix=''
                                        decimalSeparator=','
                                        precision={getDecimalPlaces(
                                            processForm?.values?.decimalPlaces ?? 2
                                        )}
                                        thousandSeparator='.'
                                        onChange={(ev) => {
                                            setMultipleWinners((prevWinners) => {
                                                const index = prevWinners.findIndex(
                                                    (elemento) => elemento.position === row.position
                                                );
                                                if (index !== -1) {
                                                    prevWinners[index].percent = ev;
                                                    return prevWinners;
                                                }
                                                return prevWinners;
                                            });
                                        }}
                                        inputProps={{ style: { textAlign: 'left' } }}
                                        component={Input}
                                    />
                                </TableCell>
                                <TableCell align='center' padding='none' style={{ width: 120 }}>
                                    <Grid>
                                        <IconButton
                                            onClick={() => handleDeletePosition(row.position)}
                                        >
                                            <Tooltip title={t('term.remove')}>
                                                <DeleteIcon fontSize='small' />
                                            </Tooltip>
                                        </IconButton>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box pt={2} pb={2} display='flex' justifyContent='end'>
                <Button
                    variant='outlined'
                    color='primary'
                    title={`${t('term.add')} ${t('term.position')}`}
                    onClick={handleAddPosition}
                    size='small'
                >
                    {`${t('term.add')} ${t('term.position')}`}
                </Button>
            </Box>
        </Box>
    );
};

export default MultipleWinners;
