import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    frame: {
        width: '100%',
        minHeight: '65vh',
        border: 'none',
    },
    unsupportedContainer: {
        textAlign: 'center',
    },
    loadingContainer: {
        width: '100%',
    },
}));

export default useStyles;
