import { FC } from 'react';
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Button } from 'common/components';
import { AddIcon } from 'common/icons';
import { OrganizationMinutesViewProps } from './props';

const OrganizationMinutesView: FC<OrganizationMinutesViewProps> = ({
    organizationMinutes,
    fetching,
    deleteOrganizationMinute,
    handleCreateMinute,
    t,
}) => {
    return (
        <>
            <TableContainer component={Paper} variant='outlined'>
                <Table size='small' aria-label='a dense table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('term.name')}</TableCell>
                            <TableCell align='right'>{t('term.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizationMinutes.length ? (
                            organizationMinutes.map(({ documentTemplate, organizationMinute }) => (
                                <TableRow key={organizationMinute.documentTemplateId}>
                                    <TableCell component='th' scope='row'>
                                        {documentTemplate && documentTemplate.name}
                                    </TableCell>
                                    <TableCell align='right'>
                                        <IconButton
                                            aria-label='deletar'
                                            size='small'
                                            onClick={() =>
                                                deleteOrganizationMinute(
                                                    organizationMinute.documentTemplateId
                                                )
                                            }
                                        >
                                            <Delete fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <>
                                {fetching &&
                                    new Array(5).fill(0).map((_, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRow key={index}>
                                            <TableCell component='th' scope='row'>
                                                <Skeleton width='100%' />
                                            </TableCell>
                                            <TableCell align='right'>
                                                <Skeleton width='100%' />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container item md={12} justifyContent='flex-end'>
                <Box mt={2}>
                    <Button
                        className='btn-add'
                        size='small'
                        variant='outlined'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={() => handleCreateMinute()}
                    >
                        {t('term.add')}
                    </Button>
                </Box>
            </Grid>
        </>
    );
};

export default OrganizationMinutesView;
