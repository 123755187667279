import { usePageContext } from 'common/components/base/pages/context';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchMaterialView from './SearchMaterialView';

interface SearchMaterialProps {}

const SearchMaterial: React.FC<SearchMaterialProps> = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const [modalCatalogsOpen, setModalCatalogsOpen] = useState(false);

    useEffect(() => {
        setTitle(t('material.pages.title'));
    }, []);

    return <SearchMaterialView {...{ modalCatalogsOpen, setModalCatalogsOpen }} />;
};

export default SearchMaterial;
