import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    creditCardTitle: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    creditCardImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxButton: {
        display: 'flex',
        marginTop: theme.spacing(3),
        justifyContent: 'flex-end',
    },
}));

export default useStyles;
