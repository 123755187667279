import { FC, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { processStageToBiddingStageId } from 'common/utils';
import { ListProcessesGridProps } from './props';
import ProcessListGridColumn from '../process-list-grid-column';
import { ProcessStage } from '../../../../clients/manager/interfaces/auction-notice.interface';
import { useStyles } from './styles';

const ListProcessesGrid: FC<ListProcessesGridProps> = ({ processes }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getProcessesByBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processes.filter((process) => biddingStage.includes(process.biddingStageId)),
        [processes]
    );

    const internalProcesses = getProcessesByBiddingStage(
        processStageToBiddingStageId(ProcessStage.internal)
    );
    const proposalsProcesses = getProcessesByBiddingStage(
        processStageToBiddingStageId(ProcessStage.proposals)
    );
    const disputeProcesses = getProcessesByBiddingStage(
        processStageToBiddingStageId(ProcessStage.dispute)
    );
    const decisionProcesses = getProcessesByBiddingStage(
        processStageToBiddingStageId(ProcessStage.decision)
    );
    const contractProcesses = getProcessesByBiddingStage(
        processStageToBiddingStageId(ProcessStage.contract)
    );

    return (
        <Box p={2} pt={1} className={classes.root}>
            <ProcessListGridColumn
                type={ProcessStage.internal}
                title={t('process.components.internal-phase')}
                processes={internalProcesses}
            />
            <ProcessListGridColumn
                type={ProcessStage.proposals}
                title={t('process.components.receiving-proposals')}
                processes={proposalsProcesses}
            />
            <ProcessListGridColumn
                type={ProcessStage.dispute}
                title={t('term.dispute')}
                processes={disputeProcesses}
            />
            <ProcessListGridColumn
                type={ProcessStage.decision}
                title={t('term.decision')}
                processes={decisionProcesses}
            />
            <ProcessListGridColumn
                type={ProcessStage.contract}
                title={t('term.contract')}
                processes={contractProcesses}
            />
        </Box>
    );
};

export default ListProcessesGrid;
