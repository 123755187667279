import { Box, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import LoadingButton from 'common/components/loading-button';
import { useTranslation } from 'react-i18next';

interface ExportIntegrationCardViewProps {
    title: string;
    description?: string;
    onSubmit: () => Promise<void>;
    loading?: boolean;
}

const ExportIntegrationCardView: FC<ExportIntegrationCardViewProps> = ({
    title,
    description,
    onSubmit,
    loading,
}) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <Box mb={2}>
                <Typography variant='body1'>
                    <strong>{title}</strong>
                </Typography>
                <Typography variant='body2'>{description}</Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LoadingButton
                        color='secondary'
                        size='small'
                        variant='contained'
                        onClick={onSubmit}
                        loading={
                            loading
                                ? { text: t('process.components.process-exporting-process') }
                                : undefined
                        }
                        disabled={loading}
                    >
                        {t('process.components.process-export-process')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ExportIntegrationCardView;
