import PrivateRoute from 'routes/components/private-route';
import SearchProviderUsers from './pages/search-provider-users';
import { SearchProviderUsersContextProvider } from './pages/search-provider-users/context/SearchProviderUsersContext';

const GroupAuthorizationRoutes: JSX.Element[] = [
    <PrivateRoute
        exact
        path='/usuarios-fornecedor'
        key='/usuarios-fornecedor'
        component={() => (
            <SearchProviderUsersContextProvider>
                <SearchProviderUsers />
            </SearchProviderUsersContextProvider>
        )}
    />,
];

export default GroupAuthorizationRoutes;
