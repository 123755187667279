import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { debounce } from 'lodash';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import moment from 'moment';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'common/utils';
import { disableField, inInternalPhase } from 'modules/process/process-utils';

const AccreditationDocumentsDate = () => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();
    const now = new Date();

    const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | null>(
        processForm?.values.auctionEndDate ? moment(processForm?.values.auctionEndDate) : null
    );

    const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment | null>(
        processForm?.values.auctionStartDate ? moment(processForm?.values.auctionStartDate) : null
    );

    const disableValidation = !!processForm?.values.auctionPublishedDate;

    const onStartDateChanged = debounce((date: MaterialUiPickersDate) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setSelectedStartDate(date);

            if (date?.isValid()) {
                processForm?.setFieldValue(
                    'auctionStartDate',
                    moment(date)
                        .set({
                            hours: 0,
                            minutes: 0,
                            seconds: 0,
                        })
                        .toISOString()
                );
            } else {
                processForm?.setFieldValue('auctionStartDate', null);
            }
        });
    }, 200);

    const onEndDateChanged = debounce((date: MaterialUiPickersDate) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setSelectedEndDate(date);

            if (date?.isValid()) {
                processForm?.setFieldValue(
                    'auctionEndDate',
                    moment(date)
                        .set({
                            hours: 23,
                            minutes: 59,
                            seconds: 59,
                        })
                        .toISOString()
                );
            } else {
                processForm?.setFieldValue('auctionEndDate', null);
            }
        });
    }, 200);

    const auctionStartDate = processForm?.values?.auctionStartDate
        ? new Date(processForm.values.auctionStartDate)
        : now;
    const minEndDate = moment(auctionStartDate).add(1, 'day');
    return (
        <>
            <Grid item xs={12} lg={3} md={3}>
                <KeyboardDatePicker
                    fullWidth
                    color='primary'
                    inputVariant='outlined'
                    label={t('info.accreditation-start-date')}
                    minDate={now}
                    value={selectedStartDate}
                    format='DD/MM/YYYY'
                    onChange={onStartDateChanged}
                    okLabel={t('term.confirm')}
                    cancelLabel={t('term.cancel')}
                    invalidDateMessage={t('info.invalid-format', { ns: 'validation' })}
                    invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                    minDateMessage={
                        !inInternalPhase(process?.biddingStageId) || disableValidation
                            ? null
                            : t('info.min-date', {
                                  value: formatDate(now, 'DD/MM/YYYY'),
                                  ns: 'validation',
                              })
                    }
                    disabled={disableField('auctionStartDate', process)}
                />
            </Grid>
            <Grid item xs={12} lg={3} md={3}>
                <KeyboardDatePicker
                    fullWidth
                    color='primary'
                    inputVariant='outlined'
                    minDate={minEndDate}
                    label={t('info.accreditation-end-date')}
                    value={selectedEndDate}
                    format='DD/MM/YYYY'
                    onChange={onEndDateChanged}
                    okLabel={t('term.confirm')}
                    cancelLabel={t('term.cancel')}
                    invalidDateMessage={t('info.invalid-format', { ns: 'validation' })}
                    invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                    minDateMessage={t('info.min-date', {
                        value: formatDate(minEndDate, 'DD/MM/YYYY'),
                        ns: 'validation',
                    })}
                    disabled={disableField('auctionEndDate', process)}
                />
            </Grid>
        </>
    );
};

export default memo(AccreditationDocumentsDate);
