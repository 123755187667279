import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles(() => ({
    container: {
        padding: '1rem',
    },
    title: {
        fontSize: formatPxToRem(18),
        fontWeight: 500,
        marginBottom: formatPxToRem(10),
    },
    infoListItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${formatPxToRem(5)} 0 ${formatPxToRem(5)} 0`,
    },
    infoPriceItem: {
        fontWeight: 600,
    },
    infoDiscountItem: {
        fontWeight: 600,
        gap: formatPxToRem(5),
        display: 'flex',
    },
    totalPriceContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${formatPxToRem(20)} 0 ${formatPxToRem(15)} 0`,
    },
    infoTotalPrice: {
        fontWeight: 700,
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: formatPxToRem(10),
    },
    footerLink: {
        textAlign: 'center',
    },
}));

export { useStyles };
