import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'common/components/loading-button';
import { ButtonSendInviteViewProps } from './interfaces/button-send-invite.interface';

const ButtonSendInviteView: React.FC<ButtonSendInviteViewProps> = ({
    onClick,
    disabled,
    sendingInvite,
    buttonText,
}) => {
    const { t } = useTranslation();
    return (
        <LoadingButton
            color='primary'
            variant='contained'
            disabled={disabled || sendingInvite}
            size='xsmall'
            title={buttonText}
            onClick={onClick}
            {...(sendingInvite
                ? {
                      loading: {
                          text: `${t('term.sending')}..`,
                      },
                  }
                : {})}
        >
            {buttonText}
        </LoadingButton>
    );
};

export default ButtonSendInviteView;
