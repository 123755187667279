import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Card, Typography, Grid } from '@material-ui/core';
import { AiFillFire, AiFillHeart, AiOutlineDollar } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { StyledAvatar } from './provider-summary-style';

interface ListItemSecondaryTextProps {
    text?: string;
    loading?: boolean;
}

const ListItemSecondaryText = ({ text, loading }: ListItemSecondaryTextProps) => (
    <Typography variant='h5'>
        {loading ? (
            <Skeleton width={40} height={40} variant='text' />
        ) : (
            <strong>{text || '0'}</strong>
        )}
    </Typography>
);

const ProviderSummaryView = ({ summary, loading }) => (
    <>
        <Card>
            <List>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <ListItem button component={Link} to='/pesquisa?onlySuggested'>
                            <ListItemAvatar style={{ marginRight: 16 }}>
                                <StyledAvatar color='warning'>
                                    <AiFillFire size={28} />
                                </StyledAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography>Sugeridos para você</Typography>}
                                secondary={
                                    <ListItemSecondaryText
                                        text={summary?.suggestionCount}
                                        loading={loading}
                                    />
                                }
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <ListItem button component={Link} to='/pesquisa?onlyProposal'>
                            <ListItemAvatar style={{ marginRight: 16 }}>
                                <StyledAvatar color='secondary'>
                                    <AiOutlineDollar size={28} />
                                </StyledAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography>Propostas iniciadas</Typography>}
                                secondary={
                                    <ListItemSecondaryText
                                        text={summary?.proposalCount}
                                        loading={loading}
                                    />
                                }
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <ListItem button component={Link} to='/pesquisa?onlyFavorites'>
                            <ListItemAvatar style={{ marginRight: 16 }}>
                                <StyledAvatar color='primary'>
                                    <AiFillHeart size={28} />
                                </StyledAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography>Seus favoritos</Typography>}
                                secondary={
                                    <ListItemSecondaryText
                                        text={summary?.favoriteCount}
                                        loading={loading}
                                    />
                                }
                            />
                        </ListItem>
                    </Grid>
                </Grid>
            </List>
        </Card>
    </>
);

export default ProviderSummaryView;
