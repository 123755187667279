import { formatPxToRem } from 'common/utils';
import palette from './palette';

export default {
    h1: {
        color: palette.text.primary,
        fontSize: formatPxToRem(96),
    },
    h2: {
        color: palette.text.primary,
        fontSize: formatPxToRem(60),
    },
    h3: {
        color: palette.text.primary,
        fontSize: formatPxToRem(48),
    },
    h4: {
        color: palette.text.primary,
        fontSize: formatPxToRem(34),
    },
    h5: {
        color: palette.text.primary,
        fontSize: formatPxToRem(24),
    },
    h6: {
        color: palette.text.primary,
        fontSize: formatPxToRem(20),
    },
    subtitle2: {
        color: palette.text.primary,
        fontSize: formatPxToRem(14),
    },
    body1: {
        color: palette.text.primary,
        fontSize: formatPxToRem(16),
    },
    body2: {
        color: palette.text.primary,
        fontSize: formatPxToRem(14),
    },
    button: {
        color: palette.text.primary,
        fontSize: formatPxToRem(14),
    },
    caption: {
        color: palette.text.hint,
        fontSize: formatPxToRem(12),
    },
};
