import { FC } from 'react';
import { Box, Chip, TableCell, TableRow, IconButton, Typography } from '@material-ui/core';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { formatCnpj } from 'common/utils';
import { useHistory } from 'react-router';
import { ProviderRegistrationUpdateRequest } from '../../../../../clients/manager/interfaces/provider-registration-update-requests.interface';
import {
    getPriorityClassName,
    getPriorityLabel,
    getStatusClassName,
    getStatusLabel,
    getTypeLabel,
} from './request-table.utils';
import { useStyles } from './styles';

export interface RequestRowViewProps {
    request: ProviderRegistrationUpdateRequest;
    onClickView: (request: ProviderRegistrationUpdateRequest) => void;
}

const RequestRowView: FC<RequestRowViewProps> = ({ request, onClickView }) => {
    const {
        i18n: { language },
    } = useTranslation();

    moment.locale(language);

    const classes = useStyles();
    const history = useHistory();

    const getCompareChangesProviderUrl = (providerId: number) => {
        history.push(`/empresa/editar/${providerId}`);
    };

    return (
        <TableRow key={request.id}>
            <TableCell component='th' scope='row'>
                <Typography variant='body2'>{`#${request.id}`}</Typography>
                <Typography variant='body2'>{getTypeLabel(request.type)}</Typography>
            </TableCell>

            <TableCell>
                <IconButton
                    onClick={() => getCompareChangesProviderUrl(request.providerId)}
                    className={classes.iconButton}
                >
                    <Typography variant='body2'>{request.provider.companyName}</Typography>
                </IconButton>
                <Typography variant='body2'>{formatCnpj(request.provider.docNumber)}</Typography>
            </TableCell>
            <TableCell>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Chip
                        classes={{
                            root: classes[getPriorityClassName(request.priority)],
                        }}
                        size='small'
                        label={getPriorityLabel(request.priority)}
                        color='primary'
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: 12,
                            color: '#317EC5',
                        }}
                        variant='body2'
                    >
                        {request.auctionNotice?.auctionNumber || ''}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>{request.user?.name.split(' ')?.[0] || '-'} </TableCell>
            <TableCell>
                <Box>
                    <Typography variant='body2'>
                        {moment(request.sendAt).utcOffset('-03:00').format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    <Typography
                        color='primary'
                        style={{
                            fontSize: 12,
                            color: '#317EC5',
                        }}
                        variant='body2'
                    >
                        {moment(request.sendAt).utcOffset('-03:00').fromNow()}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Chip
                        classes={{
                            root: classes[getStatusClassName(request.status)],
                        }}
                        size='small'
                        label={getStatusLabel(request.status)}
                        clickable
                        color='primary'
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    {request.finishedAt ? (
                        <Typography
                            style={{
                                fontSize: 12,
                            }}
                            variant='body2'
                        >
                            {moment
                                .utc(request.finishedAt)
                                .utcOffset('-03:00')
                                .format('DD/MM/YYYY HH:mm')}
                        </Typography>
                    ) : null}
                </Box>
            </TableCell>
            <TableCell>
                <IconButton onClick={() => onClickView(request)}>
                    <EditIcon fontSize='small' />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default RequestRowView;
