import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import PersonPhoneInput from 'common/components/phone-input';
import { InfoIcon } from 'common/icons';
import CodeValidator from 'common/components/code-validator';
import { SignUpContext } from '../context/SignUpContext';

const Identification = () => {
    const { newUser, setNewUser, currentStep, setCurrentStep, handleClickPrev } =
        useContext(SignUpContext);

    const [openCodeValidator, setOpenCodeValidator] = useState(false);

    const { t } = useTranslation();

    const initialValues = {
        name: newUser?.name,
        phone: `${newUser?.phoneCountryCode ? `+${newUser.phoneCountryCode}` : ''}${
            newUser?.phone ?? ''
        }`,
        email: newUser?.email,
        verificationCode: '',
    };

    const validationSchema = yup.object({
        verificationCode: yup.string().optional(),
        name: yup.string().required(t('please-enter-your-name', { ns: 'validation' })),
        phone: yup
            .string()
            .lowercase()
            .required(t('please-enter-your-phone', { ns: 'validation' }))
            .phone({
                message: t('please-enter-a-valid-phone', { ns: 'validation' }),
            }),
        email: yup
            .string()
            .email(t('please-enter-a-valid-email', { ns: 'validation' }))
            .required(t('please-enter-your-email', { ns: 'validation' })),
    });

    const onSubmit = (values: typeof initialValues) => {
        if (!values?.verificationCode) {
            setOpenCodeValidator(true);
            return;
        }

        const parsedPhoneNumber = parsePhoneNumber(values.phone);

        setNewUser((prevNewUser) => ({
            ...prevNewUser,
            ...values,
            phone: parsedPhoneNumber && parsedPhoneNumber.nationalNumber,
            phoneCountryCode: parsedPhoneNumber && parsedPhoneNumber.countryCallingCode,
        }));

        if (currentStep === 1) {
            setCurrentStep(2);
        }
    };

    const form2 = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <Grid className='step-2' item>
            <CodeValidator
                email={form2.values.email}
                open={openCodeValidator}
                onSubmit={async (values) => {
                    await form2.setFieldValue('verificationCode', values.verificationCode, false);
                    setOpenCodeValidator(false);
                    form2.handleSubmit();
                }}
                onClose={() => setOpenCodeValidator(false)}
            />
            <Typography variant='body2'>{t('sign-up.pages.stepps.info-account-steps')}</Typography>
            <form onSubmit={form2.handleSubmit}>
                <div className='first-item'>
                    <Grid item xs={6}>
                        <TextField
                            label={t('term.full-name')}
                            name='name'
                            value={form2.values.name}
                            onChange={form2.handleChange}
                            error={form2.touched.name && Boolean(form2.errors.name)}
                            helperText={form2.touched.name && form2.errors.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneInput
                            flags={flags}
                            initialValueFormat='national'
                            name='phoneNumber'
                            placeholder={t('term.phone')}
                            value={form2.values.phone}
                            defaultCountry='BR'
                            onChange={(e) => {
                                form2.setFieldValue('phone', e);
                            }}
                            autoFocus
                            error={form2.touched.phone && Boolean(form2.errors.phone)}
                            helperText={form2.touched.phone && form2.errors.phone}
                            inputComponent={PersonPhoneInput}
                        />
                    </Grid>
                </div>

                <div>
                    <TextField
                        fullWidth
                        label={t('term.email')}
                        name='email'
                        value={form2.values.email}
                        onChange={form2.handleChange}
                        error={form2.touched.email && Boolean(form2.errors.email)}
                        helperText={form2.touched.email && form2.errors.email}
                    />
                </div>

                <Grid item>
                    <Button size='small' variant='text' color='primary' onClick={handleClickPrev}>
                        {t('term.back')}
                    </Button>
                    <Button size='small' variant='contained' color='primary' type='submit'>
                        {t('term.advance')}
                    </Button>
                </Grid>
            </form>
            <Grid className='consents-content' container direction='column' spacing={1}>
                <Grid item>
                    <Grid container alignItems='center'>
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.info-question-account-data')}
                        </Typography>
                        <Tooltip
                            title={
                                t('sign-up.pages.stepps.info-question-account-data-title') as string
                            }
                            aria-label='info'
                        >
                            <div>
                                <InfoIcon color='secondary' />
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems='center'>
                        <Typography variant='body2'>{t('term.consent')}</Typography>
                        <Tooltip
                            title={t('sign-up.pages.stepps.info-privacy-policy-title') as string}
                            aria-label='info'
                        >
                            <div>
                                <InfoIcon color='secondary' />
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Identification;
