import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'common/components';
import ReactDOM from 'react-dom';
import React, { FC, useEffect, useState } from 'react';
import { SupplyCategory } from 'clients/manager/interfaces/supply-categories.interface';
import { supplyCategoriesRequests } from 'clients/manager/supply-categories.requests';

interface SelectSupplyCategoriesProps {
    label: string;
    onChange: (supplyCategoriesIds: number[]) => void;
    value: number[];
    disabled?: boolean;
}

const SelectSupplyCategories: FC<SelectSupplyCategoriesProps> = ({
    label,
    onChange,
    value: initialValue,
    disabled,
}) => {
    const [supplyCategories, setSupplyCategories] = useState<SupplyCategory[]>([]);
    const [fetchingSupplyCategories, setFetchingSupplyCategories] = useState(true);
    const [selectedValues, setSelectedValues] = useState<number[]>(initialValue);

    const getSupplyCategories = async () => {
        try {
            const response = await supplyCategoriesRequests.listSupplyCategories();
            ReactDOM.unstable_batchedUpdates(() => {
                setSupplyCategories(response?.data ?? []);
                setFetchingSupplyCategories(false);
            });
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    useEffect(() => {
        getSupplyCategories();
    }, []);

    const handleChangeValues = (_: React.ChangeEvent<any>, options: SupplyCategory[]) => {
        const newValues = options?.map((supplyCategory) => supplyCategory.id) ?? [];
        setSelectedValues(newValues);
        onChange(newValues);
    };

    const getSupplyCategoryLabel = (supplyCategory: SupplyCategory) => {
        if (supplyCategory.externalId) {
            return `${supplyCategory.externalId}. ${supplyCategory.categoryName}`;
        }

        return supplyCategory.categoryName;
    };

    return (
        <Autocomplete
            multiple
            limitTags={10}
            key={`${supplyCategories.length}`}
            disabled={fetchingSupplyCategories || disabled}
            defaultValue={supplyCategories.filter((supplyCategory) =>
                selectedValues.includes(supplyCategory.id)
            )}
            fullWidth
            disableCloseOnSelect
            options={supplyCategories}
            getOptionLabel={(option) => getSupplyCategoryLabel(option)}
            filterSelectedOptions
            onChange={handleChangeValues}
            renderInput={(params) => (
                <TextField {...params} name='supliesProviders' variant='outlined' label={label} />
            )}
        />
    );
};

export default SelectSupplyCategories;
