import { FC, useState } from 'react';
import { Button, ButtonSize, Radio } from '@licitar/ui-kit';
import { Provider } from 'common/interfaces/provider.interface';
import { useSelector } from 'react-redux';
import { RawItems } from 'clients/price-base/interfaces/raw-items.response.interface';
import { Spin } from 'common/components/base/spin';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import { useTranslation } from 'react-i18next';
import { providerRequests } from 'clients/price-base/providers.requests';
import { addNotificationError } from 'common/utils';
import { Content, ActionsArea, OverLay } from './styled';
import { PriceListPdfProps } from './props';
import { AppState } from '../../../../../store';
import { createSyntheticPdf } from './components/create-synthetic-pdf';
import { createIntermediaryPdf } from './components/create-intermediary-pdf';
import { createCompletePdf } from './components/create-complete-pdf';

const PriceListPdf: FC<PriceListPdfProps> = ({ priceList }) => {
    const { t } = useTranslation();
    const [pdfSelected, setPdfSelected] = useState(0);
    const [generatingPdf, setGeneratingPdf] = useState(false);

    const { authUser } = useSelector((state: AppState) => state.authUserState);

    const getItemsAndProviders = async () => {
        const items = await priceListRequests.getRawItems(priceList._id);

        const providersSet = new Set<string>();
        items.forEach((item) => item.prices.forEach((price) => providersSet.add(price.providerId)));

        const providers = await providerRequests.getProviders(Array.from(providersSet));
        return { items, providers };
    };

    const handleSelected = (value: number) => {
        setGeneratingPdf(false);
        setPdfSelected(value);
    };

    const getPdfToRender = (items: RawItems[], providers: Provider[]) => {
        if (!authUser) {
            return;
        }

        switch (pdfSelected) {
            case 0:
                return createSyntheticPdf(priceList, authUser, (generating) =>
                    setGeneratingPdf(generating)
                );

            case 1:
                return createIntermediaryPdf(priceList, authUser, items, providers, (generating) =>
                    setGeneratingPdf(generating)
                );

            case 2:
                return createCompletePdf(priceList, authUser, items, providers, (generating) =>
                    setGeneratingPdf(generating)
                );

            default:
                break;
        }
    };

    const printPdf = async () => {
        setGeneratingPdf(true);

        try {
            const { items, providers } = await getItemsAndProviders();
            getPdfToRender(items, providers);
        } catch (error) {
            setGeneratingPdf(false);
            addNotificationError({
                message: t('mkp.user-price-list.error-generate-document'),
            });
        }
    };

    return (
        <Content>
            {generatingPdf && (
                <OverLay>
                    <Spin />
                </OverLay>
            )}
            <Radio
                name='type'
                label={t('mkp.user-price-list.report-type-1')}
                value={0}
                checked={pdfSelected === 0}
                onChange={() => handleSelected(0)}
            />
            <Radio
                name='type'
                label={t('mkp.user-price-list.report-type-2')}
                value={1}
                checked={pdfSelected === 1}
                onChange={() => handleSelected(1)}
            />
            <Radio
                name='type'
                label={t('mkp.user-price-list.report-type-3')}
                value={2}
                checked={pdfSelected === 2}
                onChange={() => handleSelected(2)}
            />

            <ActionsArea>
                <Button disabled={generatingPdf} onClick={() => printPdf()} size={ButtonSize.sm}>
                    {t('term.print-out')}
                </Button>
            </ActionsArea>
        </Content>
    );
};

export default PriceListPdf;
