import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import { Tooltip, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import canEditAuthorizationGroup from 'modules/group-authorization/helper/canEditAuthorizationGroup';
import { TextField } from 'common/components';

const SearchGroupAuthorizationTableView = ({
    groups,
    handleChangeFilter,
    handleEdit,
    handleView,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <TextField
                label={t('group-auth.pages.search-group.components.search-group')}
                placeholder={t('group-auth.pages.search-group.components.search-group-press-enter')}
                fullWidth
                onKeyPress={handleChangeFilter}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('group-auth.pages.edit-group.components.edit-group-auth.group-name')}
                        </TableCell>
                        <TableCell>{t('term.description')}</TableCell>
                        <TableCell>{t('term.actions')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {groups &&
                        groups.map((row) => (
                            <TableRow hover key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell align='center' padding='none' style={{ width: 80 }}>
                                    {canEditAuthorizationGroup(row) && (
                                        <Tooltip
                                            title={
                                                t(
                                                    'group-auth.pages.search-group.components.edit-group'
                                                ) as string
                                            }
                                        >
                                            <IconButton onClick={() => handleEdit(row)}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {!canEditAuthorizationGroup(row) && (
                                        <Tooltip
                                            title={
                                                t(
                                                    'group-auth.pages.search-group.components.view-group'
                                                ) as string
                                            }
                                        >
                                            <IconButton onClick={() => handleView(row)}>
                                                <VisibilityIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    {groups && !groups.length && (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {!groups && (
                        <TableRow>
                            <TableCell colSpan={3} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default SearchGroupAuthorizationTableView;
