import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from '../../interfaces/ok.interface';
import {
    GenericBody,
    GenericResponse,
    PlainGenericResponse,
} from '../../interfaces/paginated.interface';
import { DocumentTemplateType } from '../interfaces/document-templates.interface';
import { ProcessDocumentsSteps } from '../interfaces/process-documents-steps.interface';
import { GeneratedDocuments } from '../interfaces/generated-documents.interface';

const path = '/documents/process-steps';

export interface ProcessDocumentsStepsResume extends ProcessDocumentsSteps {
    totalGenerated: number;
    generatedDocuments?: GeneratedDocuments[];
}

export interface ListProcessStepsFiltersParams {
    auctionId?: number;
    q?: string;
}

export interface ListProcessDocumentsSteps {
    auctionId: number;
    documentType?: DocumentTemplateType;
    documentTypes?: DocumentTemplateType[];
}

interface ListUsersBelongToOrganizationBody extends GenericBody<ListProcessStepsFiltersParams> {}

const processDocumentStepsRequests = {
    listProcessStepsResume: async (body?: ListUsersBelongToOrganizationBody) => {
        return doRequest<GenericResponse<ProcessDocumentsStepsResume>>(
            managerApiInstance.post(`${path}/listProcessDocumentsStepsResume`, body)
        );
    },
    listProcessSteps: async (filters: ListProcessDocumentsSteps) =>
        doRequest<GenericResponse<ProcessDocumentsSteps>>(
            managerApiInstance.post(`${path}/listProcessDocumentsSteps`, {
                params: filters,
            })
        ),
    createProcessStep: async (document: Omit<ProcessDocumentsSteps, '_id' | 'organizationId'>) =>
        doRequest<PlainGenericResponse<ProcessDocumentsSteps>>(
            managerApiInstance.post(`${path}/createProcessDocumentsSteps`, document)
        ),
    updateProcessStep: async (processStepId: string, processDocumentStep: ProcessDocumentsSteps) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/updateProcessDocumentsSteps`, {
                ...processDocumentStep,
                _id: processStepId,
            })
        ),
    deleteProcessStep: async (processStepId: string) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/deleteProcessDocumentsSteps`, {
                processDocumentStepId: processStepId,
            })
        ),
    sendStepDocumentsToSignature: async (processStepId: string) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/sendStepDocumentsToSignature`, {
                processDocumentStepId: processStepId,
            })
        ),
};

export { processDocumentStepsRequests };
