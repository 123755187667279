import { Typography, Box, Grid } from '@material-ui/core';
import { Modal, ModalPosition, TextField } from 'common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import LoadingButton from 'common/components/loading-button';
import { FavoriteMessageForm } from './modal-edit-or-create-favorite-message';

interface ModalEditOrCreateFavoriteMessageViewṔrops {
    onClose: () => void;
    messageId?: number;
    form: FormikProps<FavoriteMessageForm>;
    fetching: boolean;
}

const ModalEditOrCreateFavoriteMessageView: FC<ModalEditOrCreateFavoriteMessageViewṔrops> = ({
    onClose,
    messageId,
    form,
    fetching,
}) => {
    const { t } = useTranslation();

    const getTerm = (termCreate: string, termUpdate: string) => {
        return messageId ? t(termUpdate) : t(termCreate);
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={onClose}
            header={
                <Typography style={{ color: 'white' }}>{t('term.favorite-message')}</Typography>
            }
        >
            <Box maxWidth='600px'>
                <Grid container spacing={2}>
                    <TextField
                        fullWidth
                        label={t('term.title-of-message')}
                        name='title'
                        onChange={form.handleChange}
                        value={form.values.title}
                        error={form.touched.title && Boolean(form.errors.title)}
                        helperText={form.touched.title && form.errors.title}
                    />
                    <TextField
                        style={{ marginTop: 10 }}
                        fullWidth
                        multiline
                        label={t('term.message')}
                        name='message'
                        onChange={form.handleChange}
                        value={form.values.message}
                        error={form.touched.message && Boolean(form.errors.message)}
                        helperText={form.touched.message && form.errors.message}
                    />
                </Grid>
                <Grid container justifyContent='flex-end'>
                    <Grid>
                        <LoadingButton
                            style={{ marginTop: 20 }}
                            className='btn-add'
                            size='small'
                            type='submit'
                            color='primary'
                            variant='contained'
                            onClick={() => form.submitForm()}
                            loading={
                                fetching
                                    ? { text: getTerm('term.creating', 'term.updating') }
                                    : undefined
                            }
                        >
                            {getTerm('term.add', 'term.update')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalEditOrCreateFavoriteMessageView;
