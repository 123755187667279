import { FC } from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import SelectDocumentTemplate from '../select-document-template';

interface ModalAddMinuteViewProps {
    selectedTemplate: DocumentTemplate | undefined;
    setSelectedTemplate: (template: DocumentTemplate | undefined) => void;
    createOrganizationMinute: (documentTemplateId: string) => void;
    t: (key: string) => string;
    onClose: () => void;
    classes: Record<string, string>;
}

const ModalAddMinuteView: FC<ModalAddMinuteViewProps> = ({
    selectedTemplate,
    setSelectedTemplate,
    createOrganizationMinute,
    t,
    onClose,
    classes,
}) => {
    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={onClose}
            header={<Typography className={classes.header}>{t('Cadastro de Ata')}</Typography>}
        >
            <Box className={classes.box}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectDocumentTemplate
                            onDocumentTemplateSelected={(documentTemplate) => {
                                setSelectedTemplate(documentTemplate);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='flex-end'>
                    <Grid>
                        <Button
                            className={`${classes.button} btn-add`}
                            size='small'
                            type='submit'
                            color='primary'
                            disabled={!selectedTemplate}
                            variant='contained'
                            onClick={() => {
                                if (selectedTemplate) {
                                    createOrganizationMinute(selectedTemplate._id);
                                }
                            }}
                        >
                            {t('term.add')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalAddMinuteView;
