import {
    Grid,
    Card,
    Typography,
    MenuItem,
    RadioGroup,
    Radio,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UseFormikResponse } from 'common/interfaces/formik.interface';
import { TextField } from 'common/components';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import { UserAuthorizationGroup } from 'modules/organization-users/interfaces/authorization-group-users.interface';
import { OrganizationUnit } from 'clients/manager/interfaces/organization.interface';
import { Alert } from '@material-ui/lab';
import UnitAuthorizationProfileTableField from './components/unit-authorization-profile-table-field';
import OrganizationAuthorizationProfileField from './components/organization-authorization-profile-field';
import { EditOrganizationUsersFormValues } from './edit-organization-users-form-schema';

interface EditOrganizationUsersFormViewProps {
    form: UseFormikResponse<EditOrganizationUsersFormValues>;
    onBlurCPF: (cpf: string) => Promise<void>;
    organizationUnits?: OrganizationUnit[];
    authorizationGroups?: UserAuthorizationGroup[];
    loading?: boolean;
    organizationUnitsLoading?: boolean;
    isEdit?: boolean;
}

const EditOrganizationUsersFormView = ({
    form,
    onBlurCPF,
    authorizationGroups,
    organizationUnits,
    loading,
    organizationUnitsLoading,
    isEdit,
}: EditOrganizationUsersFormViewProps) => {
    const { t } = useTranslation();
    const { IBGEcountries } = useIBGECountries();

    const foundUser = !!form?.values?.name;

    return (
        <form id='formEditOrganizationUser' onSubmit={form.handleSubmit}>
            <Card style={{ marginBottom: 20 }}>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t(
                                'group-auth.pages.edit-group.components.edit-group-auth.general-data'
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <TextField
                            select
                            label={t('term.country')}
                            fullWidth
                            name='countryCode'
                            value={form.values.countryCode}
                            onChange={form.handleChange}
                            onBlur={() => onBlurCPF(form.values.cpf)}
                            error={form.touched.countryCode && !!form.errors.countryCode}
                            helperText={form.touched.countryCode && form.errors.countryCode}
                            disabled={isEdit}
                        >
                            {IBGEcountries.map(({ code, name }) => (
                                <MenuItem key={code} value={code}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <IdentificationNumberTextField
                            identificationNumber={form.values.cpf}
                            country={form.values.countryCode}
                            fieldName='cpf'
                            type='cpf'
                            onBlur={(e) => onBlurCPF(e.target.value)}
                            InputProps={{
                                endAdornment: loading && (
                                    <CircularProgress color='primary' size={20} />
                                ),
                            }}
                            disabled={loading || isEdit}
                            {...form}
                        />
                    </Grid>
                    <Grid item xs={4} lg={6}>
                        <TextField
                            fullWidth
                            label={t('term.name')}
                            name='name'
                            value={form.values.name}
                            InputLabelProps={{ shrink: !!form?.values?.name }}
                            InputProps={{
                                endAdornment: loading && (
                                    <CircularProgress color='primary' size={20} />
                                ),
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {loading && (
                            <Alert severity='info'>
                                <strong>{t('term.loading')}...</strong>
                            </Alert>
                        )}
                        {!loading && form?.values.name && (
                            <Alert severity='success'>
                                <strong>
                                    {t('organization-users.pages.components.found-user-alert-1')}
                                </strong>{' '}
                                {t('organization-users.pages.components.found-user-alert-2')}
                            </Alert>
                        )}
                        {!loading && !form?.values.name && !isEdit && (
                            <Alert severity='warning'>
                                <strong>
                                    {t('organization-users.pages.components.warning-alert-1')}
                                </strong>{' '}
                                {t('organization-users.pages.components.warning-alert-2')}
                            </Alert>
                        )}
                        {!loading && !form?.values.name && isEdit && (
                            <Alert severity='error'>
                                <strong>
                                    {t('organization-users.pages.components.error-alert-1')}
                                </strong>{' '}
                                {t('organization-users.pages.components.error-alert-2')}
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </Card>
            <Card>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t('organization-users.pages.components.authorization-profile')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-label='Authorization profile'
                            name='authorizationProfile'
                            value={form.values.authorizationProfile}
                            onChange={form.handleChange}
                        >
                            <FormControlLabel
                                control={<Radio value='unit' />}
                                label={t(
                                    'organization-users.pages.components.authorization-profile-unit'
                                )}
                                disabled={!foundUser}
                            />
                            <FormControlLabel
                                control={<Radio value='organization' />}
                                label={t(
                                    'organization-users.pages.components.authorization-profile-organization'
                                )}
                                disabled={!foundUser}
                            />
                        </RadioGroup>
                    </Grid>
                    {form.values.authorizationProfile === 'unit' && (
                        <UnitAuthorizationProfileTableField
                            {...{
                                form,
                                organizationUnits,
                                authorizationGroups,
                                disabled: !foundUser,
                                loading: organizationUnitsLoading,
                            }}
                        />
                    )}
                    {form.values.authorizationProfile === 'organization' && (
                        <OrganizationAuthorizationProfileField
                            {...{ form, authorizationGroups, disabled: !foundUser, loading }}
                        />
                    )}
                </Grid>
            </Card>
        </form>
    );
};

export default EditOrganizationUsersFormView;
