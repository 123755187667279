import { useState } from 'react';
import TableCellHiddenView from './table-cell-hidden-view';

const TableCellHidden = ({ cpf, ...props }) => {
  const [isCpfVisible, setIsCpfVisible] = useState(false);

  const toggleCpfVisibility = () => {
    setIsCpfVisible(!isCpfVisible);
  };

  return <TableCellHiddenView cpf={cpf} isCpfVisible={isCpfVisible} toggleCpfVisibility={toggleCpfVisibility} { ...props } />;
};

export default TableCellHidden;
