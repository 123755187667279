import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftSection: {
        display: 'flex',
        alignItems: 'center',
    },
    lotInfo: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
    },
    lotId: {
        fontSize: 11,
        margin: '0px 1rem',
    },
    advancedInfoButton: {
        textTransform: 'none',
        fontSize: 13,
    },
    editIcon: {
        color: theme.palette.warning.main,
    },
    tooltip: {
        fontSize: '14px',
    },
    pendingChanges: {
        marginLeft: '1rem',
        display: 'flex',
        alignItems: 'center',
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center',
    },
    lotLabel: {
        marginRight: '1rem',
        fontWeight: 'bold',
        color: theme.palette.grey['600'],
    },
}));

export default useStyles;
