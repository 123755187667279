import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { apiRedirectTo, featureFlagEnabled, getBackofficeRedirectUrl } from 'common/utils';
import LoadingButton from 'common/components/loading-button';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { useProcessFormContext } from 'modules/process/context/process-form.context';

const ButtonCreateProcess = () => {
    const [creatingProcess, setCreatingProcess] = useState(false);

    const { t } = useTranslation();
    const { processLotsForm, setProcessLotsAndItems } = useProcessLotsFormContext();
    const { processForm, setProcess } = useProcessFormContext();
    const history = useHistory();

    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const handleResetProcessForm = () => {
        setProcessLotsAndItems([]);
        setProcess(undefined);
        processLotsForm.resetForm();
        processForm?.resetForm();
    };

    const handleClickCreateProcess = async () => {
        setCreatingProcess(true);
        handleResetProcessForm();

        if (!featureFlagEnabled('novaTelaProcessos')) {
            const path = encodeURIComponent('processos/?novo');
            return apiRedirectTo(backofficeRedirectUrl, path);
        }

        history.push('/processos/novo');
    };

    return (
        <LoadingButton
            color='primary'
            variant='contained'
            size='small'
            title={t('process.components.add-new-process')}
            onClick={() => handleClickCreateProcess()}
            {...(creatingProcess
                ? {
                      loading: {
                          text: `${t('term.creating')}..`,
                      },
                  }
                : {})}
        >
            {t('process.components.new-process')}
        </LoadingButton>
    );
};

export default memo(ButtonCreateProcess);
