import React, { useState } from 'react';

import InformationFileChipView from './information-file-chip-view';

interface InformationFileChipProps {
    url: string;
}

const InformationFileChip: React.FC<InformationFileChipProps> = ({ url }) => {
    const [isDownloaded, setIsDownloaded] = useState(false);

    const getLabelFile = (fileUrl: string) => {
        return fileUrl.split('/').at(-1) ?? '';
    };

    const onDownloaded = () => {
        setIsDownloaded((state) => !state);
    };

    return (
        <InformationFileChipView
            url={url}
            label={getLabelFile(url)}
            isDownloaded={isDownloaded}
            onClick={onDownloaded}
        />
    );
};

export default InformationFileChip;
