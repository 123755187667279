import { styled } from 'common/theme/styled';

const Content = styled.div`
    background: #fff;
    width: 350px;
    height: auto;
    padding: 15px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.textDefault};
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Example = styled.p`
    color: ${(props) => props.theme.colors.textLight};
    margin: 10px 0;
`;

export { Content, Actions, Example };
