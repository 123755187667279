import React from 'react';

interface RatingIndicatorViewProps {
    stars: any[];
}

const RatingIndicatorView: React.FC<RatingIndicatorViewProps> = ({ stars }) => {
    return (
        <div>
            {stars.map((Star) => (
                <Star />
            ))}
        </div>
    );
};

export default RatingIndicatorView;
