import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    paperContent: {
        display: 'flex',
        marginTop: theme.spacing(3),
        flexDirection: 'column',
    },
}));

export default useStyles;
