import { FC, useEffect, useState } from 'react';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import {
    addNotificationError,
    addNotificationInfo,
    addNotificationWarning,
    average,
    calculateMedian,
    lowestValue,
    timeout,
} from 'common/utils';
import { PriceList, PriceListItem } from 'common/interfaces/price-list.interface';
import { PriceMountType } from 'common/interfaces/price.interface';
import { nanoid } from 'nanoid';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PriceListItemRequest } from 'clients/price-base/interfaces/price-list-response.interface';
import { Button } from 'common/components';
import { usePriceBankContext } from 'modules/priceBank/context/price-list.context';
import { MarketplaceWindowEvents } from 'modules/priceBank/marketplace.events';
import { Content } from './styled';
import { AddItemsProps } from './props';
import ModalSelectPriceList from '../modal-select-price-list';
import ModalAddItems, { CreateItemForm } from '../modal-add-items';

const AddItems: FC<AddItemsProps> = ({
    pricesSelected,
    onItemCreated,
    firstItemSelected,
    onItemUpdated,
}) => {
    const { t } = useTranslation();
    const [modalAddItemsOpened, setModalAddItemsOpened] = useState(false);
    const [canAddOnList, setCanAddOnList] = useState(false);
    const [modalSelectPriceListOpened, setModalSelectPriceListOpened] = useState(false);
    const [formCreateItem, setFormCreateItem] = useState<CreateItemForm | undefined>(undefined);

    const { priceListSelected, setPriceListSelected } = usePriceBankContext();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const queryItemId = query.get('itemId');

    useEffect(() => {
        let length = 0;
        Object.values(pricesSelected).forEach((item) => {
            length += Object.keys(item).length || 0;
        });
        setCanAddOnList(!!length);
    }, [pricesSelected]);

    const calculateResume = (priceMountType: PriceMountType) => {
        const unitValues: number[] = [];
        const pricesIds: string[] = [];

        Object.values(pricesSelected).forEach((item) => {
            Object.values(item).forEach((price) => {
                unitValues.push(price.unitValue);
                pricesIds.push(price._id);
            });
        });

        let result: number = 0;

        switch (priceMountType) {
            case PriceMountType.lowerPrice:
                result = lowestValue(unitValues);
                break;

            case PriceMountType.media:
                result = average(unitValues);
                break;

            case PriceMountType.median:
                result = calculateMedian(unitValues);
                break;

            default:
                break;
        }

        return { referenceValue: result, itemPrices: pricesIds };
    };

    const handleOnPriceListSelected = async (
        priceList: PriceList,
        formCreateItemDeep = formCreateItem
    ) => {
        if (!formCreateItemDeep) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('mkp.search.try-again-created-list'),
            });
            return;
        }

        const { priceMountType, unitMeasurement, description, amount } = formCreateItemDeep;
        const { referenceValue, itemPrices } = calculateResume(priceMountType);

        const payload: PriceListItemRequest = {
            itemPrices,
            unitMeasurement,
            referenceValue: referenceValue || 0,
            priceMountType,
            description,
            amount,
        };

        try {
            await priceListRequests.createItemsPriceList(priceList._id, [payload]);
            setModalSelectPriceListOpened(false);
            onItemCreated();
            addNotificationInfo({
                title: t('mkp.search.item-add'),
                message: `${t('mkp.search.item-add-in')} (${priceList.name})`,
            });

            timeout(() => {
                setPriceListSelected((prevState) => {
                    if (!prevState) {
                        return prevState;
                    }

                    return {
                        ...prevState,
                        priceListItems: [
                            ...prevState.priceListItems,
                            {
                                ...(payload as PriceListItem),
                                _id: nanoid(),
                            },
                        ],
                    };
                });
            }, 500);
        } catch (error) {
            addNotificationError({
                title: t('term.error'),
                message: `${t('mkp.search.try-again-item-add-in')} (${priceList.name})`,
            });
        }
    };

    const onCreateItem = (currFormCreateItem: CreateItemForm) => {
        setFormCreateItem(currFormCreateItem);
        setModalAddItemsOpened(false);

        if (!priceListSelected) {
            return setModalSelectPriceListOpened(true);
        }
        return handleOnPriceListSelected(priceListSelected, currFormCreateItem);
    };

    const handleEditItem = async (priceList: PriceList, formUpdateItemDeep = formCreateItem) => {
        if (!formUpdateItemDeep || !queryItemId) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('mkp.search.error-updated-item'),
            });
            return;
        }

        const { priceMountType, unitMeasurement, description, amount } = formUpdateItemDeep;
        const { referenceValue, itemPrices } = calculateResume(priceMountType);

        const payload: PriceListItemRequest = {
            itemPrices,
            unitMeasurement,
            referenceValue: referenceValue || 0,
            priceMountType,
            description,
            amount,
        };

        try {
            await priceListRequests.updateItemPriceList(priceList._id, queryItemId, payload);
            onItemUpdated();
            addNotificationInfo({
                title: t('mkp.search.updated-item'),
                message: t('mkp.search.updated-item'),
            });
        } catch (error) {
            addNotificationError({
                title: t('term.error'),
                message: t('mkp.search.try-again-updated-item'),
            });
        }
    };

    const onEditItem = (currFormCreateItem: CreateItemForm) => {
        setFormCreateItem(currFormCreateItem);
        setModalAddItemsOpened(false);
        return handleEditItem(priceListSelected as PriceList, currFormCreateItem);
    };

    UseWindowEvent(
        MarketplaceWindowEvents.REQUEST_CREATE_ITEM,
        () => setModalAddItemsOpened(true),
        []
    );

    return (
        <Content>
            {modalAddItemsOpened && (
                <ModalAddItems
                    priceListSelected={priceListSelected}
                    firstItemSelected={firstItemSelected}
                    opened={modalAddItemsOpened}
                    onClose={() => setModalAddItemsOpened(false)}
                    onCreate={onCreateItem}
                    onEdit={onEditItem}
                />
            )}
            {modalSelectPriceListOpened && (
                <ModalSelectPriceList
                    onPriceListSelected={handleOnPriceListSelected}
                    opened={modalSelectPriceListOpened}
                    onClose={() => setModalSelectPriceListOpened(false)}
                />
            )}
            <Button
                size='small'
                variant='contained'
                style={{
                    margin: '0 0 0 10px',
                }}
                disabled={!canAddOnList}
                onClick={() => setModalAddItemsOpened(true)}
            >
                {queryItemId ? t('mkp.search.update-item') : t('mkp.search.add-item')}
            </Button>
        </Content>
    );
};

export default AddItems;
