/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { Grid, Breadcrumbs, Typography } from '@material-ui/core';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import i18n from 'common/i18n';
import { SignUpOrganizationContext } from './context/SignUpOrganizationContext';
import { SignUpOrganizationProps } from './props';
import Address from './Stepps/address';
import Identification from './Stepps/identification';
import Region from './Stepps/region';
import { Container, Content } from './styles';

function getSteps() {
    return [i18n.t('term.identification'), i18n.t('term.address2'), i18n.t('term.region')];
}

function getStepContent(stepIndex: number) {
    switch (stepIndex) {
        case 0:
            return i18n.t('term.identification');
        case 1:
            return i18n.t('term.address2');
        case 2:
            return i18n.t('term.region');
        default:
            return 'Unknown stepIndex';
    }
}

const SignUpStepperOrganization: FC<SignUpOrganizationProps> = () => {
    const { activeStep } = useContext(SignUpOrganizationContext);

    const steps = getSteps();
    // const [organization, setOrganization] = useState({});
    // const [modalOpen, setModalOpen] = useState<boolean>(false);
    // const [units, setUnits] = useState([]);

    // const handleCloseModal = () => {
    //     setModalOpen(false);
    // };

    // const handleDelete = (index: any) => {
    //     const newArray = units.filter((item, currentIndex) => currentIndex !== index);
    //     setUnits(newArray);
    //     setOrganization({ ...organization, responsibles: newArray });
    // };

    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Container>
                <Content>
                    <Breadcrumbs className='breadcrumbs-nav'>
                        <Typography color='primary'>{t('term.home')}</Typography>
                        <Typography color='primary'>
                            {t('term.organization-registration')}
                        </Typography>
                    </Breadcrumbs>
                    <div>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div className='content-steppers'>
                            {activeStep === steps.length ? (
                                <>
                                    <Grid
                                        container
                                        className='sign-up-sucess'
                                        justifyContent='space-around'
                                        alignItems='center'
                                        spacing={2}
                                    >
                                        <Typography variant='h4' color='secondary'>
                                            {t(
                                                'sign-up.pages.stepper-organization.index.info-success-registration-1'
                                            )}
                                            <strong>
                                                {t(
                                                    'sign-up.pages.stepper-organization.index.info-success-registration-2'
                                                )}
                                            </strong>
                                        </Typography>
                                        <Grid item md={6}>
                                            <img
                                                className='image-sucess'
                                                src='/assets/images/svgs/sign_up_person_success_img.png'
                                                alt='person'
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography>
                                                {t(
                                                    'sign-up.pages.stepper-organization.index.info-success-validate-registration'
                                                )}
                                            </Typography>
                                            <Typography>
                                                {t(
                                                    'sign-up.pages.stepper-organization.index.info-success-enter-account-simple'
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <div>
                                    <Typography variant='h5' color='primary'>
                                        {getStepContent(activeStep)}
                                    </Typography>

                                    {activeStep === 0 && <Identification />}

                                    {activeStep === 1 && <Address />}

                                    {/* {activeStep === 2 && (
                                    <>
                                        <Grid>
                                            <TableContainer component={Paper}>
                                                <Table size='small' aria-label='a dense table'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Nome</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {units &&
                                                        units.map((item: any, index) => (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <TableBody key={index}>
                                                                <TableRow>
                                                                    <TableCell
                                                                        component='th'
                                                                        scope='row'
                                                                    >
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component='th'
                                                                        align='right'
                                                                        scope='row'
                                                                        padding='checkbox'
                                                                    >
                                                                        <IconButton
                                                                            aria-label='delete'
                                                                            edge='end'
                                                                            onClick={() =>
                                                                                handleDelete(index)
                                                                            }
                                                                        >
                                                                            <DeleteIcon color='error' />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        ))}
                                                </Table>
                                            </TableContainer>
                                            <Button
                                                className='btn-add'
                                                size='small'
                                                variant='outlined'
                                                color='primary'
                                                startIcon={<AddIcon />}
                                                onClick={() => setModalOpen(true)}
                                            >
                                                Adicionar
                                            </Button>
                                        </Grid>
                                        <OrganizationAddModal
                                            organization={organization}
                                            setOrganization={setOrganization}
                                            modalOpen={modalOpen}
                                            units={units}
                                            setUnits={setUnits}
                                            handleCloseModal={handleCloseModal}
                                        />
                                        <div className='stepper-nav'>
                                            <Button
                                                size='small'
                                                color='primary'
                                                variant='contained'
                                                onClick={handleBack}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                size='small'
                                                variant='contained'
                                                color='primary'
                                                type='submit'
                                                onClick={handleNext}
                                            >
                                                {activeStep === steps.length - 1
                                                    ? 'Finalizar'
                                                    : 'Avançar'}
                                            </Button>
                                        </div>
                                    </>
                                )} */}

                                    {activeStep === 2 && <Region />}
                                </div>
                            )}
                        </div>
                    </div>
                </Content>
            </Container>
        </PageWrapper>
    );
};

export default SignUpStepperOrganization;
