import React from 'react';
import {
    Grid,
    Card,
    CardActions,
    Typography,
    FormControlLabel,
    Checkbox,
    Box,
} from '@material-ui/core';
import { Button } from 'common/components';
import { UseFormikResponse } from 'common/interfaces/formik.interface';
import { useTranslation } from 'react-i18next';
import { ProfileLgpdLegalBasisFormValues } from './profile-lgpd-legal-basis';

interface ProfileLgpdLegalBasisViewProps {
    form: UseFormikResponse<ProfileLgpdLegalBasisFormValues>;
}

const ProfileLgpdLegalBasisView: React.FC<ProfileLgpdLegalBasisViewProps> = ({ form }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <form onSubmit={form.handleSubmit} noValidate>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Typography variant='h6'>{t('title.privacy-and-preferences')}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: 8 }}>
                        <FormControlLabel
                            label={t(
                                'auth.profile.profile-lgpd-legal-basis.allow-send-all-process-opportunities-by-email'
                            )}
                            control={
                                <Checkbox
                                    color='primary'
                                    checked={
                                        form?.values.allowSendProcessOpportunitiesByEmail &&
                                        form?.values.allowSendProviderProcessOpportunitiesByEmail
                                    }
                                    indeterminate={
                                        form?.values.allowSendProcessOpportunitiesByEmail !==
                                        form?.values.allowSendProviderProcessOpportunitiesByEmail
                                    }
                                    onChange={() => {
                                        const isAllEnabled =
                                            form?.values.allowSendProcessOpportunitiesByEmail &&
                                            form?.values
                                                .allowSendProviderProcessOpportunitiesByEmail;

                                        form.setValues((state) => ({
                                            ...state,
                                            allowSendProcessOpportunitiesByEmail: !isAllEnabled,
                                            allowSendProviderProcessOpportunitiesByEmail:
                                                !isAllEnabled,
                                        }));
                                    }}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            <FormControlLabel
                                label={t(
                                    'auth.profile.profile-lgpd-legal-basis.allow-send-process-opportunities-by-email'
                                )}
                                name='allowSendProcessOpportunitiesByEmail'
                                onChange={form.handleChange}
                                control={
                                    <Checkbox
                                        color='primary'
                                        checked={form?.values.allowSendProcessOpportunitiesByEmail}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={t(
                                    'auth.profile.profile-lgpd-legal-basis.allow-send-provider-process-opportunities-by-email'
                                )}
                                name='allowSendProviderProcessOpportunitiesByEmail'
                                onChange={form.handleChange}
                                control={
                                    <Checkbox
                                        color='primary'
                                        checked={
                                            form?.values
                                                .allowSendProviderProcessOpportunitiesByEmail
                                        }
                                    />
                                }
                            />
                        </Box>
                    </Grid>

                    <CardActions style={{ padding: 8, justifyContent: 'flex-end' }}>
                        <Button
                            type='submit'
                            size='small'
                            variant='contained'
                            disabled={form.isSubmitting}
                        >
                            {t('term.save')}
                        </Button>
                    </CardActions>
                </Grid>
            </form>
        </Card>
    );
};

export default ProfileLgpdLegalBasisView;
