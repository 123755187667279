import styled from 'styled-components';

export const LegendContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.875rem;
`;

export const LegendSquare = styled.span<{ squareColor: string }>`
    width: 14px;
    height: 14px;
    border-radius: 7px;
    /* border-width: 1px; */
    /* border-color: var(--border-color); */
    background-color: ${(props) => props.squareColor};
`;
