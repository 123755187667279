import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import EditOrganizationUsersForm, {
    EditOrganizationUsersFormProps,
} from './components/edit-organization-users-form';

interface EditOrganizationUsersViewProps {
    onSubmit: EditOrganizationUsersFormProps['onSubmit'];
    cpf?: string;
    country?: string;
}

const EditOrganizationUsersView: React.FC<EditOrganizationUsersViewProps> = ({
    onSubmit,
    country,
    cpf,
}) => (
    <PageWrapper>
        <EditOrganizationUsersForm {...{ onSubmit, country, cpf }} />
    </PageWrapper>
);

export default EditOrganizationUsersView;
