import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles((theme) => ({
    deadlineButton: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        '&:disabled': {
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
        },
    },
    deadlineText: {
        fontSize: formatPxToRem(13),
        fontWeight: 600,
        color: theme.palette.warning.main,
    },
}));

export { useStyles };
