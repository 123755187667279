import { FC } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { JudgmentCriterion } from 'clients/manager/interfaces/auction-notice.interface';
import HelpIconLabel from '../../../help-icon-label';

interface SelectJudgmentCriterionViewProps {
    disabled?: boolean;
    defaultValue?: number | string | null;
    options: Array<{ value: JudgmentCriterion; label: string }>;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectJudgmentCriterionView: FC<SelectJudgmentCriterionViewProps> = ({
    options,
    defaultValue,
    onChange,
    disabled,
}) => {
    const { t } = useTranslation();

    return (
        <HelpIconLabel
            title={
                <>
                    <p>{t('info.judgment-criterion')}</p>
                    <p>
                        <b>{t('term.rate-type-bid')}: </b>
                        {t('info.rate-type-bid')}
                    </p>
                </>
            }
        >
            <TextField
                fullWidth
                color='primary'
                name='judgmentCriterion'
                label={t('term.judgment-criterion')}
                onChange={onChange ? debounce(onChange, 300) : undefined}
                variant='outlined'
                select
                defaultValue={defaultValue}
                disabled={disabled}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </HelpIconLabel>
    );
};

export default SelectJudgmentCriterionView;
