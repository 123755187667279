import { doRequest, managerApiInstance } from '../api';
import { OkResponse } from '../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import {
    GetProcessMinuteBody,
    CreateOrganizationMinuteBody,
    DeleteOrganizationMinuteBody,
    ProcessMinuteResponse,
    OrganizationMinuteDetailsResponse,
} from './interfaces/organization-minutes.interface';

const BASE_PATH = 'organization-minutes';
const BASE_PATH_PUBLIC = 'public-organization-minutes';

const organizationMinutesRequests = {
    getProcessMinutes: async (data: GetProcessMinuteBody, isPublic: boolean) => {
        const basePath = isPublic ? BASE_PATH_PUBLIC : BASE_PATH;
        return doRequest<GenericResponse<ProcessMinuteResponse>>(
            managerApiInstance.post(`/${basePath}/getProcessMinutes`, data)
        );
    },
    listOrganizationMinutes: async () =>
        doRequest<GenericResponse<OrganizationMinuteDetailsResponse>>(
            managerApiInstance.post(`/${BASE_PATH}/listOrganizationMinutes`)
        ),
    deleteOrganizationMinute: async (data: DeleteOrganizationMinuteBody) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${BASE_PATH}/deleteOrganizationMinute`, data)
        ),
    createOrganizationMinute: async (data: CreateOrganizationMinuteBody) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${BASE_PATH}/createOrganizationMinute`, data)
        ),
};

export { organizationMinutesRequests };
