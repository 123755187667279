import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { HiringPlanRequest } from 'clients/manager/interfaces/hiring-plans.interfaces';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import { organizationUnitsRequests } from 'clients/manager/organization.requests';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import DialogNewPcaView from './dialog-new-pca-view';

interface DialogNewPcaProps {}

const DialogNewPca: React.FC<DialogNewPcaProps> = () => {
    const { setHiringPlansList, onCloseModalCrudPca, setOrganizationUnitsList } =
        useHiringPlansContext();
    const { t } = useTranslation();
    const [isLoadingSubmitForm, setIsLoading] = useState(false);
    const [isLoadingOrganizationUnits, setIsLoadingOrganizationUnits] = useState(false);

    const form = useFormik<HiringPlanRequest>({
        initialValues: {
            year: new Date().toString(),
        },
        validationSchema: yup.object({
            year: yup.date().required(t('hiring-plans.info.form-required-year-pca')),
            organizationUnitId: yup
                .number()
                .required(t('hiring-plans.info.form-required-purchasing-unit-pca')),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            try {
                const response = await hiringPlansRequests.createHiringPlan({
                    year: new Date(values.year).getFullYear().toString(),
                    organizationUnitId: values.organizationUnitId,
                });
                setHiringPlansList((prevState) => [...prevState, response]);
                resetForm();
                onCloseModalCrudPca();
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('hiring-plans.info.create-pca-success'),
                });
            } catch (err) {
                addNotificationError({
                    title: t('term.error'),
                    message: t('hiring-plans.info.create-pca-error'),
                });
            } finally {
                setIsLoading(false);
            }
        },
    });

    const searchOrganizationUnits = useCallback(async () => {
        setIsLoadingOrganizationUnits(true);
        try {
            const response = await organizationUnitsRequests.listOrganizationUnit();
            setOrganizationUnitsList(response);
        } catch (err) {
            onCloseModalCrudPca();
            addNotificationError({
                title: t('term.error'),
                message: t('hiring-plans.info.get-organization-unit-error'),
            });
        } finally {
            setIsLoadingOrganizationUnits(false);
        }
    }, []);

    useEffect(() => {
        searchOrganizationUnits();
    }, []);

    return (
        <DialogNewPcaView
            form={form}
            isLoadingSubmitForm={isLoadingSubmitForm}
            isLoadingOrganizationUnits={isLoadingOrganizationUnits}
        />
    );
};

export default DialogNewPca;
