const sanitizeText = (text?: string) => {
    if (!text || text === '') {
        return '';
    }

    return text?.replace(/(<([^>]+)>)/gi, '').trim();
};

const sanitizeCnpj = (cnpj?: string) => {
    if (!cnpj || cnpj === '') {
        return '';
    }

    return cnpj?.replace(/[\./-]/gm, '').trim();
};

export { sanitizeText, sanitizeCnpj };
