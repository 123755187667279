import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialogWithJustificationView from './confirm-dialog-with-justification-view';

export interface ConfirmDialogWithJustificationProps {
    message?: string;
    title?: string;
    onConfirm: (justification: string) => void;
    onCancel?: () => void;
    disabled?: boolean;
    children: React.ReactElement;
}

const ConfirmDialogWithJustificationContainer: FC<ConfirmDialogWithJustificationProps> = ({
    children,
    message,
    title,
    onConfirm,
    onCancel,
    disabled = false,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [justification, setJustification] = useState('');

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);
        onCancel?.();
    };

    const handleConfirm = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onConfirm(justification);
        setOpen(false);
    };

    const cloneChildren = React.cloneElement(children, {
        onClick: (ev: React.MouseEvent<HTMLElement>) => {
            children.props.onClick?.(ev);
            ev.stopPropagation();
            if (disabled) {
                return onConfirm(justification);
            }
            setOpen(true);
        },
    });

    return (
        <ConfirmDialogWithJustificationView
            open={open}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            title={title}
            message={message}
            justification={justification}
            setJustification={setJustification}
            cloneChildren={cloneChildren}
            t={t}
        />
    );
};

export default ConfirmDialogWithJustificationContainer;
