import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import { PriceListRequest } from 'clients/price-base/interfaces/price-list-response.interface';
import { YupValidationMessages } from 'common/constants/yup-validation-messages.constant';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { PriceList } from 'common/interfaces/price-list.interface';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import { addNotificationError, addNotificationInfo } from 'common/utils';
import { Content, Actions } from './styled';
import { CreatePriceListFormProps } from './props';

const initialValuesForm: PriceListRequest & { _id?: string } = {
    name: '',
    description: '',
};

const CreatePriceListForm: FC<CreatePriceListFormProps> = ({
    onCancel,
    onPriceListChanged,
    action = 'create',
    initialValues = { ...initialValuesForm },
}) => {
    const { t } = useTranslation();

    const onCreatePriceList = async (form: PriceListRequest) => {
        const payload = {
            ...form,
        };

        try {
            let priceList: PriceList | undefined;

            switch (action) {
                case 'create':
                    priceList = await priceListRequests.createPriceList(payload);
                    addNotificationInfo({
                        title: t('mkp.search.created-list'),
                        message: t('mkp.search.created-list-success'),
                    });
                    break;

                case 'update':
                    await priceListRequests.updatePriceList(initialValues._id as string, payload);
                    priceList = {
                        ...initialValues,
                        ...form,
                    } as PriceList;
                    addNotificationInfo({
                        title: t('mkp.search.updated-list'),
                        message: t('mkp.search.updated-list-success'),
                    });
                    break;

                default:
                    break;
            }

            onPriceListChanged(priceList);
        } catch (error) {
            addNotificationError({
                title: t('term.err'),
                message: t('editor.components.editor.config.error-message'),
            });
        }
    };

    const form = useFormik({
        initialValues,
        onSubmit: (currForm) => {
            onCreatePriceList(currForm);
        },
        validationSchema: Yup.object({
            name: Yup.string().required(YupValidationMessages.REQUIRED),
            description: Yup.string().required(YupValidationMessages.REQUIRED),
        }),
    });

    return (
        <Content>
            <form onSubmit={form.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type='text'
                            name='name'
                            fullWidth
                            autoFocus
                            label={t('term.name')}
                            value={form.values.name}
                            onChange={(ev) => form.setFieldValue('name', ev.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type='text'
                            name='description'
                            fullWidth
                            label={t('term.description')}
                            value={form.values.description}
                            onChange={(ev) => form.setFieldValue('description', ev.target.value)}
                        />
                    </Grid>
                </Grid>

                <Actions>
                    <Button variant='outlined' size='small' type='button' onClick={onCancel}>
                        {t('term.cancel')}
                    </Button>
                    <Button variant='contained' size='small' type='submit'>
                        {action === 'create' ? t('term.create') : t('term.update')}
                    </Button>
                </Actions>
            </form>
        </Content>
    );
};

export default CreatePriceListForm;
