import { FC, useEffect, useState } from 'react';
import { PurgeResume } from 'common/interfaces/purge.interface';
import { usePageContext } from 'common/components/base/pages/context';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import Breadcrumb from 'common/components/base/breadcrumb';
import { useTranslation } from 'react-i18next';
import { purgeRequests } from 'clients/price-base/purge.requests';
import { addNotificationError } from 'common/utils';
import OverView from './components/overView';

const Purge: FC<any> = () => {
    const { t } = useTranslation();
    const [purgeResume, setPurgeResume] = useState<undefined | PurgeResume>(undefined);
    const { setTitle } = usePageContext();

    const fetchPurgeResume = async () => {
        try {
            const resume = await purgeRequests.getPurgeResume();
            setPurgeResume(resume);
        } catch (e) {
            addNotificationError({
                message: t('mkp.settings.error-query-data'),
            });
        }
    };

    useEffect(() => {
        setTitle(t('term.purge'));
        fetchPurgeResume();
    }, []);

    const onGeneratePurgeData = async (beforeAt: number) => {
        try {
            await purgeRequests.createPurgeData(beforeAt);
            await fetchPurgeResume();
        } catch (e) {
            addNotificationError({
                message: t('mkp.settings.error-generate-data'),
            });
        }
    };

    const onPurgeData = async (purgeType: string) => {
        try {
            await purgeRequests.purgeData(purgeType);
        } catch (e) {
            addNotificationError({
                message: t('mkp.settings.error-exec-action'),
            });
        }
    };

    return (
        <PageWrapper>
            <Breadcrumb
                paths={{
                    'banco-precos': {
                        value: t('shared.pages.menu-header.option.price-bank'),
                        disabled: true,
                    },
                    configuracoes: t('term.settings'),
                    expurgo: t('term.purge'),
                }}
            />
            {purgeResume && (
                <OverView
                    resume={purgeResume}
                    onGeneratePurgeData={onGeneratePurgeData}
                    onReload={fetchPurgeResume}
                    onPurgeData={onPurgeData}
                />
            )}
        </PageWrapper>
    );
};

export default Purge;
