import { FC, useCallback } from 'react';
import moment from 'moment';
import { CheckBox } from '@licitar/ui-kit';
import { formatCpfOrCnpj, formatCurrency, getPriceOriginType, sanitizeText } from 'common/utils';
import { ItemPriceModelProps } from './props';
import {
    Content,
    SelectArea,
    ProviderDetails,
    BrandDetails,
    OriginPriceDetails,
    ModelDetails,
    Price,
    Details,
} from './styled';

const ItemPriceModel: FC<ItemPriceModelProps> = ({
    checked,
    setChecked,
    onSelected,
    provider,
    price,
    showSelectItem = true,
}) => {
    const originPriceType = getPriceOriginType(price.priceType);
    const formattedPrice = formatCurrency(price.unitValue);

    const formattedCnpjCpf = useCallback(
        () => (provider?.cpfCnpj ? formatCpfOrCnpj(provider?.cpfCnpj) : '-'),
        [provider?.cpfCnpj]
    );

    return (
        <Content>
            {showSelectItem && (
                <SelectArea>
                    <CheckBox
                        name='price'
                        checked={checked}
                        value={checked}
                        onChange={() => {
                            setChecked(!checked);
                            onSelected();
                        }}
                    />
                </SelectArea>
            )}
            <Details>
                <ProviderDetails>
                    <span title={provider?.name}>{sanitizeText(provider?.name) || '-'}</span>
                    <span title={provider?.cpfCnpj}>{formattedCnpjCpf()}</span>
                </ProviderDetails>
                <BrandDetails title={price.itemBrand}>
                    <span>{sanitizeText(price.itemBrand) || '-'}</span>
                </BrandDetails>
                <ModelDetails title={price.manufacturerDescription}>
                    <span>{sanitizeText(price.manufacturerDescription) || '-'}</span>
                </ModelDetails>
                <OriginPriceDetails>
                    <span>{originPriceType}</span>
                    <span>{moment(price.saleDate).fromNow()}</span>
                </OriginPriceDetails>
                <Price>
                    <span>{formattedPrice}</span>
                </Price>
            </Details>
        </Content>
    );
};

export default ItemPriceModel;
