import { FC, useCallback, useMemo } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { TextField } from 'common/components';
import { debounce } from 'lodash';
import { disableField, processRules } from 'modules/process/process-utils';
import { usePlatformContext } from 'routes/platform.context';
import { useTranslation } from 'react-i18next';
import { processActions } from 'modules/process/process-actions';
import ExpandedContentCard from '../../../expand-content-card';
import { ProcessDefinitionsProps } from './props';
import SelectSupplyCategories from '../select-supply-categories';
import { useProcessFormContext } from '../../../../context/process-form.context';
import ProcessContentHeader from '../process-content-header';
import { getObjectDefinitionFormLabels } from '../../../../context/schema/object-definition-form-schema';

const ProcessDefinitions: FC<ProcessDefinitionsProps> = ({ disabledExpand }) => {
    const { processForm, process } = useProcessFormContext();
    const { platform } = usePlatformContext();
    const { t } = useTranslation();

    const handleChangeSupplyCategories = debounce((supplyCategories: number[]) => {
        processForm?.setFieldValue('supliesProviders', supplyCategories.join(','));
    }, 0);

    const handleChangeSimpleDescription = debounce((event) => {
        processForm?.setFieldValue('simpleDescription', event.target.value);
    }, 300);

    const getSupplyCategories = useCallback(() => {
        if (!processForm?.values.supliesProviders) {
            return [];
        }

        return processForm?.values.supliesProviders?.split(',').map(Number);
    }, [processForm]);

    const visibleSupplyCategories = useMemo(
        () =>
            !processRules.visibleSegmentsByLot(platform) &&
            !processRules.visibleSegmentsByLotToMarketplace(processForm?.values),
        [platform, processForm]
    );

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <ProcessContentHeader
                            title={t('term.object-definition')}
                            partialProcessFormLabels={getObjectDefinitionFormLabels()}
                        />
                    }
                >
                    <Grid container spacing={2}>
                        {visibleSupplyCategories && (
                            <Grid item xs={12}>
                                <SelectSupplyCategories
                                    value={getSupplyCategories()}
                                    onChange={(supplyCategories) =>
                                        handleChangeSupplyCategories(supplyCategories)
                                    }
                                    label={t('term.supply-segments')}
                                    disabled={processActions.cannotEditUntilDispute(process)}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                color='primary'
                                name='simpleDescription'
                                label={t('term.object-notice')}
                                multiline
                                inputProps={{
                                    maxLength: 5000,
                                }}
                                minRows={2}
                                defaultValue={processForm?.values.simpleDescription}
                                onChange={handleChangeSimpleDescription}
                                variant='outlined'
                                disabled={disableField('simpleDescription', process)}
                            />
                        </Grid>
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessDefinitions;
