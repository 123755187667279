import { CONSTANTS } from 'common/constants';
import { getAuthUserToken } from 'modules/auth/session.utils';

const isValidAuthUser = () => {
    const { TOKEN, AUTH_USER } = CONSTANTS.cookies;

    try {
        const authUser = getAuthUserToken();
        if (authUser?.userId) return true;

        localStorage.removeItem(AUTH_USER);
        localStorage.removeItem(TOKEN);
        return false;
    } catch (error) {
        console.log('ERROR: ', error);
        return false;
    }
};

export { isValidAuthUser };
