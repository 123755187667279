import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import {
    CreateProcessInvite,
    ProcessInvite,
    ProcessInviteInfoCard,
} from './interfaces/process-invite.interface';

const processInvitesRequests = {
    listProcessInvites: async (processId: number) =>
        doRequest<GenericResponse<ProcessInvite>>(
            managerApiInstance.post('/process-invites/listProcessInvites', { processId })
        ),
    createProcessInvites: async (processId: number, invites: CreateProcessInvite[]) =>
        doRequest<PlainGenericResponse<ProcessInvite[]>>(
            managerApiInstance.post('/process-invites/createProcessInvites', {
                processId,
                invites,
            })
        ),
    doSendInvites: async (processId: number, participantDocument: string, url: string) =>
        doRequest<PlainGenericResponse<ProcessInvite>>(
            managerApiInstance.post('/process-invites/doSendInvites', {
                processId,
                participantDocument,
                url,
            })
        ),
    deleteInvite: async (body: { id: number; processId: number }) =>
        doRequest<PlainGenericResponse<boolean>>(
            managerApiInstance.post('/process-invites/deleteInvite', body)
        ),
    getProcessInvitesVerification: async (processId: number, onlySended?: boolean) =>
        doRequest<PlainGenericResponse<ProcessInvite>>(
            managerApiInstance.post('/process-invites/getProcessInvitesVerification', {
                processId,
                onlySended: onlySended ?? false,
            })
        ),
    getProviderPendingInvitesVerification: async (params?: { providerId?: number }) =>
        doRequest<GenericResponse<ProcessInviteInfoCard>>(
            managerApiInstance.post(
                '/process-invites/getProviderPendingInvitesVerification',
                params
            )
        ),
};

export { processInvitesRequests };
