import { SelectSegmentCategories } from './segment-categories-providers.interface';
import { SupplyCategory, SupplyCategoryTypeEnum } from './supply-categories.interface';

export interface SupplyCategoriesProviders {
    id: number;
    providerId: number;
    supplyCategoryId: number;
    dateTimeInsert: Date;
    dateTimeUpdate?: Date;
    statusApproved: StatusApprovedSupplyCategories;
}

export interface CompleteSupplyCategoriesProviders {
    id: number;
    providerId: number;
    supplyCategoryId: number;
    dateTimeInsert: Date;
    dateTimeUpdate?: Date;
    statusApproved: StatusApprovedSupplyCategories;
    masterSupplyCategory: SupplyCategory;
    supplyCategory: SupplyCategory;
    dueDate?: string;
    suppliesAs?: SelectSegmentCategories;
    supplyCategoryType?: SupplyCategoryTypeEnum;
    technicalManagerName?: string;
    technicalManagerDocNumber?: string;
    technicalManagerBoardType?: number;
    technicalManagerBoardNumber?: string;
    manufacturerIdentifierNumber?: string;
}

export interface CompleteSupplyCategoriesProvidersSettings {
    id: number;
    providerId: number;
    supplyCategoryId: number;
    dateTimeUpdate?: Date;
    statusApproved: StatusApprovedSupplyCategories;
    dueDate?: string;
    name?: string;
    externalId?: string;
    suppliesAs?: SelectSegmentCategories;
    supplyCategoryType?: SupplyCategoryTypeEnum;
    technicalManagerName?: string;
    technicalManagerDocNumber?: string;
    technicalManagerBoardType?: number;
    technicalManagerBoardNumber?: string;
    manufacturerIdentifierNumber?: string;
}

export interface CompleteSupplyCategoriesProvidersSettingsUpdate {
    id: number;
    providerId: number;
    statusApproved: StatusApprovedSupplyCategories;
    dueDate?: string;
    supplyCategoryProviderId: number;
    suppliesAs?: SelectSegmentCategories;
    supplyCategoryType?: SupplyCategoryTypeEnum;
    technicalManagerName?: string;
    technicalManagerDocNumber?: string;
    technicalManagerBoardType?: number;
    technicalManagerBoardNumber?: string;
    manufacturerIdentifierNumber?: string;
}

export enum StatusApprovedSupplyCategories {
    pending = 'pending',
    approved = 'approved',
    reproved = 'reproved',
    expired = 'expired',
}
