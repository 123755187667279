import { styled } from 'common/theme/styled';

const Content = styled.div<{ retract: boolean }>`
    margin: 15px 0 0 0;
    background: #fff;
    border: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    border-radius: 4px;
    flex: 1;

    ${(props) =>
        props.retract &&
        `
        margin-left: 320px;
    `}
`;

const Wrapper = styled.div`
    display: flex;
`;

const Header = styled.div`
    div {
        padding: 10px 0;
    }

    span {
        color: ${(props) => props.theme.colors.textLight};
        font-weight: 600;
        font-size: 15px;
    }

    display: flex;
    align-items: center;
    padding: 0 25px 0 75px;
    background: ${(props) => props.theme.colors.tableHeader};
`;

const EmptyResults = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-top: 1px ${(props) => props.theme.colors.borderColorDefault} solid;

    span {
        color: ${(props) => props.theme.colors.textLight};
        padding-top: 30px;
    }
`;

const InitialSerchText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-top: 1px ${(props) => props.theme.colors.borderColorDefault} solid;

    span {
        color: ${(props) => props.theme.colors.textLight};
    }
`;

const ProviderHeader = styled.div`
    width: 35%;
`;

const BrandHeader = styled.div`
    width: 17%;
`;

const ModelHeader = styled.div`
    width: 20%;
`;

const OriginHeader = styled.div`
    width: 14%;
`;

const PriceHeader = styled.div`
    width: 14%;
`;

const SkeletonLine = styled.div`
    height: 67px;
    padding: 7px 25px;
    background: #fff;
    border-top: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
`;

const LoadingArea = styled.div`
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.textLight};
    padding: 30px;
    border-top: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
`;

const EmptyImage = styled.img`
    height: 130px;
    cursor: default;
`;

export {
    Content,
    Header,
    EmptyResults,
    InitialSerchText,
    ProviderHeader,
    BrandHeader,
    ModelHeader,
    OriginHeader,
    PriceHeader,
    SkeletonLine,
    LoadingArea,
    EmptyImage,
    Wrapper,
};
