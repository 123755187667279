import { Dispatch, SetStateAction } from 'react';
import { match } from 'react-router-dom';

export interface SignUpProviderProps {
    match: match;
}

export interface LegalRepresentative {
    providerId?: number;
    name: string;
    cpf: string;
    phone: string;
    enail: string;
    job: string;
}

export enum IdentificationTaxRegimeOptions {
    'Outros Enquadramentos' = 0,
    'ME/EPP - Simples Nacional' = 1,
    'MEI' = 3,
}

export interface ApiCnpjWsProviderInfo {
    companyName: string;
    tradingName: string;
    stateCompanyRegistrationNumber: string;
    zipCode: string;
    ibgecode: string;
    country: string;
    street: string;
    district: string;
    state: string;
    city: string;
    numberPlace: string;
    complementPlace: string;
    taxRegime: string | number;
}

export type ApiCnpjWsResponse = {
    data: ApiCnpjWsProviderInfo;
    error: { detalhes: string; status: number; titulo: string };
};

export interface Provider {
    docType?: string;
    docNumber?: string;
    companyName?: string;
    tradingName?: string;
    zipCode?: string;
    publicPlace?: string;
    district?: string;
    ibgeCode?: string | null;
    cityName?: string;
    state?: string;
    numberPlace?: string;
    complementPlace?: string;
    itsSimpleCompany?: number;
    emailForNotifications?: string;
    phoneNumber?: string;
    emailForBilling?: string;
    billingPhoneNumber?: string;
    stateCompanyRegistrationNumber?: string;
    country?: string;
    countryCode?: string;
    countryCodeBilling?: string;
    countryCodeLegalRepresentative?: string;
    socialSecurityCode?: string;
    birthdate?: Date;
    gender?: string;
    docNumberMei?: string;
}

export interface ISignUpContextProviderValue {
    provider?: Provider;
    setProvider?: any;
    setFianancialProvider?: any;
    activeStep?: any;
    setActiveStep?: any;
    handleNext?: any;
    handleBack?: any;
    providerId: string;
    errorMessage?: string;
    setErrorMessage?: any;
    segmentsCategories?: any;
    setSegmentsCategories?: any;
    listLegalRepresentative?: any;
    setListLegalRepresentative?: any;
    legalRepresentative?: LegalRepresentative;
    setCnpjWsProviderInfo?: Dispatch<SetStateAction<ApiCnpjWsProviderInfo>>;
    cnpjWsProviderInfo?: ApiCnpjWsProviderInfo;
    setHasFoundedCnpjWsInfo?: Dispatch<SetStateAction<boolean>>;
    hasFoundedCnpjWsInfo?: boolean;
    setLegalRepresentative?: any;
    userProviderExist?: boolean;
    setUserProviderExist?: any;
    docNumberTextError?: string;
    setDocNumberTextError?: any;
    loading?: boolean;
}
