import React, { createContext, ReactNode, useContext, useState } from 'react';

export type PageContextType = {
    title: string | undefined;
    setTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const PageContext = createContext<PageContextType>({
    title: undefined,
    setTitle: () => {},
});

export const usePageContext = () => useContext(PageContext);

export const PageContextProvider = ({ children }: { children: ReactNode }) => {
    const [title, setTitle] = useState<string | undefined>(undefined);

    return (
        <PageContext.Provider
            value={{
                title,
                setTitle,
            }}
        >
            {children}
        </PageContext.Provider>
    );
};
