import { useEffect } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import OrderPageView from './order-page-view';

const OrderPage = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    useEffect(() => {
        setTitle(t('term.orders'));
    });

    const { data, loading, ...paginationParams } = usePaginatedQuery(
        marketplaceRequests.listOrdersByOrganization,
        undefined,
        { initialLimit: 5 }
    );

    return (
        <OrderPageView
            orders={data?.data ?? []}
            loading={loading}
            paginationParams={paginationParams}
        />
    );
};

export default OrderPage;
