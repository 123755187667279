import React, { CSSProperties, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as DefaultModal, ModalProps as DefaultModalProps } from '@material-ui/core';
import clsx from 'clsx';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { MdClose } from 'react-icons/md';

interface ModalProps extends DefaultModalProps {
    position?: ModalPosition | PositionProps;
    header?: React.ReactElement;
    footer?: React.ReactElement;
    borderRadius?: number;
}

interface PositionProps {
    display: CSSProperties['display'];
    justifyContent: CSSProperties['justifyContent'];
    alignItems?: CSSProperties['alignItems'];
}

enum ModalPosition {
    center = 'center',
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
}

const modalPositionMap: {
    [key in ModalPosition]: PositionProps;
} = {
    [ModalPosition.center]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [ModalPosition.top]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    [ModalPosition.bottom]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    [ModalPosition.left]: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    [ModalPosition.right]: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
};

const Modal: FC<ModalProps> = (props) => {
    const {
        position = ModalPosition.center,
        className,
        children,
        open,
        onClose,
        header,
        footer,
    } = props;
    const { display, justifyContent, alignItems } =
        typeof position !== 'object' ? modalPositionMap[position] : position;

    const useStyles = makeStyles((theme) => ({
        modal: {
            display,
            justifyContent,
            alignItems,
            fontFamily: theme.typography.fontFamily,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
            borderRadius: props.borderRadius ?? 0,
        },
        content: {
            padding: theme.spacing(2),
        },
        header: {
            background: theme.palette.primary.main,
            margin: 0,
            padding: theme.spacing(2, 2),
            color: theme.palette.primary.contrastText,
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    }));

    const classes = useStyles();

    return (
        <div>
            <DefaultModal
                className={className ?? classes.modal}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
                {...props}
                open={open}
            >
                <Fade in={open}>
                    <>
                        <div className={classes.paper}>
                            {header && (
                                <div className={classes.header}>
                                    {header}
                                    <MdClose
                                        style={{ cursor: 'pointer', margin: '0 0 0 5px' }}
                                        onClick={(ev) => onClose?.(ev, 'escapeKeyDown')}
                                    />
                                </div>
                            )}
                            {React.cloneElement(children, {
                                className: clsx(
                                    children.props.className,
                                    classes.content,
                                    'modal-content'
                                ),
                            })}
                            {footer && footer}
                        </div>
                    </>
                </Fade>
            </DefaultModal>
        </div>
    );
};

export type { ModalProps };
export { Modal, ModalPosition };
