import { styled } from 'common/theme/styled';

const Content = styled.div`
    margin: 15px 0 0 0;
    background: #fff;
    border: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    border-radius: 4px;
`;

const Header = styled.div`
    span {
        color: ${(props) => props.theme.colors.textLight};
        font-weight: 600;
    }

    div {
        padding: 15px 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    padding: 0 50px 0 0;
`;

const EmptyList = styled.div`
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.textLight};
    padding: 30px;
    border-top: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
`;

const PriceMountHeader = styled.div`
    width: 15%;
`;

const PriceHeader = styled.div`
    width: 15%;
`;

const DescriptionHeader = styled.div`
    width: 40%;
`;

const MeasureHeader = styled.div`
    width: 20%;
    margin-left: 80px;
`;

export {
    Content,
    Header,
    PriceMountHeader,
    MeasureHeader,
    DescriptionHeader,
    PriceHeader,
    EmptyList,
};
