export interface User {
    name: string;
    cpf: string;
    country: string;
    typeUser: string;
    id: number;
    adminProfile: AdminProfileType;
    active: 1;
    phone: string;
    email: string;
    language: UserLanguages;
    authorizationGroupUsers: AuthorizationGroupUsers[];
    organizationsUsers: OrganizationUser[];
    featureFlags: { [key: string]: boolean };
    lgpdLegalBasis?: string;
    dateTimeInsert?: string;
    dateTimeUpdate?: string;
}

interface OrganizationUser {
    dateTimeInsert: string;
    id: number;
    isAdmin: number;
    organizationId: number;
    organizationUnitId: number;
    userId: number;
}

export interface AuthorizationGroupUsers {
    id: string;
    name: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
}

export enum UserLanguages {
    'pt-BR' = 'pt-BR',
    en = 'en',
}

export enum AdminProfileType {
    default = 'default',
    admin = 'admin',
    partner = 'partner',
    n1 = 'n1',
    super = 'super',
}

export enum TypeUser {
    organization = 'O',
    admin = 'A',
    provider = 'P',
    citizen = 'C',
}

export enum TypeAccess {
    'provider' = 'provider',
    'organization' = 'organization',
    'management' = 'management',
    'citizen' = 'citizen',
}

// POST /users/updateLgpdLegalBasis
interface UpdateLgpdLegalBasisBody {
    allowSendProcessOpportunitiesByEmail: boolean;
}
interface UpdateLgpdLegalBasisResponse extends User {}

// POST /users/sendEmailVerificationCode
interface SendEmailVerificationCodeBody {
    email: string;
}

// POST /users/validateVerificationCode
interface ValidateVerificationCodeBody {
    email: string;
    verificationCode: string;
}
interface ValidateVerificationCodeReponse {
    id: number;
    email: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

// POST /users/existingUserVerificationCode
interface ExistingUserVerificationCodeBody {
    email: string;
    verificationCode: string;
}
interface ExistingUserVerificationCodeReponse {
    id: number;
    email: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

export type {
    UpdateLgpdLegalBasisBody,
    UpdateLgpdLegalBasisResponse,
    SendEmailVerificationCodeBody,
    ValidateVerificationCodeBody,
    ValidateVerificationCodeReponse,
    ExistingUserVerificationCodeBody,
    ExistingUserVerificationCodeReponse,
};
