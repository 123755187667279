import { FC, useState } from 'react';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import { CONSTANTS } from 'common/constants';
import { userRequests } from 'clients/manager/user.requests';
import { StatusResponseEnum } from 'common/enums';
import { useFormik } from 'formik';
import { validateCpf } from 'common/utils';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormTechManagerSearhView from './form-tech-manager-search-view';
import { FormTechManagerSearhProps } from './props';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';

const FormTechManagerSearh: FC<FormTechManagerSearhProps> = ({
    form,
    providerNotFounded = false,
    showModalAddTecnicalResponsible,
    showCpfFields,
    setShowCpfFields
}) => {
    const { IBGEcountries } = useIBGECountries();
    const { t } = useTranslation();
    const { setCountryCode, setTechnicalManagerDocNumber } =
        useProviderUpdateRequestWarningContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [cpfExist, setCpfExist] = useState(false);

    const initialValues = {
        cpf: '',
        country: CONSTANTS.ibgeCodes.BRAZIL,
    };

    function validateCPF(cpf) {
        const validate = validateCpf(cpf);
        return validate || false;
    }

    const getValidationSchema = () => {
        return yup.object({
            country: yup.string().required(t('term.required-field')),
            cpf: yup
                .string()
                .required(t('term.required-field'))
                .when('country', {
                    is: CONSTANTS.ibgeCodes.BRAZIL,
                    then: yup
                        .string()
                        .test(
                            'cpf',
                            t('sign-up.provider.registration-error-invalid-document'),
                            validateCPF
                        ),
                }),
        });
    };

    const onSubmit = async () => {
        await verifyIfCpfExist();
    };

    const tecnicalResponsable = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: getValidationSchema(),
        onSubmit,
    });

    const verifyIfCpfExist = async () => {
        try {
            setLoading(true);
            const result = await userRequests.getUserByDocAndCountry({
                cpf: tecnicalResponsable.values.cpf,
                countryCode: tecnicalResponsable.values.country,
            });

            setTechnicalManagerDocNumber(tecnicalResponsable.values.cpf);

            if (result.status === StatusResponseEnum.success) {
                if (result.data) {
                    const { name, cpf } = result.data;

                    form.setFieldValue('technicalManagerName', name);
                    form.setFieldValue('technicalManagerDocNumber', cpf);
                } else {
                    form.setFieldValue('technicalManagerDocNumber', tecnicalResponsable.values.cpf);
                    showModalAddTecnicalResponsible(true);
                }
                setCpfExist(false);
                setShowCpfFields(true);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const hasCpfCorrectLength =
        tecnicalResponsable.values.country === CONSTANTS.ibgeCodes.BRAZIL
            ? !(tecnicalResponsable.values.cpf.replace(/\D/g, '').length === 11)
            : !(tecnicalResponsable.values.cpf.length > 0);

    const countryOnChange = (e) => {
        if (e.target.value === CONSTANTS.ibgeCodes.BRAZIL) {
            tecnicalResponsable.setFieldValue('cpf', tecnicalResponsable.values.cpf?.slice(0, 11));
            setTechnicalManagerDocNumber(tecnicalResponsable.values.cpf?.slice(0, 11));
        }
        setLoading(false);
        tecnicalResponsable.setFieldValue('country', e.target.value);
        setCountryCode(e.target.value);
    };

    return (
        <FormTechManagerSearhView
            providerNotFoundedError={providerNotFounded}
            form={form}
            tecnicalResponsableForm={tecnicalResponsable}
            countries={IBGEcountries}
            countryOnChange={countryOnChange}
            cpfExist={cpfExist}
            showCpfFields={showCpfFields}
            hasCpfCorrectLength={hasCpfCorrectLength}
            loading={loading}
            newTecnicalResponsible
        />
    );
};

export default FormTechManagerSearh;
