import { TypeUser, User } from '../../common/interfaces/user.interface';

export enum UserActionTypes {
    SET_AUTH_USER = 'SET_AUTH_USER',
    REMOVE_AUTH_USER = 'REMOVE_AUTH_USER',
    SET_AUTH_USER_CURRENT_ACCESS_TYPE = 'SET_AUTH_USER_CURRENT_ACCESS_TYPE',
}

export interface IReduxBaseAction {
    type: UserActionTypes;
}

interface SetAuthUserAction extends IReduxBaseAction {
    type: UserActionTypes.SET_AUTH_USER;
    payload: {
        user: User;
    };
}

interface SetAuthUserCurrentAccessTypeAction extends IReduxBaseAction {
    type: UserActionTypes.SET_AUTH_USER_CURRENT_ACCESS_TYPE;
    payload: {
        accessType: TypeUser;
    };
}

interface RemoveAuthUserAction extends IReduxBaseAction {
    type: UserActionTypes.REMOVE_AUTH_USER;
}

export type UserReducerActions =
    | SetAuthUserAction
    | RemoveAuthUserAction
    | SetAuthUserCurrentAccessTypeAction;
