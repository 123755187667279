import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    input: {
        width: '100%',
    },
    searchArea: {
        display: 'flex',
        alignItems: 'center',
    },
});

export { useStyles };
