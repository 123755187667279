import { styled } from 'common/theme/styled';
import { Card as CardDefault } from '@licitar/ui-kit';
import { VscEdit } from 'react-icons/vsc';

const Card = styled(CardDefault)`
    cursor: pointer;
    min-height: 110px;
`;

const EditIcon = styled(VscEdit)`
    font-size: 20px;
    border-radius: 50%;
    padding: 3px;
    background: #efefef;
    color: ${(props) => props.theme.colors.iconColorDefault};
    border: 1px #ddd solid;
`;

const LastChanges = styled.div`
    span {
        color: ${(props) => props.theme.colors.textLight2};
        font-size: 11px;
    }
`;

const CardActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CardDetails = styled.div`
    display: grid;

    span {
        color: ${(props) => props.theme.colors.textLight};
        line-height: 1.8;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
    }

    span:nth-child(1) {
        font-weight: 600;
    }
`;

export { Card, EditIcon, CardDetails, LastChanges, CardActions };
