import { doRequest, priceBaseInstance } from '../api';
import { RegionCity, RegionMeso, RegionMicro, RegionUf } from './interfaces/region.interface';

const regionsRequests = {
    getRegionCities: async (q: string): Promise<RegionCity[]> =>
        doRequest<RegionCity[]>(priceBaseInstance.get(`/regions/cities?cityName=${q}`)),
    getRegionCitiesByState: async (q: string): Promise<RegionCity[]> =>
        doRequest<RegionCity[]>(priceBaseInstance.get(`/regions/cities?state=${q}`)),
    getRegionUf: async (): Promise<RegionUf[]> =>
        doRequest<RegionUf[]>(priceBaseInstance.get(`/regions/uf`)),
    getMicroRegion: async (q: string): Promise<RegionMicro[]> =>
        doRequest<RegionMicro[]>(priceBaseInstance.get(`/regions/micro-region?uf=${q}`)),
    getMesoRegion: async (q: string): Promise<RegionMeso[]> =>
        doRequest<RegionMeso[]>(priceBaseInstance.get(`/regions/meso-region?uf=${q}`)),
    getRegionsByCityOrIbgeIds: async (q: string[]): Promise<RegionCity[]> =>
        doRequest<RegionCity[]>(
            priceBaseInstance.get(`/regions/cities?cityIdOrIbgeCodeList=${q.join(',')}`)
        ),
};

export { regionsRequests };
