import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import { usePlatformContext } from 'routes/platform.context';
import NotFound from 'routes/not-found';
import SearchInvoices from './pages/search-invoices';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    const { platform } = usePlatformContext();

    if (!platform?.generalSettings?.invoices?.enableModule) {
        return <PrivateRoute path='*' key='*' component={() => <NotFound />} />;
    }

    return (
        <Switch>
            <PrivateRoute
                exact
                path={`${match.path}/`}
                key='/'
                component={() => <SearchInvoices />}
            />
        </Switch>
    );
};

const InvoicesRoutes: JSX.Element[] = [
    <Route path='/faturas' key='/faturas' render={() => <ChildRoutes />} />,
];

export default InvoicesRoutes;
