import { Platform } from 'clients/manager/platforms.requests';
import { processStageToBiddingStageId } from 'common/utils';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { AuctionTypeEnum } from 'common/enums';
import {
    AuctionNoticeLotWithItems,
    LotStatus,
    TypeOfBenefitValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import {
    AuctionNotice,
    AuctionType,
    DetailedAutcionNotice,
    IntegrationTypeFile,
    JudgmentCriterion,
    MethodDispute,
    ProcessStage,
    TypeCancel,
    TypeValueBidTypes,
} from '../../clients/manager/interfaces/auction-notice.interface';
import { AuctionTypeRule } from '../../clients/manager/interfaces/auction-type-rules.interface';
import { processActions } from './process-actions';
import { LegalSupport } from './components/process-data/enum/legal-support.enum';
import { AuctionLotFormValues } from './context/lots.form';

const getDecimalPlaces = (decimalPlaces?: number) => (!decimalPlaces ? 2 : decimalPlaces);

const inInternalPhase = (biddingStageId?: number) =>
    !biddingStageId
        ? false
        : [BiddingStageEnum.draft, BiddingStageEnum.published].includes(biddingStageId);

const processUtils = {
    isKit: (process?: AuctionNotice) =>
        !!(process && TypeValueBidTypes.kit === process.typeValueBid),
    notKit: (process?: AuctionNotice) =>
        !!(process && TypeValueBidTypes.kit !== process.typeValueBid),
    isDecisionProcess: (process?: AuctionNotice) =>
        !!(process && BiddingStageEnum.decision === process.biddingStageId),
    isPublishedProcess: (process?: AuctionNotice) => !!process?.auctionPublishedDate,
    disabledProcess: (process?: AuctionNotice) =>
        !!process?.auctionCanceled && process?.typeCancel !== TypeCancel.suspended,
    finishedProcess: (process?: AuctionNotice) => !!process?.auctionFinished,
    openProcess: (process?: AuctionNotice) =>
        !!(process && !process.auctionFinished && !process.auctionCanceled),
    isTrading: (process?: AuctionNotice) =>
        !!(process && AuctionType.auction === process.auctionType),
    isDispenseWithoutDispute(process?: AuctionNotice | DetailedAutcionNotice): boolean {
        if (!process) {
            return false;
        }

        if ('auctionType' in process) {
            return (
                process.auctionType === AuctionType.dispense &&
                process.methodDispute === MethodDispute.notApplicable
            );
        }

        if ('processType' in process) {
            return (
                process.processType === AuctionTypeEnum.dispense &&
                process.disputeMethod.id === MethodDispute.notApplicable
            );
        }

        return false;
    },
    isAccreditationProcess: (process?: AuctionNotice) =>
        !!(process && AuctionType.accreditation === process.auctionType),
    notAccreditationProcess: (process?: AuctionNotice) =>
        !!(process && AuctionType.accreditation !== process.auctionType),
    isUnenforceabilityProcess: (process?: AuctionNotice) =>
        process && AuctionType.unenforceability === process.auctionType,
    notUnenforceabilityProcess: (process?: AuctionNotice) =>
        process && AuctionType.unenforceability !== process.auctionType,
    isPublicSale: (process?: AuctionNotice) =>
        !!(process && AuctionType.publicSale === process.auctionType),
    isConcurrenceProcess: (process?: AuctionNotice) =>
        !!(process && AuctionType.concurrence === process.auctionType),
    notConcurrenceProcess: (process?: AuctionNotice) =>
        !!(process && AuctionType.concurrence !== process.auctionType),
    isDispenseProcess: (process?: AuctionNotice) =>
        !!(process && AuctionType.dispense === process.auctionType),
    notDispenseProcess: (process?: AuctionNotice) =>
        process && AuctionType.dispense !== process.auctionType,
    isBestTechnique: (process?: AuctionNotice) =>
        !!(process && process.judgmentCriterion === JudgmentCriterion.bestTechnique),
    isHighestPrice: (process?: AuctionNotice) =>
        !!(process && process.judgmentCriterion === JudgmentCriterion.highestPrice),
    isTechniqueAndPrice: (process?: AuctionNotice) =>
        !!(process && process.judgmentCriterion === JudgmentCriterion.techniqueAndPrice),
    isMethodDisputeOpen: (process?: AuctionNotice) =>
        !!(process && process?.methodDispute === MethodDispute.aberto),
    isNewLaw: (legalSupportId: number | undefined) =>
        !!(legalSupportId && legalSupportId > 0 && legalSupportId < 999),
    isImportedByManagementSystem: (process?: AuctionNotice) =>
        !!(
            process?.integrationFileModel &&
            process.integrationFileModel === IntegrationTypeFile.managementSystem
        ),
    isMarketplace: (process?: AuctionNotice) =>
        !!(
            process &&
            processUtils.isAccreditationProcess(process) &&
            process.legalSupportId === LegalSupport._14133_2021_Art79_III
        ),
    notIsMarketplace: (process?: AuctionNotice) =>
        !!(
            !process ||
            processUtils.notAccreditationProcess(process) ||
            process.legalSupportId !== LegalSupport._14133_2021_Art79_III
        ),
    isAllLotsFrustred: (lots: AuctionNoticeLotWithItems[]) => {
        if (lots.length === 0) return false;

        const createdLots = lots.filter((lot) => Boolean(lot.id));

        if (createdLots.length === 0) return false;

        const frustratedLots = createdLots.filter(
            (lot) => Boolean(lot.isItFrustrated) || lot.status === LotStatus.canceled
        );

        return createdLots.length === frustratedLots.length;
    },
    inPncp: (pncpLink: string | undefined): boolean => !!pncpLink,
    isAlreadyPublished: (biddingStageId?: BiddingStageEnum): boolean =>
        !!biddingStageId && biddingStageId > BiddingStageEnum.draft,
};

const processRules = {
    visiblePriceRegistration: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            [AuctionType.auction, AuctionType.dispense, AuctionType.concurrence].includes(
                process.auctionType
            )
        ),
    visibleSourceOfResources: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            [
                AuctionType.auction,
                AuctionType.dispense,
                AuctionType.concurrence,
                AuctionType.unenforceability,
            ].includes(process.auctionType)
        ),
    visibleTechnicalCriteria: (process?: AuctionNotice): boolean =>
        !!(process && process.judgmentCriterion === JudgmentCriterion.bestTechnique),
    visibleTechnicalAndPriceCriteria: (process?: AuctionNotice): boolean =>
        !!(process && process.judgmentCriterion === JudgmentCriterion.techniqueAndPrice),
    visibleJudgmentCriterion: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            [AuctionType.auction, AuctionType.dispense, AuctionType.concurrence].includes(
                process.auctionType
            )
        ),
    visibleTypeValueBid: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            [
                AuctionType.auction,
                AuctionType.dispense,
                AuctionType.concurrence,
                AuctionType.publicSale,
            ].includes(process.auctionType)
        ),
    visibleMethodDispute: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            [
                AuctionType.auction,
                AuctionType.concurrence,
                AuctionType.publicSale,
                AuctionType.dispense,
            ].includes(process.auctionType)
        ),
    visibleDisputeDuration: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            [AuctionType.dispense].includes(process.auctionType) &&
            process.methodDispute === MethodDispute.aberto
        ),
    visibleImpugnationDate: (process?: AuctionNotice): boolean =>
        !(
            process &&
            [AuctionType.dispense, AuctionType.unenforceability].includes(process.auctionType)
        ),
    visibleClarificationDate: (
        process?: AuctionNotice,
        auctionTypeRule?: AuctionTypeRule
    ): boolean => {
        const diasUteis = auctionTypeRule?.generalSettings?.solicitacoes.esclarecimentos.diasUteis;
        if (diasUteis === '0' || diasUteis === '') {
            return false;
        }
        if (
            process &&
            [AuctionType.dispense, AuctionType.unenforceability].includes(process.auctionType)
        ) {
            return false;
        }

        return true;
    },
    visibleLotTypeOfBenefit: (
        auctionTypeRule?: AuctionTypeRule,
        process?: AuctionNotice
    ): boolean =>
        !!(
            process &&
            auctionTypeRule &&
            [AuctionType.auction, AuctionType.dispense, AuctionType.concurrence].includes(
                process.auctionType
            ) &&
            auctionTypeRule.generalSettings?.cadastro?.aplicarLei123
        ),
    visibleLotConfidentialValue: (
        auctionTypeRule?: AuctionTypeRule,
        process?: AuctionNotice
    ): boolean =>
        !!(
            process &&
            Number(auctionTypeRule?.generalSettings?.cadastro?.sigilosidadeDosLotes) === 0 &&
            [AuctionType.auction, AuctionType.dispense, AuctionType.concurrence].includes(
                process.auctionType
            )
        ),
    visibleLotMultipleWinners: (auctionTypeRule?: AuctionTypeRule): boolean =>
        !!(Number(auctionTypeRule?.generalSettings?.cadastro?.permitirMultiplosVencedores) === 1),
    visibleLotBidAmountDifference: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            ![AuctionType.accreditation, AuctionType.unenforceability].includes(process.auctionType)
        ),
    visibleLotMarkIsRequired: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            ![
                AuctionType.accreditation,
                AuctionType.publicSale,
                AuctionType.unenforceability,
            ].includes(process.auctionType)
        ),
    visibleLotForceSameDescription: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            ![
                AuctionType.accreditation,
                AuctionType.publicSale,
                AuctionType.unenforceability,
            ].includes(process.auctionType)
        ),
    visibleLotRequireDocuments: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            ![AuctionType.accreditation, AuctionType.publicSale].includes(process.auctionType)
        ),
    visibleLotAmount: (process?: AuctionNotice): boolean =>
        !!(process && ![AuctionType.accreditation].includes(process.auctionType)),
    visibleLotTypeOfItems: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            (!processUtils.isAccreditationProcess(process) || processUtils.isMarketplace(process))
        ),
    visibleLotAdvancedInfo: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            [
                AuctionType.unenforceability,
                AuctionType.auction,
                AuctionType.dispense,
                AuctionType.concurrence,
                AuctionType.publicSale,
                AuctionType.accreditation,
            ].includes(process.auctionType)
        ),
    visibleDisputeRoomRedirectIcon: (process?: AuctionNotice): boolean =>
        !!(
            process &&
            process.biddingStageId &&
            processUtils.notAccreditationProcess(process) &&
            processUtils.notUnenforceabilityProcess(process) &&
            processStageToBiddingStageId([
                ProcessStage.dispute,
                ProcessStage.contract,
                ProcessStage.decision,
            ]).includes(process.biddingStageId)
        ),
    visibleIsPhaseReversal: (
        auctionTypeRule?: AuctionTypeRule,
        process?: AuctionNotice
    ): boolean => {
        if (!process) {
            return false;
        }

        return !!(
            auctionTypeRule?.generalSettings?.processo?.sequenciaFases?.invertida ||
            auctionTypeRule?.generalSettings?.processo?.sequenciaFases?.normal
        );
    },
    visibleLotItemsKits: (auctionTypeRule?: AuctionTypeRule, process?: AuctionNotice): boolean =>
        !auctionTypeRule?.generalSettings?.cadastro?.ocultarQuantitativoDoLote &&
        !!(process && process.typeValueBid === TypeValueBidTypes.kit) &&
        ![AuctionType.accreditation, AuctionType.unenforceability].includes(process.auctionType),
    visibleLotReservedQuotaSelection: (process?: AuctionNotice): boolean =>
        inInternalPhase(process?.biddingStageId),
    visibleSegmentsByLot: (platforms?: Platform, process?: AuctionNotice): boolean =>
        !!(
            platforms?.generalSettings?.proposals?.allowBidsOnlyFromTheSameSupplySegment ||
            (process && [AuctionType.unenforceability].includes(process.auctionType))
        ),
    visibleSegmentsByLotToMarketplace: (process?: AuctionNotice): boolean =>
        processUtils.isMarketplace(process),
    visibleUnenforceableNumber: (process?: AuctionNotice): boolean =>
        !(
            process &&
            (![AuctionType.accreditation].includes(process.auctionType) ||
                processUtils.isMarketplace(process))
        ),
    visibleRangeRegionParticipation: (
        auctionTypeRule?: AuctionTypeRule,
        process?: AuctionNotice
    ): boolean =>
        !!(
            process &&
            auctionTypeRule?.generalSettings?.cadastro?.aplicarLei123 &&
            ![AuctionType.accreditation].includes(process.auctionType)
        ),
    visibleBudgetAllocationNumber: (process?: AuctionNotice): boolean => {
        return !!(
            process &&
            (![AuctionType.accreditation, AuctionType.publicSale].includes(process.auctionType) ||
                processUtils.isUnenforceabilityProcess(process))
        );
    },
    visibleDecimalPlaces: (process?: AuctionNotice): boolean =>
        !!(process && ![AuctionType.accreditation].includes(process.auctionType)),
    visibleMethodDisputeClosed: (
        auctionTypeRule?: AuctionTypeRule,
        process?: AuctionNotice
    ): boolean =>
        !!(
            (process && auctionTypeRule && auctionTypeRule.generalSettings.cadastro.modoFechado) ||
            ((processUtils.isTechniqueAndPrice(process) || processUtils.isBestTechnique(process)) &&
                processUtils.isConcurrenceProcess(process))
        ),
    visibleMethodDisputeClosedOpened: (
        auctionTypeRule?: AuctionTypeRule,
        process?: AuctionNotice
    ): boolean =>
        !!(
            process &&
            auctionTypeRule &&
            auctionTypeRule.generalSettings.cadastro.modoFechadoAberto &&
            (processUtils.isConcurrenceProcess(process) || processUtils.isTrading(process))
        ),
    visibleRandomEndTime: (auctionTypeRule?: AuctionTypeRule, process?: AuctionNotice) =>
        !!(
            Number(auctionTypeRule?.generalSettings.cadastro.permitirRandomico) > 0 &&
            processUtils.isMethodDisputeOpen(process) &&
            !processUtils.isDispenseProcess(process) &&
            !processUtils.isAccreditationProcess(process)
        ),
    visibleAllowMultipleWinners: (
        process?: AuctionNotice,
        auctionTypeRule?: AuctionTypeRule,
        lot?: AuctionLotFormValues
    ) =>
        ![TypeOfBenefitValue.masterQuota, TypeOfBenefitValue.reservedQuota].includes(
            lot?.typeOfBenefit ?? TypeOfBenefitValue.noBenefit
        ) &&
        Number(auctionTypeRule?.generalSettings?.cadastro?.permitirMultiplosVencedores) === 1 &&
        !processUtils.isUnenforceabilityProcess(process),
    visiblePublicationStepCreateProcess: (process?: AuctionNotice) =>
        !(
            processUtils.isAccreditationProcess(process) ||
            (processUtils.isUnenforceabilityProcess(process) &&
                (process?.biddingStageId === undefined ||
                    BiddingStageEnum.draft === process?.biddingStageId))
        ),
};

const disableField = (
    field: string,
    process: AuctionNotice | undefined,
    ...moreValidations: boolean[]
) => {
    const isDispensePublished = !!(
        processUtils.isDispenseProcess(process) &&
        process &&
        process.biddingStageId > BiddingStageEnum.draft
    );

    const processIsPublished = !!(process && process.biddingStageId > BiddingStageEnum.draft);

    const judgmentCriterionIsBestTechnique =
        process?.judgmentCriterion === JudgmentCriterion.bestTechnique ||
        process?.judgmentCriterion === JudgmentCriterion.techniqueAndPrice;

    const isDisabled = moreValidations.length
        ? moreValidations.some((validation) => validation === true)
        : false;
    const cannotEdit = processActions.cannotEditUntilDispute(process) || isDisabled;
    const disableField = {
        auctionType: cannotEdit || processIsPublished,
        legalSupportId: cannotEdit,
        auctionNumber: cannotEdit,
        accreditationNumber: cannotEdit,
        unenforceableNumber: cannotEdit,
        rangeRegionParticipation: cannotEdit,
        disputeDuration: cannotEdit,
        methodDispute: cannotEdit || judgmentCriterionIsBestTechnique || isDispensePublished,
        judgmentCriterion: cannotEdit,
        typeValueBid: cannotEdit,
        decimalPlaces: cannotEdit,
        isPhaseReversal: cannotEdit,
        randomEndTime: cannotEdit,
        auctionStartDate: cannotEdit,
        auctionEndDate: cannotEdit,
        startDateTimeDispute: cannotEdit,
        itsPriceRegistration: cannotEdit,
        hideSearches: cannotEdit,
        simpleDescription: cannotEdit,
        bidAmountDifference: cannotEdit,
    };
    return disableField[field] === true;
};

export { getDecimalPlaces, inInternalPhase, processRules, processUtils, disableField };
