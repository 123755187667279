import { FC } from 'react';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import { Button } from 'common/components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SyncIcon from '@material-ui/icons/Sync';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { DeleteIcon } from 'common/icons';
import ErrorStatus from 'modules/process/components/error-status';
import { AuctionLotWithItemsForm } from 'modules/process/context/lots.form';
import { FormikProps } from 'formik';
import { formatComplement, formatLotId } from 'common/utils';
import { getFormErrorsLabels } from '../../form-errors';
import { ProcessLotHeaderProps } from './process-lot-header';
import useStyles from './process-lot-header-style';

interface ProcessLotHeaderViewProps extends ProcessLotHeaderProps {
    processLotsForm: FormikProps<AuctionLotWithItemsForm>;
    lotItemIdentifier: number;
    isLotValid: boolean;
    canDeleteLot: boolean;
    canEditLot: boolean;
}

const ProcessLotHeaderView: FC<ProcessLotHeaderViewProps> = ({
    lot,
    lotHasChanged,
    visibleAdvancedInfoBlock,
    expandedLot,
    onExpandLot,
    onClickDeleteLot,
    onClickAdvancedInfo,
    canEditLot,
    canDeleteLot,
    isLotValid,
    lotItemIdentifier,
    processLotsForm,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box
            className={classes.root}
            onClick={(event) => {
                event.stopPropagation();
                onExpandLot(!expandedLot);
            }}
        >
            <Box className={classes.leftSection}>
                <div className={classes.lotInfo}>
                    <div className={classes.lotLabel}>{`${t('term.lot')}`}</div>
                    <div>{formatLotId(lotItemIdentifier)}</div>
                    <div className={classes.lotId}>
                        <span>{lot.externalItemId ? `(${lot.externalItemId})` : ''}</span>
                    </div>
                    {visibleAdvancedInfoBlock && (
                        <Button
                            className={classes.advancedInfoButton}
                            variant='text'
                            color='link'
                            size='small'
                            onClick={(event) => {
                                event.stopPropagation();
                                onClickAdvancedInfo();
                            }}
                        >
                            <b>{t('term.info-advanced')}</b> &nbsp;
                            <KeyboardArrowDownIcon />
                        </Button>
                    )}
                </div>
                {canEditLot && (
                    <ErrorStatus
                        isValid={isLotValid}
                        title={getFormErrorsLabels(processLotsForm, lot.id)}
                    />
                )}
                {lotHasChanged && (
                    <Box className={classes.pendingChanges}>
                        <Tooltip
                            aria-label='info'
                            classes={{ tooltip: classes.tooltip }}
                            title={t('info.pending-lot-changes')}
                        >
                            <SyncIcon className={classes.editIcon} />
                        </Tooltip>
                    </Box>
                )}
                <div>{formatComplement(lot, processLotsForm?.values?.lots)}</div>
            </Box>
            <Box className={classes.rightSection}>
                {canDeleteLot && (
                    <ConfirmDialog
                        title={t('process.components.message-confirm-delete-lot')}
                        message={t('info.message-confirm-delete-lot')}
                        onConfirm={onClickDeleteLot}
                    >
                        <IconButton title={t('info.delete-lot')} aria-label='delete' edge='end'>
                            <DeleteIcon />
                        </IconButton>
                    </ConfirmDialog>
                )}
                <IconButton title={t('info.expand-lot')} aria-label='expand' edge='end'>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ProcessLotHeaderView;
