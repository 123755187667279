import { styled } from 'common/theme/styled';
import { Creatable } from '@licitar/ui-kit';

const Content = styled.div`
    padding: 0 10px;
    width: 310px;
    margin: 15px 7px 0 0;
    background: #fff;
    border: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    border-radius: 4px;
    position: absolute;
`;

const SaleRange = styled.div`
    display: flex;
`;

const FiltersArea = styled.div`
    padding: 10px 0;
    border-top: 1px ${({ theme }) => theme.colors.borderColorDefault} solid;
`;

const CreatableSelect = styled(Creatable)`
    &.customSelect {
        min-width: 210px;
    }
`;

const ActionsArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 25px 0 5px 0;
`;

const FilterContent = styled.div``;

const FilterContentTitle = styled.div`
    margin: 5px 0;

    span {
        color: ${({ theme }) => theme.colors.textLight};
        font-size: 13px;
    }
`;

const Title = styled.h5`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 15px;
    margin: 11px 0;
`;

export {
    Content,
    SaleRange,
    CreatableSelect,
    FiltersArea,
    ActionsArea,
    FilterContent,
    FilterContentTitle,
    Title,
};
