import { AdminProfileType, TypeAccess, User } from '../interfaces/user.interface';
import { Store } from '../../store';
import { getAccessTypeToEnvironment } from './getters/get-access-type-to-environment.utils';

// um usuário pode ter vários tipos de visualização, a maioria das rotas/menus são separados
// conforme este tipo de acesso que vem no token do usuário
const hasCurrentAccessType = (canAccessTypesAccess: TypeAccess[], authUser?: User): boolean => {
    const store = Store.getState();

    if (!store.authUserState.authUser) {
        return false;
    }

    if (!authUser) {
        // eslint-disable-next-line prefer-destructuring
        authUser = store.authUserState.authUser;
    }

    const hasPermission =
        store.authUserState.currentAccessType &&
        canAccessTypesAccess
            .map((accessType) => getAccessTypeToEnvironment(accessType))
            .includes(store.authUserState.currentAccessType);

    return !!hasPermission || !!store.authUserState.authUserTokenData?.isSystemAdmin;
};

// valida se um usuário pode executar uma ação de acordo com o que foi cadastrado
// nos grupos de autorização
const hasPermissionToExecute = (actionName: string): boolean => {
    const store = Store.getState();

    const { authUser, authUserTokenData } = store.authUserState;

    // é super admin, então permite
    if (authUser?.adminProfile === AdminProfileType.super) {
        return true;
    }

    // é admin da organização logada, então permite
    if (
        authUserTokenData?.organizationId &&
        authUser?.organizationsUsers.find(
            (orgUser) =>
                Number(orgUser.organizationId) === Number(authUserTokenData?.organizationId) &&
                orgUser.isAdmin
        )
    ) {
        return true;
    }

    const authorizationGroupUsers = store.authUserState.authUser?.authorizationGroupUsers;
    return !!authorizationGroupUsers?.find((action) => action.name === actionName);
};

const featureFlagEnabled = (featureFlagName: string): boolean => {
    const store = Store.getState();
    return !!store.authUserState.authUser?.featureFlags?.[featureFlagName];
};

const isTestOrganization = () => {
    const store = Store.getState();

    const { authUserTokenData } = store.authUserState;
    // 1 é a unidade de testes em todos os ambientes
    return authUserTokenData?.organizationId === 1;
};

export { hasCurrentAccessType, hasPermissionToExecute, featureFlagEnabled, isTestOrganization };
