import { FC, useState } from 'react';
import { Input } from '@licitar/ui-kit';
import { styled } from 'common/theme/styled';
import { useTranslation } from 'react-i18next';
import { IAdvancedFilters } from 'modules/priceBank/context/interfaces';

const RangeOptions = styled.div`
    font-size: 14px;
    margin: 0 0 4px 0;
`;

const RangeOption = styled.span<{ selected: boolean }>`
    color: ${(props) => props.theme.colors.tertiary};
    cursor: pointer;

    ${(props) =>
        props.selected &&
        `
        text-decoration: underline;
    `}
`;

export enum RangeValueTypes {
    median = 'median',
    defaultValue = 'defaultValue',
}

interface RangeValueFiltersProps {
    advancedFilters: IAdvancedFilters | undefined;
    handleChange: (field: string, value: any) => void;
}

const RangeValueFilters: FC<RangeValueFiltersProps> = ({ advancedFilters, handleChange }) => {
    const [type, setType] = useState(
        advancedFilters?.metadata?.priceRangeType ?? RangeValueTypes.defaultValue
    );

    const handleChangeInput = (currType: RangeValueTypes) => {
        handleChange('metadata.priceRangeType', currType);
        setType(currType);
    };

    const getPrefix = () => {
        if (type === RangeValueTypes.median) {
            return '% ';
        }
        return 'R$ ';
    };

    const { t } = useTranslation();

    return (
        <>
            <RangeOptions>
                <RangeOption
                    onClick={() => handleChangeInput(RangeValueTypes.defaultValue)}
                    selected={type === RangeValueTypes.defaultValue}
                >
                    {t('term.value')}
                </RangeOption>
                {' / '}
                <RangeOption
                    onClick={() => handleChangeInput(RangeValueTypes.median)}
                    selected={type === RangeValueTypes.median}
                >
                    {t('term.median')}
                </RangeOption>
            </RangeOptions>
            <div style={{ display: 'flex' }}>
                {type === RangeValueTypes.defaultValue ? (
                    <>
                        <Input
                            autoFocus
                            name='priceStart'
                            label='Min'
                            type='currency'
                            value={advancedFilters?.priceStart}
                            style={{ margin: '0 5px 0 0' }}
                            prefix={getPrefix()}
                            onChange={(value: number) => handleChange('priceStart', value)}
                        />
                        <Input
                            name='priceEnd'
                            label='Max'
                            value={advancedFilters?.priceEnd}
                            type='currency'
                            prefix={getPrefix()}
                            onChange={(value: number) => handleChange('priceEnd', value)}
                        />
                    </>
                ) : (
                    <>
                        <Input
                            autoFocus
                            name='metadata.medianBetween'
                            label={t('term.between')}
                            type='currency'
                            value={advancedFilters?.metadata?.medianBetween}
                            style={{ width: '100%' }}
                            prefix={getPrefix()}
                            onChange={(value: number) =>
                                handleChange('metadata.medianBetween', value)
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default RangeValueFilters;
