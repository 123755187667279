import React, { useState } from 'react';

import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { auctionNoticeParticipate } from 'clients/manager/auction-notice-participate.request';
import { GetProcessEnabledProvidersParams } from 'clients/manager/interfaces/auction-notice-participate.interface';
import EnabledProvidersContainerView from './EnabledProvidersContainerView';

interface EnabledProvidersContainerProps {
    processId: number;
    disabled?: boolean;
}

const EnabledProvidersContainer: React.FC<EnabledProvidersContainerProps> = ({
    processId,
    disabled,
}) => {
    const [enabledProvidersParams] = useState<GetProcessEnabledProvidersParams>({
        processId,
    });

    const {
        data: enabledProvidersData,
        loading,
        ...paginationParams
    } = usePaginatedQuery(
        auctionNoticeParticipate.getProcessEnabledProviders,
        enabledProvidersParams,
        { skip: disabled }
    );

    const data = enabledProvidersData?.data ?? [];

    const [providerDialogData, setProviderDialogData] = useState({
        open: false,
        processId: 0,
        providerId: 0,
    });

    const handleShowProviderInformations = (id: number, providerId: number) => {
        setProviderDialogData({
            processId: id,
            providerId,
            open: true,
        });
    };

    const handleHideProviderInformations = () => {
        setProviderDialogData((prev) => ({ ...prev, open: false }));
    };

    return (
        <EnabledProvidersContainerView
            {...{
                data,
                loading,
                paginationParams,
                processId,
                providerDialogData,
                handleShowProviderInformations,
                handleHideProviderInformations,
            }}
        />
    );
};

export default EnabledProvidersContainer;
