/* eslint-disable no-alert */
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { useFormik } from 'formik';
import CatalogsService from 'modules/material/services/catalog.service';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ModalCatalogsView from './ModalCatalogsView';

interface ModalCatalogsProps {
    handleClose: () => void;
}

const ModalCatalogs: React.FC<ModalCatalogsProps> = ({ handleClose }) => {
    const { t } = useTranslation();

    const [catalogs, setCatalogs] = useState<any[] | undefined>();

    const initialValues = {
        name: '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('term.required-field')).max(200),
    });

    const onSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
            if (!values.name.trim()) {
                setErrors({ name: t('term.required-field') });
                return;
            }
            const { data } = await CatalogsService.doSave(values);

            if (values.id) {
                setCatalogs((prev = []) =>
                    prev.map((catalog) => (catalog.id === data.id ? data : catalog))
                );
            } else {
                setCatalogs((prev = []) => [...prev, data]);
            }
            addNotificationSuccess();
        } catch {
            addNotificationError({
                message: t('material.pages.edit.ALREADY_EXISTS_CATALOG_WITH_SAME_NAME'),
            });
        } finally {
            setSubmitting(false);
            resetForm();
        }
    };
    const form = useFormik({ initialValues, validationSchema, onSubmit });

    useEffect(() => {
        const getcatalogs = async () => {
            const { data } = await CatalogsService.listCatalogsOrganization();
            setCatalogs(data);
        };

        getcatalogs();
    }, []);

    const handleRemove = async (row: any) => {
        try {
            if (window.confirm(`${t('material.remove-catalog')} ${row.name}?`)) {
                await CatalogsService.doDelete(row.id);
                addNotificationSuccess();
                setCatalogs((prev = []) => prev.filter((catalog) => catalog.id !== row.id));
            }
        } catch {
            addNotificationError({
                message: t('material.pages.edit.error-delete-catalog'),
            });
        }
    };

    return <ModalCatalogsView {...{ handleClose, handleRemove, catalogs, form }} />;
};

export default ModalCatalogs;
