import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse } from 'clients/interfaces/paginated.interface';

export interface CategoriesDocuments {
    id: number;
    categoryName: string;
    description: string;
    categoryType: string;
    dateTimeInsert: string;
    dateTimeUpdate: string;
}
const BASE_PATH = '/categories-documents';

const categoriesDocumentsRequests = {
    listCategoriesDocuments: async () =>
        doRequest<GenericResponse<CategoriesDocuments>>(
            managerApiInstance.post(`${BASE_PATH}/listCategoriesDocuments`)
        ),
};

export { categoriesDocumentsRequests };
