import {
    DocumentsDataProps,
    SegmentsDataProps,
} from 'common/components/provider-update-request-warning/interfaces';
import { LegalRepresentative } from 'modules/sign-up/pages/sign-up-stepper-provider/props';
import { Contract, ContractGeneratedDocument } from './contract.interface';
import {
    RegistrationUpdateRequestPriority,
    RegistrationUpdateRequestStatus,
    RegistrationUpdateRequestType,
} from './provider-registration-update-requests.interface';
import { UserFinancialData } from './user-financial-data.interface';
import { User } from './user.interface';
import { Signer } from './document-signers.interface';

interface ProviderProposalDocument {
    dateTimeInsert: Date | null;
    dateTimeOrganization: Date | null;
    documentTypeId: number | null;
    fileDescription: string;
    filename: string;
    id: number;
    observationOrganization: any;
    statusOrganization: number;
    isDocumentProtected?: boolean | number;
}

interface ProviderContractDocument {
    contract: Contract;
    provider: {
        id: number;
        companyName: string;
    };
    documentSigners?: {
        signers?: Signer[];
        signDocLicitarUrl?: string;
    };
    generatedDocument?: ContractGeneratedDocument;
}

interface Participant {
    email: string;
    name: string;
}

interface Provider {
    externalId: string;
    itsSimpleCompany: number;
    docType: string;
    docNumber: string;
    companyName: string;
    dateTimeInsert: Date;
    dateTimeUpdate: Date | null;
    tradingName: string;
    zipCode: string;
    publicPlace: string;
    district: string;
    cityName: string;
    state: string;
    numberPlace: string;
    complementPlace: string;
    ibgeCode: string | null;
    emailForNotifications: string;
    emailForBilling: string;
    phoneNumber: string;
    billingPhoneNumber: string;
    id: number;
    stateCompanyRegistrationNumber?: string;
    socialSecurityCode?: string;
    signupValidDateTime: Date;
    signupValidExpiration?: Date;
    signupValid?: number;
    suspendedUntil?: Date;
    situation: string;
    userId: number;
    isDefaulting?: number;
    isNotificationsEnabled?: boolean;
    country: string;
    phoneCountryCode: string;
    billingPhoneCountryCode: string;
    versionOfAcceptedTerms?: string;
    birthplace?: number;
    intructionLevel?: number;
    profession?: number;
    gender?: string;
    docNumberMei?: string;
    birthdate?: Date;
    legalRepresentative?: User;
    socialProvider?: {
        docNumberMei?: string;
        socialSecurityCode?: string;
        birthdate?: Date;
        birthplace?: number;
        profession?: number;
        intructionLevel?: number;
        gender?: string;
    };
}

interface DoRequestProviderUpdate extends Omit<Provider, 'ibgeCode'> {
    ibgeCode: number | null;
}
interface ProviderBodyData extends Omit<Provider, 'signupValidExpiration' | 'suspendedUntil'> {
    signupValidExpiration?: string | null;
    suspendedUntil?: string | null;
}

interface DataFinancialData {
    data: UserFinancialData;
    type: string;
}

interface DataProviderUpdateRequest {
    id?: number;
    auctionId?: number;
    priority?: RegistrationUpdateRequestPriority;
    status?: RegistrationUpdateRequestStatus;
    userId?: number;
    updateAt?: string;
    sendAt?: string;
    finishedAt?: string;
    createdAt?: string;
    type?: RegistrationUpdateRequestType;
    providerId?: number;
    operatorId?: number | null | undefined;
    message?: string;
    dataSegments?: SegmentsDataProps[];
    dataLegalRepresentatives?: LegalRepresentative[];
    dataFinancialData?: DataFinancialData[];
    provider?: ProviderUpdateRequest;
    dataDocuments?: DocumentsDataProps[];
}
interface ProviderUpdateRequest {
    externalId?: string;
    itsSimpleCompany?: boolean;
    docType?: string;
    docNumber?: string;
    companyName?: string;
    dateTimeInsert?: Date;
    dateTimeUpdate?: Date | null;
    tradingName?: string;
    zipCode?: string;
    publicPlace?: string;
    district?: string;
    cityName?: string;
    state?: string;
    numberPlace?: string;
    complementPlace?: string;
    ibgeCode?: string | null;
    emailForNotifications?: string;
    emailForBilling?: string;
    phoneNumber?: string;
    billingPhoneNumber?: string;
    id?: number;
    stateCompanyRegistrationNumber?: string;
    socialSecurityCode?: string;
    signupValidDateTime: Date;
    signupValidExpiration?: string;
    signupValid?: number;
    situation?: string;
    userId?: number;
    isDefaulting?: number;
    country?: string;
    phoneCountryCode?: string;
    billingPhoneCountryCode?: string;
    versionOfAcceptedTerms?: string;
}
interface ProviderResponse {
    id: string;
}

interface ProviderCheckExist {
    docNumber: string;
}
interface ProviderCheckExistResponse {
    exists: boolean;
}

interface ProviderLegalRepresentative {
    cpf: string;
    email: string;
    job: string;
    name: string;
    phone: string;
    userId: number;
}

export enum ProviderRequestStatus {
    pending = 0,
    approved = 1,
    reproved = -1,
}

interface ListProviderBodyData {
    limit?: number;
    offset?: number;
    params?: {
        searchedText?: string;
        status?: ProviderRequestStatus;
        categoryId?: number | null | undefined;
        searchType?: string;
    };
}

export interface GetProviderByIdData {
    id?: number;
}

// POST /providers/toggleOpportunities
export interface ToggleOpportunitiesBody {
    isNotificationsEnabled: boolean;
}

export type {
    Provider,
    ProviderBodyData,
    ProviderResponse,
    ProviderCheckExist,
    ListProviderBodyData,
    ProviderContractDocument,
    ProviderProposalDocument,
    ProviderCheckExistResponse,
    ProviderLegalRepresentative,
    ProviderUpdateRequest,
    DataProviderUpdateRequest,
    Participant,
    DoRequestProviderUpdate,
};
