import { MenuItem } from '@material-ui/core';
import { SupplyCategory } from 'clients/manager/interfaces/supply-categories.interface';
import { supplyCategoriesRequests } from 'clients/manager/supply-categories.requests';
import { TextField } from 'common/components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectCategoryProps {
    value: number | undefined | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, category: SupplyCategory) => void;
    omitAllOption?: boolean;
}

const SelectCategory: FC<SelectCategoryProps> = ({ value, onChange, omitAllOption }) => {
    const [supplyCategories, setSupplyCategories] = useState<SupplyCategory[]>([]);

    useEffect(() => {
        const request = async () => {
            try {
                const response = await supplyCategoriesRequests.listSupplyCategories();
                setSupplyCategories(response?.data ?? []);
            } catch (error) {}
        };

        request();
    }, []);

    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='categoryId'
            label={t('term.category')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(
                    event,
                    supplyCategories.find(
                        (category) => category.id === Number(event.target.value)
                    ) as SupplyCategory
                )
            }
            variant='outlined'
            select
            value={value}
        >
            {!omitAllOption && <MenuItem value={undefined}>{t('term.all')}</MenuItem>}
            {supplyCategories.map((category) => (
                <MenuItem value={category.id}>{category.categoryName}</MenuItem>
            ))}
        </TextField>
    );
};

export default SelectCategory;
