import { styled } from 'common/theme/styled';

const Content = styled.div`
    display: flex;
    margin: 0 0 0 25px;
    flex: 1;
`;

const CardTitle = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.textLight};
    justify-content: flex-end;
    line-height: 1.6;
`;

const CardContent = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.textDefault};
    text-align: right;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const CardWrapper = styled.div`
    padding: 0 16px;
    flex: 1;
`;

const Card = styled.div`
    flex: 1;

    &:not(:last-child) {
        margin: 0 15px 0 0;
    }
`;

export { Content, CardContent, CardTitle, Card, CardWrapper };
