import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { LaunchOutlinedIcon } from 'common/icons';
import useStyles from './ConclusionStyle';

export default function ConclusionView({ isCreditCardSelected, downloadInvoice }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <CheckCircleIcon color='secondary' className={classes.checkIcon} />
            {isCreditCardSelected ? (
                <>
                    <Typography variant='h5'>
                        <strong>{t('bidding.checkout.conclusion.credit_card.title')}</strong>
                    </Typography>
                    <Typography>{t('bidding.checkout.conclusion.credit_card.subtitle')}</Typography>
                </>
            ) : (
                <>
                    <Typography variant='h5'>
                        <strong>{t('bidding.checkout.conclusion.invoice.title')}</strong>
                    </Typography>
                    <Typography variant='h5'>
                        <strong>{t('bidding.checkout.conclusion.invoice.subtitle')}</strong>
                    </Typography>
                    <Button
                        size='medium'
                        variant='contained'
                        color='primary'
                        className={classes.invoiceButton}
                        startIcon={<LaunchOutlinedIcon />}
                        onClick={() => downloadInvoice()}
                    >
                        {t('bidding.checkout.conclusion.download_invoice')}
                    </Button>
                </>
            )}
        </div>
    );
}
