import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@licitar/ui-kit';
import { SearchPriceListsProps } from './props';
import { Content } from './styled';

const SearchPriceLists: FC<SearchPriceListsProps> = ({ onTermApplied }) => {
    const { t } = useTranslation();
    const [term, setTerm] = useState<string>('');

    useEffect(() => {
        onTermApplied(term);
    }, [term]);

    return (
        <Content>
            <Input
                name='term'
                style={{
                    minWidth: '330px',
                }}
                label={t('mkp.user-price-list.search-list')}
                type='search'
                value={term}
                onChange={(ev) => setTerm(ev.target.value)}
            />
        </Content>
    );
};

export default SearchPriceLists;
