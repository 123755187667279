import { managerApiInstance } from 'clients/api';

const BASE_PATH = '/payments';

const createCreditCardPayment = (creditCardInfos) =>
    managerApiInstance.post(`${BASE_PATH}/createPayment`, creditCardInfos);

const createInvoicePayment = (invoiceInfos) =>
    managerApiInstance.post(`${BASE_PATH}/createPayment`, invoiceInfos);

const DocumentTypesService = {
    createCreditCardPayment,
    createInvoicePayment,
};

export default DocumentTypesService;
