import React, { useEffect, useState } from 'react';

import { usePlatformContext } from 'routes/platform.context';
import { useIsMount } from 'common/hooks/use-is-mount.hook';
import mime from 'mime';
import { noticesInformationRequests } from 'clients/manager/notice-information.requests';
import { addNotificationApiError, getFilenameFromUrl } from 'common/utils';
import InformationFilesView from './information-files-view';

interface InformationFilesProps {
    type?: 'image' | 'file';
    mutiple?: boolean;
    label?: string;
    onChange: (newFiles: string[]) => void;
    value?: string[];
}

const InformationFiles: React.FC<InformationFilesProps> = ({
    type = 'image',
    mutiple = false,
    label,
    onChange,
    value = [],
}) => {
    const isFirstRender = useIsMount();
    const { platform } = usePlatformContext();

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState<string[]>(value);

    const imageExtensions = '.jpg,.png,.jpeg';
    const fileExtensions = '.zip,.doc,.docx,.xlsx,.xls,.pdf';
    const extensions = type === 'image' ? imageExtensions : fileExtensions;

    const imageAccept = 'image/*';
    const fileAccept =
        platform?.fileSettings?.extensions.map((extension) => mime.getType(extension)).join(', ') ||
        '*';
    const accept = type === 'image' ? imageAccept : fileAccept;

    const handleSelectFile = async (file: File) => {
        try {
            setLoading(true);
            const { data } = await noticesInformationRequests.uploadFileNoticeInformation(file);

            if (mutiple) {
                return setFiles((state) => [...state, data.url]);
            }
            setFiles([data.url]);
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteFile = async (fileIndex: number) => {
        try {
            setLoading(true);
            const file = files[fileIndex];
            const fileName = getFilenameFromUrl(file);

            const response = await noticesInformationRequests.deleteFileNoticeInformation(fileName);

            if (response.status === 'success') {
                setFiles((state) => state.filter((_file, index) => fileIndex !== index));
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isFirstRender) return;

        const timeoutId = setTimeout(() => {
            if (files !== value) {
                onChange(files);
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [files]);

    useEffect(() => {
        if (isFirstRender) return;

        setFiles(value);
    }, [value]);

    return (
        <InformationFilesView
            type={type}
            label={label}
            mutiple={mutiple}
            loading={loading}
            files={files}
            accept={accept}
            extensions={extensions}
            deleteFile={handleDeleteFile}
            selectFile={handleSelectFile}
        />
    );
};

export default InformationFiles;
