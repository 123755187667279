import React from 'react';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { Grid, TablePagination } from '@material-ui/core';
import MarketplaceItemCard from 'modules/marketplace/components/marketplace-item-card';
import {
    ListMarketplaceItemsResponse,
    ListMarketplaceProcessResponse,
    ListSupplyCategoriesByOrganizationResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { useTranslation } from 'react-i18next';
import { EmptyList } from 'modules/marketplace/components';
import { isEmpty } from 'lodash';
import HomeHeader from './components/home-header';
import MarketplaceFilters from './components/marketplace-filters';
import { useStyles } from './home-page-style';

interface HomePageViewProps {
    filterProcesses?: ListMarketplaceProcessResponse[];
    filterCategories?: ListSupplyCategoriesByOrganizationResponse[];
    filtersLoading?: boolean;
    quantityOfItemsOnCart?: number;
    marketplaceItems?: ListMarketplaceItemsResponse[];
    loadingMarketplaceItems?: boolean;
    actionsLoading?: boolean;
    onAddToCart?: (id: number) => Promise<void>;
    onRemoveFromCart?: (id: number) => Promise<void>;
    onItemQuantityChange?: (params: { id: number; quantity: number }) => Promise<void>;
    paginationParams: Omit<
        UsePaginatedQueryResponse<ListMarketplaceItemsResponse>,
        'data' | 'loading' | 'setData'
    >;
}

const ROWS_PER_PAGE_OPTIONS = [10, 20, 30];

const HomePageView: React.FC<HomePageViewProps> = ({
    filterProcesses = [],
    filterCategories = [],
    quantityOfItemsOnCart,
    marketplaceItems,
    loadingMarketplaceItems = false,
    filtersLoading = false,
    actionsLoading = false,
    paginationParams,
    onAddToCart,
    onRemoveFromCart,
    onItemQuantityChange,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const { totalCount, page, limit, setLimit, setPage } = paginationParams;

    return (
        <PageWrapper>
            <HomeHeader quantityOfItemsOnCart={quantityOfItemsOnCart} />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <MarketplaceFilters
                        categories={filterCategories}
                        processes={filterProcesses}
                        loading={filtersLoading}
                        totalItemsFound={marketplaceItems?.length}
                    />
                </Grid>
                <Grid item xs={9}>
                    <section className={styles.container}>
                        {!loadingMarketplaceItems && isEmpty(marketplaceItems) && (
                            <EmptyList
                                title={t('marketplace.home.no-items-found')}
                                subtitle={t('marketplace.home.try-remove-or-create-filters')}
                            />
                        )}
                        {marketplaceItems?.map((item) => (
                            <MarketplaceItemCard
                                key={item.id}
                                item={item}
                                isActionLoading={actionsLoading}
                                onAddToCart={onAddToCart}
                                onRemoveFromCart={onRemoveFromCart}
                                onItemQuantityChange={onItemQuantityChange}
                            />
                        ))}
                        {loadingMarketplaceItems && (
                            <>
                                <MarketplaceItemCard loading />
                                <MarketplaceItemCard loading />
                                <MarketplaceItemCard loading />
                                <MarketplaceItemCard loading />
                            </>
                        )}
                    </section>
                    <TablePagination
                        page={page}
                        count={totalCount}
                        onPageChange={(_e, page) => setPage(page)}
                        rowsPerPage={limit || ROWS_PER_PAGE_OPTIONS[0]}
                        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        onRowsPerPageChange={(event) =>
                            setLimit(Number(event.target.value) ?? ROWS_PER_PAGE_OPTIONS[0])
                        }
                        component='div'
                        labelRowsPerPage={t('text.lines-per-page')}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default HomePageView;
