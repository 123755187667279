import { FC, useState } from 'react';
import { FormTechManagerProps } from './props';
import FormTechManagerView from './form-tech-manager-view';

const FormTechManager: FC<FormTechManagerProps> = ({ form, providerNotFounded = false }) => {
    const [segmentCategory, setSegmentCategory] = useState(
        form.values.suppliesAs || 'manufacturer'
    );

    const handleOnStatusSegmentChange = (segmentoType: string) => setSegmentCategory(segmentoType);

    return (
        <FormTechManagerView
            segmentCategory={segmentCategory}
            handleOnStatusSegmentChange={handleOnStatusSegmentChange}
            providerNotFoundedError={providerNotFounded}
            form={form}
        />
    );
};

export default FormTechManager;
