import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { UseQueryConfigOptions } from './use-query.hook';

interface UseQueryProps<TData, TBody extends any> {
    axiosRequest: (body?: TBody, config?: AxiosRequestConfig<any>) => Promise<TData>;
    body?: TBody;
    config?: {
        skip?: boolean;
        onSuccess?: (data: TData) => void;
        onError?: (err: any) => void;
    };
}

export function useQueryWithBody<TData, TBody extends any>(
    axiosRequest: UseQueryProps<TData, TBody>['axiosRequest'],
    body?: TBody,
    config?: UseQueryConfigOptions<TData>
) {
    const [data, setData] = useState<TData>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        const fetchAPI = async () => {
            try {
                setLoading(true);
                setData(undefined);
                const data = await axiosRequest(body, {
                    signal: abortController.signal,
                });
                setData(data);
                config?.onSuccess?.(data);
            } catch (err) {
                setError(err);
                config?.onError?.(err);
            } finally {
                setLoading(false);
            }
        };

        if (!config?.skip) fetchAPI();
        return () => abortController.abort();
    }, [body]);

    return {
        data,
        setData,
        loading,
        error,
    };
}
