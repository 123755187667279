import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { getColumns } from './columns';
import { CustomLabel } from './styles';

interface LotItemsTableHeadProps {
    showSelectAllRows: boolean;
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}

const LotItemsTableHead: FC<LotItemsTableHeadProps> = ({
    onSelectAllClick,
    numSelected,
    rowCount,
    showSelectAllRows,
}) => {
    const columns = getColumns();
    const { processForm } = useProcessFormContext();
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                {showSelectAllRows && (
                    <TableCell padding='checkbox'>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': t('process.components.select-all-itens-lot'),
                            }}
                            disabled={processUtils.notKit(processForm?.values)}
                        />
                    </TableCell>
                )}
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                    >
                        <CustomLabel>{column.label ? column.label : null}</CustomLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default LotItemsTableHead;
