import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { useLocation } from 'react-router';
import { addNotification } from 'common/utils';
import { NotificationType } from 'common/interfaces/notification.interface';
import { usePlatformContext } from 'routes/platform.context';
import ProfileView from './profile-view';

const Profile = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();
    const { platform } = usePlatformContext();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const isEnableCognitoAccess = Boolean(platform?.generalSettings?.cognito?.enableModule);

    useEffect(() => {
        setTitle(t('shared.authenticated-header.my-profile'));
        const isExternalProviderError = query.get('externalProviderError');

        if (isExternalProviderError)
            addNotification({
                title: t('auth.profile-provider.error'),
                message: t('auth.profile-provider.error.message'),
                type: NotificationType.danger,
                duration: 2000,
            });
    }, []);

    return <ProfileView enableConnectProviders={isEnableCognitoAccess} />;
};

export default Profile;
