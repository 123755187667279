import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Box, Button, Grid, Tooltip } from '@material-ui/core';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';
import { TermSelected } from '.';
import { useStyles } from './contract-term-style';

export interface ContractTermsProps {
    handleSelectTerm: (term: TermSelected) => void;
    contractTerm: {
        terminationNotice: boolean;
        apostilmentTerm: boolean;
        addendum: boolean;
    };
}

const ContractTerms: FC<ContractTermsProps> = ({ handleSelectTerm, contractTerm }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const infoMessage = (contractTerm: string, isDisabled: boolean) =>
        isDisabled ? t('info.contract.create-term-disabled', { value: t(contractTerm) }) : '';
    return (
        <Grid item xs={12} spacing={2}>
            <Box width={1} height={1} display='flex' alignItems='center'>
                <Tooltip
                    title={infoMessage('term.termination-notice', !contractTerm.terminationNotice)}
                >
                    <span>
                        <Button
                            size='small'
                            variant='contained'
                            disabled={!contractTerm.terminationNotice}
                            onClick={() => handleSelectTerm(DocumentTemplateType.terminationNotice)}
                        >
                            {t('term.termination-notice')}
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip
                    title={infoMessage('term.apostilment-term', !contractTerm.apostilmentTerm)}
                >
                    <span>
                        <Button
                            size='small'
                            variant='contained'
                            disabled={!contractTerm.apostilmentTerm}
                            className={classes.button}
                            onClick={() => handleSelectTerm(DocumentTemplateType.apostilmentTerm)}
                        >
                            {t('term.apostilment-term')}
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title={infoMessage('term.addendum', !contractTerm.addendum)}>
                    <span>
                        <Button
                            size='small'
                            variant='contained'
                            disabled={!contractTerm.addendum}
                            className={classes.button}
                            onClick={() => handleSelectTerm(DocumentTemplateType.addendum)}
                        >
                            {t('term.addendum')}
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        </Grid>
    );
};

export default ContractTerms;
