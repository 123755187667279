import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { IntegrationSelectInputProps } from './integration-select-input';

const IntegrationSelectInputView: React.FC<IntegrationSelectInputProps> = ({
    options,
    loading,
    ...props
}) => (
    <TextField
        key={props?.value}
        id='select'
        fullWidth
        color='primary'
        variant='outlined'
        select
        disabled={loading}
        {...props}
    >
        {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.description}
            </MenuItem>
        ))}
    </TextField>
);

export default IntegrationSelectInputView;
