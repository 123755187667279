import { AuctionLotFormValues } from 'modules/process/context/lots.form';
import { Chip, Typography } from '@material-ui/core';
import {
    AuctionNoticeLotWithItems,
    TypeOfBenefitValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import { useTranslation } from 'react-i18next';
import { ClassNameMap } from '@material-ui/styles';
import { useStyles } from './format-lot.style';

export const formatLotId = (itemId: number) => {
    const formattedItem = String(itemId).padStart(2, '0');
    const classes = useStyles();

    return <Typography className={classes.formattedItem}>{formattedItem}</Typography>;
};

const getChip = (label: string, classes: ClassNameMap) => (
    <Chip size='small' className={classes.chipSegment} label={label} />
);

export const formatComplement = (lot: AuctionLotFormValues, lots?: AuctionNoticeLotWithItems[]) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const existQuotaChildren = lots?.find((formLot) => lot.id && formLot.quotaId === lot.id);

    if (lot.typeOfBenefit === TypeOfBenefitValue.masterQuota || existQuotaChildren) {
        const label = `${t('term.master-lot')}`;
        return getChip(label, classes);
    }

    if (lot.quotaId && lot.typeOfBenefit === TypeOfBenefitValue.reservedQuota) {
        const label = `${t('term.reserved-quota')}`;
        return getChip(label, classes);
    }

    return null;
};
