/* eslint-disable no-case-declarations */
import { CircularProgress, IconButton, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { regionsRequests } from 'clients/price-base/regions.requests';
import { addNotification } from 'common/utils';
import { NotificationType } from 'common/interfaces/notification.interface';
import { MdClear } from 'react-icons/md';
import { RegionCollapse, RegionFilterContainer, RegionOption, RegionOptions } from './styled';
import STATES from './states.json';

type RegionFilterValue = {
    state?: { title: string; uf: string };
    region?: { title: string; codes: number[]; type?: 'city' | 'microRegion' | 'mesoRegion' };
};

interface RegionFilterProps {
    onChange: (value: RegionFilterValue) => void;
    value: RegionFilterValue;
}

const RegionTypes = {
    city: 'city',
    microRegion: 'microRegion',
    mesoRegion: 'mesoRegion',
};

const RegionFilter = ({ value, onChange }: RegionFilterProps) => {
    const { t } = useTranslation();

    const [loadingStates, setLoadingStates] = useState(false);
    const [loadingRegions, setLoadingRegions] = useState(false);

    const [states, setStates] = useState<typeof STATES>([]);
    const [regions, setRegions] = useState<{ title: string; codes: number[] }[]>([]);
    const [regionType, setRegionType] = useState<'city' | 'mesoRegion' | 'microRegion'>('city');

    const autoCompleteRef = useRef<HTMLDivElement>();

    const throwNotification = (
        message: string,
        type: NotificationType = NotificationType.danger
    ) => {
        addNotification({
            title: type === NotificationType.danger ? t('bidding.notification-error-occurred') : '',
            message,
            type,
            duration: 3000,
        });
    };

    const regionLabel = useMemo(() => {
        switch (regionType) {
            case 'mesoRegion':
                return t('bidding.filter.region.mesoregion');
            case 'microRegion':
                return t('bidding.filter.region.microregion');
            default:
                return t('bidding.filter.region.city');
        }
    }, [regionType]);

    useEffect(() => {
        onChange({ ...value, region: undefined });
    }, [regionType]);

    useEffect(() => {
        setLoadingStates(true);
        regionsRequests
            .getRegionUf()
            .then((data) => {
                const responseUfs = data.map(({ uf }) => uf);
                setStates(STATES.filter(({ uf }) => responseUfs.includes(uf.toLowerCase())));
            })
            .catch(() => {
                throwNotification(t('bidding.filter.region.region.notification-error-get-uf'));
            })
            .finally(() => setLoadingStates(false));
    }, []);

    useEffect(() => {
        const getRegions = async () => {
            try {
                if (loadingRegions || !value.state?.uf) return;
                autoCompleteRef.current?.querySelector('button')?.click();
                setLoadingRegions(true);

                let gotRegions: { title: string; codes: number[] }[] = [];

                switch (regionType) {
                    case 'mesoRegion':
                        const mesoData = await regionsRequests
                            .getMesoRegion(value.state.uf)
                            .catch(() => {
                                throw new Error('mesoregions-error');
                            });

                        if (mesoData.length === 0) throw new Error('mesoregions-warn-empty');
                        gotRegions = mesoData.map(({ mesoRegion: title, ibgeCodes }) => ({
                            title,
                            codes: ibgeCodes.map((str) => Number(str)),
                        }));
                        break;

                    case 'microRegion':
                        const microData = await regionsRequests
                            .getMicroRegion(value.state.uf)
                            .catch(() => {
                                throw new Error('microregions-error');
                            });

                        if (microData.length === 0) throw new Error('microregions-warn-empty');
                        gotRegions = microData.map(({ microRegion: title, ibgeCodes }) => ({
                            title,
                            codes: ibgeCodes.map((str) => Number(str)),
                        }));
                        break;

                    default:
                        const cityData = await regionsRequests
                            .getRegionCitiesByState(value.state.uf)
                            .catch(() => {
                                throw new Error('cities-error');
                            });

                        if (cityData.length === 0) throw new Error('cities-warn-empty');
                        gotRegions = cityData.map(({ name: title, ibgeCode }) => ({
                            title,
                            codes: [Number(ibgeCode)],
                        }));
                }

                setRegions(gotRegions);
            } catch (e: any) {
                let notificationMessage: string | undefined;
                let notificationType = NotificationType.danger;

                switch (e?.message) {
                    case 'mesoregions-error':
                        notificationMessage = 'error-get-mesoregions';
                        break;

                    case 'microregions-error':
                        notificationMessage = 'error-get-microregions';
                        break;

                    case 'cities-error':
                        notificationMessage = 'error-get-cities';
                        break;

                    case 'mesoregions-warn-empty':
                        notificationMessage = 'warning-empty-mesoregions';
                        notificationType = NotificationType.warning;
                        break;

                    case 'microregions-warn-empty':
                        notificationMessage = 'warning-empty-microregions';
                        notificationType = NotificationType.warning;
                        break;

                    case 'cities-warn-empty':
                        notificationMessage = 'warning-empty-cities';
                        notificationType = NotificationType.warning;
                        break;

                    default:
                        break;
                }

                setRegions([]);
                throwNotification(
                    notificationMessage
                        ? t(`bidding.filter.region.region.notification-${notificationMessage}`)
                        : t('bidding.error.try-again-later'),
                    notificationType
                );
            } finally {
                setLoadingRegions(false);
            }
        };

        getRegions();
    }, [regionType, value.state]);

    useEffect(() => {
        if (value.region?.type) setRegionType(value.region.type);
    }, [value.region]);

    return (
        <RegionFilterContainer>
            <Autocomplete
                key={value.state?.title}
                value={value.state}
                onChange={(_, val) =>
                    onChange({ ...value, state: val || undefined, region: undefined })
                }
                options={states}
                limitTags={1}
                defaultValue={value.state}
                getOptionLabel={(option: { title: string }) => option.title}
                disabled={loadingStates}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='outlined'
                        label={t('bidding.filter.region.uf')}
                        InputProps={
                            loadingStates
                                ? {
                                      endAdornment: (
                                          <CircularProgress color='secondary' size={24} />
                                      ),
                                  }
                                : params.InputProps
                        }
                    />
                )}
            />
            <RegionCollapse in={!!value.state}>
                <RegionOptions active={Object.values(RegionTypes).indexOf(regionType)}>
                    <RegionOption
                        onClick={() => setRegionType('city')}
                        selected={regionType === RegionTypes.city}
                    >
                        {t('bidding.filter.region.city')}
                    </RegionOption>
                    <RegionOption
                        onClick={() => setRegionType('microRegion')}
                        selected={regionType === RegionTypes.microRegion}
                    >
                        {t('bidding.filter.region.microregion')}
                    </RegionOption>
                    <RegionOption
                        onClick={() => setRegionType('mesoRegion')}
                        selected={regionType === RegionTypes.mesoRegion}
                    >
                        {t('bidding.filter.region.mesoregion')}
                    </RegionOption>
                </RegionOptions>
                {!value.region && (
                    <Autocomplete
                        value={value.region}
                        defaultValue={value.region}
                        onChange={(event: any, val) => {
                            if (val) onChange({ ...value, region: { ...val, type: regionType } });
                            else if (event.currentTarget.tagName === 'BUTTON')
                                onChange({ ...value, region: undefined });
                        }}
                        options={regions}
                        limitTags={1}
                        getOptionLabel={(option: { title: string }) => option.title}
                        disabled={loadingRegions}
                        ref={autoCompleteRef}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label={regionLabel}
                                InputProps={
                                    loadingRegions
                                        ? {
                                              endAdornment: (
                                                  <CircularProgress color='secondary' size={24} />
                                              ),
                                          }
                                        : {
                                              ...params.InputProps,
                                              style: {
                                                  textTransform: 'capitalize',
                                              },
                                          }
                                }
                            />
                        )}
                    />
                )}
                {value.region && (
                    <TextField
                        value={value.region?.title}
                        variant='outlined'
                        disabled
                        fullWidth
                        label={regionLabel}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <IconButton
                                    onClick={() => onChange({ ...value, region: undefined })}
                                    size='small'
                                >
                                    <MdClear />
                                </IconButton>
                            ),
                        }}
                    />
                )}
            </RegionCollapse>
        </RegionFilterContainer>
    );
};

export default RegionFilter;
