import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    TablePagination,
    IconButton,
    Grid,
    CircularProgress,
    Tooltip,
    Typography,
} from '@material-ui/core';

import Breadcrumb from 'common/components/base/breadcrumb';
import { Notification } from 'clients/manager/interfaces/notification.interface';
import {
    ListNotificationBodyData,
    notificationsRequests,
} from 'clients/manager/notifications.requests';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { usePageContext } from 'common/components/base/pages/context';
import { ListArea, ViewIcon } from 'modules/editor/templates/pages/template-list/styles';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { BlankArea } from 'modules/bidding/pages/search/styled';
import { useMenuHeaderContext } from 'common/components/authenticated-header/menu-header.context';
import { addNotificationError } from 'common/utils';
import { Header, Span } from './styles';

const rowsPerPageOptions = [10, 20];
const body = {
    limit: rowsPerPageOptions[0],
    offset: 0,
    params: { type: 'push' },
};

const Notifications = () => {
    const { setTitle } = usePageContext();
    const history = useHistory();
    const { setNotificationsCountContext } = useMenuHeaderContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [requestsCount, setRequestsCount] = useState<number>(0);
    const [requestFilters, setRequestFilters] = useState<ListNotificationBodyData>(body);
    const [page, setPage] = useState(0);

    const [notifications, setNotifications] = useState<Notification[]>([]);

    const listNotifications = async (requestFilters: ListNotificationBodyData) => {
        setLoading(true);
        setError('');
        try {
            const response = await notificationsRequests.listNotifications(requestFilters);
            setNotifications(response.data || []);
            setRequestsCount(response?.meta?.count || 0);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            addNotificationError({
                title: t('bidding.notification-error-occurred'),
                message: t('error.response.try-again'),
            });
            setError(t('error.response.try-again'));
        }
    };

    useEffect(() => {
        listNotifications(requestFilters);
        setTitle(t('term.notifications'));
    }, [requestFilters]);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
        setRequestFilters((prevState) => ({
            ...prevState,
            offset: (requestFilters.limit || 0) * newPage,
        }));
    };

    const handleChangeNotificationsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequestFilters((prevState) => ({
            ...prevState,
            limit: parseInt(event.target.value, 10),
        }));
        setPage(0);
    };

    const handleNotificationDetails = async (_event: unknown, notification: Notification) => {
        history.push(`/notificacoes/${notification.id}`);
        setError('');

        if (!notification.isRead) {
            try {
                const {
                    data: { count },
                } = await notificationsRequests.getNotificationsCount();

                setNotificationsCountContext(count - 1);
            } catch (error) {
                setError(t('error.response.try-again'));
                addNotificationError({
                    title: t('bidding.notification-error-occurred'),
                    message: t('error.response.try-again'),
                });
            }
        }
    };

    const header = (
        <Header>
            <Breadcrumb
                customPath='/notificacoes'
                paths={{
                    notificacoes: {
                        value: t('term.notifications'),
                        disabled: true,
                    },
                }}
            />
        </Header>
    );

    return (
        <PageWrapper>
            {loading && (
                <BlankArea>
                    <CircularProgress color='secondary' size={64} />
                </BlankArea>
            )}
            {error && <Typography color='error'>{error}</Typography>}
            {!loading && !error && (
                <>
                    {header}
                    <ListArea>
                        <Table size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('term.notification')}</TableCell>
                                    <TableCell>{t('audit.view.date-hour')}</TableCell>
                                    <TableCell>{t('term.actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notifications.map((notification) => (
                                    <TableRow hover key={notification.id}>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                            style={
                                                notification.isRead
                                                    ? { fontWeight: 'normal' }
                                                    : { fontWeight: 'bold' }
                                            }
                                        >
                                            {notification.subject}
                                            {notification.isRead && (
                                                <Span>
                                                    {`${t('notification.read.date')} ${moment(
                                                        notification.dateTimeRead
                                                    ).format('DD/MM/YYYY HH:mm:ss')}`}
                                                </Span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {moment(notification.dateTimeInsert).format(
                                                'DD/MM/YYYY HH:mm:ss'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                <Tooltip title={t('term.view') as string}>
                                                    <IconButton
                                                        onClick={(e) =>
                                                            handleNotificationDetails(
                                                                e,
                                                                notification
                                                            )
                                                        }
                                                    >
                                                        <ViewIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component='div'
                            count={requestsCount}
                            rowsPerPage={requestFilters.limit || rowsPerPageOptions[0]}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeNotificationsPerPage}
                            labelRowsPerPage={t('text.lines-per-page')}
                        />
                    </ListArea>
                </>
            )}
        </PageWrapper>
    );
};

export default Notifications;
