import { MenuItem } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    onPurgeData: (purgeType: string) => void;
}

const options = [
    {
        label: 'item(mongo)',
        value: 'collectionItem',
    },
    {
        label: 'itemPrice(mongo)',
        value: 'collectionItemPrice',
    },
    {
        label: 'item(elastic)',
        value: 'elasticItem',
    },
];

const Purger: FC<Props> = ({ onPurgeData }) => {
    const { t } = useTranslation();
    const [purgeType, setPurgeType] = useState<any | undefined>(undefined);
    return (
        <div>
            <p>
                <h3>{t('mkp.settings.expurg-data-in')}</h3>
            </p>
            <div style={{ marginBottom: '10px' }}>
                <TextField
                    select
                    fullWidth
                    label={t('term.origin')}
                    onChange={(event) => setPurgeType(event.target.value)}
                    value={purgeType}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => {
                        onPurgeData(purgeType?.value as string);
                    }}
                >
                    Ok
                </Button>
            </div>
        </div>
    );
};

export default Purger as FC<Props>;
