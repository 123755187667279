import { FC, useState } from 'react';
import { ContractWithDetails } from 'clients/manager/interfaces/contract.interface';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { pncpRequests } from 'clients/manager/pncp.requests';
import { contractRequests } from 'clients/manager/contract.requests';
import { useTranslation } from 'react-i18next';
import { useContractsContext } from '../../context/contracts.context';
import { ContractListRowProps } from './props/ContractListRowProps';
import ContractRowView from './contracts-list-row-view';

const ContractListRow: FC<ContractListRowProps> = ({ contractWithDetails }) => {
    const { contract, provider, process } = contractWithDetails;
    const { setContracts } = useContractsContext();
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const { t } = useTranslation();

    const createContract = async () => {
        const { data } = await contractRequests.createContract({
            processId: process.id,
            providerId: provider.id,
            simpleDescription: process.simpleDescription,
        });

        setContracts((prevState) =>
            prevState.map((item) => {
                if (
                    item.process.id === data.auctionNoticeId &&
                    item.provider.id === data.providerId
                ) {
                    return {
                        ...item,
                        contract: data,
                    };
                }
                return item;
            })
        );
    };

    const deleteContract = async (contractWithDetails: ContractWithDetails) => {
        try {
            const data = await contractRequests.deleteContract(contractWithDetails.contract.id);

            if (data.data.ok) {
                return setContracts((prevState) =>
                    prevState.filter(
                        (item) => item?.contract?.id !== contractWithDetails.contract.id
                    )
                );
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const deleteContractOrAta = async (
        itsPriceRegistration: boolean,
        contractId: string,
        justification: string
    ) => {
        if (itsPriceRegistration) {
            return pncpRequests.deleteAta(contractId, justification);
        }
        return pncpRequests.deleteContract(contractId, justification);
    };

    const deleteContractFromPncp = async (contractId: string, justification: string) => {
        try {
            const response = await deleteContractOrAta(
                contractWithDetails.process.itsPriceRegistration,
                contractId,
                justification
            );

            setContracts((prevState) =>
                prevState.map((item) => {
                    if (item?.contract?.id === contract?.id) {
                        return {
                            ...item,
                            contract: {
                                ...contract,
                                ...response.data,
                            },
                        };
                    }
                    return item;
                })
            );

            addNotificationSuccess({
                message: t('info.contract-deleted-pncp'),
                title: t('term.success'),
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const handleContractUpdated = (data: ContractWithDetails) => {
        setContracts((prevState) =>
            prevState.map((item) => {
                if (item?.contract?.id === data.contract.id) {
                    return {
                        ...data,
                    };
                }

                return item;
            })
        );
    };

    return (
        <ContractRowView
            contractWithDetails={contractWithDetails}
            setContracts={setContracts}
            modalEditVisible={modalEditVisible}
            setModalEditVisible={setModalEditVisible}
            createContract={createContract}
            deleteContract={deleteContract}
            deleteContractFromPncp={deleteContractFromPncp}
            handleContractUpdated={handleContractUpdated}
        />
    );
};

export default ContractListRow;
