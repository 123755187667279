import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './order-deadline-countdown-style';

interface OrderDeadlineCountdownViewProps {
    secondsToDeadline: number;
    countdown: string;
}

const OrderDeadlineCountdownView: React.FC<OrderDeadlineCountdownViewProps> = ({
    secondsToDeadline,
    countdown,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <Typography
            variant='body2'
            className={secondsToDeadline > 0 ? styles.running : styles.finished}
        >
            {t('order.term.remmaning-time', { value: countdown })}
        </Typography>
    );
};

export default OrderDeadlineCountdownView;
