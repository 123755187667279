import { styled } from 'common/theme/styled';
import { IoMdEye } from 'react-icons/io';
import { makeStyles } from '@material-ui/core';
import { theme } from 'common/theme';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardSpacing: {
        margin: theme.spacing(1),
    },
});

const VisualizeIcon = styled(IoMdEye)`
    font-size: 22px;
    min-width: 30px;
    color: ${(props) => props.theme.colors.iconColorDefault};
    cursor: pointer;
`;

export { useStyles };
export { VisualizeIcon };
