import { FC, useEffect, useState } from 'react';
import { PriceList } from 'common/interfaces/price-list.interface';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import Breadcrumb from 'common/components/base/breadcrumb';
import { usePageContext } from 'common/components/base/pages/context';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import { addNotificationError } from 'common/utils';
import { usePriceBankContext } from 'modules/priceBank/context/price-list.context';
import { AddedItemsProps } from './props';
import { AddIcon, PriceListCards, CardPriceListAdd, Header } from './styled';
import ModalCreatePriceList from '../../components/modal-create-price-list';
import CardPriceList from '../../components/card-price-list';
import SearchPriceLists from '../../components/search-price-lists';
import ModalEditPriceList from '../../components/modal-edit-price-list';

const UserPriceLists: FC<AddedItemsProps> = () => {
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);
    const [priceListsPersist, setPriceListsPersist] = useState<PriceList[]>([]);
    const [creatingPriceList, setCreatingPriceList] = useState(false);
    const [editingPriceList, setEditingPriceList] = useState<PriceList | undefined>(undefined);

    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const history = useHistory();
    const { setPriceListSelected } = usePriceBankContext();

    const getPriceLists = async () => {
        try {
            const lists = await priceListRequests.getPriceLists();
            setPriceListsPersist(lists);
            setPriceLists(lists);
        } catch (error) {
            addNotificationError({
                message: t('mkp.user-price-list.error-get-list'),
            });
        }
    };

    useEffect(() => {
        setTitle(t('shared.pages.menu-header.option.my-quotes'));
        getPriceLists();
    }, []);

    const handleAddPriceList = () => {
        setCreatingPriceList(true);
    };

    const handleSelectPriceList = (priceList: PriceList) => {
        setPriceListSelected(priceList);
        return history.push(`/banco-precos/minhas-listas/${priceList._id}`);
    };

    const onFilterPriceLists = (term: string) => {
        term = term.toLowerCase();

        const data = priceListsPersist.filter(
            (priceList) =>
                priceList.name.toLowerCase().search(term) > -1 ||
                priceList.description.toLowerCase().search(term) > -1
        );

        setPriceLists([...data]);
    };

    const onPriceListChanged = (priceList: PriceList) => {
        setCreatingPriceList(false);
        setEditingPriceList(undefined);

        const existPriceList = priceLists.find(
            (currPriceList) => currPriceList._id === priceList._id
        );

        if (existPriceList) {
            return setPriceLists((prevState) => [
                ...prevState.map((currPriceList) => {
                    if (currPriceList._id === priceList._id) {
                        return { ...currPriceList, ...priceList };
                    }
                    return currPriceList;
                }),
            ]);
        }
        setPriceLists((prevState) => [...prevState, priceList]);
    };

    return (
        <PageWrapper>
            {!!creatingPriceList && (
                <ModalCreatePriceList
                    opened={creatingPriceList}
                    onPriceListChanged={onPriceListChanged}
                    onClose={() => setCreatingPriceList(false)}
                />
            )}
            {!!editingPriceList && (
                <ModalEditPriceList
                    opened={!!editingPriceList}
                    priceList={editingPriceList as PriceList}
                    onPriceListChanged={onPriceListChanged}
                    onClose={() => setEditingPriceList(undefined)}
                />
            )}
            <Header>
                <Breadcrumb
                    paths={{
                        'banco-precos': {
                            value: t('shared.pages.menu-header.option.price-bank'),
                            disabled: true,
                        },
                        'minhas-listas': t('mkp.search.my-list-price'),
                    }}
                />
                <SearchPriceLists onTermApplied={onFilterPriceLists} />
            </Header>
            <PriceListCards>
                <CardPriceListAdd title={t('term.add-list')} onClick={handleAddPriceList}>
                    <AddIcon />
                </CardPriceListAdd>
                {orderBy(priceLists, 'createdAt', 'desc').map((priceList) => (
                    <CardPriceList
                        key={priceList._id}
                        handleEditPriceList={() => setEditingPriceList(priceList)}
                        priceList={priceList}
                        handleSelectPriceList={handleSelectPriceList}
                    />
                ))}
            </PriceListCards>
        </PageWrapper>
    );
};

export default UserPriceLists;
