/* eslint-disable @typescript-eslint/dot-notation */
import React, { useCallback, useEffect, useState } from 'react';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { useFormik } from 'formik';
import canEditAuthorizationGroup from 'modules/group-authorization/helper/canEditAuthorizationGroup';
import GroupAuthorizationService from 'modules/group-authorization/services/group-authorization.service';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { usePageContext } from 'common/components/base/pages/context';
import * as Yup from 'yup';
import EditGroupAuthorizationView from './EditGroupAuthorizationView';

interface EditGroupAuthorizationProps {
    readOnly?: boolean;
}

const EditGroupAuthorization: React.FC<EditGroupAuthorizationProps> = (props) => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const params = useParams();
    const history = useHistory();

    // eslint-disable-next-line react/destructuring-assignment
    const [readOnly, setReadOnly] = useState<boolean>(props?.readOnly ?? false);

    const initialValues = { name: '', description: '' };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('term.required-field')).max(100),
        description: Yup.string().required(t('term.required-field')).max(200),
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const { data } = await GroupAuthorizationService.doSaveAuthorizationGroup(values);

            history.push(`/grupo-autorizacao/${data.id}`);

            addNotificationSuccess();
        } catch {
            addNotificationError();
        } finally {
            setSubmitting(false);
        }
    };

    const form = useFormik({ initialValues, validationSchema, onSubmit });

    const getGroup = useCallback(async () => {
        const { data: group } = await GroupAuthorizationService.getAuthorizationGroupWithCategories(
            params['id']
        );

        if (!canEditAuthorizationGroup(group)) {
            setReadOnly(true);
        }

        form.setValues(group);
    }, []);

    useEffect(() => {
        setTitle(t('group-auth.pages.edit-group.components.edit-group-auth.group-auth'));

        if (params['id']) {
            getGroup();
        }
    }, []);

    return <EditGroupAuthorizationView {...{ form, readOnly }} />;
};

export default EditGroupAuthorization;
