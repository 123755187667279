import React, { memo } from 'react';
import AdditionalDocuments from './components/additional-documents';
import BasicDocuments from './components/basic-documents';

export interface ProviderDocumentsProps {
    disabled?: boolean;
}

const ProviderDocuments: React.FC<ProviderDocumentsProps> = ({ disabled = false }) => (
    <>
        <BasicDocuments disabled={disabled} />
        <AdditionalDocuments disabled={disabled} />
    </>
);

export default memo(ProviderDocuments);
