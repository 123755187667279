import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { useSearchDocumentTypesContext } from './context/SearchDocumentTypesContext';
import SearchDocumentTypesView from './SearchDocumentTypesView';

const SearchDocumentTypes = () => {
    const { documentTypeDialog, setDocumentTypeDialog } = useSearchDocumentTypesContext();

    const { t } = useTranslation();
    const { setTitle } = usePageContext();

    useEffect(() => {
        setTitle(t('term.document-types'));
    }, []);

    const handleNew = () => setDocumentTypeDialog({ open: true });

    return (
        <SearchDocumentTypesView {...{ handleNew, documentTypeDialog, setDocumentTypeDialog }} />
    );
};

export default SearchDocumentTypes;
