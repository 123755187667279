/* eslint-disable react/no-find-dom-node */
import { Component } from 'react';
import ReactDOM from 'react-dom';
import mask from './mask';
import { CurrencyInputProps } from './props';

class CurrencyInput extends Component<CurrencyInputProps & any, any> {
    inputSelectionStart: number;

    inputSelectionEnd: number;

    theInput: any;

    constructor(props: any) {
        super(props);
        this.prepareProps = this.prepareProps.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.setSelectionRange = this.setSelectionRange.bind(this);
        this.state = this.prepareProps(this.props);

        this.inputSelectionStart = 1;
        this.inputSelectionEnd = 1;
    }

    componentDidMount() {
        const node: any = ReactDOM.findDOMNode(this.theInput);
        let selectionStart: number;
        let selectionEnd: number;

        const { autoFocus, suffix } = this.props;
        const { maskedValue } = this.state;

        if (autoFocus) {
            this.theInput?.focus();
            selectionEnd = maskedValue.length - (suffix?.length ?? 0);
            selectionStart = selectionEnd;
        } else {
            selectionEnd = Math.min(
                node?.selectionEnd,
                this.theInput?.value.length - (suffix?.length ?? 0)
            );
            selectionStart = Math.min(node?.selectionStart, selectionEnd);
        }

        this.setSelectionRange(node, selectionStart, selectionEnd);
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps: any) {
        this.setState(this.prepareProps(nextProps));
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillUpdate() {
        const node: any = ReactDOM.findDOMNode(this.theInput);
        this.inputSelectionStart = node?.selectionStart;
        this.inputSelectionEnd = node?.selectionEnd;
    }

    componentDidUpdate(_: any, prevState: { maskedValue: string }) {
        const { decimalSeparator, suffix, thousandSeparator, prefix, precision } = this.props;
        const { maskedValue } = this.state;

        const node = ReactDOM.findDOMNode(this.theInput);
        const isNegative = false;
        const minPos = (prefix?.length ?? 0) + (isNegative ? 1 : 0);
        let selectionEnd = Math.max(
            minPos,
            Math.min(this.inputSelectionEnd, this.theInput?.value.length - (suffix?.length ?? 0))
        );
        let selectionStart = Math.max(minPos, Math.min(this.inputSelectionEnd, selectionEnd));

        const regexEscapeRegex = /[-[\]{}()*+?.,\\^$|#\s]/g;
        const separatorsRegex = new RegExp(
            `${decimalSeparator.replace(regexEscapeRegex, '\\$&')}|${thousandSeparator.replace(
                regexEscapeRegex,
                '\\$&'
            )}`,
            'g'
        );
        const currSeparatorCount = (maskedValue.match(separatorsRegex) || []).length;
        const prevSeparatorCount = (prevState.maskedValue.match(separatorsRegex) || []).length;
        const adjustment = Math.max(currSeparatorCount - prevSeparatorCount, 0);

        selectionEnd += adjustment;
        selectionStart += adjustment;

        const baselength =
            (suffix?.length ?? 0) +
            (prefix?.length ?? 0) +
            (Number(precision) > 0 ? decimalSeparator.length : 0) +
            Number(precision) +
            1;

        if (maskedValue.length === baselength) {
            selectionEnd = this.theInput?.value.length - (suffix?.length ?? 0);
            selectionStart = selectionEnd;
        }

        this.setSelectionRange(node, selectionStart, selectionEnd);
        this.inputSelectionStart = selectionStart;
        this.inputSelectionEnd = selectionEnd;
    }

    handleChange(event: {
        preventDefault: () => void;
        target: { value: any };
        persist: () => void;
    }) {
        const {
            precision,
            decimalSeparator,
            thousandSeparator,
            allowNegative,
            prefix,
            suffix,
            onChange,
        } = this.props;

        event.preventDefault();
        const { maskedValue, value } = mask(
            event.target.value,
            precision,
            decimalSeparator,
            thousandSeparator,
            allowNegative,
            prefix,
            suffix
        );

        event.persist();

        // eslint-disable-next-line react/no-unused-state
        this.setState({ maskedValue, value }, () => {
            onChange?.(maskedValue, value, event);
        });
    }

    handleFocus() {
        if (!this.theInput) return;
        const { suffix, prefix } = this.props;

        const selectionEnd = this.theInput?.value.length - (suffix?.length ?? 0);
        const isNegative = (this.theInput?.value.match(/-/g) || []).length % 2 === 1;
        const selectionStart = (prefix?.length ?? 0) + (isNegative ? 1 : 0);

        this.inputSelectionStart = selectionStart;
        this.inputSelectionEnd = selectionEnd;
    }

    handleBlur() {
        this.inputSelectionStart = 0;
        this.inputSelectionEnd = 0;
    }

    setSelectionRange(node: any, start: number, end: number) {
        if (document.activeElement === node) {
            node?.setSelectionRange(start, end);
        }
    }

    getMaskedValue() {
        const { maskedValue } = this.state;
        return maskedValue;
    }

    prepareProps(props: Readonly<CurrencyInputProps & any>) {
        const customProps = { ...props };
        delete customProps.onChange;
        delete customProps.value;
        delete customProps.decimalSeparator;
        delete customProps.thousandSeparator;
        delete customProps.precision;
        delete customProps.allowNegative;
        delete customProps.allowEmpty;
        delete customProps.prefix;
        delete customProps.suffix;
        delete customProps.autoFocus;
        delete customProps.component;

        let initialValue = props.value;
        if (initialValue === null) {
            initialValue = props.allowEmpty ? null : '';
        } else {
            if (typeof initialValue === 'string') {
                if (props.thousandSeparator === '.') {
                    initialValue = initialValue.replace(/\./g, '');
                }

                if (props.decimalSeparator !== '.') {
                    initialValue = initialValue.replace(
                        new RegExp(props.decimalSeparator, 'g'),
                        '.'
                    );
                }

                initialValue = initialValue.replace(/[^0-9-.]/g, '');
                initialValue = Number.parseFloat(initialValue);
            }
            initialValue = Number(initialValue).toLocaleString(undefined, {
                style: 'decimal',
                minimumFractionDigits: props.precision,
                maximumFractionDigits: props.precision,
            });
        }

        const { maskedValue, value } = mask(
            initialValue,
            props.precision,
            props.decimalSeparator,
            props.thousandSeparator,
            props.allowNegative,
            props.prefix,
            props.suffix
        );

        return { maskedValue, value, customProps };
    }

    render() {
        const { maskedValue, customProps } = this.state;
        const { component } = this.props;
        const Component = component;

        return (
            <Component
                fullWidth
                {...customProps}
                inputRef={(input) => {
                    this.theInput = input;
                }}
                type='text'
                value={maskedValue}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onMouseUp={this.handleFocus}
            />
        );
    }
}

export { CurrencyInput };
