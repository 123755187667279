// This errors messages must be configured in public/locales/[language]/notification
const apiErrorsMessages = {
    DEFAULT_ERROR: 'error.description',
    PROCESS_CANCELED: 'error.process-canceled',
    CANNOT_CREATE_PARTICIPATE: 'error.dont-possible-register-participation',
    CANNOT_FOUND_PROVIDER_1DOC: 'error.only-suppliers-can-participate',
    CANNOT_SEND_PROPOSAL_OUT_TIME: 'error.participate-time-over',
    PROVIDER_ALREADY_PARTICIPATE: 'error.already-participating',
    SESSION_MANAGEMENT_NOT_ENABLED: 'error.session-disabled',
    PROCESS_FINISHED_OR_CANCELED: 'error.process-canceled-or-finished',
    CANNOT_SEND_SOLICITATION: 'error.cannot-send-solicitation',
    INVALID_FILE_FORMAT: 'error.invalid-file-format',
};

export { apiErrorsMessages };
