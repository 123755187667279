import { FormControl, InputLabel, SelectProps } from '@material-ui/core';
import { SearchMenuItem, StyledSearchSelect } from './styled';

type SearchOptions = {
    name: string;
    value: string | number;
    icon?: JSX.Element;
};

interface SearchSelectProps extends Pick<SelectProps, 'onChange' | 'value'> {
    options?: SearchOptions[];
    label: string;
    style?: React.CSSProperties;
}

const SearchSelect = ({ label, options, onChange, value, style }: SearchSelectProps) => (
    <FormControl {...{ style }} variant='outlined'>
        <InputLabel>{label}</InputLabel>
        <StyledSearchSelect {...{ value, onChange, label }}>
            {options?.map((option, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <SearchMenuItem key={idx} value={option.value}>
                    {option.icon}
                    {option.name}
                </SearchMenuItem>
            ))}
        </StyledSearchSelect>
    </FormControl>
);

export default SearchSelect;
