import { FC, useEffect, useRef } from 'react';
import { debounce, omit } from 'lodash';
import { timeout, urlQueryToObject, serialize, addNotificationError } from 'common/utils';
import { dispatchWindowEvent, UseWindowEvent } from 'common/hooks/events.hook';
import { useLocation } from 'react-router';
import { ItemPricesResponse } from 'clients/price-base/interfaces/item-prices-response.interface';
import { itemRequests } from 'clients/price-base/items.requests';
import { useTranslation } from 'react-i18next';
import {
    initialAdvancedFilters,
    initialFilters,
    useMarketplaceSearchFiltersContext,
} from 'modules/priceBank/context/search-filters.context';
import { MarketplaceWindowEvents } from 'modules/priceBank/marketplace.events';
import { IAdvancedFilters, IFilters } from 'modules/priceBank/context/interfaces';
import { ItemsMetadata } from '../../pages/search-items/interfaces';
import { RangeValueTypes } from '../advanced-filters/range-value-filters';
import TextSearch from '../text-search';
import {
    Content,
    FilterIconFilled,
    FilterIconEmpty,
    ResumeArea,
    TextFilter,
    SearchInfo,
    Search,
} from './styled';
import { TableFiltersProps } from './props';
import ResumeCards from '../resume-cards';

const SCROLL_CONTROLLED_KEY = 'items-end-block';
const SCROLL_AREA_KEY = 'page-child';

const TableHeader: FC<TableFiltersProps> = ({
    pricesSelected,
    onDataFetched,
    itemsMetadata,
    resetItemsMetadata,
}) => {
    const {
        appliedFilters,
        setAppliedFilters,
        loadingMore,
        setLoadingMore,
        notLoadMore,
        setNotLoadMore,
    } = useMarketplaceSearchFiltersContext();
    const { t } = useTranslation();

    const PriceBankContextRef: any = useRef(null);
    PriceBankContextRef.current = { loadingMore, notLoadMore };

    const location = useLocation();

    const prepareAdvancedFilters = (
        currentFilters: IFilters,
        currentItemsMetadata?: ItemsMetadata
    ): IAdvancedFilters | undefined => {
        const deepItemsMetadata = currentItemsMetadata ?? itemsMetadata;
        const deepCurrentFilters = { ...currentFilters };
        const { filters = undefined } = deepCurrentFilters;

        if (!filters || Object.keys(filters).length === 0) {
            return undefined;
        }

        const custom: Partial<IAdvancedFilters> = {};
        // calcula range de valor dependendo do tipo de filtro aplicado
        if (
            filters?.metadata?.priceRangeType === RangeValueTypes.median &&
            deepItemsMetadata?.median &&
            deepItemsMetadata?.median > 0
        ) {
            const medianBetween = filters.metadata?.medianBetween;
            if (!!medianBetween && medianBetween > 0) {
                custom.priceStart = Number(
                    (
                        deepItemsMetadata?.median -
                        (deepItemsMetadata?.median ?? 0) * (medianBetween / 100)
                    ).toFixed(3)
                );

                custom.priceEnd = Number(
                    (
                        deepItemsMetadata?.median +
                        (deepItemsMetadata?.median ?? 0) * (medianBetween / 100)
                    ).toFixed(3)
                );
            }
        } else if (filters.metadata?.priceRangeType === RangeValueTypes.defaultValue) {
            custom.priceStart = filters.priceStart;
            custom.priceEnd = filters.priceEnd;
        } else {
            custom.priceStart = undefined;
            custom.priceEnd = undefined;
        }

        return {
            ...{ ...filters, ...custom },
            metadata: undefined,
        };
    };

    const prepareResponse = (response: ItemPricesResponse, { filters }: IFilters) => {
        const items = response.items.map((item) => {
            if (!!filters?.priceStart && !!filters?.priceEnd) {
                item.prices = item.prices.filter(
                    (price) =>
                        !!filters?.priceStart &&
                        !!filters?.priceEnd &&
                        price.unitValue > filters?.priceStart &&
                        price.unitValue < filters?.priceEnd
                );
            }
            return item;
        });

        return { ...response, items };
    };

    const getItems = async (
        filters?: IFilters,
        currItemsMetadata?: ItemsMetadata,
        canUpdateMetadata?: boolean
    ) => {
        const currentFilters = filters || appliedFilters;
        setLoadingMore(true);

        const preparedAdvancedFilters = prepareAdvancedFilters(currentFilters, currItemsMetadata);
        // aumenta limite já que o filtro de preço é feito no front, se um item tiver um preço pelo menos
        const limit =
            currentFilters.filters?.priceStart || currentFilters.filters?.priceEnd
                ? currentFilters.limit * 2.5
                : currentFilters.limit;

        const skip =
            currentFilters.filters?.priceStart || currentFilters.filters?.priceEnd
                ? currentFilters.skip * 2.5
                : currentFilters.skip;

        try {
            const response = await itemRequests.getItems({
                q: currentFilters.text,
                limit,
                skip,
                ...(preparedAdvancedFilters || {}),
            });

            const { items = [] } = response;

            if (!items.length || items.length < currentFilters.limit) {
                setNotLoadMore(true);
            } else {
                setNotLoadMore(false);
            }
            setLoadingMore(false);

            const preparedResponse = prepareResponse(response, currentFilters);
            return onDataFetched(
                preparedResponse,
                currentFilters.origin === 'search',
                !!canUpdateMetadata
            );
        } catch (error) {
            setLoadingMore(false);
            addNotificationError({
                title: t('term.error'),
                message: t('mkp.search.try-again-query'),
            });
        }
    };

    const scrollToTop = (smooth?: boolean) => {
        const content = document.getElementById(SCROLL_AREA_KEY);
        if (!content) {
            return;
        }
        if (smooth) {
            return content.scrollTo({ top: 0, behavior: 'smooth' });
        }
        content.scrollTop = 0;
    };

    const onScroll = () => {
        setAppliedFilters((prevState) => ({
            ...prevState,
            origin: 'scroll',
            skip: prevState.skip + prevState.limit,
        }));
    };

    const onPageScroll = () => {
        const contentEl = document.getElementById(SCROLL_CONTROLLED_KEY);

        if (!contentEl) {
            return;
        }

        const rect = contentEl.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;
        const isVisible = elemTop >= 0 && elemBottom - elemBottom * 0.23 <= window.innerHeight;

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { loadingMore, notLoadMore } = PriceBankContextRef.current;

        if (isVisible && !loadingMore && !notLoadMore) {
            return onScroll();
        }
    };

    const bindScroll = (bind?: boolean) => {
        const element = document.getElementById(SCROLL_AREA_KEY);

        if (!element) {
            return;
        }

        if (!bind) {
            return element.removeEventListener('scroll', onPageScroll);
        }

        element.addEventListener(
            'scroll',
            debounce(() => onPageScroll(), 200)
        );
    };

    const focusSearchInput = () => {
        const el: any = document.getElementById('search-items');
        if (!el) {
            return;
        }

        timeout(() => !el.focused && el.focus(), 0);
    };

    useEffect(() => {
        bindScroll(true);
        focusSearchInput();

        return () => bindScroll(false);
    }, []);

    useEffect(() => {
        if (appliedFilters.origin === 'scroll') {
            getItems();
        }
    }, [appliedFilters]);

    const createSearchHistory = (text: string) => {
        const queryKeys = urlQueryToObject();
        queryKeys.q = text;
        const search = serialize(queryKeys);

        window.history.pushState('', '', `${location.pathname}?${search}`);
    };

    const applyTextFilter = debounce((value: string) => {
        createSearchHistory(value);

        setAppliedFilters((prevState) => ({
            ...prevState,
            text: value,
            origin: 'search',
            skip: initialFilters.skip,
        }));
    }, 300);

    const resetResults = () => {
        onDataFetched(
            {
                items: [],
                providers: [],
                count: 0,
                max: 0,
                min: 0,
                median: 0,
            },
            true,
            true
        );
        return setAppliedFilters((prev) => ({
            ...initialFilters,
            filters: prev.filters,
        }));
    };

    const handleGetApiItems = (
        filters: IFilters,
        currItemsMetadata?: ItemsMetadata,
        canUpdateMetadata?: boolean,
        resetScroll?: boolean
    ) => {
        if (filters?.text === '') {
            return resetResults();
        }
        getItems(filters, currItemsMetadata, canUpdateMetadata);
        if (resetScroll) {
            return scrollToTop();
        }
    };

    const searchByText = () => {
        const el: any = document.getElementById('search-items');
        if (!el) {
            return;
        }

        const newAppliedFilters: IFilters = {
            ...appliedFilters,
            text: el.value,
            origin: 'search',
            skip: initialFilters.skip,
            fetched: true,
        };

        if (newAppliedFilters?.text === '') {
            return resetResults();
        }

        // se o texto anterior for diferente do atual força update do objeto metadata dos items
        // para que os filtros não fiquem inconsistentes
        if (newAppliedFilters.text !== appliedFilters.text) {
            resetItemsMetadata();

            const resetedAppliedFilters = {
                ...newAppliedFilters,
                filters: initialAdvancedFilters,
            };

            setAppliedFilters(resetedAppliedFilters);
            return handleGetApiItems(
                resetedAppliedFilters,
                {
                    count: 0,
                    max: 0,
                    min: 0,
                    median: 0,
                },
                true,
                true
            );
        }

        // se já foi feito uma pesquisa anteriormente não atualiza os dados da consulta
        // mesmo que o texto seja igual
        const canUpdateItemsMetadata = !!itemsMetadata;

        setAppliedFilters({ ...newAppliedFilters });
        handleGetApiItems(newAppliedFilters, undefined, !canUpdateItemsMetadata);
    };

    const handleAvancedFilters = (filters: IFilters) => getItems(filters);

    const onAdvancedFiltersChanged = (filters: IFilters, forceCloseAdvancedFilters?: boolean) => {
        setAppliedFilters({
            ...filters,
            fetched: !!filters.text,
            origin: 'search',
        });

        if (forceCloseAdvancedFilters) {
            focusSearchInput();
        }
    };

    UseWindowEvent(
        MarketplaceWindowEvents.DO_ADVANCED_FILTERS,
        ({ detail }) => {
            const { filters } = detail;
            return handleAvancedFilters(filters);
        },
        []
    );

    UseWindowEvent(
        MarketplaceWindowEvents.ADVANCED_FILTERS_CHANGED,
        ({ detail }) => {
            const { filters, forceCloseAdvancedFilters } = detail;
            return onAdvancedFiltersChanged(filters, forceCloseAdvancedFilters);
        },
        []
    );

    const createRawItem = () =>
        dispatchWindowEvent(MarketplaceWindowEvents.REQUEST_CREATE_ITEM, {});

    const onClickAdvancedFilters = () =>
        dispatchWindowEvent(MarketplaceWindowEvents.ENABLE_DISABLE_ADVANCED_FILTERS, {});

    const validAdvancedFilters = omit(appliedFilters.filters, 'metadata');

    return (
        <Content>
            <ResumeArea>
                <TextFilter>
                    <Search>
                        <TextSearch
                            appliedFilters={appliedFilters}
                            applyTextFilter={applyTextFilter}
                            searchByText={searchByText}
                            loadingMore={loadingMore}
                        />
                        {appliedFilters.text && appliedFilters.fetched && (
                            <>
                                {!!validAdvancedFilters &&
                                Object.keys(validAdvancedFilters).length > 0 ? (
                                    <FilterIconFilled
                                        title={t('mkp.search.filters-applied')}
                                        onClick={onClickAdvancedFilters}
                                    />
                                ) : (
                                    <FilterIconEmpty
                                        title={t('mkp.search.no-filters-applied')}
                                        onClick={onClickAdvancedFilters}
                                    />
                                )}
                            </>
                        )}
                    </Search>
                    <SearchInfo>
                        {itemsMetadata?.count ? (
                            <span>{`${
                                itemsMetadata?.count === 10000
                                    ? `${itemsMetadata?.count}+`
                                    : itemsMetadata?.count
                            } ${t('mkp.search.found-items')}`}</span>
                        ) : (
                            <span />
                        )}
                        <span
                            role='button'
                            tabIndex={-30}
                            onKeyDown={() => createRawItem()}
                            onClick={() => createRawItem()}
                            title={t('mkp.search.add-manual-item-list')}
                        >
                            {t('mkp.search.manually-add')}
                        </span>
                    </SearchInfo>
                </TextFilter>
                <ResumeCards pricesSelected={pricesSelected} />
            </ResumeArea>
        </Content>
    );
};

export default TableHeader;
