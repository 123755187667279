import { Modal, ModalPosition } from 'common/components';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { Button, ButtonSize } from '@licitar/ui-kit';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VARIABLE_TRIGGER } from '../../../components/editor';
import { VariablesListProps } from './props';
import {
    ContentScroll,
    VariableItem,
    SeparadorTitle,
    EmptyCustomVariable,
    EditIcon,
} from './styles';

const VariablesList: FC<VariablesListProps> = ({
    variables,
    onClose,
    visible,
    onClickCreateVariable,
    onClickEditVariable,
}) => {
    const { customs, defaults, auction } = variables;
    const { t } = useTranslation();

    UseWindowEvent(
        'keydown',
        (event) => {
            const { key } = event;
            if (key === 'Escape') {
                onClose();
            }
        },
        []
    );

    return (
        <Modal
            position={ModalPosition.right}
            open={visible}
            onClose={onClose}
            header={<span>Variáveis disponíveis</span>}
        >
            <ContentScroll>
                <SeparadorTitle>
                    <span>{t('term.general')}</span>
                </SeparadorTitle>
                <div>
                    {defaults.map((defaultVariable) => (
                        <VariableItem key={defaultVariable.id}>
                            <div title={defaultVariable.description}>
                                <span className='description'>{defaultVariable.description}</span>
                                <span className='name'>{`${VARIABLE_TRIGGER}${defaultVariable.name}`}</span>
                            </div>
                        </VariableItem>
                    ))}
                </div>
                <SeparadorTitle style={{ margin: '15px 0 5px 0' }}>
                    <span>{t('term.customized')}</span>
                    <Button
                        title={t(
                            'editor.templates.components.templates.variable-list.create-custom-var'
                        )}
                        size={ButtonSize.ssm}
                        onClick={onClickCreateVariable}
                    >
                        {t('term.create')}
                    </Button>
                </SeparadorTitle>
                <div>
                    {customs.length ? (
                        customs.map((customVariable) => (
                            <VariableItem key={customVariable._id}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flex: 1,
                                    }}
                                >
                                    <div title={customVariable.description}>
                                        <span className='description'>
                                            {customVariable.description}
                                        </span>
                                        <span className='name'>
                                            {`${VARIABLE_TRIGGER}${customVariable.name}`}
                                        </span>
                                    </div>
                                    <div>
                                        <EditIcon
                                            title={t(
                                                'editor.templates.components.templates.variable-list.edit-custom-var'
                                            )}
                                            onClick={() => onClickEditVariable(customVariable)}
                                        />
                                    </div>
                                </div>
                            </VariableItem>
                        ))
                    ) : (
                        <EmptyCustomVariable>
                            <span>{t('term.none-registered')}</span>
                        </EmptyCustomVariable>
                    )}
                </div>
                <SeparadorTitle style={{ margin: '15px 0 5px 0' }}>
                    <span>{t('term.process')}</span>
                </SeparadorTitle>
                <div>
                    {auction.length ? (
                        auction.map((auctionVariable) => (
                            <VariableItem key={auctionVariable.id}>
                                <div title={auctionVariable.description}>
                                    <span className='description'>
                                        {auctionVariable.description}
                                    </span>
                                    <span className='name'>
                                        {`${VARIABLE_TRIGGER}${auctionVariable.name}`}
                                    </span>
                                </div>
                            </VariableItem>
                        ))
                    ) : (
                        <EmptyCustomVariable>
                            <span>{t('term.none-registered')}</span>
                        </EmptyCustomVariable>
                    )}
                </div>
            </ContentScroll>
        </Modal>
    );
};

export default VariablesList;
