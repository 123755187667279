import { FC, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { DropzoneState } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    borderRadius: 6,
    borderColor: '#e3e2e2',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const focusedStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#E74C3C',
};

interface UploadFileProps extends DropzoneState {
    label?: string | any;
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
}

const UploadFile: FC<UploadFileProps> = ({
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    label,
    disabled = false,
    style: customStyles,
    className,
}) => {
    const { t } = useTranslation();

    const style: any = useMemo(
        () => ({
            ...baseStyle,
            ...customStyles,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject, customStyles]
    );

    return (
        <Box className={className} {...getRootProps({ style: { cursor: 'pointer', ...style } })}>
            <input {...getInputProps()} disabled={!!getInputProps?.()?.disabled || disabled} />
            {label || <Typography variant='body2'>{t('info.send-files')}</Typography>}
        </Box>
    );
};

export default UploadFile;
