import PreviewEditor from 'modules/editor/components/preview-editor';
import { FC, useEffect, useState } from 'react';
import { Modal as M, ModalPosition } from 'common/components';
import { useTranslation } from 'react-i18next';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import { addNotificationError } from 'common/utils';

export interface PreviewTemplateProps {
    documentTemplateId: string;
    onClose: () => void;
    visible: boolean;
}

const PreviewTemplate: FC<PreviewTemplateProps> = ({ documentTemplateId, onClose, visible }) => {
    const [template, setTemplate] = useState<DocumentTemplate | undefined>();
    const { t } = useTranslation();

    const getTemplate = async () => {
        try {
            const { data: docTemplate } = await templateRequests.getTemplate(documentTemplateId);
            setTemplate(docTemplate);
        } catch (error) {
            addNotificationError({
                message: t(
                    'editor.process.pages.edit-process.index.notification-error-onload-template'
                ),
                title: t('term.err'),
            });
        }
    };

    useEffect(() => {
        getTemplate();
    }, [documentTemplateId]);

    return (
        <M
            position={ModalPosition.right}
            open={visible}
            onClose={onClose}
            header={<span>{`${t('term.visualization')} - ${template?.name}`}</span>}
        >
            <PreviewEditor loading={!template} html={template?.content} />
        </M>
    );
};

export default PreviewTemplate;
