import {
    CircularProgress,
    Grid,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { TextField } from 'common/components';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const SearchDocumentTypesTableView = ({
    handleChangeFilter,
    documentTypes,
    handleEdit,
    handleRemove,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <TextField
                label={t('document-types.search')}
                placeholder={t('message.press-enter-to-search')}
                fullWidth
                onKeyPress={handleChangeFilter}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.category')}</TableCell>
                        <TableCell>{t('term.document-name')}</TableCell>
                        <TableCell>{t('term.description')}</TableCell>
                        <TableCell>{t('term.required')}?</TableCell>
                        <TableCell colSpan={2}>{t('term.active')}?</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {documentTypes &&
                        documentTypes.map((row) => (
                            <TableRow hover key={row.id}>
                                <TableCell>{row.categoryName}</TableCell>
                                <TableCell>{row.documentName}</TableCell>
                                <TableCell>{row.documentDescription}</TableCell>
                                <TableCell>
                                    {row.documentRequired ? t('term.yes') : t('term.no')}
                                </TableCell>
                                <TableCell>{row.actived ? t('term.yes') : t('term.no')}</TableCell>
                                <TableCell align='center' padding='none' style={{ width: 120 }}>
                                    <Grid>
                                        <>
                                            <IconButton onClick={() => handleEdit(row)}>
                                                <Tooltip title={t('term.edit')}>
                                                    <EditIcon fontSize='small' />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton onClick={() => handleRemove(row)}>
                                                <Tooltip title={t('term.remove')}>
                                                    <DeleteIcon fontSize='small' />
                                                </Tooltip>
                                            </IconButton>
                                        </>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    {documentTypes && !documentTypes.length && (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {!documentTypes && (
                        <TableRow>
                            <TableCell colSpan={6} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default SearchDocumentTypesTableView;
