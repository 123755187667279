import React from 'react';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HiCheck, HiOutlinePaperClip } from 'react-icons/hi';

interface InformationFileChipViewProps {
    url: string;
    label: string;
    onClick?: () => void;
    isDownloaded?: boolean;
}

const InformationFileChipView: React.FC<InformationFileChipViewProps> = ({
    url,
    label,
    onClick,
    isDownloaded = false,
}) => {
    const { t } = useTranslation();

    return (
        <Chip
            clickable
            disabled={isDownloaded}
            onClick={onClick}
            component='a'
            href={url}
            rel='noreferrer'
            target='_blank'
            download={url}
            color='default'
            size='medium'
            variant='outlined'
            label={!isDownloaded ? label : t('notices-information.button.file-downloaded')}
            icon={
                !isDownloaded ? (
                    <HiOutlinePaperClip color='var(--primary-color)' size={22} />
                ) : (
                    <HiCheck color='var(--success-color)' size={22} />
                )
            }
        />
    );
};

export default InformationFileChipView;
