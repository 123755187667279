import GroupAuthorizationService from 'modules/group-authorization/services/group-authorization.service';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchGroupAuthorizationTableView from './SearchGroupAuthorizationTableView';

const SearchGroupAuthorizationTable = () => {
    const history = useHistory();

    const [groups, setGroups] = useState(null);

    const getGroups = useCallback(async (name) => {
        setGroups(null);
        const { data } = await GroupAuthorizationService.getAuthorizationGroup(name);
        setGroups(data);
    }, []);

    useEffect(() => {
        getGroups('');
    }, []);

    const handleEdit = (row) => {
        history.push(`/grupo-autorizacao/${row.id}`);
    };

    const handleView = (row) => {
        history.push(`/grupo-autorizacao/detalhes/${row.id}`);
    };

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            getGroups(event.target.value);
        }
    };

    return (
        <SearchGroupAuthorizationTableView
            {...{ groups, handleChangeFilter, handleEdit, handleView }}
        />
    );
};

export default SearchGroupAuthorizationTable;
