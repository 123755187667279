import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    Category,
    ListMarketplaceProcessResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useStyles } from './filter-item-style';

interface FilterItemViewProps {
    loading?: boolean;
    items: Category[] | ListMarketplaceProcessResponse[];
    onClick: (item: Category | ListMarketplaceProcessResponse) => void;
    getDescription: (item: Category | ListMarketplaceProcessResponse) => string;
    isFilterSelected: (type: 'process' | 'category', id: number) => boolean;
    type: 'process' | 'category';
}

const FilterItemView: React.FC<FilterItemViewProps> = ({
    loading = false,
    items,
    type,
    onClick,
    getDescription,
    isFilterSelected,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    if (loading || !items) {
        return (
            <>
                <Skeleton variant='text' className={styles.skeleton} width={250} />
                <Skeleton variant='text' className={styles.skeleton} width={225} />
                <Skeleton variant='text' className={styles.skeleton} width={250} />
                <Skeleton variant='text' className={styles.skeleton} width={240} />
                <Skeleton variant='text' className={styles.skeleton} width={200} />
                <Skeleton variant='text' className={styles.skeleton} width={210} />
                <Skeleton variant='text' className={styles.skeleton} width={250} />
            </>
        );
    }

    return (
        <>
            {isEmpty(items) && !loading && (
                <Typography className={styles.noneFilterItem}>
                    {t('marketplace.home.no-filters-available')}
                </Typography>
            )}
            {items.map((item) => (
                <Typography
                    key={item.id}
                    onClick={() => onClick(item)}
                    className={`${styles.filterItem} ${
                        isFilterSelected(type, type === 'process' ? item.processId : item.id)
                            ? styles.filterItemColorSelected
                            : styles.filterItemColor
                    }`}
                >
                    {getDescription(item)}
                </Typography>
            ))}
        </>
    );
};

export default FilterItemView;
