import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MethodDispute } from 'clients/manager/interfaces/auction-notice.interface';
import { disableField, processRules, processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';

const MethodDisputeField = () => {
    const { processForm, auctionTypeRules } = useProcessFormContext();
    const { t } = useTranslation();

    const handleChangeMethodDispute = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        return processForm?.setValues({
            ...processForm?.values,
            [event.target.name]: value,
            ...(value !== MethodDispute.aberto.toString()
                ? {
                      randomEndTime: 0,
                  }
                : {}),
        });
    }, 30);

    const methodDisputeOptions = useMemo(() => {
        const opts: Array<{ value: MethodDispute; label: string }> = [];
        if (processUtils.isDispenseProcess(processForm?.values)) {
            return [
                { label: `${t('term.with')} ${t('term.dispute')}`, value: MethodDispute.aberto },
                {
                    label: `${t('term.without')} ${t('term.dispute')}`,
                    value: MethodDispute.notApplicable,
                },
            ];
        }

        if (auctionTypeRules?.generalSettings.cadastro.modoAberto)
            opts.push({ label: t('term.open'), value: MethodDispute.aberto });

        if (auctionTypeRules?.generalSettings.cadastro.modoAbertoFechado)
            opts.push({
                label: `${t('term.open')} + ${t('term.closed')}`,
                value: MethodDispute.abertoFechado,
            });

        if (processRules.visibleMethodDisputeClosed(auctionTypeRules, processForm?.values))
            opts.push({ label: t('term.closed'), value: MethodDispute.fechado });

        if (processRules.visibleMethodDisputeClosedOpened(auctionTypeRules, processForm?.values)) {
            opts.push({
                label: `${t('term.closed')} + ${t('term.open')}`,
                value: MethodDispute.fechadoAberto,
            });
        }

        if (auctionTypeRules?.generalSettings.cadastro.modoNaoSeAplica) {
            opts.push({
                label: `${t('term.not-applicable')}`,
                value: MethodDispute.notApplicable,
            });
        }

        return opts;
    }, [auctionTypeRules, processForm?.values]);

    useEffect(() => {
        if (!processForm?.values.methodDispute) return;

        const methodDisputeOptionsValues = methodDisputeOptions.map((item) => item.value);
        if (!methodDisputeOptionsValues.includes(processForm?.values.methodDispute))
            processForm.setFieldValue('methodDispute', undefined);
    }, [processForm?.values.methodDispute, methodDisputeOptions]);

    const Select = useCallback(
        () => (
            <TextField
                fullWidth
                color='primary'
                label={t('term.dispute-mode')}
                name='methodDispute'
                onChange={handleChangeMethodDispute}
                value={processForm?.values.methodDispute}
                defaultValue={processForm?.values.methodDispute}
                variant='outlined'
                select
                disabled={disableField('methodDispute', processForm?.values)}
            >
                {methodDisputeOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>
        ),
        [processForm?.values?.methodDispute, methodDisputeOptions]
    );

    return <Select />;
};

export default MethodDisputeField;
