import { usePageContext } from 'common/components/base/pages/context';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { useFormik } from 'formik';
import MaterialsService from 'modules/material/services/material.service';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import EditMaterialView from './EditMaterialView';

interface EditMaterialProps {}

const EditMaterial: React.FC<EditMaterialProps> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { setTitle } = usePageContext();
    const params = useParams<{ id?: string }>();

    const [modalCatalogsOpen, setModalCatalogsOpen] = useState(false);
    const [catalogs, setCatalogs] = useState([]);

    const initialValues = {
        code: '',
        name: '',
        description: '',
        catalogId: '',
        supplyCategoryId: null,
    };

    const validationSchema = Yup.object().shape({
        code: Yup.string().required(t('term.required-field')).max(50),
        name: Yup.string().required(t('term.required-field')).max(200),
        catalogId: Yup.string().required(t('term.required-field')),
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const { data } = await MaterialsService.doSave(values);

            if (!params.id) {
                history.push(`/materiais/${data.id}`);
            }

            addNotificationSuccess();
        } catch {
            addNotificationError({
                message: t('material.pages.edit.ALREADY_EXISTS_MATERIAL_WITH_SAME_CODE'),
            });
        } finally {
            setSubmitting(false);
        }
    };
    const form = useFormik({ initialValues, validationSchema, onSubmit });

    const getMaterial = useCallback(async () => {
        const { data } = await MaterialsService.getById(params.id as string);

        form.setValues(data);
    }, []);

    const getCatalogs = useCallback(async () => {
        const { data } = await MaterialsService.getInitialData();
        setCatalogs(data.catalogs);
    }, []);

    useEffect(() => {
        setTitle(t('material.pages.title'));

        if (params.id) {
            getMaterial();
        }

        getCatalogs();
    }, []);

    useEffect(() => {
        if (!modalCatalogsOpen) {
            getCatalogs();
        }
    }, [modalCatalogsOpen]);

    return <EditMaterialView {...{ form, catalogs, modalCatalogsOpen, setModalCatalogsOpen }} />;
};

export default EditMaterial;
