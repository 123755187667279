import { Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ExceptionData } from 'clients/manager/interfaces/auction-notice-participate.interface';
import { TypeCancel } from 'clients/manager/interfaces/auction-notice.interface';
import { Platform } from 'clients/manager/platforms.requests';
import { formatDate } from 'common/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ExceptionsAlertsViewProps {
    isFinished?: boolean;
    isCanceled?: boolean;
    cancelType?: TypeCancel | null;
    dangerExceptions: ExceptionData[];
    warningExceptions: ExceptionData[];
    platform: Platform | undefined;
}

const ExceptionsAlertsView: React.FC<ExceptionsAlertsViewProps> = ({
    isFinished,
    isCanceled,
    cancelType,
    dangerExceptions,
    warningExceptions,
    platform,
}) => {
    const { t } = useTranslation();
    const getCancelTypeMessage = () => {
        const processStatus = t(`bidding.process.status.${cancelType}`);
        return (
            <span>
                {t('term.process')} {processStatus}! {t('term.warnings.partial.message.first')}
                <a
                    href='#noticesSection'
                    style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}
                >
                    {t('term.warnings.link')}
                </a>
                {t('term.warnings.partial.message.second')}
            </span>
        );
    };

    return (
        <>
            {isFinished && cancelType && (
                <Alert
                    severity={isCanceled ? 'error' : 'info'}
                    variant='outlined'
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <AlertTitle style={{ margin: 'auto' }}>
                        <strong>{getCancelTypeMessage()}</strong>
                    </AlertTitle>
                </Alert>
            )}

            {dangerExceptions.length > 0 && (
                <Alert severity='error' variant='outlined'>
                    <AlertTitle>
                        <strong>{t('provider-users.pages.components.alert')}</strong>
                    </AlertTitle>
                    <Grid>
                        {dangerExceptions.map(({ messages, options }) => (
                            <Typography key={messages.join(' ')}>
                                {messages
                                    .map(
                                        (message) =>
                                            `- ${t(message, {
                                                suspendedDate: formatDate(options?.data),
                                            })}`
                                    )
                                    .join(' ')}
                            </Typography>
                        ))}
                    </Grid>
                </Alert>
            )}
            {warningExceptions.length > 0 && (
                <Alert severity='warning' variant='outlined'>
                    <AlertTitle>
                        <strong>{t('provider-users.pages.components.alert')}</strong>
                    </AlertTitle>
                    <Grid>
                        {warningExceptions.map(({ messages }) => (
                            <Typography key={messages.join(' ')}>
                                {messages
                                    .map(
                                        (message) =>
                                            `- ${t(message, {
                                                email: platform?.notificationEmail,
                                            })}`
                                    )
                                    .join(' ')}
                            </Typography>
                        ))}
                    </Grid>
                </Alert>
            )}
        </>
    );
};

export default ExceptionsAlertsView;
