import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserCheck, FiUserPlus } from 'react-icons/fi';
import { HiOutlineNewspaper, HiUserCircle } from 'react-icons/hi';
import LanguagesButtons from '../languages-buttons';
import { MobileMenuDropDownContainer, MobileMenuDropDownListContainer } from './styles';

interface MobileMenuDropDownProps {
    handleSignUp?: () => void;
    handleSignIn: () => void;
    handleInfo: () => void;
}

const MobileMenuDropDown = ({
    handleSignUp,
    handleSignIn,
    handleInfo,
}: MobileMenuDropDownProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onClick = () => setOpen(false);

        if (open) window.addEventListener('click', onClick);
        else window.removeEventListener('click', onClick);

        return () => window.removeEventListener('click', onClick);
    }, [open, containerRef]);

    return (
        <MobileMenuDropDownContainer>
            <IconButton onClick={() => setOpen(!open)}>
                <HiUserCircle size={26} color='var(--primary-color)' />
            </IconButton>
            <MobileMenuDropDownListContainer ref={containerRef} {...{ open }}>
                <List>
                    <ListItem onClick={handleSignIn} button>
                        <ListItemIcon>
                            <FiUserCheck color='var(--primary-color)' size={26} />
                        </ListItemIcon>
                        <ListItemText primary={t('auth.general.sign-in')} />
                    </ListItem>
                    {handleSignUp && (
                        <ListItem onClick={handleSignUp} button>
                            <ListItemIcon>
                                <FiUserPlus color='var(--primary-color)' size={26} />
                            </ListItemIcon>
                            <ListItemText primary={t('auth.general.sign-up')} />
                        </ListItem>
                    )}
                </List>
                <Divider />
                <List>
                    <ListItem onClick={handleInfo} button>
                        <ListItemIcon>
                            <HiOutlineNewspaper size={26} color='var(--primary-color)' />
                        </ListItemIcon>
                        <ListItemText primary={t('shared.unnauthenticated-header.notices')} />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemText primary={t('system.i18n.language')} />
                        <LanguagesButtons />
                    </ListItem>
                </List>
            </MobileMenuDropDownListContainer>
        </MobileMenuDropDownContainer>
    );
};

export default MobileMenuDropDown;
