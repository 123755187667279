import { TypeAccess, TypeUser } from '../../interfaces/user.interface';

const getAccessTypeToEnvironment = (accessType: TypeAccess): TypeUser => {
    switch (accessType) {
        case TypeAccess.management:
            return TypeUser.admin;

        case TypeAccess.provider:
            return TypeUser.provider;

        case TypeAccess.organization:
            return TypeUser.organization;

        case TypeAccess.citizen:
            return TypeUser.citizen;

        default:
            return TypeUser.citizen;
    }
};

const getEnviromentToAccessType = (typeUser: TypeUser): TypeAccess => {
    const obj = {
        [TypeUser.admin]: TypeAccess.management,
        [TypeUser.provider]: TypeAccess.provider,
        [TypeUser.organization]: TypeAccess.organization,
        [TypeUser.citizen]: TypeAccess.citizen,
    };

    return obj?.[typeUser];
};

export { getAccessTypeToEnvironment, getEnviromentToAccessType };
