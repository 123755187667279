import autoTable from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { getPriceMountTypeName, formatCurrency, addNotificationError } from 'common/utils';
import i18n from 'i18next';
import { PriceList } from 'common/interfaces/price-list.interface';
import { User } from 'common/interfaces/user.interface';
import { addFonts } from './shared/fonts';
import { addTitle } from './shared/title';
import { BottomLine, TopBottomLeftLine, TopBottomRightLine, TopLine } from './shared/lines';
import { addHeaders } from './shared/header';
import { addFooters } from './shared/footer';

export const createSyntheticPdf = (
    priceList: PriceList,
    authUser: User,
    onGeneratingDoc: (generating: boolean) => void
) => {
    const { t } = i18n;

    try {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
            format: 'a4',
            orientation: 'portrait',
        });

        addFonts(doc);
        addTitle(doc);
        doc.setFont('Roboto', 'normal');

        priceList.priceListItems.forEach((item, i) => {
            const itens = [
                [
                    t('term.prices'),
                    t('term.amount'),
                    t('term.calculation'),
                    t('term.estimated-price'),
                    t('term.total-value'),
                ],
                [
                    item.itemPrices.length,
                    item.amount ?? '-',
                    getPriceMountTypeName(item.priceMountType),
                    formatCurrency(item.referenceValue),
                    formatCurrency((item.amount ?? 1) * item.referenceValue),
                ],
                [item.description],
            ];

            autoTable(doc, {
                startY: i === 0 ? 38 : undefined,
                styles: {
                    overflow: 'linebreak',
                },
                margin: {
                    bottom: 20,
                },
                theme: 'plain',
                pageBreak: 'avoid',
                rowPageBreak: 'avoid',
                body: itens,
                alternateRowStyles: undefined,
                useCss: true,
                columnStyles: {
                    0: { cellWidth: 30 },
                    1: { cellWidth: 33 },
                    2: { cellWidth: 33 },
                    3: { cellWidth: 43 },
                    4: { cellWidth: 43 },
                },
                didDrawCell: (data) => {
                    if (data.cursor) {
                        if (data.row.index === 0) {
                            TopLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        } else if (data.row.index === 1) {
                            BottomLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        } else {
                            TopLine(data);
                            BottomLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        }
                    }
                },
                didParseCell: (data) => {
                    data.cell.styles.font = 'Roboto';
                    data.cell.styles.textColor = '#444';
                    data.cell.styles.fontSize = 9;

                    if (data.row.section === 'body') {
                        if (data.row.index === 0 || data.row.index === 1) {
                            data.cell.styles.cellPadding = {
                                top: 0.85,
                                bottom: 0.85,
                                left: 1.4,
                                right: 1.4,
                            };
                        }
                        if (data.row.index === 0) {
                            data.cell.styles.fontStyle = 'bold';
                        } else if (data.row.index === 1) {
                            data.cell.styles.halign = 'right';
                            if (data.column.index === 3) {
                                data.cell.styles.textColor = '#02AE81';
                                data.cell.styles.fontStyle = 'bold';
                            }
                        } else if (data.row.index === 2) {
                            data.cell.styles.fillColor = [247, 247, 247];
                            data.cell.colSpan = 5;
                        }
                    }
                },
            });
        });

        // addVerification(doc, priceList._id);
        addHeaders(
            doc,
            {
                name: authUser?.name as string,
            },
            priceList._id
        );
        addFooters(doc);
        doc.save(`${priceList.name}.pdf`);
        onGeneratingDoc(false);
    } catch (error) {
        onGeneratingDoc(false);
        addNotificationError({
            title: t('term.error'),
            message: t('mkp.user-price-list.error-generate-document'),
        });
    }
};
