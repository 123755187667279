/* eslint-disable no-alert */
import { addNotificationSuccess } from 'common/utils';
import HolidaysService from 'modules/holidays/services/HolidaysService';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchHolidaysContext } from '../../context/SearchHolidaysContext';
import SearchHolidaysTableView from './SearchHolidaysTableView';

const SearchHolidaysTable = () => {
    const { holidayDialog, setHolidayDialog } = useSearchHolidaysContext();

    const { t } = useTranslation();

    const [holidays, setHolidays] = useState();

    const getHolidays = useCallback(async (name) => {
        const { data } = await HolidaysService.listHolidaysOrganization(name);
        setHolidays(data);
    }, []);

    useEffect(() => {
        getHolidays('');
    }, []);

    useEffect(() => {
        if (!holidayDialog.open) {
            getHolidays('');
        }
    }, [holidayDialog.open]);

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            getHolidays(event.target.value);
        }
    };

    const handleRemove = async (row) => {
        if (window.confirm(`${t('holidays.remove-holiday')} ${row.description}?`)) {
            await HolidaysService.doDelete(row.id);
            getHolidays('');
            addNotificationSuccess();
        }
    };

    const handleEdit = (holiday) => {
        setHolidayDialog({ open: true, holiday });
    };

    return (
        <SearchHolidaysTableView {...{ holidays, handleChangeFilter, handleEdit, handleRemove }} />
    );
};

export default SearchHolidaysTable;
