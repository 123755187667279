import { Link } from 'react-router-dom';
import styled from 'styled-components';

type LinkBreadCrumbProps = {
    active?: boolean;
};

export const LinkBreadCrumb = styled(Link)<LinkBreadCrumbProps>`
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }

    pointer-events: ${({ active }) => (active ? 'all' : 'none')};
`;
