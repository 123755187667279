import React from 'react';
import { timeout } from './timer.utils';

export const focusElement = (idOrRef: string | React.RefObject<any>, _timeout?: number) => {
    if (typeof idOrRef === 'string') {
        const element = document.getElementById(idOrRef);
        return timeout(() => {
            element?.focus();
        }, _timeout ?? 0);
    }

    if (_timeout) {
        timeout(() => {
            idOrRef.current?.focus();
        }, _timeout ?? 0);
    }
};
