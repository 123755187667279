import { AppBar, Box, makeStyles, Paper, Tab, Tabs, Theme } from '@material-ui/core';
import { FC, useState } from 'react';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Description } from '@material-ui/icons';
import OrganizationGeneralData from '../organization-general-data';
import { OrganizationTabsProps } from './props';
import OrganizationUnits from '../organization-units';
import OrganizationMinutes from '../organization-minutes';
import FavoriteMessage from '../favorite-message';
import OrganizationDocumentBlocks from '../organization-document-blocks';

const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const CustomTab = withStyles((theme: Theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(15),
    },
}))(Tab);

const OrganizationTabs: FC<OrganizationTabsProps> = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const { t } = useTranslation();

    return (
        <Box mt={2}>
            <Paper square className={classes.root}>
                <AppBar position='static' color='default'>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons='auto'
                        indicatorColor='secondary'
                        textColor='secondary'
                    >
                        <CustomTab
                            label={t('term.general-data')}
                            icon={<FormatAlignLeftIcon />}
                            {...a11yProps(0)}
                        />
                        <CustomTab
                            label={t('term.purchasing-units')}
                            icon={<LocationCityIcon />}
                            {...a11yProps(1)}
                        />
                        <CustomTab
                            label={t('term.documents')}
                            icon={<AssignmentIcon />}
                            {...a11yProps(2)}
                        />
                        <CustomTab label={t('Atas')} icon={<Description />} {...a11yProps(3)} />
                        <CustomTab
                            label={t('term.favorite-messages')}
                            icon={<RateReviewIcon />}
                            {...a11yProps(4)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <OrganizationGeneralData />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <OrganizationUnits />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <OrganizationDocumentBlocks />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <OrganizationMinutes />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <FavoriteMessage />
                </TabPanel>
            </Paper>
        </Box>
    );
};

export default OrganizationTabs;
