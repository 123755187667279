import { Route } from 'react-router';
import UnsubscribeLgpd from './pages/unsubscribe-lgpd';

const PublicRoutes: JSX.Element[] = [
    <Route
        path='/public/unsubscribe/:token'
        key='/public/unsubscribe'
        exact
        component={UnsubscribeLgpd}
    />,
];

export default PublicRoutes;
