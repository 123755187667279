import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const MobileMenuDropDownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const MobileMenuDropDownListContainer = styled(Paper)<{ open?: boolean }>`
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: absolute;
    z-index: 9999;
    top: 100%;
    right: 0;
    width: 100vw;
    max-width: 300px;

    @media (max-width: 400px) {
        max-width: unset;
        width: calc(100vw - 40px);
        right: 0;
    }
`;
