import Typography from '@material-ui/core/Typography';
import { getMd5Hash, apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { CheckCircleIcon, LaunchOutlinedIcon } from 'common/icons';
import useStyles from '../../steps/Conclusion/ConclusionStyle';

const PostPaid = ({ auctionId }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const redirect = () => {
        const hash = getMd5Hash(String(auctionId));
        const path = `proposta?idE=${hash}`;
        apiRedirectTo(backofficeRedirectUrl, path);
    };

    return (
        <div className={classes.root}>
            <CheckCircleIcon color='secondary' className={classes.checkIcon} />
            <Typography variant='h6'>
                <strong>{t('bidding.checkout.conclusion.post-paid-1')}</strong>
            </Typography>
            <Typography variant='h6'>{t('bidding.checkout.conclusion.post-paid-2')}</Typography>
            <Button
                size='medium'
                variant='contained'
                color='primary'
                className={classes.invoiceButton}
                startIcon={<LaunchOutlinedIcon />}
                onClick={() => redirect()}
            >
                {t('bidding.process.section.general.action.proposal')}
            </Button>
        </div>
    );
};

export default PostPaid;
