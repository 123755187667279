import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            position: 'relative',
        },
        card: {
            minHeight: theme.spacing(15),
            padding: theme.spacing(1),
            borderRadius: 3,
            cursor: 'pointer',
            '& .MuiCardContent-root': {
                '&:last-child': {
                    paddingBottom: 0,
                },
            },
        },
        textProcess: {
            color: '#9d9d9d',
        },
        textInfoArea: {
            fontSize: '14px',
        },
        chipRoot: {
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            paddingRight: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
        },
        title: {
            textOverflow: 'ellipsis',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: '#5b5b5b',
        },
        textDisputeDate: {
            color: '#7f7f7f',
        },
        badgeDisputeRoom: {
            position: 'absolute',
            top: '-7px',
            right: '8px',
            fontSize: '11px',
            background: 'var(--info-color)',
            padding: '1px 4px',
            borderRadius: '5px',
            color: '#FFF',
            cursor: 'pointer',
        },
    })
);

export { useStyles };
