import i18n from 'common/i18n';
import { ProcessStage } from 'clients/manager/interfaces/auction-notice.interface';

const processStageToBiddingStageId = (stages: ProcessStage | ProcessStage[]): number[] => {
    const biddingStageId: number[] = [];
    const obj = {
        [ProcessStage.internal]: [1, 7, 12],
        [ProcessStage.proposals]: [2, 8, 13],
        [ProcessStage.dispute]: [9, 14],
        [ProcessStage.decision]: [10, 15],
        [ProcessStage.contract]: [3, 11, 16],
    };

    if (!Array.isArray(stages)) {
        stages = [stages];
    }

    stages.forEach((stage) => {
        biddingStageId.push(...obj[stage]);
    });

    return biddingStageId;
};

const getProcessStageByBiddingStageId = (biddingStageId: number): ProcessStage => {
    const obj = {
        [ProcessStage.internal]: [1, 7, 12],
        [ProcessStage.proposals]: [2, 8, 13],
        [ProcessStage.dispute]: [9, 14],
        [ProcessStage.decision]: [10, 15],
        [ProcessStage.contract]: [3, 11, 16],
    };

    let currentStage: ProcessStage = ProcessStage.internal;

    Object.keys(obj).forEach((stage) => {
        const biddingStageIds = obj[stage];
        if (biddingStageIds.includes(biddingStageId)) {
            currentStage = stage as ProcessStage;
        }
    });

    return currentStage;
};

const getLabelFromProcessStage = (
    processStage: ProcessStage,
    isDispenseWithoutDispute?: boolean
): string =>
    ({
        [ProcessStage.internal]: i18n.t('process.components.internal-phase'),
        [ProcessStage.proposals]: i18n.t('term.published'),
        [ProcessStage.dispute]: isDispenseWithoutDispute
            ? i18n.t('term.judgment')
            : i18n.t('term.dispute'),
        [ProcessStage.decision]: i18n.t('term.decision'),
        [ProcessStage.contract]: i18n.t('term.contract'),
    }[processStage]);

export { getLabelFromProcessStage, getProcessStageByBiddingStageId, processStageToBiddingStageId };
