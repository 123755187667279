import { CONSTANTS } from 'common/constants';
import { addNotificationWarning } from 'common/utils';
import Compressor from 'compressorjs';
import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface InputFileProps {
    accept?: string;
    mutiple?: boolean;
    disabled?: boolean;
    onChange(value: File): void;
}

const InputFile: React.FC<InputFileProps> = ({
    accept = 'image/*',
    mutiple = false,
    disabled = false,
    onChange,
    children,
}) => {
    const { t } = useTranslation();
    const inputId = nanoid();
    const inputRef = useRef<HTMLInputElement>(null);

    const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            Array.from(event.target.files).forEach((file) => {
                if (file.size > CONSTANTS.defaultFileMaxSize) {
                    addNotificationWarning({
                        message: t('info.invalid-file-size', { maxSize: '5MB' }),
                    });
                }

                // eslint-disable-next-line no-new
                new Compressor(file, {
                    maxWidth: 1280,
                    maxHeight: 720,
                    quality: 0.7,
                    async success(result) {
                        onChange(result as any);
                    },
                    async error(error) {
                        if (
                            error.message ===
                            'The first argument must be an image File or Blob object.'
                        ) {
                            onChange(file);
                        }
                    },
                });
            });

            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };

    return (
        <>
            <input
                id={inputId}
                ref={inputRef}
                type='file'
                accept={accept}
                multiple={mutiple}
                disabled={disabled}
                onChange={onSelectFile}
                style={{ display: 'none' }}
            />
            <label htmlFor={inputId}>{children}</label>
        </>
    );
};

export default InputFile;
