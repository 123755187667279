import {
    CircularProgress,
    Grid,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    IconButton,
    Typography,
} from '@material-ui/core';
import { TextField } from 'common/components';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

const SearchHolidaysTableView = ({ holidays, handleChangeFilter, handleEdit, handleRemove }) => {
    const { t } = useTranslation();

    const authUserIsAdmin = useSelector(
        (state: AppState) => !state?.authUserState?.authUserTokenData?.organizationId
    );

    return (
        <>
            <TextField
                label={t('holidays.search')}
                placeholder={t('message.press-enter-to-search')}
                fullWidth
                onKeyPress={handleChangeFilter}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />

            <br />
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.type')}</TableCell>
                        <TableCell>{t('term.date')}</TableCell>
                        <TableCell colSpan={2}>{t('term.description')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {holidays &&
                        holidays.map((row) => (
                            <TableRow hover key={row.id}>
                                <TableCell>{t(`term.${row.typeHoliday}`)}</TableCell>
                                <TableCell>
                                    {moment.utc(row.dateHoliday).format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell align='center' padding='none' style={{ width: 120 }}>
                                    <Grid>
                                        {(row.organizationId || authUserIsAdmin) && (
                                            <>
                                                <IconButton onClick={() => handleEdit(row)}>
                                                    <Tooltip title={t('term.edit')}>
                                                        <EditIcon fontSize='small' />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton onClick={() => handleRemove(row)}>
                                                    <Tooltip title={t('term.remove')}>
                                                        <DeleteIcon fontSize='small' />
                                                    </Tooltip>
                                                </IconButton>
                                            </>
                                        )}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    {holidays && !holidays.length && (
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {!holidays && (
                        <TableRow>
                            <TableCell colSpan={4} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default SearchHolidaysTableView;
