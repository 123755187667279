import { CircularProgress, Grid, Paper } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Breadcrumb from 'common/components/base/breadcrumb';
import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { useHistory } from 'react-router';
import useStyles from './CheckoutStyle';
import SelectedStep from './components/SelectedStep';
import Conclusion from './steps/Conclusion';
import Confirmation from './steps/Confirmation';
import Payment from './steps/Payment';
import Plans from './steps/Plans';

const CheckoutView = ({ auctionId, process, loading, step, isStepDifferentFromConclusion }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { selectedSteps } = useCheckoutContext();

    return (
        <PageWrapper>
            {loading ? (
                <div className={classes.loader}>
                    <CircularProgress color='secondary' size={60} />
                </div>
            ) : (
                <>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Breadcrumb
                            customPath='/pesquisa/:id/:checkout'
                            paths={{
                                pesquisa: {
                                    value: t('bidding.page.search.title'),
                                    onClick: () => history.push('/pesquisa'),
                                },
                            }}
                            params={{
                                id: {
                                    value: process?.processNumber,
                                    disabled: false,
                                },
                                checkout: {
                                    value: t('bidding.checkout.breadcrumb.payment'),
                                    disabled: true,
                                },
                            }}
                        />
                    </Grid>

                    <br />

                    <Stepper className={classes.stepper} activeStep={step} alternativeLabel>
                        <Step className={classes.step}>
                            <StepLabel StepIconComponent={ShoppingCartIcon}>
                                {t('bidding.checkout.steps.plan')}
                            </StepLabel>
                        </Step>
                        <Step className={classes.step}>
                            <StepLabel StepIconComponent={ShoppingCartIcon}>
                                {t('bidding.checkout.steps.payment')}
                            </StepLabel>
                        </Step>
                        <Step className={classes.step}>
                            <StepLabel StepIconComponent={VisibilityIcon}>
                                {t('bidding.checkout.steps.confirmation')}
                            </StepLabel>
                        </Step>
                        <Step className={classes.step}>
                            <StepLabel StepIconComponent={CheckCircleIcon}>
                                {t('bidding.checkout.steps.conclusion')}
                            </StepLabel>
                        </Step>
                    </Stepper>
                    <>
                        {isStepDifferentFromConclusion &&
                            selectedSteps.plan.selected &&
                            Object.keys(selectedSteps)
                                .slice(0, step - 1)
                                .map((selectedStep, index) => (
                                    <SelectedStep
                                        key={`${selectedSteps[selectedStep].selected}-${selectedSteps[selectedStep].selected}`}
                                        selectedStep={selectedSteps[selectedStep].selected}
                                        index={index}
                                    />
                                ))}
                    </>
                    <Paper className={classes.paper}>
                        {step === 1 && <Plans process={process} />}
                        {step === 2 && <Payment />}
                        {step === 3 && <Confirmation />}
                        {step === 4 && <Conclusion auctionId={auctionId} />}
                    </Paper>
                </>
            )}
        </PageWrapper>
    );
};

export default CheckoutView;
