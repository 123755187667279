import { doRequest, managerApiInstance } from 'clients/api';
import { GenericBody, GenericResponse } from 'clients/interfaces/paginated.interface';
import {
    ListMarketplaceItemsBody,
    ListMarketplaceItemsResponse,
    ListMarketplaceProcessResponse,
    ListSupplyCategoriesByOrganizationResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { AxiosRequestConfig } from 'axios';
import { ordersRequests } from './orders.requests';
import { marketplaceCartRequests } from './marketplace-cart.requests';

const BASE_URL = '/marketplace';

const marketplaceRequests = {
    listMarketplaceProcesses: async () =>
        doRequest<GenericResponse<ListMarketplaceProcessResponse>>(
            managerApiInstance.post(`${BASE_URL}/listMarketplaceProcesses`, {})
        ),
    listSupplyCategoriesByOrganization: async () =>
        doRequest<GenericResponse<ListSupplyCategoriesByOrganizationResponse>>(
            managerApiInstance.post(`${BASE_URL}/listSupplyCategoriesByOrganization`)
        ),
    listMarketplaceItems: async (
        body?: GenericBody<ListMarketplaceItemsBody>,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<ListMarketplaceItemsResponse>>(
            managerApiInstance.post(`${BASE_URL}/listMarketplaceItems`, body, config)
        ),
    ...ordersRequests,
    ...marketplaceCartRequests,
};

export { marketplaceRequests };
