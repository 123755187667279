import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import DetailsNoticesInformationPage from './pages/notice-information';
import SearchNoticesInformationPage from './pages/main';
import NoticesInformationProvider from './contexts/NoticesInformationContext';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <NoticesInformationProvider>
                <PrivateRoute
                    exact
                    allowGuest
                    path={`${match.path}/`}
                    key='/avisos-informacoes-lista'
                    component={() => <SearchNoticesInformationPage />}
                />
                <PrivateRoute
                    exact
                    allowGuest
                    path={`${match.path}/:id`}
                    key='/avisos-informacoes-detalhes'
                    component={() => <DetailsNoticesInformationPage />}
                />
            </NoticesInformationProvider>
        </Switch>
    );
};

const NoticesInformationRoutes: JSX.Element[] = [
    <Route path='/avisos-informacoes' key='/avisos-informacoes' render={() => <ChildRoutes />} />,
];

export default NoticesInformationRoutes;
