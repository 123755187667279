import { CircularProgress, Grid } from '@material-ui/core';
import CheckoutPaper from '../../components/CheckoutPaper';
import PaperFooter from '../../components/PaperFooter';
import Plan from '../../components/Plan';
import useStyles from './PlansStyle';

export default function PlansView({ selected, handleClick, availablePlans, loading, process }) {
    const classes = useStyles();

    return (
        <CheckoutPaper title={null}>
            <Grid container spacing={4}>
                {loading || availablePlans.length === 0 ? (
                    <div className={classes.loader}>
                        <CircularProgress color='secondary' size={60} />
                    </div>
                ) : (
                    availablePlans.map((availablePlan) => (
                        <Grid item xs={12} lg={4}>
                            <Plan
                                key={availablePlan.title}
                                title={availablePlan.title}
                                benefits={availablePlan.benefits}
                                priceType={availablePlan.priceDetails.priceType}
                                value={availablePlan.billing.value}
                                handleSelected={() => handleClick(availablePlan)}
                                selected={selected && selected.title === availablePlan.title}
                                objSelected={selected ?? null}
                                processType={process?.processType}
                                paymentCondition={availablePlan?.billing?.type}
                                planName={availablePlan?.name}
                            />
                        </Grid>
                    ))
                )}
            </Grid>

            <PaperFooter
                selected={selected && selected.title}
                disabled={!selected}
                details={selected}
            />
        </CheckoutPaper>
    );
}
