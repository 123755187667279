import { styled } from 'common/theme/styled';
import { GrFormNext } from 'react-icons/gr';

const Item = styled.div`
    padding: 8px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        color: ${(props) => props.theme.colors.textDefault};
        font-size: 14px;
    }

    :not(:last-child) {
        border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    }
`;

const ConfirmIcon = styled(GrFormNext)`
    font-size: 22px;
    min-width: 30px;
    color: ${(props) => props.theme.colors.iconColorDefault};
`;

export { Item, ConfirmIcon };
