import styled from 'styled-components';
import { Card, TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';

export const PcaPlanPageWrapper = styled(PageWrapper)`
    > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    }
`;

export const HeaderPage = styled.div`
    height: 7rem;
    padding: 16px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const PcaCard = styled(Card)`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border: 1px var(--border-color) solid;

    > section {
        margin-top: 1rem;
        flex: 1;
        overflow-y: auto;
    }
`;

export const PcaCardContainer = styled.div`
    display: flex;
    padding: 1rem 0;

    > div {
        flex: 1;
        padding: 0 1rem;
    }
`;

export const HeaderCard = styled.header`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    > h1 {
        font-size: 1.5rem;
        font-weight: 400;
        color: var(--text-color);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    > div {
        margin-left: auto;
    }
`;

export const FooterCard = styled.footer`
    margin-bottom: 0;
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > p {
        display: block;
        margin: 0;
        font-size: 10px;
        color: var(--tertiary-color);
        margin-top: auto;
        padding-top: 8px;
    }
`;

export const Box = styled(Card)`
    border: 0;
    box-shadow: none;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #0001;
        border-radius: 2px;
        transition: 0.4s;
    }
`;

const BoxDefaultCSS = styled(Card)`
    width: 100%;
    min-height: 7rem;
    > p {
        margin: 8px 0;
        font-size: 0.875rem;
    }

    > h2 {
        font-size: 20px;
        margin: 8px 0;
    }
`;

export const BoxValueEstimated = styled(BoxDefaultCSS)`
    background-color: #81e69b;
`;

export const BoxQuantityItens = styled(BoxDefaultCSS)`
    background-color: #99c5f2;
`;

export const SkeletonBox = styled(Skeleton)`
    width: 100%;
    height: 7rem;
    border-radius: 2px;
`;

export const Th = styled(TableCell)`
    color: var(--primary-color);
`;

export const Stack = styled.div<{ direction: 'row' | 'column' }>`
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.direction};
    justify-content: center;
`;

export const BlankArea = styled.div<{ border?: boolean; minHeight?: boolean }>`
    border: ${({ border }) => border && '1px dashed var(--border-color)'};
    border-radius: ${({ border }) => border && '4px'};
    flex: ${({ minHeight }) => (minHeight ? 0 : 1)} 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    > h1,
    h2,
    h3 {
        line-height: normal;
        margin: 0;
    }

    > h1 {
        font-size: 18px;
        color: var(--primary-color);
    }

    > h2 {
        font-size: 16px;
        color: var(--text-light-color);
        font-weight: 300;
    }

    > h3 {
        font-size: 16px;
        color: var(--text-light-color);
        font-weight: 400;
    }
`;

export const LoadingIndicatorContainer = styled.div`
    width: 100%;
    min-height: 48px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
`;
