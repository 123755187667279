import { FC, memo, useState } from 'react';
import { Box } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'common/components/loading-button';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { useProcessFormContext } from '../../context/process-form.context';

interface ModalPermissionPublishProps {
    onClose: () => void;
}

const ModalPermissionPublish: FC<ModalPermissionPublishProps> = ({ onClose }) => {
    const [requestingPublish, setRequestingPublish] = useState(false);
    const { t } = useTranslation();
    const { process } = useProcessFormContext();

    if (!process?.id) {
        return null;
    }

    const handleClickRequest = async () => {
        try {
            setRequestingPublish(true);
            const response = await auctionNoticeRequests.requestPublishProcess(process.id);

            if (response?.data?.ok) {
                setRequestingPublish(false);
                onClose();
                addNotificationSuccess();
            }
            setRequestingPublish(false);
        } catch (error) {
            setRequestingPublish(false);
            addNotificationApiError(error);
        }
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={onClose}
            header={<span>{t('info.without-permission-publish')}</span>}
        >
            <Box minWidth='400px' maxWidth='500px'>
                <Box width={1}>
                    <Alert severity='warning'>{t('info.without-permission-publish-message')}</Alert>
                    <Box width={1} mt={2} display='flex' justifyContent='space-between'>
                        <Button
                            variant='outlined'
                            color='primary'
                            title={t('term.cancel')}
                            onClick={onClose}
                            size='small'
                        >
                            {t('term.cancel')}
                        </Button>
                        <LoadingButton
                            variant='contained'
                            color='primary'
                            title={t('info.request-publish')}
                            onClick={handleClickRequest}
                            size='small'
                            {...(requestingPublish
                                ? {
                                      loading: {
                                          text: `${t('term.loading')}...`,
                                      },
                                  }
                                : {})}
                        >
                            {t('info.request-publish')}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default memo(ModalPermissionPublish);
