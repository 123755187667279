import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCompareDocumentDataProps } from '../interfaces';

const TableDocumentsView: React.FC<TableCompareDocumentDataProps> = ({ documents }) => {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper} style={{ marginTop: '15px' }}>
            <Table style={{ minWidth: 450 }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.action')}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.document')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((item) => {
                        return (
                            <TableRow>
                                <TableCell
                                    style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}
                                >
                                    {t(`term.${item.type}`)}
                                </TableCell>
                                <TableCell>
                                    {' '}
                                    <a target='_blank' href={item.data.url} rel='noreferrer'>
                                        {' '}
                                        {item.data.fileName}{' '}
                                    </a>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableDocumentsView;
