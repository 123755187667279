import { FC, ReactNode, useState } from 'react';
import {
    TextField as TextFieldDefault,
    BaseTextFieldProps as DefaultBaseTextFieldProps,
    TextFieldProps as DefaultTextFieldProps,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from './styles';

interface TextFieldProps extends Omit<DefaultTextFieldProps, 'color'> {
    color?: 'success' | 'warning' | 'danger' | DefaultBaseTextFieldProps['color'];
    extraEndAdornment?: ReactNode;
}

const customColors = ['success', 'warning', 'danger'];

const getColors = (color: any) => {
    if (customColors.some((c) => c === color)) return undefined;

    return color;
};

const TextField: FC<TextFieldProps> = ({ color, className, extraEndAdornment, ...rest }) => {
    const [showPassword, setShowPassword] = useState(false);

    const classes = useStyles();

    return (
        <TextFieldDefault
            {...rest}
            InputProps={{
                endAdornment:
                    rest?.type === 'password' ? (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => setShowPassword((state) => !state)}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            {extraEndAdornment}
                        </InputAdornment>
                    ) : undefined,
                ...rest?.InputProps,
            }}
            type={showPassword ? 'text' : rest?.type}
            className={clsx(className, classes.root, {
                [classes.success]: color === 'success',
                [classes.warning]: color === 'warning',
                [classes.danger]: color === 'danger',
            })}
            variant='filled'
            color={getColors(color)}
        />
    );
};

export type { TextFieldProps };
export { TextField };
