enum AccountType {
    CI = 'CI',
    CC = 'CC',
}

enum MethodType {
    PIX = 'PIX',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
}

enum PixType {
    CPF = 'CPF',
    CNPJ = 'CNPJ',
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
    RANDOM = 'RANDOM',
}

interface UserFinancialData {
    id: number;
    userId: number;
    providerId?: number | null;
    methodType: MethodType;
    accountType?: AccountType | null;
    accountAgency?: string | null;
    accountNumber?: string | null;
    docNumberReceiver?: string | null;
    bankCode?: string | null;
    pixValue?: string | null;
    pixType?: PixType | null;
    createdAt: string;
    updatedAt?: string | null;
    deletedAt?: string | null;
}
export type { UserFinancialData };
export { AccountType, MethodType, PixType };
