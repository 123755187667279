import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { Button, TextField, Modal } from 'common/components';
import typeHoliday from 'modules/holidays/type-holiday';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        padding: theme.spacing(2),
        width: theme.spacing(80),
    },
    buttons: {
        padding: theme.spacing(2),
        gap: theme.spacing(2),
    },
}));

const SearchHolidaysDialogView = ({ form, handleClose }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const header = <span>{t('term.holidays')}</span>;
    return (
        <Modal open onClose={handleClose} {...{ header }}>
            <div className={classes.modal}>
                <form onSubmit={form.handleSubmit} noValidate>
                    <Grid container direction='column' spacing={3}>
                        <Grid item container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    label={t('term.type')}
                                    fullWidth
                                    name='typeHoliday'
                                    value={form.values.typeHoliday}
                                    onChange={form.handleChange}
                                    error={form.touched.typeHoliday && !!form.errors.typeHoliday}
                                    helperText={form.touched.typeHoliday && form.errors.typeHoliday}
                                >
                                    {Object.keys(typeHoliday).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {t(`term.${key}`)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    type='date'
                                    label={t('term.date')}
                                    fullWidth
                                    name='dateHoliday'
                                    value={form.values.dateHoliday}
                                    onChange={form.handleChange}
                                    error={form.touched.dateHoliday && !!form.errors.dateHoliday}
                                    helperText={form.touched.dateHoliday && form.errors.dateHoliday}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <TextField
                                label={t('term.description')}
                                fullWidth
                                name='description'
                                value={form.values.description}
                                onChange={form.handleChange}
                                error={form.touched.description && !!form.errors.description}
                                helperText={form.touched.description && form.errors.description}
                            />
                        </Grid>

                        <Grid container justifyContent='flex-end' className={classes.buttons}>
                            <Button size='small' onClick={handleClose}>
                                {t('term.cancel')}
                            </Button>

                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                size='small'
                                disabled={form.isSubmitting}
                            >
                                {t('term.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
};

export default SearchHolidaysDialogView;
