import { t } from 'i18next';
import { PlainGenericResponse } from '../../../clients/interfaces/paginated.interface';
import { contractRequests } from '../../../clients/manager/contract.requests';
import { addNotificationError } from '../../utils';

export const redirectToGenerateDocument = async (
    contractId: string,
    regenerate: boolean,
    backofficeRedirectUrlPath: string
) => {
    try {
        let response: PlainGenericResponse<{
            path: string;
        }>;

        if (regenerate) {
            response = await contractRequests.doReGenerateContract(contractId);
        } else {
            response = await contractRequests.doGenerateContract(contractId);
        }

        if (response.data?.path) {
            const url = `${backofficeRedirectUrlPath}/${response.data.path}`;
            window.open(url, '_blank')?.focus();
        }
        return true;
    } catch (error) {
        addNotificationError({
            message: t('info.error-generate-document'),
            title: t('term.error'),
        });
        return false;
    }
};
