import { Box, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Rating from '@material-ui/lab/Rating';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import useStyles from './modal-rating-contracts-style';

export default function ModalRatingContractsView({
    onClose,
    open,
    getModalTitle,
    form,
    ratings,
    handleRatingChange,
    handleHoverChange,
    handleLabelRating,
    loadingContractRating,
    loadingFormSubmit,
    contractRatingEdited,
    handleDeleteContractRating,
}) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Modal
            {...{ open, onClose }}
            position={ModalPosition.center}
            header={<span className={classes.header}>{getModalTitle()}</span>}
        >
            <Box className={classes.modalContent}>
                <form {...form}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper variant='outlined' elevation={2}>
                                <div className={classes.ratingBox}>
                                    <Typography variant='h5'>
                                        {t('contracts.rating.title')}
                                    </Typography>
                                    {loadingContractRating ? (
                                        <>
                                            <Skeleton variant='text' height={22} width='30%' />
                                            <Skeleton variant='text' height={22} width='30%' />
                                        </>
                                    ) : (
                                        <>
                                            <Typography component='legend'>
                                                {t('contracts.rating.generalRatingTitle', {
                                                    value: handleLabelRating(ratings.generalRating),
                                                })}
                                            </Typography>
                                            <Rating
                                                size='large'
                                                name='hover-generalRating'
                                                value={ratings.generalRating.value}
                                                onChange={(event, newValue) =>
                                                    handleRatingChange('generalRating', newValue)
                                                }
                                                onChangeActive={(event, newHover) =>
                                                    handleHoverChange('generalRating', newHover)
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Box marginTop={2}>
                                <Typography align='center' variant='h5'>
                                    {t('contracts.rating.characteristics')}
                                </Typography>
                                <Typography align='center' variant='caption' component='legend'>
                                    {t('contracts.rating.optional')}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center'>
                                {loadingContractRating ? (
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        alignItems='center'
                                    >
                                        <Skeleton variant='text' height={22} width='30%' />
                                        <Skeleton variant='text' height={22} width='30%' />
                                    </Box>
                                ) : (
                                    <div className={classes.ratingBox}>
                                        <Typography component='legend'>
                                            {t('contracts.rating.costBenefitRatingTitle', {
                                                value: handleLabelRating(ratings.costBenefitRating),
                                            })}
                                        </Typography>
                                        <Rating
                                            size='small'
                                            name='hover-costBenefitRating'
                                            value={ratings.costBenefitRating.value}
                                            onChange={(event, newValue) =>
                                                handleRatingChange('costBenefitRating', newValue)
                                            }
                                            onChangeActive={(event, newHover) =>
                                                handleHoverChange('costBenefitRating', newHover)
                                            }
                                        />
                                    </div>
                                )}
                            </Box>
                            <Box display='flex' justifyContent='center'>
                                {loadingContractRating ? (
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        alignItems='center'
                                    >
                                        <Skeleton variant='text' height={22} width='30%' />
                                        <Skeleton variant='text' height={22} width='30%' />
                                    </Box>
                                ) : (
                                    <div className={classes.ratingBox}>
                                        <Typography component='legend'>
                                            {t('contracts.rating.contractQualityRatingTitle', {
                                                value: handleLabelRating(
                                                    ratings.contractQualityRating
                                                ),
                                            })}
                                        </Typography>
                                        <Rating
                                            size='small'
                                            name='hover-contractQualityRating'
                                            value={ratings.contractQualityRating.value}
                                            onChange={(event, newValue) =>
                                                handleRatingChange(
                                                    'contractQualityRating',
                                                    newValue
                                                )
                                            }
                                            onChangeActive={(event, newHover) =>
                                                handleHoverChange('contractQualityRating', newHover)
                                            }
                                        />
                                    </div>
                                )}
                            </Box>
                            <Box display='flex' justifyContent='center'>
                                {loadingContractRating ? (
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        alignItems='center'
                                    >
                                        <Skeleton variant='text' height={22} width='30%' />
                                        <Skeleton variant='text' height={22} width='30%' />
                                    </Box>
                                ) : (
                                    <div className={classes.ratingBox}>
                                        <Typography component='legend'>
                                            {t('contracts.rating.deadlineComplianceRatingTitle', {
                                                value: handleLabelRating(
                                                    ratings.deadlineComplianceRating
                                                ),
                                            })}
                                        </Typography>
                                        <Rating
                                            size='small'
                                            name='hover-deadlineComplianceRating'
                                            value={ratings.deadlineComplianceRating.value}
                                            onChange={(event, newValue) =>
                                                handleRatingChange(
                                                    'deadlineComplianceRating',
                                                    newValue
                                                )
                                            }
                                            onChangeActive={(event, newHover) =>
                                                handleHoverChange(
                                                    'deadlineComplianceRating',
                                                    newHover
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box marginY={2}>
                                <Typography variant='h5'>
                                    {t('contracts.rating.contractDetail')}
                                </Typography>
                                <Typography variant='caption' component='legend'>
                                    {t('contracts.rating.optional')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {loadingContractRating ? (
                                <Box display='flex' justifyContent='center' marginBottom={2.5}>
                                    <Skeleton variant='text' height={22} width='96%' />
                                </Box>
                            ) : (
                                <TextField
                                    fullWidth
                                    color='primary'
                                    variant='outlined'
                                    label={t('contracts.rating.labelDetail')}
                                    name='detail'
                                    defaultValue={form.values.detail}
                                    value={form.values.detail}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    multiline
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                    error={form.touched.detail && !!form.errors.detail}
                                    helperText={`${
                                        form.values?.detail?.length
                                            ? form.values?.detail?.length
                                            : '0'
                                    }/500`}
                                    autoFocus
                                    minRows={3}
                                    maxRows={4}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mt={1} width={1} display='flex' justifyContent='space-between'>
                            <Button variant='outlined' size='small' onClick={() => onClose()}>
                                {t('term.cancel')}
                            </Button>

                            <Box display='flex' gridGap={16}>
                                {Boolean(contractRatingEdited) && (
                                    <Button
                                        className={classes.deleteButton}
                                        variant='contained'
                                        size='small'
                                        onClick={handleDeleteContractRating}
                                    >
                                        {!loadingFormSubmit ? (
                                            t('term.delete')
                                        ) : (
                                            <CircularProgress color='primary' size={25} />
                                        )}
                                    </Button>
                                )}

                                <Button
                                    variant='contained'
                                    size='small'
                                    disabled={loadingFormSubmit || !form.dirty || !form.isValid}
                                    onClick={() => form.submitForm()}
                                >
                                    {!loadingFormSubmit ? (
                                        t('term.save')
                                    ) : (
                                        <CircularProgress color='primary' size={25} />
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
}
