import { MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';

export const SearchMenuItem = styled(MenuItem)`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledSearchSelect = styled(Select)`
    > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }
`;
