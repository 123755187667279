/* eslint-disable react/no-array-index-key */
import { CircularProgress } from '@material-ui/core';
import { auctionNoticeMessage } from 'clients/manager/auction-notice-message.requests';
import {
    AuctionMessagesList,
    DetailedAutcionNotice,
} from 'clients/manager/interfaces/auction-notice.interface';
import ProcessComment from 'modules/bidding/components/process-comment';
import ProcessCommentBox from 'modules/bidding/components/process-comment-box';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiConversation } from 'react-icons/bi';
import moment from 'moment';
import { MessageTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';
import { getAuthUserToken } from 'modules/auth/session.utils';
import {
    CleanContainer,
    ProcessDescriptionCard,
    ProcessMessageListContainer,
    ProcessMessageSection,
} from '../../styled';

interface RequestContainerProps {
    id: string;
    loading: boolean;
    currentDate: {
        localDate: Date;
        serverDate: Date;
        diference: number;
    };
    generalData?: DetailedAutcionNotice;
    messageTypeRule?: MessageTypeRule;
}

enum MessageType {
    type1 = 'explanation',
    type2 = 'impugnment',
    type3 = 'appeal',
    type4 = 'objection',
}

export type GetMessagesArgs = { offset?: number; limit?: number };

const MessagesContainer = ({
    loading: generalLoading = true,
    generalData,
    messageTypeRule,
    id,
    currentDate,
}: RequestContainerProps) => {
    const { t } = useTranslation();
    const requestListContainerRef = useRef<HTMLDivElement>(null);
    const [messages, setMessages] = useState<AuctionMessagesList[]>([]);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const loading = useMemo(
        () => generalLoading || messagesLoading,
        [generalLoading, messagesLoading]
    );
    const [buttonsRules, setButtonsRules] = useState({
        impugnment: false,
        explanation: false,
        appeal: false,
    });
    const [canShowButton, setCanShowButton] = useState({
        impugnment: false,
        objection: false,
        explanation: false,
        appeal: false,
    });

    const getMessages = async (args?: GetMessagesArgs, noLoading: boolean = false) => {
        setMessagesLoading(!noLoading);
        try {
            const { data } = await auctionNoticeMessage.listMessagesByAuctionId(id, {
                limit: args?.limit || 100,
                offset: args?.offset || 0,
            });
            return data;
        } finally {
            setMessagesLoading(false);
        }
    };

    const scrollToBottom = () => {
        if (!requestListContainerRef.current) return;

        const scrollBottomCoord = requestListContainerRef.current.scrollHeight;

        requestListContainerRef.current.scrollTo({ top: scrollBottomCoord });
    };

    useEffect(() => {
        getMessages().then((data) => setMessages(data));
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages, loading]);

    useEffect(() => {
        const authUser = getAuthUserToken();
        if (!authUser?.userId) return;

        if (messageTypeRule) {
            const { recurso, esclarecimentos, impugnacao } = messageTypeRule;

            setButtonsRules({
                appeal: recurso.habilitar,
                impugnment: impugnacao.habilitar,
                explanation: esclarecimentos.habilitar,
            });
        }
    }, [messageTypeRule]);

    useEffect(() => {
        const isCurrentDateBeforeDeadline = (deadLine: Date) =>
            moment(new Date()).add(currentDate.diference, 'ms').isBefore(deadLine);

        let timer: NodeJS.Timeout;

        if (generalData) {
            setCanShowButton({
                impugnment: isCurrentDateBeforeDeadline(new Date(generalData.deadlineImpugnment)),
                appeal: isCurrentDateBeforeDeadline(new Date(generalData.deadlineAppeal)),
                explanation: isCurrentDateBeforeDeadline(
                    new Date(generalData.deadlineClarification)
                ),
                objection: isCurrentDateBeforeDeadline(new Date(generalData.deadlineCounterReason)),
            });

            timer = setInterval(() => {
                setCanShowButton({
                    impugnment: isCurrentDateBeforeDeadline(
                        new Date(generalData.deadlineImpugnment)
                    ),
                    explanation:
                        generalData.deadlineClarification === null
                            ? true
                            : isCurrentDateBeforeDeadline(
                                  new Date(generalData.deadlineClarification)
                              ),
                    appeal: isCurrentDateBeforeDeadline(new Date(generalData.deadlineAppeal)),
                    objection: isCurrentDateBeforeDeadline(
                        new Date(generalData.deadlineCounterReason)
                    ),
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [currentDate, generalData]);

    return (
        <ProcessDescriptionCard variant='outlined'>
            <header>
                <BiConversation size='1.5rem' />
                <h1>{t('bidding.process.section.messages')}</h1>
            </header>
            <ProcessMessageSection>
                <ProcessMessageListContainer
                    ref={requestListContainerRef}
                    empty={!loading && messages.length === 0}
                    {...{ loading }}
                >
                    {loading && <CircularProgress />}
                    {!loading && messages.length === 0 && (
                        <CleanContainer>
                            <h3>{t('bidding.process.section.messages.blank')}</h3>
                        </CleanContainer>
                    )}
                    {!loading &&
                        messages.map(({ message, answer, provider, type }, index) => (
                            <ProcessComment
                                key={`${index}-${message.createdAt}`}
                                name={message.user.name}
                                message={message.message}
                                date={new Date(message.createdAt)}
                                files={message.files}
                                title={provider?.companyName}
                                deferral={message?.deferral}
                                replies={
                                    (answer?.message && [
                                        {
                                            message: answer.message,
                                            date: new Date(answer.createdAt),
                                            name: answer?.user?.name,
                                            files: answer.files,
                                        },
                                    ]) ||
                                    []
                                }
                                type={MessageType[`type${type}`]}
                                last={index === messages.length - 1}
                            />
                        ))}
                </ProcessMessageListContainer>
                <ProcessCommentBox
                    {...{ id, messages, setMessages, getMessages, canShowButton, buttonsRules }}
                />
            </ProcessMessageSection>
        </ProcessDescriptionCard>
    );
};

export default memo(MessagesContainer);
