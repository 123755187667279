import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    chipSegment: {
        backgroundColor: theme.palette.grey['300'],
        color: theme.palette.grey['700'],
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem',
        marginLeft: '2.5rem',
        maxWidth: '100%',
        fontSize: 16,
        fontWeight: 'bold',
    },
    formattedItem: {
        color: theme.palette.grey['700'],
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: 'Nunito',
    },
}));
