import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType } from 'common/utils';
import { ProviderUpdateRequestWarningContextProvider } from 'common/components/provider-update-request-warning/context';
import { TypeAccess } from 'common/interfaces/user.interface';
import NotFound from 'routes/not-found';
import EditCompany from './edit-company';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <ProviderUpdateRequestWarningContextProvider>
            <Switch>
                <PrivateRoute
                    canAccess={() => hasCurrentAccessType([TypeAccess.provider])}
                    path={`${match.path}/`}
                    exact
                    component={() => null}
                />
                <PrivateRoute
                    canAccess={() => hasCurrentAccessType([TypeAccess.provider])}
                    path={`${match.path}/editar/:idProvider?/:defaultTab?`}
                    exact
                    component={() => <EditCompany />}
                />
                <PrivateRoute path='*' key='*' component={() => <NotFound />} />
            </Switch>
        </ProviderUpdateRequestWarningContextProvider>
    );
};

const CompanyRoutes: JSX.Element[] = [
    <Route path='/empresa' key='/empresa' render={() => <ChildRoutes />} />,
];

export default CompanyRoutes;
