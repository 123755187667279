import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import useStyles from './process-iframe-data-styles';

interface ProcessIframeDataViewProps {
    src?: string;
    loading?: boolean;
    title: string;
}

const ProcessIframeDataView: React.FC<ProcessIframeDataViewProps> = ({
    title,
    src,
    loading = false,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <div>
            {!!src && (
                <iframe className={styles.frame} title={title} src={src}>
                    <div className={styles.unsupportedContainer}>
                        <Typography>{t('process-tabs.unsupported-iframe-text')}</Typography>
                        <a href={src} rel='noopener noreferrer'>
                            {t('process-tabs.unsupported-iframe-redirect')}
                        </a>
                    </div>
                </iframe>
            )}
            {loading && (
                <Alert className={styles.loadingContainer} severity='info'>
                    <strong>{t('term.loading')}...</strong>
                </Alert>
            )}
        </div>
    );
};

export default ProcessIframeDataView;
