type Storage = 'session' | 'local';

const getStorage = (type: Storage) => {
    switch (type) {
        case 'session':
            return sessionStorage;
        default:
            return localStorage;
    }
};

const storage = {
    get: (key: string, storageType: Storage = 'local') => {
        const value = getStorage(storageType).getItem(key);
        try {
            return value && JSON.parse(value);
        } catch {
            return value;
        }
    },
    set: (key: string, value: string | Record<string, unknown>, storageType: Storage = 'local') => {
        getStorage(storageType).setItem(
            key,
            typeof value !== 'string' ? JSON.stringify(value) : value
        );
    },
    remove: (key: string, storageType: Storage = 'local') =>
        getStorage(storageType).removeItem(key),
};

export { storage };
