import { FC, useEffect, useState } from 'react';
import { average, calculateMedian, lowestValue, formatCurrency } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { ResumeCardsProps } from './props';
import { CardTitle, Content, CardContent, Card, CardWrapper } from './styled';

interface Resume {
    lowestPrice: number;
    average: number;
    median: number;
}

const intialResume = {
    lowestPrice: 0,
    average: 0,
    median: 0,
};

const ResumeCards: FC<ResumeCardsProps> = ({ pricesSelected }) => {
    const { t } = useTranslation();
    const [resume, setResume] = useState<Resume>(intialResume);

    const calculateResume = () => {
        const unitValues: number[] = [];
        Object.values(pricesSelected).forEach((item) => {
            Object.values(item).forEach((price) => unitValues.push(price.unitValue));
        });

        if (!unitValues.length) {
            return setResume(intialResume);
        }

        const currLowestPrice = lowestValue(unitValues);
        const currAverage = average(unitValues);
        const currMedian = calculateMedian(unitValues);

        setResume(() => ({
            lowestPrice: currLowestPrice,
            average: currAverage,
            median: currMedian,
        }));
    };

    useEffect(() => {
        calculateResume();
    }, [pricesSelected]);

    const getFormattedValue = (value: number) => (value === 0 ? '-' : formatCurrency(value));

    return (
        <Content>
            <Card>
                <CardWrapper>
                    <CardTitle>
                        <span>{t('term.average')}</span>
                    </CardTitle>
                    <CardContent>
                        <span>{getFormattedValue(resume.average)}</span>
                    </CardContent>
                </CardWrapper>
            </Card>

            <Card>
                <CardWrapper>
                    <CardTitle>
                        <span>{t('term.median')}</span>
                    </CardTitle>
                    <CardContent>
                        <span>{getFormattedValue(resume.median)}</span>
                    </CardContent>
                </CardWrapper>
            </Card>

            <Card>
                <CardWrapper>
                    <CardTitle>
                        <span>{t('term.lowest-price')}</span>
                    </CardTitle>
                    <CardContent>
                        <span>{getFormattedValue(resume.lowestPrice)}</span>
                    </CardContent>
                </CardWrapper>
            </Card>
        </Content>
    );
};

export default ResumeCards;
