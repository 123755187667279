import { Skeleton } from '@licitar/ui-kit';
import { FC, useEffect, useRef } from 'react';
import { ViewPage, EditArea, SkeletonLine } from './styles';

interface PreviewEditorProps {
    html: string | undefined;
    loading: boolean;
    withBorders?: boolean;
}

const SkeletonLoader: FC<any> = () => (
    <>
        {new Array(5).fill(null).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SkeletonLine key={index}>
                <Skeleton width='100%' height='24px' count={5} color='#e7e7e7' />
            </SkeletonLine>
        ))}
    </>
);

const PreviewEditor: FC<PreviewEditorProps> = ({ html, loading, withBorders }) => {
    const viewRef: any = useRef(null);

    useEffect(() => {
        if (html) {
            viewRef.current.innerHTML = html;
        }
    }, [html]);

    return (
        <EditArea>
            <ViewPage withBorders={withBorders} ref={viewRef}>
                {loading && !html ? <SkeletonLoader /> : null}
            </ViewPage>
        </EditArea>
    );
};

export default PreviewEditor;
