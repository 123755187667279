import React from 'react';
import { Typography } from '@material-ui/core';
import VerificationInput from 'react-verification-input';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useStyles from './verification-field-style';

interface VerificationFieldViewProps {
    errorText?: string;
    loadingText?: string;
    hasError?: boolean;
    isLoading?: boolean;
    value?: string;
    length?: number;
    onChange?: (value: string) => void;
    onBlur?: () => void;
}

const VerificationFieldView: React.FC<VerificationFieldViewProps> = ({
    value,
    onChange,
    onBlur,
    errorText,
    loadingText,
    length = 7,
    hasError = false,
    isLoading = false,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <VerificationInput
                autoFocus
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                length={length}
                placeholder=''
                classNames={{
                    container: 'container',
                    character: hasError ? 'character-invalid' : 'character',
                    characterInactive: 'character-inactive',
                    characterSelected: 'character-selected',
                    characterFilled: isLoading ? 'character-inactive' : 'character--filled',
                }}
                inputProps={{
                    disabled: isLoading,
                }}
            />

            {!isLoading && hasError && (
                <Typography className={styles.error}>
                    {errorText}
                    <img
                        className={styles.icon}
                        src='/assets/icons/error_filled.svg'
                        alt='Error icon'
                    />
                </Typography>
            )}

            {isLoading && (
                <Typography className={styles.loading}>
                    {loadingText ?? t('code-validator.field-loading')}
                    <img
                        className={clsx(styles.icon, 'rotating')}
                        src='/assets/icons/loading.svg'
                        alt='Spinner icon'
                    />
                </Typography>
            )}
        </>
    );
};

export default VerificationFieldView;
