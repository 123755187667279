export enum NotificationType {
    success = 'success',
    danger = 'danger',
    info = 'info',
    default = 'default',
    warning = 'warning',
}

export interface BaseNotificationProps {
    title: string;
    message: string;
    type: NotificationType;
    duration?: number;
}

export interface NotificationProps extends Partial<BaseNotificationProps> {}

export interface NotificationWithMessageProps extends NotificationProps {
    message: string;
}
