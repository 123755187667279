import { Plan, planRequests } from 'clients/manager/plan.request';
import GenericError from 'common/components/generic-error';
import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PlansView from './PlansView';

interface Params {
    [key: string]: string;
}

const Plans = ({ process }) => {
    const [selected, setSelected] = useState<any>('');
    const { setSelectedSteps } = useCheckoutContext();
    const [availablePlans, setAvailablePlans] = useState<Plan[]>([]);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [messageError, setMessageError] = useState();

    const { auctionId } = useParams<Params>();

    useEffect(() => {
        const fetchAvailablePlans = async () => {
            try {
                setLoading(true);
                const { data: responsePlans } = await planRequests.listAvailablePlans(
                    Number(auctionId)
                );

                setAvailablePlans(responsePlans);
                setSelected(responsePlans[0]);
            } catch (error: any) {
                const { response } = error;
                const { data } = response;
                setHasError(true);
                setMessageError(data.token);
            } finally {
                setLoading(false);
            }
        };

        fetchAvailablePlans();
    }, []);

    const handleClick = (selected) => {
        setSelected(selected);
    };

    return hasError ? (
        <GenericError message={messageError} />
    ) : (
        <PlansView
            {...{
                selected,
                handleClick,
                setSelectedSteps,
                availablePlans,
                loading,
                process,
            }}
        />
    );
};
export default Plans;
