import axios, { AxiosResponse } from 'axios';

const viaCepInstance = axios.create({
    baseURL: `https://viacep.com.br/ws/`,
    timeout: 15000,
});

const doRequest = async <T>(request: Promise<AxiosResponse<T>>) => {
    const req = await request;
    return req.data;
};

export { viaCepInstance, doRequest };
