import React, { FC, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProcessInvite } from 'clients/manager/interfaces/process-invite.interface';
import moment from 'moment';

interface InvitesTableProps {
    invites: ProcessInvite[];
}

const InvitesTable: FC<InvitesTableProps> = ({ invites }) => {
    const rowsPerPageOptions = [8];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);

    const { t } = useTranslation();

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <TableContainer>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left' padding='normal'>
                                {t('term.name')}
                            </TableCell>
                            <TableCell align='left' padding='normal'>
                                Email
                            </TableCell>
                            <TableCell align='left' padding='normal'>
                                {t('term.sent')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invites
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((invite) => (
                                <TableRow key={invite.id}>
                                    <TableCell>{invite.name}</TableCell>
                                    <TableCell>{invite.email}</TableCell>
                                    <TableCell>
                                        {moment(invite.createdAt).format('DD/MM/YYYY HH:ss')}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {invites.length > rowsPerPage && (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component='div'
                    size='small'
                    count={invites.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('text.lines-per-page')}
                />
            )}
        </>
    );
};

export default InvitesTable;
