enum StatusCrc {
    'reproved' = -1,
    'pending' = 0,
    'isValid' = 1,
    'suspended' = 2,
}

enum StatusPayment {
    'pending' = 'pending',
    'paid' = 'paid',
    'free' = 'free',
    'canceled' = 'canceled',
}

enum StatusResponseEnum {
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    error = 'error',
}

export { StatusPayment, StatusResponseEnum, StatusCrc };
