import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    captionText: {
        fontWeight: 'normal',
    },
    boxInformationFiles: {
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderColor: 'var(--border-color)',
        borderRadius: '4px',
        height: theme.spacing(20),
        overflow: 'auto',
    },
    buttonUploadFile: {
        cursor: 'pointer',
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    chipNoImageMessage: {
        border: 'none',
        padding: 8,
    },
    boxContainerFiles: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));

export default useStyles;
