interface Municipality {
    id: number;
    description: string;
}

const municipalityConstants: Municipality[] = [
    { id: 1100015, description: 'ALTA FLORESTA D OESTE' },
    { id: 1100023, description: 'ARIQUEMES' },
    { id: 1100031, description: 'CABIXI' },
    { id: 1100049, description: 'CACOAL' },
    { id: 1100056, description: 'CEREJEIRAS' },
    { id: 1100064, description: 'COLORADO DO OESTE' },
    { id: 1100072, description: 'CORUMBIARA' },
    { id: 1100080, description: 'COSTA MARQUES' },
    { id: 1100098, description: 'ESPIGAO DO OESTE' },
    { id: 1100106, description: 'GUAJARA MIRIM' },
    { id: 1100114, description: 'JARU' },
    { id: 1100122, description: 'JI PARANA' },
    { id: 1100130, description: 'MACHADINHO D OESTE' },
    { id: 1100148, description: 'NOVA BRASILANDIA D OESTE' },
    { id: 1100155, description: 'OURO PRETO DO OESTE' },
    { id: 1100189, description: 'PIMENTA BUENO' },
    { id: 1100205, description: 'PORTO VELHO' },
    { id: 1100254, description: 'PRESIDENTE MEDICI' },
    { id: 1100262, description: 'RIO CRESPO' },
    { id: 1100288, description: 'ROLIM DE MOURA' },
    { id: 1100296, description: 'SANTA LUZIA D OESTE' },
    { id: 1100304, description: 'VILHENA' },
    { id: 1100320, description: 'SAO MIGUEL DO GUAPORE' },
    { id: 1100338, description: 'NOVA MAMORE' },
    { id: 1100346, description: 'ALVORADA D OESTE' },
    { id: 1100379, description: 'ALTO ALEGRE DOS PARECIS' },
    { id: 1100403, description: 'ALTO PARAISO' },
    { id: 1100452, description: 'BURITIS' },
    { id: 1100502, description: 'NOVO HORIZONTE DO OESTE' },
    { id: 1100601, description: 'CACAULANDIA' },
    { id: 1100700, description: 'CAMPO NOVO DE RONDONIA' },
    { id: 1100809, description: 'CANDEIAS DO JAMARI' },
    { id: 1100908, description: 'CASTANHEIRAS' },
    { id: 1100924, description: 'CHUPINGUAIA' },
    { id: 1100940, description: 'CUJUBIM' },
    { id: 1101005, description: 'GOVERNADOR JORGE TEIXEIRA' },
    { id: 1101104, description: 'ITAPUA DO OESTE' },
    { id: 1101203, description: 'MINISTRO ANDREAZZA' },
    { id: 1101302, description: 'MIRANTE DA SERRA' },
    { id: 1101401, description: 'MONTE NEGRO' },
    { id: 1101435, description: 'NOVA UNIAO' },
    { id: 1101450, description: 'PARECIS' },
    { id: 1101468, description: 'PIMENTEIRAS DO OESTE' },
    { id: 1101476, description: 'PRIMAVERA DE RONDONIA' },
    { id: 1101484, description: 'SAO FELIPE D OESTE' },
    { id: 1101492, description: 'SAO FRANCISCO DO GUAPORE' },
    { id: 1101500, description: 'SERINGUEIRAS' },
    { id: 1101559, description: 'TEIXEIROPOLIS' },
    { id: 1101609, description: 'THEOBROMA' },
    { id: 1101708, description: 'URUPA' },
    { id: 1101757, description: 'VALE DO ANARI' },
    { id: 1101807, description: 'VALE DO PARAISO' },
    { id: 1200013, description: 'ACRELANDIA' },
    { id: 1200054, description: 'ASSIS BRASIL' },
    { id: 1200104, description: 'BRASILEIA' },
    { id: 1200138, description: 'BUJARI' },
    { id: 1200179, description: 'CAPIXABA' },
    { id: 1200203, description: 'CRUZEIRO DO SUL' },
    { id: 1200252, description: 'EPITACIOLANDIA' },
    { id: 1200302, description: 'FEIJO' },
    { id: 1200328, description: 'JORDAO' },
    { id: 1200336, description: 'MANCIO LIMA' },
    { id: 1200344, description: 'MANOEL URBANO' },
    { id: 1200351, description: 'MARECHAL THAUMATURGO' },
    { id: 1200385, description: 'PLACIDO DE CASTRO' },
    { id: 1200393, description: 'PORTO WALTER' },
    { id: 1200401, description: 'RIO BRANCO' },
    { id: 1200427, description: 'RODRIGUES ALVES' },
    { id: 1200435, description: 'SANTA ROSA DO PURUS' },
    { id: 1200450, description: 'SENADOR GUIOMARD' },
    { id: 1200500, description: 'SENA MADUREIRA' },
    { id: 1200609, description: 'TARAUACA' },
    { id: 1200708, description: 'XAPURI' },
    { id: 1200807, description: 'PORTO ACRE' },
    { id: 1300029, description: 'ALVARAES' },
    { id: 1300060, description: 'AMATURA' },
    { id: 1300086, description: 'ANAMA' },
    { id: 1300102, description: 'ANORI' },
    { id: 1300144, description: 'APUI' },
    { id: 1300201, description: 'ATALAIA DO NORTE' },
    { id: 1300300, description: 'AUTAZES' },
    { id: 1300409, description: 'BARCELOS' },
    { id: 1300508, description: 'BARREIRINHA' },
    { id: 1300607, description: 'BENJAMIN CONSTANT' },
    { id: 1300631, description: 'BERURI' },
    { id: 1300680, description: 'BOA VISTA DO RAMOS' },
    { id: 1300706, description: 'BOCA DO ACRE' },
    { id: 1300805, description: 'BORBA' },
    { id: 1300839, description: 'CAAPIRANGA' },
    { id: 1300904, description: 'CANUTAMA' },
    { id: 1301001, description: 'CARAUARI' },
    { id: 1301100, description: 'CAREIRO' },
    { id: 1301159, description: 'CAREIRO DA VARZEA' },
    { id: 1301209, description: 'COARI' },
    { id: 1301308, description: 'CODAJAS' },
    { id: 1301407, description: 'EIRUNEPE' },
    { id: 1301506, description: 'ENVIRA' },
    { id: 1301605, description: 'FONTE BOA' },
    { id: 1301654, description: 'GUAJARA' },
    { id: 1301704, description: 'HUMAITA' },
    { id: 1301803, description: 'IPIXUNA' },
    { id: 1301852, description: 'IRANDUBA' },
    { id: 1301902, description: 'ITACOATIARA' },
    { id: 1301951, description: 'ITAMARATI' },
    { id: 1302009, description: 'ITAPIRANGA' },
    { id: 1302108, description: 'JAPURA' },
    { id: 1302207, description: 'JURUA' },
    { id: 1302306, description: 'JUTAI' },
    { id: 1302405, description: 'LABREA' },
    { id: 1302504, description: 'MANACAPURU' },
    { id: 1302553, description: 'MANAQUIRI' },
    { id: 1302603, description: 'MANAUS' },
    { id: 1302702, description: 'MANICORE' },
    { id: 1302801, description: 'MARAA' },
    { id: 1302900, description: 'MAUES' },
    { id: 1303007, description: 'NHAMUNDA' },
    { id: 1303106, description: 'NOVA OLINDA DO NORTE' },
    { id: 1303205, description: 'NOVO AIRAO' },
    { id: 1303304, description: 'NOVO ARIPUANA' },
    { id: 1303403, description: 'PARINTINS' },
    { id: 1303502, description: 'PAUINI' },
    { id: 1303536, description: 'PRESIDENTE FIGUEIREDO' },
    { id: 1303569, description: 'RIO PRETO DA EVA' },
    { id: 1303601, description: 'SANTA ISABEL DO RIO NEGRO' },
    { id: 1303700, description: 'SANTO ANTONIO DO ICA' },
    { id: 1303809, description: 'SAO GABRIEL DA CACHOEIRA' },
    { id: 1303908, description: 'SAO PAULO DE OLIVENCA' },
    { id: 1303957, description: 'SAO SEBASTIAO DO UATUMA' },
    { id: 1304005, description: 'SILVES' },
    { id: 1304062, description: 'TABATINGA' },
    { id: 1304104, description: 'TAPAUA' },
    { id: 1304203, description: 'TEFE' },
    { id: 1304237, description: 'TONANTINS' },
    { id: 1304260, description: 'UARINI' },
    { id: 1304302, description: 'URUCARA' },
    { id: 1304401, description: 'URUCURITUBA' },
    { id: 1400027, description: 'AMAJARI' },
    { id: 1400050, description: 'ALTO ALEGRE' },
    { id: 1400100, description: 'BOA VISTA' },
    { id: 1400159, description: 'BONFIM' },
    { id: 1400175, description: 'CANTA' },
    { id: 1400209, description: 'CARACARAI' },
    { id: 1400233, description: 'CAROEBE' },
    { id: 1400282, description: 'IRACEMA' },
    { id: 1400308, description: 'MUCAJAI' },
    { id: 1400407, description: 'NORMANDIA' },
    { id: 1400456, description: 'PACARAIMA' },
    { id: 1400472, description: 'RORAINOPOLIS' },
    { id: 1400506, description: 'SAO JOAO DA BALIZA' },
    { id: 1400605, description: 'SAO LUIZ' },
    { id: 1400704, description: 'UIRAMUTA' },
    { id: 1500107, description: 'ABAETETUBA' },
    { id: 1500131, description: 'ABEL FIGUEIREDO' },
    { id: 1500206, description: 'ACARA' },
    { id: 1500305, description: 'AFUA' },
    { id: 1500347, description: 'AGUA AZUL DO NORTE' },
    { id: 1500404, description: 'ALENQUER' },
    { id: 1500503, description: 'ALMEIRIM' },
    { id: 1500602, description: 'ALTAMIRA' },
    { id: 1500701, description: 'ANAJAS' },
    { id: 1500800, description: 'ANANINDEUA' },
    { id: 1500859, description: 'ANAPU' },
    { id: 1500909, description: 'AUGUSTO CORREA' },
    { id: 1500958, description: 'AURORA DO PARA' },
    { id: 1501006, description: 'AVEIRO' },
    { id: 1501105, description: 'BAGRE' },
    { id: 1501204, description: 'BAIAO' },
    { id: 1501253, description: 'BANNACH' },
    { id: 1501303, description: 'BARCARENA' },
    { id: 1501402, description: 'BELEM' },
    { id: 1501451, description: 'BELTERRA' },
    { id: 1501501, description: 'BENEVIDES' },
    { id: 1501576, description: 'BOM JESUS DO TOCANTINS' },
    { id: 1501600, description: 'BONITO' },
    { id: 1501709, description: 'BRAGANCA' },
    { id: 1501725, description: 'BRASIL NOVO' },
    { id: 1501758, description: 'BREJO GRANDE DO ARAGUAIA' },
    { id: 1501782, description: 'BREU BRANCO' },
    { id: 1501808, description: 'BREVES' },
    { id: 1501907, description: 'BUJARU' },
    { id: 1501956, description: 'CACHOEIRA DO PIRIA' },
    { id: 1502004, description: 'CACHOEIRA DO ARARI' },
    { id: 1502103, description: 'CAMETA' },
    { id: 1502152, description: 'CANAA DOS CARAJAS' },
    { id: 1502202, description: 'CAPANEMA' },
    { id: 1502301, description: 'CAPITAO POCO' },
    { id: 1502400, description: 'CASTANHAL' },
    { id: 1502509, description: 'CHAVES' },
    { id: 1502608, description: 'COLARES' },
    { id: 1502707, description: 'CONCEICAO DO ARAGUAIA' },
    { id: 1502756, description: 'CONCORDIA DO PARA' },
    { id: 1502764, description: 'CUMARU DO NORTE' },
    { id: 1502772, description: 'CURIONOPOLIS' },
    { id: 1502806, description: 'CURRALINHO' },
    { id: 1502855, description: 'CURUA' },
    { id: 1502905, description: 'CURUCA' },
    { id: 1502939, description: 'DOM ELISEU' },
    { id: 1502954, description: 'ELDORADO DOS CARAJAS' },
    { id: 1503002, description: 'FARO' },
    { id: 1503044, description: 'FLORESTA DO ARAGUAIA' },
    { id: 1503077, description: 'GARRAFAO DO NORTE' },
    { id: 1503093, description: 'GOIANESIA DO PARA' },
    { id: 1503101, description: 'GURUPA' },
    { id: 1503200, description: 'IGARAPE ACU' },
    { id: 1503309, description: 'IGARAPE MIRI' },
    { id: 1503408, description: 'INHANGAPI' },
    { id: 1503457, description: 'IPIXUNA DO PARA' },
    { id: 1503507, description: 'IRITUIA' },
    { id: 1503606, description: 'ITAITUBA' },
    { id: 1503705, description: 'ITUPIRANGA' },
    { id: 1503754, description: 'JACAREACANGA' },
    { id: 1503804, description: 'JACUNDA' },
    { id: 1503903, description: 'JURUTI' },
    { id: 1504000, description: 'LIMOEIRO DO AJURU' },
    { id: 1504059, description: 'MAE DO RIO' },
    { id: 1504109, description: 'MAGALHAES BARATA' },
    { id: 1504208, description: 'MARABA' },
    { id: 1504307, description: 'MARACANA' },
    { id: 1504406, description: 'MARAPANIM' },
    { id: 1504422, description: 'MARITUBA' },
    { id: 1504455, description: 'MEDICILANDIA' },
    { id: 1504505, description: 'MELGACO' },
    { id: 1504604, description: 'MOCAJUBA' },
    { id: 1504703, description: 'MOJU' },
    { id: 1504752, description: 'MOJUI DOS CAMPOS' },
    { id: 1504802, description: 'MONTE ALEGRE' },
    { id: 1504901, description: 'MUANA' },
    { id: 1504950, description: 'NOVA ESPERANCA DO PIRIA' },
    { id: 1504976, description: 'NOVA IPIXUNA' },
    { id: 1505007, description: 'NOVA TIMBOTEUA' },
    { id: 1505031, description: 'NOVO PROGRESSO' },
    { id: 1505064, description: 'NOVO REPARTIMENTO' },
    { id: 1505106, description: 'OBIDOS' },
    { id: 1505205, description: 'OEIRAS DO PARA' },
    { id: 1505304, description: 'ORIXIMINA' },
    { id: 1505403, description: 'OUREM' },
    { id: 1505437, description: 'OURILANDIA DO NORTE' },
    { id: 1505486, description: 'PACAJA' },
    { id: 1505494, description: 'PALESTINA DO PARA' },
    { id: 1505502, description: 'PARAGOMINAS' },
    { id: 1505536, description: 'PARAUAPEBAS' },
    { id: 1505551, description: 'PAU D ARCO' },
    { id: 1505601, description: 'PEIXE BOI' },
    { id: 1505635, description: 'PICARRA' },
    { id: 1505650, description: 'PLACAS' },
    { id: 1505700, description: 'PONTA DE PEDRAS' },
    { id: 1505809, description: 'PORTEL' },
    { id: 1505908, description: 'PORTO DE MOZ' },
    { id: 1506005, description: 'PRAINHA' },
    { id: 1506104, description: 'PRIMAVERA' },
    { id: 1506112, description: 'QUATIPURU' },
    { id: 1506138, description: 'REDENCAO' },
    { id: 1506161, description: 'RIO MARIA' },
    { id: 1506187, description: 'RONDON DO PARA' },
    { id: 1506195, description: 'RUROPOLIS' },
    { id: 1506203, description: 'SALINOPOLIS' },
    { id: 1506302, description: 'SALVATERRA' },
    { id: 1506351, description: 'SANTA BARBARA DO PARA' },
    { id: 1506401, description: 'SANTA CRUZ DO ARARI' },
    { id: 1506500, description: 'SANTA ISABEL DO PARA' },
    { id: 1506559, description: 'SANTA LUZIA DO PARA' },
    { id: 1506583, description: 'SANTA MARIA DAS BARREIRAS' },
    { id: 1506609, description: 'SANTA MARIA DO PARA' },
    { id: 1506708, description: 'SANTANA DO ARAGUAIA' },
    { id: 1506807, description: 'SANTAREM' },
    { id: 1506906, description: 'SANTAREM NOVO' },
    { id: 1507003, description: 'SANTO ANTONIO DO TAUA' },
    { id: 1507102, description: 'SAO CAETANO DE ODIVELAS' },
    { id: 1507151, description: 'SAO DOMINGOS DO ARAGUAIA' },
    { id: 1507201, description: 'SAO DOMINGOS DO CAPIM' },
    { id: 1507300, description: 'SAO FELIX DO XINGU' },
    { id: 1507409, description: 'SAO FRANCISCO DO PARA' },
    { id: 1507458, description: 'SAO GERALDO DO ARAGUAIA' },
    { id: 1507466, description: 'SAO JOAO DA PONTA' },
    { id: 1507474, description: 'SAO JOAO DE PIRABAS' },
    { id: 1507508, description: 'SAO JOAO DO ARAGUAIA' },
    { id: 1507607, description: 'SAO MIGUEL DO GUAMA' },
    { id: 1507706, description: 'SAO SEBASTIAO DA BOA VISTA' },
    { id: 1507755, description: 'SAPUCAIA' },
    { id: 1507805, description: 'SENADOR JOSE PORFIRIO' },
    { id: 1507904, description: 'SOURE' },
    { id: 1507953, description: 'TAILANDIA' },
    { id: 1507961, description: 'TERRA ALTA' },
    { id: 1507979, description: 'TERRA SANTA' },
    { id: 1508001, description: 'TOME ACU' },
    { id: 1508035, description: 'TRACUATEUA' },
    { id: 1508050, description: 'TRAIRAO' },
    { id: 1508084, description: 'TUCUMA' },
    { id: 1508100, description: 'TUCURUI' },
    { id: 1508126, description: 'ULIANOPOLIS' },
    { id: 1508159, description: 'URUARA' },
    { id: 1508209, description: 'VIGIA' },
    { id: 1508308, description: 'VISEU' },
    { id: 1508357, description: 'VITORIA DO XINGU' },
    { id: 1508407, description: 'XINGUARA' },
    { id: 1600055, description: 'SERRA DO NAVIO' },
    { id: 1600105, description: 'AMAPA' },
    { id: 1600154, description: 'PEDRA BRANCA DO AMAPARI' },
    { id: 1600204, description: 'CALCOENE' },
    { id: 1600212, description: 'CUTIAS' },
    { id: 1600238, description: 'FERREIRA GOMES' },
    { id: 1600253, description: 'ITAUBAL' },
    { id: 1600279, description: 'LARANJAL DO JARI' },
    { id: 1600303, description: 'MACAPA' },
    { id: 1600402, description: 'MAZAGAO' },
    { id: 1600501, description: 'OIAPOQUE' },
    { id: 1600535, description: 'PORTO GRANDE' },
    { id: 1600550, description: 'PRACUUBA' },
    { id: 1600600, description: 'SANTANA' },
    { id: 1600709, description: 'TARTARUGALZINHO' },
    { id: 1600808, description: 'VITORIA DO JARI' },
    { id: 1700251, description: 'ABREULANDIA' },
    { id: 1700301, description: 'AGUIARNOPOLIS' },
    { id: 1700350, description: 'ALIANCA DO TOCANTINS' },
    { id: 1700400, description: 'ALMAS' },
    { id: 1700707, description: 'ALVORADA' },
    { id: 1701002, description: 'ANANAS' },
    { id: 1701051, description: 'ANGICO' },
    { id: 1701101, description: 'APARECIDA DO RIO NEGRO' },
    { id: 1701309, description: 'ARAGOMINAS' },
    { id: 1701903, description: 'ARAGUACEMA' },
    { id: 1702000, description: 'ARAGUACU' },
    { id: 1702109, description: 'ARAGUAINA' },
    { id: 1702158, description: 'ARAGUANA' },
    { id: 1702208, description: 'ARAGUATINS' },
    { id: 1702307, description: 'ARAPOEMA' },
    { id: 1702406, description: 'ARRAIAS' },
    { id: 1702554, description: 'AUGUSTINOPOLIS' },
    { id: 1702703, description: 'AURORA DO TOCANTINS' },
    { id: 1702901, description: 'AXIXA DO TOCANTINS' },
    { id: 1703008, description: 'BABACULANDIA' },
    { id: 1703057, description: 'BANDEIRANTES DO TOCANTINS' },
    { id: 1703073, description: 'BARRA DO OURO' },
    { id: 1703107, description: 'BARROLANDIA' },
    { id: 1703206, description: 'BERNARDO SAYAO' },
    { id: 1703305, description: 'BOM JESUS DO TOCANTINS' },
    { id: 1703602, description: 'BRASILANDIA DO TOCANTINS' },
    { id: 1703701, description: 'BREJINHO DE NAZARE' },
    { id: 1703800, description: 'BURITI DO TOCANTINS' },
    { id: 1703826, description: 'CACHOEIRINHA' },
    { id: 1703842, description: 'CAMPOS LINDOS' },
    { id: 1703867, description: 'CARIRI DO TOCANTINS' },
    { id: 1703883, description: 'CARMOLANDIA' },
    { id: 1703891, description: 'CARRASCO BONITO' },
    { id: 1703909, description: 'CASEARA' },
    { id: 1704105, description: 'CENTENARIO' },
    { id: 1704600, description: 'CHAPADA DE AREIA' },
    { id: 1705102, description: 'CHAPADA DA NATIVIDADE' },
    { id: 1705508, description: 'COLINAS DO TOCANTINS' },
    { id: 1705557, description: 'COMBINADO' },
    { id: 1705607, description: 'CONCEICAO DO TOCANTINS' },
    { id: 1706001, description: 'COUTO DE MAGALHAES' },
    { id: 1706100, description: 'CRISTALANDIA' },
    { id: 1706258, description: 'CRIXAS DO TOCANTINS' },
    { id: 1706506, description: 'DARCINOPOLIS' },
    { id: 1707009, description: 'DIANOPOLIS' },
    { id: 1707108, description: 'DIVINOPOLIS DO TOCANTINS' },
    { id: 1707207, description: 'DOIS IRMAOS DO TOCANTINS' },
    { id: 1707306, description: 'DUERE' },
    { id: 1707405, description: 'ESPERANTINA' },
    { id: 1707553, description: 'FATIMA' },
    { id: 1707652, description: 'FIGUEIROPOLIS' },
    { id: 1707702, description: 'FILADELFIA' },
    { id: 1708205, description: 'FORMOSO DO ARAGUAIA' },
    { id: 1708254, description: 'FORTALEZA DO TABOCAO' },
    { id: 1708304, description: 'GOIANORTE' },
    { id: 1709005, description: 'GOIATINS' },
    { id: 1709302, description: 'GUARAI' },
    { id: 1709500, description: 'GURUPI' },
    { id: 1709807, description: 'IPUEIRAS' },
    { id: 1710508, description: 'ITACAJA' },
    { id: 1710706, description: 'ITAGUATINS' },
    { id: 1710904, description: 'ITAPIRATINS' },
    { id: 1711100, description: 'ITAPORA DO TOCANTINS' },
    { id: 1711506, description: 'JAU DO TOCANTINS' },
    { id: 1711803, description: 'JUARINA' },
    { id: 1711902, description: 'LAGOA DA CONFUSAO' },
    { id: 1711951, description: 'LAGOA DO TOCANTINS' },
    { id: 1712009, description: 'LAJEADO' },
    { id: 1712157, description: 'LAVANDEIRA' },
    { id: 1712405, description: 'LIZARDA' },
    { id: 1712454, description: 'LUZINOPOLIS' },
    { id: 1712504, description: 'MARIANOPOLIS DO TOCANTINS' },
    { id: 1712702, description: 'MATEIROS' },
    { id: 1712801, description: 'MAURILANDIA DO TOCANTINS' },
    { id: 1713205, description: 'MIRACEMA DO TOCANTINS' },
    { id: 1713304, description: 'MIRANORTE' },
    { id: 1713601, description: 'MONTE DO CARMO' },
    { id: 1713700, description: 'MONTE SANTO DO TOCANTINS' },
    { id: 1713809, description: 'PALMEIRAS DO TOCANTINS' },
    { id: 1713957, description: 'MURICILANDIA' },
    { id: 1714203, description: 'NATIVIDADE' },
    { id: 1714302, description: 'NAZARE' },
    { id: 1714880, description: 'NOVA OLINDA' },
    { id: 1715002, description: 'NOVA ROSALANDIA' },
    { id: 1715101, description: 'NOVO ACORDO' },
    { id: 1715150, description: 'NOVO ALEGRE' },
    { id: 1715259, description: 'NOVO JARDIM' },
    { id: 1715507, description: 'OLIVEIRA DE FATIMA' },
    { id: 1715705, description: 'PALMEIRANTE' },
    { id: 1715754, description: 'PALMEIROPOLIS' },
    { id: 1716109, description: 'PARAISO DO TOCANTINS' },
    { id: 1716208, description: 'PARANA' },
    { id: 1716307, description: 'PAU D ARCO' },
    { id: 1716505, description: 'PEDRO AFONSO' },
    { id: 1716604, description: 'PEIXE' },
    { id: 1716653, description: 'PEQUIZEIRO' },
    { id: 1716703, description: 'COLMEIA' },
    { id: 1717008, description: 'PINDORAMA DO TOCANTINS' },
    { id: 1717206, description: 'PIRAQUE' },
    { id: 1717503, description: 'PIUM' },
    { id: 1717800, description: 'PONTE ALTA DO BOM JESUS' },
    { id: 1717909, description: 'PONTE ALTA DO TOCANTINS' },
    { id: 1718006, description: 'PORTO ALEGRE DO TOCANTINS' },
    { id: 1718204, description: 'PORTO NACIONAL' },
    { id: 1718303, description: 'PRAIA NORTE' },
    { id: 1718402, description: 'PRESIDENTE KENNEDY' },
    { id: 1718451, description: 'PUGMIL' },
    { id: 1718501, description: 'RECURSOLANDIA' },
    { id: 1718550, description: 'RIACHINHO' },
    { id: 1718659, description: 'RIO DA CONCEICAO' },
    { id: 1718709, description: 'RIO DOS BOIS' },
    { id: 1718758, description: 'RIO SONO' },
    { id: 1718808, description: 'SAMPAIO' },
    { id: 1718840, description: 'SANDOLANDIA' },
    { id: 1718865, description: 'SANTA FE DO ARAGUAIA' },
    { id: 1718881, description: 'SANTA MARIA DO TOCANTINS' },
    { id: 1718899, description: 'SANTA RITA DO TOCANTINS' },
    { id: 1718907, description: 'SANTA ROSA DO TOCANTINS' },
    { id: 1719004, description: 'SANTA TEREZA DO TOCANTINS' },
    { id: 1720002, description: 'SANTA TEREZINHA DO TOCANTINS' },
    { id: 1720101, description: 'SAO BENTO DO TOCANTINS' },
    { id: 1720150, description: 'SAO FELIX DO TOCANTINS' },
    { id: 1720200, description: 'SAO MIGUEL DO TOCANTINS' },
    { id: 1720259, description: 'SAO SALVADOR DO TOCANTINS' },
    { id: 1720309, description: 'SAO SEBASTIAO DO TOCANTINS' },
    { id: 1720499, description: 'SAO VALERIO DA NATIVIDADE' },
    { id: 1720655, description: 'SILVANOPOLIS' },
    { id: 1720804, description: 'SITIO NOVO DO TOCANTINS' },
    { id: 1720853, description: 'SUCUPIRA' },
    { id: 1720903, description: 'TAGUATINGA' },
    { id: 1720937, description: 'TAIPAS DO TOCANTINS' },
    { id: 1720978, description: 'TALISMA' },
    { id: 1721000, description: 'PALMAS' },
    { id: 1721109, description: 'TOCANTINIA' },
    { id: 1721208, description: 'TOCANTINOPOLIS' },
    { id: 1721257, description: 'TUPIRAMA' },
    { id: 1721307, description: 'TUPIRATINS' },
    { id: 1722081, description: 'WANDERLANDIA' },
    { id: 1722107, description: 'XAMBIOA' },
    { id: 2100055, description: 'ACAILANDIA' },
    { id: 2100105, description: 'AFONSO CUNHA' },
    { id: 2100154, description: 'AGUA DOCE DO MARANHAO' },
    { id: 2100204, description: 'ALCANTARA' },
    { id: 2100303, description: 'ALDEIAS ALTAS' },
    { id: 2100402, description: 'ALTAMIRA DO MARANHAO' },
    { id: 2100436, description: 'ALTO ALEGRE DO MARANHAO' },
    { id: 2100477, description: 'ALTO ALEGRE DO PINDARE' },
    { id: 2100501, description: 'ALTO PARNAIBA' },
    { id: 2100550, description: 'AMAPA DO MARANHAO' },
    { id: 2100600, description: 'AMARANTE DO MARANHAO' },
    { id: 2100709, description: 'ANAJATUBA' },
    { id: 2100808, description: 'ANAPURUS' },
    { id: 2100832, description: 'APICUM ACU' },
    { id: 2100873, description: 'ARAGUANA' },
    { id: 2100907, description: 'ARAIOSES' },
    { id: 2100956, description: 'ARAME' },
    { id: 2101004, description: 'ARARI' },
    { id: 2101103, description: 'AXIXA' },
    { id: 2101202, description: 'BACABAL' },
    { id: 2101251, description: 'BACABEIRA' },
    { id: 2101301, description: 'BACURI' },
    { id: 2101350, description: 'BACURITUBA' },
    { id: 2101400, description: 'BALSAS' },
    { id: 2101509, description: 'BARAO DE GRAJAU' },
    { id: 2101608, description: 'BARRA DO CORDA' },
    { id: 2101707, description: 'BARREIRINHAS' },
    { id: 2101731, description: 'BELAGUA' },
    { id: 2101772, description: 'BELA VISTA DO MARANHAO' },
    { id: 2101806, description: 'BENEDITO LEITE' },
    { id: 2101905, description: 'BEQUIMAO' },
    { id: 2101939, description: 'BERNARDO DO MEARIM' },
    { id: 2101970, description: 'BOA VISTA DO GURUPI' },
    { id: 2102002, description: 'BOM JARDIM' },
    { id: 2102036, description: 'BOM JESUS DAS SELVAS' },
    { id: 2102077, description: 'BOM LUGAR' },
    { id: 2102101, description: 'BREJO' },
    { id: 2102150, description: 'BREJO DE AREIA' },
    { id: 2102200, description: 'BURITI' },
    { id: 2102309, description: 'BURITI BRAVO' },
    { id: 2102325, description: 'BURITICUPU' },
    { id: 2102358, description: 'BURITIRANA' },
    { id: 2102374, description: 'CACHOEIRA GRANDE' },
    { id: 2102408, description: 'CAJAPIO' },
    { id: 2102507, description: 'CAJARI' },
    { id: 2102556, description: 'CAMPESTRE DO MARANHAO' },
    { id: 2102606, description: 'CANDIDO MENDES' },
    { id: 2102705, description: 'CANTANHEDE' },
    { id: 2102754, description: 'CAPINZAL DO NORTE' },
    { id: 2102804, description: 'CAROLINA' },
    { id: 2102903, description: 'CARUTAPERA' },
    { id: 2103000, description: 'CAXIAS' },
    { id: 2103109, description: 'CEDRAL' },
    { id: 2103125, description: 'CENTRAL DO MARANHAO' },
    { id: 2103158, description: 'CENTRO DO GUILHERME' },
    { id: 2103174, description: 'CENTRO NOVO DO MARANHAO' },
    { id: 2103208, description: 'CHAPADINHA' },
    { id: 2103257, description: 'CIDELANDIA' },
    { id: 2103307, description: 'CODO' },
    { id: 2103406, description: 'COELHO NETO' },
    { id: 2103505, description: 'COLINAS' },
    { id: 2103554, description: 'CONCEICAO DO LAGO ACU' },
    { id: 2103604, description: 'COROATA' },
    { id: 2103703, description: 'CURURUPU' },
    { id: 2103752, description: 'DAVINOPOLIS' },
    { id: 2103802, description: 'DOM PEDRO' },
    { id: 2103901, description: 'DUQUE BACELAR' },
    { id: 2104008, description: 'ESPERANTINOPOLIS' },
    { id: 2104057, description: 'ESTREITO' },
    { id: 2104073, description: 'FEIRA NOVA DO MARANHAO' },
    { id: 2104081, description: 'FERNANDO FALCAO' },
    { id: 2104099, description: 'FORMOSA DA SERRA NEGRA' },
    { id: 2104107, description: 'FORTALEZA DOS NOGUEIRAS' },
    { id: 2104206, description: 'FORTUNA' },
    { id: 2104305, description: 'GODOFREDO VIANA' },
    { id: 2104404, description: 'GONCALVES DIAS' },
    { id: 2104503, description: 'GOVERNADOR ARCHER' },
    { id: 2104552, description: 'GOVERNADOR EDSON LOBAO' },
    { id: 2104602, description: 'GOVERNADOR EUGENIO BARROS' },
    { id: 2104628, description: 'GOVERNADOR LUIZ ROCHA' },
    { id: 2104651, description: 'GOVERNADOR NEWTON BELLO' },
    { id: 2104677, description: 'GOVERNADOR NUNES FREIRE' },
    { id: 2104701, description: 'GRACA ARANHA' },
    { id: 2104800, description: 'GRAJAU' },
    { id: 2104909, description: 'GUIMARAES' },
    { id: 2105005, description: 'HUMBERTO DE CAMPOS' },
    { id: 2105104, description: 'ICATU' },
    { id: 2105153, description: 'IGARAPE DO MEIO' },
    { id: 2105203, description: 'IGARAPE GRANDE' },
    { id: 2105302, description: 'IMPERATRIZ' },
    { id: 2105351, description: 'ITAIPAVA DO GRAJAU' },
    { id: 2105401, description: 'ITAPECURU MIRIM' },
    { id: 2105427, description: 'ITINGA DO MARANHAO' },
    { id: 2105450, description: 'JATOBA' },
    { id: 2105476, description: 'JENIPAPO DOS VIEIRAS' },
    { id: 2105500, description: 'JOAO LISBOA' },
    { id: 2105609, description: 'JOSELANDIA' },
    { id: 2105658, description: 'JUNCO DO MARANHAO' },
    { id: 2105708, description: 'LAGO DA PEDRA' },
    { id: 2105807, description: 'LAGO DO JUNCO' },
    { id: 2105906, description: 'LAGO VERDE' },
    { id: 2105922, description: 'LAGOA DO MATO' },
    { id: 2105948, description: 'LAGO DOS RODRIGUES' },
    { id: 2105963, description: 'LAGOA GRANDE DO MARANHAO' },
    { id: 2105989, description: 'LAJEADO NOVO' },
    { id: 2106003, description: 'LIMA CAMPOS' },
    { id: 2106102, description: 'LORETO' },
    { id: 2106201, description: 'LUIS DOMINGUES' },
    { id: 2106300, description: 'MAGALHAES DE ALMEIDA' },
    { id: 2106326, description: 'MARACACUME' },
    { id: 2106359, description: 'MARAJA DO SENA' },
    { id: 2106375, description: 'MARANHAOZINHO' },
    { id: 2106409, description: 'MATA ROMA' },
    { id: 2106508, description: 'MATINHA' },
    { id: 2106607, description: 'MATOES' },
    { id: 2106631, description: 'MATOES DO NORTE' },
    { id: 2106672, description: 'MILAGRES DO MARANHAO' },
    { id: 2106706, description: 'MIRADOR' },
    { id: 2106755, description: 'MIRANDA DO NORTE' },
    { id: 2106805, description: 'MIRINZAL' },
    { id: 2106904, description: 'MONCAO' },
    { id: 2107001, description: 'MONTES ALTOS' },
    { id: 2107100, description: 'MORROS' },
    { id: 2107209, description: 'NINA RODRIGUES' },
    { id: 2107258, description: 'NOVA COLINAS' },
    { id: 2107308, description: 'NOVA IORQUE' },
    { id: 2107357, description: 'NOVA OLINDA DO MARANHAO' },
    { id: 2107407, description: 'OLHO D AGUA DAS CUNHAS' },
    { id: 2107456, description: 'OLINDA NOVA DO MARANHAO' },
    { id: 2107506, description: 'PACO DO LUMIAR' },
    { id: 2107605, description: 'PALMEIRANDIA' },
    { id: 2107704, description: 'PARAIBANO' },
    { id: 2107803, description: 'PARNARAMA' },
    { id: 2107902, description: 'PASSAGEM FRANCA' },
    { id: 2108009, description: 'PASTOS BONS' },
    { id: 2108058, description: 'PAULINO NEVES' },
    { id: 2108108, description: 'PAULO RAMOS' },
    { id: 2108207, description: 'PEDREIRAS' },
    { id: 2108256, description: 'PEDRO DO ROSARIO' },
    { id: 2108306, description: 'PENALVA' },
    { id: 2108405, description: 'PERI MIRIM' },
    { id: 2108454, description: 'PERITORO' },
    { id: 2108504, description: 'PINDARE MIRIM' },
    { id: 2108603, description: 'PINHEIRO' },
    { id: 2108702, description: 'PIO XII' },
    { id: 2108801, description: 'PIRAPEMAS' },
    { id: 2108900, description: 'POCAO DE PEDRAS' },
    { id: 2109007, description: 'PORTO FRANCO' },
    { id: 2109056, description: 'PORTO RICO DO MARANHAO' },
    { id: 2109106, description: 'PRESIDENTE DUTRA' },
    { id: 2109205, description: 'PRESIDENTE JUSCELINO' },
    { id: 2109239, description: 'PRESIDENTE MEDICI' },
    { id: 2109270, description: 'PRESIDENTE SARNEY' },
    { id: 2109304, description: 'PRESIDENTE VARGAS' },
    { id: 2109403, description: 'PRIMEIRA CRUZ' },
    { id: 2109452, description: 'RAPOSA' },
    { id: 2109502, description: 'RIACHAO' },
    { id: 2109551, description: 'RIBAMAR FIQUENE' },
    { id: 2109601, description: 'ROSARIO' },
    { id: 2109700, description: 'SAMBAIBA' },
    { id: 2109759, description: 'SANTA FILOMENA DO MARANHAO' },
    { id: 2109809, description: 'SANTA HELENA' },
    { id: 2109908, description: 'SANTA INES' },
    { id: 2110005, description: 'SANTA LUZIA' },
    { id: 2110039, description: 'SANTA LUZIA DO PARUA' },
    { id: 2110104, description: 'SANTA QUITERIA DO MARANHAO' },
    { id: 2110203, description: 'SANTA RITA' },
    { id: 2110237, description: 'SANTANA DO MARANHAO' },
    { id: 2110278, description: 'SANTO AMARO' },
    { id: 2110302, description: 'SANTO ANTONIO DOS LOPES' },
    { id: 2110401, description: 'SAO BENEDITO DO RIO PRETO' },
    { id: 2110500, description: 'SAO BENTO' },
    { id: 2110609, description: 'SAO BERNARDO' },
    { id: 2110658, description: 'SAO DOMINGOS DO AZEITAO' },
    { id: 2110708, description: 'SAO DOMINGOS DO MARANHAO' },
    { id: 2110807, description: 'SAO FELIX DE BALSAS' },
    { id: 2110856, description: 'SAO FRANCISCO DO BREJAO' },
    { id: 2110906, description: 'SAO FRANCISCO DO MARANHAO' },
    { id: 2111003, description: 'SAO JOAO BATISTA' },
    { id: 2111029, description: 'SAO JOAO DO CARU' },
    { id: 2111052, description: 'SAO JOAO DO PARAISO' },
    { id: 2111078, description: 'SAO JOAO DO SOTER' },
    { id: 2111102, description: 'SAO JOAO DOS PATOS' },
    { id: 2111201, description: 'SAO JOSE DE RIBAMAR' },
    { id: 2111250, description: 'SAO JOSE DOS BASILIOS' },
    { id: 2111300, description: 'SAO LUIS' },
    { id: 2111409, description: 'SAO LUIS GONZAGA DO MARANHAO' },
    { id: 2111508, description: 'SAO MATEUS DO MARANHAO' },
    { id: 2111532, description: 'SAO PEDRO DA AGUA BRANCA' },
    { id: 2111573, description: 'SAO PEDRO DOS CRENTES' },
    { id: 2111607, description: 'SAO RAIMUNDO DAS MANGABEIRAS' },
    { id: 2111631, description: 'SAO RAIMUNDO DO DOCA BEZERRA' },
    { id: 2111672, description: 'SAO ROBERTO' },
    { id: 2111706, description: 'SAO VICENTE FERRER' },
    { id: 2111722, description: 'SATUBINHA' },
    { id: 2111748, description: 'SENADOR ALEXANDRE COSTA' },
    { id: 2111763, description: 'SENADOR LA ROQUE' },
    { id: 2111789, description: 'SERRANO DO MARANHAO' },
    { id: 2111805, description: 'SITIO NOVO' },
    { id: 2111904, description: 'SUCUPIRA DO NORTE' },
    { id: 2111953, description: 'SUCUPIRA DO RIACHAO' },
    { id: 2112001, description: 'TASSO FRAGOSO' },
    { id: 2112100, description: 'TIMBIRAS' },
    { id: 2112209, description: 'TIMON' },
    { id: 2112233, description: 'TRIZIDELA DO VALE' },
    { id: 2112274, description: 'TUFILANDIA' },
    { id: 2112308, description: 'TUNTUM' },
    { id: 2112407, description: 'TURIACU' },
    { id: 2112456, description: 'TURILANDIA' },
    { id: 2112506, description: 'TUTOIA' },
    { id: 2112605, description: 'URBANO SANTOS' },
    { id: 2112704, description: 'VARGEM GRANDE' },
    { id: 2112803, description: 'VIANA' },
    { id: 2112852, description: 'VILA NOVA DOS MARTIRIOS' },
    { id: 2112902, description: 'VITORIA DO MEARIM' },
    { id: 2113009, description: 'VITORINO FREIRE' },
    { id: 2114007, description: 'ZE DOCA' },
    { id: 2200053, description: 'ACAUA' },
    { id: 2200103, description: 'AGRICOLANDIA' },
    { id: 2200202, description: 'AGUA BRANCA' },
    { id: 2200251, description: 'ALAGOINHA DO PIAUI' },
    { id: 2200277, description: 'ALEGRETE DO PIAUI' },
    { id: 2200301, description: 'ALTO LONGA' },
    { id: 2200400, description: 'ALTOS' },
    { id: 2200459, description: 'ALVORADA DO GURGUEIA' },
    { id: 2200509, description: 'AMARANTE' },
    { id: 2200608, description: 'ANGICAL DO PIAUI' },
    { id: 2200707, description: 'ANISIO DE ABREU' },
    { id: 2200806, description: 'ANTONIO ALMEIDA' },
    { id: 2200905, description: 'AROAZES' },
    { id: 2200954, description: 'AROEIRAS DO ITAIM' },
    { id: 2201002, description: 'ARRAIAL' },
    { id: 2201051, description: 'ASSUNCAO DO PIAUI' },
    { id: 2201101, description: 'AVELINO LOPES' },
    { id: 2201150, description: 'BAIXA GRANDE DO RIBEIRO' },
    { id: 2201176, description: 'BARRA D ALCANTARA' },
    { id: 2201200, description: 'BARRAS' },
    { id: 2201309, description: 'BARREIRAS DO PIAUI' },
    { id: 2201408, description: 'BARRO DURO' },
    { id: 2201507, description: 'BATALHA' },
    { id: 2201556, description: 'BELA VISTA DO PIAUI' },
    { id: 2201572, description: 'BELEM DO PIAUI' },
    { id: 2201606, description: 'BENEDITINOS' },
    { id: 2201705, description: 'BERTOLINIA' },
    { id: 2201739, description: 'BETANIA DO PIAUI' },
    { id: 2201770, description: 'BOA HORA' },
    { id: 2201804, description: 'BOCAINA' },
    { id: 2201903, description: 'BOM JESUS' },
    { id: 2201919, description: 'BOM PRINCIPIO DO PIAUI' },
    { id: 2201929, description: 'BONFIM DO PIAUI' },
    { id: 2201945, description: 'BOQUEIRAO DO PIAUI' },
    { id: 2201960, description: 'BRASILEIRA' },
    { id: 2201988, description: 'BREJO DO PIAUI' },
    { id: 2202000, description: 'BURITI DOS LOPES' },
    { id: 2202026, description: 'BURITI DOS MONTES' },
    { id: 2202059, description: 'CABECEIRAS DO PIAUI' },
    { id: 2202075, description: 'CAJAZEIRAS DO PIAUI' },
    { id: 2202083, description: 'CAJUEIRO DA PRAIA' },
    { id: 2202091, description: 'CALDEIRAO GRANDE DO PIAUI' },
    { id: 2202109, description: 'CAMPINAS DO PIAUI' },
    { id: 2202117, description: 'CAMPO ALEGRE DO FIDALGO' },
    { id: 2202133, description: 'CAMPO GRANDE DO PIAUI' },
    { id: 2202174, description: 'CAMPO LARGO DO PIAUI' },
    { id: 2202208, description: 'CAMPO MAIOR' },
    { id: 2202251, description: 'CANAVIEIRA' },
    { id: 2202307, description: 'CANTO DO BURITI' },
    { id: 2202406, description: 'CAPITAO DE CAMPOS' },
    { id: 2202455, description: 'CAPITAO GERVASIO OLIVEIRA' },
    { id: 2202505, description: 'CARACOL' },
    { id: 2202539, description: 'CARAUBAS DO PIAUI' },
    { id: 2202554, description: 'CARIDADE DO PIAUI' },
    { id: 2202604, description: 'CASTELO DO PIAUI' },
    { id: 2202653, description: 'CAXINGO' },
    { id: 2202703, description: 'COCAL' },
    { id: 2202711, description: 'COCAL DE TELHA' },
    { id: 2202729, description: 'COCAL DOS ALVES' },
    { id: 2202737, description: 'COIVARAS' },
    { id: 2202752, description: 'COLONIA DO GURGUEIA' },
    { id: 2202778, description: 'COLONIA DO PIAUI' },
    { id: 2202802, description: 'CONCEICAO DO CANINDE' },
    { id: 2202851, description: 'CORONEL JOSE DIAS' },
    { id: 2202901, description: 'CORRENTE' },
    { id: 2203008, description: 'CRISTALANDIA DO PIAUI' },
    { id: 2203107, description: 'CRISTINO CASTRO' },
    { id: 2203206, description: 'CURIMATA' },
    { id: 2203230, description: 'CURRAIS' },
    { id: 2203255, description: 'CURRALINHOS' },
    { id: 2203271, description: 'CURRAL NOVO DO PIAUI' },
    { id: 2203305, description: 'DEMERVAL LOBAO' },
    { id: 2203354, description: 'DIRCEU ARCOVERDE' },
    { id: 2203404, description: 'DOM EXPEDITO LOPES' },
    { id: 2203420, description: 'DOMINGOS MOURAO' },
    { id: 2203453, description: 'DOM INOCENCIO' },
    { id: 2203503, description: 'ELESBAO VELOSO' },
    { id: 2203602, description: 'ELISEU MARTINS' },
    { id: 2203701, description: 'ESPERANTINA' },
    { id: 2203750, description: 'FARTURA DO PIAUI' },
    { id: 2203800, description: 'FLORES DO PIAUI' },
    { id: 2203859, description: 'FLORESTA DO PIAUI' },
    { id: 2203909, description: 'FLORIANO' },
    { id: 2204006, description: 'FRANCINOPOLIS' },
    { id: 2204105, description: 'FRANCISCO AYRES' },
    { id: 2204154, description: 'FRANCISCO MACEDO' },
    { id: 2204204, description: 'FRANCISCO SANTOS' },
    { id: 2204303, description: 'FRONTEIRAS' },
    { id: 2204352, description: 'GEMINIANO' },
    { id: 2204402, description: 'GILBUES' },
    { id: 2204501, description: 'GUADALUPE' },
    { id: 2204550, description: 'GUARIBAS' },
    { id: 2204600, description: 'HUGO NAPOLEAO' },
    { id: 2204659, description: 'ILHA GRANDE' },
    { id: 2204709, description: 'INHUMA' },
    { id: 2204808, description: 'IPIRANGA DO PIAUI' },
    { id: 2204907, description: 'ISAIAS COELHO' },
    { id: 2205003, description: 'ITAINOPOLIS' },
    { id: 2205102, description: 'ITAUEIRA' },
    { id: 2205151, description: 'JACOBINA DO PIAUI' },
    { id: 2205201, description: 'JAICOS' },
    { id: 2205250, description: 'JARDIM DO MULATO' },
    { id: 2205276, description: 'JATOBA DO PIAUI' },
    { id: 2205300, description: 'JERUMENHA' },
    { id: 2205359, description: 'JOAO COSTA' },
    { id: 2205409, description: 'JOAQUIM PIRES' },
    { id: 2205458, description: 'JOCA MARQUES' },
    { id: 2205508, description: 'JOSE DE FREITAS' },
    { id: 2205516, description: 'JUAZEIRO DO PIAUI' },
    { id: 2205524, description: 'JULIO BORGES' },
    { id: 2205532, description: 'JUREMA' },
    { id: 2205540, description: 'LAGOINHA DO PIAUI' },
    { id: 2205557, description: 'LAGOA ALEGRE' },
    { id: 2205565, description: 'LAGOA DO BARRO DO PIAUI' },
    { id: 2205573, description: 'LAGOA DE SAO FRANCISCO' },
    { id: 2205581, description: 'LAGOA DO PIAUI' },
    { id: 2205599, description: 'LAGOA DO SITIO' },
    { id: 2205607, description: 'LANDRI SALES' },
    { id: 2205706, description: 'LUIS CORREIA' },
    { id: 2205805, description: 'LUZILANDIA' },
    { id: 2205854, description: 'MADEIRO' },
    { id: 2205904, description: 'MANOEL EMIDIO' },
    { id: 2205953, description: 'MARCOLANDIA' },
    { id: 2206001, description: 'MARCOS PARENTE' },
    { id: 2206050, description: 'MASSAPE DO PIAUI' },
    { id: 2206100, description: 'MATIAS OLIMPIO' },
    { id: 2206209, description: 'MIGUEL ALVES' },
    { id: 2206308, description: 'MIGUEL LEAO' },
    { id: 2206357, description: 'MILTON BRANDAO' },
    { id: 2206407, description: 'MONSENHOR GIL' },
    { id: 2206506, description: 'MONSENHOR HIPOLITO' },
    { id: 2206605, description: 'MONTE ALEGRE DO PIAUI' },
    { id: 2206654, description: 'MORRO CABECA NO TEMPO' },
    { id: 2206670, description: 'MORRO DO CHAPEU DO PIAUI' },
    { id: 2206696, description: 'MURICI DOS PORTELAS' },
    { id: 2206704, description: 'NAZARE DO PIAUI' },
    { id: 2206720, description: 'NAZARIA' },
    { id: 2206753, description: 'NOSSA SENHORA DE NAZARE' },
    { id: 2206803, description: 'NOSSA SENHORA DOS REMEDIOS' },
    { id: 2206902, description: 'NOVO ORIENTE DO PIAUI' },
    { id: 2206951, description: 'NOVO SANTO ANTONIO' },
    { id: 2207009, description: 'OEIRAS' },
    { id: 2207108, description: 'OLHO D AGUA DO PIAUI' },
    { id: 2207207, description: 'PADRE MARCOS' },
    { id: 2207306, description: 'PAES LANDIM' },
    { id: 2207355, description: 'PAJEU DO PIAUI' },
    { id: 2207405, description: 'PALMEIRA DO PIAUI' },
    { id: 2207504, description: 'PALMEIRAIS' },
    { id: 2207553, description: 'PAQUETA' },
    { id: 2207603, description: 'PARNAGUA' },
    { id: 2207702, description: 'PARNAIBA' },
    { id: 2207751, description: 'PASSAGEM FRANCA DO PIAUI' },
    { id: 2207777, description: 'PATOS DO PIAUI' },
    { id: 2207793, description: 'PAU D ARCO DO PIAUI' },
    { id: 2207801, description: 'PAULISTANA' },
    { id: 2207850, description: 'PAVUSSU' },
    { id: 2207900, description: 'PEDRO II' },
    { id: 2207934, description: 'PEDRO LAURENTINO' },
    { id: 2207959, description: 'NOVA SANTA RITA' },
    { id: 2208007, description: 'PICOS' },
    { id: 2208106, description: 'PIMENTEIRAS' },
    { id: 2208205, description: 'PIO IX' },
    { id: 2208304, description: 'PIRACURUCA' },
    { id: 2208403, description: 'PIRIPIRI' },
    { id: 2208502, description: 'PORTO' },
    { id: 2208551, description: 'PORTO ALEGRE DO PIAUI' },
    { id: 2208601, description: 'PRATA DO PIAUI' },
    { id: 2208650, description: 'QUEIMADA NOVA' },
    { id: 2208700, description: 'REDENCAO DO GURGUEIA' },
    { id: 2208809, description: 'REGENERACAO' },
    { id: 2208858, description: 'RIACHO FRIO' },
    { id: 2208874, description: 'RIBEIRA DO PIAUI' },
    { id: 2208908, description: 'RIBEIRO GONCALVES' },
    { id: 2209005, description: 'RIO GRANDE DO PIAUI' },
    { id: 2209104, description: 'SANTA CRUZ DO PIAUI' },
    { id: 2209153, description: 'SANTA CRUZ DOS MILAGRES' },
    { id: 2209203, description: 'SANTA FILOMENA' },
    { id: 2209302, description: 'SANTA LUZ' },
    { id: 2209351, description: 'SANTANA DO PIAUI' },
    { id: 2209377, description: 'SANTA ROSA DO PIAUI' },
    { id: 2209401, description: 'SANTO ANTONIO DE LISBOA' },
    { id: 2209450, description: 'SANTO ANTONIO DOS MILAGRES' },
    { id: 2209500, description: 'SANTO INACIO DO PIAUI' },
    { id: 2209559, description: 'SAO BRAZ DO PIAUI' },
    { id: 2209609, description: 'SAO FELIX DO PIAUI' },
    { id: 2209658, description: 'SAO FRANCISCO DE ASSIS DO PIAUI' },
    { id: 2209708, description: 'SAO FRANCISCO DO PIAUI' },
    { id: 2209757, description: 'SAO GONCALO DO GURGUEIA' },
    { id: 2209807, description: 'SAO GONCALO DO PIAUI' },
    { id: 2209856, description: 'SAO JOAO DA CANABRAVA' },
    { id: 2209872, description: 'SAO JOAO DA FRONTEIRA' },
    { id: 2209906, description: 'SAO JOAO DA SERRA' },
    { id: 2209955, description: 'SAO JOAO DA VARJOTA' },
    { id: 2209971, description: 'SAO JOAO DO ARRAIAL' },
    { id: 2210003, description: 'SAO JOAO DO PIAUI' },
    { id: 2210052, description: 'SAO JOSE DO DIVINO' },
    { id: 2210102, description: 'SAO JOSE DO PEIXE' },
    { id: 2210201, description: 'SAO JOSE DO PIAUI' },
    { id: 2210300, description: 'SAO JULIAO' },
    { id: 2210359, description: 'SAO LOURENCO DO PIAUI' },
    { id: 2210375, description: 'SAO LUIS DO PIAUI' },
    { id: 2210383, description: 'SAO MIGUEL DA BAIXA GRANDE' },
    { id: 2210391, description: 'SAO MIGUEL DO FIDALGO' },
    { id: 2210409, description: 'SAO MIGUEL DO TAPUIO' },
    { id: 2210508, description: 'SAO PEDRO DO PIAUI' },
    { id: 2210607, description: 'SAO RAIMUNDO NONATO' },
    { id: 2210623, description: 'SEBASTIAO BARROS' },
    { id: 2210631, description: 'SEBASTIAO LEAL' },
    { id: 2210656, description: 'SIGEFREDO PACHECO' },
    { id: 2210706, description: 'SIMOES' },
    { id: 2210805, description: 'SIMPLICIO MENDES' },
    { id: 2210904, description: 'SOCORRO DO PIAUI' },
    { id: 2210938, description: 'SUSSUAPARA' },
    { id: 2210953, description: 'TAMBORIL DO PIAUI' },
    { id: 2210979, description: 'TANQUE DO PIAUI' },
    { id: 2211001, description: 'TERESINA' },
    { id: 2211100, description: 'UNIAO' },
    { id: 2211209, description: 'URUCUI' },
    { id: 2211308, description: 'VALENCA DO PIAUI' },
    { id: 2211357, description: 'VARZEA BRANCA' },
    { id: 2211407, description: 'VARZEA GRANDE' },
    { id: 2211506, description: 'VERA MENDES' },
    { id: 2211605, description: 'VILA NOVA DO PIAUI' },
    { id: 2211704, description: 'WALL FERRAZ' },
    { id: 2300101, description: 'ABAIARA' },
    { id: 2300150, description: 'ACARAPE' },
    { id: 2300200, description: 'ACARAU' },
    { id: 2300309, description: 'ACOPIARA' },
    { id: 2300408, description: 'AIUABA' },
    { id: 2300507, description: 'ALCANTARAS' },
    { id: 2300606, description: 'ALTANEIRA' },
    { id: 2300705, description: 'ALTO SANTO' },
    { id: 2300754, description: 'AMONTADA' },
    { id: 2300804, description: 'ANTONINA DO NORTE' },
    { id: 2300903, description: 'APUIARES' },
    { id: 2301000, description: 'AQUIRAZ' },
    { id: 2301109, description: 'ARACATI' },
    { id: 2301208, description: 'ARACOIABA' },
    { id: 2301257, description: 'ARARENDA' },
    { id: 2301307, description: 'ARARIPE' },
    { id: 2301406, description: 'ARATUBA' },
    { id: 2301505, description: 'ARNEIROZ' },
    { id: 2301604, description: 'ASSARE' },
    { id: 2301703, description: 'AURORA' },
    { id: 2301802, description: 'BAIXIO' },
    { id: 2301851, description: 'BANABUIU' },
    { id: 2301901, description: 'BARBALHA' },
    { id: 2301950, description: 'BARREIRA' },
    { id: 2302008, description: 'BARRO' },
    { id: 2302057, description: 'BARROQUINHA' },
    { id: 2302107, description: 'BATURITE' },
    { id: 2302206, description: 'BEBERIBE' },
    { id: 2302305, description: 'BELA CRUZ' },
    { id: 2302404, description: 'BOA VIAGEM' },
    { id: 2302503, description: 'BREJO SANTO' },
    { id: 2302602, description: 'CAMOCIM' },
    { id: 2302701, description: 'CAMPOS SALES' },
    { id: 2302800, description: 'CANINDE' },
    { id: 2302909, description: 'CAPISTRANO' },
    { id: 2303006, description: 'CARIDADE' },
    { id: 2303105, description: 'CARIRE' },
    { id: 2303204, description: 'CARIRIACU' },
    { id: 2303303, description: 'CARIUS' },
    { id: 2303402, description: 'CARNAUBAL' },
    { id: 2303501, description: 'CASCAVEL' },
    { id: 2303600, description: 'CATARINA' },
    { id: 2303659, description: 'CATUNDA' },
    { id: 2303709, description: 'CAUCAIA' },
    { id: 2303808, description: 'CEDRO' },
    { id: 2303907, description: 'CHAVAL' },
    { id: 2303931, description: 'CHORO' },
    { id: 2303956, description: 'CHOROZINHO' },
    { id: 2304004, description: 'COREAU' },
    { id: 2304103, description: 'CRATEUS' },
    { id: 2304202, description: 'CRATO' },
    { id: 2304236, description: 'CROATA' },
    { id: 2304251, description: 'CRUZ' },
    { id: 2304269, description: 'DEPUTADO IRAPUAN PINHEIRO' },
    { id: 2304277, description: 'ERERE' },
    { id: 2304285, description: 'EUSEBIO' },
    { id: 2304301, description: 'FARIAS BRITO' },
    { id: 2304350, description: 'FORQUILHA' },
    { id: 2304400, description: 'FORTALEZA' },
    { id: 2304459, description: 'FORTIM' },
    { id: 2304509, description: 'FRECHEIRINHA' },
    { id: 2304608, description: 'GENERAL SAMPAIO' },
    { id: 2304657, description: 'GRACA' },
    { id: 2304707, description: 'GRANJA' },
    { id: 2304806, description: 'GRANJEIRO' },
    { id: 2304905, description: 'GROAIRAS' },
    { id: 2304954, description: 'GUAIUBA' },
    { id: 2305001, description: 'GUARACIABA DO NORTE' },
    { id: 2305100, description: 'GUARAMIRANGA' },
    { id: 2305209, description: 'HIDROLANDIA' },
    { id: 2305233, description: 'HORIZONTE' },
    { id: 2305266, description: 'IBARETAMA' },
    { id: 2305308, description: 'IBIAPINA' },
    { id: 2305332, description: 'IBICUITINGA' },
    { id: 2305357, description: 'ICAPUI' },
    { id: 2305407, description: 'ICO' },
    { id: 2305506, description: 'IGUATU' },
    { id: 2305605, description: 'INDEPENDENCIA' },
    { id: 2305654, description: 'IPAPORANGA' },
    { id: 2305704, description: 'IPAUMIRIM' },
    { id: 2305803, description: 'IPU' },
    { id: 2305902, description: 'IPUEIRAS' },
    { id: 2306009, description: 'IRACEMA' },
    { id: 2306108, description: 'IRAUCUBA' },
    { id: 2306207, description: 'ITAICABA' },
    { id: 2306256, description: 'ITAITINGA' },
    { id: 2306306, description: 'ITAPAJE' },
    { id: 2306405, description: 'ITAPIPOCA' },
    { id: 2306504, description: 'ITAPIUNA' },
    { id: 2306553, description: 'ITAREMA' },
    { id: 2306603, description: 'ITATIRA' },
    { id: 2306702, description: 'JAGUARETAMA' },
    { id: 2306801, description: 'JAGUARIBARA' },
    { id: 2306900, description: 'JAGUARIBE' },
    { id: 2307007, description: 'JAGUARUANA' },
    { id: 2307106, description: 'JARDIM' },
    { id: 2307205, description: 'JATI' },
    { id: 2307254, description: 'JIJOCA DE JERICOACOARA' },
    { id: 2307304, description: 'JUAZEIRO DO NORTE' },
    { id: 2307403, description: 'JUCAS' },
    { id: 2307502, description: 'LAVRAS DA MANGABEIRA' },
    { id: 2307601, description: 'LIMOEIRO DO NORTE' },
    { id: 2307635, description: 'MADALENA' },
    { id: 2307650, description: 'MARACANAU' },
    { id: 2307700, description: 'MARANGUAPE' },
    { id: 2307809, description: 'MARCO' },
    { id: 2307908, description: 'MARTINOPOLE' },
    { id: 2308005, description: 'MASSAPE' },
    { id: 2308104, description: 'MAURITI' },
    { id: 2308203, description: 'MERUOCA' },
    { id: 2308302, description: 'MILAGRES' },
    { id: 2308351, description: 'MILHA' },
    { id: 2308377, description: 'MIRAIMA' },
    { id: 2308401, description: 'MISSAO VELHA' },
    { id: 2308500, description: 'MOMBACA' },
    { id: 2308609, description: 'MONSENHOR TABOSA' },
    { id: 2308708, description: 'MORADA NOVA' },
    { id: 2308807, description: 'MORAUJO' },
    { id: 2308906, description: 'MORRINHOS' },
    { id: 2309003, description: 'MUCAMBO' },
    { id: 2309102, description: 'MULUNGU' },
    { id: 2309201, description: 'NOVA OLINDA' },
    { id: 2309300, description: 'NOVA RUSSAS' },
    { id: 2309409, description: 'NOVO ORIENTE' },
    { id: 2309458, description: 'OCARA' },
    { id: 2309508, description: 'OROS' },
    { id: 2309607, description: 'PACAJUS' },
    { id: 2309706, description: 'PACATUBA' },
    { id: 2309805, description: 'PACOTI' },
    { id: 2309904, description: 'PACUJA' },
    { id: 2310001, description: 'PALHANO' },
    { id: 2310100, description: 'PALMACIA' },
    { id: 2310209, description: 'PARACURU' },
    { id: 2310258, description: 'PARAIPABA' },
    { id: 2310308, description: 'PARAMBU' },
    { id: 2310407, description: 'PARAMOTI' },
    { id: 2310506, description: 'PEDRA BRANCA' },
    { id: 2310605, description: 'PENAFORTE' },
    { id: 2310704, description: 'PENTECOSTE' },
    { id: 2310803, description: 'PEREIRO' },
    { id: 2310852, description: 'PINDORETAMA' },
    { id: 2310902, description: 'PIQUET CARNEIRO' },
    { id: 2310951, description: 'PIRES FERREIRA' },
    { id: 2311009, description: 'PORANGA' },
    { id: 2311108, description: 'PORTEIRAS' },
    { id: 2311207, description: 'POTENGI' },
    { id: 2311231, description: 'POTIRETAMA' },
    { id: 2311264, description: 'QUITERIANOPOLIS' },
    { id: 2311306, description: 'QUIXADA' },
    { id: 2311355, description: 'QUIXELO' },
    { id: 2311405, description: 'QUIXERAMOBIM' },
    { id: 2311504, description: 'QUIXERE' },
    { id: 2311603, description: 'REDENCAO' },
    { id: 2311702, description: 'RERIUTABA' },
    { id: 2311801, description: 'RUSSAS' },
    { id: 2311900, description: 'SABOEIRO' },
    { id: 2311959, description: 'SALITRE' },
    { id: 2312007, description: 'SANTANA DO ACARAU' },
    { id: 2312106, description: 'SANTANA DO CARIRI' },
    { id: 2312205, description: 'SANTA QUITERIA' },
    { id: 2312304, description: 'SAO BENEDITO' },
    { id: 2312403, description: 'SAO GONCALO DO AMARANTE' },
    { id: 2312502, description: 'SAO JOAO DO JAGUARIBE' },
    { id: 2312601, description: 'SAO LUIS DO CURU' },
    { id: 2312700, description: 'SENADOR POMPEU' },
    { id: 2312809, description: 'SENADOR SA' },
    { id: 2312908, description: 'SOBRAL' },
    { id: 2313005, description: 'SOLONOPOLE' },
    { id: 2313104, description: 'TABULEIRO DO NORTE' },
    { id: 2313203, description: 'TAMBORIL' },
    { id: 2313252, description: 'TARRAFAS' },
    { id: 2313302, description: 'TAUA' },
    { id: 2313351, description: 'TEJUCUOCA' },
    { id: 2313401, description: 'TIANGUA' },
    { id: 2313500, description: 'TRAIRI' },
    { id: 2313559, description: 'TURURU' },
    { id: 2313609, description: 'UBAJARA' },
    { id: 2313708, description: 'UMARI' },
    { id: 2313757, description: 'UMIRIM' },
    { id: 2313807, description: 'URUBURETAMA' },
    { id: 2313906, description: 'URUOCA' },
    { id: 2313955, description: 'VARJOTA' },
    { id: 2314003, description: 'VARZEA ALEGRE' },
    { id: 2314102, description: 'VICOSA DO CEARA' },
    { id: 2400109, description: 'ACARI' },
    { id: 2400208, description: 'ACU' },
    { id: 2400307, description: 'AFONSO BEZERRA' },
    { id: 2400406, description: 'AGUA NOVA' },
    { id: 2400505, description: 'ALEXANDRIA' },
    { id: 2400604, description: 'ALMINO AFONSO' },
    { id: 2400703, description: 'ALTO DO RODRIGUES' },
    { id: 2400802, description: 'ANGICOS' },
    { id: 2400901, description: 'ANTONIO MARTINS' },
    { id: 2401008, description: 'APODI' },
    { id: 2401107, description: 'AREIA BRANCA' },
    { id: 2401206, description: 'AREZ' },
    { id: 2401305, description: 'CAMPO GRANDE' },
    { id: 2401404, description: 'BAIA FORMOSA' },
    { id: 2401453, description: 'BARAUNA' },
    { id: 2401503, description: 'BARCELONA' },
    { id: 2401602, description: 'BENTO FERNANDES' },
    { id: 2401651, description: 'BODO' },
    { id: 2401701, description: 'BOM JESUS' },
    { id: 2401800, description: 'BREJINHO' },
    { id: 2401859, description: 'CAICARA DO NORTE' },
    { id: 2401909, description: 'CAICARA DO RIO DO VENTO' },
    { id: 2402006, description: 'CAICO' },
    { id: 2402105, description: 'CAMPO REDONDO' },
    { id: 2402204, description: 'CANGUARETAMA' },
    { id: 2402303, description: 'CARAUBAS' },
    { id: 2402402, description: 'CARNAUBA DOS DANTAS' },
    { id: 2402501, description: 'CARNAUBAIS' },
    { id: 2402600, description: 'CEARA MIRIM' },
    { id: 2402709, description: 'CERRO CORA' },
    { id: 2402808, description: 'CORONEL EZEQUIEL' },
    { id: 2402907, description: 'CORONEL JOAO PESSOA' },
    { id: 2403004, description: 'CRUZETA' },
    { id: 2403103, description: 'CURRAIS NOVOS' },
    { id: 2403202, description: 'DOUTOR SEVERIANO' },
    { id: 2403251, description: 'PARNAMIRIM' },
    { id: 2403301, description: 'ENCANTO' },
    { id: 2403400, description: 'EQUADOR' },
    { id: 2403509, description: 'ESPIRITO SANTO' },
    { id: 2403608, description: 'EXTREMOZ' },
    { id: 2403707, description: 'FELIPE GUERRA' },
    { id: 2403756, description: 'FERNANDO PEDROZA' },
    { id: 2403806, description: 'FLORANIA' },
    { id: 2403905, description: 'FRANCISCO DANTAS' },
    { id: 2404002, description: 'FRUTUOSO GOMES' },
    { id: 2404101, description: 'GALINHOS' },
    { id: 2404200, description: 'GOIANINHA' },
    { id: 2404309, description: 'GOVERNADOR DIX SEPT ROSADO' },
    { id: 2404408, description: 'GROSSOS' },
    { id: 2404507, description: 'GUAMARE' },
    { id: 2404606, description: 'IELMO MARINHO' },
    { id: 2404705, description: 'IPANGUACU' },
    { id: 2404804, description: 'IPUEIRA' },
    { id: 2404853, description: 'ITAJA' },
    { id: 2404903, description: 'ITAU' },
    { id: 2405009, description: 'JACANA' },
    { id: 2405108, description: 'JANDAIRA' },
    { id: 2405207, description: 'JANDUIS' },
    { id: 2405306, description: 'BOA SAUDE' },
    { id: 2405405, description: 'JAPI' },
    { id: 2405504, description: 'JARDIM DE ANGICOS' },
    { id: 2405603, description: 'JARDIM DE PIRANHAS' },
    { id: 2405702, description: 'JARDIM DO SERIDO' },
    { id: 2405801, description: 'JOAO CAMARA' },
    { id: 2405900, description: 'JOAO DIAS' },
    { id: 2406007, description: 'JOSE DA PENHA' },
    { id: 2406106, description: 'JUCURUTU' },
    { id: 2406155, description: 'JUNDIA' },
    { id: 2406205, description: 'LAGOA D ANTA' },
    { id: 2406304, description: 'LAGOA DE PEDRAS' },
    { id: 2406403, description: 'LAGOA DE VELHOS' },
    { id: 2406502, description: 'LAGOA NOVA' },
    { id: 2406601, description: 'LAGOA SALGADA' },
    { id: 2406700, description: 'LAJES' },
    { id: 2406809, description: 'LAJES PINTADAS' },
    { id: 2406908, description: 'LUCRECIA' },
    { id: 2407005, description: 'LUIS GOMES' },
    { id: 2407104, description: 'MACAIBA' },
    { id: 2407203, description: 'MACAU' },
    { id: 2407252, description: 'MAJOR SALES' },
    { id: 2407302, description: 'MARCELINO VIEIRA' },
    { id: 2407401, description: 'MARTINS' },
    { id: 2407500, description: 'MAXARANGUAPE' },
    { id: 2407609, description: 'MESSIAS TARGINO' },
    { id: 2407708, description: 'MONTANHAS' },
    { id: 2407807, description: 'MONTE ALEGRE' },
    { id: 2407906, description: 'MONTE DAS GAMELEIRAS' },
    { id: 2408003, description: 'MOSSORO' },
    { id: 2408102, description: 'NATAL' },
    { id: 2408201, description: 'NISIA FLORESTA' },
    { id: 2408300, description: 'NOVA CRUZ' },
    { id: 2408409, description: 'OLHO D AGUA DO BORGES' },
    { id: 2408508, description: 'OURO BRANCO' },
    { id: 2408607, description: 'PARANA' },
    { id: 2408706, description: 'PARAU' },
    { id: 2408805, description: 'PARAZINHO' },
    { id: 2408904, description: 'PARELHAS' },
    { id: 2408953, description: 'RIO DO FOGO' },
    { id: 2409100, description: 'PASSA E FICA' },
    { id: 2409209, description: 'PASSAGEM' },
    { id: 2409308, description: 'PATU' },
    { id: 2409332, description: 'SANTA MARIA' },
    { id: 2409407, description: 'PAU DOS FERROS' },
    { id: 2409506, description: 'PEDRA GRANDE' },
    { id: 2409605, description: 'PEDRA PRETA' },
    { id: 2409704, description: 'PEDRO AVELINO' },
    { id: 2409803, description: 'PEDRO VELHO' },
    { id: 2409902, description: 'PENDENCIAS' },
    { id: 2410009, description: 'PILOES' },
    { id: 2410108, description: 'POCO BRANCO' },
    { id: 2410207, description: 'PORTALEGRE' },
    { id: 2410256, description: 'PORTO DO MANGUE' },
    { id: 2410306, description: 'SERRA CAIADA' },
    { id: 2410405, description: 'PUREZA' },
    { id: 2410504, description: 'RAFAEL FERNANDES' },
    { id: 2410603, description: 'RAFAEL GODEIRO' },
    { id: 2410702, description: 'RIACHO DA CRUZ' },
    { id: 2410801, description: 'RIACHO DE SANTANA' },
    { id: 2410900, description: 'RIACHUELO' },
    { id: 2411007, description: 'RODOLFO FERNANDES' },
    { id: 2411056, description: 'TIBAU' },
    { id: 2411106, description: 'RUY BARBOSA' },
    { id: 2411205, description: 'SANTA CRUZ' },
    { id: 2411403, description: 'SANTANA DO MATOS' },
    { id: 2411429, description: 'SANTANA DO SERIDO' },
    { id: 2411502, description: 'SANTO ANTONIO' },
    { id: 2411601, description: 'SAO BENTO DO NORTE' },
    { id: 2411700, description: 'SAO BENTO DO TRAIRI' },
    { id: 2411809, description: 'SAO FERNANDO' },
    { id: 2411908, description: 'SAO FRANCISCO DO OESTE' },
    { id: 2412005, description: 'SAO GONCALO DO AMARANTE' },
    { id: 2412104, description: 'SAO JOAO DO SABUGI' },
    { id: 2412203, description: 'SAO JOSE DE MIPIBU' },
    { id: 2412302, description: 'SAO JOSE DO CAMPESTRE' },
    { id: 2412401, description: 'SAO JOSE DO SERIDO' },
    { id: 2412500, description: 'SAO MIGUEL' },
    { id: 2412559, description: 'SAO MIGUEL DO GOSTOSO' },
    { id: 2412609, description: 'SAO PAULO DO POTENGI' },
    { id: 2412708, description: 'SAO PEDRO' },
    { id: 2412807, description: 'SAO RAFAEL' },
    { id: 2412906, description: 'SAO TOME' },
    { id: 2413003, description: 'SAO VICENTE' },
    { id: 2413102, description: 'SENADOR ELOI DE SOUZA' },
    { id: 2413201, description: 'SENADOR GEORGINO AVELINO' },
    { id: 2413300, description: 'SERRA DE SAO BENTO' },
    { id: 2413359, description: 'SERRA DO MEL' },
    { id: 2413409, description: 'SERRA NEGRA DO NORTE' },
    { id: 2413508, description: 'SERRINHA' },
    { id: 2413557, description: 'SERRINHA DOS PINTOS' },
    { id: 2413607, description: 'SEVERIANO MELO' },
    { id: 2413706, description: 'SITIO NOVO' },
    { id: 2413805, description: 'TABOLEIRO GRANDE' },
    { id: 2413904, description: 'TAIPU' },
    { id: 2414001, description: 'TANGARA' },
    { id: 2414100, description: 'TENENTE ANANIAS' },
    { id: 2414159, description: 'TENENTE LAURENTINO CRUZ' },
    { id: 2414209, description: 'TIBAU DO SUL' },
    { id: 2414308, description: 'TIMBAUBA DOS BATISTAS' },
    { id: 2414407, description: 'TOUROS' },
    { id: 2414456, description: 'TRIUNFO POTIGUAR' },
    { id: 2414506, description: 'UMARIZAL' },
    { id: 2414605, description: 'UPANEMA' },
    { id: 2414704, description: 'VARZEA' },
    { id: 2414753, description: 'VENHA VER' },
    { id: 2414803, description: 'VERA CRUZ' },
    { id: 2414902, description: 'VICOSA' },
    { id: 2415008, description: 'VILA FLOR' },
    { id: 2500106, description: 'AGUA BRANCA' },
    { id: 2500205, description: 'AGUIAR' },
    { id: 2500304, description: 'ALAGOA GRANDE' },
    { id: 2500403, description: 'ALAGOA NOVA' },
    { id: 2500502, description: 'ALAGOINHA' },
    { id: 2500536, description: 'ALCANTIL' },
    { id: 2500577, description: 'ALGODAO DE JANDAIRA' },
    { id: 2500601, description: 'ALHANDRA' },
    { id: 2500700, description: 'SAO JOAO DO RIO DO PEIXE' },
    { id: 2500734, description: 'AMPARO' },
    { id: 2500775, description: 'APARECIDA' },
    { id: 2500809, description: 'ARACAGI' },
    { id: 2500908, description: 'ARARA' },
    { id: 2501005, description: 'ARARUNA' },
    { id: 2501104, description: 'AREIA' },
    { id: 2501153, description: 'AREIA DE BARAUNAS' },
    { id: 2501203, description: 'AREIAL' },
    { id: 2501302, description: 'AROEIRAS' },
    { id: 2501351, description: 'ASSUNCAO' },
    { id: 2501401, description: 'BAIA DA TRAICAO' },
    { id: 2501500, description: 'BANANEIRAS' },
    { id: 2501534, description: 'BARAUNA' },
    { id: 2501575, description: 'BARRA DE SANTANA' },
    { id: 2501609, description: 'BARRA DE SANTA ROSA' },
    { id: 2501708, description: 'BARRA DE SAO MIGUEL' },
    { id: 2501807, description: 'BAYEUX' },
    { id: 2501906, description: 'BELEM' },
    { id: 2502003, description: 'BELEM DO BREJO DO CRUZ' },
    { id: 2502052, description: 'BERNARDINO BATISTA' },
    { id: 2502102, description: 'BOA VENTURA' },
    { id: 2502151, description: 'BOA VISTA' },
    { id: 2502201, description: 'BOM JESUS' },
    { id: 2502300, description: 'BOM SUCESSO' },
    { id: 2502409, description: 'BONITO DE SANTA FE' },
    { id: 2502508, description: 'BOQUEIRAO' },
    { id: 2502607, description: 'IGARACY' },
    { id: 2502706, description: 'BORBOREMA' },
    { id: 2502805, description: 'BREJO DO CRUZ' },
    { id: 2502904, description: 'BREJO DOS SANTOS' },
    { id: 2503001, description: 'CAAPORA' },
    { id: 2503100, description: 'CABACEIRAS' },
    { id: 2503209, description: 'CABEDELO' },
    { id: 2503308, description: 'CACHOEIRA DOS INDIOS' },
    { id: 2503407, description: 'CACIMBA DE AREIA' },
    { id: 2503506, description: 'CACIMBA DE DENTRO' },
    { id: 2503555, description: 'CACIMBAS' },
    { id: 2503605, description: 'CAICARA' },
    { id: 2503704, description: 'CAJAZEIRAS' },
    { id: 2503753, description: 'CAJAZEIRINHAS' },
    { id: 2503803, description: 'CALDAS BRANDAO' },
    { id: 2503902, description: 'CAMALAU' },
    { id: 2504009, description: 'CAMPINA GRANDE' },
    { id: 2504033, description: 'CAPIM' },
    { id: 2504074, description: 'CARAUBAS' },
    { id: 2504108, description: 'CARRAPATEIRA' },
    { id: 2504157, description: 'CASSERENGUE' },
    { id: 2504207, description: 'CATINGUEIRA' },
    { id: 2504306, description: 'CATOLE DO ROCHA' },
    { id: 2504355, description: 'CATURITE' },
    { id: 2504405, description: 'CONCEICAO' },
    { id: 2504504, description: 'CONDADO' },
    { id: 2504603, description: 'CONDE' },
    { id: 2504702, description: 'CONGO' },
    { id: 2504801, description: 'COREMAS' },
    { id: 2504850, description: 'COXIXOLA' },
    { id: 2504900, description: 'CRUZ DO ESPIRITO SANTO' },
    { id: 2505006, description: 'CUBATI' },
    { id: 2505105, description: 'CUITE' },
    { id: 2505204, description: 'CUITEGI' },
    { id: 2505238, description: 'CUITE DE MAMANGUAPE' },
    { id: 2505279, description: 'CURRAL DE CIMA' },
    { id: 2505303, description: 'CURRAL VELHO' },
    { id: 2505352, description: 'DAMIAO' },
    { id: 2505402, description: 'DESTERRO' },
    { id: 2505501, description: 'VISTA SERRANA' },
    { id: 2505600, description: 'DIAMANTE' },
    { id: 2505709, description: 'DONA INES' },
    { id: 2505808, description: 'DUAS ESTRADAS' },
    { id: 2505907, description: 'EMAS' },
    { id: 2506004, description: 'ESPERANCA' },
    { id: 2506103, description: 'FAGUNDES' },
    { id: 2506202, description: 'FREI MARTINHO' },
    { id: 2506251, description: 'GADO BRAVO' },
    { id: 2506301, description: 'GUARABIRA' },
    { id: 2506400, description: 'GURINHEM' },
    { id: 2506509, description: 'GURJAO' },
    { id: 2506608, description: 'IBIARA' },
    { id: 2506707, description: 'IMACULADA' },
    { id: 2506806, description: 'INGA' },
    { id: 2506905, description: 'ITABAIANA' },
    { id: 2507002, description: 'ITAPORANGA' },
    { id: 2507101, description: 'ITAPOROROCA' },
    { id: 2507200, description: 'ITATUBA' },
    { id: 2507309, description: 'JACARAU' },
    { id: 2507408, description: 'JERICO' },
    { id: 2507507, description: 'JOAO PESSOA' },
    { id: 2507606, description: 'JUAREZ TAVORA' },
    { id: 2507705, description: 'JUAZEIRINHO' },
    { id: 2507804, description: 'JUNCO DO SERIDO' },
    { id: 2507903, description: 'JURIPIRANGA' },
    { id: 2508000, description: 'JURU' },
    { id: 2508109, description: 'LAGOA' },
    { id: 2508208, description: 'LAGOA DE DENTRO' },
    { id: 2508307, description: 'LAGOA SECA' },
    { id: 2508406, description: 'LASTRO' },
    { id: 2508505, description: 'LIVRAMENTO' },
    { id: 2508554, description: 'LOGRADOURO' },
    { id: 2508604, description: 'LUCENA' },
    { id: 2508703, description: 'MAE D AGUA' },
    { id: 2508802, description: 'MALTA' },
    { id: 2508901, description: 'MAMANGUAPE' },
    { id: 2509008, description: 'MANAIRA' },
    { id: 2509057, description: 'MARCACAO' },
    { id: 2509107, description: 'MARI' },
    { id: 2509156, description: 'MARIZOPOLIS' },
    { id: 2509206, description: 'MASSARANDUBA' },
    { id: 2509305, description: 'MATARACA' },
    { id: 2509339, description: 'MATINHAS' },
    { id: 2509370, description: 'MATO GROSSO' },
    { id: 2509396, description: 'MATUREIA' },
    { id: 2509404, description: 'MOGEIRO' },
    { id: 2509503, description: 'MONTADAS' },
    { id: 2509602, description: 'MONTE HOREBE' },
    { id: 2509701, description: 'MONTEIRO' },
    { id: 2509800, description: 'MULUNGU' },
    { id: 2509909, description: 'NATUBA' },
    { id: 2510006, description: 'NAZAREZINHO' },
    { id: 2510105, description: 'NOVA FLORESTA' },
    { id: 2510204, description: 'NOVA OLINDA' },
    { id: 2510303, description: 'NOVA PALMEIRA' },
    { id: 2510402, description: 'OLHO D AGUA' },
    { id: 2510501, description: 'OLIVEDOS' },
    { id: 2510600, description: 'OURO VELHO' },
    { id: 2510659, description: 'PARARI' },
    { id: 2510709, description: 'PASSAGEM' },
    { id: 2510808, description: 'PATOS' },
    { id: 2510907, description: 'PAULISTA' },
    { id: 2511004, description: 'PEDRA BRANCA' },
    { id: 2511103, description: 'PEDRA LAVRADA' },
    { id: 2511202, description: 'PEDRAS DE FOGO' },
    { id: 2511301, description: 'PIANCO' },
    { id: 2511400, description: 'PICUI' },
    { id: 2511509, description: 'PILAR' },
    { id: 2511608, description: 'PILOES' },
    { id: 2511707, description: 'PILOEZINHOS' },
    { id: 2511806, description: 'PIRPIRITUBA' },
    { id: 2511905, description: 'PITIMBU' },
    { id: 2512002, description: 'POCINHOS' },
    { id: 2512036, description: 'POCO DANTAS' },
    { id: 2512077, description: 'POCO DE JOSE DE MOURA' },
    { id: 2512101, description: 'POMBAL' },
    { id: 2512200, description: 'PRATA' },
    { id: 2512309, description: 'PRINCESA ISABEL' },
    { id: 2512408, description: 'PUXINANA' },
    { id: 2512507, description: 'QUEIMADAS' },
    { id: 2512606, description: 'QUIXABA' },
    { id: 2512705, description: 'REMIGIO' },
    { id: 2512721, description: 'PEDRO REGIS' },
    { id: 2512747, description: 'RIACHAO' },
    { id: 2512754, description: 'RIACHAO DO BACAMARTE' },
    { id: 2512762, description: 'RIACHAO DO POCO' },
    { id: 2512788, description: 'RIACHO DE SANTO ANTONIO' },
    { id: 2512804, description: 'RIACHO DOS CAVALOS' },
    { id: 2512903, description: 'RIO TINTO' },
    { id: 2513000, description: 'SALGADINHO' },
    { id: 2513109, description: 'SALGADO DE SAO FELIX' },
    { id: 2513158, description: 'SANTA CECILIA' },
    { id: 2513208, description: 'SANTA CRUZ' },
    { id: 2513307, description: 'SANTA HELENA' },
    { id: 2513356, description: 'SANTA INES' },
    { id: 2513406, description: 'SANTA LUZIA' },
    { id: 2513505, description: 'SANTANA DE MANGUEIRA' },
    { id: 2513604, description: 'SANTANA DOS GARROTES' },
    { id: 2513653, description: 'JOCA CLAUDINO' },
    { id: 2513703, description: 'SANTA RITA' },
    { id: 2513802, description: 'SANTA TERESINHA' },
    { id: 2513851, description: 'SANTO ANDRE' },
    { id: 2513901, description: 'SAO BENTO' },
    { id: 2513927, description: 'SAO BENTINHO' },
    { id: 2513943, description: 'SAO DOMINGOS DO CARIRI' },
    { id: 2513968, description: 'SAO DOMINGOS' },
    { id: 2513984, description: 'SAO FRANCISCO' },
    { id: 2514008, description: 'SAO JOAO DO CARIRI' },
    { id: 2514107, description: 'SAO JOAO DO TIGRE' },
    { id: 2514206, description: 'SAO JOSE DA LAGOA TAPADA' },
    { id: 2514305, description: 'SAO JOSE DE CAIANA' },
    { id: 2514404, description: 'SAO JOSE DE ESPINHARAS' },
    { id: 2514453, description: 'SAO JOSE DOS RAMOS' },
    { id: 2514503, description: 'SAO JOSE DE PIRANHAS' },
    { id: 2514552, description: 'SAO JOSE DE PRINCESA' },
    { id: 2514602, description: 'SAO JOSE DO BONFIM' },
    { id: 2514651, description: 'SAO JOSE DO BREJO DO CRUZ' },
    { id: 2514701, description: 'SAO JOSE DO SABUGI' },
    { id: 2514800, description: 'SAO JOSE DOS CORDEIROS' },
    { id: 2514909, description: 'SAO MAMEDE' },
    { id: 2515005, description: 'SAO MIGUEL DE TAIPU' },
    { id: 2515104, description: 'SAO SEBASTIAO DE LAGOA DE ROCA' },
    { id: 2515203, description: 'SAO SEBASTIAO DO UMBUZEIRO' },
    { id: 2515302, description: 'SAPE' },
    { id: 2515401, description: 'SAO VICENTE DO SERIDO' },
    { id: 2515500, description: 'SERRA BRANCA' },
    { id: 2515609, description: 'SERRA DA RAIZ' },
    { id: 2515708, description: 'SERRA GRANDE' },
    { id: 2515807, description: 'SERRA REDONDA' },
    { id: 2515906, description: 'SERRARIA' },
    { id: 2515930, description: 'SERTAOZINHO' },
    { id: 2515971, description: 'SOBRADO' },
    { id: 2516003, description: 'SOLANEA' },
    { id: 2516102, description: 'SOLEDADE' },
    { id: 2516151, description: 'SOSSEGO' },
    { id: 2516201, description: 'SOUSA' },
    { id: 2516300, description: 'SUME' },
    { id: 2516409, description: 'TACIMA' },
    { id: 2516508, description: 'TAPEROA' },
    { id: 2516607, description: 'TAVARES' },
    { id: 2516706, description: 'TEIXEIRA' },
    { id: 2516755, description: 'TENORIO' },
    { id: 2516805, description: 'TRIUNFO' },
    { id: 2516904, description: 'UIRAUNA' },
    { id: 2517001, description: 'UMBUZEIRO' },
    { id: 2517100, description: 'VARZEA' },
    { id: 2517209, description: 'VIEIROPOLIS' },
    { id: 2517407, description: 'ZABELE' },
    { id: 2600054, description: 'ABREU E LIMA' },
    { id: 2600104, description: 'AFOGADOS DA INGAZEIRA' },
    { id: 2600203, description: 'AFRANIO' },
    { id: 2600302, description: 'AGRESTINA' },
    { id: 2600401, description: 'AGUA PRETA' },
    { id: 2600500, description: 'AGUAS BELAS' },
    { id: 2600609, description: 'ALAGOINHA' },
    { id: 2600708, description: 'ALIANCA' },
    { id: 2600807, description: 'ALTINHO' },
    { id: 2600906, description: 'AMARAJI' },
    { id: 2601003, description: 'ANGELIM' },
    { id: 2601052, description: 'ARACOIABA' },
    { id: 2601102, description: 'ARARIPINA' },
    { id: 2601201, description: 'ARCOVERDE' },
    { id: 2601300, description: 'BARRA DE GUABIRABA' },
    { id: 2601409, description: 'BARREIROS' },
    { id: 2601508, description: 'BELEM DE MARIA' },
    { id: 2601607, description: 'BELEM DO SAO FRANCISCO' },
    { id: 2601706, description: 'BELO JARDIM' },
    { id: 2601805, description: 'BETANIA' },
    { id: 2601904, description: 'BEZERROS' },
    { id: 2602001, description: 'BODOCO' },
    { id: 2602100, description: 'BOM CONSELHO' },
    { id: 2602209, description: 'BOM JARDIM' },
    { id: 2602308, description: 'BONITO' },
    { id: 2602407, description: 'BREJAO' },
    { id: 2602506, description: 'BREJINHO' },
    { id: 2602605, description: 'BREJO DA MADRE DE DEUS' },
    { id: 2602704, description: 'BUENOS AIRES' },
    { id: 2602803, description: 'BUIQUE' },
    { id: 2602902, description: 'CABO DE SANTO AGOSTINHO' },
    { id: 2603009, description: 'CABROBO' },
    { id: 2603108, description: 'CACHOEIRINHA' },
    { id: 2603207, description: 'CAETES' },
    { id: 2603306, description: 'CALCADO' },
    { id: 2603405, description: 'CALUMBI' },
    { id: 2603454, description: 'CAMARAGIBE' },
    { id: 2603504, description: 'CAMOCIM DE SAO FELIX' },
    { id: 2603603, description: 'CAMUTANGA' },
    { id: 2603702, description: 'CANHOTINHO' },
    { id: 2603801, description: 'CAPOEIRAS' },
    { id: 2603900, description: 'CARNAIBA' },
    { id: 2603926, description: 'CARNAUBEIRA DA PENHA' },
    { id: 2604007, description: 'CARPINA' },
    { id: 2604106, description: 'CARUARU' },
    { id: 2604155, description: 'CASINHAS' },
    { id: 2604205, description: 'CATENDE' },
    { id: 2604304, description: 'CEDRO' },
    { id: 2604403, description: 'CHA DE ALEGRIA' },
    { id: 2604502, description: 'CHA GRANDE' },
    { id: 2604601, description: 'CONDADO' },
    { id: 2604700, description: 'CORRENTES' },
    { id: 2604809, description: 'CORTES' },
    { id: 2604908, description: 'CUMARU' },
    { id: 2605004, description: 'CUPIRA' },
    { id: 2605103, description: 'CUSTODIA' },
    { id: 2605152, description: 'DORMENTES' },
    { id: 2605202, description: 'ESCADA' },
    { id: 2605301, description: 'EXU' },
    { id: 2605400, description: 'FEIRA NOVA' },
    { id: 2605459, description: 'FERNANDO DE NORONHA' },
    { id: 2605509, description: 'FERREIROS' },
    { id: 2605608, description: 'FLORES' },
    { id: 2605707, description: 'FLORESTA' },
    { id: 2605806, description: 'FREI MIGUELINHO' },
    { id: 2605905, description: 'GAMELEIRA' },
    { id: 2606002, description: 'GARANHUNS' },
    { id: 2606101, description: 'GLORIA DO GOITA' },
    { id: 2606200, description: 'GOIANA' },
    { id: 2606309, description: 'GRANITO' },
    { id: 2606408, description: 'GRAVATA' },
    { id: 2606507, description: 'IATI' },
    { id: 2606606, description: 'IBIMIRIM' },
    { id: 2606705, description: 'IBIRAJUBA' },
    { id: 2606804, description: 'IGARASSU' },
    { id: 2606903, description: 'IGUARACI' },
    { id: 2607000, description: 'INAJA' },
    { id: 2607109, description: 'INGAZEIRA' },
    { id: 2607208, description: 'IPOJUCA' },
    { id: 2607307, description: 'IPUBI' },
    { id: 2607406, description: 'ITACURUBA' },
    { id: 2607505, description: 'ITAIBA' },
    { id: 2607604, description: 'ILHA DE ITAMARACA' },
    { id: 2607653, description: 'ITAMBE' },
    { id: 2607703, description: 'ITAPETIM' },
    { id: 2607752, description: 'ITAPISSUMA' },
    { id: 2607802, description: 'ITAQUITINGA' },
    { id: 2607901, description: 'JABOATAO DOS GUARARAPES' },
    { id: 2607950, description: 'JAQUEIRA' },
    { id: 2608008, description: 'JATAUBA' },
    { id: 2608057, description: 'JATOBA' },
    { id: 2608107, description: 'JOAO ALFREDO' },
    { id: 2608206, description: 'JOAQUIM NABUCO' },
    { id: 2608255, description: 'JUCATI' },
    { id: 2608305, description: 'JUPI' },
    { id: 2608404, description: 'JUREMA' },
    { id: 2608453, description: 'LAGOA DO CARRO' },
    { id: 2608503, description: 'LAGOA DO ITAENGA' },
    { id: 2608602, description: 'LAGOA DO OURO' },
    { id: 2608701, description: 'LAGOA DOS GATOS' },
    { id: 2608750, description: 'LAGOA GRANDE' },
    { id: 2608800, description: 'LAJEDO' },
    { id: 2608909, description: 'LIMOEIRO' },
    { id: 2609006, description: 'MACAPARANA' },
    { id: 2609105, description: 'MACHADOS' },
    { id: 2609154, description: 'MANARI' },
    { id: 2609204, description: 'MARAIAL' },
    { id: 2609303, description: 'MIRANDIBA' },
    { id: 2609402, description: 'MORENO' },
    { id: 2609501, description: 'NAZARE DA MATA' },
    { id: 2609600, description: 'OLINDA' },
    { id: 2609709, description: 'OROBO' },
    { id: 2609808, description: 'OROCO' },
    { id: 2609907, description: 'OURICURI' },
    { id: 2610004, description: 'PALMARES' },
    { id: 2610103, description: 'PALMEIRINA' },
    { id: 2610202, description: 'PANELAS' },
    { id: 2610301, description: 'PARANATAMA' },
    { id: 2610400, description: 'PARNAMIRIM' },
    { id: 2610509, description: 'PASSIRA' },
    { id: 2610608, description: 'PAUDALHO' },
    { id: 2610707, description: 'PAULISTA' },
    { id: 2610806, description: 'PEDRA' },
    { id: 2610905, description: 'PESQUEIRA' },
    { id: 2611002, description: 'PETROLANDIA' },
    { id: 2611101, description: 'PETROLINA' },
    { id: 2611200, description: 'POCAO' },
    { id: 2611309, description: 'POMBOS' },
    { id: 2611408, description: 'PRIMAVERA' },
    { id: 2611507, description: 'QUIPAPA' },
    { id: 2611533, description: 'QUIXABA' },
    { id: 2611606, description: 'RECIFE' },
    { id: 2611705, description: 'RIACHO DAS ALMAS' },
    { id: 2611804, description: 'RIBEIRAO' },
    { id: 2611903, description: 'RIO FORMOSO' },
    { id: 2612000, description: 'SAIRE' },
    { id: 2612109, description: 'SALGADINHO' },
    { id: 2612208, description: 'SALGUEIRO' },
    { id: 2612307, description: 'SALOA' },
    { id: 2612406, description: 'SANHARO' },
    { id: 2612455, description: 'SANTA CRUZ' },
    { id: 2612471, description: 'SANTA CRUZ DA BAIXA VERDE' },
    { id: 2612505, description: 'SANTA CRUZ DO CAPIBARIBE' },
    { id: 2612554, description: 'SANTA FILOMENA' },
    { id: 2612604, description: 'SANTA MARIA DA BOA VISTA' },
    { id: 2612703, description: 'SANTA MARIA DO CAMBUCA' },
    { id: 2612802, description: 'SANTA TEREZINHA' },
    { id: 2612901, description: 'SAO BENEDITO DO SUL' },
    { id: 2613008, description: 'SAO BENTO DO UNA' },
    { id: 2613107, description: 'SAO CAITANO' },
    { id: 2613206, description: 'SAO JOAO' },
    { id: 2613305, description: 'SAO JOAQUIM DO MONTE' },
    { id: 2613404, description: 'SAO JOSE DA COROA GRANDE' },
    { id: 2613503, description: 'SAO JOSE DO BELMONTE' },
    { id: 2613602, description: 'SAO JOSE DO EGITO' },
    { id: 2613701, description: 'SAO LOURENCO DA MATA' },
    { id: 2613800, description: 'SAO VICENTE FERRER' },
    { id: 2613909, description: 'SERRA TALHADA' },
    { id: 2614006, description: 'SERRITA' },
    { id: 2614105, description: 'SERTANIA' },
    { id: 2614204, description: 'SIRINHAEM' },
    { id: 2614303, description: 'MOREILANDIA' },
    { id: 2614402, description: 'SOLIDAO' },
    { id: 2614501, description: 'SURUBIM' },
    { id: 2614600, description: 'TABIRA' },
    { id: 2614709, description: 'TACAIMBO' },
    { id: 2614808, description: 'TACARATU' },
    { id: 2614857, description: 'TAMANDARE' },
    { id: 2615003, description: 'TAQUARITINGA DO NORTE' },
    { id: 2615102, description: 'TEREZINHA' },
    { id: 2615201, description: 'TERRA NOVA' },
    { id: 2615300, description: 'TIMBAUBA' },
    { id: 2615409, description: 'TORITAMA' },
    { id: 2615508, description: 'TRACUNHAEM' },
    { id: 2615607, description: 'TRINDADE' },
    { id: 2615706, description: 'TRIUNFO' },
    { id: 2615805, description: 'TUPANATINGA' },
    { id: 2615904, description: 'TUPARETAMA' },
    { id: 2616001, description: 'VENTUROSA' },
    { id: 2616100, description: 'VERDEJANTE' },
    { id: 2616183, description: 'VERTENTE DO LERIO' },
    { id: 2616209, description: 'VERTENTES' },
    { id: 2616308, description: 'VICENCIA' },
    { id: 2616407, description: 'VITORIA DE SANTO ANTAO' },
    { id: 2616506, description: 'XEXEU' },
    { id: 2700102, description: 'AGUA BRANCA' },
    { id: 2700201, description: 'ANADIA' },
    { id: 2700300, description: 'ARAPIRACA' },
    { id: 2700409, description: 'ATALAIA' },
    { id: 2700508, description: 'BARRA DE SANTO ANTONIO' },
    { id: 2700607, description: 'BARRA DE SAO MIGUEL' },
    { id: 2700706, description: 'BATALHA' },
    { id: 2700805, description: 'BELEM' },
    { id: 2700904, description: 'BELO MONTE' },
    { id: 2701001, description: 'BOCA DA MATA' },
    { id: 2701100, description: 'BRANQUINHA' },
    { id: 2701209, description: 'CACIMBINHAS' },
    { id: 2701308, description: 'CAJUEIRO' },
    { id: 2701357, description: 'CAMPESTRE' },
    { id: 2701407, description: 'CAMPO ALEGRE' },
    { id: 2701506, description: 'CAMPO GRANDE' },
    { id: 2701605, description: 'CANAPI' },
    { id: 2701704, description: 'CAPELA' },
    { id: 2701803, description: 'CARNEIROS' },
    { id: 2701902, description: 'CHA PRETA' },
    { id: 2702009, description: 'COITE DO NOIA' },
    { id: 2702108, description: 'COLONIA LEOPOLDINA' },
    { id: 2702207, description: 'COQUEIRO SECO' },
    { id: 2702306, description: 'CORURIPE' },
    { id: 2702355, description: 'CRAIBAS' },
    { id: 2702405, description: 'DELMIRO GOUVEIA' },
    { id: 2702504, description: 'DOIS RIACHOS' },
    { id: 2702553, description: 'ESTRELA DE ALAGOAS' },
    { id: 2702603, description: 'FEIRA GRANDE' },
    { id: 2702702, description: 'FELIZ DESERTO' },
    { id: 2702801, description: 'FLEXEIRAS' },
    { id: 2702900, description: 'GIRAU DO PONCIANO' },
    { id: 2703007, description: 'IBATEGUARA' },
    { id: 2703106, description: 'IGACI' },
    { id: 2703205, description: 'IGREJA NOVA' },
    { id: 2703304, description: 'INHAPI' },
    { id: 2703403, description: 'JACARE DOS HOMENS' },
    { id: 2703502, description: 'JACUIPE' },
    { id: 2703601, description: 'JAPARATINGA' },
    { id: 2703700, description: 'JARAMATAIA' },
    { id: 2703759, description: 'JEQUIA DA PRAIA' },
    { id: 2703809, description: 'JOAQUIM GOMES' },
    { id: 2703908, description: 'JUNDIA' },
    { id: 2704005, description: 'JUNQUEIRO' },
    { id: 2704104, description: 'LAGOA DA CANOA' },
    { id: 2704203, description: 'LIMOEIRO DE ANADIA' },
    { id: 2704302, description: 'MACEIO' },
    { id: 2704401, description: 'MAJOR ISIDORO' },
    { id: 2704500, description: 'MARAGOGI' },
    { id: 2704609, description: 'MARAVILHA' },
    { id: 2704708, description: 'MARECHAL DEODORO' },
    { id: 2704807, description: 'MARIBONDO' },
    { id: 2704906, description: 'MAR VERMELHO' },
    { id: 2705002, description: 'MATA GRANDE' },
    { id: 2705101, description: 'MATRIZ DE CAMARAGIBE' },
    { id: 2705200, description: 'MESSIAS' },
    { id: 2705309, description: 'MINADOR DO NEGRAO' },
    { id: 2705408, description: 'MONTEIROPOLIS' },
    { id: 2705507, description: 'MURICI' },
    { id: 2705606, description: 'NOVO LINO' },
    { id: 2705705, description: 'OLHO D AGUA DAS FLORES' },
    { id: 2705804, description: 'OLHO D AGUA DO CASADO' },
    { id: 2705903, description: 'OLHO D AGUA GRANDE' },
    { id: 2706000, description: 'OLIVENCA' },
    { id: 2706109, description: 'OURO BRANCO' },
    { id: 2706208, description: 'PALESTINA' },
    { id: 2706307, description: 'PALMEIRA DOS INDIOS' },
    { id: 2706406, description: 'PAO DE ACUCAR' },
    { id: 2706422, description: 'PARICONHA' },
    { id: 2706448, description: 'PARIPUEIRA' },
    { id: 2706505, description: 'PASSO DE CAMARAGIBE' },
    { id: 2706604, description: 'PAULO JACINTO' },
    { id: 2706703, description: 'PENEDO' },
    { id: 2706802, description: 'PIACABUCU' },
    { id: 2706901, description: 'PILAR' },
    { id: 2707008, description: 'PINDOBA' },
    { id: 2707107, description: 'PIRANHAS' },
    { id: 2707206, description: 'POCO DAS TRINCHEIRAS' },
    { id: 2707305, description: 'PORTO CALVO' },
    { id: 2707404, description: 'PORTO DE PEDRAS' },
    { id: 2707503, description: 'PORTO REAL DO COLEGIO' },
    { id: 2707602, description: 'QUEBRANGULO' },
    { id: 2707701, description: 'RIO LARGO' },
    { id: 2707800, description: 'ROTEIRO' },
    { id: 2707909, description: 'SANTA LUZIA DO NORTE' },
    { id: 2708006, description: 'SANTANA DO IPANEMA' },
    { id: 2708105, description: 'SANTANA DO MUNDAU' },
    { id: 2708204, description: 'SAO BRAS' },
    { id: 2708303, description: 'SAO JOSE DA LAJE' },
    { id: 2708402, description: 'SAO JOSE DA TAPERA' },
    { id: 2708501, description: 'SAO LUIS DO QUITUNDE' },
    { id: 2708600, description: 'SAO MIGUEL DOS CAMPOS' },
    { id: 2708709, description: 'SAO MIGUEL DOS MILAGRES' },
    { id: 2708808, description: 'SAO SEBASTIAO' },
    { id: 2708907, description: 'SATUBA' },
    { id: 2708956, description: 'SENADOR RUI PALMEIRA' },
    { id: 2709004, description: 'TANQUE D ARCA' },
    { id: 2709103, description: 'TAQUARANA' },
    { id: 2709152, description: 'TEOTONIO VILELA' },
    { id: 2709202, description: 'TRAIPU' },
    { id: 2709301, description: 'UNIAO DOS PALMARES' },
    { id: 2709400, description: 'VICOSA' },
    { id: 2800100, description: 'AMPARO DE SAO FRANCISCO' },
    { id: 2800209, description: 'AQUIDABA' },
    { id: 2800308, description: 'ARACAJU' },
    { id: 2800407, description: 'ARAUA' },
    { id: 2800506, description: 'AREIA BRANCA' },
    { id: 2800605, description: 'BARRA DOS COQUEIROS' },
    { id: 2800670, description: 'BOQUIM' },
    { id: 2800704, description: 'BREJO GRANDE' },
    { id: 2801009, description: 'CAMPO DO BRITO' },
    { id: 2801108, description: 'CANHOBA' },
    { id: 2801207, description: 'CANINDE DE SAO FRANCISCO' },
    { id: 2801306, description: 'CAPELA' },
    { id: 2801405, description: 'CARIRA' },
    { id: 2801504, description: 'CARMOPOLIS' },
    { id: 2801603, description: 'CEDRO DE SAO JOAO' },
    { id: 2801702, description: 'CRISTINAPOLIS' },
    { id: 2801900, description: 'CUMBE' },
    { id: 2802007, description: 'DIVINA PASTORA' },
    { id: 2802106, description: 'ESTANCIA' },
    { id: 2802205, description: 'FEIRA NOVA' },
    { id: 2802304, description: 'FREI PAULO' },
    { id: 2802403, description: 'GARARU' },
    { id: 2802502, description: 'GENERAL MAYNARD' },
    { id: 2802601, description: 'GRACCHO CARDOSO' },
    { id: 2802700, description: 'ILHA DAS FLORES' },
    { id: 2802809, description: 'INDIAROBA' },
    { id: 2802908, description: 'ITABAIANA' },
    { id: 2803005, description: 'ITABAIANINHA' },
    { id: 2803104, description: 'ITABI' },
    { id: 2803203, description: 'ITAPORANGA D AJUDA' },
    { id: 2803302, description: 'JAPARATUBA' },
    { id: 2803401, description: 'JAPOATA' },
    { id: 2803500, description: 'LAGARTO' },
    { id: 2803609, description: 'LARANJEIRAS' },
    { id: 2803708, description: 'MACAMBIRA' },
    { id: 2803807, description: 'MALHADA DOS BOIS' },
    { id: 2803906, description: 'MALHADOR' },
    { id: 2804003, description: 'MARUIM' },
    { id: 2804102, description: 'MOITA BONITA' },
    { id: 2804201, description: 'MONTE ALEGRE DE SERGIPE' },
    { id: 2804300, description: 'MURIBECA' },
    { id: 2804409, description: 'NEOPOLIS' },
    { id: 2804458, description: 'NOSSA SENHORA APARECIDA' },
    { id: 2804508, description: 'NOSSA SENHORA DA GLORIA' },
    { id: 2804607, description: 'NOSSA SENHORA DAS DORES' },
    { id: 2804706, description: 'NOSSA SENHORA DE LOURDES' },
    { id: 2804805, description: 'NOSSA SENHORA DO SOCORRO' },
    { id: 2804904, description: 'PACATUBA' },
    { id: 2805000, description: 'PEDRA MOLE' },
    { id: 2805109, description: 'PEDRINHAS' },
    { id: 2805208, description: 'PINHAO' },
    { id: 2805307, description: 'PIRAMBU' },
    { id: 2805406, description: 'POCO REDONDO' },
    { id: 2805505, description: 'POCO VERDE' },
    { id: 2805604, description: 'PORTO DA FOLHA' },
    { id: 2805703, description: 'PROPRIA' },
    { id: 2805802, description: 'RIACHAO DO DANTAS' },
    { id: 2805901, description: 'RIACHUELO' },
    { id: 2806008, description: 'RIBEIROPOLIS' },
    { id: 2806107, description: 'ROSARIO DO CATETE' },
    { id: 2806206, description: 'SALGADO' },
    { id: 2806305, description: 'SANTA LUZIA DO ITANHY' },
    { id: 2806404, description: 'SANTANA DO SAO FRANCISCO' },
    { id: 2806503, description: 'SANTA ROSA DE LIMA' },
    { id: 2806602, description: 'SANTO AMARO DAS BROTAS' },
    { id: 2806701, description: 'SAO CRISTOVAO' },
    { id: 2806800, description: 'SAO DOMINGOS' },
    { id: 2806909, description: 'SAO FRANCISCO' },
    { id: 2807006, description: 'SAO MIGUEL DO ALEIXO' },
    { id: 2807105, description: 'SIMAO DIAS' },
    { id: 2807204, description: 'SIRIRI' },
    { id: 2807303, description: 'TELHA' },
    { id: 2807402, description: 'TOBIAS BARRETO' },
    { id: 2807501, description: 'TOMAR DO GERU' },
    { id: 2807600, description: 'UMBAUBA' },
    { id: 2900108, description: 'ABAIRA' },
    { id: 2900207, description: 'ABARE' },
    { id: 2900306, description: 'ACAJUTIBA' },
    { id: 2900355, description: 'ADUSTINA' },
    { id: 2900405, description: 'AGUA FRIA' },
    { id: 2900504, description: 'ERICO CARDOSO' },
    { id: 2900603, description: 'AIQUARA' },
    { id: 2900702, description: 'ALAGOINHAS' },
    { id: 2900801, description: 'ALCOBACA' },
    { id: 2900900, description: 'ALMADINA' },
    { id: 2901007, description: 'AMARGOSA' },
    { id: 2901106, description: 'AMELIA RODRIGUES' },
    { id: 2901155, description: 'AMERICA DOURADA' },
    { id: 2901205, description: 'ANAGE' },
    { id: 2901304, description: 'ANDARAI' },
    { id: 2901353, description: 'ANDORINHA' },
    { id: 2901403, description: 'ANGICAL' },
    { id: 2901502, description: 'ANGUERA' },
    { id: 2901601, description: 'ANTAS' },
    { id: 2901700, description: 'ANTONIO CARDOSO' },
    { id: 2901809, description: 'ANTONIO GONCALVES' },
    { id: 2901908, description: 'APORA' },
    { id: 2901957, description: 'APUAREMA' },
    { id: 2902005, description: 'ARACATU' },
    { id: 2902054, description: 'ARACAS' },
    { id: 2902104, description: 'ARACI' },
    { id: 2902203, description: 'ARAMARI' },
    { id: 2902252, description: 'ARATACA' },
    { id: 2902302, description: 'ARATUIPE' },
    { id: 2902401, description: 'AURELINO LEAL' },
    { id: 2902500, description: 'BAIANOPOLIS' },
    { id: 2902609, description: 'BAIXA GRANDE' },
    { id: 2902658, description: 'BANZAE' },
    { id: 2902708, description: 'BARRA' },
    { id: 2902807, description: 'BARRA DA ESTIVA' },
    { id: 2902906, description: 'BARRA DO CHOCA' },
    { id: 2903003, description: 'BARRA DO MENDES' },
    { id: 2903102, description: 'BARRA DO ROCHA' },
    { id: 2903201, description: 'BARREIRAS' },
    { id: 2903235, description: 'BARRO ALTO' },
    { id: 2903276, description: 'BARROCAS' },
    { id: 2903300, description: 'BARRO PRETO' },
    { id: 2903409, description: 'BELMONTE' },
    { id: 2903508, description: 'BELO CAMPO' },
    { id: 2903607, description: 'BIRITINGA' },
    { id: 2903706, description: 'BOA NOVA' },
    { id: 2903805, description: 'BOA VISTA DO TUPIM' },
    { id: 2903904, description: 'BOM JESUS DA LAPA' },
    { id: 2903953, description: 'BOM JESUS DA SERRA' },
    { id: 2904001, description: 'BONINAL' },
    { id: 2904050, description: 'BONITO' },
    { id: 2904100, description: 'BOQUIRA' },
    { id: 2904209, description: 'BOTUPORA' },
    { id: 2904308, description: 'BREJOES' },
    { id: 2904407, description: 'BREJOLANDIA' },
    { id: 2904506, description: 'BROTAS DE MACAUBAS' },
    { id: 2904605, description: 'BRUMADO' },
    { id: 2904704, description: 'BUERAREMA' },
    { id: 2904753, description: 'BURITIRAMA' },
    { id: 2904803, description: 'CAATIBA' },
    { id: 2904852, description: 'CABACEIRAS DO PARAGUACU' },
    { id: 2904902, description: 'CACHOEIRA' },
    { id: 2905008, description: 'CACULE' },
    { id: 2905107, description: 'CAEM' },
    { id: 2905156, description: 'CAETANOS' },
    { id: 2905206, description: 'CAETITE' },
    { id: 2905305, description: 'CAFARNAUM' },
    { id: 2905404, description: 'CAIRU' },
    { id: 2905503, description: 'CALDEIRAO GRANDE' },
    { id: 2905602, description: 'CAMACAN' },
    { id: 2905701, description: 'CAMACARI' },
    { id: 2905800, description: 'CAMAMU' },
    { id: 2905909, description: 'CAMPO ALEGRE DE LOURDES' },
    { id: 2906006, description: 'CAMPO FORMOSO' },
    { id: 2906105, description: 'CANAPOLIS' },
    { id: 2906204, description: 'CANARANA' },
    { id: 2906303, description: 'CANAVIEIRAS' },
    { id: 2906402, description: 'CANDEAL' },
    { id: 2906501, description: 'CANDEIAS' },
    { id: 2906600, description: 'CANDIBA' },
    { id: 2906709, description: 'CANDIDO SALES' },
    { id: 2906808, description: 'CANSANCAO' },
    { id: 2906824, description: 'CANUDOS' },
    { id: 2906857, description: 'CAPELA DO ALTO ALEGRE' },
    { id: 2906873, description: 'CAPIM GROSSO' },
    { id: 2906899, description: 'CARAIBAS' },
    { id: 2906907, description: 'CARAVELAS' },
    { id: 2907004, description: 'CARDEAL DA SILVA' },
    { id: 2907103, description: 'CARINHANHA' },
    { id: 2907202, description: 'CASA NOVA' },
    { id: 2907301, description: 'CASTRO ALVES' },
    { id: 2907400, description: 'CATOLANDIA' },
    { id: 2907509, description: 'CATU' },
    { id: 2907558, description: 'CATURAMA' },
    { id: 2907608, description: 'CENTRAL' },
    { id: 2907707, description: 'CHORROCHO' },
    { id: 2907806, description: 'CICERO DANTAS' },
    { id: 2907905, description: 'CIPO' },
    { id: 2908002, description: 'COARACI' },
    { id: 2908101, description: 'COCOS' },
    { id: 2908200, description: 'CONCEICAO DA FEIRA' },
    { id: 2908309, description: 'CONCEICAO DO ALMEIDA' },
    { id: 2908408, description: 'CONCEICAO DO COITE' },
    { id: 2908507, description: 'CONCEICAO DO JACUIPE' },
    { id: 2908606, description: 'CONDE' },
    { id: 2908705, description: 'CONDEUBA' },
    { id: 2908804, description: 'CONTENDAS DO SINCORA' },
    { id: 2908903, description: 'CORACAO DE MARIA' },
    { id: 2909000, description: 'CORDEIROS' },
    { id: 2909109, description: 'CORIBE' },
    { id: 2909208, description: 'CORONEL JOAO SA' },
    { id: 2909307, description: 'CORRENTINA' },
    { id: 2909406, description: 'COTEGIPE' },
    { id: 2909505, description: 'CRAVOLANDIA' },
    { id: 2909604, description: 'CRISOPOLIS' },
    { id: 2909703, description: 'CRISTOPOLIS' },
    { id: 2909802, description: 'CRUZ DAS ALMAS' },
    { id: 2909901, description: 'CURACA' },
    { id: 2910008, description: 'DARIO MEIRA' },
    { id: 2910057, description: 'DIAS D AVILA' },
    { id: 2910107, description: 'DOM BASILIO' },
    { id: 2910206, description: 'DOM MACEDO COSTA' },
    { id: 2910305, description: 'ELISIO MEDRADO' },
    { id: 2910404, description: 'ENCRUZILHADA' },
    { id: 2910503, description: 'ENTRE RIOS' },
    { id: 2910602, description: 'ESPLANADA' },
    { id: 2910701, description: 'EUCLIDES DA CUNHA' },
    { id: 2910727, description: 'EUNAPOLIS' },
    { id: 2910750, description: 'FATIMA' },
    { id: 2910776, description: 'FEIRA DA MATA' },
    { id: 2910800, description: 'FEIRA DE SANTANA' },
    { id: 2910859, description: 'FILADELFIA' },
    { id: 2910909, description: 'FIRMINO ALVES' },
    { id: 2911006, description: 'FLORESTA AZUL' },
    { id: 2911105, description: 'FORMOSA DO RIO PRETO' },
    { id: 2911204, description: 'GANDU' },
    { id: 2911253, description: 'GAVIAO' },
    { id: 2911303, description: 'GENTIO DO OURO' },
    { id: 2911402, description: 'GLORIA' },
    { id: 2911501, description: 'GONGOGI' },
    { id: 2911600, description: 'GOVERNADOR MANGABEIRA' },
    { id: 2911659, description: 'GUAJERU' },
    { id: 2911709, description: 'GUANAMBI' },
    { id: 2911808, description: 'GUARATINGA' },
    { id: 2911857, description: 'HELIOPOLIS' },
    { id: 2911907, description: 'IACU' },
    { id: 2912004, description: 'IBIASSUCE' },
    { id: 2912103, description: 'IBICARAI' },
    { id: 2912202, description: 'IBICOARA' },
    { id: 2912301, description: 'IBICUI' },
    { id: 2912400, description: 'IBIPEBA' },
    { id: 2912509, description: 'IBIPITANGA' },
    { id: 2912608, description: 'IBIQUERA' },
    { id: 2912707, description: 'IBIRAPITANGA' },
    { id: 2912806, description: 'IBIRAPUA' },
    { id: 2912905, description: 'IBIRATAIA' },
    { id: 2913002, description: 'IBITIARA' },
    { id: 2913101, description: 'IBITITA' },
    { id: 2913200, description: 'IBOTIRAMA' },
    { id: 2913309, description: 'ICHU' },
    { id: 2913408, description: 'IGAPORA' },
    { id: 2913457, description: 'IGRAPIUNA' },
    { id: 2913507, description: 'IGUAI' },
    { id: 2913606, description: 'ILHEUS' },
    { id: 2913705, description: 'INHAMBUPE' },
    { id: 2913804, description: 'IPECAETA' },
    { id: 2913903, description: 'IPIAU' },
    { id: 2914000, description: 'IPIRA' },
    { id: 2914109, description: 'IPUPIARA' },
    { id: 2914208, description: 'IRAJUBA' },
    { id: 2914307, description: 'IRAMAIA' },
    { id: 2914406, description: 'IRAQUARA' },
    { id: 2914505, description: 'IRARA' },
    { id: 2914604, description: 'IRECE' },
    { id: 2914653, description: 'ITABELA' },
    { id: 2914703, description: 'ITABERABA' },
    { id: 2914802, description: 'ITABUNA' },
    { id: 2914901, description: 'ITACARE' },
    { id: 2915007, description: 'ITAETE' },
    { id: 2915106, description: 'ITAGI' },
    { id: 2915205, description: 'ITAGIBA' },
    { id: 2915304, description: 'ITAGIMIRIM' },
    { id: 2915353, description: 'ITAGUACU DA BAHIA' },
    { id: 2915403, description: 'ITAJU DO COLONIA' },
    { id: 2915502, description: 'ITAJUIPE' },
    { id: 2915601, description: 'ITAMARAJU' },
    { id: 2915700, description: 'ITAMARI' },
    { id: 2915809, description: 'ITAMBE' },
    { id: 2915908, description: 'ITANAGRA' },
    { id: 2916005, description: 'ITANHEM' },
    { id: 2916104, description: 'ITAPARICA' },
    { id: 2916203, description: 'ITAPE' },
    { id: 2916302, description: 'ITAPEBI' },
    { id: 2916401, description: 'ITAPETINGA' },
    { id: 2916500, description: 'ITAPICURU' },
    { id: 2916609, description: 'ITAPITANGA' },
    { id: 2916708, description: 'ITAQUARA' },
    { id: 2916807, description: 'ITARANTIM' },
    { id: 2916856, description: 'ITATIM' },
    { id: 2916906, description: 'ITIRUCU' },
    { id: 2917003, description: 'ITIUBA' },
    { id: 2917102, description: 'ITORORO' },
    { id: 2917201, description: 'ITUACU' },
    { id: 2917300, description: 'ITUBERA' },
    { id: 2917334, description: 'IUIU' },
    { id: 2917359, description: 'JABORANDI' },
    { id: 2917409, description: 'JACARACI' },
    { id: 2917508, description: 'JACOBINA' },
    { id: 2917607, description: 'JAGUAQUARA' },
    { id: 2917706, description: 'JAGUARARI' },
    { id: 2917805, description: 'JAGUARIPE' },
    { id: 2917904, description: 'JANDAIRA' },
    { id: 2918001, description: 'JEQUIE' },
    { id: 2918100, description: 'JEREMOABO' },
    { id: 2918209, description: 'JEQUIRICA' },
    { id: 2918308, description: 'JITAUNA' },
    { id: 2918357, description: 'JOAO DOURADO' },
    { id: 2918407, description: 'JUAZEIRO' },
    { id: 2918456, description: 'JUCURUCU' },
    { id: 2918506, description: 'JUSSARA' },
    { id: 2918555, description: 'JUSSARI' },
    { id: 2918605, description: 'JUSSIAPE' },
    { id: 2918704, description: 'LAFAIETE COUTINHO' },
    { id: 2918753, description: 'LAGOA REAL' },
    { id: 2918803, description: 'LAJE' },
    { id: 2918902, description: 'LAJEDAO' },
    { id: 2919009, description: 'LAJEDINHO' },
    { id: 2919058, description: 'LAJEDO DO TABOCAL' },
    { id: 2919108, description: 'LAMARAO' },
    { id: 2919157, description: 'LAPAO' },
    { id: 2919207, description: 'LAURO DE FREITAS' },
    { id: 2919306, description: 'LENCOIS' },
    { id: 2919405, description: 'LICINIO DE ALMEIDA' },
    { id: 2919504, description: 'LIVRAMENTO DE NOSSA SENHORA' },
    { id: 2919553, description: 'LUIS EDUARDO MAGALHAES' },
    { id: 2919603, description: 'MACAJUBA' },
    { id: 2919702, description: 'MACARANI' },
    { id: 2919801, description: 'MACAUBAS' },
    { id: 2919900, description: 'MACURURE' },
    { id: 2919926, description: 'MADRE DE DEUS' },
    { id: 2919959, description: 'MAETINGA' },
    { id: 2920007, description: 'MAIQUINIQUE' },
    { id: 2920106, description: 'MAIRI' },
    { id: 2920205, description: 'MALHADA' },
    { id: 2920304, description: 'MALHADA DE PEDRAS' },
    { id: 2920403, description: 'MANOEL VITORINO' },
    { id: 2920452, description: 'MANSIDAO' },
    { id: 2920502, description: 'MARACAS' },
    { id: 2920601, description: 'MARAGOGIPE' },
    { id: 2920700, description: 'MARAU' },
    { id: 2920809, description: 'MARCIONILIO SOUZA' },
    { id: 2920908, description: 'MASCOTE' },
    { id: 2921005, description: 'MATA DE SAO JOAO' },
    { id: 2921054, description: 'MATINA' },
    { id: 2921104, description: 'MEDEIROS NETO' },
    { id: 2921203, description: 'MIGUEL CALMON' },
    { id: 2921302, description: 'MILAGRES' },
    { id: 2921401, description: 'MIRANGABA' },
    { id: 2921450, description: 'MIRANTE' },
    { id: 2921500, description: 'MONTE SANTO' },
    { id: 2921609, description: 'MORPARA' },
    { id: 2921708, description: 'MORRO DO CHAPEU' },
    { id: 2921807, description: 'MORTUGABA' },
    { id: 2921906, description: 'MUCUGE' },
    { id: 2922003, description: 'MUCURI' },
    { id: 2922052, description: 'MULUNGU DO MORRO' },
    { id: 2922102, description: 'MUNDO NOVO' },
    { id: 2922201, description: 'MUNIZ FERREIRA' },
    { id: 2922250, description: 'MUQUEM DE SAO FRANCISCO' },
    { id: 2922300, description: 'MURITIBA' },
    { id: 2922409, description: 'MUTUIPE' },
    { id: 2922508, description: 'NAZARE' },
    { id: 2922607, description: 'NILO PECANHA' },
    { id: 2922656, description: 'NORDESTINA' },
    { id: 2922706, description: 'NOVA CANAA' },
    { id: 2922730, description: 'NOVA FATIMA' },
    { id: 2922755, description: 'NOVA IBIA' },
    { id: 2922805, description: 'NOVA ITARANA' },
    { id: 2922854, description: 'NOVA REDENCAO' },
    { id: 2922904, description: 'NOVA SOURE' },
    { id: 2923001, description: 'NOVA VICOSA' },
    { id: 2923035, description: 'NOVO HORIZONTE' },
    { id: 2923050, description: 'NOVO TRIUNFO' },
    { id: 2923100, description: 'OLINDINA' },
    { id: 2923209, description: 'OLIVEIRA DOS BREJINHOS' },
    { id: 2923308, description: 'OURICANGAS' },
    { id: 2923357, description: 'OUROLANDIA' },
    { id: 2923407, description: 'PALMAS DE MONTE ALTO' },
    { id: 2923506, description: 'PALMEIRAS' },
    { id: 2923605, description: 'PARAMIRIM' },
    { id: 2923704, description: 'PARATINGA' },
    { id: 2923803, description: 'PARIPIRANGA' },
    { id: 2923902, description: 'PAU BRASIL' },
    { id: 2924009, description: 'PAULO AFONSO' },
    { id: 2924058, description: 'PE DE SERRA' },
    { id: 2924108, description: 'PEDRAO' },
    { id: 2924207, description: 'PEDRO ALEXANDRE' },
    { id: 2924306, description: 'PIATA' },
    { id: 2924405, description: 'PILAO ARCADO' },
    { id: 2924504, description: 'PINDAI' },
    { id: 2924603, description: 'PINDOBACU' },
    { id: 2924652, description: 'PINTADAS' },
    { id: 2924678, description: 'PIRAI DO NORTE' },
    { id: 2924702, description: 'PIRIPA' },
    { id: 2924801, description: 'PIRITIBA' },
    { id: 2924900, description: 'PLANALTINO' },
    { id: 2925006, description: 'PLANALTO' },
    { id: 2925105, description: 'POCOES' },
    { id: 2925204, description: 'POJUCA' },
    { id: 2925253, description: 'PONTO NOVO' },
    { id: 2925303, description: 'PORTO SEGURO' },
    { id: 2925402, description: 'POTIRAGUA' },
    { id: 2925501, description: 'PRADO' },
    { id: 2925600, description: 'PRESIDENTE DUTRA' },
    { id: 2925709, description: 'PRESIDENTE JANIO QUADROS' },
    { id: 2925758, description: 'PRESIDENTE TANCREDO NEVES' },
    { id: 2925808, description: 'QUEIMADAS' },
    { id: 2925907, description: 'QUIJINGUE' },
    { id: 2925931, description: 'QUIXABEIRA' },
    { id: 2925956, description: 'RAFAEL JAMBEIRO' },
    { id: 2926004, description: 'REMANSO' },
    { id: 2926103, description: 'RETIROLANDIA' },
    { id: 2926202, description: 'RIACHAO DAS NEVES' },
    { id: 2926301, description: 'RIACHAO DO JACUIPE' },
    { id: 2926400, description: 'RIACHO DE SANTANA' },
    { id: 2926509, description: 'RIBEIRA DO AMPARO' },
    { id: 2926608, description: 'RIBEIRA DO POMBAL' },
    { id: 2926657, description: 'RIBEIRAO DO LARGO' },
    { id: 2926707, description: 'RIO DE CONTAS' },
    { id: 2926806, description: 'RIO DO ANTONIO' },
    { id: 2926905, description: 'RIO DO PIRES' },
    { id: 2927002, description: 'RIO REAL' },
    { id: 2927101, description: 'RODELAS' },
    { id: 2927200, description: 'RUY BARBOSA' },
    { id: 2927309, description: 'SALINAS DA MARGARIDA' },
    { id: 2927408, description: 'SALVADOR' },
    { id: 2927507, description: 'SANTA BARBARA' },
    { id: 2927606, description: 'SANTA BRIGIDA' },
    { id: 2927705, description: 'SANTA CRUZ CABRALIA' },
    { id: 2927804, description: 'SANTA CRUZ DA VITORIA' },
    { id: 2927903, description: 'SANTA INES' },
    { id: 2928000, description: 'SANTALUZ' },
    { id: 2928059, description: 'SANTA LUZIA' },
    { id: 2928109, description: 'SANTA MARIA DA VITORIA' },
    { id: 2928208, description: 'SANTANA' },
    { id: 2928307, description: 'SANTANOPOLIS' },
    { id: 2928406, description: 'SANTA RITA DE CASSIA' },
    { id: 2928505, description: 'SANTA TEREZINHA' },
    { id: 2928604, description: 'SANTO AMARO' },
    { id: 2928703, description: 'SANTO ANTONIO DE JESUS' },
    { id: 2928802, description: 'SANTO ESTEVAO' },
    { id: 2928901, description: 'SAO DESIDERIO' },
    { id: 2928950, description: 'SAO DOMINGOS' },
    { id: 2929008, description: 'SAO FELIX' },
    { id: 2929057, description: 'SAO FELIX DO CORIBE' },
    { id: 2929107, description: 'SAO FELIPE' },
    { id: 2929206, description: 'SAO FRANCISCO DO CONDE' },
    { id: 2929255, description: 'SAO GABRIEL' },
    { id: 2929305, description: 'SAO GONCALO DOS CAMPOS' },
    { id: 2929354, description: 'SAO JOSE DA VITORIA' },
    { id: 2929370, description: 'SAO JOSE DO JACUIPE' },
    { id: 2929404, description: 'SAO MIGUEL DAS MATAS' },
    { id: 2929503, description: 'SAO SEBASTIAO DO PASSE' },
    { id: 2929602, description: 'SAPEACU' },
    { id: 2929701, description: 'SATIRO DIAS' },
    { id: 2929750, description: 'SAUBARA' },
    { id: 2929800, description: 'SAUDE' },
    { id: 2929909, description: 'SEABRA' },
    { id: 2930006, description: 'SEBASTIAO LARANJEIRAS' },
    { id: 2930105, description: 'SENHOR DO BONFIM' },
    { id: 2930154, description: 'SERRA DO RAMALHO' },
    { id: 2930204, description: 'SENTO SE' },
    { id: 2930303, description: 'SERRA DOURADA' },
    { id: 2930402, description: 'SERRA PRETA' },
    { id: 2930501, description: 'SERRINHA' },
    { id: 2930600, description: 'SERROLANDIA' },
    { id: 2930709, description: 'SIMOES FILHO' },
    { id: 2930758, description: 'SITIO DO MATO' },
    { id: 2930766, description: 'SITIO DO QUINTO' },
    { id: 2930774, description: 'SOBRADINHO' },
    { id: 2930808, description: 'SOUTO SOARES' },
    { id: 2930907, description: 'TABOCAS DO BREJO VELHO' },
    { id: 2931004, description: 'TANHACU' },
    { id: 2931053, description: 'TANQUE NOVO' },
    { id: 2931103, description: 'TANQUINHO' },
    { id: 2931202, description: 'TAPEROA' },
    { id: 2931301, description: 'TAPIRAMUTA' },
    { id: 2931350, description: 'TEIXEIRA DE FREITAS' },
    { id: 2931400, description: 'TEODORO SAMPAIO' },
    { id: 2931509, description: 'TEOFILANDIA' },
    { id: 2931608, description: 'TEOLANDIA' },
    { id: 2931707, description: 'TERRA NOVA' },
    { id: 2931806, description: 'TREMEDAL' },
    { id: 2931905, description: 'TUCANO' },
    { id: 2932002, description: 'UAUA' },
    { id: 2932101, description: 'UBAIRA' },
    { id: 2932200, description: 'UBAITABA' },
    { id: 2932309, description: 'UBATA' },
    { id: 2932408, description: 'UIBAI' },
    { id: 2932457, description: 'UMBURANAS' },
    { id: 2932507, description: 'UNA' },
    { id: 2932606, description: 'URANDI' },
    { id: 2932705, description: 'URUCUCA' },
    { id: 2932804, description: 'UTINGA' },
    { id: 2932903, description: 'VALENCA' },
    { id: 2933000, description: 'VALENTE' },
    { id: 2933059, description: 'VARZEA DA ROCA' },
    { id: 2933109, description: 'VARZEA DO POCO' },
    { id: 2933158, description: 'VARZEA NOVA' },
    { id: 2933174, description: 'VARZEDO' },
    { id: 2933208, description: 'VERA CRUZ' },
    { id: 2933257, description: 'VEREDA' },
    { id: 2933307, description: 'VITORIA DA CONQUISTA' },
    { id: 2933406, description: 'WAGNER' },
    { id: 2933455, description: 'WANDERLEY' },
    { id: 2933505, description: 'WENCESLAU GUIMARAES' },
    { id: 2933604, description: 'XIQUE XIQUE' },
    { id: 3100104, description: 'ABADIA DOS DOURADOS' },
    { id: 3100203, description: 'ABAETE' },
    { id: 3100302, description: 'ABRE CAMPO' },
    { id: 3100401, description: 'ACAIACA' },
    { id: 3100500, description: 'ACUCENA' },
    { id: 3100609, description: 'AGUA BOA' },
    { id: 3100708, description: 'AGUA COMPRIDA' },
    { id: 3100807, description: 'AGUANIL' },
    { id: 3100906, description: 'AGUAS FORMOSAS' },
    { id: 3101003, description: 'AGUAS VERMELHAS' },
    { id: 3101102, description: 'AIMORES' },
    { id: 3101201, description: 'AIURUOCA' },
    { id: 3101300, description: 'ALAGOA' },
    { id: 3101409, description: 'ALBERTINA' },
    { id: 3101508, description: 'ALEM PARAIBA' },
    { id: 3101607, description: 'ALFENAS' },
    { id: 3101631, description: 'ALFREDO VASCONCELOS' },
    { id: 3101706, description: 'ALMENARA' },
    { id: 3101805, description: 'ALPERCATA' },
    { id: 3101904, description: 'ALPINOPOLIS' },
    { id: 3102001, description: 'ALTEROSA' },
    { id: 3102050, description: 'ALTO CAPARAO' },
    { id: 3102100, description: 'ALTO RIO DOCE' },
    { id: 3102209, description: 'ALVARENGA' },
    { id: 3102308, description: 'ALVINOPOLIS' },
    { id: 3102407, description: 'ALVORADA DE MINAS' },
    { id: 3102506, description: 'AMPARO DA SERRA' },
    { id: 3102605, description: 'ANDRADAS' },
    { id: 3102704, description: 'CACHOEIRA DE PAJEU' },
    { id: 3102803, description: 'ANDRELANDIA' },
    { id: 3102852, description: 'ANGELANDIA' },
    { id: 3102902, description: 'ANTONIO CARLOS' },
    { id: 3103009, description: 'ANTONIO DIAS' },
    { id: 3103108, description: 'ANTONIO PRADO DE MINAS' },
    { id: 3103207, description: 'ARACAI' },
    { id: 3103306, description: 'ARACITABA' },
    { id: 3103405, description: 'ARACUAI' },
    { id: 3103504, description: 'ARAGUARI' },
    { id: 3103603, description: 'ARANTINA' },
    { id: 3103702, description: 'ARAPONGA' },
    { id: 3103751, description: 'ARAPORA' },
    { id: 3103801, description: 'ARAPUA' },
    { id: 3103900, description: 'ARAUJOS' },
    { id: 3104007, description: 'ARAXA' },
    { id: 3104106, description: 'ARCEBURGO' },
    { id: 3104205, description: 'ARCOS' },
    { id: 3104304, description: 'AREADO' },
    { id: 3104403, description: 'ARGIRITA' },
    { id: 3104452, description: 'ARICANDUVA' },
    { id: 3104502, description: 'ARINOS' },
    { id: 3104601, description: 'ASTOLFO DUTRA' },
    { id: 3104700, description: 'ATALEIA' },
    { id: 3104809, description: 'AUGUSTO DE LIMA' },
    { id: 3104908, description: 'BAEPENDI' },
    { id: 3105004, description: 'BALDIM' },
    { id: 310500410, description: 'SAO VICENTE' },
    { id: 3105103, description: 'BAMBUI' },
    { id: 3105202, description: 'BANDEIRA' },
    { id: 3105301, description: 'BANDEIRA DO SUL' },
    { id: 3105400, description: 'BARAO DE COCAIS' },
    { id: 3105509, description: 'BARAO DE MONTE ALTO' },
    { id: 3105608, description: 'BARBACENA' },
    { id: 3105707, description: 'BARRA LONGA' },
    { id: 3105905, description: 'BARROSO' },
    { id: 3106002, description: 'BELA VISTA DE MINAS' },
    { id: 3106101, description: 'BELMIRO BRAGA' },
    { id: 3106200, description: 'BELO HORIZONTE' },
    { id: 3106309, description: 'BELO ORIENTE' },
    { id: 3106408, description: 'BELO VALE' },
    { id: 3106507, description: 'BERILO' },
    { id: 3106606, description: 'BERTOPOLIS' },
    { id: 3106655, description: 'BERIZAL' },
    { id: 3106705, description: 'BETIM' },
    { id: 3106804, description: 'BIAS FORTES' },
    { id: 3106903, description: 'BICAS' },
    { id: 3107000, description: 'BIQUINHAS' },
    { id: 3107109, description: 'BOA ESPERANCA' },
    { id: 3107208, description: 'BOCAINA DE MINAS' },
    { id: 3107307, description: 'BOCAIUVA' },
    { id: 3107406, description: 'BOM DESPACHO' },
    { id: 3107505, description: 'BOM JARDIM DE MINAS' },
    { id: 3107604, description: 'BOM JESUS DA PENHA' },
    { id: 3107703, description: 'BOM JESUS DO AMPARO' },
    { id: 3107802, description: 'BOM JESUS DO GALHO' },
    { id: 3107901, description: 'BOM REPOUSO' },
    { id: 3108008, description: 'BOM SUCESSO' },
    { id: 3108107, description: 'BONFIM' },
    { id: 3108206, description: 'BONFINOPOLIS DE MINAS' },
    { id: 3108255, description: 'BONITO DE MINAS' },
    { id: 3108305, description: 'BORDA DA MATA' },
    { id: 3108404, description: 'BOTELHOS' },
    { id: 3108503, description: 'BOTUMIRIM' },
    { id: 3108552, description: 'BRASILANDIA DE MINAS' },
    { id: 3108602, description: 'BRASILIA DE MINAS' },
    { id: 3108701, description: 'BRAS PIRES' },
    { id: 3108800, description: 'BRAUNAS' },
    { id: 3108909, description: 'BRASOPOLIS' },
    { id: 3109006, description: 'BRUMADINHO' },
    { id: 3109105, description: 'BUENO BRANDAO' },
    { id: 3109204, description: 'BUENOPOLIS' },
    { id: 3109253, description: 'BUGRE' },
    { id: 3109303, description: 'BURITIS' },
    { id: 3109402, description: 'BURITIZEIRO' },
    { id: 3109451, description: 'CABECEIRA GRANDE' },
    { id: 3109501, description: 'CABO VERDE' },
    { id: 3109600, description: 'CACHOEIRA DA PRATA' },
    { id: 3109709, description: 'CACHOEIRA DE MINAS' },
    { id: 3109808, description: 'CACHOEIRA DOURADA' },
    { id: 3109907, description: 'CAETANOPOLIS' },
    { id: 3110004, description: 'CAETE' },
    { id: 3110103, description: 'CAIANA' },
    { id: 3110202, description: 'CAJURI' },
    { id: 3110301, description: 'CALDAS' },
    { id: 3110400, description: 'CAMACHO' },
    { id: 3110509, description: 'CAMANDUCAIA' },
    { id: 3110608, description: 'CAMBUI' },
    { id: 3110707, description: 'CAMBUQUIRA' },
    { id: 3110806, description: 'CAMPANARIO' },
    { id: 3110905, description: 'CAMPANHA' },
    { id: 3111002, description: 'CAMPESTRE' },
    { id: 3111101, description: 'CAMPINA VERDE' },
    { id: 3111150, description: 'CAMPO AZUL' },
    { id: 3111200, description: 'CAMPO BELO' },
    { id: 3111309, description: 'CAMPO DO MEIO' },
    { id: 3111408, description: 'CAMPO FLORIDO' },
    { id: 3111507, description: 'CAMPOS ALTOS' },
    { id: 3111606, description: 'CAMPOS GERAIS' },
    { id: 3111705, description: 'CANAA' },
    { id: 3111804, description: 'CANAPOLIS' },
    { id: 3111903, description: 'CANA VERDE' },
    { id: 3112000, description: 'CANDEIAS' },
    { id: 3112059, description: 'CANTAGALO' },
    { id: 3112109, description: 'CAPARAO' },
    { id: 3112208, description: 'CAPELA NOVA' },
    { id: 3112307, description: 'CAPELINHA' },
    { id: 3112406, description: 'CAPETINGA' },
    { id: 3112505, description: 'CAPIM BRANCO' },
    { id: 3112604, description: 'CAPINOPOLIS' },
    { id: 3112653, description: 'CAPITAO ANDRADE' },
    { id: 3112703, description: 'CAPITAO ENEAS' },
    { id: 3112802, description: 'CAPITOLIO' },
    { id: 3112901, description: 'CAPUTIRA' },
    { id: 3113008, description: 'CARAI' },
    { id: 3113107, description: 'CARANAIBA' },
    { id: 3113206, description: 'CARANDAI' },
    { id: 3113305, description: 'CARANGOLA' },
    { id: 3113404, description: 'CARATINGA' },
    { id: 3113503, description: 'CARBONITA' },
    { id: 3113602, description: 'CAREACU' },
    { id: 3113701, description: 'CARLOS CHAGAS' },
    { id: 3113800, description: 'CARMESIA' },
    { id: 3113909, description: 'CARMO DA CACHOEIRA' },
    { id: 3114006, description: 'CARMO DA MATA' },
    { id: 3114105, description: 'CARMO DE MINAS' },
    { id: 3114204, description: 'CARMO DO CAJURU' },
    { id: 3114303, description: 'CARMO DO PARANAIBA' },
    { id: 3114402, description: 'CARMO DO RIO CLARO' },
    { id: 3114501, description: 'CARMOPOLIS DE MINAS' },
    { id: 3114550, description: 'CARNEIRINHO' },
    { id: 3114600, description: 'CARRANCAS' },
    { id: 3114709, description: 'CARVALHOPOLIS' },
    { id: 3114808, description: 'CARVALHOS' },
    { id: 3114907, description: 'CASA GRANDE' },
    { id: 3115003, description: 'CASCALHO RICO' },
    { id: 3115102, description: 'CASSIA' },
    { id: 3115201, description: 'CONCEICAO DA BARRA DE MINAS' },
    { id: 3115300, description: 'CATAGUASES' },
    { id: 3115359, description: 'CATAS ALTAS' },
    { id: 3115409, description: 'CATAS ALTAS DA NORUEGA' },
    { id: 3115458, description: 'CATUJI' },
    { id: 3115474, description: 'CATUTI' },
    { id: 3115508, description: 'CAXAMBU' },
    { id: 3115607, description: 'CEDRO DO ABAETE' },
    { id: 3115706, description: 'CENTRAL DE MINAS' },
    { id: 3115805, description: 'CENTRALINA' },
    { id: 3115904, description: 'CHACARA' },
    { id: 3116001, description: 'CHALE' },
    { id: 3116100, description: 'CHAPADA DO NORTE' },
    { id: 3116159, description: 'CHAPADA GAUCHA' },
    { id: 3116209, description: 'CHIADOR' },
    { id: 3116308, description: 'CIPOTANEA' },
    { id: 3116407, description: 'CLARAVAL' },
    { id: 3116506, description: 'CLARO DOS POCOES' },
    { id: 3116605, description: 'CLAUDIO' },
    { id: 3116704, description: 'COIMBRA' },
    { id: 3116803, description: 'COLUNA' },
    { id: 3116902, description: 'COMENDADOR GOMES' },
    { id: 3117009, description: 'COMERCINHO' },
    { id: 3117108, description: 'CONCEICAO DA APARECIDA' },
    { id: 3117207, description: 'CONCEICAO DAS PEDRAS' },
    { id: 3117306, description: 'CONCEICAO DAS ALAGOAS' },
    { id: 3117405, description: 'CONCEICAO DE IPANEMA' },
    { id: 3117504, description: 'CONCEICAO DO MATO DENTRO' },
    { id: 3117603, description: 'CONCEICAO DO PARA' },
    { id: 3117702, description: 'CONCEICAO DO RIO VERDE' },
    { id: 3117801, description: 'CONCEICAO DOS OUROS' },
    { id: 3117836, description: 'CONEGO MARINHO' },
    { id: 3117876, description: 'CONFINS' },
    { id: 3117900, description: 'CONGONHAL' },
    { id: 3118007, description: 'CONGONHAS' },
    { id: 3118106, description: 'CONGONHAS DO NORTE' },
    { id: 3118205, description: 'CONQUISTA' },
    { id: 3118304, description: 'CONSELHEIRO LAFAIETE' },
    { id: 3118403, description: 'CONSELHEIRO PENA' },
    { id: 3118502, description: 'CONSOLACAO' },
    { id: 3118601, description: 'CONTAGEM' },
    { id: 3118700, description: 'COQUEIRAL' },
    { id: 3118809, description: 'CORACAO DE JESUS' },
    { id: 3118908, description: 'CORDISBURGO' },
    { id: 3119005, description: 'CORDISLANDIA' },
    { id: 3119104, description: 'CORINTO' },
    { id: 3119203, description: 'COROACI' },
    { id: 3119302, description: 'COROMANDEL' },
    { id: 3119401, description: 'CORONEL FABRICIANO' },
    { id: 3119500, description: 'CORONEL MURTA' },
    { id: 3119609, description: 'CORONEL PACHECO' },
    { id: 3119708, description: 'CORONEL XAVIER CHAVES' },
    { id: 3119807, description: 'CORREGO DANTA' },
    { id: 3119906, description: 'CORREGO DO BOM JESUS' },
    { id: 3119955, description: 'CORREGO FUNDO' },
    { id: 3120003, description: 'CORREGO NOVO' },
    { id: 3120102, description: 'COUTO DE MAGALHAES DE MINAS' },
    { id: 3120151, description: 'CRISOLITA' },
    { id: 3120201, description: 'CRISTAIS' },
    { id: 3120300, description: 'CRISTALIA' },
    { id: 3120409, description: 'CRISTIANO OTONI' },
    { id: 3120508, description: 'CRISTINA' },
    { id: 3120607, description: 'CRUCILANDIA' },
    { id: 3120706, description: 'CRUZEIRO DA FORTALEZA' },
    { id: 3120805, description: 'CRUZILIA' },
    { id: 3120839, description: 'CUPARAQUE' },
    { id: 3120870, description: 'CURRAL DE DENTRO' },
    { id: 3120904, description: 'CURVELO' },
    { id: 3121001, description: 'DATAS' },
    { id: 3121100, description: 'DELFIM MOREIRA' },
    { id: 3121209, description: 'DELFINOPOLIS' },
    { id: 3121258, description: 'DELTA' },
    { id: 3121308, description: 'DESCOBERTO' },
    { id: 3121407, description: 'DESTERRO DE ENTRE RIOS' },
    { id: 3121506, description: 'DESTERRO DO MELO' },
    { id: 3121605, description: 'DIAMANTINA' },
    { id: 3121704, description: 'DIOGO DE VASCONCELOS' },
    { id: 3121803, description: 'DIONISIO' },
    { id: 3121902, description: 'DIVINESIA' },
    { id: 3122009, description: 'DIVINO' },
    { id: 3122108, description: 'DIVINO DAS LARANJEIRAS' },
    { id: 3122207, description: 'DIVINOLANDIA DE MINAS' },
    { id: 3122306, description: 'DIVINOPOLIS' },
    { id: 312230610, description: 'SANTO ANTONIO DOS CAMPOS' },
    { id: 3122355, description: 'DIVISA ALEGRE' },
    { id: 3122405, description: 'DIVISA NOVA' },
    { id: 3122454, description: 'DIVISOPOLIS' },
    { id: 3122470, description: 'DOM BOSCO' },
    { id: 3122504, description: 'DOM CAVATI' },
    { id: 3122603, description: 'DOM JOAQUIM' },
    { id: 3122702, description: 'DOM SILVERIO' },
    { id: 3122801, description: 'DOM VICOSO' },
    { id: 3122900, description: 'DONA EUZEBIA' },
    { id: 3123007, description: 'DORES DE CAMPOS' },
    { id: 3123106, description: 'DORES DE GUANHAES' },
    { id: 3123205, description: 'DORES DO INDAIA' },
    { id: 3123304, description: 'DORES DO TURVO' },
    { id: 3123403, description: 'DORESOPOLIS' },
    { id: 3123502, description: 'DOURADOQUARA' },
    { id: 3123528, description: 'DURANDE' },
    { id: 3123601, description: 'ELOI MENDES' },
    { id: 3123700, description: 'ENGENHEIRO CALDAS' },
    { id: 3123809, description: 'ENGENHEIRO NAVARRO' },
    { id: 3123858, description: 'ENTRE FOLHAS' },
    { id: 3123908, description: 'ENTRE RIOS DE MINAS' },
    { id: 3124005, description: 'ERVALIA' },
    { id: 3124104, description: 'ESMERALDAS' },
    { id: 3124203, description: 'ESPERA FELIZ' },
    { id: 3124302, description: 'ESPINOSA' },
    { id: 3124401, description: 'ESPIRITO SANTO DO DOURADO' },
    { id: 3124500, description: 'ESTIVA' },
    { id: 3124609, description: 'ESTRELA DALVA' },
    { id: 3124708, description: 'ESTRELA DO INDAIA' },
    { id: 3124807, description: 'ESTRELA DO SUL' },
    { id: 3124906, description: 'EUGENOPOLIS' },
    { id: 3125002, description: 'EWBANK DA CAMARA' },
    { id: 3125101, description: 'EXTREMA' },
    { id: 3125200, description: 'FAMA' },
    { id: 3125309, description: 'FARIA LEMOS' },
    { id: 3125408, description: 'FELICIO DOS SANTOS' },
    { id: 3125507, description: 'SAO GONCALO DO RIO PRETO' },
    { id: 3125606, description: 'FELISBURGO' },
    { id: 3125705, description: 'FELIXLANDIA' },
    { id: 3125804, description: 'FERNANDES TOURINHO' },
    { id: 3125903, description: 'FERROS' },
    { id: 3125952, description: 'FERVEDOURO' },
    { id: 3126000, description: 'FLORESTAL' },
    { id: 3126109, description: 'FORMIGA' },
    { id: 3126208, description: 'FORMOSO' },
    { id: 3126307, description: 'FORTALEZA DE MINAS' },
    { id: 3126406, description: 'FORTUNA DE MINAS' },
    { id: 3126505, description: 'FRANCISCO BADARO' },
    { id: 3126604, description: 'FRANCISCO DUMONT' },
    { id: 3126703, description: 'FRANCISCO SA' },
    { id: 3126752, description: 'FRANCISCOPOLIS' },
    { id: 3126802, description: 'FREI GASPAR' },
    { id: 3126901, description: 'FREI INOCENCIO' },
    { id: 3126950, description: 'FREI LAGONEGRO' },
    { id: 3127008, description: 'FRONTEIRA' },
    { id: 3127057, description: 'FRONTEIRA DOS VALES' },
    { id: 3127073, description: 'FRUTA DE LEITE' },
    { id: 3127107, description: 'FRUTAL' },
    { id: 3127206, description: 'FUNILANDIA' },
    { id: 3127305, description: 'GALILEIA' },
    { id: 3127339, description: 'GAMELEIRAS' },
    { id: 3127354, description: 'GLAUCILANDIA' },
    { id: 3127370, description: 'GOIABEIRA' },
    { id: 3127388, description: 'GOIANA' },
    { id: 3127404, description: 'GONCALVES' },
    { id: 3127503, description: 'GONZAGA' },
    { id: 3127602, description: 'GOUVEIA' },
    { id: 3127701, description: 'GOVERNADOR VALADARES' },
    { id: 3127800, description: 'GRAO MOGOL' },
    { id: 3127909, description: 'GRUPIARA' },
    { id: 3128006, description: 'GUANHAES' },
    { id: 3128105, description: 'GUAPE' },
    { id: 3128204, description: 'GUARACIABA' },
    { id: 3128253, description: 'GUARACIAMA' },
    { id: 3128303, description: 'GUARANESIA' },
    { id: 3128402, description: 'GUARANI' },
    { id: 3128501, description: 'GUARARA' },
    { id: 3128600, description: 'GUARDA MOR' },
    { id: 3128709, description: 'GUAXUPE' },
    { id: 3128808, description: 'GUIDOVAL' },
    { id: 3128907, description: 'GUIMARANIA' },
    { id: 3129004, description: 'GUIRICEMA' },
    { id: 3129103, description: 'GURINHATA' },
    { id: 3129202, description: 'HELIODORA' },
    { id: 3129301, description: 'IAPU' },
    { id: 3129400, description: 'IBERTIOGA' },
    { id: 3129509, description: 'IBIA' },
    { id: 3129608, description: 'IBIAI' },
    { id: 3129657, description: 'IBIRACATU' },
    { id: 3129707, description: 'IBIRACI' },
    { id: 3129806, description: 'IBIRITE' },
    { id: 3129905, description: 'IBITIURA DE MINAS' },
    { id: 3130002, description: 'IBITURUNA' },
    { id: 3130051, description: 'ICARAI DE MINAS' },
    { id: 3130101, description: 'IGARAPE' },
    { id: 3130200, description: 'IGARATINGA' },
    { id: 3130309, description: 'IGUATAMA' },
    { id: 3130408, description: 'IJACI' },
    { id: 3130507, description: 'ILICINEA' },
    { id: 3130556, description: 'IMBE DE MINAS' },
    { id: 3130606, description: 'INCONFIDENTES' },
    { id: 3130655, description: 'INDAIABIRA' },
    { id: 3130705, description: 'INDIANOPOLIS' },
    { id: 3130804, description: 'INGAI' },
    { id: 3130903, description: 'INHAPIM' },
    { id: 3131000, description: 'INHAUMA' },
    { id: 3131109, description: 'INIMUTABA' },
    { id: 3131158, description: 'IPABA' },
    { id: 3131208, description: 'IPANEMA' },
    { id: 3131307, description: 'IPATINGA' },
    { id: 3131406, description: 'IPIACU' },
    { id: 3131505, description: 'IPUIUNA' },
    { id: 3131604, description: 'IRAI DE MINAS' },
    { id: 3131703, description: 'ITABIRA' },
    { id: 3131802, description: 'ITABIRINHA' },
    { id: 3131901, description: 'ITABIRITO' },
    { id: 3132008, description: 'ITACAMBIRA' },
    { id: 3132107, description: 'ITACARAMBI' },
    { id: 3132206, description: 'ITAGUARA' },
    { id: 3132305, description: 'ITAIPE' },
    { id: 3132404, description: 'ITAJUBA' },
    { id: 3132503, description: 'ITAMARANDIBA' },
    { id: 3132602, description: 'ITAMARATI DE MINAS' },
    { id: 3132701, description: 'ITAMBACURI' },
    { id: 3132800, description: 'ITAMBE DO MATO DENTRO' },
    { id: 3132909, description: 'ITAMOGI' },
    { id: 3133006, description: 'ITAMONTE' },
    { id: 3133105, description: 'ITANHANDU' },
    { id: 3133204, description: 'ITANHOMI' },
    { id: 3133303, description: 'ITAOBIM' },
    { id: 3133402, description: 'ITAPAGIPE' },
    { id: 3133501, description: 'ITAPECERICA' },
    { id: 313350110, description: 'MARILANDIA' },
    { id: 3133600, description: 'ITAPEVA' },
    { id: 3133709, description: 'ITATIAIUCU' },
    { id: 3133758, description: 'ITAU DE MINAS' },
    { id: 3133808, description: 'ITAUNA' },
    { id: 3133907, description: 'ITAVERAVA' },
    { id: 3134004, description: 'ITINGA' },
    { id: 3134103, description: 'ITUETA' },
    { id: 3134202, description: 'ITUIUTABA' },
    { id: 3134301, description: 'ITUMIRIM' },
    { id: 3134400, description: 'ITURAMA' },
    { id: 3134509, description: 'ITUTINGA' },
    { id: 3134608, description: 'JABOTICATUBAS' },
    { id: 3134707, description: 'JACINTO' },
    { id: 3134806, description: 'JACUI' },
    { id: 3134905, description: 'JACUTINGA' },
    { id: 3135001, description: 'JAGUARACU' },
    { id: 3135050, description: 'JAIBA' },
    { id: 3135076, description: 'JAMPRUCA' },
    { id: 3135100, description: 'JANAUBA' },
    { id: 3135209, description: 'JANUARIA' },
    { id: 3135308, description: 'JAPARAIBA' },
    { id: 3135357, description: 'JAPONVAR' },
    { id: 3135407, description: 'JECEABA' },
    { id: 3135456, description: 'JENIPAPO DE MINAS' },
    { id: 3135506, description: 'JEQUERI' },
    { id: 3135605, description: 'JEQUITAI' },
    { id: 3135704, description: 'JEQUITIBA' },
    { id: 3135803, description: 'JEQUITINHONHA' },
    { id: 3135902, description: 'JESUANIA' },
    { id: 3136009, description: 'JOAIMA' },
    { id: 3136108, description: 'JOANESIA' },
    { id: 3136207, description: 'JOAO MONLEVADE' },
    { id: 3136306, description: 'JOAO PINHEIRO' },
    { id: 3136405, description: 'JOAQUIM FELICIO' },
    { id: 3136504, description: 'JORDANIA' },
    { id: 3136520, description: 'JOSE GONCALVES DE MINAS' },
    { id: 3136553, description: 'JOSE RAYDAN' },
    { id: 3136579, description: 'JOSENOPOLIS' },
    { id: 3136603, description: 'NOVA UNIAO' },
    { id: 3136652, description: 'JUATUBA' },
    { id: 3136702, description: 'JUIZ DE FORA' },
    { id: 3136801, description: 'JURAMENTO' },
    { id: 3136900, description: 'JURUAIA' },
    { id: 3136959, description: 'JUVENILIA' },
    { id: 3137007, description: 'LADAINHA' },
    { id: 3137106, description: 'LAGAMAR' },
    { id: 3137205, description: 'LAGOA DA PRATA' },
    { id: 3137304, description: 'LAGOA DOS PATOS' },
    { id: 3137403, description: 'LAGOA DOURADA' },
    { id: 3137502, description: 'LAGOA FORMOSA' },
    { id: 3137536, description: 'LAGOA GRANDE' },
    { id: 3137601, description: 'LAGOA SANTA' },
    { id: 3137700, description: 'LAJINHA' },
    { id: 3137809, description: 'LAMBARI' },
    { id: 3137908, description: 'LAMIM' },
    { id: 3138005, description: 'LARANJAL' },
    { id: 3138104, description: 'LASSANCE' },
    { id: 3138203, description: 'LAVRAS' },
    { id: 3138302, description: 'LEANDRO FERREIRA' },
    { id: 3138351, description: 'LEME DO PRADO' },
    { id: 3138401, description: 'LEOPOLDINA' },
    { id: 3138500, description: 'LIBERDADE' },
    { id: 3138609, description: 'LIMA DUARTE' },
    { id: 3138625, description: 'LIMEIRA DO OESTE' },
    { id: 3138658, description: 'LONTRA' },
    { id: 3138674, description: 'LUISBURGO' },
    { id: 3138682, description: 'LUISLANDIA' },
    { id: 3138708, description: 'LUMINARIAS' },
    { id: 3138807, description: 'LUZ' },
    { id: 3138906, description: 'MACHACALIS' },
    { id: 3139003, description: 'MACHADO' },
    { id: 3139102, description: 'MADRE DE DEUS DE MINAS' },
    { id: 3139201, description: 'MALACACHETA' },
    { id: 3139250, description: 'MAMONAS' },
    { id: 3139300, description: 'MANGA' },
    { id: 3139409, description: 'MANHUACU' },
    { id: 3139508, description: 'MANHUMIRIM' },
    { id: 3139607, description: 'MANTENA' },
    { id: 3139706, description: 'MARAVILHAS' },
    { id: 3139805, description: 'MAR DE ESPANHA' },
    { id: 3139904, description: 'MARIA DA FE' },
    { id: 3140001, description: 'MARIANA' },
    { id: 314000110, description: 'PASSAGEM DE MARIANA' },
    { id: 3140100, description: 'MARILAC' },
    { id: 3140159, description: 'MARIO CAMPOS' },
    { id: 3140209, description: 'MARIPA DE MINAS' },
    { id: 3140308, description: 'MARLIERIA' },
    { id: 3140407, description: 'MARMELOPOLIS' },
    { id: 3140506, description: 'MARTINHO CAMPOS' },
    { id: 3140530, description: 'MARTINS SOARES' },
    { id: 3140555, description: 'MATA VERDE' },
    { id: 3140605, description: 'MATERLANDIA' },
    { id: 3140704, description: 'MATEUS LEME' },
    { id: 3140803, description: 'MATIAS BARBOSA' },
    { id: 3140852, description: 'MATIAS CARDOSO' },
    { id: 3140902, description: 'MATIPO' },
    { id: 3141009, description: 'MATO VERDE' },
    { id: 3141108, description: 'MATOZINHOS' },
    { id: 314110810, description: 'MOCAMBEIRO' },
    { id: 3141207, description: 'MATUTINA' },
    { id: 3141306, description: 'MEDEIROS' },
    { id: 3141405, description: 'MEDINA' },
    { id: 3141504, description: 'MENDES PIMENTEL' },
    { id: 3141603, description: 'MERCES' },
    { id: 3141702, description: 'MESQUITA' },
    { id: 3141801, description: 'MINAS NOVAS' },
    { id: 3141900, description: 'MINDURI' },
    { id: 3142007, description: 'MIRABELA' },
    { id: 3142106, description: 'MIRADOURO' },
    { id: 3142205, description: 'MIRAI' },
    { id: 3142254, description: 'MIRAVANIA' },
    { id: 3142304, description: 'MOEDA' },
    { id: 3142403, description: 'MOEMA' },
    { id: 3142502, description: 'MONJOLOS' },
    { id: 3142601, description: 'MONSENHOR PAULO' },
    { id: 3142700, description: 'MONTALVANIA' },
    { id: 3142809, description: 'MONTE ALEGRE DE MINAS' },
    { id: 3142908, description: 'MONTE AZUL' },
    { id: 3143005, description: 'MONTE BELO' },
    { id: 3143104, description: 'MONTE CARMELO' },
    { id: 3143153, description: 'MONTE FORMOSO' },
    { id: 3143203, description: 'MONTE SANTO DE MINAS' },
    { id: 3143302, description: 'MONTES CLAROS' },
    { id: 3143401, description: 'MONTE SIAO' },
    { id: 3143450, description: 'MONTEZUMA' },
    { id: 3143500, description: 'MORADA NOVA DE MINAS' },
    { id: 3143609, description: 'MORRO DA GARCA' },
    { id: 3143708, description: 'MORRO DO PILAR' },
    { id: 3143807, description: 'MUNHOZ' },
    { id: 3143906, description: 'MURIAE' },
    { id: 3144003, description: 'MUTUM' },
    { id: 3144102, description: 'MUZAMBINHO' },
    { id: 3144201, description: 'NACIP RAYDAN' },
    { id: 3144300, description: 'NANUQUE' },
    { id: 3144359, description: 'NAQUE' },
    { id: 3144375, description: 'NATALANDIA' },
    { id: 3144409, description: 'NATERCIA' },
    { id: 3144508, description: 'NAZARENO' },
    { id: 3144607, description: 'NEPOMUCENO' },
    { id: 3144656, description: 'NINHEIRA' },
    { id: 3144672, description: 'NOVA BELEM' },
    { id: 3144706, description: 'NOVA ERA' },
    { id: 3144805, description: 'NOVA LIMA' },
    { id: 3144904, description: 'NOVA MODICA' },
    { id: 3145000, description: 'NOVA PONTE' },
    { id: 3145059, description: 'NOVA PORTEIRINHA' },
    { id: 3145109, description: 'NOVA RESENDE' },
    { id: 3145208, description: 'NOVA SERRANA' },
    { id: 3145307, description: 'NOVO CRUZEIRO' },
    { id: 3145356, description: 'NOVO ORIENTE DE MINAS' },
    { id: 3145372, description: 'NOVORIZONTE' },
    { id: 3145406, description: 'OLARIA' },
    { id: 3145455, description: 'OLHOS D AGUA' },
    { id: 3145505, description: 'OLIMPIO NORONHA' },
    { id: 3145604, description: 'OLIVEIRA' },
    { id: 3145703, description: 'OLIVEIRA FORTES' },
    { id: 3145802, description: 'ONCA DE PITANGUI' },
    { id: 3145851, description: 'ORATORIOS' },
    { id: 3145877, description: 'ORIZANIA' },
    { id: 3145901, description: 'OURO BRANCO' },
    { id: 3146008, description: 'OURO FINO' },
    { id: 3146107, description: 'OURO PRETO' },
    { id: 314610710, description: 'CACHOEIRA DO CAMPO' },
    { id: 3146206, description: 'OURO VERDE DE MINAS' },
    { id: 3146255, description: 'PADRE CARVALHO' },
    { id: 3146305, description: 'PADRE PARAISO' },
    { id: 3146404, description: 'PAINEIRAS' },
    { id: 3146503, description: 'PAINS' },
    { id: 3146552, description: 'PAI PEDRO' },
    { id: 3146602, description: 'PAIVA' },
    { id: 3146701, description: 'PALMA' },
    { id: 3146750, description: 'PALMOPOLIS' },
    { id: 3146909, description: 'PAPAGAIOS' },
    { id: 3147006, description: 'PARACATU' },
    { id: 3147105, description: 'PARA DE MINAS' },
    { id: 3147204, description: 'PARAGUACU' },
    { id: 3147303, description: 'PARAISOPOLIS' },
    { id: 3147402, description: 'PARAOPEBA' },
    { id: 3147501, description: 'PASSABEM' },
    { id: 3147600, description: 'PASSA QUATRO' },
    { id: 3147709, description: 'PASSA TEMPO' },
    { id: 3147808, description: 'PASSA VINTE' },
    { id: 3147907, description: 'PASSOS' },
    { id: 3147956, description: 'PATIS' },
    { id: 3148004, description: 'PATOS DE MINAS' },
    { id: 3148103, description: 'PATROCINIO' },
    { id: 3148202, description: 'PATROCINIO DO MURIAE' },
    { id: 3148301, description: 'PAULA CANDIDO' },
    { id: 3148400, description: 'PAULISTAS' },
    { id: 3148509, description: 'PAVAO' },
    { id: 3148608, description: 'PECANHA' },
    { id: 3148707, description: 'PEDRA AZUL' },
    { id: 3148756, description: 'PEDRA BONITA' },
    { id: 3148806, description: 'PEDRA DO ANTA' },
    { id: 3148905, description: 'PEDRA DO INDAIA' },
    { id: 3149002, description: 'PEDRA DOURADA' },
    { id: 3149101, description: 'PEDRALVA' },
    { id: 3149150, description: 'PEDRAS DE MARIA DA CRUZ' },
    { id: 3149200, description: 'PEDRINOPOLIS' },
    { id: 3149309, description: 'PEDRO LEOPOLDO' },
    { id: 3149408, description: 'PEDRO TEIXEIRA' },
    { id: 3149507, description: 'PEQUERI' },
    { id: 3149606, description: 'PEQUI' },
    { id: 3149705, description: 'PERDIGAO' },
    { id: 3149804, description: 'PERDIZES' },
    { id: 3149903, description: 'PERDOES' },
    { id: 3149952, description: 'PERIQUITO' },
    { id: 3150000, description: 'PESCADOR' },
    { id: 3150109, description: 'PIAU' },
    { id: 3150158, description: 'PIEDADE DE CARATINGA' },
    { id: 3150208, description: 'PIEDADE DE PONTE NOVA' },
    { id: 3150307, description: 'PIEDADE DO RIO GRANDE' },
    { id: 3150406, description: 'PIEDADE DOS GERAIS' },
    { id: 3150505, description: 'PIMENTA' },
    { id: 3150539, description: 'PINGO D AGUA' },
    { id: 3150570, description: 'PINTOPOLIS' },
    { id: 3150604, description: 'PIRACEMA' },
    { id: 3150703, description: 'PIRAJUBA' },
    { id: 3150802, description: 'PIRANGA' },
    { id: 3150901, description: 'PIRANGUCU' },
    { id: 3151008, description: 'PIRANGUINHO' },
    { id: 3151107, description: 'PIRAPETINGA' },
    { id: 3151206, description: 'PIRAPORA' },
    { id: 3151305, description: 'PIRAUBA' },
    { id: 3151404, description: 'PITANGUI' },
    { id: 3151503, description: 'PIUMHI' },
    { id: 3151602, description: 'PLANURA' },
    { id: 3151701, description: 'POCO FUNDO' },
    { id: 3151800, description: 'POCOS DE CALDAS' },
    { id: 3151909, description: 'POCRANE' },
    { id: 3152006, description: 'POMPEU' },
    { id: 3152105, description: 'PONTE NOVA' },
    { id: 3152131, description: 'PONTO CHIQUE' },
    { id: 3152170, description: 'PONTO DOS VOLANTES' },
    { id: 3152204, description: 'PORTEIRINHA' },
    { id: 3152303, description: 'PORTO FIRME' },
    { id: 3152402, description: 'POTE' },
    { id: 3152501, description: 'POUSO ALEGRE' },
    { id: 3152600, description: 'POUSO ALTO' },
    { id: 3152709, description: 'PRADOS' },
    { id: 3152808, description: 'PRATA' },
    { id: 3152907, description: 'PRATAPOLIS' },
    { id: 3153004, description: 'PRATINHA' },
    { id: 3153103, description: 'PRESIDENTE BERNARDES' },
    { id: 3153301, description: 'PRESIDENTE KUBITSCHEK' },
    { id: 3153400, description: 'PRESIDENTE OLEGARIO' },
    { id: 3153509, description: 'ALTO JEQUITIBA' },
    { id: 3153608, description: 'PRUDENTE DE MORAIS' },
    { id: 3153707, description: 'QUARTEL GERAL' },
    { id: 3153806, description: 'QUELUZITO' },
    { id: 3153905, description: 'RAPOSOS' },
    { id: 3154002, description: 'RAUL SOARES' },
    { id: 3154101, description: 'RECREIO' },
    { id: 3154150, description: 'REDUTO' },
    { id: 3154200, description: 'RESENDE COSTA' },
    { id: 3154309, description: 'RESPLENDOR' },
    { id: 3154408, description: 'RESSAQUINHA' },
    { id: 3154457, description: 'RIACHINHO' },
    { id: 3154507, description: 'RIACHO DOS MACHADOS' },
    { id: 3154606, description: 'RIBEIRAO DAS NEVES' },
    { id: 3154705, description: 'RIBEIRAO VERMELHO' },
    { id: 3154804, description: 'RIO ACIMA' },
    { id: 3154903, description: 'RIO CASCA' },
    { id: 3155009, description: 'RIO DOCE' },
    { id: 3155108, description: 'RIO DO PRADO' },
    { id: 3155207, description: 'RIO ESPERA' },
    { id: 3155306, description: 'RIO MANSO' },
    { id: 3155405, description: 'RIO NOVO' },
    { id: 3155504, description: 'RIO PARANAIBA' },
    { id: 3155603, description: 'RIO PARDO DE MINAS' },
    { id: 3155702, description: 'RIO PIRACICABA' },
    { id: 3155801, description: 'RIO POMBA' },
    { id: 3155900, description: 'RIO PRETO' },
    { id: 3156007, description: 'RIO VERMELHO' },
    { id: 3156106, description: 'RITAPOLIS' },
    { id: 3156205, description: 'ROCHEDO DE MINAS' },
    { id: 3156304, description: 'RODEIRO' },
    { id: 3156403, description: 'ROMARIA' },
    { id: 3156452, description: 'ROSARIO DA LIMEIRA' },
    { id: 3156502, description: 'RUBELITA' },
    { id: 3156601, description: 'RUBIM' },
    { id: 3156700, description: 'SABARA' },
    { id: 3156809, description: 'SABINOPOLIS' },
    { id: 3156908, description: 'SACRAMENTO' },
    { id: 3157005, description: 'SALINAS' },
    { id: 3157104, description: 'SALTO DA DIVISA' },
    { id: 3157203, description: 'SANTA BARBARA' },
    { id: 3157252, description: 'SANTA BARBARA DO LESTE' },
    { id: 3157278, description: 'SANTA BARBARA DO MONTE VERDE' },
    { id: 3157302, description: 'SANTA BARBARA DO TUGURIO' },
    { id: 3157336, description: 'SANTA CRUZ DE MINAS' },
    { id: 3157377, description: 'SANTA CRUZ DE SALINAS' },
    { id: 3157401, description: 'SANTA CRUZ DO ESCALVADO' },
    { id: 3157500, description: 'SANTA EFIGENIA DE MINAS' },
    { id: 3157609, description: 'SANTA FE DE MINAS' },
    { id: 3157658, description: 'SANTA HELENA DE MINAS' },
    { id: 3157708, description: 'SANTA JULIANA' },
    { id: 3157807, description: 'SANTA LUZIA' },
    { id: 3157906, description: 'SANTA MARGARIDA' },
    { id: 3158003, description: 'SANTA MARIA DE ITABIRA' },
    { id: 3158102, description: 'SANTA MARIA DO SALTO' },
    { id: 3158201, description: 'SANTA MARIA DO SUACUI' },
    { id: 3158300, description: 'SANTANA DA VARGEM' },
    { id: 3158409, description: 'SANTANA DE CATAGUASES' },
    { id: 3158508, description: 'SANTANA DE PIRAPAMA' },
    { id: 3158607, description: 'SANTANA DO DESERTO' },
    { id: 3158706, description: 'SANTANA DO GARAMBEU' },
    { id: 3158805, description: 'SANTANA DO JACARE' },
    { id: 3158904, description: 'SANTANA DO MANHUACU' },
    { id: 3158953, description: 'SANTANA DO PARAISO' },
    { id: 3159001, description: 'SANTANA DO RIACHO' },
    { id: 3159100, description: 'SANTANA DOS MONTES' },
    { id: 3159209, description: 'SANTA RITA DE CALDAS' },
    { id: 3159308, description: 'SANTA RITA DE JACUTINGA' },
    { id: 3159357, description: 'SANTA RITA DE MINAS' },
    { id: 3159407, description: 'SANTA RITA DO IBITIPOCA' },
    { id: 3159506, description: 'SANTA RITA DO ITUETO' },
    { id: 3159605, description: 'SANTA RITA DO SAPUCAI' },
    { id: 3159704, description: 'SANTA ROSA DA SERRA' },
    { id: 3159803, description: 'SANTA VITORIA' },
    { id: 3159902, description: 'SANTO ANTONIO DO AMPARO' },
    { id: 3160009, description: 'SANTO ANTONIO DO AVENTUREIRO' },
    { id: 3160108, description: 'SANTO ANTONIO DO GRAMA' },
    { id: 3160207, description: 'SANTO ANTONIO DO ITAMBE' },
    { id: 3160306, description: 'SANTO ANTONIO DO JACINTO' },
    { id: 3160405, description: 'SANTO ANTONIO DO MONTE' },
    { id: 3160454, description: 'SANTO ANTONIO DO RETIRO' },
    { id: 3160504, description: 'SANTO ANTONIO DO RIO ABAIXO' },
    { id: 3160603, description: 'SANTO HIPOLITO' },
    { id: 3160702, description: 'SANTOS DUMONT' },
    { id: 3160801, description: 'SAO BENTO ABADE' },
    { id: 3160900, description: 'SAO BRAS DO SUACUI' },
    { id: 3160959, description: 'SAO DOMINGOS DAS DORES' },
    { id: 3161007, description: 'SAO DOMINGOS DO PRATA' },
    { id: 3161056, description: 'SAO FELIX DE MINAS' },
    { id: 3161106, description: 'SAO FRANCISCO' },
    { id: 3161205, description: 'SAO FRANCISCO DE PAULA' },
    { id: 3161304, description: 'SAO FRANCISCO DE SALES' },
    { id: 3161403, description: 'SAO FRANCISCO DO GLORIA' },
    { id: 3161502, description: 'SAO GERALDO' },
    { id: 3161601, description: 'SAO GERALDO DA PIEDADE' },
    { id: 3161650, description: 'SAO GERALDO DO BAIXIO' },
    { id: 3161700, description: 'SAO GONCALO DO ABAETE' },
    { id: 3161809, description: 'SAO GONCALO DO PARA' },
    { id: 3161908, description: 'SAO GONCALO DO RIO ABAIXO' },
    { id: 3162005, description: 'SAO GONCALO DO SAPUCAI' },
    { id: 3162104, description: 'SAO GOTARDO' },
    { id: 3162203, description: 'SAO JOAO BATISTA DO GLORIA' },
    { id: 3162252, description: 'SAO JOAO DA LAGOA' },
    { id: 3162302, description: 'SAO JOAO DA MATA' },
    { id: 3162401, description: 'SAO JOAO DA PONTE' },
    { id: 3162450, description: 'SAO JOAO DAS MISSOES' },
    { id: 3162500, description: 'SAO JOAO DEL REI' },
    { id: 3162559, description: 'SAO JOAO DO MANHUACU' },
    { id: 3162575, description: 'SAO JOAO DO MANTENINHA' },
    { id: 3162609, description: 'SAO JOAO DO ORIENTE' },
    { id: 3162658, description: 'SAO JOAO DO PACUI' },
    { id: 3162708, description: 'SAO JOAO DO PARAISO' },
    { id: 3162807, description: 'SAO JOAO EVANGELISTA' },
    { id: 3162906, description: 'SAO JOAO NEPOMUCENO' },
    { id: 3162922, description: 'SAO JOAQUIM DE BICAS' },
    { id: 3162948, description: 'SAO JOSE DA BARRA' },
    { id: 3162955, description: 'SAO JOSE DA LAPA' },
    { id: 3163003, description: 'SAO JOSE DA SAFIRA' },
    { id: 3163102, description: 'SAO JOSE DA VARGINHA' },
    { id: 3163201, description: 'SAO JOSE DO ALEGRE' },
    { id: 3163300, description: 'SAO JOSE DO DIVINO' },
    { id: 3163409, description: 'SAO JOSE DO GOIABAL' },
    { id: 3163508, description: 'SAO JOSE DO JACURI' },
    { id: 3163607, description: 'SAO JOSE DO MANTIMENTO' },
    { id: 3163706, description: 'SAO LOURENCO' },
    { id: 3163805, description: 'SAO MIGUEL DO ANTA' },
    { id: 3163904, description: 'SAO PEDRO DA UNIAO' },
    { id: 3164001, description: 'SAO PEDRO DOS FERROS' },

    { id: 3164100, description: 'SAO PEDRO DO SUACUI' },
    { id: 3164209, description: 'SAO ROMAO' },
    { id: 3164308, description: 'SAO ROQUE DE MINAS' },
    { id: 3164407, description: 'SAO SEBASTIAO DA BELA VISTA' },
    { id: 3164431, description: 'SAO SEBASTIAO DA VARGEM ALEGRE' },
    { id: 3164472, description: 'SAO SEBASTIAO DO ANTA' },
    { id: 3164506, description: 'SAO SEBASTIAO DO MARANHAO' },
    { id: 3164605, description: 'SAO SEBASTIAO DO OESTE' },
    { id: 3164704, description: 'SAO SEBASTIAO DO PARAISO' },
    { id: 3164803, description: 'SAO SEBASTIAO DO RIO PRETO' },
    { id: 3164902, description: 'SAO SEBASTIAO DO RIO VERDE' },
    { id: 3165008, description: 'SAO TIAGO' },
    { id: 3165107, description: 'SAO TOMAS DE AQUINO' },
    { id: 3165206, description: 'SAO THOME DAS LETRAS' },
    { id: 3165305, description: 'SAO VICENTE DE MINAS' },
    { id: 3165404, description: 'SAPUCAI MIRIM' },
    { id: 3165503, description: 'SARDOA' },
    { id: 3165537, description: 'SARZEDO' },
    { id: 3165552, description: 'SETUBINHA' },
    { id: 3165560, description: 'SEM PEIXE' },
    { id: 3165578, description: 'SENADOR AMARAL' },
    { id: 3165602, description: 'SENADOR CORTES' },
    { id: 3165701, description: 'SENADOR FIRMINO' },
    { id: 3165800, description: 'SENADOR JOSE BENTO' },
    { id: 3165909, description: 'SENADOR MODESTINO GONCALVES' },
    { id: 3166006, description: 'SENHORA DE OLIVEIRA' },
    { id: 3166105, description: 'SENHORA DO PORTO' },
    { id: 3166204, description: 'SENHORA DOS REMEDIOS' },
    { id: 3166303, description: 'SERICITA' },
    { id: 3166402, description: 'SERITINGA' },
    { id: 3166501, description: 'SERRA AZUL DE MINAS' },
    { id: 3166600, description: 'SERRA DA SAUDADE' },
    { id: 3166709, description: 'SERRA DOS AIMORES' },
    { id: 3166808, description: 'SERRA DO SALITRE' },
    { id: 3166907, description: 'SERRANIA' },
    { id: 3166956, description: 'SERRANOPOLIS DE MINAS' },
    { id: 3167004, description: 'SERRANOS' },
    { id: 3167103, description: 'SERRO' },
    { id: 3167202, description: 'SETE LAGOAS' },
    { id: 3167301, description: 'SILVEIRANIA' },
    { id: 3167400, description: 'SILVIANOPOLIS' },
    { id: 3167509, description: 'SIMAO PEREIRA' },
    { id: 3167608, description: 'SIMONESIA' },
    { id: 3167707, description: 'SOBRALIA' },
    { id: 3167806, description: 'SOLEDADE DE MINAS' },
    { id: 3167905, description: 'TABULEIRO' },
    { id: 3168002, description: 'TAIOBEIRAS' },
    { id: 3168051, description: 'TAPARUBA' },
    { id: 3168101, description: 'TAPIRA' },
    { id: 3168200, description: 'TAPIRAI' },
    { id: 3168309, description: 'TAQUARACU DE MINAS' },
    { id: 3168408, description: 'TARUMIRIM' },
    { id: 3168507, description: 'TEIXEIRAS' },
    { id: 3168606, description: 'TEOFILO OTONI' },
    { id: 3168705, description: 'TIMOTEO' },
    { id: 3168804, description: 'TIRADENTES' },
    { id: 3168903, description: 'TIROS' },
    { id: 3169000, description: 'TOCANTINS' },
    { id: 3169059, description: 'TOCOS DO MOJI' },
    { id: 3169109, description: 'TOLEDO' },
    { id: 3169208, description: 'TOMBOS' },
    { id: 3169307, description: 'TRES CORACOES' },
    { id: 3169356, description: 'TRES MARIAS' },
    { id: 3169406, description: 'TRES PONTAS' },
    { id: 3169505, description: 'TUMIRITINGA' },
    { id: 3169604, description: 'TUPACIGUARA' },
    { id: 3169703, description: 'TURMALINA' },
    { id: 3169802, description: 'TURVOLANDIA' },
    { id: 3169901, description: 'UBA' },
    { id: 3170008, description: 'UBAI' },
    { id: 3170057, description: 'UBAPORANGA' },
    { id: 3170107, description: 'UBERABA' },
    { id: 317010710, description: 'PONTE ALTA' },
    { id: 3170206, description: 'UBERLANDIA' },
    { id: 3170305, description: 'UMBURATIBA' },
    { id: 3170404, description: 'UNAI' },
    { id: 3170438, description: 'UNIAO DE MINAS' },
    { id: 3170479, description: 'URUANA DE MINAS' },
    { id: 3170503, description: 'URUCANIA' },
    { id: 3170529, description: 'URUCUIA' },
    { id: 3170578, description: 'VARGEM ALEGRE' },
    { id: 3170602, description: 'VARGEM BONITA' },
    { id: 3170651, description: 'VARGEM GRANDE DO RIO PARDO' },
    { id: 3170701, description: 'VARGINHA' },
    { id: 3170750, description: 'VARJAO DE MINAS' },
    { id: 3170800, description: 'VARZEA DA PALMA' },
    { id: 3170909, description: 'VARZELANDIA' },
    { id: 3171006, description: 'VAZANTE' },
    { id: 3171030, description: 'VERDELANDIA' },
    { id: 3171071, description: 'VEREDINHA' },
    { id: 3171105, description: 'VERISSIMO' },
    { id: 3171154, description: 'VERMELHO NOVO' },
    { id: 3171204, description: 'VESPASIANO' },
    { id: 3171303, description: 'VICOSA' },
    { id: 3171402, description: 'VIEIRAS' },
    { id: 3171501, description: 'MATHIAS LOBATO' },
    { id: 3171600, description: 'VIRGEM DA LAPA' },
    { id: 3171709, description: 'VIRGINIA' },
    { id: 3171808, description: 'VIRGINOPOLIS' },
    { id: 3171907, description: 'VIRGOLANDIA' },
    { id: 3172004, description: 'VISCONDE DO RIO BRANCO' },
    { id: 3172103, description: 'VOLTA GRANDE' },
    { id: 3172202, description: 'WENCESLAU BRAZ' },
    { id: 3200102, description: 'AFONSO CLAUDIO' },
    { id: 3200136, description: 'AGUIA BRANCA' },
    { id: 3200169, description: 'AGUA DOCE DO NORTE' },
    { id: 3200201, description: 'ALEGRE' },
    { id: 3200300, description: 'ALFREDO CHAVES' },
    { id: 3200359, description: 'ALTO RIO NOVO' },
    { id: 3200409, description: 'ANCHIETA' },
    { id: 3200508, description: 'APIACA' },
    { id: 3200607, description: 'ARACRUZ' },
    { id: 3200706, description: 'ATILIO VIVACQUA' },
    { id: 3200805, description: 'BAIXO GUANDU' },
    { id: 3200904, description: 'BARRA DE SAO FRANCISCO' },
    { id: 3201001, description: 'BOA ESPERANCA' },
    { id: 3201100, description: 'BOM JESUS DO NORTE' },
    { id: 3201159, description: 'BREJETUBA' },
    { id: 3201209, description: 'CACHOEIRO DE ITAPEMIRIM' },
    { id: 3201308, description: 'CARIACICA' },
    { id: 3201407, description: 'CASTELO' },
    { id: 3201506, description: 'COLATINA' },
    { id: 3201605, description: 'CONCEICAO DA BARRA' },
    { id: 3201704, description: 'CONCEICAO DO CASTELO' },
    { id: 3201803, description: 'DIVINO DE SAO LOURENCO' },
    { id: 3201902, description: 'DOMINGOS MARTINS' },
    { id: 3202009, description: 'DORES DO RIO PRETO' },
    { id: 3202108, description: 'ECOPORANGA' },
    { id: 3202207, description: 'FUNDAO' },
    { id: 3202256, description: 'GOVERNADOR LINDENBERG' },
    { id: 3202306, description: 'GUACUI' },
    { id: 3202405, description: 'GUARAPARI' },
    { id: 3202454, description: 'IBATIBA' },
    { id: 3202504, description: 'IBIRACU' },
    { id: 3202553, description: 'IBITIRAMA' },
    { id: 3202603, description: 'ICONHA' },
    { id: 3202652, description: 'IRUPI' },
    { id: 3202702, description: 'ITAGUACU' },
    { id: 3202801, description: 'ITAPEMIRIM' },
    { id: 3202900, description: 'ITARANA' },
    { id: 3203007, description: 'IUNA' },
    { id: 3203056, description: 'JAGUARE' },
    { id: 3203106, description: 'JERONIMO MONTEIRO' },
    { id: 3203130, description: 'JOAO NEIVA' },
    { id: 3203163, description: 'LARANJA DA TERRA' },
    { id: 3203205, description: 'LINHARES' },
    { id: 3203304, description: 'MANTENOPOLIS' },
    { id: 3203320, description: 'MARATAIZES' },
    { id: 3203346, description: 'MARECHAL FLORIANO' },
    { id: 3203353, description: 'MARILANDIA' },
    { id: 3203403, description: 'MIMOSO DO SUL' },
    { id: 3203502, description: 'MONTANHA' },
    { id: 3203601, description: 'MUCURICI' },
    { id: 3203700, description: 'MUNIZ FREIRE' },
    { id: 3203809, description: 'MUQUI' },
    { id: 3203908, description: 'NOVA VENECIA' },
    { id: 3204005, description: 'PANCAS' },
    { id: 3204054, description: 'PEDRO CANARIO' },
    { id: 3204104, description: 'PINHEIROS' },
    { id: 3204203, description: 'PIUMA' },
    { id: 3204252, description: 'PONTO BELO' },
    { id: 3204302, description: 'PRESIDENTE KENNEDY' },
    { id: 3204351, description: 'RIO BANANAL' },
    { id: 3204401, description: 'RIO NOVO DO SUL' },
    { id: 3204500, description: 'SANTA LEOPOLDINA' },
    { id: 3204559, description: 'SANTA MARIA DE JETIBA' },
    { id: 3204609, description: 'SANTA TERESA' },
    { id: 3204658, description: 'SAO DOMINGOS DO NORTE' },
    { id: 3204708, description: 'SAO GABRIEL DA PALHA' },
    { id: 3204807, description: 'SAO JOSE DO CALCADO' },
    { id: 3204906, description: 'SAO MATEUS' },
    { id: 3204955, description: 'SAO ROQUE DO CANAA' },
    { id: 3205002, description: 'SERRA' },
    { id: 3205010, description: 'SOORETAMA' },
    { id: 3205036, description: 'VARGEM ALTA' },
    { id: 3205069, description: 'VENDA NOVA DO IMIGRANTE' },
    { id: 3205101, description: 'VIANA' },
    { id: 3205150, description: 'VILA PAVAO' },
    { id: 3205176, description: 'VILA VALERIO' },
    { id: 3205200, description: 'VILA VELHA' },
    { id: 3205309, description: 'VITORIA' },
    { id: 3300100, description: 'ANGRA DOS REIS' },
    { id: 3300159, description: 'APERIBE' },
    { id: 3300209, description: 'ARARUAMA' },
    { id: 3300225, description: 'AREAL' },
    { id: 3300233, description: 'ARMACAO DOS BUZIOS' },
    { id: 3300258, description: 'ARRAIAL DO CABO' },
    { id: 3300308, description: 'BARRA DO PIRAI' },
    { id: 3300407, description: 'BARRA MANSA' },
    { id: 3300456, description: 'BELFORD ROXO' },
    { id: 3300506, description: 'BOM JARDIM' },
    { id: 3300605, description: 'BOM JESUS DO ITABAPOANA' },
    { id: 3300704, description: 'CABO FRIO' },
    { id: 3300803, description: 'CACHOEIRAS DE MACACU' },
    { id: 3300902, description: 'CAMBUCI' },
    { id: 3300936, description: 'CARAPEBUS' },
    { id: 3300951, description: 'COMENDADOR LEVY GASPARIAN' },
    { id: 3301009, description: 'CAMPOS DOS GOYTACAZES' },
    { id: 3301108, description: 'CANTAGALO' },
    { id: 3301157, description: 'CARDOSO MOREIRA' },
    { id: 3301207, description: 'CARMO' },
    { id: 3301306, description: 'CASIMIRO DE ABREU' },
    { id: 3301405, description: 'CONCEICAO DE MACABU' },
    { id: 3301504, description: 'CORDEIRO' },
    { id: 3301603, description: 'DUAS BARRAS' },
    { id: 3301702, description: 'DUQUE DE CAXIAS' },
    { id: 3301801, description: 'ENGENHEIRO PAULO DE FRONTIN' },
    { id: 3301850, description: 'GUAPIMIRIM' },
    { id: 3301876, description: 'IGUABA GRANDE' },
    { id: 3301900, description: 'ITABORAI' },
    { id: 3302007, description: 'ITAGUAI' },
    { id: 3302056, description: 'ITALVA' },
    { id: 3302106, description: 'ITAOCARA' },
    { id: 3302205, description: 'ITAPERUNA' },
    { id: 3302254, description: 'ITATIAIA' },
    { id: 3302270, description: 'JAPERI' },
    { id: 3302304, description: 'LAJE DO MURIAE' },
    { id: 3302403, description: 'MACAE' },
    { id: 3302452, description: 'MACUCO' },
    { id: 3302502, description: 'MAGE' },
    { id: 3302601, description: 'MANGARATIBA' },
    { id: 3302700, description: 'MARICA' },
    { id: 3302809, description: 'MENDES' },
    { id: 3302858, description: 'MESQUITA' },
    { id: 3302908, description: 'MIGUEL PEREIRA' },
    { id: 3303005, description: 'MIRACEMA' },
    { id: 3303104, description: 'NATIVIDADE' },
    { id: 3303203, description: 'NILOPOLIS' },
    { id: 3303302, description: 'NITEROI' },
    { id: 3303401, description: 'NOVA FRIBURGO' },
    { id: 3303500, description: 'NOVA IGUACU' },
    { id: 3303609, description: 'PARACAMBI' },
    { id: 3303708, description: 'PARAIBA DO SUL' },
    { id: 3303807, description: 'PARATI' },
    { id: 3303856, description: 'PATY DO ALFERES' },
    { id: 3303906, description: 'PETROPOLIS' },
    { id: 3303955, description: 'PINHEIRAL' },
    { id: 3304003, description: 'PIRAI' },
    { id: 3304102, description: 'PORCIUNCULA' },
    { id: 3304110, description: 'PORTO REAL' },
    { id: 3304128, description: 'QUATIS' },
    { id: 3304144, description: 'QUEIMADOS' },
    { id: 3304151, description: 'QUISSAMA' },
    { id: 3304201, description: 'RESENDE' },
    { id: 3304300, description: 'RIO BONITO' },
    { id: 3304409, description: 'RIO CLARO' },
    { id: 3304508, description: 'RIO DAS FLORES' },
    { id: 3304524, description: 'RIO DAS OSTRAS' },
    { id: 3304557, description: 'RIO DE JANEIRO' },
    { id: 3304607, description: 'SANTA MARIA MADALENA' },
    { id: 3304706, description: 'SANTO ANTONIO DE PADUA' },
    { id: 3304755, description: 'SAO FRANCISCO DE ITABAPOANA' },
    { id: 3304805, description: 'SAO FIDELIS' },
    { id: 3304904, description: 'SAO GONCALO' },
    { id: 3305000, description: 'SAO JOAO DA BARRA' },
    { id: 3305109, description: 'SAO JOAO DE MERITI' },
    { id: 3305133, description: 'SAO JOSE DE UBA' },
    { id: 3305158, description: 'SAO JOSE DO VALE DO RIO PRETO' },
    { id: 3305208, description: 'SAO PEDRO DA ALDEIA' },
    { id: 3305307, description: 'SAO SEBASTIAO DO ALTO' },
    { id: 3305406, description: 'SAPUCAIA' },
    { id: 3305505, description: 'SAQUAREMA' },
    { id: 3305554, description: 'SEROPEDICA' },
    { id: 3305604, description: 'SILVA JARDIM' },
    { id: 3305703, description: 'SUMIDOURO' },
    { id: 3305752, description: 'TANGUA' },
    { id: 3305802, description: 'TERESOPOLIS' },
    { id: 3305901, description: 'TRAJANO DE MORAES' },
    { id: 3306008, description: 'TRES RIOS' },
    { id: 3306107, description: 'VALENCA' },
    { id: 3306156, description: 'VARRE SAI' },
    { id: 3306206, description: 'VASSOURAS' },
    { id: 3306305, description: 'VOLTA REDONDA' },
    { id: 3500105, description: 'ADAMANTINA' },
    { id: 3500204, description: 'ADOLFO' },
    { id: 3500303, description: 'AGUAI' },
    { id: 3500402, description: 'AGUAS DA PRATA' },
    { id: 3500501, description: 'AGUAS DE LINDOIA' },
    { id: 3500550, description: 'AGUAS DE SANTA BARBARA' },
    { id: 3500600, description: 'AGUAS DE SAO PEDRO' },
    { id: 3500709, description: 'AGUDOS' },
    { id: 3500758, description: 'ALAMBARI' },
    { id: 3500808, description: 'ALFREDO MARCONDES' },
    { id: 3500907, description: 'ALTAIR' },
    { id: 3501004, description: 'ALTINOPOLIS' },
    { id: 3501103, description: 'ALTO ALEGRE' },
    { id: 3501152, description: 'ALUMINIO' },
    { id: 3501202, description: 'ALVARES FLORENCE' },
    { id: 3501301, description: 'ALVARES MACHADO' },
    { id: 3501400, description: 'ALVARO DE CARVALHO' },
    { id: 3501509, description: 'ALVINLANDIA' },
    { id: 3501608, description: 'AMERICANA' },
    { id: 3501707, description: 'AMERICO BRASILIENSE' },
    { id: 3501806, description: 'AMERICO DE CAMPOS' },
    { id: 3501905, description: 'AMPARO' },
    { id: 3502002, description: 'ANALANDIA' },
    { id: 3502101, description: 'ANDRADINA' },
    { id: 3502200, description: 'ANGATUBA' },
    { id: 3502309, description: 'ANHEMBI' },
    { id: 3502408, description: 'ANHUMAS' },
    { id: 3502507, description: 'APARECIDA' },
    { id: 3502606, description: 'APARECIDA D OESTE' },
    { id: 3502705, description: 'APIAI' },
    { id: 3502754, description: 'ARACARIGUAMA' },
    { id: 3502804, description: 'ARACATUBA' },
    { id: 3502903, description: 'ARACOIABA DA SERRA' },
    { id: 3503000, description: 'ARAMINA' },
    { id: 3503109, description: 'ARANDU' },
    { id: 3503158, description: 'ARAPEI' },
    { id: 3503208, description: 'ARARAQUARA' },
    { id: 3503307, description: 'ARARAS' },
    { id: 3503356, description: 'ARCO IRIS' },
    { id: 3503406, description: 'AREALVA' },
    { id: 3503505, description: 'AREIAS' },
    { id: 3503604, description: 'AREIOPOLIS' },
    { id: 3503703, description: 'ARIRANHA' },
    { id: 3503802, description: 'ARTUR NOGUEIRA' },
    { id: 3503901, description: 'ARUJA' },
    { id: 3503950, description: 'ASPASIA' },
    { id: 3504008, description: 'ASSIS' },
    { id: 3504107, description: 'ATIBAIA' },
    { id: 3504206, description: 'AURIFLAMA' },
    { id: 3504305, description: 'AVAI' },
    { id: 3504404, description: 'AVANHANDAVA' },
    { id: 3504503, description: 'AVARE' },
    { id: 3504602, description: 'BADY BASSITT' },
    { id: 3504701, description: 'BALBINOS' },
    { id: 3504800, description: 'BALSAMO' },
    { id: 3504909, description: 'BANANAL' },
    { id: 3505005, description: 'BARAO DE ANTONINA' },
    { id: 3505104, description: 'BARBOSA' },
    { id: 3505203, description: 'BARIRI' },
    { id: 3505302, description: 'BARRA BONITA' },
    { id: 3505351, description: 'BARRA DO CHAPEU' },
    { id: 3505401, description: 'BARRA DO TURVO' },
    { id: 3505500, description: 'BARRETOS' },
    { id: 3505609, description: 'BARRINHA' },
    { id: 3505708, description: 'BARUERI' },
    { id: 3505807, description: 'BASTOS' },
    { id: 3505906, description: 'BATATAIS' },
    { id: 3506003, description: 'BAURU' },
    { id: 3506102, description: 'BEBEDOURO' },
    { id: 3506201, description: 'BENTO DE ABREU' },
    { id: 3506300, description: 'BERNARDINO DE CAMPOS' },
    { id: 3506359, description: 'BERTIOGA' },
    { id: 3506409, description: 'BILAC' },
    { id: 3506508, description: 'BIRIGUe' },
    { id: 3506607, description: 'BIRITIBA MIRIM' },
    { id: 3506706, description: 'BOA ESPERANCA DO SUL' },
    { id: 3506805, description: 'BOCAINA' },
    { id: 3506904, description: 'BOFETE' },
    { id: 3507001, description: 'BOITUVA' },
    { id: 3507100, description: 'BOM JESUS DOS PERDOES' },
    { id: 3507159, description: 'BOM SUCESSO DE ITARARE' },
    { id: 3507209, description: 'BORA' },
    { id: 3507308, description: 'BORACEIA' },
    { id: 3507407, description: 'BORBOREMA' },
    { id: 3507456, description: 'BOREBI' },
    { id: 3507506, description: 'BOTUCATU' },
    { id: 3507605, description: 'BRAGANCA PAULISTA' },
    { id: 3507704, description: 'BRAUNA' },
    { id: 3507753, description: 'BREJO ALEGRE' },
    { id: 3507803, description: 'BRODOWSKI' },
    { id: 3507902, description: 'BROTAS' },
    { id: 3508009, description: 'BURI' },
    { id: 3508108, description: 'BURITAMA' },
    { id: 3508207, description: 'BURITIZAL' },
    { id: 3508306, description: 'CABRALIA PAULISTA' },
    { id: 3508405, description: 'CABREUVA' },
    { id: 3508504, description: 'CACAPAVA' },
    { id: 3508603, description: 'CACHOEIRA PAULISTA' },
    { id: 3508702, description: 'CACONDE' },
    { id: 3508801, description: 'CAFELANDIA' },
    { id: 3508900, description: 'CAIABU' },
    { id: 3509007, description: 'CAIEIRAS' },
    { id: 3509106, description: 'CAIUA' },
    { id: 3509205, description: 'CAJAMAR' },
    { id: 3509254, description: 'CAJATI' },
    { id: 3509304, description: 'CAJOBI' },
    { id: 3509403, description: 'CAJURU' },
    { id: 3509452, description: 'CAMPINA DO MONTE ALEGRE' },
    { id: 3509502, description: 'CAMPINAS' },
    { id: 3509601, description: 'CAMPO LIMPO PAULISTA' },
    { id: 3509700, description: 'CAMPOS DO JORDAO' },
    { id: 3509809, description: 'CAMPOS NOVOS PAULISTA' },
    { id: 3509908, description: 'CANANEIA' },
    { id: 3509957, description: 'CANAS' },
    { id: 3510005, description: 'CANDIDO MOTA' },
    { id: 3510104, description: 'CANDIDO RODRIGUES' },
    { id: 3510153, description: 'CANITAR' },
    { id: 3510203, description: 'CAPAO BONITO' },
    { id: 3510302, description: 'CAPELA DO ALTO' },
    { id: 3510401, description: 'CAPIVARI' },
    { id: 3510500, description: 'CARAGUATATUBA' },
    { id: 3510609, description: 'CARAPICUIBA' },
    { id: 3510708, description: 'CARDOSO' },
    { id: 3510807, description: 'CASA BRANCA' },
    { id: 3510906, description: 'CASSIA DOS COQUEIROS' },
    { id: 3511003, description: 'CASTILHO' },
    { id: 3511102, description: 'CATANDUVA' },
    { id: 3511201, description: 'CATIGUA' },
    { id: 3511300, description: 'CEDRAL' },
    { id: 3511409, description: 'CERQUEIRA CESAR' },
    { id: 3511508, description: 'CERQUILHO' },
    { id: 3511607, description: 'CESARIO LANGE' },
    { id: 3511706, description: 'CHARQUEADA' },
    { id: 3511904, description: 'CLEMENTINA' },
    { id: 3512001, description: 'COLINA' },
    { id: 3512100, description: 'COLOMBIA' },
    { id: 3512209, description: 'CONCHAL' },
    { id: 3512308, description: 'CONCHAS' },
    { id: 3512407, description: 'CORDEIROPOLIS' },
    { id: 3512506, description: 'COROADOS' },
    { id: 3512605, description: 'CORONEL MACEDO' },
    { id: 3512704, description: 'CORUMBATAI' },
    { id: 3512803, description: 'COSMOPOLIS' },
    { id: 3512902, description: 'COSMORAMA' },
    { id: 3513009, description: 'COTIA' },
    { id: 3513108, description: 'CRAVINHOS' },
    { id: 3513207, description: 'CRISTAIS PAULISTA' },
    { id: 3513306, description: 'CRUZALIA' },
    { id: 3513405, description: 'CRUZEIRO' },
    { id: 3513504, description: 'CUBATAO' },
    { id: 3513603, description: 'CUNHA' },
    { id: 3513702, description: 'DESCALVADO' },
    { id: 3513801, description: 'DIADEMA' },
    { id: 3513850, description: 'DIRCE REIS' },
    { id: 3513900, description: 'DIVINOLANDIA' },
    { id: 3514007, description: 'DOBRADA' },
    { id: 3514106, description: 'DOIS CORREGOS' },
    { id: 3514205, description: 'DOLCINOPOLIS' },
    { id: 3514304, description: 'DOURADO' },
    { id: 3514403, description: 'DRACENA' },
    { id: 3514502, description: 'DUARTINA' },
    { id: 3514601, description: 'DUMONT' },
    { id: 3514700, description: 'ECHAPORA' },
    { id: 3514809, description: 'ELDORADO' },
    { id: 3514908, description: 'ELIAS FAUSTO' },
    { id: 3514924, description: 'ELISIARIO' },
    { id: 3514957, description: 'EMBAUBA' },
    { id: 3515004, description: 'EMBU DAS ARTES' },
    { id: 3515103, description: 'EMBU GUACU' },
    { id: 3515129, description: 'EMILIANOPOLIS' },
    { id: 3515152, description: 'ENGENHEIRO COELHO' },
    { id: 3515186, description: 'ESPIRITO SANTO DO PINHAL' },
    { id: 3515194, description: 'ESPIRITO SANTO DO TURVO' },
    { id: 3515202, description: 'ESTRELA D OESTE' },
    { id: 3515301, description: 'ESTRELA DO NORTE' },
    { id: 3515350, description: 'EUCLIDES DA CUNHA PAULISTA' },
    { id: 3515400, description: 'FARTURA' },
    { id: 3515509, description: 'FERNANDOPOLIS' },
    { id: 3515608, description: 'FERNANDO PRESTES' },
    { id: 3515657, description: 'FERNAO' },
    { id: 3515707, description: 'FERRAZ DE VASCONCELOS' },
    { id: 3515806, description: 'FLORA RICA' },
    { id: 3515905, description: 'FLOREAL' },
    { id: 3516002, description: 'FLORIDA PAULISTA' },
    { id: 3516101, description: 'FLORINIA' },
    { id: 3516200, description: 'FRANCA' },
    { id: 3516309, description: 'FRANCISCO MORATO' },
    { id: 3516408, description: 'FRANCO DA ROCHA' },
    { id: 3516507, description: 'GABRIEL MONTEIRO' },
    { id: 3516606, description: 'GALIA' },
    { id: 3516705, description: 'GARCA' },
    { id: 3516804, description: 'GASTAO VIDIGAL' },
    { id: 3516853, description: 'GAVIAO PEIXOTO' },
    { id: 3516903, description: 'GENERAL SALGADO' },
    { id: 3517000, description: 'GETULINA' },
    { id: 3517109, description: 'GLICERIO' },
    { id: 3517208, description: 'GUAICARA' },
    { id: 3517307, description: 'GUAIMBE' },
    { id: 3517406, description: 'GUAIRA' },
    { id: 3517505, description: 'GUAPIACU' },
    { id: 3517604, description: 'GUAPIARA' },
    { id: 3517703, description: 'GUARA' },
    { id: 3517802, description: 'GUARACAI' },
    { id: 3517901, description: 'GUARACI' },
    { id: 3518008, description: 'GUARANI D OESTE' },
    { id: 3518107, description: 'GUARANTA' },
    { id: 3518206, description: 'GUARARAPES' },
    { id: 3518305, description: 'GUARAREMA' },
    { id: 3518404, description: 'GUARATINGUETA' },
    { id: 3518503, description: 'GUAREI' },
    { id: 3518602, description: 'GUARIBA' },
    { id: 3518701, description: 'GUARUJA' },
    { id: 3518800, description: 'GUARULHOS' },
    { id: 3518859, description: 'GUATAPARA' },
    { id: 3518909, description: 'GUZOLANDIA' },
    { id: 3519006, description: 'HERCULANDIA' },
    { id: 3519055, description: 'HOLAMBRA' },
    { id: 3519071, description: 'HORTOLANDIA' },
    { id: 3519105, description: 'IACANGA' },
    { id: 3519204, description: 'IACRI' },
    { id: 3519253, description: 'IARAS' },
    { id: 3519303, description: 'IBATE' },
    { id: 3519402, description: 'IBIRA' },
    { id: 3519501, description: 'IBIRAREMA' },
    { id: 3519600, description: 'IBITINGA' },
    { id: 3519709, description: 'IBIUNA' },
    { id: 3519808, description: 'ICEM' },
    { id: 3519907, description: 'IEPE' },
    { id: 3520004, description: 'IGARACU DO TIETE' },
    { id: 3520103, description: 'IGARAPAVA' },
    { id: 3520202, description: 'IGARATA' },
    { id: 3520301, description: 'IGUAPE' },
    { id: 3520400, description: 'ILHABELA' },
    { id: 3520426, description: 'ILHA COMPRIDA' },
    { id: 3520442, description: 'ILHA SOLTEIRA' },
    { id: 3520509, description: 'INDAIATUBA' },
    { id: 3520608, description: 'INDIANA' },
    { id: 3520707, description: 'INDIAPORA' },
    { id: 3520806, description: 'INUBIA PAULISTA' },
    { id: 3520905, description: 'IPAUSSU' },
    { id: 3521002, description: 'IPERO' },
    { id: 3521101, description: 'IPEUNA' },
    { id: 3521150, description: 'IPIGUA' },
    { id: 3521200, description: 'IPORANGA' },
    { id: 3521309, description: 'IPUA' },
    { id: 3521408, description: 'IRACEMAPOLIS' },
    { id: 3521507, description: 'IRAPUA' },
    { id: 3521606, description: 'IRAPURU' },
    { id: 3521705, description: 'ITABERA' },
    { id: 3521804, description: 'ITAI' },
    { id: 3521903, description: 'ITAJOBI' },
    { id: 3522000, description: 'ITAJU' },
    { id: 3522109, description: 'ITANHAEM' },
    { id: 3522158, description: 'ITAOCA' },
    { id: 3522208, description: 'ITAPECERICA DA SERRA' },
    { id: 3522307, description: 'ITAPETININGA' },
    { id: 3522406, description: 'ITAPEVA' },
    { id: 3522505, description: 'ITAPEVI' },
    { id: 3522604, description: 'ITAPIRA' },
    { id: 3522653, description: 'ITAPIRAPUA PAULISTA' },
    { id: 3522703, description: 'ITAPOLIS' },
    { id: 3522802, description: 'ITAPORANGA' },
    { id: 3522901, description: 'ITAPUI' },
    { id: 3523008, description: 'ITAPURA' },
    { id: 3523107, description: 'ITAQUAQUECETUBA' },
    { id: 3523206, description: 'ITARARE' },
    { id: 3523305, description: 'ITARIRI' },
    { id: 3523404, description: 'ITATIBA' },
    { id: 3523503, description: 'ITATINGA' },
    { id: 3523602, description: 'ITIRAPINA' },
    { id: 3523701, description: 'ITIRAPUA' },
    { id: 3523800, description: 'ITOBI' },
    { id: 3523909, description: 'ITU' },
    { id: 3524006, description: 'ITUPEVA' },
    { id: 3524105, description: 'ITUVERAVA' },
    { id: 3524204, description: 'JABORANDI' },
    { id: 3524303, description: 'JABOTICABAL' },
    { id: 3524402, description: 'JACAREI' },
    { id: 3524501, description: 'JACI' },
    { id: 3524600, description: 'JACUPIRANGA' },
    { id: 3524709, description: 'JAGUARIUNA' },
    { id: 3524808, description: 'JALES' },
    { id: 3524907, description: 'JAMBEIRO' },
    { id: 3525003, description: 'JANDIRA' },
    { id: 3525102, description: 'JARDINOPOLIS' },
    { id: 3525201, description: 'JARINU' },
    { id: 3525300, description: 'JAU' },
    { id: 3525409, description: 'JERIQUARA' },
    { id: 3525508, description: 'JOANOPOLIS' },
    { id: 3525607, description: 'JOAO RAMALHO' },
    { id: 3525706, description: 'JOSE BONIFACIO' },
    { id: 3525805, description: 'JULIO MESQUITA' },
    { id: 3525854, description: 'JUMIRIM' },
    { id: 3525904, description: 'JUNDIAI' },
    { id: 3526001, description: 'JUNQUEIROPOLIS' },
    { id: 3526100, description: 'JUQUIA' },
    { id: 3526209, description: 'JUQUITIBA' },
    { id: 3526308, description: 'LAGOINHA' },
    { id: 3526407, description: 'LARANJAL PAULISTA' },
    { id: 3526506, description: 'LAVINIA' },
    { id: 3526605, description: 'LAVRINHAS' },
    { id: 3526704, description: 'LEME' },
    { id: 3526803, description: 'LENCOIS PAULISTA' },
    { id: 3526902, description: 'LIMEIRA' },
    { id: 3527009, description: 'LINDOIA' },
    { id: 3527108, description: 'LINS' },
    { id: 3527207, description: 'LORENA' },
    { id: 3527256, description: 'LOURDES' },
    { id: 3527306, description: 'LOUVEIRA' },
    { id: 3527405, description: 'LUCELIA' },
    { id: 3527504, description: 'LUCIANOPOLIS' },
    { id: 3527603, description: 'LUIS ANTONIO' },
    { id: 3527702, description: 'LUIZIANIA' },
    { id: 3527801, description: 'LUPERCIO' },
    { id: 3527900, description: 'LUTECIA' },
    { id: 3528007, description: 'MACATUBA' },
    { id: 3528106, description: 'MACAUBAL' },
    { id: 3528205, description: 'MACEDONIA' },
    { id: 3528304, description: 'MAGDA' },
    { id: 3528403, description: 'MAIRINQUE' },
    { id: 3528502, description: 'MAIRIPORA' },
    { id: 3528601, description: 'MANDURI' },
    { id: 3528700, description: 'MARABA PAULISTA' },
    { id: 3528809, description: 'MARACAI' },
    { id: 3528858, description: 'MARAPOAMA' },
    { id: 3528908, description: 'MARIAPOLIS' },
    { id: 3529005, description: 'MARILIA' },
    { id: 3529104, description: 'MARINOPOLIS' },
    { id: 3529203, description: 'MARTINOPOLIS' },
    { id: 3529302, description: 'MATAO' },
    { id: 3529401, description: 'MAUA' },
    { id: 3529500, description: 'MENDONCA' },
    { id: 3529609, description: 'MERIDIANO' },
    { id: 3529658, description: 'MESOPOLIS' },
    { id: 3529708, description: 'MIGUELOPOLIS' },
    { id: 3529807, description: 'MINEIROS DO TIETE' },
    { id: 3529906, description: 'MIRACATU' },
    { id: 3530003, description: 'MIRA ESTRELA' },
    { id: 3530102, description: 'MIRANDOPOLIS' },
    { id: 3530201, description: 'MIRANTE DO PARANAPANEMA' },
    { id: 3530300, description: 'MIRASSOL' },
    { id: 3530409, description: 'MIRASSOLANDIA' },
    { id: 3530508, description: 'MOCOCA' },
    { id: 3530607, description: 'MOGI DAS CRUZES' },
    { id: 3530706, description: 'MOGI GUACU' },
    { id: 3530805, description: 'MOGI MIRIM' },
    { id: 3530904, description: 'MOMBUCA' },
    { id: 3531001, description: 'MONCOES' },
    { id: 3531100, description: 'MONGAGUA' },
    { id: 3531209, description: 'MONTE ALEGRE DO SUL' },
    { id: 3531308, description: 'MONTE ALTO' },
    { id: 3531407, description: 'MONTE APRAZIVEL' },
    { id: 3531506, description: 'MONTE AZUL PAULISTA' },
    { id: 3531605, description: 'MONTE CASTELO' },
    { id: 3531704, description: 'MONTEIRO LOBATO' },
    { id: 3531803, description: 'MONTE MOR' },
    { id: 3531902, description: 'MORRO AGUDO' },
    { id: 3532009, description: 'MORUNGABA' },
    { id: 3532058, description: 'MOTUCA' },
    { id: 3532108, description: 'MURUTINGA DO SUL' },
    { id: 3532157, description: 'NANTES' },
    { id: 3532207, description: 'NARANDIBA' },
    { id: 3532306, description: 'NATIVIDADE DA SERRA' },
    { id: 3532405, description: 'NAZARE PAULISTA' },
    { id: 3532504, description: 'NEVES PAULISTA' },
    { id: 3532603, description: 'NHANDEARA' },
    { id: 3532702, description: 'NIPOA' },
    { id: 3532801, description: 'NOVA ALIANCA' },
    { id: 3532827, description: 'NOVA CAMPINA' },
    { id: 3532843, description: 'NOVA CANAA PAULISTA' },
    { id: 3532868, description: 'NOVA CASTILHO' },
    { id: 3532900, description: 'NOVA EUROPA' },
    { id: 3533007, description: 'NOVA GRANADA' },
    { id: 3533106, description: 'NOVA GUATAPORANGA' },
    { id: 3533205, description: 'NOVA INDEPENDENCIA' },
    { id: 3533254, description: 'NOVAIS' },
    { id: 3533304, description: 'NOVA LUZITANIA' },
    { id: 3533403, description: 'NOVA ODESSA' },
    { id: 3533502, description: 'NOVO HORIZONTE' },
    { id: 3533601, description: 'NUPORANGA' },
    { id: 3533700, description: 'OCAUCU' },
    { id: 3533809, description: 'OLEO' },
    { id: 3533908, description: 'OLIMPIA' },
    { id: 3534005, description: 'ONDA VERDE' },
    { id: 3534104, description: 'ORIENTE' },
    { id: 3534203, description: 'ORINDIUVA' },
    { id: 3534302, description: 'ORLANDIA' },
    { id: 3534401, description: 'OSASCO' },
    { id: 3534500, description: 'OSCAR BRESSANE' },
    { id: 3534609, description: 'OSVALDO CRUZ' },
    { id: 3534708, description: 'OURINHOS' },
    { id: 3534757, description: 'OUROESTE' },
    { id: 3534807, description: 'OURO VERDE' },
    { id: 3534906, description: 'PACAEMBU' },
    { id: 3535002, description: 'PALESTINA' },
    { id: 3535101, description: 'PALMARES PAULISTA' },
    { id: 3535200, description: 'PALMEIRA D OESTE' },
    { id: 3535309, description: 'PALMITAL' },
    { id: 3535408, description: 'PANORAMA' },
    { id: 3535507, description: 'PARAGUACU PAULISTA' },
    { id: 3535606, description: 'PARAIBUNA' },
    { id: 3535705, description: 'PARAISO' },
    { id: 3535804, description: 'PARANAPANEMA' },
    { id: 3535903, description: 'PARANAPUA' },
    { id: 3536000, description: 'PARAPUA' },
    { id: 3536109, description: 'PARDINHO' },
    { id: 3536208, description: 'PARIQUERA ACU' },
    { id: 3536257, description: 'PARISI' },
    { id: 3536307, description: 'PATROCINIO PAULISTA' },
    { id: 3536406, description: 'PAULICEIA' },
    { id: 3536505, description: 'PAULINIA' },
    { id: 3536570, description: 'PAULISTANIA' },
    { id: 3536604, description: 'PAULO DE FARIA' },
    { id: 3536703, description: 'PEDERNEIRAS' },
    { id: 3536802, description: 'PEDRA BELA' },
    { id: 3536901, description: 'PEDRANOPOLIS' },
    { id: 3537008, description: 'PEDREGULHO' },
    { id: 3537107, description: 'PEDREIRA' },
    { id: 3537156, description: 'PEDRINHAS PAULISTA' },
    { id: 3537206, description: 'PEDRO DE TOLEDO' },
    { id: 3537305, description: 'PENAPOLIS' },
    { id: 3537404, description: 'PEREIRA BARRETO' },
    { id: 3537503, description: 'PEREIRAS' },
    { id: 3537602, description: 'PERUIBE' },
    { id: 3537701, description: 'PIACATU' },
    { id: 3537800, description: 'PIEDADE' },
    { id: 3537909, description: 'PILAR DO SUL' },
    { id: 3538006, description: 'PINDAMONHANGABA' },
    { id: 3538105, description: 'PINDORAMA' },
    { id: 3538204, description: 'PINHALZINHO' },
    { id: 3538303, description: 'PIQUEROBI' },
    { id: 3538501, description: 'PIQUETE' },
    { id: 3538600, description: 'PIRACAIA' },
    { id: 3538709, description: 'PIRACICABA' },
    { id: 3538808, description: 'PIRAJU' },
    { id: 3538907, description: 'PIRAJUI' },
    { id: 3539004, description: 'PIRANGI' },
    { id: 3539103, description: 'PIRAPORA DO BOM JESUS' },
    { id: 3539202, description: 'PIRAPOZINHO' },
    { id: 3539301, description: 'PIRASSUNUNGA' },
    { id: 3539400, description: 'PIRATININGA' },
    { id: 3539509, description: 'PITANGUEIRAS' },
    { id: 3539608, description: 'PLANALTO' },
    { id: 3539707, description: 'PLATINA' },
    { id: 3539806, description: 'POA' },
    { id: 3539905, description: 'POLONI' },
    { id: 3540002, description: 'POMPEIA' },
    { id: 3540101, description: 'PONGAI' },
    { id: 3540200, description: 'PONTAL' },
    { id: 3540259, description: 'PONTALINDA' },
    { id: 3540309, description: 'PONTES GESTAL' },
    { id: 3540408, description: 'POPULINA' },
    { id: 3540507, description: 'PORANGABA' },
    { id: 3540606, description: 'PORTO FELIZ' },
    { id: 3540705, description: 'PORTO FERREIRA' },
    { id: 3540754, description: 'POTIM' },
    { id: 3540804, description: 'POTIRENDABA' },
    { id: 3540853, description: 'PRACINHA' },
    { id: 3540903, description: 'PRADOPOLIS' },
    { id: 3541000, description: 'PRAIA GRANDE' },
    { id: 3541059, description: 'PRATANIA' },
    { id: 3541109, description: 'PRESIDENTE ALVES' },
    { id: 3541208, description: 'PRESIDENTE BERNARDES' },
    { id: 3541307, description: 'PRESIDENTE EPITACIO' },
    { id: 3541406, description: 'PRESIDENTE PRUDENTE' },
    { id: 3541505, description: 'PRESIDENTE VENCESLAU' },
    { id: 3541604, description: 'PROMISSAO' },
    { id: 3541653, description: 'QUADRA' },
    { id: 3541703, description: 'QUATA' },
    { id: 3541802, description: 'QUEIROZ' },
    { id: 3541901, description: 'QUELUZ' },
    { id: 3542008, description: 'QUINTANA' },
    { id: 3542107, description: 'RAFARD' },
    { id: 3542206, description: 'RANCHARIA' },
    { id: 3542305, description: 'REDENCAO DA SERRA' },
    { id: 3542404, description: 'REGENTE FEIJO' },
    { id: 3542503, description: 'REGINOPOLIS' },
    { id: 3542602, description: 'REGISTRO' },
    { id: 3542701, description: 'RESTINGA' },
    { id: 3542800, description: 'RIBEIRA' },
    { id: 3542909, description: 'RIBEIRAO BONITO' },
    { id: 3543006, description: 'RIBEIRAO BRANCO' },
    { id: 3543105, description: 'RIBEIRAO CORRENTE' },
    { id: 3543204, description: 'RIBEIRAO DO SUL' },
    { id: 3543238, description: 'RIBEIRAO DOS INDIOS' },
    { id: 3543253, description: 'RIBEIRAO GRANDE' },
    { id: 3543303, description: 'RIBEIRAO PIRES' },
    { id: 3543402, description: 'RIBEIRAO PRETO' },
    { id: 3543501, description: 'RIVERSUL' },
    { id: 3543600, description: 'RIFAINA' },
    { id: 3543709, description: 'RINCAO' },
    { id: 3543808, description: 'RINOPOLIS' },
    { id: 3543907, description: 'RIO CLARO' },
    { id: 3544004, description: 'RIO DAS PEDRAS' },
    { id: 3544103, description: 'RIO GRANDE DA SERRA' },
    { id: 3544202, description: 'RIOLANDIA' },
    { id: 3544251, description: 'ROSANA' },
    { id: 3544301, description: 'ROSEIRA' },
    { id: 3544400, description: 'RUBIACEA' },
    { id: 3544509, description: 'RUBINEIA' },
    { id: 3544608, description: 'SABINO' },
    { id: 3544707, description: 'SAGRES' },
    { id: 3544806, description: 'SALES' },
    { id: 3544905, description: 'SALES OLIVEIRA' },
    { id: 3545001, description: 'SALESOPOLIS' },
    { id: 3545100, description: 'SALMOURAO' },
    { id: 3545159, description: 'SALTINHO' },
    { id: 3545209, description: 'SALTO' },
    { id: 3545308, description: 'SALTO DE PIRAPORA' },
    { id: 3545407, description: 'SALTO GRANDE' },
    { id: 3545506, description: 'SANDOVALINA' },
    { id: 3545605, description: 'SANTA ADELIA' },
    { id: 3545704, description: 'SANTA ALBERTINA' },
    { id: 3545803, description: 'SANTA BARBARA D OESTE' },
    { id: 3546009, description: 'SANTA BRANCA' },
    { id: 3546108, description: 'SANTA CLARA D OESTE' },
    { id: 3546207, description: 'SANTA CRUZ DA CONCEICAO' },
    { id: 3546256, description: 'SANTA CRUZ DA ESPERANCA' },
    { id: 3546306, description: 'SANTA CRUZ DAS PALMEIRAS' },
    { id: 3546405, description: 'SANTA CRUZ DO RIO PARDO' },
    { id: 3546504, description: 'SANTA ERNESTINA' },
    { id: 3546603, description: 'SANTA FE DO SUL' },
    { id: 3546702, description: 'SANTA GERTRUDES' },
    { id: 3546801, description: 'SANTA ISABEL' },
    { id: 3546900, description: 'SANTA LUCIA' },
    { id: 3547007, description: 'SANTA MARIA DA SERRA' },
    { id: 3547106, description: 'SANTA MERCEDES' },
    { id: 3547205, description: 'SANTANA DA PONTE PENSA' },
    { id: 3547304, description: 'SANTANA DE PARNAIBA' },
    { id: 3547403, description: 'SANTA RITA D OESTE' },
    { id: 3547502, description: 'SANTA RITA DO PASSA QUATRO' },
    { id: 3547601, description: 'SANTA ROSA DE VITERBO' },
    { id: 3547650, description: 'SANTA SALETE' },
    { id: 3547700, description: 'SANTO ANASTACIO' },
    { id: 3547809, description: 'SANTO ANDRE' },
    { id: 3547908, description: 'SANTO ANTONIO DA ALEGRIA' },
    { id: 3548005, description: 'SANTO ANTONIO DE POSSE' },
    { id: 3548054, description: 'SANTO ANTONIO DO ARACANGUA' },
    { id: 3548104, description: 'SANTO ANTONIO DO JARDIM' },
    { id: 3548203, description: 'SANTO ANTONIO DO PINHAL' },
    { id: 3548302, description: 'SANTO EXPEDITO' },
    { id: 3548401, description: 'SANTOPOLIS DO AGUAPEI' },
    { id: 3548500, description: 'SANTOS' },
    { id: 3548609, description: 'SAO BENTO DO SAPUCAI' },
    { id: 3548708, description: 'SAO BERNARDO DO CAMPO' },
    { id: 3548807, description: 'SAO CAETANO DO SUL' },
    { id: 3548906, description: 'SAO CARLOS' },
    { id: 3549003, description: 'SAO FRANCISCO' },
    { id: 3549102, description: 'SAO JOAO DA BOA VISTA' },
    { id: 3549201, description: 'SAO JOAO DAS DUAS PONTES' },
    { id: 3549250, description: 'SAO JOAO DE IRACEMA' },
    { id: 3549300, description: 'SAO JOAO DO PAU D ALHO' },
    { id: 3549409, description: 'SAO JOAQUIM DA BARRA' },
    { id: 3549508, description: 'SAO JOSE DA BELA VISTA' },
    { id: 3549607, description: 'SAO JOSE DO BARREIRO' },
    { id: 3549706, description: 'SAO JOSE DO RIO PARDO' },
    { id: 3549805, description: 'SAO JOSE DO RIO PRETO' },
    { id: 3549904, description: 'SAO JOSE DOS CAMPOS' },
    { id: 3549953, description: 'SAO LOURENCO DA SERRA' },
    { id: 3550001, description: 'SAO LUIZ DO PARAITINGA' },
    { id: 3550100, description: 'SAO MANUEL' },
    { id: 3550209, description: 'SAO MIGUEL ARCANJO' },
    { id: 3550308, description: 'SAO PAULO' },
    { id: 3550407, description: 'SAO PEDRO' },
    { id: 3550506, description: 'SAO PEDRO DO TURVO' },
    { id: 3550605, description: 'SAO ROQUE' },
    { id: 3550704, description: 'SAO SEBASTIAO' },
    { id: 3550803, description: 'SAO SEBASTIAO DA GRAMA' },
    { id: 3550902, description: 'SAO SIMAO' },
    { id: 3551009, description: 'SAO VICENTE' },
    { id: 3551108, description: 'SARAPUI' },
    { id: 3551207, description: 'SARUTAIA' },
    { id: 3551306, description: 'SEBASTIANOPOLIS DO SUL' },
    { id: 3551405, description: 'SERRA AZUL' },
    { id: 3551504, description: 'SERRANA' },
    { id: 3551603, description: 'SERRA NEGRA' },
    { id: 3551702, description: 'SERTAOZINHO' },
    { id: 3551801, description: 'SETE BARRAS' },
    { id: 3551900, description: 'SEVERINIA' },
    { id: 3552007, description: 'SILVEIRAS' },
    { id: 3552106, description: 'SOCORRO' },
    { id: 3552205, description: 'SOROCABA' },
    { id: 3552304, description: 'SUD MENNUCCI' },
    { id: 3552403, description: 'SUMARE' },
    { id: 3552502, description: 'SUZANO' },
    { id: 3552551, description: 'SUZANAPOLIS' },
    { id: 3552601, description: 'TABAPUA' },
    { id: 3552700, description: 'TABATINGA' },
    { id: 3552809, description: 'TABOAO DA SERRA' },
    { id: 3552908, description: 'TACIBA' },
    { id: 3553005, description: 'TAGUAI' },
    { id: 3553104, description: 'TAIACU' },
    { id: 3553203, description: 'TAIUVA' },
    { id: 3553302, description: 'TAMBAU' },
    { id: 3553401, description: 'TANABI' },
    { id: 3553500, description: 'TAPIRAI' },
    { id: 3553609, description: 'TAPIRATIBA' },
    { id: 3553658, description: 'TAQUARAL' },
    { id: 3553708, description: 'TAQUARITINGA' },
    { id: 3553807, description: 'TAQUARITUBA' },
    { id: 3553856, description: 'TAQUARIVAI' },
    { id: 3553906, description: 'TARABAI' },
    { id: 3553955, description: 'TARUMA' },
    { id: 3554003, description: 'TATUI' },
    { id: 3554102, description: 'TAUBATE' },
    { id: 3554201, description: 'TEJUPA' },
    { id: 3554300, description: 'TEODORO SAMPAIO' },
    { id: 3554409, description: 'TERRA ROXA' },
    { id: 3554508, description: 'TIETE' },
    { id: 3554607, description: 'TIMBURI' },
    { id: 3554656, description: 'TORRE DE PEDRA' },
    { id: 3554706, description: 'TORRINHA' },
    { id: 3554755, description: 'TRABIJU' },
    { id: 3554805, description: 'TREMEMBE' },
    { id: 3554904, description: 'TRES FRONTEIRAS' },
    { id: 3554953, description: 'TUIUTI' },
    { id: 3555000, description: 'TUPA' },
    { id: 3555109, description: 'TUPI PAULISTA' },
    { id: 3555208, description: 'TURIUBA' },
    { id: 3555307, description: 'TURMALINA' },
    { id: 3555356, description: 'UBARANA' },
    { id: 3555406, description: 'UBATUBA' },
    { id: 3555505, description: 'UBIRAJARA' },
    { id: 3555604, description: 'UCHOA' },
    { id: 3555703, description: 'UNIAO PAULISTA' },
    { id: 3555802, description: 'URANIA' },
    { id: 3555901, description: 'URU' },
    { id: 3556008, description: 'URUPES' },
    { id: 3556107, description: 'VALENTIM GENTIL' },
    { id: 3556206, description: 'VALINHOS' },
    { id: 3556305, description: 'VALPARAISO' },
    { id: 3556354, description: 'VARGEM' },
    { id: 3556404, description: 'VARGEM GRANDE DO SUL' },
    { id: 3556453, description: 'VARGEM GRANDE PAULISTA' },
    { id: 3556503, description: 'VARZEA PAULISTA' },
    { id: 3556602, description: 'VERA CRUZ' },
    { id: 3556701, description: 'VINHEDO' },
    { id: 3556800, description: 'VIRADOURO' },
    { id: 3556909, description: 'VISTA ALEGRE DO ALTO' },
    { id: 3556958, description: 'VITORIA BRASIL' },
    { id: 3557006, description: 'VOTORANTIM' },
    { id: 3557105, description: 'VOTUPORANGA' },
    { id: 3557154, description: 'ZACARIAS' },
    { id: 3557204, description: 'CHAVANTES' },
    { id: 3557303, description: 'ESTIVA GERBI' },
    { id: 4100103, description: 'ABATIA' },
    { id: 4100202, description: 'ADRIANOPOLIS' },
    { id: 4100301, description: 'AGUDOS DO SUL' },
    { id: 4100400, description: 'ALMIRANTE TAMANDARE' },
    { id: 4100459, description: 'ALTAMIRA DO PARANA' },
    { id: 4100509, description: 'ALTONIA' },
    { id: 4100608, description: 'ALTO PARANA' },
    { id: 4100707, description: 'ALTO PIQUIRI' },
    { id: 4100806, description: 'ALVORADA DO SUL' },
    { id: 4100905, description: 'AMAPORA' },
    { id: 4101002, description: 'AMPERE' },
    { id: 4101051, description: 'ANAHY' },
    { id: 4101101, description: 'ANDIRA' },
    { id: 4101150, description: 'ANGULO' },
    { id: 4101200, description: 'ANTONINA' },
    { id: 4101309, description: 'ANTONIO OLINTO' },
    { id: 4101408, description: 'APUCARANA' },
    { id: 4101507, description: 'ARAPONGAS' },
    { id: 4101606, description: 'ARAPOTI' },
    { id: 4101655, description: 'ARAPUA' },
    { id: 4101705, description: 'ARARUNA' },
    { id: 4101804, description: 'ARAUCARIA' },
    { id: 4101853, description: 'ARIRANHA DO IVAI' },
    { id: 4101903, description: 'ASSAI' },
    { id: 4102000, description: 'ASSIS CHATEAUBRIAND' },
    { id: 4102109, description: 'ASTORGA' },
    { id: 4102208, description: 'ATALAIA' },
    { id: 4102307, description: 'BALSA NOVA' },
    { id: 4102406, description: 'BANDEIRANTES' },
    { id: 4102505, description: 'BARBOSA FERRAZ' },
    { id: 4102604, description: 'BARRACAO' },
    { id: 4102703, description: 'BARRA DO JACARE' },
    { id: 4102752, description: 'BELA VISTA DA CAROBA' },
    { id: 4102802, description: 'BELA VISTA DO PARAISO' },
    { id: 4102901, description: 'BITURUNA' },
    { id: 4103008, description: 'BOA ESPERANCA' },
    { id: 4103024, description: 'BOA ESPERANCA DO IGUACU' },
    { id: 4103040, description: 'BOA VENTURA DE SAO ROQUE' },
    { id: 4103057, description: 'BOA VISTA DA APARECIDA' },
    { id: 4103107, description: 'BOCAIUVA DO SUL' },
    { id: 4103156, description: 'BOM JESUS DO SUL' },
    { id: 4103206, description: 'BOM SUCESSO' },
    { id: 4103222, description: 'BOM SUCESSO DO SUL' },
    { id: 4103305, description: 'BORRAZOPOLIS' },
    { id: 4103354, description: 'BRAGANEY' },
    { id: 4103370, description: 'BRASILANDIA DO SUL' },
    { id: 4103404, description: 'CAFEARA' },
    { id: 4103453, description: 'CAFELANDIA' },
    { id: 4103479, description: 'CAFEZAL DO SUL' },
    { id: 4103503, description: 'CALIFORNIA' },
    { id: 4103602, description: 'CAMBARA' },
    { id: 4103701, description: 'CAMBE' },
    { id: 4103800, description: 'CAMBIRA' },
    { id: 4103909, description: 'CAMPINA DA LAGOA' },
    { id: 4103958, description: 'CAMPINA DO SIMAO' },
    { id: 4104006, description: 'CAMPINA GRANDE DO SUL' },
    { id: 4104055, description: 'CAMPO BONITO' },
    { id: 4104105, description: 'CAMPO DO TENENTE' },
    { id: 4104204, description: 'CAMPO LARGO' },
    { id: 4104253, description: 'CAMPO MAGRO' },
    { id: 4104303, description: 'CAMPO MOURAO' },
    { id: 4104402, description: 'CANDIDO DE ABREU' },
    { id: 4104428, description: 'CANDOI' },
    { id: 4104451, description: 'CANTAGALO' },
    { id: 4104501, description: 'CAPANEMA' },
    { id: 4104600, description: 'CAPITAO LEONIDAS MARQUES' },
    { id: 4104659, description: 'CARAMBEI' },
    { id: 4104709, description: 'CARLOPOLIS' },
    { id: 4104808, description: 'CASCAVEL' },
    { id: 4104907, description: 'CASTRO' },
    { id: 4105003, description: 'CATANDUVAS' },
    { id: 4105102, description: 'CENTENARIO DO SUL' },
    { id: 4105201, description: 'CERRO AZUL' },
    { id: 4105300, description: 'CEU AZUL' },
    { id: 4105409, description: 'CHOPINZINHO' },
    { id: 4105508, description: 'CIANORTE' },
    { id: 4105607, description: 'CIDADE GAUCHA' },
    { id: 4105706, description: 'CLEVELANDIA' },
    { id: 4105805, description: 'COLOMBO' },
    { id: 4105904, description: 'COLORADO' },
    { id: 4106001, description: 'CONGONHINHAS' },
    { id: 4106100, description: 'CONSELHEIRO MAIRINCK' },
    { id: 4106209, description: 'CONTENDA' },
    { id: 4106308, description: 'CORBELIA' },
    { id: 4106407, description: 'CORNELIO PROCOPIO' },
    { id: 4106456, description: 'CORONEL DOMINGOS SOARES' },
    { id: 4106506, description: 'CORONEL VIVIDA' },
    { id: 4106555, description: 'CORUMBATAI DO SUL' },
    { id: 4106571, description: 'CRUZEIRO DO IGUACU' },
    { id: 4106605, description: 'CRUZEIRO DO OESTE' },
    { id: 4106704, description: 'CRUZEIRO DO SUL' },
    { id: 4106803, description: 'CRUZ MACHADO' },
    { id: 4106852, description: 'CRUZMALTINA' },
    { id: 4106902, description: 'CURITIBA' },
    { id: 4107009, description: 'CURIUVA' },
    { id: 4107108, description: 'DIAMANTE DO NORTE' },
    { id: 4107124, description: 'DIAMANTE DO SUL' },
    { id: 4107157, description: 'DIAMANTE D OESTE' },
    { id: 4107207, description: 'DOIS VIZINHOS' },
    { id: 4107256, description: 'DOURADINA' },
    { id: 4107306, description: 'DOUTOR CAMARGO' },
    { id: 4107405, description: 'ENEAS MARQUES' },
    { id: 4107504, description: 'ENGENHEIRO BELTRAO' },
    { id: 4107520, description: 'ESPERANCA NOVA' },
    { id: 4107538, description: 'ENTRE RIOS DO OESTE' },
    { id: 4107546, description: 'ESPIGAO ALTO DO IGUACU' },
    { id: 4107553, description: 'FAROL' },
    { id: 4107603, description: 'FAXINAL' },
    { id: 4107652, description: 'FAZENDA RIO GRANDE' },
    { id: 4107702, description: 'FENIX' },
    { id: 4107736, description: 'FERNANDES PINHEIRO' },
    { id: 4107751, description: 'FIGUEIRA' },
    { id: 4107801, description: 'FLORAI' },
    { id: 4107850, description: 'FLOR DA SERRA DO SUL' },
    { id: 4107900, description: 'FLORESTA' },
    { id: 4108007, description: 'FLORESTOPOLIS' },
    { id: 4108106, description: 'FLORIDA' },
    { id: 4108205, description: 'FORMOSA DO OESTE' },
    { id: 4108304, description: 'FOZ DO IGUACU' },
    { id: 4108320, description: 'FRANCISCO ALVES' },
    { id: 4108403, description: 'FRANCISCO BELTRAO' },
    { id: 4108452, description: 'FOZ DO JORDAO' },
    { id: 4108502, description: 'GENERAL CARNEIRO' },
    { id: 4108551, description: 'GODOY MOREIRA' },
    { id: 4108601, description: 'GOIOERE' },
    { id: 4108650, description: 'GOIOXIM' },
    { id: 4108700, description: 'GRANDES RIOS' },
    { id: 4108809, description: 'GUAIRA' },
    { id: 4108908, description: 'GUAIRACA' },
    { id: 4108957, description: 'GUAMIRANGA' },
    { id: 4109005, description: 'GUAPIRAMA' },
    { id: 4109104, description: 'GUAPOREMA' },
    { id: 4109203, description: 'GUARACI' },
    { id: 4109302, description: 'GUARANIACU' },
    { id: 4109401, description: 'GUARAPUAVA' },
    { id: 4109500, description: 'GUARAQUECABA' },
    { id: 4109609, description: 'GUARATUBA' },
    { id: 4109658, description: 'HONORIO SERPA' },
    { id: 4109708, description: 'IBAITI' },
    { id: 4109757, description: 'IBEMA' },
    { id: 4109807, description: 'IBIPORA' },
    { id: 4109906, description: 'ICARAIMA' },
    { id: 4110003, description: 'IGUARACU' },
    { id: 4110052, description: 'IGUATU' },
    { id: 4110078, description: 'IMBAU' },
    { id: 4110102, description: 'IMBITUVA' },
    { id: 4110201, description: 'INACIO MARTINS' },
    { id: 4110300, description: 'INAJA' },
    { id: 4110409, description: 'INDIANOPOLIS' },
    { id: 4110508, description: 'IPIRANGA' },
    { id: 4110607, description: 'IPORA' },
    { id: 4110656, description: 'IRACEMA DO OESTE' },
    { id: 4110706, description: 'IRATI' },
    { id: 4110805, description: 'IRETAMA' },
    { id: 4110904, description: 'ITAGUAJE' },
    { id: 4110953, description: 'ITAIPULANDIA' },
    { id: 4111001, description: 'ITAMBARACA' },
    { id: 4111100, description: 'ITAMBE' },
    { id: 4111209, description: 'ITAPEJARA D OESTE' },
    { id: 4111258, description: 'ITAPERUCU' },
    { id: 4111308, description: 'ITAUNA DO SUL' },
    { id: 4111407, description: 'IVAI' },
    { id: 4111506, description: 'IVAIPORA' },
    { id: 4111555, description: 'IVATE' },
    { id: 4111605, description: 'IVATUBA' },
    { id: 4111704, description: 'JABOTI' },
    { id: 4111803, description: 'JACAREZINHO' },
    { id: 4111902, description: 'JAGUAPITA' },
    { id: 4112009, description: 'JAGUARIAIVA' },
    { id: 4112108, description: 'JANDAIA DO SUL' },
    { id: 4112207, description: 'JANIOPOLIS' },
    { id: 4112306, description: 'JAPIRA' },
    { id: 4112405, description: 'JAPURA' },
    { id: 4112504, description: 'JARDIM ALEGRE' },
    { id: 4112603, description: 'JARDIM OLINDA' },
    { id: 4112702, description: 'JATAIZINHO' },
    { id: 4112751, description: 'JESUITAS' },
    { id: 4112801, description: 'JOAQUIM TAVORA' },
    { id: 4112900, description: 'JUNDIAI DO SUL' },
    { id: 4112959, description: 'JURANDA' },
    { id: 4113007, description: 'JUSSARA' },
    { id: 4113106, description: 'KALORE' },
    { id: 4113205, description: 'LAPA' },
    { id: 4113254, description: 'LARANJAL' },
    { id: 4113304, description: 'LARANJEIRAS DO SUL' },
    { id: 4113403, description: 'LEOPOLIS' },
    { id: 4113429, description: 'LIDIANOPOLIS' },
    { id: 4113452, description: 'LINDOESTE' },
    { id: 4113502, description: 'LOANDA' },
    { id: 4113601, description: 'LOBATO' },
    { id: 4113700, description: 'LONDRINA' },
    { id: 4113734, description: 'LUIZIANA' },
    { id: 4113759, description: 'LUNARDELLI' },
    { id: 4113809, description: 'LUPIONOPOLIS' },
    { id: 4113908, description: 'MALLET' },
    { id: 4114005, description: 'MAMBORE' },
    { id: 4114104, description: 'MANDAGUACU' },
    { id: 4114203, description: 'MANDAGUARI' },
    { id: 4114302, description: 'MANDIRITUBA' },
    { id: 4114351, description: 'MANFRINOPOLIS' },
    { id: 4114401, description: 'MANGUEIRINHA' },
    { id: 4114500, description: 'MANOEL RIBAS' },
    { id: 4114609, description: 'MARECHAL CANDIDO RONDON' },
    { id: 4114708, description: 'MARIA HELENA' },
    { id: 4114807, description: 'MARIALVA' },
    { id: 4114906, description: 'MARILANDIA DO SUL' },
    { id: 4115002, description: 'MARILENA' },
    { id: 4115101, description: 'MARILUZ' },
    { id: 4115200, description: 'MARINGA' },
    { id: 4115309, description: 'MARIOPOLIS' },
    { id: 4115358, description: 'MARIPA' },
    { id: 4115408, description: 'MARMELEIRO' },
    { id: 4115457, description: 'MARQUINHO' },
    { id: 4115507, description: 'MARUMBI' },
    { id: 4115606, description: 'MATELANDIA' },
    { id: 4115705, description: 'MATINHOS' },
    { id: 4115739, description: 'MATO RICO' },
    { id: 4115754, description: 'MAUA DA SERRA' },
    { id: 4115804, description: 'MEDIANEIRA' },
    { id: 4115853, description: 'MERCEDES' },
    { id: 4115903, description: 'MIRADOR' },
    { id: 4116000, description: 'MIRASELVA' },
    { id: 4116059, description: 'MISSAL' },
    { id: 4116109, description: 'MOREIRA SALES' },
    { id: 4116208, description: 'MORRETES' },
    { id: 4116307, description: 'MUNHOZ DE MELO' },
    { id: 4116406, description: 'NOSSA SENHORA DAS GRACAS' },
    { id: 4116505, description: 'NOVA ALIANCA DO IVAI' },
    { id: 4116604, description: 'NOVA AMERICA DA COLINA' },
    { id: 4116703, description: 'NOVA AURORA' },
    { id: 4116802, description: 'NOVA CANTU' },
    { id: 4116901, description: 'NOVA ESPERANCA' },
    { id: 4116950, description: 'NOVA ESPERANCA DO SUDOESTE' },
    { id: 4117008, description: 'NOVA FATIMA' },
    { id: 4117057, description: 'NOVA LARANJEIRAS' },
    { id: 4117107, description: 'NOVA LONDRINA' },
    { id: 4117206, description: 'NOVA OLIMPIA' },
    { id: 4117214, description: 'NOVA SANTA BARBARA' },
    { id: 4117222, description: 'NOVA SANTA ROSA' },
    { id: 4117255, description: 'NOVA PRATA DO IGUACU' },
    { id: 4117271, description: 'NOVA TEBAS' },
    { id: 4117297, description: 'NOVO ITACOLOMI' },
    { id: 4117305, description: 'ORTIGUEIRA' },
    { id: 4117404, description: 'OURIZONA' },
    { id: 4117453, description: 'OURO VERDE DO OESTE' },
    { id: 4117503, description: 'PAICANDU' },
    { id: 4117602, description: 'PALMAS' },
    { id: 4117701, description: 'PALMEIRA' },
    { id: 4117800, description: 'PALMITAL' },
    { id: 4117909, description: 'PALOTINA' },
    { id: 4118006, description: 'PARAISO DO NORTE' },
    { id: 4118105, description: 'PARANACITY' },
    { id: 4118204, description: 'PARANAGUA' },
    { id: 4118303, description: 'PARANAPOEMA' },
    { id: 4118402, description: 'PARANAVAI' },
    { id: 4118451, description: 'PATO BRAGADO' },
    { id: 4118501, description: 'PATO BRANCO' },
    { id: 4118600, description: 'PAULA FREITAS' },
    { id: 4118709, description: 'PAULO FRONTIN' },
    { id: 4118808, description: 'PEABIRU' },
    { id: 4118857, description: 'PEROBAL' },
    { id: 4118907, description: 'PEROLA' },
    { id: 4119004, description: 'PEROLA D OESTE' },
    { id: 4119103, description: 'PIEN' },
    { id: 4119152, description: 'PINHAIS' },
    { id: 4119202, description: 'PINHALAO' },
    { id: 4119251, description: 'PINHAL DE SAO BENTO' },
    { id: 4119301, description: 'PINHAO' },
    { id: 4119400, description: 'PIRAI DO SUL' },
    { id: 4119509, description: 'PIRAQUARA' },
    { id: 4119608, description: 'PITANGA' },
    { id: 4119657, description: 'PITANGUEIRAS' },
    { id: 4119707, description: 'PLANALTINA DO PARANA' },
    { id: 4119806, description: 'PLANALTO' },
    { id: 4119905, description: 'PONTA GROSSA' },
    { id: 4119954, description: 'PONTAL DO PARANA' },
    { id: 4120002, description: 'PORECATU' },
    { id: 4120101, description: 'PORTO AMAZONAS' },
    { id: 4120150, description: 'PORTO BARREIRO' },
    { id: 4120200, description: 'PORTO RICO' },
    { id: 4120309, description: 'PORTO VITORIA' },
    { id: 4120333, description: 'PRADO FERREIRA' },
    { id: 4120358, description: 'PRANCHITA' },
    { id: 4120408, description: 'PRESIDENTE CASTELO BRANCO' },
    { id: 4120507, description: 'PRIMEIRO DE MAIO' },
    { id: 4120606, description: 'PRUDENTOPOLIS' },
    { id: 4120655, description: 'QUARTO CENTENARIO' },
    { id: 4120705, description: 'QUATIGUA' },
    { id: 4120804, description: 'QUATRO BARRAS' },
    { id: 4120853, description: 'QUATRO PONTES' },
    { id: 4120903, description: 'QUEDAS DO IGUACU' },
    { id: 4121000, description: 'QUERENCIA DO NORTE' },
    { id: 4121109, description: 'QUINTA DO SOL' },
    { id: 4121208, description: 'QUITANDINHA' },
    { id: 4121257, description: 'RAMILANDIA' },
    { id: 4121307, description: 'RANCHO ALEGRE' },
    { id: 4121356, description: 'RANCHO ALEGRE D OESTE' },
    { id: 4121406, description: 'REALEZA' },
    { id: 4121505, description: 'REBOUCAS' },
    { id: 4121604, description: 'RENASCENCA' },
    { id: 4121703, description: 'RESERVA' },
    { id: 4121752, description: 'RESERVA DO IGUACU' },
    { id: 4121802, description: 'RIBEIRAO CLARO' },
    { id: 4121901, description: 'RIBEIRAO DO PINHAL' },
    { id: 4122008, description: 'RIO AZUL' },
    { id: 4122107, description: 'RIO BOM' },
    { id: 4122156, description: 'RIO BONITO DO IGUACU' },
    { id: 4122172, description: 'RIO BRANCO DO IVAI' },
    { id: 4122206, description: 'RIO BRANCO DO SUL' },
    { id: 4122305, description: 'RIO NEGRO' },
    { id: 4122404, description: 'ROLANDIA' },
    { id: 4122503, description: 'RONCADOR' },
    { id: 4122602, description: 'RONDON' },
    { id: 4122651, description: 'ROSARIO DO IVAI' },
    { id: 4122701, description: 'SABAUDIA' },
    { id: 4122800, description: 'SALGADO FILHO' },
    { id: 4122909, description: 'SALTO DO ITARARE' },
    { id: 4123006, description: 'SALTO DO LONTRA' },
    { id: 4123105, description: 'SANTA AMELIA' },
    { id: 4123204, description: 'SANTA CECILIA DO PAVAO' },
    { id: 4123303, description: 'SANTA CRUZ DE MONTE CASTELO' },
    { id: 4123402, description: 'SANTA FE' },
    { id: 4123501, description: 'SANTA HELENA' },
    { id: 4123600, description: 'SANTA INES' },
    { id: 4123709, description: 'SANTA ISABEL DO IVAI' },
    { id: 4123808, description: 'SANTA IZABEL DO OESTE' },
    { id: 4123824, description: 'SANTA LUCIA' },
    { id: 4123857, description: 'SANTA MARIA DO OESTE' },
    { id: 4123907, description: 'SANTA MARIANA' },
    { id: 4123956, description: 'SANTA MONICA' },
    { id: 4124004, description: 'SANTANA DO ITARARE' },
    { id: 4124020, description: 'SANTA TEREZA DO OESTE' },
    { id: 4124053, description: 'SANTA TEREZINHA DE ITAIPU' },
    { id: 4124103, description: 'SANTO ANTONIO DA PLATINA' },
    { id: 4124202, description: 'SANTO ANTONIO DO CAIUA' },
    { id: 4124301, description: 'SANTO ANTONIO DO PARAISO' },
    { id: 4124400, description: 'SANTO ANTONIO DO SUDOESTE' },
    { id: 4124509, description: 'SANTO INACIO' },
    { id: 4124608, description: 'SAO CARLOS DO IVAI' },
    { id: 4124707, description: 'SAO JERONIMO DA SERRA' },
    { id: 4124806, description: 'SAO JOAO' },
    { id: 4124905, description: 'SAO JOAO DO CAIUA' },
    { id: 4125001, description: 'SAO JOAO DO IVAI' },
    { id: 4125100, description: 'SAO JOAO DO TRIUNFO' },
    { id: 4125209, description: 'SAO JORGE D OESTE' },
    { id: 4125308, description: 'SAO JORGE DO IVAI' },
    { id: 4125357, description: 'SAO JORGE DO PATROCINIO' },
    { id: 4125407, description: 'SAO JOSE DA BOA VISTA' },
    { id: 4125456, description: 'SAO JOSE DAS PALMEIRAS' },
    { id: 4125506, description: 'SAO JOSE DOS PINHAIS' },
    { id: 4125555, description: 'SAO MANOEL DO PARANA' },
    { id: 4125605, description: 'SAO MATEUS DO SUL' },
    { id: 4125704, description: 'SAO MIGUEL DO IGUACU' },
    { id: 4125753, description: 'SAO PEDRO DO IGUACU' },
    { id: 4125803, description: 'SAO PEDRO DO IVAI' },
    { id: 4125902, description: 'SAO PEDRO DO PARANA' },
    { id: 4126009, description: 'SAO SEBASTIAO DA AMOREIRA' },
    { id: 4126108, description: 'SAO TOME' },
    { id: 4126207, description: 'SAPOPEMA' },
    { id: 4126256, description: 'SARANDI' },
    { id: 4126272, description: 'SAUDADE DO IGUACU' },
    { id: 4126306, description: 'SENGES' },
    { id: 4126355, description: 'SERRANOPOLIS DO IGUACU' },
    { id: 4126405, description: 'SERTANEJA' },
    { id: 4126504, description: 'SERTANOPOLIS' },
    { id: 4126603, description: 'SIQUEIRA CAMPOS' },
    { id: 4126652, description: 'SULINA' },
    { id: 4126678, description: 'TAMARANA' },
    { id: 4126702, description: 'TAMBOARA' },
    { id: 4126801, description: 'TAPEJARA' },
    { id: 4126900, description: 'TAPIRA' },
    { id: 4127007, description: 'TEIXEIRA SOARES' },
    { id: 4127106, description: 'TELEMACO BORBA' },
    { id: 4127205, description: 'TERRA BOA' },
    { id: 4127304, description: 'TERRA RICA' },
    { id: 4127403, description: 'TERRA ROXA' },
    { id: 4127502, description: 'TIBAGI' },
    { id: 4127601, description: 'TIJUCAS DO SUL' },
    { id: 4127700, description: 'TOLEDO' },
    { id: 4127809, description: 'TOMAZINA' },
    { id: 4127858, description: 'TRES BARRAS DO PARANA' },
    { id: 4127882, description: 'TUNAS DO PARANA' },
    { id: 4127908, description: 'TUNEIRAS DO OESTE' },
    { id: 4127957, description: 'TUPASSI' },
    { id: 4127965, description: 'TURVO' },
    { id: 4128005, description: 'UBIRATA' },
    { id: 4128104, description: 'UMUARAMA' },
    { id: 4128203, description: 'UNIAO DA VITORIA' },
    { id: 4128302, description: 'UNIFLOR' },
    { id: 4128401, description: 'URAI' },
    { id: 4128500, description: 'WENCESLAU BRAZ' },
    { id: 4128534, description: 'VENTANIA' },
    { id: 4128559, description: 'VERA CRUZ DO OESTE' },
    { id: 4128609, description: 'VERE' },
    { id: 4128625, description: 'ALTO PARAISO' },
    { id: 4128633, description: 'DOUTOR ULYSSES' },
    { id: 4128658, description: 'VIRMOND' },
    { id: 4128708, description: 'VITORINO' },
    { id: 4128807, description: 'XAMBRE' },
    { id: 4200051, description: 'ABDON BATISTA' },
    { id: 4200101, description: 'ABELARDO LUZ' },
    { id: 4200200, description: 'AGROLANDIA' },
    { id: 4200309, description: 'AGRONOMICA' },
    { id: 4200408, description: 'AGUA DOCE' },
    { id: 4200507, description: 'AGUAS DE CHAPECO' },
    { id: 4200556, description: 'AGUAS FRIAS' },
    { id: 4200606, description: 'AGUAS MORNAS' },
    { id: 4200705, description: 'ALFREDO WAGNER' },
    { id: 4200754, description: 'ALTO BELA VISTA' },
    { id: 4200804, description: 'ANCHIETA' },
    { id: 4200903, description: 'ANGELINA' },
    { id: 4201000, description: 'ANITA GARIBALDI' },
    { id: 4201109, description: 'ANITAPOLIS' },
    { id: 4201208, description: 'ANTONIO CARLOS' },
    { id: 4201257, description: 'APIUNA' },
    { id: 4201273, description: 'ARABUTA' },
    { id: 4201307, description: 'ARAQUARI' },
    { id: 4201406, description: 'ARARANGUA' },
    { id: 4201505, description: 'ARMAZEM' },
    { id: 4201604, description: 'ARROIO TRINTA' },
    { id: 4201653, description: 'ARVOREDO' },
    { id: 4201703, description: 'ASCURRA' },
    { id: 4201802, description: 'ATALANTA' },
    { id: 4201901, description: 'AURORA' },
    { id: 4201950, description: 'BALNEARIO ARROIO DO SILVA' },
    { id: 4202008, description: 'BALNEARIO CAMBORIU' },
    { id: 4202057, description: 'BALNEARIO BARRA DO SUL' },
    { id: 4202073, description: 'BALNEARIO GAIVOTA' },
    { id: 4202081, description: 'BANDEIRANTE' },
    { id: 4202099, description: 'BARRA BONITA' },
    { id: 4202107, description: 'BARRA VELHA' },
    { id: 4202131, description: 'BELA VISTA DO TOLDO' },
    { id: 4202156, description: 'BELMONTE' },
    { id: 4202206, description: 'BENEDITO NOVO' },
    { id: 4202305, description: 'BIGUACU' },
    { id: 4202404, description: 'BLUMENAU' },
    { id: 4202438, description: 'BOCAINA DO SUL' },
    { id: 4202453, description: 'BOMBINHAS' },
    { id: 4202503, description: 'BOM JARDIM DA SERRA' },
    { id: 4202537, description: 'BOM JESUS' },
    { id: 4202578, description: 'BOM JESUS DO OESTE' },
    { id: 4202602, description: 'BOM RETIRO' },
    { id: 4202701, description: 'BOTUVERA' },
    { id: 4202800, description: 'BRACO DO NORTE' },
    { id: 4202859, description: 'BRACO DO TROMBUDO' },
    { id: 4202875, description: 'BRUNOPOLIS' },
    { id: 4202909, description: 'BRUSQUE' },
    { id: 4203006, description: 'CACADOR' },
    { id: 4203105, description: 'CAIBI' },
    { id: 4203154, description: 'CALMON' },
    { id: 4203204, description: 'CAMBORIU' },
    { id: 4203253, description: 'CAPAO ALTO' },
    { id: 4203303, description: 'CAMPO ALEGRE' },
    { id: 4203402, description: 'CAMPO BELO DO SUL' },
    { id: 4203501, description: 'CAMPO ERE' },
    { id: 4203600, description: 'CAMPOS NOVOS' },
    { id: 4203709, description: 'CANELINHA' },
    { id: 4203808, description: 'CANOINHAS' },
    { id: 4203907, description: 'CAPINZAL' },
    { id: 4203956, description: 'CAPIVARI DE BAIXO' },
    { id: 4204004, description: 'CATANDUVAS' },
    { id: 4204103, description: 'CAXAMBU DO SUL' },
    { id: 4204152, description: 'CELSO RAMOS' },
    { id: 4204178, description: 'CERRO NEGRO' },
    { id: 4204194, description: 'CHAPADAO DO LAGEADO' },
    { id: 4204202, description: 'CHAPECO' },
    { id: 4204251, description: 'COCAL DO SUL' },
    { id: 4204301, description: 'CONCORDIA' },
    { id: 4204350, description: 'CORDILHEIRA ALTA' },
    { id: 4204400, description: 'CORONEL FREITAS' },
    { id: 4204459, description: 'CORONEL MARTINS' },
    { id: 4204509, description: 'CORUPA' },
    { id: 4204558, description: 'CORREIA PINTO' },
    { id: 4204608, description: 'CRICIUMA' },
    { id: 4204707, description: 'CUNHA PORA' },
    { id: 4204756, description: 'CUNHATAI' },
    { id: 4204806, description: 'CURITIBANOS' },
    { id: 4204905, description: 'DESCANSO' },
    { id: 4205001, description: 'DIONISIO CERQUEIRA' },
    { id: 4205100, description: 'DONA EMMA' },
    { id: 4205159, description: 'DOUTOR PEDRINHO' },
    { id: 4205175, description: 'ENTRE RIOS' },
    { id: 4205191, description: 'ERMO' },
    { id: 4205209, description: 'ERVAL VELHO' },
    { id: 4205308, description: 'FAXINAL DOS GUEDES' },
    { id: 4205357, description: 'FLOR DO SERTAO' },
    { id: 4205407, description: 'FLORIANOPOLIS' },
    { id: 4205431, description: 'FORMOSA DO SUL' },
    { id: 4205456, description: 'FORQUILHINHA' },
    { id: 4205506, description: 'FRAIBURGO' },
    { id: 4205555, description: 'FREI ROGERIO' },
    { id: 4205605, description: 'GALVAO' },
    { id: 4205704, description: 'GAROPABA' },
    { id: 4205803, description: 'GARUVA' },
    { id: 4205902, description: 'GASPAR' },
    { id: 4206009, description: 'GOVERNADOR CELSO RAMOS' },
    { id: 4206108, description: 'GRAO PARA' },
    { id: 4206207, description: 'GRAVATAL' },
    { id: 4206306, description: 'GUABIRUBA' },
    { id: 4206405, description: 'GUARACIABA' },
    { id: 4206504, description: 'GUARAMIRIM' },
    { id: 4206603, description: 'GUARUJA DO SUL' },
    { id: 4206652, description: 'GUATAMBU' },
    { id: 4206702, description: 'HERVAL D OESTE' },
    { id: 4206751, description: 'IBIAM' },
    { id: 4206801, description: 'IBICARE' },
    { id: 4206900, description: 'IBIRAMA' },
    { id: 4207007, description: 'ICARA' },
    { id: 4207106, description: 'ILHOTA' },
    { id: 4207205, description: 'IMARUI' },
    { id: 4207304, description: 'IMBITUBA' },
    { id: 4207403, description: 'IMBUIA' },
    { id: 4207502, description: 'INDAIAL' },
    { id: 4207577, description: 'IOMERE' },
    { id: 4207601, description: 'IPIRA' },
    { id: 4207650, description: 'IPORA DO OESTE' },
    { id: 4207684, description: 'IPUACU' },
    { id: 4207700, description: 'IPUMIRIM' },
    { id: 4207759, description: 'IRACEMINHA' },
    { id: 4207809, description: 'IRANI' },
    { id: 4207858, description: 'IRATI' },
    { id: 4207908, description: 'IRINEOPOLIS' },
    { id: 4208005, description: 'ITA' },
    { id: 4208104, description: 'ITAIOPOLIS' },
    { id: 4208203, description: 'ITAJAI' },
    { id: 4208302, description: 'ITAPEMA' },
    { id: 4208401, description: 'ITAPIRANGA' },
    { id: 4208450, description: 'ITAPOA' },
    { id: 4208500, description: 'ITUPORANGA' },
    { id: 4208609, description: 'JABORA' },
    { id: 4208708, description: 'JACINTO MACHADO' },
    { id: 4208807, description: 'JAGUARUNA' },
    { id: 4208906, description: 'JARAGUA DO SUL' },
    { id: 4208955, description: 'JARDINOPOLIS' },
    { id: 4209003, description: 'JOACABA' },
    { id: 4209102, description: 'JOINVILLE' },
    { id: 4209151, description: 'JOSE BOITEUX' },
    { id: 4209177, description: 'JUPIA' },
    { id: 4209201, description: 'LACERDOPOLIS' },
    { id: 4209300, description: 'LAGES' },
    { id: 4209409, description: 'LAGUNA' },
    { id: 4209458, description: 'LAJEADO GRANDE' },
    { id: 4209508, description: 'LAURENTINO' },
    { id: 4209607, description: 'LAURO MUeLLE' },
    { id: 4209706, description: 'LEBON REGIS' },
    { id: 4209805, description: 'LEOBERTO LEAL' },
    { id: 4209854, description: 'LINDOIA DO SUL' },
    { id: 4209904, description: 'LONTRAS' },
    { id: 4210001, description: 'LUIZ ALVES' },
    { id: 4210035, description: 'LUZERNA' },
    { id: 4210050, description: 'MACIEIRA' },
    { id: 4210100, description: 'MAFRA' },
    { id: 4210209, description: 'MAJOR GERCINO' },
    { id: 4210308, description: 'MAJOR VIEIRA' },
    { id: 4210407, description: 'MARACAJA' },
    { id: 4210506, description: 'MARAVILHA' },
    { id: 4210555, description: 'MAREMA' },
    { id: 4210605, description: 'MASSARANDUBA' },
    { id: 4210704, description: 'MATOS COSTA' },
    { id: 4210803, description: 'MELEIRO' },
    { id: 4210852, description: 'MIRIM DOCE' },
    { id: 4210902, description: 'MODELO' },
    { id: 4211009, description: 'MONDAI' },
    { id: 4211058, description: 'MONTE CARLO' },
    { id: 4211108, description: 'MONTE CASTELO' },
    { id: 4211207, description: 'MORRO DA FUMACA' },
    { id: 4211256, description: 'MORRO GRANDE' },
    { id: 4211306, description: 'NAVEGANTES' },
    { id: 4211405, description: 'NOVA ERECHIM' },
    { id: 4211454, description: 'NOVA ITABERABA' },
    { id: 4211504, description: 'NOVA TRENTO' },
    { id: 4211603, description: 'NOVA VENEZA' },
    { id: 4211652, description: 'NOVO HORIZONTE' },
    { id: 4211702, description: 'ORLEANS' },
    { id: 4211751, description: 'OTACILIO COSTA' },
    { id: 4211801, description: 'OURO' },
    { id: 4211850, description: 'OURO VERDE' },
    { id: 4211876, description: 'PAIAL' },
    { id: 4211892, description: 'PAINEL' },
    { id: 4211900, description: 'PALHOCA' },
    { id: 4212007, description: 'PALMA SOLA' },
    { id: 4212056, description: 'PALMEIRA' },
    { id: 4212106, description: 'PALMITOS' },
    { id: 4212205, description: 'PAPANDUVA' },
    { id: 4212239, description: 'PARAISO' },
    { id: 4212254, description: 'PASSO DE TORRES' },
    { id: 4212270, description: 'PASSOS MAIA' },
    { id: 4212304, description: 'PAULO LOPES' },
    { id: 4212403, description: 'PEDRAS GRANDES' },
    { id: 4212502, description: 'PENHA' },
    { id: 4212601, description: 'PERITIBA' },
    { id: 4212650, description: 'PESCARIA BRAVA' },
    { id: 4212700, description: 'PETROLANDIA' },
    { id: 4212809, description: 'BALNEARIO PICARRAS' },
    { id: 4212908, description: 'PINHALZINHO' },
    { id: 4213005, description: 'PINHEIRO PRETO' },
    { id: 4213104, description: 'PIRATUBA' },
    { id: 4213153, description: 'PLANALTO ALEGRE' },
    { id: 4213203, description: 'POMERODE' },
    { id: 4213302, description: 'PONTE ALTA' },
    { id: 4213351, description: 'PONTE ALTA DO NORTE' },
    { id: 4213401, description: 'PONTE SERRADA' },
    { id: 4213500, description: 'PORTO BELO' },
    { id: 4213609, description: 'PORTO UNIAO' },
    { id: 4213708, description: 'POUSO REDONDO' },
    { id: 4213807, description: 'PRAIA GRANDE' },
    { id: 4213906, description: 'PRESIDENTE CASTELLO BRANCO' },
    { id: 4214003, description: 'PRESIDENTE GETULIO' },
    { id: 4214102, description: 'PRESIDENTE NEREU' },
    { id: 4214151, description: 'PRINCESA' },
    { id: 4214201, description: 'QUILOMBO' },
    { id: 4214300, description: 'RANCHO QUEIMADO' },
    { id: 4214409, description: 'RIO DAS ANTAS' },
    { id: 4214508, description: 'RIO DO CAMPO' },
    { id: 4214607, description: 'RIO DO OESTE' },
    { id: 4214706, description: 'RIO DOS CEDROS' },
    { id: 4214805, description: 'RIO DO SUL' },
    { id: 4214904, description: 'RIO FORTUNA' },
    { id: 4215000, description: 'RIO NEGRINHO' },
    { id: 4215059, description: 'RIO RUFINO' },
    { id: 4215075, description: 'RIQUEZA' },
    { id: 4215109, description: 'RODEIO' },
    { id: 4215208, description: 'ROMELANDIA' },
    { id: 4215307, description: 'SALETE' },
    { id: 4215356, description: 'SALTINHO' },
    { id: 4215406, description: 'SALTO VELOSO' },
    { id: 4215455, description: 'SANGAO' },
    { id: 4215505, description: 'SANTA CECILIA' },
    { id: 4215554, description: 'SANTA HELENA' },
    { id: 4215604, description: 'SANTA ROSA DE LIMA' },
    { id: 4215653, description: 'SANTA ROSA DO SUL' },
    { id: 4215679, description: 'SANTA TEREZINHA' },
    { id: 4215687, description: 'SANTA TEREZINHA DO PROGRESSO' },
    { id: 4215695, description: 'SANTIAGO DO SUL' },
    { id: 4215703, description: 'SANTO AMARO DA IMPERATRIZ' },
    { id: 4215752, description: 'SAO BERNARDINO' },
    { id: 4215802, description: 'SAO BENTO DO SUL' },
    { id: 4215901, description: 'SAO BONIFACIO' },
    { id: 4216008, description: 'SAO CARLOS' },
    { id: 4216057, description: 'SAO CRISTOVAO DO SUL' },
    { id: 4216107, description: 'SAO DOMINGOS' },
    { id: 4216206, description: 'SAO FRANCISCO DO SUL' },
    { id: 4216255, description: 'SAO JOAO DO OESTE' },
    { id: 4216305, description: 'SAO JOAO BATISTA' },
    { id: 4216354, description: 'SAO JOAO DO ITAPERIU' },
    { id: 4216404, description: 'SAO JOAO DO SUL' },
    { id: 4216503, description: 'SAO JOAQUIM' },
    { id: 4216602, description: 'SAO JOSE' },
    { id: 4216701, description: 'SAO JOSE DO CEDRO' },
    { id: 4216800, description: 'SAO JOSE DO CERRITO' },
    { id: 4216909, description: 'SAO LOURENCO DO OESTE' },
    { id: 4217006, description: 'SAO LUDGERO' },
    { id: 4217105, description: 'SAO MARTINHO' },
    { id: 4217154, description: 'SAO MIGUEL DA BOA VISTA' },
    { id: 4217204, description: 'SAO MIGUEL DO OESTE' },
    { id: 4217253, description: 'SAO PEDRO DE ALCANTARA' },
    { id: 4217303, description: 'SAUDADES' },
    { id: 4217402, description: 'SCHROEDER' },
    { id: 4217501, description: 'SEARA' },
    { id: 4217550, description: 'SERRA ALTA' },
    { id: 4217600, description: 'SIDEROPOLIS' },
    { id: 4217709, description: 'SOMBRIO' },
    { id: 4217758, description: 'SUL BRASIL' },
    { id: 4217808, description: 'TAIO' },
    { id: 4217907, description: 'TANGARA' },
    { id: 4217956, description: 'TIGRINHOS' },
    { id: 4218004, description: 'TIJUCAS' },
    { id: 4218103, description: 'TIMBE DO SUL' },
    { id: 4218202, description: 'TIMBO' },
    { id: 4218251, description: 'TIMBO GRANDE' },
    { id: 4218301, description: 'TRES BARRAS' },
    { id: 4218350, description: 'TREVISO' },
    { id: 4218400, description: 'TREZE DE MAIO' },
    { id: 4218509, description: 'TREZE TILIAS' },
    { id: 4218608, description: 'TROMBUDO CENTRAL' },
    { id: 4218707, description: 'TUBARAO' },
    { id: 4218756, description: 'TUNAPOLIS' },
    { id: 4218806, description: 'TURVO' },
    { id: 4218855, description: 'UNIAO DO OESTE' },
    { id: 4218905, description: 'URUBICI' },
    { id: 4218954, description: 'URUPEMA' },
    { id: 4219002, description: 'URUSSANGA' },
    { id: 4219101, description: 'VARGEAO' },
    { id: 4219150, description: 'VARGEM' },
    { id: 4219176, description: 'VARGEM BONITA' },
    { id: 4219200, description: 'VIDAL RAMOS' },
    { id: 4219309, description: 'VIDEIRA' },
    { id: 4219358, description: 'VITOR MEIRELES' },
    { id: 4219408, description: 'WITMARSUM' },
    { id: 4219507, description: 'XANXERE' },
    { id: 4219606, description: 'XAVANTINA' },
    { id: 4219705, description: 'XAXIM' },
    { id: 4219853, description: 'ZORTEA' },
    { id: 4220000, description: 'BALNEARIO RINCAO' },
    { id: 4300002, description: 'LAGOA DOS PATOS' },
    { id: 4300034, description: 'ACEGUA' },
    { id: 4300059, description: 'AGUA SANTA' },
    { id: 4300109, description: 'AGUDO' },
    { id: 4300208, description: 'AJURICABA' },
    { id: 4300307, description: 'ALECRIM' },
    { id: 4300406, description: 'ALEGRETE' },
    { id: 4300455, description: 'ALEGRIA' },
    { id: 4300471, description: 'ALMIRANTE TAMANDARE DO SUL' },
    { id: 4300505, description: 'ALPESTRE' },
    { id: 4300554, description: 'ALTO ALEGRE' },
    { id: 4300570, description: 'ALTO FELIZ' },
    { id: 4300604, description: 'ALVORADA' },
    { id: 4300638, description: 'AMARAL FERRADOR' },
    { id: 4300646, description: 'AMETISTA DO SUL' },
    { id: 4300661, description: 'ANDRE DA ROCHA' },
    { id: 4300703, description: 'ANTA GORDA' },
    { id: 4300802, description: 'ANTONIO PRADO' },
    { id: 4300851, description: 'ARAMBARE' },
    { id: 4300877, description: 'ARARICA' },
    { id: 4300901, description: 'ARATIBA' },
    { id: 4301008, description: 'ARROIO DO MEIO' },
    { id: 4301057, description: 'ARROIO DO SAL' },
    { id: 4301073, description: 'ARROIO DO PADRE' },
    { id: 4301107, description: 'ARROIO DOS RATOS' },
    { id: 4301206, description: 'ARROIO DO TIGRE' },
    { id: 4301305, description: 'ARROIO GRANDE' },
    { id: 4301404, description: 'ARVOREZINHA' },
    { id: 4301503, description: 'AUGUSTO PESTANA' },
    { id: 4301552, description: 'AUREA' },
    { id: 4301602, description: 'BAGE' },
    { id: 4301636, description: 'BALNEARIO PINHAL' },
    { id: 4301651, description: 'BARAO' },
    { id: 4301701, description: 'BARAO DE COTEGIPE' },
    { id: 4301750, description: 'BARAO DO TRIUNFO' },
    { id: 4301800, description: 'BARRACAO' },
    { id: 4301859, description: 'BARRA DO GUARITA' },
    { id: 4301875, description: 'BARRA DO QUARAI' },
    { id: 4301909, description: 'BARRA DO RIBEIRO' },
    { id: 4301925, description: 'BARRA DO RIO AZUL' },
    { id: 4301958, description: 'BARRA FUNDA' },
    { id: 4302006, description: 'BARROS CASSAL' },
    { id: 4302055, description: 'BENJAMIN CONSTANT DO SUL' },
    { id: 4302105, description: 'BENTO GONCALVES' },
    { id: 4302154, description: 'BOA VISTA DAS MISSOES' },
    { id: 4302204, description: 'BOA VISTA DO BURICA' },
    { id: 4302220, description: 'BOA VISTA DO CADEADO' },
    { id: 4302238, description: 'BOA VISTA DO INCRA' },
    { id: 4302253, description: 'BOA VISTA DO SUL' },
    { id: 4302303, description: 'BOM JESUS' },
    { id: 4302352, description: 'BOM PRINCIPIO' },
    { id: 4302378, description: 'BOM PROGRESSO' },
    { id: 4302402, description: 'BOM RETIRO DO SUL' },
    { id: 4302451, description: 'BOQUEIRAO DO LEAO' },
    { id: 4302501, description: 'BOSSOROCA' },
    { id: 4302584, description: 'BOZANO' },
    { id: 4302600, description: 'BRAGA' },
    { id: 4302659, description: 'BROCHIER' },
    { id: 4302709, description: 'BUTIA' },
    { id: 4302808, description: 'CACAPAVA DO SUL' },
    { id: 4302907, description: 'CACEQUI' },
    { id: 4303004, description: 'CACHOEIRA DO SUL' },
    { id: 4303103, description: 'CACHOEIRINHA' },
    { id: 4303202, description: 'CACIQUE DOBLE' },
    { id: 4303301, description: 'CAIBATE' },
    { id: 4303400, description: 'CAICARA' },
    { id: 4303509, description: 'CAMAQUA' },
    { id: 4303558, description: 'CAMARGO' },
    { id: 4303608, description: 'CAMBARA DO SUL' },
    { id: 4303673, description: 'CAMPESTRE DA SERRA' },
    { id: 4303707, description: 'CAMPINA DAS MISSOES' },
    { id: 4303806, description: 'CAMPINAS DO SUL' },
    { id: 4303905, description: 'CAMPO BOM' },
    { id: 4304002, description: 'CAMPO NOVO' },
    { id: 4304101, description: 'CAMPOS BORGES' },
    { id: 4304200, description: 'CANDELARIA' },
    { id: 4304309, description: 'CANDIDO GODOI' },
    { id: 4304358, description: 'CANDIOTA' },
    { id: 4304408, description: 'CANELA' },
    { id: 4304507, description: 'CANGUCU' },
    { id: 4304606, description: 'CANOAS' },
    { id: 4304614, description: 'CANUDOS DO VALE' },
    { id: 4304622, description: 'CAPAO BONITO DO SUL' },
    { id: 4304630, description: 'CAPAO DA CANOA' },
    { id: 4304655, description: 'CAPAO DO CIPO' },
    { id: 4304663, description: 'CAPAO DO LEAO' },
    { id: 4304671, description: 'CAPIVARI DO SUL' },
    { id: 4304689, description: 'CAPELA DE SANTANA' },
    { id: 4304697, description: 'CAPITAO' },
    { id: 4304705, description: 'CARAZINHO' },
    { id: 4304713, description: 'CARAA' },
    { id: 4304804, description: 'CARLOS BARBOSA' },
    { id: 4304853, description: 'CARLOS GOMES' },
    { id: 4304903, description: 'CASCA' },
    { id: 4304952, description: 'CASEIROS' },
    { id: 4305009, description: 'CATUIPE' },
    { id: 4305108, description: 'CAXIAS DO SUL' },
    { id: 4305116, description: 'CENTENARIO' },
    { id: 4305124, description: 'CERRITO' },
    { id: 4305132, description: 'CERRO BRANCO' },
    { id: 4305157, description: 'CERRO GRANDE' },
    { id: 4305173, description: 'CERRO GRANDE DO SUL' },
    { id: 4305207, description: 'CERRO LARGO' },
    { id: 4305306, description: 'CHAPADA' },
    { id: 4305355, description: 'CHARQUEADAS' },
    { id: 4305371, description: 'CHARRUA' },
    { id: 4305405, description: 'CHIAPETTA' },
    { id: 4305439, description: 'CHUI' },
    { id: 4305447, description: 'CHUVISCA' },
    { id: 4305454, description: 'CIDREIRA' },
    { id: 4305504, description: 'CIRIACO' },
    { id: 4305587, description: 'COLINAS' },
    { id: 4305603, description: 'COLORADO' },
    { id: 4305702, description: 'CONDOR' },
    { id: 4305801, description: 'CONSTANTINA' },
    { id: 4305835, description: 'COQUEIRO BAIXO' },
    { id: 4305850, description: 'COQUEIROS DO SUL' },
    { id: 4305871, description: 'CORONEL BARROS' },
    { id: 4305900, description: 'CORONEL BICACO' },
    { id: 4305934, description: 'CORONEL PILAR' },
    { id: 4305959, description: 'COTIPORA' },
    { id: 4305975, description: 'COXILHA' },
    { id: 4306007, description: 'CRISSIUMAL' },
    { id: 4306056, description: 'CRISTAL' },
    { id: 4306072, description: 'CRISTAL DO SUL' },
    { id: 4306106, description: 'CRUZ ALTA' },
    { id: 4306130, description: 'CRUZALTENSE' },
    { id: 4306205, description: 'CRUZEIRO DO SUL' },
    { id: 4306304, description: 'DAVID CANABARRO' },
    { id: 4306320, description: 'DERRUBADAS' },
    { id: 4306353, description: 'DEZESSEIS DE NOVEMBRO' },
    { id: 4306379, description: 'DILERMANDO DE AGUIAR' },
    { id: 4306403, description: 'DOIS IRMAOS' },
    { id: 4306429, description: 'DOIS IRMAOS DAS MISSOES' },
    { id: 4306452, description: 'DOIS LAJEADOS' },
    { id: 4306502, description: 'DOM FELICIANO' },
    { id: 4306551, description: 'DOM PEDRO DE ALCANTARA' },
    { id: 4306601, description: 'DOM PEDRITO' },
    { id: 4306700, description: 'DONA FRANCISCA' },
    { id: 4306734, description: 'DOUTOR MAURICIO CARDOSO' },
    { id: 4306759, description: 'DOUTOR RICARDO' },
    { id: 4306767, description: 'ELDORADO DO SUL' },
    { id: 4306809, description: 'ENCANTADO' },
    { id: 4306908, description: 'ENCRUZILHADA DO SUL' },
    { id: 4306924, description: 'ENGENHO VELHO' },
    { id: 4306932, description: 'ENTRE IJUIS' },
    { id: 4306957, description: 'ENTRE RIOS DO SUL' },
    { id: 4306973, description: 'EREBANGO' },
    { id: 4307005, description: 'ERECHIM' },
    { id: 4307054, description: 'ERNESTINA' },
    { id: 4307104, description: 'HERVAL' },
    { id: 4307203, description: 'ERVAL GRANDE' },
    { id: 4307302, description: 'ERVAL SECO' },
    { id: 4307401, description: 'ESMERALDA' },
    { id: 4307450, description: 'ESPERANCA DO SUL' },
    { id: 4307500, description: 'ESPUMOSO' },
    { id: 4307559, description: 'ESTACAO' },
    { id: 4307609, description: 'ESTANCIA VELHA' },
    { id: 4307708, description: 'ESTEIO' },
    { id: 4307807, description: 'ESTRELA' },
    { id: 4307815, description: 'ESTRELA VELHA' },
    { id: 4307831, description: 'EUGENIO DE CASTRO' },
    { id: 4307864, description: 'FAGUNDES VARELA' },
    { id: 4307906, description: 'FARROUPILHA' },
    { id: 4308003, description: 'FAXINAL DO SOTURNO' },
    { id: 4308052, description: 'FAXINALZINHO' },
    { id: 4308078, description: 'FAZENDA VILANOVA' },
    { id: 4308102, description: 'FELIZ' },
    { id: 4308201, description: 'FLORES DA CUNHA' },
    { id: 4308250, description: 'FLORIANO PEIXOTO' },
    { id: 4308300, description: 'FONTOURA XAVIER' },
    { id: 4308409, description: 'FORMIGUEIRO' },
    { id: 4308433, description: 'FORQUETINHA' },
    { id: 4308458, description: 'FORTALEZA DOS VALOS' },
    { id: 4308508, description: 'FREDERICO WESTPHALEN' },
    { id: 4308607, description: 'GARIBALDI' },
    { id: 4308656, description: 'GARRUCHOS' },
    { id: 4308706, description: 'GAURAMA' },
    { id: 4308805, description: 'GENERAL CAMARA' },
    { id: 4308854, description: 'GENTIL' },
    { id: 4308904, description: 'GETULIO VARGAS' },
    { id: 4309001, description: 'GIRUA' },
    { id: 4309050, description: 'GLORINHA' },
    { id: 4309100, description: 'GRAMADO' },
    { id: 4309126, description: 'GRAMADO DOS LOUREIROS' },
    { id: 4309159, description: 'GRAMADO XAVIER' },
    { id: 4309209, description: 'GRAVATAI' },
    { id: 4309258, description: 'GUABIJU' },
    { id: 4309308, description: 'GUAIBA' },
    { id: 4309407, description: 'GUAPORE' },
    { id: 4309506, description: 'GUARANI DAS MISSOES' },
    { id: 4309555, description: 'HARMONIA' },
    { id: 4309571, description: 'HERVEIRAS' },
    { id: 4309605, description: 'HORIZONTINA' },
    { id: 4309654, description: 'HULHA NEGRA' },
    { id: 4309704, description: 'HUMAITA' },
    { id: 4309753, description: 'IBARAMA' },
    { id: 4309803, description: 'IBIACA' },
    { id: 4309902, description: 'IBIRAIARAS' },
    { id: 4309951, description: 'IBIRAPUITA' },
    { id: 4310009, description: 'IBIRUBA' },
    { id: 4310108, description: 'IGREJINHA' },
    { id: 4310207, description: 'IJUI' },
    { id: 4310306, description: 'ILOPOLIS' },
    { id: 4310330, description: 'IMBE' },
    { id: 4310363, description: 'IMIGRANTE' },
    { id: 4310405, description: 'INDEPENDENCIA' },
    { id: 4310413, description: 'INHACORA' },
    { id: 4310439, description: 'IPE' },
    { id: 4310462, description: 'IPIRANGA DO SUL' },
    { id: 4310504, description: 'IRAI' },
    { id: 4310538, description: 'ITAARA' },
    { id: 4310553, description: 'ITACURUBI' },
    { id: 4310579, description: 'ITAPUCA' },
    { id: 4310603, description: 'ITAQUI' },
    { id: 4310652, description: 'ITATI' },
    { id: 4310702, description: 'ITATIBA DO SUL' },
    { id: 4310751, description: 'IVORA' },
    { id: 4310801, description: 'IVOTI' },
    { id: 4310850, description: 'JABOTICABA' },
    { id: 4310876, description: 'JACUIZINHO' },
    { id: 4310900, description: 'JACUTINGA' },
    { id: 4311007, description: 'JAGUARAO' },
    { id: 4311106, description: 'JAGUARI' },
    { id: 4311122, description: 'JAQUIRANA' },
    { id: 4311130, description: 'JARI' },
    { id: 4311155, description: 'JOIA' },
    { id: 4311205, description: 'JULIO DE CASTILHOS' },
    { id: 4311239, description: 'LAGOA BONITA DO SUL' },
    { id: 4311254, description: 'LAGOAO' },
    { id: 4311270, description: 'LAGOA DOS TRES CANTOS' },
    { id: 4311304, description: 'LAGOA VERMELHA' },
    { id: 4311403, description: 'LAJEADO' },
    { id: 4311429, description: 'LAJEADO DO BUGRE' },
    { id: 4311502, description: 'LAVRAS DO SUL' },
    { id: 4311601, description: 'LIBERATO SALZANO' },
    { id: 4311627, description: 'LINDOLFO COLLOR' },
    { id: 4311643, description: 'LINHA NOVA' },
    { id: 4311700, description: 'MACHADINHO' },
    { id: 4311718, description: 'MACAMBARA' },
    { id: 4311734, description: 'MAMPITUBA' },
    { id: 4311759, description: 'MANOEL VIANA' },
    { id: 4311775, description: 'MAQUINE' },
    { id: 4311791, description: 'MARATA' },
    { id: 4311809, description: 'MARAU' },
    { id: 4311908, description: 'MARCELINO RAMOS' },
    { id: 4311981, description: 'MARIANA PIMENTEL' },
    { id: 4312005, description: 'MARIANO MORO' },
    { id: 4312054, description: 'MARQUES DE SOUZA' },
    { id: 4312104, description: 'MATA' },
    { id: 4312138, description: 'MATO CASTELHANO' },
    { id: 4312153, description: 'MATO LEITAO' },
    { id: 4312179, description: 'MATO QUEIMADO' },
    { id: 4312203, description: 'MAXIMILIANO DE ALMEIDA' },
    { id: 4312252, description: 'MINAS DO LEAO' },
    { id: 4312302, description: 'MIRAGUAI' },
    { id: 4312351, description: 'MONTAURI' },
    { id: 4312377, description: 'MONTE ALEGRE DOS CAMPOS' },
    { id: 4312385, description: 'MONTE BELO DO SUL' },
    { id: 4312401, description: 'MONTENEGRO' },
    { id: 4312427, description: 'MORMACO' },
    { id: 4312443, description: 'MORRINHOS DO SUL' },
    { id: 4312450, description: 'MORRO REDONDO' },
    { id: 4312476, description: 'MORRO REUTER' },
    { id: 4312500, description: 'MOSTARDAS' },
    { id: 4312609, description: 'MUCUM' },
    { id: 4312617, description: 'MUITOS CAPOES' },
    { id: 4312625, description: 'MULITERNO' },
    { id: 4312658, description: 'NAO ME TOQUE' },
    { id: 4312674, description: 'NICOLAU VERGUEIRO' },
    { id: 4312708, description: 'NONOAI' },
    { id: 4312757, description: 'NOVA ALVORADA' },
    { id: 4312807, description: 'NOVA ARACA' },
    { id: 4312906, description: 'NOVA BASSANO' },
    { id: 4312955, description: 'NOVA BOA VISTA' },
    { id: 4313003, description: 'NOVA BRESCIA' },
    { id: 4313011, description: 'NOVA CANDELARIA' },
    { id: 4313037, description: 'NOVA ESPERANCA DO SUL' },
    { id: 4313060, description: 'NOVA HARTZ' },
    { id: 4313086, description: 'NOVA PADUA' },
    { id: 4313102, description: 'NOVA PALMA' },
    { id: 4313201, description: 'NOVA PETROPOLIS' },
    { id: 4313300, description: 'NOVA PRATA' },
    { id: 4313334, description: 'NOVA RAMADA' },
    { id: 4313359, description: 'NOVA ROMA DO SUL' },
    { id: 4313375, description: 'NOVA SANTA RITA' },
    { id: 4313391, description: 'NOVO CABRAIS' },
    { id: 4313409, description: 'NOVO HAMBURGO' },
    { id: 4313425, description: 'NOVO MACHADO' },
    { id: 4313441, description: 'NOVO TIRADENTES' },
    { id: 4313466, description: 'NOVO XINGU' },
    { id: 4313490, description: 'NOVO BARREIRO' },
    { id: 4313508, description: 'OSORIO' },
    { id: 4313607, description: 'PAIM FILHO' },
    { id: 4313656, description: 'PALMARES DO SUL' },
    { id: 4313706, description: 'PALMEIRA DAS MISSOES' },
    { id: 4313805, description: 'PALMITINHO' },
    { id: 4313904, description: 'PANAMBI' },
    { id: 4313953, description: 'PANTANO GRANDE' },
    { id: 4314001, description: 'PARAI' },
    { id: 4314027, description: 'PARAISO DO SUL' },
    { id: 4314035, description: 'PARECI NOVO' },
    { id: 4314050, description: 'PAROBE' },
    { id: 4314068, description: 'PASSA SETE' },
    { id: 4314076, description: 'PASSO DO SOBRADO' },
    { id: 4314100, description: 'PASSO FUNDO' },
    { id: 4314134, description: 'PAULO BENTO' },
    { id: 4314159, description: 'PAVERAMA' },
    { id: 4314175, description: 'PEDRAS ALTAS' },
    { id: 4314209, description: 'PEDRO OSORIO' },
    { id: 4314308, description: 'PEJUCARA' },
    { id: 4314407, description: 'PELOTAS' },
    { id: 4314423, description: 'PICADA CAFE' },
    { id: 4314456, description: 'PINHAL' },
    { id: 4314464, description: 'PINHAL DA SERRA' },
    { id: 4314472, description: 'PINHAL GRANDE' },
    { id: 4314498, description: 'PINHEIRINHO DO VALE' },
    { id: 4314506, description: 'PINHEIRO MACHADO' },
    { id: 4314548, description: 'PINTO BANDEIRA' },
    { id: 4314555, description: 'PIRAPO' },
    { id: 4314605, description: 'PIRATINI' },
    { id: 4314704, description: 'PLANALTO' },
    { id: 4314753, description: 'POCO DAS ANTAS' },
    { id: 4314779, description: 'PONTAO' },
    { id: 4314787, description: 'PONTE PRETA' },
    { id: 4314803, description: 'PORTAO' },
    { id: 4314902, description: 'PORTO ALEGRE' },
    { id: 4315008, description: 'PORTO LUCENA' },
    { id: 4315057, description: 'PORTO MAUA' },
    { id: 4315073, description: 'PORTO VERA CRUZ' },
    { id: 4315107, description: 'PORTO XAVIER' },
    { id: 4315131, description: 'POUSO NOVO' },
    { id: 4315149, description: 'PRESIDENTE LUCENA' },
    { id: 4315156, description: 'PROGRESSO' },
    { id: 4315172, description: 'PROTASIO ALVES' },
    { id: 4315206, description: 'PUTINGA' },
    { id: 4315305, description: 'QUARAI' },
    { id: 4315313, description: 'QUATRO IRMAOS' },
    { id: 4315321, description: 'QUEVEDOS' },
    { id: 4315354, description: 'QUINZE DE NOVEMBRO' },
    { id: 4315404, description: 'REDENTORA' },
    { id: 4315453, description: 'RELVADO' },
    { id: 4315503, description: 'RESTINGA SECA' },
    { id: 4315552, description: 'RIO DOS INDIOS' },
    { id: 4315602, description: 'RIO GRANDE' },
    { id: 4315701, description: 'RIO PARDO' },
    { id: 4315750, description: 'RIOZINHO' },
    { id: 4315800, description: 'ROCA SALES' },
    { id: 4315909, description: 'RODEIO BONITO' },
    { id: 4315958, description: 'ROLADOR' },
    { id: 4316006, description: 'ROLANTE' },
    { id: 4316105, description: 'RONDA ALTA' },
    { id: 4316204, description: 'RONDINHA' },
    { id: 4316303, description: 'ROQUE GONZALES' },
    { id: 4316402, description: 'ROSARIO DO SUL' },
    { id: 4316428, description: 'SAGRADA FAMILIA' },
    { id: 4316436, description: 'SALDANHA MARINHO' },
    { id: 4316451, description: 'SALTO DO JACUI' },
    { id: 4316477, description: 'SALVADOR DAS MISSOES' },
    { id: 4316501, description: 'SALVADOR DO SUL' },
    { id: 4316600, description: 'SANANDUVA' },
    { id: 4316709, description: 'SANTA BARBARA DO SUL' },
    { id: 4316733, description: 'SANTA CECILIA DO SUL' },
    { id: 4316758, description: 'SANTA CLARA DO SUL' },
    { id: 4316808, description: 'SANTA CRUZ DO SUL' },
    { id: 4316907, description: 'SANTA MARIA' },
    { id: 4316956, description: 'SANTA MARIA DO HERVAL' },
    { id: 4316972, description: 'SANTA MARGARIDA DO SUL' },
    { id: 4317004, description: 'SANTANA DA BOA VISTA' },
    { id: 4317103, description: 'SANTANA DO LIVRAMENTO' },
    { id: 4317202, description: 'SANTA ROSA' },
    { id: 4317251, description: 'SANTA TEREZA' },
    { id: 4317301, description: 'SANTA VITORIA DO PALMAR' },
    { id: 4317400, description: 'SANTIAGO' },
    { id: 4317509, description: 'SANTO ANGELO' },
    { id: 4317558, description: 'SANTO ANTONIO DO PALMA' },
    { id: 4317608, description: 'SANTO ANTONIO DA PATRULHA' },
    { id: 4317707, description: 'SANTO ANTONIO DAS MISSOES' },
    { id: 4317756, description: 'SANTO ANTONIO DO PLANALTO' },
    { id: 4317806, description: 'SANTO AUGUSTO' },
    { id: 4317905, description: 'SANTO CRISTO' },
    { id: 4317954, description: 'SANTO EXPEDITO DO SUL' },
    { id: 4318002, description: 'SAO BORJA' },
    { id: 4318051, description: 'SAO DOMINGOS DO SUL' },
    { id: 4318101, description: 'SAO FRANCISCO DE ASSIS' },
    { id: 4318200, description: 'SAO FRANCISCO DE PAULA' },
    { id: 4318309, description: 'SAO GABRIEL' },
    { id: 4318408, description: 'SAO JERONIMO' },
    { id: 4318424, description: 'SAO JOAO DA URTIGA' },
    { id: 4318432, description: 'SAO JOAO DO POLESINE' },
    { id: 4318440, description: 'SAO JORGE' },
    { id: 4318457, description: 'SAO JOSE DAS MISSOES' },
    { id: 4318465, description: 'SAO JOSE DO HERVAL' },
    { id: 4318481, description: 'SAO JOSE DO HORTENCIO' },
    { id: 4318499, description: 'SAO JOSE DO INHACORA' },
    { id: 4318507, description: 'SAO JOSE DO NORTE' },
    { id: 4318606, description: 'SAO JOSE DO OURO' },
    { id: 4318614, description: 'SAO JOSE DO SUL' },
    { id: 4318622, description: 'SAO JOSE DOS AUSENTES' },
    { id: 4318705, description: 'SAO LEOPOLDO' },
    { id: 4318804, description: 'SAO LOURENCO DO SUL' },
    { id: 4318903, description: 'SAO LUIZ GONZAGA' },
    { id: 4319000, description: 'SAO MARCOS' },
    { id: 4319109, description: 'SAO MARTINHO' },
    { id: 4319125, description: 'SAO MARTINHO DA SERRA' },
    { id: 4319158, description: 'SAO MIGUEL DAS MISSOES' },
    { id: 4319208, description: 'SAO NICOLAU' },
    { id: 4319307, description: 'SAO PAULO DAS MISSOES' },
    { id: 4319356, description: 'SAO PEDRO DA SERRA' },
    { id: 4319364, description: 'SAO PEDRO DAS MISSOES' },
    { id: 4319372, description: 'SAO PEDRO DO BUTIA' },
    { id: 4319406, description: 'SAO PEDRO DO SUL' },
    { id: 4319505, description: 'SAO SEBASTIAO DO CAI' },
    { id: 4319604, description: 'SAO SEPE' },
    { id: 4319703, description: 'SAO VALENTIM' },
    { id: 4319711, description: 'SAO VALENTIM DO SUL' },
    { id: 4319737, description: 'SAO VALERIO DO SUL' },
    { id: 4319752, description: 'SAO VENDELINO' },
    { id: 4319802, description: 'SAO VICENTE DO SUL' },
    { id: 4319901, description: 'SAPIRANGA' },
    { id: 4320008, description: 'SAPUCAIA DO SUL' },
    { id: 4320107, description: 'SARANDI' },
    { id: 4320206, description: 'SEBERI' },
    { id: 4320230, description: 'SEDE NOVA' },
    { id: 4320263, description: 'SEGREDO' },
    { id: 4320305, description: 'SELBACH' },
    { id: 4320321, description: 'SENADOR SALGADO FILHO' },
    { id: 4320354, description: 'SENTINELA DO SUL' },
    { id: 4320404, description: 'SERAFINA CORREA' },
    { id: 4320453, description: 'SERIO' },
    { id: 4320503, description: 'SERTAO' },
    { id: 4320552, description: 'SERTAO SANTANA' },
    { id: 4320578, description: 'SETE DE SETEMBRO' },
    { id: 4320602, description: 'SEVERIANO DE ALMEIDA' },
    { id: 4320651, description: 'SILVEIRA MARTINS' },
    { id: 4320677, description: 'SINIMBU' },
    { id: 4320701, description: 'SOBRADINHO' },
    { id: 4320800, description: 'SOLEDADE' },
    { id: 4320859, description: 'TABAI' },
    { id: 4320909, description: 'TAPEJARA' },
    { id: 4321006, description: 'TAPERA' },
    { id: 4321105, description: 'TAPES' },
    { id: 4321204, description: 'TAQUARA' },
    { id: 4321303, description: 'TAQUARI' },
    { id: 4321329, description: 'TAQUARUCU DO SUL' },
    { id: 4321352, description: 'TAVARES' },
    { id: 4321402, description: 'TENENTE PORTELA' },
    { id: 4321436, description: 'TERRA DE AREIA' },
    { id: 4321451, description: 'TEUTONIA' },
    { id: 4321469, description: 'TIO HUGO' },
    { id: 4321477, description: 'TIRADENTES DO SUL' },
    { id: 4321493, description: 'TOROPI' },
    { id: 4321501, description: 'TORRES' },
    { id: 4321600, description: 'TRAMANDAI' },
    { id: 4321626, description: 'TRAVESSEIRO' },
    { id: 4321634, description: 'TRES ARROIOS' },
    { id: 4321667, description: 'TRES CACHOEIRAS' },
    { id: 4321709, description: 'TRES COROAS' },
    { id: 4321808, description: 'TRES DE MAIO' },
    { id: 4321832, description: 'TRES FORQUILHAS' },
    { id: 4321857, description: 'TRES PALMEIRAS' },
    { id: 4321907, description: 'TRES PASSOS' },
    { id: 4321956, description: 'TRINDADE DO SUL' },
    { id: 4322004, description: 'TRIUNFO' },
    { id: 4322103, description: 'TUCUNDUVA' },
    { id: 4322152, description: 'TUNAS' },
    { id: 4322186, description: 'TUPANCI DO SUL' },
    { id: 4322202, description: 'TUPANCIRETA' },
    { id: 4322251, description: 'TUPANDI' },
    { id: 4322301, description: 'TUPARENDI' },
    { id: 4322327, description: 'TURUCU' },
    { id: 4322343, description: 'UBIRETAMA' },
    { id: 4322350, description: 'UNIAO DA SERRA' },
    { id: 4322376, description: 'UNISTALDA' },
    { id: 4322400, description: 'URUGUAIANA' },
    { id: 4322509, description: 'VACARIA' },
    { id: 4322525, description: 'VALE VERDE' },
    { id: 4322533, description: 'VALE DO SOL' },
    { id: 4322541, description: 'VALE REAL' },
    { id: 4322558, description: 'VANINI' },
    { id: 4322608, description: 'VENANCIO AIRES' },
    { id: 4322707, description: 'VERA CRUZ' },
    { id: 4322806, description: 'VERANOPOLIS' },
    { id: 4322855, description: 'VESPASIANO CORREA' },
    { id: 4322905, description: 'VIADUTOS' },
    { id: 4323002, description: 'VIAMAO' },
    { id: 4323101, description: 'VICENTE DUTRA' },
    { id: 4323200, description: 'VICTOR GRAEFF' },
    { id: 4323309, description: 'VILA FLORES' },
    { id: 4323358, description: 'VILA LANGARO' },
    { id: 4323408, description: 'VILA MARIA' },
    { id: 4323457, description: 'VILA NOVA DO SUL' },
    { id: 4323507, description: 'VISTA ALEGRE' },
    { id: 4323606, description: 'VISTA ALEGRE DO PRATA' },
    { id: 4323705, description: 'VISTA GAUCHA' },
    { id: 4323754, description: 'VITORIA DAS MISSOES' },
    { id: 4323770, description: 'WESTFALIA' },
    { id: 4323804, description: 'XANGRI LA' },
    { id: 5000203, description: 'AGUA CLARA' },
    { id: 5000252, description: 'ALCINOPOLIS' },
    { id: 5000609, description: 'AMAMBAI' },
    { id: 5000708, description: 'ANASTACIO' },
    { id: 5000807, description: 'ANAURILANDIA' },
    { id: 5000856, description: 'ANGELICA' },
    { id: 5000906, description: 'ANTONIO JOAO' },
    { id: 5001003, description: 'APARECIDA DO TABOADO' },
    { id: 5001102, description: 'AQUIDAUANA' },
    { id: 5001243, description: 'ARAL MOREIRA' },
    { id: 5001508, description: 'BANDEIRANTES' },
    { id: 5001904, description: 'BATAGUASSU' },
    { id: 5002001, description: 'BATAYPORA' },
    { id: 5002100, description: 'BELA VISTA' },
    { id: 5002159, description: 'BODOQUENA' },
    { id: 5002209, description: 'BONITO' },
    { id: 5002308, description: 'BRASILANDIA' },
    { id: 5002407, description: 'CAARAPO' },
    { id: 5002605, description: 'CAMAPUA' },
    { id: 5002704, description: 'CAMPO GRANDE' },
    { id: 5002803, description: 'CARACOL' },
    { id: 5002902, description: 'CASSILANDIA' },
    { id: 5002951, description: 'CHAPADAO DO SUL' },
    { id: 5003108, description: 'CORGUINHO' },
    { id: 5003157, description: 'CORONEL SAPUCAIA' },
    { id: 5003207, description: 'CORUMBA' },
    { id: 5003256, description: 'COSTA RICA' },
    { id: 5003306, description: 'COXIM' },
    { id: 5003454, description: 'DEODAPOLIS' },
    { id: 5003488, description: 'DOIS IRMAOS DO BURITI' },
    { id: 5003504, description: 'DOURADINA' },
    { id: 5003702, description: 'DOURADOS' },
    { id: 5003751, description: 'ELDORADO' },
    { id: 5003801, description: 'FATIMA DO SUL' },
    { id: 5003900, description: 'FIGUEIRAO' },
    { id: 5004007, description: 'GLORIA DE DOURADOS' },
    { id: 5004106, description: 'GUIA LOPES DA LAGUNA' },
    { id: 5004304, description: 'IGUATEMI' },
    { id: 5004403, description: 'INOCENCIA' },
    { id: 5004502, description: 'ITAPORA' },
    { id: 5004601, description: 'ITAQUIRAI' },
    { id: 5004700, description: 'IVINHEMA' },
    { id: 5004809, description: 'JAPORA' },
    { id: 5004908, description: 'JARAGUARI' },
    { id: 5005004, description: 'JARDIM' },
    { id: 5005103, description: 'JATEI' },
    { id: 5005152, description: 'JUTI' },
    { id: 5005202, description: 'LADARIO' },
    { id: 5005251, description: 'LAGUNA CARAPA' },
    { id: 5005400, description: 'MARACAJU' },
    { id: 5005608, description: 'MIRANDA' },
    { id: 5005681, description: 'MUNDO NOVO' },
    { id: 5005707, description: 'NAVIRAI' },
    { id: 5005806, description: 'NIOAQUE' },
    { id: 5006002, description: 'NOVA ALVORADA DO SUL' },
    { id: 5006200, description: 'NOVA ANDRADINA' },
    { id: 5006259, description: 'NOVO HORIZONTE DO SUL' },
    { id: 5006275, description: 'PARAISO DAS AGUAS' },
    { id: 5006309, description: 'PARANAIBA' },
    { id: 5006358, description: 'PARANHOS' },
    { id: 5006408, description: 'PEDRO GOMES' },
    { id: 5006606, description: 'PONTA PORA' },
    { id: 5006903, description: 'PORTO MURTINHO' },
    { id: 5007109, description: 'RIBAS DO RIO PARDO' },
    { id: 5007208, description: 'RIO BRILHANTE' },
    { id: 5007307, description: 'RIO NEGRO' },
    { id: 5007406, description: 'RIO VERDE DE MATO GROSSO' },
    { id: 5007505, description: 'ROCHEDO' },
    { id: 5007554, description: 'SANTA RITA DO PARDO' },
    { id: 5007695, description: 'SAO GABRIEL DO OESTE' },
    { id: 5007703, description: 'SETE QUEDAS' },
    { id: 5007802, description: 'SELVIRIA' },
    { id: 5007901, description: 'SIDROLANDIA' },
    { id: 5007935, description: 'SONORA' },
    { id: 5007950, description: 'TACURU' },
    { id: 5007976, description: 'TAQUARUSSU' },
    { id: 5008008, description: 'TERENOS' },
    { id: 5008305, description: 'TRES LAGOAS' },
    { id: 5008404, description: 'VICENTINA' },
    { id: 5100102, description: 'ACORIZAL' },
    { id: 5100201, description: 'AGUA BOA' },
    { id: 5100250, description: 'ALTA FLORESTA' },
    { id: 5100300, description: 'ALTO ARAGUAIA' },
    { id: 5100359, description: 'ALTO BOA VISTA' },
    { id: 5100409, description: 'ALTO GARCAS' },
    { id: 5100508, description: 'ALTO PARAGUAI' },
    { id: 5100607, description: 'ALTO TAQUARI' },
    { id: 5100805, description: 'APIACAS' },
    { id: 5101001, description: 'ARAGUAIANA' },
    { id: 5101209, description: 'ARAGUAINHA' },
    { id: 5101258, description: 'ARAPUTANGA' },
    { id: 5101308, description: 'ARENAPOLIS' },
    { id: 5101407, description: 'ARIPUANA' },
    { id: 5101605, description: 'BARAO DE MELGACO' },
    { id: 5101704, description: 'BARRA DO BUGRES' },
    { id: 5101803, description: 'BARRA DO GARCAS' },
    { id: 5101852, description: 'BOM JESUS DO ARAGUAIA' },
    { id: 5101902, description: 'BRASNORTE' },
    { id: 5102504, description: 'CACERES' },
    { id: 5102603, description: 'CAMPINAPOLIS' },
    { id: 5102637, description: 'CAMPO NOVO DO PARECIS' },
    { id: 5102678, description: 'CAMPO VERDE' },
    { id: 5102686, description: 'CAMPOS DE JULIO' },
    { id: 5102694, description: 'CANABRAVA DO NORTE' },
    { id: 5102702, description: 'CANARANA' },
    { id: 5102793, description: 'CARLINDA' },
    { id: 5102850, description: 'CASTANHEIRA' },
    { id: 5103007, description: 'CHAPADA DOS GUIMARAES' },
    { id: 5103056, description: 'CLAUDIA' },
    { id: 5103106, description: 'COCALINHO' },
    { id: 5103205, description: 'COLIDER' },
    { id: 5103254, description: 'COLNIZA' },
    { id: 5103304, description: 'COMODORO' },
    { id: 5103353, description: 'CONFRESA' },
    { id: 5103361, description: 'CONQUISTA D OESTE' },
    { id: 5103379, description: 'COTRIGUACU' },
    { id: 5103403, description: 'CUIABA' },
    { id: 5103437, description: 'CURVELANDIA' },
    { id: 5103452, description: 'DENISE' },
    { id: 5103502, description: 'DIAMANTINO' },
    { id: 5103601, description: 'DOM AQUINO' },
    { id: 5103700, description: 'FELIZ NATAL' },
    { id: 5103809, description: 'FIGUEIROPOLIS D OESTE' },
    { id: 5103858, description: 'GAUCHA DO NORTE' },
    { id: 5103908, description: 'GENERAL CARNEIRO' },
    { id: 5103957, description: 'GLORIA D OESTE' },
    { id: 5104104, description: 'GUARANTA DO NORTE' },
    { id: 5104203, description: 'GUIRATINGA' },
    { id: 5104500, description: 'INDIAVAI' },
    { id: 5104526, description: 'IPIRANGA DO NORTE' },
    { id: 5104542, description: 'ITANHANGA' },
    { id: 5104559, description: 'ITAUBA' },
    { id: 5104609, description: 'ITIQUIRA' },
    { id: 5104807, description: 'JACIARA' },
    { id: 5104906, description: 'JANGADA' },
    { id: 5105002, description: 'JAURU' },
    { id: 5105101, description: 'JUARA' },
    { id: 5105150, description: 'JUINA' },
    { id: 5105176, description: 'JURUENA' },
    { id: 5105200, description: 'JUSCIMEIRA' },
    { id: 5105234, description: 'LAMBARI D OESTE' },
    { id: 5105259, description: 'LUCAS DO RIO VERDE' },
    { id: 5105309, description: 'LUCIARA' },
    { id: 5105507, description: 'VILA BELA DA SANTISSIMA TRINDADE' },
    { id: 5105580, description: 'MARCELANDIA' },
    { id: 5105606, description: 'MATUPA' },
    { id: 5105622, description: 'MIRASSOL D OESTE' },
    { id: 5105903, description: 'NOBRES' },
    { id: 5106000, description: 'NORTELANDIA' },
    { id: 5106109, description: 'NOSSA SENHORA DO LIVRAMENTO' },
    { id: 5106158, description: 'NOVA BANDEIRANTES' },
    { id: 5106174, description: 'NOVA NAZARE' },
    { id: 5106182, description: 'NOVA LACERDA' },
    { id: 5106190, description: 'NOVA SANTA HELENA' },
    { id: 5106208, description: 'NOVA BRASILANDIA' },
    { id: 5106216, description: 'NOVA CANAA DO NORTE' },
    { id: 5106224, description: 'NOVA MUTUM' },
    { id: 5106232, description: 'NOVA OLIMPIA' },
    { id: 5106240, description: 'NOVA UBIRATA' },
    { id: 5106257, description: 'NOVA XAVANTINA' },
    { id: 5106265, description: 'NOVO MUNDO' },
    { id: 5106273, description: 'NOVO HORIZONTE DO NORTE' },
    { id: 5106281, description: 'NOVO SAO JOAQUIM' },
    { id: 5106299, description: 'PARANAITA' },
    { id: 5106307, description: 'PARANATINGA' },
    { id: 5106315, description: 'NOVO SANTO ANTONIO' },
    { id: 5106372, description: 'PEDRA PRETA' },
    { id: 5106422, description: 'PEIXOTO DE AZEVEDO' },
    { id: 5106455, description: 'PLANALTO DA SERRA' },
    { id: 5106505, description: 'POCONE' },
    { id: 5106653, description: 'PONTAL DO ARAGUAIA' },
    { id: 5106703, description: 'PONTE BRANCA' },
    { id: 5106752, description: 'PONTES E LACERDA' },
    { id: 5106778, description: 'PORTO ALEGRE DO NORTE' },
    { id: 5106802, description: 'PORTO DOS GAUCHOS' },
    { id: 5106828, description: 'PORTO ESPERIDIAO' },
    { id: 5106851, description: 'PORTO ESTRELA' },
    { id: 5107008, description: 'POXOREU' },
    { id: 5107040, description: 'PRIMAVERA DO LESTE' },
    { id: 5107065, description: 'QUERENCIA' },
    { id: 5107107, description: 'SAO JOSE DOS QUATRO MARCOS' },
    { id: 5107156, description: 'RESERVA DO CABACAL' },
    { id: 5107180, description: 'RIBEIRAO CASCALHEIRA' },
    { id: 5107198, description: 'RIBEIRAOZINHO' },
    { id: 5107206, description: 'RIO BRANCO' },
    { id: 5107248, description: 'SANTA CARMEM' },
    { id: 5107263, description: 'SANTO AFONSO' },
    { id: 5107297, description: 'SAO JOSE DO POVO' },
    { id: 5107305, description: 'SAO JOSE DO RIO CLARO' },
    { id: 5107354, description: 'SAO JOSE DO XINGU' },
    { id: 5107404, description: 'SAO PEDRO DA CIPA' },
    { id: 5107578, description: 'RONDOLANDIA' },
    { id: 5107602, description: 'RONDONOPOLIS' },
    { id: 5107701, description: 'ROSARIO OESTE' },
    { id: 5107743, description: 'SANTA CRUZ DO XINGU' },
    { id: 5107750, description: 'SALTO DO CEU' },
    { id: 5107768, description: 'SANTA RITA DO TRIVELATO' },
    { id: 5107776, description: 'SANTA TEREZINHA' },
    { id: 5107792, description: 'SANTO ANTONIO DO LESTE' },
    { id: 5107800, description: 'SANTO ANTONIO DO LEVERGER' },
    { id: 5107859, description: 'SAO FELIX DO ARAGUAIA' },
    { id: 5107875, description: 'SAPEZAL' },
    { id: 5107883, description: 'SERRA NOVA DOURADA' },
    { id: 5107909, description: 'SINOP' },
    { id: 5107925, description: 'SORRISO' },
    { id: 5107941, description: 'TABAPORA' },
    { id: 5107958, description: 'TANGARA DA SERRA' },
    { id: 5108006, description: 'TAPURAH' },
    { id: 5108055, description: 'TERRA NOVA DO NORTE' },
    { id: 5108105, description: 'TESOURO' },
    { id: 5108204, description: 'TORIXOREU' },
    { id: 5108303, description: 'UNIAO DO SUL' },
    { id: 5108352, description: 'VALE DE SAO DOMINGOS' },
    { id: 5108402, description: 'VARZEA GRANDE' },
    { id: 5108501, description: 'VERA' },
    { id: 5108600, description: 'VILA RICA' },
    { id: 5108808, description: 'NOVA GUARITA' },
    { id: 5108857, description: 'NOVA MARILANDIA' },
    { id: 5108907, description: 'NOVA MARINGA' },
    { id: 5108956, description: 'NOVA MONTE VERDE' },
    { id: 5200050, description: 'ABADIA DE GOIAS' },
    { id: 5200100, description: 'ABADIANIA' },
    { id: 5200134, description: 'ACREUNA' },
    { id: 5200159, description: 'ADELANDIA' },
    { id: 5200175, description: 'AGUA FRIA DE GOIAS' },
    { id: 5200209, description: 'AGUA LIMPA' },
    { id: 5200258, description: 'AGUAS LINDAS DE GOIAS' },
    { id: 5200308, description: 'ALEXANIA' },
    { id: 5200506, description: 'ALOANDIA' },
    { id: 5200555, description: 'ALTO HORIZONTE' },
    { id: 5200605, description: 'ALTO PARAISO DE GOIAS' },
    { id: 5200803, description: 'ALVORADA DO NORTE' },
    { id: 5200829, description: 'AMARALINA' },
    { id: 5200852, description: 'AMERICANO DO BRASIL' },
    { id: 5200902, description: 'AMORINOPOLIS' },
    { id: 5201108, description: 'ANAPOLIS' },
    { id: 5201207, description: 'ANHANGUeER' },
    { id: 5201306, description: 'ANICUNS' },
    { id: 5201405, description: 'APARECIDA DE GOIANIA' },
    { id: 5201454, description: 'APARECIDA DO RIO DOCE' },
    { id: 5201504, description: 'APORE' },
    { id: 5201603, description: 'ARACU' },
    { id: 5201702, description: 'ARAGARCAS' },
    { id: 5201801, description: 'ARAGOIANIA' },
    { id: 5202155, description: 'ARAGUAPAZ' },
    { id: 5202353, description: 'ARENOPOLIS' },
    { id: 5202502, description: 'ARUANA' },
    { id: 5202601, description: 'AURILANDIA' },
    { id: 5202809, description: 'AVELINOPOLIS' },
    { id: 5203104, description: 'BALIZA' },
    { id: 5203203, description: 'BARRO ALTO' },
    { id: 5203302, description: 'BELA VISTA DE GOIAS' },
    { id: 5203401, description: 'BOM JARDIM DE GOIAS' },
    { id: 5203500, description: 'BOM JESUS' },
    { id: 5203559, description: 'BONFINOPOLIS' },
    { id: 5203575, description: 'BONOPOLIS' },
    { id: 5203609, description: 'BRAZABRANTES' },
    { id: 5203807, description: 'BRITANIA' },
    { id: 5203906, description: 'BURITI ALEGRE' },
    { id: 5203939, description: 'BURITI DE GOIAS' },
    { id: 5203962, description: 'BURITINOPOLIS' },
    { id: 5204003, description: 'CABECEIRAS' },
    { id: 5204102, description: 'CACHOEIRA ALTA' },
    { id: 5204201, description: 'CACHOEIRA DE GOIAS' },
    { id: 5204250, description: 'CACHOEIRA DOURADA' },
    { id: 5204300, description: 'CACU' },
    { id: 5204409, description: 'CAIAPONIA' },
    { id: 5204508, description: 'CALDAS NOVAS' },
    { id: 5204557, description: 'CALDAZINHA' },
    { id: 5204607, description: 'CAMPESTRE DE GOIAS' },
    { id: 5204656, description: 'CAMPINACU' },
    { id: 5204706, description: 'CAMPINORTE' },
    { id: 5204805, description: 'CAMPO ALEGRE DE GOIAS' },
    { id: 5204854, description: 'CAMPO LIMPO DE GOIAS' },
    { id: 5204904, description: 'CAMPOS BELOS' },
    { id: 5204953, description: 'CAMPOS VERDES' },
    { id: 5205000, description: 'CARMO DO RIO VERDE' },
    { id: 5205059, description: 'CASTELANDIA' },
    { id: 5205109, description: 'CATALAO' },
    { id: 5205208, description: 'CATURAI' },
    { id: 5205307, description: 'CAVALCANTE' },
    { id: 5205406, description: 'CERES' },
    { id: 5205455, description: 'CEZARINA' },
    { id: 5205471, description: 'CHAPADAO DO CEU' },
    { id: 5205497, description: 'CIDADE OCIDENTAL' },
    { id: 5205513, description: 'COCALZINHO DE GOIAS' },
    { id: 5205521, description: 'COLINAS DO SUL' },
    { id: 5205703, description: 'CORREGO DO OURO' },
    { id: 5205802, description: 'CORUMBA DE GOIAS' },
    { id: 5205901, description: 'CORUMBAIBA' },
    { id: 5206206, description: 'CRISTALINA' },
    { id: 5206305, description: 'CRISTIANOPOLIS' },
    { id: 5206404, description: 'CRIXAS' },
    { id: 5206503, description: 'CROMINIA' },
    { id: 5206602, description: 'CUMARI' },
    { id: 5206701, description: 'DAMIANOPOLIS' },
    { id: 5206800, description: 'DAMOLANDIA' },
    { id: 5206909, description: 'DAVINOPOLIS' },
    { id: 5207105, description: 'DIORAMA' },
    { id: 5207253, description: 'DOVERLANDIA' },
    { id: 5207352, description: 'EDEALINA' },
    { id: 5207402, description: 'EDEIA' },
    { id: 5207501, description: 'ESTRELA DO NORTE' },
    { id: 5207535, description: 'FAINA' },
    { id: 5207600, description: 'FAZENDA NOVA' },
    { id: 5207808, description: 'FIRMINOPOLIS' },
    { id: 5207907, description: 'FLORES DE GOIAS' },
    { id: 5208004, description: 'FORMOSA' },
    { id: 5208103, description: 'FORMOSO' },
    { id: 5208152, description: 'GAMELEIRA DE GOIAS' },
    { id: 5208301, description: 'DIVINOPOLIS DE GOIAS' },
    { id: 5208400, description: 'GOIANAPOLIS' },
    { id: 5208509, description: 'GOIANDIRA' },
    { id: 5208608, description: 'GOIANESIA' },
    { id: 5208707, description: 'GOIANIA' },
    { id: 5208806, description: 'GOIANIRA' },
    { id: 5208905, description: 'GOIAS' },
    { id: 5209101, description: 'GOIATUBA' },
    { id: 5209150, description: 'GOUVELANDIA' },
    { id: 5209200, description: 'GUAPO' },
    { id: 5209291, description: 'GUARAITA' },
    { id: 5209408, description: 'GUARANI DE GOIAS' },
    { id: 5209457, description: 'GUARINOS' },
    { id: 5209606, description: 'HEITORAI' },
    { id: 5209705, description: 'HIDROLANDIA' },
    { id: 5209804, description: 'HIDROLINA' },
    { id: 5209903, description: 'IACIARA' },
    { id: 5209937, description: 'INACIOLANDIA' },
    { id: 5209952, description: 'INDIARA' },
    { id: 5210000, description: 'INHUMAS' },
    { id: 5210109, description: 'IPAMERI' },
    { id: 5210158, description: 'IPIRANGA DE GOIAS' },
    { id: 5210208, description: 'IPORA' },
    { id: 5210307, description: 'ISRAELANDIA' },
    { id: 5210406, description: 'ITABERAI' },
    { id: 5210562, description: 'ITAGUARI' },
    { id: 5210604, description: 'ITAGUARU' },
    { id: 5210802, description: 'ITAJA' },
    { id: 5210901, description: 'ITAPACI' },
    { id: 5211008, description: 'ITAPIRAPUA' },
    { id: 5211206, description: 'ITAPURANGA' },
    { id: 5211305, description: 'ITARUMA' },
    { id: 5211404, description: 'ITAUCU' },
    { id: 5211503, description: 'ITUMBIARA' },
    { id: 5211602, description: 'IVOLANDIA' },
    { id: 5211701, description: 'JANDAIA' },
    { id: 5211800, description: 'JARAGUA' },
    { id: 5211909, description: 'JATAI' },
    { id: 5212006, description: 'JAUPACI' },
    { id: 5212055, description: 'JESUPOLIS' },
    { id: 5212105, description: 'JOVIANIA' },
    { id: 5212204, description: 'JUSSARA' },
    { id: 5212253, description: 'LAGOA SANTA' },
    { id: 5212303, description: 'LEOPOLDO DE BULHOES' },
    { id: 5212501, description: 'LUZIANIA' },
    { id: 5212600, description: 'MAIRIPOTABA' },
    { id: 5212709, description: 'MAMBAI' },
    { id: 5212808, description: 'MARA ROSA' },
    { id: 5212907, description: 'MARZAGAO' },
    { id: 5212956, description: 'MATRINCHA' },
    { id: 5213004, description: 'MAURILANDIA' },
    { id: 5213053, description: 'MIMOSO DE GOIAS' },
    { id: 5213087, description: 'MINACU' },
    { id: 5213103, description: 'MINEIROS' },
    { id: 5213400, description: 'MOIPORA' },
    { id: 5213509, description: 'MONTE ALEGRE DE GOIAS' },
    { id: 5213707, description: 'MONTES CLAROS DE GOIAS' },
    { id: 5213756, description: 'MONTIVIDIU' },
    { id: 5213772, description: 'MONTIVIDIU DO NORTE' },
    { id: 5213806, description: 'MORRINHOS' },
    { id: 5213855, description: 'MORRO AGUDO DE GOIAS' },
    { id: 5213905, description: 'MOSSAMEDES' },
    { id: 5214002, description: 'MOZARLANDIA' },
    { id: 5214051, description: 'MUNDO NOVO' },
    { id: 5214101, description: 'MUTUNOPOLIS' },
    { id: 5214408, description: 'NAZARIO' },
    { id: 5214507, description: 'NEROPOLIS' },
    { id: 5214606, description: 'NIQUELANDIA' },
    { id: 5214705, description: 'NOVA AMERICA' },
    { id: 5214804, description: 'NOVA AURORA' },
    { id: 5214838, description: 'NOVA CRIXAS' },
    { id: 5214861, description: 'NOVA GLORIA' },
    { id: 5214879, description: 'NOVA IGUACU DE GOIAS' },
    { id: 5214903, description: 'NOVA ROMA' },
    { id: 5215009, description: 'NOVA VENEZA' },
    { id: 5215207, description: 'NOVO BRASIL' },
    { id: 5215231, description: 'NOVO GAMA' },
    { id: 5215256, description: 'NOVO PLANALTO' },
    { id: 5215306, description: 'ORIZONA' },
    { id: 5215405, description: 'OURO VERDE DE GOIAS' },
    { id: 5215504, description: 'OUVIDOR' },
    { id: 5215603, description: 'PADRE BERNARDO' },
    { id: 5215652, description: 'PALESTINA DE GOIAS' },
    { id: 5215702, description: 'PALMEIRAS DE GOIAS' },
    { id: 5215801, description: 'PALMELO' },
    { id: 5215900, description: 'PALMINOPOLIS' },
    { id: 5216007, description: 'PANAMA' },
    { id: 5216304, description: 'PARANAIGUARA' },
    { id: 5216403, description: 'PARAUNA' },
    { id: 5216452, description: 'PEROLANDIA' },
    { id: 5216809, description: 'PETROLINA DE GOIAS' },
    { id: 5216908, description: 'PILAR DE GOIAS' },
    { id: 5217104, description: 'PIRACANJUBA' },
    { id: 5217203, description: 'PIRANHAS' },
    { id: 5217302, description: 'PIRENOPOLIS' },
    { id: 5217401, description: 'PIRES DO RIO' },
    { id: 5217609, description: 'PLANALTINA' },
    { id: 5217708, description: 'PONTALINA' },
    { id: 5218003, description: 'PORANGATU' },
    { id: 5218052, description: 'PORTEIRAO' },
    { id: 5218102, description: 'PORTELANDIA' },
    { id: 5218300, description: 'POSSE' },
    { id: 5218391, description: 'PROFESSOR JAMIL' },
    { id: 5218508, description: 'QUIRINOPOLIS' },
    { id: 5218607, description: 'RIALMA' },
    { id: 5218706, description: 'RIANAPOLIS' },
    { id: 5218789, description: 'RIO QUENTE' },
    { id: 5218805, description: 'RIO VERDE' },
    { id: 5218904, description: 'RUBIATABA' },
    { id: 5219001, description: 'SANCLERLANDIA' },
    { id: 5219100, description: 'SANTA BARBARA DE GOIAS' },
    { id: 5219209, description: 'SANTA CRUZ DE GOIAS' },
    { id: 5219258, description: 'SANTA FE DE GOIAS' },
    { id: 5219308, description: 'SANTA HELENA DE GOIAS' },
    { id: 5219357, description: 'SANTA ISABEL' },
    { id: 5219407, description: 'SANTA RITA DO ARAGUAIA' },
    { id: 5219456, description: 'SANTA RITA DO NOVO DESTINO' },
    { id: 5219506, description: 'SANTA ROSA DE GOIAS' },
    { id: 5219605, description: 'SANTA TEREZA DE GOIAS' },
    { id: 5219704, description: 'SANTA TEREZINHA DE GOIAS' },
    { id: 5219712, description: 'SANTO ANTONIO DA BARRA' },
    { id: 5219738, description: 'SANTO ANTONIO DE GOIAS' },
    { id: 5219753, description: 'SANTO ANTONIO DO DESCOBERTO' },
    { id: 5219803, description: 'SAO DOMINGOS' },
    { id: 5219902, description: 'SAO FRANCISCO DE GOIAS' },
    { id: 5220009, description: 'SAO JOAO D ALIANCA' },
    { id: 5220058, description: 'SAO JOAO DA PARAUNA' },
    { id: 5220108, description: 'SAO LUIS DE MONTES BELOS' },
    { id: 5220157, description: 'SAO LUIZ DO NORTE' },
    { id: 5220207, description: 'SAO MIGUEL DO ARAGUAIA' },
    { id: 5220264, description: 'SAO MIGUEL DO PASSA QUATRO' },
    { id: 5220280, description: 'SAO PATRICIO' },
    { id: 5220405, description: 'SAO SIMAO' },
    { id: 5220454, description: 'SENADOR CANEDO' },
    { id: 5220504, description: 'SERRANOPOLIS' },
    { id: 5220603, description: 'SILVANIA' },
    { id: 5220686, description: 'SIMOLANDIA' },
    { id: 5220702, description: 'SITIO D ABADIA' },
    { id: 5221007, description: 'TAQUARAL DE GOIAS' },
    { id: 5221080, description: 'TERESINA DE GOIAS' },
    { id: 5221197, description: 'TEREZOPOLIS DE GOIAS' },
    { id: 5221304, description: 'TRES RANCHOS' },
    { id: 5221403, description: 'TRINDADE' },
    { id: 5221452, description: 'TROMBAS' },
    { id: 5221502, description: 'TURVANIA' },
    { id: 5221551, description: 'TURVELANDIA' },
    { id: 5221577, description: 'UIRAPURU' },
    { id: 5221601, description: 'URUACU' },
    { id: 5221700, description: 'URUANA' },
    { id: 5221809, description: 'URUTAI' },
    { id: 5221858, description: 'VALPARAISO DE GOIAS' },
    { id: 5221908, description: 'VARJAO' },
    { id: 5222005, description: 'VIANOPOLIS' },
    { id: 5222054, description: 'VICENTINOPOLIS' },
    { id: 5222203, description: 'VILA BOA' },
    { id: 5222302, description: 'VILA PROPICIO' },
    { id: 5300108, description: 'BRASILIA' },
];

export { municipalityConstants };
