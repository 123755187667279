function formatCnpj(v: string) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
    return v;
}

function formatCpf(v: string) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return v;
}

function formatCpfOrCnpj(v: string) {
    if (v.length === 11) {
        return formatCpf(v);
    }
    if (v.length === 14) {
        return formatCnpj(v);
    }
    return v;
}

function formatRG(doc: string) {
    doc = doc.replace(/\D/g, '');
    return doc.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
}

export { formatCnpj, formatCpf, formatCpfOrCnpj, formatRG };
