import PrivateRoute from 'routes/components/private-route';
import { SearchHolidaysContextProvider } from './pages/search-holidays/context/SearchHolidaysContext';
import SearchHolidays from './pages/search-holidays/SearchHolidays';

const HolidaysRoutes: JSX.Element[] = [
    <PrivateRoute
        exact
        path='/feriados'
        key='/feriados'
        component={() => (
            <SearchHolidaysContextProvider>
                <SearchHolidays />
            </SearchHolidaysContextProvider>
        )}
    />,
];

export default HolidaysRoutes;
