import i18n from 'i18next';

export const getProjectOptions = () => [
    {
        label: i18n.t('audit.project.platform', { value: 'v2' }),
        value: 'manager',
    },
    {
        label: i18n.t('audit.project.platform', { value: 'v1' }),
        value: 'php',
    },
    {
        label: i18n.t('audit.project.dispute-room'),
        value: 'dispute',
    },
];
