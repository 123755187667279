import { managerApiInstance } from 'clients/api';

const BASE_PATH = '/provider-users';

const listProviderUsers = (name: string) =>
    managerApiInstance.post(`${BASE_PATH}/listProviderUsers`, { name });

const doLinkUserAndProvider = (values) =>
    managerApiInstance.post(`${BASE_PATH}/doLinkUserAndProvider`, {
        ...values,
        isItLegalRepresentative: !!values?.isItLegalRepresentative,
    });

const doUnLinkUserAndProvider = (id) =>
    managerApiInstance.post(`${BASE_PATH}/doUnLinkUserAndProvider`, { id });

const ProviderUsersService = { listProviderUsers, doLinkUserAndProvider, doUnLinkUserAndProvider };

export default ProviderUsersService;
