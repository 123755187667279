import { formatCurrency } from 'common/utils';
import { PriceListItem } from 'common/interfaces/price-list.interface';
import { Button, ButtonSize } from '@licitar/ui-kit';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePriceBankContext } from 'modules/priceBank/context/price-list.context';
import { TableHeaderProps } from './props';
import { Content, Card, CardContent, CardTitle, CardWrapper } from './styled';

const TableHeader: FC<TableHeaderProps> = ({ priceList }) => {
    const { setPriceListSelected } = usePriceBankContext();
    const { t } = useTranslation();

    const summReferenceValue = () =>
        priceList.priceListItems
            ?.map((item: PriceListItem) => item.referenceValue)
            .reduce((prev, current) => prev + current, 0) || 0;

    const history = useHistory();

    const handleAddItems = () => {
        setPriceListSelected(priceList);
        history.push(`/banco-precos/minhas-listas/${priceList._id}/pesquisa`);
    };

    return (
        <Content>
            <Button size={ButtonSize.sm} onClick={handleAddItems}>
                {t('term.add-itens')}
            </Button>
            <Card>
                <CardWrapper>
                    <CardTitle>
                        <span>Total</span>
                    </CardTitle>
                    <CardContent>
                        <span>{formatCurrency(summReferenceValue())}</span>
                    </CardContent>
                </CardWrapper>
            </Card>
        </Content>
    );
};

export default TableHeader;
