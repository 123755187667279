import {
    Box,
    CircularProgress,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import { ContractWithDetails } from 'clients/manager/interfaces/contract.interface';
import { TextField } from 'common/components';
import StepSigners from 'common/components/contracts/components/step-signers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ChipContractStatus from '../../../../../../common/components/contracts/components/chip-contract-status';
import { useSearchContractsContext } from '../../context/search-contracts-context';
import useStyles from './search-contracts-table-style';
import SearchContractsActions from '../search-contracts-actions';

const rowsPerPageOptions = [10, 20];

const SearchContractsTableView = ({ loading }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        contracts,
        requestsCount,
        handleChangePage,
        handleChangeContractsPerPage,
        requestFilters,
        page,
        handleChangeFilter,
    } = useSearchContractsContext();

    return (
        <>
            <TextField
                label={t('contracts.pages.components.search-contracts')}
                placeholder={t('group-auth.pages.search-group.components.search-group-press-enter')}
                fullWidth
                onKeyPress={handleChangeFilter}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.number')}</TableCell>
                        <TableCell>{t('term.process')}</TableCell>
                        <TableCell>{t('term.supplier')}</TableCell>
                        <TableCell>{t('term.period')}</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>{t('term.subscribers')}</TableCell>
                        <TableCell>{t('contracts.rating.head')}</TableCell>
                        <TableCell align='center'>{t('term.actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contracts &&
                        (contracts as ContractWithDetails[]).map((contractWithDetails) => {
                            const { contract, process, provider, documentSigners } =
                                contractWithDetails;
                            return (
                                <TableRow hover key={contract.id}>
                                    <TableCell>{contract.contractNumber ?? '-'}</TableCell>
                                    <TableCell>{process.id}</TableCell>
                                    <TableCell>{provider.companyName}</TableCell>
                                    <TableCell>
                                        {`${t('term.from')}: ${
                                            contract.startDate
                                                ? moment(contract.startDate).format('DD/MM/YYYY')
                                                : '-'
                                        }
                                    ${t('term.until')} ${
                                            contract.endDate
                                                ? moment(contract.endDate).format('DD/MM/YYYY')
                                                : '-'
                                        }`}
                                    </TableCell>
                                    <TableCell>
                                        <ChipContractStatus status={contract.status} />
                                    </TableCell>
                                    <TableCell>
                                        {documentSigners?.signers?.length ? (
                                            <StepSigners
                                                signers={
                                                    (documentSigners?.signers ?? []).map(
                                                        ({ email, name, signatureDate }) => ({
                                                            email,
                                                            name,
                                                            signedAt: +new Date(signatureDate),
                                                        })
                                                    ) ?? []
                                                }
                                            />
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='center'
                                            gridGap={4}
                                        >
                                            {contract?.assessment ? (
                                                <>
                                                    <Typography className={classes.ratingInfo}>
                                                        {contract.assessment.generalRating}
                                                    </Typography>
                                                    <StarIcon className={classes.star} />
                                                </>
                                            ) : (
                                                <>
                                                    <Typography className={classes.ratingInfo}>
                                                        0
                                                    </Typography>
                                                    <StarIcon />
                                                </>
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        padding='none'
                                        className={classes.actions}
                                    >
                                        <SearchContractsActions
                                            contractWithDetails={contractWithDetails}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    {contracts && !loading && !contracts.length && (
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={7} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={requestsCount}
                rowsPerPage={requestFilters.limit || rowsPerPageOptions[0]}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeContractsPerPage}
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </>
    );
};

export default SearchContractsTableView;
