import React from 'react';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import { formatCurrency } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { TableHead } from '@aws-amplify/ui-react';
import { isEmpty } from 'lodash';
import { ListContractsByOrderIdResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useStyles } from './contract-items-table-style';

interface ContractItemsTableViewProps {
    contractItems?: ListContractsByOrderIdResponse['contract']['items'];
    loading?: boolean;
}

const ContractItemsTableView: React.FC<ContractItemsTableViewProps> = ({
    contractItems = [],
    loading = false,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <section>
            <Table className={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left' colSpan={2} className={styles.headTableCell}>
                            {t('term.item-description')}
                        </TableCell>
                        <TableCell align='right' className={styles.headTableCell}>
                            {t('marketplace.contract.unit')}
                        </TableCell>
                        <TableCell align='right' className={styles.headTableCell}>
                            {t('term.unit-value-2')}
                        </TableCell>
                        <TableCell align='right' className={styles.headTableCell}>
                            {t('term.amount')}
                        </TableCell>
                        <TableCell align='right' className={styles.headTableCell}>
                            {t('term.total')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contractItems?.map((contractItem) => (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography variant='body2'>
                                    {contractItem.itemDescription}
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography variant='body2'>
                                    {contractItem.itemUnitMeasurement}
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                {formatCurrency(contractItem.itemValue)}
                            </TableCell>
                            <TableCell align='right'>{contractItem.itemAmount}</TableCell>
                            <TableCell align='right'>
                                {formatCurrency(contractItem.itemValue * contractItem.itemAmount)}
                            </TableCell>
                        </TableRow>
                    ))}
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={4} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {isEmpty(contractItems) && (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </section>
    );
};

export default ContractItemsTableView;
