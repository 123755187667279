import { addNotificationSuccess } from 'common/utils';
import ProviderUsersService from 'modules/provider-users/services/ProviderUsersService';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchProviderUsersContext } from '../../context/SearchProviderUsersContext';
import SearchProviderUsersTableView from './SearchProviderUsersTableView';

const SearchProviderUsersTable = () => {
    const { t } = useTranslation();

    const { providerUsersDialog, setProviderUsersDialog } = useSearchProviderUsersContext();
    const [users, setUsers] = useState();

    const getUsers = useCallback(async (name) => {
        const { data } = await ProviderUsersService.listProviderUsers(name);
        setUsers(data);
    }, []);

    useEffect(() => {
        getUsers('');
    }, []);

    useEffect(() => {
        if (!providerUsersDialog.open) {
            getUsers('');
        }
    }, [providerUsersDialog.open]);

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            getUsers(event.target.value);
        }
    };

    const handleRemove = async (row) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(`${t('provider-users.pages.components.remove-user')} ${row.name}?`)) {
            await ProviderUsersService.doUnLinkUserAndProvider(row.id);
            getUsers('');
            addNotificationSuccess();
        }
    };

    const handleEdit = (providerUser) => {
        setProviderUsersDialog({ open: true, providerUser });
    };

    return (
        <SearchProviderUsersTableView
            {...{ users, handleChangeFilter, handleRemove, handleEdit }}
        />
    );
};

export default SearchProviderUsersTable;
