import { styled } from 'common/theme/styled';

const HeaderBar = styled.div`
    background: ${({ theme }) => theme.colors.background};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    border-bottom: 1px ${({ theme }) => theme.colors.borderColorDefault} solid;
    position: relative;
`;

const MenuOptions = styled.div`
    background: ${({ theme }) => theme.colors.background};
    padding: 20px 15px;
    display: flex;
`;

const Content = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    height: 100vh;
`;

const DataArea = styled.div`
    background: ${({ theme }) => theme.colors.backgroundPageDefault};
    display: flex;
    margin: 0;
    flex: 1;
    flex-direction: column;
    overflow-x: hidden;
`;

const MenuSelectedInfo = styled.div`
    margin: 0 14px 0 24px;

    span:first-child {
        color: ${({ theme }) => theme.colors.textLight};
        font-size: 21px;
    }
`;

const InformationGroup = styled.div`
    display: flex;
    align-items: center;
`;

export { Content, MenuOptions, HeaderBar, DataArea, MenuSelectedInfo, InformationGroup };
