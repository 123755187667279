import { useEffect, useState } from 'react';
import moment from 'moment';
import SummaryDeadlineInputView from './summary-deadline-input-view';

export interface SummaryDeadlineInputProps {
    initialDeadline?: string;
    deadline: string;
    onDeadlineChange: (value: string) => any;
}

const SummaryDeadlineInput: React.FC<SummaryDeadlineInputProps> = ({
    initialDeadline,
    deadline,
    onDeadlineChange,
}) => {
    const [inputIsActive, setInputIsActive] = useState(false);
    const inputIsEnabled = inputIsActive || deadline.length > 0;

    const initialDeadlineDate = moment(initialDeadline);
    const deadlineCountdownIsEnabled = initialDeadlineDate.isValid() && !!initialDeadline;

    const dateDifference = initialDeadlineDate.diff(moment(), 'seconds');
    const [deadlineCountdown, setDeadlineCountdown] = useState(0);

    const handleActiveInput = () => {
        setInputIsActive(true);
    };

    useEffect(() => {
        if (dateDifference < 0) return;
        setDeadlineCountdown(dateDifference);
    }, [dateDifference]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (deadlineCountdown > 0) {
                setDeadlineCountdown((state) => state - 1);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [deadlineCountdown]);

    useEffect(() => {
        if (deadline === '') {
            setInputIsActive(false);
        }
    }, [deadline]);

    return (
        <SummaryDeadlineInputView
            deadlineCountdownIsEnabled={deadlineCountdownIsEnabled}
            deadlineCountdown={deadlineCountdown}
            deadline={deadline}
            onDeadlineChange={onDeadlineChange}
            handleActiveInput={handleActiveInput}
            inputIsEnabled={inputIsEnabled}
        />
    );
};

export default SummaryDeadlineInput;
