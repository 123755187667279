import React, { createContext, ReactNode, useContext, useState } from 'react';

export type ProviderUpdateRequestWarningContextType = {
    forceHasChanges: boolean | undefined;
    setForceHasChanges: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    countryCode: string;
    setCountryCode: React.Dispatch<React.SetStateAction<string>>;
    technicalManagerDocNumber: string;
    setTechnicalManagerDocNumber: React.Dispatch<React.SetStateAction<string>>;
};

export const ProviderUpdateRequestWarningContext =
    createContext<ProviderUpdateRequestWarningContextType>({
        forceHasChanges: undefined,
        setForceHasChanges: () => { },
        countryCode: '',
        setCountryCode: () => { },
        technicalManagerDocNumber: '',
        setTechnicalManagerDocNumber: () => { },
    });

export const useProviderUpdateRequestWarningContext = () =>
    useContext(ProviderUpdateRequestWarningContext);

export const ProviderUpdateRequestWarningContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [forceHasChanges, setForceHasChanges] = useState<boolean | undefined>(undefined);
    const [countryCode, setCountryCode] = useState<string>('1058');
    const [technicalManagerDocNumber, setTechnicalManagerDocNumber] = useState<string>('');

    return (
        <ProviderUpdateRequestWarningContext.Provider
            value={{
                forceHasChanges,
                setForceHasChanges,
                countryCode,
                setCountryCode,
                technicalManagerDocNumber,
                setTechnicalManagerDocNumber,
            }}
        >
            {children}
        </ProviderUpdateRequestWarningContext.Provider>
    );
};
