import { FC, useContext } from 'react';
import * as yup from 'yup';
import { FinancialProviderData } from 'clients/manager/interfaces/financial-provider-data.interface';
import { SignUpProviderContext } from '../../../../common/components/base/pages/context/SignUpProviderContext';
import { useFormik } from 'formik';
import ProviderFinancialDataSignupView from './provider-financial-data-signup-view';

const ProviderFinancialDataSignup: FC = () => {
    const { setFianancialProvider, loading } = useContext(SignUpProviderContext);

    const initialValues: FinancialProviderData = {
        docNumberReceiver: '',
        exerciseYear: '',
        classification: '',
        operationalResult: '',
        netWorth: 0,
        workingCapital: 0,
        providerId: 0,
    };

    const validationSchema = yup.object({
        docNumberReceiver: yup.string(),
        exerciseYear: yup.string(),
        classification: yup.string(),
        operationalResult: yup.string(),
        netWorth: yup.number(),
        workingCapital: yup.number(),
        providerId: yup.number(),
    });

    const onSubmit = (values: FinancialProviderData) => {
        setFianancialProvider(values);
    };

    const formFinancialProvider = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <ProviderFinancialDataSignupView
            formFinancialProvider={formFinancialProvider}
            loading={loading}
        />
    );
};

export default ProviderFinancialDataSignup;
