import React, { FC, useState, useEffect } from 'react';
import { ItemPriceProps } from './props';
import ItemPriceModel from '../item-price-model';

const ItemPrice: FC<ItemPriceProps> = React.memo(
    ({ price, onSelected, provider, pricesSelected }) => {
        const [checked, setChecked] = useState<boolean>(false);

        // tentativa de melhorar desempenho em lista grande
        // se ocorrer problemas remover
        useEffect(() => {
            if (Object.keys(pricesSelected).length === 0) {
                setChecked(false);
            }
        }, [pricesSelected]);

        return provider ? (
            <ItemPriceModel
                checked={checked}
                setChecked={setChecked}
                price={price}
                provider={provider}
                onSelected={onSelected}
            />
        ) : null;
    }
);

export default ItemPrice;
