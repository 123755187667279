export const getBase64FromFile = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const start = 'base64,';
            const base64 = reader.result?.toString().split(start)[1];
            resolve(base64 ?? '');
        };
        reader.onerror = (error) => reject(error);
    });
