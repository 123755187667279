import { Grid, Card, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import ModalGroupActions from '../ModalGroupActions';

const EditGroupAuthorizationTableView = ({
    categories,
    selectedCategory,
    setSelectedCategory,
    authorizationGroupId,
    readOnly,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Card>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Typography variant='h6'>
                            {t('group-auth.pages.edit-group.components.category-actions-group')}
                        </Typography>
                    </Grid>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('group-auth.pages.edit-group.components.category-name')}
                                </TableCell>
                                <TableCell align='center'>
                                    {t('group-auth.pages.edit-group.components.authorization')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.map((row) => (
                                <TableRow
                                    hover
                                    key={row.name}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setSelectedCategory(row)}
                                >
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell align='center'>
                                        {`${row?.totalLinked}/${row?.totalActions}`}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Card>

            {selectedCategory && (
                <ModalGroupActions
                    {...{ selectedCategory, setSelectedCategory, authorizationGroupId, readOnly }}
                />
            )}
        </>
    );
};

export default EditGroupAuthorizationTableView;
