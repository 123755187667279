import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 10,
            '& .MuiListItemIcon-root': {
                minWidth: 'initial',
            },
            '& .leftIcon': {
                width: 80,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& img': {
                    marginLeft: '-20px',
                },
            },
            '& .Mui-selected': {
                '& .MuiListItemIcon-root': {
                    color: theme.palette.secondary.main,
                },
            },
            '& .MuiTypography-colorTextSecondary': {
                color: '#777777',
            },

            '& .MuiListItemText-primary': {
                fontWeight: 500,
                fontSize: 20,
            },
            '& .Mui-selected .MuiListItemText-primary': {
                color: theme.palette.secondary.main,
            },
            '& .Mui-selected .MuiTypography-colorTextSecondary': {
                color: theme.palette.secondary.main,
            },
            '& .MuiListItem-root': {
                backgroundColor: theme.palette.background.paper,
                border: '1px solid #dddddd',
                marginBottom: 10,
                '& :hover': {
                    '& .MuiListItemText-primary': {
                        color: theme.palette.secondary.main,
                    },
                    '& .MuiTypography-colorTextSecondary': {
                        color: theme.palette.secondary.main,
                    },
                },
                '&.Mui-selected': {
                    border: `1px solid ${theme.palette.secondary.main}`,
                },
            },
            '& .checkIcon': {
                minMidth: 'initial',
                justifyContent: 'flex-end',
                marginTop: '-25px',
            },
        },
    })
);
