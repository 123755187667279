import { FC } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CheckCircleIcon } from 'common/icons';

export const useStyles = makeStyles(() => ({
    iconValid: {
        color: '#02AE51',
    },
    iconInvalid: {
        color: '#E74C3C',
    },
    customTooltipWidth: {
        maxWidth: 600,
        fontSize: '14px',
        maxHeight: '700px',
    },
}));

interface ErrorStatusProps {
    isValid: boolean;
    title: any;
    onlyError?: boolean;
}

const ErrorStatus: FC<ErrorStatusProps> = ({ isValid, title, onlyError }) => {
    const classes = useStyles();

    if (isValid && onlyError) {
        return null;
    }

    return (
        <Box display='flex' alignItems='center'>
            {isValid ? (
                <CheckCircleIcon className={classes.iconValid} />
            ) : (
                <Tooltip
                    title={<>{title}</>}
                    aria-label='info'
                    classes={{
                        tooltip: classes.customTooltipWidth,
                    }}
                >
                    <ErrorOutlineIcon className={classes.iconInvalid} />
                </Tooltip>
            )}
        </Box>
    );
};

export default ErrorStatus;
