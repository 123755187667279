import { CircularProgress, Grid, makeStyles, Checkbox, Typography } from '@material-ui/core';
import { Button, Modal } from 'common/components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    list: {
        width: theme.spacing(75),
        maxHeight: theme.spacing(50),
        minHeight: theme.spacing(25),
        overflow: 'auto',
    },
    loadingContainer: {
        height: theme.spacing(20),
    },
    buttons: {
        padding: theme.spacing(2),
        gap: theme.spacing(2),
    },
}));

const ModalGroupActionsView = ({
    selectedCategory,
    actions,
    handleToggle,
    handleSave,
    handleClose,
    readOnly,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const header = (
        <span>
            {t('group-auth.pages.edit-group.components.modal-group.category-actions')}{' '}
            <strong>{selectedCategory.name}</strong>
        </span>
    );

    return (
        <Modal open onClose={handleClose} {...{ header }}>
            <>
                <List className={classes.list}>
                    {actions &&
                        actions.map((action) => {
                            const labelId = `checkbox-list-label-${action.id}`;

                            return (
                                <ListItem
                                    key={action.id}
                                    role={undefined}
                                    dense
                                    button
                                    onClick={handleToggle(action)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge='start'
                                            checked={action.enabled}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        secondary={
                                            <Typography noWrap>{action.description}</Typography>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    {!actions && (
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            className={classes.loadingContainer}
                        >
                            <CircularProgress />
                        </Grid>
                    )}
                </List>

                <Grid container justifyContent='flex-end' className={classes.buttons}>
                    <Button size='small' onClick={handleClose}>
                        {t('term.cancel')}
                    </Button>
                    {!readOnly && (
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            onClick={handleSave}
                        >
                            {t('term.save')}
                        </Button>
                    )}
                </Grid>
            </>
        </Modal>
    );
};

export default ModalGroupActionsView;
