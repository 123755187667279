import { TableCellProps } from '@material-ui/core';
import i18n from 'i18next';

export interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: TableCellProps['align'];
}

export const getColumns = (): Column[] => [
    { id: 'itemDescription', label: i18n.t('term.description'), minWidth: 320 },
    { id: 'unitMeasurement', label: i18n.t('term.unit-measurement'), minWidth: 130 },
    {
        id: 'amount',
        label: i18n.t('term.amount'),
        minWidth: 70,
        align: 'right',
    },
    {
        id: 'referenceValue',
        label: i18n.t('term.unit-value'),
        minWidth: 120,
        align: 'right',
    },
    {
        id: 'totalValue',
        label: i18n.t('term.total-value'),
        minWidth: 130,
        align: 'right',
    },
];
