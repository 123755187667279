import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatPxToRem } from 'common/utils';

const helperText = (_theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: formatPxToRem(5),
    fontFamily: 'Public Sans Variable',
    fontSize: formatPxToRem(12),
    fontWeight: 400,
    lineHeight: formatPxToRem(16),
    marginTop: formatPxToRem(8),
});

const useStyles = makeStyles<Theme>((theme) => ({
    error: {
        ...helperText(theme),
        color: '#CC2A18',
    },
    loading: {
        ...helperText(theme),
        color: '#15151A',
    },
    icon: {
        height: formatPxToRem(16),
        userSelect: 'none',
        pointerEvents: 'none',
    },
}));

export default useStyles;
