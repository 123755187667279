import { memo, useCallback, useState } from 'react';
import { Button, ButtonProps } from 'common/components';
import ForumIcon from '@material-ui/icons/Forum';
import { processStageToBiddingStageId } from 'common/utils';
import { useProcessFormContext } from '../../context/process-form.context';
import { ProcessStage } from '../../../../clients/manager/interfaces/auction-notice.interface';
import ModalListChats from '../modal-list-chats';

const ButtonProcessChat = (props?: ButtonProps) => {
    const [visibleChatList, setVisibleChatList] = useState(false);
    const { process, processForm } = useProcessFormContext();

    const handleClickViewChat = async () => {
        if (!process) {
            return;
        }

        setVisibleChatList(true);
    };

    const inBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processForm?.values?.biddingStageId &&
            biddingStage.includes(processForm.values.biddingStageId),
        [processForm?.values?.biddingStageId]
    );

    if (
        !process ||
        !inBiddingStage(
            processStageToBiddingStageId([
                ProcessStage.dispute,
                ProcessStage.contract,
                ProcessStage.decision,
            ])
        )
    ) {
        return null;
    }

    return (
        <>
            {visibleChatList && <ModalListChats onClose={() => setVisibleChatList(false)} />}
            <Button
                {...props}
                color='secondary'
                variant='contained'
                size='xsmall'
                onClick={handleClickViewChat}
                startIcon={<ForumIcon />}
            >
                Chat
            </Button>
        </>
    );
};

export default memo(ButtonProcessChat);
