import { Grid, Hidden, Card, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Check } from '@material-ui/icons';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import ImgLink from 'common/components/ImgLink';
import { StyledAvatar, StyledLinearProgress, cardStyle } from './account-journey-style';
import AccountJourneyListItem from './components/account-journey-list-item';

const AccountJourneyProgress = ({ accountJourneyStatus }) => {
    const { t } = useTranslation();

    const totalProgress = 20 + Object.values(accountJourneyStatus).filter(Boolean).length * 20;

    return (
        <>
            <Typography variant='body2' color='secondary' style={{ margin: '0 16px' }}>
                <strong>{totalProgress}%</strong> -{' '}
                {t('provider-dashboard.components.complete-journey')}
            </Typography>
            <StyledLinearProgress variant='determinate' color='secondary' value={totalProgress} />
        </>
    );
};

const AccountJourneyView = ({ accountJourneyStatus, name, imgSrc, imgHref }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const providerId = authUserTokenData?.providerId;

    const handleRedirect = (tab: string) => {
        history.push(`/empresa/editar/${providerId}`, {
            info: tab,
        });
    };

    return (
        <Card style={cardStyle}>
            <Typography variant='h6'>
                {t('term.hello')} {name}, {t('provider-dashboard.components.next-steps')} 🎉
            </Typography>
            <br />
            <Grid container>
                <Grid item xs={12} md={7}>
                    {accountJourneyStatus && (
                        <AccountJourneyProgress {...{ accountJourneyStatus }} />
                    )}

                    <List dense disablePadding>
                        <AccountJourneyListItem
                            primaryText='provider-dashboard.components.company-data'
                            done
                            onClick={() => handleRedirect('dados-gerais')}
                            number={1}
                        />

                        <AccountJourneyListItem
                            primaryText='provider-dashboard.components.supply-segments'
                            done={accountJourneyStatus?.hasSomeSupplyCategory}
                            onClick={() => handleRedirect('segmentos')}
                            number={2}
                        />

                        <AccountJourneyListItem
                            primaryText='provider-dashboard.components.legal-representatives'
                            done={accountJourneyStatus?.hasSomeLegalRepresentative}
                            onClick={() => handleRedirect('representantes-legais')}
                            number={3}
                        />

                        <AccountJourneyListItem
                            primaryText='provider-dashboard.components.submit-documents'
                            done={accountJourneyStatus?.hasAllRequiredDocumentTypes}
                            onClick={() => handleRedirect('documentos')}
                            number={4}
                        />

                        <ListItem component={RouterLink} to='/pesquisa'>
                            <ListItemAvatar>
                                <StyledAvatar done={accountJourneyStatus?.hasSomeLibraryDocument}>
                                    {accountJourneyStatus?.hasSomeLibraryDocument ? <Check /> : 5}
                                </StyledAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={t('provider-dashboard.components.locate-process')}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Hidden smDown>
                    <Grid item container alignItems='center' md={5}>
                        <ImgLink
                            width='100%'
                            href={imgHref}
                            src={imgSrc || '/assets/images/svgs/sign_up_person_img.png'}
                            alt={t('provider-dashboard.components.next-steps')}
                        />
                    </Grid>
                </Hidden>
            </Grid>
        </Card>
    );
};

export default AccountJourneyView;
