import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { debounce } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { disableField } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';

const PriceRegistrationField = () => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    const handleChangePriceRegistration = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        return processForm?.setValues({
            ...processForm?.values,
            [event.target.name]: value,
            ...(Number(value) === 1
                ? {
                      isRideAllowed: 0,
                      itsPriceRegistration: 1,
                      expirationDatePriceRegistration: processForm.values.expirationDatePriceRegistration,
                  }
                : {
                      isRideAllowed: 0,
                      itsPriceRegistration: 0,
                      expirationDatePriceRegistration: null,
                  }),
        });
    }, 30);

    return (
        <TextField
            fullWidth
            color='primary'
            label={t('term.price-registration')}
            name='itsPriceRegistration'
            onChange={handleChangePriceRegistration}
            variant='outlined'
            select
            defaultValue={processForm?.values.itsPriceRegistration}
            disabled={disableField('itsPriceRegistration', process)}
        >
            <MenuItem value={1}>{t('term.yes')}</MenuItem>
            <MenuItem value={0}>{t('term.no')}</MenuItem>
        </TextField>
    );
};

export default PriceRegistrationField;
