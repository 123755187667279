import { FC, useEffect, useState } from 'react';
import { extractorRequests, StartExtractionDto } from 'clients/price-base/extractor.requests';
import { Extraction } from 'common/interfaces/extraction.interface';
import { Modal } from '@licitar/ui-kit';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { usePageContext } from 'common/components/base/pages/context';
import Breadcrumb from 'common/components/base/breadcrumb';
import { useTranslation } from 'react-i18next';
import { addNotificationError } from 'common/utils';
import ExtractionList from '../../components/extractionList';
import ExtractorForm from '../../components/extractorForm';
import LastExtractionResume from '../../components/lastExtractionResume';

const Overview: FC<any> = () => {
    const { t } = useTranslation();
    const [extractions, setExtractions] = useState<undefined | Extraction[]>(undefined);
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const { setTitle } = usePageContext();

    const fetchExtractions = async () => {
        const extractionsData = await extractorRequests.getExtractions();
        setExtractions(extractionsData);
    };

    useEffect(() => {
        setTitle(t('term.extractor'));
        fetchExtractions();
    }, []);

    const onExtractStart = async (values: StartExtractionDto) => {
        try {
            const extraction = await extractorRequests.startExtraction(values);
            if (extraction.ok) {
                setTimeout(fetchExtractions, 100);
            }
        } catch (e) {
            addNotificationError({
                message: t('mkp.search.error-start-extration'),
            });
        }
    };

    const onCancelExtraction = async () => {
        try {
            if (extractions) {
                const extraction = await extractorRequests.cancelExtraction(extractions[0]._id);
                if (extraction.ok) {
                    setTimeout(fetchExtractions, 100);
                }
            }
        } catch (e) {
            addNotificationError({
                message: t('mkp.search.error-cancel-extration'),
            });
        }
    };

    return (
        <PageWrapper>
            <Breadcrumb
                paths={{
                    'banco-precos': {
                        value: t('shared.pages.menu-header.option.price-bank'),
                        disabled: true,
                    },
                    configuracoes: t('term.settings'),
                    extrator: t('term.extract'),
                }}
            />
            {extractions && (
                <>
                    <LastExtractionResume
                        extraction={extractions[0]}
                        onExtract={() => setModalOpened(true)}
                        onReload={fetchExtractions}
                    />
                    <ExtractionList extractions={extractions} onCancel={onCancelExtraction} />
                    <Modal
                        width='400px'
                        opened={modalOpened}
                        onClickOutSide={() => setModalOpened(false)}
                    >
                        <ExtractorForm
                            onExtract={(values) => {
                                setModalOpened(false);
                                onExtractStart(values);
                            }}
                        />
                    </Modal>
                </>
            )}
        </PageWrapper>
    );
};

export default Overview;
