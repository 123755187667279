import { useTranslation } from 'react-i18next';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/Cancel';
import { getFilenameFromUrl } from 'common/utils';
import useStyles from './file-preview-style';

interface FilePreviewProps {
    url: string;
    onDelete: () => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ url, onDelete }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const filename = getFilenameFromUrl(url);

    return (
        <Paper className={classes.filesRoot}>
            <Typography variant='caption'>
                {getNewFileName({ name: filename } as File, 15)}
            </Typography>
            <IconButton size='small' onClick={onDelete}>
                <Tooltip title={t('term.remove')}>
                    <DeleteForeverIcon fontSize='default' color='inherit' />
                </Tooltip>
            </IconButton>
        </Paper>
    );
};

export default FilePreview;
