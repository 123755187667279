import { FC, useRef, useState, useEffect } from 'react';
import { Editor as TEditor } from '@tinymce/tinymce-react';
import { nanoid } from 'nanoid';
import { EditorProps } from './props';
import { EditorConfigs } from './editor-config.service';
import { Content } from './styles';
import { EditorVariable, EDITOR_ID, EDITOR_KEY } from '.';
import EditorLoader from '../editor-loader';

// usado como se fosse o fetch de uma api para o mentions_fetch do tinymce
// pegar as variaveis customizadas quando variavel é criada depois do editor estar montado
// eslint-disable-next-line import/no-mutable-exports
export let externalVariables: EditorVariable[] = [];

const EditorCreator: FC<EditorProps> = ({
    initialData,
    onChange,
    variables,
    onUploadFile,
    visible = true,
}) => {
    const editorRef: any = useRef(null);
    const [loadingEditor, setLoadingEditor] = useState(true);

    const plainVariables = () => {
        const newest: EditorVariable[] = [];
        Object.keys(variables).forEach((k) => {
            variables[k]?.forEach((v) => {
                newest.push({
                    id: String(v.id),
                    name: v.name,
                    description: v.description,
                    custom: k === 'customs',
                });
            });
        });
        return newest;
    };

    const [localEditor, setLocalEditor] = useState<string>(initialData);

    useEffect(() => {
        setLocalEditor(initialData);
    }, [initialData]);

    const handleVariableClick = () => {
        // const newElement = `<strong>${tmpElement.innerHTML}</strong>`;
        // return editorRef.current.insertContent(newElement);
    };

    const [editorKey] = useState(nanoid());

    const onChangeEditor = () => {
        if (editorRef.current) {
            return onChange(editorRef.current.getContent());
        }
    };

    useEffect(() => {
        externalVariables = plainVariables();
    }, [Object.keys(variables.customs).length]);

    return (
        <Content visible={visible}>
            <div>
                {loadingEditor ? <EditorLoader /> : null}
                <TEditor
                    key={editorKey}
                    id={EDITOR_ID}
                    apiKey={EDITOR_KEY}
                    onInit={(_, editor) => {
                        editorRef.current = editor;
                        setLoadingEditor(false);
                    }}
                    initialValue={localEditor}
                    onChange={onChangeEditor}
                    init={EditorConfigs.init(true, onUploadFile, handleVariableClick, [], true)}
                />
            </div>
        </Content>
    );
};

export default EditorCreator;
