import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import ProcessFormErrors from '../process-form-errors';

interface ProcessContentHeaderProps {
    title: string;
    partialProcessFormLabels: { [key: string]: string };
}

const ProcessContentHeader: FC<ProcessContentHeaderProps> = ({
    title,
    partialProcessFormLabels,
}) => (
    <Box display='flex' alignItems='center'>
        <Typography variant='body1'>
            <b>{title}</b>
        </Typography>
        <ProcessFormErrors partialProcessFormLabels={partialProcessFormLabels} />
    </Box>
);

export default ProcessContentHeader;
