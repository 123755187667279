import React from 'react';

import CodeValidatorActionsView from './code-validator-actions-view';

interface CodeValidatorActionsProps {
    onClose?: () => void;
    onConfirm?: () => void;
    confirmButtonText?: string;
    closeButtonText?: string;
    disabled?: boolean;
}

const CodeValidatorActions: React.FC<CodeValidatorActionsProps> = (props) => {
    return <CodeValidatorActionsView {...props} />;
};

export default CodeValidatorActions;
