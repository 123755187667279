import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import ExpandedContentCard from 'modules/process/components/expand-content-card';
import { getProcessParticipantFormLabels } from 'modules/process/context/schema/process-participant-form-schema';
import { InfoIcon } from 'common/icons';
import { Alert } from '@material-ui/lab';
import InputMask from 'react-input-mask';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { formatDate } from 'common/utils';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import ProcessContentHeader from '../process-content-header';
import { useStyles } from './process-participant-style';

const ProcessParticipantView = ({
    disabledExpand,
    processForm,
    participant,
    handleChangeDefault,
    isNewParticipant,
    isButtonDisabled,
    showButtonCleanData,
    deleteInvite,
    showMessage,
    handleParticipantDocumentChange,
    handleGetParticipant,
    loading,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const dateLastSendInvite = processForm?.values.lastSendAt
        ? `${t('term.lastSend')} ${formatDate(processForm?.values.lastSendAt, 'L LT')}`
        : '';

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <ProcessContentHeader
                            title={t('process.components.process-participant-data')}
                            partialProcessFormLabels={getProcessParticipantFormLabels()}
                        />
                    }
                >
                    <Grid container>
                        {showMessage ? (
                            <>
                                <Alert severity='info' variant='standard' className={classes.alert}>
                                    <Box
                                        width={1}
                                        display='flex'
                                        justifyContent='space-between'
                                        alignItems='center'
                                    >
                                        <Typography variant='body2'>
                                            {t('term.proponent-not-registred')}
                                        </Typography>
                                    </Box>
                                </Alert>
                            </>
                        ) : (
                            <></>
                        )}
                        <Grid container spacing={2} alignItems='center' sm={12} md={12} xl={12}>
                            <Grid item sm={4} md={4} xl={4}>
                                <InputMask
                                    mask={
                                        participant && participant.document.length <= 11
                                            ? '999.999.999-99****'
                                            : '99.999.999/9999-99'
                                    }
                                    maskChar={null}
                                    value={(participant && participant.document) ?? ''}
                                    onChange={handleParticipantDocumentChange}
                                    disabled={showButtonCleanData}
                                >
                                    {() => (
                                        <TextField
                                            fullWidth
                                            color='primary'
                                            label={t('term.proponent-cpf-cnpj')}
                                            name='participantDocument'
                                            inputProps={{
                                                maxLength: 18,
                                            }}
                                            variant='outlined'
                                            className={classes.textField}
                                            defaultValue={processForm?.values.participantDocument}
                                            disabled={showButtonCleanData}
                                        />
                                    )}
                                </InputMask>
                            </Grid>

                            <Grid item>
                                <Tooltip
                                    title={t('term.proponent-tooltip-info') as string}
                                    aria-label='info'
                                >
                                    <div className={classes.tooltipIcon}>
                                        <InfoIcon color='secondary' />
                                    </div>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                {showButtonCleanData ? (
                                    <ConfirmDialog
                                        title={t('info.confirm-remove-participate')}
                                        onConfirm={deleteInvite}
                                    >
                                        <Button
                                            variant='contained'
                                            size='xsmall'
                                            color='secondary'
                                            disabled={
                                                processForm?.values?.biddingStageId >
                                                BiddingStageEnum.draft
                                            }
                                        >
                                            {t('term.clear-data')}
                                        </Button>
                                    </ConfirmDialog>
                                ) : (
                                    <Button
                                        variant='contained'
                                        size='xsmall'
                                        color='primary'
                                        onClick={handleGetParticipant}
                                        disabled={isButtonDisabled}
                                        className={classes.searchButton}
                                    >
                                        {t('term.search-')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                        {loading ? (
                            <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                marginTop='1rem'
                                marginBottom='1rem'
                                marginLeft='50%'
                            >
                                <CircularProgress />
                            </Box>
                        ) : isNewParticipant ? (
                            <>
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems='center'
                                    sm={12}
                                    md={12}
                                    xl={12}
                                >
                                    <Grid item sm={4} md={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            color='primary'
                                            label={t('term.name')}
                                            name='participantName'
                                            variant='outlined'
                                            className={classes.textField}
                                            defaultValue={processForm?.values.participantName}
                                            onChange={handleChangeDefault}
                                            disabled={showButtonCleanData && showMessage}
                                        />
                                    </Grid>
                                    <Grid item sm={7} md={7} xl={7}>
                                        <TextField
                                            fullWidth
                                            color='primary'
                                            label={t('term.email')}
                                            name='participantEmail'
                                            variant='outlined'
                                            type='email'
                                            className={classes.textField}
                                            defaultValue={processForm?.values.participantEmail}
                                            onChange={handleChangeDefault}
                                            disabled={showButtonCleanData && showMessage}
                                            helperText={dateLastSendInvite}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            participant &&
                            participant.document !== '' &&
                            participant.name !== '' &&
                            participant.email !== '' && (
                                <>
                                    <Grid item xs={12}>
                                        <TableContainer
                                            component={Paper}
                                            variant='outlined'
                                            className={classes.tableContainer}
                                        >
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{t('term.cpf-cnpj')}</TableCell>
                                                        <TableCell>{t('term.name')}</TableCell>
                                                        <TableCell>
                                                            {t(
                                                                'bidding.process.lot.provider.dialog.zip-code'
                                                            )}
                                                        </TableCell>
                                                        <TableCell>{t('term.state')}</TableCell>
                                                        <TableCell>{t('term.city')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            {participant.document}
                                                        </TableCell>
                                                        <TableCell>{participant.name}</TableCell>
                                                        <TableCell>{participant.zipCode}</TableCell>
                                                        <TableCell>{participant.state}</TableCell>
                                                        <TableCell>{participant.city}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item sm={7} md={7} xl={7}>
                                        <div>
                                            <small>{dateLastSendInvite}</small>
                                        </div>
                                    </Grid>
                                </>
                            )
                        )}
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessParticipantView;
