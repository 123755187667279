import { styled } from 'common/theme/styled';

const DownloadButton = styled.a`
    text-decoration: none;
`;

const ActionsArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0 0;
`;

export { DownloadButton, ActionsArea };
