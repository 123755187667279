/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect } from 'react';
import { addNotificationError, addNotificationInfo, formatCurrency } from 'common/utils';
import { Price, PriceMountType } from 'common/interfaces/price.interface';
import { Provider } from 'common/interfaces/provider.interface';
import { useHistory } from 'react-router';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import { providerRequests } from 'clients/price-base/providers.requests';
import { useTranslation } from 'react-i18next';
import ItemPriceHeader from '../item-price-header';
import { SkeletonLines } from './skeleton';
import ItemPrice from '../item-price';
import {
    Card,
    Details,
    PricesAmountDetails,
    MeasureDetails,
    DescriptionDetails,
    PriceMountDetails,
    PriceDetails,
    IconArrowDown,
    IconArrowRight,
    IconMoreOptions,
} from './styled';
import { PriceListItemProps } from './props';
import DropDownV2, { DropDownOption } from '../drop-down';

const PriceListItem: FC<PriceListItemProps> = ({ priceListItem, onItemDeleted }) => {
    const [showPrices, setShowPrices] = useState(false);
    const [prices, setPrices] = useState<Price[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const { t } = useTranslation();

    const history = useHistory();

    const getPriceMountTypeName = (priceMountType: PriceMountType) => {
        switch (priceMountType) {
            case PriceMountType.lowerPrice:
                return t('term.lowest-price');

            case PriceMountType.media:
                return t('term.average');

            case PriceMountType.median:
                return t('term.median');

            default:
                break;
        }
    };

    const deleteItem = async () => {
        try {
            await priceListRequests.deleteItemPriceList(
                priceListItem.priceListId,
                priceListItem._id
            );
            onItemDeleted();
            addNotificationInfo({
                title: t('mkp.user-price-list.item-deleted'),
                message: t('mkp.user-price-list.item-deleted-success'),
            });
        } catch (error) {
            addNotificationError({
                message: t('mkp.user-price-list.error-delete-item-list'),
            });
        }
    };

    const editItem = () => {
        history.push({
            pathname: `/banco-precos/minhas-listas/${priceListItem.priceListId}/pesquisa/editar`,
            search: `?itemId=${priceListItem._id}&q=${priceListItem.description}`,
        });
    };

    const options: DropDownOption[] = [
        {
            label: t('term.delete'),
            action: () => deleteItem(),
        },
        {
            label: t('mkp.user-price-list.redo-search'),
            action: () => editItem(),
        },
    ];

    const getItemPrices = async () => {
        try {
            const { itemPrices = [] } = await priceListRequests.getItemPrices(
                priceListItem.priceListId,
                priceListItem._id
            );
            setPrices(itemPrices || []);
        } catch (error) {
            addNotificationError({
                message: t('mkp.user-price-list.error-query-price-item'),
            });
        }
    };

    useEffect(() => {
        if (!showPrices || prices.length > 0) {
            return;
        }
        getItemPrices();
    }, [showPrices]);

    const getProviders = async () => {
        const providerIds = new Set(prices.map((price) => price.providerId));

        try {
            const providersData = await providerRequests.getProviders(Array.from(providerIds));
            setProviders(providersData);
        } catch (error) {
            addNotificationError({
                message: t('mkp.user-price-list.error-query-information-suppliers'),
            });
        }
    };

    useEffect(() => {
        if (prices.length === 0 || providers.length > 0) {
            return;
        }
        getProviders();
    }, [prices]);

    const priceMountTypeName = getPriceMountTypeName(priceListItem.priceMountType);
    const formattedReferenceValue = formatCurrency(priceListItem.referenceValue);

    return (
        <>
            <Card onClick={() => priceListItem.itemPrices.length > 0 && setShowPrices(!showPrices)}>
                {priceListItem.itemPrices.length > 0 && (
                    <PricesAmountDetails>
                        <span>{`(${priceListItem.itemPrices?.length})`}</span>
                        {showPrices ? <IconArrowDown /> : <IconArrowRight />}
                    </PricesAmountDetails>
                )}
                <Details>
                    <MeasureDetails>
                        <span title={priceListItem.unitMeasurement}>
                            {priceListItem.unitMeasurement}
                        </span>
                    </MeasureDetails>
                    <DescriptionDetails>
                        <span title={priceListItem.description}>
                            {priceListItem.description || '-'}
                        </span>
                    </DescriptionDetails>
                    <PriceMountDetails title={priceMountTypeName}>
                        <span>{priceMountTypeName}</span>
                    </PriceMountDetails>
                    <PriceDetails>
                        <span title={formattedReferenceValue}>{formattedReferenceValue}</span>
                    </PriceDetails>
                </Details>
                <div style={{ width: '25px' }}>
                    <DropDownV2 options={options}>
                        <IconMoreOptions />
                    </DropDownV2>
                </div>
            </Card>
            {showPrices ? (
                prices.length === 0 ? (
                    <SkeletonLines size={priceListItem.itemPrices.length} />
                ) : (
                    <>
                        <ItemPriceHeader />
                        {prices?.map((price) => {
                            const provider = providers.find(
                                (currProvider) => currProvider._id === price.providerId
                            );

                            return <ItemPrice key={price._id} price={price} provider={provider} />;
                        })}
                    </>
                )
            ) : null}
        </>
    );
};

export default PriceListItem;
