import React, { FC, ReactNode, useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import HelpIconLabel from 'modules/process/components/help-icon-label';
import { disableField, processUtils } from 'modules/process/process-utils';
import { processActions } from 'modules/process/process-actions';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface RandomEndTimeFieldProps {
    handleChangeDefault: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RandomEndTimeField: FC<RandomEndTimeFieldProps> = ({ handleChangeDefault }) => {
    const { auctionTypeRules, processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    const randomEndTimeDefaultValue =
        Number(processForm?.values.randomEndTime) < 1 ? null : processForm?.values.randomEndTime;

    const randomEndTimeOptions = useMemo(() => {
        if (Number(auctionTypeRules?.generalSettings.cadastro.permitirRandomico) === 1) {
            const opt: ReactNode = <MenuItem value={0}>{t('info.do-not-apply')}</MenuItem>;
            const opts = new Array(25).fill(null).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={index + 6}>
                    {`${String(index + 6).padStart(2, '0')} ${t('term.minutes')}`}
                </MenuItem>
            ));
            return [opt, ...opts];
        }
        if (
            auctionTypeRules?.generalSettings.cadastro.permitirRandomico &&
            Number(auctionTypeRules.generalSettings.cadastro.permitirRandomico) > 1
        ) {
            return (
                <MenuItem value={auctionTypeRules?.generalSettings.cadastro.permitirRandomico}>
                    {`${String(
                        auctionTypeRules?.generalSettings.cadastro.permitirRandomico
                    ).padStart(2, '0')} ${t('term.minutes')}`}
                </MenuItem>
            );
        }

        if (
            auctionTypeRules?.generalSettings.cadastro.permitirRandomico &&
            Number(auctionTypeRules.generalSettings.cadastro.permitirRandomico) === 0
        ) {
            return (
                <MenuItem value={auctionTypeRules?.generalSettings.cadastro.permitirRandomico}>
                    {t('info.do-not-apply')}
                </MenuItem>
            );
        }
    }, []);

    return (
        <HelpIconLabel
            title={
                <>
                    <p>{t('info.randon-end-time')}</p>
                </>
            }
        >
            <TextField
                fullWidth
                color='primary'
                label={t('term.random')}
                name='randomEndTime'
                onChange={handleChangeDefault}
                variant='outlined'
                select
                defaultValue={randomEndTimeDefaultValue}
                disabled={disableField('randomEndTime', process)}
            >
                {randomEndTimeOptions}
            </TextField>
        </HelpIconLabel>
    );
};

export default RandomEndTimeField;
