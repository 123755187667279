import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    input: {
        width: '100%',
        padding: theme.spacing(0.5),
        fontSize: '14px',
        border: `1px ${theme.palette.grey[400]} solid`,
    },
    textArea: {
        width: '100%',
        padding: theme.spacing(1.0),
        fontSize: '14px',
        border: `1px ${theme.palette.grey[400]} solid`,
    },
}));

export const CustomLabel = withStyles(() =>
    createStyles({
        root: {
            '&:hover': {
                color: '#444',
            },
            '&$active': {
                color: '#444',
            },
        },
        icon: {
            color: 'inherit !important',
        },
    })
)(Box);
