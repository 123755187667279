import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { useSearchHolidaysContext } from './context/SearchHolidaysContext';
import SearchHolidaysView from './SearchHolidaysView';

const SearchHolidays = () => {
    const { holidayDialog, setHolidayDialog } = useSearchHolidaysContext();

    const { t } = useTranslation();
    const { setTitle } = usePageContext();

    useEffect(() => {
        setTitle(t('term.holidays'));
    }, []);

    const handleNew = () => setHolidayDialog({ open: true });

    return <SearchHolidaysView {...{ handleNew, holidayDialog }} />;
};

export default SearchHolidays;
