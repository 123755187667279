import {
    Card,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import {
    GetNextDisputesBody,
    GetNextDisputesData,
} from 'modules/citizen-dashboard/interfaces/next-disputes.interface';
import { Dispatch, SetStateAction } from 'react';
import { Button } from 'common/components';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { getDisputeUrl } from 'common/utils/getters/get-url-redirect-dispute.utils';
import { usePlatformContext } from 'routes/platform.context';

const DisputeSkeleton = () => (
    <TableRow>
        <TableCell>
            <Skeleton width={70} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={120} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={190} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={50} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={60} variant='text' />
        </TableCell>
    </TableRow>
);

interface NextDisputesViewProps {
    process?: GetNextDisputesData[];
    loading: boolean;
    date: string;
    setNextDisputesBody: Dispatch<SetStateAction<GetNextDisputesBody>>;
}

const NextDisputesView = ({
    process,
    loading,
    date,
    setNextDisputesBody,
}: NextDisputesViewProps) => {
    const { t } = useTranslation();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const { platform } = usePlatformContext();

    const getLink = (processID, service: string) =>
        getDisputeUrl(processID, authUserTokenData, service, platform);

    return (
        <Card>
            <Grid container justifyContent='space-between'>
                <Grid item>
                    <Typography variant='h6'>
                        {t('citizen-dashboard.components.next-disputes.track-disputes')}
                    </Typography>
                </Grid>
                <Grid item>
                    <KeyboardDatePicker
                        autoOk
                        size='small'
                        variant='inline'
                        inputVariant='outlined'
                        label={t('term.dispute-date')}
                        format='DD/MM/yyyy'
                        value={date || null}
                        InputAdornmentProps={{ position: 'end' }}
                        invalidDateMessage={t('info.invalid-format', { ns: 'validation' })}
                        invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                        onChange={(date) => {
                            if (date?.isValid()) {
                                setNextDisputesBody((state) => ({
                                    ...state,
                                    date: date?.format('yyyy-MM-DD'),
                                }));
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.number')}</TableCell>
                        <TableCell>{t('term.public-entity')}</TableCell>
                        <TableCell>{t('term.object')}</TableCell>
                        <TableCell>{t('term.time')}</TableCell>
                        <TableCell>{t('term.link')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <>
                            <DisputeSkeleton />
                            <DisputeSkeleton />
                        </>
                    )}
                    {!loading &&
                        process &&
                        process.map((row) => (
                            <TableRow>
                                <TableCell>{row.auctionNumber}</TableCell>
                                <TableCell>{row.organizationName}</TableCell>
                                <TableCell>{row.simpleDescription}</TableCell>
                                <TableCell>
                                    {moment(row.startDateTimeDispute).format('HH:mm')}
                                </TableCell>
                                <TableCell>
                                    <Link
                                        href={getLink(row.id, row.service)}
                                        target='_blank'
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Button size='small' variant='outlined'>
                                            {t('citizen-dashboard.components.next-disputes.track')}
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    {process && !loading && !process.length && (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    );
};

export default NextDisputesView;
