import PrivateRoute from 'routes/components/private-route';
import NotificationDetails from './pages/notification/notificationDetails';
import Notifications from './pages/notification/notifications';

const NotificationRoutes: JSX.Element[] = [
    <PrivateRoute path='/notificacoes' key='/notificacoes' exact component={Notifications} />,
    <PrivateRoute
        path='/notificacoes/:notificationId'
        key='/notificacoes/:notificationId'
        exact
        component={NotificationDetails}
    />,
];

export default NotificationRoutes;
