import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationUpdateRequestPriority } from '../../../../../clients/manager/interfaces/provider-registration-update-requests.interface';

interface SelectPriorityProps {
    value: RegistrationUpdateRequestPriority | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    omitAllOption?: boolean;
}

const SelectPriority: FC<SelectPriorityProps> = ({ value, onChange, omitAllOption }) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='priority'
            label={t('term.priority')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            variant='outlined'
            select
            value={value}
        >
            {!omitAllOption && <MenuItem value={undefined}>{t('term.all')}</MenuItem>}
            <MenuItem value={RegistrationUpdateRequestPriority.low}>{t('term.low')}</MenuItem>
            <MenuItem value={RegistrationUpdateRequestPriority.medium}>{t('term.medium')}</MenuItem>
            <MenuItem value={RegistrationUpdateRequestPriority.high}>{t('term.high')}</MenuItem>
        </TextField>
    );
};

export default SelectPriority;
