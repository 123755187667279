import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType, hasPermissionToExecute } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import NotFound from 'routes/not-found';
import { ProcessDocumentsStepsContextProvider } from './context/process-document-steps';
import { ProcessFormContextProvider } from './context/process-form.context';
import { ProcessLotsFormContextProvider } from './context/process-lots-form.context';
import CreateProcess from './pages/create-process';
import ProcessList from './pages/process-list';
import { ContractsContextProvider } from './components/process-contracts-data/context/contracts.context';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <ProcessFormContextProvider>
            <ProcessLotsFormContextProvider>
                <ProcessDocumentsStepsContextProvider>
                    <ContractsContextProvider>
                        <Switch>
                            <PrivateRoute
                                canAccess={() =>
                                    hasCurrentAccessType([TypeAccess.organization]) &&
                                    hasPermissionToExecute('listarProcessos')
                                }
                                path={`${match.path}`}
                                exact
                                component={() => <ProcessList />}
                            />
                            <PrivateRoute
                                canAccess={() =>
                                    hasCurrentAccessType([TypeAccess.organization]) &&
                                    hasPermissionToExecute('listarProcessos')
                                }
                                exact
                                path={`${match.path}/novo`}
                                component={() => <CreateProcess />}
                            />
                            <PrivateRoute
                                canAccess={() =>
                                    hasCurrentAccessType([TypeAccess.organization]) &&
                                    hasPermissionToExecute('listarProcessos')
                                }
                                exact
                                path={`${match.path}/:auctionId`}
                                component={() => <CreateProcess />}
                            />
                            <PrivateRoute path='*' component={() => <NotFound />} />
                        </Switch>
                    </ContractsContextProvider>
                </ProcessDocumentsStepsContextProvider>
            </ProcessLotsFormContextProvider>
        </ProcessFormContextProvider>
    );
};

const ProcessRoutes: JSX.Element[] = [
    <Route path='/processos' key='/processos' render={() => <ChildRoutes />} />,
];

export default ProcessRoutes;
