import React from 'react';
import { useTranslation } from 'react-i18next';

import { StatusPayment } from 'common/enums';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { SelectStatusPaymentProps } from './select-status-payment';

const SelectStatusPaymentView: React.FC<SelectStatusPaymentProps> = ({
    value,
    onChange,
    disableAllOption,
}) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='status'
            label={t('invoices.page.search-invoices.search-invoices-table.statusPayment')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            variant='outlined'
            select
            value={value}
        >
            {!disableAllOption && <MenuItem value={undefined}>{t('term.all')}</MenuItem>}
            <MenuItem value={StatusPayment.pending}>{t('payment.pending')}</MenuItem>
            <MenuItem value={StatusPayment.free}>{t('payment.free')}</MenuItem>
            <MenuItem value={StatusPayment.paid}>{t('payment.paid')}</MenuItem>
            <MenuItem value={StatusPayment.canceled}>{t('payment.canceled')}</MenuItem>
        </TextField>
    );
};

export default SelectStatusPaymentView;
