import { GenericBody } from 'clients/interfaces/paginated.interface';
import { ProcessDocumentsSteps } from './process-documents-steps.interface';
import { DocumentSigners } from './document-signers.interface';
import { TypeRegistrationEnum } from './auction-notice.interface';

export enum ContractTypeId {
    contrato_termo_inicial = 'contrato_termo_inicial',
    comodato = 'comodato',
    arrendamento = 'arrendamento',
    concessao = 'concessao',
    termo_adesao = 'termo_adesao',
    convenio = 'convenio',
    empenho = 'empenho',
    outros = 'outros',
    termo_execucao_descentralizada = 'termo_execucao_descentralizada',
    acordo_cooperacao_tecnica = 'acordo_cooperacao_tecnica',
    termo_compromisso = 'termo_compromisso',
    carta_contrato = 'carta_contrato',
}

export enum TermTypeIdEnum {
    termination_notice = 'termo_rescisao',
    addendum = 'termo_aditivo',
    apostilment_term = 'termo_apostilamento',
}

export enum ProcessCategoryId {
    cessao = 'cessao',
    compras = 'compras',
    informatica = 'informatica',
    internacional = 'internacional',
    locacao_imoveis = 'locacao_imoveis',
    mao_de_obra = 'mao_de_obra',
    obras = 'obras',
    servicos = 'servicos',
    servicos_engenharia = 'servicos_engenharia',
    servicos_saude = 'servicos_saude',
}

export enum ContractVersion {
    v1 = 'v1',
    v2 = 'v2',
}

export enum SignatureMethod {
    internal = 'internal',
    external = 'external',
}

export enum ContractStatus {
    draft = 'draft',
    generated = 'generated',
    signatures = 'signatures',
    concluded = 'concluded',
    obsolete = 'obsolete',
}

export interface Assessment {
    id: number;
    generalRating: number;
    costBenefitRating: number;
    contractQualityRating: number;
    deadlineComplianceRating: number;
    detail: string;
    userId: number;
    contractId: string;
}

export interface Contract {
    id: string;
    auctionNoticeId: number;
    providerId: number;
    contractNumber: string;
    startDate: Date;
    endDate: Date;
    processCategoryId: ProcessCategoryId;
    contractTypeId: ContractTypeId;
    termTypeId: TermTypeIdEnum;
    contractVersion: ContractVersion;
    objectContract: string;
    additionalInformation: string;
    pncpLink?: string;
    initialValue: number;
    accumulatedValue: number;
    installments: number;
    deadline?: string;
    contractInspector?: string;
    deliveryWaysAndAddress?: string;
    signtureMethod?: SignatureMethod;
    status: ContractStatus;
    contractOriginId?: string;
    justificationOfTerm?: string;
    assessment?: Assessment;
    items?: ContractItem[];
}

export interface ContractItem {
    id: number;
    contractId: string;
    itemId: number;
    itemDescription: string;
    itemUnitMeasurement: string;
    itemAmount: number;
    itemValue: number;
}

export interface ContractGeneratedDocument {
    file: {
        location: string;
        createdAt: number;
        name: string;
    };
    sentForSignatureAt?: number;
}

export type ContractDocumentStep = Pick<ProcessDocumentsSteps, '_id' | 'type' | 'status'>;

export interface ContractWithDetails {
    processDocumentTemplateId?: string;
    contract: Contract;
    process: {
        id: number;
        auctionNumber: string;
        accreditationNumber: string;
        itsPriceRegistration: boolean;
        auctionType: string;
        legalSupportId: number;
        simpleDescription: string;
        pncpLink: string | string;
        typeRegistration: TypeRegistrationEnum;
    };
    provider: {
        id: number;
        companyName: string;
    };
    documentSigners?: DocumentSigners;
    generatedDocument?: ContractGeneratedDocument;
    processDocumentsStep?: ContractDocumentStep;
}

export interface ListContractsFromProcessResponse {
    processDocumentTemplateId?: string;
    contract?: Contract;
    process: {
        id: number;
        auctionNumber: string;
        accreditationNumber: string;
        itsPriceRegistration: boolean;
        auctionType: string;
        legalSupportId: number;
        simpleDescription: string;
        typeRegistration: TypeRegistrationEnum;
    };
    provider: {
        id: number;
        companyName: string;
    };
    documentSigners?: DocumentSigners;
    generatedDocument?: ContractGeneratedDocument;
    processDocumentsStep?: ContractDocumentStep;
}

export interface ListContractsBodyData {
    limit?: number;
    offset?: number;
    params?: {
        searchedText?: string;
    };
}

export interface ListContractsFromProcessParams {
    processId: number;
}

export interface ListContractsFromProcessFilters
    extends GenericBody<ListContractsFromProcessParams> {}

export interface UpdateContractData {
    params: {
        contractId: string | null;
    };
    fileChanged?: boolean;
    processCategoryId?: ProcessCategoryId | null;
    contractTypeId?: ContractTypeId | null;
    contractNumber?: string | null;
    objectContract?: string | null;
    additionalInformation?: string | null;
    startDate?: string | null | Date;
    endDate?: string | null | Date;
    initialValue?: number | null;
    accumulatedValue?: number | null;
    installments?: number | null;
    deadline?: string;
    contractInspector?: string;
    deliveryWaysAndAddress?: string;
    signtureMethod?: SignatureMethod;
}

export interface CreateContractData {
    processId: number;
    providerId: number;
    simpleDescription: string;
}

export interface CreateContractTermData {
    params: {
        contractId: string;
    };
    processCategoryId: ProcessCategoryId;
    contractTypeId: ContractTypeId;
    termTypeId: TermTypeIdEnum;
    contractNumber: string;
    objectContract: string;
    additionalInformation: string;
    startDate: string | Date;
    endDate: string | Date;
    initialValue: number;
    accumulatedValue: number;
    installments: number;
    deadline?: string;
    contractInspector?: string;
    deliveryWaysAndAddress?: string;
    signtureMethod?: SignatureMethod;
}

export interface RatingContractRequestBody {
    generalRating: number;
    costBenefitRating?: number;
    contractQualityRating?: number;
    deadlineComplianceRating?: number;
    detail?: string;
    contractId: string;
    userId?: number;
}
