import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    Box,
} from '@material-ui/core';
import { Button } from 'common/components';
import ProviderAddModal from 'modules/sign-up/components/ProviderAddModal';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';
import { DeleteIcon } from 'common/icons';
import { useProviderUpdateRequestWarningContext } from 'common/components/provider-update-request-warning/context';
import VisibleMaskDoc from 'modules/sign-up/components/visible-mask-doc-number';
import { CONSTANTS } from 'common/constants';
import { providerRequests } from '../../../../../clients/manager/provider.requests';
import { BoxEmpty } from '../styles';

export interface ProviderFinancialDataProps {
    idProvider?: number;
    disabled?: boolean;
}

const LegalRepresentative: FC<ProviderFinancialDataProps> = ({
    idProvider = 0,
    disabled = false,
}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { listLegalRepresentative, providerId, setLegalRepresentative } =
        useContext(SignUpProviderContext);
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const handleCloseModal = () => {
        setModalOpen(false);
        setForceHasChanges(true);
    };

    const deleteRepresentative = async (representativeUserId: number) => {
        let providerIdParam = Number(providerId);
        if (idProvider) providerIdParam = idProvider;
        await providerRequests.deleteLegalRepresentative(providerIdParam, representativeUserId);
    };

    const handleDelete = async (userId: number) => {
        try {
            setLoading(true);
            await deleteRepresentative(userId);
            setLegalRepresentative({});
        } catch (e: any) {
            return e;
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };

    const { t } = useTranslation();

    const getTypeTranslations = (type: string) => {
        switch (type) {
            case 'nationalRepresentative':
                return t('term.nationalRepresentative');
                break;
            case 'administrator':
                return t('term.administrator');
                break;
            case 'proxy':
                return t('term.proxy');
                break;

            default:
                break;
        }
    };

    return (
        <>
            <Box mb={1} width={1} display='flex' justifyContent='flex-end'>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => setModalOpen(true)}
                    disabled={disabled}
                >
                    {t('term.new')}
                </Button>
            </Box>
            <Grid>
                {listLegalRepresentative.length === 0 ? (
                    <BoxEmpty>
                        <Typography>Sem registros</Typography>
                    </BoxEmpty>
                ) : (
                    <TableContainer>
                        <Table size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('term.type')}</TableCell>
                                    <TableCell>{t('term.name')}</TableCell>
                                    <TableCell>{t('term.documents')}</TableCell>
                                    <TableCell>{t('term.email')}</TableCell>
                                    <TableCell>{t('term.phone')}</TableCell>
                                    <TableCell>{t('term.actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listLegalRepresentative.length !== 0 &&
                                    listLegalRepresentative.map(
                                        ({
                                            name,
                                            userId,
                                            cpf,
                                            email,
                                            job,
                                            phone,
                                            type,
                                            doc,
                                            country,
                                        }) => (
                                            <TableRow key={userId}>
                                                <TableCell>{getTypeTranslations(type)}</TableCell>
                                                <TableCell>
                                                    {name}
                                                    {job && <p>{job}</p>}
                                                </TableCell>
                                                <TableCell>
                                                    <VisibleMaskDoc
                                                        cpf={cpf}
                                                        rg={doc}
                                                        isForeignDocument={
                                                            country !== CONSTANTS.ibgeCodes.BRAZIL
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell> {email}</TableCell>
                                                <TableCell>{phone}</TableCell>
                                                {listLegalRepresentative.length > 1 ? (
                                                    <TableCell
                                                        component='th'
                                                        align='right'
                                                        scope='row'
                                                        padding='normal'
                                                        size='small'
                                                    >
                                                        <IconButton
                                                            aria-label='delete'
                                                            onClick={() => handleDelete(userId)}
                                                            disabled={loading || disabled}
                                                        >
                                                            <DeleteIcon fontSize='small' />
                                                        </IconButton>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        component='th'
                                                        align='right'
                                                        scope='row'
                                                        padding='normal'
                                                        size='small'
                                                        style={{ height: '55px' }}
                                                    />
                                                )}
                                            </TableRow>
                                        )
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
            <ProviderAddModal modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
        </>
    );
};

export default LegalRepresentative;
