import React from 'react';
import RatingIndicatorView from './rating-indicator-view';
import { FullStarIcon, HalfStarIcon, EmptyStarIcon } from './components';

interface RatingIndicatorProps {
    max: number;
    actual: number;
}

const RatingIndicator: React.FC<RatingIndicatorProps> = ({ max, actual }) => {
    function mountRating() {
        const rating: React.ReactNode[] = [];

        for (let i = 1; i < max + 1; i++) {
            if (i <= actual) rating.push(FullStarIcon);
            else if (i - actual < 1) rating.push(HalfStarIcon);
            else rating.push(EmptyStarIcon);
        }

        return rating;
    }

    return <RatingIndicatorView stars={mountRating()} />;
};

export default RatingIndicator;
