import { Card } from '@licitar/ui-kit';
import { styled } from 'common/theme/styled';
import { BsEye } from 'react-icons/bs';
import { MdContentCopy } from 'react-icons/md';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ListArea = styled(Card)`
    padding: 10px 15px;
    background: #fff;

    table {
        background: #fff;

        tr,
        td {
            color: ${(props) => props.theme.colors.textDefault};
            font-size: 14px;
        }
    }
`;

const ActionsArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const ViewIcon = styled(BsEye)`
    font-size: 17px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.iconColorDefault};
`;

const CopyIcon = styled(MdContentCopy)`
    font-size: 17px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.iconColorDefault};
`;

const InfoArea = styled.div`
    margin: 20px;
    display: flex;
    justify-content: center;
    flex: 1;
`;

export { Header, ListArea, ActionsArea, ViewIcon, InfoArea, CopyIcon };
