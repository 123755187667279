import { doRequest, managerApiInstance } from 'clients/api';
import { AxiosRequestConfig } from 'axios';
import {
    GenericResponse,
    NoDataGenericResponse,
    PlainGenericResponse,
} from '../interfaces/paginated.interface';
import {
    ChatFavoriteMessage,
    CreateFavoriteMessage,
    DeleteFavoriteMessage,
    UpdateFavoriteMessage,
} from './interfaces/chat-favorite-message.interface';

const BASE_PATH = '/chat/favorite-message';

const chatFavoriteMessageRequest = {
    list: async (config?: AxiosRequestConfig<any>) =>
        doRequest<GenericResponse<ChatFavoriteMessage>>(
            managerApiInstance.post(`${BASE_PATH}/list`, {}, config)
        ),
    create: async (body: CreateFavoriteMessage, config?: AxiosRequestConfig<any>) =>
        doRequest<PlainGenericResponse<ChatFavoriteMessage>>(
            managerApiInstance.post(`${BASE_PATH}/create`, body, config)
        ),
    update: async (body: UpdateFavoriteMessage, config?: AxiosRequestConfig<any>) =>
        doRequest<NoDataGenericResponse>(
            managerApiInstance.post(`${BASE_PATH}/update`, body, config)
        ),
    delete: async (body: DeleteFavoriteMessage, config?: AxiosRequestConfig<any>) =>
        doRequest<NoDataGenericResponse>(
            managerApiInstance.post(`${BASE_PATH}/delete`, body, config)
        ),
};

export { chatFavoriteMessageRequest };
