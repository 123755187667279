import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { styled } from 'common/theme/styled';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    padding: 15px 0 5px 0;
    background: ${(props) => props.theme.colors.backgroundPageDefault};
    z-index: 10;
`;

const Page = styled(PageWrapper)`
    padding-top: 0;
`;

export { Header, Page };
