import { FC, useEffect, useRef, useState } from 'react';
import Breadcrumb from 'common/components/base/breadcrumb';
import { Button, ButtonSize } from '@licitar/ui-kit';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { useHistory, useParams } from 'react-router-dom';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import { DocumentVariables } from 'clients/manager/interfaces/document-variables.interface';
import { usePageContext } from 'common/components/base/pages/context';
import { useTranslation } from 'react-i18next';
import { templateVariablesRequests } from 'clients/manager/documents/template-variables.requests';
import { addNotificationError, addNotificationSuccess, addNotificationWarning } from 'common/utils';
import { EditorCreator } from '../../../components/editor';
import ModalCreateVariable from '../../components/modal-create-variable';
import VariablesList from '../../components/variables-list';
import { defaultVariables, editalVariables } from '../../../services/variables.service';
import { Header, EditArea, Page } from './styles';

interface Params {
    [key: string]: string;
}

interface EditTemplateProps {}

const EditTemplate: FC<EditTemplateProps> = () => {
    const [template, setTemplate] = useState<DocumentTemplate | undefined>();
    const [editorState, setEditorState] = useState<string>('');
    const [showVariables, setShowVariables] = useState(false);
    const [creatingVariable, setCreatingVariable] = useState(false);
    const [editingVariable, setEditingVariable] = useState<DocumentVariables | undefined>();
    const [customVariables, setCustomVariables] = useState<DocumentVariables[]>([]);
    const { t } = useTranslation();

    const editorStateRef = useRef(editorState);
    const { templateId = undefined } = useParams<Params>();
    const { setTitle } = usePageContext();

    const history = useHistory();

    const getTemplate = async () => {
        if (!templateId) {
            return;
        }

        try {
            const { data: docTemplate } = await templateRequests.getTemplate(templateId);
            setTemplate(docTemplate);
            setEditorState(`${docTemplate.content}`);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.templates.components.templates.pages.notification-error-onload-model'
                ),
            });
        }
    };

    useEffect(() => {
        setTitle(t('editor.templates.components.templates.pages.edit-model'));
        getTemplate();
    }, []);

    const getCustomVariables = async () => {
        try {
            const data = await templateVariablesRequests.getVariables();
            if (data?.length) {
                setCustomVariables(data);
            }
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.templates.components.templates.pages.notification-error-onload-variables'
                ),
            });
        }
    };

    useEffect(() => {
        getCustomVariables();
    }, []);

    const onBeforeUnload = (e: any) => {
        if (editorStateRef.current) {
            e.returnValue = '';
            return;
        }
        return undefined;
    };

    UseWindowEvent('beforeunload', (e) => onBeforeUnload(e), []);

    const updateTemplate = async () => {
        if (!template) {
            return;
        }

        try {
            await templateRequests.updateTemplate({
                ...template,
                content: editorState,
            });
            editorStateRef.current = '';

            return addNotificationSuccess({
                title: t(
                    'editor.templates.components.templates.pages.notification-success-save-model'
                ),
                message: t(
                    'editor.templates.components.templates.pages.notification-success-save-model'
                ),
            });
        } catch (error) {
            return addNotificationError({
                title: t('term.err'),
                message: t(
                    'editor.templates.components.templates.pages.notification-error-save-model'
                ),
            });
        }
    };

    const handleSaveEditor = async () => updateTemplate();

    const handleShowVariables = () => {
        setShowVariables(true);
    };

    const handleEditorChange = (html: string) => {
        editorStateRef.current = html;
        setEditorState(html);
    };

    return (
        <Page>
            <Header>
                <Breadcrumb
                    customPath='modelos/:templateId/editar'
                    paths={{
                        modelos: {
                            value: t('editor.templates.components.templates.pages.list-model'),
                            onClick: () => history.push('/processos/editor/modelos'),
                        },
                        editar: { value: t('term.edit'), disabled: true },
                    }}
                    params={{
                        templateId: {
                            value: template?.name ?? '',
                            onClick: () =>
                                history.push(`/processos/editor/modelos/${template?._id}`),
                        },
                    }}
                />
                <div style={{ display: 'flex' }}>
                    <Button
                        size={ButtonSize.sm}
                        title={t(
                            'editor.templates.components.templates.pages.title-create-new-model'
                        )}
                        style={{ margin: '2px 0 0 10px' }}
                        onClick={handleShowVariables}
                        outline
                    >
                        {t('editor.process.pages.edit-process.index.show-variables')}
                    </Button>
                    <Button
                        size={ButtonSize.sm}
                        title={t(
                            'editor.templates.components.templates.pages.title-create-new-model'
                        )}
                        style={{ margin: '2px 0 0 10px' }}
                        onClick={() => handleSaveEditor()}
                    >
                        {t('term.save')}
                    </Button>
                </div>
            </Header>
            {creatingVariable && (
                <ModalCreateVariable
                    opened={creatingVariable}
                    onClose={() => setCreatingVariable(false)}
                    onCreate={(variable) => {
                        setCreatingVariable(false);
                        setShowVariables(true);
                        setCustomVariables((prevState) => [...prevState, variable]);
                    }}
                />
            )}
            {!!editingVariable && (
                <ModalCreateVariable
                    variable={editingVariable}
                    opened={!!editingVariable}
                    onClose={() => setEditingVariable(undefined)}
                    onCreate={(variable) => {
                        setEditingVariable(undefined);
                        setShowVariables(true);
                        setCustomVariables((prevState) => [
                            ...prevState.map((customVariable) => {
                                if (customVariable._id === variable._id) {
                                    return variable;
                                }
                                return customVariable;
                            }),
                        ]);
                    }}
                />
            )}
            <EditArea>
                {template ? (
                    <EditorCreator
                        onUploadFile={(blob) =>
                            templateRequests.uploadTemplateImage(template._id, blob)
                        }
                        variables={{
                            defaults: defaultVariables(),
                            customs:
                                customVariables?.map((variable) => ({
                                    description: variable.description,
                                    id: variable._id,
                                    name: variable.name,
                                    values: variable.values ?? [],
                                })) ?? [],
                            auction: editalVariables(template.processTypes),
                        }}
                        initialData={template?.content ?? ''}
                        onChange={handleEditorChange}
                    />
                ) : null}
                {showVariables && template && (
                    <VariablesList
                        visible={showVariables}
                        onClickCreateVariable={() => {
                            setShowVariables(false);
                            setCreatingVariable(true);
                        }}
                        onClickEditVariable={(variable) => {
                            setShowVariables(false);
                            setEditingVariable(variable);
                        }}
                        onClose={() => setShowVariables(false)}
                        variables={{
                            defaults: defaultVariables(),
                            customs: customVariables ?? [],
                            auction: editalVariables(template.processTypes),
                        }}
                    />
                )}
            </EditArea>
        </Page>
    );
};

export default EditTemplate;
