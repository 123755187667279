import { ChangeEvent, FC, useState, useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { TypeOfBenefitProps } from 'modules/process/interfaces/type-of-benefit';
import { TypeOfBenefitValue } from '../../../../clients/manager/interfaces/auction-notice-lot.interface';
import { AuctionType } from '../../../../clients/manager/interfaces/auction-notice.interface';
import HelpIconLabel from '../help-icon-label';
import { TypeBenefitDialog } from '../dialog-benefit-type';

const TypeOfBenefit: FC<TypeOfBenefitProps> = ({
    auctionType,
    onChange,
    showDoNotChanges,
    omitOptions,
    disabledOptions,
    isMasterQuotaSaved,
    value,
    ...rest
}) => {
    const { t } = useTranslation();
    const isVisibleOption = (opt: TypeOfBenefitValue) => !omitOptions?.includes(opt);
    const isDisabledOption = (opt: TypeOfBenefitValue) => !!disabledOptions?.includes(opt);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [typeOfBenefit, setTypeOfBenefit] = useState<TypeOfBenefitValue | null>(value ?? null);
    const [previousTypeOfBenefit, setPreviousTypeOfBenefit] = useState<TypeOfBenefitValue | null>(value ?? null);
    const defaultOptions: JSX.Element[] = [];
    const exemptionOptions: JSX.Element[] = [];
    const options = auctionType === AuctionType.dispense ? exemptionOptions : defaultOptions;
    const title = t('term.title-confirm-change-benefit-type');
    const messageBase = t('term.message-confirm-change-benefit-type');
    const message = messageBase + t('term.message-confirm-change-benefit-type-master-quota');

    useEffect(() => {
        setTypeOfBenefit(value ?? TypeOfBenefitValue.noBenefit);
        setPreviousTypeOfBenefit(value ?? TypeOfBenefitValue.noBenefit);
    }, [value]);

    const setVisibleOptions = () => {
        if (isVisibleOption(TypeOfBenefitValue.noBenefit)) {
            defaultOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.noBenefit}
                    key={TypeOfBenefitValue.noBenefit}
                    disabled={isDisabledOption(TypeOfBenefitValue.noBenefit)}
                >
                    {t('process.components.no-benefit')}
                </MenuItem>
            );
        }

        if (isVisibleOption(TypeOfBenefitValue.isExclusive)) {
            defaultOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.isExclusive}
                    disabled={isDisabledOption(TypeOfBenefitValue.isExclusive)}
                >
                    {t('process.components.exclusive-to')}
                </MenuItem>
            );
        }

        if (isVisibleOption(TypeOfBenefitValue.masterQuota)) {
            defaultOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.masterQuota}
                    key={TypeOfBenefitValue.masterQuota}
                    disabled={isDisabledOption(TypeOfBenefitValue.masterQuota)}
                >
                    {t('process.components.apply-quota')}
                </MenuItem>
            );
        }

        if (isVisibleOption(TypeOfBenefitValue.reservedQuota)) {
            defaultOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.reservedQuota}
                    key={TypeOfBenefitValue.reservedQuota}
                    disabled={isDisabledOption(TypeOfBenefitValue.reservedQuota)}
                >
                    {t('term.reserved-quota')}
                </MenuItem>
            );
        }

        if (showDoNotChanges) {
            defaultOptions.unshift(<MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>);
        }

        if (isVisibleOption(TypeOfBenefitValue.noBenefit)) {
            exemptionOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.noBenefit}
                    key={TypeOfBenefitValue.noBenefit}
                    disabled={isDisabledOption(TypeOfBenefitValue.noBenefit)}
                >
                    {t('process.components.no-benefit')}
                </MenuItem>
            );
        }

        if (isVisibleOption(TypeOfBenefitValue.isExclusive)) {
            exemptionOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.isExclusive}
                    key={TypeOfBenefitValue.isExclusive}
                    disabled={isDisabledOption(TypeOfBenefitValue.isExclusive)}
                >
                    {t('process.components.exclusive-to')}
                </MenuItem>
            );
        }

        if (isVisibleOption(TypeOfBenefitValue.subcontracting)) {
            exemptionOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.subcontracting}
                    key={TypeOfBenefitValue.subcontracting}
                    disabled={isDisabledOption(TypeOfBenefitValue.subcontracting)}
                >
                    {t('process.components.subcontracting')}
                </MenuItem>
            );
        }

        if (isVisibleOption(TypeOfBenefitValue.masterQuota)) {
            exemptionOptions.push(
                <MenuItem
                    value={TypeOfBenefitValue.masterQuota}
                    key={TypeOfBenefitValue.masterQuota}
                    disabled={isDisabledOption(TypeOfBenefitValue.masterQuota)}
                >
                    {t('process.components.apply-quota')}
                </MenuItem>
            );
        }

        if (showDoNotChanges) {
            exemptionOptions.unshift(<MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>);
        }
    };

    setVisibleOptions();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value as TypeOfBenefitValue;
        setTypeOfBenefit(newValue);

        if (isMasterQuotaSaved) {
            setShowDialog(true);
        } else {
            onChange(newValue);
        }
    };

    const handleConfirm = () => {
        if (typeOfBenefit != null) onChange(typeOfBenefit);
        setShowDialog(false);
    };

    const handleCancel = () => {
        setTypeOfBenefit(previousTypeOfBenefit);
        setShowDialog(false);
    };

    return (
        <>
            <HelpIconLabel title={<p>{t('info.benefit-type-label')}</p>}>
                <TextField
                    fullWidth
                    id='select'
                    color='primary'
                    label={t('term.benefit-type')}
                    variant='outlined'
                    select
                    value={typeOfBenefit}
                    onChange={handleInputChange}
                    {...rest}
                >
                    {options}
                </TextField>
            </HelpIconLabel>

            {isMasterQuotaSaved && showDialog && (
                <TypeBenefitDialog
                    open={showDialog}
                    title={title}
                    message={message}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
};

export default TypeOfBenefit;
