import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { styled } from 'common/theme/styled';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Page = styled(PageWrapper)`
    padding-top: 0;
`;

const Span = styled.span`
    font-size: 11px;
    color: #5da82f;
    display: block;
    padding-top: 3px;
`;

export { Header, Page, Span };
