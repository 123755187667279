import { MenuItem } from '@material-ui/core';
import { StatusApprovedSupplyCategories } from 'clients/manager/interfaces/supply-categories-providers.interface';
import { TextField } from 'common/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectStatusSegmentsProps {
    value: StatusApprovedSupplyCategories | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    omitAllOption?: boolean;
}

const SelectStatusSegments: FC<SelectStatusSegmentsProps> = ({
    value,
    onChange,
    omitAllOption,
}) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='status'
            label='Status'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            variant='outlined'
            select
            value={value}
        >
            {!omitAllOption && <MenuItem value={undefined}>{t('term.all')}</MenuItem>}
            <MenuItem value={StatusApprovedSupplyCategories.approved}>
                {t('term.approved')}
            </MenuItem>
            <MenuItem value={StatusApprovedSupplyCategories.reproved}>
                {t('term.reproved')}
            </MenuItem>
            <MenuItem value={StatusApprovedSupplyCategories.pending}>{t('term.pending')}</MenuItem>
            <MenuItem value={StatusApprovedSupplyCategories.expired}>{t('term.expired')}</MenuItem>
        </TextField>
    );
};

export default SelectStatusSegments;
