import { addNotificationSuccess } from 'common/utils';
import { useFormik } from 'formik';
import {
    DocumentStage,
    DocumentTypeDTO,
} from 'modules/document-types/interfaces/document-type.dto';
import DocumentTypesService from 'modules/document-types/services/DocumentTypesService';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
    CategoriesDocuments,
    categoriesDocumentsRequests,
} from 'clients/manager/categories-documents.requests';
import { DocumentTypesProposal } from 'clients/manager/document-types.requests';
import SearchDocumentTypesDialogView from './SearchDocumentTypesDialogView';

interface SearchDocumentTypesDialogProps {
    setDocumentTypeDialog: Dispatch<
        SetStateAction<{
            open: boolean;
        }>
    >;
    updateDocumentTypeList?: (newDocument: DocumentTypesProposal) => void;
}
const SearchDocumentTypesDialog: FC<SearchDocumentTypesDialogProps> = ({
    setDocumentTypeDialog,
    updateDocumentTypeList,
}) => {
    const { t } = useTranslation();

    const [categoriesDocuments, setCategoriesDocuments] = useState<CategoriesDocuments[]>([]);

    useEffect(() => {
        const fetchCategoriesDocuments = async () => {
            const { data } = await categoriesDocumentsRequests.listCategoriesDocuments();
            setCategoriesDocuments(data);
        };

        fetchCategoriesDocuments();
    }, []);

    const handleClose = () => setDocumentTypeDialog({ open: false });

    const initialValues = {
        categoryDocumentId: undefined,
        documentName: '',
        documentDescription: '',
        documentRequired: false,
        actived: true,
    };

    const validationSchema = Yup.object().shape({
        categoryDocumentId: Yup.number().required(t('term.required-field')),
        documentName: Yup.string().required(t('term.required-field')),
    });

    const onSubmit = async (values: DocumentTypeDTO, { setSubmitting }) => {
        try {
            const { data } = await DocumentTypesService.createDocumentType({
                ...values,
                documentStage: DocumentStage.process,
            });
            if (updateDocumentTypeList) updateDocumentTypeList(data);
            addNotificationSuccess();
            handleClose();
        } finally {
            setSubmitting(false);
        }
    };

    const form = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return <SearchDocumentTypesDialogView {...{ form, categoriesDocuments, handleClose }} />;
};

export default SearchDocumentTypesDialog;
