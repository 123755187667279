import { FC, useEffect, useState } from 'react';
import { documentBlocksRequests } from 'clients/manager/documents/document-blocks.requests';
import {
    DocumentBlockType,
    DocumentBlocks,
} from 'clients/manager/interfaces/document-blocks.interface';
import { useTranslation } from 'react-i18next';
import { addNotificationWarning } from 'common/utils';
import OrganizationDocumentBlocksView from './organization-document-blocks-view';

interface OrganizationDocumentBlocksProps {}

const OrganizationDocumentBlocks: FC<OrganizationDocumentBlocksProps> = () => {
    const [organizationBlocks, setOrganizationBlocks] = useState<DocumentBlocks[]>([]);
    const { t } = useTranslation();

    const getDocumentBlocks = async () => {
        try {
            const response = await documentBlocksRequests.listBlocks();
            setOrganizationBlocks(response?.data ?? []);
        } catch (error) {
            addNotificationWarning({
                title: t('term.err'),
                message: t('organization.edit.components.notification-error-not-found-data'),
            });
        }
    };

    useEffect(() => {
        getDocumentBlocks();
    }, []);

    const headerBlock = organizationBlocks.find((block) => block.type === DocumentBlockType.header);
    const footerBlock = organizationBlocks.find((block) => block.type === DocumentBlockType.footer);

    const onDocumentBlockCreated = (createdBlock: DocumentBlocks) => {
        setOrganizationBlocks((prevState) => [...prevState, createdBlock]);
    };

    return (
        <OrganizationDocumentBlocksView
            headerBlock={headerBlock}
            footerBlock={footerBlock}
            onDocumentBlockCreated={onDocumentBlockCreated}
        />
    );
};

export default OrganizationDocumentBlocks;
