import { Card, CardContent as CardContentDefault } from '@licitar/ui-kit';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { styled } from 'common/theme/styled';

const Content = styled(Card)``;

const CardContent = styled(CardContentDefault)`
    display: flex;
    justify-content: space-between;
`;

const Page = styled(PageWrapper)`
    padding-top: 0 !important;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.backgroundPageDefault};
    padding: 15px 0;
    position: sticky;
    top: 0px;
    z-index: 10;
`;

const Title = styled.span`
    font-weight: 600;
    font-size: 18px;
    padding: 5px 0 15px 0;
`;

const CardInformations = styled.div`
    width: 40%;

    li {
        list-style-type: none;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    li::before {
        content: '';
        display: inline-block;
        vertical-align: bottom;
        padding: 10px 23px 0 0;
        height: 17px;
        width: 17px;
        background-image: url('/assets/icons/checked.svg');
        background-repeat: no-repeat;
    }
`;

const CardForm = styled.div`
    flex: 1;
    max-width: 600px;
`;

export { Content, Page, Header, Title, CardInformations, CardForm, CardContent };
