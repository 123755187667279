import { authRequests } from 'clients/manager/auth.requests';
import { DoLoginTypeAccess } from 'clients/manager/interfaces/auth.interface';
import { TypeAccess } from 'common/interfaces/user.interface';
import { addNotificationApiError, addNotificationError } from 'common/utils';
import { t } from 'i18next';
import { useHistory } from 'react-router';
import { setAuthToken, setAuthUserToken } from 'modules/auth/session.utils';
import AccessLikeItemView from './access-like-view';

interface AccessLikeItemProps {
    providerId: string;
}

const AccessLikeItem: React.FC<AccessLikeItemProps> = ({ providerId }) => {
    const history = useHistory();

    const handleAccessLike = async () => {
        try {
            const doLoginObject: DoLoginTypeAccess = {
                type: TypeAccess.provider,
                resourceId: providerId,
            };
            const response = await authRequests.doLoginTypeAccess(doLoginObject);
            if (response.data.token) {
                setAuthToken(response.data.token);
                setAuthUserToken(response.data.token);

                history.push(`/auth/?token=${response.data.token}&redirectUrl=/painel-fornecedor`);
                return;
            }

            return addNotificationError({
                title: t('term.err'),
                message: t('editor.components.editor.config.error-message'),
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    return <AccessLikeItemView onClick={handleAccessLike} />;
};

export default AccessLikeItem;
