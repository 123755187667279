import jsPDF from 'jspdf';

const addFooters = (doc: jsPDF | any) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(11);
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(9);
        doc.line(14, 282, 196, 282);
        doc.text('Licitar Digital - Sistema de Licitações Online', 15, 287);
        doc.text(`Página ${String(i)} de ${String(pageCount)}`, 174, 287);
    }
};

export { addFooters };
