import { FC } from 'react';
import { SelectStatusSegmentsCategoryProps } from './props';
import SelectStatusSegmentsCategoryView from './SelectStatusSegmentsCategoryView';

const SelectStatusSegmentsCategory: FC<SelectStatusSegmentsCategoryProps> = ({
    value,
    onChange,
}) => {
    return <SelectStatusSegmentsCategoryView onChange={onChange} value={value} />;
};

export default SelectStatusSegmentsCategory;
