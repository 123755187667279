import { Store } from 'react-notifications-component';
import { getTranslation, getLanguage } from 'common/utils';
import {
    BaseNotificationProps,
    NotificationProps,
    NotificationType,
    NotificationWithMessageProps,
} from 'common/interfaces/notification.interface';
import { AxiosError } from 'axios';
import { apiErrorsMessages } from 'common/constants/api-errors-messages.constant';
import { UserLanguages } from 'common/interfaces/user.interface';

const getCustomTranslation = (message: string) => getTranslation(message, 'notification');

const baseNotification = ({ title, message, type, duration }: BaseNotificationProps) =>
    Store.addNotification({
        title,
        message,
        type,
        insert: 'bottom',
        container: 'bottom-right',
        dismiss: {
            duration: duration ?? 4000,
            pauseOnHover: true,
            click: true,
            showIcon: true,
        },
    });

const addNotificationInfo = ({ title, message, duration }: NotificationWithMessageProps) =>
    baseNotification({
        title: title || getCustomTranslation('info.title'),
        message: message,
        duration: duration,
        type: NotificationType.info,
    });

const addNotificationSuccess = ({ title, message, duration }: NotificationProps = {}) =>
    baseNotification({
        title: title || getCustomTranslation('success.title'),
        message: message || getCustomTranslation('success.description'),
        duration: duration,
        type: NotificationType.success,
    });

const addNotificationWarning = ({ title, message, duration }: NotificationWithMessageProps) =>
    baseNotification({
        title: title || getCustomTranslation('warning.title'),
        message: message,
        duration: duration,
        type: NotificationType.warning,
    });

const addNotificationError = ({ title, message, duration }: NotificationProps = {}) =>
    baseNotification({
        title: title || getCustomTranslation('error.title'),
        message: message || getCustomTranslation('error.description'),
        duration: duration,
        type: NotificationType.danger,
    });

const addNotificationApiError = (
    error:
        | string
        | AxiosError<{
              message: { pt: string; en: string };
              token: string;
          }>
        | AxiosError<{
              message: string;
              token: string;
          }>,
    config?: Pick<Partial<BaseNotificationProps>, 'type' | 'title'>
) => {
    let message;
    if (typeof error === 'string') {
        message = error;
    } else {
        if (error?.response?.data.message !== 'string') {
            const currentLanguage = getLanguage();

            const ptMessage = (error as any)?.response?.data?.message?.pt;
            const enMessage = (error as any)?.response?.data?.message?.en;

            if (!!ptMessage && currentLanguage === UserLanguages['pt-BR']) message = ptMessage;
            if (!!enMessage && currentLanguage === UserLanguages.en) message = ptMessage;
        }

        if (
            !!error?.response?.data?.token &&
            Object.keys(apiErrorsMessages).includes(error.response.data.token)
        ) {
            message = getCustomTranslation(
                apiErrorsMessages[error?.response?.data?.token ?? 'DEFAULT_ERROR'] ??
                    'error.description'
            );
        }
    }

    baseNotification({
        title: config?.title ?? getCustomTranslation('error.title'),
        message: message || getCustomTranslation('error.description'),
        type: config?.type ?? NotificationType.danger,
    });
};

const addNotification = baseNotification;

export {
    addNotification,
    addNotificationInfo,
    addNotificationSuccess,
    addNotificationWarning,
    addNotificationError,
    addNotificationApiError,
};
