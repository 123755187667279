import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import EditMaterial from './pages/edit-material/EditMaterial';
import SearchMaterial from './pages/search-material/SearchMaterial';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute exact path={`${match.path}/novo`} component={() => <EditMaterial />} />
            <PrivateRoute exact path={`${match.path}/:id`} component={() => <EditMaterial />} />
            <PrivateRoute exact path={`${match.path}/`} component={() => <SearchMaterial />} />
        </Switch>
    );
};

const MaterialRoutes: JSX.Element[] = [
    <Route path='/materiais' key='/materiais' render={() => <ChildRoutes />} />,
];

export default MaterialRoutes;
