import * as Yup from 'yup';
import i18n from 'i18next';

export const getUnitDataPartialValidationSchema = () => ({
    organizationUnitId: Yup.number()
        .nullable(true)
        .required(i18n.t('required-field', { ns: 'validation' })),
});

export const getUnitDataFormLabels = () => ({
    organizationUnitId: i18n.t('term.organization-unit'),
});
