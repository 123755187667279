function buildFormatLongFn(args) {
    return (options = {} as any) => {
        // TODO: Remove String()
        const width = options.width ? String(options.width) : args.defaultWidth;
        const format = args.formats[width] || args.formats[args.defaultWidth];
        return format;
    };
}
function buildLocalizeFn(args) {
    return (dirtyIndex, options) => {
        const context = options?.context ? String(options.context) : 'standalone';

        let valuesArray;
        if (context === 'formatting' && args.formattingValues) {
            const defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
            const width = options?.width ? String(options.width) : defaultWidth;
            valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
        } else {
            const { defaultWidth } = args;
            const width = options?.width ? String(options.width) : args.defaultWidth;
            valuesArray = args.values[width] || args.values[defaultWidth];
        }
        const index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex;
        // @ts-ignore: For some reason TypeScript just don't want to match it, no matter how hard we try. I challenge you to try to remove it!
        return valuesArray[index];
    };
}
function buildMatchPatternFn(args) {
    return (string, options = {} as any) => {
        const matchResult = string.match(args.matchPattern);
        if (!matchResult) return null;
        const matchedString = matchResult[0];

        const parseResult = string.match(args.parsePattern);
        if (!parseResult) return null;
        let value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
        value = options.valueCallback ? options.valueCallback(value as any) : value;

        const rest = string.slice(matchedString.length);

        return { value, rest };
    };
}
function findKey(object, predicate) {
    for (const key in object) {
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(key) && predicate(object[key])) {
            return key;
        }
    }
    return undefined;
}

function findIndex(array, predicate: (item) => boolean): number | undefined {
    for (let key = 0; key < array.length; key++) {
        if (predicate(array[key])) {
            return key;
        }
    }
    return undefined;
}
function buildMatchFn(args) {
    return (string, options = {} as any) => {
        const { width } = options;

        const matchPattern =
            (width && args.matchPatterns[width]) || args.matchPatterns[args.defaultMatchWidth];
        const matchResult = string.match(matchPattern);

        if (!matchResult) {
            return null;
        }
        const matchedString = matchResult[0];

        const parsePatterns =
            (width && args.parsePatterns[width]) || args.parsePatterns[args.defaultParseWidth];

        const key = Array.isArray(parsePatterns)
            ? findIndex(parsePatterns, (pattern) => pattern.test(matchedString))
            : findKey(parsePatterns, (pattern: any) => pattern.test(matchedString));

        let value;

        value = args.valueCallback ? args.valueCallback(key) : key;
        value = options.valueCallback ? options.valueCallback(value as any) : value;

        const rest = string.slice(matchedString.length);

        return { value, rest };
    };
}

// FORMAT DISTANCE
const formatDistanceLocale = {
    lessThanXSeconds: {
        one: 'menos de um segundo',
        other: 'menos de {{count}} segundos',
    },

    xSeconds: {
        one: '1 segundo',
        other: '{{count}} segundos',
    },

    halfAMinute: 'meio minuto',

    lessThanXMinutes: {
        one: 'menos de um minuto',
        other: 'menos de {{count}} minutos',
    },

    xMinutes: {
        one: '1 minuto',
        other: '{{count}} minutos',
    },

    aboutXHours: {
        one: 'cerca de 1 hora',
        other: 'cerca de {{count}} horas',
    },

    xHours: {
        one: '1 hora',
        other: '{{count}} horas',
    },

    xDays: {
        one: '1 dia',
        other: '{{count}} dias',
    },

    aboutXWeeks: {
        one: 'cerca de 1 semana',
        other: 'cerca de {{count}} semanas',
    },

    xWeeks: {
        one: '1 semana',
        other: '{{count}} semanas',
    },

    aboutXMonths: {
        one: 'cerca de 1 mês',
        other: 'cerca de {{count}} meses',
    },

    xMonths: {
        one: '1 mês',
        other: '{{count}} meses',
    },

    aboutXYears: {
        one: 'cerca de 1 ano',
        other: 'cerca de {{count}} anos',
    },

    xYears: {
        one: '1 ano',
        other: '{{count}} anos',
    },

    overXYears: {
        one: 'mais de 1 ano',
        other: 'mais de {{count}} anos',
    },

    almostXYears: {
        one: 'quase 1 ano',
        other: 'quase {{count}} anos',
    },
};

const formatDistance = (token, count, options) => {
    let result;

    const tokenValue = formatDistanceLocale[token];
    if (typeof tokenValue === 'string') {
        result = tokenValue;
    } else if (count === 1) {
        result = tokenValue.one;
    } else {
        result = tokenValue.other.replace('{{count}}', String(count));
    }

    if (options?.addSuffix) {
        if (options.comparison && options.comparison > 0) {
            return `em ${result}`;
        }
        return `há ${result}`;
    }

    return result;
};

// FORMAT LONG
const dateFormats = {
    full: "EEEE, d 'de' MMMM 'de' y",
    long: "d 'de' MMMM 'de' y",
    medium: 'd MMM y',
    short: 'dd/MM/yyyy',
};

const timeFormats = {
    full: 'HH:mm:ss zzzz',
    long: 'HH:mm:ss z',
    medium: 'HH:mm:ss',
    short: 'HH:mm',
};

const dateTimeFormats = {
    full: "{{date}} 'às' {{time}}",
    long: "{{date}} 'às' {{time}}",
    medium: '{{date}}, {{time}}',
    short: '{{date}}, {{time}}',
};

const formatLong = {
    date: buildFormatLongFn({
        formats: dateFormats,
        defaultWidth: 'full',
    }),

    time: buildFormatLongFn({
        formats: timeFormats,
        defaultWidth: 'full',
    }),

    dateTime: buildFormatLongFn({
        formats: dateTimeFormats,
        defaultWidth: 'full',
    }),
};

// FORMAT RELATIVE
const formatRelativeLocale = {
    lastWeek: (date: Date): string => {
        const weekday = date.getDay();
        const last = weekday === 0 || weekday === 6 ? 'último' : 'última';
        return `'${last}' eeee 'às' p`;
    },
    yesterday: "'ontem às' p",
    today: "'hoje às' p",
    tomorrow: "'amanhã às' p",
    nextWeek: "eeee 'às' p",
    other: 'P',
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const formatRelative = (token, date, _baseDate, _options) => {
    const format = formatRelativeLocale[token];

    if (typeof format === 'function') {
        return format(date);
    }

    return format;
};

const eraValues = {
    narrow: ['AC', 'DC'] as const,
    abbreviated: ['AC', 'DC'] as const,
    wide: ['antes de cristo', 'depois de cristo'] as const,
};

const quarterValues = {
    narrow: ['1', '2', '3', '4'] as const,
    abbreviated: ['T1', 'T2', 'T3', 'T4'] as const,
    wide: ['1º trimestre', '2º trimestre', '3º trimestre', '4º trimestre'] as const,
};

const monthValues = {
    narrow: ['j', 'f', 'm', 'a', 'm', 'j', 'j', 'a', 's', 'o', 'n', 'd'] as const,
    abbreviated: [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez',
    ] as const,
    wide: [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
    ] as const,
};

const dayValues = {
    narrow: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'] as const,
    short: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'] as const,
    abbreviated: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'] as const,
    wide: [
        'domingo',
        'segunda-feira',
        'terça-feira',
        'quarta-feira',
        'quinta-feira',
        'sexta-feira',
        'sábado',
    ] as const,
};

const dayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'mn',
        noon: 'md',
        morning: 'manhã',
        afternoon: 'tarde',
        evening: 'tarde',
        night: 'noite',
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'meia-noite',
        noon: 'meio-dia',
        morning: 'manhã',
        afternoon: 'tarde',
        evening: 'tarde',
        night: 'noite',
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'meia-noite',
        noon: 'meio-dia',
        morning: 'manhã',
        afternoon: 'tarde',
        evening: 'tarde',
        night: 'noite',
    },
};

const formattingDayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'mn',
        noon: 'md',
        morning: 'da manhã',
        afternoon: 'da tarde',
        evening: 'da tarde',
        night: 'da noite',
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'meia-noite',
        noon: 'meio-dia',
        morning: 'da manhã',
        afternoon: 'da tarde',
        evening: 'da tarde',
        night: 'da noite',
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'meia-noite',
        noon: 'meio-dia',
        morning: 'da manhã',
        afternoon: 'da tarde',
        evening: 'da tarde',
        night: 'da noite',
    },
};

// LOCALIZE
const ordinalNumber = (dirtyNumber, options) => {
    const number = Number(dirtyNumber);

    if (options?.unit === 'week') {
        return `${number}ª`;
    }
    return `${number}º`;
};

const localize = {
    ordinalNumber,

    era: buildLocalizeFn({
        values: eraValues,
        defaultWidth: 'wide',
    }),

    quarter: buildLocalizeFn({
        values: quarterValues,
        defaultWidth: 'wide',
        argumentCallback: (quarter) => quarter - 1,
    }),

    month: buildLocalizeFn({
        values: monthValues,
        defaultWidth: 'wide',
    }),

    day: buildLocalizeFn({
        values: dayValues,
        defaultWidth: 'wide',
    }),

    dayPeriod: buildLocalizeFn({
        values: dayPeriodValues,
        defaultWidth: 'wide',
        formattingValues: formattingDayPeriodValues,
        defaultFormattingWidth: 'wide',
    }),
};

const matchOrdinalNumberPattern = /^(\d+)[ºªo]?/i;
const parseOrdinalNumberPattern = /\d+/i;

const matchEraPatterns = {
    narrow: /^(ac|dc|a|d)/i,
    abbreviated: /^(a\.?\s?c\.?|d\.?\s?c\.?)/i,
    wide: /^(antes de cristo|depois de cristo)/i,
};
const parseEraPatterns = {
    any: [/^ac/i, /^dc/i] as const,
    wide: [/^antes de cristo/i, /^depois de cristo/i] as const,
};

const matchQuarterPatterns = {
    narrow: /^[1234]/i,
    abbreviated: /^T[1234]/i,
    wide: /^[1234](º)? trimestre/i,
};
const parseQuarterPatterns = {
    any: [/1/i, /2/i, /3/i, /4/i] as const,
};

const matchMonthPatterns = {
    narrow: /^[jfmajsond]/i,
    abbreviated: /^(jan|fev|mar|abr|mai|jun|jul|ago|set|out|nov|dez)/i,
    wide: /^(janeiro|fevereiro|março|abril|maio|junho|julho|agosto|setembro|outubro|novembro|dezembro)/i,
};
const parseMonthPatterns = {
    narrow: [
        /^j/i,
        /^f/i,
        /^m/i,
        /^a/i,
        /^m/i,
        /^j/i,
        /^j/i,
        /^a/i,
        /^s/i,
        /^o/i,
        /^n/i,
        /^d/i,
    ] as const,
    any: [
        /^ja/i,
        /^fev/i,
        /^mar/i,
        /^abr/i,
        /^mai/i,
        /^jun/i,
        /^jul/i,
        /^ago/i,
        /^set/i,
        /^out/i,
        /^nov/i,
        /^dez/i,
    ] as const,
};

const matchDayPatterns = {
    narrow: /^(dom|[23456]ª?|s[aá]b)/i,
    short: /^(dom|[23456]ª?|s[aá]b)/i,
    abbreviated: /^(dom|seg|ter|qua|qui|sex|s[aá]b)/i,
    wide: /^(domingo|(segunda|ter[cç]a|quarta|quinta|sexta)([- ]feira)?|s[aá]bado)/i,
};
const parseDayPatterns = {
    short: [/^d/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^s[aá]/i] as const,
    narrow: [/^d/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^s[aá]/i] as const,
    any: [/^d/i, /^seg/i, /^t/i, /^qua/i, /^qui/i, /^sex/i, /^s[aá]b/i] as const,
};

const matchDayPeriodPatterns = {
    narrow: /^(a|p|mn|md|(da) (manhã|tarde|noite))/i,
    any: /^([ap]\.?\s?m\.?|meia[-\s]noite|meio[-\s]dia|(da) (manhã|tarde|noite))/i,
};
const parseDayPeriodPatterns = {
    any: {
        am: /^a/i,
        pm: /^p/i,
        midnight: /^mn|^meia[-\s]noite/i,
        noon: /^md|^meio[-\s]dia/i,
        morning: /manhã/i,
        afternoon: /tarde/i,
        evening: /tarde/i,
        night: /noite/i,
    },
};

//   MATCH
const match = {
    ordinalNumber: buildMatchPatternFn({
        matchPattern: matchOrdinalNumberPattern,
        parsePattern: parseOrdinalNumberPattern,
        valueCallback: (value) => parseInt(value, 10),
    }),

    era: buildMatchFn({
        matchPatterns: matchEraPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseEraPatterns,
        defaultParseWidth: 'any',
    }),

    quarter: buildMatchFn({
        matchPatterns: matchQuarterPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseQuarterPatterns,
        defaultParseWidth: 'any',
        valueCallback: (index) => index + 1,
    }),

    month: buildMatchFn({
        matchPatterns: matchMonthPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseMonthPatterns,
        defaultParseWidth: 'any',
    }),

    day: buildMatchFn({
        matchPatterns: matchDayPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseDayPatterns,
        defaultParseWidth: 'any',
    }),

    dayPeriod: buildMatchFn({
        matchPatterns: matchDayPeriodPatterns,
        defaultMatchWidth: 'any',
        parsePatterns: parseDayPeriodPatterns,
        defaultParseWidth: 'any',
    }),
};

// LOCALE EXPORT
const ptBRLocale = {
    code: 'pt-BR',
    formatDistance: formatDistance,
    formatLong: formatLong,
    formatRelative: formatRelative,
    localize: localize,
    match: match,
    options: {
        weekStartsOn: 0 /* Sunday */,
        firstWeekContainsDate: 1,
    },
};

export default ptBRLocale;
