import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import {
    Provider,
    ProviderContractDocument,
    ProviderProposalDocument,
} from 'clients/manager/interfaces/provider.interface';
import { useTranslation } from 'react-i18next';
import { AiFillEyeInvisible, AiOutlineFile } from 'react-icons/ai';
import { Skeleton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { providerRequests } from 'clients/manager/provider.requests';
import { formatDate } from 'common/utils';
import { CleanContainer } from '../../../styled';
import {
    DocumentsContainer,
    LotDialogInformationContainer,
    StyledLotDialog,
    StyledLotInfoAnonymizedComponent,
    useStyles,
} from './styled';

interface LotDialogProps {
    open?: boolean;
    onClose?: () => void;
    processId: number;
    providerId: number;
}

const LotInfoAnonymizedComponent = () => (
    <StyledLotInfoAnonymizedComponent>
        <Skeleton width={100} height={30} />
        <AiFillEyeInvisible color='var(--text-light-color)' />
    </StyledLotInfoAnonymizedComponent>
);

const LotDialog = ({ open = false, onClose = () => {}, processId, providerId }: LotDialogProps) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        contract: {} as ProviderContractDocument,
        documents: [] as ProviderProposalDocument[],
        provider: {} as Provider,
    });

    const { t } = useTranslation();
    const styles = useStyles();

    const handleOpenDocument = (filename?: string) => {
        if (filename) {
            window.open(filename, '_blank');
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const dataContract = providerRequests.getContractByAuctionAndProvider(
                    processId,
                    providerId
                );
                const dataListProposalDocuments = providerRequests.listProviderProposalDocuments(
                    processId,
                    providerId
                );
                const dataProvider = providerRequests.getProviderById(providerId);

                const [{ data: contract }, { data: documents }, provider] = await Promise.all([
                    dataContract,
                    dataListProposalDocuments,
                    dataProvider,
                ]);

                setData({
                    provider,
                    contract: contract as any,
                    documents,
                });
            } catch {
                onClose();
            } finally {
                setLoading(false);
            }
        }

        if (open) {
            fetchData();
        }
    }, [open]);

    const contract = data?.contract;
    const provider = data?.provider;
    const documents = data?.documents ?? [];

    return (
        <StyledLotDialog maxWidth='md' {...{ open, onClose }}>
            <DialogTitle>{t('bidding.process.lot.provider.dialog.title')}</DialogTitle>
            {loading && (
                <CleanContainer height='40vh'>
                    <CircularProgress />
                </CleanContainer>
            )}
            {!loading && (
                <>
                    <DialogContent>
                        <Typography variant='h5'>
                            <strong>{t('bidding.process.lot.provider.dialog.general-data')}</strong>
                        </Typography>
                        <br />
                        <Grid container direction='row' wrap='wrap'>
                            <LotDialogInformationContainer
                                container
                                item
                                direction='column'
                                xs={12}
                                md={6}
                            >
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {provider?.docType === 'F' ? 'CPF' : 'CNPJ'}:
                                        </strong>
                                    </Typography>
                                    {provider?.docType === 'F' ? (
                                        <LotInfoAnonymizedComponent />
                                    ) : (
                                        <Typography>{provider?.docNumber}</Typography>
                                    )}
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {provider?.docType === 'F'
                                                ? t(
                                                      'bidding.process.lot.provider.dialog.provider-name'
                                                  )
                                                : t(
                                                      'bidding.process.lot.provider.dialog.company-name'
                                                  )}
                                            :
                                        </strong>
                                    </Typography>
                                    <Typography>{provider?.companyName}</Typography>
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {provider?.docType === 'F'
                                                ? t(
                                                      'bidding.process.lot.provider.dialog.provider-professional-name'
                                                  )
                                                : t(
                                                      'bidding.process.lot.provider.dialog.trading-name'
                                                  )}
                                            :
                                        </strong>
                                    </Typography>
                                    <Typography>{provider?.tradingName}</Typography>
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>ME/EPP/COOP:</strong>
                                    </Typography>
                                    <Typography>
                                        {provider?.itsSimpleCompany === 1 ? 'Sim' : 'Não'}
                                    </Typography>
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>Email:</strong>
                                    </Typography>
                                    {provider?.docType === 'F' ? (
                                        <LotInfoAnonymizedComponent />
                                    ) : (
                                        <Typography>{provider?.emailForNotifications}</Typography>
                                    )}
                                </Grid>
                            </LotDialogInformationContainer>
                            <LotDialogInformationContainer
                                container
                                item
                                direction='column'
                                xs={12}
                                md={6}
                            >
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {t('bidding.process.lot.provider.dialog.postal-code')}:
                                        </strong>
                                    </Typography>
                                    {provider?.docType === 'F' ? (
                                        <LotInfoAnonymizedComponent />
                                    ) : (
                                        <Typography>{provider?.zipCode}</Typography>
                                    )}
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {t('bidding.process.lot.provider.dialog.address')}:
                                        </strong>
                                    </Typography>
                                    {provider?.docType === 'F' ? (
                                        <LotInfoAnonymizedComponent />
                                    ) : (
                                        <Typography>
                                            {provider?.publicPlace}, {provider?.numberPlace}{' '}
                                            {provider?.complementPlace &&
                                                `- ${provider.complementPlace}`}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {t('bidding.process.lot.provider.dialog.neighborhood')}:
                                        </strong>
                                    </Typography>
                                    {provider?.docType === 'F' ? (
                                        <LotInfoAnonymizedComponent />
                                    ) : (
                                        <Typography>{provider?.district}</Typography>
                                    )}
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {t('bidding.process.lot.provider.dialog.city')}:
                                        </strong>
                                    </Typography>
                                    <Typography>
                                        {provider?.cityName} / {provider?.state}
                                    </Typography>
                                </Grid>
                                <Grid container item alignItems='center'>
                                    <Typography>
                                        <strong>
                                            {t('bidding.process.lot.provider.dialog.phone')}:
                                        </strong>
                                    </Typography>
                                    {provider?.docType === 'F' ? (
                                        <LotInfoAnonymizedComponent />
                                    ) : (
                                        <Typography>{provider?.phoneNumber}</Typography>
                                    )}
                                </Grid>
                            </LotDialogInformationContainer>
                        </Grid>
                        {contract?.generatedDocument?.file?.location && (
                            <>
                                <br />
                                <Typography variant='h5'>
                                    <strong>{t('term.contract')}</strong>
                                </Typography>
                                <br />
                                <DocumentsContainer variant='outlined'>
                                    <List disablePadding>
                                        <ListItem
                                            key={contract.contract.id}
                                            onClick={() =>
                                                handleOpenDocument(
                                                    contract?.documentSigners?.signDocLicitarUrl ||
                                                        contract?.generatedDocument?.file?.location
                                                )
                                            }
                                            button
                                        >
                                            <ListItemIcon>
                                                <AiOutlineFile size={24} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={`${t('term.contract')} Nº ${
                                                    contract?.contract?.contractNumber
                                                }`}
                                            />
                                        </ListItem>
                                    </List>
                                </DocumentsContainer>
                            </>
                        )}
                        <br />
                        <Typography variant='h5'>
                            <strong>{t('bidding.process.lot.provider.dialog.documents')}</strong>
                        </Typography>
                        <br />
                        <DocumentsContainer variant='outlined'>
                            <List disablePadding>
                                {documents.map((document, index) => (
                                    <ListItem
                                        key={document.id}
                                        divider={documents.length > index + 1}
                                        onClick={() => handleOpenDocument(document.filename)}
                                        disabled={!!document.isDocumentProtected}
                                        button
                                    >
                                        <ListItemIcon>
                                            <AiOutlineFile size={24} />
                                        </ListItemIcon>
                                        <ListItemText
                                            // style={{ display: 'flex', flexGrow: 1 }}
                                            primary={`${document.fileDescription}${
                                                document.isDocumentProtected
                                                    ? ` (${t('term.protected')})`
                                                    : ''
                                            }`}
                                        />
                                        {document.dateTimeInsert && (
                                            <ListItem className={styles.listItemDateInsert}>
                                                <Typography variant='caption'>
                                                    {formatDate(
                                                        document.dateTimeInsert,
                                                        'DD/MM/YY HH:mm'
                                                    )}
                                                </Typography>
                                            </ListItem>
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        </DocumentsContainer>
                    </DialogContent>
                    <DialogActions style={{ padding: 24 }}>
                        <Button
                            onClick={onClose}
                            color='secondary'
                            size='large'
                            variant='contained'
                        >
                            {t('bidding.process.lot.provider.dialog.close')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </StyledLotDialog>
    );
};

export default LotDialog;
