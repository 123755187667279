import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    chipStatusStarted: {
        backgroundColor: theme.palette.success.main,
        fontWeight: 600,
    },
    chipStatusFrustrated: {
        backgroundColor: theme.palette.error.main,
        fontWeight: 600,
    },
    chipStatusFinished: {
        backgroundColor: theme.palette.grey[600],
        fontWeight: 600,
    },
    chipStatusPending: {
        backgroundColor: theme.palette.grey[500],
        fontWeight: 600,
    },
}));
