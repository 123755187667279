interface Profession {
    id: number;
    description: string;
}

const professionConstants: Profession[] = [
    {
        id: 1110,
        description: 'Químico',
    },
    {
        id: 1210,
        description: 'Físico',
    },
    {
        id: 1930,
        description: 'Meteorologista',
    },
    {
        id: 2020,
        description: 'Engenheiro Agrônomo',
    },
    {
        id: 2040,
        description: 'Engenheiro Florestal',
    },
    {
        id: 2110,
        description: 'Engenheiro Civil',
    },
    {
        id: 2175,
        description: 'Arquiteto',
    },
    {
        id: 2270,
        description: 'Desenhista Industrial(designer)',
    },
    {
        id: 2305,
        description: 'Engenheiro Eletricista',
    },
    {
        id: 2310,
        description: 'Engenheiro Eletrônico',
    },
    {
        id: 2340,
        description: 'Engenheiro Telecomunicações',
    },
    {
        id: 2410,
        description: 'Engenheiro Mecânico',
    },
    {
        id: 2510,
        description: 'Engenheiro Químico',
    },
    {
        id: 2630,
        description: 'Engenheiro Metalúrgico',
    },
    {
        id: 2710,
        description: 'Engenheiro de Minas',
    },
    {
        id: 2840,
        description: 'Engenheiro Segurança Trabalho',
    },
    {
        id: 2935,
        description: 'Engenheiro Agrimensor',
    },
    {
        id: 2950,
        description: 'Engenheiro de tráfego',
    },
    {
        id: 3020,
        description: 'Técnico Contabilidade',
    },
    {
        id: 3050,
        description: 'Técnico Administração',
    },
    {
        id: 3110,
        description: 'Técnico Agropecuário',
    },
    {
        id: 3120,
        description: 'Técnico Agrícola',
    },
    {
        id: 3160,
        description: 'Técnico de Piscicultura',
    },
    {
        id: 3205,
        description: 'Técnico Mineração',
    },
    {
        id: 3210,
        description: 'Técnico Metalúrgico',
    },
    {
        id: 3230,
        description: 'Técnico Geologia',
    },
    {
        id: 3315,
        description: 'Técnico Obras Civis',
    },
    {
        id: 3330,
        description: 'Técnico Agrimensura',
    },
    {
        id: 3350,
        description: 'Técnico Hidrografia',
    },
    {
        id: 3360,
        description: 'Técnico Estradas',
    },
    {
        id: 3380,
        description: 'Topógrafo',
    },
    {
        id: 3405,
        description: 'Eletrotécnico',
    },
    {
        id: 3410,
        description: 'Técnico Eletrônico',
    },
    {
        id: 3430,
        description: 'Técnico Telecomunicações',
    },
    {
        id: 3435,
        description: 'Técnico de manutenção mecânica',
    },
    {
        id: 3450,
        description: 'Técnico Telefonia',
    },
    {
        id: 3510,
        description: 'Técnico Mecânico',
    },
    {
        id: 3530,
        description: 'Técnico Mecânico (aeronaves)',
    },
    {
        id: 3540,
        description: 'Téc Mecânico (veículos)',
    },
    {
        id: 3550,
        description: 'Técnico mecânico(refrigeração)',
    },
    {
        id: 3575,
        description: 'Técnico mecânico (máquinas)',
    },
    {
        id: 3605,
        description: 'Técnico Químico',
    },
    {
        id: 3630,
        description: 'Técnico Laboratório An.Fis/Qui',
    },
    {
        id: 3640,
        description: 'Laboratorista Industrial',
    },
    {
        id: 3805,
        description: 'Desenhista Técnico',
    },
    {
        id: 3810,
        description: 'Desenhista Técnico Industrial',
    },
    {
        id: 3870,
        description: 'Desenhista Técnico (Artes Gr.)',
    },
    {
        id: 3885,
        description: 'Desenhista detalhista',
    },
    {
        id: 3887,
        description: 'Desenhista copista',
    },
    {
        id: 3945,
        description: 'Técnico Segurança Trabalho',
    },
    {
        id: 3950,
        description: 'Técnico Meteorologia',
    },
    {
        id: 3982,
        description: 'Técnico Microfilmagem',
    },
    {
        id: 3984,
        description: 'Técnico Programação Visual',
    },
    {
        id: 3987,
        description: 'Inspetor de Riscos',
    },
    {
        id: 3988,
        description: 'Técnico Eletromecânico',
    },
    {
        id: 4120,
        description: 'Piloto comercial (linh.aéreas)',
    },
    {
        id: 4130,
        description: 'Piloto comercial (exceto LA)',
    },
    {
        id: 4135,
        description: 'Piloto Helicóptero',
    },
    {
        id: 5110,
        description: 'Biologista',
    },
    {
        id: 5120,
        description: 'Botânico',
    },
    {
        id: 5230,
        description: 'Bioquímico',
    },
    {
        id: 6105,
        description: 'Médico',
    },
    {
        id: 6122,
        description: 'Médico do Trabalho',
    },
    {
        id: 6810,
        description: 'Nutricionista',
    },
    {
        id: 7140,
        description: 'Enfermeiro do Trabalho',
    },
    {
        id: 7215,
        description: 'Técnico Enfermagem Trabalho',
    },
    {
        id: 7310,
        description: 'Assistente Social',
    },
    {
        id: 7410,
        description: 'Psicólogo',
    },
    {
        id: 7620,
        description: 'Fisioterapeuta',
    },
    {
        id: 7630,
        description: 'Terapeuta Ocupacional',
    },
    {
        id: 8110,
        description: 'Estatístico',
    },
    {
        id: 8220,
        description: 'Matemático',
    },
    {
        id: 8250,
        description: 'Atuário',
    },
    {
        id: 8320,
        description: 'Analista de Sistemas',
    },
    {
        id: 8330,
        description: 'Analista de suporte sistemas',
    },
    {
        id: 8420,
        description: 'Programador de computador',
    },
    {
        id: 8425,
        description: 'Técnico Teleprocessamento',
    },
    {
        id: 9110,
        description: 'Economista',
    },
    {
        id: 9220,
        description: 'Administrador',
    },
    {
        id: 9310,
        description: 'Contador',
    },
    {
        id: 9320,
        description: 'Auditor Contábil',
    },
    {
        id: 9910,
        description: 'Auditor geral',
    },
    {
        id: 12110,
        description: 'Advogado',
    },
    {
        id: 14220,
        description: 'Professor 1ª a 4ª séries',
    },
    {
        id: 14290,
        description: 'Outros Profess Ensino Fundam',
    },
    {
        id: 14440,
        description: 'Instrutor aprendiz. e treinam.',
    },
    {
        id: 14945,
        description: 'Pedagogo',
    },
    {
        id: 15210,
        description: 'Jornalista',
    },
    {
        id: 15947,
        description: 'Agente Publicitário',
    },
    {
        id: 15955,
        description: 'Relações Públicas',
    },
    {
        id: 15970,
        description: 'Técnico Comunicação',
    },
    {
        id: 18120,
        description: 'Prof Educação Física',
    },
    {
        id: 19120,
        description: 'Bibliotecário',
    },
    {
        id: 19130,
        description: 'Arquivologista',
    },
    {
        id: 19220,
        description: 'Sociólogo',
    },
    {
        id: 19225,
        description: 'Economista Doméstico',
    },
    {
        id: 19250,
        description: 'Geógrafo',
    },
    {
        id: 19530,
        description: 'Tradutor',
    },
    {
        id: 19540,
        description: 'Intérprete',
    },
    {
        id: 19735,
        description: 'Analista de Recursos Humanos',
    },
    {
        id: 19955,
        description: 'Analista Comercialização',
    },
    {
        id: 21491,
        description: 'Engenheiro Controle de Qualidade',
    },
    {
        id: 23320,
        description: 'Diretor empr.prod/dist.ene.ele',
    },
    {
        id: 23910,
        description: 'Diretor empresa',
    },
    {
        id: 24125,
        description: 'Gerente Executivo',
    },
    {
        id: 30110,
        description: 'Chefe de escritório em geral',
    },
    {
        id: 31125,
        description: 'Assistentes Administrativo',
    },
    {
        id: 31190,
        description: 'Outros agentes admin/ass.admin',
    },
    {
        id: 31390,
        description: 'Outros superiores de polícia',
    },
    {
        id: 32105,
        description: 'Secretário',
    },
    {
        id: 32110,
        description: 'Secretário Executivo',
    },
    {
        id: 32115,
        description: 'Secretário Bilíngue',
    },
    {
        id: 32340,
        description: 'Operador de teleimpressor',
    },
    {
        id: 32350,
        description: 'Operador Rede Teleprocessamto',
    },
    {
        id: 34220,
        description: 'Operador Computador',
    },
    {
        id: 34240,
        description: 'Digitador',
    },
    {
        id: 34320,
        description: 'Operador equip.entrada dados',
    },
    {
        id: 38020,
        description: 'Telefonista',
    },
    {
        id: 39115,
        description: 'Almoxarife',
    },
    {
        id: 39310,
        description: 'Auxiliar Escritório',
    },
    {
        id: 39330,
        description: 'Auxiliar de pessoal',
    },
    {
        id: 39530,
        description: 'Arquivista',
    },
    {
        id: 39540,
        description: 'Fitotecário',
    },
    {
        id: 39935,
        description: 'Leiturista',
    },
    {
        id: 39950,
        description: 'Operador de maquina operadora',
    },
    {
        id: 39975,
        description: 'Auxiliar de almoxarifado',
    },
    {
        id: 55120,
        description: 'Zelador de edifício',
    },
    {
        id: 57215,
        description: 'Aux Engermagem do Trabalho',
    },
    {
        id: 58320,
        description: 'Guarda Segurança',
    },
    {
        id: 66420,
        description: 'Trabalhador da criação peixes',
    },
    {
        id: 70183,
        description: 'Mestre (Construção civil)',
    },
    {
        id: 79630,
        description: 'Estofador de veículos',
    },
    {
        id: 81110,
        description: 'Marceneiro em geral',
    },
    {
        id: 83110,
        description: 'Forjador em geral',
    },
    {
        id: 83320,
        description: 'Torneiro Mecânico',
    },
    {
        id: 83915,
        description: 'Serralheiro',
    },
    {
        id: 84010,
        description: 'Ajustador Mecânico',
    },
    {
        id: 84170,
        description: 'Montador de máquina escritorio',
    },
    {
        id: 84320,
        description: 'Mecânico Manutenção Veículos',
    },
    {
        id: 84410,
        description: 'Mecânico manutenção Aeronaves',
    },
    {
        id: 84510,
        description: 'Mecânico Manutenção Máquinas',
    },
    {
        id: 84565,
        description: 'Mecânico manut.máquinas escrit',
    },
    {
        id: 84590,
        description: 'Outros mecanicos manut.máquina',
    },
    {
        id: 85110,
        description: 'Montador equipamentos elétr',
    },
    {
        id: 85140,
        description: 'Montador equip.elétr(cent.elé)',
    },
    {
        id: 85405,
        description: 'Eletricista de Manutenção',
    },
    {
        id: 85510,
        description: 'Eletricista de Instalações',
    },
    {
        id: 85520,
        description: 'Eletricista instalações(edif.)',
    },
    {
        id: 85540,
        description: 'Eletr.instalações(exc.aeronave',
    },
    {
        id: 85720,
        description: 'Inst.linhas eletr.alta/bxa.ten',
    },
    {
        id: 86145,
        description: 'Operador de vídeo',
    },
    {
        id: 87105,
        description: 'Encanador',
    },
    {
        id: 87120,
        description: 'Instalador tubul.gas combustiv',
    },
    {
        id: 87210,
        description: 'Soldador',
    },
    {
        id: 87370,
        description: 'Chapeador carroceria metálica',
    },
    {
        id: 90240,
        description: 'Borracheiro',
    },
    {
        id: 92240,
        description: 'Impressor de off-set',
    },
    {
        id: 92520,
        description: 'Fotógrafo (fotogravura)',
    },
    {
        id: 92620,
        description: 'Encadernador',
    },
    {
        id: 93120,
        description: 'Pintor de Obras',
    },
    {
        id: 93960,
        description: 'Pintor de Veículos',
    },
    {
        id: 95110,
        description: 'Pedreiro',
    },
    {
        id: 95210,
        description: 'Armador estrutura concreto',
    },
    {
        id: 95410,
        description: 'Carpinteiro',
    },
    {
        id: 95710,
        description: 'Vidraceiro',
    },
    {
        id: 95932,
        description: 'Servente de Obras',
    },
    {
        id: 96120,
        description: 'Operador Central Termoelétrica',
    },
    {
        id: 96130,
        description: 'Operador Central Hidrelétrica',
    },
    {
        id: 96180,
        description: 'Operador de Subestação',
    },
    {
        id: 97110,
        description: 'Trab.movim.carga/desc.mercador',
    },
    {
        id: 97130,
        description: 'Carregador(veículo transp.terr',
    },
    {
        id: 98510,
        description: 'Motorista',
    },
    {
        id: 98915,
        description: 'Operador de máquinas/veículos',
    },
    {
        id: 99910,
        description: 'Pessoa que procura prim.empreg',
    },
    {
        id: 114205,
        description: 'Dirig Entidades de Trabalhador',
    },
    {
        id: 114210,
        description: 'Dirigentes de Entidades Patron',
    },
    {
        id: 121005,
        description: 'Diretor de planejamento estratégico',
    },
    {
        id: 121010,
        description: 'Dir Geral Empresa e Organiz',
    },
    {
        id: 122205,
        description:
            'Diretor de produção e operações da indústria de transformação, extração mineral e utilidades',
    },
    {
        id: 122615,
        description: 'Diretor de operações de serviços de telecomunicações',
    },
    {
        id: 123105,
        description: 'Diretor Administrativo',
    },
    {
        id: 123110,
        description: 'Diretor Administr Financeiro',
    },
    {
        id: 123115,
        description: 'Diretor Financeiro',
    },
    {
        id: 123205,
        description: 'Diretor de Recursos Humanos',
    },
    {
        id: 123210,
        description: 'Diretor de Relações Trabalho',
    },
    {
        id: 123305,
        description: 'Diretor Comercial',
    },
    {
        id: 123310,
        description: 'Diretor Marketing',
    },
    {
        id: 123405,
        description: 'Diretor de Suprimentos',
    },
    {
        id: 123605,
        description: 'Diretor Serviços Informática',
    },
    {
        id: 123705,
        description: 'Diretor Pesquisa e Desenvolv',
    },
    {
        id: 123805,
        description: 'Diretor de Manutenção',
    },
    {
        id: 141205,
        description: 'Gerente Produção Operações',
    },
    {
        id: 141305,
        description: 'Gerente administrativo',
    },
    {
        id: 141605,
        description: 'Gerente de operações de transportes',
    },
    {
        id: 141615,
        description: 'Gerente de logística',
    },
    {
        id: 142105,
        description: 'Gerente Administrativo',
    },
    {
        id: 142110,
        description: 'Gerente de Riscos',
    },
    {
        id: 142115,
        description: 'Gerente Financeiro',
    },
    {
        id: 142205,
        description: 'Gerente Recursos Humanos',
    },
    {
        id: 142210,
        description: 'Gerente Depart de Pessoal',
    },
    {
        id: 142305,
        description: 'Gerente comercial',
    },
    {
        id: 142310,
        description: 'Gerente de comunicação',
    },
    {
        id: 142315,
        description: 'Gerente de Marketing',
    },
    {
        id: 142320,
        description: 'Gerente de Vendas',
    },
    {
        id: 142325,
        description: 'Relações Públicas',
    },
    {
        id: 142330,
        description: 'Analista Negócios',
    },
    {
        id: 142335,
        description: 'Analista Pesquisa de Mercado',
    },
    {
        id: 142340,
        description: 'Ouvidor',
    },
    {
        id: 142405,
        description: 'Gerente de Compras',
    },
    {
        id: 142410,
        description: 'Gerente de Suprimentos',
    },
    {
        id: 142415,
        description: 'Gerente de Almoxarifado',
    },
    {
        id: 142505,
        description: 'Gerente de Rede',
    },
    {
        id: 142510,
        description: 'Gerente Desenvolv Sistemas',
    },
    {
        id: 142515,
        description: 'Ger Produção Tecnol. Informac',
    },
    {
        id: 142520,
        description: 'Ger Projeto Tecnol da Informac',
    },
    {
        id: 142525,
        description: 'Ger Segur Tecnol Informação',
    },
    {
        id: 142530,
        description: 'Ger Sup Técnico Tecnol Inform',
    },
    {
        id: 142535,
        description: 'Tecnólogo em Gestão da Tecnologia da Informação',
    },
    {
        id: 142605,
        description: 'Ger Pesquisa Desenvolvimento',
    },
    {
        id: 142705,
        description: 'Ger Projetos e Serviços Manut',
    },
    {
        id: 201215,
        description: 'Especial Ensaios Metrológicos',
    },
    {
        id: 202105,
        description: 'Engenheiro Mecatrônico',
    },
    {
        id: 202110,
        description: 'Eng Controle e Automação',
    },
    {
        id: 203005,
        description: 'Pesquis Biologia Ambiental',
    },
    {
        id: 203010,
        description: 'Pesquisador Biologia Animal',
    },
    {
        id: 203015,
        description: 'Pesquisador Biologia Vegetal',
    },
    {
        id: 203105,
        description: 'Pesq Ciência Comput e Inform',
    },
    {
        id: 203110,
        description: 'Pesq Ciência Terra Meio Amb',
    },
    {
        id: 203125,
        description: 'Pesquisador em Química',
    },
    {
        id: 203205,
        description: 'Pesquisador Engenharia Civil',
    },
    {
        id: 203210,
        description: 'Pesquis Eng e Tecnologia',
    },
    {
        id: 203215,
        description: 'Pesquis Eng Elétrica Eletrôn',
    },
    {
        id: 203220,
        description: 'Pesquis Engenharia Mecânica',
    },
    {
        id: 203225,
        description: 'Pesq Eng Metalur Minas e Mat',
    },
    {
        id: 203230,
        description: 'Pesquis Engenharia Química',
    },
    {
        id: 203320,
        description: 'Pesquisador Saúde Coletiva',
    },
    {
        id: 203410,
        description: 'Pesq Ciência Pesca e Aquicult',
    },
    {
        id: 203415,
        description: 'Pesquis Ciência da Zootecnia',
    },
    {
        id: 203420,
        description: 'Pesquisador Ciência Florestal',
    },
    {
        id: 203510,
        description: 'Pesquisador Economia',
    },
    {
        id: 211115,
        description: 'Matemático',
    },
    {
        id: 211120,
        description: 'Matemático aplicado',
    },
    {
        id: 211205,
        description: 'Estatístico',
    },
    {
        id: 211505,
        description: 'Atuário',
    },
    {
        id: 212205,
        description: 'Eng Aplicat em Computação',
    },
    {
        id: 212210,
        description: 'Eng Equipamentos Comput',
    },
    {
        id: 212215,
        description: 'Eng Sist Operac em Comput',
    },
    {
        id: 212305,
        description: 'Administrador Banco de Dados',
    },
    {
        id: 212310,
        description: 'Administrador de Redes',
    },
    {
        id: 212315,
        description: 'Administ Sistema Operacional',
    },
    {
        id: 212405,
        description: 'Analista Desenvolv Sistemas',
    },
    {
        id: 212410,
        description: 'Anal Redes e Comunic Dados',
    },
    {
        id: 212415,
        description: 'Analista Sistema Automação',
    },
    {
        id: 212420,
        description: 'Anal Suporte Computacional',
    },
    {
        id: 213205,
        description: 'Químico',
    },
    {
        id: 213210,
        description: 'Químico Industrial',
    },
    {
        id: 213315,
        description: 'Metereologista',
    },
    {
        id: 213405,
        description: 'Geólogo',
    },
    {
        id: 213410,
        description: 'Geólogo Engenharia',
    },
    {
        id: 213425,
        description: 'Hidrogeólogo',
    },
    {
        id: 214005,
        description: 'Engenheiro Ambiental',
    },
    {
        id: 214105,
        description: 'Arquiteto Edificações',
    },
    {
        id: 214115,
        description: 'Arquiteto Patrimônio',
    },
    {
        id: 214125,
        description: 'Arquiteto Urbanista',
    },
    {
        id: 214205,
        description: 'Engenheiro Civil',
    },
    {
        id: 214215,
        description: 'Engenheiro Civil (edificações)',
    },
    {
        id: 214220,
        description: 'Eng Civil (estrut metálica)',
    },
    {
        id: 214230,
        description: 'Engenheiro Civil (geotécnica)',
    },
    {
        id: 214235,
        description: 'Engenheiro Civil (hidrologia)',
    },
    {
        id: 214240,
        description: 'Engenharia Civil (hidráulica)',
    },
    {
        id: 214305,
        description: 'Engenheiro Eletricista',
    },
    {
        id: 214310,
        description: 'Engenheiro Eletrônico',
    },
    {
        id: 214315,
        description: 'Eng Eletricista de Manutenção',
    },
    {
        id: 214320,
        description: 'Eng Eletricista de Projetos',
    },
    {
        id: 214325,
        description: 'Eng Eletrônico de Manutenção',
    },
    {
        id: 214330,
        description: 'Eng Eletrônico de Projetos',
    },
    {
        id: 214335,
        description: 'Eng Manut Telecomunicações',
    },
    {
        id: 214340,
        description: 'Engenheiro Telecomunicações',
    },
    {
        id: 214345,
        description: 'Eng Projetista Telecomunic',
    },
    {
        id: 214350,
        description: 'Eng Redes de Comunicação',
    },
    {
        id: 214355,
        description: 'Eng Controle e Automação',
    },
    {
        id: 214405,
        description: 'Engenheiro Mecânico',
    },
    {
        id: 214410,
        description: 'Eng Mecânico Automotivo',
    },
    {
        id: 214505,
        description: 'Engenheiro Químico',
    },
    {
        id: 214530,
        description: 'Eng Químico (utilid meio amb)',
    },
    {
        id: 214610,
        description: 'Engenheiro Metalúrgico',
    },
    {
        id: 214705,
        description: 'Consultor técnico - na extração mineral',
    },
    {
        id: 214805,
        description: 'Engenheiro Agrimensor',
    },
    {
        id: 214810,
        description: 'Engenheiro Cartógrafo',
    },
    {
        id: 214905,
        description: 'Engenheiro de Produção',
    },
    {
        id: 214910,
        description: 'Engenheiro Qualidade',
    },
    {
        id: 214915,
        description: 'Eng Segurança do Trabalho',
    },
    {
        id: 214920,
        description: 'Engenheiro Riscos',
    },
    {
        id: 215305,
        description: 'Piloto de aeronaves',
    },
    {
        id: 221105,
        description: 'Biólogo',
    },
    {
        id: 222110,
        description: 'Engenheiro Agrônomo',
    },
    {
        id: 222115,
        description: 'Engenheiro Pesca',
    },
    {
        id: 222120,
        description: 'Engenheiro Florestal',
    },
    {
        id: 223310,
        description: 'Zootecnista',
    },
    {
        id: 223530,
        description: 'Enfermeiro do Trabalho',
    },
    {
        id: 223710,
        description: 'Nutricionista',
    },
    {
        id: 224120,
        description: 'PREPARADOR FÍSICO',
    },
    {
        id: 225140,
        description: 'Médico do Trabalho',
    },
    {
        id: 233205,
        description: 'Instrutor de aprendizagem e treinamento agropecuário (formação profissional)',
    },
    {
        id: 233210,
        description: 'Instrutor Aprend Treinam Indus',
    },
    {
        id: 233215,
        description: 'Instrutor de aprendizagem e treinamento comercial (formação profissional)',
    },
    {
        id: 239405,
        description: 'Coordenador Pedagógico',
    },
    {
        id: 239415,
        description: 'Pedagogo',
    },
    {
        id: 241005,
        description: 'Advogado',
    },
    {
        id: 241010,
        description: 'Advogado de Empresa',
    },
    {
        id: 241015,
        description: 'Advogado (direito civil)',
    },
    {
        id: 241020,
        description: 'Advogado (direito público)',
    },
    {
        id: 241025,
        description: 'Advogado (direito penal)',
    },
    {
        id: 241030,
        description: 'Advogado (áreas especiais)',
    },
    {
        id: 241035,
        description: 'Advogado (direito do trabalho)',
    },
    {
        id: 241040,
        description: 'Consultor Jurídico',
    },
    {
        id: 251120,
        description: 'Sociólogo',
    },
    {
        id: 251205,
        description: 'Economista',
    },
    {
        id: 251215,
        description: 'Economista Financeiro',
    },
    {
        id: 251220,
        description: 'Economista Industrial',
    },
    {
        id: 251225,
        description: 'Economista Setor Público',
    },
    {
        id: 251230,
        description: 'Economista Ambiental',
    },
    {
        id: 251305,
        description: 'Geógrafo',
    },
    {
        id: 251535,
        description: 'Psicólogo do Trabalho',
    },
    {
        id: 251540,
        description: 'Psicólogo do Trabalho',
    },
    {
        id: 251605,
        description: 'Assistente Social',
    },
    {
        id: 251610,
        description: 'Economista Doméstico',
    },
    {
        id: 252105,
        description: 'Administrador',
    },
    {
        id: 252205,
        description: 'Auditor (contadores e afins)',
    },
    {
        id: 252210,
        description: 'Contador',
    },
    {
        id: 252405,
        description: 'Analista Recursos Humanos',
    },
    {
        id: 252545,
        description: 'Analista financeiro (instituições financeiras)',
    },
    {
        id: 252605,
        description: 'Gestor em segurança',
    },
    {
        id: 253105,
        description: 'Relações Públicas',
    },
    {
        id: 253120,
        description: 'Analista Negócios',
    },
    {
        id: 253125,
        description: 'Analista Pesquisa de Mercado',
    },
    {
        id: 261110,
        description: 'Assessor de  Imprensa',
    },
    {
        id: 261125,
        description: 'Jornalista',
    },
    {
        id: 261205,
        description: 'Bibliotecário',
    },
    {
        id: 261210,
        description: 'Documentalista',
    },
    {
        id: 261215,
        description: 'Anal Informac (pesq inform red',
    },
    {
        id: 261305,
        description: 'Arquivista',
    },
    {
        id: 261420,
        description: 'Tradutor',
    },
    {
        id: 262410,
        description: 'Desenhista Industrial',
    },
    {
        id: 300105,
        description: 'Téc Mecatrôn (autom manuf)',
    },
    {
        id: 300305,
        description: 'Técnico em Eletromecânica',
    },
    {
        id: 301110,
        description: 'Téc Lab Anál Fís/Quím mat cons',
    },
    {
        id: 311105,
        description: 'Técnico Químico',
    },
    {
        id: 311505,
        description: 'Téc Controle Meio Ambiente',
    },
    {
        id: 311510,
        description: 'Técnico Meteorologia',
    },
    {
        id: 311515,
        description: 'Téc Utilidade (prod distrib gá',
    },
    {
        id: 311520,
        description: 'Técnico Tratamento de Efluente',
    },
    {
        id: 312105,
        description: 'Técnico Obras Civis',
    },
    {
        id: 312305,
        description: 'Técnico em Agrimensura',
    },
    {
        id: 312310,
        description: 'Técnico Geodésia e Cartografia',
    },
    {
        id: 312315,
        description: 'Técnico em Hidrografia',
    },
    {
        id: 312320,
        description: 'Topógrafo',
    },
    {
        id: 313105,
        description:
            'Eletrotécnico (aux.eletrotecnico, tecnico ensaios elétricos, téc. oper. eletrotécnica',
    },
    {
        id: 313110,
        description: 'Eletrotécnico (prod energia)',
    },
    {
        id: 313115,
        description: 'Eletrot (teste,inspeção, ensai',
    },
    {
        id: 313120,
        description: 'Técnico Manutenção Elétrica',
    },
    {
        id: 313130,
        description: 'Téc Eletric (n lig prod energi',
    },
    {
        id: 313205,
        description: 'Técnico Manutenção Eletrônica',
    },
    {
        id: 313215,
        description: 'Técnico Eletrônico (em geral)',
    },
    {
        id: 313305,
        description: 'Téc Comunic Dados (teleproc)',
    },
    {
        id: 313310,
        description: 'Técnico de Redes (telecomun)',
    },
    {
        id: 313315,
        description: 'Técnico Telecomun (telefonia)',
    },
    {
        id: 313320,
        description: 'Téc Transm (telecom) manut',
    },
    {
        id: 313410,
        description: 'Técnico Instrumentação',
    },
    {
        id: 314110,
        description: 'Téc Mecânico (exceto manut)',
    },
    {
        id: 314115,
        description: 'Téc Mec(calef ventil e calef)',
    },
    {
        id: 314305,
        description: 'Téc Automobil (mecânica)',
    },
    {
        id: 314310,
        description: 'Técnico Mecânico (Aeronaves)',
    },
    {
        id: 314405,
        description: 'Téc Mecân Manut Sist e Instrum',
    },
    {
        id: 314410,
        description: 'Téc Mecânico Manu Máquinas',
    },
    {
        id: 317110,
        description: 'Program Sistema de Informação',
    },
    {
        id: 317205,
        description: 'Operador de Computador',
    },
    {
        id: 317210,
        description: 'Téc Apoio Usu Inform (helpdesk',
    },
    {
        id: 318005,
        description: 'Desenhista Técnico',
    },
    {
        id: 318010,
        description: 'Desenhista Copista',
    },
    {
        id: 318015,
        description: 'Desenhista Detalhista',
    },
    {
        id: 321105,
        description: 'Técnico Agrícola',
    },
    {
        id: 321210,
        description: 'Técnico Florestal',
    },
    {
        id: 321305,
        description: 'Técnico Piscicultura',
    },
    {
        id: 322215,
        description: 'Téc Enfermagem do Trabalho',
    },
    {
        id: 322230,
        description: 'Auxiliar Enfermagem',
    },
    {
        id: 341105,
        description: 'Piloto comercial (avião)',
    },
    {
        id: 341110,
        description: 'Piloto comercial (helicóptero)',
    },
    {
        id: 341115,
        description: 'Mecânico de Vôo',
    },
    {
        id: 342110,
        description: 'Oper Transp (Téc Oper Transp)',
    },
    {
        id: 351105,
        description: 'Técnico de Contabilidade',
    },
    {
        id: 351115,
        description: 'Consultor contábil (técnico)',
    },
    {
        id: 351305,
        description: 'Técnico Administração',
    },
    {
        id: 351505,
        description: 'Técnico em Secretariado',
    },
    {
        id: 351605,
        description: 'Técnico Segurança no Trabalho',
    },
    {
        id: 351725,
        description: 'Inspetor de Riscos',
    },
    {
        id: 352205,
        description: 'Analista Ambiental',
    },
    {
        id: 354140,
        description: 'Técnico em atendimento e vendas',
    },
    {
        id: 354205,
        description: 'Comprador',
    },
    {
        id: 354405,
        description: 'Leiloeiro',
    },
    {
        id: 354410,
        description: 'Avaliador de Imóveis',
    },
    {
        id: 354605,
        description: 'Consultor imobiliário',
    },
    {
        id: 354815,
        description: 'Consultor de viagem',
    },
    {
        id: 371105,
        description: 'Auxiliar de Biblioteca',
    },
    {
        id: 371110,
        description: 'Técnico Biblioteconomia',
    },
    {
        id: 371305,
        description: 'Técnico Programação Visual',
    },
    {
        id: 372205,
        description: 'Operador Rede de Teleproces',
    },
    {
        id: 391115,
        description: 'Controlador de entrada e saída',
    },
    {
        id: 391135,
        description: 'Técnico de matéria-prima e material',
    },
    {
        id: 391205,
        description: 'Inspetor de qualidade',
    },
    {
        id: 391210,
        description: 'Técnico de garantia da qualidade',
    },
    {
        id: 395105,
        description: 'Téc Apoio Pesquisa e Desenv',
    },
    {
        id: 410105,
        description: 'Supervisor Administrativo',
    },
    {
        id: 410205,
        description: 'Supervisor de Almoxarifado',
    },
    {
        id: 410215,
        description: 'Supervisor de Contas a Pagar',
    },
    {
        id: 410220,
        description: 'Superv de Controle Patrimonial',
    },
    {
        id: 410230,
        description: 'Supervisor Orçamento',
    },
    {
        id: 410235,
        description: 'Supervisor Tesouraria',
    },
    {
        id: 411005,
        description: 'Auxiliar de Escritório, em ger',
    },
    {
        id: 411010,
        description: 'Assistente Administrativo',
    },
    {
        id: 411020,
        description: 'Auxiliar de judiciário',
    },
    {
        id: 412110,
        description: 'Digitador',
    },
    {
        id: 413110,
        description: 'Auxiliar de contabilidade',
    },
    {
        id: 414105,
        description: 'Almoxarife (auxiliar)',
    },
    {
        id: 414110,
        description: 'Armazenista',
    },
    {
        id: 415130,
        description: 'Operador Máquina Copiadora',
    },
    {
        id: 420135,
        description: 'Superv Telemark e Atendimento',
    },
    {
        id: 421305,
        description: 'Consultor de cobrança',
    },
    {
        id: 422205,
        description: 'Telefonista',
    },
    {
        id: 422305,
        description: 'Operador Telemarketing Ativo',
    },
    {
        id: 422310,
        description: 'Oper Telemark Ativo/Receptivo',
    },
    {
        id: 422315,
        description: 'Oper Telemarketing Receptivo',
    },
    {
        id: 422320,
        description: 'Operador Telemarketing Téc',
    },
    {
        id: 423105,
        description: 'Despachante Documentalista',
    },
    {
        id: 510310,
        description: 'Superv Segur Patrim (vigilante',
    },
    {
        id: 514225,
        description: 'Trab Serv Manut Edifí e lograd',
    },
    {
        id: 514310,
        description: 'Auxiliar de manutenção predial',
    },
    {
        id: 514325,
        description: 'Trabalhador da manutenção de edificações',
    },
    {
        id: 515315,
        description: 'Consultor em dependência química',
    },
    {
        id: 517330,
        description: 'Vigilante',
    },
    {
        id: 519940,
        description: 'Leiturista',
    },
    {
        id: 521110,
        description: 'Consultor de vendas',
    },
    {
        id: 622015,
        description: 'Trabalh Prod Muda e semente',
    },
    {
        id: 631325,
        description: 'Trabalhador Criação de Peixes',
    },
    {
        id: 710205,
        description: 'Mestre (construção civil)',
    },
    {
        id: 715205,
        description: 'Pedreiro',
    },
    {
        id: 715305,
        description: 'Armador Estrutura de Concreto',
    },
    {
        id: 715505,
        description: 'Carpinteiro',
    },
    {
        id: 715610,
        description: 'Eletricista Instal (edifícios)',
    },
    {
        id: 716305,
        description: 'Vidraceiro',
    },
    {
        id: 716610,
        description: 'Pintor de obras',
    },
    {
        id: 717020,
        description: 'Servente de Obras',
    },
    {
        id: 721105,
        description: 'Ferramenteiro',
    },
    {
        id: 721215,
        description: 'Torneiro Mecânico',
    },
    {
        id: 724110,
        description: 'Encanador (Bombeiro Hidráulico',
    },
    {
        id: 724115,
        description: 'Bomb Eletric (Instal Tubulaçõe',
    },
    {
        id: 724130,
        description: 'Gasista',
    },
    {
        id: 724315,
        description: 'Soldador',
    },
    {
        id: 725105,
        description: 'Montador escritório',
    },
    {
        id: 725205,
        description: 'Montador de Máquinas',
    },
    {
        id: 731125,
        description: 'Mont Equip Elétr (cent elétric',
    },
    {
        id: 731205,
        description: 'Mont Equipam Eletrônicos',
    },
    {
        id: 731305,
        description: 'Instal-repar Equip Comut Telef',
    },
    {
        id: 731320,
        description: 'Instal-repar Linhas Apar Telec',
    },
    {
        id: 732105,
        description: 'Elet Man L Elét,Telef Com Dado',
    },
    {
        id: 732110,
        description: 'Auxiliar Manutenção',
    },
    {
        id: 732120,
        description: 'Inst Linh Elét Alta Baix Tens',
    },
    {
        id: 732130,
        description: 'Inst-repar Redes Telef Com Dad',
    },
    {
        id: 766215,
        description: 'Impressor de Off-set',
    },
    {
        id: 766315,
        description: 'Encadernador',
    },
    {
        id: 771105,
        description: 'Marceneiro',
    },
    {
        id: 782220,
        description: 'Operador de Empilhadeira',
    },
    {
        id: 782305,
        description: 'Motorista Carro de Passeio',
    },
    {
        id: 782510,
        description: 'Motorista Caminhão',
    },
    {
        id: 782515,
        description: 'Motorista Operacional Guincho',
    },
    {
        id: 783210,
        description: 'Carregador',
    },
    {
        id: 783215,
        description: 'Carregador (veículos de transportes terrestres)',
    },
    {
        id: 818110,
        description: 'Aux Labor Anál físico-químicas',
    },
    {
        id: 860110,
        description:
            'Supervisor de operação de fluidos (distribuição, captação, tratamento de água, gases, vapor)',
    },
    {
        id: 860115,
        description:
            'Supervisor de operação elétrica (geração, transmissão e distribuição de energia elétrica)',
    },
    {
        id: 861105,
        description: 'Operador Central Hidrelétrica',
    },
    {
        id: 861115,
        description: 'Operador Central Termoelétrica',
    },
    {
        id: 861205,
        description: 'Operador Subestação',
    },
    {
        id: 862505,
        description: 'Oper Instalac de Refrigeração',
    },
    {
        id: 911120,
        description: 'Mecânico Manut Motor Diesel',
    },
    {
        id: 911125,
        description: 'Mec Manut Redutores (Transm)',
    },
    {
        id: 911205,
        description: 'Mec Man Inst Apar Climat Refri',
    },
    {
        id: 911305,
        description: 'Mec Manut Máquinas (geral)',
    },
    {
        id: 914105,
        description: 'Mec Manut Aeronaves (geral)',
    },
    {
        id: 914405,
        description: 'Mec Manut Auto Motoc e simil',
    },
    {
        id: 915105,
        description: 'Técnico em manutenção de instrumentos de medição e precisão',
    },
    {
        id: 951105,
        description: 'Eletr Manutenção Eletroeletr',
    },
    {
        id: 953115,
        description: 'Eletr Inst (autom máq operatri',
    },
    {
        id: 954305,
        description: 'Reparador Equipam Escritório',
    },
    {
        id: 991305,
        description: 'Lanterneiro',
    },
    {
        id: 991310,
        description: 'Capoteiro',
    },
    {
        id: 991315,
        description: 'Pintor de Veículos',
    },
    {
        id: 991405,
        description: 'Trab Manutenção de Edificação',
    },
    {
        id: 992115,
        description: 'Borracheiro',
    },
    {
        id: 999120,
        description: 'Outros',
    },
    {
        id: 999125,
        description: 'Autônomo',
    },
];

// Retorna apenas um objeto, caso a descrição esteja duplicada
function filterUniqueDescriptions(professions: Profession[]): Profession[] {
    const seenDescriptions = new Set<string>();
    return professions.filter((profession) => {
        if (seenDescriptions.has(profession.description)) {
            return false;
        }
        seenDescriptions.add(profession.description);
        return true;
    });
}

// Ordena constantes em ordem alfabética
function sortProfessionsAlphabetically(professions: Profession[]): Profession[] {
    return professions.sort((a, b) => a.description.localeCompare(b.description));
}

const filterUniqueProfessions = filterUniqueDescriptions(professionConstants);
const professionsInOrderAlphabetically = sortProfessionsAlphabetically(filterUniqueProfessions);

export { professionsInOrderAlphabetically as professionConstants };
