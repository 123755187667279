import { Grid, makeStyles, MenuItem, Paper, Typography } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { usePlatformContext } from 'routes/platform.context';
import { useTranslation } from 'react-i18next';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import { LogoContainer } from './styles';
import { RegisterLink } from '../user-auth/styles';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh;',
        backgroundColor: '#f2f2f2',
    },
    paper: {
        padding: '3rem',
        borderRadius: '1rem',
        width: '28rem',
        margin: '1rem',
    },
}));

export const ForgotPasswordView = ({ form }) => {
    const classes = useStyles();
    const { platform } = usePlatformContext();
    const defaultImageLogo = platform?.urlOriginalLogo;
    const { t } = useTranslation();

    const { IBGEcountries } = useIBGECountries();

    return (
        <Grid container justifyContent='center' alignItems='center' className={classes.root}>
            <Grid item>
                <LogoContainer>
                    <img src={defaultImageLogo} alt='logo' />
                </LogoContainer>
                <Paper className={classes.paper}>
                    <form onSubmit={form.handleSubmit} noValidate>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography variant='h6'>{t('auth.forgot-password')}</Typography>

                                <Typography variant='body2'>
                                    {t('auth.forgot-password-text')}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Grid container direction='row' spacing={2}>
                                    <Grid item xs={8}>
                                        <IdentificationNumberTextField
                                            identificationNumber={form.values.cpf}
                                            country={form.values.country}
                                            fieldName='cpf'
                                            type='cpf'
                                            {...form}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            label={t('term.country')}
                                            fullWidth
                                            name='country'
                                            value={form.values.country}
                                            onChange={form.handleChange}
                                            error={form.touched.country && !!form.errors.country}
                                            helperText={form.touched.country && form.errors.country}
                                        >
                                            {IBGEcountries.map(({ code, name }) => (
                                                <MenuItem key={code} value={code}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle1'>
                                    <RegisterLink to='/login'>
                                        {t('password-reset.back-to-login')}
                                    </RegisterLink>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    disabled={form.isSubmitting}
                                >
                                    {t('term.advance')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
};
