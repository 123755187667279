import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import {
    IntegrationV2CreateProcessBody,
    IntegrationV2CreateProcessResponse,
    IntegrationV2SendProcessResultBody,
    IntegrationV2SendProcessResultResponse,
} from '../interfaces/integrations-v2.interfaces';

const BASE_PATH = '/integrations-v2';

const integrationsV2Requests = {
    createProcess: async (body: IntegrationV2CreateProcessBody, config?: AxiosRequestConfig<any>) =>
        doRequest<IntegrationV2CreateProcessResponse>(
            managerApiInstance.post(`${BASE_PATH}/createProcess`, body, config)
        ),
    doSendProcessResult: async (
        body: IntegrationV2SendProcessResultBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<IntegrationV2SendProcessResultResponse>(
            managerApiInstance.post(`${BASE_PATH}/doSendProcessResult`, body, config)
        ),
};

export { integrationsV2Requests };
