export const formatExport = {
    el: '.exp',
    hlh: '.exp',
    pilar: '.exp',
    publicenter: '.exp',
    govbr: '.exp',
    conam: '.exp',
    sonner: '.exp',
    planejar: '.exp',
    cmm: '.exp',
    memory: '.EXP',
    conceito: '.exp',
    ipm: '.exp',
    tg: '.exp',
    betha: '.exp',
    diretriz: '.exp',
    fiorilli: '.exp',
    equiplano: '.exp',
    escal: '.exp',
    jms: '.EXP',
    epublica: '.exp',
    thema: '.exp',
    assessor: '.exp',
    gemmap: '.exp',
    habeas: '.exp',
    mgf: '.exp',
    embras: '.exp',
};
