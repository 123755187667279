import { SignatureMethod } from 'clients/manager/interfaces/contract.interface';
import i18n from 'common/i18n';

const getTranslation = (signatureMethod?: SignatureMethod) => {
    return i18n.t(`signature-method.${signatureMethod}`, { ns: 'getters' });
};

const getSignatureMethod = (signatureMethod?: SignatureMethod): string => {
    const mapper = {
        [SignatureMethod.internal]: getTranslation(SignatureMethod.internal),
        [SignatureMethod.external]: getTranslation(SignatureMethod.external),
    };

    return signatureMethod ? mapper?.[signatureMethod] : '';
};

export { getSignatureMethod };
