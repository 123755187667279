import { Grid, Card } from '@material-ui/core';
import Breadcrumb from 'common/components/base/breadcrumb';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SearchContractsTable from './components/search-contracts-table';

const SearchContractsView = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Breadcrumb
                    customPath='/contratos'
                    paths={{
                        contratos: {
                            value: t('term.contracts'),
                            disabled: true,
                        },
                    }}
                />
            </Grid>
            <Card>
                <SearchContractsTable />
            </Card>
        </PageWrapper>
    );
};

export default SearchContractsView;
