import { FC, useEffect, useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { Edit } from '@material-ui/icons';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ListProcessesTableProps } from './props';
import { getColumns } from './columns';
import ChipLotsCount from '../chips/chip-lots-count';
import { auctionTypeToLabel } from '../auction-type-label';
import { AuctionNoticeOrganizationResume } from '../../../../clients/manager/interfaces/auction-notice.interface';
import ChipDisputeRoom from '../chips/chip-dispute-room';
import { processRules } from '../../process-utils';
import ChipSolicitationsCount from '../chips/chip-solicitations';
import ChipProvidersCount from '../chips/chip-providers';
import {
    apiRedirectTo,
    featureFlagEnabled,
    getBackofficeRedirectUrl,
    getLabelFromProcessStage,
    getMd5Hash,
    getProcessStageByBiddingStageId,
} from '../../../../common/utils';
import ChipSuspendedStatus from '../chips/chip-suspended';
import { getJudgmentCriterion } from '../../../../common/utils/getters/get-judgment-criterion.utils';

const ListProcessesTable: FC<ListProcessesTableProps> = ({ processes }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const backofficeRedirectUrlPath = getBackofficeRedirectUrl();

    const history = useHistory();
    const { t } = useTranslation();

    const rows = processes.map((process) => ({
        id: process.id,
        code: process.id,
        modality: auctionTypeToLabel(process.auctionType).long,
        title: process.simpleDescription,
        edital: process.accreditationNumber ?? '--',
        step: getLabelFromProcessStage(getProcessStageByBiddingStageId(process.biddingStageId)),
        info: {
            ...pick(process, [
                'biddingStageId',
                'lotsCount',
                'providersCount',
                'requestsCount',
                'judgmentCriterion',
                'rateTypeBid',
            ]),
            auctionType: process.auctionType,
        },
    }));

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEditClick = (processId: number) => {
        const showNewVersionProcess = featureFlagEnabled('novaTelaProcessos');

        if (showNewVersionProcess) {
            const auctionHash = getMd5Hash(String(processId));
            const path = `processos/?ver&idE=${auctionHash}&aba=dados`;

            return apiRedirectTo(backofficeRedirectUrlPath, path, true);
        }

        history.push({
            pathname: `/processos/${processId}`,
            search: `?aba=dados`,
        });
    };

    const columns = getColumns();

    return (
        <Box p={2} pt={1}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                                    {columns.map((column) => {
                                        const value = row[column.id];

                                        if (column.id === 'modality') {
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    size='small'
                                                    align={column.align}
                                                >
                                                    <Typography variant='body2'>
                                                        <b>{value}</b>
                                                    </Typography>
                                                    <Typography variant='body2'>
                                                        {getJudgmentCriterion(
                                                            row?.info?.judgmentCriterion,
                                                            row?.info.rateTypeBid === 1
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }

                                        if (column.id === 'actions') {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <IconButton
                                                        size='small'
                                                        title={t('term.edit-proccess')}
                                                        aria-label={t('term.edit')}
                                                        onClick={() => handleEditClick(row.id)}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                            );
                                        }

                                        if (column.id === 'info') {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <Box display='flex'>
                                                        <ChipLotsCount
                                                            count={value?.lotsCount ?? '--'}
                                                        />

                                                        <ChipSolicitationsCount
                                                            count={value?.requestsCount ?? '-'}
                                                        />
                                                        <ChipProvidersCount process={value} />
                                                        <ChipSuspendedStatus
                                                            typeCancel={value.typeCancel}
                                                        />
                                                        {processRules.visibleDisputeRoomRedirectIcon(
                                                            value
                                                        ) && <ChipDisputeRoom processId={row.id} />}
                                                    </Box>
                                                </TableCell>
                                            );
                                        }

                                        return (
                                            <TableCell
                                                key={column.id}
                                                size='small'
                                                align={column.align}
                                            >
                                                <Typography variant='body2'>
                                                    {value || '--'}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </Box>
    );
};

export default ListProcessesTable;
