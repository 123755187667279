import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import {
    HiringPlanItemUnitRequest,
    HiringPlanItensResponse,
} from 'clients/manager/interfaces/hiring-plans.interfaces';

interface DialogDeleteItemPcaViewProps {
    form: FormikProps<Pick<HiringPlanItemUnitRequest, 'id' | 'justification'>>;
    isLoadingSubmitForm: boolean;
    searchDescriptionItemBeingDeleted: (idItem?: number) => HiringPlanItensResponse | undefined;
}

const DialogDeleteItemPcaView: React.FC<DialogDeleteItemPcaViewProps> = (props) => {
    const { form, isLoadingSubmitForm, searchDescriptionItemBeingDeleted } = props;
    const { t } = useTranslation();
    const { itemRemoveId, isOpenDialogDeleteItemPca, onCloseDialogDeleteItemPca } =
        useHiringPlansContext();
    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth='md'
            aria-labelledby='delete-pca-unit-item-dialog'
            aria-describedby='delete-pca-unit-item-dialog-description'
            open={isOpenDialogDeleteItemPca}
            onClose={onCloseDialogDeleteItemPca}
        >
            <DialogTitle
                id='delete-pca-unit-item-dialog-title'
                style={{ backgroundColor: 'var(--primary-color)' }}
            >
                <Typography variant='h6' color='textSecondary'>
                    {t('hiring-plans.title.delete-pca-unit-item')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box width='608px' paddingY={2}>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    type='text'
                                    variant='outlined'
                                    name='itemDescription'
                                    label={t('hiring-plans.labels.description')}
                                    value={
                                        searchDescriptionItemBeingDeleted(itemRemoveId)?.description
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    type='text'
                                    variant='outlined'
                                    name='justification'
                                    label={t('hiring-plans.labels.justification')}
                                    value={form.values.justification}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.justification) &&
                                        Boolean(form.errors.justification)
                                    }
                                    helperText={
                                        Boolean(form.values.justification) &&
                                        form.errors.justification
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    disabled={isLoadingSubmitForm}
                    color='default'
                    size='medium'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={() => {
                        form.resetForm();
                        onCloseDialogDeleteItemPca();
                    }}
                >
                    {t('term.cancel')}
                </Button>
                <Button
                    disabled={isLoadingSubmitForm || !form.isValid}
                    color='primary'
                    size='medium'
                    variant='contained'
                    style={{
                        minWidth: '128px',
                        height: '100%',
                    }}
                    onClick={form.submitForm}
                >
                    {!isLoadingSubmitForm ? (
                        t('term.confirm')
                    ) : (
                        <CircularProgress color='primary' size={25} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogDeleteItemPcaView;
