import { CircularProgress } from '@material-ui/core';
import { auctionNoticeMessage } from 'clients/manager/auction-notice-message.requests';
import { AuctionMessagesList } from 'clients/manager/interfaces/auction-notice.interface';
import ProcessComment from 'modules/bidding/components/process-comment';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiConversation } from 'react-icons/bi';
import { MessageType } from 'common/enums';
import {
    CleanContainer,
    ProcessDescriptionCard,
    ProcessMessageListContainer,
    ProcessMessageSection,
} from 'modules/bidding/pages/process/styled';

interface ProcessSolicitationsMessagesContainerProps {
    id: string;
}
const ProcessSolicitationsMessagesContainer: React.FC<
    ProcessSolicitationsMessagesContainerProps
> = ({ id }) => {
    const { t } = useTranslation();

    const requestListContainerRef = useRef<HTMLDivElement>(null);

    const [messages, setMessages] = useState<AuctionMessagesList[]>([]);
    const [loading, setLoading] = useState(false);

    const getMessages = async (noLoading: boolean = false) => {
        setLoading(!noLoading);
        try {
            const { data } = await auctionNoticeMessage.listMessagesByAuctionId(id, {
                limit: 100,
                offset: 0,
            });
            return data;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMessages().then((data) => setMessages(data));
    }, []);

    return (
        <ProcessDescriptionCard variant='outlined'>
            <header>
                <BiConversation size='1.5rem' />
                <h1>{t('bidding.process.section.messages')}</h1>
            </header>
            <ProcessMessageSection>
                <ProcessMessageListContainer
                    ref={requestListContainerRef}
                    empty={!loading && messages.length === 0}
                    {...{ loading }}
                >
                    {loading && <CircularProgress />}
                    {!loading && messages.length === 0 && (
                        <CleanContainer>
                            <h3>{t('bidding.process.section.messages.blank')}</h3>
                        </CleanContainer>
                    )}
                    {!loading &&
                        messages.map(({ message, answer, provider, type, isPublished }, index) => (
                            <ProcessComment
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${index}-${message.createdAt}`}
                                id={message.id}
                                auctionId={id}
                                name={message.user.name}
                                message={message.message}
                                originalMessage={message.originalMessage}
                                date={new Date(message.createdAt)}
                                files={message.files}
                                title={provider?.companyName}
                                deferral={message?.deferral}
                                isPublished={isPublished}
                                replies={
                                    (answer?.message && [
                                        {
                                            message: answer.message,
                                            date: new Date(answer.createdAt),
                                            name: answer?.user?.name,
                                            files: answer.files,
                                        },
                                    ]) ||
                                    []
                                }
                                type={MessageType[`type${type}`]}
                                last={index === messages.length - 1}
                                enableManager
                                {...{ setMessages, getMessages }}
                            />
                        ))}
                </ProcessMessageListContainer>
            </ProcessMessageSection>
        </ProcessDescriptionCard>
    );
};

export default memo(ProcessSolicitationsMessagesContainer);
