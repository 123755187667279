import { ButtonBase, Collapse } from '@material-ui/core';
import styled from 'styled-components';

export const RegionFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RegionOptions = styled.div<{ active: number }>`
    font-size: 12px;
    display: flex;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    overflow: hidden;
    margin: 0.8rem 0;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: ${({ active }) => active * (100 / 3)}%;
        background-color: var(--primary-color);
        transition: left 0.4s;
        width: calc(100% / 3);
        height: 100%;
        z-index: -1;
    }
`;

export const RegionOption = styled.span<{ selected: boolean }>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 4px;
    color: ${({ selected }) => (selected ? 'var(--background-color)' : 'var(--text-color)')};
    transition: color 0.4s;
    cursor: pointer;
`;

export const RegionCollapse = styled(Collapse)`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const SelectedValueInput = styled(ButtonBase)`
    background-color: red;
`;
