import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'common/components/loading-button';

interface ButtonSaveProcessViewProps {
    onClick?: () => Promise<void>;
    loading: boolean;
    isEditting?: boolean;
}

const ButtonSaveProcessView: React.FC<ButtonSaveProcessViewProps> = ({
    loading,
    onClick,
    isEditting,
}) => {
    const { t } = useTranslation();
    return (
        <LoadingButton
            variant='contained'
            size='xsmall'
            onClick={onClick}
            {...(loading
                ? {
                      loading: {
                          text: `${isEditting ? t('term.updating') : t('term.creating')}..`,
                      },
                  }
                : {})}
        >
            {t('term.save')}
        </LoadingButton>
    );
};

export default memo(ButtonSaveProcessView);
