import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';

import { NoDataGenericResponse } from 'clients/interfaces/paginated.interface';

const BASE_PATH = '/users';

const publicUsersRequests = {
    unsubscribeLgpdLegalBasis: async (body?: { token: string }, config?: AxiosRequestConfig<any>) =>
        doRequest<NoDataGenericResponse>(
            managerApiInstance.post(`${BASE_PATH}/unsubscribeLgpdLegalBasis`, body, config)
        ),
};

export { publicUsersRequests };
