export const serialize = function (obj: any) {
    const str: string[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        if (
            (obj[key] && obj[key] !== undefined && obj[key] !== null && obj[key] !== '') ||
            obj[key]?.length > 0
        ) {
            str.push(
                encodeURIComponent(key) +
                    (typeof obj[key] === 'string' && obj[key].substring(0, 2) === '!='
                        ? `!=${encodeURIComponent(obj[key].substring(2))}`
                        : `=${encodeURIComponent(obj[key])}`)
            );
        }
    }

    return str.join('&');
};
