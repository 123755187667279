import { DetailedAutcionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { StatusResponseEnum } from 'common/enums';
import React from 'react';
import { usePlatformContext } from 'routes/platform.context';
import { ExceptionData } from 'clients/manager/interfaces/auction-notice-participate.interface';
import ExceptionsAlertsView from './exceptions-alerts-view';

interface BiddingProcessProps {
    exceptions: ExceptionData[];
    generalData?: DetailedAutcionNotice;
}

const ExceptionsAlerts: React.FC<BiddingProcessProps> = ({ generalData, exceptions }) => {
    const { platform } = usePlatformContext();

    const dangerExceptions = exceptions.filter(({ type }) => StatusResponseEnum.danger === type);
    const warningExceptions = exceptions.filter(({ type }) => StatusResponseEnum.warning === type);

    return (
        <ExceptionsAlertsView
            isCanceled={generalData?.isCanceled}
            isFinished={generalData?.isFinished}
            cancelType={generalData?.cancelType}
            dangerExceptions={dangerExceptions}
            warningExceptions={warningExceptions}
            platform={platform}
        />
    );
};

export default ExceptionsAlerts;
