import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    List,
    ListItem,
    ListItemSecondaryAction,
    IconButton,
    ListItemText,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import UploadFile from 'common/components/upload-file';
import { addNotificationError } from 'common/utils';
import { DeleteIcon } from 'common/icons';
import { usePlatformContext } from 'routes/platform.context';
import { CONSTANTS } from 'common/constants';
import mime from 'mime';
import { UploadProcessDocumentProps } from './props';

const useStyles = makeStyles(() => ({
    icon: {
        width: '20px',
        height: '20px',
    },
    secondaryAction: {
        '& .MuiTypography-body2': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '15px',
            fontWeight: 'normal',
        },
    },
    listItem: {
        borderRadius: '4px',
    },
}));

export { useStyles };

const UploadProcessDocument: FC<UploadProcessDocumentProps> = ({
    selectedFile,
    onSelectedFile,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { platform } = usePlatformContext();

    const maxUploadSize = Number(platform?.fileSettings?.maxUploadSizeOrganization) * 1024 * 1024;
    const getDropZoneSettings = useCallback(
        () => ({
            multiple: false,
            maxSize: platform?.fileSettings?.maxUploadSizeOrganization
                ? maxUploadSize ?? CONSTANTS.defaultFileMaxSize
                : CONSTANTS.defaultFileMaxSize,
            accept:
                platform?.fileSettings?.extensions.map(
                    (extension) => mime.getType(extension) ?? ''
                ) ?? '*',

            onDropRejected: (files) => {
                addNotificationError({
                    title: t('info.invalid-files'),
                    message: t('info.files-were-not-accepted', {
                        value: String(files.length).padStart(2, '0'),
                    }),
                });
            },
        }),
        []
    );

    const dropzoneProps = useDropzone(getDropZoneSettings());
    const { acceptedFiles } = dropzoneProps;

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            onSelectedFile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    return (
        <Box width={1} mt={1}>
            <UploadFile {...dropzoneProps} />
            {selectedFile && (
                <Box>
                    <List dense>
                        {[selectedFile].map((file, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <ListItem key={index} className={classes.listItem}>
                                <ListItemText
                                    title={file.name}
                                    className={classes.secondaryAction}
                                    primary={file.name}
                                />
                                <ListItemSecondaryAction>
                                    <Box>
                                        <IconButton size='small' edge='end' title='Deletar arquivo'>
                                            <DeleteIcon
                                                className={classes.icon}
                                                onClick={() => {
                                                    onSelectedFile(undefined);
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    );
};

export default UploadProcessDocument;
