import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    featureFlagEnabled,
} from 'common/utils';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import { userRequests } from 'clients/manager/user.requests';
import ProfileUpdatePasswordView from './profile-update-password-view';

interface IProfileUpdatePassword {
    isOpenDialog: boolean;
    onCloseModalDialog: () => void;
}

type UpdatePassword = {
    code?: string;
    currentPassword?: string;
    password?: string;
    confirmPassword?: string;
};

const ProfileUpdatePassword = (props: IProfileUpdatePassword) => {
    const { isOpenDialog, onCloseModalDialog } = props;
    const cognitoIsEnabled = featureFlagEnabled('enableCognitoAccess');
    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const { forgotPasswordSubmit } = useUserConnectedProviders();
    const { t } = useTranslation();

    const regexUppercase = /^(?=.*[A-Z]).+$/;
    const regexLowercase = /^(?=.*[a-z]).+$/;
    const regexNumber = /^(?=.*[0-9]).+$/;
    const regexSpecial = /[!@#$%^&*(),.?":{}|<>]/;

    const cognitoUpdatePassword = async (
        values: UpdatePassword,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        const { code, confirmPassword } = values;
        try {
            if (authUser && code && confirmPassword) {
                await forgotPasswordSubmit(
                    authUser.cpf,
                    authUser.country,
                    confirmPassword,
                    String(code)
                );
                onCloseModalDialog();
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('info.notification-success-change-password'),
                });
            }
        } catch (error: any) {
            onCloseModalDialog();
            addNotificationError({
                title: t('term.error'),
                message: t('info.notification-error-change-password'),
            });
        } finally {
            setSubmitting(false);
        }
    };

    const defaultUpdatePassword = async (
        { currentPassword, password, confirmPassword }: UpdatePassword,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        if (!currentPassword || !password || !confirmPassword) return;
        try {
            await userRequests.updatePassword({
                currentPassword,
                newPassword: password,
                newPasswordConfirm: confirmPassword,
            });
            addNotificationSuccess();
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            onCloseModalDialog();
            setSubmitting(false);
        }
    };

    const form = useFormik<UpdatePassword>({
        initialValues: {
            code: undefined,
            password: '',
            confirmPassword: '',
        },
        validationSchema: yup.object().shape({
            code: cognitoIsEnabled
                ? yup.string().required(t('term.required-field')).min(6).max(6)
                : yup.string(),
            currentPassword: cognitoIsEnabled ? yup.string() : yup.string().required().max(50),
            password: yup
                .string()
                .matches(regexLowercase, t('password-lowercase', { ns: 'validation' }))
                .matches(regexUppercase, t('password-upercase', { ns: 'validation' }))
                .matches(regexNumber, t('password-number', { ns: 'validation' }))
                .matches(regexSpecial, t('password-special-character', { ns: 'validation' }))
                .min(8, t('password-min-number-character', { ns: 'validation' }))
                .required(t('password', { ns: 'validation' })),
            confirmPassword: yup
                .string()
                .oneOf([yup.ref('password'), null], t('password-equals', { ns: 'validation' }))
                .required(t('password-confirm', { ns: 'validation' })),
        }),
        onSubmit: async (values: UpdatePassword, { setSubmitting }) => {
            if (cognitoIsEnabled) {
                await cognitoUpdatePassword(values, setSubmitting);
            } else {
                await defaultUpdatePassword(values, setSubmitting);
            }
        },
    });

    return (
        <ProfileUpdatePasswordView
            form={form}
            isOpenDialog={isOpenDialog}
            onCloseModalDialog={onCloseModalDialog}
        />
    );
};

export default ProfileUpdatePassword;

export type { UpdatePassword };
