import Typography from '@material-ui/core/Typography';
import useStyles from './CheckoutPaperStyle';

export default function CheckoutPaperView({ children, title }) {
    const classes = useStyles();

    return (
        <>
            {title && (
                <Typography variant='h5'>
                    <strong>{title}</strong>
                </Typography>
            )}

            <div className={classes.paperContent}>{children}</div>
        </>
    );
}
