import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

export const StyledAvatar = withStyles((theme) => ({
    root: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: ({ color }: { color: string }) => theme.palette[color].main,
    },
}))(Avatar);
