import { addNotificationError, addNotificationSuccess, validateCpf } from 'common/utils';
import { useFormik } from 'formik';
import ProviderUsersService from 'modules/provider-users/services/ProviderUsersService';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CONSTANTS } from 'common/constants';
import { useSearchProviderUsersContext } from '../../context/SearchProviderUsersContext';
import SearchProviderUsersDialogView from './SearchProviderUsersDialogView';

const SearchProviderUsersDialog = () => {
    const { setProviderUsersDialog, providerUsersDialog } = useSearchProviderUsersContext();

    const { t } = useTranslation();

    const handleClose = () => setProviderUsersDialog({ open: false });

    const initialValues = {
        cpf: '',
        isAdmin: false,
        country: '',
    };

    const validationSchema = Yup.object().shape({
        cpf: Yup.string()
            .required(t('term.required-field'))
            .test('cpf-test', t('cpf-or-cnpj-invalid', { ns: 'validation' }), (value, ctx) => {
                if (CONSTANTS.ibgeCodes.BRAZIL === ctx.parent.country) {
                    return validateCpf(value ?? '');
                }
                return true;
            }),
        country: Yup.string().required(t('term.required-field')),
    });

    const onSubmit = async ({ cpf, country, ...values }, { setSubmitting }) => {
        try {
            await ProviderUsersService.doLinkUserAndProvider({
                cpf: cpf.replace(/[.-]/g, ''),
                countryCode: country,
                ...values,
            });

            addNotificationSuccess();
            handleClose();
        } catch {
            addNotificationError({
                title: t('organization-users.pages.components.user-not-exist'),
            });
        } finally {
            setSubmitting(false);
        }
    };

    const form = useFormik({
        initialValues: providerUsersDialog.providerUser || initialValues,
        validationSchema,
        onSubmit,
    });

    return <SearchProviderUsersDialogView {...{ form, handleClose }} />;
};

export default SearchProviderUsersDialog;
