import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineReload } from 'react-icons/ai';
import { styled } from 'common/theme/styled';

const Content = styled.div`
    padding: 30px;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.textDefault};
    font-size: 14px;
`;

const CenteredContent = styled.div`
    display: flex;
    justify-content: center;
`;

const Image = styled.img`
    height: 180px;
`;

const ReloadIcon = styled(AiOutlineReload)`
    color: ${(props) => props.theme.colors.iconColorDefault};
    font-size: 26px;
    cursor: pointer;
`;

interface PageErrorFetchDataProps {}

const PageErrorFetchData: FC<PageErrorFetchDataProps> = ({ children }) => {
    const { t } = useTranslation();

    return (
        <Content>
            <div>
                <p>
                    <Image src='/assets/images/svgs/undraw_not_found.svg' />
                </p>
                <CenteredContent>{children || null}</CenteredContent>
                <CenteredContent>
                    <ReloadIcon
                        title={t('term.try-again')}
                        onClick={() => window.location.reload()}
                    />
                </CenteredContent>
            </div>
        </Content>
    );
};

export default PageErrorFetchData;
