import styled from 'styled-components';

export const LanguageButtonsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const LanguageButton = styled.button<{ active?: boolean }>`
    width: 36px;
    height: 36px;
    border: unset;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    padding: 0;
    border: 2px solid var(--icon-color);
    background-color: var(--icon-color);

    > img {
        height: 100%;
        border-radius: 18px;
        pointer-events: none;
    }

    > svg {
        transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0)')};
        transition: transform 0.1s;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        padding: 2px;
        background-color: var(--icon-color);
        position: absolute;
        left: -3px;
        bottom: -3px;
        color: var(--background-color);
    }
`;
