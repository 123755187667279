import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import ProvidersActions from 'common/components/providers/components/providers-actions';
import { useHistory } from 'react-router';

interface SearchProvidersActionsViewProps {
    provider: Provider;
    isSuperAdmin: boolean;
}

const SearchProvidersActionsView: FC<SearchProvidersActionsViewProps> = ({
    provider,
    isSuperAdmin,
}) => {
    const history = useHistory();

    return (
        <Grid>
            <ProvidersActions
                provider={provider}
                onEditContract={() => history.push(`/empresa/editar/${provider.id}`)}
                isSuperAdmin={isSuperAdmin}
            />
        </Grid>
    );
};

export default SearchProvidersActionsView;
