import { Grid, makeStyles, Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';
import { Button, Modal, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'common/utils';

const useStyles = makeStyles((theme) => ({
    modal: {
        padding: theme.spacing(2),
        width: theme.spacing(80),
    },
    buttons: {
        padding: theme.spacing(2),
        gap: theme.spacing(2),
    },
}));

const SearchDocumentTypesDialogView = ({ form, categoriesDocuments, handleClose }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const header = <span>{t('term.document-types')}</span>;

    return (
        <Modal open onClose={handleClose} {...{ header }}>
            <div className={classes.modal}>
                <form onSubmit={form.handleSubmit} noValidate>
                    <Grid container direction='column' spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label={t('term.category-document')}
                                fullWidth
                                name='categoryDocumentId'
                                value={form.values.categoryDocumentId}
                                onChange={form.handleChange}
                                error={
                                    form.touched.categoryDocumentId &&
                                    !!form.errors.categoryDocumentId
                                }
                                helperText={
                                    form.touched.categoryDocumentId &&
                                    form.errors.categoryDocumentId
                                }
                            >
                                {categoriesDocuments &&
                                    categoriesDocuments.map((category) => (
                                        <MenuItem value={category.id}>
                                            {category.categoryName}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={t('term.document-name')}
                                fullWidth
                                name='documentName'
                                value={form.values.documentName}
                                onChange={form.handleChange}
                                error={form.touched.documentName && !!form.errors.documentName}
                                helperText={form.touched.documentName && form.errors.documentName}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                multiline
                                minRows={3}
                                label={t('term.description')}
                                fullWidth
                                name='documentDescription'
                                value={form.values.documentDescription}
                                onChange={form.handleChange}
                            />
                        </Grid>

                        <Grid item container spacing={3}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox checked={form.values.documentRequired} />}
                                    label={t('term.required')}
                                    onChange={(event: React.ChangeEvent<any>) =>
                                        form.setFieldValue('documentRequired', event.target.checked)
                                    }
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox checked={form.values.actived} />}
                                    label={t('term.active')}
                                    onChange={(event: React.ChangeEvent<any>) =>
                                        form.setFieldValue('actived', event.target.checked)
                                    }
                                />
                            </Grid>
                        </Grid>

                        {form.values.dateTimeInsert && form.values.dateTimeUpdate && (
                            <Grid item container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        label={t('term.created-at')}
                                        fullWidth
                                        name='dateTimeInsert'
                                        value={formatDate(form.values.dateTimeInsert, 'L LT')}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label={t('term.last-update')}
                                        fullWidth
                                        name='dateTimeUpdate'
                                        value={formatDate(form.values.dateTimeUpdate, 'L LT')}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Grid container justifyContent='flex-end' className={classes.buttons}>
                            <Button size='small' onClick={handleClose}>
                                {t('term.cancel')}
                            </Button>

                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                size='small'
                                disabled={form.isSubmitting}
                            >
                                {t('term.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
};

export default SearchDocumentTypesDialogView;
