import { FC, useRef, useState } from 'react';
import { Editor as TEditor } from '@tinymce/tinymce-react';
import { EditorProps } from './props';
import { EditorConfigs } from './editor-config.service';
import { Content } from './styles';
import { EditorVariable, EDITOR_ID, EDITOR_KEY, VARIABLE_TRIGGER } from '.';
import EditorLoader from '../editor-loader';
import ModalSelectVariableValue from '../../process-document-templates/components/modal-select-variable-value';

interface MentionData {
    variable: EditorVariable;
    id: string;
    rng: any;
}

const EditorReplacer: FC<EditorProps> = ({
    initialData,
    onChange,
    variables,
    onKeyPress,
    onUndo,
    onUploadFile,
    visible = true,
}) => {
    const editorRef: any = useRef(null);
    const [mention, setMention] = useState<MentionData | undefined>(undefined);
    const [loadingEditor, setLoadingEditor] = useState(true);

    const onChangeEditor = () => {
        if (editorRef.current) {
            return onChange(editorRef.current.getContent());
        }
    };

    const onKeyPressEditor = () => {
        if (editorRef.current && !!onKeyPress) {
            return onKeyPress(editorRef.current.getContent());
        }
    };

    const onUndoEditor = () => {
        if (editorRef.current && !!onUndo) {
            return onUndo(editorRef.current.getContent());
        }
    };

    const getPlainVariables = () => {
        const newest: EditorVariable[] = [];
        Object.keys(variables).forEach((k) => {
            variables[k].forEach((v) => {
                newest.push({
                    id: String(v.id),
                    name: v.name,
                    description: v.description,
                    custom: k === 'customs',
                });
            });
        });
        return newest;
    };

    const plainVariables = getPlainVariables();

    const setEditorText = (variableName: string, rng: any, text: string) => {
        // não permite editar o texto de uma variável com valor substituido
        const element = `<span 
                            contenteditable='false'
                            class='replaced-variable'
                            data-replaced-custom-variable='${`${VARIABLE_TRIGGER}${variableName}`}'>
                                ${text}
                        </span>`;
        editorRef.current.selection.setRng(rng);
        editorRef.current.insertContent(element);
        setMention(undefined);
    };

    const handleVariableClick = ({ id, rng }: { id: string; rng: any }) => {
        const variable = plainVariables.find((v) => v.id === id);
        if (!variable?.custom) {
            return;
        }
        return setMention({
            id,
            rng,
            variable,
        });

        // abrir modal com textarea para caso ñão exista valor para a variavel de auction por exemplo, o valor seja digitado
    };

    return (
        <Content visible={visible}>
            <div>
                {loadingEditor && visible ? <EditorLoader /> : null}
                <TEditor
                    id={EDITOR_ID}
                    apiKey={EDITOR_KEY}
                    onInit={(_, editor) => {
                        editorRef.current = editor;
                        setLoadingEditor(false);
                    }}
                    initialValue={initialData}
                    onChange={onChangeEditor}
                    onKeyPress={onKeyPressEditor}
                    onUndo={onUndoEditor}
                    init={EditorConfigs.init(
                        true,
                        onUploadFile,
                        handleVariableClick,
                        plainVariables
                    )}
                />
            </div>
            {mention && (
                <ModalSelectVariableValue
                    variableName={mention.variable.name}
                    onClose={() => setMention(undefined)}
                    variableId={mention.variable.id}
                    setText={(text: string) => {
                        setEditorText(mention.variable.name, mention.rng, text);
                    }}
                />
            )}
        </Content>
    );
};

export default EditorReplacer;
