/* eslint-disable no-alert */
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { addNotificationSuccess } from 'common/utils';
import {
    ListMaterialData,
    ListMaterialParams,
} from 'modules/material/interfaces/material.interface';
import MaterialsService from 'modules/material/services/material.service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import SearchMaterialTableView from './SearchMaterialTableView';

interface SearchMaterialTableProps {}

const SearchMaterialTable: React.FC<SearchMaterialTableProps> = () => {
    const { t } = useTranslation();

    const history = useHistory();

    const [materialParams, setMaterialParams] = useState<ListMaterialParams>();

    const queryParams = usePaginatedQuery(
        MaterialsService.listMaterialsOrganization,
        materialParams
    );

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            setMaterialParams({ q: event.target.value });
        }
    };

    const handleEdit = (id: string) => {
        history.push(`/materiais/${id}`);
    };

    const handleRemove = async (row: ListMaterialData) => {
        if (window.confirm(`${t('material.remove-material-category')} ${row.name}?`)) {
            await MaterialsService.doDelete(row.id);
            setMaterialParams({ q: '' });
            addNotificationSuccess();
        }
    };

    return (
        <SearchMaterialTableView
            handleChangeFilter={handleChangeFilter}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            queryParams={queryParams}
        />
    );
};

export default SearchMaterialTable;
