import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

interface ImgLinkProps
    extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    href?: string;
    src: string;
    alt?: string;
}

const ImgLink = ({ href, src, alt, ...props }: ImgLinkProps) => {
    if (href) {
        return (
            <a href={href} target='_blank' rel='noopener noreferrer'>
                <img {...props} src={src} alt={alt} />
            </a>
        );
    }
    return <img {...props} src={src} alt={alt} />;
};

export default ImgLink;
