import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useBiddingFilters from 'modules/bidding/hooks/useBiddingFilters';
import { AiFillFire, AiFillHeart, AiOutlineDollar } from 'react-icons/ai';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { VscDebugRestart } from 'react-icons/vsc';
import ptBRLocale from 'common/constants/pt-BR-locale.constant';
import SearchSelect from '../search-select';
import ProcessStatusSearchSelectOptions from './SearchOptions/ProcessStatusSearchSelectOptions';
import ProcessTypeSearchSelectOptions from './SearchOptions/ProcessTypeSearchSelectOptions';
import {
    AdvancedFiltersContainer,
    SearchFiltersAsideContainer,
    SuggestedButtonFilter,
} from './styled';
import RegionFilter from '../region-filter';

registerLocale('pt-br', ptBRLocale as any);

interface SearchFiltersAsideProps {
    show?: boolean;
}

const SearchFiltersAside = ({ show }: SearchFiltersAsideProps) => {
    const { authUser, authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const { t } = useTranslation();
    const {
        state,
        setState,
        region,
        setRegion,
        onlySuggested,
        onlyFavorited,
        onlyProposal,
        supplyLine,
        setOnlyFavorited,
        setOnlySuggested,
        setOnlyProposal,
        setSupplyLine,
        changeDate,
        startDate,
        endDate,
        processStage,
        processType,
        setProcessStage,
        setProcessType,
        supplies,
        resetFilters,
    } = useBiddingFilters();

    const onlySuggestedHandle = () => setOnlySuggested((prev) => !prev);
    const onlyFavoritedHandle = () => setOnlyFavorited((prev) => !prev);
    const onlyProposalHandle = () => setOnlyProposal((prev) => !prev);

    const ProcessStatusOptions = useMemo(() => {
        const data = ProcessStatusSearchSelectOptions;
        const isAccreditation = processType === 'accreditation';
        const isAll = processType === 'all';

        if (isAccreditation)
            return data.filter(({ value }) => ['published', 'contract', 'all'].includes(value));
        if (isAll) return data;
        return data.filter(({ value }) => !['published', 'contract'].includes(value));
    }, [ProcessStatusSearchSelectOptions, processType]);

    return (
        <SearchFiltersAsideContainer {...{ show }}>
            <div>
                <h1>
                    {t('bidding.page.search.advanced-filters')}
                    <button onClick={resetFilters} type='button'>
                        <VscDebugRestart color='var(--primary-color)' size={16} />
                    </button>
                </h1>
                {authUser && authUserTokenData?.typeAccess === 'provider' && (
                    <>
                        <SuggestedButtonFilter
                            onClick={onlySuggestedHandle}
                            color='darkorange'
                            active={onlySuggested}
                        >
                            <AiFillFire size={16} />
                            {t('bidding.filter.only-suggested')}
                        </SuggestedButtonFilter>

                        <SuggestedButtonFilter onClick={onlyProposalHandle} active={onlyProposal}>
                            <AiOutlineDollar size={16} />
                            {t('bidding.filter.only-proposal')}
                        </SuggestedButtonFilter>
                    </>
                )}
                {authUser && (
                    <SuggestedButtonFilter onClick={onlyFavoritedHandle} active={onlyFavorited}>
                        <AiFillHeart size={16} />
                        {t('bidding.filter.only-favorited')}
                    </SuggestedButtonFilter>
                )}
                <AdvancedFiltersContainer disabled={onlyFavorited || onlySuggested || onlyProposal}>
                    <RegionFilter
                        value={{ state, region }}
                        onChange={({ state, region }) => {
                            setState(state);
                            setRegion(region);
                        }}
                    />
                    <SearchSelect
                        label={t('bidding.process.type')}
                        value={processType}
                        onChange={(e: any) => setProcessType(e.target.value)}
                        options={ProcessTypeSearchSelectOptions.map((option) => ({
                            ...option,
                            name: t(`bidding.process.type.${option.value}`),
                        }))}
                    />
                    <SearchSelect
                        label={t('bidding.process.stage')}
                        value={processStage}
                        onChange={(e: any) => setProcessStage(e.target.value)}
                        options={ProcessStatusOptions.map((option) => ({
                            ...option,
                            name: t(`bidding.process.status.${option.value}`),
                        }))}
                    />
                    <Autocomplete
                        multiple
                        value={supplyLine}
                        onChange={(_, value) => setSupplyLine(value)}
                        options={supplies.filter(
                            ({ title }) => title !== t('bidding.process.supply-line.all')
                        )}
                        limitTags={1}
                        getOptionLabel={(option: { title: string }) => option.title}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                                if (
                                    option.title === t('bidding.process.supply-line.all') &&
                                    supplyLine.length >= 2
                                )
                                    return null;
                                return (
                                    <Chip
                                        label={option.title}
                                        {...getTagProps({ index })}
                                        onDelete={
                                            option.title === t('bidding.process.supply-line.all')
                                                ? undefined
                                                : () =>
                                                      setSupplyLine(
                                                          supplyLine.filter(
                                                              ({ title }) => title !== option.title
                                                          )
                                                      )
                                        }
                                    />
                                );
                            })
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label={t('bidding.process.supply-line')}
                            />
                        )}
                    />
                    <DatePicker
                        customInput={
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label={t('term.dispute-date')}
                                variant='outlined'
                            />
                        }
                        onChange={changeDate}
                        locale='pt-br'
                        selectsRange
                        dateFormat='dd/MM/yyyy'
                        {...{ startDate, endDate }}
                    />
                </AdvancedFiltersContainer>
            </div>
        </SearchFiltersAsideContainer>
    );
};

export default memo(SearchFiltersAside);
