enum MessageType {
    type1 = 'explanation',
    type2 = 'impugnment',
    type3 = 'appeal',
    type4 = 'objection',
}

enum MessageTypeEnum {
    elucidation = 1, // Esclarecimento
    impugnment = 2, // Impugnação
    appeal = 3, // Recurso
    counterReason = 4, // Contrarrazão
}

export { MessageType, MessageTypeEnum };
