import { styled } from 'common/theme/styled';

const EditorStyles = styled.div`
    .tox-tinymce {
        width: 793px !important;
    }

    div.card,
    .tox div.card {
        width: 240px;
        background: white;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
        padding: 8px;
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
            Cantarell, 'Helvetica Neue', sans-serif;
    }

    div.card::after,
    .tox div.card::after {
        content: '';
        clear: both;
        display: table;
    }

    div.card h1,
    .tox div.card h1 {
        font-size: 14px;
        font-weight: bold;
        margin: 0 0 8px;
        padding: 0;
        line-height: normal;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
            Cantarell, 'Helvetica Neue', sans-serif;
    }

    div.card p,
    .tox div.card p {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
            Cantarell, 'Helvetica Neue', sans-serif;
    }
`;

const Content = styled(EditorStyles)<{ visible: boolean }>`
    display: flex;
    justify-content: center;
    position: relative;

    .tox-editor-header {
        width: 791px;
    }

    .tox-sidebar-wrap {
        margin: 78px 0 0 0;
        overflow: hidden;
    }

    .tox-tinymce {
        border: 0;
        box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);

        ${(props) => !props.visible && `display: none;`}
    }
`;

export { Content };
