import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authUserReducer } from './user/user.reducer';

const reducers = combineReducers({
    authUserState: authUserReducer,
});

export type AppState = ReturnType<typeof reducers>;

// @TODO: remove utilização do redux, salvar usuário em context por exemplo
export const Store = createStore(reducers, composeWithDevTools());
