interface SaleSource {
    sourceType: SaleSourceTypes;
    processNumber: string;
    foreignSaleId: string;
    saleDate: number;
    cpfCnpj?: string;
    name: string;
    publicCompanyName: string;
    mnemonicName?: string;
    address: string;
    zipCode: string;
    city: string;
    uf: string;
}

enum SaleSourceTypes {
    'COMPRAS_NET' = 'COMPRAS_NET',
    'LICITAR_DIGITAL' = 'LICITAR_DIGITAL',
    'PNCP' = 'PNCP',
}

export type { SaleSource };
export { SaleSourceTypes };
