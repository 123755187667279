import {
    Box,
    Divider,
    Grid,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TextField } from 'common/components';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import LoadingButton from 'common/components/loading-button';
import { StatusCrc } from 'common/enums';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { addNotificationError } from 'common/utils';
import { CompanyFormProps } from './props';

const AdministrationForm: FC<CompanyFormProps> = ({ updatingProvider, disabled }) => {
    const { t } = useTranslation();
    const form = useFormikContext<Provider>();

    const hasFormErrors = Object.keys(form.errors).length > 0;

    if (!form.values?.docNumber) {
        return (
            <Box p={2}>
                <Grid container>
                    {new Array(4).fill(null).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Skeleton key={index} height='45px' width='100%' />
                    ))}
                </Grid>
            </Box>
        );
    }

    const eligiblePrepaidPlansOnly = () => form.values.isDefaulting === 1;

    const handleEligiblePrepaidPlansOnly = () => (form.values.isDefaulting === 1 ? 0 : 1);

    return (
        <Box p={2}>
            <Grid container>
                <Box mt={3} mb={3} width={1}>
                    <Typography variant='h6'>{t('term.administration-data')}</Typography>
                    <Divider component='h6' />
                </Box>
                <Grid container spacing={1}>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('organization.organization-data.components.external-id')}
                            name='externalId'
                            onChange={form.handleChange}
                            disabled={disabled}
                            value={form.values.externalId}
                            error={form.touched.externalId && Boolean(form.errors.externalId)}
                            helperText={form.touched.externalId && form.errors.externalId}
                        />
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            select
                            label={t('term.status-crc')}
                            fullWidth
                            name='signupValid'
                            value={form.values.signupValid}
                            onChange={(e) => form.setFieldValue('signupValid', e.target.value)}
                            disabled={disabled}
                        >
                            <MenuItem value={StatusCrc.reproved}>{t('term.reproved')}</MenuItem>
                            <MenuItem value={StatusCrc.pending}>{t('term.pending')}</MenuItem>
                            <MenuItem value={StatusCrc.isValid}>{t('term.is-valid')}</MenuItem>
                            <MenuItem value={StatusCrc.suspended}>{t('term.suspended')}</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        {form.values.signupValid === StatusCrc.suspended ? (
                            <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label='Suspenso até:'
                                name='suspendedUntil'
                                format='DD/MM/yyyy'
                                value={form.values.suspendedUntil}
                                InputAdornmentProps={{ position: 'end' }}
                                invalidDateMessage={t('info.invalid-format', {
                                    ns: 'validation',
                                })}
                                invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                error={
                                    form.touched.suspendedUntil &&
                                    Boolean(form.errors.suspendedUntil)
                                }
                                clearable
                                onChange={(date) => {
                                    const suspendedUntilDate = date?.invalidAt()
                                        ? moment(date).format()
                                        : null;

                                    form.setFieldValue('suspendedUntil', suspendedUntilDate);
                                }}
                                disabled={disabled}
                            />
                        ) : (
                            <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label={t('term.validate-crc')}
                                name='signupValidExpiration'
                                format='DD/MM/yyyy'
                                value={form.values.signupValidExpiration}
                                InputAdornmentProps={{ position: 'end' }}
                                invalidDateMessage={t('info.invalid-format', {
                                    ns: 'validation',
                                })}
                                invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                error={
                                    form.touched.signupValidExpiration &&
                                    Boolean(form.errors.signupValidExpiration)
                                }
                                helperText={
                                    form.touched.signupValidExpiration &&
                                    form.errors.signupValidExpiration
                                }
                                clearable
                                onChange={(date) => {
                                    const signupValidExpirationDate = date?.invalidAt()
                                        ? moment(date).format()
                                        : null;

                                    form.setFieldValue(
                                        'signupValidExpiration',
                                        signupValidExpirationDate
                                    );
                                }}
                                disabled={disabled}
                            />
                        )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.validate-crc-financial-index')}
                            name='situation'
                            value={form.values.situation}
                            onChange={(e) => {
                                form.setFieldValue('situation', e.target.value);
                            }}
                            disabled={disabled}
                            error={form.touched.situation && Boolean(form.errors.situation)}
                            helperText={form.touched.situation && form.errors.situation}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <FormControlLabel
                            color='primary'
                            disabled={disabled}
                            control={
                                <Checkbox
                                    color='primary'
                                    checked={eligiblePrepaidPlansOnly()}
                                    onChange={() =>
                                        form.setFieldValue(
                                            'isDefaulting',
                                            handleEligiblePrepaidPlansOnly()
                                        )
                                    }
                                    disabled={disabled}
                                />
                            }
                            label={`${t('term.eligible-prepaid-plans-only')}`}
                        />
                    </Grid>
                </Grid>
                <Box mt={3} mb={3} width={1}>
                    <Divider component='h6' />
                </Box>
            </Grid>
            <Grid container alignContent='center' justifyContent='flex-end'>
                <Box mt={2}>
                    <LoadingButton
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            form.submitForm();
                            if (hasFormErrors) {
                                return addNotificationError({
                                    message: t('bidding.process.crc-form-error-alert'),
                                });
                            }
                        }}
                        disabled={disabled}
                        size='small'
                        {...(updatingProvider
                            ? {
                                  loading: {
                                      text: `${t('term.updating')}..`,
                                  },
                              }
                            : {})}
                    >
                        {t('term.save-data')}
                    </LoadingButton>
                </Box>
            </Grid>
        </Box>
    );
};

export default AdministrationForm;
