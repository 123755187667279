import { TypeAccess } from 'common/interfaces/user.interface';
import md5 from 'md5';
import { JwtContent } from 'store/user/user.reducer';

const getDisputeHash = (processId: number | string, tokenContent: JwtContent | undefined) => {
    if (tokenContent?.typeAccess === TypeAccess.organization) {
        return md5(`${tokenContent.typeAccess}_${processId}_${tokenContent.organizationId}`);
    }
    if (tokenContent?.typeAccess === TypeAccess.provider) {
        return md5(`${tokenContent.typeAccess}_${processId}_${tokenContent.providerId}`);
    }
    if (tokenContent?.typeAccess === TypeAccess.citizen) {
        return md5(`${tokenContent.typeAccess}_${processId}_${tokenContent.userId}`);
    }
    return md5(`citizen_${processId}_1`);
};

export { getDisputeHash };
