import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import SearchGroupAuthorizationView from './SearchGroupAuthorizationView';

const SearchGroupAuthorization = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    useEffect(() => {
        setTitle(t('group-auth.pages.edit-group.components.edit-group-auth.group-auth'));
    }, []);

    return <SearchGroupAuthorizationView />;
};

export default SearchGroupAuthorization;
