import { FC, useState } from 'react';
import { Button, ButtonSize, Input, Select } from '@licitar/ui-kit';
import moment from 'moment';
import { set } from 'lodash';
import { SaleSourceTypes } from 'common/interfaces/sale-source.interface';
import { useTranslation } from 'react-i18next';
import { getExtractionTypeLabel } from 'common/utils';
import { IAdvancedFilters, IFilters } from 'modules/priceBank/context/interfaces';
import { AdvancedFiltersProps } from './props';
import {
    Content,
    SaleRange,
    FiltersArea,
    ActionsArea,
    FilterContent,
    Title,
    FilterContentTitle,
} from './styled';
import RegionFilter from './region-filters';
import RangeValueFilters from './range-value-filters';

const FilterWrapper: FC<{ title: string }> = ({ title, children }) => (
    <FilterContent>
        <FilterContentTitle>
            <span>{title}</span>
        </FilterContentTitle>
        {children}
    </FilterContent>
);

const AdvancedFilters: FC<AdvancedFiltersProps> = ({
    appliedFilters,
    onFiltersChanged,
    onFilter,
}) => {
    const { t } = useTranslation();
    const [advancedFilters, setAdvancedFilters] = useState<IAdvancedFilters | undefined>(
        appliedFilters.filters
    );

    const handleChange = (field: string, value: any) => {
        setAdvancedFilters(set({ ...advancedFilters }, field, value));
    };

    const handleFieldChanges = (fields: Partial<IAdvancedFilters>) => {
        setAdvancedFilters((prevState) => ({
            ...prevState,
            ...fields,
        }));
    };

    const handleFilter = () => {
        const newAppliedFilters: IFilters = {
            ...appliedFilters,
            origin: 'search',
            filters: advancedFilters,
            skip: 0,
        };
        onFiltersChanged({ ...newAppliedFilters });
        onFilter(newAppliedFilters);
    };

    const handleClear = () => {
        const newAppliedFilters: IFilters = {
            ...appliedFilters,
            origin: 'search',
            filters: undefined,
        };
        onFiltersChanged({ ...newAppliedFilters }, true);
        onFilter(newAppliedFilters);
    };

    const getSaleSourceTypeOptions = () => {
        const items = Object.keys(SaleSourceTypes).map((key) => ({
            value: key,
            label: getExtractionTypeLabel(key as SaleSourceTypes),
        }));

        return [{ label: t('term.all'), value: '' }, ...items];
    };

    const saleSourceOptions = getSaleSourceTypeOptions();

    return (
        <Content>
            <Title>{t('term.filters-advanced')}</Title>
            <FiltersArea>
                <FilterWrapper title={t('term.region-buyer')}>
                    <RegionFilter
                        advancedFilters={advancedFilters}
                        handleChange={handleChange}
                        handleFieldChanges={handleFieldChanges}
                    />
                </FilterWrapper>
                <FilterWrapper title={t('term.between-values')}>
                    <RangeValueFilters
                        advancedFilters={advancedFilters}
                        handleChange={handleChange}
                    />
                </FilterWrapper>
                <FilterWrapper title={t('term.period')}>
                    <SaleRange>
                        <Input
                            style={{ width: '100%' }}
                            value={
                                advancedFilters?.saleDateEnd &&
                                advancedFilters?.saleDateStart &&
                                moment
                                    .duration(
                                        moment(advancedFilters?.saleDateEnd).diff(
                                            advancedFilters?.saleDateStart
                                        )
                                    )
                                    .asDays()
                            }
                            onChange={(ev) => {
                                if (ev.target.value > 0) {
                                    handleFieldChanges({
                                        saleDateEnd: moment().endOf('day').valueOf(),
                                        saleDateStart: moment(moment().endOf('day'))
                                            .subtract(ev.target.value, 'days')
                                            .valueOf(),
                                    });
                                } else {
                                    handleFieldChanges({
                                        saleDateEnd: undefined,
                                        saleDateStart: undefined,
                                    });
                                }
                            }}
                            type='number'
                            name='period'
                            label={t('term.days')}
                        />
                    </SaleRange>
                </FilterWrapper>
                <FilterWrapper title={t('term.origin')}>
                    <Select
                        name='sourceType'
                        options={saleSourceOptions}
                        isMulti={false}
                        value={
                            saleSourceOptions.find(
                                (option) => option.value === advancedFilters?.sourceType
                            ) ?? saleSourceOptions[0]
                        }
                        label={t('term.origin-price')}
                        onChange={(option) => {
                            if (!option) {
                                return handleChange('sourceType', undefined);
                            }
                            handleChange('sourceType', option.value);
                        }}
                    />
                </FilterWrapper>
                <ActionsArea>
                    <Button
                        style={{ margin: '0 10px 0 0 ' }}
                        outline
                        size={ButtonSize.sm}
                        onClick={handleClear}
                    >
                        {t('term.remove')}
                    </Button>
                    <Button size={ButtonSize.sm} onClick={handleFilter}>
                        {t('term.apply')}
                    </Button>
                </ActionsArea>
            </FiltersArea>
        </Content>
    );
};

export default AdvancedFilters;
