import { FC, useCallback } from 'react';
import { ContractStatus } from 'clients/manager/interfaces/contract.interface';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';
import { useStyles } from './styles';

interface ChipContractStatusProps {
    status: ContractStatus;
}

const ChipContractStatus: FC<ChipContractStatusProps> = ({ status }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getLabel = useCallback(() => {
        switch (status) {
            case ContractStatus.draft:
                return t('term.draft');

            case ContractStatus.generated:
                return t('term.generated');

            case ContractStatus.signatures:
                return t('term.signatures');

            case ContractStatus.concluded:
                return t('term.concluded');
            case ContractStatus.obsolete:
                return 'Obsoleto';
            default:
                return t('term.draft');
        }
    }, [status]);

    const label = getLabel();

    return (
        <Chip className={classes.chip} label={label} color='primary' size='small' title={label} />
    );
};

export default ChipContractStatus;
