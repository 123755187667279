import { OrderItemStatusEnum, OrderStatusEnum } from 'common/enums';
import { Order, OrderItem } from '../interfaces/marketplace.interface';

export const marketplaceUtils = {};

export const orderUtils = {
    isJudged: (order?: Order) => order?.status === OrderStatusEnum.JUDGED,
    notJudged: (order?: Order) => order?.status !== OrderStatusEnum.JUDGED,
};

export const orderItemUtils = {
    isFinished: (orderItem?: OrderItem) => orderItem?.status === OrderItemStatusEnum.FINISHED,
    notFinished: (orderItem?: OrderItem) => orderItem?.status !== OrderItemStatusEnum.FINISHED,

    isFrustrated: (orderItem?: OrderItem) => orderItem?.status === OrderItemStatusEnum.FRUSTRATED,
    notFrustrated: (orderItem?: OrderItem) => orderItem?.status !== OrderItemStatusEnum.FRUSTRATED,
};
