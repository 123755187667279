import { managerApiInstance } from 'clients/api';
import { addNotificationError } from 'common/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getEnableHttpOnlyToken } from 'modules/auth/session.utils';
import SignInView from './SignInView';

const SignIn = () => {
    const initialValues = { cpf: '', password: '' };

    const validationSchema = Yup.object().shape({
        cpf: Yup.string().required().max(100),
        password: Yup.string().required().max(100),
    });

    const onSubmit = async ({ cpf, password }, { setSubmitting }) => {
        try {
            const { enableHttpToken } = getEnableHttpOnlyToken();

            const { data } = await managerApiInstance.post(
                '/auth/doLogin',
                {
                    cpf: cpf.replace(/[.-]/g, ''),
                    password,
                },
                { withCredentials: enableHttpToken }
            );

            window.location.href = `/auth?token=${data.token}`;
        } catch {
            addNotificationError();
        } finally {
            setSubmitting(false);
        }
    };

    const form = useFormik({ initialValues, validationSchema, onSubmit });

    return <SignInView {...{ form }} />;
};

export default SignIn;
