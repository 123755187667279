import { Box } from '@material-ui/core';
import { memo, useState } from 'react';
import i18n from 'i18next';
import ContentTabs from '../content-tabs';
import DefaultDocuments from './components/default-documents';
import AdditionalDocuments from './components/additional-documents';

enum DocumentsTab {
    general = 1,
    additional = 2,
}

type ContentTab = {
    [key in DocumentsTab]: { label: string; title: string };
};

const ProcessDocumentsData = () => {
    const [contentTabSelected, setContentTabSelected] = useState<DocumentsTab>(
        DocumentsTab.general
    );

    const contentTabs: ContentTab = {
        [DocumentsTab.general]: {
            label: i18n.t('term.process'),
            title: i18n.t('term.process'),
        },
        [DocumentsTab.additional]: {
            label: i18n.t('term.additional'),
            title: i18n.t('term.additional'),
        },
    };

    return (
        <Box>
            <ContentTabs
                tabs={contentTabs}
                currentTab={contentTabSelected}
                onTabChanged={(tab) => setContentTabSelected(tab)}
            />
            {contentTabSelected === DocumentsTab.general && <DefaultDocuments />}
            {contentTabSelected === DocumentsTab.additional && <AdditionalDocuments />}
        </Box>
    );
};

export default memo(ProcessDocumentsData);
