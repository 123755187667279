import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    loading: {
        marginLeft: 5,
        color: '#a1a1a1',
        width: '18px !important',
        height: '18px !important',
    },
}));
