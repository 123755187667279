import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { FormikProps } from 'formik';
import { useMediaQuery } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { HiOutlineX } from 'react-icons/hi';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import LoadingButton from 'common/components/loading-button';
import { featureFlagEnabled } from 'common/utils';
import { UpdatePassword } from './profile-update-password';
import CreateNewPassword from './create-password-view';
import { Content } from './styles';

interface IProfileUpdatePasswordView {
    form: FormikProps<UpdatePassword>;
    isOpenDialog: boolean;
    onCloseModalDialog: () => void;
}

const useStyles = makeStyles({
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        },
    },
});

const ProfileUpdatePasswordView = (props: IProfileUpdatePasswordView) => {
    const { form, isOpenDialog, onCloseModalDialog } = props;
    const cognitoIsEnabled = featureFlagEnabled('enableCognitoAccess');
    const firstInputRef = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classesInput = useStyles();

    const handleCloseModal: DialogProps['onClose'] = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onCloseModalDialog();
    };

    useEffect(() => {
        setTimeout(() => {
            if (isOpenDialog && firstInputRef && !!firstInputRef.current)
                firstInputRef.current.focus();
            form.resetForm();
        }, 100);
    }, [isOpenDialog]);

    return (
        <Dialog
            fullWidth
            disableEscapeKeyDown
            fullScreen={fullScreen}
            maxWidth='md'
            aria-labelledby='modal-dialog-change-password'
            aria-describedby='modal-dialog-change-password-description'
            open={isOpenDialog}
            onClose={handleCloseModal}
        >
            <DialogTitle
                disableTypography
                id='modal-dialog-change-password-title'
                style={{
                    backgroundColor: 'var(--primary-color)',
                    padding: '12px 24px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='h6' color='textSecondary'>
                    {t('title.update-password')}
                </Typography>
                <IconButton
                    aria-label='close modal change password'
                    color='primary'
                    onClick={onCloseModalDialog}
                    style={{
                        padding: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}
                >
                    <HiOutlineX size={28} color='var(--table-header-color)' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {cognitoIsEnabled && (
                    <Typography variant='subtitle1' style={{ margin: '12px 0' }}>
                        {t('info.description-dialog-update-password')}
                    </Typography>
                )}
                <FormControl fullWidth>
                    <Content container direction='column' spacing={2}>
                        {cognitoIsEnabled && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    inputRef={firstInputRef}
                                    className={classesInput.input}
                                    type='number'
                                    label={t('term.code')}
                                    name='code'
                                    value={form.values.code}
                                    onChange={form.handleChange}
                                    error={form.touched.code && !!form.errors.code}
                                    helperText={
                                        (form.touched.code && form.errors.code) ||
                                        t('info.code-instruction-password-confirm')
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item container spacing={2}>
                            <CreateNewPassword form={form} />
                        </Grid>
                    </Content>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    disabled={form.isSubmitting}
                    color='default'
                    size='small'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={() => {
                        form.resetForm();
                        onCloseModalDialog();
                    }}
                >
                    {t('term.cancel')}
                </Button>
                <LoadingButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => form.handleSubmit()}
                    disabled={form.isSubmitting || !form.isValid}
                    {...(form.isSubmitting && { loading: { text: '' } })}
                >
                    {t('term.confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ProfileUpdatePasswordView;
