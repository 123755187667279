import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from '../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';

const path = 'auction-notice-technical-criteria';

export interface AuctionTechnicalCriteria {
    id: string;
    auctionNoticeId: number;
    title: string;
    description: string;
    maxPoints: number;
    dateTimeInsert: Date;
    userId: number;
}

export interface CreateAuctionTechnicalCriteria
    extends Omit<AuctionTechnicalCriteria, 'id' | 'userId' | 'dateTimeInsert'> {}

interface UpdateAuctionTechnicalCriteria
    extends Omit<AuctionTechnicalCriteria, 'userId' | 'dateTimeInsert'> {}

interface ListTechnicalCriteriaFilters {
    offset?: number;
    limit?: number;
    params: {
        auctionId: number;
    };
}

const technicalCriteriaRequests = {
    createTechnicalCriteria: async (data: CreateAuctionTechnicalCriteria) =>
        doRequest<PlainGenericResponse<AuctionTechnicalCriteria>>(
            managerApiInstance.post(`/${path}/createTechnicalCriteria`, data)
        ),
    bulkCreateTechnicalCriteria: async (data: {
        technicalCriteriaDto: CreateAuctionTechnicalCriteria[];
        processId: number;
    }) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/bulkCreateTechnicalCriteria`, data)
        ),
    updateTechnicalCriteria: async (data: UpdateAuctionTechnicalCriteria) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/updateTechnicalCriteria`, data)
        ),
    deleteTechnicalCriteria: async (technicalCriteriaId: string) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/deleteTechnicalCriteria`, {
                technicalCriteriaId,
            })
        ),
    listTechnicalCriteria: async (filters: ListTechnicalCriteriaFilters) =>
        doRequest<GenericResponse<AuctionTechnicalCriteria>>(
            managerApiInstance.post(`/${path}/listTechnicalCriteria`, filters)
        ),
};

export { technicalCriteriaRequests };
