import { doRequest, managerApiInstance } from 'clients/api';
import { useHistory } from 'react-router';
import { Auth } from 'aws-amplify';
import { addNotification } from 'common/utils';
import { NotificationType } from 'common/interfaces/notification.interface';
import { INewUser } from 'modules/sign-up/pages/sign-up/props';
import { NewUserCreated } from 'clients/manager/interfaces/user.interface';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from 'routes/platform.context';
import { useMemo } from 'react';
import { useValidadeToken } from './useValidateToken';

type CheckConnections = {
    id: number;
    cognitoUserName: string;
    provider: string;
}[];

const useUserConnectedProviders = () => {
    const history = useHistory();
    const { authenticate } = useValidadeToken();
    const { platform } = usePlatformContext();
    const { t } = useTranslation();

    const isfeatureFlagEnabled = useMemo(() => {
        return platform?.generalSettings?.cognito.enableModule;
    }, [platform]);

    const oauthLogin = async (accessToken: string, idToken: string) => {
        try {
            const { data } = await managerApiInstance.post('/publicUserProviderConnection/login', {
                cognitoAccessToken: accessToken,
                idToken,
            });

            await authenticate(data.token);
        } catch {
            addNotification({
                title: t('auth.provider.error'),
                message: t('auth.provider.error.message'),
                type: NotificationType.danger,
                duration: 2000,
            });

            setTimeout(async () => {
                await Auth.deleteUser();
            }, 2000);
        }
    };

    const connectUser = async (userId: number, accessToken: string, idToken: string) => {
        try {
            await managerApiInstance.post('/userProviderConnection/create', {
                accessToken,
                userId,
                idToken,
            });
            history.push('/meu-perfil');
        } catch (error) {
            history.push('/meu-perfil?externalProviderError=true');
        }
    };

    const disconnectUser = async (userId: number, provider: string) => {
        await managerApiInstance.delete('/userProviderConnection/connection', {
            params: {
                provider,
                userId,
            },
        });
    };

    const checkConnections = async (userId: number) => {
        const { data } = await managerApiInstance.get<CheckConnections>(
            '/userProviderConnection/connection',
            {
                params: {
                    userId,
                },
            }
        );

        return data;
    };

    const sendPasswordResetCode = async (cpf?: string, country?: string) => {
        await managerApiInstance.post('/publicUserProviderConnection/doSendPasswordResetCode', {
            cpf,
            country,
        });
    };

    const forgotPasswordSubmit = async (
        cpf: string,
        country: string,
        newPassword: string,
        verificationCode: string
    ) =>
        doRequest(
            managerApiInstance.post('/publicUserProviderConnection/doForgotPasswordSubmit', {
                cpf,
                country,
                newPassword,
                verificationCode,
            })
        );
    const createUser = async (newUser: INewUser) => {
        const response = await managerApiInstance.post<NewUserCreated>(
            '/publicUserProviderConnection/createUser',
            newUser
        );

        return response.data;
    };

    return {
        oauthLogin,
        connectUser,
        checkConnections,
        disconnectUser,
        sendPasswordResetCode,
        createUser,
        isfeatureFlagEnabled,
        forgotPasswordSubmit,
    };
};

export default useUserConnectedProviders;
