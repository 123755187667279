import { useTranslation } from 'react-i18next';
import { GoCheck } from 'react-icons/go';
import { LanguageButton, LanguageButtonsContainer } from './styles';

const LanguagesButtons = () => {
    const {
        i18n: { changeLanguage, language },
    } = useTranslation();

    const changeLng = (lng?: string) => changeLanguage(lng);

    return (
        <LanguageButtonsContainer>
            <LanguageButton active={language === 'en'} onClick={() => changeLng('en')}>
                <img src='/assets/images/locales/en-es.png' alt='en' />
                <GoCheck />
            </LanguageButton>
            <LanguageButton active={language === 'pt-BR'} onClick={() => changeLng('pt-BR')}>
                <img src='/assets/images/locales/pt-br.png' alt='pt-BR' />
                <GoCheck />
            </LanguageButton>
        </LanguageButtonsContainer>
    );
};

export default LanguagesButtons;
