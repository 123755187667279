import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import HiringPlansProvider from './contexts/HiringPlansContext';
import HiringPlansSearch from './pages/search';
import PcaPlan from './pages/pca';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <HiringPlansProvider>
                <PrivateRoute
                    exact
                    path={`${match.path}/`}
                    key='/planos-contratacao-pesquisar'
                    component={() => <HiringPlansSearch />}
                />
                <PrivateRoute
                    exact
                    path={`${match.path}/:pcaId/:organizationUnitId`}
                    key='/planos-contratacao/visualizar-plano-contratacao'
                    component={() => <PcaPlan />}
                />
            </HiringPlansProvider>
        </Switch>
    );
};

const HiringPlansRoutes: JSX.Element[] = [
    <Route path='/planos-contratacao' key='/planos-contratacao' render={() => <ChildRoutes />} />,
];

export default HiringPlansRoutes;
