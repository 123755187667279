import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { TypeValueBidTypes } from 'clients/manager/interfaces/auction-notice.interface';
import { auctionNoticeLotRequests } from 'clients/manager/auction-notice-lot.requests';
import { actionLotItemRequests } from 'clients/manager/auction-lot-item.requests';
import {
    AuctionNoticeLotItem,
    AuctionNoticeLotWithItems,
    UpdateLotQuotaResponse,
    TypeOfBenefitValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    addNotificationWarning,
    timeout,
} from 'common/utils';
import { useTranslation } from 'react-i18next';
import { diff } from 'deep-object-diff';
import { orderLots } from 'common/utils/lots-ordener.util';
import { OkResponse } from 'clients/interfaces/ok.interface';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { StatusResponseEnum } from 'common/enums';
import { ProcessLotProps } from './props';
import { lotActions, processActions } from '../../../../process-actions';
import { processRules, processUtils } from '../../../../process-utils';
import {
    AuctionLotFormValues,
    transformLotItemToCompareValues,
    transformLotToCompareValues,
} from '../../../../context/lots.form';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';
import ProcessLotView from './process-lot-view';

export const rowsPerPageOptions = [8, 16];

const ProcessLot: FC<ProcessLotProps> = ({
    lot,
    lotItem,
    isVisible,
    hasSusIntegration,
    handleDefaultPage,
}) => {
    const { t } = useTranslation();
    const { processForm, process } = useProcessFormContext();
    const {
        processLotsForm,
        processLotsBkpBeforeSave,
        setProcessLotsBkpBeforeSave,
        setFieldValue,
    } = useProcessLotsFormContext();
    const [oppenedSusModal, setOppenedSusModal] = useState<number>();
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
    const [visibleAdvancedInfo, setVisibleAdvancedInfo] = useState(!!isVisible);
    const [expandedLot, setExpandedLot] = useState<boolean>(!!isVisible);
    const [creatingLotItem, setCreatingLotItem] = useState(false);
    const [savingLot] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const canSaveLot = Object.keys(processLotsForm.errors?.lots?.[lotItem - 1] ?? {}).length === 0;
    const isSavedLot = !!lot?.id;
    const { lots } = processLotsForm.values;
    const lotQuotas = lots.filter((lot) => lot.quotaId).length;
    const lotsId = lots.filter((lot) => lot.id).length;
    const numLots = lotsId - lotQuotas;
    const hasOnlyOneLot = numLots === 1;
    const cantDeleteLot =
        hasOnlyOneLot && process && processActions.notInDraft(process.biddingStageId);

    const foundLot = processLotsForm.values.lots.find(
        (lotForm) => lotForm.item === lot.item && lotForm.quotaId === lot.quotaId
    );
    const values = foundLot || ({ items: [] } as any);

    const initialFormValues =
        processLotsBkpBeforeSave?.lots?.find((lot) =>
            lot?.id ? lot.id === foundLot?.id : lot.item === foundLot?.item
        ) ||
        processLotsForm?.initialValues?.lots?.find((lot) =>
            lot?.id ? lot.id === foundLot?.id : lot.item === foundLot?.item
        );

    useEffect(() => {
        if (!initialFormValues && processLotsBkpBeforeSave?.lots.length === 0) {
            setProcessLotsBkpBeforeSave?.((prevState) => {
                if (prevState) {
                    prevState = {
                        ...prevState,
                        lots: processLotsForm.values.lots,
                    };
                }
                return prevState;
            });
        }
    }, []);

    const deleteLotItems = async () => {
        if (!process?.id || !lot?.id) {
            return;
        }

        try {
            const itemsIds =
                values?.items
                    ?.filter((item, index) => item?.id && selectedItems?.includes(index))
                    ?.map((item) => item?.id) ?? [];

            if (itemsIds?.length > 0) {
                await actionLotItemRequests.deleteProcessLotItems({
                    lotId: lot.id,
                    auctionNoticeId: process.id,
                    itemsIds: itemsIds,
                });
            }

            addNotificationSuccess({
                message: `${t('term.lot')} ${t('term.saved')}.`,
            });

            return;
        } catch (error) {
            addNotificationError({
                title: t('process.components.error-remove-item'),
                message: t('process.components.error-remove-item-try-again'),
            });
            return undefined;
        }
    };

    const handleClickCreateItem = async () => {
        if (!process?.id) {
            return;
        }

        setCreatingLotItem(true);

        try {
            const item = {
                id: null,
                lotId: lot.id,
                auctionNoticeId: process.id,
                item:
                    (lot?.items?.reduce(
                        (higherLotItemValue, actualLotItem) => {
                            return actualLotItem.item > higherLotItemValue.item
                                ? actualLotItem
                                : higherLotItemValue;
                        },
                        { item: 0 }
                    )?.item ?? 0) + 1,
                dateTimeInsert: new Date().toISOString(),
                externalItemId: null,
                itemDescription: '',
                unitMeasurement: '',
                amount: 0,
                attachDatasheet: null,
                referenceValue: 0,
                specificDocuments: null,
                dateTimeUpdate: null,
                pncpLink: null,
                susCode: null,
                susText: null,
            };

            ReactDOM.unstable_batchedUpdates(() => {
                // se adicionar mais itens do que a quantidade de paginação, pula para a próxima página
                if (values.items.length + 1 > rowsPerPageOptions[0]) {
                    const nextPage =
                        Math.ceil((values.items.length + 1) / rowsPerPageOptions[0]) - 1;
                    setPage(nextPage);
                }
                const newLotItems = [...values.items, item];

                setFieldValue(lot, 'items', newLotItems);

                const nextIndex = values.items.length;
                timeout(() => {
                    const input = document.getElementById(`description-${nextIndex}`);
                    input?.focus();
                    setCreatingLotItem(false);
                }, 0);
            });
        } catch (error) {
            setCreatingLotItem(false);
            addNotificationError({
                title: t('process.components.error-add-item'),
                message: t('process.components.error-add-item-try-again'),
            });
        }
    };

    const handleDeleteItems = async () => {
        deleteLotItems();

        ReactDOM.unstable_batchedUpdates(() => {
            const newLotItems = values.items.filter((_, index) => !selectedItems.includes(index));

            setSelectedItems([]);
            setFieldValue(lot, 'items', newLotItems);
            setProcessLotsBkpBeforeSave?.((prevState) => {
                if (prevState) {
                    const foundPrevLot = prevState?.lots?.findIndex(
                        (prevLot) => prevLot.id === lot.id
                    );

                    if (foundPrevLot !== -1 && foundPrevLot !== undefined)
                        prevState.lots[foundPrevLot].items = newLotItems;
                }
                return prevState;
            });
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        });
    };

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = values.items.map((_, index) => index);
            setSelectedItems(newSelecteds);
            return;
        }
        setSelectedItems([]);
    };

    const isSelected = (name: number) => selectedItems.indexOf(name) !== -1;

    const handleCheckItem = (_: React.MouseEvent<unknown>, name: number) => {
        const selectedIndex = selectedItems.indexOf(name);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedItems, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, selectedIndex),
                selectedItems.slice(selectedIndex + 1)
            );
        }

        setSelectedItems(newSelected);
    };

    const onClickAdvancedInfo = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            setVisibleAdvancedInfo((prevState) => !prevState);
            setExpandedLot(true);
        });
    };

    const foundLotIndex = processLotsForm?.values?.lots?.findIndex(
        (formLot) => formLot.id === foundLot?.id
    );

    const isLotValid =
        Object.keys(processLotsForm.errors?.lots?.[foundLotIndex] ?? {}).length === 0;

    const hasLotsChanges =
        Object.keys(
            diff(
                transformLotToCompareValues(values),
                transformLotToCompareValues(initialFormValues ?? {})
            )
        ).length > 0 &&
        values?.id &&
        initialFormValues?.id;

    const hasLotItemsChanges = !!(values as AuctionLotFormValues).items.find((newValue) => {
        const initialValue = initialFormValues?.items.find(
            (oldValues) => oldValues.item === newValue.item
        );

        if (!initialValue?.item) return true;

        return (
            Object.keys(
                diff(
                    transformLotItemToCompareValues(newValue),
                    transformLotItemToCompareValues(initialValue)
                )
            ).length > 0
        );
    })?.item;

    const lotHasChanged = hasLotsChanges || hasLotItemsChanges;

    const setLotsBkp = async (lotToUpdate?: AuctionNoticeLotWithItems) => {
        setProcessLotsBkpBeforeSave?.((prevState) => {
            if (prevState) {
                const filteredLots = prevState.lots.filter(
                    (prevLot) => prevLot.id !== lot.id && prevLot.quotaId !== lot.id
                );

                if (lotToUpdate) filteredLots.push(lotToUpdate);

                prevState = {
                    ...prevState,
                    lots: filteredLots,
                };
                orderLots(prevState?.lots ?? []);
            }

            return prevState;
        });
    };

    const removeLotFromForm = () => {
        const remainingLots = processLotsForm.values.lots.filter(
            (formLot) => formLot.item !== lot.item
        );
        orderLots(remainingLots);
        processLotsForm.setValues({ lots: remainingLots });
        setLoading(false);
        handleDefaultPage(remainingLots);
        addNotificationSuccess({
            message: t('process.components.lot-deleted'),
        });
    };

    const deleteLot = async (auctionId: number, auctionLotId: number) => {
        const response = await auctionNoticeLotRequests.deleteLot({
            auctionId,
            auctionLotId,
        });
        return response;
    };

    const updateLotQuota = async (
        auctionId: number,
        auctionLotId: number,
        typeOfBenefit: string
    ) => {
        return auctionNoticeLotRequests.updateLotQuota({
            auctionId,
            auctionLotId,
            typeOfBenefit,
        });
    };

    const reorganizeLots = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            const currentLots: AuctionNoticeLotWithItems[] = processLotsForm.values.lots.filter(
                (formLot) => {
                    return formLot.id !== lot.id && formLot.quotaId !== lot.id;
                }
            );

            orderLots(currentLots);
            processLotsForm.setValues({ lots: currentLots });
            setLotsBkp();
            handleDefaultPage(currentLots);
        });
    };

    const reorganizeLotsAfterRemoveLotQuota = (
        lotItems: AuctionNoticeLotItem[],
        typeOfBenefit: string
    ) => {
        const currentLots: AuctionNoticeLotWithItems[] = processLotsForm.values.lots.filter(
            (formLot) => formLot.quotaId !== lot.id
        );

        const lotToUpdate: AuctionNoticeLotWithItems | undefined = currentLots.find(
            (formLot) => formLot.id === lot.id
        );
        if (lotToUpdate) {
            lotToUpdate.typeOfBenefit = typeOfBenefit as TypeOfBenefitValue;
            lotToUpdate.items = lotItems;
        }

        orderLots(currentLots);
        processLotsForm.setValues({ lots: currentLots });
        setLotsBkp(lotToUpdate);
        handleDefaultPage(currentLots, true);
    };

    const handleApiResponse = (status: string) => {
        setLoading(false);

        if (status === StatusResponseEnum.success) {
            reorganizeLots();

            return addNotificationSuccess({
                message: t('process.components.lot-deleted'),
            });
        }

        throw new Error(t('process.components.error-lot-delete'));
    };

    const handleApiResponseQuota = (
        response: PlainGenericResponse<UpdateLotQuotaResponse>,
        typeOfBenefit: string
    ) => {
        setLoading(false);
        const { status, data } = response;

        if (status === StatusResponseEnum.success) {
            reorganizeLotsAfterRemoveLotQuota(data.updatedLotItems, typeOfBenefit);
            return addNotificationSuccess({
                message: t('process.components.lot-deleted'),
            });
        }

        throw new Error(t('process.components.error-lot-delete'));
    };

    const hasReservedQuota =
        lot.id !== null &&
        processLotsForm.values.lots.find(
            (formLot) =>
                formLot.quotaId === lot.id &&
                formLot.typeOfBenefit === TypeOfBenefitValue.reservedQuota
        );

    const onClickDeleteLot = async () => {
        if (!process?.id) {
            return;
        }

        // @TODO: Remover esse logica que impede deletar lote quota
        if (hasReservedQuota) {
            setLoading(false);
            addNotificationWarning({
                message: t('error.cannot-delete-master-quota'),
            });
            return;
        }

        try {
            setLoading(true);
            if (isSavedLot) {
                if (cantDeleteLot) {
                    setLoading(false);
                    addNotificationError({
                        message: t('error.not-allowed-remove-all-lots'),
                    });
                    return;
                }

                const response: PlainGenericResponse<OkResponse> = await deleteLot(
                    process.id,
                    lot.id
                );

                return handleApiResponse(response.status);
            }

            removeLotFromForm();
        } catch (error) {
            setLoading(false);
            addNotificationApiError(error);
        }
    };

    const onClickUpdateLotQuota = async (typeOfBenefit: string) => {
        if (!process?.id) {
            return;
        }

        try {
            setLoading(true);
            if (isSavedLot) {
                const response: PlainGenericResponse<UpdateLotQuotaResponse> = await updateLotQuota(
                    process.id,
                    lot.id,
                    typeOfBenefit
                );
                return handleApiResponseQuota(response, typeOfBenefit);
            }
        } catch (error) {
            setLoading(false);
            addNotificationApiError(error);
        }
    };

    const canEditLotQuota =
        lotActions.canEditLotQuota(lot, processLotsForm?.values?.lots as any) || lotHasChanged;
    const visibleAdvancedInfoBlock = processRules.visibleLotAdvancedInfo(processForm?.values);
    const canEditLot =
        processActions.canEditLot(processForm?.values.biddingStageId) && canEditLotQuota;
    const canCreateLotQuota = lotActions.canCreateLotQuota(lot, isLotValid, processForm?.values);
    const canEditLotItems = processActions.canEditLotItems(processForm?.values) && canEditLotQuota;
    const canDeleteLotItems =
        processActions.canDeleteLotItems(processForm?.values) && canEditLotQuota;
    const canCreateLotItem =
        processActions.canCreateLotItem(processForm?.values) && canEditLotQuota;
    const disableCreateLotItem =
        (processForm?.values.typeValueBid === TypeValueBidTypes.unit && values.items.length >= 1) ||
        (processUtils.isMarketplace(process) && values.items.length >= 1);
    const showSus = Boolean(
        hasSusIntegration &&
            processUtils.isAccreditationProcess(processForm?.values) &&
            processUtils.notIsMarketplace(processForm?.values)
    );

    return (
        <ProcessLotView
            {...{
                lotHasChanged,
                canSaveLot,
                canEditLot,
                canEditLotItems,
                canCreateLotQuota: !!canCreateLotQuota,
                canCreateLotItem,
                disableCreateLotItem,
                canDeleteLotItems,
                visibleAdvancedInfoBlock,
                visibleAdvancedInfo,
                showSus: showSus,
                expandedLot,
                setExpandedLot,
                creatingLotItem,
                savingLot,
                lotItem,
                oppenedSusModal,
                setOppenedSusModal,
                page,
                rowsPerPage,
                lot,
                selectedItems,
                values,
                handleSelectAllClick,
                isSelected,
                handleCheckItem,
                handleChangePage,
                handleChangeRowsPerPage,
                handleClickCreateItem,
                handleDeleteItems,
                onClickAdvancedInfo,
                onClickDeleteLot,
                onClickUpdateLotQuota,
                loading,
            }}
        />
    );
};

export default ProcessLot;
