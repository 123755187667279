import { Avatar, FormGroup } from '@material-ui/core';
import styled from 'styled-components';

export const ProcessCommentContainer = styled.div`
    display: grid;
    position: relative;
    grid-template-areas: 'avatar title' 'line content';
    grid-template-columns: 3rem auto;
    grid-template-rows: 3rem auto;
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
`;

export const ProcessCommentAvatarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > svg {
        position: absolute;
        top: 2px;
        left: 2px;
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        border-radius: 100%;

        ::before {
            content: '';
            top: 0;
            left: 0;
            position: absolute;
            background-color: red;
            width: 20px;
            height: 20px;
        }
    }
`;

export const ProcessCommentAvatar = styled(Avatar)`
    background-color: var(--primary-color);
`;

export const ProcessCommentTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 80%;

    > h1 {
        margin: 0;
        font-weight: 400;
        font-size: 1.25rem;
        color: var(--text-light-color);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ProcessCommentLineContainer = styled.div<{ last: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    visibility: ${({ last }) => last && 'hidden'};

    &::before {
        content: '';
        flex: 1;
        max-width: 1px;
        background-color: var(--text-light-secondary-color);
        opacity: 0.4;
    }
`;

export const ProcessCommentContentContainer = styled.div<{ last: boolean }>`
    padding-bottom: ${({ last }) => (last ? 0 : 2)}rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 80%;

    > p {
        margin: 0;
    }
`;

export const ProcessCommentContentInfoContainer = styled.div`
    font-size: 0.75rem;
    color: var(--text-light-secondary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 0.5rem;
    margin-bottom: 0.25rem;

    > p {
        width: max-content;
        margin: 0 !important;
    }
`;

export const ProcessCommentContentActionsContainer = styled(FormGroup)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
`;

export const ChipContainer = styled.section`
    display: flex;
`;

export const ProcessChipsContainer = styled.div<{ isManaging?: boolean }>`
    display: flex;
    flex-direction: ${({ isManaging }) => (isManaging ? 'column' : 'row')};
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.25rem;

    > div {
        color: var(--secondary-color);
        padding: 0.5rem;
        max-width: 100%;

        svg {
            top: unset;
            left: unset;
            position: relative;
            width: 0.8rem;
        }
    }
`;

export const ProcessCommentRepliesContainer = styled.div`
    padding-left: 2rem;

    div {
        position: relative;
    }

    svg {
        position: absolute;
        left: -1.5rem;
        top: 0.3rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 0.25rem;
    }
`;

export const SeeMoreButton = styled.button<{ loading?: boolean }>`
    background-color: transparent;
    border: none;
    color: ${({ loading }) => (loading ? 'rgba(49,126,197,0.4)' : 'var(--tertiary-color)')};
    cursor: ${({ loading }) => (loading ? 'wait' : 'pointer')};
    padding: 0;
    margin-bottom: 8px;
    text-decoration: underline;
`;

export const ReplyButton = styled(SeeMoreButton)``;

export const ManageButton = styled(SeeMoreButton)`
    margin-bottom: 0;
    margin-left: 8px;
`;
