import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, useFormik } from 'formik';
import { CONSTANTS } from 'common/constants';
import { Grid, Breadcrumbs, Button, Typography } from '@material-ui/core';
import { addNotificationError } from 'common/utils';
import { organizationUnitsRequests } from 'clients/manager/organization.requests';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { AuthorizationGroupUsersRequests } from 'modules/organization-users/services/authorization-group-users.requests';
import { UserAuthorizationGroup } from 'modules/organization-users/interfaces/authorization-group-users.interface';
import { Link } from 'react-router-dom';
import EditOrganizationUsersFormView from './edit-organization-users-form-view';
import {
    EditOrganizationUsersFormSchema,
    EditOrganizationUsersFormValues,
} from './edit-organization-users-form-schema';

export interface EditOrganizationUsersFormProps {
    onSubmit: (
        values: EditOrganizationUsersFormValues,
        formikHelpers: FormikHelpers<EditOrganizationUsersFormValues>
    ) => void | Promise<any>;
    cpf?: string;
    country?: string;
}

const EditOrganizationUsersForm: React.FC<EditOrganizationUsersFormProps> = ({
    onSubmit,
    cpf,
    country,
}) => {
    const { t } = useTranslation();

    const initialValues: EditOrganizationUsersFormValues = {
        id: undefined,
        cpf: '',
        name: undefined,
        countryCode: CONSTANTS.ibgeCodes.BRAZIL,
        authorizationProfile: 'unit',
        organization: {
            isAdmin: false,
            authorizationGroups: [],
        },
        organizationUnits: [],
    };

    const isEdit = !!cpf && !!country;

    const [authorizationGroups, setAuthorizationGroups] = useState<UserAuthorizationGroup[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const { data: organizationUnits, loading: organizationUnitsLoading } = useQueryWithBody(
        organizationUnitsRequests.listOrganizationUnit
    );

    const form = useFormik<EditOrganizationUsersFormValues>({
        initialValues,
        validationSchema: EditOrganizationUsersFormSchema(t),
        onSubmit,
    });

    const onBlurCPF = async (cpf: string) => {
        try {
            if (cpf) {
                setLoading(true);
                const { data } = await AuthorizationGroupUsersRequests.getByUser({
                    cpf: cpf.replace(/[.-]/g, ''),
                    countryCode: form.values.countryCode,
                });

                form.setFieldValue('id', data?.user?.id);
                form.setFieldValue('name', data?.user?.name);
                setAuthorizationGroups(data?.groups);
            }
        } catch {
            addNotificationError({
                title: t('organization-users.pages.components.user-not-exist'),
            });
            form.setFieldValue('id', undefined);
            form.setFieldValue('name', undefined);
            setAuthorizationGroups([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function findUser() {
            if (cpf && country && organizationUnits) {
                try {
                    setLoading(true);
                    const { data } = await AuthorizationGroupUsersRequests.getByUser({
                        cpf: cpf,
                        countryCode: country,
                    });

                    const defaultOrganizationUnits: EditOrganizationUsersFormValues['organizationUnits'] =
                        [];

                    data?.organizationUnits?.forEach((formOrganizationUnit) => {
                        const foundIndex = organizationUnits?.findIndex(
                            (organizationUnit) =>
                                String(organizationUnit?.id) ===
                                String(formOrganizationUnit?.organizationUnitId)
                        );

                        const authorizationGroups =
                            (formOrganizationUnit?.selectedAuthorizationGroups.map(
                                (selectedAuthorizationGroup) =>
                                    data?.groups.find(
                                        (group) => group.groupId === selectedAuthorizationGroup
                                    )
                            ) as any) ?? [];

                        if (foundIndex !== undefined && foundIndex !== -1)
                            defaultOrganizationUnits[foundIndex] = {
                                organizationUnitId: formOrganizationUnit?.organizationUnitId,
                                isAdmin: formOrganizationUnit?.isAdmin,
                                authorizationGroups,
                            };
                    });

                    setAuthorizationGroups(data?.groups);

                    form.setValues({
                        id: data?.user?.id,
                        name: data?.user?.name,
                        countryCode: country,
                        cpf: cpf,
                        authorizationProfile: data?.authorizationProfile,
                        organization: {
                            isAdmin: data?.organization?.isAdmin ?? false,
                            authorizationGroups:
                                (data?.organization?.selectedAuthorizationGroups.map(
                                    (selectedAuthorizationGroup) =>
                                        data?.groups.find(
                                            (group) => group.groupId === selectedAuthorizationGroup
                                        )
                                ) as any) ?? [],
                        },
                        organizationUnits: defaultOrganizationUnits,
                    });
                } catch {
                    form.setFieldValue('name', undefined);
                    setAuthorizationGroups([]);
                } finally {
                    setLoading(false);
                }
            }
        }
        findUser();
    }, [organizationUnits]);

    return (
        <>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Breadcrumbs color='primary'>
                    <Link to='/usuarios-organizacao' style={{ textDecoration: 'none' }}>
                        <Typography>
                            {t('organization-users.pages.components.users-organization')}
                        </Typography>
                    </Link>
                    <Typography>
                        {cpf && country
                            ? t('organization-users.pages.components.edit-user', {
                                  user: form?.values?.name,
                              })
                            : t('organization-users.pages.components.add-user')}
                    </Typography>
                </Breadcrumbs>

                <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    type='submit'
                    form='formEditOrganizationUser'
                    disabled={form.isSubmitting}
                >
                    {t('term.save')}
                </Button>
            </Grid>
            <br />
            <EditOrganizationUsersFormView
                {...{
                    form,
                    onBlurCPF,
                    authorizationGroups,
                    organizationUnits,
                    loading: loading || organizationUnitsLoading,
                    organizationUnitsLoading,
                    isEdit,
                }}
            />
        </>
    );
};

export default EditOrganizationUsersForm;
