import { styled } from 'common/theme/styled';

const Content = styled.div`
    display: flex;
    height: 67px;
    align-items: center;
    padding: 0 25px;

    &:not(:last-child) {
        border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    }
`;

const SelectArea = styled.div`
    display: flex;
    align-items: center;
    width: 50px;
`;

const Details = styled.div`
    justify-content: space-between;
    display: flex;
    flex: 1;
`;

const ProviderDetails = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.tertiary};
    }

    span:first-child {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    span:nth-child(2) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const Price = styled.div`
    display: flex;
    width: 14%;
    align-items: center;

    span {
        color: ${(props) => props.theme.colors.textLight};
        font-size: 14px;
    }
`;

const OriginPriceDetails = styled.div`
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.textLight};
    }

    span:nth-child(1) {
        font-weight: 600;
    }
`;

const BrandDetails = styled.div`
    display: flex;
    width: 17%;
    align-items: center;
    color: ${(props) => props.theme.colors.textLight};

    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.textLight};
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
`;

const ModelDetails = styled.div`
    display: flex;
    width: 20%;
    align-items: center;

    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.textLight};
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
`;

export {
    Content,
    Details,
    SelectArea,
    ProviderDetails,
    ModelDetails,
    BrandDetails,
    OriginPriceDetails,
    Price,
};
