import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Check } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { StyledAvatar } from '../../account-journey-style';

const AccountJourneyListItem = ({ primaryText, done = false, onClick, number }) => {
    const { t } = useTranslation();

    return (
        <ListItem style={{ cursor: 'pointer' }} onClick={onClick}>
            <ListItemAvatar>
                <StyledAvatar done={done}>{done ? <Check /> : number}</StyledAvatar>
            </ListItemAvatar>
            <ListItemText primary={t(primaryText)} />
        </ListItem>
    );
};

export default AccountJourneyListItem;
