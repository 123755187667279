import React, { useCallback } from 'react';
import { useMarketplaceContext } from 'modules/marketplace/context/marketplace.context';
import { ListMarketplaceItemsResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { Skeleton } from '@material-ui/lab';
import { OrderItemStatusEnum } from 'common/enums';
import MarketplaceItemView from './marketplace-item-card-view';
import { useStyles } from './marketplace-item-card-style';

interface MarketplaceItemCardViewProps {
    item?: ListMarketplaceItemsResponse;
    type?: 'cart' | 'order' | 'judgment';
    quantity?: number;
    loading?: boolean;
    isActionLoading?: boolean;
    orderItemStatus?: OrderItemStatusEnum;
    onAddToCart?: (id: number) => void;
    onRemoveFromCart?: (id: number) => void;
    onItemQuantityChange?: (value: { id: number; quantity: number }) => void;
    onDeclassify?: (value: { lotId: number; providerId: number }) => void;
    isDisqualifying?: boolean;
    isJudged?: boolean;
}

const MarketplaceItemCard: React.FC<MarketplaceItemCardViewProps> = ({
    item,
    type = 'cart',
    quantity,
    loading = false,
    isActionLoading = false,
    orderItemStatus,
    onAddToCart,
    onRemoveFromCart,
    onItemQuantityChange,
    onDeclassify,
    isDisqualifying = false,
    isJudged = false,
}) => {
    const styles = useStyles();
    const { marketplaceCart } = useMarketplaceContext();

    if (loading || !item) {
        return <Skeleton className={styles.skeleton} />;
    }

    const handleOnAddToCart = useCallback(() => {
        onAddToCart?.(item.id);
    }, []);

    const handleOnRemoveFromCart = useCallback(() => {
        onRemoveFromCart?.(item.id);
    }, []);

    const handleOnItemQuantityChange = useCallback((quantity: number) => {
        onItemQuantityChange?.({ id: item.id, quantity });
    }, []);

    const handleOnDeclassify = useCallback((providerId: number) => {
        const lotId = item?.lotId;
        if (!lotId) return;

        onDeclassify?.({ lotId, providerId });
    }, []);

    const cartItem = marketplaceCart?.items.find((cartItem) => cartItem?.item?.id === item.id);
    const hasOnCart = Boolean(cartItem);

    return (
        <MarketplaceItemView
            item={item}
            type={type}
            quantity={quantity}
            cartItem={cartItem}
            isActionLoading={isActionLoading}
            orderItemStatus={orderItemStatus}
            hasOnCart={hasOnCart}
            onAddToCart={handleOnAddToCart}
            onItemQuantityChange={handleOnItemQuantityChange}
            onRemoveFromCart={handleOnRemoveFromCart}
            onDeclassify={handleOnDeclassify}
            isDisqualifying={isDisqualifying}
            isJudged={isJudged}
        />
    );
};

export default MarketplaceItemCard;
