import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { AuctionTypeRule, MessageTypeRule } from './interfaces/auction-type-rules.interface';

const auctionTypeRulesRequests = {
    listAuctionTypeRules: async (ruleId?: string | undefined) =>
        doRequest<GenericResponse<AuctionTypeRule>>(
            managerApiInstance.post(`/auction-type-rules/listAuctionTypeRules`, {
                params: { ruleId },
            })
        ),
    getAuctionTypeRulesToMessages: async (ruleId: string) =>
        doRequest<PlainGenericResponse<MessageTypeRule>>(
            managerApiInstance.post(`/auction-type-rules/getAuctionTypeRulesToMessages`, {
                params: { ruleId },
            })
        ),
};

export { auctionTypeRulesRequests };
