import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { GenericResponse, PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { DocumentBlocks } from '../interfaces/document-blocks.interface';

const path = '/documents/blocks';

const documentBlocksRequests = {
    getBlock: (documentBlockId: string) =>
        doRequest<PlainGenericResponse<DocumentBlocks>>(
            managerApiInstance.post(`${path}/getBlock`, { documentBlockId })
        ),
    createBlock: async (documentBlock: Omit<DocumentBlocks, '_id'>) =>
        doRequest<PlainGenericResponse<DocumentBlocks>>(
            managerApiInstance.post(`${path}/createBlock`, documentBlock)
        ),
    updateBlock: async (documentBlockId: string, documentBlock: DocumentBlocks) =>
        doRequest<PlainGenericResponse<DocumentBlocks>>(
            managerApiInstance.post(`${path}/updateBlock`, {
                ...documentBlock,
                _id: documentBlockId,
            })
        ),
    listBlocks: async () =>
        doRequest<GenericResponse<DocumentBlocks>>(managerApiInstance.post(`${path}/listBlocks`)),
    uploadImage: async (file: File) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);

        return doRequest<PlainGenericResponse<{ url: string }>>(
            managerApiUploadFileInstance.post(`${path}/uploadFileOnBlock`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        );
    },
};

export { documentBlocksRequests };
