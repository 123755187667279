import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles(() => ({
    divider: {
        marginTop: formatPxToRem(20),
        marginBottom: formatPxToRem(20),
    },
    divider2: {
        marginTop: formatPxToRem(10),
        marginBottom: formatPxToRem(20),
    },
    showMoreContainer: {
        display: 'flex',
        width: '100%',
        marginBottom: 0,
    },
}));

export { useStyles };
