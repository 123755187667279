import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import {
    HiringPlanItemUnitRequest,
    HiringPlanResponse,
} from 'clients/manager/interfaces/hiring-plans.interfaces';
import { DatePicker } from '@material-ui/pickers';

interface DialogNewEditItemPcaViewProps {
    form: FormikProps<HiringPlanItemUnitRequest>;
    pcaId: number;
    submitForm: () => void;
    pcaIsSynchronized: boolean;
    isLoadingSubmitForm: boolean;
    setFecharFormSalvar: React.Dispatch<React.SetStateAction<boolean>>;
    searchDetailsPcaUnit: (idPca: number) => HiringPlanResponse | undefined;
}

const DialogNewEditItemPcaView: React.FC<DialogNewEditItemPcaViewProps> = (props) => {
    const {
        form,
        pcaId,
        submitForm,
        pcaIsSynchronized,
        isLoadingSubmitForm,
        setFecharFormSalvar,
        searchDetailsPcaUnit,
    } = props;
    const { t } = useTranslation();
    const {
        itemEditId,
        listItemCatalog,
        listItemCategories,
        isOpenDialogCrudItemPca,
        onCloseDialogCrudItemPca,
        listItemCatalogClassification,
    } = useHiringPlansContext();
    const pcaDetails = searchDetailsPcaUnit(pcaId);

    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth='md'
            aria-labelledby='create-new-pca-dialog'
            aria-describedby='create-new-pca-dialog-description'
            open={isOpenDialogCrudItemPca}
            onClose={onCloseDialogCrudItemPca}
        >
            <DialogTitle
                id='create-new-pca-dialog-title'
                style={{ backgroundColor: 'var(--primary-color)' }}
            >
                <Typography variant='h6' color='textSecondary'>
                    {!itemEditId
                        ? t('hiring-plans.title.create-pca-unit-item', {
                              year: pcaDetails?.year || '',
                              organization:
                                  pcaDetails?.organizationUnits.organizationUnitName || '',
                          })
                        : t('hiring-plans.title.edit-pca-unit-item', {
                              year: pcaDetails?.year || '',
                              organization:
                                  pcaDetails?.organizationUnits.organizationUnitName || '',
                          })}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box width='768px' paddingY={2}>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    type='text'
                                    variant='outlined'
                                    name='description'
                                    label={t('hiring-plans.labels.description')}
                                    value={form.values.description}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.description) &&
                                        Boolean(form.errors.description)
                                    }
                                    helperText={
                                        Boolean(form.values.description) && form.errors.description
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    variant='outlined'
                                    name='pcaItemCategory'
                                    label={t('hiring-plans.labels.pca-item-category')}
                                    value={form.values.pcaItemCategory}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.pcaItemCategory) &&
                                        Boolean(form.errors.pcaItemCategory)
                                    }
                                    helperText={
                                        Boolean(form.values.pcaItemCategory) &&
                                        form.errors.pcaItemCategory
                                    }
                                >
                                    {listItemCategories.map((category) => (
                                        <MenuItem key={category.id} value={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    type='number'
                                    variant='outlined'
                                    name='quantity'
                                    label={t('hiring-plans.labels.quantity')}
                                    value={form.values.quantity}
                                    onChange={form.handleChange}
                                    InputProps={{ inputMode: 'numeric' }}
                                    error={
                                        Boolean(
                                            !form.values.quantity || form.values.quantity < 1
                                        ) && Boolean(form.errors.quantity)
                                    }
                                    helperText={
                                        Boolean(
                                            !form.values.quantity || form.values.quantity < 1
                                        ) && form.errors.quantity
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    fullWidth
                                    type='number'
                                    disabled={isLoadingSubmitForm}
                                    variant='outlined'
                                    name='unitValue'
                                    label={t('hiring-plans.labels.unit-value')}
                                    value={form.values.unitValue}
                                    onChange={form.handleChange}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Typography variant='button' color='primary'>
                                                    R$
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={
                                        Boolean(
                                            !form.values.unitValue || form.values.unitValue < 0
                                        ) && Boolean(form.errors.unitValue)
                                    }
                                    helperText={
                                        Boolean(
                                            !form.values.unitValue || form.values.unitValue < 0
                                        ) && form.errors.unitValue
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    disabled
                                    fullWidth
                                    type='number'
                                    variant='outlined'
                                    name='totalValue'
                                    label={t('hiring-plans.labels.total-value')}
                                    value={form.values.totalValue}
                                    onChange={form.handleChange}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Typography variant='button' color='primary'>
                                                    R$
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={
                                        Boolean(form.values.totalValue) &&
                                        Boolean(form.errors.totalValue)
                                    }
                                    helperText={
                                        Boolean(form.values.totalValue) && form.errors.totalValue
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    type='text'
                                    variant='outlined'
                                    name='providerUnit'
                                    label={t('hiring-plans.labels.supply-unit')}
                                    value={form.values.providerUnit}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.providerUnit) &&
                                        Boolean(form.errors.providerUnit)
                                    }
                                    helperText={
                                        Boolean(form.values.providerUnit) &&
                                        form.errors.providerUnit
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    type='text'
                                    variant='outlined'
                                    name='requestUnit'
                                    label={t('hiring-plans.labels.requesting-unit')}
                                    value={form.values.requestUnit}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.requestUnit) &&
                                        Boolean(form.errors.requestUnit)
                                    }
                                    helperText={
                                        Boolean(form.values.requestUnit) && form.errors.requestUnit
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    required
                                    fullWidth
                                    type='number'
                                    disabled={isLoadingSubmitForm}
                                    variant='outlined'
                                    name='budgetValueAssignment'
                                    label={t('hiring-plans.labels.budget-value')}
                                    value={form.values.budgetValueAssignment}
                                    onChange={form.handleChange}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Typography variant='button' color='primary'>
                                                    R$
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={
                                        Boolean(form.values.budgetValueAssignment) &&
                                        Boolean(form.errors.budgetValueAssignment)
                                    }
                                    helperText={
                                        Boolean(form.values.budgetValueAssignment) &&
                                        form.errors.budgetValueAssignment
                                    }
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <DatePicker
                                    fullWidth
                                    required
                                    format='DD/MM/yyyy'
                                    disabled={isLoadingSubmitForm}
                                    key='date-picker-desired-date'
                                    color='primary'
                                    inputVariant='outlined'
                                    name='expectedDate'
                                    label={t('hiring-plans.labels.desired-date')}
                                    value={form.values.expectedDate}
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'expectedDate',
                                            value && value.format('yyyy-MM-DD')
                                        );
                                    }}
                                    error={
                                        Boolean(form.values.expectedDate) &&
                                        Boolean(form.errors.expectedDate)
                                    }
                                    helperText={
                                        Boolean(form.values.expectedDate) &&
                                        form.errors.expectedDate
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    variant='outlined'
                                    name='catalog'
                                    label={t('hiring-plans.labels.catalog')}
                                    value={form.values.catalog}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.catalog) && Boolean(form.errors.catalog)
                                    }
                                    helperText={Boolean(form.values.catalog) && form.errors.catalog}
                                >
                                    {listItemCatalog.map((catalog) => (
                                        <MenuItem key={catalog.id} value={catalog.id}>
                                            {catalog.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    variant='outlined'
                                    name='catalogClassification'
                                    label={t('hiring-plans.labels.catalog-classification')}
                                    value={form.values.catalogClassification}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.catalogClassification) &&
                                        Boolean(form.errors.catalogClassification)
                                    }
                                    helperText={
                                        Boolean(form.values.catalogClassification) &&
                                        form.errors.catalogClassification
                                    }
                                >
                                    {listItemCatalogClassification.map((catalog) => (
                                        <MenuItem key={catalog.id} value={catalog.id}>
                                            {catalog.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    type='text'
                                    variant='outlined'
                                    name='superiorClassificationCode'
                                    label={t('hiring-plans.labels.top-rating-code')}
                                    value={form.values.superiorClassificationCode}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.superiorClassificationCode) &&
                                        Boolean(form.errors.superiorClassificationCode)
                                    }
                                    helperText={
                                        Boolean(form.values.superiorClassificationCode) &&
                                        form.errors.superiorClassificationCode
                                    }
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    type='text'
                                    variant='outlined'
                                    name='superiorClassificationName'
                                    label={t('hiring-plans.labels.description-top-rating')}
                                    value={form.values.superiorClassificationName}
                                    onChange={form.handleChange}
                                    error={
                                        Boolean(form.values.superiorClassificationName) &&
                                        Boolean(form.errors.superiorClassificationName)
                                    }
                                    helperText={
                                        Boolean(form.values.superiorClassificationName) &&
                                        form.errors.superiorClassificationName
                                    }
                                />
                            </Grid>
                            {itemEditId && pcaIsSynchronized && (
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        disabled={isLoadingSubmitForm}
                                        type='text'
                                        variant='outlined'
                                        name='justification'
                                        label={t('hiring-plans.labels.justification')}
                                        value={form.values.justification}
                                        onChange={form.handleChange}
                                        error={
                                            Boolean(form.values.justification) &&
                                            Boolean(form.errors.justification)
                                        }
                                        helperText={
                                            Boolean(form.values.justification) &&
                                            form.errors.justification
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    disabled={isLoadingSubmitForm}
                    color='default'
                    size='medium'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={() => {
                        form.resetForm();
                        onCloseDialogCrudItemPca();
                    }}
                >
                    {t('term.cancel')}
                </Button>
                <Button
                    disabled={isLoadingSubmitForm || !form.isValid}
                    color='primary'
                    size='medium'
                    variant='contained'
                    style={{
                        minWidth: '128px',
                        height: '100%',
                    }}
                    onClick={() => {
                        setFecharFormSalvar(true);
                        submitForm();
                    }}
                >
                    {!isLoadingSubmitForm ? (
                        t('term.save')
                    ) : (
                        <CircularProgress color='primary' size={25} />
                    )}
                </Button>
                <Button
                    disabled={isLoadingSubmitForm || !form.isValid}
                    color='primary'
                    size='medium'
                    variant='contained'
                    style={{
                        minWidth: '128px',
                        height: '100%',
                    }}
                    onClick={() => form.handleSubmit()}
                >
                    {!isLoadingSubmitForm ? (
                        t('hiring-plans.labels.save-continue')
                    ) : (
                        <CircularProgress color='primary' size={25} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogNewEditItemPcaView;
