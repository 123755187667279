import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import {
    DoSyncFileBody,
    DoSyncFileResponse,
} from './interfaces/pncp.interface';
import { Contract } from './interfaces/contract.interface';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';

const path = 'integration/pncp';

const pncpRequests = {
    doSyncFile: async (body: DoSyncFileBody, config?: AxiosRequestConfig<any>) =>
        doRequest<DoSyncFileResponse>(managerApiInstance.post(`/${path}/doSyncFile`, body, config)),
    deleteContract: async (contractId: string, justification: string) =>
        doRequest<PlainGenericResponse<Contract>>(
            managerApiInstance.delete(`/${path}/contract/${contractId}`, {
                data: { justification },
            })
        ),
    deleteAta: async (contractId: string, justification: string) =>
        doRequest<PlainGenericResponse<Contract>>(
            managerApiInstance.delete(`/${path}/ata/${contractId}`, {
                data: { justification },
            })
        ),
};

export { pncpRequests };
