import { useEffect, useState, useRef, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, makeStyles, Paper, Tab, Tabs, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import { providerRequests } from 'clients/manager/provider.requests';
import ProviderDocuments from 'common/components/provider-documents';
import { useProviderUpdateRequestWarningContext } from 'common/components/provider-update-request-warning/context';
import SegmentsSelector from 'common/components/segments-selector';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    hasPermissionToExecute,
} from 'common/utils';
import { Formik } from 'formik';
import LegalRepresentative from 'modules/sign-up/pages/sign-up-stepper-provider/Steps/legal-representative';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
    HiOutlineCurrencyDollar,
    HiOutlineLibrary,
    HiOutlineOfficeBuilding,
    HiOutlinePaperClip,
    HiOutlineUserGroup,
    HiOutlineViewGridAdd,
} from 'react-icons/hi';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useParams } from 'react-router';
import * as yup from 'yup';
import ProviderFinancialData from 'common/components/provider-financial-data';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { TypeAccess } from 'common/interfaces/user.interface';
import moment from 'moment';
import { FinancialProviderDataRequests } from 'clients/manager/financial-provider-data.requests';
import { FinancialProviderData } from 'clients/manager/interfaces/financial-provider-data.interface';
import { usePlatformContext } from 'routes/platform.context';
import { Settings } from '@material-ui/icons';
import ProviderBankData from 'common/components/provider-bank-data';
import { globalConstants } from 'common/constants/global.constant';
import { CONSTANTS } from 'common/constants';
import { StatusCrc } from 'common/enums';
import CodeValidator from 'common/components/code-validator';
import CompanyForm from '../company-form';
import { CompanyTabsProps } from './props';
import AdministrationForm from '../administration-form';

interface RedirectState {
    info: string;
}

const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const CustomTab = withStyles((theme: Theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(15),
    },
}))(Tab);

const CompanyTabs: FC<CompanyTabsProps> = ({ reload, setHasUpdate }) => {
    const { platform } = usePlatformContext();
    const controlIndexPainel = platform?.generalSettings.general.showFinancialDataSignup ? 1 : 0;
    const TABS = {
        'dados-gerais': 0,
        'dados-bancarios': controlIndexPainel + 1,
        'representantes-legais': controlIndexPainel + 2,
        segmentos: controlIndexPainel + 3,
        documentos: controlIndexPainel + 4,
    };

    const classes = useStyles();
    const { t } = useTranslation();
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const location = useLocation();
    const { defaultTab, idProvider } = useParams<{
        defaultTab: string;
        idProvider: string;
    }>();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const [provider, setProvider] = useState<Provider>();
    const [financialData, setFinancialData] = useState<FinancialProviderData>();
    const initialTabRef = useRef<number>(TABS[defaultTab] || 0);
    const [selectedTab, setSelectedTab] = useState(initialTabRef.current);
    const [updatingProvider, setUpdatingProvider] = useState<boolean>(false);
    const [openCodeValidator, setOpenCodeValidator] = useState(false);

    const isAdmin = authUserTokenData?.typeAccess === TypeAccess.management;
    const hasPermissionToEdit = !isAdmin || hasPermissionToExecute('alterarFornecedores');
    const hasPermissionToEditAdminTab =
        isAdmin && hasPermissionToExecute('validarDocumentosDosFornecedores');
    const hasPermissionToViewDocuments = isAdmin
        ? hasPermissionToExecute('verDocumentosDosFornecedores')
        : true;

    useEffect(() => {
        const infoFromPreviousPage = (location.state as RedirectState)?.info;
        setSelectedTab(
            infoFromPreviousPage === undefined ? TABS['dados-gerais'] : TABS[infoFromPreviousPage]
        );
    }, [location.state]);

    const handleChange = (_: React.ChangeEvent<{}>, tab: number) => {
        setSelectedTab(tab);
    };

    const convertFormatDate = (value) => {
        const formatedString = moment(value, 'YYYY-MM-DD').format();
        return moment(formatedString, 'YYYY-MM-DD').toDate();
    };

    const validationSchema = yup.object({
        tradingName: yup.string().required(t('required-field', { ns: 'validation' })),
        zipCode: yup
            .string()
            .required(t('please-fill-in-the-cep', { ns: 'validation' }))
            .max(9, t('cep-min-number-character', { ns: 'validation' })),
        publicPlace: yup.string().required(t('address', { ns: 'validation' })),
        numberPlace: yup.string().required(t('number', { ns: 'validation' })),
        district: yup.string().required(t('district', { ns: 'validation' })),
        state: yup.string().required(t('state', { ns: 'validation' })),
        cityName: yup.string().required(t('city', { ns: 'validation' })),
        emailForBilling: yup
            .string()
            .email(t('please-enter-a-valid-email', { ns: 'validation' }))
            .nullable(),
        emailForNotifications: yup
            .string()
            .email(t('please-enter-a-valid-email', { ns: 'validation' }))
            .nullable(),
        phoneNumber: yup
            .string()
            .nullable()
            .min(10, t('please-enter-a-valid-phone', { ns: 'validation' })),
        billingPhoneNumber: yup
            .string()
            .nullable()
            .min(10, t('please-enter-a-valid-phone', { ns: 'validation' })),
        netWorth: yup.number().nullable(),
        workingCapital: yup.number().nullable(),
        stateCompanyRegistrationNumber: yup
            .string()
            .nullable()
            .when('docType', {
                is: (docType) => docType === 'J',
                then: yup.string().required(t('required-field', { ns: 'validation' })),
                otherwise: yup.string().nullable(),
            }),
        docNumberMei: yup
            .string()
            .nullable()
            .when('itsSimpleCompany', {
                is: (itsSimpleCompany) => itsSimpleCompany == 2,
                then: yup.string().required(t('required-field', { ns: 'validation' })),
                otherwise: yup.string().nullable(),
            }),
        signupValidExpiration: yup
            .string()
            .nullable()
            .test('valid-date', t('info.invalid-date', { ns: 'validation' }), (value) => {
                if (authUserTokenData?.typeAccess === TypeAccess.provider) return true;
                if (!value) return true;
                return moment(value).isValid();
            }),
        suspendedUntil: yup.string().when('signupValid', {
            is: StatusCrc.suspended,
            then: yup
                .string()
                .test('valid-date', t('info.invalid-date', { ns: 'validation' }), (value) => {
                    if (authUserTokenData?.typeAccess === TypeAccess.provider) return true;
                    if (!value) return true;
                    return moment(value).isValid();
                }),
            otherwise: yup.string().nullable(),
        }),

        docType: yup.string().required(t('required-field', { ns: 'validation' })),
        docNumber: yup.string().when(['country', 'docType'], {
            is: (country: string) => country === globalConstants.ibgeCodes.BRAZIL,
            then: yup.string().when('docType', {
                is: 'J',
                then: yup
                    .string()
                    .test('valid-cnpj', t('provider.cnpj-invalid', { ns: 'validation' }), (value) =>
                        /^[0-9]{14}$/.test(value || '')
                    ),
                otherwise: yup
                    .string()
                    .test('valid-cpf', t('provider.cpf-invalid', { ns: 'validation' }), (value) =>
                        /^[0-9]{11}$/.test(value || '')
                    ),
            }),
            otherwise: yup.string(),
        }),
    });

    const getProvider = async () => {
        try {
            let response;

            if (idProvider)
                response = await providerRequests.getProvider({ id: Number(idProvider) });
            else response = await providerRequests.getProvider();

            const { data } = await FinancialProviderDataRequests.getFinancialProviderData({
                providerId: response.id,
            });
            setFinancialData(data);
            setProvider(response);
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    useEffect(() => {
        getProvider();
    }, [reload]);

    const isBrazillian = provider?.country === CONSTANTS.ibgeCodes.BRAZIL;
    const initialValues = {
        zipCode: provider?.zipCode,
        publicPlace: provider?.publicPlace,
        numberPlace: provider?.numberPlace,
        complementPlace: provider?.complementPlace,
        district: provider?.district,
        state: provider?.state,
        country: provider?.country ?? CONSTANTS.ibgeCodes.BRAZIL,
        externalId: provider?.externalId,
        cityName: provider?.cityName,
        docNumber: provider?.docNumber,
        docType: provider?.docType,
        tradingName: provider?.tradingName,
        companyName: provider?.companyName,
        emailForBilling: provider?.emailForBilling,
        emailForNotifications: provider?.emailForNotifications,
        signupValid: provider?.signupValid,
        socialSecurityCode: provider?.socialSecurityCode,
        signupValidExpiration: provider?.signupValidExpiration,
        suspendedUntil: provider?.suspendedUntil,
        situation: provider?.situation,
        isDefaulting: provider?.isDefaulting,
        billingPhoneNumber: `+${provider?.billingPhoneCountryCode}${provider?.billingPhoneNumber}`,
        phoneNumber: `+${provider?.phoneCountryCode}${provider?.phoneNumber}`,
        stateCompanyRegistrationNumber:
            isBrazillian || provider?.stateCompanyRegistrationNumber
                ? provider?.stateCompanyRegistrationNumber
                : 'Isento',
        birthdate: provider?.birthdate,
        gender: provider?.gender,
        birthplace: provider?.birthplace,
        intructionLevel: provider?.intructionLevel,
        profession: provider?.profession,
        itsSimpleCompany: provider?.itsSimpleCompany,
        docNumberMei: provider?.itsSimpleCompany == 2 ? provider?.docNumberMei : null,
        ibgeCode: provider?.ibgeCode ? provider?.ibgeCode : null,
        verificationCode: '',
        isNotificationsEnabled: !!provider?.isNotificationsEnabled,
    };

    const updateProvider = async (providerForm: Partial<Provider>) => {
        if (!provider) {
            return;
        }

        if (providerForm.emailForNotifications !== provider?.emailForNotifications) {
            if (!(providerForm as typeof initialValues).verificationCode) {
                setOpenCodeValidator(true);
                return;
            }
        }

        let parsedPhoneNumber;
        let parsedBillingPhoneNumber;
        if (providerForm?.phoneNumber && providerForm?.billingPhoneNumber) {
            parsedPhoneNumber = parsePhoneNumber(providerForm.phoneNumber);
            parsedBillingPhoneNumber = parsePhoneNumber(providerForm.billingPhoneNumber);
        }

        setUpdatingProvider(true);

        try {
            if (authUserTokenData?.typeAccess === TypeAccess.management) {
                const signupValidExpiration =
                    providerForm.signupValid === StatusCrc.suspended
                        ? null
                        : providerForm?.signupValidExpiration;
                const suspendedUntil =
                    providerForm.signupValid === StatusCrc.suspended
                        ? providerForm?.suspendedUntil
                        : null;

                const result = await providerRequests.doProviderUpdateByAdmin({
                    ...provider,
                    ...providerForm,
                    signupValidExpiration: signupValidExpiration
                        ? convertFormatDate(signupValidExpiration).toISOString()
                        : null,
                    suspendedUntil: suspendedUntil
                        ? convertFormatDate(suspendedUntil).toISOString()
                        : null,
                    phoneNumber: parsedPhoneNumber && parsedPhoneNumber.nationalNumber,
                    billingPhoneNumber:
                        parsedBillingPhoneNumber && parsedBillingPhoneNumber.nationalNumber,
                    phoneCountryCode: parsedPhoneNumber && parsedPhoneNumber.countryCallingCode,
                    billingPhoneCountryCode:
                        parsedBillingPhoneNumber && parsedBillingPhoneNumber.countryCallingCode,
                });

                ReactDOM.unstable_batchedUpdates(() => {
                    addNotificationSuccess({
                        title: 'Informações Atualizadas',
                        message: t('info.admin-provider-request-update'),
                        duration: 6000,
                    });
                    setUpdatingProvider(false);
                });
                return result;
            }

            const result = await providerRequests.doRequestProviderUpdate({
                ...provider,
                ...providerForm,
                socialProvider: {
                    docNumberMei: providerForm.docNumberMei || provider.docNumberMei,
                    socialSecurityCode:
                        providerForm.socialSecurityCode || provider.socialSecurityCode,
                    birthdate: providerForm.birthdate || provider.birthdate,
                    birthplace: providerForm.birthplace || provider.birthplace,
                    profession: providerForm.profession || provider.profession,
                    intructionLevel: providerForm.intructionLevel || provider.intructionLevel,
                    gender: providerForm.gender || provider.gender,
                },
                ibgeCode: providerForm.ibgeCode ? parseInt(providerForm.ibgeCode, 10) : null,
                phoneNumber: parsedPhoneNumber && parsedPhoneNumber.nationalNumber,
                billingPhoneNumber:
                    parsedBillingPhoneNumber && parsedBillingPhoneNumber.nationalNumber,
                phoneCountryCode: parsedPhoneNumber && parsedPhoneNumber.countryCallingCode,
                billingPhoneCountryCode:
                    parsedBillingPhoneNumber && parsedBillingPhoneNumber.countryCallingCode,
                stateCompanyRegistrationNumber:
                    providerForm.country !== CONSTANTS.ibgeCodes.BRAZIL
                        ? 'Isento'
                        : providerForm.stateCompanyRegistrationNumber,
            });

            if (!result.exists) {
                addNotificationError(t('info.provider-not-founded'));
                setUpdatingProvider(false);
                return false;
            }

            ReactDOM.unstable_batchedUpdates(() => {
                addNotificationSuccess({
                    title: 'Solicitação enviada',
                    message: t('info.provider-request-update'),
                    duration: 6000,
                });
                setForceHasChanges(true);
                setUpdatingProvider(false);
            });

            return result;
        } catch (err) {
            addNotificationApiError(err);
            setUpdatingProvider(false);
        }
    };

    return (
        <Box mt={2}>
            <Paper square className={classes.root}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    indicatorColor='secondary'
                    textColor='secondary'
                >
                    <CustomTab
                        label={t('term.general-data')}
                        icon={<HiOutlineOfficeBuilding />}
                        {...a11yProps(0)}
                    />
                    {platform?.generalSettings.general.showFinancialDataSignup && (
                        <CustomTab
                            disabled={!platform?.generalSettings.general.showFinancialDataSignup}
                            label={t('info.financial-data')}
                            icon={<HiOutlineCurrencyDollar />}
                            {...a11yProps(controlIndexPainel)}
                        />
                    )}
                    <CustomTab
                        label={t('info.bank-data')}
                        icon={<HiOutlineLibrary />}
                        {...a11yProps(controlIndexPainel + 1)}
                    />
                    <CustomTab
                        label={t('term.legal-representative')}
                        icon={<HiOutlineUserGroup />}
                        {...a11yProps(controlIndexPainel + 2)}
                    />
                    <CustomTab
                        label={t('term.segments')}
                        icon={<HiOutlineViewGridAdd />}
                        {...a11yProps(controlIndexPainel + 3)}
                    />
                    <CustomTab
                        label={t('term.documents')}
                        icon={<HiOutlinePaperClip />}
                        disabled={!hasPermissionToViewDocuments}
                        {...a11yProps(controlIndexPainel + 4)}
                    />
                    {isAdmin && (
                        <CustomTab
                            label={t('term.administration')}
                            icon={<Settings />}
                            {...a11yProps(controlIndexPainel + 5)}
                        />
                    )}
                </Tabs>
                <Formik
                    {...{
                        initialValues,
                        validationSchema,
                        enableReinitialize: true,
                        onSubmit: (values) => {
                            updateProvider(values as any);
                        },
                    }}
                >
                    {(form) => (
                        <>
                            <CodeValidator
                                email={form.values.emailForNotifications}
                                open={openCodeValidator}
                                onSubmit={async (values) => {
                                    await form.setFieldValue(
                                        'verificationCode',
                                        values.verificationCode,
                                        false
                                    );
                                    setOpenCodeValidator(false);
                                    form.handleSubmit();
                                }}
                                onClose={() => setOpenCodeValidator(false)}
                            />
                            <TabPanel value={selectedTab} index={0}>
                                <CompanyForm
                                    updatingProvider={updatingProvider}
                                    provider={provider}
                                    disabled={!hasPermissionToEdit}
                                />
                            </TabPanel>
                            {platform?.generalSettings.general.showFinancialDataSignup && (
                                <TabPanel value={selectedTab} index={controlIndexPainel}>
                                    <ProviderFinancialData
                                        financialData={financialData && financialData[0]}
                                        providerId={provider?.id || 0}
                                        setHasUpdate={setHasUpdate}
                                        disabled={!hasPermissionToEdit}
                                    />
                                </TabPanel>
                            )}
                            <TabPanel value={selectedTab} index={controlIndexPainel + 1}>
                                <ProviderBankData
                                    idProvider={Number(idProvider)}
                                    disabled={!hasPermissionToEdit}
                                />
                            </TabPanel>
                            <TabPanel value={selectedTab} index={controlIndexPainel + 2}>
                                <LegalRepresentative
                                    idProvider={Number(idProvider)}
                                    disabled={!hasPermissionToEdit}
                                />
                            </TabPanel>
                            <TabPanel value={selectedTab} index={controlIndexPainel + 3}>
                                <SegmentsSelector disabled={!hasPermissionToEdit} />
                            </TabPanel>
                            <TabPanel value={selectedTab} index={controlIndexPainel + 4}>
                                <ProviderDocuments
                                    disabled={!hasPermissionToEdit || !hasPermissionToViewDocuments}
                                />
                            </TabPanel>
                            {isAdmin && (
                                <TabPanel value={selectedTab} index={controlIndexPainel + 5}>
                                    <AdministrationForm
                                        updatingProvider={updatingProvider}
                                        provider={provider}
                                        disabled={!hasPermissionToEditAdminTab}
                                    />
                                </TabPanel>
                            )}
                        </>
                    )}
                </Formik>
            </Paper>
        </Box>
    );
};

export default CompanyTabs;
