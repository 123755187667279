import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import {
    DocumentTemplate,
    DocumentTemplateType,
    ListTemplatesFilters,
} from 'clients/manager/interfaces/document-templates.interface';
import { addNotificationWarning } from 'common/utils';
import { SelectDocumentTemplateProps } from './props';
import SelectDocumentTemplateView from './select-document-template-view';

export interface SelectDocumentTemplateFilters {
    type: DocumentTemplateType;
}

const SelectDocumentTemplate: FC<SelectDocumentTemplateProps> = ({
    onDocumentTemplateSelected,
    disabled = false,
    filters,
    initialValue,
}) => {
    const { t } = useTranslation();
    const [documentTemplates, setDocumentTemplates] = useState<DocumentTemplate[]>([]);

    const getTemplates = async () => {
        try {
            const templateFilters: ListTemplatesFilters = {};

            if (filters) {
                if (!templateFilters.params) {
                    templateFilters.params = {};
                }

                templateFilters.params.type = filters.type;
            }

            const response = await templateRequests.getTemplates(templateFilters);
            setDocumentTemplates(response?.data ?? []);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('editor.process.components.template-selector.error-onload-templates'),
            });
        }
    };

    useEffect(() => {
        if (disabled) {
            return;
        }

        getTemplates();
    }, [filters?.type]);

    const handleChange = (value: DocumentTemplate | null) => {
        if (!value) {
            return;
        }

        const { _id: documentTemplateId } = value;
        const template = documentTemplates.find(
            (documentTemplate) => documentTemplate._id === documentTemplateId
        );
        onDocumentTemplateSelected(template);
    };

    const getInitialValue = () => {
        if (initialValue) {
            return documentTemplates.find((template) => template._id === initialValue);
        }

        return undefined;
    };

    return (
        <SelectDocumentTemplateView
            disabled={disabled}
            documentTemplates={documentTemplates}
            getInitialValue={getInitialValue}
            handleChange={handleChange}
            t={t}
        />
    );
};

export default SelectDocumentTemplate;
