import { Card } from '@licitar/ui-kit';
import { IoAdd } from 'react-icons/io5';
import { styled } from 'common/theme/styled';

const AddIcon = styled(IoAdd)`
    font-size: 34px;
    color: ${(props) => props.theme.colors.textLight};
`;

const CardPriceListAdd = styled(Card)`
    cursor: pointer;
    display: flex;
    min-height: 110px;
    align-items: center;
    justify-content: center;
`;

const PriceListCards = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export { AddIcon, PriceListCards, CardPriceListAdd, Header };
