import { FC, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import ReactDOM from 'react-dom';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { userRequests } from '../../../../clients/manager/user.requests';
import { User } from '../../../../common/interfaces/user.interface';

interface SelectOrganizationUserProps {
    onChange: (requesterUserId: number) => void;
    label: string;
    value?: number;
    users?: User[];
}

const SelectOrganizationUser: FC<SelectOrganizationUserProps> = ({
    onChange,
    label,
    value,
    users: defaultUsers,
}) => {
    const [users, setUsers] = useState<User[]>(defaultUsers ?? []);
    const [fetchingUsers, setFetchingUsers] = useState(!defaultUsers?.length);
    const { processForm, process } = useProcessFormContext();

    const getOrganizationUsers = async (organizationUnitId: number) => {
        try {
            const response = await userRequests.listUsersBelongToOrganizationUnit({
                params: { organizationUnitId },
            });
            ReactDOM.unstable_batchedUpdates(() => {
                setUsers(response.data ?? []);
                setFetchingUsers(false);
            });
        } catch (error) {
            setFetchingUsers(false);
        }
    };

    useEffect(() => {
        if (
            !users?.length &&
            (processForm?.values?.organizationUnitId || process?.organizationUnitId)
        ) {
            const unitId = processForm?.values?.organizationUnitId || process?.organizationUnitId;
            getOrganizationUsers(unitId as number);
        }
    }, [processForm?.values?.organizationUnitId]);

    useEffect(() => {
        if (defaultUsers) {
            setUsers(defaultUsers);
        }
    }, [defaultUsers]);

    const handleChangeRequester = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(parseInt(event.target.value, 10));
    };

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            label={label}
            onChange={handleChangeRequester}
            variant='outlined'
            select
            disabled={fetchingUsers}
            value={value}
            defaultValue={value}
            key={value}
        >
            {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                    {user.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SelectOrganizationUser;
