import { styled } from 'common/theme/styled';

const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    .tox-editor-header {
        width: 100%;
    }

    .tox-tinymce {
        width: 100%;
        border: 0;
        box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
    }
`;

export { Content };
