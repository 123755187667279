import { getAccessTypeToEnvironment } from 'common/utils';
import { AdminProfileType, TypeAccess, TypeUser, User } from 'common/interfaces/user.interface';
import { getAuthUserToken } from 'modules/auth/session.utils';
import { UserReducerActions, UserActionTypes } from './user.types';

export interface JwtContent {
    userId: number;
    typeAccess: string;
    providerId?: number;
    organizationId: number;
    organizationUnitId: number;
    // gerado no front para facilitar
    isSystemAdmin?: boolean;
}

export interface IReduxAuthUser {
    authUser: User | undefined;
    authUserTokenData: JwtContent | undefined;
    currentAccessType: TypeUser | undefined;
}

const initialState: IReduxAuthUser = {
    authUser: undefined,
    authUserTokenData: undefined,
    currentAccessType: undefined,
};

export const authUserReducer = (
    state: IReduxAuthUser = initialState,
    action: UserReducerActions
): IReduxAuthUser => {
    switch (action.type) {
        case UserActionTypes.SET_AUTH_USER: {
            const { payload } = action;

            const tokenData = getAuthUserToken() as JwtContent;

            return {
                ...state,
                authUser: {
                    ...payload.user,
                },
                authUserTokenData: {
                    ...tokenData,
                    isSystemAdmin:
                        tokenData?.typeAccess === TypeAccess.management ||
                        payload.user.adminProfile !== AdminProfileType.default,
                },
                currentAccessType: getAccessTypeToEnvironment(tokenData?.typeAccess as TypeAccess),
            };
        }

        case UserActionTypes.REMOVE_AUTH_USER: {
            return {
                ...state,
                authUser: undefined,
                authUserTokenData: undefined,
                currentAccessType: undefined,
            };
        }

        case UserActionTypes.SET_AUTH_USER_CURRENT_ACCESS_TYPE: {
            const { payload } = action;

            return {
                ...state,
                currentAccessType: payload.accessType,
            };
        }

        default:
            return state;
    }
};
