import { PlanTitleEnum } from 'clients/manager/plan.request';
import { SelectedStepsObject, useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import PaperFooterView from './PaperFooterView';

interface PaperFooterProps {
    selected: string;
    details: object;
    disabled?: any;
}

export default function PaperFooter({ selected, details, disabled }: PaperFooterProps) {
    const { step, setStep, setSelectedSteps } = useCheckoutContext();

    const setInState = (
        data:
            | { plan: SelectedStepsObject }
            | { payment: SelectedStepsObject }
            | { confirmation: SelectedStepsObject }
    ) => {
        setSelectedSteps((prevState) => ({
            ...prevState,
            ...data,
        }));
    };

    const handleClick = () => {
        if (step === 1) {
            setInState({ plan: { selected, details } });
            if (selected === PlanTitleEnum.payIfYouWin) {
                setStep(3);
                return;
            }
        } else if (step === 2) {
            setInState({ payment: { selected, details } });
        } else if (step === 3) {
            setInState({ confirmation: { selected, details } });
        }

        setStep(step + 1);
    };

    return <PaperFooterView {...{ handleClick, disabled }} />;
}
