import { FC, useEffect, useState } from 'react';
import { Select } from '@licitar/ui-kit';
import { useTranslation } from 'react-i18next';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { addNotificationWarning } from 'common/utils';

interface TemplateSelectorProps {
    selectedTemplateId: string | undefined;
    templates?: DocumentTemplate[];
    onSelectedTemplate: (template?: DocumentTemplate) => void;
    name: string;
    label: string;
}

const TemplateSelector: FC<TemplateSelectorProps> = ({
    selectedTemplateId,
    templates,
    onSelectedTemplate,
    name,
    label,
}) => {
    const [currentTemplates, setCurrentTemplates] = useState<DocumentTemplate[]>(templates ?? []);
    const { t } = useTranslation();

    const getTemplates = async () => {
        try {
            const response = await templateRequests.getTemplates();
            setCurrentTemplates(response?.data ?? []);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('editor.process.components.template-selector.error-onload-templates'),
            });
        }
    };

    useEffect(() => {
        if (!templates?.length) {
            getTemplates();
        }
    }, []);

    const getTemplatesOptions = () =>
        currentTemplates?.map((template) => ({ label: template.name, value: template._id })) ?? [];

    return (
        <Select
            value={getTemplatesOptions().find((item) => item.value === selectedTemplateId)}
            name={name}
            label={label}
            options={getTemplatesOptions()}
            onChange={({ value }) => {
                const template = currentTemplates?.find(
                    (currTemplate) => currTemplate._id === value
                );
                onSelectedTemplate(template);
            }}
        />
    );
};

export default TemplateSelector;
