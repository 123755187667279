import * as Yup from 'yup';
import i18n from 'i18next';
import moment from 'moment';
import { AuctionTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';
import { auctionTypeToLabel } from 'modules/process/components/auction-type-label';
import {
    AuctionNotice,
    AuctionType,
    MethodDispute,
} from '../../../../clients/manager/interfaces/auction-notice.interface';
import { processRules, processUtils } from '../../process-utils';

export const getGeneralDataPartialValidationSchema = (
    values: AuctionNotice | undefined,
    auctionTypeRules: AuctionTypeRule | undefined
) => {
    const objValidation: { [key: string]: any } = {
        auctionNumber: Yup.string().nullable(true),

        accreditationNumber: Yup.string().nullable(true),
    };
    if (
        !auctionTypeRules?.generalSettings?.cadastro?.numeroProcessoAutomatico &&
        !auctionTypeRules?.generalSettings?.cadastro?.formulaNumeroAutomatico
    ) {
        objValidation.auctionNumber = objValidation.auctionNumber
            .min(6, i18n.t('info.min-auction-number', { ns: 'validation' }))
            .required(i18n.t('required-field', { ns: 'validation' }));
    }
    if (
        !auctionTypeRules?.generalSettings?.cadastro?.numeroModalidadeAutomatico &&
        !auctionTypeRules?.generalSettings?.cadastro?.formulaNumeroModalidadeAutomatico
    ) {
        objValidation.accreditationNumber = objValidation.accreditationNumber
            .min(6, i18n.t('info.min-auction-number', { ns: 'validation' }))
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processRules.visiblePriceRegistration(values)) {
        objValidation.itsPriceRegistration = Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processRules.visibleTypeValueBid(values)) {
        objValidation.typeValueBid = Yup.string()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processRules.visibleDecimalPlaces(values)) {
        objValidation.decimalPlaces = Yup.number()
            .nullable(true)
            .min(1, i18n.t('required-field', { ns: 'validation' }))
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (values?.itsPriceRegistration === 1) {
        objValidation.expirationDatePriceRegistration = Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));

        objValidation.isRideAllowed = Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    const randomEnabled = auctionTypeRules?.generalSettings.cadastro?.permitirRandomico;
    if (
        values?.methodDispute === MethodDispute.aberto &&
        randomEnabled &&
        Number(randomEnabled) > 0
    ) {
        objValidation.randomEndTime = Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processRules.visibleDisputeDuration(values)) {
        objValidation.disputeDuration = Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processRules.visibleMethodDispute(values)) {
        objValidation.methodDispute = Yup.number()
            .required(i18n.t('required-field', { ns: 'validation' }))
            .nullable(true);
    }

    if (processRules.visibleUnenforceableNumber(values)) {
        objValidation.unenforceableNumber = Yup.string()
            .nullable(true)
            .min(6, i18n.t('info.min-auction-number', { ns: 'validation' }))
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processRules.visibleRangeRegionParticipation(auctionTypeRules, values)) {
        objValidation.rangeRegionParticipation = Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processUtils.isAccreditationProcess(values)) {
        objValidation.auctionStartDate = Yup.string()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));

        objValidation.auctionEndDate = Yup.string()
            .nullable(true)
            .when('auctionStartDate', (auctionStartDate: string, schema: any) =>
                schema.test({
                    test: (auctionEndDate: any) => {
                        if (!auctionEndDate) return true;
                        return (
                            moment(auctionEndDate).valueOf() > moment(auctionStartDate).valueOf()
                        );
                    },
                    message: i18n.t('info.auction-end-date'),
                })
            )
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    if (processRules.visibleJudgmentCriterion(values)) {
        objValidation.judgmentCriterion = Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' }));
    }

    return objValidation;
};

export const getGeneralDataFormLabels = (values?: AuctionNotice) => {
    const labels: { [key: string]: string } = {
        auctionNumber: i18n.t('term.process-number'),
        accreditationNumber: i18n.t('term.accreditation-number-form', {
            accreditation: auctionTypeToLabel(values?.auctionType).long,
        }),
        rangeRegionParticipation: i18n.t('term.regional-preference'),
        methodDispute: i18n.t('term.dispute-mode'),
        judgmentCriterion: i18n.t('term.judgment-criterion'),
        typeValueBid: i18n.t('term.bid-format'),
        budgetAllocationNumber: i18n.t('term.budget-appropriation'),
        decimalPlaces: i18n.t('term.decimals'),
        randomEndTime: i18n.t('term.random'),
        itsPriceRegistration: i18n.t('term.price-registration'),
        expirationDatePriceRegistration: i18n.t('term.expiration-date'),
        isRideAllowed: i18n.t('term.ride-is-allowed'),
        disputeDuration: i18n.t('term.dispute-duration'),
        unenforceableNumber: i18n.t('term.unenforceable-number'),
    };

    if (processUtils.isAccreditationProcess(values)) {
        labels.auctionStartDate = i18n.t('info.accreditation-start-date');
        labels.auctionEndDate = i18n.t('info.accreditation-end-date');
    }

    return labels;
};
