enum TypeBilling {
    'boleto' = 'boleto',
    'credit_card' = 'credit_card',
    'debit_card' = 'debit_card',
    'transfer' = 'transfer',
    'deposit' = 'deposit',
}

enum TypeInvoice {
    'yearly' = 'yearly',
    'monthly' = 'monthly',
    'quarterly' = 'quarterly',
    'semester' = 'semester',
    'demand' = 'demand',
    'byAuction' = 'byAuction',
}

export { TypeBilling, TypeInvoice };
