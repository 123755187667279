import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

export const useStyles = makeStyles(() => ({
    headerContainer: {
        width: formatPxToRem(500),
    },
    optionContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: 0,
    },
    optionTitle: {
        fontWeight: 700,
        marginBottom: 0,
        fontSize: formatPxToRem(12),
    },
}));
