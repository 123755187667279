import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { disableField } from 'modules/process/process-utils';
import { Autocomplete } from '@material-ui/lab';
import { AuctionLegalSupport } from '../../../../clients/manager/interfaces/legall-support.interface';
import { auctionLegalSupportRequests } from '../../../../clients/manager/auction-legal-support.requests';
import { useProcessFormContext } from '../../context/process-form.context';

interface SelectLegalAssistanceProps {
    onChange: (value: number) => void;
}

const SelectLegalAssistance: React.FC<SelectLegalAssistanceProps> = ({ onChange }) => {
    const { t } = useTranslation();
    const { processForm, process } = useProcessFormContext();

    const [legalSupportList, setLegalSupportList] = useState<AuctionLegalSupport[]>([]);
    const [fetching, setFetching] = useState(false);

    const getLegalSupportList = useCallback(async () => {
        try {
            if (!processForm?.values.auctionType) {
                return;
            }
            setFetching(true);

            const response = await auctionLegalSupportRequests.listLegalSupport(
                processForm?.values.auctionType
            );

            const currentLegalSupport = response.data.find(
                (legalSupport) => legalSupport.id === processForm?.values.legalSupportId
            );

            if (!currentLegalSupport) {
                processForm?.setFieldValue('legalSupportId', undefined);
            }

            setLegalSupportList(response?.data ?? []);
        } catch (error) {
            console.error(error);
        } finally {
            setFetching(false);
        }
    }, [processForm?.values.ruleId, processForm?.values.ruleId]);

    useEffect(() => {
        getLegalSupportList();
    }, [processForm?.values.auctionType, processForm?.values.ruleId]);

    const value = useMemo(() => {
        if (processForm?.values.ruleId) {
            return legalSupportList.find(
                (legalSupport) => legalSupport.id === processForm?.values.legalSupportId
            );
        }

        return undefined;
    }, [legalSupportList, processForm?.values.legalSupportId]);

    return (
        <Autocomplete
            key='select-legal-assistance'
            options={legalSupportList}
            loading={fetching}
            disabled={disableField('legalSupportId', process, !legalSupportList.length) || fetching}
            defaultValue={value || null}
            value={value || null}
            getOptionSelected={(option: AuctionLegalSupport, value: AuctionLegalSupport) => {
                return option.id === value.id;
            }}
            getOptionLabel={(option) => option.description}
            renderInput={(params) => (
                <TextField {...params} label={t('term.legal-support')} variant='outlined' />
            )}
            onChange={(_, legalSupportId) => {
                if (legalSupportId) {
                    onChange(legalSupportId.id);
                }
            }}
        />
    );
};

export default SelectLegalAssistance;
