import { FC } from 'react';
import { CardContent } from '@licitar/ui-kit';
import moment from 'moment';
import { CardPriceListProps } from './props';
import { Card, EditIcon, CardDetails, LastChanges, CardActions } from './styled';

const CardPriceList: FC<CardPriceListProps> = ({
    priceList,
    handleSelectPriceList,
    handleEditPriceList,
}) => (
    <Card key={priceList._id} onClick={() => handleSelectPriceList(priceList)}>
        <CardContent>
            <CardDetails title={priceList.name}>
                <span>{priceList?.name}</span>
                <span>{priceList?.description}</span>
            </CardDetails>
            <CardActions>
                <LastChanges>
                    <span>{moment(priceList.createdAt).format('LLL')}</span>
                </LastChanges>
                <EditIcon
                    title='Editar'
                    onClick={(ev) => {
                        ev.stopPropagation();
                        handleEditPriceList();
                    }}
                />
            </CardActions>
        </CardContent>
    </Card>
);

export default CardPriceList;
