import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    cardSelected: {
        display: 'flex',
        marginBottom: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    boxIcons: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(2),
    },
}));

export default useStyles;
