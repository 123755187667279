import {
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Modal, TextField } from 'common/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        padding: theme.spacing(2),
        width: theme.spacing(80),
    },
    buttons: {
        padding: theme.spacing(2),
        gap: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: 300,
    },
}));

interface ModalCatalogsViewProps {
    handleClose: () => void;
    handleRemove: (row: any) => void;
    catalogs?: any[];
    form: any;
}

const ModalCatalogsView: React.FC<ModalCatalogsViewProps> = ({
    handleClose,
    handleRemove,
    catalogs,
    form,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const header = <span>{`${t('term.catalog')}s`}</span>;
    return (
        <Modal open onClose={handleClose} {...{ header }}>
            <div className={classes.modal}>
                <Grid container direction='column' spacing={3}>
                    <Grid item container alignItems='center' spacing={2}>
                        <Grid item xs={10}>
                            <TextField
                                size='small'
                                label={t('term.name')}
                                fullWidth
                                name='name'
                                value={form.values.name}
                                onChange={form.handleChange}
                                error={form.touched.name && !!form.errors.name}
                                required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                size='small'
                                variant='contained'
                                color='primary'
                                onClick={form.handleSubmit}
                                disabled={form.isSubmitting}
                            >
                                {t('term.save')}
                            </Button>
                        </Grid>
                    </Grid>

                    <br />

                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{`${t('term.catalog')}s`}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {catalogs &&
                                    catalogs.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell
                                                align='center'
                                                padding='none'
                                                style={{ width: 120 }}
                                            >
                                                <>
                                                    <IconButton onClick={() => form.setValues(row)}>
                                                        <Tooltip title={t('term.edit') as string}>
                                                            <EditIcon fontSize='small' />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleRemove(row)}>
                                                        <Tooltip title={t('term.remove') as string}>
                                                            <DeleteIcon fontSize='small' />
                                                        </Tooltip>
                                                    </IconButton>
                                                </>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {catalogs && !catalogs.length && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography gutterBottom align='center'>
                                                {t('info.none-data')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!catalogs && (
                                    <TableRow>
                                        <TableCell colSpan={5} align='center'>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container justifyContent='flex-end' className={classes.buttons}>
                        <Button size='small' onClick={handleClose}>
                            {t('term.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default ModalCatalogsView;
