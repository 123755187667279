import { FC } from 'react';
import { Chip, ChipProps } from '@material-ui/core';
import { getGlobalEnableStatusLabel } from 'common/utils';
import { useStyles } from './styles';

interface ChipGlobalEnableStatusProps {
    status: number;
}

const ChipGlobalEnableStatus: FC<ChipGlobalEnableStatusProps> = ({ status }) => {
    const classes = useStyles();

    const defaultProps: ChipProps = {
        label: getGlobalEnableStatusLabel(status),
        className: classes.chipStatusDisabled,
    };

    switch (status) {
        case -1:
            defaultProps.className = classes.chipStatusDisabled;
            break;
        case 0:
            defaultProps.className = classes.chipStatusPending;
            break;
        case 1:
            defaultProps.className = classes.chipStatusEnabled;
            break;
        case 2:
            defaultProps.className = classes.chipStatusPartialEnabled;
            break;
        default:
            defaultProps.className = classes.chipStatusDisabled;
            break;
    }

    return <Chip size='small' color='primary' {...defaultProps} />;
};

export default ChipGlobalEnableStatus;
