import { DocumentProcessType } from 'clients/manager/interfaces';
import { orderBy, uniqBy } from 'lodash';
import {
    DefaultVariables,
    DefaultVariable,
    OrganizationVariables,
    ProcessVariable,
    ProcessVariables,
    ProcessUsersAuctioneerVariables,
    ProcessTeamVariables,
    ProviderVariables,
    ProcessHomologationVariables,
    ProcessContractVariables,
    ProcessUsersAdjudicationVariables,
    ProcessSolicitationVariables,
    ProcessDisputeVariables,
    ProcessProviderInformationVariables,
} from 'modules/editor/interfaces/variables.interface';
import moment from 'moment';
import i18n from 'i18next';
import 'moment/locale/pt-br';

const defaultProcessTypes = [
    DocumentProcessType.accreditation,
    DocumentProcessType.auction,
    DocumentProcessType.exemption,
    DocumentProcessType.publicSale,
    DocumentProcessType.concurrence,
];

const i18nConfig = { ns: 'editor-variables' };

// Não anterar id dos registos, são utilizados como referência nos documentos
// que utilizaram-na

export const defaultVariables = () => {
    moment.locale('pt-br');
    const variables: DefaultVariable[] = [
        {
            name: DefaultVariables.anoAtual1,
            description: i18n.t('default-variable-year', i18nConfig),
            id: '5001',
        },
        {
            name: DefaultVariables.anoAtual2,
            description: i18n.t('default-variable-year-curt', i18nConfig),
            id: '5002',
        },
        {
            name: DefaultVariables.diaAtual,
            description: i18n.t('default-variable-day', i18nConfig),
            id: '5003',
        },
        {
            name: DefaultVariables.mesAtual,
            description: i18n.t('default-variable-month', i18nConfig),
            id: '5004',
        },
        {
            name: DefaultVariables.mesAtualPorExtenso,
            description: i18n.t('default-variable-month-2', i18nConfig),
            id: '5005',
        },
        {
            name: DefaultVariables.dataAtualPorExtenso,
            description: i18n.t('default-variable-date', i18nConfig),
            id: '5006',
        },
    ];

    return variables;
};

export const editalVariables = (processTypes: DocumentProcessType[]) => {
    const auctionOrganizationVariables: ProcessVariable[] = [
        {
            name: OrganizationVariables.nomeOrganizacao,
            description: i18n.t('edital-variable-organization-name', i18nConfig),
            id: '1001',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.cnpjOrganizacao,
            description: i18n.t('edital-variable-organization-cnpj', i18nConfig),
            id: '1002',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.enderecoLogradouroOrganizacao,
            description: i18n.t('edital-variable-organization-address', i18nConfig),
            id: '1003',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.enderecoNumeroOrganizacao,
            description: i18n.t('edital-variable-organization-address-number', i18nConfig),
            id: '1004',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.enderecoComplementoOrganizacao,
            description: i18n.t('edital-variable-organization-address-complement', i18nConfig),
            id: '1005',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.enderecoBairroOrganizacao,
            description: i18n.t('edital-variable-organization-district-complement', i18nConfig),
            id: '1006',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.enderecoCidadeOrganizacao,
            description: i18n.t('edital-variable-organization-district-city', i18nConfig),
            id: '1007',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.enderecoEstadoOrganizacao,
            description: i18n.t('edital-variable-organization-district-state', i18nConfig),
            id: '1008',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.enderecoCEPOrganizacao,
            description: i18n.t('edital-variable-organization-district-cep', i18nConfig),
            id: '1009',
            processTypes: defaultProcessTypes,
        },
        {
            name: OrganizationVariables.nomeUnidadeCompradora,
            description: i18n.t('edital-variable-organization-unit-buy', i18nConfig),
            id: '1010',
            processTypes: defaultProcessTypes,
        },
    ];

    const auctionVariables: ProcessVariable[] = [
        {
            name: ProcessVariables.numeroEdital,
            description: i18n.t('edital-variable-edital-number', i18nConfig),
            id: '1301',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessTeamVariables.nomePresidente,
            description: i18n.t('edital-variable-edital-name-pres', i18nConfig),
            id: '1304',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessTeamVariables.cpfPresidente,
            description: i18n.t('edital-variable-edital-cpf-pres', i18nConfig),
            id: '1305',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessTeamVariables.nomeOperador,
            description: i18n.t('edital-variable-edital-operator-name', i18nConfig),
            id: '2023',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessUsersAuctioneerVariables.nomeAutoridadeCompetente,
            description: i18n.t('edital-variable-edital-name-auto', i18nConfig),
            id: '1306',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessUsersAuctioneerVariables.cpfAutoridadeCompetente,
            description: i18n.t('edital-variable-edital-cpf-auto', i18nConfig),
            id: '1307',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.numeroDotacaoOrcamentaria,
            description: i18n.t('edital-variable-edital-number-dot', i18nConfig),
            id: '1308',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.limiteImpugnacao,
            description: i18n.t('edital-variable-edital-limit-impug', i18nConfig),
            id: '1309',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.dataPublicacao,
            description: i18n.t('edital-variable-edital-date-publi', i18nConfig),
            id: '1310',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.horaDisputa,
            description: i18n.t('edital-variable-edital-hour-dis', i18nConfig),
            id: '1311',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.dataDisputa,
            description: i18n.t('edital-variable-edital-date-dis', i18nConfig),
            id: '1312',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.inicioEnvioDeDocumentos,
            description: i18n.t('edital-variable-edital-documents-start-date', i18nConfig),
            id: '2028',
            processTypes: [DocumentProcessType.accreditation],
        },
        {
            name: ProcessVariables.fimEnvioDeDocumentos,
            description: i18n.t('edital-variable-edital-documents-end-date', i18nConfig),
            id: '2029',
            processTypes: [DocumentProcessType.accreditation],
        },
        {
            name: ProcessVariables.tipoIntervalo,
            description: i18n.t('edital-variable-edital-type-int', i18nConfig),
            id: '1313',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.numeroInexigibilidadeDoCredenciamento,
            description: i18n.t(
                'edital-variable-edital-number-unenforceability-accreditation',
                i18nConfig
            ),
            id: '2025',
            processTypes: [DocumentProcessType.accreditation],
        },
        {
            name: ProcessVariables.numeroInexigibilidade,
            description: i18n.t('edital-variable-edital-number-proccess-inex', i18nConfig),
            id: '1302',
            processTypes: [DocumentProcessType.unenforceability, DocumentProcessType.accreditation],
        },
        {
            name: ProcessVariables.numeroCredenciamento,
            description: i18n.t('edital-variable-edital-number-proccess-cred', i18nConfig),
            id: '1303',
            processTypes: [DocumentProcessType.accreditation],
        },
        {
            name: ProcessVariables.numeroPregao,
            description: i18n.t('edital-variable-edital-number-proccess-preg', i18nConfig),
            id: '1314',
            processTypes: [DocumentProcessType.auction],
        },
        {
            name: ProcessVariables.numeroDispensa,
            description: i18n.t('edital-variable-edital-number-proccess-dis', i18nConfig),
            id: '1315',
            processTypes: [DocumentProcessType.exemption],
        },
        {
            name: ProcessVariables.numeroProcesso,
            description: i18n.t('edital-variable-edital-number-proccess', i18nConfig),
            id: '1316',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.numeroModalidade,
            description: i18n.t('edital-variable-edital-number-modality', i18nConfig),
            id: '2026',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.descricaoModalidade,
            description: i18n.t('edital-variable-edital-description-modality', i18nConfig),
            id: '2027',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.objetoEdital,
            description: i18n.t('edital-variable-edital-desc-obj', i18nConfig),
            id: '1317',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.lotesEItens,
            description: i18n.t('edital-variable-edital-table-itens', i18nConfig),
            id: '1318',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.criterioPreferencia,
            description: i18n.t('edital-variable-edital-crit-pref', i18nConfig),
            id: '1319',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.lotesEItensSemValores,
            description: i18n.t('edital-variable-edital-table-unit-tot', i18nConfig),
            id: '1320',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.numeroConcorrencia,
            description: i18n.t('edital-variable-edital-number-proccess-conc', i18nConfig),
            id: '1321',
            processTypes: [DocumentProcessType.concurrence],
        },
        {
            name: ProcessVariables.numeroLeilao,
            description: i18n.t('edital-variable-edital-number-proccess-lei', i18nConfig),
            id: '1322',
            processTypes: [DocumentProcessType.publicSale],
        },
        {
            name: ProcessVariables.dataDePublicacaoDiarioOficial,
            description: i18n.t('v.publication-date-official', i18nConfig),
            id: '1323',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.amparoLegal,
            description: i18n.t('v.legal-support', i18nConfig),
            id: '1324',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessUsersAuctioneerVariables.emailAutoridadeCompetente,
            description: i18n.t('v.email-competent-authority', i18nConfig),
            id: '1325',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.dataAdjudicacao,
            description: i18n.t('v.adjudication-date', i18nConfig),
            id: '1326',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessUsersAdjudicationVariables.nomeResponsavelAdjudicacao,
            description: i18n.t('v.adjudication-user', i18nConfig),
            id: '1327',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.listaDeAvisosDoProcesso,
            description: i18n.t('v.process-messages', i18nConfig),
            id: '2021',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessVariables.objetoProcesso,
            description: i18n.t('v.process-object', i18nConfig),
            id: '2022',
            processTypes: defaultProcessTypes,
        },
    ];

    const providerVariables: ProcessVariable[] = [
        {
            name: ProviderVariables.cargoRepresentanteFornecedor,
            description: i18n.t('provider-variable-edital-cargo-repre', i18nConfig),
            id: '1601',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.cpfCNPJFornecedor,
            description: i18n.t('provider-variable-edital-cpf-cnpj-forn', i18nConfig),
            id: '1602',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.cpfRepresentanteFornecedor,
            description: i18n.t('provider-variable-edital-cpf-repre', i18nConfig),
            id: '1603',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.emailRepresentanteFornecedor,
            description: i18n.t('provider-variable-edital-email-repre', i18nConfig),
            id: '1604',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.enderecoBairroFornecedor,
            description: i18n.t('provider-variable-edital-end-district', i18nConfig),
            id: '1605',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.enderecoCEPFornecedor,
            description: i18n.t('provider-variable-edital-end-cep', i18nConfig),
            id: '1606',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.enderecoCidadeFornecedor,
            description: i18n.t('provider-variable-edital-end-city', i18nConfig),
            id: '1607',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.enderecoComplementoFornecedor,
            description: i18n.t('provider-variable-edital-end-comp', i18nConfig),
            id: '1608',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.enderecoEstadoFornecedor,
            description: i18n.t('provider-variable-edital-end-state', i18nConfig),
            id: '1609',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.enderecoLogradouroFornecedor,
            description: i18n.t('provider-variable-edital-end-rua', i18nConfig),
            id: '1610',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.enderecoNumeroFornecedor,
            description: i18n.t('provider-variable-edital-end-number', i18nConfig),
            id: '1611',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.razaoSocialFornecedor,
            description: i18n.t('provider-variable-edital-raz-soc-forn', i18nConfig),
            id: '1612',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.nomeRepresentanteFornecedor,
            description: i18n.t('provider-variable-edital-name-rep', i18nConfig),
            id: '1613',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProviderVariables.telefoneRepresentanteFornecedor,
            description: i18n.t('provider-variable-edital-tel-rep', i18nConfig),
            id: '1614',
            processTypes: defaultProcessTypes,
        },
    ];

    const auctionHomologationVariables: ProcessVariable[] = [
        {
            name: ProcessHomologationVariables.anoHomologacaoFinal,
            description: i18n.t('auction-variable-edital-year-homol', i18nConfig),
            id: '1901',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessHomologationVariables.dataHomologacaoFinal,
            description: i18n.t('auction-variable-edital-date-homol', i18nConfig),
            id: '1902',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessHomologationVariables.diaHomologacaoFinal,
            description: i18n.t('auction-variable-edital-day-homol', i18nConfig),
            id: '1903',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessHomologationVariables.mesExtensoHomologacaoFinal,
            description: i18n.t('auction-variable-edital-month-ext-homol', i18nConfig),
            id: '1904',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessHomologationVariables.mesHomologacaoFinal,
            description: i18n.t('auction-variable-edital-month-homol', i18nConfig),
            id: '1905',
            processTypes: defaultProcessTypes,
        },
    ];

    const processSolicitationVariables: ProcessVariable[] = [
        {
            name: ProcessSolicitationVariables.listaDeAdjudicacaoEHomologacao,
            description: i18n.t('auction-variable-edital-adjucation-homologation', i18nConfig),
            id: '2013',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessSolicitationVariables.listaDeEsclarecimentosEImpugnacoes,
            description: i18n.t('auction-variable-edital-clarification-impugnment', i18nConfig),
            id: '2014',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessSolicitationVariables.listaDeRecursosEContrarrazoes,
            description: i18n.t('auction-variable-edital-appeal-counterargument', i18nConfig),
            id: '2015',
            processTypes: defaultProcessTypes,
        },
    ];

    const processDisputeVariables: ProcessVariable[] = [
        {
            name: ProcessDisputeVariables.listaMensagensDoChat,
            description: i18n.t('auction-variable-edital-chat-messages', i18nConfig),
            id: '2016',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessDisputeVariables.listaDeLances,
            description: i18n.t('auction-variable-edital-bids', i18nConfig),
            id: '2017',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessDisputeVariables.listaClassificacaoPorLote,
            description: i18n.t('auction-variable-edital-ranking-per-lot', i18nConfig),
            id: '2018',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessDisputeVariables.resumoPropostasVencedorasPorLote,
            description: i18n.t('auction-variable-edital-proposal-winners', i18nConfig),
            id: '2019',
            processTypes: defaultProcessTypes,
        },
    ];

    const providerInformationVariables: ProcessVariable[] = [
        {
            name: ProcessProviderInformationVariables.resultadoDoExameDeConformidade,
            description: i18n.t('auction-variable-edital-conformity-examination', i18nConfig),
            id: '2020',
            processTypes: defaultProcessTypes,
        },
    ];

    const auctionContractVariables: ProcessVariable[] = [
        {
            name: ProcessContractVariables.numeroContrato,
            description: i18n.t('auction-variable-edital-number-contract', i18nConfig),
            id: '2001',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.porteEmpresa,
            description: i18n.t('auction-variable-edital-size-emp-contract', i18nConfig),
            id: '2002',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.tabelaContrato,
            description: i18n.t('auction-variable-edital-table-contract', i18nConfig),
            id: '2003',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.tabelaItemsDoEmpenho,
            description: i18n.t('auction-variable-edital-table-contract-items', i18nConfig),
            id: '2024',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.tabelaContratoSemSubTotal,
            description: i18n.t('auction-variable-edital-table-sub-contract', i18nConfig),
            id: '2004',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.tabelaServicosContrato,
            description: i18n.t('auction-variable-edital-table-serv-contract', i18nConfig),
            id: '2005',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.tabelaServicosContratoSimples,
            description: i18n.t('auction-variable-edital-table-serv-simples-contract', i18nConfig),
            id: '2006',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.valorTotal,
            description: i18n.t('auction-variable-edital-value-tot-contract', i18nConfig),
            id: '2007',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.valorTotalExtenso,
            description: i18n.t('auction-variable-edital-value-tot-ext-contract', i18nConfig),
            id: '2008',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.valorTotalReferencia,
            description: i18n.t('auction-variable-edital-value-tot-ref-contract', i18nConfig),
            id: '2009',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.valorTotalReferenciaExtenso,
            description: i18n.t('auction-variable-edital-value-tot-ref-ext-contract', i18nConfig),
            id: '2010',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.valorTotalServico,
            description: i18n.t('auction-variable-edital-value-tot-serv-contract', i18nConfig),
            id: '2011',
            processTypes: defaultProcessTypes,
        },
        {
            name: ProcessContractVariables.valorTotalServicoExtenso,
            description: i18n.t('auction-variable-edital-value-tot-serv-ext-contract', i18nConfig),
            id: '2012',
            processTypes: defaultProcessTypes,
        },
    ];

    const variables: ProcessVariable[] = [
        ...auctionOrganizationVariables,
        ...auctionVariables,
        ...providerVariables,
        ...auctionHomologationVariables,
        ...auctionContractVariables,
        ...processSolicitationVariables,
        ...processDisputeVariables,
        ...providerInformationVariables,
    ];

    return orderBy(
        uniqBy(
            processTypes.reduce<ProcessVariable[]>(
                (acc, processType) => [
                    ...acc,
                    ...variables.filter((variable) => variable.processTypes.includes(processType)),
                ],
                []
            ),
            'id'
        ),
        'name'
    );
};
