import { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, MaskedTextField, Modal, ModalPosition, TextField } from 'common/components';
import { FormTechManagerViewAddProps } from './props';
import { useStyles } from './form-tech-manager-add-style';

const FormTechManagerViewAdd: FC<FormTechManagerViewAddProps> = ({ form, open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Modal
            position={ModalPosition.center}
            open={open}
            onClose={() => onClose()}
            header={
                <Typography className={classes.title} variant='h5'>
                    {t('sign-up.organization.registration-of-legal-responsible')}
                </Typography>
            }
        >
            <Box width={485}>
                <form onSubmit={form.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('term.full-name')}
                                name='name'
                                onChange={form.handleChange}
                                value={form.values.name}
                                error={form.touched.name && Boolean(form.errors.name)}
                                helperText={form.touched.name && form.errors.name}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MaskedTextField
                                fullWidth
                                mask='999.999.999-99'
                                label='CPF'
                                name='cpf'
                                onChange={form.handleChange}
                                value={form.values.cpf}
                                error={form.touched.cpf && Boolean(form.errors.cpf)}
                                helperText={form.touched.cpf && form.errors.cpf}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MaskedTextField
                                fullWidth
                                mask='(99) 99999-9999'
                                label={t('term.phone')}
                                name='phone'
                                onChange={form.handleChange}
                                value={form.values.phone}
                                error={form.touched.phone && Boolean(form.errors.phone)}
                                helperText={form.touched.phone && form.errors.phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('term.email')}
                                name='email'
                                onChange={form.handleChange}
                                value={form.values.email}
                                error={form.touched.email && Boolean(form.errors.email)}
                                helperText={form.touched.email && form.errors.email}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                        <Grid>
                            <Button
                                className={classes.button}
                                size='small'
                                type='submit'
                                color='primary'
                                variant='contained'
                            >
                                {t('term.add')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};

export default FormTechManagerViewAdd;
