import { styled } from 'common/theme/styled';

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
`;

const PriceListInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px 0;

    span {
        color: ${({ theme }) => theme.colors.textLight};
    }
`;

export { Actions, PriceListInfo };
