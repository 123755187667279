import { timeout } from 'common/utils';
import {
    EDITOR_IFRAME_ID,
    VARIABLE_ELEMENT_CLASSNAME,
    VARIABLE_TRIGGER,
} from 'modules/editor/components/editor';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VariablesToReplaceProps } from './props';
import {
    Content,
    SeparadorTitle,
    VariableItem,
    WarningIcon,
    CheckIcon,
    Header,
    ContentVariables,
} from './styles';

const VariablesToReplace: FC<VariablesToReplaceProps> = ({
    initialPendentVariablesToReplace,
    pendentVariablesToReplace,
}) => {
    const { t } = useTranslation();

    const isReplaced = (path: string, variableName: string) => {
        const styles = { width: '20px', margin: '0 3px 0 0' };

        return pendentVariablesToReplace[path]?.find((value) => value === variableName) ? (
            <WarningIcon title={t('term.pending')} style={styles} />
        ) : (
            <CheckIcon title={t('term.updated')} style={styles} />
        );
    };

    const scrollToVariable = (variableName: string) => {
        try {
            const editorIframe: any = document.getElementById(EDITOR_IFRAME_ID);
            if (!editorIframe) {
                return;
            }
            const findedVariables: any[] = Array.from(
                editorIframe?.contentWindow?.document?.getElementsByClassName(
                    VARIABLE_ELEMENT_CLASSNAME
                )
            );

            if (!findedVariables.length) {
                return;
            }
            const existTriggered = findedVariables.find(
                (el) => el.innerHTML?.replace(VARIABLE_TRIGGER, '')?.trim() === variableName
            );

            if (!existTriggered) {
                return;
            }

            timeout(() => existTriggered.classList.add('highlight'), 200);
            timeout(() => existTriggered.classList.remove('highlight'), 1800);

            return existTriggered.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    const variableTitle = (variableName: string) => `${t('term.goto-var')} ${variableName}`;

    return (
        <Content>
            <Header>
                <span>{t('term.variables')}</span>
            </Header>
            <ContentVariables>
                {initialPendentVariablesToReplace.customs?.length ? (
                    <>
                        <SeparadorTitle>
                            <span>{t('term.customized')}</span>
                        </SeparadorTitle>
                        <div>
                            {initialPendentVariablesToReplace.customs?.map(
                                (variableName, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <VariableItem key={`customs:${index}`}>
                                        {isReplaced('customs', variableName)}
                                        <span
                                            className='name'
                                            title={variableTitle(variableName)}
                                            onClick={() => scrollToVariable(variableName)}
                                            role='button'
                                            tabIndex={-10}
                                            onKeyDown={() => scrollToVariable(variableName)}
                                        >
                                            {variableName}
                                        </span>
                                    </VariableItem>
                                )
                            )}
                        </div>
                    </>
                ) : null}
                {initialPendentVariablesToReplace.auction.length ? (
                    <>
                        <SeparadorTitle>
                            <span>{t('term.process')}</span>
                        </SeparadorTitle>
                        <div>
                            {initialPendentVariablesToReplace.auction?.map(
                                (variableName, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <VariableItem key={`auction:${index}`}>
                                        {isReplaced('auction', variableName)}
                                        <span
                                            className='name'
                                            title={variableTitle(variableName)}
                                            onClick={() => scrollToVariable(variableName)}
                                            role='button'
                                            tabIndex={-9}
                                            onKeyDown={() => scrollToVariable(variableName)}
                                        >
                                            {variableName}
                                        </span>
                                    </VariableItem>
                                )
                            )}
                        </div>
                    </>
                ) : null}
                {initialPendentVariablesToReplace.defaults.length ? (
                    <>
                        <SeparadorTitle>
                            <span>{t('term.general')}</span>
                        </SeparadorTitle>
                        <div>
                            {initialPendentVariablesToReplace.defaults?.map(
                                (variableName, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <VariableItem key={`defaults:${index}`}>
                                        {isReplaced('defaults', variableName)}
                                        <span
                                            className='name'
                                            title={variableTitle(variableName)}
                                            onClick={() => scrollToVariable(variableName)}
                                            role='button'
                                            tabIndex={-8}
                                            onKeyDown={() => scrollToVariable(variableName)}
                                        >
                                            {variableName}
                                        </span>
                                    </VariableItem>
                                )
                            )}
                        </div>
                    </>
                ) : null}
            </ContentVariables>
        </Content>
    );
};

export default VariablesToReplace;
