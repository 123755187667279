import { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { providerRequests } from 'clients/manager/provider.requests';
import { UserLanguages } from 'common/interfaces/user.interface';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { FormTechManagerAddProps } from './props';
import FormTechManagerAddView from './form-tech-manager-view-add';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';

const FormTechManageAdd: FC<FormTechManagerAddProps> = ({
    showModalAddTecnicalResponsible,
    onClose,
    form,
    setShowCpfFields,
}) => {
    const { t } = useTranslation();
    const { countryCode, technicalManagerDocNumber } = useProviderUpdateRequestWarningContext();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const createNewUser = async (values) => {
        const phone = values.phone.replace(/[^\d]/g, '');

        const technicalManagerFields = {
            ...values,
            country: countryCode,
            cpf: technicalManagerDocNumber,
            phone,
            phoneCountryCode: countryCode,
            language: UserLanguages['pt-BR'],
            providerId: Number(authUserTokenData?.providerId),
        };

        await providerRequests.doAddUserAsTecnicalResponsible(technicalManagerFields);

        showModalAddTecnicalResponsible(false);
        setShowCpfFields(true);

        form.setFieldValue('technicalManagerName', values.name);
        form.setFieldValue('technicalManagerDocNumber', technicalManagerDocNumber);
    };

    const responsibleForm = useFormik({
        initialValues: {
            name: '',
            cpf: technicalManagerDocNumber || form?.values?.technicalManagerDocNumber,
            phone: '',
            email: '',
        },
        validationSchema: yup.object({
            name: yup.string().required(t('please-enter-a-name', { ns: 'validation' })),
            cpf: yup
                .string()
                .required(t('please-fill-in-the-CPF', { ns: 'validation' }))
                .min(11, t('the-CPF-must-contain-at-least-11-digits', { ns: 'validation' })),
            phone: yup.string().required(t('please-enter-a-phone', { ns: 'validation' })),
            email: yup
                .string()
                .email(t('please-enter-a-valid-email', { ns: 'validation' }))
                .required(t('please-enter-an-email', { ns: 'validation' })),
        }),
        onSubmit: (values) => {
            showModalAddTecnicalResponsible(false);
            createNewUser(values);
        },
    });

    const handleClose = () => {
        showModalAddTecnicalResponsible(false);
        onClose();
    };

    return <FormTechManagerAddView form={responsibleForm} open onClose={handleClose} />;
};

export default FormTechManageAdd;
