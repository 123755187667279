import { Box, Grid, Paper } from '@material-ui/core';
import { memo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import ProcessModality from '../process-modality';
import ProcessSourceResources from '../process-source-resources';
import ProcessTeam from '../process-team';
import ProcessGeneralData from '../process-general-data';
import ProcessDefinitions from '../process-definitions';
import { useProcessFormContext } from '../../../../context/process-form.context';
import ExpandedContentCard from '../../../expand-content-card';
import ProcessPublication from '../process-publication';
import { processRules, processUtils } from '../../../../process-utils';
import ProcessTechnicalCriteria from '../process-technical-criteria';
import ProcessIntegrations from '../process-integrations';
import ProcessAccreditationDocuments from '../process-accreditation-documents';
import ProcessUnitData from '../process-unit-data';
import ProcessParticipant from '../process-participant/process-participant';

const LoadingArea = memo(() => {
    const { t } = useTranslation();

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard title={t('term.modality')}>
                    <Box width={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={5}>
                                <Skeleton width='100%' height='50px' />
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <Skeleton width='100%' height='50px' />
                            </Grid>
                        </Grid>
                    </Box>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
});

const ProcessEditData = () => {
    const { processForm, loadingProcessData } = useProcessFormContext();
    const disabledExpand = !processForm?.values?.ruleId;
    const defaultProps = {
        disabledExpand,
    };
    const isAccreditation = processUtils.isAccreditationProcess(processForm?.values);
    const isUnenforceabilityProcess = processUtils.isUnenforceabilityProcess(processForm?.values);

    return (
        <Box>
            {loadingProcessData ? undefined : <ProcessIntegrations {...defaultProps} />}
            {loadingProcessData ? <LoadingArea /> : <ProcessModality />}
            <ProcessUnitData {...defaultProps} />
            <ProcessTeam {...defaultProps} />
            {processRules.visibleSourceOfResources(processForm?.values) ? (
                <ProcessSourceResources {...defaultProps} />
            ) : null}
            <ProcessGeneralData {...defaultProps} />
            {isUnenforceabilityProcess && <ProcessParticipant {...defaultProps} />}
            <ProcessDefinitions {...defaultProps} />
            {processRules.visibleTechnicalCriteria(processForm?.values) ||
            processRules.visibleTechnicalAndPriceCriteria(processForm?.values) ? (
                <ProcessTechnicalCriteria
                    {...defaultProps}
                    isTechniqueAndPrice={processRules.visibleTechnicalAndPriceCriteria(
                        processForm?.values
                    )}
                />
            ) : null}
            {isAccreditation && <ProcessAccreditationDocuments />}
            {processRules.visiblePublicationStepCreateProcess(processForm?.values) && (
                <ProcessPublication {...defaultProps} />
            )}
        </Box>
    );
};

export default memo(ProcessEditData);
