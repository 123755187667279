import { TextField } from 'common/components';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { TypeNextNumber, auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { formatAuctionNumber, timeout } from 'common/utils';
import { processActions } from 'modules/process/process-actions';
import { disableField, processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface AuctionNumberFieldProps {
    updateDebounceProcess: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: string
    ) => void;
}

let previousProcessRuleId: string | undefined;

const AuctionNumberField: FC<AuctionNumberFieldProps> = ({ updateDebounceProcess }) => {
    const { processForm, auctionTypeRules, process } = useProcessFormContext();
    const { t } = useTranslation();

    const [auctionNumber, setAuctionNumber] = useState<string | null | undefined>(
        processForm?.values.auctionNumber
    );
    const [requireAutoAuctionNumber, setRequireAutoAuctionNumber] = useState(
        Boolean(auctionTypeRules?.generalSettings?.cadastro.numeroProcessoAutomatico)
    );

    useEffect(() => {
        setAuctionNumber(processForm?.values.auctionNumber);
    }, [processForm?.values.auctionNumber]);

    const getNextProcessNumber = async (): Promise<void> => {
        if (!processForm?.values.id) {
            return;
        }

        const response = await auctionNoticeRequests.getNextNumber(
            processForm.values.id,
            TypeNextNumber.process,
            processForm.values.ruleId
        );
        const processNumber = response?.data.number;
        timeout(() => {
            setAuctionNumber(processNumber);
            processForm?.setFieldValue('auctionNumber', processNumber);
        }, 300);
    };

    useEffect(() => {
        ReactDOM.unstable_batchedUpdates(() => {
            const processRegistry = auctionTypeRules?.generalSettings?.cadastro;
            const auctionNumber = processForm?.values.auctionNumber;
            const ruleId = processForm?.values.ruleId;

            if (
                (processForm && processRegistry?.numeroProcessoAutomatico && !auctionNumber) ||
                (processRegistry?.numeroProcessoAutomatico &&
                    previousProcessRuleId !== ruleId &&
                    !!previousProcessRuleId)
            ) {
                setRequireAutoAuctionNumber(true);
                getNextProcessNumber();
            } else if (
                processForm &&
                !processRegistry?.numeroProcessoAutomatico &&
                auctionNumber &&
                requireAutoAuctionNumber
            ) {
                setRequireAutoAuctionNumber(false);
                processForm?.setFieldValue('auctionNumber', '');
            }

            if (processForm && !processRegistry?.numeroProcessoAutomatico) {
                setRequireAutoAuctionNumber(false);
            }

            previousProcessRuleId = ruleId;
        });
    }, [auctionTypeRules, processForm?.values.ruleId]);

    const handleChangeAuctionNumber = (event: React.ChangeEvent<any>) => {
        if (
            !auctionTypeRules?.generalSettings?.cadastro?.numeroProcessoAutomatico &&
            auctionTypeRules?.generalSettings?.cadastro?.formulaNumeroAutomatico
        ) {
            setAuctionNumber(event.target.value);
        } else {
            const value: string = event.target.value.replace(/\D/g, '');
            setAuctionNumber(formatAuctionNumber(value));
        }
    };

    const getKey = () => {
        if (requireAutoAuctionNumber) {
            return `${auctionNumber}:${Number(requireAutoAuctionNumber)}`;
        }

        return 'auction-number-field';
    };

    return (
        <TextField
            fullWidth
            key={getKey()}
            color='primary'
            disabled={disableField('auctionNumber', process, requireAutoAuctionNumber)}
            label={t('term.process-number')}
            name='auctionNumber'
            inputProps={{
                maxLength: 30,
            }}
            value={auctionNumber}
            onChange={(event) => {
                handleChangeAuctionNumber(event);
                updateDebounceProcess(event, 'auctionNumber');
            }}
            variant='outlined'
        />
    );
};

export default AuctionNumberField;
