import { Box, Grid, Typography } from '@material-ui/core';
import { TextField } from 'common/components';
import { FC } from 'react';
import { Formik } from 'formik';
import { OrganizationUnit } from 'clients/manager/interfaces/organization.interface';
import LoadingButton from 'common/components/loading-button';
import { useTranslation } from 'react-i18next';
import IntegrationSelectInputView from '../integration-select-input/integration-select-input-view';
import { RequestMetadata } from '../../interfaces/organization-apps.interfaces';

interface ImportIntegrationCardViewProps {
    title: string;
    description?: string;
    fields?: RequestMetadata[];
    isActive: boolean;
    onSubmit: (values: any) => Promise<void>;
    organizationUnits?: OrganizationUnit[];
    loadingOrganizationUnits: boolean;
}

const ImportIntegrationCardView: FC<ImportIntegrationCardViewProps> = ({
    fields,
    onSubmit,
    isActive,
    loadingOrganizationUnits,
    organizationUnits,
    title,
    description,
}) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <Box mb={2}>
                <Typography variant='body1'>
                    <strong>{title}</strong>
                </Typography>
                <Typography variant='body2'>{description}</Typography>
            </Box>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    await onSubmit(values);
                    setSubmitting(false);
                }}
                initialValues={{}}
            >
                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {isActive &&
                                fields?.map((field) => {
                                    if (field?.type === 'organizationUnits') {
                                        return (
                                            <Grid item xs={3}>
                                                <IntegrationSelectInputView
                                                    options={
                                                        organizationUnits?.map((unit) => ({
                                                            id: unit?.id,
                                                            description: unit.organizationUnitName,
                                                        })) ?? []
                                                    }
                                                    label={field?.description}
                                                    name={field?.field}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values[field?.field]}
                                                    loading={loadingOrganizationUnits}
                                                />
                                            </Grid>
                                        );
                                    }
                                    if (field?.type === 'select') {
                                        return (
                                            <Grid item xs={3}>
                                                <IntegrationSelectInputView
                                                    options={field?.options as any}
                                                    label={field?.description}
                                                    name={field?.field}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values[field?.field]}
                                                />
                                            </Grid>
                                        );
                                    }
                                    return (
                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                color='primary'
                                                variant='outlined'
                                                name={field?.field}
                                                label={field?.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[field?.field]}
                                            />
                                        </Grid>
                                    );
                                })}
                            <Grid item xs={12}>
                                <LoadingButton
                                    color={isActive ? 'secondary' : 'primary'}
                                    size='small'
                                    variant='contained'
                                    type='submit'
                                    disabled={isSubmitting}
                                    loading={
                                        isSubmitting
                                            ? {
                                                  text: t(
                                                      'process.components.process-importing-process'
                                                  ),
                                              }
                                            : undefined
                                    }
                                >
                                    {t('process.components.process-import-process')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    );
};

export default ImportIntegrationCardView;
