import { Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatCpf, formatRG } from 'common/utils';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface VisibleMaskDocViewProps {
    showDoc: boolean;
    cpf: string;
    rg: string;
    isForeignDocument?: boolean;
    handleShowDoc: () => void;
}

const VisibleMaskDocView: React.FC<VisibleMaskDocViewProps> = ({
    showDoc,
    cpf,
    rg,
    isForeignDocument = false,
    handleShowDoc,
}) => {
    const { t } = useTranslation();

    return (
        <Grid container>
            {showDoc ? (
                <div>
                    <p>
                        {isForeignDocument ? `${t('term.tin')}:${cpf}` : ''}
                        {!isForeignDocument ? `${t('term.cpf')}:${formatCpf(cpf)}` : ''}
                    </p>
                    {rg && (
                        <p>
                            {t('term.rg')}:{formatRG(rg)}
                        </p>
                    )}
                </div>
            ) : (
                <div>
                    <p>***.***.***-**</p>
                </div>
            )}
            <IconButton aria-label='toggle password visibility' onClick={handleShowDoc}>
                {showDoc ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Grid>
    );
};

export default VisibleMaskDocView;
