import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Modal, ModalPosition, TextField } from 'common/components';
import { DebouncedFunc } from 'lodash';
import { ListMaterialData } from 'modules/material/interfaces/material.interface';
import { useTranslation } from 'react-i18next';
import { useStyles } from './search-materials-modal-styles';

interface SearchMaterialsModalViewProps {
    loading: boolean;
    code?: string;
    options: ListMaterialData[];
    onChange: (value: ListMaterialData | null) => Promise<void>;
    open?: boolean;
    onClose?: () => void;
    debounceSearchMaterials: DebouncedFunc<(q: string) => void>;
}

const SearchMaterialsModalView: React.FC<SearchMaterialsModalViewProps> = ({
    options,
    code,
    loading,
    onChange,
    open = false,
    onClose,
    debounceSearchMaterials,
}) => {
    const { t } = useTranslation();

    const styles = useStyles();

    const value = code ? options?.find((value) => value.code === code) : undefined;

    return (
        <Modal
            position={ModalPosition.center}
            open={open}
            onClose={(event: any) => {
                event.stopPropagation();
                onClose?.();
            }}
            header={<span className={styles.headerContainer}>{t('material.add-material')}</span>}
        >
            <div>
                <Autocomplete
                    autoSelect
                    autoComplete
                    fullWidth
                    value={value}
                    options={options ?? []}
                    loading={loading}
                    loadingText={<Typography>{`${t('term.loading')}...`}</Typography>}
                    noOptionsText={<Typography>{t('term.items-not-found')}</Typography>}
                    onChange={async (_, value) => {
                        await onChange(value);
                    }}
                    getOptionLabel={(option) =>
                        `${option?.code} | ${option?.catalog?.name} - ${option?.name} ${
                            option?.description ? `(${option?.description})` : ''
                        }`
                    }
                    renderOption={(option) => (
                        <div className={styles.optionContainer}>
                            <Typography className={styles.optionTitle}>
                                {option?.code} | {option?.catalog?.name}
                            </Typography>
                            <Typography variant='body2'>{option?.name}</Typography>
                            {option?.description && (
                                <Typography variant='caption'>{option?.description}</Typography>
                            )}
                        </div>
                    )}
                    renderInput={(params) => (
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                debounceSearchMaterials(event.target.value?.toLowerCase())
                            }
                            {...params}
                            label={t('term.search-by-name')}
                            placeholder={t('term.search-here')}
                            InputProps={{
                                ...params?.InputProps,
                                endAdornment: loading && (
                                    <Box display='flex' alignItems='center'>
                                        {loading ? (
                                            <CircularProgress color='inherit' size={24} />
                                        ) : null}
                                        {params?.InputProps?.endAdornment}
                                    </Box>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        </Modal>
    );
};

export default SearchMaterialsModalView;
