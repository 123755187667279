import React, { FC, memo, ReactNode, useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { disableField, processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface DecimalPlacesFieldProps {
    handleChangeDefault: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DecimalPlacesField: FC<DecimalPlacesFieldProps> = ({ handleChangeDefault }) => {
    const { processForm, auctionTypeRules, process } = useProcessFormContext();
    const { t } = useTranslation();

    const decimalPlacesOptions = useMemo(() => {
        const opts: ReactNode[] = [];

        if (auctionTypeRules?.generalSettings.cadastro.casasDecimais_2)
            opts.push(<MenuItem value={2}>{`2 ${t('term.decimal-places')}`}</MenuItem>);

        if (auctionTypeRules?.generalSettings.cadastro.casasDecimais_3)
            opts.push(<MenuItem value={3}>{`3 ${t('term.decimal-places')}`}</MenuItem>);

        if (auctionTypeRules?.generalSettings.cadastro.casasDecimais_4)
            opts.push(<MenuItem value={4}>{`4 ${t('term.decimal-places')}`}</MenuItem>);

        return opts;
    }, [auctionTypeRules]);

    return (
        <TextField
            fullWidth
            color='primary'
            label={t('term.decimals')}
            onChange={handleChangeDefault}
            variant='outlined'
            name='decimalPlaces'
            select
            defaultValue={processForm?.values.decimalPlaces}
            disabled={disableField('decimalPlaces', process)}
        >
            {decimalPlacesOptions}
        </TextField>
    );
};

export default memo(DecimalPlacesField);
