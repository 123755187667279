import { FC, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Yup, { addNotificationApiError, addNotificationSuccess, getLanguage } from 'common/utils';
import moment from 'moment';
import * as momentConfig from 'moment';
import documentTypeProviderRequests from 'clients/manager/document-type-provider.request';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    CompleteSupplyCategoriesProvidersSettings,
    CompleteSupplyCategoriesProvidersSettingsUpdate,
} from 'clients/manager/interfaces/supply-categories-providers.interface';
import momentDurationFormatSetup from 'moment-duration-format';
import MomentUtils from '@date-io/moment';
import { UserLanguages } from 'common/interfaces/user.interface';
import { SupplyCategoryTypeEnum } from 'clients/manager/interfaces/supply-categories.interface';
import { SelectSegmentCategories } from 'clients/manager/interfaces/segment-categories-providers.interface';
import { ModalSettingsSegmentsProps } from './props';
import SelectStatusSegments from '../select-status-segments-settings';
import FormTechManager from '../form-tech-manager';

const ModalSettingsSegments: FC<ModalSettingsSegmentsProps> = ({ onClose, supplyCategories }) => {
    const { t } = useTranslation();
    useEffect(() => {
        moment.locale('pt-br');
        momentConfig.tz.setDefault('America/Sao_Paulo');
        momentDurationFormatSetup(momentConfig);
    }, []);

    const getValidationSchema = () => {
        if (supplyCategories.supplyCategoryType === SupplyCategoryTypeEnum.product)
            return Yup.object().shape({
                suppliesAs: Yup.string(),
                technicalManagerBoardNumber: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
                manufacturerIdentifierNumber: Yup.string().when('suppliesAs', {
                    is: (value) => value !== SelectSegmentCategories.manufacturer,
                    then: Yup.string().required(t('required-field', { ns: 'validation' })),
                    otherwise: Yup.string().nullable(),
                }),
                technicalManagerBoardType: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
                technicalManagerDocNumber: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
                technicalManagerName: Yup.string().required(
                    t('required-field', { ns: 'validation' })
                ),
            });
    };

    const form = useFormik<CompleteSupplyCategoriesProvidersSettings>({
        initialValues: {
            id: supplyCategories.id,
            providerId: supplyCategories.providerId,
            supplyCategoryId: supplyCategories.supplyCategoryId,
            dateTimeUpdate: supplyCategories.dateTimeUpdate,
            statusApproved: supplyCategories.statusApproved,
            dueDate: supplyCategories.dueDate,
            externalId: supplyCategories.externalId,
            name: supplyCategories.name,
            suppliesAs: supplyCategories.suppliesAs,
            technicalManagerBoardNumber: supplyCategories.technicalManagerBoardNumber,
            technicalManagerBoardType: supplyCategories.technicalManagerBoardType,
            technicalManagerDocNumber: supplyCategories.technicalManagerDocNumber,
            technicalManagerName: supplyCategories.technicalManagerName,
            manufacturerIdentifierNumber: supplyCategories.manufacturerIdentifierNumber,
        },
        enableReinitialize: true,
        validationSchema: getValidationSchema(),
        onSubmit: async (values) => {
            try {
                const updateSupplyCategorie: CompleteSupplyCategoriesProvidersSettingsUpdate = {
                    id: values.id,
                    providerId: values.providerId,
                    dueDate: values.dueDate,
                    statusApproved: values.statusApproved,
                    supplyCategoryProviderId: values.supplyCategoryId,
                    suppliesAs: values.suppliesAs,
                    supplyCategoryType: values.supplyCategoryType,
                    technicalManagerName: values.technicalManagerName,
                    technicalManagerDocNumber: values.technicalManagerDocNumber,
                    technicalManagerBoardType: values.technicalManagerBoardType,
                    technicalManagerBoardNumber: values.technicalManagerBoardNumber,
                    manufacturerIdentifierNumber: values.manufacturerIdentifierNumber,
                };

                const response = await documentTypeProviderRequests.doSaveSupplyCategoryProvider(
                    updateSupplyCategorie
                );
                if (!response?.status) {
                    throw new Error('Err');
                }

                onClose();
                return addNotificationSuccess();
            } catch (error) {
                addNotificationApiError(error);
            }
        },
    });

    const onClickReview = () => onClose();

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={(event: any) => {
                event.stopPropagation();
                onClose();
            }}
            header={<span>{`${supplyCategories?.externalId} - ${supplyCategories?.name}`}</span>}
        >
            <Box width='800px'>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={6}>
                        <SelectStatusSegments
                            onChange={(statusApproved) =>
                                form.setFieldValue('statusApproved', statusApproved.target.value)
                            }
                            value={form.values.statusApproved}
                            omitAllOption
                        />
                    </Grid>
                    <Grid item md={6} sm={6}>
                        <MuiPickersUtilsProvider
                            libInstance={momentConfig}
                            utils={MomentUtils}
                            locale={getLanguage() === UserLanguages['pt-BR'] ? 'pt-br' : undefined}
                        >
                            <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label={t('term.date')}
                                name='dueDate'
                                format='DD/MM/yyyy'
                                value={form.values.dueDate}
                                InputAdornmentProps={{ position: 'end' }}
                                invalidDateMessage={t('info.invalid-format', { ns: 'validation' })}
                                invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                onChange={(date) => {
                                    form.setFieldValue(
                                        'dueDate',
                                        moment(date).format('yyyy-MM-DD')
                                    );
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                {supplyCategories.supplyCategoryType === SupplyCategoryTypeEnum.product && (
                    <FormTechManager form={form} />
                )}
                <Box mt={2} width={1}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' size='small' onClick={onClickReview}>
                                {t('term.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => form.submitForm()}
                            >
                                {t('term.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalSettingsSegments;
