import { IoMdTrash } from 'react-icons/io';
import { styled } from 'common/theme/styled';

const ActionsArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
`;

const DeleteIcon = styled(IoMdTrash)`
    font-size: 16px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.iconColorDefault};
`;

const VariableWrapper = styled.div`
    display: flex;
`;

const VariableActions = styled.div`
    display: flex;
    width: 20px;
    justify-content: flex-end;
    margin: 15px 0;
`;

export { ActionsArea, DeleteIcon, VariableWrapper, VariableActions };
