import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { ProcessTransaction } from './interfaces/process-transactions.interface';

const path = 'auction-notice-transactions';

interface CreateProcessTransaction {
    message: string;
    processId: number;
}

interface ListProcessTransactions {
    limit?: number;
    offset?: number;
    params: {
        processId: number;
    };
}

const processTransactionsRequests = {
    listProcessTransactions: async (data: ListProcessTransactions) =>
        doRequest<GenericResponse<ProcessTransaction>>(
            managerApiInstance.post(`/${path}/listAuctionNoticeTransactions`, data)
        ),
    createProcessTransaction: async (transaction: CreateProcessTransaction) =>
        doRequest<PlainGenericResponse<ProcessTransaction>>(
            managerApiInstance.post(`/${path}/createAuctionNoticeTransaction`, transaction)
        ),
};

export { processTransactionsRequests };
