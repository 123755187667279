import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        objectFit: 'cover',
        height: theme.spacing(7.5),
        width: '100%',
        borderRadius: theme.shape.borderRadius,
    },
    imagesRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        padding: theme.spacing(1),
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
        '&:hover': {
            boxShadow: theme.shadows[3],
        },
    },
    deleteButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
}));

export default useStyles;
