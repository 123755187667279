import { providerDashboardRequests } from 'modules/provider-dashboard/services/provider-dashboard.requests';
import { useQuery } from 'common/hooks/use-query.hook';
import ProviderSummaryView from './provider-summary-view';

const ProviderSummary = () => {
    const { data: summary, loading } = useQuery(providerDashboardRequests.getProviderSummary);

    return <ProviderSummaryView {...{ summary, loading }} />;
};

export default ProviderSummary;
