import { LinearProgress, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledLinearProgress = withStyles((theme) => ({
    root: {
        height: theme.spacing(1.5),
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
        margin: theme.spacing(0.5, 2, 1),
    },
    bar: {
        borderRadius: 5,
    },
}))(LinearProgress);

export const StyledAvatar = withStyles((theme) => ({
    root: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: ({ done }: { done: boolean }) =>
            done ? theme.palette.secondary.main : theme.palette.grey[400],
    },
}))(Avatar);

export const cardStyle = {
    height: '350px',
};
