/* eslint-disable no-nested-ternary */
import React, { FC, useState } from 'react';
import { Spin } from 'common/components/base/spin';
import { dispatchWindowEvent, UseWindowEvent } from 'common/hooks/events.hook';
import { useTranslation } from 'react-i18next';
import { addNotificationInfo } from 'common/utils';
import AdvancedFilters from '../advanced-filters';
import Item from '../item';
import { TableListProps } from './props';
import { SkeletonLines } from './skeleton';
import {
    Content,
    EmptyResults,
    InitialSerchText,
    Header,
    ProviderHeader,
    BrandHeader,
    ModelHeader,
    OriginHeader,
    PriceHeader,
    LoadingArea,
    EmptyImage,
    Wrapper,
} from './styled';
import { useMarketplaceSearchFiltersContext } from 'modules/priceBank/context/search-filters.context';
import { IFilters } from 'modules/priceBank/context/interfaces';
import { MarketplaceWindowEvents } from 'modules/priceBank/marketplace.events';

const TableListHeader = () => {
    const { t } = useTranslation();

    return (
        <Header>
            <ProviderHeader>
                <span>{t('term.supplier')}</span>
            </ProviderHeader>
            <BrandHeader>
                <span>{t('term.brand')}</span>
            </BrandHeader>
            <ModelHeader>
                <span>{t('term.model')}</span>
            </ModelHeader>
            <OriginHeader>
                <span>{t('term.origin')}</span>
            </OriginHeader>
            <PriceHeader>
                <span>{t('term.price')}</span>
            </PriceHeader>
        </Header>
    );
};

const TableList: FC<TableListProps> = ({ items, onPriceSelected, providers, pricesSelected }) => {
    const { notLoadMore, loadingMore, appliedFilters } = useMarketplaceSearchFiltersContext();
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
    const { t } = useTranslation();

    const handleDoAvancedFilters = (filters: IFilters) => {
        if (filters?.text === '') {
            return addNotificationInfo({
                message: t('mkp.search.need-enter-term'),
                title: t('mkp.search.mandatory-term'),
            });
        }

        dispatchWindowEvent(MarketplaceWindowEvents.DO_ADVANCED_FILTERS, {
            filters,
        });
        setShowAdvancedFilters(false);
    };

    const handleOnFiltersChanged = (filters: IFilters, forceCloseAdvancedFilters?: boolean) => {
        dispatchWindowEvent(MarketplaceWindowEvents.ADVANCED_FILTERS_CHANGED, {
            filters,
            forceCloseAdvancedFilters,
        });
    };

    UseWindowEvent(
        MarketplaceWindowEvents.ENABLE_DISABLE_ADVANCED_FILTERS,
        () => {
            setShowAdvancedFilters((prevState) => !prevState);
        },
        []
    );

    return (
        <Wrapper>
            {showAdvancedFilters && (
                <AdvancedFilters
                    appliedFilters={appliedFilters}
                    onFiltersChanged={handleOnFiltersChanged}
                    onFilter={handleDoAvancedFilters}
                />
            )}
            <Content id='table-list' retract={showAdvancedFilters}>
                <TableListHeader />
                {appliedFilters.origin === 'search' && loadingMore ? (
                    <LoadingArea>
                        <Spin />
                    </LoadingArea>
                ) : Object.keys(items).length === 0 && !appliedFilters.fetched ? (
                    <InitialSerchText>
                        <span>{t('mkp.search.digit-term-search')}</span>
                    </InitialSerchText>
                ) : Object.keys(items).length === 0 && appliedFilters.fetched ? (
                    <EmptyResults>
                        <EmptyImage src='/assets/images/svgs/empty.svg' alt='Nenhum resultado' />
                        <span>{t('mkp.search.not-found-item-try-again')}</span>
                    </EmptyResults>
                ) : (
                    <>
                        {Object.values(items)
                            .filter((item) => item.prices.length > 0)
                            .map((item) => (
                                <Item
                                    pricesSelected={pricesSelected}
                                    providers={providers}
                                    key={item.itemId}
                                    onPriceSelected={(priceIndex) =>
                                        onPriceSelected(priceIndex, item.itemId)
                                    }
                                    item={item}
                                />
                            ))}
                        {!notLoadMore && <SkeletonLines />}
                        <div id='items-end-block' />
                    </>
                )}
            </Content>
        </Wrapper>
    );
};

// tentativa de melhorar desempenho em lista grande
// se gerar problemas remover
export default React.memo(TableList, (props, nextProps) => {
    const pricesSelectedLength = Object.keys(props.pricesSelected).length;
    const nextPricesSelectedLength = Object.keys(nextProps.pricesSelected).length;

    if (
        (pricesSelectedLength > 0 || pricesSelectedLength === 0) &&
        nextPricesSelectedLength === 0
    ) {
        return false;
    }
    return Object.keys(props.items).length === Object.keys(nextProps.items).length;
});
