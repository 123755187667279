import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'common/hooks/use-query.hook';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { Skeleton } from '@material-ui/lab';
import ExpandedContentCard from '../../../expand-content-card';
import { ProcessIntegrationsProps } from './props';
import { organizationAppsRequests } from './services/organization-apps.requests';
import ImportIntegrationCard from './components/import-intergration-card';
import ExportIntegrationCard from './components/export-intergration-card';

const ProcessIntegrations: FC<ProcessIntegrationsProps> = () => {
    const { processForm } = useProcessFormContext();
    const { t } = useTranslation();

    const isEditForm = !!processForm?.values?.id;
    const isFinishing = processForm?.values?.biddingStageId === BiddingStageEnum.finishing;

    const { data: organizationAppData, loading } = useQuery(
        organizationAppsRequests.listOrganizationApps,
        {
            skip: isEditForm && !isFinishing,
        }
    );
    const data = organizationAppData?.data;

    const show = () => {
        if (!data?.length) return null;
        const integrations = data.filter(
            ({ integrationSettings }) =>
                integrationSettings?.integrationCategoryId === 2 ||
                integrationSettings?.metadata?.settings?.typeIntegration === 'formBeforeInsert'
        );
        if ((isEditForm && !isFinishing) || !integrations?.length) {
            return null;
        }

        return (
            <Box mt={2} mb={2}>
                <Paper variant='outlined' elevation={2}>
                    <ExpandedContentCard
                        defaultExpanded={false}
                        header={
                            <Box display='flex'>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant='body1'>
                                        <b>{t('info.integrations-data')}</b>
                                    </Typography>
                                </Box>
                            </Box>
                        }
                    >
                        {loading ? (
                            <Box width={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <Skeleton width='100%' height='50px' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Skeleton width='100%' height='50px' />
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : (
                            <>
                                {isFinishing ? (
                                    <Grid container spacing={2}>
                                        {integrations.map((item) => (
                                            <ExportIntegrationCard
                                                processId={processForm?.values?.id}
                                                item={item}
                                            />
                                        ))}
                                    </Grid>
                                ) : (
                                    <Grid container spacing={2}>
                                        {integrations.map((item) => (
                                            <ImportIntegrationCard item={item} />
                                        ))}
                                    </Grid>
                                )}
                            </>
                        )}
                    </ExpandedContentCard>
                </Paper>
            </Box>
        );
    };

    return show();
};

export default ProcessIntegrations;
