import { Store } from 'store';
import { removeAuthUser } from 'store/user/user.actions';
import { CONSTANTS } from 'common/constants';
import jwtDecode from 'jwt-decode';
import { JwtContent } from 'store/user/user.reducer';

const setAuthUserToken = (token: string) => {
    const { AUTH_USER } = CONSTANTS.cookies;
    const tokenData = jwtDecode(token) as JwtContent;

    localStorage.setItem(AUTH_USER, JSON.stringify(tokenData));
};
const getAuthUserToken = (): JwtContent | undefined => {
    const { AUTH_USER } = CONSTANTS.cookies;

    const authUserToken = localStorage.getItem(AUTH_USER);
    if (!authUserToken) return undefined;

    return JSON.parse(authUserToken) as JwtContent;
};

const setAuthToken = (token: string) => {
    const { TOKEN } = CONSTANTS.cookies;

    localStorage.setItem(TOKEN, token);
};
const getAuthToken = (): { token?: string } => {
    const { TOKEN } = CONSTANTS.cookies;

    const token = localStorage.getItem(TOKEN) || undefined;

    return { token };
};

const setEnableHttpOnlyToken = (enableHttpToken: boolean) => {
    const { ENABLE_HTTP_TOKEN } = CONSTANTS.cookies;

    localStorage.setItem(ENABLE_HTTP_TOKEN, String(enableHttpToken));
};
const getEnableHttpOnlyToken = (): { enableHttpToken?: boolean } => {
    const { ENABLE_HTTP_TOKEN } = CONSTANTS.cookies;

    const enableHttpToken = localStorage.getItem(ENABLE_HTTP_TOKEN) === 'true';

    return { enableHttpToken };
};

const removeAuthToken = async (clearStore?: boolean) => {
    const { TOKEN, AUTH_USER } = CONSTANTS.cookies;

    if (clearStore) {
        Store.dispatch(removeAuthUser());
    }

    localStorage.removeItem(TOKEN);
    localStorage.removeItem(AUTH_USER);
    localStorage.removeItem('COGNITO_AUTH');
    localStorage.clear();
};

export {
    setAuthToken,
    setAuthUserToken,
    removeAuthToken,
    getAuthToken,
    getAuthUserToken,
    setEnableHttpOnlyToken,
    getEnableHttpOnlyToken,
};
