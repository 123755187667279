import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    chipHigh: {
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(1.6),
    },
    chipMedium: {
        backgroundColor: theme.palette.warning.main,
        padding: theme.spacing(1.6),
    },
    chipLow: {
        backgroundColor: theme.palette.info.main,
        padding: theme.spacing(1.6),
    },
    chipStatusPending: {
        backgroundColor: theme.palette.grey[400],
        padding: theme.spacing(1.6),
    },
    chipStatusReproved: {
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(1.6),
    },
    chipStatusApproved: {
        backgroundColor: theme.palette.success.main,
        padding: theme.spacing(1.6),
    },
    iconButton: {
        all: 'initial',
        padding: theme.spacing(1),
        paddingLeft: 0,
        borderRadius: '4px',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
}));
