import { FC, useEffect, useState } from 'react';
import { PricesItem } from 'common/interfaces/price.interface';
import { ItemPricesResponse } from 'clients/price-base/interfaces/item-prices-response.interface';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'common/components/base/breadcrumb';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import { usePageContext } from 'common/components/base/pages/context';
import { useTranslation } from 'react-i18next';
import { addNotificationError } from 'common/utils';
import { usePriceBankContext } from 'modules/priceBank/context/price-list.context';
import { MarketplaceSearchFiltersContextProvider } from 'modules/priceBank/context/search-filters.context';
import {
    GroupedProvider,
    IPricesItem,
    IPricesItemSelected,
    ItemsMetadata,
    Params,
} from './interfaces';
import { FloatContent, Page, FloatContentHeader } from './styled';
import { PriceBaseProps } from './props';
import TableHeader from '../../components/table-header';
import TableList from '../../components/table-list';
import AddItems from '../../components/add-items';

const SearchItems: FC<PriceBaseProps> = () => {
    const { t } = useTranslation();

    const [items, setItems] = useState<IPricesItem>({});
    const [providers, setProviders] = useState<GroupedProvider[]>([]);
    const [itemsMetadata, setItemsMetadata] = useState<ItemsMetadata | undefined>(undefined);

    const [pricesSelected, setPricesSelected] = useState<IPricesItemSelected>({});
    const [firstItemSelected, setFirstItemSelected] = useState<PricesItem | undefined>(undefined);

    const { priceListSelected, setPriceListSelected } = usePriceBankContext();
    const { priceListId = undefined } = useParams<Params>();

    const { setTitle } = usePageContext();

    useEffect(() => {
        setTitle(t('term.search'));
    }, []);

    const getPriceList = async () => {
        try {
            const priceList = await priceListRequests.getPriceList(priceListId as string);
            setPriceListSelected(priceList);
        } catch (error) {
            addNotificationError({
                message: t('mkp.search.error-query-list-price'),
            });
        }
    };

    useEffect(() => {
        if (!priceListSelected && priceListId) {
            getPriceList();
        } else if (!priceListId) {
            setPriceListSelected(undefined);
        }
    }, [priceListId]);

    const createItemList = (currItems: PricesItem[]) => {
        const replacedItems: IPricesItem = currItems.reduce((acc: IPricesItem, cur) => {
            acc[cur.itemId] = cur;
            return acc;
        }, {});

        return replacedItems;
    };

    const onDataFetched = (
        response: ItemPricesResponse,
        resetList: boolean,
        canUpdateMetadata: boolean
    ) => {
        const replacedList = createItemList(response.items || []);

        if (resetList) {
            setProviders(response.providers || []);
            setPricesSelected({});
            setFirstItemSelected(undefined);
            setItems(replacedList);
        } else {
            setProviders((prevState) => [...prevState, ...(response.providers || [])]);
            setItems((prevState) => ({
                ...prevState,
                ...replacedList,
            }));
        }

        // mantem apenas o count atualizado, o restante só é alterado quando muda o texto da pesquisa
        if (canUpdateMetadata) {
            return setItemsMetadata({
                count: response.count,
                max: response.max,
                median: response.median,
                min: response.min,
            });
        }

        return setItemsMetadata((prevState) => ({
            ...prevState,
            count: response.count,
        }));
    };

    const handlePriceSelected = (priceIndex: number, itemId: string) => {
        const { prices } = items[itemId];
        const currentPrice = prices[priceIndex];

        if (!pricesSelected[itemId]) {
            pricesSelected[itemId] = {};
        }

        if (pricesSelected[itemId][currentPrice._id]) {
            delete pricesSelected[itemId][currentPrice._id];

            if (Object.keys(pricesSelected[itemId]).length === 0) {
                delete pricesSelected[itemId];
            }
        } else {
            pricesSelected[itemId][currentPrice._id] = currentPrice;
        }

        const { length } = Object.keys(pricesSelected);

        // gambiarram temporaria
        if (length === 1) {
            setFirstItemSelected(items[itemId]);
        } else if (length === 0) {
            setFirstItemSelected(undefined);
        }

        setPricesSelected({ ...pricesSelected });
    };

    const onItemCreated = () => {
        setPricesSelected({});
    };

    const onItemUpdated = () => {
        setPricesSelected({});
    };

    const resetItemsMetadata = () => {
        setItemsMetadata({
            count: 0,
            max: 0,
            median: 0,
            min: 0,
        });
    };

    return (
        <Page>
            <MarketplaceSearchFiltersContextProvider>
                <FloatContent>
                    <FloatContentHeader>
                        <Breadcrumb
                            params={{
                                priceListId: priceListSelected?.name || '',
                            }}
                            paths={{
                                'banco-precos': {
                                    value: t('shared.pages.menu-header.option.price-bank'),
                                    disabled: true,
                                },
                                pesquisa: t('term.search'),
                                'minhas-listas': t('mkp.search.my-list-price'),
                                editar: t('term.edit'),
                            }}
                        />
                        <AddItems
                            pricesSelected={pricesSelected}
                            onItemCreated={onItemCreated}
                            onItemUpdated={onItemUpdated}
                            firstItemSelected={firstItemSelected}
                        />
                    </FloatContentHeader>
                    <TableHeader
                        pricesSelected={pricesSelected}
                        onDataFetched={onDataFetched}
                        itemsMetadata={itemsMetadata}
                        resetItemsMetadata={resetItemsMetadata}
                    />
                </FloatContent>
                <TableList
                    providers={providers}
                    items={items}
                    onPriceSelected={handlePriceSelected}
                    pricesSelected={pricesSelected}
                />
            </MarketplaceSearchFiltersContextProvider>
        </Page>
    );
};

export default SearchItems;
