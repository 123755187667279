function formatToTimer(value: number) {
    if (value <= 0) return '';

    return `${String(value).padStart(2, '0')}:`;
}

export function formatCountdown(countdownInSeconds: number) {
    const days = parseInt(String(countdownInSeconds / (60 * 60 * 24)), 10);
    const hours = parseInt(String(countdownInSeconds / (60 * 60)), 10) % 24;
    const minutes = parseInt(String(countdownInSeconds / 60), 10) % 60;
    const seconds = countdownInSeconds % 60;

    const daysString = formatToTimer(days);
    const hoursString = formatToTimer(hours);
    const minutesString = `${String(minutes).padStart(2, '0')}:`;
    const secondsString = String(seconds).padStart(2, '0');

    return `${daysString}${hoursString}${minutesString}${secondsString}`;
}
