import React, { memo, ReactElement, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, DialogContent, DialogContentText } from '@material-ui/core';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { AppealStatusType } from 'clients/manager/interfaces/auction-notice.interface';
import { addNotificationApiError } from 'common/utils';

const FinishResourceIntentDialog = ({ children }) => {
    const { process, processForm, setProcessBkpBeforeSave } = useProcessFormContext();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onClick: (ev: any) => {
            (children as any)?.props?.onClick?.(ev);
            ev.stopPropagation();
            setOpen(true);
        },
    });

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);
    };

    const handleConfirm = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);

        if (!process?.id || !processForm) {
            return;
        }

        try {
            const response = await auctionNoticeRequests.finishAppealPhase(process.id);

            if (response?.data?.ok) {
                const newValues = {
                    ...processForm.values,
                    appealStatus: AppealStatusType.closed,
                };
                setProcessBkpBeforeSave(newValues);
                processForm.setValues(newValues);
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    return (
        <>
            {cloneChildren}
            <Dialog open={open} onClose={handleClose} maxWidth='xs'>
                <Box p={1}>
                    <DialogTitle>{t('info.finish-resource-intent-title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color='textPrimary'>
                            {t('info.finish-resource-intent-message')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color='primary' size='small'>
                            {t('term.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleConfirm}
                            autoFocus
                            size='small'
                        >
                            {t('term.close')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default memo(FinishResourceIntentDialog);
