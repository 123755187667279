type TokenData = {
    signInUserSession: {
        accessToken: {
            jwtToken: string;
        };
        idToken: {
            jwtToken: string;
        };
    };
};

export async function connectProviders(event: string, data?: TokenData) {
    const jwtToken = data?.signInUserSession?.accessToken.jwtToken ?? '';
    const idToken = data?.signInUserSession?.idToken.jwtToken ?? '';

    switch (event) {
        case 'signIn':
            localStorage.setItem('COGNITO_AUTH', JSON.stringify({ jwtToken, idToken }));
            window.location.href = `/oauth`;
            break;
        default:
            break;
    }
}
