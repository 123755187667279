import { Buffer } from 'buffer';

const encryptEncodedParam = (value: string | Object) => {
    if (!value) {
        return '';
    }
    if (typeof value === 'object') {
        return Buffer.from(JSON.stringify(value)).toString('base64');
    }

    return Buffer.from(value).toString('base64');
};

const decryptEncodedParam = <T>(value: string): T | undefined => {
    if (!value) {
        return undefined;
    }
    const str = Buffer.from(value, 'base64').toString('ascii');
    let decodedValue = str;

    try {
        const obj = JSON.parse(str);
        decodedValue = obj;
        // eslint-disable-next-line no-empty
    } catch (error) {}

    return decodedValue as unknown as T;
};

export { encryptEncodedParam, decryptEncodedParam };
