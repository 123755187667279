import { OkDto } from 'clients/interfaces/ok.interface';
import { doRequest, priceBaseInstance } from 'clients/api';
import {
    PriceListItemRaw,
    PriceListItemRequest,
    PriceListRequest,
    PriceListResponse,
    PriceListItem,
} from './interfaces/price-list-response.interface';
import { RawItems } from './interfaces/raw-items.response.interface';

const priceListRequests = {
    createPriceList: async (data: PriceListRequest): Promise<PriceListResponse> =>
        doRequest<PriceListResponse>(priceBaseInstance.post(`/price-lists`, data)),
    updatePriceList: async (
        priceListId: string,
        data: PriceListRequest
    ): Promise<PriceListResponse> =>
        doRequest<PriceListResponse>(priceBaseInstance.put(`/price-lists/${priceListId}`, data)),
    getPriceLists: async (): Promise<PriceListResponse[]> =>
        doRequest<PriceListResponse[]>(priceBaseInstance.get('/price-lists')),
    getPriceList: async (priceListId: string): Promise<PriceListResponse> =>
        doRequest<PriceListResponse>(priceBaseInstance.get(`/price-lists/${priceListId}`)),
    createItemsPriceList: async (
        priceListId: string,
        data: PriceListItemRequest[]
    ): Promise<PriceListItemRaw[]> =>
        doRequest<PriceListItemRaw[]>(
            priceBaseInstance.post(`/price-lists/${priceListId}/items`, data)
        ),
    deleteItemPriceList: async (priceListId: string, itemPriceId: string): Promise<OkDto> =>
        doRequest<OkDto>(
            priceBaseInstance.delete(`/price-lists/${priceListId}/items/${itemPriceId}`)
        ),
    updateItemPriceList: async (
        priceListId: string,
        itemPriceId: string,
        data: Partial<PriceListItemRequest>
    ): Promise<OkDto> =>
        doRequest<OkDto>(
            priceBaseInstance.put(`/price-lists/${priceListId}/items/${itemPriceId}`, data)
        ),
    getItemPrices: async (priceListId: string, priceListItemId: string): Promise<PriceListItem> =>
        doRequest<PriceListItem>(
            priceBaseInstance.get(`/price-lists/${priceListId}/items/${priceListItemId}`)
        ),
    getRawItems: async (priceListId: string): Promise<RawItems[]> =>
        doRequest<RawItems[]>(priceBaseInstance.get(`/price-lists/${priceListId}/items`)),
};

export { priceListRequests };
