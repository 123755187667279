import { OkDto } from 'clients/interfaces/ok.interface';
import { doRequest, priceBaseInstance } from 'clients/api';
import { PurgeResume } from 'common/interfaces/purge.interface';

const purgeRequests = {
    getPurgeResume: async (): Promise<PurgeResume> =>
        doRequest<PurgeResume>(priceBaseInstance.get('/purges')),
    createPurgeData: async (beforeAt: number): Promise<PurgeResume> =>
        doRequest<PurgeResume>(priceBaseInstance.post('/purge-datas', { beforeAt })),
    purgeData: async (purgeSource: string): Promise<OkDto> =>
        doRequest<OkDto>(priceBaseInstance.post('/purges', { purgeSource })),
};

export { purgeRequests };
