import i18n from 'common/i18n';
import { ProcessCategoryId } from '../../../clients/manager/interfaces/contract.interface';

const getTranslation = (processCategoryId?: ProcessCategoryId) => {
    return i18n.t(`process-category.${processCategoryId}`, { ns: 'getters' });
};

const getProcessCategoryId = (processCategoryId?: ProcessCategoryId): string => {
    const mapper = {
        [ProcessCategoryId.cessao]: getTranslation(ProcessCategoryId.cessao),
        [ProcessCategoryId.compras]: getTranslation(ProcessCategoryId.compras),
        [ProcessCategoryId.informatica]: getTranslation(ProcessCategoryId.informatica),
        [ProcessCategoryId.internacional]: getTranslation(ProcessCategoryId.internacional),
        [ProcessCategoryId.locacao_imoveis]: getTranslation(ProcessCategoryId.locacao_imoveis),
        [ProcessCategoryId.mao_de_obra]: getTranslation(ProcessCategoryId.mao_de_obra),
        [ProcessCategoryId.obras]: getTranslation(ProcessCategoryId.obras),
        [ProcessCategoryId.servicos]: getTranslation(ProcessCategoryId.servicos),
        [ProcessCategoryId.servicos_engenharia]: getTranslation(
            ProcessCategoryId.servicos_engenharia
        ),
        [ProcessCategoryId.servicos_saude]: getTranslation(ProcessCategoryId.servicos_saude),
    };

    return processCategoryId ? mapper?.[processCategoryId] : '';
};

export { getProcessCategoryId };
