import { priceBaseInstance } from 'clients/api';
import { doRequest, viaCepInstance } from 'clients/external';
import { IRegions } from 'modules/sign-up/pages/sign-up-stepper-organization/props';
import { Address, RegionByIbgeCode } from './interfaces/address.interface';

const addressRequest = {
    getAddress: async (cep: string) => doRequest<Address>(viaCepInstance.get(`${cep}/json/`)),
    getRegions: async (value: string) =>
        doRequest<IRegions[]>(priceBaseInstance.get(`/regions/cities?cityName=${value}`)),
    getRegionByIbgeCode: async (ibgeCode: string) =>
        doRequest<RegionByIbgeCode>(priceBaseInstance.get(`/regions/cities/${ibgeCode}`)),
};

export { addressRequest };
