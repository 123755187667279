import Grid from '@material-ui/core/Grid';
import SegmentsSelector from 'common/components/segments-selector';
import { Button } from 'common/components';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';

const Segments = () => {
    const { handleNext } = useContext(SignUpProviderContext);
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item xs={12}>
                <SegmentsSelector />
            </Grid>
            <Grid item xs={12}>
                <div className='stepper-nav'>
                    <Button size='small' variant='contained' color='primary' onClick={handleNext}>
                        {t('term.save-and-forward')}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default Segments;
