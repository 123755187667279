import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import UnauthenticatedHeader from 'common/components/unauthenticated-header';
import AuthenticatedHeader from 'common/components/authenticated-header';
import SideMenu from './components/side-menu';
import { PageMenuHeaderProps } from './props';
import { Content, DataArea } from './styled';

const PageMenuHeader: FC<PageMenuHeaderProps> = ({ children }) => {
    const { authUser, currentAccessType } = useSelector((state: AppState) => state.authUserState);
    const [visibleMobileMenu, setVisibleMobileMenu] = useState(false);

    return (
        <Content>
            {authUser && currentAccessType && (
                <SideMenu
                    currentAccessType={currentAccessType}
                    visibleMobileMenu={visibleMobileMenu}
                    setVisibleMobileMenu={setVisibleMobileMenu}
                />
            )}
            <DataArea>
                {authUser ? (
                    <AuthenticatedHeader onExpandMenu={() => setVisibleMobileMenu(true)} />
                ) : (
                    <UnauthenticatedHeader />
                )}

                {children}
            </DataArea>
        </Content>
    );
};

export default PageMenuHeader;
