import { memo, useEffect, useState } from 'react';
import { Box, CircularProgress, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Counter from '../time-until-counter';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            color: '#a1a1a1',
            width: '15px !important',
            height: '15px !important',
        },
    })
);

const TimeUntilDispute = () => {
    const { processForm, setProcessBkpBeforeSave, process } = useProcessFormContext();
    const { t } = useTranslation();
    const classes = useStyles();

    const duration = !processForm?.values?.startDateTimeDispute
        ? -1
        : moment
              .duration(
                  moment(moment(processForm?.values?.startDateTimeDispute).add(1, 'minute')).diff(
                      moment()
                  )
              )
              .asSeconds();

    const [finishedTime, setFinishedTime] = useState(false);

    const updateProcess = () => {
        if (!process?.id || !processForm || processForm?.values.biddingStageId === 9) {
            return;
        }

        const newValues: AuctionNotice = {
            ...(processForm?.values as AuctionNotice),
            biddingStageId: 9,
            dateTimeUpdate: new Date().toISOString(),
        };
        setProcessBkpBeforeSave(newValues);
        processForm?.setValues(newValues);
    };

    useEffect(() => {
        if (processForm?.values && finishedTime) {
            updateProcess();
        }
    }, [finishedTime, processForm?.values]);

    if (duration <= 0 || finishedTime) {
        return null;
    }

    return (
        <Box mt={0.5} display='flex' alignItems='center'>
            <Typography
                style={{
                    color: '#3366b3',
                    fontWeight: 500,
                    marginRight: 6,
                }}
                variant='body2'
            >
                {`${t('info.dispute-time')} `}
                <Counter secondsRemaining={duration} onFinishTimer={() => setFinishedTime(true)} />
            </Typography>
            <CircularProgress className={classes.root} />
        </Box>
    );
};

export default memo(TimeUntilDispute);
