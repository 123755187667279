import { useState } from 'react';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { useHistory } from 'react-router';
import {
    DocumentTemplate,
    ListTemplatesParams,
} from 'clients/manager/interfaces/document-templates.interface';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SearchTemplateTableView from './search-template-table-view';
import ModalCreateTemplate from '../modal-create-template';
import ModalEditTemplate from '../modal-edit-template';

const SearchTemplateTable = () => {
    const [templateParams, setTemplateParams] = useState<ListTemplatesParams>();
    const [templateUpdated, setTemplateUpdated] = useState<DocumentTemplate | undefined>();
    const [editingTemplate, setEditingTemplate] = useState<{
        opened: boolean;
        fromTemplateId?: string;
    }>({
        opened: false,
        fromTemplateId: undefined,
    });
    const [creatingTemplate, setCreatingTemplate] = useState<{
        opened: boolean;
        fromTemplateId?: string;
    }>({
        opened: false,
        fromTemplateId: undefined,
    });
    const history = useHistory();

    const queryParams = usePaginatedQuery(templateRequests.getTemplates, templateParams);

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            setTemplateParams({ q: event.target.value });
        }
    };

    const handleEdit = (templateId: string) => {
        setEditingTemplate({ opened: true, fromTemplateId: templateId });
    };

    const handleView = (templateId: string) => {
        history.push(`/processos/editor/modelos/${templateId}`);
    };

    const handleCopy = (templateId: string) => {
        setCreatingTemplate({ opened: true, fromTemplateId: templateId });
    };

    const handleNewTemplate = () => {
        setCreatingTemplate({ opened: true });
    };

    return (
        <PageWrapper>
            {creatingTemplate.opened && (
                <ModalCreateTemplate
                    fromTemplateId={creatingTemplate.fromTemplateId}
                    onCancel={() =>
                        setCreatingTemplate({ opened: false, fromTemplateId: undefined })
                    }
                />
            )}
            {editingTemplate.opened && editingTemplate.fromTemplateId && (
                <ModalEditTemplate
                    fromTemplateId={editingTemplate.fromTemplateId}
                    setTemplateUpdated={setTemplateUpdated}
                    onCancel={() =>
                        setEditingTemplate({ opened: false, fromTemplateId: undefined })
                    }
                />
            )}
            <SearchTemplateTableView
                {...{
                    queryParams,
                    handleChangeFilter,
                    handleEdit,
                    handleCopy,
                    handleView,
                    templateUpdated,
                    handleNewTemplate,
                }}
            />
        </PageWrapper>
    );
};

export default SearchTemplateTable;
