import { TextField } from 'common/components';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { formatAuctionNumber, timeout } from 'common/utils';
import { TypeNextNumber, auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { auctionTypeToLabel } from 'modules/process/components/auction-type-label';
import { processActions } from 'modules/process/process-actions';
import { disableField } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface AccreditationNumberFieldProps {
    updateDebounceProcess: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: string
    ) => void;
}

let previousProcessRuleId: string | undefined;

const AccreditationNumberField: FC<AccreditationNumberFieldProps> = ({ updateDebounceProcess }) => {
    const { processForm, auctionTypeRules, process } = useProcessFormContext();
    const { t } = useTranslation();

    const [accreditationNumber, setAccreditationNumber] = useState(
        processForm?.values.accreditationNumber
    );

    const [requireAutoAccreditationNumber, setRequireAutoAccreditationNumber] = useState(
        Boolean(auctionTypeRules?.generalSettings?.cadastro.numeroModalidadeAutomatico)
    );

    useEffect(() => {
        setAccreditationNumber(processForm?.values.accreditationNumber);
    }, [processForm?.values.accreditationNumber]);

    const getNextAccreditationNumber = async (): Promise<void> => {
        if (!processForm?.values.id) {
            return;
        }

        const response = await auctionNoticeRequests.getNextNumber(
            processForm.values.id,
            TypeNextNumber.accreditation,
            processForm.values.ruleId
        );
        const accreditationNumber = response?.data.number;
        timeout(() => {
            setAccreditationNumber(accreditationNumber);
            processForm?.setFieldValue('accreditationNumber', accreditationNumber);
        }, 300);
    };

    useEffect(() => {
        ReactDOM.unstable_batchedUpdates(() => {
            const processRegistry = auctionTypeRules?.generalSettings?.cadastro;
            const accreditationNumber = processForm?.values.accreditationNumber;
            const ruleId = processForm?.values.ruleId;
            if (
                (processForm &&
                    processRegistry?.numeroModalidadeAutomatico &&
                    !accreditationNumber) ||
                (processRegistry?.numeroModalidadeAutomatico &&
                    previousProcessRuleId !== ruleId &&
                    !!previousProcessRuleId)
            ) {
                setRequireAutoAccreditationNumber(true);
                getNextAccreditationNumber();
            } else if (
                processForm &&
                !processRegistry?.numeroModalidadeAutomatico &&
                accreditationNumber &&
                requireAutoAccreditationNumber
            ) {
                setRequireAutoAccreditationNumber(false);
                processForm?.setFieldValue('accreditationNumber', '');
            }

            if (processForm && !processRegistry?.numeroModalidadeAutomatico) {
                setRequireAutoAccreditationNumber(false);
            }

            previousProcessRuleId = ruleId;
        });
    }, [processForm?.values.ruleId]);

    const handleChangeAccreditationNumber = (event: React.ChangeEvent<any>) => {
        if (
            !auctionTypeRules?.generalSettings?.cadastro?.numeroModalidadeAutomatico &&
            auctionTypeRules?.generalSettings?.cadastro?.formulaNumeroModalidadeAutomatico
        ) {
            setAccreditationNumber(event.target.value);
        } else {
            const value: string = event.target.value.replace(/\D/g, '');
            setAccreditationNumber(formatAuctionNumber(value));
        }
    };

    const getKey = () => {
        if (requireAutoAccreditationNumber) {
            return `${accreditationNumber}:${Number(requireAutoAccreditationNumber)}`;
        }

        return 'auction-number-field';
    };

    return (
        <TextField
            fullWidth
            key={getKey()}
            color='primary'
            disabled={disableField('accreditationNumber', process, requireAutoAccreditationNumber)}
            label={t('term.accreditation-number-form', {
                accreditation: auctionTypeToLabel(processForm?.values?.auctionType).long,
            })}
            name='accreditationNumber'
            inputProps={{
                maxLength: 30,
            }}
            value={accreditationNumber}
            onChange={(event) => {
                handleChangeAccreditationNumber(event);
                updateDebounceProcess(event, 'accreditationNumber');
            }}
            variant='outlined'
        />
    );
};

export default AccreditationNumberField;
