import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipStatusEnabled: {
            backgroundColor: theme.palette.success.main,
            padding: theme.spacing(1),
        },
        chipStatusPending: {
            backgroundColor: theme.palette.grey[500],
            padding: theme.spacing(1),
        },
        chipStatusPartialEnabled: {
            backgroundColor: theme.palette.warning.main,
            padding: theme.spacing(1),
        },
        chipStatusDisabled: {
            backgroundColor: theme.palette.error.main,
            padding: theme.spacing(1),
        },
    })
);

export { useStyles };
