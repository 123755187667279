import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionNoticeOrganizationResume } from 'clients/manager/interfaces/auction-notice.interface';
import { Person } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import { useStyles } from './styles';

interface ChipProvidersCountProps {
    process?: AuctionNoticeOrganizationResume;
}

const ChipProvidersCount: FC<ChipProvidersCountProps> = ({ process }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (process && process?.biddingStageId < 8) {
        return null;
    }

    return (
        <>
            {process?.providersCount ? (
                <Chip
                    onClick={(event) => event.stopPropagation()}
                    className={classes.chip}
                    icon={<Person />}
                    label={process?.providersCount ?? '-'}
                    color='default'
                    size='small'
                    title={`${process?.providersCount} ${t('term.providers')}`}
                />
            ) : undefined}
        </>
    );
};

export default ChipProvidersCount;
