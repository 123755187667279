import PrivateRoute from 'routes/components/private-route';
import SearchDocumentTypes from './pages/search-document-types';
import { SearchDocumentTypesContextProvider } from './pages/search-document-types/context/SearchDocumentTypesContext';

const HolidaysRoutes: JSX.Element[] = [
    <PrivateRoute
        exact
        path='/tipos-documentos'
        key='/tipos-documentos'
        component={() => (
            <SearchDocumentTypesContextProvider>
                <SearchDocumentTypes />
            </SearchDocumentTypesContextProvider>
        )}
    />,
];

export default HolidaysRoutes;
