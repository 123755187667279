import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import { TextField } from 'common/components';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { SearchOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
    ProviderRegistrationUpdateRequest,
    RegistrationUpdateRequests,
} from '../../../../../clients/manager/interfaces/provider-registration-update-requests.interface';
import { providerRegistrationUpdateRequests } from '../../../../../clients/manager/provider-registration-update-request.requests';
import ModalEditRequest from '../modal-edit-request';
import SelectPriority from '../select-priority';
import SelectStatus from '../select-status';
import SelectOperator from '../select-operator';
import RequestRowView from './row-view';

const rowsPerPageOptions = [10, 20];

const RequestsTable: FC = () => {
    const [updateRequests, setUpdateRequests] = useState<ProviderRegistrationUpdateRequest[]>([]);
    const [updateRequestsCount, setUpdateRequestsCount] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [requestFilters, setRequestFilters] = useState<RegistrationUpdateRequests>({
        limit: rowsPerPageOptions[0],
        offset: 0,
    });
    const [textSearch, setTextSearch] = useState('');
    const [fetchingRequests, setFetchingRequests] = useState(false);
    const [selectedUpdateRequest, setSelectedUpdateRequest] = useState<
        ProviderRegistrationUpdateRequest | undefined
    >();

    const { t } = useTranslation();

    useEffect(() => {
        const request = async () => {
            setFetchingRequests(true);

            try {
                const filters: RegistrationUpdateRequests = {
                    limit: requestFilters.limit,
                    offset: requestFilters.offset,
                    params: {},
                };

                if (textSearch) {
                    filters.params = {
                        ...filters.params,
                        q: textSearch,
                    };
                }

                if (Object.keys(requestFilters?.params ?? {}).length) {
                    filters.params = {
                        ...filters.params,
                        ...requestFilters.params,
                    };
                }

                const response =
                    await providerRegistrationUpdateRequests.listProviderRegistrationRequests(
                        filters
                    );
                setUpdateRequests(response?.data || []);
                setUpdateRequestsCount(response?.meta?.count || 0);
                setFetchingRequests(false);
            } catch (error) {
                setFetchingRequests(false);
            }
        };

        request();
    }, [textSearch, requestFilters]);

    const handleChangeRequestFilters = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: string
    ) => {
        setRequestFilters((prev) => ({
            ...prev,
            offset: 0,
            params: {
                ...prev.params,
                [field]: event.target.value,
            },
        }));
    };

    const debounceSearchText = debounce((text: string) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setTextSearch(text);
            setRequestFilters((prevState) => ({
                ...prevState,
                offset: 0,
            }));
        });
    }, 500);

    const handleChangePage = (event: unknown, newPage: number) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setPage(newPage);
            setRequestFilters((prevState) => ({
                ...prevState,
                offset: (requestFilters.limit || 0) * newPage,
            }));
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setRequestFilters((prevState) => ({
                ...prevState,
                limit: parseInt(event.target.value, 10),
            }));
            setPage(0);
        });
    };

    const handleClickView = (request: ProviderRegistrationUpdateRequest) =>
        setSelectedUpdateRequest(request);

    return (
        <>
            {selectedUpdateRequest && (
                <ModalEditRequest
                    registrationUpdateRequest={selectedUpdateRequest}
                    onClose={() => setSelectedUpdateRequest(undefined)}
                    onUpdateRequest={(newRequest) => {
                        setUpdateRequests((prevState) => [
                            ...prevState.map((request) => {
                                if (request.id === newRequest.id) {
                                    return newRequest;
                                }

                                return request;
                            }),
                        ]);
                    }}
                />
            )}
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={4} sm={5}>
                        <TextField
                            fullWidth
                            label={t('info.search-update-requests')}
                            name='process_text'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                debounceSearchText(event.target.value?.toLowerCase())
                            }
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        title={t('process.components.search-process') as string}
                                        aria-label='info'
                                    >
                                        <SearchOutlined />
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={2} sm={4}>
                        <SelectPriority
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleChangeRequestFilters(event, event.target.name)
                            }
                            value={requestFilters.params?.priority}
                        />
                    </Grid>
                    <Grid item md={2} sm={4}>
                        <SelectStatus
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleChangeRequestFilters(event, event.target.name)
                            }
                            value={requestFilters.params?.status}
                        />
                    </Grid>
                    <Grid item md={2} sm={4}>
                        <SelectOperator
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleChangeRequestFilters(event, event.target.name)
                            }
                            value={requestFilters.params?.userId}
                        />
                    </Grid>
                    <Grid item md={2} sm={4}>
                        <KeyboardDatePicker
                            autoOk
                            variant='inline'
                            inputVariant='outlined'
                            label={t('term.date')}
                            name='sendAt'
                            format='DD/MM/yyyy'
                            value={requestFilters.params?.sendAt || null}
                            InputAdornmentProps={{ position: 'end' }}
                            invalidDateMessage={t('info.invalid-format', { ns: 'validation' })}
                            invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                            onChange={(date) => {
                                if (date?.isValid() || date === null) {
                                    setRequestFilters((prev) => ({
                                        ...prev,
                                        params: {
                                            ...prev.params,
                                            sendAt: date ? moment.utc(date).valueOf() : undefined,
                                        },
                                    }));
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Grid container>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('term.type')}</TableCell>
                                        <TableCell>{t('term.supplier')}</TableCell>
                                        <TableCell align='center'>{t('term.priority')}</TableCell>
                                        <TableCell>{t('term.responsible')}</TableCell>
                                        <TableCell>{t('term.solicitation')}</TableCell>
                                        <TableCell align='center'>Status</TableCell>
                                        <TableCell width='120px' />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fetchingRequests && (
                                        <>
                                            {new Array(5).fill(null).map((_, index) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <TableRow key={index}>
                                                    <TableCell colSpan={7} scope='row'>
                                                        <Skeleton height='40px' width='100%' />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )}
                                    {!fetchingRequests &&
                                        updateRequests.map((request) => (
                                            <RequestRowView
                                                request={request}
                                                onClickView={handleClickView}
                                            />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box width={1} justifyContent='flex-end' display='flex'>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                component='div'
                                count={updateRequestsCount}
                                rowsPerPage={requestFilters.limit ?? rowsPerPageOptions[0]}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={t('text.lines-per-page')}
                            />
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default RequestsTable;
