import { Button, Card, Typography } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useStyles from './PlanStyle';

const PlanView = ({
    title,
    benefits,
    priceType,
    value,
    handleSelected,
    selected,
    paymentCondition,
    planName,
    objSelected,
}) => {
    const classes = useStyles(selected);
    const { t } = useTranslation();

    return (
        <Card className={classes.card} onClick={handleSelected}>
            <div
                className={
                    objSelected.name === planName ? classes.cardHeaderBlue : classes.cardHeaderGray
                }
            >
                <div className={classes.cardHeaderDivTextSmall}>
                    {title ? (
                        <div>
                            <Typography className={classes.cardHeaderTextSmallPaymentCondition}>
                                {title}
                            </Typography>
                        </div>
                    ) : null}
                    {paymentCondition === 'pós-pago' ? (
                        <div>
                            <Typography className={classes.cardHeaderTextSmallTag}>
                                O MAIS ESCOLHIDO
                            </Typography>
                        </div>
                    ) : null}
                </div>

                <div className={classes.cardHeaderDivTag}>
                    <Typography variant='h6' className={classes.cardHeaderText}>
                        {paymentCondition}
                    </Typography>
                </div>

                <div className={classes.cardHeaderDivTextLarge}>
                    {priceType === 'monetary' ? (
                        <>
                            <div className={classes.cardPrice}>
                                <strong>R$</strong>
                            </div>
                            <div className={classes.cardPrice}>
                                <strong>{Math.trunc(value)}</strong>
                            </div>
                            <div>
                                <Typography className={classes.cardPriceCents}>
                                    <strong>,{value.toFixed(2).split('.')[1]}</strong>
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.cardPrice}>
                                <strong>{value.toString().replace('.', ',')}</strong>
                            </div>
                            <div className={classes.cardPrice}>
                                <strong>%</strong>
                            </div>
                        </>
                    )}
                </div>

                {paymentCondition === 'pós-pago' ? (
                    <Typography className={classes.cardHeaderTextSmall}>
                        71% dos fornecedores optam por esse plano!
                    </Typography>
                ) : null}
            </div>
            <div className={classes.cardContent}>
                {benefits.map((benefit) => (
                    <div key={`${benefit.title}`} className={classes.benefit}>
                        <div className={classes.iconBenefit}>
                            <Done
                                className={
                                    objSelected.name === planName
                                        ? classes.benefitIconBlue
                                        : classes.benefitIconGray
                                }
                            />
                            <Typography
                                className={
                                    objSelected.name === planName
                                        ? classes.benefitTextBlue
                                        : classes.benefitTextGray
                                }
                            >
                                {benefit.title}
                            </Typography>
                        </div>
                        <Typography className={classes.benefitText}>
                            {benefit.description}
                        </Typography>
                    </div>
                ))}
            </div>
            <div className={classes.divSelectButton}>
                <Button
                    variant='contained'
                    color='primary'
                    className={
                        objSelected.name === planName
                            ? classes.selectButtonBlue
                            : classes.selectButtonGray
                    }
                >
                    {objSelected.name === planName
                        ? t('term.selected.plan')
                        : t('term.select.plan')}
                </Button>
            </div>
        </Card>
    );
};

export default PlanView;
