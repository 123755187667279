import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { GenericResponse, PlainGenericResponse } from '../../interfaces/paginated.interface';
import { ProcessDocumentsTemplates } from '../interfaces/process-documents-templates.interface';

const path = '/documents/process-templates';

const processDocumentsTemplatesRequests = {
    getProcessDocumentTemplate: async (processDocumentTemplateId: string) =>
        doRequest<PlainGenericResponse<ProcessDocumentsTemplates>>(
            managerApiInstance.post(`${path}/getProcessDocumentTemplate`, {
                processDocumentTemplateId,
            })
        ),
    listProcessDocumentTemplates: async (auctionId?: number) => {
        const params: { auctionId?: number } = {};

        if (auctionId) {
            params.auctionId = auctionId;
        }

        return doRequest<GenericResponse<ProcessDocumentsTemplates>>(
            managerApiInstance.post(`${path}/listProcessDocumentsTemplates`, undefined, {
                params,
            })
        );
    },
    createProcessDocumentTemplate: async (
        document: Omit<ProcessDocumentsTemplates, '_id' | 'organizationId'>
    ) =>
        doRequest<PlainGenericResponse<ProcessDocumentsTemplates>>(
            managerApiInstance.post(`${path}/createProcessDocumentTemplate`, document)
        ),
    updateProcessDocumentTemplate: async (
        processDocumentTemplateId: string,
        processDocumentTemplate: ProcessDocumentsTemplates
    ) =>
        doRequest<PlainGenericResponse<ProcessDocumentsTemplates>>(
            managerApiInstance.post(`${path}/updateProcessTemplateDocument`, {
                ...processDocumentTemplate,
                _id: processDocumentTemplateId,
            })
        ),
    deleteProcessDocumentTemplate: async (processDocumentTemplateId: string) =>
        doRequest<PlainGenericResponse<ProcessDocumentsTemplates>>(
            managerApiInstance.post(`${path}/deleteProcessTemplateDocument`, {
                processDocumentTemplateId,
            })
        ),
    uploadImage: async (processDocumentTemplateId: string, file: File) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);

        return doRequest<PlainGenericResponse<{ url: string }>>(
            managerApiUploadFileInstance.post(
                `${path}/uploadFileOnProcessDocumentTemplate`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        processDocumentTemplateId,
                    },
                }
            )
        );
    },
};
export { processDocumentsTemplatesRequests };
