import { contractRequests } from 'clients/manager/contract.requests';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import Yup from 'common/utils/yup-extended.utils';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import ModalRatingContractsView from './modal-rating-contracts-view';
import { ModalRatingContractsProps } from './props';
import { useSearchContractsContext } from '../../../../../modules/contracts/pages/search-contracts/context/search-contracts-context';

const LABELS = {
    1: 'péssimo',
    2: 'ruim',
    3: 'bom',
    4: 'muito bom',
    5: 'excelente',
};

const ModalRatingContracts: FC<ModalRatingContractsProps> = ({
    contractWithDetails,
    onClose,
    open,
}) => {
    const { t } = useTranslation();
    const [contractRatingEdited, setContractRatingEdited] = useState<any>();
    const [loadingContractRating, setLoadingContractRating] = useState(false);
    const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
    const [ratings, setRatings] = useState({
        generalRating: { value: 0, hover: -1 },
        costBenefitRating: { value: 0, hover: -1 },
        contractQualityRating: { value: 0, hover: -1 },
        deadlineComplianceRating: { value: 0, hover: -1 },
    });
    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const { refreshAndResetTable } = useSearchContractsContext();

    const { contract, provider, process } = contractWithDetails;

    const getModalTitle = () =>
        `${t('term.contract')} ${contract.contractNumber} - ${provider.companyName} / ${
            process.accreditationNumber
        }`;

    const handleSubmitForm = async (formValues) => {
        try {
            setLoadingFormSubmit(true);
            const removedEmptyValues = Object.fromEntries(
                Object.entries(formValues).filter(([, value]) => Boolean(value))
            );

            const payload = {
                ...removedEmptyValues,
                generalRating: formValues.generalRating,
                contractId: contract.id,
                userId: authUser?.id,
            };
            const hasContractRatingEdited = Boolean(contractRatingEdited);

            if (hasContractRatingEdited) {
                await contractRequests.updateRatingContract(contractRatingEdited?.id, payload);
            } else {
                await contractRequests.createRatingContract(payload);
            }

            refreshAndResetTable();

            if (onClose) onClose({}, 'backdropClick');

            addNotificationSuccess({
                message: hasContractRatingEdited
                    ? t('contracts.rating.edited')
                    : t('contracts.rating.created'),
                title: t('term.success'),
            });
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoadingFormSubmit(false);
        }
    };

    const form = useFormik({
        initialValues: {
            generalRating: '',
            costBenefitRating: '',
            contractQualityRating: '',
            deadlineComplianceRating: '',
            detail: '',
        },
        validationSchema: Yup.object({
            generalRating: Yup.number().required(t('required-field')),
            costBenefitRating: Yup.number().nullable(),
            contractQualityRating: Yup.number().nullable(),
            deadlineComplianceRating: Yup.number().nullable(),
            detail: Yup.string()
                .max(500, t('notices-information.info.maximum-length', { value: 500 }))
                .nullable(),
        }),
        onSubmit: async (values) => handleSubmitForm(values),
    });

    const handleRatingChange = (key, newValue) => {
        form.setFieldValue(key, newValue);
        setRatings((prevRatings) => ({
            ...prevRatings,
            [key]: { ...prevRatings[key], value: newValue },
        }));
    };

    const handleHoverChange = (key, newHover) =>
        setRatings((prevRatings) => ({
            ...prevRatings,
            [key]: { ...prevRatings[key], hover: newHover },
        }));

    const handleLabelRating = ({ value, hover }) =>
        value ? LABELS[hover !== -1 ? hover : value] : t('contracts.rating.notEvaluated');

    const handleDeleteContractRating = async () => {
        try {
            setLoadingFormSubmit(true);
            await contractRequests.deleteRatingContract(contractRatingEdited?.id);

            if (onClose) onClose({}, 'backdropClick');

            addNotificationSuccess({
                message: t('contracts.rating.deletedContract'),
                title: t('term.delete'),
            });
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoadingFormSubmit(false);
        }
    };

    const getRatingContractInfo = async () => {
        try {
            setLoadingContractRating(true);
            const ratingContractResponse = await contractRequests.getByFilterRatingContract(
                contract.id
            );

            const hasRatingContractResponse = Boolean(ratingContractResponse?.data[0]);

            if (hasRatingContractResponse) {
                setContractRatingEdited(ratingContractResponse?.data[0]);
                const ratingContract = ratingContractResponse?.data[0];

                form.setValues({
                    ...form.values,
                    generalRating: ratingContract?.generalRating,
                    costBenefitRating: ratingContract?.costBenefitRating,
                    contractQualityRating: ratingContract?.contractQualityRating,
                    deadlineComplianceRating: ratingContract?.deadlineComplianceRating,
                    detail: ratingContract?.detail,
                });
                setRatings((prevValuesRatings) => ({
                    ...prevValuesRatings,
                    generalRating: { value: ratingContract?.generalRating, hover: -1 },
                    costBenefitRating: { value: ratingContract?.costBenefitRating, hover: -1 },
                    contractQualityRating: {
                        value: ratingContract?.contractQualityRating,
                        hover: -1,
                    },
                    deadlineComplianceRating: {
                        value: ratingContract?.deadlineComplianceRating,
                        hover: -1,
                    },
                }));
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoadingContractRating(false);
        }
    };

    useEffect(() => {
        getRatingContractInfo();
    }, []);

    return (
        <ModalRatingContractsView
            {...{
                onClose,
                open,
                getModalTitle,
                form,
                ratings,
                handleRatingChange,
                handleHoverChange,
                handleLabelRating,
                loadingContractRating,
                loadingFormSubmit,
                contractRatingEdited,
                handleDeleteContractRating,
            }}
        />
    );
};

export default ModalRatingContracts;
