import { Grid, Breadcrumbs, Card, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import ProviderUsersDialog from './components/SearchProviderUsersDialog';
import SearchProviderUsersTable from './components/SearchProviderUsersTable';

const SearchProviderUsersView = ({ handleNew, providerUsersDialog }) => {
    const { t } = useTranslation();

    return (
        <>
            <PageWrapper>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Breadcrumbs>
                        <Typography>
                            {t('provider-users.pages.components.users-provider')}
                        </Typography>
                    </Breadcrumbs>

                    <Button size='small' variant='contained' color='primary' onClick={handleNew}>
                        {t('term.add')}
                    </Button>
                </Grid>

                <br />

                <Card>
                    <SearchProviderUsersTable />
                </Card>
            </PageWrapper>
            {providerUsersDialog.open && <ProviderUsersDialog />}
        </>
    );
};

export default SearchProviderUsersView;
