import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse } from 'clients/interfaces/paginated.interface';
import { DocumentTypes } from './interfaces/document-types.interface';

export interface DocumentTypesProposal {
    id: number;
    documentStage: string;
    documentName: string;
    documentDescription: string;
    documentSystem: number;
    documentRequired: number;
    canItContainPersonalData: number;
    categoryDocument: {
        id: number;
        categoryName: string;
        description: string;
        dateTimeInsert: string;
        dateTimeUpdate: string;
    };
    documentProposal?: {
        id: number;
        auctionNoticeId: number;
        documentTypeId: number;
    };
}

export interface CreateDocumentType {
    documentName: string;
    documentDescription: string;
    documentSystem?: boolean;
    documentRequired?: boolean;
    categoryDocumentId: number;
}

const path = 'document-types';

const documentTypesRequests = {
    listDocumentTypesToAuctionNoticeProposal: async (auctionId: number) =>
        doRequest<GenericResponse<DocumentTypesProposal>>(
            managerApiInstance.post(`/${path}/listDocumentTypesToAuctionNoticeProposal`, {
                params: { auctionId },
            })
        ),
    // rota na api implementada sem padrão .data
    doSaveDocumentType: async (data: CreateDocumentType) =>
        doRequest<DocumentTypes>(managerApiInstance.post(`/${path}/doSaveDocumentType`, data)),
};

export { documentTypesRequests };
