import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 4,
            backgroundColor: theme.palette.background.paper,
            '& .MuiListItem-root': {
                minWidth: 'initial',
                paddingTop: 2,
                paddingBottom: 2,
            },
            '& .MuiDivider-middle': {
                marginBottom: 2,
            },
        },
        listItemText: {
            fontSize: 14,
        },
    })
);
