import { FC, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Modal, ModalPosition } from 'common/components';
import {
    Contract,
    ContractGeneratedDocument,
    ContractStatus,
    ContractWithDetails,
} from 'clients/manager/interfaces/contract.interface';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';
import { useContractsContext } from 'modules/process/components/process-contracts-data/context/contracts.context';
import EditContractForm from '../edit-contract-form';
import { ModalEditContractProps } from './props';
import { ContentScroll } from './styles';
import { contractRequests } from '../../../../../clients/manager/contract.requests';

const ModalEditContract: FC<ModalEditContractProps> = ({
    contractWithDetails,
    onClose,
    setContracts,
}) => {
    const { steps } = useContractsContext();
    const [contractData, setContractData] = useState<ContractWithDetails | undefined>(undefined);
    const [contractTerm, setContractTerm] = useState({
        terminationNotice: false,
        apostilmentTerm: false,
        addendum: false,
    });

    const { contract, provider, process } = contractData || contractWithDetails;
    const { t } = useTranslation();

    const handleContractAdded = async () => {
        const { data } = await contractRequests.listContractsFromProcess({
            params: { processId: process.id },
        });
        setContracts(data);
    };

    const handleContractUpdated = (data: ContractWithDetails) => {
        setContractData(data);
        setContracts((prevState) =>
            prevState.map((item) => {
                if (item?.contract?.id === data.contract.id) {
                    return {
                        ...data,
                    };
                }

                return item;
            })
        );
    };

    const checkTermTemplate = () => {
        if (steps) {
            setContractTerm({
                terminationNotice: steps.some(
                    (term) => term.documentType === DocumentTemplateType.terminationNotice
                ),
                apostilmentTerm: steps.some(
                    (term) => term.documentType === DocumentTemplateType.apostilmentTerm
                ),
                addendum: steps.some((term) => term.documentType === DocumentTemplateType.addendum),
            });
        }
    };

    const getContractData = async () => {
        const response = await contractRequests.getContract(contract.id);
        setContractData(response.data);
        handleContractUpdated(response.data);
    };
    useEffect(() => {
        getContractData();
        checkTermTemplate();
    }, []);

    const handleContractUploaded = (
        contract: Contract,
        generatedDocument: Pick<ContractGeneratedDocument, 'file'>
    ) => {
        setContracts((prevState) =>
            prevState.map((item) => {
                if (!item?.contract) return item;
                if (item.contract.id === contract.id) {
                    return {
                        ...item,
                        contract: {
                            ...item.contract,
                            status: ContractStatus.generated,
                        },
                        generatedDocument,
                    };
                }

                return item;
            })
        );
        getContractData();
    };

    const handleDeletedGeneratedDocument = () => {
        setContracts((prevState) =>
            prevState.map((item) => {
                if (!item?.contract) return item;
                if (item.contract.id === contract.id) {
                    return {
                        ...item,
                        contract: {
                            ...item.contract,
                            status: ContractStatus.draft,
                        },
                        generatedDocument: undefined,
                    };
                }

                return item;
            })
        );
        getContractData();
    };

    const getModalTitle = () =>
        `${t('term.contract')} ${contract.contractNumber ?? '00'} - ${provider.companyName} / ${
            process.accreditationNumber
        }`;

    return (
        <Modal
            position={ModalPosition.right}
            open
            onClose={onClose}
            header={<span>{getModalTitle()}</span>}
        >
            <ContentScroll>
                <Box maxWidth={800}>
                    <EditContractForm
                        contractWithDetails={contractData || contractWithDetails}
                        onContractUpdated={handleContractUpdated}
                        onContractUploaded={handleContractUploaded}
                        onContractAdded={handleContractAdded}
                        onDeletedGeneratedDocument={handleDeletedGeneratedDocument}
                        contractTerm={contractTerm}
                        onClose={onClose}
                    />
                </Box>
            </ContentScroll>
        </Modal>
    );
};

export default ModalEditContract;
