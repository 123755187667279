import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AccessLikeItemView = ({ onClick }) => {
    const { t } = useTranslation();

    return <MenuItem onClick={onClick}>{t('term.access')}</MenuItem>;
};

export default AccessLikeItemView;
