import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { styled } from 'common/theme/styled';

const FloatContent = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
    padding-top: 20px;
    background: ${({ theme }) => theme.colors.backgroundPageDefault};
`;

const Page = styled(PageWrapper)`
    padding-top: 0 !important;
`;

const FloatContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export { FloatContent, Page, FloatContentHeader };
