import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core';
import { HiOutlineCalendar, HiBookmark, HiOutlineShare, HiCheck } from 'react-icons/hi';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { formatDate } from 'common/utils';
import { NoticeInformation } from 'clients/manager/interfaces/notice-information.interfaces';
import { getLabelChip } from 'modules/notices-information/utils';
import { useHistory } from 'react-router';
import useNoticesInformationContext from 'modules/notices-information/hooks/useNoticesInformation';

const CardContentStyled = styled(CardContent)({
    flex: 10,
    padding: 16,
    maxHeight: '320px',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'var(--background-hover-color)',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--scrollbar-color)',
    },
});

interface CardNoticeInformationViewProps extends NoticeInformation {
    handleEditNoticeInformation: (id: number) => void;
}

const CardNoticeInformationView = (props: CardNoticeInformationViewProps) => {
    const {
        id,
        title,
        resume,
        audience,
        updateAt,
        expirationAt,
        isFixedOnTop,
        coverImage,
        handleEditNoticeInformation,
    } = props;
    const { t } = useTranslation();
    const navigate = useHistory();
    const { authenticatedUserIsAdmin } = useNoticesInformationContext();

    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const publicacaoExpirada = expirationAt && new Date(expirationAt) < new Date();

    const handleShareNoticeInformation = () => {
        setCopiedToClipboard(true);
        navigator.clipboard.writeText(`${location.href}/${Number(id)}`);

        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 3500);
    };

    return (
        <Card
            elevation={0}
            variant='outlined'
            style={{
                position: 'relative',
                minHeight: 512,
                height: '100%',
                borderRadius: 8,
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    top: 12,
                    left: 14,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                {isFixedOnTop && (
                    <Icon color='primary'>
                        <HiBookmark />
                    </Icon>
                )}
                <IconButton
                    disabled={copiedToClipboard}
                    size='small'
                    onClick={handleShareNoticeInformation}
                    title={t('term.share')}
                    style={{
                        backgroundColor: 'var(--primary-color)',
                        color: 'var(--background-color)',
                    }}
                >
                    {!copiedToClipboard ? (
                        <HiOutlineShare size={26} style={{ padding: 4 }} />
                    ) : (
                        <HiCheck size={26} style={{ padding: 4 }} />
                    )}
                </IconButton>
            </Box>

            <Box
                style={{
                    position: 'absolute',
                    top: 12,
                    right: 14,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                    gap: 3,
                }}
            >
                <Chip label={getLabelChip(t, audience).toLowerCase()} color='primary' />
                {authenticatedUserIsAdmin && publicacaoExpirada && expirationAt && (
                    <Chip
                        label={t('notices-information.labels.expired', {
                            dateExpire: formatDate(expirationAt),
                        })}
                        color='primary'
                        style={{ backgroundColor: 'var(--danger-color)' }}
                    />
                )}
            </Box>
            {coverImage ? (
                <CardMedia
                    component='img'
                    height={140}
                    style={{ objectFit: 'cover' }}
                    image={coverImage}
                    title={t('notices-information.labels.news-information-cover-image')}
                />
            ) : (
                <Box style={{ height: 144, backgroundColor: 'var(--secondary-color)' }} />
            )}
            <CardContentStyled>
                <Typography gutterBottom variant='h6' color='secondary'>
                    {title}
                </Typography>
                <Typography variant='body2' color='textPrimary' style={{ textAlign: 'justify' }}>
                    {resume}
                </Typography>
            </CardContentStyled>
            {authenticatedUserIsAdmin ? (
                <CardActions
                    style={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        fullWidth
                        variant='contained'
                        color='default'
                        onClick={() => navigate.push(`/avisos-informacoes/${Number(id)}`)}
                    >
                        {t('notices-information.button.learn-more').toUpperCase()}
                    </Button>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => handleEditNoticeInformation(Number(id))}
                    >
                        {t('notices-information.button.edit-post').toUpperCase()}
                    </Button>
                </CardActions>
            ) : (
                <CardActions
                    style={{
                        padding: 16,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => navigate.push(`/avisos-informacoes/${Number(id)}`)}
                        >
                            {t('notices-information.button.learn-more').toUpperCase()}
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            disabled
                            style={{
                                userSelect: 'none',
                                opacity: 1,
                                color: 'var(--primary-color)',
                            }}
                            variant='text'
                            color='primary'
                            startIcon={<HiOutlineCalendar size={24} />}
                        >
                            {formatDate(updateAt)}
                        </Button>
                    </Box>
                </CardActions>
            )}
        </Card>
    );
};

export default CardNoticeInformationView;
