import { memo, useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    ProviderRegistrationUpdateRequest,
    RegistrationUpdateRequestStatus,
} from 'clients/manager/interfaces/provider-registration-update-requests.interface';
import { AppState } from 'store';
import { providerRegistrationUpdateRequests } from 'clients/manager/provider-registration-update-request.requests';
import { Button } from 'common/components';
import { useParams } from 'react-router';
import moment from 'moment';
import { TypeAccess, TypeUser } from '../../interfaces/user.interface';
import { timeout } from '../../utils/timer.utils';
import ModalSendRequest from './modal-send-view';
import { useProviderUpdateRequestWarningContext } from './context';
import ModalCompareRequest from './modal-compare-view';

const useStyles = makeStyles(() => ({
    message: {
        width: '100%;',
    },
}));

const ProviderUpdateRequestWarning = ({
    setHasUpdate,
}: {
    setHasUpdate?: (open: boolean) => void;
}) => {
    const { currentAccessType, authUserTokenData } = useSelector(
        (state: AppState) => state.authUserState
    );
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const [hasUpdateRequestsPending, setHasUpdateRequestsPending] = useState(false);
    const [hasDraftChanges, setHasDraftChanges] = useState(false);
    const [visibleModalSend, setVisibleModalSend] = useState(false);
    const [visibleModalCompare, setVisibleModalCompare] = useState(false);
    const [requestUpdate, setRequestUpdate] = useState<ProviderRegistrationUpdateRequest[]>([]);
    const { forceHasChanges, setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const { idProvider } = useParams<{ idProvider: string }>();
    const classes = useStyles();
    const { t } = useTranslation();

    const request = async () => {
        const draft = await providerRegistrationUpdateRequests.getRegistrationUpdateRequest({
            params: { status: RegistrationUpdateRequestStatus.draft },
        });

        const pending = await providerRegistrationUpdateRequests.getRegistrationUpdateRequest({
            params: { status: RegistrationUpdateRequestStatus.pending },
        });

        setHasDraftChanges(draft?.data.ok);
        setHasPendingChanges(pending?.data.ok);
    };

    const requestById = async () => {
        await providerRegistrationUpdateRequests
            .getRegistrationUpdateRequestById(Number(idProvider))
            .then((res) => {
                if (res.data.length) {
                    setRequestUpdate(res.data);
                    setHasUpdateRequestsPending(true);
                }
            });
    };

    useEffect(() => {
        if (currentAccessType === TypeUser.provider) {
            timeout(request, 500);
        }

        if (authUserTokenData?.typeAccess === TypeAccess.management) {
            timeout(requestById, 500);
        }
    }, [currentAccessType, forceHasChanges]);

    if (!hasPendingChanges && !hasDraftChanges && !forceHasChanges && !hasUpdateRequestsPending) {
        return null;
    }
    return (
        <>
            {visibleModalSend && (
                <ModalSendRequest
                    onSendRequest={() => {
                        setHasPendingChanges(false);
                        setForceHasChanges(false);
                    }}
                    onClose={() => {
                        setVisibleModalSend(false);
                    }}
                />
            )}
            {visibleModalCompare && (
                <ModalCompareRequest
                    onClose={() => {
                        setVisibleModalCompare(false);
                    }}
                    setHasUpdate={setHasUpdate}
                    requestUpdate={requestUpdate}
                    setHasUpdateRequestsPending={setHasUpdateRequestsPending}
                />
            )}
            {(hasDraftChanges || hasPendingChanges || hasUpdateRequestsPending) && (
                <Box mb={2}>
                    <Alert
                        severity='info'
                        variant='standard'
                        style={{
                            border: '1px solid #349ff3',
                        }}
                        classes={{
                            message: classes.message,
                        }}
                    >
                        <Box
                            width={1}
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            {hasDraftChanges && (
                                <Typography variant='body2'>
                                    {t('info.provider-pending-changes')}
                                </Typography>
                            )}
                            {hasPendingChanges && (
                                <Typography variant='body2'>
                                    {t('info.pending-update-request')}
                                </Typography>
                            )}
                            {hasUpdateRequestsPending && (
                                <Typography variant='body2'>
                                    {t('info.update-request-warning-1') +
                                        requestUpdate[0].id +
                                        t('info.update-request-warning-to') +
                                        t(`term.${requestUpdate[0].type}`) +
                                        t('info.update-request-warning-2') +
                                        moment(requestUpdate[0].sendAt).format('DD/MM/YYYY HH:mm') +
                                        t('info.update-request-warning-3') +
                                        t(`term.${requestUpdate[0].priority}`) +
                                        t('info.update-request-warning-4')}
                                </Typography>
                            )}
                            {hasDraftChanges && (
                                <Button
                                    variant='contained'
                                    size='xsmall'
                                    onClick={() => setVisibleModalSend(true)}
                                >
                                    {t('term.send')}
                                </Button>
                            )}
                            {hasUpdateRequestsPending && (
                                <Button
                                    variant='contained'
                                    size='xsmall'
                                    onClick={() => setVisibleModalCompare(true)}
                                    style={{ width: 120 }}
                                >
                                    {t('term.open-request')}
                                </Button>
                            )}
                        </Box>
                    </Alert>
                </Box>
            )}
        </>
    );
};

export default memo(ProviderUpdateRequestWarning);
