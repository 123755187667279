import PrivateRoute from 'routes/components/private-route';
import { ProviderUpdateRequestWarningContextProvider } from 'common/components/provider-update-request-warning/context';
import { hasCurrentAccessType } from 'common/utils';
import { TypeAccess } from '../../common/interfaces/user.interface';
import ProviderDashboard from './pages/provider-dashboard';

const GroupAuthorizationRoutes: JSX.Element[] = [
    <PrivateRoute
        exact
        path='/painel-fornecedor'
        key='/painel-fornecedor'
        canAccess={() => hasCurrentAccessType([TypeAccess.provider])}
        component={() => (
            <ProviderUpdateRequestWarningContextProvider>
                <ProviderDashboard />
            </ProviderUpdateRequestWarningContextProvider>
        )}
    />,
];

export default GroupAuthorizationRoutes;
