import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import { ProcessDocumentsStepsResume } from '../../../clients/manager/documents/process-documents-steps.requests';

export type ProcessDocumentsStepsContextType = {
    processDocumentsSteps: ProcessDocumentsStepsResume[];
    setProcessDocumentsSteps: React.Dispatch<React.SetStateAction<ProcessDocumentsStepsResume[]>>;
};

export const ProcessDocumentsStepsContext = createContext<ProcessDocumentsStepsContextType>({
    processDocumentsSteps: [],
    setProcessDocumentsSteps: () => {},
});

export const useProcessDocumentsStepsContext = () => useContext(ProcessDocumentsStepsContext);

export const ProcessDocumentsStepsContextProvider = ({ children }: { children: ReactNode }) => {
    const [processDocumentsSteps, setProcessDocumentsSteps] = useState<
        ProcessDocumentsStepsResume[]
    >([]);

    const processDocumentsStepsRef =
        useRef<
            Pick<
                ProcessDocumentsStepsContextType,
                'processDocumentsSteps' | 'setProcessDocumentsSteps'
            >
        >();
    processDocumentsStepsRef.current = { processDocumentsSteps, setProcessDocumentsSteps };

    return (
        <ProcessDocumentsStepsContext.Provider
            value={{
                processDocumentsSteps: processDocumentsStepsRef.current.processDocumentsSteps,
                setProcessDocumentsSteps: processDocumentsStepsRef.current.setProcessDocumentsSteps,
            }}
        >
            {children}
        </ProcessDocumentsStepsContext.Provider>
    );
};
