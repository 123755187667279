export enum ProviderValueStatus {
    not_callable = 'not_callable',
    callable = 'callable',
    called = 'called',
    not_covered = 'not_covered',
    covered = 'covered',
    re_covered = 're_covered',
    winner = 'winner',
    winner_unclassified = 'winner_unclassified',
    winner_disabled = 'winner_disabled',
    provider_declined = 'provider_declined',
    tied = 'tied',
}
