import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { TextField } from 'common/components';
import { CONSTANTS } from 'common/constants';

const StateRegistrationNumber = ({
    form,
    exemptStateRegistration,
    setExemptStateRegistration,
    t,
    country,
    disabled = false,
}) => {
    if (country !== CONSTANTS.ibgeCodes.BRAZIL) return <></>;
    return (
        <>
            <Grid item xs={2}>
                <TextField
                    variant='outlined'
                    disabled={exemptStateRegistration || disabled}
                    fullWidth
                    label={t('term.state-registration-number')}
                    name='stateCompanyRegistrationNumber'
                    value={form.values.stateCompanyRegistrationNumber ?? ''}
                    onChange={form.handleChange}
                    error={
                        form.touched.stateCompanyRegistrationNumber &&
                        Boolean(form.errors.stateCompanyRegistrationNumber)
                    }
                    helperText={
                        form.touched.stateCompanyRegistrationNumber &&
                        form.errors.stateCompanyRegistrationNumber
                    }
                />
            </Grid>
            <Grid item xs={1}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color='secondary'
                            disabled={disabled}
                            checked={form.values.stateCompanyRegistrationNumber === 'Isento'}
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                form.setFieldValue(
                                    'stateCompanyRegistrationNumber',
                                    isChecked ? 'Isento' : ''
                                );
                                setExemptStateRegistration(isChecked);
                            }}
                        />
                    }
                    label='Isento'
                />
            </Grid>
        </>
    );
};

export default StateRegistrationNumber;
