import {
    ListSupplyCategoriesFilters,
    SupplyCategory,
} from 'clients/manager/interfaces/supply-categories.interface';
import { supplyCategoriesRequests } from 'clients/manager/supply-categories.requests';
import React, { useEffect, useState } from 'react';
import SupplyCategoryAutocompleteView from './SupplyCategoryAutocompleteView';

interface SupplyCategoryAutocompleteProps {
    handleOnChange: (category: SupplyCategory) => void;
    label?: string;
    level?: number;
    value?: SupplyCategory;
}

const SupplyCategoryAutocomplete: React.FC<SupplyCategoryAutocompleteProps> = ({
    handleOnChange,
    label,
    level = 2,
    value,
}) => {
    const [supplyCategories, setSupplyCategories] = useState<SupplyCategory[]>([]);

    useEffect(() => {
        const getSupplyCategories = async () => {
            const filters: ListSupplyCategoriesFilters = {
                params: {
                    level,
                },
            };

            const response = await supplyCategoriesRequests.listSupplyCategories(filters);
            setSupplyCategories(response.data);
        };
        getSupplyCategories();
    }, []);

    return (
        <SupplyCategoryAutocompleteView {...{ supplyCategories, handleOnChange, value, label }} />
    );
};

export default SupplyCategoryAutocomplete;
