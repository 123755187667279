import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    TypeOfBenefitValue,
    TypeOfItemsValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import { Button, Modal } from 'common/components';
import { CurrencyInput } from 'common/components/currency';
import HelpIconLabel from 'modules/process/components/help-icon-label';
import TypeOfBenefit from 'modules/process/components/type-benefit';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'common/components/loading-button';
import { isUndefined } from 'lodash';

interface ModalBulkChangesLotsViewProps {
    onClose: () => void;
    formHasChanges: boolean;
    disabledFields?: boolean;
    getDefaultChecked: (field: string) => any;
    handleFieldChanged: (field: string, value: any) => void;
    getMenuTypeOfItems: () => JSX.Element[];
    form: any;
    process: any;
    processRules: any;
    auctionTypeRules: any;
    processForm: any;
    disableField: (field: string, values: any) => boolean;
    getDecimalPlaces: (decimalPlaces: number) => number;
    updating: boolean;
    handleClickCancel: () => void;
    handleClickConfirmChanges: () => void;
}

const ModalBulkChangesLotsView: React.FC<ModalBulkChangesLotsViewProps> = ({
    onClose,
    formHasChanges,
    disabledFields,
    getDefaultChecked,
    handleFieldChanged,
    getMenuTypeOfItems,
    form,
    process,
    processRules,
    auctionTypeRules,
    processForm,
    disableField,
    getDecimalPlaces,
    updating,
    handleClickCancel,
    handleClickConfirmChanges,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            open
            onClose={onClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
        >
            <Box p={2} width={1}>
                <Box width={1} mb={2}>
                    {formHasChanges ? (
                        <Alert severity='error'>
                            <Typography variant='body2'>
                                {t('info.bulk-update-with-changes')}
                            </Typography>
                        </Alert>
                    ) : (
                        <Alert severity='warning'>
                            <Typography variant='body2'>
                                {t('process.components.alert-changes')}
                            </Typography>
                        </Alert>
                    )}
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id='select'
                            color='primary'
                            disabled={disabledFields ?? true}
                            label={t('term.type-itens')}
                            defaultValue={getDefaultChecked('typeOfItems')}
                            onChange={(event) =>
                                handleFieldChanged('typeOfItems', event.target.value)
                            }
                            variant='outlined'
                            select
                        >
                            <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                            {getMenuTypeOfItems()}
                        </TextField>
                    </Grid>
                    {(form.values.typeOfItems === TypeOfItemsValue.properties ||
                        form.values.typeOfItems === TypeOfItemsValue.furniture) && (
                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth
                                color='primary'
                                defaultValue={form.values.propertyOrFurnitureCode}
                                label={
                                    form.values.typeOfItems === TypeOfItemsValue.properties
                                        ? t('term.estate-properties-code')
                                        : t('term.heritage-code')
                                }
                                onChange={(event) =>
                                    handleFieldChanged(
                                        'propertyOrFurnitureCode',
                                        event.target.value
                                    )
                                }
                                variant='outlined'
                                multiline
                            />
                        </Grid>
                    )}
                    {process &&
                        processRules.visibleLotTypeOfBenefit(
                            auctionTypeRules,
                            processForm?.values
                        ) && (
                            <Grid item xs={12} md={4}>
                                <TypeOfBenefit
                                    omitOptions={[
                                        TypeOfBenefitValue.reservedQuota,
                                        TypeOfBenefitValue.masterQuota,
                                    ]}
                                    showDoNotChanges
                                    defaultValue={getDefaultChecked('typeOfBenefit')}
                                    onChange={(typeOfBenefit) =>
                                        handleFieldChanged('typeOfBenefit', typeOfBenefit)
                                    }
                                    disabled={disabledFields}
                                    auctionType={process.auctionType}
                                    value={TypeOfBenefitValue.doNotChange}
                                />
                            </Grid>
                        )}
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id='select'
                            color='primary'
                            label={t('process.components.require-mark-manufacturer')}
                            variant='outlined'
                            select
                            disabled={disabledFields}
                            defaultValue={getDefaultChecked('markIsRequired')}
                            onChange={(event) =>
                                handleFieldChanged('markIsRequired', Boolean(event.target.value))
                            }
                        >
                            <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                            <MenuItem value={1}>{t('term.yes')}</MenuItem>
                            <MenuItem value={0}>{t('term.no')}</MenuItem>
                        </TextField>
                    </Grid>
                    {processRules.visibleLotConfidentialValue(
                        auctionTypeRules,
                        processForm?.values
                    ) && (
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                id='select'
                                disabled={disabledFields}
                                color='primary'
                                label={t('process.components.confidential-value')}
                                variant='outlined'
                                select
                                defaultValue={getDefaultChecked('showReferenceValue')}
                                onChange={(event) =>
                                    handleFieldChanged(
                                        'showReferenceValue',
                                        Boolean(event.target.value)
                                    )
                                }
                            >
                                <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                                <MenuItem value={1}>{t('term.yes')}</MenuItem>
                                <MenuItem value={0}>{t('term.no')}</MenuItem>
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                        <HelpIconLabel title={t('info.force-same-description.help')}>
                            <TextField
                                fullWidth
                                id='select'
                                disabled={disabledFields}
                                color='primary'
                                label={t('info.force-same-description')}
                                variant='outlined'
                                select
                                defaultValue={getDefaultChecked('forceSameDescription')}
                                onChange={(event) =>
                                    handleFieldChanged(
                                        'forceSameDescription',
                                        Boolean(event.target.value)
                                    )
                                }
                            >
                                <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                                <MenuItem value={1}>{t('term.yes')}</MenuItem>
                                <MenuItem value={0}>{t('term.no')}</MenuItem>
                            </TextField>
                        </HelpIconLabel>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id='select'
                            disabled={disabledFields}
                            color='primary'
                            label={t('info.require-documents')}
                            variant='outlined'
                            select
                            defaultValue={getDefaultChecked('requireDocuments')}
                            onChange={(event) =>
                                handleFieldChanged('requireDocuments', Boolean(event.target.value))
                            }
                        >
                            <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                            <MenuItem value={1}>{t('term.yes')}</MenuItem>
                            <MenuItem value={0}>{t('term.no')}</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CurrencyInput
                            allowNegative={false}
                            prefix='R$ '
                            decimalSeparator=','
                            disabled={disableField('bidAmountDifference', processForm?.values)}
                            precision={getDecimalPlaces(process?.decimalPlaces)}
                            thousandSeparator='.'
                            label={t('process.components.value-between-bids')}
                            variant='outlined'
                            value={form.values.bidAmountDifference ?? 0}
                            defaultValue={
                                form.values.bidAmountDifference ||
                                auctionTypeRules?.generalSettings?.cadastro?.intervaloEntreLances
                            }
                            color='primary'
                            onChange={(_, value: number) =>
                                handleFieldChanged('bidAmountDifference', value)
                            }
                            component={TextField}
                        />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='flex-end' mt={3}>
                    <Button
                        variant='outlined'
                        color='primary'
                        title={t('term.cancel')}
                        onClick={handleClickCancel}
                        size='small'
                        style={{ marginRight: '16px' }}
                    >
                        {t('term.cancel')}
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color='primary'
                        title={t('process.components.apply-alterations-all-proccess')}
                        onClick={handleClickConfirmChanges}
                        size='small'
                        disabled={Object.values(form.values).every((value) => isUndefined(value))}
                        {...(updating
                            ? {
                                  loading: {
                                      text: `${t('term.updating')}..`,
                                  },
                              }
                            : {})}
                    >
                        {t('term.apply-changes')}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalBulkChangesLotsView;
