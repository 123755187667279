import React from 'react';
import { IconButton } from '@material-ui/core';
import { ListMaterialData } from 'modules/material/interfaces/material.interface';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import SearchMaterialsModal from './components/search-materials-modal';
import { StyledBadge } from './materials-input-style';

interface MaterialsInputViewProps {
    defaultValue?: string;
    badgeContent?: string;
    onChange: (value: ListMaterialData | null) => void;
    isOpen?: boolean;
    onOpenModal?: () => void;
    onCloseModal?: () => void;
}

const MaterialsInputView: React.FC<MaterialsInputViewProps> = ({
    isOpen = false,
    defaultValue,
    badgeContent,
    onChange,
    onOpenModal,
    onCloseModal,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <IconButton
                onClick={onOpenModal}
                aria-label={t('term.materials-catalog')}
                title={t('term.materials-catalog')}
            >
                <StyledBadge badgeContent={badgeContent} color='primary'>
                    <AiOutlineFileSearch size={22} />
                </StyledBadge>
            </IconButton>
            <SearchMaterialsModal
                open={isOpen}
                code={defaultValue}
                onClose={onCloseModal}
                onChange={onChange}
            />
        </>
    );
};

export default MaterialsInputView;
