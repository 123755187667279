import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import {
    CreateMarketplaceCartItemBody,
    CreateMarketplaceCartItemResponse,
    DeleteMarketplaceCartItemBody,
    DeleteMarketplaceCartItemResponse,
    MarketplaceCartResponse,
    UpdateMarketplaceCartItemQuantityBody,
    UpdateMarketplaceCartItemQuantityResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';

const BASE_URL = '/marketplace-cart';

const marketplaceCartRequests = {
    getMarketplaceCart: async () =>
        doRequest<PlainGenericResponse<MarketplaceCartResponse>>(
            managerApiInstance.post(`${BASE_URL}/getCart`)
        ),
    createMarketplaceCartItem: async (
        body?: CreateMarketplaceCartItemBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<PlainGenericResponse<CreateMarketplaceCartItemResponse>>(
            managerApiInstance.post(`${BASE_URL}/createItem`, body, config)
        ),
    updateMarketplaceCartItemQuantity: async (
        body?: UpdateMarketplaceCartItemQuantityBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<PlainGenericResponse<UpdateMarketplaceCartItemQuantityResponse>>(
            managerApiInstance.post(`${BASE_URL}/updateItemQuantity`, body, config)
        ),
    deleteMarketplaceCartItem: async (
        body?: DeleteMarketplaceCartItemBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<PlainGenericResponse<DeleteMarketplaceCartItemResponse>>(
            managerApiInstance.post(`${BASE_URL}/deleteItem`, body, config)
        ),
};

export { marketplaceCartRequests };
