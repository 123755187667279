import { Scroll } from 'common/components/base/scroll';
import { styled } from 'common/theme/styled';

const Content = styled(Scroll)<{
    minimized: boolean;
    bgColor?: string;
    expanded: boolean;
    mobile: boolean;
}>`
    background: ${({ theme, bgColor }) => bgColor || theme.colors.background};
    width: 305px;
    min-width: 305px;
    border-right: 1px ${({ theme }) => theme.colors.borderColorDefault} solid;
    transition: background-color 0.5s ease;
    overflow-y: auto;
    height: 100vh;
    overflow-x: hidden;

    ${({ mobile }) =>
        mobile &&
        `
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 16777271;
            width: 100%;
        `}

    ${({ expanded, minimized, mobile }) =>
        minimized &&
        expanded &&
        !mobile &&
        `
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 16777271;
        `}

    ::-webkit-scrollbar {
        width: 5px;
    }

    ${({ minimized, expanded, theme, mobile, bgColor }) =>
        minimized &&
        !expanded &&
        !mobile &&
        `
            width: 70px;
            min-width: 70px;            
            background: ${bgColor || theme.colors.primary};
        `}
`;

const OptionGroup = styled.div`
    &:nth-child(n + 2) {
        margin: 10px 0 0 0;
    }
`;

const OptionGroupItem = styled.div<{
    showBorder: boolean;
    expanded: boolean;
    minimized: boolean;
    mobile: boolean;
}>`
    margin: 0 18px 5px 18px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textLight};
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;

    ${({ showBorder, theme }) =>
        showBorder &&
        `
        border-bottom: 1px ${theme.colors.borderColorDefault} solid;
    `}

    ${({ expanded, minimized, mobile }) =>
        !expanded &&
        minimized &&
        !mobile &&
        `
        color: transparent;
    `}
`;

const Option = styled.div<{
    expanded: boolean;
    minimized: boolean;
    mobile: boolean;
    disabled: boolean;
    color?: string;
}>`
    display: flex;
    align-items: center;
    padding: 3px 16px;
    height: 38px;
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        `
            opacity: 0.5; 
            cursor: not-allowed;
    `}

    ${({ expanded, minimized, mobile }) =>
        !expanded &&
        minimized &&
        !mobile &&
        `
        justify-content: center;
    `};

    &:hover {
        background: ${({ theme }) => theme.colors.backgroundHoverLight};
    }

    svg {
        width: 24px;
        height: 24px;
        margin: 5px;
        color: ${({ color }) => color ?? '#777'};
    }

    span {
        margin: 0 0 0 6px;
        color: ${({ theme }) => theme.colors.textLight};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 13px;
        text-transform: capitalize;
    }

    a {
        text-decoration: none;
    }
`;

const LogoExpanded = styled.img`
    height: 50px;
    margin: 18px 16px 0 16px;
`;

const LogoMinimized = styled.img`
    width: 50px;
    margin: 18px 16px 0 16px;
`;

export { Content, Option, OptionGroup, OptionGroupItem, LogoExpanded, LogoMinimized };
