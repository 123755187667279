import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import {
    formatCurrency,
    getContractStatus,
    getProcessCategoryId,
    getSignatureMethod,
} from 'common/utils';
import { useTranslation } from 'react-i18next';
import { ListContractsByOrderIdResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import RatingIndicator from 'modules/marketplace/components/rating-indicator';
import { GrDocumentConfig } from 'react-icons/gr';
import SearchContractsActions from 'modules/contracts/pages/search-contracts/components/search-contracts-actions/search-contracts-actions';
import { useStyles } from './marketplace-contract-card-style';
import ContractItemsTable from './components/contract-items-table';

interface MarketplaceContractCardViewProps {
    contractWithDetails?: ListContractsByOrderIdResponse;
    loading?: boolean;
}

const MarketplaceContractCardView: React.FC<MarketplaceContractCardViewProps> = ({
    contractWithDetails,
    loading = false,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Paper
            variant='outlined'
            key={contractWithDetails?.contract.id}
            className={styles.cardContainer}
        >
            <Grid container spacing={2} direction='column' className={styles.cardContentContainer}>
                <Grid item>
                    <Box className={styles.processInfoContainer}>
                        {contractWithDetails && (
                            <SearchContractsActions
                                contractWithDetails={contractWithDetails}
                                icon={
                                    <span className={styles.contractWithDetailsContainer}>
                                        <GrDocumentConfig size={20} />
                                        <Typography
                                            variant='body2'
                                            className={styles.textProcessInfo}
                                        >
                                            {t('info.view-edit')}
                                        </Typography>
                                    </span>
                                }
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography className={styles.title} variant='body1'>
                                        {contractWithDetails?.provider?.companyName}
                                    </Typography>
                                    <Typography variant='body2'>
                                        {contractWithDetails?.contract.additionalInformation}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={styles.titlePriceLabel} variant='body2'>
                                {t('marketplace.contract.total-value')}
                            </Typography>
                            <Typography className={styles.titlePrice} variant='body1'>
                                {contractWithDetails?.contract?.accumulatedValue
                                    ? formatCurrency(contractWithDetails.contract.accumulatedValue)
                                    : '--'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} className={styles.listInfoContainer}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.contract-status')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {getContractStatus(contractWithDetails?.contract.status)}
                                    </Typography>
                                </Grid>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.contract-category')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {getProcessCategoryId(
                                            contractWithDetails?.contract.processCategoryId
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.signture-method')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {getSignatureMethod(
                                            contractWithDetails?.contract.signtureMethod
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.process-ID')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {contractWithDetails?.contract.auctionNoticeId}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={styles.infoItemLabel} variant='body2'>
                                {t('marketplace.contract.contract-assessment')}
                            </Typography>
                            <RatingIndicator
                                actual={
                                    contractWithDetails?.contract?.assessment?.generalRating ?? 0
                                }
                                max={5}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ContractItemsTable
                contractItems={contractWithDetails?.contract?.items}
                loading={loading}
            />
        </Paper>
    );
};

export default MarketplaceContractCardView;
