import { Box } from '@material-ui/core';
import { orderBy } from 'lodash';
import ProcessMessage from 'modules/bidding/components/process-notice';
import { memo, useEffect, useState } from 'react';
import { ProcessTransaction } from '../../../../../../clients/manager/interfaces/process-transactions.interface';
import { processTransactionsRequests } from '../../../../../../clients/manager/process-transactions.requests';
import { useProcessFormContext } from '../../../../context/process-form.context';
import ButtonCreateMessage from '../button-create-message';

const MessagesList = () => {
    const [processTransactions, setProcessTransactions] = useState<ProcessTransaction[]>([]);

    const { process } = useProcessFormContext();

    const handleCreateTransaction = (transaction: ProcessTransaction) => {
        setProcessTransactions((state) => [...state, transaction]);
    };

    const listTransactions = async () => {
        if (!process?.id) {
            return;
        }

        const response = await processTransactionsRequests.listProcessTransactions({
            params: {
                processId: process.id,
            },
        });

        setProcessTransactions(response?.data || []);
    };

    useEffect(() => {
        listTransactions();
    }, [process]);

    return (
        <Box>
            <Box display='flex' justifyContent='flex-end'>
                <ButtonCreateMessage onCreateTransaction={handleCreateTransaction} />
            </Box>
            <Box width={1} mt={1}>
                {orderBy(processTransactions, 'dateTimeTransaction', 'desc').map((transaction) => (
                    <Box pt={0.5}>
                        <ProcessMessage date={new Date(transaction.dateTimeTransaction)}>
                            <div
                                style={{ fontSize: 14 }}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: transaction.description || '' }}
                            />
                        </ProcessMessage>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default memo(MessagesList);
