import { FC, useEffect, useState } from 'react';
import {
    Accordion,
    Box,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    makeStyles,
    AccordionSummary,
    Avatar,
    IconButton,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Button, TextField } from 'common/components';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { stringToColor } from 'common/utils';
import {
    ProcessDocumentsStepsSigner,
    ProcessDocumentsStepsStatus,
} from 'clients/manager/interfaces/process-documents-steps.interface';
import { userRequests } from 'clients/manager/user.requests';
import { DeleteIcon, ExpandMoreIcon } from 'common/icons';
import { OrganizationUser } from 'clients/manager/interfaces/user.interface';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { debounce } from 'lodash';
import { ProcessDocumentsStepsResume } from 'clients/manager/documents/process-documents-steps.requests';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiListItemIcon-root': {
            minWidth: 30,
        },
        margin: 0,
        border: 0,
    },
    icon: {
        width: 28,
        height: 28,
        fontSize: 15,
    },
    accordion: {
        '& .MuiAccordionSummary-content': {
            margin: '12px 0',
            height: '40px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
        borderRadius: 4,
        border: '1px #e3e3e3 solid',
    },
    accordionHeader: {
        backgroundColor: '#4b4b4b08',
    },
}));

interface FormDefaultSignersProps {
    signers: ProcessDocumentsStepsSigner[];
    onChange: (signers: ProcessDocumentsStepsSigner[]) => void;
    processDocumentsStep: ProcessDocumentsStepsResume | undefined;
}

const FormDefaultSigners: FC<FormDefaultSignersProps> = ({
    signers,
    onChange,
    processDocumentsStep,
}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<OrganizationUser[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const { process } = useProcessFormContext();
    const classes = useStyles();
    const { t } = useTranslation();

    const listUsers = async () => {
        if (!process) return;
        setLoading(true);

        try {
            const response = await userRequests.listUsersBelongToOrganizationUnit({
                params: { q: searchText, organizationUnitId: process?.organizationUnitId },
            });
            setOptions(response?.data ?? []);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        listUsers();
    }, [searchText]);

    const handleChangeText = (event) => {
        setSearchText(event.target.value);
    };

    useEffect(() => {
        if (!open) {
            return setOptions([]);
        }
    }, [open]);

    const handleClickAddUser = (orgUser: OrganizationUser) => {
        const newSigners: ProcessDocumentsStepsSigner[] = [
            ...signers,
            {
                userId: orgUser.id,
                name: orgUser.name,
                email: orgUser.email,
            },
        ];

        ReactDOM.unstable_batchedUpdates(() => {
            if (options.length === 1) {
                setOpen(false);
            }

            onChange(newSigners);
            setSearchText('');
        });
    };

    const userIsSelected = (userId: number) => signers.some((signer) => signer.userId === userId);

    const handleClickDelete = (signer: ProcessDocumentsStepsSigner) => {
        const newSigners: ProcessDocumentsStepsSigner[] = signers.filter(
            (s) => s.userId !== signer.userId
        );
        onChange(newSigners);
    };

    const cannotChangeSigners = () => {
        if (!processDocumentsStep?.status) return false;
        return (
            processDocumentsStep?.status !== ProcessDocumentsStepsStatus.draft &&
            processDocumentsStep?.status !== ProcessDocumentsStepsStatus.generated
        );
    };

    return (
        <Accordion
            classes={{ root: classes.accordion }}
            elevation={0}
            TransitionProps={{ unmountOnExit: true, timeout: 200 }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                    root: classes.accordionHeader,
                }}
            >
                <Box display='flex' alignItems='center'>
                    <Typography variant='body2'>
                        {t('info.define-signers')} - {`(${signers.length})`}
                    </Typography>
                </Box>
            </AccordionSummary>
            <Box width={1} p={2}>
                <Grid container>
                    <Grid item xs={12}>
                        <Autocomplete
                            onClose={() => {
                                setSearchText('');
                                setOpen(false);
                            }}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            options={options}
                            disabled={cannotChangeSigners()}
                            loading={loading}
                            renderOption={(option) => {
                                const optionSelected = userIsSelected(option.id);

                                return (
                                    <Box
                                        onClick={(ev) => ev.stopPropagation()}
                                        width={1}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='space-between'
                                    >
                                        <Typography variant='body2'>{option.name}</Typography>
                                        {optionSelected ? (
                                            <Button
                                                disabled
                                                size='xsmall'
                                                variant='contained'
                                                color='secondary'
                                            >
                                                {t('term.added')}
                                            </Button>
                                        ) : (
                                            <Button
                                                size='xsmall'
                                                variant='contained'
                                                color='primary'
                                                onClick={() => handleClickAddUser(option)}
                                            >
                                                {t('term.add')}
                                            </Button>
                                        )}
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={searchText}
                                    onChange={debounce(
                                        (event: React.ChangeEvent<any>) => handleChangeText(event),
                                        500
                                    )}
                                    label={
                                        cannotChangeSigners()
                                            ? 'Não é possível adicionar assinantes após ter enviado para assinatura'
                                            : 'Buscar usuário'
                                    }
                                    variant='outlined'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? (
                                                    <CircularProgress color='inherit' size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Box mt={2} width={1}>
                        {signers.length > 0 ? (
                            <List dense disablePadding>
                                {signers?.map((signer) => (
                                    <>
                                        <ListItem
                                            key={signer.userId}
                                            classes={{ root: classes.root }}
                                            button
                                            style={{
                                                margin: '3px 0',
                                                border: 0,
                                            }}
                                        >
                                            <ListItemIcon>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={signer.userId}
                                                        style={{
                                                            backgroundColor: stringToColor(
                                                                signer.name
                                                            ),
                                                        }}
                                                        classes={{ root: classes.icon }}
                                                        title={`${signer.name} - ${signer.email}`}
                                                        alt={signer.name}
                                                    >
                                                        {signer.name.charAt(0).toUpperCase()}
                                                    </Avatar>
                                                </ListItemAvatar>
                                            </ListItemIcon>
                                            <ListItemText
                                                secondary={
                                                    <>
                                                        <Typography
                                                            variant='body2'
                                                            color='textPrimary'
                                                        >
                                                            {signer.email}
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge='end'
                                                    size='small'
                                                    aria-label='delete'
                                                    onClick={() => handleClickDelete(signer)}
                                                    disabled={cannotChangeSigners()}
                                                >
                                                    <DeleteIcon
                                                        style={{
                                                            fontSize: '1.3rem',
                                                        }}
                                                    />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider component='li' />
                                    </>
                                ))}
                            </List>
                        ) : (
                            <Typography variant='body2'>{t('info.no-signers-added')}.</Typography>
                        )}
                    </Box>
                </Grid>
            </Box>
        </Accordion>
    );
};

export default FormDefaultSigners;
