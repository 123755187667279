import React, { memo } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PieChartCustomTooltip from '../custom-tooltip';
import PieChartCustomLegend from '../custom-legend';
import { BlankArea } from '../item-count-total-amount-pca-style';

interface ChartItemCountAndTotalAmountByPCAProps {
    consolidatedData?: Array<any>;
}

const ChartItemCountAndTotalAmountByPCA = (props: ChartItemCountAndTotalAmountByPCAProps) => {
    const { consolidatedData } = props;
    const { t } = useTranslation();
    const COLORS = [
        '#f66364',
        '#f5b04d',
        '#71c989',
        '#bac1c4',
        '#93bfeb',
        '#f5aa85',
        '#66c2a3',
        '#80d840',
    ];

    if (!consolidatedData || consolidatedData.length === 0) {
        return (
            <Box width='100%' height='400px'>
                <Typography variant='subtitle1' align='center' style={{ marginBottom: '16px' }}>
                    {t('hiring-plans.legend.estimated-total-quantity-items-category')}
                </Typography>
                <BlankArea border>
                    <h1>{t('hiring-plans.info.chart-empty-list-items-pca')}</h1>
                    <h3>
                        {t('hiring-plans.info.chart-empty-list-items-pca-instruction', {
                            button: t('hiring-plans.labels.new-item-pca'),
                        })}
                    </h3>
                </BlankArea>
            </Box>
        );
    }

    return (
        <Box width='100%' height='400px'>
            <Typography variant='subtitle1' align='center' style={{ marginBottom: '16px' }}>
                {t('hiring-plans.legend.estimated-total-quantity-items-category')}
            </Typography>
            <ResponsiveContainer width='100%' height='85%'>
                <PieChart>
                    <Tooltip content={<PieChartCustomTooltip />} />
                    <Legend verticalAlign='bottom' height={36} content={<PieChartCustomLegend />} />
                    <Pie
                        isAnimationActive
                        animationEasing='ease-out'
                        data={consolidatedData}
                        dataKey='quantidadeItens'
                        nameKey='categoriaItemNome'
                        cy='50%'
                        cx='50%'
                        innerRadius={30}
                        outerRadius={70}
                    >
                        {consolidatedData.map((value, index) => (
                            <Cell
                                key={`cell-graph-quantity${value.categoriaItemId}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                    <Pie
                        isAnimationActive
                        animationEasing='ease-out'
                        data={consolidatedData}
                        dataKey='valorTotal'
                        nameKey='categoriaItemNome'
                        cy='50%'
                        cx='50%'
                        innerRadius={90}
                        outerRadius={120}
                    >
                        {consolidatedData.map((value, index) => (
                            <Cell
                                key={`cell-graph-amount-${value.categoriaItemId}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default memo(ChartItemCountAndTotalAmountByPCA);
