import { Modal, ModalPosition } from '@licitar/ui-kit';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PriceListPdf from '../price-list-pdf';
import { ModalGenerateDocProps } from './props';

const ModalGenerateDoc: FC<ModalGenerateDocProps> = ({ priceList, type, onClose }) => {
    const getRenderByType = () => {
        switch (type) {
            case 'pdf':
                return <PriceListPdf priceList={priceList} />;

            default:
                return <></>;
        }
    };

    const { t } = useTranslation();

    return (
        <Modal
            width='460px'
            opened
            onClickOutSide={onClose}
            position={ModalPosition.center}
            title={t('mkp.user-price-list.generate-list-price')}
        >
            {getRenderByType()}
        </Modal>
    );
};

export default ModalGenerateDoc;
