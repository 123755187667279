/* eslint-disable no-nested-ternary */
import { Avatar, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const ProcessMessageContainer = styled(Paper)<{
    avatar?: boolean;
    maxwidth?: boolean;
    answer?: boolean;
}>`
    position: relative;
    padding: 1rem;
    padding-right: 2rem;
    padding-bottom: 1.5rem;
    margin-top: 10px;
    border-left: 4px solid
        ${({ answer }) => (answer ? 'var(--tertiary-color)' : 'var(--primary-color)')};
    white-space: pre-line;
    margin-bottom: 0.75rem;
    width: ${({ maxwidth }) => (maxwidth ? 'initial' : 'fit-content')};
    min-width: 8rem;
    margin-left: ${({ avatar, answer }) => (avatar ? '3rem' : answer ? '1rem' : 0)};
    width: 100%;

    p {
        margin: 0;
    }

    > span {
        position: absolute;
        bottom: -0.75rem;
        left: -4px;
        line-height: 1.5rem;
        font-size: 0.75rem;
        padding: 0 0.75rem 0 0.5rem;
        border-radius: 2rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 8px;
        color: var(--background-color);
        background-color: ${({ answer }) =>
            answer ? 'var(--tertiary-color)' : 'var(--primary-color)'};
        width: fit-content;
        white-space: nowrap;
    }
`;

export const ProcessMessageAvatar = styled(Avatar)`
    position: absolute;
    background-color: var(--secondary-color);
    border: 2px solid var(--primary-color);
    left: -3.25rem;
    top: 0;
`;

export const ProcessChipsContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    > div {
        color: var(--secondary-color);
        padding: 0.5rem;

        > svg {
            width: 0.8rem;
        }
    }
`;
