import { useFormik } from 'formik';
import { Button, Input } from '@licitar/ui-kit';
import { FC, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Breadcrumb from 'common/components/base/breadcrumb';
import { usePageContext } from 'common/components/base/pages/context';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    decryptEncodedParam,
    internalRedirect,
    addNotificationError,
    addNotificationSuccess,
    getBackofficeRedirectUrl,
    apiRedirectTo,
} from 'common/utils';
import { YupValidationMessages } from 'common/constants/yup-validation-messages.constant';
import { AppState } from 'store';
import { processDocumentsTemplatesRequests } from 'clients/manager/documents/process-documents-templates.requests';
import { ProcessDocumentsTemplates } from 'clients/manager/interfaces/process-documents-templates.interface';
import TemplateSelector from '../../components/template-selector';
import { Page, Header, Content, CardForm, CardInformations, Title, CardContent } from './styles';
import { CreateProcessDocumentTemplateProps } from './props';
import { getProccessDocumentRedirectUrl } from '../../redirect-url';

const CreateProcessDocumentTemplate: FC<CreateProcessDocumentTemplateProps> = () => {
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const { currentAccessType } = useSelector((state: AppState) => state.authUserState);

    const initialValues: Partial<ProcessDocumentsTemplates> = {
        content: '',
        name: '',
        fromTemplateId: undefined,
        description: '',
    };

    const redirectNextPage = (processDocumentTemplateId: string) =>
        history.push(
            `/processos/editor/modelo-documento/${processDocumentTemplateId}?${query.toString()}`
        );

    const getAuctionData = useCallback(() => {
        const encodedAuction = query.get('auction');
        return decryptEncodedParam<{ id: number; auctionNumber: string; auctionType: string }>(
            encodedAuction ?? ''
        );
    }, [query]);

    const createDocument = async (values: ProcessDocumentsTemplates) => {
        const auctionData = getAuctionData();

        try {
            if (!auctionData?.id) {
                return addNotificationError({
                    title: t('term.err'),
                    message: t('editor.process.pages.create'),
                });
            }

            const response = await processDocumentsTemplatesRequests.createProcessDocumentTemplate({
                content: '',
                fromTemplateId: values.fromTemplateId,
                name: values.name,
                auctionId: Number(auctionData.id),
                processTypes: values.processTypes,
                type: values.type,
                supplyCategories: values.supplyCategories,
            });
            addNotificationSuccess({
                title: t('term.success'),
                message: t('term.created-document'),
            });
            redirectNextPage(response?.data?._id);
        } catch (error) {
            addNotificationError({
                title: t('term.err'),
                message: t('editor.process.pages.erro-create-document'),
            });
        }
    };

    useEffect(() => {
        setTitle(t('editor.process.pages.create-process.info-breadcrumb'));
    }, []);

    const form = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required(YupValidationMessages.REQUIRED),
            fromTemplateId: Yup.string().required(YupValidationMessages.REQUIRED),
            processTypes: Yup.array()
                .of(Yup.number())
                .min(1)
                .required(YupValidationMessages.REQUIRED),
            supplyCategories: Yup.array()
                .of(Yup.number())
                .min(1)
                .required(YupValidationMessages.REQUIRED),
            type: Yup.string().required(YupValidationMessages.REQUIRED),
            description: Yup.string().required(YupValidationMessages.REQUIRED),
        }),
        onSubmit: (values) => {
            createDocument(values as ProcessDocumentsTemplates);
        },
    });

    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    return (
        <Page>
            <Header>
                <Breadcrumb
                    paths={{
                        'modelo-documento': {
                            value: t('editor.process.pages.create-process.info-breadcrumb'),
                            disabled: true,
                        },
                    }}
                    params={{
                        auction: {
                            value: getAuctionData()?.auctionNumber ?? '',
                            onClick: () => {
                                const auctionData = getAuctionData();
                                if (!auctionData) {
                                    return;
                                }
                                const url = apiRedirectTo(
                                    backofficeRedirectUrl,
                                    getProccessDocumentRedirectUrl(
                                        '/',
                                        auctionData?.id,
                                        auctionData?.auctionType
                                    ),
                                    false,
                                    true
                                ) as string;

                                if (currentAccessType) {
                                    return internalRedirect(
                                        backofficeRedirectUrl,
                                        url,
                                        currentAccessType,
                                        true
                                    );
                                }

                                internalRedirect(backofficeRedirectUrl, url, undefined, true);
                            },
                        },
                    }}
                    customPath='/:auction/modelo-documento'
                />
            </Header>
            <Content>
                <CardContent>
                    <CardInformations>
                        <Title>{t('term.informations')}</Title>
                        <p style={{ color: '#777' }}>
                            {t('editor.process.pages.create-process.info-term')}
                        </p>
                        <ul>
                            <li>{t('term.contracts')}</li>
                            <li>Atas</li>
                            <li>{t('term.public-notices')}</li>
                            <li>{t('term.terms-of-reference')}</li>
                        </ul>
                    </CardInformations>
                    <CardForm>
                        <Title>{t('term.document-template')}</Title>
                        <div style={{ display: 'flex', width: '100%', margin: '16px 0 15px 0' }}>
                            <Input
                                style={{ width: '50%', margin: '0 10px 0 0 ' }}
                                label='ID'
                                name='auctionId'
                                disabled
                                value={getAuctionData()?.id}
                            />
                            <Input
                                style={{ width: '50%' }}
                                label={t('term.auction-number')}
                                name='auctionNumber'
                                disabled
                                value={getAuctionData()?.auctionNumber}
                            />
                        </div>
                        <Input
                            style={{ width: '100%', margin: '0 0 15px 0' }}
                            label={t('term.doc-description')}
                            name='description'
                            value={form.values.description}
                            onChange={(ev) => form.setFieldValue('description', ev.target.value)}
                        />
                        <div style={{ width: '100%' }}>
                            <TemplateSelector
                                name='template'
                                label={t('term.base-model')}
                                selectedTemplateId={form.values.fromTemplateId}
                                onSelectedTemplate={(template) => {
                                    form.setValues({
                                        ...form.values,
                                        fromTemplateId: template?._id,
                                        processTypes: template?.processTypes,
                                        type: template?.type,
                                        name: template?.name,
                                        supplyCategories: template?.supplyCategories,
                                    });
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                title={t('term.advance')}
                                disabled={!form.dirty || !!Object.keys(form.errors).length}
                                style={{
                                    margin: '15px 0 0 0',
                                }}
                                onClick={() => form.submitForm()}
                            >
                                {t('term.advance')}
                            </Button>
                        </div>
                    </CardForm>
                </CardContent>
            </Content>
        </Page>
    );
};

export default CreateProcessDocumentTemplate;
