import { Breadcrumbs, Card, Grid, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ModalCatalogs from '../modal-catalogs';
import SearchMaterialTable from './components/SearchMaterialTable';

interface SearchMaterialViewProps {
    modalCatalogsOpen: boolean;
    setModalCatalogsOpen: (open: boolean) => void;
}

const SearchMaterialView: React.FC<SearchMaterialViewProps> = ({
    modalCatalogsOpen,
    setModalCatalogsOpen,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleNew = () => {
        history.push('/materiais/novo');
    };

    return (
        <PageWrapper>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Breadcrumbs>
                    <Typography>{t('material.pages.title')}</Typography>
                </Breadcrumbs>

                <Grid item>
                    <Button
                        size='small'
                        variant='text'
                        color='primary'
                        disableRipple
                        onClick={() => setModalCatalogsOpen(true)}
                    >
                        {`${t('material.pages.edit.material-catalog')}s`}
                    </Button>

                    <Button size='small' variant='contained' color='primary' onClick={handleNew}>
                        {t('term.new')}
                    </Button>
                </Grid>
            </Grid>

            <br />

            <Card>
                <SearchMaterialTable />
            </Card>

            {modalCatalogsOpen && <ModalCatalogs handleClose={() => setModalCatalogsOpen(false)} />}
        </PageWrapper>
    );
};

export default SearchMaterialView;
