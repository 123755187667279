import styled from 'styled-components';

export const Container = styled.footer`
    width: 100%;
    padding: 20px 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .MuiButton-root {
        text-transform: initial;
        font-size: 16px;
        font-weight: normal;
    }

    @media (max-width: 1024px) {
        justify-content: center;
    }

    @media (max-width: 580px) {
        flex-direction: column;
        gap: 0;
        .MuiButton-root {
            font-size: 14px;
        }
    }
`;
