import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { invoiceRequests } from 'clients/manager/invoice.request';
import { useEffect, useState } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { useTranslation } from 'react-i18next';
import { ListProviderInvoicesParams } from 'clients/manager/interfaces/invoice.interface';
import { StatusPayment } from 'common/enums';
import SearchInvoicesView from './search-invoices-view';

const SearchInvoices = () => {
    const [invoicesParams, setInvoicesParams] = useState<ListProviderInvoicesParams>();

    const { t } = useTranslation();
    const { setTitle } = usePageContext();

    const queryProps = usePaginatedQuery(invoiceRequests.listProviderInvoices, invoicesParams);

    const handleOnChangeStatusPayment = (statusPayment: StatusPayment) => {
        setInvoicesParams({
            statusPayment,
        });
    };

    useEffect(() => {
        setTitle(t('term.invoices'));
    });

    return (
        <SearchInvoicesView
            queryParams={queryProps}
            onChangeStatusPayment={handleOnChangeStatusPayment}
            statusPayment={invoicesParams?.statusPayment}
        />
    );
};

export default SearchInvoices;
