import { TextField } from 'common/components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAuctionNumber } from 'common/utils';
import { disableField } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';

export interface UnenforceableNumberFieldProps {
    updateDebounceProcess: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: string
    ) => void;
}

const UnenforceableNumberField: FC<UnenforceableNumberFieldProps> = ({ updateDebounceProcess }) => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    const [unenforceableNumber, setUnenforceableNumber] = useState<string | null | undefined>(
        processForm?.values.unenforceableNumber
    );

    useEffect(() => {
        setUnenforceableNumber(processForm?.values.unenforceableNumber);
    }, [processForm?.values.unenforceableNumber]);

    const handleChangeUnenforceableNumber = (event: React.ChangeEvent<any>) => {
        const value: string = event.target.value.replace(/\D/g, '');
        setUnenforceableNumber(formatAuctionNumber(value));
    };

    return (
        <TextField
            fullWidth
            color='primary'
            label={t('term.unenforceable-number')}
            name='unenforceableNumber'
            inputProps={{
                maxLength: 11,
            }}
            value={unenforceableNumber}
            onChange={(event) => {
                handleChangeUnenforceableNumber(event);
                updateDebounceProcess(event, 'unenforceableNumber');
            }}
            variant='outlined'
            disabled={disableField('unenforceableNumber', process)}
        />
    );
};

export default UnenforceableNumberField;
