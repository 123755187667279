import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { HiringPlansListRequest } from 'clients/manager/interfaces/hiring-plans.interfaces';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { addNotificationError } from 'common/utils';
import AdvancedSearchFiltersAsideView from './advanced-search-filters-aside-view';

export interface AdvancedSearchFiltersAsideProps {
    showAdvancedFilters: boolean;
}

const AdvancedSearchFiltersAside: React.FC<AdvancedSearchFiltersAsideProps> = (props) => {
    const { setHiringPlansList } = useHiringPlansContext();

    const { t } = useTranslation();
    const [isLoadingSubmitForm, setIsLoading] = useState(false);

    const form = useFormik<HiringPlansListRequest>({
        initialValues: {
            year: '',
            status: '',
        },
        validationSchema: yup.object({
            year: yup.string().length(4, t('hiring-plans.info.field-enter-full-year-search-pca')),
            status: yup.string(),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const valuesFormatted: HiringPlansListRequest = {
                    year: values.year && values.year.length > 0 ? values.year : undefined,
                    status: values.status && values.status.length > 0 ? values.status : undefined,
                };

                const response = await hiringPlansRequests.getAllHiringPlans({
                    ...valuesFormatted,
                });
                setHiringPlansList(response);
            } catch (err) {
                addNotificationError({
                    title: t('term.error'),
                    message: t('hiring-plans.info.create-pca-error'),
                });
            } finally {
                setIsLoading(false);
            }
        },
    });

    const handlerResetAllForm = () => {
        form.resetForm();
    };

    return (
        <AdvancedSearchFiltersAsideView
            {...props}
            form={form}
            isLoadingSubmitForm={isLoadingSubmitForm}
            handlerResetAllForm={handlerResetAllForm}
        />
    );
};

export default AdvancedSearchFiltersAside;
