import { useEffect } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { processInvitesRequests } from 'clients/manager/process-invites.requests';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import ProviderDashboardView from './provider-dashboard-view';

const ProviderDashboard = () => {
    const { setTitle } = usePageContext();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const { data } = useQueryWithBody(
        processInvitesRequests.getProviderPendingInvitesVerification,
        authUserTokenData
    );
    const invites = data && data?.data.length > 0 ? data?.data : null;

    useEffect(() => {
        setTitle('Painel');
    }, []);

    return <ProviderDashboardView invites={invites} />;
};

export default ProviderDashboard;
