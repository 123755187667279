import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    JudgmentCriterion,
    MethodDispute,
} from 'clients/manager/interfaces/auction-notice.interface';
import { disableField } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';
import SelectJudgmentCriterionView from './select-judgment-criterion-view';

interface SelectJudgmentCriterionProps {}

const SelectJudgmentCriterion: FC<SelectJudgmentCriterionProps> = () => {
    const { processForm, auctionTypeRules, process } = useProcessFormContext();
    const { t } = useTranslation();

    const judgmentCriterion = processForm?.values.judgmentCriterion;

    const judgmentCriterionOptions = useMemo(
        () => [
            {
                label: t('term.lowest-price'),
                value: JudgmentCriterion.lowestPrice,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioMenorPreco,
            },
            {
                label: `${t('term.lowest-price')} - ${t('term.rate')}`,
                value: JudgmentCriterion.lowestPriceRate,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioMenorPrecoTaxa,
            },
            {
                label: t('term.highest-discount'),
                value: JudgmentCriterion.highestDiscount,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioMaiorDesconto,
            },
            {
                label: t('term.highest-price'),
                value: JudgmentCriterion.highestPrice,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioMaiorPreco,
            },
            {
                label: t('term.highest-economic-return'),
                value: JudgmentCriterion.highestEconomicReturn,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioMaiorRetornoEconomico,
            },
            {
                label: t('term.artistic-content'),
                value: JudgmentCriterion.artisticContent,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioConteudoArtistico,
            },
            {
                label: t('term.best-technique'),
                value: JudgmentCriterion.bestTechnique,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioMelhorTecnica,
            },
            {
                label: t('term.technique-and-price'),
                value: JudgmentCriterion.techniqueAndPrice,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioMelhorTecnicaEPreco,
            },
            {
                label: t('term.not-applicable'),
                value: JudgmentCriterion.notApplicable,
                enabled: auctionTypeRules?.generalSettings?.cadastro?.criterioNaoSeAplica,
            },
        ],
        [auctionTypeRules, processForm?.values]
    );

    const handleOnChangeJudgmentCriterion = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event?.target?.value;

        if (value === JudgmentCriterion.lowestPriceRate) {
            processForm?.setValues({
                ...processForm.values,
                judgmentCriterion: 1,
                rateTypeBid: 1,
                typeValueBid: null,
            });
            return;
        }

        processForm?.setFieldValue('judgmentCriterion', value);
        processForm?.setFieldValue('rateTypeBid', 0);

        if (
            Number(value) === JudgmentCriterion.bestTechnique ||
            Number(value) === JudgmentCriterion.techniqueAndPrice
        ) {
            processForm?.setFieldValue('methodDispute', MethodDispute.fechado);
        }
    };

    useEffect(() => {
        const foundJudgmentCriterion = judgmentCriterionOptions.find(
            (criterion) => criterion.value === judgmentCriterion
        );

        if (!auctionTypeRules || !foundJudgmentCriterion) {
            return;
        }

        if (!foundJudgmentCriterion.enabled) {
            processForm?.setFieldValue('judgmentCriterion', null);
        }
    }, [auctionTypeRules]);

    const isRateType =
        judgmentCriterion === JudgmentCriterion.lowestPrice &&
        processForm?.values.rateTypeBid === 1;

    const options = judgmentCriterionOptions.filter((item) => !!item.enabled);

    return (
        <SelectJudgmentCriterionView
            defaultValue={isRateType ? JudgmentCriterion.lowestPriceRate : judgmentCriterion}
            disabled={disableField('judgmentCriterion', process)}
            options={options}
            onChange={handleOnChangeJudgmentCriterion}
        />
    );
};

export default SelectJudgmentCriterion;
