import React from 'react';
import { useMarketplaceContext } from 'modules/marketplace/context/marketplace.context';
import {
    ListSupplyCategoriesByOrganizationResponse,
    ListMarketplaceProcessResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import MarketplaceFiltersView from './marketplace-filters-view';

interface MarketplaceFiltersProps {
    processes?: ListMarketplaceProcessResponse[];
    categories?: ListSupplyCategoriesByOrganizationResponse[];
    loading?: boolean;
    totalItemsFound?: number;
}

const MarketplaceFilters: React.FC<MarketplaceFiltersProps> = ({
    processes = [],
    categories = [],
    loading = false,
    totalItemsFound = 0,
}) => {
    const { filters, setFilters } = useMarketplaceContext();

    const isFilterSelected = (type: 'process' | 'category', id: number) => {
        if (type === 'process') {
            return filters.processesIds.some((processId) => processId === id);
        }
        if (type === 'category') {
            return filters.categoriesIds.some((processId) => processId === id);
        }

        return false;
    };

    const handleOnSelectFilter = (type: 'process' | 'category', id: number) => {
        const isSelected = isFilterSelected(type, id);

        if (type === 'process') {
            if (isSelected) {
                return setFilters((state) => ({
                    ...state,
                    processesIds: [...state.processesIds.filter((processId) => processId !== id)],
                }));
            }
            return setFilters((state) => ({
                ...state,
                processesIds: [...state.processesIds, id],
            }));
        }

        if (type === 'category') {
            if (isSelected) {
                return setFilters((state) => ({
                    ...state,
                    categoriesIds: [
                        ...state.categoriesIds.filter((categoryId) => categoryId !== id),
                    ],
                }));
            }
            return setFilters((state) => ({
                ...state,
                categoriesIds: [...state.categoriesIds, id],
            }));
        }
    };

    const totalSelectedFilters = filters.categoriesIds.length + filters.processesIds.length;

    return (
        <MarketplaceFiltersView
            processes={processes}
            categories={categories}
            loading={loading}
            totalItemsFound={totalItemsFound}
            totalSelectedFilters={totalSelectedFilters}
            onSelectFilter={handleOnSelectFilter}
            isFilterSelected={isFilterSelected}
        />
    );
};

export default MarketplaceFilters;
