import { FC, useState } from 'react';
import ItemPriceModel from 'modules/priceBank/search/components/item-price-model';
import { ItemPriceProps } from './props';

const ItemPrice: FC<ItemPriceProps> = ({ price, provider }) => {
    const [checked, setChecked] = useState(false);

    return (
        <ItemPriceModel
            checked={checked}
            setChecked={setChecked}
            price={price}
            provider={provider}
            showSelectItem={false}
            onSelected={() => {}}
        />
    );
};

export default ItemPrice;
