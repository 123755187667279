import { providerRequests } from 'clients/manager/provider.requests';
import { TextField, TextFieldProps } from 'common/components';
import { FormikProps } from 'formik';
import React, { Dispatch, FC, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { storage, validateCnpj, validateCpf } from 'common/utils';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';
import { CONSTANTS } from 'common/constants';
import { IdentificationTaxRegimeOptions } from 'modules/sign-up/pages/sign-up-stepper-provider/props';

interface IdentificationNumberTextFieldProps extends FormikProps<{ docNumber: string } & any> {
    identificationNumber: string | undefined;
    country: string;
    fieldName: string;
    type?: 'cpf' | 'cnpj' | 'cpfCnpj';
    InputProps?: object;
    disabled?: boolean;
    resetSearch?: any;
    setLoading?: Dispatch<SetStateAction<boolean>>;
    setExemptStateRegistration?: Dispatch<SetStateAction<boolean>>;
    onBlur?: TextFieldProps['onBlur'];
}
export const mask = (idNumber: string | undefined, country: string) => {
    if (country !== CONSTANTS.ibgeCodes.BRAZIL) return idNumber;
    if (!idNumber) {
        idNumber = '';
    }
    idNumber = idNumber.replace(/\D/g, '');

    if (idNumber.length <= 11) {
        idNumber = idNumber.replace(/(\d{3})(\d)/, '$1.$2');
        idNumber = idNumber.replace(/(\d{3})(\d)/, '$1.$2');
        idNumber = idNumber.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
        idNumber = idNumber.replace(/^(\d{2})(\d)/, '$1.$2');
        idNumber = idNumber.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        idNumber = idNumber.replace(/\.(\d{3})(\d)/, '.$1/$2');
        idNumber = idNumber.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return idNumber;
};

const IdentificationNumberTextField: FC<IdentificationNumberTextFieldProps> = ({
    identificationNumber,
    resetForm,
    fieldName,
    country,
    type,
    InputProps,
    setFieldValue,
    setExemptStateRegistration,
    setLoading,
    touched,
    errors,
    disabled,
    resetSearch,
    onBlur,
}) => {
    const {
        setUserProviderExist,
        setDocNumberTextError,
        hasFoundedCnpjWsInfo,
        setCnpjWsProviderInfo,
        setHasFoundedCnpjWsInfo,
    } = useContext(SignUpProviderContext);

    const providerApiLocalStorageKey = 'provider-cnpjws-api-data';

    const validateUser = async (ev: React.FocusEvent<HTMLInputElement>) => {
        const docValue = ev.target.value
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('_', '')
            .replaceAll('/', '');
        try {
            const result = await providerRequests.doCheckProviderExist({
                docNumber: docValue,
            });
            if (result.exists === true) {
                setUserProviderExist(true);
            } else setUserProviderExist(false);
        } catch (e: any) {
            return null;
        }
    };

    const clearMainFields = () => {
        setFieldValue('companyName', '');
        setFieldValue('tradingName', '');
        setFieldValue('itsSimpleCompany', '');
        setExemptStateRegistration!(false);
    };

    const fillFormFieldsWith = (dataSource: any) => {
        setFieldValue('companyName', dataSource.companyName ?? '');
        setFieldValue('tradingName', dataSource.tradingName ?? '');
        setFieldValue(
            'itsSimpleCompany',
            dataSource.taxRegime ? IdentificationTaxRegimeOptions[dataSource.taxRegime] : ''
        );

        dataSource.taxRegime = IdentificationTaxRegimeOptions[dataSource.taxRegime];
        setCnpjWsProviderInfo!(dataSource);

        if (!dataSource.stateCompanyRegistrationNumber) {
            setExemptStateRegistration!(true);
            setFieldValue(
                'stateCompanyRegistrationNumber',
                dataSource.stateCompanyRegistrationNumber
            );
        }
    };

    const searchInputedCNPJ = async (docNumber: string): Promise<any> => {
        if (hasFoundedCnpjWsInfo) {
            setHasFoundedCnpjWsInfo!(false);
            resetForm();
            setFieldValue(fieldName, docNumber);
        }

        setLoading!(true);
        const result = await providerRequests.getCnpjWsApiProviderInfo(docNumber);
        const providerApiInfo = result.data;

        if (result.error || !result.data) return setLoading!(false);

        setHasFoundedCnpjWsInfo!(Boolean(providerApiInfo));

        storage.set(providerApiLocalStorageKey, { docNumber, providerApiInfo }, 'local');

        setLoading!(false);

        return providerApiInfo;
    };

    const getCachedProviderInfoOrFetch = async (docNumber: string) => {
        const cached = storage.get(providerApiLocalStorageKey, 'local');

        if (cached) {
            if (cached.docNumber === docNumber) {
                fillFormFieldsWith(cached.providerApiInfo);
                return setHasFoundedCnpjWsInfo!(true);
            }
            storage.remove(providerApiLocalStorageKey);
        }

        const providerInfo = await searchInputedCNPJ(docNumber);

        fillFormFieldsWith(providerInfo);
    };

    const onChangeTextField = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (resetSearch) {
            resetSearch();
        }
        const docNumber = ev.target.value
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('_', '')
            .replaceAll('/', '');

        if (country !== CONSTANTS.ibgeCodes.BRAZIL) {
            setFieldValue(fieldName, docNumber);
            return;
        }

        if (docNumber.length > 11 && type === 'cpf') return;

        setFieldValue(fieldName, docNumber);

        if (docNumber.length !== 14 && hasFoundedCnpjWsInfo) {
            setHasFoundedCnpjWsInfo!(false);
            clearMainFields();
        }

        if (docNumber.length === 14) {
            getCachedProviderInfoOrFetch(docNumber);
        }
    };

    const { t } = useTranslation();

    const validateDocNumber = (ev: React.FocusEvent<HTMLInputElement>) => {
        if (country !== CONSTANTS.ibgeCodes.BRAZIL) {
            setDocNumberTextError('');
            return;
        }
        const docNumber = ev.target.value
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('/', '');

        if (docNumber.length === 14) {
            if (!validateCnpj(docNumber)) {
                setDocNumberTextError(
                    t('sign-up.pages.stepper-organization.identification.info-error-cnpj-invalid')
                );
            } else {
                setDocNumberTextError('');
            }
        } else if (docNumber.length === 11) {
            if (!validateCpf(docNumber)) {
                setDocNumberTextError(t('sign-up.pages.stepps.error-cpf-invalid'));
            } else {
                setDocNumberTextError('');
            }
        }
    };
    const getType = () => {
        if (!type || type === 'cpf') return 'CPF';
        if (type === 'cnpj') return 'CNPJ';
        if (type === 'cpfCnpj') return t('term.cpf-or-cnpj');
    };

    const getTinLabel = () => (country === CONSTANTS.ibgeCodes.BRAZIL ? getType() : t('term.tin'));
    return (
        <TextField
            fullWidth
            label={getTinLabel()}
            name={fieldName}
            defaultValue=''
            InputProps={InputProps || { maxLength: 18 }}
            value={mask(identificationNumber, country)}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChangeTextField(ev)}
            onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
                if (onBlur) {
                    return onBlur(ev);
                }
                validateUser(ev);
                validateDocNumber(ev);
            }}
            error={touched[fieldName] && Boolean(errors[fieldName])}
            helperText={touched[fieldName] && errors[fieldName]}
            disabled={disabled}
        />
    );
};

export default IdentificationNumberTextField;
