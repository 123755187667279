import { doRequest, managerApiInstance } from 'clients/api';
import {
    HiringPlanResponse,
    HiringPlanRequest,
    HiringPlanItensRequest,
    HiringPlanItensResponse,
    HiringPlanItemUnitRequest,
    HiringPlansListRequest,
} from './interfaces/hiring-plans.interfaces';

const hiringPlansRequests = {
    createHiringPlan: async (data: HiringPlanRequest) =>
        doRequest<HiringPlanResponse>(managerApiInstance.post(`/pca/createPca`, data)),
    createHiringPlanUnitItem: async (data: HiringPlanItemUnitRequest) =>
        doRequest<HiringPlanItensResponse>(managerApiInstance.post(`/pca/createPcaItem`, data)),
    editHiringPlanUnitItem: async (data: HiringPlanItemUnitRequest) =>
        doRequest<HiringPlanItensResponse>(managerApiInstance.post(`/pca/updatePcaItem`, data)),
    deleteHiringPlanUnitItem: async (
        data: Pick<HiringPlanItemUnitRequest, 'id' | 'justification'>
    ) => doRequest<{}>(managerApiInstance.post(`/pca/deletePcaItem`, data)),
    getAllHiringPlans: async (data?: HiringPlansListRequest) =>
        doRequest<Array<HiringPlanResponse>>(managerApiInstance.post(`/pca/listPca`, data)),
    getListItensHiringPlan: async (data: HiringPlanItensRequest) =>
        doRequest<Array<HiringPlanItensResponse>>(
            managerApiInstance.post(`/pca/listPcaItem`, data)
        ),
    synchronizeHiringPlanWithPncp: async (data: Pick<HiringPlanResponse, 'id'>) =>
        doRequest<{}>(managerApiInstance.post(`/pca/synchronizePca`, data)),
};

export { hiringPlansRequests };
