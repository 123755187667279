import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { DropzoneOptions } from 'react-dropzone';

export interface UploadDocumentsProps {
    onDeleteFile: (fileId: number | string) => void;
    listFiles: () => Promise<UploadedFile[]>;
    onUploadFile: (
        file: UploadedFile,
        cbProgress: (progress: number) => void
    ) => Promise<PlainGenericResponse<any>> | undefined;
    options: Pick<DropzoneOptions, 'multiple' | 'accept' | 'disabled'>;
    skeletonRenderRows: number;
    acceptFilesInfo?: boolean;
    uploadType?: 'single' | 'multiple';
    viewMode?: boolean;
    canDelete?: boolean;
    showMessage?: boolean;
}

export enum UploadedFileStatusTypes {
    pending = 'pending',
    accepted = 'accepted',
    rejected = 'rejected',
}

export interface UploadedFile {
    file: File | any;
    name: string;
    preview: string;
    status: UploadedFileStatusTypes;
    id: string;
    originalId?: number | string;
    progress: number;
}
