import { Box, Grid, Paper } from '@material-ui/core';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AuctionType } from 'clients/manager/interfaces/auction-notice.interface';
import { AuctionTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';
import { AuctionTypeEnum } from 'common/enums';
import { getDefaultFormValuesByAuctionType } from 'modules/process/context/process-form-default-values';
import { ProcessModalityProps } from './props';
import ExpandedContentCard from '../../../expand-content-card';
import SelectLegalAssistance from '../../../select-legal-assistance';
import { useProcessFormContext } from '../../../../context/process-form.context';
import SelectAuctionTypeRule from '../../../select-auction-type-rule';
import { processUtils } from '../../../../process-utils';
import ProcessContentHeader from '../process-content-header';
import { getModalityFormLabels } from '../../../../context/schema/modality-form-schema';

const ProcessModality: FC<ProcessModalityProps> = () => {
    const { processForm, setAuctionTypeRules } = useProcessFormContext();

    const { t } = useTranslation();

    const onChangeAuctionType = (auctionTypeRule: AuctionTypeRule) => {
        ReactDOM.unstable_batchedUpdates(() => {
            processForm?.setValues((oldValues) => {
                const defaultValues = getDefaultFormValuesByAuctionType(
                    auctionTypeRule.auctionType as AuctionTypeEnum,
                    processUtils.isMarketplace(oldValues)
                );

                return {
                    ...oldValues,
                    auctionType: auctionTypeRule.auctionType as AuctionType,
                    ruleId: auctionTypeRule.id,
                    ...defaultValues,
                };
            });
            setAuctionTypeRules(auctionTypeRule);
        });
    };

    const onChangeLegalAssistance = (legalSupportId: number) => {
        processForm?.setFieldValue('legalSupportId', legalSupportId);
    };

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    header={
                        <ProcessContentHeader
                            title={t('term.modality')}
                            partialProcessFormLabels={getModalityFormLabels()}
                        />
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <SelectAuctionTypeRule onChange={onChangeAuctionType} />
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <SelectLegalAssistance onChange={onChangeLegalAssistance} />
                        </Grid>
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessModality;
