import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { useTranslation } from 'react-i18next';
import { HiringPlanRequest } from 'clients/manager/interfaces/hiring-plans.interfaces';
import { FormikProps } from 'formik';
import { DatePicker } from '@material-ui/pickers';

interface DialogNewPcaViewProps {
    form: FormikProps<HiringPlanRequest>;
    isLoadingSubmitForm: boolean;
    isLoadingOrganizationUnits: boolean;
}

const DialogNewPcaView: React.FC<DialogNewPcaViewProps> = (props) => {
    const { form, isLoadingSubmitForm, isLoadingOrganizationUnits } = props;
    const { t } = useTranslation();
    const { isOpenDialogCrudPca, onCloseModalCrudPca, organizationUnitsList } =
        useHiringPlansContext();
    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth='md'
            aria-labelledby='create-new-pca-dialog'
            aria-describedby='create-new-pca-dialog-description'
            open={isOpenDialogCrudPca}
            onClose={onCloseModalCrudPca}
        >
            <DialogTitle
                id='create-new-pca-dialog-title'
                style={{ backgroundColor: 'var(--primary-color)' }}
            >
                <Typography variant='h6' color='textSecondary'>
                    {t('hiring-plans.title.register-pca')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box width='384px' paddingY={2}>
                    <FormControl fullWidth>
                        <Grid container spacing={2} direction='column'>
                            <Grid item>
                                <DatePicker
                                    fullWidth
                                    required
                                    maxDate={new Date(
                                        new Date().setFullYear(new Date().getFullYear() + 25)
                                    ).toJSON()}
                                    minDate={new Date(
                                        new Date().setFullYear(new Date().getFullYear() - 25)
                                    ).toJSON()}
                                    disabled={isLoadingSubmitForm || isLoadingOrganizationUnits}
                                    key='date-picker-pca-year'
                                    color='primary'
                                    inputVariant='outlined'
                                    name='year'
                                    views={['year']}
                                    label={t('hiring-plans.labels.ano-pca')}
                                    value={form.values.year}
                                    onChange={(value) => {
                                        form.setFieldValue('year', value?.toJSON());
                                    }}
                                    error={Boolean(form.errors.year)}
                                    helperText={form.errors.year}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm || isLoadingOrganizationUnits}
                                    variant='outlined'
                                    name='organizationUnitId'
                                    label={t('hiring-plans.labels.unit-purchasing')}
                                    value={form.values.organizationUnitId}
                                    onChange={form.handleChange}
                                    error={Boolean(form.errors.organizationUnitId)}
                                    helperText={form.errors.organizationUnitId}
                                >
                                    {organizationUnitsList.map(({ id, organizationUnitName }) => (
                                        <MenuItem key={id} value={id}>
                                            {organizationUnitName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    disabled={isLoadingSubmitForm || isLoadingOrganizationUnits}
                    color='default'
                    size='medium'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={() => {
                        form.resetForm();
                        onCloseModalCrudPca();
                    }}
                >
                    {t('term.cancel')}
                </Button>
                <Button
                    disabled={isLoadingSubmitForm || !form.isValid || isLoadingOrganizationUnits}
                    color='primary'
                    size='medium'
                    variant='contained'
                    style={{
                        minWidth: '128px',
                        height: '100%',
                    }}
                    onClick={() => form.handleSubmit()}
                >
                    {!isLoadingSubmitForm ? (
                        t('term.save')
                    ) : (
                        <CircularProgress color='primary' size={25} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogNewPcaView;
