import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { noticesInformationRequests } from 'clients/manager/notice-information.requests';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import useNoticesInformationContext from 'modules/notices-information/hooks/useNoticesInformation';
import { NoticeInformationForm } from 'modules/notices-information/contexts/NoticesInformationContext';
import DialogCrudNoticeInformationView from './dialog-crud-notice-information-view';

interface DialogCrudNoticeInformationProps {
    isOpenDialogNewNoticeInformation: boolean;
    onCloseModalDialogNewNoticeInformation: () => void;
}

const DialogCrudNoticeInformation: React.FC<DialogCrudNoticeInformationProps> = (props) => {
    const { isOpenDialogNewNoticeInformation, onCloseModalDialogNewNoticeInformation } = props;
    const { t } = useTranslation();
    const { noticeInformationIdForEdit, noticeInformationSelectedForEdit, setNoticesInformation } =
        useNoticesInformationContext();
    const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false);

    const handleCreateNoticeInformation = async (values: NoticeInformationForm) => {
        const response = await noticesInformationRequests.createNoticeInformation({
            title: values.title,
            resume: values.resume,
            description: values.description,
            urlLink: values.urlLink,
            textLink: values.textLink,
            isPublic: values.isPublic,
            isFixedOnTop: values.isFixedOnTop,
            audience: values.audience,
            expirationAt: values.expirationAt,
            coverImage: values.coverImage,
            informationFiles: values.informationFiles ?? [],
        });
        setNoticesInformation((prevState) => [...prevState, response.data]);
    };

    const handleUpdateNoticeInformation = async (values: NoticeInformationForm) => {
        const response = await noticesInformationRequests.updateNoticeInformation(
            {
                title: values.title,
                resume: values.resume,
                description: values.description,
                urlLink: values.urlLink,
                textLink: values.textLink,
                isPublic: values.isPublic,
                isFixedOnTop: values.isFixedOnTop,
                audience: values.audience,
                expirationAt: values.expirationAt,
                coverImage: values.coverImage,
                informationFiles: values.informationFiles ?? [],
            },
            Number(noticeInformationIdForEdit)
        );

        setNoticesInformation((prevState) => {
            const informations = prevState.map((register) => {
                if (register.id === noticeInformationIdForEdit) {
                    return response.data;
                }
                return register;
            });
            return informations;
        });
    };

    const form = useFormik<NoticeInformationForm>({
        initialValues: {
            title: '',
            resume: '',
            description: '',
            urlLink: '',
            textLink: '',
            isPublic: false,
            isFixedOnTop: false,
            audience: 'public',
            expirationAt: null,
            coverImage: null,
            informationFiles: [],
        },
        validationSchema: yup.object({
            title: yup
                .string()
                .required(t('term.required'))
                .min(8, t('notices-information.info.minimum-length', { num: 8 }))
                .max(100, t('notices-information.info.maximum-length', { num: 100 })),
            resume: yup
                .string()
                .required(t('term.required'))
                .min(16, t('notices-information.info.minimum-length', { num: 16 }))
                .max(300, t('notices-information.info.maximum-length', { num: 300 })),
            description: yup
                .string()
                .required(t('term.required'))
                .min(16, t('notices-information.info.minimum-length', { num: 16 }))
                .max(10000, t('notices-information.info.maximum-length', { num: 10000 })),
            urlLink: yup
                .string()
                .max(300, t('notices-information.info.maximum-length', { num: 300 })),
            textLink: yup
                .string()
                .max(150, t('notices-information.info.maximum-length', { num: 150 })),
            isPublic: yup.bool().required(t('term.required')),
            isFixedOnTop: yup.bool().required(t('term.required')),
            expirationAt: yup
                .string()
                .nullable()
                .max(32, t('notices-information.info.maximum-length', { num: 32 })),
            coverImage: yup
                .string()
                .nullable()
                .max(255, t('notices-information.info.maximum-length', { num: 255 })),
            audience: yup
                .string()
                .required(t('term.required'))
                .max(32, t('notices-information.info.maximum-length', { num: 32 })),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoadingSubmitForm(true);
            try {
                if (!noticeInformationIdForEdit) {
                    await handleCreateNoticeInformation(values);
                } else {
                    await handleUpdateNoticeInformation(values);
                }
                resetForm();
                onCloseModalDialogNewNoticeInformation();
                addNotificationSuccess({
                    title: t('term.success'),
                    message: !noticeInformationIdForEdit
                        ? t('notices-information.info.create-notices-information-success')
                        : t('notices-information.info.updated-notices-information-success'),
                });
            } catch (err) {
                addNotificationError({
                    title: t('term.error'),
                    message: !noticeInformationIdForEdit
                        ? t('notices-information.info.create-notices-information-error')
                        : t('notices-information.info.updated-notices-information-error'),
                });
            } finally {
                setIsLoadingSubmitForm(false);
            }
        },
    });

    useEffect(() => {
        if (noticeInformationIdForEdit && noticeInformationSelectedForEdit) {
            form.setValues({ ...noticeInformationSelectedForEdit } as any);
        }

        return () => {
            form.resetForm();
        };
    }, [noticeInformationSelectedForEdit]);

    return (
        <DialogCrudNoticeInformationView
            form={form}
            isLoadingSubmitForm={isLoadingSubmitForm}
            noticesInformationForEdit={noticeInformationSelectedForEdit}
            isOpenDialogNewNoticeInformation={isOpenDialogNewNoticeInformation}
            onCloseModalDialogNewNoticeInformation={onCloseModalDialogNewNoticeInformation}
        />
    );
};

export default DialogCrudNoticeInformation;
