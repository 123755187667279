import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { RangeValueTypes } from '../search/components/advanced-filters/range-value-filters';
import { IAdvancedFilters, IFilters } from './interfaces';

export const initialAdvancedFilters: IAdvancedFilters = {
    metadata: {
        priceRangeType: RangeValueTypes.defaultValue,
    },
};
export const initialFilters: IFilters = {
    text: '',
    limit: 8,
    skip: 0,
    filters: undefined,
    fetched: false,
};

export type MarketplaceSearchFiltersContextType = {
    setAppliedFilters: React.Dispatch<React.SetStateAction<IFilters>>;
    appliedFilters: IFilters;
    setLoadingMore: React.Dispatch<React.SetStateAction<boolean>>;
    loadingMore: boolean;
    setNotLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
    notLoadMore: boolean;
};

export const MarketplaceSearchFilters = createContext<MarketplaceSearchFiltersContextType>({
    appliedFilters: initialFilters,
    setAppliedFilters: () => {},
    loadingMore: false,
    setLoadingMore: () => {},
    notLoadMore: false,
    setNotLoadMore: () => {},
});

export const useMarketplaceSearchFiltersContext = () => useContext(MarketplaceSearchFilters);

export const MarketplaceSearchFiltersContextProvider = ({ children }: { children: ReactNode }) => {
    const query = new URLSearchParams(useLocation().search);
    const querySearch = query.get('q');

    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [notLoadMore, setNotLoadMore] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useState<IFilters>({
        ...initialFilters,
        filters: initialAdvancedFilters,
        text: querySearch || initialFilters.text,
    });

    const appliedFiltersRef: any = useRef(null);
    appliedFiltersRef.current = { appliedFilters, setAppliedFilters };

    const loadingMoreRef: any = useRef(null);
    loadingMoreRef.current = { loadingMore };

    const notLoadMoreRef: any = useRef(null);
    notLoadMoreRef.current = { notLoadMore };

    return (
        <MarketplaceSearchFilters.Provider
            value={{
                appliedFilters: appliedFiltersRef.current.appliedFilters,
                setAppliedFilters: appliedFiltersRef.current.setAppliedFilters,
                setLoadingMore,
                loadingMore: loadingMoreRef.current.loadingMore,
                setNotLoadMore,
                notLoadMore: notLoadMoreRef.current.notLoadMore,
            }}
        >
            {children}
        </MarketplaceSearchFilters.Provider>
    );
};
