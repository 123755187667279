/* eslint-disable no-useless-computed-key */
import { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextField } from 'common/components';
import { featureFlagEnabled } from 'common/utils';
import { UpdatePassword } from './profile-update-password';

interface ICreateNewPassword {
    form: FormikProps<UpdatePassword>;
}

const CreateNewPassword = (props: ICreateNewPassword) => {
    const { form } = props;
    const cognitoIsEnabled = featureFlagEnabled('enableCognitoAccess');
    const { t } = useTranslation();
    const [validatePassword, setValidadePassword] = useState({
        uppercase: false,
        lowercase: false,
        number: false,
        length: false,
        special: false,
    });

    const secureText = (password: string) => {
        const regexUppercase = RegExp(/^(?=.*[A-Z]).+$/);
        const regexLowercase = RegExp(/^(?=.*[a-z]).+$/);
        const regexNumber = RegExp(/^(?=.*[0-9]).+$/);
        const regexSpecial = RegExp(/[!@#$%^&*(),.?":{}|<>]/g);
        const length = password.length >= 8;

        ReactDOM.unstable_batchedUpdates(() => {
            setValidadePassword({
                uppercase: regexUppercase.test(password),
                lowercase: regexLowercase.test(password),
                number: regexNumber.test(password),
                special: regexSpecial.test(password),
                length,
            });
        });
    };

    return (
        <>
            <Grid item xs={9}>
                <div className='first-item'>
                    {!cognitoIsEnabled && (
                        <TextField
                            type='password'
                            fullWidth
                            label={t('term.current-password')}
                            name='currentPassword'
                            value={form.values.currentPassword}
                            onChange={form.handleChange}
                            error={form.touched.currentPassword && !!form.errors.currentPassword}
                            helperText={form.touched.currentPassword && form.errors.currentPassword}
                        />
                    )}
                    <TextField
                        fullWidth
                        autoComplete='off'
                        label={t('term.password')}
                        name='password'
                        type='password'
                        value={form.values.password}
                        onChange={(e) => {
                            form.handleChange(e);
                            secureText(e.target.value);
                        }}
                        error={form.touched.password && Boolean(form.errors.password)}
                    />
                    <TextField
                        fullWidth
                        autoComplete='off'
                        label={t('term.confirm-password')}
                        name='confirmPassword'
                        type='password'
                        value={form.values.confirmPassword}
                        onChange={form.handleChange}
                        error={form.touched.confirmPassword && Boolean(form.errors.confirmPassword)}
                        helperText={form.touched.confirmPassword && form.errors.confirmPassword}
                    />
                </div>
            </Grid>
            <Grid item xs={3}>
                <div className='passwordValidation'>
                    <div className='validation'>
                        {validatePassword.length ? (
                            <CheckCircleIcon className='validation-true' />
                        ) : (
                            <CancelIcon className='validation-false' />
                        )}
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.info-password-min-character')}
                        </Typography>
                    </div>
                    <div className='validation'>
                        {validatePassword.uppercase ? (
                            <CheckCircleIcon className='validation-true' />
                        ) : (
                            <CancelIcon className='validation-false' />
                        )}
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.info-password-uppercase')}
                        </Typography>
                    </div>
                    <div className='validation'>
                        {validatePassword.lowercase ? (
                            <CheckCircleIcon className='validation-true' />
                        ) : (
                            <CancelIcon className='validation-false' />
                        )}
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.info-password-lowercase')}
                        </Typography>
                    </div>
                    <div className='validation'>
                        {validatePassword.special ? (
                            <CheckCircleIcon className='validation-true' />
                        ) : (
                            <CancelIcon className='validation-false' />
                        )}
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.info-password-special-char')}
                        </Typography>
                    </div>
                    <div className='validation'>
                        {validatePassword.number ? (
                            <CheckCircleIcon className='validation-true' />
                        ) : (
                            <CancelIcon className='validation-false' />
                        )}
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.info-password-number-char')}
                        </Typography>
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default CreateNewPassword;
