import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { AuctionMessagesList, AuctionTransaction } from './interfaces/auction-notice.interface';
import {
    AnonymizeMessageTextBody,
    RevertAnonymizeMessageFileBody,
    UpdateIsPublishedMessageBody,
} from './interfaces/auction-notice-message.interface';

export interface PaginationArgs {
    offset?: number;
    limit?: number;
}

const BASE_PATH = '/auction-notice-message';

const auctionNoticeMessage = {
    listTransactionsByAuctionId: async (id: number | string, paginationArgs?: PaginationArgs) =>
        doRequest<PlainGenericResponse<AuctionTransaction[]>>(
            managerApiInstance.post('/auction-notice-transactions/listTransactionsByAuctionId', {
                limit: paginationArgs?.limit || 10,
                offset: paginationArgs?.offset || 0,
                params: { auctionId: Number(id) },
            })
        ),
    listMessagesByAuctionId: async (id: number | string, paginationArgs?: PaginationArgs) =>
        doRequest<PlainGenericResponse<AuctionMessagesList[]>>(
            managerApiInstance.post(`${BASE_PATH}/listMessagesByAuctionId`, {
                limit: paginationArgs?.limit || 10,
                offset: paginationArgs?.offset || 0,
                params: { auctionId: Number(id) },
            })
        ),
    answerMessage: async (formData: FormData) =>
        doRequest(
            managerApiUploadFileInstance.post(`${BASE_PATH}/doAnswerMessage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        ),
    createMessage: async (formData: FormData) =>
        doRequest(
            managerApiUploadFileInstance.post(`${BASE_PATH}/createMessage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        ),
    anonymizeMessageFile: async (formData: FormData) =>
        doRequest<GenericResponse<null>>(
            managerApiUploadFileInstance.post(`${BASE_PATH}/doAnonymizeMessageFile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        ),
    revertAnonymizeMessageFile: async (body: RevertAnonymizeMessageFileBody) =>
        doRequest<GenericResponse<null>>(
            managerApiInstance.post(`${BASE_PATH}/doRevertAnonymizeMessageFile`, body)
        ),
    anonymizeMessageText: async (body: AnonymizeMessageTextBody) =>
        doRequest<GenericResponse<null>>(
            managerApiInstance.post(`${BASE_PATH}/doAnonymizeMessageText`, body)
        ),
    updateIsPublishedMessage: async (body: UpdateIsPublishedMessageBody) =>
        doRequest<GenericResponse<null>>(
            managerApiInstance.post(`${BASE_PATH}/doUpdateIsPublishedMessage`, body)
        ),
};

export { auctionNoticeMessage };
