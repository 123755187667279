import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Input, TextArea } from '@licitar/ui-kit';
import {
    timeout,
    focusElement,
    addNotificationWarning,
    addNotificationSuccess,
    addNotificationError,
} from 'common/utils';
import {
    DocumentVariables,
    DocumentVariablesValueType,
} from 'clients/manager/interfaces/document-variables.interface';
import { useTranslation } from 'react-i18next';
import { YupValidationMessages } from 'common/constants/yup-validation-messages.constant';
import { Box, FormControlLabel, FormLabel, Radio as RadioButton } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import * as Yup from 'yup';
import { templateVariablesRequests } from 'clients/manager/documents/template-variables.requests';
import { ActionsArea, DeleteIcon, VariableWrapper, VariableActions } from './styles';
import { ModalCreateVariablePropsProps } from './props';

type DocumentVariablesForm = Omit<DocumentVariables, '_id'>;

const ModalCreateVariable: FC<ModalCreateVariablePropsProps> = ({
    opened,
    onClose,
    variable,
    onCreate,
}) => {
    const { t } = useTranslation();

    const [customVariable, setCustomVariable] = useState<DocumentVariables | undefined>(undefined);
    const [validationSchema, setValidationSchema] = useState<any>(
        Yup.object({
            name: Yup.string().required(YupValidationMessages.REQUIRED),
            description: Yup.string().required(YupValidationMessages.REQUIRED),
        })
    );

    const initialValues: DocumentVariablesForm = {
        description: '',
        name: '',
        organizationId: 2,
        type: DocumentVariablesValueType.select,
        values: [{ value: '' }],
    };

    const getCustomVariableValues = async () => {
        if (!variable?._id) {
            return;
        }
        try {
            const currVariable = await templateVariablesRequests.getVariable(variable._id);
            return setCustomVariable({
                ...variable,
                ...currVariable,
            });
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.templates.components.modal-create-variable.notification-error-value-variable'
                ),
            });
        }
    };

    useEffect(() => {
        getCustomVariableValues();
    }, [variable]);

    const create = async (toCreateVariable: DocumentVariables) => {
        try {
            const createdVariable = await templateVariablesRequests.createVariable(
                toCreateVariable
            );
            addNotificationSuccess({
                title: t('term.success'),
                message: t(
                    'editor.templates.components.modal-create-variable.notification-created-variable'
                ),
            });
            return onCreate(createdVariable);
        } catch (error) {
            addNotificationError({
                title: t('term.err'),
                message: t(
                    'editor.templates.components.modal-create-variable.notification-error-created-variable'
                ),
            });
        }
    };

    const update = async (toUpdateVariable: DocumentVariables) => {
        if (!variable?._id) {
            return;
        }

        try {
            const createdVariable = await templateVariablesRequests.updateVariable(
                variable._id,
                toUpdateVariable
            );
            addNotificationSuccess({
                title: t('term.success'),
                message: t(
                    'editor.templates.components.modal-create-variable.notification-updated-variable'
                ),
            });
            return onCreate(createdVariable);
        } catch (error) {
            addNotificationError({
                title: t('term.err'),
                message: t(
                    'editor.templates.components.modal-create-variable.notification-error-updated-variable'
                ),
            });
        }
    };

    const form = useFormik({
        initialValues: customVariable || initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => {
            if (variable?._id) {
                return update(values as DocumentVariables);
            }
            create(values as DocumentVariables);
        },
    });

    useEffect(() => {
        timeout(() => {
            focusElement('description-ref');
        }, 10);
    }, []);

    const handleChangeVariableType = (type: DocumentVariablesValueType) => {
        if (type === DocumentVariablesValueType.select) {
            setValidationSchema(
                Yup.object({
                    name: Yup.string().required(YupValidationMessages.REQUIRED),
                    description: Yup.string().required(YupValidationMessages.REQUIRED),
                    values: Yup.array().of(
                        Yup.object().shape({
                            value: Yup.string().required(YupValidationMessages.REQUIRED),
                        })
                    ),
                })
            );
            return form.setValues({
                ...form.values,
                type,
            });
        }

        setValidationSchema(
            Yup.object({
                name: Yup.string().required(YupValidationMessages.REQUIRED),
                description: Yup.string().required(YupValidationMessages.REQUIRED),
            })
        );

        return form.setValues({
            ...form.values,
            type,
            values: undefined,
        });
    };

    return (
        <Modal
            position={ModalPosition.center}
            open={opened}
            onClose={onClose}
            header={
                <span>{`${t('term.custom-var')} ${
                    form.values.name ? `- ${form.values.name}` : ''
                }`}</span>
            }
        >
            <Box width='650px'>
                <div style={{ display: 'flex' }}>
                    <Input
                        id='description-ref'
                        title={t('term.description-var')}
                        style={{ width: '50%', margin: '0 10px 15px 0' }}
                        autoFocus
                        name='description'
                        value={form.values.description}
                        label={t('term.description')}
                        onChange={(ev) => {
                            const { value } = ev.target;
                            form.setFieldValue('description', value);
                            const varValue = value.replaceAll(' ', '_');
                            form.setFieldValue('name', varValue);
                        }}
                    />
                    <Input
                        title={t(
                            'editor.templates.components.modal-create-variable.title-name-variable'
                        )}
                        style={{ width: '50%' }}
                        disabled
                        name='name'
                        value={form.values.name}
                        label={t('term.name-var')}
                    />
                </div>
                <FormLabel component='legend'>
                    {t('editor.templates.components.modal-create-variable.label-name-variable')}
                </FormLabel>
                <Box flex>
                    <FormControlLabel
                        checked={form.values.type === DocumentVariablesValueType.select}
                        control={<RadioButton />}
                        label={t('term.options-pred')}
                        onChange={() => handleChangeVariableType(DocumentVariablesValueType.select)}
                    />
                    <FormControlLabel
                        checked={form.values.type === DocumentVariablesValueType.text}
                        control={<RadioButton />}
                        label={t(
                            'editor.templates.components.modal-create-variable.label-radio-button-variable'
                        )}
                        onChange={() => handleChangeVariableType(DocumentVariablesValueType.text)}
                    />
                </Box>
                {form.values.type === DocumentVariablesValueType.select && (
                    <>
                        <Box>
                            {form.values.values?.map(({ value }, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <VariableWrapper key={index}>
                                    <TextArea
                                        id={`default-value-${index}`}
                                        style={{ margin: '0 0 5px 0', flex: 1 }}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        label={t('term.value-var')}
                                        name='variable_value'
                                        value={value}
                                        onChange={(ev) => {
                                            form.setFieldValue(
                                                `values[${index}].value`,
                                                ev.target.value
                                            );
                                        }}
                                        autoFocus
                                    />
                                    {form.values.values && form.values.values.length > 1 && (
                                        <VariableActions>
                                            <DeleteIcon
                                                title={t('term.delete-value')}
                                                onClick={() => {
                                                    const values = [
                                                        ...(form.values.values ?? [{ value: '' }]),
                                                    ];
                                                    values.splice(index, 1);
                                                    form.setFieldValue('values', values);
                                                }}
                                            />
                                        </VariableActions>
                                    )}
                                </VariableWrapper>
                            ))}
                        </Box>
                        <Box>
                            <Button
                                title={t(
                                    'editor.templates.components.modal-create-variable.title-button-new-value-variable'
                                )}
                                size='xsmall'
                                variant='contained'
                                onClick={() => {
                                    form.setFieldValue('values', [
                                        ...(form.values.values ?? []),
                                        {
                                            value: '',
                                        },
                                    ]);
                                    return timeout(
                                        () =>
                                            focusElement(
                                                `default-value-${
                                                    form.values.values && form.values.values.length
                                                }`
                                            ),
                                        0
                                    );
                                }}
                            >
                                {t(
                                    'editor.templates.components.modal-create-variable.label-button-new-value-variable'
                                )}
                            </Button>
                        </Box>
                    </>
                )}
                <ActionsArea>
                    <Button
                        title={t('term.cancel')}
                        size='small'
                        variant='outlined'
                        onClick={onClose}
                    >
                        {t('term.cancel')}
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        disabled={!form.dirty || !!Object.keys(form.errors).length}
                        title={t(
                            'editor.templates.components.modal-create-variable.title-button-create-variable'
                        )}
                        onClick={form.submitForm}
                    >
                        {variable ? t('term.update') : t('term.create')}
                    </Button>
                </ActionsArea>
            </Box>
        </Modal>
    );
};

export default ModalCreateVariable;
