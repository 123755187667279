import { FiFile, FiX } from 'react-icons/fi';
import { ChipContainer, ChipDescription, ChipIconContainer } from './styled';

interface ChipProps {
    title: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    onClose?: () => void;
    disabled?: boolean;
}

const Chip = ({ title, icon, onClick, onClose, disabled = false }: ChipProps) => (
    <ChipContainer {...{ onClick, disabled }}>
        <ChipIconContainer>{icon || <FiFile color='var(--background-color)' />}</ChipIconContainer>
        <ChipDescription>{title}</ChipDescription>
        <ChipIconContainer>
            <button type='button' onClick={onClose}>
                <FiX color='var(--background-color)' size='1rem' />
            </button>
        </ChipIconContainer>
    </ChipContainer>
);

export default Chip;
