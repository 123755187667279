import {
    Box,
    Card,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
} from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { FaClock, FaEnvelope, FaPaperPlane, FaTimes } from 'react-icons/fa';
import { cardStyles, tableContainerStyles, textStyles } from './process-proposals-style';

const ProcessProposalsView = ({ data, onClick }) => {
    const { t } = useTranslation();

    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'withPendingIssues':
                return (
                    <FaTimes style={{ cursor: 'pointer' }} color='var(--primary-color)' size={18} />
                );
            case 'waitingResponse':
                return (
                    <FaClock style={{ cursor: 'pointer' }} color='var(--primary-color)' size={18} />
                );
            case 'waitingProposal':
                return (
                    <FaPaperPlane
                        style={{ cursor: 'pointer' }}
                        color='var(--primary-color)'
                        size={18}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Card style={cardStyles}>
            <Box display='flex' alignItems='center'>
                <FaEnvelope className='envelope-icon' color='var(--primary-color)' size={22} />
                <Typography variant='h6' style={textStyles}>
                    {t('term.pending-invitations')}
                </Typography>
                <Tooltip title={t('term.pending-invitations-message')}>
                    <InfoIcon color='primary' />
                </Tooltip>
            </Box>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <TableContainer style={tableContainerStyles}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('term.process')}</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.map((item, index) => (
                                    <TableRow key={item.processNumber}>
                                        <TableCell style={{ width: '80px' }}>
                                            {item.processNumber}
                                        </TableCell>
                                        <TableCell style={{ padding: 0, paddingLeft: 10 }}>
                                            {t(`term.status-${item.status}`)}
                                        </TableCell>
                                        <TableCell
                                            align='right'
                                            onClick={() => onClick(item.processId)}
                                        >
                                            {getStatusIcon(item.status)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Card>
    );
};

export default ProcessProposalsView;
