import { Dialog, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AiFillEdit } from 'react-icons/ai';
import { FaReplyAll } from 'react-icons/fa';
import { IoIosHand } from 'react-icons/io';
import { RiShieldCheckFill } from 'react-icons/ri';

interface ProcessDialogCommentTypeProps {
    open?: boolean;
    onClose?: () => void;
    handleProcessType: (type: number) => void;
    type?: number;
    canShowButton: {
        impugnment: boolean;
        objection: boolean;
        appeal: boolean;
    };
    buttonsRules: {
        impugnment: boolean;
        explanation: boolean;
        appeal: boolean;
    };
}

const ProcessDialogCommentType = ({
    open = false,
    onClose = () => {},
    handleProcessType,
    canShowButton,
    buttonsRules,
    type,
}: ProcessDialogCommentTypeProps) => {
    const { t } = useTranslation();

    const handleType = (type: number) => {
        handleProcessType(type);
        onClose();
    };

    return (
        <Dialog {...{ open, onClose }}>
            <List disablePadding>
                {buttonsRules.explanation && (
                    <ListItem onClick={() => handleType(1)} selected={type === 1} button divider>
                        <ListItemIcon>
                            <RiShieldCheckFill color='var(--primary-color)' size={24} />
                        </ListItemIcon>
                        <ListItemText primary={t('bidding.process.message.type.explanation')} />
                    </ListItem>
                )}
                {buttonsRules.impugnment && canShowButton.impugnment && (
                    <ListItem onClick={() => handleType(2)} selected={type === 2} button divider>
                        <ListItemIcon>
                            <IoIosHand color='var(--primary-color)' size={24} />
                        </ListItemIcon>
                        <ListItemText primary={t('bidding.process.message.type.impugnment')} />
                    </ListItem>
                )}
                {buttonsRules.appeal && canShowButton.appeal && (
                    <ListItem onClick={() => handleType(3)} selected={type === 3} button divider>
                        <ListItemIcon>
                            <AiFillEdit color='var(--primary-color)' size={24} />
                        </ListItemIcon>
                        <ListItemText primary={t('bidding.process.message.type.appeal')} />
                    </ListItem>
                )}
                {buttonsRules.appeal && canShowButton.objection && (
                    <ListItem onClick={() => handleType(4)} selected={type === 4} button>
                        <ListItemIcon>
                            <FaReplyAll color='var(--primary-color)' size={24} />
                        </ListItemIcon>
                        <ListItemText primary={t('bidding.process.message.type.objection')} />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
};

export default ProcessDialogCommentType;
