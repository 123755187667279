import { Chip, FormControl, FormControlLabel, MenuItem, Select, Switch } from '@material-ui/core';
import {
    AuctionMessageFile,
    AuctionMessagesList,
} from 'clients/manager/interfaces/auction-notice.interface';
import { ChangeEvent, Dispatch, SetStateAction, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEdit } from 'react-icons/ai';
import { BsArrowReturnRight } from 'react-icons/bs';
import { FaReplyAll } from 'react-icons/fa';
import { FiPaperclip } from 'react-icons/fi';
import { IoIosHand } from 'react-icons/io';
import { RiShieldCheckFill } from 'react-icons/ri';
import { addNotificationApiError, addNotificationSuccess, formatDate } from 'common/utils';
import { Editor as TEditor } from '@tinymce/tinymce-react';
import { auctionNoticeMessage } from 'clients/manager/auction-notice-message.requests';
import { Button } from 'common/components';
import ProcessSolicitationsCommentBox from 'modules/process/components/process-solicitations-data/components/process-solicitations-comment-box';
import { MessageType } from 'common/enums';
import { getDeferralLabel } from 'common/utils/getters/get-deferral-label.utils';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { EDITOR_KEY } from 'modules/editor/components/editor';
import ProcessCommentTreatment from './ProcessCommentTreatment';
import {
    ChipContainer,
    ManageButton,
    ProcessChipsContainer,
    ProcessCommentAvatar,
    ProcessCommentAvatarContainer,
    ProcessCommentContainer,
    ProcessCommentContentActionsContainer,
    ProcessCommentContentContainer,
    ProcessCommentContentInfoContainer,
    ProcessCommentLineContainer,
    ProcessCommentRepliesContainer,
    ProcessCommentTitleContainer,
    ReplyButton,
    SeeMoreButton,
} from './styled';

interface ProcessMessageProps {
    id?: number;
    auctionId?: string;
    originalMessage?: string;
    enableManager?: boolean;
    message: string;
    deferral?: number;
    title?: string;
    date: Date;
    files?: AuctionMessageFile[];
    name?: string;
    last?: boolean;
    isPublished?: boolean;
    type: 'impugnment' | 'appeal' | 'explanation' | 'objection';
    replies?: {
        name: string;
        message: string;
        date: Date;
        files?: AuctionMessageFile[];
        deferred?: boolean;
    }[];
    getMessages?: (noLoading?: boolean) => Promise<AuctionMessagesList[]>;
    setMessages?: Dispatch<SetStateAction<AuctionMessagesList[]>>;
}

const ProcessComment = ({
    title,
    id,
    auctionId,
    originalMessage,
    message,
    date,
    files,
    type,
    last = false,
    isPublished,
    replies = [],
    enableManager,
    getMessages,
    setMessages,
    ...props
}: ProcessMessageProps) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [seeMoreMessage, setSeeMoreMessage] = useState(false);
    const [seeMoreAnswer, setSeeMoreAnswer] = useState(false);

    const [isManaging, setIsManaging] = useState(false);
    const [isReplyEnable, setIsReplyEnable] = useState(false);
    const [messageIsPublished, setMessageIsPublished] = useState(isPublished);
    const [editorMessage, setEditorMessage] = useState<string>(message);
    const [deferral, setDeferral] = useState(props?.deferral ?? 0);

    const editorRef: any = useRef(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setEditorMessage(message);
    }, [message]);

    const updateProcessMessage = async () => {
        if (!getMessages || !setMessages) return;

        const messages = await getMessages(true);

        setMessages(messages);
    };
    const onChangeEditor = () => {
        if (editorRef.current) {
            setEditorMessage(editorRef.current.getContent({ format: 'text' }));
        }
    };

    const handleChangeFile = async (
        messageFileId: number,
        event: ChangeEvent<HTMLInputElement>
    ) => {
        try {
            setLoading(true);

            const acceptFileTypes = ['application/pdf'];
            const inputFiles = event.target.files;

            const isAnyFileDenied =
                inputFiles &&
                Array.from(inputFiles).filter(({ type }) => !acceptFileTypes.includes(type))
                    .length !== 0;

            if (inputFiles?.length === 0 || inputFiles === null) return;
            if (isAnyFileDenied) return;

            const file = Array.from(inputFiles)[0];

            const formData = new FormData();
            formData.append('auctionId', String(auctionId));
            formData.append('messageId', String(id));
            formData.append('messageFileId', String(messageFileId));
            const newFileName = getNewFileName(file);
            formData.append('file', file, newFileName);

            await auctionNoticeMessage.anonymizeMessageFile(formData);

            await updateProcessMessage();

            addNotificationSuccess();
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleRevertAnonymizeFile = async (messageFileId: number) => {
        try {
            if (id) {
                setLoading(true);
                await auctionNoticeMessage.revertAnonymizeMessageFile({
                    auctionId: Number(auctionId),
                    messageFileId: Number(messageFileId),
                    messageId: id,
                });
                await updateProcessMessage();
                addNotificationSuccess();
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveManagementChanges = async () => {
        try {
            setLoading(true);
            if (auctionId && id) {
                await Promise.all([
                    auctionNoticeMessage.anonymizeMessageText({
                        auctionId: Number(auctionId),
                        messageId: id,
                        message: editorMessage,
                        deferral: String(deferral),
                    }),
                    auctionNoticeMessage.updateIsPublishedMessage({
                        auctionId: Number(auctionId),
                        messageId: id,
                        isPublished: messageIsPublished,
                    }),
                ]);
            }
            setIsManaging(false);
            addNotificationSuccess();
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const deferralLabel = getDeferralLabel(deferral);

    return (
        <ProcessCommentContainer>
            <ProcessCommentAvatarContainer>
                <ProcessCommentAvatar>
                    {type === 'appeal' && <AiFillEdit />}
                    {type === 'impugnment' && <IoIosHand />}
                    {type === 'explanation' && <RiShieldCheckFill />}
                    {type === 'objection' && <FaReplyAll />}
                </ProcessCommentAvatar>
            </ProcessCommentAvatarContainer>
            <ProcessCommentTitleContainer>
                <h1>
                    {t(`bidding.process.message.type.${type}`)}
                    {title && ` - ${title}`}
                    {deferralLabel?.label && (
                        <strong style={{ color: deferralLabel.color, fontSize: '15px' }}>
                            {` ${deferralLabel.label}`}
                        </strong>
                    )}
                </h1>
                {enableManager && (
                    <div>
                        {isManaging && (
                            <ManageButton
                                disabled={loading}
                                loading={loading}
                                onClick={() => setIsManaging(false)}
                                style={{ marginRight: '0.5rem' }}
                            >
                                {t('term.back')}
                            </ManageButton>
                        )}
                        <Button
                            disabled={isManaging || loading}
                            size='xsmall'
                            color='primary'
                            variant='contained'
                            onClick={async () =>
                                isManaging && !loading
                                    ? handleSaveManagementChanges()
                                    : setIsManaging(true)
                            }
                        >
                            {t('term.manage')}
                        </Button>
                    </div>
                )}
            </ProcessCommentTitleContainer>
            <ProcessCommentLineContainer {...{ last }} />
            <ProcessCommentContentContainer {...{ last }}>
                {isManaging ? (
                    <TEditor
                        key={id}
                        id={`message-editor-${id}`}
                        apiKey={EDITOR_KEY}
                        onInit={(_, editor) => {
                            editorRef.current = editor;
                        }}
                        onChange={onChangeEditor}
                        init={{
                            menubar: false,
                            toolbar: 'hiddeProhibitedWord showAllWords',
                            setup: function (editor) {
                                editor.ui.registry.addButton('hiddeProhibitedWord', {
                                    text: t(
                                        'bidding.process.section.messages.anonymize-selected-text'
                                    ),
                                    onAction: function (_) {
                                        if (editor.selection.getContent({ format: 'text' }))
                                            editor.insertContent(`[Anonimizado]`);
                                    },
                                    onSetup: (api) => {
                                        const callback = () => {
                                            api.setDisabled(false);
                                        };
                                        editor.on('init', callback);
                                        return () => {
                                            editor.off('init', callback);
                                        };
                                    },
                                });
                                editor.ui.registry.addButton('showAllWords', {
                                    text: t(
                                        'bidding.process.section.messages.restore-original-text'
                                    ),
                                    onAction: function (_) {
                                        editor.setContent(originalMessage || editor.startContent);
                                        editor.insertContent('');
                                    },
                                    onSetup: (api) => {
                                        const callback = () => {
                                            api.setDisabled(false);
                                        };
                                        editor.on('init', callback);
                                        return () => {
                                            editor.off('init', callback);
                                        };
                                    },
                                });
                            },
                        }}
                        disabled
                        initialValue={editorMessage}
                    />
                ) : (
                    <ProcessCommentTreatment>
                        {seeMoreMessage ? editorMessage : editorMessage.substring(0, 200)}
                        {editorMessage.length >= 199 && (
                            <>
                                <SeeMoreButton onClick={() => setSeeMoreMessage((prev) => !prev)}>
                                    {seeMoreMessage
                                        ? ` ${t('bidding.process.section.messages.see-less')}`
                                        : `... ${t('bidding.process.section.messages.see-more')}`}
                                </SeeMoreButton>
                            </>
                        )}
                    </ProcessCommentTreatment>
                )}
                <ProcessCommentContentInfoContainer>
                    {formatDate(date, 'LLL')}
                    {isManaging && (
                        <ProcessCommentContentActionsContainer>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color='primary'
                                        checked={messageIsPublished}
                                        onChange={() => setMessageIsPublished((state) => !state)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label='Tornar público'
                                labelPlacement='start'
                                style={{ fontSize: '10px' }}
                            />
                            {type === MessageType.type3 && (
                                <FormControl size='small' variant='outlined' color='primary'>
                                    <Select
                                        value={deferral}
                                        onChange={(e) => setDeferral(e.target.value as number)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value={0}>Selecione...</MenuItem>
                                        <MenuItem value={1}>Deferido</MenuItem>
                                        <MenuItem value={2}>Parcialmente deferido</MenuItem>
                                        <MenuItem value={-1}>Indeferido</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            <Button
                                disabled={loading}
                                size='small'
                                color='primary'
                                variant='contained'
                                onClick={async () =>
                                    isManaging && !loading
                                        ? handleSaveManagementChanges()
                                        : setIsManaging(true)
                                }
                            >
                                {t('term.save')}
                            </Button>
                        </ProcessCommentContentActionsContainer>
                    )}
                </ProcessCommentContentInfoContainer>
                {files && files.length > 0 && (
                    <ProcessChipsContainer isManaging={isManaging}>
                        {files.map((file) => {
                            const fileIsAnonymized =
                                enableManager &&
                                !!file?.originalFileMessage &&
                                file.originalFileMessage !== file.fileMessage;

                            return (
                                <ChipContainer>
                                    <Chip
                                        key={file.id}
                                        color='secondary'
                                        size='small'
                                        label={
                                            (file.fileName.trim()
                                                ? file.fileName
                                                : t(
                                                      'bidding.process.section.messages.attachment'
                                                  )) + (fileIsAnonymized ? ' [Anonimizado]' : '')
                                        }
                                        onClick={() => window.open(file.fileMessage, '_blank')}
                                        variant='outlined'
                                        icon={<FiPaperclip color='var(--secondary-color)' />}
                                        onDelete={
                                            !loading && isManaging && fileIsAnonymized
                                                ? async () => handleRevertAnonymizeFile(file.id)
                                                : undefined
                                        }
                                    />
                                    {isManaging && (
                                        <ManageButton
                                            disabled={loading}
                                            loading={loading}
                                            onClick={
                                                !loading
                                                    ? () => fileInputRef.current?.click()
                                                    : undefined
                                            }
                                        >
                                            <input
                                                ref={fileInputRef}
                                                onChange={async (e) => handleChangeFile(file.id, e)}
                                                accept='application/pdf'
                                                hidden
                                                type='file'
                                            />
                                            {t(
                                                'bidding.process.section.messages.send-anonymized-file'
                                            )}
                                        </ManageButton>
                                    )}
                                </ChipContainer>
                            );
                        })}
                    </ProcessChipsContainer>
                )}
                {enableManager && isReplyEnable && (
                    <ProcessSolicitationsCommentBox
                        {...{
                            id: auctionId,
                            messageId: id,
                            setMessages,
                            getMessages,
                            onBack: () => setIsReplyEnable(false),
                        }}
                    />
                )}
                {!isReplyEnable && (
                    <ProcessCommentRepliesContainer>
                        {replies.map((reply) => (
                            <div key={`${reply.date}`}>
                                <BsArrowReturnRight size='0.75rem' color='var(--tertiary-color)' />
                                <p>
                                    {seeMoreAnswer
                                        ? reply.message
                                        : reply.message.substring(0, 200)}
                                    {reply.message.length >= 199 && (
                                        <>
                                            {!seeMoreAnswer && '...'}{' '}
                                            <SeeMoreButton
                                                onClick={() => setSeeMoreAnswer((prev) => !prev)}
                                            >
                                                {seeMoreAnswer
                                                    ? t('bidding.process.section.messages.see-less')
                                                    : t(
                                                          'bidding.process.section.messages.see-more'
                                                      )}
                                            </SeeMoreButton>
                                        </>
                                    )}
                                </p>
                                <ProcessCommentContentInfoContainer key={i18n.language}>
                                    {reply?.name && [`${reply.name}`, <div />]}
                                    {formatDate(reply.date, 'LLL')}
                                </ProcessCommentContentInfoContainer>
                                {reply.files && (
                                    <ProcessChipsContainer>
                                        {reply?.files?.map((file) => (
                                            <Chip
                                                key={file.id}
                                                color='secondary'
                                                size='small'
                                                label={
                                                    file.fileName.trim()
                                                        ? file.fileName
                                                        : t(
                                                              'bidding.process.section.messages.attachment'
                                                          )
                                                }
                                                onClick={() =>
                                                    window.open(file.fileMessage, '_blank')
                                                }
                                                variant='outlined'
                                                icon={
                                                    <FiPaperclip color='var(--secondary-color)' />
                                                }
                                            />
                                        ))}
                                    </ProcessChipsContainer>
                                )}
                            </div>
                        ))}
                        {enableManager && (
                            <div>
                                {replies?.length === 0 && (
                                    <>
                                        <BsArrowReturnRight
                                            size='0.75rem'
                                            color='var(--tertiary-color)'
                                        />
                                        {t('bidding.process.section.messages.empty-reply')}...
                                        <br />
                                    </>
                                )}
                                <ReplyButton
                                    disabled={loading}
                                    loading={loading}
                                    onClick={!loading ? () => setIsReplyEnable(true) : undefined}
                                >
                                    {replies?.length === 0
                                        ? t('bidding.process.section.messages.reply')
                                        : t('bidding.process.section.messages.replace-reply')}
                                </ReplyButton>
                            </div>
                        )}
                    </ProcessCommentRepliesContainer>
                )}
            </ProcessCommentContentContainer>
        </ProcessCommentContainer>
    );
};

export default memo(ProcessComment);
