import React, { useEffect } from 'react';
import Yup from 'common/utils/yup-extended.utils';
import { addNotificationError, apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import { globalConstants } from 'common/constants/global.constant';
import { ForgotPasswordView } from './ForgotPasswordView';

export const ForgotPassword = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const initialValues = { cpf: '', country: globalConstants.ibgeCodes.BRAZIL };
    const { sendPasswordResetCode, isfeatureFlagEnabled } = useUserConnectedProviders();
    const backofficeRedirectUrlPath = getBackofficeRedirectUrl();

    const validationSchema = Yup.object().shape({
        cpf: Yup.string().required(t('term.required-field')).max(100),
        country: Yup.string().required(t('term.required-field')),
    });

    const onSubmit = async ({ cpf, country }, { setSubmitting }) => {
        try {
            const cleanCpf = cpf.replace(/[.-]/g, '');

            await sendPasswordResetCode(cleanCpf, country);
            history.push(`/alterar-senha?username=${cleanCpf}&country=${country}`);
        } catch {
            addNotificationError();
        } finally {
            setSubmitting(false);
        }
    };

    const checkCognitoFeatureFlag = async () => {
        if (!isfeatureFlagEnabled) {
            apiRedirectTo(backofficeRedirectUrlPath, 'recuperarsenha', true);
        }
    };

    useEffect(() => {
        checkCognitoFeatureFlag();
    }, []);

    const form = useFormik({ initialValues, validationSchema, onSubmit });
    return <ForgotPasswordView form={form} />;
};
