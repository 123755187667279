import React, { useMemo } from 'react';

import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { apiRedirectTo, getBackofficeRedirectUrl, getMd5Hash } from 'common/utils';
import ProcessIframeDataView from './process-iframe-data-view';

interface ProcessIframeDataProps {
    urlTab: string;
}

const ProcessIframeData: React.FC<ProcessIframeDataProps> = ({ urlTab }) => {
    const { processForm } = useProcessFormContext();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const processId = processForm?.values?.id;
    const iframeTitle = `process-screen-on-${urlTab}-tab`;

    const iframeSrc = useMemo(() => {
        if (!processId || !urlTab) return;

        const auctionHash = getMd5Hash(String(processId));
        const path = encodeURIComponent(
            `processos/?ver&idE=${auctionHash}&aba=${urlTab}&typeFrame=1`
        );

        return apiRedirectTo(backofficeRedirectUrl, path, false, true);
    }, [processId, urlTab]);

    return (
        <ProcessIframeDataView
            title={iframeTitle}
            src={iframeSrc}
            loading={!iframeSrc && !!urlTab}
        />
    );
};

export default ProcessIframeData;
