const calculateMedian = (numbers: number[]) => {
    const sorted = numbers.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
};

const lowestValue = (numbers: number[]) =>
    numbers.reduce((prev, current) => (prev < current ? prev : current));

const average = (numbers: number[]) =>
    numbers.reduce((prev, current) => prev + current, 0) / numbers.length;

export { calculateMedian, lowestValue, average };
