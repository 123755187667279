import { FC, useEffect, useState, useRef } from 'react';
import moment from 'moment';

export interface CounterProps {
    secondsRemaining: number;
    onFinishTimer: () => void;
}

const Counter: FC<CounterProps> = ({ secondsRemaining, onFinishTimer }) => {
    const [seconds, setSeconds] = useState(secondsRemaining);
    const secondsRef: any = useRef(null);
    secondsRef.current = { seconds, setSeconds };

    const intervalRef: any = useRef(null);

    const handleClearInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = null;
    };

    const startCounter = () => {
        intervalRef.current = setInterval(() => {
            if (secondsRef.current.seconds >= 1) {
                return secondsRef.current.setSeconds(secondsRef.current.seconds - 1);
            }
            handleClearInterval();
            return onFinishTimer();
        }, 1000);
    };

    useEffect(() => {
        secondsRef.current.setSeconds(secondsRemaining);
        handleClearInterval();

        if (secondsRemaining > 0) {
            return startCounter();
        }
    }, [secondsRemaining]);

    useEffect(() => () => handleClearInterval(), []);

    const getFormat = () => {
        const { seconds } = secondsRef.current;

        if (seconds < 86_400) {
            return 'hh[h] mm[m] ss[s]';
        }

        return 'd[d] hh[h] mm[m] ss[s]';
    };

    return (
        <>
            {secondsRef.current.seconds <= 0
                ? null
                : moment
                      .duration(Math.round(secondsRef.current.seconds) * 1000)
                      .format(getFormat(), { forceLength: true, stopTrim: 'h' })}
        </>
    );
};

export default Counter;
