import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotificationApiError, addNotificationSuccess, validateDate } from 'common/utils';
import { usePageContext } from 'common/components/base/pages/context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import { useMarketplaceContext } from 'modules/marketplace/context/marketplace.context';
import { isFinite } from 'lodash';
import { useHistory } from 'react-router';
import CartPageView from './cart-page-view';

const CartPage = () => {
    const history = useHistory();
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const { onAddToCart, onRemoveFromCart, onItemQuantityChange, clearCart, marketplaceCart } =
        useMarketplaceContext();

    const onCreateOrder = async (deadlineToSendProposal: string) => {
        try {
            setLoading(true);

            const isValidDeadline =
                validateDate(deadlineToSendProposal) && !!deadlineToSendProposal;

            const response = await marketplaceRequests.createMarketplaceOrder({
                deadlineToSendProposal: isValidDeadline ? deadlineToSendProposal : undefined,
            });

            if (isFinite(response.data.id)) {
                history.push(`/mercado/pedidos/julgamento/${response.data.id}`);
                addNotificationSuccess({
                    message: t('marketplace.success-order-creation'),
                    title: t('term.success'),
                });
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            clearCart();
            setLoading(false);
        }
    };

    useEffect(() => {
        setTitle(t('term.cart'));
    });

    return (
        <CartPageView
            marketplaceCart={marketplaceCart}
            cartItems={marketplaceCart?.items}
            onAddToCart={onAddToCart}
            onRemoveFromCart={onRemoveFromCart}
            onItemQuantityChange={onItemQuantityChange}
            onCreateOrder={onCreateOrder}
            isCreating={loading}
        />
    );
};

export default CartPage;
