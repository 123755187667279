import { Grid } from '@material-ui/core';
import { memo } from 'react';
import { useParams } from 'react-router';
import ProcessSolicitationsMessagesContainer from 'modules/process/components/process-solicitations-data/components/process-solicitations-messages-container';

const ProcessSolicitationsData = () => {
    const { auctionId } = useParams<{
        [key: string]: string;
    }>();

    return (
        <Grid container>
            <Grid item xs={12}>
                <ProcessSolicitationsMessagesContainer {...{ id: auctionId }} />
            </Grid>
        </Grid>
    );
};

export default memo(ProcessSolicitationsData);
