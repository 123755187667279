import { Card } from '@material-ui/core';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SearchProvidersTable from './components/search-providers-table';

const SearchProvidersView = () => {
    return (
        <PageWrapper>
            <Card>
                <SearchProvidersTable />
            </Card>
        </PageWrapper>
    );
};

export default SearchProvidersView;
