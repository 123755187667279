import { serialize } from 'common/utils';
import { doRequest, priceBaseInstance } from '../api';
import { ItemPricesResponse } from './interfaces/item-prices-response.interface';

const itemRequests = {
    getItems: async (params: { [key: string]: any }): Promise<ItemPricesResponse> => {
        const query = serialize(params);
        return doRequest<ItemPricesResponse>(priceBaseInstance.get(`/items?${query}`));
    },
};

export { itemRequests };
