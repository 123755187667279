import { useTranslation } from 'react-i18next';
import { styled } from 'common/theme/styled';

const Header = styled.div`
    span {
        color: ${(props) => props.theme.colors.textLight};
        font-weight: 600;
    }

    div {
        padding: 12px 0;
    }

    display: flex;
    align-items: center;
    padding: 0 25px 0 25px;
    border-bottom: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
`;

const ProviderHeader = styled.div`
    width: 35%;
`;

const BrandHeader = styled.div`
    width: 17%;
`;

const ModelHeader = styled.div`
    width: 20%;
`;

const OriginHeader = styled.div`
    width: 14%;
`;

const PriceHeader = styled.div`
    width: 14%;
`;

const ItemPriceHeader = () => {
    const { t } = useTranslation();

    return (
        <Header>
            <ProviderHeader>
                <span>{t('term.supplier')}</span>
            </ProviderHeader>
            <BrandHeader>
                <span>{t('term.brand')}</span>
            </BrandHeader>
            <ModelHeader>
                <span>{t('term.model')}</span>
            </ModelHeader>
            <OriginHeader>
                <span>{t('term.origin')}</span>
            </OriginHeader>
            <PriceHeader>
                <span>{t('term.price')}</span>
            </PriceHeader>
        </Header>
    );
};

export default ItemPriceHeader;
