/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { AppState } from 'store';
import { setAuthUser } from 'store/user/user.actions';
import { userRequests } from 'clients/manager/user.requests';
import i18next from 'i18next';
import { isValidAuthUser } from 'common/utils/validators/validate-auth-user.utils';
import Authenticating from '../authenticating';
import { PrivateRouteProps } from './props';

const PrivateRoute: FC<PrivateRouteProps> = ({ component, ...rest }) => {
    const { authUser } = useSelector((state: AppState) => state.authUserState);

    const [authenticating, setAuthenticating] = useState(!authUser);
    const dispatch = useDispatch();
    const history = useHistory();

    const fetchAndSetUser = async () => {
        const user = await userRequests.getAuthenticatedUser();

        if (user) {
            i18next.changeLanguage(user.language);
            return dispatch(setAuthUser({ user }));
        }

        return setAuthenticating(false);
    };

    const authenticate = () => {
        setAuthenticating(true);

        if (!isValidAuthUser()) {
            return setAuthenticating(false);
        }

        fetchAndSetUser();
    };

    useEffect(() => {
        if (!authUser) {
            return authenticate();
        }

        const canAccess = rest.canAccess?.();

        if (!canAccess && canAccess !== undefined) {
            setAuthenticating(false);
            return history.push('/sem-permissao');
        }

        return setAuthenticating(false);
    }, [authUser]);

    const Component = component as FC;

    return (
        <Route
            /* eslint-disable react/jsx-props-no-spreading */
            {...rest}
            render={({ location }) =>
                authenticating ? (
                    <Authenticating />
                ) : authUser || rest.allowGuest ? (
                    <Component {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
export default PrivateRoute;
