import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { contractRequests } from 'clients/manager/contract.requests';
import { addNotificationError } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { ListContractsFromProcessParams } from 'clients/manager/interfaces/contract.interface';
import AlertContract from '../../components/alert-contract';
import { useContractsContext } from '../../context/contracts.context';
import ContractsListRow from '../../components/contracts-list-row';

interface ContractsListProps {
    processId: number;
}
const rowsPerPageOptions = [10, 20];

const ContractsList: React.FC<ContractsListProps> = ({ processId }) => {
    const [contractParams] = useState<ListContractsFromProcessParams>({
        processId,
    });
    const { t } = useTranslation();
    const { process } = useProcessFormContext();
    const { contracts, setContracts } = useContractsContext();
    const { data, loading, totalCount, page, limit, setLimit, setPage, error } = usePaginatedQuery(
        contractRequests.listContractsFromProcess,
        contractParams
    );

    useEffect(() => {
        setContracts(data?.data || []);
    }, [data?.data]);

    if (error) {
        addNotificationError({
            title: 'Ocorreu um erro',
            message: 'Não foi possível listar os contratos',
        });
    }

    return (
        <Box mt={2}>
            {process?.id && <AlertContract processId={process.id} />}
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('term.supplier')}</TableCell>
                                <TableCell>{t('contract.type')}</TableCell>
                                <TableCell>Nº</TableCell>
                                <TableCell>{t('term.period')}</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>{t('term.subscribers')}</TableCell>
                                <TableCell>{t('contracts.rating.head')}</TableCell>
                                <TableCell padding='none'>{t('term.actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableCell colSpan={8} align='center'>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && !contracts.length && (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography variant='body2' align='center'>
                                            {t('info.none-data')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading &&
                                contracts.map((contract) => (
                                    <ContractsListRow contractWithDetails={contract} />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    count={totalCount}
                    page={page}
                    onPageChange={(_e, page) => setPage(page)}
                    rowsPerPage={limit || rowsPerPageOptions[0]}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component='div'
                    onRowsPerPageChange={(event) =>
                        setLimit(Number(event.target.value) ?? rowsPerPageOptions[0])
                    }
                    labelRowsPerPage={t('text.lines-per-page')}
                />
            </Box>
        </Box>
    );
};

export default ContractsList;
