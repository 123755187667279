import styled from 'styled-components';
import OutBorderRadius from '../out-border-radius';

interface ProcessSearchItemContainerProps {
    favorited?: boolean;
    suggested?: boolean;
    proposal?: boolean;
}

export const ProcessSearchItemContainer = styled.div<ProcessSearchItemContainerProps>`
    background-color: var(--background-color);
    border: 1px var(--border-color) solid;
    border-radius: 4px;
    display: flex;
`;

export const ProcessSearchItemIconContainer = styled.div<ProcessSearchItemContainerProps>`
    flex-direction: column;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background-color: var(--secondary-color);
    color: var(--background-color);
    aspect-ratio: 1/1;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    width: 100%;
    max-width: 124px;
    transition: all 0.4s;

    > svg {
        font-size: 32px;
    }

    > h1 {
        color: var(--background-color);
        line-height: normal;
        position: relative;
        font-weight: 400;
        font-size: 80%;
        margin: 0;
        top: 8px;
    }

    > span {
        display: block;
        position: absolute;
        background-color: ${({ suggested }) => (suggested ? 'darkorange' : 'var(--primary-color)')};
        font-size: 10px;
        padding: 0 6px;
        line-height: 16px;
        border-radius: 4px;
        top: 8px;
        left: -8px;
    }

    > section {
        position: absolute;
        display: flex;
        background-color: var(--background-color);
        top: 0;
        right: 0;
        padding: 4px;
        gap: 4px;
        border-bottom-left-radius: 8px;
        color: var(--primary-color);
    }

    @media (max-width: 568px) {
        aspect-ratio: 2/3;
        max-width: calc(124px / 2);
        padding-top: 32px;

        > h1 {
            display: none;
        }

        > section {
            top: unset;
            bottom: 0;
            background-color: transparent;
            color: var(--background-color);
        }
    }

    @media (max-width: 400px) {
        display: none;
    }
`;

export const ProcessResponsiveBadgesContainer = styled.div`
    display: none;
    gap: 4px;
    color: var(--primary-color);

    svg {
        display: none;
    }

    @media (max-width: 568px) {
        display: flex;

        svg:last-child {
            display: block;
        }
    }

    @media (max-width: 400px) {
        svg:first-child {
            display: block;
        }
    }
`;

export const ProcessSearchItemLeftOutBorder = styled(OutBorderRadius)`
    position: absolute;
    left: -4px;
    top: 0;

    @media (max-width: 568px) {
        display: none;
    }
`;

export const ProcessSearchItemBottomOutBorder = styled(OutBorderRadius)`
    position: absolute;
    right: 0;
    bottom: -4px;
`;

export const ProcessSearchItemContent = styled.div`
    display: flex;
    flex: 1;
`;

export const ProcessSearchItemDescription = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 4px;
    flex: 1;

    > div {
        display: flex;
        align-items: center;
        gap: 8px;

        > img {
            height: 24px;
            padding: 4px;
            border: 1px solid var(--border-color);
            border-radius: 4px;
        }
        > h1 {
            font-size: 20px;
            color: var(--text-color);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            font-weight: 400;
            margin: 0;
        }
    }

    > h2 {
        max-width: 100%;
        word-break: break-word;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-light-secondary-color);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        margin: 0;
    }
`;

export const ProcessSearchIds = styled.p`
    display: block;
    margin: 0;
    font-size: 10px;
    color: var(--tertiary-color);
    margin-top: auto;
    padding-top: 8px;
`;

export const ProcessSearchItemStatusContainer = styled.div<{ color: string }>`
    position: relative;
    width: 100%;
    max-width: 120px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${({ color }) => color};
    padding: 0 16px;
    padding-top: 20px;

    h1 {
        font-size: 14px;
        text-align: center;
        line-height: normal;
        color: inherit;
    }

    @media (max-width: 568px) {
        display: none;
    }
`;

export const ProcessSearchItemActionsContainer = styled.div<ProcessSearchItemContainerProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--primary-color);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    > a {
        height: 100%;
        border-radius: 0;
        color: var(--background-color);
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
    }

    a + a {
        ::after {
            content: '';
            position: absolute;
            width: 60%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.15);
            left: 20%;
            top: 0;
        }
    }
`;
