import { doRequest, managerApiInstance } from 'clients/api';

interface ListAuctionNoticeFilesFilters {
    params: {
        auctionNoticeId: number;
    };
    isFavorite: boolean;
}

const favoriteAuctionNotice = {
    doChangeFavoriteProcess: async (filters: ListAuctionNoticeFilesFilters) =>
        doRequest(
            managerApiInstance.post('/favorite-auction-notice/doChangeFavoriteProcess', filters)
        ),
};

export { favoriteAuctionNotice };
