import React from 'react';

import { FaTrophy } from 'react-icons/fa';
import { Chip, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProviderValueStatus } from 'common/enums/provider-values-status.enum';
import ProviderStatusBadgeView from './provider-status-badge-view';

interface ProviderStatusBadgeProps {
    status?: ProviderValueStatus;
}

const ProviderStatusBadge: React.FC<ProviderStatusBadgeProps> = ({ status }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const mapper = {
        [ProviderValueStatus.winner]: {
            title: t('term.winner'),
            icon: <FaTrophy color={theme.palette.warning.main} size={16} />,
        },
        [ProviderValueStatus.called]: {
            title: t('marketplace.empate-ficto-description'),
            icon: <Chip label={t('term.convoke')} color='primary' size='small' />,
        },
        default: undefined,
    };

    const value = mapper[status ?? 'default'];
    return <ProviderStatusBadgeView hidden={!value} title={value?.title} icon={value?.icon} />;
};

export default ProviderStatusBadge;
