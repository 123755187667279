import { FC, useCallback, useEffect, useState } from 'react';
import baseStyled, { ThemeProvider, ThemedStyledInterface } from 'styled-components';
import { useMUIThemeContext } from '..';

export interface ThemeProps {
    theme: ThemeTypes;
}

export enum ThemeTypes {
    light = 'light',
    dark = 'dark',
}

export enum ThemeColors {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    background = 'background',
    backgroundPageDefault = 'backgroundPageDefault',
    info = 'info',
    warning = 'warning',
    danger = 'danger',
    // text colors
    textDefault = 'textDefault',
    textLight = 'textLight',
    textLight2 = 'textLight2',
    textDark = 'textDark',
    success = 'success',
    // others
    borderColorDefault = 'borderColorDefault',
    iconColorDefault = 'iconColorDefault',
    backgroundHoverLight = 'backgroundHoverLight',
    modalShadow = 'modalShadow',
    scrollbar = 'scrollbar',
    tableHeader = 'tableHeader',
}

export enum ScreenSize {
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    lgg = 'lgg',
    xg = 'xg',
    xgg = 'xgg',
}

export interface BackOfficeTheme {
    colors: { [key in ThemeColors]: string };
    screen: { [key in ScreenSize]: string };
}

const Theme: FC<ThemeProps> = ({ children, theme }) => {
    const { platformColor } = useMUIThemeContext();

    const lightTheme: BackOfficeTheme = {
        colors: {
            primary: platformColor?.primary ?? '#47516f',
            secondary: platformColor?.secondary ?? '#00b081',
            tertiary: platformColor?.secondary ?? '#317EC5',
            background: '#FFF',
            backgroundPageDefault: '#F7F7F7',
            textDefault: '#333',
            textLight: '#777',
            textLight2: '#999',
            textDark: '#000',
            info: platformColor?.info ?? '#17a2b8',
            warning: platformColor?.warning ?? '#ffc107',
            danger: platformColor?.danger ?? '#dc3545',
            success: platformColor?.success ?? '#28a745',
            iconColorDefault: '#676767',
            borderColorDefault: '#DDD',
            backgroundHoverLight: '#FAFAFA',
            scrollbar: '#999',
            modalShadow: '0 0.1rem 0.55rem 0 rgba(58, 59, 69, 0.15) !important',
            tableHeader: '#f5f5f5',
        },
        screen: {
            sm: '366px',
            md: '568px',
            lg: '1066px',
            lgg: '1366px',
            xg: '1590px',
            xgg: '1900px',
        },
    };

    const darkTheme: BackOfficeTheme = {
        ...lightTheme,
        colors: {
            ...lightTheme.colors,
            backgroundPageDefault: '#444',
            background: '#444',
            textDefault: '#FFF',
            textLight: '#FFF',
            textDark: '#FFF',
        },
    };

    const getTheme = useCallback(
        (): BackOfficeTheme => (theme === ThemeTypes.dark ? darkTheme : lightTheme),
        [theme, platformColor]
    );
    const [selectedTheme, setSelectedTheme] = useState<BackOfficeTheme>(getTheme());

    useEffect(() => {
        setSelectedTheme(getTheme());
    }, [theme, getTheme]);

    return <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>;
};

export const styled = baseStyled as ThemedStyledInterface<BackOfficeTheme>;
export default Theme;
