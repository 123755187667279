import { ButtonBase } from '@material-ui/core';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import styled from 'styled-components';

export const SearchPageWrapper = styled(PageWrapper)<{ active: boolean }>`
    position: relative;
    padding-top: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    > div {
        display: flex;
        flex-direction: column;
        position: relative;
        > section {
            display: flex;
            gap: 16px;

            @media (max-width: 1100px) {
                flex-direction: column;
            }

            > section {
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    @media (max-width: 1100px) {
        overflow-y: ${({ active }) => active && 'hidden'};
    }
`;

export const SearchHeader = styled.div`
    position: sticky;
    top: -1px;
    background-color: var(--background-page-default-color);
    padding: 0 16px;
    padding-top: 16px;
    margin-left: -16px;
    width: calc(100% + 32px);
    z-index: 99;
    display: flex;
    flex-direction: column;
`;

export const FiltersBackgroundBlock = styled.div<{ active: boolean }>`
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;

    @media (max-width: 1100px) {
        display: ${({ active }) => active && 'block'};
    }
`;

export const FastFiltersContainer = styled.div<{ active: boolean }>`
    position: relative;
    padding: 0 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: ${({ active }) => !active && '16px'};
    visibility: ${({ active }) => (active ? 'show' : 'hidden')};
    padding: ${({ active }) => active && '16px'};
    flex-wrap: wrap;

    > p {
        margin: 0;
    }

    > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }
`;

export const BlankArea = styled.div<{ border?: boolean; minHeight?: boolean }>`
    border: ${({ border }) => border && '1px dashed var(--border-color)'};
    border-radius: ${({ border }) => border && '1rem'};
    flex: ${({ minHeight }) => (minHeight ? 0 : 1)} 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    > h1,
    h2,
    h3 {
        line-height: normal;
        margin: 0;
    }

    > h1 {
        color: var(--primary-color);
    }

    > h2 {
        color: var(--text-light-color);
        font-weight: 300;
    }

    > h3 {
        color: var(--text-light-color);
        font-weight: 400;
    }
`;

export const SuggestedButton = styled(ButtonBase)<{
    active: boolean;
    color?: string;
    quantity?: number;
}>`
    background-color: ${({ active, color = 'var(--primary-color)' }) =>
        active ? color : 'transparent'};
    width: fit-content;
    padding: 4px 8px;
    padding-right: ${({ quantity }) => (quantity ? 42 : 16)}px;
    border-radius: 4px;
    border: solid 1px ${({ color = 'var(--primary-color)' }) => color};
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: ${({ active, color = 'var(--primary-color)' }) =>
        active ? 'var(--background-color)' : color};
    transition: color 0.4s, background-color 0.4s;

    ::after {
        content: '${({ quantity }) => quantity}';
        background-color: ${({ active }) => active && 'rgba(0, 0, 0, 0.15)'};
        border-left: 1px solid transparent;
        border-color: ${({ active, color = 'var(--primary-color)' }) => !active && color};
        transition: background-color 0.4s, border-color 0.4s;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        position: absolute;
        right: 0;
        display: ${({ quantity }) => (quantity ? 'flex' : 'none')};
        align-items: center;
        justify-content: center;
        font-weight: 600;
        height: 100%;
        width: 32px;
    }
`;
