import { Box, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';

const Image = styled.img`
    height: 140px;
`;

interface WithoutPermissionProps {}

const WithoutPermission: FC<WithoutPermissionProps> = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='60vh'>
                <Grid container direction='column' alignItems='center' justify='center'>
                    <Grid item xs={12}>
                        <Image
                            src='/assets/images/svgs/undraw_cant_access.svg'
                            alt={t('info.without-permission')}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Typography variant='h5' gutterBottom>
                            {t('info.without-permission')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            {t('info.without-permission-message')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </PageWrapper>
    );
};

export default WithoutPermission;
