import { Button, Typography } from '@material-ui/core';
import { Not } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { BiShoppingBag } from 'react-icons/bi';
import {
    ListMarketplaceItemsResponse,
    MarketplaceCartItem,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { useStyles } from './action-buttons-style';
import QuantityCounter from '../quantity-counter';

interface ActionButtonsProps {
    cartItem?: MarketplaceCartItem;
    type: 'cart' | 'order' | 'judgment';
    quantity?: number;
    hasOnCart: boolean;
    loading?: boolean;
    onAddToCart: () => void;
    onRemoveFromCart: () => void;
    onItemQuantityChange: (value: number) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
    cartItem,
    type,
    quantity,
    hasOnCart,
    loading,
    onAddToCart,
    onRemoveFromCart,
    onItemQuantityChange,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    const getMax = useCallback(
        (item?: ListMarketplaceItemsResponse) => {
            if (!item?.amountOrdered) return item?.amount ?? 0;

            if (item.amountOrdered > item.amount) return 0;

            return item.amount - item.amountOrdered;
        },
        [cartItem]
    );

    if (type === 'cart') {
        return (
            <>
                {Not(hasOnCart) && (
                    <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        disabled={loading}
                        startIcon={<BiShoppingBag size={18} />}
                        onClick={onAddToCart}
                        className={styles.addToCartButton}
                    >
                        <Typography variant='button' color='textSecondary'>
                            {t('term.marketplace-button-add')}
                        </Typography>
                    </Button>
                )}
                {hasOnCart && (
                    <QuantityCounter
                        initialQuantity={cartItem?.itemAmount ?? 0}
                        max={getMax(cartItem?.item)}
                        isRemoving={loading}
                        onChange={onItemQuantityChange}
                        onRemove={onRemoveFromCart}
                    />
                )}
            </>
        );
    }

    if (isNumber(quantity)) {
        return <Typography variant='button'>Qtd. {quantity}</Typography>;
    }

    return <></>;
};

export default ActionButtons;
