import { Button, ButtonProps, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './more-order-button-style';

interface MoreOrderButtonProps extends ButtonProps {
    active?: boolean;
}

const MoreOrderButton: React.FC<MoreOrderButtonProps> = ({ active = false, ...props }) => {
    const { t } = useTranslation();
    const styles = useStyles();

    if (active) {
        return (
            <>
                <Button {...props} className={styles.showMoreContainer}>
                    <Typography variant='button' color='textPrimary'>
                        {t('term.show-more-items')}
                    </Typography>
                </Button>
                <Divider className={styles.divider2} />
            </>
        );
    }

    return <Divider className={styles.divider} />;
};

export default MoreOrderButton;
