import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';
import { FormatListBulleted } from '@material-ui/icons';
import { useStyles } from './styles';

interface ChipLotsCountProps {
    count: number | string;
}

const ChipLotsCount: FC<ChipLotsCountProps> = ({ count }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Chip
            onClick={(event) => event.stopPropagation()}
            className={classes.chip}
            icon={<FormatListBulleted />}
            label={count}
            color='default'
            size='small'
            title={`${count} ${t('term.lot(s)')}`}
        />
    );
};

export default ChipLotsCount;
