import i18n from 'common/i18n';

function getTranslation(token: string, ns?: string) {
    return i18n.t(token, ns ? { ns } : undefined);
}

function getLanguage() {
    return i18n.language;
}

export { getTranslation, getLanguage };
