import { Grid, Breadcrumbs, Button, Card, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SearchOrganizationUsersTable from './components/search-organization-users-table';

const SearchOrganizationUsersView = ({ handleNew }) => {
    const { t } = useTranslation();

    return (
        <>
            <PageWrapper>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Breadcrumbs>
                        <Typography>
                            {t('organization-users.pages.components.users-unit')}
                        </Typography>
                    </Breadcrumbs>

                    <Button size='small' variant='contained' color='primary' onClick={handleNew}>
                        {t('term.add')}
                    </Button>
                </Grid>

                <br />

                <Card>
                    <SearchOrganizationUsersTable />
                </Card>
            </PageWrapper>
        </>
    );
};

export default SearchOrganizationUsersView;
