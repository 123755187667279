import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { MaskedTextField, TextField, Button } from 'common/components';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%;',
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    },
}));

const SignInView = ({ form }) => {
    const classes = useStyles();

    return (
        <>
            <Grid container justifyContent='center' alignItems='center' className={classes.root}>
                <Grid item xs={12} sm={8} md={4}>
                    <Paper className={classes.paper}>
                        <Typography variant='h4' align='center' gutterBottom>
                            Entrar
                        </Typography>

                        <form onSubmit={form.handleSubmit} noValidate>
                            <Grid container direction='column' spacing={2}>
                                <Grid item>
                                    <MaskedTextField
                                        fullWidth
                                        label='CPF'
                                        mask='999.999.999-99'
                                        name='cpf'
                                        value={form.values.cpf}
                                        onChange={form.handleChange}
                                        error={form.touched.cpf && !!form.errors.cpf}
                                        helperText={form.touched.cpf && form.errors.cpf}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        type='password'
                                        fullWidth
                                        label='Senha'
                                        name='password'
                                        value={form.values.password}
                                        onChange={form.handleChange}
                                        error={form.touched.password && !!form.errors.password}
                                        helperText={form.touched.password && form.errors.password}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        disabled={form.isSubmitting}
                                    >
                                        Entrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default SignInView;
