import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import Breadcrumb from 'common/components/base/breadcrumb';
import { EmptyList } from 'modules/marketplace/components';
import { ListContractsByOrderIdResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import MarketplaceContractCard from './components/marketplace-contract-card';

interface ContractPageViewProps {
    contracts?: ListContractsByOrderIdResponse[];
    loading?: boolean;
}

const ContractPageView: React.FC<ContractPageViewProps> = ({ contracts, loading = false }) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='mercado/pedidos/empenho'
                paths={{
                    mercado: {
                        value: t('term.marketplace'),
                        onClick: () => history.replace('/mercado'),
                    },
                    pedidos: {
                        value: t('term.orders'),
                        onClick: () => history.replace('/mercado/pedidos'),
                    },
                    empenho: { value: t('marketplace.contract.title'), disabled: true },
                }}
            />
            {isEmpty(contracts) && !loading && (
                <EmptyList
                    title={t('marketplace.contract.empty-list')}
                    subtitle={t('marketplace.cart.can-access-orders')}
                    href='/mercado/pedidos'
                />
            )}
            {loading && <MarketplaceContractCard loading />}
            {contracts?.map((contract) => (
                <MarketplaceContractCard
                    key={contract.contract.id}
                    contract={contract}
                    loading={loading}
                />
            ))}
        </PageWrapper>
    );
};

export default ContractPageView;
