import { addNotificationError, addNotificationSuccess } from 'common/utils';
import GroupAuthorizationService from 'modules/group-authorization/services/group-authorization.service';
import { useEffect, useState } from 'react';
import ModalGroupActionsView from './ModalGroupActionsView';

interface ActionGroupDTO {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
}

const ModalGroupActions = ({
    selectedCategory,
    setSelectedCategory,
    authorizationGroupId,
    readOnly,
}) => {
    const [actions, setActions] = useState<ActionGroupDTO[]>();

    const handleClose = () => setSelectedCategory(null);

    const handleSave = async () => {
        try {
            await GroupAuthorizationService.doInsertActionInAuthorizationGroup(
                authorizationGroupId,
                selectedCategory.id,
                actions?.filter((act) => act.enabled).map((act) => act.id)
            );

            addNotificationSuccess();
            handleClose();
        } catch {
            addNotificationError();
        }
    };

    useEffect(() => {
        const fetchActions = async () => {
            const data = await GroupAuthorizationService.getActionByCategoryAndGroup(
                authorizationGroupId,
                selectedCategory.id
            );

            setActions(data);
        };

        fetchActions();
    }, [selectedCategory]);

    const handleToggle = (action: ActionGroupDTO) => () => {
        if (!readOnly) {
            const updatedActions =
                actions &&
                actions.map((prevAct) =>
                    action.id === prevAct.id ? { ...action, enabled: !action.enabled } : prevAct
                );

            setActions(updatedActions);
        }
    };

    return (
        <ModalGroupActionsView
            {...{ selectedCategory, actions, handleToggle, handleSave, handleClose, readOnly }}
        />
    );
};

export default ModalGroupActions;
