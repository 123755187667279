import { Box, Link, ListSubheader, Card, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DocumentsAlmostExpiringOrExpired } from 'clients/manager/document-type-provider.request';
import Markdown from 'common/components/markdown';
import { theme } from 'common/theme';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RiCalendar2Fill, RiExternalLinkLine } from 'react-icons/ri';

const ExpiredDocumentsView = ({ data }: { data: DocumentsAlmostExpiringOrExpired[] }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    moment.locale(language);

    function formatDueDate(dueDate?: string) {
        if (!dueDate) return '';

        return `${moment(dueDate).utcOffset('-03:00').format('DD/MM/YYYY')} - ${moment(dueDate)
            .utcOffset('-03:00')
            .fromNow()}`;
    }
    function dateIsBeforeToday(date?: string) {
        if (!date) return null;

        return moment(date).utcOffset('-03:00').diff(moment(Date.now())) < 0;
    }

    return (
        <Card style={{ height: '350px' }}>
            <Box display='flex' alignItems='center'>
                <RiCalendar2Fill color='var(--primary-color)' size={24} />
                <Typography variant='h6' style={{ paddingLeft: 10 }}>
                    {t('provider-dashboard.components.expired-documents.next-due-dates')}
                </Typography>
            </Box>
            <List dense style={{ minHeight: 120 }}>
                {data.length ? (
                    <>
                        <ListSubheader style={{ display: 'flex', alignItems: 'center' }}>
                            <RiExternalLinkLine color='var(--secondary-color)' size={24} />
                            <Typography
                                variant='subtitle1'
                                style={{ paddingLeft: 10, marginTop: 20 }}
                            >
                                {t('term.register')}
                            </Typography>
                        </ListSubheader>
                        <Box paddingLeft={5} maxHeight={200} style={{ overflowY: 'scroll' }}>
                            {data.map((document) => (
                                <ListItem key={document.id}>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant='caption'
                                                style={{
                                                    color: dateIsBeforeToday(document.dueDate)
                                                        ? theme.palette.error.main
                                                        : theme.palette.warning.main,
                                                }}
                                            >
                                                {formatDueDate(document.dueDate)}
                                            </Typography>
                                        }
                                        secondary={
                                            <Link
                                                variant='caption'
                                                color='textPrimary'
                                                href='/empresa/editar/documentos'
                                            >
                                                {document?.documentType?.documentDescription ? (
                                                    <Markdown
                                                        variant='caption'
                                                        value={
                                                            document.documentType
                                                                .documentDescription
                                                        }
                                                    />
                                                ) : (
                                                    document?.fileName
                                                )}
                                            </Link>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </Box>
                    </>
                ) : (
                    <Box paddingLeft={5}>
                        <Typography variant='caption'>
                            {t(
                                'provider-dashboard.components.expired-documents.no-documents-close-to-expiry'
                            )}
                            ...
                        </Typography>
                    </Box>
                )}
            </List>
        </Card>
    );
};

export default ExpiredDocumentsView;
