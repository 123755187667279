import PlanView from './PlanView';

const Plan = ({
    title,
    benefits,
    priceType,
    value,
    handleSelected,
    selected,
    processType,
    paymentCondition,
    planName,
    objSelected,
}) => (
    <PlanView
        {...{
            title,
            benefits,
            priceType,
            value,
            handleSelected,
            selected,
            processType,
            paymentCondition,
            planName,
            objSelected,
        }}
    />
);

export default Plan;
