import { FC, useEffect, useState } from 'react';
import { Button, ButtonSize, Input, Modal } from '@licitar/ui-kit';
import * as XLSX from 'xlsx';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PriceMountType } from 'common/interfaces/price.interface';
import { PriceListItemRequest } from 'clients/price-base/interfaces/price-list-response.interface';
import { addNotificationError, timeout } from 'common/utils';
import { priceListRequests } from 'clients/price-base/price-list.requests';
import { DownloadButton, ActionsArea } from './styled';
import { ImportXlsxItemsProps } from './props';

interface XlsxItem {
    itemDescription: string;
    lotDescription: string;
    amount: number;
    unitMeasurement: string;
    priceMountType: number;
}

const XLSX_COLUMNS = 5;

const ImportXlsxItems: FC<ImportXlsxItemsProps> = ({ onItemsCreated, priceListId }) => {
    const [opened, setOpened] = useState(false);
    const [validatedItems, setValidatedItems] = useState<XlsxItem[]>([]);
    const [isValidXlsx, setIsValidXlsx] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setIsValidXlsx(false);
        setValidatedItems([]);
    }, [opened]);

    const validateXlsx = (data: any[], step: number) => {
        if (step === 1) {
            if (!isArray(data)) {
                throw new Error(t('mkp.user-price-list.unknown-error'));
            } else if (data.length === 0) {
                throw new Error(t('mkp.user-price-list.error-search-item'));
            } else if (!data.every((obj) => Object.keys(obj).length === XLSX_COLUMNS)) {
                throw new Error(t('mkp.user-price-list.verify-data'));
            }
        }
    };

    const transformXlsx = (data: any[]): XlsxItem[] => {
        const newData: XlsxItem[] = [];

        // @TODO: melhorar isso, feito para validações
        // eslint-disable-next-line no-restricted-syntax
        for (const row of data) {
            const obj: any = {};
            Object.keys(row).forEach((k, i) => {
                if (i === 0) {
                    obj.lotDescription = row[k];
                } else if (i === 1) {
                    obj.itemDescription = row[k];
                } else if (i === 2) {
                    obj.unitMeasurement = row[k];
                } else if (i === 3) {
                    obj.amount = row[k];
                } else if (i === 4) {
                    obj.priceMountType = row[k];
                }
            });
            newData.push(obj);
        }
        return newData;
    };

    const readFiles = (ev: any) => {
        const reader = new FileReader();
        const file = ev.target.files?.[0];

        if (!file) {
            return;
        }

        reader.onload = (e) => {
            const data = e?.target?.result;
            const readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            const dataParse: any[] = XLSX.utils.sheet_to_json(ws, { header: 0 });
            try {
                validateXlsx(dataParse, 1);
                const transformedData = transformXlsx(dataParse);
                validateXlsx(transformedData, 1);

                setValidatedItems(transformedData);
                setIsValidXlsx(true);
            } catch (error: any) {
                addNotificationError({
                    message: error?.message || t('mkp.user-price-list.unknown-error'),
                });
            }
        };
        reader.readAsBinaryString(file);
    };

    const getPriceMountType = (type: number) => {
        switch (type) {
            case 1:
                return PriceMountType.media;
            case 2:
                return PriceMountType.median;
            case 3:
                return PriceMountType.lowerPrice;
            default:
                return '';
        }
    };

    const createItems = async () => {
        const itemsPayload: PriceListItemRequest[] = validatedItems.map(
            (item) =>
                ({
                    amount: item.amount,
                    description: item.itemDescription,
                    itemPrices: [],
                    referenceValue: 0,
                    priceMountType: getPriceMountType(item.priceMountType) as PriceMountType,
                    unitMeasurement: item.unitMeasurement,
                    lotName: item.lotDescription,
                } as PriceListItemRequest)
        );

        try {
            await priceListRequests.createItemsPriceList(priceListId, itemsPayload);
            setOpened(false);
            timeout(() => onItemsCreated(itemsPayload.length), 100);
        } catch (error) {
            setOpened(false);
            addNotificationError({
                message: t('error.response.try-again'),
            });
        }
    };

    return (
        <>
            {opened && (
                <Modal
                    title={t('mkp.user-price-list.import-itens')}
                    opened={opened}
                    width='470px'
                    onClickOutSide={() => setOpened(false)}
                >
                    <span>
                        {t('mkp.user-price-list.download-model-msg-1')}
                        <DownloadButton
                            title={t('mkp.user-price-list.download-model')}
                            href='/assets/samples/modelo_importacao_planilha_itens_pesquisa_licitar.xlsx'
                            download
                        >
                            {t('mkp.user-price-list.download-model-msg-2')}
                        </DownloadButton>
                        {t('mkp.user-price-list.download-model-msg-3')}
                    </span>
                    <Input
                        style={{
                            margin: '20px 0 0 0',
                            width: '100%',
                        }}
                        accept='.xlsx, .xls'
                        type='file'
                        label={t('term.file')}
                        name='file'
                        onChange={(ev) => {
                            ev.preventDefault();
                            readFiles(ev);
                        }}
                    />
                    <ActionsArea>
                        <Button size={ButtonSize.sm} outline onClick={() => setOpened(false)}>
                            {t('term.cancel')}
                        </Button>
                        <Button size={ButtonSize.sm} disabled={!isValidXlsx} onClick={createItems}>
                            {t('term.add-itens')}
                        </Button>
                    </ActionsArea>
                </Modal>
            )}
            <Button size={ButtonSize.sm} onClick={() => setOpened(true)}>
                {t('mkp.user-price-list.import-itens')}
            </Button>
        </>
    );
};

export default ImportXlsxItems;
