import { addNotificationApiError, hasPermissionToExecute } from 'common/utils';
import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { ListProviderBodyData } from 'clients/manager/interfaces/provider.interface';
import { providerRequests } from 'clients/manager/provider.requests';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { AdminProfileType } from 'common/interfaces/user.interface';
import { useSearchProvidersContext } from '../../context/search-providers-context';
import SearchProvidersTableView from './search-providers-table-view';

const SearchProvidersTable = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const { setProviders, setRequestsCount, requestFilters } = useSearchProvidersContext();
    const { authUser } = useSelector((state: AppState) => state.authUserState);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const listProviders = async (requestFilters: ListProviderBodyData) => {
        setLoading(true);
        setError('');

        try {
            const response = await providerRequests.listProviders(requestFilters);
            setProviders(response.data || []);
            setRequestsCount(response?.meta?.count || 0);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            addNotificationApiError(error);
            setError(t('error.response.try-again'));
        }
    };

    const isSuperAdmin = authUser?.adminProfile === AdminProfileType.super;
    const hasPermissionToView = isSuperAdmin || hasPermissionToExecute('verDadosDosFornecedores');

    useEffect(() => {
        listProviders(requestFilters);
        setTitle(t('term.providers'));
    }, [requestFilters]);

    return (
        <>
            {error && <Typography color='error'>{error}</Typography>}
            {!error && (
                <SearchProvidersTableView
                    {...{
                        loading,
                        isSuperAdmin,
                        hasPermissionToView,
                    }}
                />
            )}
        </>
    );
};

export default SearchProvidersTable;
