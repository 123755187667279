import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import useStyles from './generic-error-style';

export default function GenericErrorView({ message }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Alert severity='error'>
                <AlertTitle>{t('bidding.notification-error-occurred')}</AlertTitle>
                <Typography>
                    {t(
                        message !== undefined
                            ? `bidding.benefits.${message.toLowerCase()}`
                            : 'bidding.error.try-again-later'
                    )}
                </Typography>
            </Alert>
        </div>
    );
}
