import { Grid, Card } from '@material-ui/core';
import Breadcrumb from 'common/components/base/breadcrumb';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { ListProviderInvoicesResponse } from 'clients/manager/interfaces/invoice.interface';
import { StatusPayment } from 'common/enums';
import SearchInvoicesTable from './components/search-invoices-table';
import SearchInvoicesTableHeader from './components/search-invoices-table-header';

export interface SearchInvoicesViewProps {
    queryParams: UsePaginatedQueryResponse<ListProviderInvoicesResponse>;
    onChangeStatusPayment: (status: StatusPayment) => void;
    statusPayment?: StatusPayment;
}

const SearchInvoicesView: React.FC<SearchInvoicesViewProps> = ({
    queryParams,
    onChangeStatusPayment,
    statusPayment,
}) => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Breadcrumb
                    customPath='/faturas'
                    paths={{
                        faturas: {
                            value: t('term.invoices'),
                            disabled: true,
                        },
                    }}
                />
            </Grid>
            <br />
            <Card>
                <SearchInvoicesTableHeader {...{ onChangeStatusPayment, statusPayment }} />
                <SearchInvoicesTable queryParams={queryParams} />
            </Card>
        </PageWrapper>
    );
};

export default SearchInvoicesView;
