import { DeepPartial } from 'redux';
/* eslint-disable @typescript-eslint/dot-notation */
import * as Yup from 'yup';
import i18n from 'i18next';
import { AuctionTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';
import { Platform } from 'clients/manager/platforms.requests';
import {
    AuctionNoticeLotItem,
    AuctionNoticeLotWithItems,
    RuleRestrictSegments,
    TypeOfBenefitValue,
    TypeOfItemsValue,
} from '../../../clients/manager/interfaces/auction-notice-lot.interface';
import { processRules, processUtils } from '../process-utils';
import {
    AuctionNotice,
    JudgmentCriterion,
    TypeValueBidTypes,
} from '../../../clients/manager/interfaces/auction-notice.interface';

export interface AuctionLotFormItem {
    itemDescription: string;
    susText: string;
    susCode: string;
    externalItemId?: string;
    unitMeasurement: string;
    amount: number;
    referenceValue: number;
    id: number;
    item: number;
}
export interface AuctionLotFormValues {
    items: AuctionLotFormItem[];
    id: number;
    externalItemId?: string;
    processId: number;
    item: number;
    typeOfBenefit: TypeOfBenefitValue;
    typeOfItems: TypeOfItemsValue;
    showReferenceValue: number;
    markIsRequired: number;
    lotDescription: string;
    bidAmountDifference: number;
    requireDocuments: number;
    quotaId?: number;
    hasQuotaCreated?: boolean;
    itemsKits?: number;
    ruleRestrictBySegments?: RuleRestrictSegments;
    listRestrictSegments?: string;
    forceSameDescription?: number;
    propertyOrFurnitureCode?: string;
    dateTimeInsert: Date;
    allowMultipleWinner?: boolean;
    multipleWinners?: {
        position: number;
        percent: string;
    }[];
}
export interface AuctionLotWithItemsForm {
    lots: AuctionNoticeLotWithItems[];
}

const i18nParams = { ns: 'validation' };

const getSchema = (
    values: AuctionLotFormValues,
    process: AuctionNotice,
    auctionTypeRules?: AuctionTypeRule,
    platform?: Platform
) => {
    const itemsValidation = {
        itemDescription: Yup.string()
            .min(3, i18n.t('enter-3-char', i18nParams))
            .required(i18n.t('require-description', i18nParams))
            .nullable(),
        unitMeasurement: Yup.string().required(i18n.t('required-field', i18nParams)).nullable(),
        amount: Yup.number()
            .min(0, i18n.t('enter-value-greater', { ...i18nParams, value: 0 }))
            .required(i18n.t('require-qtd', i18nParams))
            .nullable(),
        referenceValue: Yup.number()
            .min(0, i18n.t('enter-value-greater', { ...i18nParams, value: 0 }))
            .test(
                'not-zero',
                i18n.t('enter-value-greater', { ...i18nParams, value: 0 }),
                (value) => value !== 0
            )
            .required(i18n.t('require-value-reference', i18nParams))
            .nullable(),
    };

    if (processUtils.notAccreditationProcess(process)) {
        itemsValidation.amount = itemsValidation.amount.test(
            'not-zero',
            i18n.t('enter-value-greater', { ...i18nParams, value: 0 }),
            (value) => value !== 0
        );
    }
    const objValidation = {
        items: Yup.array()
            .of(Yup.object().shape(itemsValidation))
            .min(1, i18n.t('require-lot-items-length', { ...i18nParams, value: 1 })),
        id: Yup.number().nullable(),
        item: Yup.number().required(i18n.t('required-field', i18nParams)),
    };

    if (
        process.typeValueBid === TypeValueBidTypes.unit &&
        process.judgmentCriterion &&
        [JudgmentCriterion.lowestPriceRate, JudgmentCriterion.highestDiscount].includes(
            process.judgmentCriterion
        )
    ) {
        objValidation['items'] = Yup.array()
            .of(Yup.object().shape(itemsValidation))
            .min(1, i18n.t('require-lot-items-length', { ...i18nParams, value: 1 }))
            .max(1, i18n.t('require-max-lot-items-length', { ...i18nParams, value: 1 }));
    }

    const visibleAdvancedInfoFields = processRules.visibleLotAdvancedInfo(process);

    if (
        processRules.visibleLotTypeOfBenefit(auctionTypeRules, process) &&
        visibleAdvancedInfoFields
    ) {
        objValidation['typeOfBenefit'] = Yup.mixed().oneOf(
            Object.values(TypeOfBenefitValue),
            i18n.t('require-benefice-type', i18nParams)
        );

        if (values.typeOfBenefit === TypeOfBenefitValue.reservedQuota) {
            objValidation['quotaId'] = Yup.number()
                .required(i18n.t('required-field', i18nParams))
                .nullable();
        }
    }

    if (visibleAdvancedInfoFields) {
        if (processRules.visibleLotTypeOfItems(process)) {
            objValidation['typeOfItems'] = Yup.mixed()
                .oneOf(Object.values(TypeOfItemsValue), i18n.t('require-item', i18nParams))
                .required(i18n.t('require-item', i18nParams));
        }

        if (values.typeOfItems === TypeOfItemsValue.properties) {
            objValidation['propertyOrFurnitureCode'] = Yup.string()
                .required(i18n.t('require-property-code', i18nParams))
                .nullable();
        }

        if (values.typeOfItems === TypeOfItemsValue.furniture) {
            objValidation['propertyOrFurnitureCode'] = Yup.string()
                .required(i18n.t('require-furniture-code', i18nParams))
                .nullable();
        }

        if (processRules.visibleLotBidAmountDifference(process)) {
            objValidation['bidAmountDifference'] = Yup.number()
                .min(0, i18n.t('enter-value-greater-zero', i18nParams))
                .test(
                    'not-zero',
                    i18n.t('enter-value-greater-zero', i18nParams),
                    (value) => value !== 0
                )
                .required(i18n.t('require-value-between-bids', i18nParams));
        }

        objValidation['showReferenceValue'] = Yup.boolean();
        if (processRules.visibleLotItemsKits(auctionTypeRules, process)) {
            objValidation['itemsKits'] = Yup.number()
                .positive(i18n.t('enter-value-greater-zero', i18nParams))
                .required(i18n.t('require-qtd', i18nParams));
        } else {
            objValidation['itemsKits'] = Yup.number();
        }
        objValidation['requireDocuments'] = Yup.boolean().nullable();
        objValidation['forceSameDescription'] = Yup.boolean();
        objValidation['markIsRequired'] = Yup.boolean();
        objValidation['lotDescription'] = Yup.string()
            .min(5, i18n.t('enter-5-char', i18nParams))
            .required(i18n.t('require-description-lot', i18nParams))
            .nullable();

        if (processRules.visibleSegmentsByLotToMarketplace(process)) {
            objValidation['listRestrictSegments'] = Yup.string()
                .moreThanArraySplit({
                    min: 1,
                    message: i18n.t('min-required', { ...i18nParams, value: 1 }),
                })
                .nullable();
        }

        if (processRules.visibleSegmentsByLot(platform)) {
            objValidation['ruleRestrictBySegments'] = Yup.string().required(
                i18n.t('required-field', i18nParams)
            );

            if (
                values.ruleRestrictBySegments === RuleRestrictSegments.and ||
                values.ruleRestrictBySegments === RuleRestrictSegments.or
            ) {
                objValidation['listRestrictSegments'] = Yup.string().moreThanArraySplit({
                    min: 1,
                    message: i18n.t('min-required', { ...i18nParams, value: 1 }),
                });
            }
        }
    }

    return objValidation;
};

const getProdValidationSchema = (
    _: AuctionNoticeLotWithItems[],
    process: AuctionNotice,
    auctionTypeRules?: AuctionTypeRule,
    platform?: Platform
) =>
    Yup.object().shape({
        lots: Yup.array().of(
            Yup.lazy((obj) => {
                return Yup.object().shape(getSchema(obj, process, auctionTypeRules, platform));
            }) as any
        ),
    });

const transformLotToFormValues = (lot: AuctionNoticeLotWithItems): AuctionLotFormValues => ({
    items: lot.items.map((item) => ({
        id: item.id,
        item: item.item,
        itemDescription: item.itemDescription ?? '',
        unitMeasurement: item.unitMeasurement ?? '',
        amount: item.amount ?? 0,
        referenceValue: item.referenceValue ?? 0,
        susCode: item?.susCode ?? '',
        susText: item?.susText ?? '',
    })),
    id: lot.id,
    externalItemId: lot?.externalItemId,
    processId: lot.auctionNoticeId,
    item: lot.item,
    markIsRequired: lot.markIsRequired || 0,
    showReferenceValue: lot.showReferenceValue ?? 0,
    typeOfBenefit: lot.typeOfBenefit,
    typeOfItems: lot.typeOfItems,
    lotDescription: lot.lotDescription || '',
    bidAmountDifference: lot.bidAmountDifference ?? 0,
    requireDocuments: lot.requireDocuments ?? 0,
    quotaId: lot.quotaId,
    hasQuotaCreated: !!lot.quota?.id || false,
    itemsKits: lot.itemsKits || 1,
    ruleRestrictBySegments: lot.ruleRestrictBySegments || RuleRestrictSegments.free,
    listRestrictSegments: lot.listRestrictSegments,
    forceSameDescription: lot.forceSameDescription ?? 0,
    propertyOrFurnitureCode: lot.propertyOrFurnitureCode || '',
    dateTimeInsert: lot.dateTimeInsert || null,
    allowMultipleWinner: lot.allowMultipleWinner,
    multipleWinners:
        lot.multipleWinners?.map(({ percent, position }) => ({
            percent,
            position,
        })) || [],
});

const transformLotItemToCompareValues = (item?: DeepPartial<AuctionNoticeLotItem>) => ({
    item: item?.item,
    itemDescription: item?.itemDescription ?? '',
    unitMeasurement: item?.unitMeasurement ?? '',
    referenceValue: item?.referenceValue ?? 0,
    susCode: item?.susCode ?? '',
    susText: item?.susText ?? '',
    amount: item?.amount,
});

const transformLotToCompareValues = (lot: DeepPartial<AuctionNoticeLotWithItems>) => ({
    bidAmountDifference: lot?.bidAmountDifference,
    item: lot?.item,
    markIsRequired: Number(lot?.markIsRequired ?? 0),
    showReferenceValue: Number(lot?.showReferenceValue ?? 0),
    requireDocuments: Number(lot?.requireDocuments ?? 0),
    forceSameDescription: Number(lot?.forceSameDescription ?? 0),
    allowMultipleWinner: Number(lot?.allowMultipleWinner ?? 0),
    typeOfBenefit: lot?.typeOfBenefit,
    typeOfItems: lot?.typeOfItems,
    lotDescription: lot?.lotDescription || '',
    itemsKits: lot?.itemsKits || 1,
    ruleRestrictBySegments: lot?.ruleRestrictBySegments || RuleRestrictSegments?.free,
    listRestrictSegments: lot?.listRestrictSegments,
    propertyOrFurnitureCode: lot?.propertyOrFurnitureCode || '',
    multipleWinners:
        lot?.multipleWinners?.map((item) => ({
            percent: item?.percent,
            position: item?.position,
        })) || [],
});

export {
    getProdValidationSchema,
    transformLotToFormValues,
    transformLotToCompareValues,
    transformLotItemToCompareValues,
};
