export enum DocumentStage {
    basic = 'basic',
    additional = 'additional',
    process = 'process',
}

export interface DocumentTypeDTO {
    categoryDocumentId: number | undefined;
    documentName: string;
    documentDescription: string;
    documentStage?: DocumentStage;
    documentRequired: boolean;
    actived: boolean;
}
