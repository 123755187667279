import { styled } from 'common/theme/styled';

export const ButtonExtractorContainer = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ResumeContainer = styled('div')`
    display: flex;
    justify-content: space-between;
`;
