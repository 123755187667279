import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../theme/components';

interface ButtonEditContractProps {
    editingContract: boolean;
    onClickEditContract: (editing: boolean) => void;
}

const ButtonEditContract: FC<ButtonEditContractProps> = ({
    editingContract,
    onClickEditContract,
}) => {
    const { t } = useTranslation();

    const handleChangeToEditStep = async (edit: boolean) => {
        onClickEditContract(edit);
    };

    if (editingContract) {
        return (
            <Button
                style={{ marginRight: 8 }}
                size='small'
                variant='outlined'
                onClick={() => handleChangeToEditStep(false)}
            >
                {t('term.cancel')}
            </Button>
        );
    }

    return (
        <Button
            style={{ marginRight: 8 }}
            size='small'
            variant='contained'
            onClick={() => handleChangeToEditStep(true)}
        >
            {t('term.edit')}
        </Button>
    );
};

export default ButtonEditContract;
