import { Box, Typography, Divider, Card } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PieChartToolTipProps = {
    active?: boolean;
    payload?: Array<{
        dataKey?: 'valorTotal' | 'quantidadeItens';
        payload: {
            valorTotal: number;
            quantidadeItens: number;
            categoriaItemNome: string;
        };
    }>;
};

const PieChartCustomTooltip: React.FC<PieChartToolTipProps> = ({ payload, active = false }) => {
    const { t } = useTranslation();
    if (active && payload && payload.length > 0) {
        return (
            <Card
                variant='outlined'
                style={{
                    padding: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    borderRadius: '6px',
                    width: 'fit-content',
                    maxWidth: '256px',
                    borderWidth: '2px',
                    borderColor: 'var(--border-color)',
                }}
            >
                <Typography variant='subtitle2'>{payload[0].payload.categoriaItemNome}</Typography>
                <Divider
                    style={{
                        height: '2px',
                        marginTop: '6px',
                        marginBottom: '6px',
                        color: 'var(--border-color)',
                    }}
                />
                <Box>
                    <Typography variant='subtitle2'>
                        {payload[0].dataKey === 'valorTotal'
                            ? new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                              }).format(payload[0].payload.valorTotal)
                            : `${payload[0].payload.quantidadeItens} 
                            ${t('hiring-plans.title.units')}`}
                    </Typography>
                </Box>
            </Card>
        );
    }

    return null;
};

export default PieChartCustomTooltip;
