import { Link, List, ListItem, Card, ListItemText, Typography } from '@material-ui/core';
import { FaExternalLinkAlt } from 'react-icons/fa';

const NeedHelpView = ({ helps }) => (
    <>
        <Card>
            <Typography variant='h6'>Precisa de ajuda?</Typography>

            <List>
                {helps.map((help) => (
                    <ListItem disableGutters divider>
                        <ListItemText
                            primary={
                                <Link href={help.url} target='_blank'>
                                    {help.name}
                                </Link>
                            }
                            secondary={<Typography variant='body2'>{help.category}</Typography>}
                        />
                    </ListItem>
                ))}
            </List>
            <br />
            <Typography variant='body2'>Não encontrou o que está procurando?</Typography>
            <Typography variant='body2'>
                Acesse nossa{' '}
                <Link href='https://licitardigital.tawk.help/category/fornecedor' target='_blank'>
                    central de ajuda <FaExternalLinkAlt size={12} />
                </Link>
            </Typography>
        </Card>
    </>
);

export default NeedHelpView;
