import { useQuery } from 'common/hooks/use-query.hook';
import { addNotificationApiError } from 'common/utils';
import { useCallback, useRef, useState } from 'react';
import { marketplaceRequests } from '../services/marketplace.requests';

export const useMarketplaceCart = () => {
    const [actionsLoading, setActionsLoading] = useState(false);

    const quantityChangeAbortControllerRef = useRef<AbortController | null>(null);

    const {
        data: marketplaceCartResponse,
        loading: marketplaceCartLoading,
        setData,
    } = useQuery(marketplaceRequests.getMarketplaceCart, {
        onError: (error) => {
            addNotificationApiError(error);
        },
    });

    const onItemQuantityChange = useCallback(async (params: { id: number; quantity: number }) => {
        try {
            const { id, quantity } = params;

            quantityChangeAbortControllerRef.current?.abort();
            quantityChangeAbortControllerRef.current = new AbortController();

            const response = await marketplaceRequests.updateMarketplaceCartItemQuantity(
                {
                    id: id,
                    itemAmount: quantity,
                },
                { signal: quantityChangeAbortControllerRef.current.signal }
            );
            const item = response.data as any;

            if (item.id) {
                setData((state) => {
                    const newItems = state?.data.items.map((stateItem) => {
                        if (stateItem.id === item.id) return item;
                        return stateItem;
                    });

                    return {
                        ...state,
                        data: { ...state?.data, items: newItems },
                    } as any;
                });
            }
        } catch (error) {
            if (error.message === 'canceled') {
                return;
            }

            addNotificationApiError(error);
        }
    }, []);

    const onAddToCart = useCallback(async (id: number) => {
        try {
            setActionsLoading(true);

            const response = await marketplaceRequests.createMarketplaceCartItem({
                itemId: id,
                itemAmount: 1,
            });
            const item = response.data as any;

            if (item?.id) {
                setData(
                    (state) =>
                        ({
                            ...state,
                            data: { ...state?.data, items: [...(state?.data?.items ?? []), item] },
                        } as any)
                );
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setActionsLoading(false);
        }
    }, []);

    const onRemoveFromCart = useCallback(async (id: number) => {
        try {
            setActionsLoading(true);

            const response = await marketplaceRequests.deleteMarketplaceCartItem({ id: id });
            const isDeleted = response.status === 'success';

            if (isDeleted) {
                setData((state) => {
                    const newItems = state?.data.items.filter(
                        (cartItem) => cartItem.item.id !== id
                    );

                    return {
                        ...state,
                        data: { ...state?.data, items: newItems },
                    } as any;
                });
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setActionsLoading(false);
        }
    }, []);

    const clearCart = useCallback(() => {
        setData(
            (state) =>
                ({
                    ...state,
                    data: { ...state?.data, items: [] },
                } as any)
        );
    }, []);

    return {
        marketplaceCartResponse,
        marketplaceCartLoading,
        actionsLoading,
        onItemQuantityChange,
        onAddToCart,
        onRemoveFromCart,
        clearCart,
    };
};
