import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { useEffect, useState, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'common/components/base/breadcrumb';
import { usePageContext } from 'common/components/base/pages/context';
import { Button, ButtonSize } from '@licitar/ui-kit';
import PreviewEditor from 'modules/editor/components/preview-editor';
import { useTranslation } from 'react-i18next';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import { addNotificationWarning } from 'common/utils';
import { Header, Page } from './styles';

interface Params {
    [key: string]: string;
}

interface ViewTemplateProps {}

const ViewTemplate: FC<ViewTemplateProps> = () => {
    const { t } = useTranslation();
    const [template, setTemplate] = useState<DocumentTemplate | undefined>(undefined);
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const { templateId = undefined } = useParams<Params>();

    const history = useHistory();
    const { setTitle } = usePageContext();

    const getTemplate = async () => {
        if (!templateId) {
            return;
        }
        try {
            const { data: docTemplate } = await templateRequests.getTemplate(templateId);
            setTemplate(docTemplate);
            setLoadingTemplate(false);
        } catch (error) {
            setLoadingTemplate(false);
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.templates.components.templates.pages.notification-error-onload-model'
                ),
            });
        }
    };

    useEffect(() => {
        setTitle(t('title.model-documents'));
        getTemplate();
    }, []);

    const handleEdit = () => {
        if (!template) {
            return;
        }
        history.push(`/processos/editor/modelos/${template._id}/edit`);
    };

    return (
        <Page>
            <Header>
                <Breadcrumb
                    customPath='/modelos/:templateId'
                    paths={{
                        modelos: {
                            value: t('editor.templates.components.templates.pages.list-model'),
                            onClick: () => history.push('/processos/editor/modelos'),
                        },
                    }}
                    params={{
                        templateId: {
                            value: template?.name ?? '',
                            disabled: true,
                        },
                    }}
                />
                <div style={{ display: 'flex' }}>
                    <Button
                        size={ButtonSize.sm}
                        title={t('editor.templates.components.templates.pages.continue-edit-model')}
                        style={{ margin: '2px 0 0 10px' }}
                        onClick={() => handleEdit()}
                    >
                        {t('editor.templates.components.templates.pages.edit-model')}
                    </Button>
                </div>
            </Header>
            <PreviewEditor withBorders loading={loadingTemplate} html={template?.content} />
        </Page>
    );
};

export default ViewTemplate;
