import React from 'react';
import {
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { TextField } from 'common/components';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { formatCpfOrCnpj } from 'common/utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ListUsersBelongToOrganizationResponse } from 'modules/organization-users/interfaces/organization-users.interface';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { Skeleton } from '@material-ui/lab';
import TableCellHidden from 'common/components/table-cell-hidden';

const rowsPerPageOptions = [10, 20];

interface SearchOrganizationUsersTableViewProps {
    handleChangeFilter: (event: any) => void;
    handleEdit: (user: { cpf: string; country: string }) => void;
    handleBlock: (user: any) => void;
    queryParams: UsePaginatedQueryResponse<ListUsersBelongToOrganizationResponse>;
}

const SearchOrganizationUsersTableSkeleton = () => (
    <TableRow hover>
        <TableCell>
            <Skeleton width={110} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={100} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={120} variant='text' />
        </TableCell>
        <TableCell>
            <Skeleton width={70} variant='text' />
        </TableCell>
        <TableCell align='center'>
            <Skeleton width={60} variant='text' />
        </TableCell>
    </TableRow>
);

const SearchOrganizationUsersTableView: React.FC<SearchOrganizationUsersTableViewProps> = ({
    queryParams,
    handleChangeFilter,
    handleEdit,
    handleBlock,
}) => {
    const { t } = useTranslation();

    const { data, loading, totalCount, page, limit, setLimit, setPage } = queryParams;
    const users = data?.data;

    return (
        <>
            <TextField
                label={t('organization-users.pages.components.search-user')}
                placeholder={t('group-auth.pages.search-group.components.search-group-press-enter')}
                fullWidth
                onKeyPress={handleChangeFilter}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.name')}</TableCell>
                        <TableCell>CPF</TableCell>
                        <TableCell>{t('term.start-date')}</TableCell>
                        <TableCell>Autorizações</TableCell>
                        <TableCell>{t('term.actions')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {users &&
                        users.map((row) => (
                            <TableRow hover key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCellHidden cpf={formatCpfOrCnpj(row.cpf)} />
                                <TableCell>
                                    {moment(row.dateTimeInsert).format('DD/MM/YYYY HH:mm:ss')}
                                </TableCell>
                                <TableCell>
                                    {row.authorizationProfile === 'unit'
                                        ? 'Unidade'
                                        : 'Organização'}
                                </TableCell>
                                <TableCell align='center' padding='none' style={{ width: 100 }}>
                                    <Grid>
                                        <Tooltip title={t('term.edit') as string}>
                                            <IconButton onClick={() => handleEdit(row)}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={t('term.delete') as string}>
                                            <IconButton onClick={() => handleBlock(row)}>
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    {!loading && users && !users.length && (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {loading && (
                        <>
                            <SearchOrganizationUsersTableSkeleton />
                            <SearchOrganizationUsersTableSkeleton />
                        </>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={totalCount}
                page={page}
                onPageChange={(_e, page) => setPage(page)}
                rowsPerPage={limit || rowsPerPageOptions[0]}
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                onRowsPerPageChange={(event) =>
                    setLimit(Number(event.target.value) ?? rowsPerPageOptions[0])
                }
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </>
    );
};

export default SearchOrganizationUsersTableView;
