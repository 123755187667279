import { JudgmentCriterion } from 'clients/manager/interfaces/auction-notice.interface';
import i18n from 'common/i18n';

const getJudgmentCriterion = (judgment?: JudgmentCriterion, rateTypeBid?: boolean): string => {
    const obj = {
        [JudgmentCriterion.bestTechnique]: i18n.t('term.best-technique'),
        [JudgmentCriterion.highestDiscount]: i18n.t('term.highest-discount'),
        [JudgmentCriterion.highestPrice]: i18n.t('term.highest-price'),
        [JudgmentCriterion.lowestPrice]: i18n.t('term.lowest-price'),
        [JudgmentCriterion.lowestPriceRate]: `${i18n.t('term.lowest-price-rate')}`,
        [JudgmentCriterion.techniqueAndPrice]: i18n.t('term.technique-and-price'),
        [JudgmentCriterion.artisticContent]: i18n.t('term.artistic-content'),
        [JudgmentCriterion.highestEconomicReturn]: i18n.t('term.highest-economic-return'),
        [JudgmentCriterion.notApplicable]: i18n.t('term.not-applicable'),
    };

    if (rateTypeBid && judgment === JudgmentCriterion.lowestPrice)
        return obj[JudgmentCriterion.lowestPriceRate];
    return typeof judgment === 'number' ? obj?.[judgment] : '';
};

export { getJudgmentCriterion };
