import { FC, useRef, useState, useEffect } from 'react';
import { Editor as TEditor } from '@tinymce/tinymce-react';
import { nanoid } from 'nanoid';
import { Content } from './styles';
import { OrganizationBlocksEditorConfigs } from './editor-config.service';
import { EDITOR_KEY } from '../../../../editor/components/editor';
import { OrganizationBlockEditorProps } from './props';

const OrganizationBlockEditor: FC<OrganizationBlockEditorProps> = ({
    initialData,
    onChange,
    onUploadFile,
    editorId,
    variables,
}) => {
    const editorRef: any = useRef(null);
    const [localEditor, setLocalEditor] = useState<string>(initialData);

    useEffect(() => {
        setLocalEditor(initialData);
    }, [initialData]);

    const [editorKey] = useState(nanoid());

    const onChangeEditor = () => {
        if (editorRef.current) {
            return onChange(editorRef.current.getContent());
        }
    };

    return (
        <Content>
            <TEditor
                key={editorKey}
                id={editorId}
                apiKey={EDITOR_KEY}
                onInit={(_, editor) => {
                    editorRef.current = editor;
                }}
                initialValue={localEditor}
                onChange={onChangeEditor}
                init={OrganizationBlocksEditorConfigs.init(onUploadFile, variables)}
            />
        </Content>
    );
};

export default OrganizationBlockEditor;
