import { 
    CircularProgress, 
    Table, 
    TableBody, 
    TableRow, 
    TableContainer, 
    TableFooter, 
    TablePagination 
} from '@material-ui/core';
import { AuctionTransaction } from 'clients/manager/interfaces/auction-notice.interface';
import ProcessMessage from 'modules/bidding/components/process-notice';
import { useTranslation } from 'react-i18next';
import { AiFillMessage } from 'react-icons/ai';
import { ProcessDescriptionCard, ProcessNoticesContainer } from '../../styled';
import { Dispatch, SetStateAction } from 'react';

type NoticiesConfig = { limit: number; offset: number; loading: boolean; count: number };

interface MessageContainerProps {
    transactions?: AuctionTransaction[];
    loading?: boolean;
    noticiesConfig: NoticiesConfig;
    setNoticiesConfig: Dispatch<SetStateAction<NoticiesConfig>>;
}

const NoticesContainer = ({ 
    transactions = [], 
    loading, 
    noticiesConfig, 
    setNoticiesConfig 
}: MessageContainerProps) => {
    const { t } = useTranslation();

    return (
        <ProcessDescriptionCard elevation={0} variant='outlined'>
            <header>
                <AiFillMessage size='1.5rem' />
                <h1>{t('bidding.process.section.notices')}</h1>
            </header>
            <section>
                <ProcessNoticesContainer
                    empty={!loading && transactions.length === 0}
                    {...{ loading }}    
                >
                    {noticiesConfig.loading && <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50vh',
                        }}
                        >
                        <CircularProgress />
                        </div>}
                    {!noticiesConfig.loading && transactions.length === 0 && (
                        <h3>{t('bidding.process.section.notices.blank')}</h3>
                    )}
                    <TableContainer>
                        <Table>
                            <TableBody>

                            {!noticiesConfig.loading &&
                                transactions.map((transaction) => (
                                    
                                    <TableRow key={transaction.id}>
                                        
                                        <ProcessMessage
                                            date={new Date(transaction.dateTimeTransaction)}
                                            name={transaction.user ? transaction.user.name : ''}
                                            key={`${transaction.user?.name}-${transaction.description}`}
                                        >
                                            {transaction.description}
                                        </ProcessMessage>

                                    </TableRow>

                                ))}

                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 40]}
                                        count={noticiesConfig.count}
                                        rowsPerPage={noticiesConfig.limit}
                                        page={noticiesConfig.offset / noticiesConfig.limit}
                                        labelRowsPerPage={t(
                                            'bidding.process.lot.table.action.rows-per-page'
                                        )}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': t(
                                                    'bidding.process.lot.table.action.rows-per-page'
                                                ),
                                            },
                                            native: true,
                                        }}
                                        onPageChange={(_, page) => {
                                            setNoticiesConfig((prev) => ({
                                                ...prev,
                                                offset:
                                                    page * prev.limit < 0
                                                        ? 0
                                                        : page * prev.limit,
                                            }));
                                        }}
                                        onRowsPerPageChange={({ target }) => {
                                            setNoticiesConfig((prev) => ({
                                                ...prev,
                                                limit: Number(target.value),
                                            }));
                                        }}
                                    />
                                </TableRow>
                            </TableFooter>

                        </Table>
                    </TableContainer>

                </ProcessNoticesContainer>
            </section>
        </ProcessDescriptionCard>
    );
};

export default NoticesContainer;
