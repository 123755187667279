import { styled } from 'common/theme/styled';

const ViewPage = styled.div<{ withBorders?: boolean }>`
    width: 793px;
    max-width: 793px;
    font-family: Arial, Helvetica, sans-serif !important;
    outline: none;
    flex: 1;
    cursor: text;
    min-height: 1123px;
    background: #fff;
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.textDefault};
    word-break: break-word;

    .variable {
        color: #2866d8;
    }

    .replaced-variable {
        color: #316fe2 !important;
    }

    table {
        border-collapse: collapse;

        td {
            border: 1px #ccc solid;
            padding: 0.4rem;
        }
    }

    ${(props) =>
        props.withBorders &&
        `
         overflow: hidden;
         padding: 16px;
         border: 1px #ccc solid;
    `}
`;

const EditArea = styled.div`
    display: flex;
    justify-content: center;
    height: calc(100% - 56px);
    overflow: auto;
    padding: 16px;
`;

const SkeletonLine = styled.div`
    width: 100%;
`;

export { EditArea, ViewPage, SkeletonLine };
