import ReactDOM from 'react-dom';
import { AxiosRequestConfig } from 'axios';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { GenericBody } from 'clients/interfaces/paginated.interface';
import { UseQueryConfigOptions } from './use-query.hook';

export interface UsePaginatedQueryResponse<TData> {
    data?: TData;
    setData: Dispatch<SetStateAction<TData | undefined>>;
    loading: boolean;
    error?: any;
    totalCount: number;
    totalPages: number;
    page: number;
    offset: number;
    limit: number;
    setOffset: Dispatch<SetStateAction<number>>;
    setLimit: (limit: number) => void;
    setPage: (page: number) => void;
    nextPage: () => void;
    prevPage: () => void;
    hasNext: boolean;
    hasPrev: boolean;
}

export interface UsePaginatedQueryConfigOption extends UseQueryConfigOptions {
    initialOffset?: number;
    initialLimit?: number;
}

export function usePaginatedQuery<TData, TParams extends any>(
    axiosRequest: (
        body?: GenericBody<TParams>,
        config?: AxiosRequestConfig<any> | undefined
    ) => Promise<TData>,
    params?: TParams,
    config?: UsePaginatedQueryConfigOption
): UsePaginatedQueryResponse<TData> {
    const [data, setData] = useState<TData>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(config?.initialOffset ?? 0);
    const [limit, setBaseLimit] = useState(config?.initialLimit ?? 10);

    const page = Math.ceil(offset / limit);

    const totalCount = (data as any)?.meta?.count ?? 0;
    const totalPages = Math.ceil(totalCount / limit);

    const hasNext = page < totalPages;
    const hasPrev = offset > 0;

    useEffect(() => {
        const abortController = new AbortController();
        const fetchAPI = async () => {
            try {
                setLoading(true);
                setData(undefined);
                const data = await axiosRequest(
                    {
                        limit,
                        offset,
                        params,
                    },
                    {
                        signal: abortController.signal,
                    }
                );
                setData(data);
            } catch (err) {
                setError(err);
            } finally {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                }
            }
        };

        if (!config?.skip) fetchAPI();
        return () => abortController.abort();
    }, [offset, limit, params]);

    const setLimit = useCallback(
        (limit: number) => {
            ReactDOM.unstable_batchedUpdates(() => {
                setBaseLimit(limit);
                setOffset(0);
            });
        },
        [offset]
    );

    const setPage = useCallback(
        (num: number) => {
            const possibleOffset = num * limit;

            if (possibleOffset > totalCount) return setOffset(totalCount);

            setOffset(possibleOffset);
        },
        [data]
    );

    const nextPage = useCallback(() => {
        if (hasNext) {
            const restOfTheItems = totalCount - offset;

            if (restOfTheItems < 0) return;

            if (restOfTheItems > limit) setOffset((state) => state + limit);
            else setOffset((state) => state + restOfTheItems);
        }
    }, [data]);

    const prevPage = useCallback(() => {
        if (hasPrev) {
            if (limit > offset) setOffset(0);
            else setOffset((state) => state - limit);
        }
    }, [data]);

    return {
        data,
        setData,
        loading,
        error,
        totalCount,
        totalPages,
        page,
        offset,
        limit,
        setOffset,
        setLimit,
        setPage,
        nextPage,
        prevPage,
        hasNext,
        hasPrev,
    };
}
