import { Theme, makeStyles } from '@material-ui/core';
import { StatusPayment } from 'common/enums';

export const useStyles = makeStyles((theme: Theme) => ({
    [StatusPayment.pending]: {
        backgroundColor: theme.palette.grey[400],
        padding: theme.spacing(1.6),
        color: theme.palette.primary.contrastText,
    },
    [StatusPayment.free]: {
        backgroundColor: theme.palette.info.main,
        padding: theme.spacing(1.6),
        color: theme.palette.primary.contrastText,
    },
    [StatusPayment.canceled]: {
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(1.6),
        color: theme.palette.primary.contrastText,
    },
    [StatusPayment.paid]: {
        backgroundColor: theme.palette.success.main,
        padding: theme.spacing(1.6),
        color: theme.palette.primary.contrastText,
    },
}));
