import { Breadcrumbs, Typography } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import OrganizationTabs from './components/organization-tabs';
import { EditOrganizationProps } from './props';

const EditOrganization: FC<EditOrganizationProps> = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    useEffect(() => {
        setTitle(t('title.edit-data-organization'));
    }, []);

    return (
        <PageWrapper>
            <Breadcrumbs color='primary'>
                <Typography color='primary'>{t('term.home')}</Typography>
                <Typography color='primary'>
                    {t('shared.pages.menu-header.option.organization-data')}
                </Typography>
            </Breadcrumbs>
            <OrganizationTabs />
        </PageWrapper>
    );
};

export default EditOrganization;
