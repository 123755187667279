import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    // background: #e5e5e5;
    padding-left: 45px;
    padding-right: 45px;
    overflow: auto;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    min-height: calc(100vh - 120px);
    overflow: auto;
    margin: 0 auto;

    .MuiBreadcrumbs-ol {
        margin: 20px 0;
    }

    .MuiBreadcrumbs-separator {
        color: #777777;
    }

    button {
        margin: 20px 0;
        span {
            font-family: 'Roboto';
        }
    }

    .MuiFormHelperText-root.Mui-error {
        margin-bottom: 0;
    }

    .MuiFormHelperText-contained {
        margin-left: 0;
    }

    h5 {
        margin-bottom: 20px;
    }

    .content-steppers {
        background: #ffffff;
        padding: 25px;

        form {
            width: 100%;
        }
    }

    .MuiTableHead-root {
        border-bottom: 1px solid #dddddd;
    }

    .MuiTableCell-head {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .MuiTableCell-root {
        button {
            margin: 8px 0;
        }

        .MuiSvgIcon-root {
            font-size: 1.4rem;
        }
    }

    .stepper-nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .MuiPaper-elevation1 {
        border: 1px solid #dddddd;
        border-radius: 2px;
        box-shadow: none;
    }

    .box-item {
        position: relative;
        padding: 25px;
        border-radius: 2px;
        margin: 15px auto;

        .accordion-item {
            width: 100%;
        }
    }

    .MuiAccordion-root {
        margin: 20px auto;
    }

    .MuiCheckbox-root {
        color: #777777;
    }

    .MuiCheckbox-root.Mui-checked {
        color: #47516f;
    }

    .MuiAccordionDetails-root {
        padding-left: 40px;
    }

    .MuiAccordionSummary-content {
        margin: 10px 0;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 10px 0 0 0;
    }

    .btn-upload {
        margin: 20px 0;
        span {
            font-family: 'Roboto';
        }
        input {
            display: none;
        }
    }

    .legend {
        padding: 10px;
        margin-left: 10px;
    }

    .doc-name {
        text-transform: initial;
        margin: 5px 0;
    }

    .sign-up-sucess {
        max-width: 700px;
        margin: 0 auto;

        h4 {
            text-align: center;
        }

        p {
            margin-bottom: 20px;
        }

        .image-sucess {
            margin: 30px auto 10px auto;
            width: 100%;
            max-width: 345px;
        }
    }

    .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
        padding-top: 23px;

        @media (max-width: 1024px) {
            padding-top: 40px;
        }

        @media (max-width: 750px) {
            padding-top: 50px;
        }
    }
`;

export const BoxItem = styled.div`
    padding: 20px 10px 0 10px;
    width: 210px;
    height: 250px;
    border 1px solid #DDDDDD;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    p {
        text-transform: uppercase;
        span {
            color: #E74C3C;
        }
    }
`;
