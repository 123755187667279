import { useEffect, useState } from 'react';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { addNotificationApiError } from 'common/utils';
import { providerRequests } from 'clients/manager/provider.requests';
import { debounce } from 'lodash';
import { processInvitesRequests } from 'clients/manager/process-invites.requests';
import { Participant } from 'clients/manager/interfaces/auction-notice.interface';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import { ProcessInvite } from 'clients/manager/interfaces/process-invite.interface';
import ProcessParticipantView from './process-participant-view';

const participantInitialValues = {
    document: '',
    email: '',
    name: '',
};

const ProcessParticipant = (defaultProps) => {
    const { processForm, setProcessBkpBeforeSave } = useProcessFormContext();
    const [participant, setParticipant] = useState<Participant | null | undefined>(
        participantInitialValues
    );
    const [isNewParticipant, setIsNewParticipant] = useState(false);
    const [showButtonCleanData, setShowButtonCleanData] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const isButtonDisabled =
        processForm?.values.participantDocument === undefined ||
        processForm?.values.participantDocument?.length < 11;
    const [loading, setLoading] = useState(false);

    const handleParticipantDocumentChange = (event) => {
        const inputValue = event.target.value.replace(/\D/g, '');
        setParticipant((prevParticipant) => {
            if (!prevParticipant) {
                return {
                    email: '',
                    name: '',
                    document: inputValue,
                };
            }

            return {
                ...prevParticipant,
                document: inputValue,
            };
        });

        processForm?.setFieldValue('participantDocument', inputValue);
    };

    const handleGetParticipant = async () => {
        try {
            setLoading(true);
            processForm?.setFieldValue(
                'participantDocument',
                processForm?.values?.participantDocument
            );
            processForm?.setFieldValue('participantName', processForm?.values?.participantName);
            processForm?.setFieldValue('participantEmail', processForm?.values?.participantEmail);
            await getParticipant(true, true);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeDefault = debounce(
        (event: React.ChangeEvent<HTMLInputElement>) =>
            processForm?.setFieldValue(event.target.name, event.target.value),
        300
    );

    function fetchParticipantData(data: Provider | ProcessInvite, type: string): Participant {
        if (type === 'provider') {
            const { docNumber, companyName, legalRepresentative, zipCode, state, cityName } =
                data as Provider;

            return {
                document: docNumber,
                name: companyName,
                email: legalRepresentative?.email ?? '',
                zipCode,
                state,
                city: cityName,
            };
        }

        const { document, name, email } = data as ProcessInvite;

        return {
            document,
            name,
            email,
        };
    }

    const getIdFromUrl = () => {
        const url = window.location.href;
        const match = url.match(/\/processos\/(\d+)\?/);

        if (match && match[1]) {
            return parseInt(match[1], 10);
        }

        return 0;
    };

    const getProcessInvitesVerification = async () => {
        try {
            const processId: number = processForm?.values.id ?? getIdFromUrl();
            if (processId > 0) {
                const invite = await processInvitesRequests.getProcessInvitesVerification(
                    processId
                );
                return invite ? invite.data : null;
            }

            return null;
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const getParticipant = async (wasClicked: boolean, searching: boolean = false) => {
        try {
            if (wasClicked) setShowButtonCleanData(true);

            const document = processForm?.values.participantDocument ?? '';
            if (document.length > 0) {
                const response = await providerRequests.getProviderByDocNumber(document);
                if (response.data) {
                    setParticipant(fetchParticipantData(response.data, 'provider'));
                    setIsNewParticipant(false);
                    setShowButtonCleanData(true);
                    return;
                }

                if (searching) {
                    setParticipant({
                        email: '',
                        name: '',
                        document,
                    });
                    setShowMessage(false);
                    setIsNewParticipant(true);
                    setShowButtonCleanData(true);
                    return;
                }

                const participantInvited = await getProcessInvitesVerification();
                if (participantInvited) {
                    setParticipant(fetchParticipantData(participantInvited, ''));
                    setShowMessage(true);
                    setIsNewParticipant(true);
                    setShowButtonCleanData(true);
                    return;
                }

                setShowButtonCleanData(false);
                setParticipant(null);
            } else {
                const participantInvited = await getProcessInvitesVerification();
                if (participantInvited) {
                    const response = await providerRequests.getProviderByDocNumber(
                        participantInvited.document
                    );

                    setShowButtonCleanData(true);

                    if (response.data) {
                        setParticipant(fetchParticipantData(response.data, 'provider'));
                        setIsNewParticipant(false);
                        return;
                    }

                    setShowMessage(true);
                    setParticipant(fetchParticipantData(participantInvited, ''));
                    setIsNewParticipant(true);
                    return;
                }
            }

            if (wasClicked) setIsNewParticipant(true);
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const deleteInvite = async () => {
        try {
            if (processForm?.values.participantId && processForm?.values.id) {
                await processInvitesRequests.deleteInvite({
                    id: processForm.values.participantId,
                    processId: processForm.values.id,
                });
            }
            setParticipant(participantInitialValues);
            setIsNewParticipant(false);
            setShowButtonCleanData(false);
            setShowMessage(false);
            processForm?.setFieldValue('participantId', null);
            processForm?.setFieldValue('lastSendAt', null);
            setProcessBkpBeforeSave(processForm?.values);
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    useEffect(() => {
        getParticipant(false);
    }, []);

    useEffect(() => {
        const { document, email, name } = participant || {};
        processForm?.setFieldValue('participantDocument', document);
        processForm?.setFieldValue('participantEmail', email);
        processForm?.setFieldValue('participantName', name);
    }, [participant]);

    return (
        <ProcessParticipantView
            {...defaultProps}
            processForm={processForm}
            getParticipant={getParticipant}
            participant={participant}
            isNewParticipant={isNewParticipant}
            handleChangeDefault={handleChangeDefault}
            isButtonDisabled={isButtonDisabled}
            showButtonCleanData={showButtonCleanData}
            deleteInvite={deleteInvite}
            showMessage={showMessage}
            loading={loading}
            handleParticipantDocumentChange={handleParticipantDocumentChange}
            handleGetParticipant={handleGetParticipant}
        />
    );
};

export default ProcessParticipant;
