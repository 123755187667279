import React, { useEffect, useState } from 'react';
import {
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress,
    TablePagination,
    Typography,
    Grid,
} from '@material-ui/core';
import { TextField } from 'common/components';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import ChipProviderStatus from 'common/components/providers/components/chip-provider-status';
import { useSearchProvidersContext } from '../../context/search-providers-context';
import SearchProvidersActionsView from './search-providers-actions-view';
import SelectCategory from '../select-category';
import SelectStatus from '../select-status';
import { styles } from './styles';
import SelectSearchType from '../select-search-type';
import { ProviderSearchTypes } from '../select-search-type/enums/searchType.enum';

const rowsPerPageOptions = [10, 20];

interface SearchProvidersTableViewProps {
    loading?: boolean;
    isSuperAdmin?: boolean;
    hasPermissionToView?: boolean;
}

const SearchProvidersTableView: React.FC<SearchProvidersTableViewProps> = ({
    loading = false,
    hasPermissionToView = false,
    isSuperAdmin = false,
}) => {
    const { t } = useTranslation();
    const [searchType, setSearchType] = useState(ProviderSearchTypes.companyOrTradingName);
    const {
        providers,
        requestsCount,
        handleChangePage,
        handleChangeProvidersPerPage,
        requestFilters,
        page,
        handleChangeFilter,
        handleChangeSearch,
    } = useSearchProvidersContext();

    const handleChangeSearchType = (searchType) => {
        setSearchType(searchType);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={3} sm={3}>
                    <SelectSearchType onChange={handleChangeSearchType} value={searchType} />
                </Grid>
                <Grid item md={4} sm={4}>
                    <TextField
                        label={t('provider.pages.components.search-provider')}
                        placeholder={t(
                            'group-auth.pages.search-group.components.search-group-press-enter'
                        )}
                        fullWidth
                        onKeyPress={(event) => handleChangeSearch(event, searchType)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <SearchIcon style={styles.inputAdornment} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item md={3} sm={3}>
                    <SelectCategory
                        onChange={(event) => handleChangeFilter(event, event.target.name)}
                        value={requestFilters?.params?.categoryId}
                    />
                </Grid>
                <Grid item md={2} sm={2}>
                    <SelectStatus
                        onChange={(event) => handleChangeFilter(event, event.target.name)}
                        value={requestFilters?.params?.status}
                    />
                </Grid>
            </Grid>
            <br />
            <br />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.id')}</TableCell>
                        <TableCell>{t('term.supplier')}</TableCell>
                        <TableCell>{t('term.uf-city')}</TableCell>
                        <TableCell>{t('term.register')}</TableCell>
                        <TableCell align='center'>Status</TableCell>
                        <TableCell align='center'>{t('term.actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {providers &&
                        (providers as Provider[]).map((provider) => {
                            const {
                                id,
                                companyName,
                                cityName,
                                state,
                                dateTimeInsert,
                                signupValid,
                            } = provider;

                            return (
                                <TableRow hover key={id}>
                                    <TableCell>{id}</TableCell>
                                    <TableCell>{companyName}</TableCell>
                                    <TableCell>
                                        {state}/{cityName}
                                    </TableCell>
                                    <TableCell>
                                        {`${
                                            dateTimeInsert
                                                ? moment(dateTimeInsert).format('DD/MM/YYYY')
                                                : '-'
                                        }
                                    `}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ChipProviderStatus status={signupValid} />
                                    </TableCell>
                                    <TableCell align='center' padding='none' style={{ width: 100 }}>
                                        {hasPermissionToView ? (
                                            <SearchProvidersActionsView
                                                provider={provider}
                                                isSuperAdmin={isSuperAdmin}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    {providers && !loading && !providers.length && (
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={7} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={requestsCount}
                rowsPerPage={requestFilters.limit || rowsPerPageOptions[0]}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeProvidersPerPage}
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </>
    );
};

export default SearchProvidersTableView;
