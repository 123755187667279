import { FC, useEffect, useState } from 'react';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { Modal, ModalPosition } from 'common/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { auditRequests } from 'clients/manager/audit.requests';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { ListAuditParams } from 'clients/manager/interfaces/audit.interface';
import FilterForm from '../../components/FilterForm';
import { Audit } from '../../interfaces/audit.interface';
import AuditView from '../../components/AuditView';
import { ListAuditProps } from './props';
import AuditTable from '../../components/audit-table';

const ListAudit: FC<ListAuditProps> = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const [selectedAudit, setSelectedAudit] = useState<Audit | undefined>(undefined);

    const [params, setParams] = useState<ListAuditParams>({
        action: '',
        userId: '',
        startDate: moment().clone().subtract(7, 'day').valueOf(),
        endDate: moment().clone().add(1, 'day').valueOf(),
        project: 'manager',
    });

    const { data: listAuditsResponse, ...paginationParams } = usePaginatedQuery(
        auditRequests.listAudits,
        params
    );

    const auditList = listAuditsResponse?.data ?? [];

    useEffect(() => {
        setTitle(t('audit.page.audit'));
    }, []);

    return (
        <PageWrapper>
            {selectedAudit && (
                <Modal
                    position={ModalPosition.right}
                    open
                    onClose={() => setSelectedAudit(undefined)}
                    header={<span>{t('audit.page.audit-item')}</span>}
                >
                    <AuditView audit={selectedAudit} />
                </Modal>
            )}
            <FilterForm
                formData={params}
                onChange={(applyFilter) => {
                    setParams((prevState) => ({
                        ...prevState,
                        ...applyFilter,
                    }));
                    paginationParams.setOffset(0);
                }}
            />
            <AuditTable
                audits={auditList}
                onSelectAudit={setSelectedAudit}
                paginationParams={paginationParams}
            />
        </PageWrapper>
    );
};

export default ListAudit;
