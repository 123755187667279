import React from 'react';

function HalfStarIcon() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            viewBox='0 0 24 24'
        >
            <mask
                id='mask0_497_7830'
                style={{ maskType: 'alpha' }}
                width='24'
                height='24'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path
                    fill='#000'
                    d='M22 9.74l-7.19-.62L12 2.5 9.19 9.13 2 9.74l5.46 4.73-1.64 7.03L12 17.77l6.18 3.73-1.63-7.03L22 9.74zM12 15.9V6.6l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.9z'
                />
            </mask>
            <g mask='url(#mask0_497_7830)'>
                <path fill='#FFB400' d='M0 0H12V24H0z' />
                <path fill='#000' fillOpacity='0.23' d='M12 0H24V24H12z' />
            </g>
        </svg>
    );
}

export default HalfStarIcon;
