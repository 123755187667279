import { CircularProgress } from '@material-ui/core';
import { auctionNoticeMessage } from 'clients/manager/auction-notice-message.requests';
import { AuctionMessagesList } from 'clients/manager/interfaces/auction-notice.interface';
import { addNotificationApiError, addNotificationWarning, sleep } from 'common/utils';
import { t } from 'i18next';
import { GetMessagesArgs } from 'modules/bidding/pages/process/components/messages-container';
import {
    ChangeEventHandler,
    Dispatch,
    memo,
    SetStateAction,
    useMemo,
    useRef,
    useState,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { AiFillEdit } from 'react-icons/ai';
import { FaReplyAll } from 'react-icons/fa';
import { FiPaperclip } from 'react-icons/fi';
import { IoIosHand } from 'react-icons/io';
import { IoSend } from 'react-icons/io5';
import { RiShieldCheckFill } from 'react-icons/ri';
import { MessageTypeEnum } from 'common/enums';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import Chip from './chip';
import ProcessDialogCommentType from './process-dialog-comment-type';
import {
    ProcessCommentBoxTextField,
    ProcessRequestButtonsContainer,
    ProcessRequestCommentAttachmentButton,
    ProcessRequestCommentChipsContainer,
    ProcessRequestCommentContainer,
    ProcessRequestCommentSendButton,
    ProcessRequestMessageTypeButton,
    ProcessRequestMessageTypeButtonsContainer,
    ProcessRequestTypeContainer,
} from './styled';

interface ProcessCommentBoxProps {
    id: string;
    messages: AuctionMessagesList[];
    setMessages: Dispatch<SetStateAction<AuctionMessagesList[]>>;
    getMessages: (
        getMessagesArgs: GetMessagesArgs,
        noLoading?: boolean
    ) => Promise<AuctionMessagesList[]>;
    canShowButton: {
        impugnment: boolean;
        objection: boolean;
        appeal: boolean;
        explanation: boolean;
    };
    buttonsRules: {
        impugnment: boolean;
        explanation: boolean;
        appeal: boolean;
    };
}

const ProcessCommentBox = ({
    id,
    setMessages,
    getMessages,
    buttonsRules,
    canShowButton,
}: ProcessCommentBoxProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showProcessTypeDialog, setShowProcessTypeDialog] = useState(false);
    const [processType, setProcessType] = useState<number>();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [files, setFiles] = useState<File[]>([]);

    const canDoButtonAction = useMemo(
        () => ({
            explanation: buttonsRules.explanation && canShowButton.explanation,
            appeal: buttonsRules.appeal && canShowButton.appeal,
            impugnment: buttonsRules.impugnment && canShowButton.impugnment,
            objection: buttonsRules.appeal && canShowButton.objection,
        }),
        [buttonsRules, canShowButton]
    );

    const handleChangeFile: ChangeEventHandler<HTMLInputElement> = (event) => {
        const acceptFileTypes = ['application/pdf'];
        const inputFiles = event.target.files;
        const isAnyFileDenied =
            inputFiles &&
            Array.from(inputFiles).filter(({ type }) => !acceptFileTypes.includes(type)).length !==
                0;

        if (inputFiles?.length === 0 || inputFiles === null) return;
        if (isAnyFileDenied) return;

        const filteredFiles = (files: File[]) => [
            ...Array.from(inputFiles).filter(
                ({ name }) => !files.map(({ name }) => name).includes(name)
            ),
            ...files,
        ];

        setFiles((prev) => filteredFiles(prev));
    };

    const removeFile = (index: number) =>
        setFiles((prev) => prev.filter((_, fileIndex) => index !== fileIndex));

    const getMessageType = (type: number): string => {
        switch (type) {
            case MessageTypeEnum.elucidation:
                return t('bidding.process.message.warning.type.explanation');
            case MessageTypeEnum.impugnment:
                return t('bidding.process.message.warning.type.impugnment');
            case MessageTypeEnum.appeal:
                return t('bidding.process.message.warning.type.appeal');
            case MessageTypeEnum.counterReason:
                return t('bidding.process.message.warning.type.objection');
            default:
                return '';
        }
    };

    const handleSubmit = async () => {
        if (!processType) return;

        if (value.length < 5) {
            setLoading(false);
            addNotificationWarning({
                message: getMessageType(processType),
                title: t('term.error'),
            });
            return;
        }

        const formData = new FormData();
        formData.append('message', value);
        formData.append('type', String(processType));
        formData.append('auctionId', id);
        files.forEach((file) => {
            const newFileName = getNewFileName(file);
            formData.append('file', file, newFileName);
        });

        setLoading(true);

        try {
            await auctionNoticeMessage.createMessage(formData);
            await sleep(1000 + 500 * files.length);
            const newMessage = await getMessages({ limit: 100, offset: 0 }, true);
            unstable_batchedUpdates(() => {
                setValue('');
                setFiles([]);
                setProcessType(undefined);
                setMessages(newMessage);
            });
        } catch (e) {
            addNotificationApiError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ProcessRequestCommentContainer hide={!Object.values(canDoButtonAction).includes(true)}>
            <ProcessRequestCommentAttachmentButton
                disabled={loading}
                onClick={() => setShowProcessTypeDialog(true)}
            >
                {processType === 1 && (
                    <RiShieldCheckFill title={t('bidding.process.message.type.explanation')} />
                )}
                {processType === 2 && (
                    <IoIosHand title={t('bidding.process.message.type.impugnment')} />
                )}
                {processType === 3 && (
                    <AiFillEdit title={t('bidding.process.message.type.appeal')} />
                )}
                {processType === 4 && (
                    <FaReplyAll title={t('bidding.process.message.type.objection')} />
                )}
            </ProcessRequestCommentAttachmentButton>
            <ProcessCommentBoxTextField
                minRows={1}
                maxRows={6}
                variant='outlined'
                value={value}
                onChange={({ target }) => setValue(target.value)}
                multiline
                InputProps={{
                    disabled: loading,
                    endAdornment: files.length > 0 && (
                        <ProcessRequestCommentChipsContainer>
                            {files.map((file, index) => (
                                <Chip
                                    key={file.name}
                                    title={file.name}
                                    onClose={() => removeFile(index)}
                                    disabled={loading}
                                />
                            ))}
                        </ProcessRequestCommentChipsContainer>
                    ),
                }}
            />
            <ProcessRequestButtonsContainer>
                <ProcessRequestCommentAttachmentButton
                    disabled={loading}
                    onClick={() => setShowProcessTypeDialog(true)}
                >
                    {processType === 1 && <RiShieldCheckFill />}
                    {processType === 2 && <IoIosHand />}
                    {processType === 3 && <AiFillEdit />}
                    {processType === 4 && <FaReplyAll />}
                </ProcessRequestCommentAttachmentButton>
                <section>
                    <ProcessRequestCommentAttachmentButton
                        disabled={loading}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <input
                            ref={fileInputRef}
                            onChange={handleChangeFile}
                            accept='application/pdf'
                            hidden
                            type='file'
                            multiple
                        />
                        <FiPaperclip />
                    </ProcessRequestCommentAttachmentButton>
                    <ProcessRequestCommentSendButton disabled={loading} onClick={handleSubmit}>
                        {loading ? (
                            <CircularProgress color='inherit' size={24} />
                        ) : (
                            <IoSend color='inherit' />
                        )}
                    </ProcessRequestCommentSendButton>
                </section>
            </ProcessRequestButtonsContainer>

            <ProcessRequestTypeContainer hide={processType !== undefined}>
                <p>{`${t('bidding.process.section.messages.send')}:`}</p>

                <ProcessRequestMessageTypeButtonsContainer>
                    {canDoButtonAction.explanation && (
                        <ProcessRequestMessageTypeButton onClick={() => setProcessType(1)}>
                            <RiShieldCheckFill color='var(--background-color)' size={16} />
                            {t('bidding.process.message.type.explanation')}
                        </ProcessRequestMessageTypeButton>
                    )}
                    {canDoButtonAction.impugnment && (
                        <ProcessRequestMessageTypeButton onClick={() => setProcessType(2)}>
                            <IoIosHand color='var(--background-color)' size={16} />
                            {t('bidding.process.message.type.impugnment')}
                        </ProcessRequestMessageTypeButton>
                    )}
                    {canDoButtonAction.appeal && (
                        <ProcessRequestMessageTypeButton onClick={() => setProcessType(3)}>
                            <AiFillEdit color='var(--background-color)' size={16} />
                            {t('bidding.process.message.type.appeal')}
                        </ProcessRequestMessageTypeButton>
                    )}
                    {canDoButtonAction.objection && (
                        <ProcessRequestMessageTypeButton onClick={() => setProcessType(4)}>
                            <FaReplyAll color='var(--background-color)' size={16} />
                            {t('bidding.process.message.type.objection')}
                        </ProcessRequestMessageTypeButton>
                    )}
                </ProcessRequestMessageTypeButtonsContainer>
            </ProcessRequestTypeContainer>
            <ProcessDialogCommentType
                handleProcessType={(type: number) => setProcessType(type)}
                onClose={() => setShowProcessTypeDialog(false)}
                open={showProcessTypeDialog}
                type={processType}
                {...{ canShowButton, buttonsRules }}
            />
        </ProcessRequestCommentContainer>
    );
};

export default memo(ProcessCommentBox);
