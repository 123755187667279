import { DocumentProcessType } from 'clients/manager/interfaces';

enum DefaultVariables {
    diaAtual = 'diaAtual',
    mesAtual = 'mesAtual',
    anoAtual1 = 'anoAtual',
    anoAtual2 = 'anoAtual1',
    mesAtualPorExtenso = 'mesAtualPorExtenso',
    dataAtualPorExtenso = 'dataAtualPorExtenso',
}

interface DefaultVariable {
    name: string;
    value?: string;
    description: string;
    id: string;
}

interface ProcessVariable {
    name:
        | OrganizationVariables
        | ProcessTeamVariables
        | ProcessUsersAuctioneerVariables
        | ProcessVariables
        | ProviderVariables
        | ProcessHomologationVariables
        | ProcessUsersAdjudicationVariables
        | ProcessContractVariables
        | ProcessSolicitationVariables
        | ProcessDisputeVariables
        | ProcessProviderInformationVariables;
    description: string;
    processTypes: DocumentProcessType[];
    id?: string;
}

interface CustomVariable {
    name: string;
    values: {
        value: string;
    }[];
    description: string;
    id: string;
}

enum ProcessContractVariables {
    tabelaContrato = 'tabelaContrato',
    tabelaItemsDoEmpenho = 'tabelaItemsDoEmpenho',
    tabelaContratoSemSubTotal = 'tabelaContratoSemSubTotal',
    tabelaServicosContrato = 'tabelaServicosContrato',
    tabelaServicosContratoSimples = 'tabelaServicosContratoSimples',
    valorTotal = 'valorTotal',
    valorTotalExtenso = 'valorTotalExtenso',
    valorTotalReferencia = 'valorTotalReferencia',
    valorTotalReferenciaExtenso = 'valorTotalReferenciaExtenso',
    valorTotalServico = 'valorTotalServico',
    valorTotalServicoExtenso = 'valorTotalServicoExtenso',
    numeroContrato = 'numeroContrato',
    porteEmpresa = 'porteEmpresa',
}

enum ProviderVariables {
    razaoSocialFornecedor = 'razaoSocialFornecedor',
    cpfCNPJFornecedor = 'cpfCNPJFornecedor',
    enderecoLogradouroFornecedor = 'enderecoLogradouroFornecedor',
    enderecoNumeroFornecedor = 'enderecoNumeroFornecedor',
    enderecoComplementoFornecedor = 'enderecoComplementoFornecedor',
    enderecoBairroFornecedor = 'enderecoBairroFornecedor',
    enderecoCidadeFornecedor = 'enderecoCidadeFornecedor',
    enderecoEstadoFornecedor = 'enderecoEstadoFornecedor',
    enderecoCEPFornecedor = 'enderecoCEPFornecedor',
    nomeRepresentanteFornecedor = 'nomeRepresentanteFornecedor',
    emailRepresentanteFornecedor = 'emailRepresentanteFornecedor',
    telefoneRepresentanteFornecedor = 'telefoneRepresentanteFornecedor',
    cargoRepresentanteFornecedor = 'cargoRepresentanteFornecedor',
    cpfRepresentanteFornecedor = 'cpfRepresentanteFornecedor',
}

enum ProcessHomologationVariables {
    dataHomologacaoFinal = 'dataHomologacaoFinal',
    diaHomologacaoFinal = 'diaHomologacaoFinal',
    mesHomologacaoFinal = 'mesHomologacaoFinal',
    anoHomologacaoFinal = 'anoHomologacaoFinal',
    mesExtensoHomologacaoFinal = 'mesExtensoHomologacaoFinal',
}

enum ProcessSolicitationVariables {
    listaDeAdjudicacaoEHomologacao = 'listaDeAdjudicacaoEHomologacao',
    listaDeEsclarecimentosEImpugnacoes = 'listaDeEsclarecimentosEImpugnacoes',
    listaDeRecursosEContrarrazoes = 'listaDeRecursosEContrarrazoes',
}

enum ProcessDisputeVariables {
    listaMensagensDoChat = 'listaMensagensDoChat',
    listaDeLances = 'listaDeLances',
    listaClassificacaoPorLote = 'listaClassificacaoPorLote',
    resumoPropostasVencedorasPorLote = 'resumoPropostasVencedorasPorLote',
}

enum ProcessProviderInformationVariables {
    resultadoDoExameDeConformidade = 'resultadoDoExameDeConformidade',
}

enum OrganizationVariables {
    nomeOrganizacao = 'nomeOrganizacao',
    cnpjOrganizacao = 'cnpjOrganizacao',
    enderecoLogradouroOrganizacao = 'enderecoLogradouroOrganizacao',
    enderecoNumeroOrganizacao = 'enderecoNumeroOrganizacao',
    enderecoComplementoOrganizacao = 'enderecoComplementoOrganizacao',
    enderecoBairroOrganizacao = 'enderecoBairroOrganizacao',
    enderecoCidadeOrganizacao = 'enderecoCidadeOrganizacao',
    enderecoEstadoOrganizacao = 'enderecoEstadoOrganizacao',
    enderecoCEPOrganizacao = 'enderecoCEPOrganizacao',
    nomeUnidadeCompradora = 'nomeUnidadeCompradora',
}

enum ProcessVariables {
    numeroEdital = 'numeroEdital',
    numeroInexigibilidade = 'numeroInexigibilidade',
    numeroInexigibilidadeDoCredenciamento = 'numeroInexigibilidadeDoCredenciamento',
    numeroDotacaoOrcamentaria = 'numeroDotacaoOrcamentaria',
    numeroPregao = 'numeroPregao',
    numeroCredenciamento = 'numeroCredenciamento',
    numeroDispensa = 'numeroDispensa',
    numeroProcesso = 'numeroProcesso',
    numeroModalidade = 'numeroModalidade',
    descricaoModalidade = 'descricaoModalidade',
    numeroConcorrencia = 'numeroConcorrencia',
    numeroLeilao = 'numeroLeilao',
    limiteImpugnacao = 'limiteImpugnacao',
    dataPublicacao = 'dataPublicacao',
    horaDisputa = 'horaDisputa',
    dataDisputa = 'dataDisputa',
    inicioEnvioDeDocumentos = 'inicioEnvioDeDocumentos',
    fimEnvioDeDocumentos = 'fimEnvioDeDocumentos',
    tipoIntervalo = 'tipoIntervalo',
    objetoEdital = 'objetoEdital',
    criterioPreferencia = 'criterioPreferencia',
    lotesEItens = 'lotesEItens',
    lotesEItensSemValores = 'lotesEItensSemValores',
    dataDePublicacaoDiarioOficial = 'dataDePublicacaoDiarioOficial',
    amparoLegal = 'amparoLegal',
    dataAdjudicacao = 'dataAdjudicacao',

    listaDeAvisosDoProcesso = 'listaDeAvisosDoProcesso',
    objetoProcesso = 'objetoProcesso',
}

enum ProcessUsersAdjudicationVariables {
    nomeResponsavelAdjudicacao = 'nomeResponsavelAdjudicacao',
}

enum ProcessTeamVariables {
    nomePresidente = 'nomePresidente',
    cpfPresidente = 'cpfPresidente',

    nomeOperador = 'nomeOperador',
}

enum ProcessUsersAuctioneerVariables {
    nomeAutoridadeCompetente = 'nomeAutoridadeCompetente',
    cpfAutoridadeCompetente = 'cpfAutoridadeCompetente',
    emailAutoridadeCompetente = 'emailAutoridadeCompetente',
}

export {
    DefaultVariables,
    OrganizationVariables,
    ProcessVariables,
    ProcessUsersAuctioneerVariables,
    ProcessTeamVariables,
    ProviderVariables,
    ProcessHomologationVariables,
    ProcessContractVariables,
    ProcessUsersAdjudicationVariables,
    ProcessSolicitationVariables,
    ProcessDisputeVariables,
    ProcessProviderInformationVariables,
};
export type { DefaultVariable, CustomVariable, ProcessVariable };
