import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import DialogSyncPcaPncpView from './dialog-sync-pca-pncp-view';

interface DialogSyncPcaPncpProps {}

const DialogSyncPcaPncp: React.FC<DialogSyncPcaPncpProps> = () => {
    const {
        pcaSyncId,
        setPcaSyncId,
        pcaStatusEnum,
        hiringPlanItens,
        hiringPlansList,
        setHiringPlansList,
        onCloseDialogSyncPcaPncp,
    } = useHiringPlansContext();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handlerSyncPcaWithPncp = useCallback(async () => {
        setIsLoading(true);
        try {
            if (pcaSyncId) {
                await hiringPlansRequests.synchronizeHiringPlanWithPncp({
                    id: pcaSyncId,
                });
            }
            setHiringPlansList((prevState) => [
                ...prevState.map((hiringPlan) => {
                    if (hiringPlan.id === pcaSyncId) {
                        return { ...hiringPlan, status: pcaStatusEnum.done };
                    }
                    return hiringPlan;
                }),
            ]);
            onCloseDialogSyncPcaPncp();
            addNotificationSuccess({
                title: t('term.success'),
                message: t('hiring-plans.info.create-pca-success'),
            });
        } catch (err) {
            addNotificationError({
                title: t('term.error'),
                message: t('hiring-plans.info.create-pca-error'),
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const searchDetailsPcaUnit = (idPca: number) =>
        hiringPlansList.find((hiringPlan) => hiringPlan.id === idPca);

    const countTotalItemsUnit = () =>
        hiringPlanItens
            ? hiringPlanItens.reduce((acc, currentItem) => acc + currentItem.quantity, 0)
            : 0;

    const amountTotalItemsUnit = () =>
        hiringPlanItens
            ? hiringPlanItens.reduce((acc, currentItem) => acc + currentItem.totalValue, 0)
            : 0;

    useEffect(() => () => setPcaSyncId(undefined), []);

    return (
        <DialogSyncPcaPncpView
            isLoading={isLoading}
            searchDetailsPcaUnit={searchDetailsPcaUnit}
            countTotalItemsUnit={countTotalItemsUnit}
            amountTotalItemsUnit={amountTotalItemsUnit}
            handlerSyncPcaWithPncp={handlerSyncPcaWithPncp}
        />
    );
};

export default DialogSyncPcaPncp;
