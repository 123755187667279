import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { OkResponse } from '../../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../../interfaces/paginated.interface';
import {
    GeneratedDocuments,
    GeneratedDocumentsOrigin,
} from '../interfaces/generated-documents.interface';

const path = '/documents/generated';

interface ListGeneratedDocumentsFilters {
    offset?: number;
    limit?: number;
    params: {
        origin?: GeneratedDocumentsOrigin;
        processDocumentStepId?: string;
        processDocumentTemplateId?: string;
        auctionId: number;
        providerId?: string;
    };
}

const generatedDocumentsRequests = {
    getDocument: async (generatedDocumentId: string) =>
        doRequest<PlainGenericResponse<GeneratedDocuments>>(
            managerApiInstance.post(`${path}/getGeneratedDocument`, {
                generatedDocumentId,
            })
        ),
    listDocuments: async (filters: ListGeneratedDocumentsFilters) =>
        doRequest<GenericResponse<GeneratedDocuments>>(
            managerApiInstance.post(`${path}/listGeneratedDocument`, filters)
        ),
    createDocument: async (document: Omit<GeneratedDocuments, '_id' | 'organizationId'>) =>
        doRequest<PlainGenericResponse<GeneratedDocuments>>(
            managerApiInstance.post(`${path}/createGeneratedDocument`, document)
        ),
    uploadFileOnGeneratedDocument: async (generatedDocumentId: string, file: File) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);

        return doRequest<PlainGenericResponse<{ url: string }>>(
            managerApiUploadFileInstance.post(`${path}/uploadFileOnGeneratedDocument`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    generatedDocumentId,
                },
            })
        );
    },
    deleteDocument: async (generatedDocumentId: string) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${path}/deleteGeneratedDocument`, {
                generatedDocumentId,
            })
        ),
};

export { generatedDocumentsRequests };
