import {
    Box,
    Grid,
    Paper,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Typography,
    Radio,
} from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { debounce } from 'lodash';
import { OriginOfFinancialResourceType } from 'clients/manager/interfaces/auction-notice.interface';
import { formatAuctionNumber } from 'common/utils';
import { processUtils } from 'modules/process/process-utils';
import { ProcessSourceResourcesProps } from './props';
import ExpandedContentCard from '../../../expand-content-card';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { getSourceResourcesFormLabels } from '../../../../context/schema/source-resources-form-schema';
import ProcessContentHeader from '../process-content-header';
import { processActions } from 'modules/process/process-actions';

const ProcessSourceResources: FC<ProcessSourceResourcesProps> = ({ disabledExpand }) => {
    const { t } = useTranslation();
    const { processForm, process } = useProcessFormContext();
    const [agreementNumber, setAgreementNumber] = useState<string | null>(null);

    const [isInsuranceChecked, setIsInsuranceChecked] = useState(
        Boolean(processForm?.values.itsAgreementOrTransfer)
    );

    useEffect(() => {
        setIsInsuranceChecked(Boolean(processForm?.values.itsAgreementOrTransfer));
    }, [processForm?.values.itsAgreementOrTransfer]);

    const handleChangeOriginOfFinancialResource = debounce((event) => {
        processForm?.setFieldValue('originOfFinancialResource', event.target.value);
    }, 300);

    const handleChangeAgreementNumber = (event: React.ChangeEvent<any>) => {
        // remove a barra
        const value: string = event.target.value.replace(/\D/g, '');
        setAgreementNumber(formatAuctionNumber(value));
    };

    const updateChangeAgreementNumber = useCallback(
        debounce((event) => {
            // remove a barra
            const value: string = event.target.value.replace(/\D/g, '');
            processForm?.setFieldValue('agreementNumber', formatAuctionNumber(value));
        }, 400),
        [processForm]
    );

    const handleIsInsuranceClick = (_: React.ChangeEvent<{}>, checked: boolean) => {
        setIsInsuranceChecked(checked);

        if (!checked) {
            return processForm?.setValues({
                ...processForm.values,
                itsAgreementOrTransfer: 0,
                originOfFinancialResource: null,
                agreementNumber: null,
            });
        }

        processForm?.setFieldValue('itsAgreementOrTransfer', Number(checked));
    };

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <ProcessContentHeader
                            title={t('process.components.source-of-Resources')}
                            partialProcessFormLabels={getSourceResourcesFormLabels()}
                        />
                    }
                >
                    <Grid container spacing={2}>
                        <Alert severity='warning'>
                            <Typography variant='body2'>{t('info.mais-brasil')}</Typography>
                        </Alert>
                        <Grid item xs={12} lg={5}>
                            <FormControlLabel
                                onChange={handleIsInsuranceClick}
                                control={<Checkbox defaultChecked={isInsuranceChecked} />}
                                disabled={processActions.cannotEditUntilDispute(process)}
                                name='itsAgreementOrTransfer'
                                label={t('process.components.agreement-transfer')}
                            />
                        </Grid>
                        {isInsuranceChecked && (
                            <>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <RadioGroup
                                        row
                                        aria-label='position'
                                        name='originOfFinancialResource'
                                        defaultValue={
                                            processForm?.values.originOfFinancialResource as string
                                        }
                                        onChange={handleChangeOriginOfFinancialResource}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    disabled={processActions.cannotEditUntilDispute(process)}
                                                    value={OriginOfFinancialResourceType.national}
                                                    color='primary'
                                                />
                                            }
                                            label={t('term.national')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    disabled={processActions.cannotEditUntilDispute(process)}
                                                    value={
                                                        OriginOfFinancialResourceType.international
                                                    }
                                                    color='primary'
                                                />
                                            }
                                            label={t('term.international')}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <TextField
                                        label={t('process.components.agreement-no')}
                                        variant='outlined'
                                        fullWidth
                                        inputProps={{
                                            maxLength: 25,
                                        }}
                                        value={agreementNumber}
                                        onChange={(event) => {
                                            handleChangeAgreementNumber(event);
                                            updateChangeAgreementNumber(event);
                                        }}
                                        name='agreementNumber'
                                        defaultValue={processForm?.values.agreementNumber}
                                        disabled={processActions.cannotEditUntilDispute(process)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessSourceResources;
