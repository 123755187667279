import React, { useEffect } from 'react';
import Yup from 'common/utils/yup-extended.utils';
import { managerApiInstance } from 'clients/api';
import { addNotificationError, apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { useFormik } from 'formik';
import { getEnableHttpOnlyToken, removeAuthToken } from 'modules/auth/session.utils';
import { useValidadeToken } from 'modules/auth/hooks/useValidateToken';
import { useTranslation } from 'react-i18next';
import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import { useHistory } from 'react-router';
import { globalConstants } from 'common/constants/global.constant';
import { UserAuthView } from './UserAuthView';

export const UserAuth = () => {
    const { t } = useTranslation();
    const initialValues = { cpf: '', password: '', country: globalConstants.ibgeCodes.BRAZIL };
    const { authenticate } = useValidadeToken();
    const { sendPasswordResetCode } = useUserConnectedProviders();
    const history = useHistory();
    const backofficeRedirectUrlPath = getBackofficeRedirectUrl();

    const { isfeatureFlagEnabled } = useUserConnectedProviders();

    const checkCognitoFeatureFlag = async () => {
        if (!isfeatureFlagEnabled) {
            apiRedirectTo(backofficeRedirectUrlPath, 'login', true);
        }
    };

    const validationSchema = Yup.object().shape({
        cpf: Yup.string().required(t('term.required-field')).max(100),
        password: Yup.string().required(t('term.required-field')).max(100),
        country: Yup.string().required(t('term.required-field')),
    });

    const resetWeakPassword = async (cpf: string, country: string) => {
        history.push(`/alterar-senha?username=${cpf}&weakPassword=true`);
        await sendPasswordResetCode(cpf, country);
    };

    const onSubmit = async ({ cpf, password, country }, { setSubmitting }) => {
        const cleanCpf = cpf.replace(/[.-]/g, '');
        try {
            const { enableHttpToken } = getEnableHttpOnlyToken();

            const { data } = await managerApiInstance.post(
                '/publicUserProviderConnection/doCognitoLogin',
                {
                    cpf: cleanCpf,
                    password,
                    country,
                },
                { withCredentials: enableHttpToken }
            );

            await authenticate(data.token);
        } catch (err: any) {
            let message = err?.response?.data?.message || err?.message;
            const code = err?.response?.data?.token || err?.code;
            if (code === 'REGISTER_COGNITO_PASSWORD_POLICY') {
                resetWeakPassword(cleanCpf, country);
                return;
            }

            if (code === 'AUTHENTICATE_INVALID_USERNAME_PASSWORD') {
                message = t('cognito.error-incorrect-username-or-password');
            }
            addNotificationError({
                message,
            });
        } finally {
            setSubmitting(false);
        }
    };
    const form = useFormik({ initialValues, validationSchema, onSubmit });

    useEffect(() => {
        removeAuthToken(true);
        checkCognitoFeatureFlag();
    }, []);

    return <UserAuthView form={form} />;
};
