import { FC, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { addNotificationSuccess, addNotificationWarning } from 'common/utils';
import { documentBlocksRequests } from 'clients/manager/documents/document-blocks.requests';
import {
    DocumentBlocks,
    DocumentBlockType,
} from 'clients/manager/interfaces/document-blocks.interface';
import OrganizationDocumentBlockView from './organization-document-block-view';

export interface OrganizationDocumentBlockProps {
    block?: DocumentBlocks;
    onBlockCreated: (block: DocumentBlocks) => void;
    blockType: DocumentBlockType;
}

type DocumentBlocksForm = Omit<DocumentBlocks, '_id'>;

const OrganizationDocumentBlock: FC<OrganizationDocumentBlockProps> = ({
    block = undefined,
    blockType,
    onBlockCreated,
}) => {
    const editorId = nanoid();
    const [editorState, setEditorState] = useState<string | undefined>(undefined);
    const editorStateRef = useRef(editorState);
    const { t } = useTranslation();

    const variables = [
        {
            id: '7001',
            name: 'pagina_atual',
            description: t('info.current-page'),
        },
        {
            id: '7002',
            name: 'numero_de_paginas',
            description: t('info.total-pages-complete-document'),
        },
    ];

    const getBlockName = () => {
        if (blockType === DocumentBlockType.header) {
            return 'default header';
        }

        if (blockType === DocumentBlockType.footer) {
            return 'default footer';
        }

        return 'default block';
    };

    const initialValues: DocumentBlocksForm = {
        name: getBlockName(),
        description: getBlockName(),
        type: blockType,
        content: '',
        organizationId: 2,
    };

    const update = async () => {
        if (!block) {
            return;
        }

        const blockForm: DocumentBlocks = {
            ...block,
            content: editorStateRef.current,
        };

        try {
            await documentBlocksRequests.updateBlock(block?._id, blockForm);
            addNotificationSuccess({
                message: t('term.updated'),
            });
        } catch (error) {
            addNotificationWarning({
                title: t('term.err'),
                message: t('organization.edit.components.notification-error-not-update'),
            });
        }
    };

    const create = async () => {
        const blockForm: DocumentBlocksForm = {
            ...initialValues,
            content: editorStateRef.current,
        };

        try {
            const response = await documentBlocksRequests.createBlock(blockForm);
            onBlockCreated(response.data);
            addNotificationSuccess({
                message: t('term.created'),
            });
        } catch (error) {
            addNotificationWarning({
                title: t('term.err'),
                message: t('organization.edit.components.notification-error-not-save'),
            });
        }
    };

    const handleEditorChange = (html: string) => {
        editorStateRef.current = html;
        setEditorState(html);
    };

    const handleSubmit = async () => {
        if (block) await update();
        else await create();
    };

    const handleOnUploadFile = async (file: File) => {
        try {
            const response = await documentBlocksRequests.uploadImage(file);
            return response;
        } catch (error) {
            console.error('Upload failed:', error);
            throw error;
        }
    };

    return (
        <OrganizationDocumentBlockView
            editorId={editorId}
            initialData={block?.content ?? ''}
            handleEditorChange={handleEditorChange}
            variables={variables}
            handleSubmit={handleSubmit}
            handleOnUploadFile={handleOnUploadFile}
            block={block}
            t={t}
        />
    );
};

export default OrganizationDocumentBlock;
