import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import { getAuthUserToken } from 'modules/auth/session.utils';
import { useEffect } from 'react';
import Authenticating from 'routes/components/authenticating';

const ProviderAuth = () => {
    const { connectUser, oauthLogin } = useUserConnectedProviders();

    const verifyProvider = async (code: { idToken: string; jwtToken: string }) => {
        const user = getAuthUserToken();
        const { idToken, jwtToken } = code;

        if (!user) {
            await oauthLogin(jwtToken, idToken);
            return;
        }

        await connectUser(user.userId, jwtToken, idToken);
    };

    useEffect(() => {
        const authCode = localStorage.getItem('COGNITO_AUTH');

        if (authCode) {
            const code = JSON.parse(authCode);
            verifyProvider(code);
        }
    }, []);

    return <Authenticating />;
};

export default ProviderAuth;
