import { GenericBody } from 'clients/interfaces/paginated.interface';
import { DocumentProcessType } from '.';

interface DocumentTemplate {
    _id: string;
    organizationId: number;
    type: DocumentTemplateType;
    name: string;
    description?: string;
    content?: string;
    createdByUserId?: number;
    createdAt?: number;
    updatedAt?: number;
    updatedByUserId?: number;
    processTypes: DocumentProcessType[];
    public?: boolean;
    supplyCategories: number[];
}

interface UpdateDocumentTemplateBody {
    _id: string;
    type: number;
    processTypes: number[];
    name: string;
    description?: string;
    content?: string;
    supplyCategories: number[];
}

enum DocumentTemplateType {
    receipt = 0, // Recibo
    auction = 1, // Edital / Projeto Básico
    contract = 2, // Contrato (Mudar para Contrato / Ata de Registro de Preço)
    protocol = 3, // Ata
    referenceTerms = 4, // Termos de Referências
    others = 5, // Outros
    hiringNotice = 6, // Aviso de Contratação Direta
    contractDraft = 7, // Minuta do Contrato
    preliminaryDesign = 8, // Anteprojeto
    preliminaryTechnicalStudy = 9, // Estudo Técnico Preliminar
    executiveProject = 10, // Projeto Executivo
    riskMap = 11, // Mapa de Riscos
    dod = 12, // DOD
    terminationNotice = 13, // Termo de Rescisão
    addendum = 14, // Termo Aditivo
    apostilmentTerm = 15, // Termo de Apostilamento
    // commitmentNote = 16, // Nota de Empenho,
    minutesOfPriceRegistration = 17, // Minuta de Ata de Registro de Preços
    actAuthorizingDirectContracting = 18, // Ato que autoriza a Contratação Direta
}

interface TemplateVariablesReplaced {
    unReplacedVariables: string[];
    replacedVariables: { [key: string]: any };
}

interface ReplaceVariables {
    auctionId: number;
    providerId?: number;
    variables: string[];
}

interface ListTemplatesParams {
    q?: string;
    type?: DocumentTemplateType;
    processType?: DocumentProcessType;
    public?: boolean;
    supplyCategoryId?: number;
    justNotSelected?: boolean;
}

interface ListTemplatesFilters extends GenericBody<ListTemplatesParams> {}

export type {
    TemplateVariablesReplaced,
    DocumentTemplate,
    ReplaceVariables,
    ListTemplatesFilters,
    ListTemplatesParams,
    UpdateDocumentTemplateBody,
};
export { DocumentTemplateType };
