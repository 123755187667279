import { FC } from 'react';
import { Select } from '@licitar/ui-kit';
import { useTranslation } from 'react-i18next';
import { getTemplateTypesLabel } from 'common/utils';
import { DocumentTemplateType } from '../../../../../clients/manager/interfaces/document-templates.interface';

interface SelectTemplateTypeProps {
    onChange: (value: DocumentTemplateType) => void;
    selectedValue?: DocumentTemplateType;
}

interface TemplateOption {
    label: string;
    value: DocumentTemplateType;
}

const SelectTemplateType: FC<SelectTemplateTypeProps> = ({ onChange, selectedValue }) => {
    const { t } = useTranslation();
    const typeOptions: TemplateOption[] = [
        {
            label: getTemplateTypesLabel(DocumentTemplateType.contract),
            value: DocumentTemplateType.contract,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.receipt),
            value: DocumentTemplateType.receipt,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.auction),
            value: DocumentTemplateType.auction,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.protocol),
            value: DocumentTemplateType.protocol,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.referenceTerms),
            value: DocumentTemplateType.referenceTerms,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.hiringNotice),
            value: DocumentTemplateType.hiringNotice,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.contractDraft),
            value: DocumentTemplateType.contractDraft,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.preliminaryDesign),
            value: DocumentTemplateType.preliminaryDesign,
        },

        {
            label: getTemplateTypesLabel(DocumentTemplateType.preliminaryTechnicalStudy),
            value: DocumentTemplateType.preliminaryTechnicalStudy,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.executiveProject),
            value: DocumentTemplateType.executiveProject,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.riskMap),
            value: DocumentTemplateType.riskMap,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.dod),
            value: DocumentTemplateType.dod,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.terminationNotice),
            value: DocumentTemplateType.terminationNotice,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.addendum),
            value: DocumentTemplateType.addendum,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.apostilmentTerm),
            value: DocumentTemplateType.apostilmentTerm,
        },
        // {
        //     label: getTemplateTypesLabel(DocumentTemplateType.commitmentNote),
        //     value: DocumentTemplateType.commitmentNote,
        // },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.minutesOfPriceRegistration),
            value: DocumentTemplateType.minutesOfPriceRegistration,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.actAuthorizingDirectContracting),
            value: DocumentTemplateType.actAuthorizingDirectContracting,
        },
        {
            label: getTemplateTypesLabel(DocumentTemplateType.others),
            value: DocumentTemplateType.others,
        },
    ];

    return (
        <Select
            value={typeOptions.find((item) => item.value === selectedValue)}
            name='type'
            label={t('term.type-model')}
            options={typeOptions}
            onChange={({ value }) => onChange(parseInt(value, 10))}
        />
    );
};

export default SelectTemplateType;
