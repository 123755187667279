/* eslint-disable no-alert */
import { addNotificationSuccess } from 'common/utils';
import DocumentTypesService from 'modules/document-types/services/DocumentTypesService';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchDocumentTypesContext } from '../../context/SearchDocumentTypesContext';
import SearchDocumentTypesTableView from './SearchDocumentTypesTableView';

const SearchDocumentTypesTable = () => {
    const { documentTypeDialog, setDocumentTypeDialog } = useSearchDocumentTypesContext();

    const { t } = useTranslation();

    const [documentTypes, setDocumentTypes] = useState();

    const getDocumentTypes = useCallback(async (name) => {
        const { data } = await DocumentTypesService.listDocumentTypesOrganization(name);
        setDocumentTypes(data);
    }, []);

    useEffect(() => {
        getDocumentTypes('');
    }, []);

    useEffect(() => {
        if (!documentTypeDialog.open) {
            getDocumentTypes('');
        }
    }, [documentTypeDialog.open]);

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            getDocumentTypes(event.target.value);
        }
    };

    const handleEdit = (documentType) => {
        setDocumentTypeDialog({ open: true, documentType });
    };

    const handleRemove = async (row) => {
        if (window.confirm(`${t('document-types.remove-document-types')} ${row.categoryName}?`)) {
            await DocumentTypesService.deleteDocumentType(row.id);
            getDocumentTypes('');
            addNotificationSuccess();
        }
    };

    return (
        <SearchDocumentTypesTableView
            {...{ handleChangeFilter, documentTypes, handleEdit, handleRemove }}
        />
    );
};

export default SearchDocumentTypesTable;
