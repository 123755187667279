import { doRequest, managerApiInstance } from 'clients/api';
import { Audit } from 'modules/audit/interfaces/audit.interface';
import { AxiosRequestConfig } from 'axios';
import { GenericResponse } from '../interfaces/paginated.interface';
import { ListAuditBody } from './interfaces/audit.interface';

const BASE_PATH = '/audit';

const auditRequests = {
    listAudits: async (body?: ListAuditBody, config?: AxiosRequestConfig<any>) =>
        doRequest<GenericResponse<Audit>>(
            managerApiInstance.post(`${BASE_PATH}/listAudit`, body, config)
        ),
};

export { auditRequests };
