import { AiOutlineDollar, AiOutlineFire } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BsBoxSeam, BsMegaphone, BsPuzzle } from 'react-icons/bs';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { BiIdCard } from 'react-icons/bi';
import { ImStack } from 'react-icons/im';
import {
    getMd5Hash,
    apiRedirectTo,
    getBackofficeRedirectUrl,
    featureFlagEnabled,
} from 'common/utils';
import i18n from 'common/i18n';
import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { ProcessTab } from '../../interfaces/process.tabs.interface';

type ITab = {
    [key in ProcessTab]: {
        icon: JSX.Element;
        label: string;
        title: string;
        ref: string;
        onClick?: () => void;
    };
};

export const getTabs = (processId?: number, process?: AuctionNotice) => {
    const { t } = i18n;

    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const iconProps = {
        style: { fontSize: '22px' },
    };

    const redirect = (customPath: string) => {
        if (!processId) {
            return;
        }

        const auctionHash = getMd5Hash(String(processId));
        const path = encodeURIComponent(`processos/?ver&idE=${auctionHash}&aba=${customPath}`);

        return apiRedirectTo(backofficeRedirectUrl, path);
    };

    const tabs: ITab = {
        [ProcessTab.general]: {
            icon: <FaRegEdit {...iconProps} />,
            label: t('term.data'),
            title: t('term.data'),
            ref: 'dados',
            ...(featureFlagEnabled('novaTelaProcessos')
                ? {}
                : { onClick: () => redirect('dados') }),
        },
        [ProcessTab.documents]: {
            icon: <IoDocumentTextOutline {...iconProps} />,
            label: t('term.documents'),
            title: t('term.documents'),
            ref: 'documentos',
        },
        [ProcessTab.messages]: {
            icon: <BsMegaphone {...iconProps} />,
            label: t('term.warnings'),
            title: t('term.warnings'),
            ref: 'mensagens',
        },
        [ProcessTab.solicitations]: {
            icon: <IoMdNotificationsOutline {...iconProps} />,
            label: t('term.solicitations'),
            title: t('term.solicitations'),
            ref: 'solicitacoes',
        },
        [ProcessTab.proposals]: {
            icon: <AiOutlineDollar {...iconProps} />,
            label: t('term.proposals'),
            title: t('term.proposals'),
            ref: 'propostas',
        },
        [ProcessTab.authorization]: {
            icon: <BiIdCard {...iconProps} />,
            label: t('term.qualification'),
            title: t('term.qualification'),
            ref: 'habilitacao',
        },
        [ProcessTab.quantitative]: {
            icon: <BsBoxSeam {...iconProps} />,
            label: t('term.quantitatives'),
            title: t('term.quantitatives'),
            ref: 'quantitativo',
        },
        [ProcessTab.lotsOverview]: {
            icon: <AiOutlineFire {...iconProps} />,
            label: t('term.decision'),
            title: t('term.decision'),
            ref: 'lotes',
        },
        [ProcessTab.contracts]: {
            icon: <ImStack {...iconProps} />,
            label: t('term.contracts'),
            title: t('term.contracts'),
            ref: 'contratos',
        },
        [ProcessTab.integrations]: {
            icon: <BsPuzzle {...iconProps} />,
            label: t('term.integrations'),
            title: t('term.integrations'),
            ref: 'integracoes',
        },
    };

    return tabs;
};
