import { Popover } from '@material-ui/core';
import { Button } from 'common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidateTermsProps } from './props';
import { Content, Actions, Example } from './styled';

const ValidateTerms: FC<ValidateTermsProps> = ({ anchorEl, onConfirm, opened }) => {
    const { t } = useTranslation();

    return (
        <Popover
            open={opened}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            anchorEl={anchorEl}
        >
            <Content>
                <span>
                    {t('mkp.search.info-popover-1')} <b>20</b> {t('mkp.search.info-popover-2')}
                </span>
                <Example>
                    <span>{t('mkp.search.info-popover-ex')}</span>
                </Example>
                <Actions>
                    <Button size='xsmall' variant='contained' onClick={onConfirm}>
                        {t('term.understood')}
                    </Button>
                </Actions>
            </Content>
        </Popover>
    );
};

export default ValidateTerms;
