import React from 'react';
import { StatusPayment } from 'common/enums';
import SelectStatusPaymentView from './select-status-payment-view';

export interface SelectStatusPaymentProps {
    value?: StatusPayment;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disableAllOption?: boolean;
}

const SelectStatusPayment: React.FC<SelectStatusPaymentProps> = (props) => (
    <SelectStatusPaymentView {...props} />
);

export default SelectStatusPayment;
