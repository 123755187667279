import { VARIABLE_ELEMENT_CLASSNAME, VARIABLE_TRIGGER } from 'modules/editor/components/editor';
import { DefaultVariable } from 'modules/editor/interfaces/variables.interface';
import { AuctionVariableValues } from './mount-auction-variables';

export const replaceVariablesToValue = (
    html: string,
    defaultVariables: DefaultVariable[],
    auctionVariables?: AuctionVariableValues
): string => {
    const newHtml = html?.replace(
        new RegExp(
            new RegExp(`<span class="${VARIABLE_ELEMENT_CLASSNAME}"[^>]*>[^>]*</span>`, 'g')
        ),
        (match: any) => {
            // cria elemento temporário para facilitar manipulação
            const el: any = document.createElement('div');
            el.innerHTML = match;

            if (!el.firstChild) {
                return match;
            }

            const value = el.firstChild.innerHTML?.replace(VARIABLE_TRIGGER, '')?.trim();
            const defaultVariableValue: string | undefined = defaultVariables.find(
                (defaultVariable) => defaultVariable.name === value
            )?.value;

            // salva variável em uma tag identificável para possiveis alterações futuramente
            if (defaultVariableValue) {
                return `<span contenteditable="false" data-replaced-default-variable="${el.firstChild.innerHTML}" content>${defaultVariableValue}</span>`;
            }

            const auctionVariableValue: string | undefined = auctionVariables?.[value];

            if (auctionVariableValue) {
                return `<span contenteditable="false" data-replaced-auction-variable="${el.firstChild.innerHTML}">${auctionVariableValue}</span>`;
            }

            return match;
        }
    );

    return newHtml;
};
