import React from 'react';
import { StatusPayment } from 'common/enums';
import { Grid } from '@material-ui/core';
import SelectStatusPayment from './components/select-status-payment';
import { SearchInvoicesTableHeaderProps } from './search-invoices-table-header';

const SearchInvoicesTableHeaderView: React.FC<SearchInvoicesTableHeaderProps> = ({
    onChangeStatusPayment,
    statusPayment,
}) => (
    <Grid container spacing={2}>
        <Grid item md={4} sm={4}>
            <SelectStatusPayment
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeStatusPayment(event?.target?.value as StatusPayment)
                }
                value={statusPayment}
            />
        </Grid>
    </Grid>
);
export default SearchInvoicesTableHeaderView;
