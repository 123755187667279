import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { FC } from 'react';

const ExpandMoreIcon: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
    </SvgIcon>
);

export default ExpandMoreIcon;
