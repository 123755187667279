import { Chip, ChipProps, Tooltip } from '@material-ui/core';
import { memo } from 'react';
import moment from 'moment';
import {
    EvaluatingStatusType,
    getEvaluatingStatusTypeDescription,
} from '../../../../interfaces/document-type-provider.interface';
import { useStyles } from './styles';

const ChipStatusView = ({
    status,
    dueDate,
}: {
    status: EvaluatingStatusType;
    dueDate?: string | null;
}) => {
    const classes = useStyles();

    const defaultProps: ChipProps = {
        label:
            getEvaluatingStatusTypeDescription()[status] ||
            getEvaluatingStatusTypeDescription()[EvaluatingStatusType.NAO_ENVIADO],
        className: classes.chipStatusNotSent,
    };

    if (status === EvaluatingStatusType.EXPIRADO) {
        defaultProps.className = classes.chipStatusExpired;
    } else if (status === EvaluatingStatusType.AGUARDANDO_AVALIACAO) {
        defaultProps.className = classes.chipStatusPending;
    } else if (status === EvaluatingStatusType.APROVADO) {
        defaultProps.className = classes.chipStatusApproved;
    } else if (status === EvaluatingStatusType.NAO_ENVIADO) {
        defaultProps.className = classes.chipStatusNotSent;
    } else if (status === EvaluatingStatusType.REPROVADO) {
        defaultProps.className = classes.chipStatusRejected;
    } else if (status === EvaluatingStatusType.AGUARDANDO_ATI_VCTE) {
        defaultProps.className = classes.chipReference;
    }

    if (status === EvaluatingStatusType.EXPIRADO) {
        return (
            <Tooltip
                title={moment(dueDate).utcOffset('-03:00').format('DD/MM/YYYY')}
                aria-label='info'
            >
                <Chip size='small' {...defaultProps} />
            </Tooltip>
        );
    }

    return <Chip size='small' color='primary' {...defaultProps} />;
};

export default memo(ChipStatusView);
