enum RegistrationUpdateRequestStatus {
    draft = 'draft',
    pending = 'pending',
    approved = 'approved',
    reproved = 'reproved',
}

enum RegistrationUpdateRequestType {
    insert = 'insert',
    update = 'update',
}

enum RegistrationUpdateRequestPriority {
    low = 'low',
    medium = 'medium',
    high = 'high',
}

interface ProviderRegistrationUpdateRequest {
    id: number;
    providerId: number;
    operatorId?: number | null | undefined;
    dataFields: any;
    dataDocuments: any;
    dataSegments: any;
    auctionId?: number | null;
    priority: RegistrationUpdateRequestPriority;
    type: RegistrationUpdateRequestType;
    status: RegistrationUpdateRequestStatus;
    createdAt: Date;
    updatedAt?: Date | null;
    finishedAt?: Date | null;
    sendAt: Date | null;
    provider: {
        id: number;
        docNumber: string;
        companyName: string;
    };
    auctionNotice?: {
        id: number;
        auctionNumber: string;
    };
    user?: {
        id: number;
        name: string;
    };
}

interface RegistrationUpdateRequests {
    offset?: number;
    limit?: number;
    params?: {
        priority?: RegistrationUpdateRequestPriority;
        status?: RegistrationUpdateRequestStatus;
        q?: string;
        userId?: number;
        sendAt?: number;
    };
}

interface GetRegistrationUpdateRequest {
    params?: {
        status?: RegistrationUpdateRequestStatus;
    };
}

interface ListChangesToCompareRequest {
    providerId: number;
    requestId: number;
}

interface ExportProviderRegistrationRequestsExcel {
    offset?: number;
    limit?: number;
    params?: {
        priority?: RegistrationUpdateRequestPriority;
        status?: RegistrationUpdateRequestStatus;
        q?: string;
        operatorId?: number;
        sendAt?: number;
    };
}

export type {
    ProviderRegistrationUpdateRequest,
    RegistrationUpdateRequests,
    GetRegistrationUpdateRequest,
    ListChangesToCompareRequest,
    ExportProviderRegistrationRequestsExcel,
};

export {
    RegistrationUpdateRequestPriority,
    RegistrationUpdateRequestType,
    RegistrationUpdateRequestStatus,
};
