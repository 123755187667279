import { FC, useState } from 'react';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { useTranslation } from 'react-i18next';
import ExportIntegrationCardView from './export-integration-card-view';
import { OrganizationsApps } from '../../interfaces/organization-apps.interfaces';
import { integrationsV2Requests } from '../../services/integrations-v2.requests';

interface ExportIntegrationCardProps {
    item: OrganizationsApps;
    processId: number;
}

const ExportIntegrationCard: FC<ExportIntegrationCardProps> = ({ item, processId }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(true);

    const hasExportFunction = item?.integrationSettings?.integrationCategoryId === 2;

    if (!item?.integrationSettings || !hasExportFunction || !isActive) return <></>;

    const handleSubmit = async () => {
        if (item.integration === 'ipm') {
            const city =
                process.env.REACT_APP_NODE_ENV === 'development' ? 'presidentegetulio' : undefined;
            try {
                setLoading(true);
                const { data } = await integrationsV2Requests.doSendProcessResult({
                    params: {
                        processId,
                        city,
                    },
                });
                if (data?.code) {
                    setIsActive(false);
                    addNotificationSuccess({
                        title: t('process.components.process-exported-success'),
                        message: data?.code,
                    });
                    return;
                }
                throw new Error(t('process.components.process-not-exported'));
            } catch (err) {
                addNotificationError({
                    title: t('process.components.process-not-exported'),
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <ExportIntegrationCardView
            title={item.integrationSettings.description}
            description={item.integrationSettings.fullDescription}
            onSubmit={handleSubmit}
            loading={loading}
        />
    );
};

export default ExportIntegrationCard;
