import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from '../interfaces/ok.interface';
import { PlainGenericResponse } from '../interfaces/paginated.interface';
import { FinancialProviderData } from './interfaces/financial-provider-data.interface';

const path = 'financial-provider-data';

export interface UpdateFinancialProviderData {
    docNumberReceiver?: string;
    id?: number;
    exerciseYear?: string;
    classification?: string;
    operationalResult?: string;
    netWorth?: number;
    workingCapital?: number;
    providerId?: number;
}

export interface GetFinancialProviderData {
    providerId: number;
}

const FinancialProviderDataRequests = {
    doUpdateFinancialProviderDataByAdmin: async (data: UpdateFinancialProviderData) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/doUpdateFinancialProviderDataByAdmin`, data)
        ),
    updateFinancialProviderData: async (data: UpdateFinancialProviderData) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/updateFinancialProviderData`, data)
        ),
    getFinancialProviderData: async (body: GetFinancialProviderData) =>
        doRequest<PlainGenericResponse<FinancialProviderData>>(
            managerApiInstance.post(`/${path}/getFinancialProviderData`, body)
        ),
};

export { FinancialProviderDataRequests };
