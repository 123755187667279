import { Spin } from 'common/components/base/spin';
import { Content } from './styled';

const Authenticating = () => (
    <Content>
        <Spin />
    </Content>
);

export default Authenticating;
