import { FC, useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { TypeOfBenefitValue } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { AuctionLotFormValues } from '../../../../context/lots.form';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';

export interface QuotaSelectReferenceProps {
    lotId: number;
    values: AuctionLotFormValues;
    disabled: boolean;
}

const QuotaSelectReference: FC<QuotaSelectReferenceProps> = ({ lotId, values, disabled }) => {
    const { processLotsForm, setFieldValue } = useProcessLotsFormContext();
    const { t } = useTranslation();

    const lotQuotaOpts = useMemo(() => {
        const { lots } = processLotsForm.values;
        const parentLotIdsWithChildren = new Set(
            lots.filter((lot) => lot.quotaId).map((lot) => lot.quotaId)
        );

        return lots
            .filter((lot) => {
                if (lot.id === lotId || lot.typeOfBenefit === TypeOfBenefitValue.reservedQuota) {
                    return false;
                }

                if (parentLotIdsWithChildren.has(lot.id) && lot.id !== values?.quotaId) {
                    return false;
                }

                return true;
            })
            .map((lot) => (
                <MenuItem key={lot.id} value={lot.id}>
                    {`${t('term.lot')} ${lot.item}`}
                </MenuItem>
            ));
    }, [processLotsForm.values.lots, lotId]);

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            disabled={disabled || !lotQuotaOpts.length}
            label={t('term.master-lot')}
            defaultValue={values.quotaId}
            value={values.quotaId}
            onChange={(event) => setFieldValue(values, 'quotaId', event.target.value)}
            variant='outlined'
            select
        >
            {lotQuotaOpts}
        </TextField>
    );
};

export default QuotaSelectReference;
