export default {
    root: {
        fontWeight: '500',
        fontFamily: 'Roboto',
    },
    contained: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
    },
};
