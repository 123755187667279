import { FC } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { SelectOrganizationUnitProps } from './props';
import { processActions } from 'modules/process/process-actions';

const SelectOrganizationUnit: FC<SelectOrganizationUnitProps> = ({
    onChange,
    label,
    value,
    organizationUnitOptions,
    loading,
}) => {
    const { process } = useProcessFormContext();
    const handleChangeRequester = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(parseInt(event.target.value, 10));
    };

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            label={label}
            onChange={handleChangeRequester}
            variant='outlined'
            select
            disabled={loading || processActions.cannotEditUntilDispute(process)}
            value={value}
            defaultValue={value}
            key={value}
        >
            {organizationUnitOptions.map((unit) => (
                <MenuItem key={unit.id} value={unit.id}>
                    {unit.organizationUnitName}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SelectOrganizationUnit;
