import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        color: theme.palette.text.primary,
    },
    stepper: {
        background: theme.palette.background.default,
        '& .MuiStep-completed': {
            '& span': {
                color: theme.palette.success.main,
            },
        },
    },
    step: {
        '& span': {
            color: theme.palette.text.primary,
        },
    },
    paper: {
        padding: theme.spacing(3, 6, 3, 6),
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6),
        width: '100%',
    },
}));

export default useStyles;
