import { FC, useEffect, useState } from 'react';
import { Select } from '@licitar/ui-kit';
import { useTranslation } from 'react-i18next';
import { SupplyCategory } from '../../../../../clients/manager/interfaces/supply-categories.interface';
import { supplyCategoriesRequests } from '../../../../../clients/manager/supply-categories.requests';

interface SelectSupplyCategoriesProps {
    onChange: (value: number[]) => void;
    selectedValues?: number[];
}

const SelectSupplyCategories: FC<SelectSupplyCategoriesProps> = ({ selectedValues, onChange }) => {
    const { t } = useTranslation();
    const [supplyCategories, setSupplyCategories] = useState<SupplyCategory[]>([]);

    const getSupplyCategories = async () => {
        try {
            const response = await supplyCategoriesRequests.listSupplyCategories({
                params: {
                    level: 1,
                },
            });
            setSupplyCategories(response?.data ?? []);
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    useEffect(() => {
        getSupplyCategories();
    }, []);

    const getOptions = () =>
        supplyCategories.map((supplyCategory) => ({
            value: supplyCategory.id,
            label: supplyCategory.categoryName,
        }));

    return (
        <Select
            value={selectedValues?.map((supplyCategoryId) => {
                const currentOption = getOptions().find(
                    (option) => option.value === supplyCategoryId
                );

                return {
                    label: currentOption?.label,
                    value: currentOption?.value,
                };
            })}
            name='type'
            isMulti
            label={t('term.supply-lines')}
            options={getOptions()}
            onChange={(options) => {
                onChange(options.map((item) => item.value));
            }}
        />
    );
};

export default SelectSupplyCategories;
