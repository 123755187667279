export enum DocumentProcessType {
    auction = 0, // Pregão
    exemption = 1, // Dispensa
    accreditation = 2, // Credenciamento
    publicSale = 3, // Leilão
    concurrence = 4, // Concorrência
    unenforceability = 5, //  Inexibilidade
    competitiveDialogue = 6, // Diálogo competitivo
    contest = 7, // Concurso
    expressionOfInterest = 8, // Manifestação de interesse
    preQualification = 9, // Pré qualificação
}
