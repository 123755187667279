import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { organizationMinutesRequests } from '../../../../../clients/manager/organization-minutes.requests';
import { DocumentTemplate } from '../../../../../clients/manager/interfaces/document-templates.interface';
import ModalAddMinuteView from './modal-add-minute-view';
import useStyles from './modal-add-minute-styles';

export interface ModalEditOrganizationUnitProps {
    onClose: () => void;
    onCreateMinute: () => void;
}

const ModalAddMinute: FC<ModalEditOrganizationUnitProps> = ({ onClose, onCreateMinute }) => {
    const { t } = useTranslation();
    const [selectedTemplate, setSelectedTemplate] = useState<DocumentTemplate | undefined>(
        undefined
    );
    const classes = useStyles();

    const createOrganizationMinute = async (documentTemplateId: string) => {
        try {
            await organizationMinutesRequests.createOrganizationMinute({
                documentTemplateId,
            });

            onCreateMinute();
            addNotificationSuccess();
        } catch (err) {
            addNotificationError();
        }
    };

    return (
        <ModalAddMinuteView
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            createOrganizationMinute={createOrganizationMinute}
            t={t}
            onClose={onClose}
            classes={classes}
        />
    );
};

export default ModalAddMinute;
