import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        // width: `calc(100% - ${theme.spacing(2)}px)`,
        marginTop: theme.spacing(1),
    },
    scroller: {
        flexGrow: 1,
    },
}));

export { useStyles };
