import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

export const ChipContainer = styled(ButtonBase)<{ disabled: boolean }>`
    background-color: var(--primary-color);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    padding: 0.7rem;
    font-size: 1rem;
    color: var(--background-color);
    filter: ${({ disabled }) => disabled && 'saturate(0) brightness(1.5)'};
    max-width: 100%;
`;

export const ChipIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
        display: flex;
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

export const ChipDescription = styled.div`
    max-width: 100%;
    color: var(--background-color);
    font-size: 0.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fit-content;
`;
