import { SaleSourceTypes } from 'common/interfaces/sale-source.interface';

enum ItemPriceType {
    BEST_BID = 'BEST_BID',
    PROPOSAL = 'PROPOSAL',
}

enum PriceMountType {
    media = 'media',
    median = 'median',
    lowerPrice = 'lowerPrice',
}

interface Price {
    _id: string;
    itemBrand: string;
    itemId: string;
    manufacturerDescription: string;
    manufacturerDetailDescription: string;
    providerId: string;
    saleDate: number;
    saleSourceId: string;
    unitMeasurement: string;
    unitValue: number;
    priceType: ItemPriceType;
}

interface PricesItem {
    description: string;
    detailDescription: string;
    itemId: string;
    unitMeasurement: string;
    saleSourceId: string;
    saleSourceSaleDate: number;
    saleSourceProcessNumber: string;
    saleSourcePublicCompanyName: string;
    saleSourceCompanyName: string;
    saleSourceCity: string;
    saleSourceUf: string;
    sourceType: SaleSourceTypes;
    prices: Price[];
}

export { ItemPriceType, PriceMountType };
export type { Price, PricesItem };
