import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: formatPxToRem(16),
        marginBottom: formatPxToRem(24),
    },
    searchContainer: {
        display: 'flex',
        flex: 1,
    },
    icon: {
        margin: '9px 0',
    },
}));

export { useStyles };
