import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectIsPublicProps {
    value: boolean | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    omitAllOption?: boolean;
}

const SelectIsPublic: FC<SelectIsPublicProps> = ({ value, onChange, omitAllOption }) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='isDocumentProtected'
            label={t('term.publication')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            variant='outlined'
            select
            value={value}
        >
            {!omitAllOption && <MenuItem value={String(value)}>{t('term.all')}</MenuItem>}
            <MenuItem value={String(true)}>{t('term.protected')}</MenuItem>
            <MenuItem value={String(false)}>{t('term.public')}</MenuItem>
        </TextField>
    );
};

export default SelectIsPublic;
