import { styled } from 'common/theme/styled';
import { Input } from '@licitar/ui-kit';
import { RiFilter2Fill, RiFilter2Line } from 'react-icons/ri';

const Content = styled.div`
    border: 1px ${(props) => props.theme.colors.borderColorDefault} solid;
    border-radius: 4px;
    background: #fff;
    margin: 10px 0 0 0;
    flex: 1;
    padding: 15px 15px 10px 15px;
`;

const ResumeArea = styled.div`
    display: flex;
    flex: 1;
`;

const TextFilter = styled.div`
    width: 50%;

    span {
        margin: 0 5px;
        font-size: 13px;
        color: ${(props) => props.theme.colors.textLight2};
    }
`;

const Search = styled.div`
    display: flex;
    align-items: center;

    div:first-child {
        flex: 1;
    }
`;

const FilterIconFilled = styled(RiFilter2Fill)`
    color: ${(props) => props.theme.colors.iconColorDefault};
    margin: 0 0 0 8px;
    font-size: 20px;
    cursor: pointer;
`;

const FilterIconEmpty = styled(RiFilter2Line)`
    color: ${(props) => props.theme.colors.iconColorDefault};
    margin: 0 0 0 8px;
    font-size: 20px;
    cursor: pointer;
`;

const SearchInput = styled(Input)``;

const SearchInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 25px 0 0;

    span:nth-child(2) {
        color: ${(props) => props.theme.colors.tertiary};
        cursor: pointer;
    }
`;

export {
    Content,
    SearchInput,
    TextFilter,
    FilterIconEmpty,
    FilterIconFilled,
    ResumeArea,
    Search,
    SearchInfo,
};
