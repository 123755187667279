import { PriceList } from 'common/interfaces/price-list.interface';
import React, { createContext, ReactNode, useContext, useState } from 'react';

export type PriceBankContextType = {
    priceListSelected: PriceList | undefined;
    setPriceListSelected: React.Dispatch<React.SetStateAction<PriceList | undefined>>;
};

export const PriceBankContext = createContext<PriceBankContextType>({
    priceListSelected: undefined,
    setPriceListSelected: () => {},
});

export const usePriceBankContext = () => useContext(PriceBankContext);

export const PriceBankProvider = ({ children }: { children: ReactNode }) => {
    const [priceListSelected, setPriceListSelected] = useState<PriceList | undefined>(undefined);

    return (
        <PriceBankContext.Provider
            value={{
                priceListSelected,
                setPriceListSelected,
            }}
        >
            {children}
        </PriceBankContext.Provider>
    );
};
