import { styled } from 'common/theme/styled';

const CollapseHeader = styled.div`
    display: grid;

    span:nth-child(1) {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
    }

    span:nth-child(2) {
        color: #777;
        font-size: 12px;
    }
`;

const Content = styled.div`
    margin: 15px 0 0 0;
`;

const CollapseContent = styled.div`
    padding: 0 10px 10px 10px;
`;

export { Content, CollapseHeader, CollapseContent };
