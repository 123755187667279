export default {
    root: {
        '&.MuiInput-root': {
            background: 'white',
            borderRadius: 5,
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
            '&.Mui-error': {
                border: '2px solid #E74C3C',
            },
        },
    },
};
