/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import i18n from 'i18next';
import { AnyObject, Maybe } from 'yup/lib/types';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { validateCnpj } from 'common/utils';

Yup.setLocale({
    mixed: {
        required: i18n.t('term.required-field'),
    },
    string: {
        min: 'Tamanho mínimo: ${min} caracteres.',
        max: 'Tamanho máximo: ${max} caracteres.',
        email: i18n.t('please-enter-a-valid-email', { ns: 'validation' }),
    },
    array: {
        min: 'É preciso selecionar no mínimo ${min}',
        max: 'É preciso selecionar no máximo ${max}',
    },
    number: {
        max: 'O valor não pode ser maior que ${max}',
        min: 'O valor não pode ser menor que ${min}',
        integer: 'O valor deve ser inteiro.',
    },
});

// existem alguns campos que são vários ids separados por vírgula, esse método valida quantidde
Yup.addMethod<Yup.StringSchema>(Yup.string, 'moreThanArraySplit', function (args) {
    return this.test(`tmore-than-array-split`, args.message, function (value) {
        const { path, createError } = this;

        if (!value || value.split(',').length < args.min) {
            return createError({ path, message: args.message });
        }

        return true;
    });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'cnpj', function (args) {
    return this.test(`cnpj`, args.message, function (value) {
        const { path, createError } = this;
        const cnpj = value && value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');

        if ((cnpj && cnpj?.length < 14) || (cnpj?.length === 14 && !validateCnpj(cnpj))) {
            return createError({ path, message: args.message });
        }

        return true;
    });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phone', function (args) {
    return this.test(`phone`, args.message, function (value = '') {
        const { path, createError } = this;

        if (isValidPhoneNumber(value)) {
            return true;
        }
        return createError({ path, message: args.message });
    });
});

declare module 'yup' {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType
    > extends Yup.BaseSchema<TType, TContext, TOut> {
        moreThanArraySplit(args: { min: number; message: string }): StringSchema<TType, TContext>;
        cnpj(args: { message: string }): StringSchema<TType, TContext>;
        phone(args: { message: string }): StringSchema<TType, TContext>;
    }
}

export default Yup;
