import { memo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { useTranslation } from 'react-i18next';
import WifiIcon from '@material-ui/icons/Wifi';
import moment from 'moment';
import StartCounterReasonDialog from './start-counter-reason-dialog';
import Counter from '../time-until-counter';

const ActionsRequest = () => {
    const { processForm } = useProcessFormContext();
    const { t } = useTranslation();

    const duration = moment
        .duration(moment(processForm?.values?.deadlineAppeal).diff(moment()))
        .asSeconds();
    const [finishedTime, setFinishedTime] = useState(duration <= 0);

    return finishedTime ? (
        <>
            <Box display='flex' alignItems='center' ml={2}>
                <WifiIcon style={{ fontSize: 16, marginRight: 4, color: '#E74C3C' }} />
                <Typography
                    style={{
                        color: '#E74C3C',
                    }}
                    variant='body2'
                >
                    Tempo para <b>Recurso</b> finalizado!
                </Typography>
                <StartCounterReasonDialog>
                    <Button size='xsmall' variant='contained' style={{ marginLeft: 8, height: 22 }}>
                        {t('info.start-counter-reason')}
                    </Button>
                </StartCounterReasonDialog>
            </Box>
        </>
    ) : (
        <>
            <Box display='flex' alignItems='center' ml={2}>
                <WifiIcon style={{ fontSize: 16, marginRight: 4, color: '#E74C3C' }} />
                <Typography
                    style={{
                        color: '#E74C3C',
                        fontWeight: 500,
                    }}
                    variant='body2'
                >
                    {`Recurso - Faltam `}
                    <Counter
                        secondsRemaining={duration}
                        onFinishTimer={() => setFinishedTime(true)}
                    />
                </Typography>
            </Box>
        </>
    );
};

export default memo(ActionsRequest);
