import { useEffect, useState } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { useHistory, useParams } from 'react-router';
import { Box, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { notificationsRequests } from 'clients/manager/notifications.requests';
import { Notification } from 'clients/manager/interfaces/notification.interface';
import { BlankArea } from 'modules/bidding/pages/search/styled';
import { useStyles } from 'common/components/loading-button';
import { t } from 'i18next';
import Breadcrumb from 'common/components/base/breadcrumb';
import moment from 'moment';
import { addNotificationError } from 'common/utils';
import { Header, Page } from './styles';

const NotificationDetails = () => {
    const classes = useStyles();
    const history = useHistory();
    const { setTitle } = usePageContext();
    const { notificationId } = useParams<{ notificationId: string }>();
    const [notification, setNotification] = useState<Notification>();
    const [html, setHtml] = useState<string | (null | undefined)>();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const getNotification = async () => {
        setLoading(true);
        setError(t(''));
        try {
            const response = await notificationsRequests.getNotificationById(
                Number(notificationId)
            );
            setNotification(response.data ?? {});
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(t('error.response.try-again'));
            addNotificationError({
                title: t('bidding.notification-error-occurred'),
                message: t('error.response.try-again'),
            });
        }
    };

    useEffect(() => {
        getNotification();
        setTitle(t('term.notifications'));
    }, []);

    useEffect(() => {
        setHtml(notification?.body);
    }, [notification]);

    const header = (
        <Header>
            <Breadcrumb
                customPath='/notificacoes/:notificationId'
                paths={{
                    notificacoes: {
                        value: t('term.notifications'),
                        onClick: () => history.push('/notificacoes'),
                    },
                }}
                params={{
                    notificationId: {
                        value: t('notification.details.title'),
                        disabled: true,
                    },
                }}
            />
        </Header>
    );
    return (
        <Page>
            {loading && (
                <BlankArea>
                    <CircularProgress color='secondary' size={64} />
                </BlankArea>
            )}
            {error && <Typography color='error'>{error}</Typography>}
            {!loading && !error && (
                <>
                    {header}
                    <Box mt={2}>
                        <Paper square className={classes.root}>
                            <Grid>
                                <Typography
                                    variant='body2'
                                    color='textPrimary'
                                    style={{ paddingLeft: 30, paddingTop: 20 }}
                                >
                                    <b>{t('term.subject')}: </b>
                                    {notification?.subject}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='textPrimary'
                                    style={{ paddingLeft: 30, paddingTop: 5 }}
                                >
                                    <b>{t('notification.date')}: </b>
                                    {moment(notification?.dateTimeInsert).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}
                                </Typography>
                                {notification?.isRead && (
                                    <Typography
                                        variant='body2'
                                        color='textPrimary'
                                        style={{ paddingLeft: 30, paddingTop: 5 }}
                                    >
                                        <b>{t('notification.read.date')}: </b>
                                        {moment(notification?.dateTimeRead).format(
                                            'DD/MM/YYYY HH:mm:ss'
                                        )}
                                    </Typography>
                                )}
                            </Grid>
                            <div
                                style={{ padding: 30, fontSize: 14, color: '#3b3b3b' }}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: html || '' }}
                            />
                        </Paper>
                    </Box>
                </>
            )}
        </Page>
    );
};

export default NotificationDetails;
