import i18n from 'i18next';

export interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

export const getColumns = (): Column[] => [
    { id: 'code', label: i18n.t('term.code'), minWidth: 70 },
    { id: 'modality', label: i18n.t('term.modality'), minWidth: 120 },
    {
        id: 'title',
        label: i18n.t('term.title'),
        minWidth: 180,
    },
    {
        id: 'edital',
        label: i18n.t('term.notice'),
        minWidth: 100,
    },
    {
        id: 'step',
        label: i18n.t('term.step'),
        minWidth: 170,
    },
    {
        id: 'info',
        label: '',
        minWidth: 130,
    },
    {
        id: 'actions',
        label: i18n.t('term.actions'),
        minWidth: 70,
    },
];
