import { TypeInvoice } from 'common/enums';
import i18n from 'common/i18n';

const getTypeInvoice = (type: TypeInvoice): string => {
    const obj = {
        [TypeInvoice.byAuction]: i18n.t('term.by-auction'),
        [TypeInvoice.demand]: i18n.t('term.demand'),
        [TypeInvoice.yearly]: i18n.t('term.yearly'),
    };

    return obj?.[type] ?? type;
};

export { getTypeInvoice };
