import { FC, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
    Breadcrumbs,
    ButtonGroup,
    Tooltip,
    Typography,
    MenuItem,
    Box,
    Grid,
    Paper,
} from '@material-ui/core';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { SearchOutlined, FormatListBulleted, GridOn } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { usePageContext } from 'common/components/base/pages/context';
import {
    auctionNoticeRequests,
    ListProcessesFromOrganizationFilters,
} from 'clients/manager/auction-notice.requests';
import {
    AuctionNoticeOrganizationResume,
    AuctionType,
} from 'clients/manager/interfaces/auction-notice.interface';
import { addNotificationWarning } from 'common/utils';
import { Button, TextField } from 'common/components';
import ListProcessesGrid from '../../components/list-processes-grid';
import { ProcessListProps } from './props';
import ListProcessesTable from '../../components/list-processes-table';
import ButtonCreateProcess from '../../components/button-create-process';

interface ProcessFilters {
    modality: AuctionType | -1;
    limit?: number;
    offset?: number;
    disputeDate?: number;
    orderBy?: string;
}

enum ProcessListView {
    table = 'table',
    grid = 'grid',
}

const ProcessList: FC<ProcessListProps> = () => {
    const { t } = useTranslation();
    const [processFilters, setProcessFilters] = useState<ProcessFilters>({
        modality: -1,
        disputeDate: undefined,
        orderBy: 'auctionStartDate',
    });
    const [textSearch, setTextSearch] = useState('');
    const [processListViewMode, setProcessListViewMode] = useState<ProcessListView>(
        ProcessListView.grid
    );
    const [processes, setProcesses] = useState<AuctionNoticeOrganizationResume[]>([]);

    const { setTitle } = usePageContext();

    useEffect(() => {
        setTitle(t('term.process'));
    }, []);

    const handleChangeProcessFilters = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: string
    ) => {
        setProcessFilters({
            ...processFilters,
            [field]: event.target.value,
        });
    };

    const getProcesses = async () => {
        try {
            const { params }: Pick<ListProcessesFromOrganizationFilters, 'params'> = {
                params: {},
            };

            if (textSearch) {
                params.q = textSearch;
            }

            if (processFilters.modality !== -1) {
                params.auctionType = processFilters.modality;
            }

            if (processFilters.disputeDate) {
                params.disputeDate = processFilters.disputeDate;
            }

            if (processFilters.orderBy) {
                params.orderBy = processFilters.orderBy;
            }

            const processes = await auctionNoticeRequests.listProcessesFromOrganizationWithResumes({
                params,
            });
            setProcesses(processes.data);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('process.components.error-list-process'),
            });
        }
    };

    useEffect(() => {
        getProcesses();
    }, [textSearch, processFilters]);

    const debounceSearchText = debounce((text: string) => setTextSearch(text), 500);

    return (
        <PageWrapper>
            <Box display='flex' justifyContent='space-between'>
                <Box>
                    <Breadcrumbs color='primary'>
                        <Typography color='textPrimary'>
                            {t('process.components.list-process')}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <ButtonCreateProcess />
                </Box>
            </Box>
            <Box mt={2} component={Paper}>
                <Box p={2}>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            alignSelf: 'center',
                            justifySelf: 'center',
                        }}
                    >
                        <Grid item md={4} sm={6} xs={12}>
                            <TextField
                                fullWidth
                                label={t('process.components.search-process')}
                                name='process_text'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    debounceSearchText(event.target.value?.toLowerCase())
                                }
                                InputProps={{
                                    endAdornment: (
                                        <Tooltip
                                            title={t('process.components.search-process') as string}
                                            aria-label='info'
                                        >
                                            <SearchOutlined />
                                        </Tooltip>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <TextField
                                fullWidth
                                id='select'
                                color='primary'
                                label={t('term.modalities')}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChangeProcessFilters(event, 'modality')
                                }
                                variant='outlined'
                                select
                                defaultValue={processFilters.modality}
                            >
                                <MenuItem value={-1}>{t('term.all')}</MenuItem>
                                <MenuItem value={AuctionType.accreditation}>
                                    {t('term.accreditation')}
                                </MenuItem>
                                <MenuItem value={AuctionType.dispense}>
                                    {t('term.electronic-dispenser')}
                                </MenuItem>
                                <MenuItem value={AuctionType.auction}>
                                    {t('term.electronic-auction')}
                                </MenuItem>
                                <MenuItem value={AuctionType.publicSale}>
                                    {t('term.auction')}
                                </MenuItem>
                                <MenuItem value={AuctionType.concurrence}>
                                    {t('term.competition')}
                                </MenuItem>
                                <MenuItem value={AuctionType.unenforceability}>
                                    {t('term.unenforceability')}
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item md={2} sm={4} xs={12}>
                            <TextField
                                fullWidth
                                id='select'
                                color='primary'
                                label='Ordenar por'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChangeProcessFilters(event, 'orderBy')
                                }
                                variant='outlined'
                                select
                                defaultValue='auctionStartDate'
                            >
                                <MenuItem value='auctionStartDate'>
                                    {t('term.auction-start-date')}
                                </MenuItem>
                                <MenuItem value='startDateTimeDispute'>
                                    {t('term.start-date-time-dispute')}
                                </MenuItem>
                                {processListViewMode === ProcessListView.table && (
                                    <MenuItem value='biddingStageId'>{t('term.step')}</MenuItem>
                                )}
                                <MenuItem value='id'>ID</MenuItem>
                                <MenuItem value='auctionType'>{t('term.modality')}</MenuItem>
                                <MenuItem value='auctionNumber'>
                                    {t('term.process-number')}
                                </MenuItem>
                                <MenuItem value='accreditationNumber'>
                                    {t('term.modality-number')}
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item md={2} sm={4} xs={6}>
                            <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label={t('term.dispute-date')}
                                name='sendAt'
                                format='DD/MM/yyyy'
                                value={processFilters.disputeDate || null}
                                InputAdornmentProps={{ position: 'end' }}
                                invalidDateMessage={t('info.invalid-format', {
                                    ns: 'validation',
                                })}
                                invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                onChange={(date) => {
                                    if (date?.isValid() || date === null) {
                                        setProcessFilters((prevFilters) => ({
                                            ...prevFilters,
                                            disputeDate: moment.utc(date).valueOf(),
                                        }));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={2} sm={4} xs={6} style={{ alignSelf: 'center' }}>
                            <ButtonGroup
                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                variant='outlined'
                                aria-label='outlined primary button group'
                            >
                                <Button
                                    title={t('process.components.view-card')}
                                    color={
                                        processListViewMode === ProcessListView.grid
                                            ? 'secondary'
                                            : 'default'
                                    }
                                    size='small'
                                    onClick={() => setProcessListViewMode(ProcessListView.grid)}
                                >
                                    <GridOn />
                                </Button>
                                <Button
                                    title={t('process.components.view-table')}
                                    color={
                                        processListViewMode === ProcessListView.table
                                            ? 'secondary'
                                            : 'default'
                                    }
                                    size='small'
                                    onClick={() => setProcessListViewMode(ProcessListView.table)}
                                >
                                    <FormatListBulleted />
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Box>
                {processListViewMode === ProcessListView.table && (
                    <ListProcessesTable processes={processes} />
                )}
                {processListViewMode === ProcessListView.grid && (
                    <ListProcessesGrid processes={processes} />
                )}
            </Box>
        </PageWrapper>
    );
};

export default ProcessList;
