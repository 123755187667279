import { doRequest, managerApiInstance } from 'clients/api';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { DocumentTypesProposal } from 'clients/manager/document-types.requests';
import { DocumentTypeDTO } from '../interfaces/document-type.dto';

const BASE_PATH = '/document-types';

const listDocumentTypesOrganization = (name: string) =>
    managerApiInstance.post(`${BASE_PATH}/listDocumentTypesOrganization`, { name });

const createDocumentType = async (values: DocumentTypeDTO) =>
    doRequest<PlainGenericResponse<DocumentTypesProposal>>(
        managerApiInstance.post(`${BASE_PATH}/createDocumentType`, values)
    );

const deleteDocumentType = (id: number) =>
    managerApiInstance.post(`${BASE_PATH}/deleteDocumentType`, { id });

const DocumentTypesService = {
    listDocumentTypesOrganization,
    deleteDocumentType,
    createDocumentType,
};

export default DocumentTypesService;
