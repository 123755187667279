import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DatePicker } from '@material-ui/pickers';
import { Editor } from '@tinymce/tinymce-react';
import { NoticeInformation } from 'clients/manager/interfaces/notice-information.interfaces';
import { noticesInformationRequests } from 'clients/manager/notice-information.requests';
import { FormikProps } from 'formik';
import useNoticesInformationContext from 'modules/notices-information/hooks/useNoticesInformation';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor as TinyMCEEditor } from 'tinymce';
import { NoticeInformationForm } from 'modules/notices-information/contexts/NoticesInformationContext';
import { HiOutlineX } from 'react-icons/hi';
import { EDITOR_KEY } from '../../../../../editor/components/editor';
import { NoticeInformationEditorConfigs } from './notice-information-editor-config';
import InformationFiles from '../information-files';

interface DialogCrudNoticeInformationViewProps {
    form: FormikProps<NoticeInformationForm>;
    isLoadingSubmitForm: boolean;
    noticesInformationForEdit?: NoticeInformation;
    isOpenDialogNewNoticeInformation: boolean;
    onCloseModalDialogNewNoticeInformation: () => void;
}

const DialogCrudNoticeInformationView: React.FC<DialogCrudNoticeInformationViewProps> = ({
    form,
    isLoadingSubmitForm,
    noticesInformationForEdit,
    isOpenDialogNewNoticeInformation,
    onCloseModalDialogNewNoticeInformation,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { audienceEnum } = useNoticesInformationContext();
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const firstInputRef = useRef<HTMLInputElement | null>(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setTimeout(() => {
            if (firstInputRef && !!firstInputRef.current) firstInputRef.current.focus();
        }, 100);
    }, []);

    return (
        <Dialog
            fullWidth
            disableEscapeKeyDown
            fullScreen={fullScreen}
            maxWidth='lg'
            aria-labelledby='create-new-notice-information-dialog'
            aria-describedby='create-new-notice-information-dialog-description'
            open={isOpenDialogNewNoticeInformation}
            onClose={onCloseModalDialogNewNoticeInformation}
        >
            <DialogTitle
                disableTypography
                id='create-new-notice-information-dialog-title'
                style={{
                    backgroundColor: 'var(--primary-color)',
                    padding: '12px 24px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='h6' color='textSecondary'>
                    {!noticesInformationForEdit
                        ? t('notices-information.title.register-notices-information')
                        : t('notices-information.title.update-notices-information')}
                </Typography>
                <IconButton
                    aria-label='close modal details'
                    color='primary'
                    onClick={onCloseModalDialogNewNoticeInformation}
                    style={{
                        padding: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}
                >
                    <HiOutlineX size={28} color='var(--table-header-color)' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box paddingY={2}>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    inputRef={firstInputRef}
                                    autoFocus
                                    fullWidth
                                    required
                                    disabled={isLoadingSubmitForm}
                                    size='small'
                                    type='text'
                                    variant='outlined'
                                    name='title'
                                    label={t('notices-information.labels.title')}
                                    value={form.values.title}
                                    onChange={form.handleChange}
                                    error={Boolean(form.errors.title)}
                                    helperText={form.errors.title}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    required
                                    disabled={isLoadingSubmitForm}
                                    size='small'
                                    type='text'
                                    variant='outlined'
                                    name='resume'
                                    label={t('notices-information.labels.resume')}
                                    value={form.values.resume}
                                    onChange={form.handleChange}
                                    error={Boolean(form.errors.resume)}
                                    helperText={form.errors.resume}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Editor
                                    key='create-new-notice-information-editor-html-key'
                                    id='create-new-notice-information-editor-html-id'
                                    apiKey={EDITOR_KEY}
                                    onInit={(_, editor) => {
                                        editorRef.current = editor;
                                    }}
                                    initialValue={
                                        noticesInformationForEdit
                                            ? noticesInformationForEdit.description
                                            : ''
                                    }
                                    onChange={() => {
                                        if (editorRef.current) {
                                            const htmlContent = editorRef.current.getContent();
                                            return form.setFieldValue('description', htmlContent);
                                        }
                                    }}
                                    init={NoticeInformationEditorConfigs.init((blob) =>
                                        noticesInformationRequests.uploadFileNoticeInformation(blob)
                                    )}
                                />
                                <FormHelperText
                                    key='error-description-editor-html'
                                    id='error-description-editor-html'
                                    style={{ color: 'var(--danger-color)' }}
                                >
                                    {form.errors.description}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    size='small'
                                    type='text'
                                    variant='outlined'
                                    name='textLink'
                                    label={t('notices-information.labels.textLink')}
                                    value={form.values.textLink}
                                    onChange={form.handleChange}
                                    error={Boolean(form.errors.textLink)}
                                    helperText={form.errors.textLink}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    size='small'
                                    type='text'
                                    variant='outlined'
                                    name='urlLink'
                                    label={t('notices-information.labels.url-link')}
                                    value={form.values.urlLink}
                                    onChange={form.handleChange}
                                    error={Boolean(form.errors.urlLink)}
                                    helperText={form.errors.urlLink}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={3}
                                md={3}
                                style={{ display: 'grid', alignItems: 'center' }}
                            >
                                <FormControlLabel
                                    disabled={isLoadingSubmitForm}
                                    name='isPublic'
                                    control={
                                        <Switch
                                            name='isPublic'
                                            checked={form.values.isPublic}
                                            color='primary'
                                        />
                                    }
                                    label={t('notices-information.labels.is-public')}
                                    onChange={form.handleChange}
                                    value={form.values.isPublic}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={3}
                                md={3}
                                style={{ display: 'grid', alignItems: 'center' }}
                            >
                                <FormControlLabel
                                    disabled={isLoadingSubmitForm}
                                    name='isFixedOnTop'
                                    control={
                                        <Switch
                                            name='isFixedOnTop'
                                            checked={form.values.isFixedOnTop}
                                            color='primary'
                                        />
                                    }
                                    label={t('notices-information.labels.is-fixed-on-top')}
                                    onChange={form.handleChange}
                                    value={form.values.isFixedOnTop}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <DatePicker
                                    fullWidth
                                    disableToolbar
                                    format='DD/MM/yyyy'
                                    disabled={isLoadingSubmitForm}
                                    key='date-picker-expiration-at'
                                    color='primary'
                                    inputVariant='outlined'
                                    name='expirationAt'
                                    label={t('notices-information.labels.expiration-at')}
                                    value={form.values.expirationAt}
                                    onChange={(value) => {
                                        form.setFieldValue('expirationAt', value?.toJSON());
                                    }}
                                    error={Boolean(form.errors.expirationAt)}
                                    helperText={form.errors.expirationAt}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    disabled={isLoadingSubmitForm}
                                    variant='outlined'
                                    name='audience'
                                    label={t('notices-information.labels.audience')}
                                    value={form.values.audience}
                                    onChange={form.handleChange}
                                    error={Boolean(form.errors.audience)}
                                    helperText={form.errors.audience}
                                >
                                    {audienceEnum.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <InformationFiles
                                    label={t('notices-information.labels.select-cover-image')}
                                    type='image'
                                    mutiple={false}
                                    value={form?.values?.coverImage ? [form.values.coverImage] : []}
                                    onChange={(images) => {
                                        form.setFieldValue('coverImage', images[0]);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InformationFiles
                                    label={t('notices-information.labels.select-files')}
                                    type='file'
                                    mutiple
                                    value={
                                        form?.values?.informationFiles
                                            ? form?.values?.informationFiles?.map(
                                                  (info) => info.url
                                              )
                                            : []
                                    }
                                    onChange={(files) => {
                                        const informationFiles = files.map((file) => ({
                                            url: file,
                                        }));

                                        form.setFieldValue('informationFiles', informationFiles);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    disabled={isLoadingSubmitForm}
                    color='default'
                    size='medium'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={() => {
                        form.resetForm();
                        onCloseModalDialogNewNoticeInformation();
                    }}
                >
                    {t('term.cancel')}
                </Button>
                <Button
                    disabled={isLoadingSubmitForm || !form.isValid}
                    color='primary'
                    size='medium'
                    variant='contained'
                    style={{
                        minWidth: '128px',
                        height: '100%',
                    }}
                    onClick={() => form.handleSubmit()}
                >
                    {!isLoadingSubmitForm ? (
                        t('term.save')
                    ) : (
                        <CircularProgress color='primary' size={25} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCrudNoticeInformationView;
