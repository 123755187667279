import { useQuery } from 'common/hooks/use-query.hook';
import documentTypeProviderRequests from 'clients/manager/document-type-provider.request';
import ExpiredDocumentsView from './expired-documents-view';

const ExpiredDocuments = () => {
    const { data: documentsAlmostExpiringOrExpired } = useQuery(
        documentTypeProviderRequests.listDocumentsAlmostExpiringOrExpired
    );

    return <ExpiredDocumentsView data={documentsAlmostExpiringOrExpired?.data ?? []} />;
};

export default ExpiredDocuments;
