import React from 'react';

import EmptyListView from './empty-list-view';

interface EmptyListProps {
    title: string;
    subtitle: string;
    href?: any;
}

const EmptyList: React.FC<EmptyListProps> = ({ title, subtitle, href }) => {
    return <EmptyListView title={title} subtitle={subtitle} href={href} />;
};

export default EmptyList;
