import { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import {
    ProcessDocumentsSteps,
    ProcessDocumentsStepsType,
} from 'clients/manager/interfaces/process-documents-steps.interface';
import LoadingButton from 'common/components/loading-button';
import SelectDocumentTemplate from '../select-document-template';
import UploadProcessDocument from '../upload-process-document';

interface UploadTypeViewProps extends FormikProps<Omit<ProcessDocumentsSteps, '_id'>> {
    viewSelected: number;
    disabledContinue: boolean;
    creatingDocumentStep: boolean;
    isEditingStep: boolean;
    disabledUploadDocument: boolean;
    createDocumentProcessStepFromFile: (file?: File) => void;
}

const UploadTypeView: FC<UploadTypeViewProps> = ({
    viewSelected,
    disabledContinue,
    creatingDocumentStep,
    isEditingStep,
    createDocumentProcessStepFromFile,
    disabledUploadDocument,
    ...rest
}) => {
    const { t } = useTranslation();

    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

    const handleItemAction = () => {
        if (viewSelected === 0) {
            return rest.submitForm();
        }

        if (viewSelected === 1 && (selectedFile || disabledUploadDocument)) {
            return createDocumentProcessStepFromFile(selectedFile);
        }
    };

    disabledContinue =
        disabledContinue || (viewSelected === 1 && !selectedFile && !disabledUploadDocument);

    return (
        <>
            {viewSelected === 0 && !isEditingStep && (
                <SelectDocumentTemplate
                    initialValue={rest.values.documentTemplateId}
                    onDocumentTemplateSelected={(documentTemplate) => {
                        if (!documentTemplate) {
                            return;
                        }

                        rest.setValues({
                            ...rest.values,
                            documentTemplateId: documentTemplate._id,
                            documentType: documentTemplate.type,
                            type: ProcessDocumentsStepsType.document,
                        });
                    }}
                    disabled={rest.values.documentType === undefined || isEditingStep}
                    filters={
                        rest.values.documentType !== undefined
                            ? {
                                  type: rest.values.documentType,
                              }
                            : undefined
                    }
                />
            )}
            {viewSelected === 1 && !isEditingStep && !disabledUploadDocument && (
                <UploadProcessDocument
                    onSelectedFile={(file) => {
                        // não está no formik então forço validação
                        rest.validateForm();
                        setSelectedFile(file);
                    }}
                    selectedFile={selectedFile}
                />
            )}

            <Box display='flex' justifyContent='flex-end' mt={2}>
                <LoadingButton
                    style={{ marginLeft: 8 }}
                    color='primary'
                    size='small'
                    variant='contained'
                    disabled={disabledContinue}
                    onClick={handleItemAction}
                    loading={
                        creatingDocumentStep
                            ? {
                                  text: `${t('term.creating')}..`,
                              }
                            : undefined
                    }
                >
                    {t('term.continue')}
                </LoadingButton>
            </Box>
        </>
    );
};

export default UploadTypeView;
