import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import {
    ListProviderInvoicesBody,
    ListProviderInvoicesResponse,
} from './interfaces/invoice.interface';

export interface CreateInvoiceData {
    auctionNoticeId: number;
    typeInvoice: string;
    price: number;
    planId: number;
}

const path = 'invoices';

const invoiceRequests = {
    listProviderInvoices: async (
        body?: ListProviderInvoicesBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<ListProviderInvoicesResponse>(
            managerApiInstance.post(`/${path}/listProviderInvoices`, body, config)
        ),
    createPostPaid: async (body: CreateInvoiceData) =>
        doRequest<{ status: string }>(managerApiInstance.post(`/${path}/createPostPaid`, body)),
};

export { invoiceRequests };
