import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userRequests } from 'clients/manager/user.requests';
import { User } from '../../../../../../common/interfaces/user.interface';
import ExpandedContentCard from '../../../expand-content-card';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { getTeamFormLabels } from '../../../../context/schema/team-form-schema';
import {
    MultipleSelectOrganizationUser,
    SelectOrganizationUser,
} from '../../../select-organization-user';
import ModalPreviousTeam from '../modal-previous-team';
import ProcessFormErrors from '../process-form-errors';
import { ProcessTeamProps } from './props';

const ProcessTeam: FC<ProcessTeamProps> = ({ disabledExpand }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [usersFiltered, setUsersFiltered] = useState<User[]>([]);

    const { processForm, auctionTypeRules, process } = useProcessFormContext();
    const { t } = useTranslation();

    const getOrganizationUsers = async (organizationUnitId: number) => {
        try {
            const response = await userRequests.listUsersBelongToOrganizationUnit({
                params: { organizationUnitId },
            });
            setUsers(response.data ?? []);
            setUsersFiltered(
                response.data.filter(
                    ({ id }) =>
                        id !== processForm?.values.presidentUserId &&
                        processForm?.values.competentAuthorityId
                )
            );
        } catch (error) {
            setUsers([]);
            setUsersFiltered([]);
        }
    };

    const handleChangeAuctioneer = debounce((userId: number | null) => {
        const result = users.filter(({ id }) => id !== userId);
        setUsersFiltered(result);
        processForm?.setFieldValue('presidentUserId', userId);
    }, 0);

    const handleChangeCompetentAuthority = debounce((userId: number | null) => {
        const result = users.filter(({ id }) => id !== userId);
        setUsersFiltered(result);
        processForm?.setFieldValue('competentAuthorityId', userId);
    }, 0);

    const handleChangeSupportTeam = debounce((userIds: number[]) => {
        processForm?.setFieldValue('committeeMembers', userIds.join(','));
    }, 0);

    const updateDatasWhenChangeUnit = () => {
        if (processForm?.values?.organizationUnitId || process?.organizationUnitId) {
            const unitId = processForm?.values?.organizationUnitId || process?.organizationUnitId;
            getOrganizationUsers(unitId as number);
            if (processForm?.values?.organizationUnitId !== process?.organizationUnitId) {
                handleChangeAuctioneer(null);
                handleChangeCompetentAuthority(null);
            }
        }
    };

    useEffect(() => {
        updateDatasWhenChangeUnit();
    }, [processForm?.values?.organizationUnitId]);

    const presidentUserLabel =
        auctionTypeRules?.generalSettings.comissao.operacional.descricao ?? '';
    const competentAuthorityLabel =
        auctionTypeRules?.generalSettings.comissao.executivo.descricao ?? '';
    const committeeMembersLabel =
        auctionTypeRules?.generalSettings.comissao.equipeApoio.descricao ?? '';

    const usersWithoutCommitteMembers = users.filter(
        ({ id }) => !processForm?.values.committeeMembers?.split(',').includes(String(id))
    );
    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <Box
                            width={1}
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Box display='flex'>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant='body1'>
                                        <b>{t('term.team')}</b>
                                    </Typography>
                                </Box>
                                <ProcessFormErrors
                                    partialProcessFormLabels={getTeamFormLabels({
                                        presidentUserLabel,
                                        committeeMembersLabel,
                                        competentAuthorityLabel,
                                    })}
                                />
                            </Box>
                            <ModalPreviousTeam
                                onConfirm={({
                                    presidentUserId,
                                    competentAuthorityId,
                                    committeeMembers,
                                }) => {
                                    processForm?.setValues({
                                        ...processForm?.values,
                                        presidentUserId,
                                        competentAuthorityId,
                                        committeeMembers: committeeMembers.join(','),
                                    });
                                }}
                            />
                        </Box>
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <SelectOrganizationUser
                                users={usersWithoutCommitteMembers}
                                label={presidentUserLabel}
                                value={processForm?.values.presidentUserId}
                                onChange={handleChangeAuctioneer}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <SelectOrganizationUser
                                users={usersWithoutCommitteMembers}
                                label={competentAuthorityLabel}
                                value={processForm?.values.competentAuthorityId}
                                onChange={handleChangeCompetentAuthority}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MultipleSelectOrganizationUser
                                users={usersFiltered}
                                label={committeeMembersLabel}
                                value={
                                    processForm?.values.committeeMembers
                                        ?.split(',')
                                        .map((userId) => Number(userId)) ?? []
                                }
                                onChange={handleChangeSupportTeam}
                            />
                        </Grid>
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessTeam;
