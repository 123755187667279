import { Box, Typography } from '@material-ui/core';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotificationApiError, addNotificationSuccess, sleep } from 'common/utils';
import { ProcessTransaction } from 'clients/manager/interfaces/process-transactions.interface';
import { processTransactionsRequests } from 'clients/manager/process-transactions.requests';
import LoadingButton from 'common/components/loading-button';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface ButtonCreateMessageProps {
    onCreateTransaction: (transaction: ProcessTransaction) => void;
}

const ButtonCreateMessage: FC<ButtonCreateMessageProps> = ({ onCreateTransaction }) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [message, setMessage] = useState<string | undefined>();
    const [creatingMessage, setCreatingMessage] = useState<boolean>(false);

    const { process } = useProcessFormContext();
    const { t } = useTranslation();

    const handleClickCreateMessage = () => setVisibleModal(true);

    const handleClickClose = () => {
        setMessage(undefined);
        setVisibleModal(false);
    };

    const handleSendMessage = async () => {
        if (!message?.length || !process?.id) {
            return;
        }

        setCreatingMessage(true);

        try {
            const response = await processTransactionsRequests.createProcessTransaction({
                processId: process.id,
                message,
            });

            if (!response?.data?.id) {
                throw new Error('Err');
            }

            onCreateTransaction(response.data);
            await sleep(200);
            setCreatingMessage(false);
            handleClickClose();
            return addNotificationSuccess();
        } catch (error) {
            setCreatingMessage(false);
            addNotificationApiError(error);
        }
    };

    return (
        <>
            <Modal
                position={ModalPosition.center}
                open={visibleModal}
                onClose={() => setVisibleModal(false)}
                header={<span>{t('info.new-message')}</span>}
            >
                <Box minWidth='400px'>
                    <Typography>{t('info.warning-message')}</Typography>
                    <Box mt={2}>
                        <TextField
                            fullWidth
                            color='primary'
                            defaultValue={message}
                            label={t('term.message')}
                            onChange={(event) => setMessage(event.target.value)}
                            variant='outlined'
                            multiline
                            autoFocus
                            minRows={3}
                            maxRows={8}
                        />
                    </Box>
                    <Box mt={2} display='flex' justifyContent='space-between'>
                        <Button size='small' variant='outlined' onClick={handleClickClose}>
                            {t('term.cancel')}
                        </Button>
                        <LoadingButton
                            disabled={!message?.length}
                            size='small'
                            variant='contained'
                            onClick={handleSendMessage}
                            {...(creatingMessage
                                ? {
                                      loading: {
                                          text: `${t('term.updating')}..`,
                                      },
                                  }
                                : {})}
                        >
                            {t('term.save')}
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
            <Button size='small' variant='contained' onClick={handleClickCreateMessage}>
                {t('info.add-message')}
            </Button>
        </>
    );
};

export default memo(ButtonCreateMessage);
