import { CircularProgress, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, MaskedTextField, TextField } from 'common/components';
import { CurrencyInput } from 'common/components/currency';
import { formatCnpj } from 'common/utils';

interface ProviderFinancialDataSignupViewProps {
    loading?: boolean;
    formFinancialProvider: any;
}

const ProviderFinancialDataSignupView: React.FC<ProviderFinancialDataSignupViewProps> = ({
    loading,
    formFinancialProvider,
}) => {
    const { t } = useTranslation();

    return (
        <Grid container>
            <form onSubmit={formFinancialProvider.handleSubmit}>
                <Grid container item spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label={t(
                                'sign-up.pages.stepper-provider.steps.financial.classification'
                            )}
                            name='classification'
                            value={formFinancialProvider.values.classification}
                            onChange={(e) => {
                                formFinancialProvider.setFieldValue(
                                    'classification',
                                    e.target.value
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            allowNegative
                            decimalSeparator=','
                            precision={2}
                            thousandSeparator='.'
                            name='workingCapital'
                            label={t('sign-up.pages.stepper-provider.steps.workingCapital')}
                            variant='outlined'
                            value={formFinancialProvider.values.workingCapital}
                            defaultValue={10000}
                            color='primary'
                            onChange={(_, value: string) => {
                                formFinancialProvider.setFieldValue(
                                    'workingCapital',
                                    value as String
                                );
                            }}
                            component={TextField}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            allowNegative
                            decimalSeparator=','
                            precision={2}
                            name='netWorth'
                            thousandSeparator='.'
                            label={t('sign-up.pages.stepper-provider.steps.netWorth')}
                            variant='outlined'
                            value={formFinancialProvider.values.netWorth}
                            defaultValue={10000}
                            color='primary'
                            onChange={(_, value: string) => {
                                formFinancialProvider.setFieldValue('netWorth', value);
                            }}
                            component={TextField}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 4 }}
                            label={t('sign-up.pages.stepper-provider.steps.exerciseYear')}
                            name='exerciseYear'
                            value={formFinancialProvider.values.exerciseYear}
                            onChange={(e) => {
                                formFinancialProvider.setFieldValue('exerciseYear', e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label={t('sign-up.pages.stepper-provider.steps.operationalResult')}
                            name='operationalResult'
                            value={formFinancialProvider.values.operationalResult}
                            onChange={(e) => {
                                formFinancialProvider.setFieldValue(
                                    'operationalResult',
                                    e.target.value
                                );
                            }}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <MaskedTextField
                            fullWidth
                            label={t(
                                'sign-up.pages.stepper-provider.steps.financial.docNumberReceiver'
                            )}
                            mask='99.999.999/9999-99'
                            name='docNumberReceiver'
                            value={formatCnpj(formFinancialProvider.values.docNumberReceiver || '')}
                            onChange={(e) => {
                                formFinancialProvider.setFieldValue(
                                    'docNumberReceiver',
                                    e.target.value
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <div className='stepper-nav'>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={loading}
                        startIcon={loading && <CircularProgress color='secondary' size={20} />}
                    >
                        {t('term.advance')}
                    </Button>
                </div>
            </form>
        </Grid>
    );
};

export default ProviderFinancialDataSignupView;
