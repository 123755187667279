import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Box } from '@material-ui/core';
import { MarkdownProps } from './markdown';
import { useStyles } from './markdown-style';

const MarkdownView: React.FC<MarkdownProps> = ({ value, variant }) => {
    const classes = useStyles(variant ?? 'body2');

    return (
        <Box className={classes[variant ?? 'body2']}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget='_blank'>
                {value}
            </ReactMarkdown>
        </Box>
    );
};

export default MarkdownView;
