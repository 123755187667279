import styled from 'styled-components';

export const BlankArea = styled.div<{ border?: boolean; minHeight?: boolean }>`
    width: 100%;
    height: calc(100% - 46px);
    border: ${({ border }) => border && '1px dashed var(--border-color)'};
    border-radius: ${({ border }) => border && '4px'};
    flex: ${({ minHeight }) => (minHeight ? 0 : 1)} 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    > h1,
    h2,
    h3 {
        text-align: center;
        line-height: normal;
        margin: 0;
    }

    > h1 {
        font-size: 18px;
        color: var(--primary-color);
    }

    > h2 {
        font-size: 14px;
        color: var(--text-light-color);
        font-weight: 300;
    }

    > h3 {
        color: var(--text-light-color);
        font-weight: 400;
    }
`;
