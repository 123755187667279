import React from 'react';
import { MarketplaceProviders } from 'modules/marketplace/interfaces/marketplace.interface';
import { ProviderValueStatus } from 'common/enums/provider-values-status.enum';
import ProvidersTableView from './providers-table-view';

interface ProvidersTableProps {
    providers?: MarketplaceProviders[];
    loading?: boolean;
    estimatedValue?: number;
    onDeclassify?: (providerId: number) => void;
    isDisqualifying?: boolean;
}

const ProvidersTable: React.FC<ProvidersTableProps> = ({
    onDeclassify,
    providers = [],
    loading = false,
    estimatedValue = 0,
    isDisqualifying = false,
}) => {
    const verifyProviderIsDisabled = (provider: MarketplaceProviders) => {
        const disabledStatus = [
            ProviderValueStatus.winner_disabled,
            ProviderValueStatus.winner_unclassified,
            ProviderValueStatus.not_covered,
            ProviderValueStatus.provider_declined,
        ];

        return disabledStatus.includes(provider.status);
    };

    const verifyProviderActionsIsDisabled = (provider: MarketplaceProviders) => {
        return provider.status !== ProviderValueStatus.winner;
    };

    return (
        <ProvidersTableView
            providers={providers}
            loading={loading}
            estimatedValue={estimatedValue}
            onDeclassify={onDeclassify}
            isDisqualifying={isDisqualifying}
            verifyProviderIsDisabled={verifyProviderIsDisabled}
            verifyProviderActionsIsDisabled={verifyProviderActionsIsDisabled}
        />
    );
};

export default ProvidersTable;
