import { ContractStatus } from 'clients/manager/interfaces/contract.interface';
import i18n from 'common/i18n';

const getTranslation = (contractStatus?: ContractStatus) => {
    return i18n.t(`contract-status.${contractStatus}`, { ns: 'getters' });
};

const getContractStatus = (contractStatus?: ContractStatus): string => {
    const mapper = {
        [ContractStatus.concluded]: getTranslation(ContractStatus.concluded),
        [ContractStatus.draft]: getTranslation(ContractStatus.draft),
        [ContractStatus.generated]: getTranslation(ContractStatus.generated),
        [ContractStatus.obsolete]: getTranslation(ContractStatus.obsolete),
        [ContractStatus.signatures]: getTranslation(ContractStatus.signatures),
    };

    return contractStatus ? mapper?.[contractStatus] : '';
};

export { getContractStatus };
