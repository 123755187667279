import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType, hasPermissionToExecute } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import { SearchOrganizationUsersContextProvider } from './pages/search-organization-users/context/SearchOrganizationUsersContext';
import SearchOrganizationUsers from './pages/search-organization-users';
import EditOrganizationUsers from './pages/edit-organization-users';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.organization]) &&
                    hasPermissionToExecute('listarUsuariosDaOrganizacao')
                }
                exact
                path={`${match.path}/novo`}
                component={() => <EditOrganizationUsers />}
            />
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.organization]) &&
                    hasPermissionToExecute('listarUsuariosDaOrganizacao')
                }
                exact
                path={`${match.path}/:country/:cpf`}
                component={() => <EditOrganizationUsers />}
            />
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.organization]) &&
                    hasPermissionToExecute('listarUsuariosDaOrganizacao')
                }
                exact
                path={`${match.path}/`}
                component={() => (
                    <SearchOrganizationUsersContextProvider>
                        <SearchOrganizationUsers />
                    </SearchOrganizationUsersContextProvider>
                )}
            />
        </Switch>
    );
};

const GroupOrganizationUsersRoutes: JSX.Element[] = [
    <Route
        path='/usuarios-organizacao'
        key='/usuarios-organizacao'
        render={() => <ChildRoutes />}
    />,
];

export default GroupOrganizationUsersRoutes;
