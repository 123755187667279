import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { CONSTANTS } from 'common/constants';
import SuspendedOptions from '../suspended-options';
import { SideMenuProps } from './props';
import { OptionMenu } from './menu.interface';
import getOptions from './options';
import { AppState } from '../../../../../../../store';

const MINIMIZED_WIDTH = 1380;

export interface OptionSelected {
    menu: OptionMenu;
}

const SideMenu: FC<SideMenuProps> = ({
    currentAccessType,
    visibleMobileMenu,
    setVisibleMobileMenu,
}) => {
    const { authUser, authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const isMobileMenu = document.documentElement.clientWidth < CONSTANTS.mobileWidth;

    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const [minimizedMenu, setMinimizedMenu] = useState(
        document.documentElement.clientWidth < MINIMIZED_WIDTH
    );
    const [expandedMenu, setExpandedMenu] = useState(false);
    const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);

    const history = useHistory();
    const options = getOptions(
        (path: string) => apiRedirectTo(backofficeRedirectUrl, path),
        (path: string) => history.push(path),
        currentAccessType,
        authUserTokenData?.isSystemAdmin ?? false
    );

    const resize = useCallback(() => {
        setWindowWidth(window.innerWidth);

        if (window.innerWidth < MINIMIZED_WIDTH) {
            setMinimizedMenu(true);
        } else {
            setMinimizedMenu(false);
        }
    }, [windowWidth]);

    UseWindowEvent('resize', () => resize(), [resize]);

    if (!visibleMobileMenu && isMobileMenu) {
        return null;
    }

    return authUser ? (
        <>
            <SuspendedOptions
                minimized={minimizedMenu}
                expanded={expandedMenu}
                menuTypeView={currentAccessType}
                options={options}
                mobile={visibleMobileMenu}
                onExpanded={(value) => {
                    if (minimizedMenu) {
                        setExpandedMenu(value);
                    }
                }}
                onClose={() => setVisibleMobileMenu(false)}
            />
            {
                // elemento que surge só para evitar um pulo na interface, gambiarra..
                !isMobileMenu && minimizedMenu && expandedMenu && <div style={{ width: '70px' }} />
            }
        </>
    ) : null;
};

export default SideMenu;
