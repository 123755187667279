import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Modal, ModalPosition } from 'common/components';
import CreatePriceListForm from 'modules/priceBank/search/components/create-price-list-form';
import { ModalEditPriceListProps } from './props';

const ModalEditPriceList: FC<ModalEditPriceListProps> = ({
    opened,
    onClose,
    onPriceListChanged,
    priceList,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            onClose={onClose}
            open={opened}
            header={<span>{t('mkp.user-price-list.edit-list-price')}</span>}
            position={ModalPosition.center}
        >
            <Box p={2}>
                <CreatePriceListForm
                    action='update'
                    initialValues={{ ...priceList }}
                    onCancel={onClose}
                    onPriceListChanged={(priceListChanged) => {
                        if (!priceListChanged) {
                            return;
                        }

                        onPriceListChanged(priceListChanged);
                    }}
                />
            </Box>
        </Modal>
    );
};

export default ModalEditPriceList;
