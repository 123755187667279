import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { AxiosRequestConfig } from 'axios';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import {
    DocumentType,
    DocumentTypeProviderSegments,
    DocumentsAndCategoryFormated,
    DocumentTypesProvidersHistoric,
    DocumentTypesProviders,
} from '../../common/interfaces/document-type-provider.interface';
import { NoDataGenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { CompleteSupplyCategoriesProvidersSettingsUpdate } from './interfaces/supply-categories-providers.interface';

export interface ListDocumentTypesProviderResponse {
    documents: DocumentsAndCategoryFormated[];
    segments?: DocumentTypeProviderSegments[];
}

export interface DocumentsAlmostExpiringOrExpired {
    id: number;
    fileName: string;
    dueDate: string;
    documentType?: {
        id?: number;
        documentName?: string;
        documentDescription?: string;
    };
}

interface ListDocumentsAlmostExpiringOrExpiredResponse {
    data: DocumentsAlmostExpiringOrExpired[];
    status: string;
}

const BASE_PATH = 'document-type-provider';

const listDocumentTypesProvider = (documentStage: DocumentType, providerId: number) =>
    doRequest<PlainGenericResponse<ListDocumentTypesProviderResponse>>(
        managerApiInstance.post(`${BASE_PATH}/listDocumentTypesProvider`, {
            documentStage,
            providerId,
        })
    );

const listDocumentTypeProviderHistory = (providerId: number) =>
    doRequest<DocumentTypesProvidersHistoric[]>(
        managerApiInstance.post(`${BASE_PATH}/listDocumentTypeProviderHistory`, {
            providerId,
        })
    );

const uploadDocumentTypeProvider = (
    file: File,
    documentTypeId: number,
    supplyCategoryIdSingle?: number,
    providerId?: number
) => {
    const formData = new FormData();
    const newFileName = getNewFileName(file);
    formData.append('file', file, newFileName);
    formData.append('documentTypeId', `${documentTypeId}`);
    formData.append('supplyCategoryIdSingle', `${supplyCategoryIdSingle ?? '0'}`);

    if (providerId) formData.append('providerId', `${providerId}`);

    return managerApiUploadFileInstance.post(`${BASE_PATH}/uploadDocumentTypeProvider`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const createDocumentTypeProviderDeclaration = (
    declarationAccepted: boolean,
    documentTypeId: number
) =>
    doRequest<NoDataGenericResponse>(
        managerApiInstance.post(`${BASE_PATH}/createDocumentTypeProviderDeclaration`, {
            declarationAccepted,
            documentTypeId,
        })
    );

const listDocumentsAlmostExpiringOrExpired = (config?: AxiosRequestConfig<any>) =>
    doRequest<ListDocumentsAlmostExpiringOrExpiredResponse>(
        managerApiInstance.post(
            `${BASE_PATH}/listDocumentsAlmostExpiringOrExpired`,
            undefined,
            config
        )
    );

const doSaveDocumentTypeProvider = (body: DocumentTypesProviders) =>
    doRequest<any>(managerApiInstance.post(`${BASE_PATH}/doSaveDocumentTypeProvider`, body));

const doSaveSupplyCategoryProvider = (body: CompleteSupplyCategoriesProvidersSettingsUpdate) =>
    doRequest<any>(managerApiInstance.post(`${BASE_PATH}/doSaveSupplyCategoryProvider`, body));

const documentTypeProviderRequests = {
    listDocumentTypesProvider,
    uploadDocumentTypeProvider,
    createDocumentTypeProviderDeclaration,
    listDocumentsAlmostExpiringOrExpired,
    listDocumentTypeProviderHistory,
    doSaveDocumentTypeProvider,
    doSaveSupplyCategoryProvider,
};

export default documentTypeProviderRequests;
