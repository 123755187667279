import { Autocomplete } from '@material-ui/lab';
import { SupplyCategory } from 'clients/manager/interfaces/supply-categories.interface';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';

interface SupplyCategoryAutocompleteViewProps {
    supplyCategories: SupplyCategory[];
    handleOnChange: (category: SupplyCategory) => void;
    label?: string;
    value?: SupplyCategory;
}

const SupplyCategoryAutocompleteView: React.FC<SupplyCategoryAutocompleteViewProps> = ({
    supplyCategories,
    handleOnChange,
    label,
    value,
}) => {
    const { t } = useTranslation();

    return (
        <Autocomplete
            options={supplyCategories}
            clearOnEscape
            disableClearable
            value={value}
            getOptionLabel={(option) => {
                return `${option.externalId ? `${option.externalId} - ` : ''}${
                    option.categoryName
                }`;
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label || t('info.search-segments')}
                    variant='outlined'
                />
            )}
            onChange={(_, category) => handleOnChange(category)}
        />
    );
};

export default SupplyCategoryAutocompleteView;
