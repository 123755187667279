import { memo } from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { LinkBreadCrumb } from './styled';

interface BreadcrumbsComponentProps {
    links: Array<{
        title: string;
        url: string;
        active?: boolean;
    }>;
}

const BreadcrumbsComponent = ({ links }: BreadcrumbsComponentProps) => (
    <Breadcrumbs color='primary'>
        {links.map((link) => (
            <LinkBreadCrumb key={`${link.url}-${link.title}`} to={link.url} active={link.active}>
                <Typography style={{ fontWeight: `${link.active ? '' : 'bold'}` }}>
                    {link.title}
                </Typography>
            </LinkBreadCrumb>
        ))}
    </Breadcrumbs>
);

export default memo(BreadcrumbsComponent);
