import React from 'react';
import { nanoid } from 'nanoid';
import { styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Skeleton from '@material-ui/lab/Skeleton';

type CardNoticeInformationSkeletonProps = {
    isLoading: boolean;
};

const CardContentStyled = styled(CardContent)({
    flex: 10,
    padding: 16,
    maxHeight: '320px',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'var(--background-hover-color)',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--scrollbar-color)',
    },
});

const CardNoticeInformationSkeleton = (props: CardNoticeInformationSkeletonProps) => {
    const { isLoading } = props;

    return (
        <Card
            elevation={0}
            variant='outlined'
            style={{
                position: 'relative',
                minHeight: 512,
                height: '100%',
                borderRadius: 8,
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {isLoading && (
                <Skeleton
                    variant='rect'
                    height={32}
                    width={80}
                    style={{ position: 'absolute', top: 12, right: 12, borderRadius: 16 }}
                />
            )}
            {isLoading && <Skeleton variant='rect' height={140} width='100%' />}
            <CardContentStyled>
                {isLoading && (
                    <>
                        <Skeleton variant='text' height={32} width='100%' />
                        <Skeleton variant='text' height={32} width='75%' />
                    </>
                )}
                {isLoading &&
                    [...Array(8)].map((_, index) => {
                        if (index + 1 === 8) {
                            return (
                                <Skeleton key={nanoid()} variant='text' height={22} width='60%' />
                            );
                        }
                        return <Skeleton key={nanoid()} variant='text' height={22} width='100%' />;
                    })}
            </CardContentStyled>
            <CardActions
                style={{
                    padding: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box>{isLoading && <Skeleton variant='text' height={56} width={128} />}</Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}
                >
                    {isLoading && <Skeleton variant='text' height={56} width={128} />}
                </Box>
            </CardActions>
        </Card>
    );
};

export default CardNoticeInformationSkeleton;
