import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { OrganizationsUsersRequests } from 'modules/organization-users/services/organizations-users.requests';
import { FormikHelpers } from 'formik';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { useHistory, useParams } from 'react-router';
import { AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { userRequests } from 'clients/manager/user.requests';
import { setAuthUser } from 'store/user/user.actions';
import { EditOrganizationUsersFormValues } from './components/edit-organization-users-form/edit-organization-users-form-schema';
import EditOrganizationUsersView from './edit-organization-users-view';

interface EditOrganizationUsersParams {
    cpf?: string;
    country?: string;
}

const EditOrganizationUsers = () => {
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const history = useHistory();
    const params = useParams<EditOrganizationUsersParams>();

    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const dispatch = useDispatch();

    const onSubmit = async (
        values: EditOrganizationUsersFormValues,
        formikHelpers: FormikHelpers<EditOrganizationUsersFormValues>
    ) => {
        try {
            const { authorizationProfile, countryCode, cpf } = values;

            const organization = values?.organization;
            const organizationUnits = values?.organizationUnits;

            await OrganizationsUsersRequests.doLinkUserInOrganizationOrUnit({
                authorizationProfile,
                countryCode,
                cpf,
                organization: {
                    isAdmin: organization?.isAdmin ?? false,
                    authorizationGroupIds: organization?.authorizationGroups?.map(
                        (authorizationGroup) => authorizationGroup.groupId
                    ),
                },
                organizationUnits: organizationUnits
                    ?.filter((organizationUnit) => !!organizationUnit?.organizationUnitId)
                    ?.map((organizationUnit) => ({
                        organizationUnitId: organizationUnit.organizationUnitId,
                        isAdmin: organizationUnit?.isAdmin ?? false,
                        authorizationGroupIds: organizationUnit.authorizationGroups?.map(
                            (authorizationGroup) => authorizationGroup.groupId
                        ),
                    })) as any,
            });

            if (values?.id === authUser?.id) {
                const user = await userRequests.getAuthenticatedUser();

                if (user) {
                    dispatch(setAuthUser({ user }));
                }
            }

            history.push(`/usuarios-organizacao`);

            addNotificationSuccess();
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            formikHelpers.setSubmitting(false);
        }
    };

    useEffect(() => {
        setTitle(t('organization-users.pages.components.users-unit'));
    }, []);

    return (
        <EditOrganizationUsersView {...{ onSubmit, country: params?.country, cpf: params?.cpf }} />
    );
};

export default EditOrganizationUsers;
