import {
    CircularProgress,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from 'common/components';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { t } from 'i18next';
import {
    ListMaterialData,
    ListMaterialResponse,
} from 'modules/material/interfaces/material.interface';

const rowsPerPageOptions = [10, 20];

interface SearchMaterialTableViewProps {
    handleChangeFilter: (event: React.KeyboardEvent) => void;
    handleEdit: (id: string) => void;
    handleRemove: (row: ListMaterialData) => void;
    queryParams: UsePaginatedQueryResponse<ListMaterialResponse>;
}

const SearchMaterialTableView: React.FC<SearchMaterialTableViewProps> = ({
    handleChangeFilter,
    handleEdit,
    handleRemove,
    queryParams,
}) => {
    const { data, loading, totalCount, page, limit, setLimit, setPage } = queryParams;

    const materials = data?.data;

    return (
        <>
            <TextField
                label={t('material.pages.search-material')}
                placeholder={t('message.press-enter-to-search')}
                fullWidth
                onKeyPress={handleChangeFilter}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('term.code')}</TableCell>
                        <TableCell>{t('term.name')}</TableCell>
                        <TableCell>{t('term.catalog')}</TableCell>
                        <TableCell>{t('term.actions')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {materials &&
                        materials.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>
                                    <Tooltip title={row.description}>
                                        <span>{row.name}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{row.catalog.name}</TableCell>
                                <TableCell align='center' padding='none' style={{ width: 120 }}>
                                    <>
                                        <IconButton onClick={() => handleEdit(row.id)}>
                                            <Tooltip title={t('term.edit') as string}>
                                                <EditIcon fontSize='small' />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={() => handleRemove(row)}>
                                            <Tooltip title={t('term.remove') as string}>
                                                <DeleteIcon fontSize='small' />
                                            </Tooltip>
                                        </IconButton>
                                    </>
                                </TableCell>
                            </TableRow>
                        ))}
                    {!loading && !materials?.length && (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={5} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={totalCount}
                page={page}
                onPageChange={(_e, page) => setPage(page)}
                rowsPerPage={limit || rowsPerPageOptions[0]}
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                onRowsPerPageChange={(event) =>
                    setLimit(Number(event.target.value) ?? rowsPerPageOptions[0])
                }
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </>
    );
};

export default SearchMaterialTableView;
