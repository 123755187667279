import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Link,
} from '@material-ui/core';
import { providerRequests } from 'clients/manager/provider.requests';
import { addNotificationError } from 'common/utils';
import { usePlatformContext } from 'routes/platform.context';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';

const PageConfirmationTerms = () => {
    const { t } = useTranslation();
    const {
        platform,
        openConfirmationTerms: [open, setOpen],
    } = usePlatformContext();

    const acceptTerms = async () => {
        try {
            await providerRequests.doAcceptTerms();
            setOpen(false);
        } catch (error) {
            addNotificationError({
                title: '',
                message: t('error.accept-terms'),
            });
            setOpen(false);
        }
    };

    const termsLink =
        platform?.termsOfService ??
        'https://licitar.digital/regulamento-sistema-de-compras-digital-licitar-digital/';

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{t('info.message-change-terms-title')}</DialogTitle>
            <DialogContent>
                <Typography variant='body2'>{t('info.message-change-terms-1')}</Typography>
                <br />
                <Link href={termsLink} target='_blank'>
                    {t('info.message-change-terms-link')}
                </Link>
            </DialogContent>
            <DialogActions>
                <Button onClick={acceptTerms} size='small' variant='contained'>
                    {t('info.message-change-terms-confirm-button')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PageConfirmationTerms;
