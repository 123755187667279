import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: 0,

            '& .MuiListItemText-multiline': {
                padding: 0,
                margin: 0,
            },
        },
        content: {
            minWidth: 400,
            maxWidth: 900,
            padding: theme.spacing(2),
            maxHeight: 500,
        },
        title: {
            flexGrow: 1,
            color: theme.palette.text.hint,
            fontSize: 18,
        },
        gridContent: {
            padding: theme.spacing(1),
        },
        icon: {
            padding: theme.spacing(1),
        },
        selectorTextPrimary: {
            color: theme.palette.text.primary,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 14,
        },
        selectorIconPrimary: {
            color: theme.palette.text.primary,
            fontSize: 20,
        },
        selectorTextSecondary: {
            color: theme.palette.grey['700'],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 12,
        },
        selector: {
            maxWidth: '300px',
        },
        optionItemHeader: {
            fontWeight: 600,
        },
        optionItem: {
            padding: '2px 0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',

            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        optionItemIcon: {
            width: 15,
            height: 15,
            marginLeft: 5,
        },
    })
);
