import { Grid } from '@material-ui/core';
import { memo } from 'react';
import MessagesList from './components/messages-list';

const ProcessMessagesData = () => (
    <Grid container>
        <Grid item xs={12}>
            <MessagesList />
        </Grid>
    </Grid>
);

export default memo(ProcessMessagesData);
