import { userRequests } from 'clients/manager/user.requests';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { FormikHelpers, useFormik } from 'formik';
import YupValidator from 'common/utils/yup-extended.utils';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { setAuthUser } from 'store/user/user.actions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileGeneralDataView from './profile-general-data-view';

export interface ProfileGeneralDataValues {
    name: string;
    cpf: string;
    email: string;
    phone: string;
    language: string;
    verificationCode: string;
}

const ProfileGeneralData = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { authUser } = useSelector((state: AppState) => state.authUserState);

    const [openCodeValidator, setOpenCodeValidator] = useState(false);

    const initialValues = {
        name: authUser?.name ?? '',
        cpf: authUser?.cpf ?? '',
        email: authUser?.email ?? '',
        phone: authUser?.phone ?? '',
        language: authUser?.language ?? '',
        verificationCode: '',
    };

    const validationSchema = YupValidator.object().shape({
        name: YupValidator.string()
            .required(t('required-field', { ns: 'validation' }))
            .max(100),
        email: YupValidator.string()
            .email(t('please-enter-a-valid-email', { ns: 'validation' }))
            .required(t('required-field', { ns: 'validation' }))
            .max(100),
        phone: YupValidator.string().required(t('required-field', { ns: 'validation' })),
        verificationCode: YupValidator.string().optional(),
    });

    const onSubmit = async (
        values: ProfileGeneralDataValues,
        { setSubmitting, setValues }: FormikHelpers<ProfileGeneralDataValues>
    ) => {
        try {
            if (values.email !== authUser?.email) {
                if (!values.verificationCode) {
                    setOpenCodeValidator(true);
                    return;
                }

                await userRequests.existingUserVerificationCode({
                    email: values.email,
                    verificationCode: values.verificationCode,
                });
            }

            const user = await userRequests.updateAuthenticatedUser({
                name: values.name,
                cpf: values.cpf,
                email: values.email,
                phone: values.phone,
                language: values.language,
            });

            if (user) {
                i18n.changeLanguage(user.language);
                setValues((values) => ({ ...values, verificationCode: '' }));
                dispatch(setAuthUser({ user }));
            }
            addNotificationSuccess();
        } catch (err) {
            addNotificationApiError(err);
        } finally {
            setSubmitting(false);
        }
    };

    const handleOnCloseValidationModal = () => {
        setOpenCodeValidator(false);
    };

    const form = useFormik<ProfileGeneralDataValues>({ initialValues, validationSchema, onSubmit });

    return (
        <ProfileGeneralDataView
            form={form}
            openCodeValidator={openCodeValidator}
            onCloseValidationModal={handleOnCloseValidationModal}
        />
    );
};

export default ProfileGeneralData;
