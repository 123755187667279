import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userRequests } from '../../../../../clients/manager/user.requests';
import { User } from '../../../../../common/interfaces/user.interface';

interface SelectOperatorProps {
    value: number | undefined | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, user: User) => void;
    omitAllOption?: boolean;
}

const SelectOperator: FC<SelectOperatorProps> = ({ value, onChange, omitAllOption }) => {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const request = async () => {
            try {
                const response = await userRequests.listAdminUsers();
                setUsers(response?.data ?? []);
            } catch (error) {}
        };

        request();
    }, []);

    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='operatorId'
            label={t('term.user')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(
                    event,
                    users.find((user) => user.id === Number(event.target.value)) as User
                )
            }
            variant='outlined'
            select
            value={value}
        >
            {!omitAllOption && <MenuItem value={undefined}>{t('term.all')}</MenuItem>}
            {users.map((user) => (
                <MenuItem value={user.id}>{user.name}</MenuItem>
            ))}
        </TextField>
    );
};

export default SelectOperator;
