import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    getBackofficeRedirectUrl,
} from 'common/utils';
import { processInvitesRequests } from 'clients/manager/process-invites.requests';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../context/process-form.context';
import ButtonSendInviteView from './button-send-invite-view';

const ButtonSendInvite = () => {
    const { t } = useTranslation();
    const { processForm } = useProcessFormContext();
    const { processLotsForm } = useProcessLotsFormContext();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const [loading, setLoading] = useState<boolean>(false);

    const notIsDraftProcess = !!processForm?.values.id;
    const hasAtLeastOneLotCreated =
        processLotsForm.values.lots.filter((lot) => !!lot.id).length > 0;

    const canSendInvites =
        notIsDraftProcess && hasAtLeastOneLotCreated && processForm?.values?.participantId;

    const sendInvite = useCallback(async () => {
        if (processUtils.notUnenforceabilityProcess(processForm?.values)) {
            return;
        }

        try {
            const validInvites = await processInvitesRequests.getProcessInvitesVerification(
                Number(processForm?.values?.id),
                false
            );

            if (validInvites.data === null) {
                addNotificationError();
            }

            const response = await processInvitesRequests.doSendInvites(
                Number(processForm?.values?.id),
                String(processForm?.values?.participantDocument),
                String(backofficeRedirectUrl)
            );
            if (response.data) {
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('info.invitation-sended'),
                });
                processForm?.setFieldValue('lastSendAt', response.data.lastSendAt);
                return;
            }
            addNotificationError({
                title: t('term.error'),
                message: t('info.invitation-sended-error'),
            });
        } catch (err) {
            addNotificationApiError(err);
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <ButtonSendInviteView
            onClick={sendInvite}
            disabled={!canSendInvites}
            sendingInvite={loading}
            buttonText={
                processForm?.values?.lastSendAt ? t('term.resend-invite') : t('term.send-invite')
            }
        />
    );
};

export default ButtonSendInvite;
