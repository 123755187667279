import React, { FC, useEffect } from 'react';
import { Box, FormControl, Grid } from '@material-ui/core';
import { Button, TextField, Modal, ModalPosition } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DocumentTypesProviders } from 'common/interfaces/document-type-provider.interface';
import documentTypeProviderRequests from 'clients/manager/document-type-provider.request';
import { useParams } from 'react-router';
import { addNotificationApiError, addNotificationSuccess, getLanguage } from 'common/utils';
import { UserLanguages } from 'common/interfaces/user.interface';
import moment from 'moment';
import * as momentConfig from 'moment';
import MomentUtils from '@date-io/moment';
import momentDurationFormatSetup from 'moment-duration-format';
import SelectIsPublic from '../select-ispublic';
import SelectDocumentsStatus from '../select-status-documents';
import { ModalEditDocumentsProps } from './props';

const ModalEditDocuments: FC<ModalEditDocumentsProps> = ({
    onClose,
    DocumentTypesProviders,
    documentName,
    onUpdateValues,
}) => {
    const { t } = useTranslation();
    const { idProvider } = useParams<{ idProvider: string }>();

    useEffect(() => {
        moment.locale('pt-br');
        momentConfig.tz.setDefault('America/Sao_Paulo');
        momentDurationFormatSetup(momentConfig);
    }, []);

    let initialIdProvider = DocumentTypesProviders.providerId;
    if (!initialIdProvider) initialIdProvider = Number(idProvider);

    const form = useFormik<DocumentTypesProviders>({
        initialValues: {
            id: DocumentTypesProviders.id,
            providerId: initialIdProvider,
            evaluatingStatus: DocumentTypesProviders.evaluatingStatus,
            isDocumentProtected: DocumentTypesProviders.isDocumentProtected,
            dueDate: DocumentTypesProviders.dueDate ?? null,
            justificationOfFailure: DocumentTypesProviders.justificationOfFailure,
        },
        validate: (values) => {
            const errors: Partial<DocumentTypesProviders> = {};

            if (values.dueDate && !moment(values.dueDate, 'YYYY-MM-DD').isValid()) {
                errors.dueDate = t('info.invalid-date');
            } else if (
                values.dueDate &&
                (moment(values.dueDate).year() < 1900 || moment(values.dueDate).year() > 2099)
            ) {
                errors.dueDate = t('error.max-date-exceeded');
            }

            return errors;
        },
        onSubmit: async (values) => {
            if (form.isValid && Object.keys(form.errors).length === 0) {
                try {
                    values.dueDate = values.dueDate
                        ? moment(values.dueDate).format('YYYY-MM-DD')
                        : '';
                    const response = await documentTypeProviderRequests.doSaveDocumentTypeProvider(
                        values
                    );
                    if (!response?.status) {
                        throw new Error(t('error.response.try-again'));
                    }

                    onUpdateValues?.(values);
                    onClose();
                    addNotificationSuccess();
                } catch (error) {
                    addNotificationApiError(error);
                }
            } else {
                addNotificationApiError(t('error.message-docs'));
            }
        },
    });

    const handleChangeDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
        form?.setFieldValue(event.target.name, event.target.value);
    };

    const shortenText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) {
            return text;
        }
        return `${text.slice(0, maxLength)}...`;
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={(event: any) => {
                event.stopPropagation();
                onClose();
            }}
            header={<span title={documentName}>{shortenText(documentName, 60)}</span>}
        >
            <Box width='600px'>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={6}>
                        <SelectDocumentsStatus
                            onChange={handleChangeDefault}
                            value={form.values.evaluatingStatus}
                            omitAllOption
                        />
                    </Grid>
                    <Grid item md={6} sm={4}>
                        <FormControl fullWidth variant='outlined'>
                            <MuiPickersUtilsProvider
                                libInstance={momentConfig}
                                utils={MomentUtils}
                                locale={
                                    getLanguage() === UserLanguages['pt-BR'] ? 'pt-br' : undefined
                                }
                            >
                                <KeyboardDatePicker
                                    fullWidth
                                    color='primary'
                                    inputVariant='outlined'
                                    label={t('term.date')}
                                    name='dueDate'
                                    value={form.values.dueDate || null}
                                    format='DD/MM/YYYY'
                                    okLabel={t('term.confirm')}
                                    cancelLabel={t('term.cancel')}
                                    onChange={(date) => form.setFieldValue('dueDate', date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={t('info.invalid-format', {
                                        ns: 'validation',
                                    })}
                                    invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                    maxDateMessage={t('info.invalid-max-date')}
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} sm={5}>
                        <TextField
                            fullWidth
                            label={t('term.justification-optional')}
                            name='justificationOfFailure'
                            value={form.values.justificationOfFailure}
                            multiline
                            rows={4}
                            onChange={handleChangeDefault}
                        />
                    </Grid>
                    <Grid item md={12} sm={6}>
                        <SelectIsPublic
                            onChange={handleChangeDefault}
                            value={form.values.isDocumentProtected}
                            omitAllOption
                        />
                    </Grid>
                </Grid>
                <Box mt={2} width={1}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' size='small' onClick={onClose}>
                                {t('term.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => form.submitForm()}
                            >
                                {t('term.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalEditDocuments;
