import {
    Box,
    Grid,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Input,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Button } from 'common/components';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import { debounce, pick } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AuctionTechnicalCriteria,
    technicalCriteriaRequests,
} from 'clients/manager/technical-criteria.requests';
import Yup from 'common/utils/yup-extended.utils';
import LoadingButton from 'common/components/loading-button';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { AddIcon, DeleteIcon } from 'common/icons';
import { processActions } from 'modules/process/process-actions';
import { nanoid } from 'nanoid';
import md5 from 'md5';
import ErrorStatus from '../../../error-status';
import ExpandedContentCard from '../../../expand-content-card';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { ProcessTechnicalCriteriaProps } from './props';
import { useStyles } from './styles';

const ProcessTechnicalCriteria: FC<ProcessTechnicalCriteriaProps> = ({
    disabledExpand,
    isTechniqueAndPrice = false,
}) => {
    const [technicalCriterias, setTechnicalCriterias] = useState<AuctionTechnicalCriteria[]>([]);

    const classes = useStyles();
    const { t } = useTranslation();
    const { process } = useProcessFormContext();

    const getRandomHighNumber = () => {
        const max = Number.MAX_SAFE_INTEGER;
        return '';
    };
    const defaultCriteria = {
        title: '',
        description: '',
        maxPoints: 0,
        auctionNoticeId: process?.id,
    };

    const listTechnicalCriterias = async () => {
        if (!process) {
            return;
        }

        const response = await technicalCriteriaRequests.listTechnicalCriteria({
            params: {
                auctionId: process.id,
            },
        });

        setTechnicalCriterias(response?.data ?? []);
    };

    useEffect(() => {
        listTechnicalCriterias();
    }, []);

    const getInitialValues = (): {
        temporaryId: string;
        auctionNoticeId: number;
        title: string;
        description: string;
        maxPoints: number;
    }[] => {
        if (!process) {
            return [];
        }

        if (technicalCriterias.length) {
            return technicalCriterias.map((criteria) => ({
                ...pick(criteria, ['auctionNoticeId', 'title', 'description', 'maxPoints']),
                temporaryId: md5(criteria.id),
            }));
        }

        return [
            {
                ...defaultCriteria,
                temporaryId: md5('0'),
                auctionNoticeId: process.id,
            },
        ];
    };

    const form = useFormik({
        initialValues: getInitialValues(),
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: Yup.array().of(
            Yup.object({
                auctionNoticeId: Yup.number().required(t('required-field', { ns: 'validation' })),
                title: Yup.string().required(t('required-field', { ns: 'validation' })),
                description: Yup.string().required(t('required-field', { ns: 'validation' })),
                maxPoints: Yup.number().required(t('required-field', { ns: 'validation' })),
            })
        ),
        onSubmit: async (values) => {
            if (!process) return;
            try {
                await technicalCriteriaRequests.bulkCreateTechnicalCriteria({
                    technicalCriteriaDto: values,
                    processId: process.id,
                });
                addNotificationSuccess({
                    message: t('info.updated.data-success'),
                });
                listTechnicalCriterias();
            } catch (err) {
                addNotificationApiError(err);
            }
        },
    });

    const maxPointsByCriteria = isTechniqueAndPrice ? 70 : 100;
    const criteriaSumLabel = t(
        isTechniqueAndPrice ? 'info.criteria-sum-lower-or-equal' : 'info.criteria-summ',
        { points: maxPointsByCriteria }
    );

    const getSummPoints = () =>
        form.values
            .filter((criteria) => !Number.isNaN(criteria.maxPoints))
            .reduce((acc, curr) => acc + curr.maxPoints, 0);

    const onChange = debounce((value: any, field: string, position: number) => {
        form.setValues([
            ...form.values.slice(0, position),
            {
                ...form.values[position],
                [field]: value,
            },
            ...form.values.slice(position + 1),
        ]);
    }, 200);

    const createTechnicalCriteria = () => {
        if (!process) {
            return;
        }

        form.setValues([
            ...form.values,
            {
                ...defaultCriteria,
                temporaryId: md5(new Date().toISOString()),
                auctionNoticeId: process.id,
            },
        ]);
    };

    const deleteTechnicalCriteria = (criteriaIndex: string) => {
        const newValues = form.values.filter((criteria) => criteria.temporaryId !== criteriaIndex);

        form.setValues(newValues);
    };

    const techniqueAndPriceValidation = getSummPoints() <= maxPointsByCriteria;
    const techniqueValidation = getSummPoints() === maxPointsByCriteria;

    const formIsValid =
        form.isValid && (isTechniqueAndPrice ? techniqueAndPriceValidation : techniqueValidation);

    const getFormErrorsLabels = () => {
        const labels = {
            title: t('term.title'),
            description: t('term.description'),
            maxPoints: t('term.max-points'),
        };

        const toRenderItems: JSX.Element[] = [];

        Object.keys(form.errors).forEach((key, index) => {
            const errors = form.errors[key];

            toRenderItems.push(
                <p>
                    <span>{`Item ${String(index + 1).padStart(2, '0')}:`}</span>
                </p>
            );

            Object.keys(errors)?.forEach((itemKey) => {
                toRenderItems.push(
                    <div>
                        <span>{labels[itemKey]}</span>
                        {' : '}
                        <span>{errors[itemKey]}</span>
                    </div>
                );
            });
        });

        return (
            <>
                {getSummPoints() !== maxPointsByCriteria && criteriaSumLabel}
                <p>{toRenderItems}</p>
            </>
        );
    };

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <Box
                            width={1}
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Box display='flex'>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant='body1'>
                                        <b>
                                            {isTechniqueAndPrice
                                                ? t('term.technique-and-price')
                                                : t('term.best-technique')}
                                        </b>
                                    </Typography>
                                </Box>
                                <Box ml={1} display='flex' alignItems='center'>
                                    <ErrorStatus
                                        isValid={formIsValid && !!process?.id}
                                        title={
                                            !process?.id
                                                ? t(
                                                      'info.required-create-process.technical-criteria'
                                                  )
                                                : getFormErrorsLabels()
                                        }
                                    />
                                </Box>
                            </Box>
                            <LoadingButton
                                disabled={
                                    !form.dirty || !!Object.keys(form.errors).length || !formIsValid
                                }
                                size='xsmall'
                                variant='contained'
                                color='primary'
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    form.submitForm();
                                }}
                            >
                                {t('term.save')}
                            </LoadingButton>
                        </Box>
                    }
                >
                    <Grid container>
                        <Box
                            mb={1}
                            width={1}
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Box width={1}>
                                <Alert severity='info'>
                                    <Typography variant='body2'>{criteriaSumLabel}</Typography>
                                </Alert>
                            </Box>
                            <Box ml={5} mr={2}>
                                <Typography variant='h5' color='secondary'>
                                    {getSummPoints()}
                                </Typography>
                                <Typography variant='body2'> {t('term.points')}</Typography>
                            </Box>
                        </Box>
                        <TableContainer>
                            <Table classes={{ root: classes.table }} stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ minWidth: 200 }}>
                                            {t('term.title')}
                                        </TableCell>
                                        <TableCell style={{ minWidth: 300 }}>
                                            {t('term.description')}
                                        </TableCell>
                                        <TableCell style={{ minWidth: 100 }}>
                                            {t('info.max-points')}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {form.values.map((technicalCriteria, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRow key={technicalCriteria.temporaryId}>
                                            <TableCell scope='row'>
                                                <Input
                                                    className={classes.textArea}
                                                    placeholder={t('term.title')}
                                                    fullWidth
                                                    color='primary'
                                                    defaultValue={technicalCriteria.title}
                                                    autoFocus
                                                    onChange={(ev) =>
                                                        onChange(ev.target.value, 'title', index)
                                                    }
                                                    disabled={processActions.cannotEditUntilDispute(
                                                        process
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell scope='row'>
                                                <Input
                                                    className={classes.textArea}
                                                    placeholder={t('term.description')}
                                                    fullWidth
                                                    color='primary'
                                                    defaultValue={technicalCriteria.description}
                                                    multiline
                                                    minRows={2}
                                                    maxRows={8}
                                                    onChange={(ev) =>
                                                        onChange(
                                                            ev.target.value,
                                                            'description',
                                                            index
                                                        )
                                                    }
                                                    disabled={processActions.cannotEditUntilDispute(
                                                        process
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell style={{ width: '160px' }} scope='row'>
                                                <Input
                                                    className={classes.input}
                                                    placeholder='Pontos'
                                                    fullWidth
                                                    type='number'
                                                    inputProps={{
                                                        max: '100',
                                                        min: '0',
                                                    }}
                                                    color='primary'
                                                    defaultValue={Number(
                                                        technicalCriteria.maxPoints
                                                    )}
                                                    onChange={(ev) =>
                                                        onChange(
                                                            parseInt(ev.target.value, 10),
                                                            'maxPoints',
                                                            index
                                                        )
                                                    }
                                                    disabled={processActions.cannotEditUntilDispute(
                                                        process
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell
                                                style={{ width: '50px' }}
                                                padding='none'
                                                scope='row'
                                            >
                                                <IconButton
                                                    size='small'
                                                    disabled={processActions.cannotEditUntilDispute(
                                                        process
                                                    )}
                                                    onClick={() =>
                                                        deleteTechnicalCriteria(
                                                            technicalCriteria.temporaryId
                                                        )
                                                    }
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={2}>
                            <Button
                                size='xsmall'
                                variant='outlined'
                                color='primary'
                                onClick={createTechnicalCriteria}
                                disabled={!process?.id}
                            >
                                <AddIcon />
                                {t('term.add')}
                            </Button>
                        </Box>
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessTechnicalCriteria;
