import { FC } from 'react';
import { makeStyles, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateType } from '../../../../../clients/manager/interfaces/document-templates.interface';

interface SelectTemplateType2Props {
    onChange: (value: DocumentTemplateType) => void;
    selectedValue?: DocumentTemplateType;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const SelectTemplateType2: FC<SelectTemplateType2Props> = ({ onChange, selectedValue }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getTypeOptions = () => [
        { label: t('term.all'), value: -1 },
        { label: t('term.contract'), value: DocumentTemplateType.contract },
        { label: t('term.receipt'), value: DocumentTemplateType.receipt },
        { label: t('term.public-notices'), value: DocumentTemplateType.auction },
        { label: 'Ata', value: DocumentTemplateType.protocol },
        { label: t('term.terms-of-reference'), value: DocumentTemplateType.referenceTerms },
        { label: t('term.others'), value: DocumentTemplateType.others },
    ];

    return (
        <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel shrink id='template-type-label'>
                {t('term.document-type')}
            </InputLabel>
            <Select
                labelId='template-type-label'
                value={getTypeOptions().find((item) => item.value === selectedValue)?.value ?? -1}
                onChange={(ev) => onChange(ev.target.value as DocumentTemplateType)}
                displayEmpty
                label={t('term.type-model')}
            >
                {getTypeOptions().map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectTemplateType2;
