import React from 'react';
import { Box } from '@material-ui/core';

interface ProviderStatusBagdeViewProps {
    title?: string;
    icon?: JSX.Element;
    hidden?: boolean;
}

const ProviderStatusBagdeView: React.FC<ProviderStatusBagdeViewProps> = ({
    title,
    icon,
    hidden,
}) => {
    if (hidden) return <></>;

    return <Box title={title}>{icon}</Box>;
};

export default ProviderStatusBagdeView;
