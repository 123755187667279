import { createContext, ReactNode, useContext, useState } from 'react';

export type MenuHeaderContextType = {
    notificationsCountContext: number;
    setNotificationsCountContext: React.Dispatch<React.SetStateAction<number>>;
};

export const MenuHeaderContext = createContext<MenuHeaderContextType>({
    notificationsCountContext: 0,
    setNotificationsCountContext: () => {},
});

export const useMenuHeaderContext = () => useContext(MenuHeaderContext);

export const MenuHeaderContextProvider = ({ children }: { children: ReactNode }) => {
    const [notificationsCountContext, setNotificationsCountContext] = useState<number>(0);

    return (
        <MenuHeaderContext.Provider
            value={{
                notificationsCountContext,
                setNotificationsCountContext,
            }}
        >
            {children}
        </MenuHeaderContext.Provider>
    );
};
