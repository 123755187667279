import { managerApiInstance } from 'clients/api';

const BASE_PATH = '/catalogs';

const listCatalogsOrganization = () => {
    return managerApiInstance.post(`${BASE_PATH}/listCatalogsOrganization`);
};

const doSave = (values: any) => {
    return managerApiInstance.post(`${BASE_PATH}/doSave`, values);
};

const doDelete = (id: string) => {
    return managerApiInstance.post(`${BASE_PATH}/doDelete`, { id });
};

const CatalogsService = { listCatalogsOrganization, doSave, doDelete };

export default CatalogsService;
