import { Dialog, Grid, makeStyles, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
    listItemDateInsert: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
    },
}));

export const LotDialogInformationContainer = styled(Grid)`
    gap: 1rem;

    > div {
        gap: 0.5rem;
    }
`;

export const StyledLotDialog = styled(Dialog)`
    > div > div {
        width: 100%;

        h1 {
            margin: 0;
            margin-bottom: 1rem;
        }
    }
`;

export const DocumentsContainer = styled(Paper)`
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #0001;
        border-radius: 2px;
        transition: 0.4s;
    }
`;

export const StyledLotInfoAnonymizedComponent = styled.div`
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    top: -1px;
    overflow: hidden;
    height: 20px;

    > svg {
        position: absolute;
        transform: translateY(1px);
    }
`;
