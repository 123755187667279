import i18n from 'i18next';
import { getTinyMceAutocompleteVariable, getTinyMceDefaultConfig } from 'common/components/editor';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { OrganizationVariables } from './props';

export const OrganizationBlocksEditorConfigs = {
    init: (
        onUploadFile: (blob: any) => Promise<PlainGenericResponse<{ url: string }> | undefined>,
        variables: OrganizationVariables[]
    ) =>
        ({
            ...getTinyMceDefaultConfig(),
            images_upload_handler: (blobInfo, success, failure) =>
                onUploadFile(blobInfo.blob())
                    .then((response) => success(response?.data.url))
                    .catch(() => failure(i18n.t('editor.components.editor.config.error-message'))),
            plugins: [
                'autoresize',
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks hr',
                'insertdatetime table paste code',
            ],
            toolbar: [
                'undo redo | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | hr',
                'numlist bullist checklist | bold italic underline strikethrough blockquote | forecolor backcolor casechange permanentpen formatpainter | pagebreak | emoticons | insertfile image pageembed template link | table format',
            ],
            table_default_attributes: {
                class: 'a_table_block',
            },
            setup: (editor) => {
                getTinyMceAutocompleteVariable(editor, variables);
            },
            content_css: '/assets/tinymce-content.css',
        } as any),
};
