import React from 'react';
import { Box } from '@material-ui/core';
import { SearchInvoicesViewProps } from '../../search-invoices-view';
import SearchInvoicesTableHeaderView from './search-invoices-table-header-view';

export interface SearchInvoicesTableHeaderProps {
    statusPayment?: SearchInvoicesViewProps['statusPayment'];
    onChangeStatusPayment: SearchInvoicesViewProps['onChangeStatusPayment'];
}

const SearchInvoicesTableHeader: React.FC<SearchInvoicesTableHeaderProps> = (props) => (
    <Box mb={1}>
        <SearchInvoicesTableHeaderView {...props} />
    </Box>
);

export default SearchInvoicesTableHeader;
