import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import { hasCurrentAccessType, hasPermissionToExecute } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import NotFound from 'routes/not-found';
import ListAudit from './pages/ListAudit';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                canAccess={() =>
                    hasCurrentAccessType([TypeAccess.management]) &&
                    hasPermissionToExecute('listarAcoesDaAuditoria')
                }
                path={`${match.path}/lista`}
                exact
                component={() => <ListAudit />}
            />
            ,
            <PrivateRoute path='*' key='*' component={() => <NotFound />} />
        </Switch>
    );
};

const AuditRoutes: JSX.Element[] = [
    <Route path='/auditoria' key='/auditoria' render={() => <ChildRoutes />} />,
];

export default AuditRoutes;
