import { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import BiddingFiltersProvider from './contexts/BiddingFiltersContext';
import BiddingProcess from './pages/process';
import BiddingSearch from './pages/search';
import Checkout from './pages/checkout';
import { CheckoutContextProvider } from './contexts/CheckoutContext';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                allowGuest
                path={`${match.path}/`}
                exact
                component={() => (
                    <BiddingFiltersProvider>
                        <BiddingSearch />
                    </BiddingFiltersProvider>
                )}
            />
            <PrivateRoute exact allowGuest path={`${match.path}/:id`} component={BiddingProcess} />
            <PrivateRoute
                allowGuest
                path={`${match.path}/:auctionId/checkout`}
                exact
                component={() => (
                    <CheckoutContextProvider>
                        <Checkout />
                    </CheckoutContextProvider>
                )}
            />
            <Route path='*' key='*' component={() => <Redirect to='/pesquisa' />} />
        </Switch>
    );
};

const BiddingRoutes: JSX.Element[] = [
    <Route path='/pesquisa' key='/licitações-pesquisa' render={() => <ChildRoutes />} />,
];

export default BiddingRoutes;
