import styled from 'styled-components';

export const LogoContainer = styled.div`
    height: 6rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    > img {
        margin: 0;
        padding: 0;
        height: calc(100% - 16px);
    }
`;
