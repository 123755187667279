import { FC } from 'react';
import { Avatar as DefaultAvatar, AvatarProps as DefaultAvatarProps } from '@material-ui/core';

enum AvatarType {
    default = 'default',
    user = 'user',
}

interface AvatarProps extends DefaultAvatarProps {
    type?: AvatarType;
    user?: {
        name: string;
        avatar?: string;
    };
}

const stringToColor = (str: string) => {
    const colors = [
        '#40407a',
        '#706fd3',
        '#34ace0',
        '#33d9b2',
        '#2c2c54',
        '#474787',
        '#aaa69d',
        '#227093',
        '#218c74',
        '#ff5252',
        '#ff793f',
        '#ffb142',
        '#ffda79',
        '#b33939',
        '#cd6133',
        '#84817a',
        '#cc8e35',
        '#ccae62',
        '#4D8066',
        '#809980',
        '#999933',
        '#FF3380',
        '#CCCC00',
        '#4D80CC',
        '#9900B3',
    ];

    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        // eslint-disable-next-line no-bitwise
        hash &= hash;
    }

    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
};

const Avatar: FC<AvatarProps> = ({ user, type = AvatarType.default, ...rest }) => {
    const firstLetter = user?.name.charAt(0).toUpperCase();

    if (type === AvatarType.default) {
        return <DefaultAvatar {...rest} />;
    }

    if (type === AvatarType.user && user?.avatar) {
        return <DefaultAvatar {...rest} src={user.avatar || rest.src} />;
    }

    if (type === AvatarType.user && !user?.avatar) {
        return (
            <DefaultAvatar {...rest}>
                <svg height='100%' width='100%'>
                    <rect
                        fill={stringToColor(user?.name ?? 'A').toString()}
                        x='0'
                        y='0'
                        height='100%'
                        width='100%'
                    />
                    <text
                        fill='#FFF'
                        fontSize={rest.style?.fontSize ?? '25px'}
                        fontWeight='500'
                        textAnchor='middle'
                        x='50%'
                        y='50%'
                        dominantBaseline='middle'
                    >
                        {firstLetter}
                    </text>
                </svg>
            </DefaultAvatar>
        );
    }

    return <DefaultAvatar {...rest} />;
};

export type { AvatarType, AvatarProps };
export { Avatar };
