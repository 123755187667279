import { Grid, Breadcrumbs, Button, Card, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import SearchDocumentTypesDialog from './components/SearchDocumentTypesDialog';
import SearchDocumentTypesTable from './components/SearchDocumentTypesTable';

const SearchDocumentTypesView = ({ handleNew, documentTypeDialog, setDocumentTypeDialog }) => {
    const { t } = useTranslation();
    return (
        <>
            <PageWrapper>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Breadcrumbs>
                        <Typography>{t('term.document-types')}</Typography>
                    </Breadcrumbs>

                    <Button size='small' variant='contained' color='primary' onClick={handleNew}>
                        {t('term.add')}
                    </Button>
                </Grid>

                <br />

                <Card>
                    <SearchDocumentTypesTable />
                </Card>
            </PageWrapper>
            {documentTypeDialog.open && (
                <SearchDocumentTypesDialog setDocumentTypeDialog={setDocumentTypeDialog} />
            )}
        </>
    );
};

export default SearchDocumentTypesView;
