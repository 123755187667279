import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useIBGECountries() {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const getTranlation = useCallback(
        (country: string) => t(`country.${country}`, { ns: 'countries' }),
        [language]
    );

    const IBGEcountries = [
        {
            code: '132',
            name: getTranlation('AFGHANISTAN'),
        },
        {
            code: '7560',
            name: getTranlation('SOUTH-AFRICA'),
        },
        {
            code: '175',
            name: getTranlation('ALBANIA'),
        },
        {
            code: '230',
            name: getTranlation('GERMANY'),
        },
        {
            code: '370',
            name: getTranlation('ANDORRA'),
        },
        {
            code: '400',
            name: getTranlation('ANGOLA'),
        },
        {
            code: '418',
            name: getTranlation('ANGUILLA'),
        },
        {
            code: '434',
            name: getTranlation('ANTIGUA-AND-BARBUDA'),
        },
        {
            code: '477',
            name: getTranlation('NETHERLANDS-ANTILLES'),
        },
        {
            code: '531',
            name: getTranlation('SAUDI-ARABIA'),
        },
        {
            code: '590',
            name: getTranlation('ALGERIA'),
        },
        {
            code: '639',
            name: getTranlation('ARGENTINA'),
        },
        {
            code: '647',
            name: getTranlation('ARMENIA'),
        },
        {
            code: '655',
            name: getTranlation('ARUBA'),
        },
        {
            code: '698',
            name: getTranlation('AUSTRALIA'),
        },
        {
            code: '728',
            name: getTranlation('AUSTRIA'),
        },
        {
            code: '736',
            name: getTranlation('AZERBAIJAN'),
        },
        {
            code: '779',
            name: getTranlation('BAHAMAS'),
        },
        {
            code: '809',
            name: getTranlation('BAHRAIN'),
        },
        {
            code: '817',
            name: getTranlation('BANGLADESH'),
        },
        {
            code: '833',
            name: getTranlation('BARBADOS'),
        },
        {
            code: '850',
            name: getTranlation('BELARUS'),
        },
        {
            code: '876',
            name: getTranlation('BELGIUM'),
        },
        {
            code: '884',
            name: getTranlation('BELIZE'),
        },
        {
            code: '2291',
            name: getTranlation('BENIN'),
        },
        {
            code: '906',
            name: getTranlation('BERMUDA'),
        },
        {
            code: '973',
            name: getTranlation('BOLIVIA'),
        },
        {
            code: '981',
            name: getTranlation('BOSNIA-AND-HERZEGOVINA'),
        },
        {
            code: '1015',
            name: getTranlation('BOTSWANA'),
        },
        {
            code: '1058',
            name: getTranlation('BRAZIL'),
        },
        {
            code: '1082',
            name: getTranlation('BRUNEI-DARUSSALAM'),
        },
        {
            code: '1112',
            name: getTranlation('BULGARIA'),
        },
        {
            code: '310',
            name: getTranlation('BURKINA-FASO'),
        },
        {
            code: '1155',
            name: getTranlation('BURUNDI'),
        },
        {
            code: '1198',
            name: getTranlation('BHUTAN'),
        },
        {
            code: '1279',
            name: getTranlation('CAPE-VERDE'),
        },
        {
            code: '1457',
            name: getTranlation('CAMEROON'),
        },
        {
            code: '1414',
            name: getTranlation('CAMBODIA'),
        },
        {
            code: '1490',
            name: getTranlation('CANADA'),
        },
        {
            code: '1511',
            name: getTranlation('CANARY-ISLANDS'),
        },
        {
            code: '1546',
            name: getTranlation('QATAR'),
        },
        {
            code: '1376',
            name: getTranlation('CAYMAN-ISLANDS'),
        },
        {
            code: '1538',
            name: getTranlation('KAZAKHSTAN'),
        },
        {
            code: '7889',
            name: getTranlation('CHAD'),
        },
        {
            code: '1589',
            name: getTranlation('CHILE'),
        },
        {
            code: '1600',
            name: getTranlation('CHINA'),
        },
        {
            code: '1635',
            name: getTranlation('CYPRUS'),
        },
        {
            code: '5118',
            name: getTranlation('CHRISTMAS-ISLAND'),
        },
        {
            code: '7412',
            name: getTranlation('SINGAPORE'),
        },
        {
            code: '1651',
            name: getTranlation('COCOS-(KEELING)-ISLANDS'),
        },
        {
            code: '1694',
            name: getTranlation('COLOMBIA'),
        },
        {
            code: '1732',
            name: getTranlation('COMOROS'),
        },
        {
            code: '1775',
            name: getTranlation('CONGO'),
        },
        {
            code: '8885',
            name: getTranlation('THE-DEMOCRATIC-REPUBLIC-OF-THE-CONGO'),
        },
        {
            code: '1830',
            name: getTranlation('COOK-ISLANDS'),
        },
        {
            code: '1872',
            name: getTranlation('NORTH-KOREA'),
        },
        {
            code: '1902',
            name: getTranlation('SOUTH-KOREA'),
        },
        {
            code: '1937',
            name: getTranlation('COTE-DIVOIRE'),
        },
        {
            code: '1961',
            name: getTranlation('COSTA-RICA'),
        },
        {
            code: '1988',
            name: getTranlation('KUWAIT'),
        },
        {
            code: '1953',
            name: getTranlation('CROATIA'),
        },
        {
            code: '1996',
            name: getTranlation('CUBA'),
        },
        {
            code: '2321',
            name: getTranlation('DENMARK'),
        },
        {
            code: '7838',
            name: getTranlation('DJIBOUTI'),
        },
        {
            code: '2356',
            name: getTranlation('DOMINICA'),
        },
        {
            code: '2402',
            name: getTranlation('EGYPT'),
        },
        {
            code: '6874',
            name: getTranlation('EL-SALVADOR'),
        },
        {
            code: '2445',
            name: getTranlation('UNITED-ARAB-EMIRATES'),
        },
        {
            code: '2399',
            name: getTranlation('ECUADOR'),
        },
        {
            code: '2437',
            name: getTranlation('ERITREA'),
        },
        {
            code: '2470',
            name: getTranlation('SLOVAKIA'),
        },
        {
            code: '2461',
            name: getTranlation('SLOVENIA'),
        },
        {
            code: '2453',
            name: getTranlation('SPAIN'),
        },
        {
            code: '2496',
            name: getTranlation('UNITED-STATES'),
        },
        {
            code: '2518',
            name: getTranlation('ESTONIA'),
        },
        {
            code: '2534',
            name: getTranlation('ETHIOPIA'),
        },
        {
            code: '2550',
            name: getTranlation('MALVINAS'),
        },
        {
            code: '2593',
            name: getTranlation('FAROE-ISLANDS'),
        },
        {
            code: '8702',
            name: getTranlation('FIJI'),
        },
        {
            code: '2674',
            name: getTranlation('PHILIPPINES'),
        },
        {
            code: '2712',
            name: getTranlation('FINLAND'),
        },
        {
            code: '1619',
            name: getTranlation('TAIWAN'),
        },
        {
            code: '2755',
            name: getTranlation('FRANCE'),
        },
        {
            code: '2810',
            name: getTranlation('GABON'),
        },
        {
            code: '2852',
            name: getTranlation('GAMBIA'),
        },
        {
            code: '2895',
            name: getTranlation('GANA'),
        },
        {
            code: '2917',
            name: getTranlation('GEORGIA'),
        },
        {
            code: '2933',
            name: getTranlation('GIBRALTAR'),
        },
        {
            code: '2976',
            name: getTranlation('GRENADA'),
        },
        {
            code: '3018',
            name: getTranlation('GREECE'),
        },
        {
            code: '3050',
            name: getTranlation('GREENLAND'),
        },
        {
            code: '3093',
            name: getTranlation('GUADELOUPE'),
        },
        {
            code: '3131',
            name: getTranlation('GUAM'),
        },
        {
            code: '3174',
            name: getTranlation('GUATEMALA'),
        },
        {
            code: '1504',
            name: getTranlation('GUERNSEY'),
        },
        {
            code: '3379',
            name: getTranlation('GUYANA'),
        },
        {
            code: '3255',
            name: getTranlation('FRENCH-GUIANA'),
        },
        {
            code: '3298',
            name: getTranlation('GUINEA'),
        },
        {
            code: '3344',
            name: getTranlation('GUINEA-BISSAU'),
        },
        {
            code: '3310',
            name: getTranlation('EQUATORIAL-GUINEA'),
        },
        {
            code: '3417',
            name: getTranlation('HAITI'),
        },
        {
            code: '3450',
            name: getTranlation('HONDURAS'),
        },
        {
            code: '3514',
            name: getTranlation('HONG-KONG'),
        },
        {
            code: '3557',
            name: getTranlation('HUNGARY'),
        },
        {
            code: '3573',
            name: getTranlation('YEMEN'),
        },
        {
            code: '3611',
            name: getTranlation('INDIA'),
        },
        {
            code: '3654',
            name: getTranlation('INDONESIA'),
        },
        {
            code: '3727',
            name: getTranlation('IRAN'),
        },
        {
            code: '3697',
            name: getTranlation('IRAQ'),
        },
        {
            code: '3751',
            name: getTranlation('IRELAND'),
        },
        {
            code: '3794',
            name: getTranlation('ICELAND'),
        },
        {
            code: '3832',
            name: getTranlation('ISRAEL'),
        },
        {
            code: '3867',
            name: getTranlation('ITALY'),
        },
        {
            code: '3913',
            name: getTranlation('JAMAICA'),
        },
        {
            code: '3999',
            name: getTranlation('JAPAN'),
        },
        {
            code: '1508',
            name: getTranlation('JERSEY'),
        },
        {
            code: '3964',
            name: getTranlation('JOHNSTONE-ATOLL'),
        },
        {
            code: '4030',
            name: getTranlation('JORDAN'),
        },
        {
            code: '4111',
            name: getTranlation('KIRIBATI'),
        },
        {
            code: '4200',
            name: getTranlation('LAOS'),
        },
        {
            code: '4235',
            name: getTranlation('LEBUAN'),
        },
        {
            code: '4260',
            name: getTranlation('LESOTHO'),
        },
        {
            code: '4278',
            name: getTranlation('LATVIA'),
        },
        {
            code: '4316',
            name: getTranlation('LEBANON'),
        },
        {
            code: '4340',
            name: getTranlation('LIBERIA'),
        },
        {
            code: '4383',
            name: getTranlation('LIBYAN-ARAB-JAMAHIRIYA'),
        },
        {
            code: '4405',
            name: getTranlation('LIECHTENSTEIN'),
        },
        {
            code: '4421',
            name: getTranlation('LITHUANIA'),
        },
        {
            code: '4456',
            name: getTranlation('LUXEMBOURG'),
        },
        {
            code: '4472',
            name: getTranlation('MACAO'),
        },
        {
            code: '4499',
            name: getTranlation('MACEDONIA'),
        },
        {
            code: '4502',
            name: getTranlation('MADAGASCAR'),
        },
        {
            code: '4525',
            name: getTranlation('MADEIRA'),
        },
        {
            code: '4553',
            name: getTranlation('MALAYSIA'),
        },
        {
            code: '4588',
            name: getTranlation('MALAWI'),
        },
        {
            code: '4618',
            name: getTranlation('MALDIVES'),
        },
        {
            code: '4642',
            name: getTranlation('MALI'),
        },
        {
            code: '4677',
            name: getTranlation('MALTA'),
        },
        {
            code: '3595',
            name: getTranlation('ISLE-OF-MAN'),
        },
        {
            code: '4723',
            name: getTranlation('NORTHERN-MARIANA-ISLANDS'),
        },
        {
            code: '4740',
            name: getTranlation('MOROCCO'),
        },
        {
            code: '4766',
            name: getTranlation('MARSHALL-ISLANDS'),
        },
        {
            code: '4774',
            name: getTranlation('MARTINIQUE'),
        },
        {
            code: '4855',
            name: getTranlation('MAURITIUS'),
        },
        {
            code: '4880',
            name: getTranlation('MAURITANIA'),
        },
        {
            code: '4885',
            name: getTranlation('MAYOTTE'),
        },
        {
            code: '4936',
            name: getTranlation('MEXICO'),
        },
        {
            code: '930',
            name: getTranlation('MYANMAR'),
        },
        {
            code: '4995',
            name: getTranlation('MICRONESIA'),
        },
        {
            code: '4901',
            name: getTranlation('MIDWAY-ATOLL'),
        },
        {
            code: '5053',
            name: getTranlation('MOZAMBIQUE'),
        },
        {
            code: '4944',
            name: getTranlation('MOLDOVA'),
        },
        {
            code: '4952',
            name: getTranlation('MONACO'),
        },
        {
            code: '4979',
            name: getTranlation('MONGOLIA'),
        },
        {
            code: '4985',
            name: getTranlation('MONTENEGRO'),
        },
        {
            code: '5010',
            name: getTranlation('MONTSERRAT'),
        },
        {
            code: '5070',
            name: getTranlation('NAMIBIA'),
        },
        {
            code: '5088',
            name: getTranlation('NAURU'),
        },
        {
            code: '5177',
            name: getTranlation('NEPAL'),
        },
        {
            code: '5215',
            name: getTranlation('NICARAGUA'),
        },
        {
            code: '5258',
            name: getTranlation('NIGER'),
        },
        {
            code: '5282',
            name: getTranlation('NIGERIA'),
        },
        {
            code: '5312',
            name: getTranlation('NIUE'),
        },
        {
            code: '5355',
            name: getTranlation('NORFOLK-ISLAND'),
        },
        {
            code: '5380',
            name: getTranlation('NORWAY'),
        },
        {
            code: '5428',
            name: getTranlation('NEW-CALEDONIA'),
        },
        {
            code: '5487',
            name: getTranlation('NEW-ZEALAND'),
        },
        {
            code: '5568',
            name: getTranlation('OMAN'),
        },
        {
            code: '5665',
            name: getTranlation('UNITED-STATES-MINOR-OUTLYING-ISLANDS'),
        },
        {
            code: '5738',
            name: getTranlation('NETHERLANDS'),
        },
        {
            code: '5754',
            name: getTranlation('PALAU'),
        },
        {
            code: '5780',
            name: getTranlation('PALESTINIAN-TERRITORY-OCCUPIED'),
        },
        {
            code: '5800',
            name: getTranlation('PANAMA'),
        },
        {
            code: '5452',
            name: getTranlation('PAPUA-NEW-GUINEA'),
        },
        {
            code: '5762',
            name: getTranlation('PAKISTAN'),
        },
        {
            code: '5860',
            name: getTranlation('PARAGUAY'),
        },
        {
            code: '5894',
            name: getTranlation('PERU'),
        },
        {
            code: '5932',
            name: getTranlation('PITCAIRN'),
        },
        {
            code: '5991',
            name: getTranlation('FRENCH-POLYNESIA'),
        },
        {
            code: '6033',
            name: getTranlation('POLAND'),
        },
        {
            code: '6114',
            name: getTranlation('PUERTO-RICO'),
        },
        {
            code: '6076',
            name: getTranlation('PORTUGAL'),
        },
        {
            code: '6238',
            name: getTranlation('KENYA'),
        },
        {
            code: '6254',
            name: getTranlation('KYRGYZSTAN'),
        },
        {
            code: '6289',
            name: getTranlation('UNITED-KINGDOM'),
        },
        {
            code: '6408',
            name: getTranlation('CENTRAL-AFRICAN-REPUBLIC'),
        },
        {
            code: '6475',
            name: getTranlation('DOMINICAN-REPUBLIC'),
        },
        {
            code: '6602',
            name: getTranlation('REUNION'),
        },
        {
            code: '6700',
            name: getTranlation('ROMANIA'),
        },
        {
            code: '6750',
            name: getTranlation('RWANDA'),
        },
        {
            code: '6769',
            name: getTranlation('RUSSIAN-FEDERATION'),
        },
        {
            code: '6858',
            name: getTranlation('WESTERN-SAHARA'),
        },
        {
            code: '6781',
            name: getTranlation('SAINT-KITTS-AND-NEVIS'),
        },
        {
            code: '6777',
            name: getTranlation('SOLOMON-ISLANDS'),
        },
        {
            code: '6904',
            name: getTranlation('SAMOA'),
        },
        {
            code: '6912',
            name: getTranlation('AMERICAN-SAMOA'),
        },
        {
            code: '6971',
            name: getTranlation('SAN-MARINO'),
        },
        {
            code: '7102',
            name: getTranlation('SAINT-HELENA'),
        },
        {
            code: '7153',
            name: getTranlation('SAINT-LUCIA'),
        },
        {
            code: '6955',
            name: getTranlation('SAINT-KITTS-AND-NEVIS'),
        },
        {
            code: '7005',
            name: getTranlation('SAINT-PIERRE-AND-MIQUELON'),
        },
        {
            code: '7200',
            name: getTranlation('SAO-TOME-AND-PRINCIPE'),
        },
        {
            code: '7056',
            name: getTranlation('SAINT-VINCENT-AND-THE-GRENADINES'),
        },
        {
            code: '7285',
            name: getTranlation('SENEGAL'),
        },
        {
            code: '7358',
            name: getTranlation('SIERRA-LEONE'),
        },
        {
            code: '7370',
            name: getTranlation('SERBIA'),
        },
        {
            code: '7315',
            name: getTranlation('SEYCHELLES'),
        },
        {
            code: '7447',
            name: getTranlation('SYRIAN-ARAB-REPUBLIC'),
        },
        {
            code: '7480',
            name: getTranlation('SOMALIA'),
        },
        {
            code: '7501',
            name: getTranlation('SRI-LANKA'),
        },
        {
            code: '7544',
            name: getTranlation('SWAZILAND'),
        },
        {
            code: '7595',
            name: getTranlation('SUDAN'),
        },
        {
            code: '7600',
            name: getTranlation('SOUTH-SUDAN'),
        },
        {
            code: '7641',
            name: getTranlation('SWEDEN'),
        },
        {
            code: '7676',
            name: getTranlation('SWITZERLAND'),
        },
        {
            code: '7706',
            name: getTranlation('SURINAME'),
        },
        {
            code: '7722',
            name: getTranlation('TAJIKISTAN'),
        },
        {
            code: '7765',
            name: getTranlation('THAILAND'),
        },
        {
            code: '7803',
            name: getTranlation('TANZANIA'),
        },
        {
            code: '7919',
            name: getTranlation('CZECH-REPUBLIC'),
        },
        {
            code: '7820',
            name: getTranlation('BRITISH-INDIAN-OCEAN-TERRITORY'),
        },
        {
            code: '7951',
            name: getTranlation('TIMOR-LESTE'),
        },
        {
            code: '8001',
            name: getTranlation('TOGO'),
        },
        {
            code: '8109',
            name: getTranlation('TONGA'),
        },
        {
            code: '8052',
            name: getTranlation('TOKELAU'),
        },
        {
            code: '8150',
            name: getTranlation('TRINIDAD-AND-TOBAGO'),
        },
        {
            code: '8206',
            name: getTranlation('TUNISIA'),
        },
        {
            code: '8230',
            name: getTranlation('TURKS-AND-CAICOS-ISLANDS'),
        },
        {
            code: '8249',
            name: getTranlation('TURKMENISTAN'),
        },
        {
            code: '8273',
            name: getTranlation('TURKEY'),
        },
        {
            code: '8281',
            name: getTranlation('TUVALU'),
        },
        {
            code: '8311',
            name: getTranlation('UKRAINE'),
        },
        {
            code: '8338',
            name: getTranlation('UGANDA'),
        },
        {
            code: '8451',
            name: getTranlation('URUGUAY'),
        },
        {
            code: '8478',
            name: getTranlation('UZBEKISTAN'),
        },
        {
            code: '5517',
            name: getTranlation('VANUATU'),
        },
        {
            code: '8486',
            name: getTranlation('VATICAN'),
        },
        {
            code: '8508',
            name: getTranlation('VENEZUELA'),
        },
        {
            code: '8583',
            name: getTranlation('VIETNAM'),
        },
        {
            code: '8630',
            name: getTranlation('VIRGIN-ISLANDS-BRITISH'),
        },
        {
            code: '8664',
            name: getTranlation('VIRGIN-ISLANDS-U.S'),
        },
        {
            code: '8737',
            name: getTranlation('WAKE-ISLAND'),
        },
        {
            code: '8907',
            name: getTranlation('ZAMBIA'),
        },
        {
            code: '6653',
            name: getTranlation('ZIMBABWE'),
        },
        {
            code: '8958',
            name: getTranlation('PANAMA-CANAL-ZONE'),
        },
    ];

    return { IBGEcountries };
}
