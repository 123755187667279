import { doRequest, managerApiInstance } from 'clients/api';
import { AxiosRequestConfig } from 'axios';
import {
    GetNextDisputesBody,
    GetNextDisputesResponse,
} from '../interfaces/next-disputes.interface';

const BASE_PATH = '/citizen-dashboard';

const citizenDashboardRequests = {
    getNextDisputes: async (body?: GetNextDisputesBody, config?: AxiosRequestConfig<any>) =>
        doRequest<GetNextDisputesResponse>(
            managerApiInstance.post(`${BASE_PATH}/getNextDisputes`, body, config)
        ),
};

export { citizenDashboardRequests };
