export enum OrderStatusEnum {
    OPENED = 'opened',
    JUDGED = 'judged',
    FINISHED = 'finished',
    CANCELED = 'canceled',
}

export enum OrderItemStatusEnum {
    PENDING = 'pending',
    STARTED = 'started',
    FINISHED = 'finished',
    FRUSTRATED = 'frustrated',
}
