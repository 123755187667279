import { GeneratedDocuments } from 'clients/manager/interfaces/generated-documents.interface';

export const getMostRecentDocumentGenerated = (data: GeneratedDocuments[] | undefined) => {
    if (!data || !data.length) return null;
    return data.reduce((mostRecent: GeneratedDocuments | null, current) => {
        const mostRecentTimestamp = mostRecent ? mostRecent.createdAt ?? 0 : 0;
        const currentTimestamp = current.createdAt;
        return currentTimestamp && currentTimestamp > mostRecentTimestamp ? current : mostRecent;
    }, null);
};
