import { Grid, Typography, MenuItem } from '@material-ui/core';
import { Button, TextField, MaskedTextField } from 'common/components';
import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReactDOM from 'react-dom';
import { validateCnpj } from 'common/utils';
import { organizationRequests } from 'clients/manager/organization.requests';
import { legalIdentityRequests } from 'clients/manager/legal-identity-types.requests';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from 'routes/platform.context';
import { SignUpOrganizationContext } from '../context/SignUpOrganizationContext';
import { LegalIdentityTypes } from '../props';

const Identification = () => {
    const { platform } = usePlatformContext();

    const {
        organization,
        setOrganization,
        handleNext,
        setUserOrganizationExist,
        userOrganizationExist,
    } = useContext(SignUpOrganizationContext);
    const [docNumberTextError, setDocNumberTextError] = useState('');
    const [legalIdentityTypes, setLegalIdentityTypes] = useState<LegalIdentityTypes[]>();

    const getLegalIdentityTypes = async () => {
        try {
            const result = await legalIdentityRequests.listLegalIdentityType();
            const arrayIdentity: LegalIdentityTypes[] = Object.entries(result).map((value) => ({
                value: parseInt(value[0], 10),
                label: String(value[1]),
            }));
            setLegalIdentityTypes(arrayIdentity);
        } catch (e: any) {
            return e;
        }
    };

    useEffect(() => {
        getLegalIdentityTypes();
    }, []);

    const initialValues = {
        organizationName: organization?.organizationName,
        docNumber: organization?.docNumber,
        legalIdentityTypeId: organization?.legalIdentityTypeId,
        platform: platform?.name,
    };

    const { t } = useTranslation(['default', 'validation']);

    const validationSchema = yup.object({
        organizationName: yup
            .string()
            .required(t('organizationName', { ns: 'validation' }))
            .min(10, t('organizationName-min-char', { ns: 'validation' })),
        docNumber: yup
            .string()
            .required(t('docNumber', { ns: 'validation' }))
            .min(14, t('docNumber-min-char', { ns: 'validation' })),
        legalIdentityTypeId: yup.string().required(t('select-option', { ns: 'validation' })),
    });

    const onSubmit = (values: any) => {
        const cnpjFormated = values.docNumber
            .replaceAll('.', '')
            .replaceAll('/', '')
            .replaceAll('-', '');

        ReactDOM.unstable_batchedUpdates(() => {
            setOrganization((prevOrganization: any) => ({
                ...prevOrganization,
                ...values,
                docNumber: cnpjFormated,
            }));

            handleNext();
        });
    };

    const validateDocNumber = (e: React.FocusEvent<HTMLInputElement>) => {
        const docNumber = e.target.value
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('/', '');

        if (docNumber.length === 14) {
            if (!validateCnpj(docNumber)) {
                setDocNumberTextError(
                    t('sign-up.pages.stepper-organization.identification.info-error-cnpj-invalid')
                );
            } else {
                setDocNumberTextError('');
            }
        }
    };

    const validateUser = async (e: React.FocusEvent<HTMLInputElement>) => {
        const docValue = e.target.value
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('_', '')
            .replaceAll('/', '');
        try {
            const result = await organizationRequests.doCheckOrganizationExist({
                docNumber: docValue,
            });
            if (docValue.length === 14) {
                if (result.exists === true) {
                    setUserOrganizationExist(true);
                } else setUserOrganizationExist(false);
            }
        } catch (ev: any) {
            return null;
        }
    };

    const formStep1 = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <Grid container>
            <form onSubmit={formStep1.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.organization-name')}
                            name='organizationName'
                            value={formStep1.values.organizationName}
                            onChange={formStep1.handleChange}
                            error={
                                formStep1.touched.organizationName &&
                                Boolean(formStep1.errors.organizationName)
                            }
                            helperText={
                                formStep1.touched.organizationName &&
                                formStep1.errors.organizationName
                            }
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            select
                            name='legalIdentityTypeId'
                            label={t('term.select-type')}
                            value={formStep1.values.legalIdentityTypeId}
                            onChange={formStep1.handleChange}
                            error={
                                formStep1.touched.legalIdentityTypeId &&
                                Boolean(formStep1.errors.legalIdentityTypeId)
                            }
                            helperText={
                                formStep1.touched.legalIdentityTypeId &&
                                formStep1.errors.legalIdentityTypeId
                            }
                        >
                            {legalIdentityTypes &&
                                legalIdentityTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <MaskedTextField
                            fullWidth
                            label='CNPJ'
                            mask='99.999.999/9999-99'
                            name='docNumber'
                            value={formStep1.values.docNumber}
                            onChange={formStep1.handleChange}
                            onBlur={(e) => {
                                validateDocNumber(e);
                                validateUser(e);
                            }}
                            error={
                                formStep1.touched.docNumber && Boolean(formStep1.errors.docNumber)
                            }
                            helperText={formStep1.touched.docNumber && formStep1.errors.docNumber}
                        />
                    </Grid>
                </Grid>
                {docNumberTextError && (
                    <Typography color='error' style={{ marginTop: 20 }}>
                        {docNumberTextError}
                    </Typography>
                )}
                {userOrganizationExist && (
                    <Typography color='error' style={{ marginTop: 20 }}>
                        {t(
                            'sign-up.pages.stepper-organization.identification.info-error-organization-invalid'
                        )}
                    </Typography>
                )}
                <div className='stepper-nav'>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={Boolean(docNumberTextError)}
                    >
                        {t('term.advance')}
                    </Button>
                </div>
            </form>
        </Grid>
    );
};

export default Identification;
