import { Grid } from '@material-ui/core';
import RegionAutoComplete from 'modules/sign-up/components/RegionAutoComplete';

const Region = () => (
    <Grid container>
        <RegionAutoComplete />
    </Grid>
);

export default Region;
