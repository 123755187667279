import { createContext, useContext, useState } from 'react';

export const SearchHolidaysContext = createContext<any>(null);

export const SearchHolidaysContextProvider = ({ children }) => {
    const [holidayDialog, setHolidayDialog] = useState({ open: false });

    return (
        <SearchHolidaysContext.Provider
            value={{
                holidayDialog,
                setHolidayDialog,
            }}
        >
            {children}
        </SearchHolidaysContext.Provider>
    );
};

export const useSearchHolidaysContext = () => useContext(SearchHolidaysContext);
