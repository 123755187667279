import { Box } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { templateVariablesRequests } from 'clients/manager/documents/template-variables.requests';
import { addNotificationWarning, focusElement } from 'common/utils';
import { useFormik } from 'formik';
import { ModalPosition, Modal } from '@licitar/ui-kit';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { YupValidationMessages } from 'common/constants/yup-validation-messages.constant';
import {
    DocumentVariables,
    DocumentVariablesValueType,
} from 'clients/manager/interfaces/document-variables.interface';
import { ModalSelectVariableValueProps } from './props';
import { Item, ConfirmIcon } from './styles';

const ModalSelectVariableValue: FC<ModalSelectVariableValueProps> = ({
    variableId,
    variableName,
    setText,
    onClose,
}) => {
    const [customVariable, setCustomVariable] = useState<DocumentVariables | undefined>();
    const { t } = useTranslation();

    const getCustomVariableValues = async () => {
        try {
            const variable = await templateVariablesRequests.getVariable(variableId);
            // se tiver um valor só no array já seta automaticamente o primeiro texto
            if (
                variable.type === DocumentVariablesValueType.select &&
                variable.values &&
                variable.values?.length <= 1
            ) {
                return setText(variable.values?.[0]?.value ?? '');
            }

            setCustomVariable(variable);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.process.components.modal-select-var.notification-error-variable'
                ),
            });
        }
    };

    useEffect(() => {
        getCustomVariableValues();
    }, []);

    useEffect(() => {
        focusElement('text-value', 0);
    }, [customVariable]);

    const form = useFormik<{ value: string }>({
        initialValues: {
            value: '',
        },
        validationSchema: Yup.object({
            value: Yup.string().required(YupValidationMessages.REQUIRED),
        }),
        onSubmit: (values) => {
            setText(values.value);
        },
    });

    const getCustomFieldByVariableType = (type: DocumentVariablesValueType) => {
        switch (type) {
            case DocumentVariablesValueType.text:
                return (
                    <TextField
                        id='text-value'
                        style={{ width: '100%' }}
                        label={t('term.value-var')}
                        name='value'
                        value={form.values.value}
                        onChange={form.handleChange}
                        minRows={2}
                        multiline
                    />
                );

            default:
                <></>;
        }
    };

    return customVariable ? (
        <Modal
            width='550px'
            title={`${t('term.custom-var')} - ${variableName}`}
            opened
            onClickOutSide={() => {
                setCustomVariable(undefined);
                onClose();
            }}
            position={ModalPosition.center}
        >
            {customVariable.type === DocumentVariablesValueType.select ? (
                customVariable.values?.map(({ value }) => (
                    <Item
                        title={t('editor.process.components.modal-select-var.set-value')}
                        onClick={() => setText(value)}
                    >
                        <span>{value}</span>
                        <ConfirmIcon onClick={() => setText(value)} />
                    </Item>
                ))
            ) : (
                <Box>
                    {getCustomFieldByVariableType(customVariable.type)}
                    <Box mt={2} display='flex' justifyContent='flex-end'>
                        <Button
                            disabled={!form.dirty || !!Object.keys(form.errors).length}
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={form.submitForm}
                        >
                            {t('term.save')}
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    ) : null;
};

export default ModalSelectVariableValue;
