import { Box } from '@material-ui/core';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { AuditViewProps } from './props';

const AuditView: FC<AuditViewProps> = ({ audit }) => {
    const { t } = useTranslation();

    return (
        <Box
            padding='8px'
            style={{
                maxWidth: '600px',
                width: 'auto',
                overflow: 'auto',
                height: 'calc(100% - 56px)',
            }}
        >
            {!!audit.actionName && (
                <p>
                    {t('term.action')}: {audit.actionName}
                </p>
            )}
            {!!audit.browser && <div>Browser: {audit.browser}</div>}
            {!!audit.ipAddress && <div>IP: {audit.ipAddress}</div>}
            {!!audit.operationSystem && (
                <div>
                    {t('audit.view.operation-system')}: {audit.operationSystem}
                </div>
            )}
            {!!audit.timestamp && (
                <div>
                    {t('audit.view.date-hour')}:{' '}
                    {moment(audit.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                </div>
            )}
            <hr />
            <ReactJson src={audit.requestBody} name='Request body' />
            <ReactJson src={audit.response} name='Response' />
            <ReactJson src={audit.requestHeaders} name='Request headers' />
        </Box>
    );
};

export default AuditView;
