import { Provider } from 'clients/manager/interfaces/provider.interface';
import { invoiceRequests } from 'clients/manager/invoice.request';
import { providerRequests } from 'clients/manager/provider.requests';
import GenericError from 'common/components/generic-error';
import { useCheckoutContext } from 'modules/bidding/contexts/CheckoutContext';
import CheckoutService from 'modules/bidding/pages/checkout/services/CheckoutService';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { formatDate } from 'common/utils';
import { CHECKOUT } from '../../CheckoutConstants';
import ConfirmationView from './ConfirmationView';

interface Params {
    [key: string]: string;
}

export default function Confirmation() {
    const { selectedSteps, setStep, setSelectedSteps } = useCheckoutContext();
    const [provider, setProvider] = useState<Provider>();
    const [hasError, setHasError] = useState(false);
    const planTitle = selectedSteps.plan.selected;
    const planDetails = selectedSteps.plan.details;
    const paymentCondition = selectedSteps.plan.details.billing.type;
    const { benefits } = selectedSteps.plan.details;
    const paymentTitle = selectedSteps.payment.selected;
    const paymentDetails = selectedSteps.payment.details;
    const today = new Date();
    const [loading, setLoading] = useState(false);
    const { auctionId } = useParams<Params>();

    useEffect(() => {
        const fetchProvider = async () => {
            try {
                setLoading(true);
                const response = await providerRequests.getProvider();
                setProvider(response);
            } catch (error) {
                setHasError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchProvider();
    }, []);

    const changeStringToAsteriskBetween = (string, startPosition, endPosition) => {
        const regex = new RegExp(`^.{${startPosition},${endPosition}}`);
        return string.replace(regex, (character) => '*'.repeat(character.length));
    };

    const createInvoicePayment = async () => {
        try {
            setLoading(true);
            const invoiceDueDate = today.setDate(today.getDate() + 2);
            const invoiceData = {
                auctionNoticeId: parseInt(auctionId, 10),
                providerId: provider?.id,
                typeInvoice: selectedSteps.plan.details.durabiliyPlan,
                dueDate: formatDate(invoiceDueDate, 'YYYY-MM-DD'),
                price: planDetails.billing.value,
                strategy: 'asaas_boleto',
            };
            const { data: reponseCreatedInvoice } = await CheckoutService.createInvoicePayment(
                invoiceData
            );
            const { data: reponseCreatedInvoiceData } = reponseCreatedInvoice;
            setSelectedSteps((prevState) => ({
                ...prevState,
                confirmation: {
                    selected: reponseCreatedInvoiceData.bankSlipUrl,
                    details: reponseCreatedInvoiceData,
                },
            }));
            setStep((oldState) => oldState + 1);
        } catch (error) {
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const createCreditCardPayment = async () => {
        try {
            setLoading(true);
            const creditCardDueDate = today;
            const { installments, expiry, number, cvc, ...paymentData } = paymentDetails;
            const [expiryMonth, expiryYear] = expiry.split('/');
            const holderName = paymentData.name;
            const rawCreditCardNumber = number.replace(/\s/g, '');
            const ccv = cvc;
            const creditCardData = {
                auctionNoticeId: parseInt(auctionId, 10),
                providerId: provider?.id,
                typeInvoice: selectedSteps.plan.details.durabiliyPlan,
                dueDate: formatDate(creditCardDueDate, 'YYYY-MM-DD'),
                price: planDetails.billing.value,
                installments: parseInt(installments, 10),
                strategy: 'asaas_credit_card',
                data: {
                    ...paymentData,
                    holderName,
                    expiryMonth,
                    expiryYear,
                    ccv,
                    number: rawCreditCardNumber,
                    email: provider?.emailForBilling,
                    cpfCnpj: provider?.docNumber,
                    postalCode: provider?.zipCode,
                    addressNumber: provider?.numberPlace,
                    addressComplement: provider?.complementPlace,
                    phone: provider?.phoneNumber,
                    mobilePhone: provider?.phoneNumber,
                },
            };

            const { data } = await CheckoutService.createInvoicePayment(creditCardData);
            setSelectedSteps((prevState) => ({
                ...prevState,
                confirmation: { selected: data.invoiceUrl, details: data },
            }));
            setStep((oldState) => oldState + 1);
        } catch (error) {
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const createInvoicePostPaid = async () => {
        const invoice = {
            auctionNoticeId: parseInt(auctionId, 10),
            typeInvoice: selectedSteps.plan.details.durabiliyPlan,
            price: 0,
            planId: Number(selectedSteps.plan.details.id),
        };
        try {
            setLoading(true);
            await invoiceRequests.createPostPaid(invoice);
            setStep((oldState) => oldState + 1);
        } catch (error) {
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const processPayment = () => {
        if (!paymentTitle) {
            createInvoicePostPaid();
        }

        if (paymentTitle === CHECKOUT.INVOICE) {
            createInvoicePayment();
        } else if (paymentTitle === CHECKOUT.CREDIT_CARD) {
            createCreditCardPayment();
        }
    };

    if (hasError) {
        return <GenericError />;
    }

    const hasProvider = !(provider === undefined);

    return (
        <ConfirmationView
            {...{
                planTitle,
                planDetails,
                benefits,
                paymentTitle,
                paymentDetails,
                hasProvider,
                provider,
                processPayment,
                loading,
                changeStringToAsteriskBetween,
                paymentCondition,
            }}
        />
    );
}
