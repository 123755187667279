import { Grid, Typography } from '@material-ui/core';
import Footer from 'common/components/base/footer';
import { FC, useContext, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';
import { usePageContext } from 'common/components/base/pages/context';
import { usePlatformContext } from 'routes/platform.context';
import ImgLink from 'common/components/ImgLink';
import { SignUpProps } from './props';
import { Container, Content } from './styles';
import TaxpayerIdentificationNumber from './Stepps/taxpayerIdentificationNumber';
import Identification from './Stepps/identification';
import CreatePassword from './Stepps/createPassword';
import ChoseUserType from './Stepps/choseUserType';
import { SignUpContext } from './context/SignUpContext';

const SignUp: FC<SignUpProps> = () => {
    const { platform } = usePlatformContext();
    const { setTitle } = usePageContext();
    const { currentStep } = useContext(SignUpContext);
    const initialImage = useRef(null);
    const sideContent = useRef(null);

    const hideSignupCitizen = !!platform?.generalSettings?.general?.hideSignupCitizen;

    useEffect(() => {
        setTitle('');
    }, []);

    useEffect(() => {
        gsap.from(initialImage.current, { x: -60, opacity: 0, duration: 2.5, ease: 'expo.out' });
    }, [initialImage, currentStep === 3]);

    useEffect(() => {
        gsap.from(sideContent.current, { x: 60, opacity: 0, duration: 2.5, ease: 'expo.out' });
    }, [sideContent, currentStep]);

    const { t } = useTranslation();

    return (
        <Container>
            <Content>
                <Grid item xs={12} ref={initialImage} sm={6}>
                    {currentStep < 3 ? (
                        <ImgLink
                            className='side-image'
                            src={
                                platform?.generalSettings?.images?.signupUrlImage ||
                                '/assets/images/svgs/sign_up_person_img.png'
                            }
                            alt='person'
                            href={platform?.generalSettings?.images?.signupUrlLink}
                        />
                    ) : (
                        <ImgLink
                            href={platform?.generalSettings?.images?.signupUrlLink}
                            className='side-image side-image-success'
                            src={
                                platform?.generalSettings?.images?.signupUrlImage ||
                                '/assets/images/svgs/sign_up_person_success_img.png'
                            }
                            alt='person'
                        />
                    )}
                </Grid>
                {hideSignupCitizen ? (
                    <Grid item ref={sideContent} xs={12} sm={6}>
                        <Typography variant='h4' color='primary' gutterBottom>
                            {t('sign-up.pages.stepps.index.info-create-free-account-disabled-1')}
                            <strong>
                                {t(
                                    'sign-up.pages.stepps.index.info-create-free-account-disabled-2'
                                )}
                            </strong>
                        </Typography>
                        <Typography variant='body2'>
                            {t('sign-up.pages.stepps.index.info-contact-suport')}
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item ref={sideContent} xs={12} sm={6}>
                        {currentStep === 3 ? (
                            <Typography variant='h3' color='secondary'>
                                {t('sign-up.pages.stepps.index.info-sucess-created-account-1')}
                                <strong>
                                    {t('sign-up.pages.stepps.index.info-sucess-created-account-2')}
                                </strong>
                            </Typography>
                        ) : (
                            <>
                                <Typography variant='h3' color='primary'>
                                    {t('sign-up.pages.stepps.index.info-create-free-account-1')}
                                    <strong>
                                        {t('sign-up.pages.stepps.index.info-create-free-account-2')}
                                    </strong>
                                </Typography>
                            </>
                        )}
                        {currentStep === 0 && <TaxpayerIdentificationNumber />}
                        {currentStep === 1 && <Identification />}
                        {currentStep === 2 && <CreatePassword />}
                        {currentStep === 3 && <ChoseUserType />}
                    </Grid>
                )}
            </Content>

            <Footer />
        </Container>
    );
};

export default SignUp;
