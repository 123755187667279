import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from 'common/theme';

const useStyles = makeStyles((theme: Theme | any) => ({
    modalContent: {
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxHeight: '80vh',
            maxWidth: '70vw',
        },
    },
    ratingBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 2,
        paddingBottom: 2,
        gap: 4,
    },
    header: {
        [theme.breakpoints.down('sm')]: {
            textOverflow: 'ellipsis',
            width: 320,
            overflow: 'hidden',
        },
    },
    deleteButton: {
        backgroundColor: theme.palette.danger.main,
    },
}));

export default useStyles;
