import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProviderSearchTypes } from './enums/searchType.enum';

interface SelectSearchTypeProps {
    value: any;
    onChange: (value: any) => void;
}

const SelectSearchType: FC<SelectSearchTypeProps> = ({ value, onChange }) => {
    const { t } = useTranslation();

    const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='status'
            label='Tipo pesquisa'
            onChange={handleSelectChange}
            variant='outlined'
            select
            defaultValue={ProviderSearchTypes.companyOrTradingName}
            value={value}
        >
            <MenuItem value={ProviderSearchTypes.id}>Id</MenuItem>
            <MenuItem value={ProviderSearchTypes.companyOrTradingName}>
                {t('term.company-or-fantasy-name')}
            </MenuItem>
            <MenuItem value={ProviderSearchTypes.docNumber}>{t('term.docNumber')}</MenuItem>
            <MenuItem value={ProviderSearchTypes.state}>{t('term.state')}</MenuItem>
            <MenuItem value={ProviderSearchTypes.cityName}>{t('term.cityName')}</MenuItem>
        </TextField>
    );
};

export default SelectSearchType;
