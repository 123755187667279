import {
    Box,
    Chip,
    CircularProgress,
    Grid,
    MenuItem,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { TextField, MaskedTextField } from 'common/components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addressRequest } from 'clients/manager/address.requests';
import { OrganizationData as Organization } from 'clients/manager/interfaces/organization.interface';
import { legalIdentityRequests } from 'clients/manager/legal-identity-types.requests';
import { organizationRequests } from 'clients/manager/organization.requests';
import { useFormik } from 'formik';
import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
    addNotificationError,
    addNotificationSuccess,
    addNotificationWarning,
    validateCnpj,
} from 'common/utils';
import { debounce } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import * as yup from 'yup';
import { ufValues } from 'common/constants/uf-values.constant';
import { DataViaCep } from 'common/interfaces/via-cep-data';
import { regionsRequests } from 'clients/price-base/regions.requests';
import LoadingButton from 'common/components/loading-button';
import { InfoIcon } from 'common/icons';
import { IRegions, LegalIdentityTypes } from './props';

interface OrganizationGeneralDataProps {}

const OrganizationGeneralData: FC<OrganizationGeneralDataProps> = () => {
    const { t } = useTranslation();

    const [organization, setOrganization] = useState<Organization>();
    const [docNumberTextError, setDocNumberTextError] = useState('');
    const [legalIdentityTypes, setLegalIdentityTypes] = useState<LegalIdentityTypes[]>();

    const [loadingField, setLoadingField] = useState<boolean>(false);
    const [dataViaCep, setDataViaCep] = useState<DataViaCep>();
    const [errorSearchCEP, setErrorSearchCEP] = useState(false);
    const [errorLenghtCEP, setErrorLenghtCEP] = useState(false);
    const [regions, setRegions] = useState<IRegions[]>([]);

    const [regionOrganization, setRegionOrganization] = useState<any>([]);
    const [regionsValues, setRegionsValues] = useState<IRegions[]>([]);
    const [value, setValue] = useState<IRegions[]>(regionsValues);

    const [errorFetchRegions, setErrorFetchRegions] = useState(false);
    const [fetchingInitialRegions, setFetchingInitialRegions] = useState(true);
    const [updatingOrganization, setUpdatingOrganization] = useState(false);

    const getLegalIdentityTypes = async () => {
        try {
            const result = await legalIdentityRequests.listLegalIdentityType();
            const arrayIdentity: LegalIdentityTypes[] = Object.entries(result).map((objValue) => ({
                value: parseInt(objValue[0], 10),
                label: String(objValue[1]),
            }));
            setLegalIdentityTypes(arrayIdentity);
        } catch (err) {
            return err;
        }
    };

    const initialValues: Partial<Organization> = {
        organizationId: organization?.id,
        organizationName: organization?.organizationName,
        docNumber: organization?.docNumber,
        zipCode: organization?.zipCode,
        publicPlace: organization?.publicPlace,
        numberPlace: organization?.numberPlace,
        complementPlace: organization?.complementPlace,
        district: organization?.district,
        state: organization?.state,
        cityName: organization?.cityName,
        ibgeCodesRegion: organization?.ibgeCodesRegion,
        legalIdentityTypeId: organization?.legalIdentityTypeId,
        ibgeCode: organization?.ibgeCode,
    };

    const validationSchema = yup.object({
        organizationName: yup
            .string()
            .required(t('organizationName', { ns: 'validation' }))
            .min(10, t('organizationName-min-char', { ns: 'validation' })),
        docNumber: yup
            .string()
            .required(t('docNumber', { ns: 'validation' }))
            .min(14, t('docNumber-min-char', { ns: 'validation' })),
        zipCode: yup
            .string()
            .required(t('please-fill-in-the-cep', { ns: 'validation' }))
            .max(9, t('cep-min-number-character', { ns: 'validation' })),
        publicPlace: yup.string().required(t('address', { ns: 'validation' })),
        numberPlace: yup.string().required(t('number', { ns: 'validation' })),
        district: yup.string().required(t('district', { ns: 'validation' })),
        state: yup.string().required(t('state', { ns: 'validation' })),
        cityName: yup.string().required(t('city', { ns: 'validation' })),
    });

    const validateDocNumber = (e: any) => {
        const docNumber = e.target.value
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('/', '');

        if (docNumber.length === 14) {
            if (!validateCnpj(docNumber)) {
                setDocNumberTextError(
                    t('sign-up.pages.stepper-organization.identification.info-error-cnpj-invalid')
                );
            } else {
                setDocNumberTextError('');
            }
        }
    };

    const updateOrganization = async (organizationForm: Partial<Organization>) => {
        if (!organization) {
            return;
        }

        setUpdatingOrganization(true);

        try {
            const result = await organizationRequests.updateOrganization({
                ...organization,
                ...organizationForm,
                organizationId: Number(organization.id),
            });
            addNotificationSuccess({
                title: t('info.registration-update'),
                message: t('info.registration-success'),
            });

            setUpdatingOrganization(false);
            return result;
        } catch (err) {
            setUpdatingOrganization(false);
            addNotificationError({
                message: t('info.registration-update-error'),
            });
        }
    };

    const getOrganization = async () => {
        const result = await organizationRequests.getOrganization();

        ReactDOM.unstable_batchedUpdates(() => {
            setOrganization(result);
            setValue([
                {
                    ibgeCode: result.ibgeCode,
                    name: result.cityName,
                    state: '',
                    uf: result.state,
                },
            ]);
            setRegionOrganization([
                {
                    name: result.cityName,
                    ibgeCode: result.ibgeCode,
                    uf: result.state,
                },
            ]);
        });
        setLoadingField(false);

        return result;
    };

    const form = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => updateOrganization(values),
        validateOnMount: true,
        enableReinitialize: true,
    });

    useEffect(() => {
        if (regionsValues.length > 0) {
            form.setFieldValue(
                'ibgeCodesRegion',
                regionsValues.map((item) => item.ibgeCode).join(',')
            );
        } else {
            form.setFieldValue('ibgeCodesRegion', initialValues.ibgeCode);
        }
    }, [regionsValues]);

    useEffect(() => {
        getOrganization();
        getLegalIdentityTypes();
    }, []);

    const getRegions = async (event) => {
        const { value } = event.target;

        if (value.length <= 2) {
            return;
        }

        try {
            const result = await addressRequest.getRegions(value);
            setRegions(result);
        } catch (err) {
            addNotificationWarning({
                title: t('term.err'),
                message: t('mkp.search.not-found-city'),
            });
        }
    };

    const onChangeInputRegions = debounce(getRegions, 600);

    const getRegionsValue = async (ibgeCodeRegion?: string) => {
        if (!organization?.ibgeCodesRegion && !ibgeCodeRegion) {
            setFetchingInitialRegions(false);
            return;
        }

        try {
            const regions = await regionsRequests.getRegionsByCityOrIbgeIds(
                String(ibgeCodeRegion ?? organization?.ibgeCodesRegion).split(',')
            );

            ReactDOM.unstable_batchedUpdates(() => {
                setRegionsValues(regions);
                setFetchingInitialRegions(false);
            });
        } catch (error) {
            setErrorFetchRegions(true);
        }
    };

    const onBlurCep = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const cepValue = e.target.value;
        const cep = cepValue?.replace(/[^0-9]/g, '');
        if (cep?.length > 8) {
            setErrorLenghtCEP(true);
            setTimeout(() => {
                setErrorLenghtCEP(false);
            }, 3000);
        }
        if (cep?.length !== 8) {
            return;
        }

        const request = async () => {
            const data = await addressRequest.getAddress(cep);
            setDataViaCep(data);

            if (!data.localidade) {
                setErrorSearchCEP(true);
            } else {
                setErrorSearchCEP(false);
            }

            form.setValues({
                ...form.values,
                publicPlace: data.logradouro,
                district: data.bairro,
                cityName: data.localidade,
                state: data.uf,
                ibgeCode: data.ibge,
                ibgeCodesRegion: data.ibge,
            });
            await getRegionsValue(data.ibge);
        };

        request();
    };

    useEffect(() => {
        getRegionsValue();
    }, [organization?.ibgeCodesRegion]);

    if (!organization) {
        return (
            <Grid container>
                {new Array(4).fill(null).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Skeleton key={index} height='45px' width='100%' />
                ))}
            </Grid>
        );
    }

    return (
        <Grid container>
            {organization && (
                <Box>
                    <Grid container spacing={2}>
                        {typeof form.values.legalIdentityTypeId === 'number' && (
                            <Grid item md={4} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    name='legalIdentityTypeId'
                                    label={t('term.select-type')}
                                    value={form.values.legalIdentityTypeId}
                                    onChange={form.handleChange}
                                    error={
                                        form.touched.legalIdentityTypeId &&
                                        Boolean(form.errors.legalIdentityTypeId)
                                    }
                                    helperText={
                                        form.touched.legalIdentityTypeId &&
                                        form.errors.legalIdentityTypeId
                                    }
                                >
                                    {legalIdentityTypes &&
                                        legalIdentityTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                        )}
                        <Grid item md={4} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                label={t('term.organization-name')}
                                name='organizationName'
                                value={form.values.organizationName}
                                onChange={form.handleChange}
                                error={
                                    form.touched.organizationName &&
                                    Boolean(form.errors.organizationName)
                                }
                                helperText={
                                    form.touched.organizationName && form.errors.organizationName
                                }
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            <MaskedTextField
                                fullWidth
                                label='CNPJ'
                                mask='99.999.999/9999-99'
                                name='docNumber'
                                value={form.values.docNumber}
                                onChange={form.handleChange}
                                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                                    validateDocNumber(e);
                                }}
                                error={form.touched.docNumber && Boolean(form.errors.docNumber)}
                                helperText={form.touched.docNumber && form.errors.docNumber}
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    {docNumberTextError && (
                        <Typography color='error' style={{ marginTop: 20 }}>
                            {docNumberTextError}
                        </Typography>
                    )}
                    <Grid container spacing={2}>
                        <Grid item md={2} sm={12} xs={12}>
                            <MaskedTextField
                                mask='99999-999'
                                fullWidth
                                label='CEP'
                                name='zipCode'
                                value={form.values.zipCode}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    form.setFieldValue(
                                        'zipCode',
                                        e.target.value.replace(/[^0-9]/g, '')
                                    );
                                }}
                                error={form.touched.zipCode && Boolean(form.errors.zipCode)}
                                helperText={form.touched.zipCode && form.errors.zipCode}
                                onBlur={(e: any) => onBlurCep(e)}
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={8} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                disabled={Boolean(organization.publicPlace && true)}
                                label={t('term.address')}
                                name='publicPlace'
                                value={form.values.publicPlace}
                                onChange={form.handleChange}
                                error={form.touched.publicPlace && Boolean(form.errors.publicPlace)}
                                helperText={form.touched.publicPlace && form.errors.publicPlace}
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={2} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                label={t('term.number')}
                                name='numberPlace'
                                value={form.values.numberPlace}
                                onChange={form.handleChange}
                                error={form.touched.numberPlace && Boolean(form.errors.numberPlace)}
                                helperText={form.touched.numberPlace && form.errors.numberPlace}
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                label={t('term.complement')}
                                name='complementPlace'
                                value={form.values.complementPlace}
                                onChange={form.handleChange}
                                error={
                                    form.touched.complementPlace &&
                                    Boolean(form.errors.complementPlace)
                                }
                                helperText={
                                    form.touched.complementPlace && form.errors.complementPlace
                                }
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                label={t('term.district')}
                                name='district'
                                disabled={Boolean(organization?.state && true)}
                                value={form.values.district}
                                onChange={form.handleChange}
                                error={form.touched.district && Boolean(form.errors.district)}
                                helperText={form.touched.district && form.errors.district}
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                        {form.values.state && (
                            <Grid item md={3} sm={12} xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    disabled={Boolean(organization?.state && true)}
                                    name='state'
                                    label={t('term.select-state')}
                                    onChange={form.handleChange}
                                    error={form.touched.state && Boolean(form.errors.state)}
                                    helperText={form.touched.state && form.errors.state}
                                    value={form.values.state}
                                >
                                    {ufValues.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )}
                        <Grid item md={3} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                disabled={Boolean(organization.cityName && true)}
                                label={t('term.city')}
                                name='cityName'
                                value={form.values.cityName}
                                onChange={form.handleChange}
                                error={form.touched.cityName && Boolean(form.errors.cityName)}
                                helperText={form.touched.cityName && form.errors.cityName}
                                InputProps={{
                                    endAdornment: loadingField && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        </Grid>
                        {errorSearchCEP && (
                            <Typography color='error'>
                                {t('sign-up.pages.stepper-organization.address.info-error-cep')}
                            </Typography>
                        )}
                        {errorLenghtCEP && (
                            <Typography color='error'>
                                {t(
                                    'sign-up.pages.stepper-organization.address.info-error-cep-min-char'
                                )}
                            </Typography>
                        )}
                    </Grid>
                    {regionOrganization.length !== 0 && value.length !== 0 && (
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={11}>
                                <Autocomplete
                                    className='input-regions'
                                    fullWidth
                                    multiple
                                    id='search-region'
                                    value={regionsValues}
                                    onChange={(_, newValue: IRegions[]) => {
                                        setRegionsValues(newValue);
                                    }}
                                    disableClearable
                                    options={regions}
                                    getOptionLabel={(option) =>
                                        `${option.name} - ${option.uf.toUpperCase()}`
                                    }
                                    disabled={errorFetchRegions || fetchingInitialRegions}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                label={`${
                                                    option.name
                                                } - ${option.uf.toUpperCase()}`}
                                                {...getTagProps({ index })}
                                                disabled={option.ibgeCode === form.values.ibgeCode}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('sign-up.region.search-regions')}
                                            onChange={onChangeInputRegions}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Tooltip
                                    title={t('sign-up.region.tooltip-info') as string}
                                    aria-label='info'
                                >
                                    <div>
                                        <InfoIcon color='secondary' />
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container alignContent='center' justifyContent='flex-end'>
                        <Box mt={2}>
                            <LoadingButton
                                className='btn-submit'
                                size='small'
                                variant='contained'
                                color='primary'
                                type='submit'
                                onClick={() => form.submitForm()}
                                {...(updatingOrganization
                                    ? {
                                          loading: {
                                              text: `${t('term.updating')}..`,
                                          },
                                      }
                                    : {})}
                            >
                                {t('term.save-data')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Box>
            )}
        </Grid>
    );
};

export default OrganizationGeneralData;
