import { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { usePageContext } from 'common/components/base/pages/context';
import Breadcrumb from 'common/components/base/breadcrumb';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import EmptyData from 'common/components/empty-data';
import { Spin } from 'common/components/base/spin';
import { DocumentProcessType } from 'clients/manager/interfaces';
import {
    DocumentTemplate,
    DocumentTemplateType,
} from 'clients/manager/interfaces/document-templates.interface';
import { addNotificationWarning } from 'common/utils';
import { TemplateLibraryProps } from './props';
import ModalCreateTemplate from '../../components/modal-create-template';
import { useStyles } from './styles';
import PreviewTemplate from '../../components/preview-template';
import SelectTemplateType2 from '../../components/select-template-type2';
import TemplateLibraryCard from '../../components/template-library-card';
import ProcessTypeList from '../../components/process-type-list';
import SupplyCategoriesList from '../../components/supply-categories-list';

interface TemplateLibraryFilters {
    type?: DocumentTemplateType;
    processType?: DocumentProcessType;
    supplyCategoryId?: number;
    offset: number;
    limit: number;
}

const TemplateLibrary: FC<TemplateLibraryProps> = () => {
    const { t } = useTranslation();
    const [templateFilters, setTemplateFilters] = useState<TemplateLibraryFilters>({
        limit: 30,
        offset: 0,
    });
    const [textSearch, setTextSearch] = useState('');
    const [fetchingTemplates, setFetchingTemplates] = useState(true);
    const [creatingTemplate, setCreatingTemplate] = useState<{
        opened: boolean;
        fromTemplateId?: string;
    }>({
        opened: false,
        fromTemplateId: undefined,
    });
    const [templates, setTemplates] = useState<{ count: number; data: DocumentTemplate[] }>({
        count: 0,
        data: [],
    });
    const [previewTemplateId, setPreviewTemplateId] = useState<string | undefined>();

    const { setTitle } = usePageContext();
    const classes = useStyles();

    const history = useHistory();

    const getTemplates = async () => {
        try {
            const response = await templateRequests.getTemplates({
                limit: templateFilters.limit,
                offset: templateFilters.offset,
                params: {
                    public: true,
                    processType: templateFilters.processType,
                    type: templateFilters.type,
                    q: textSearch,
                    supplyCategoryId: templateFilters.supplyCategoryId,
                },
            });

            if (templateFilters.offset === 0) {
                setTemplates({
                    data: response?.data ?? [],
                    count: response?.meta?.count ?? 0,
                });
            } else {
                setTemplates((prevState) => ({
                    count: response?.meta?.count ?? 0,
                    data: [...prevState.data, ...response.data],
                }));
            }
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('editor.process.components.template-selector.error-onload-templates'),
            });
        }
        setFetchingTemplates(false);
    };

    useEffect(() => {
        setFetchingTemplates(true);
        getTemplates();
    }, [textSearch, templateFilters]);

    useEffect(() => {
        setTitle(t('shared.pages.menu-header.option.templates-library'));
        getTemplates();
    }, []);

    const loadMoreTemplates = () => {
        setTemplateFilters((prevState) => ({
            ...prevState,
            offset: prevState.offset + prevState.limit,
        }));
    };

    const handleUpdateFilters = (filters: Partial<TemplateLibraryFilters>) => {
        setTemplateFilters((prevState) => ({
            ...prevState,
            ...filters,
            offset: 0,
        }));
    };

    const debounceSearchText = debounce((text: string) => setTextSearch(text), 500);

    const getRender = () => {
        if (!fetchingTemplates && !templates.data.length) {
            return (
                <EmptyData>
                    <p>{t('editor.templates.components.templates.pages.none-model')}</p>
                </EmptyData>
            );
        }

        if (fetchingTemplates && templateFilters.offset === 0) {
            return Array.from(new Array(3)).map(() => (
                <TemplateLibraryCard onPreviewTemplate={() => {}} onCreatingTemplate={() => {}} />
            ));
        }

        return templates.data.map((template) => (
            <TemplateLibraryCard
                key={template._id}
                onPreviewTemplate={() => setPreviewTemplateId(template._id)}
                onCreatingTemplate={() => {
                    setCreatingTemplate({
                        opened: true,
                        fromTemplateId: template._id,
                    });
                }}
                template={template}
            />
        ));
    };

    const getLoadMoreButtonRender = () => {
        if (fetchingTemplates && templateFilters.offset > 0) {
            return <Spin />;
        }

        if (fetchingTemplates && templates.data.length < templates.count) {
            return null;
        }

        if (templates.data.length < templates.count) {
            return (
                <Button
                    onClick={loadMoreTemplates}
                    size='small'
                    variant='contained'
                    color='primary'
                >
                    {t('term.load-more')}
                </Button>
            );
        }

        return null;
    };

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='modelos/biblioteca'
                paths={{
                    modelos: {
                        value: t('editor.templates.components.templates.pages.list-model'),
                        onClick: () => history.push('/processos/editor/modelos'),
                    },
                    biblioteca: { value: t('term.library'), disabled: true },
                }}
            />
            {creatingTemplate.opened && (
                <ModalCreateTemplate
                    fromTemplateId={creatingTemplate.fromTemplateId}
                    onCancel={() =>
                        setCreatingTemplate({ opened: false, fromTemplateId: undefined })
                    }
                />
            )}
            {previewTemplateId && (
                <PreviewTemplate
                    visible={!!previewTemplateId}
                    documentTemplateId={previewTemplateId}
                    onClose={() => {
                        setPreviewTemplateId(undefined);
                    }}
                />
            )}
            <Box mt={2}>
                <Grid container>
                    <Grid xs={2} md={3}>
                        <Box borderRadius='5px' bgcolor='#FFF' padding={1}>
                            <ProcessTypeList
                                selectedProcessType={templateFilters.processType}
                                onSelectProcessType={(processType) =>
                                    handleUpdateFilters({ processType })
                                }
                            />
                        </Box>
                        <Box marginTop={2} borderRadius='5px' bgcolor='#FFF' padding={1}>
                            <SupplyCategoriesList
                                selectedSupplyCategoryId={templateFilters.supplyCategoryId}
                                onSelectSupplyCategory={(supplyCategory) =>
                                    handleUpdateFilters({
                                        supplyCategoryId: supplyCategory?.id ?? undefined,
                                    })
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid xs={10} md={9}>
                        <Box marginLeft={1} bgcolor='#fff' padding={1} borderRadius={5}>
                            <Grid container alignItems='center'>
                                <Grid item xs={4}>
                                    <SelectTemplateType2
                                        selectedValue={templateFilters.type}
                                        onChange={(value) =>
                                            handleUpdateFilters({
                                                type: value >= 0 ? value : undefined,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box paddingLeft={2} className={classes.searchArea}>
                                        <TextField
                                            type='search'
                                            style={{ width: '100%', margin: '0 10px 0 0' }}
                                            name='template-search'
                                            label={t(
                                                'editor.templates.components.templates.pages.search-title-conte'
                                            )}
                                            onChange={(ev) =>
                                                debounceSearchText(ev.target.value?.toLowerCase())
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginTop={2} marginLeft={1}>
                            <Grid container>{getRender()}</Grid>
                        </Box>
                        <Box display='flex' marginTop={3} justifyContent='center'>
                            {getLoadMoreButtonRender()}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </PageWrapper>
    );
};

export default TemplateLibrary;
