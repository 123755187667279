import { makeStyles } from '@material-ui/core';
import { Theme } from 'common/theme';

interface StyleProps {
    isExceededMaximum: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    removeButton: {
        minWidth: '0px',
        padding: '10px',
        marginLeft: '10px',
        marginRight: '10px',
    },
    counterContainer: {
        display: 'flex',
        alignItems: 'center',
        border: 'solid 2px #1d1ce5',
        borderRadius: '4px',
        backgroundColor: '#fff',
        overflow: 'hidden',
    },
    count: {
        textAlign: 'center',
        width: '120px',
        padding: '0px 5px 0px 5px',
        fontSize: '20px',
        color: '#1d1ce5',
        fontFamily: 'Roboto',
        fontWeight: 500,
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
            background: 'red',
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
            textAlign: 'center',
        },
    },
    actionButton: {
        minWidth: '0px',
        color: 'white',
        borderRadius: '0px',
        padding: '10px',
    },
    numberInput: {
        minWidth: '0px',
        width: '100px',
        height: '28px',
    },
    suffix: {
        fontWeight: 'bold',
        color: (props) =>
            props.isExceededMaximum ? theme.palette.danger.main : theme.palette.text?.primary,
    },
}));
