import { MenuItem } from '@material-ui/core';
import { SelectSegmentCategories } from 'clients/manager/interfaces/segment-categories-providers.interface';
import { TextField } from 'common/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectStatusSegmentsCategoryProps } from './props';

const SelectStatusSegmentsCategoryView: FC<SelectStatusSegmentsCategoryProps> = ({
    value,
    onChange,
}) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            name='suppliesAs'
            label='Categoria de Fornecedor'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            variant='outlined'
            select
            value={value}
        >
            <MenuItem value={SelectSegmentCategories.manufacturer}>
                {t('term.manufacturer')}
            </MenuItem>
            <MenuItem value={SelectSegmentCategories.dealer}>{t('term.dealer')}</MenuItem>
            <MenuItem value={SelectSegmentCategories.commercialPartner}>
                {t('term.commercialPartner')}
            </MenuItem>
        </TextField>
    );
};

export default SelectStatusSegmentsCategoryView;
