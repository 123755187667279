import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse } from '../interfaces/paginated.interface';
import { GeneratedDocument } from './interfaces/generated-documents.interface';

interface ListAuctionNoticeFilesFilters {
    params: {
        auctionId: number;
    };
}

const generatedDocuments = {
    listAuctionNoticeFiles: async (filters: ListAuctionNoticeFilesFilters) =>
        doRequest<GenericResponse<GeneratedDocument>>(
            managerApiInstance.post('/documents/generated/listPublicGeneratedDocuments', filters)
        ),
    sendGeneratedDocumentToSign: async (id: string) =>
        doRequest<GenericResponse<GeneratedDocument>>(
            managerApiInstance.post('/documents/generated/doSendGeneratedDocumentToSign', {
                generatedDocumentId: id,
            })
        ),
};

export { generatedDocuments };
