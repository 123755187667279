import { DocumentTemplateType } from './document-templates.interface';
import { GeneratedDocuments } from './generated-documents.interface';

interface ProcessDocumentsSteps {
    _id: string;
    name: string;
    order: number;
    flowId?: string;
    description?: string;
    documentType?: DocumentTemplateType;
    signers: ProcessDocumentsStepsSigner[];
    organizationId?: number;
    createdByUserId?: number;
    createdAt?: number;
    updatedAt?: number;
    updatedByUserId?: number;
    documentTemplateId?: string;
    auctionId: number;
    type: ProcessDocumentsStepsType;
    refProcessDocumentTemplateId?: string;
    uploadDocument?: ProcessDocumentsStepsUploadedDocument;
    status: ProcessDocumentsStepsStatus;
    visibility: ProcessDocumentsStepsVisibility;
    deletedAt?: number;
    generatedDocuments?: GeneratedDocuments[];
}

enum ProcessDocumentsStepsVisibility {
    public = 'public',
    private = 'private',
}

enum ProcessDocumentsStepsStatus {
    draft = 'draft',
    generated = 'generated',
    concluded = 'concluded',
    cancelled = 'cancelled',
    awaiting_signatures = 'awaiting_signatures',
}

enum ProcessDocumentsStepsType {
    document = 'document',
    upload = 'upload',
}

interface ProcessDocumentsStepsSigner {
    userId?: number;
    name: string;
    email: string;
    signedAt?: number;
}

interface ProcessDocumentsStepsUploadedDocument {
    location: string;
    name: string;
    createdAt: number;
}

export type {
    ProcessDocumentsSteps,
    ProcessDocumentsStepsSigner,
    ProcessDocumentsStepsUploadedDocument,
};
export { ProcessDocumentsStepsStatus, ProcessDocumentsStepsVisibility, ProcessDocumentsStepsType };
