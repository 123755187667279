import { Checkbox, CircularProgress, FormControlLabel, Grid } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { TextField } from 'common/components';
import { UseFormikResponse } from 'common/interfaces/formik.interface';
import { useTranslation } from 'react-i18next';
import { UserAuthorizationGroup } from 'modules/organization-users/interfaces/authorization-group-users.interface';
import { EditOrganizationUsersFormValues } from '../../edit-organization-users-form-schema';

interface OrganizationAuthorizationProfileFieldProps {
    form: UseFormikResponse<EditOrganizationUsersFormValues>;
    authorizationGroups?: UserAuthorizationGroup[];
    disabled?: boolean;
    loading?: boolean;
}

const OrganizationAuthorizationProfileField: React.FC<
    OrganizationAuthorizationProfileFieldProps
> = ({ form, authorizationGroups, disabled, loading }) => {
    const { t } = useTranslation();

    const hasError = form?.touched?.organization && !!form?.errors?.organization;
    const errorText = hasError ? form?.errors?.organization : '';

    return (
        <>
            {hasError && (
                <Grid item xs={12}>
                    <Alert severity='error'>
                        <strong>{t('term.err')}!</strong> {errorText}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <FormControlLabel
                    disabled={disabled}
                    label={`${t('term.administrator')}?`}
                    name='organization.isAdmin'
                    onChange={form.handleChange}
                    control={
                        <Checkbox checked={form?.values?.organization?.isAdmin} color='primary' />
                    }
                />
            </Grid>
            <Grid item xs={8}>
                <Autocomplete
                    fullWidth
                    disabled={disabled}
                    hidden={form?.values?.organization?.isAdmin}
                    multiple
                    value={form?.values?.organization?.authorizationGroups ?? []}
                    onChange={(_, values) => {
                        form.setFieldValue('organization.authorizationGroups', values);
                        form.setFieldValue('organization.isAdmin', false);
                    }}
                    options={authorizationGroups ?? []}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option?.name}
                    getOptionSelected={(option, value) => option?.name === value?.name}
                    renderOption={(option, state) => (
                        <>
                            <Checkbox checked={state?.selected} color='primary' />
                            {option?.name}
                        </>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('organization-users.pages.components.group-auth')}
                            placeholder={t('organization-users.pages.components.select-group-auth')}
                            InputProps={{
                                ...params?.InputProps,
                                endAdornment: loading && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                            error={hasError}
                        />
                    )}
                />
            </Grid>
        </>
    );
};

export default OrganizationAuthorizationProfileField;
