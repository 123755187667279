import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    addToCartButton: {
        width: '180px',
        height: '32px',
    },
}));

export { useStyles };
