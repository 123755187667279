import axios, { AxiosInstance, AxiosResponse } from 'axios';
import i18n from 'i18next';
import { CONSTANTS } from 'common/constants';
import { addNotificationError } from 'common/utils';
import { getAuthToken, getEnableHttpOnlyToken } from 'modules/auth/session.utils';

const setInterceptors = (instance: AxiosInstance) => {
    instance.interceptors.request.use(
        (config) => {
            const { enableHttpToken } = getEnableHttpOnlyToken();

            config.withCredentials = enableHttpToken;

            if (enableHttpToken) return config;

            const { token } = getAuthToken();

            if (token && config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            window.location.href = '/erro-sistema';
            Promise.reject(error);
        }
    );
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.token === 'WITHOUT_PERMISSION') {
                addNotificationError({
                    message: i18n.t('notifications.forbidden.message'),
                    title: i18n.t('notifications.forbidden.title'),
                });
            }
            return Promise.reject(error);
        }
    );

    return instance;
};
const setPriceBaseInterceptors = (instance: AxiosInstance) => {
    instance.interceptors.request.use(
        (config) => {
            const { token } = getAuthToken();

            if (token && config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            window.location.href = '/erro-sistema';
            Promise.reject(error);
        }
    );
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 403) {
                addNotificationError({
                    message: i18n.t('notifications.forbidden.message'),
                    title: i18n.t('notifications.forbidden.title'),
                });
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

const priceBaseInstance = setPriceBaseInterceptors(
    axios.create({
        baseURL: CONSTANTS.API_PRICE_BASE_URL,
        timeout: 30000,
    })
);

const managerApiInstance = setInterceptors(
    axios.create({
        baseURL: CONSTANTS.MANAGER_API_URL,
        timeout: 15000,
    })
);

const managerApiUploadFileInstance = setInterceptors(
    axios.create({
        baseURL: CONSTANTS.MANAGER_API_URL,
        timeout: 60000,
    })
);

const doRequest = async <T>(request: Promise<AxiosResponse<T>>) => {
    const req = await request;
    return req.data;
};

export { priceBaseInstance, managerApiInstance, managerApiUploadFileInstance, doRequest };
