/* eslint-disable react/destructuring-assignment */
import InputMask from 'react-input-mask';
import { TextFieldProps, TextField } from 'common/components';

const MaskedTextField = (props: any) => (
    <InputMask mask={props.mask} value={props.value} {...props} onChange={props.onChange}>
        {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField disabled={props.disabled} variant='filled' {...inputProps} />
        )}
    </InputMask>
);

export { MaskedTextField };
