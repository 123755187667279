import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';
import { formatPxToRem } from 'common/utils';

const textStyle: ClassNameMap<string> = {
    fontFamily: 'Public Sans Variable',
    fontSize: formatPxToRem(12),
    fontWeight: '400',
    lineHeight: formatPxToRem(16),
    color: '#4E4E59',
};

const useStyles = makeStyles<Theme>((_theme) => ({
    countdownContainer: {
        marginTop: formatPxToRem(8),
    },
    countdownText: {
        ...textStyle,
    },
    resendText: {
        ...textStyle,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: formatPxToRem(4),
    },
    resendLink: {
        ...textStyle,
        color: '#2323DB',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
            color: '#1010AF',
        },
        '&:active': {
            color: '#0A0A82',
        },
    },
}));

export default useStyles;
