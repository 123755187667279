import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { userRequests } from 'clients/manager/user.requests';
import { storage } from 'common/utils';
import { UserLanguages } from 'common/interfaces/user.interface';
import { getAuthUserToken } from 'modules/auth/session.utils';

const i18DetectionToken = 'I18NEXTLNG';

const languageDetector = {
    type: 'languageDetector' as LanguageDetectorAsyncModule['type'],
    async: true,
    async detect(callback) {
        try {
            const authUser = getAuthUserToken();

            if (!authUser?.userId) throw new Error('Token not found');

            const user = await userRequests.getAuthenticatedUser();
            if (user.language) return callback(user.language);
        } catch (error) {
            const storageLanguage = storage.get(i18DetectionToken, 'local');
            if (storageLanguage) return callback(storageLanguage);
        }
        callback(UserLanguages['pt-BR']);
    },
    init() {},
    cacheUserLanguage(lng: string) {
        storage.set(i18DetectionToken, lng, 'local');
    },
};

i18n.use(Backend)
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        fallbackLng: UserLanguages['pt-BR'],
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        ns: ['default', 'validation', 'notification', 'editor-variables', 'countries', 'getters'],
        defaultNS: 'default',
        nsSeparator: ':',
    });

export default i18n;
