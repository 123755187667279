import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { SegmentationTypeEnum } from 'common/interfaces/document-type-provider.interface';
import ProviderDocumentItem from '../provider-document-item';
import ProviderSingleDocumentItem from '../provider-sigle-document-item';
import { DocumentTypeProviderSegment } from '../../interfaces';

interface DocumentsGroupProps {
    categoryName: string;
    categoryDescription: string;
    documents: DocumentTypeProviderSegment[];
    disabled?: boolean;
    setDoNewRequest?: Dispatch<SetStateAction<boolean>>;
}

const DocumentsGroup: FC<DocumentsGroupProps> = ({
    categoryName,
    categoryDescription,
    documents,
    disabled = false,
    setDoNewRequest = undefined
}) => {
    const theme = useTheme();

    const othersDocuments = documents.filter(
        (document) => document.segmentationType !== SegmentationTypeEnum.single
    );

    const singleDocuments = documents
        .filter((document) => document.segmentationType === SegmentationTypeEnum.single)
        .map((document) => {
            const segmentsId = document.segmentsId ?? [];
            const segments = document.segments ?? [];

            const foundSegment =
                segmentsId.length > 0
                    ? segments.find((segment) => segment.id === segmentsId[0])
                    : undefined;

            const updatedDocument = {
                ...document,
                segments: foundSegment ? [foundSegment] : [],
                dateTimeInsert: document.dateTimeInsert,
                dateTimeUpdate: document.dateTimeUpdate,
            };

            return updatedDocument;
        })
        .map((document) => [document]);

    return (
        <Box>
            <Box mt={2}>
                <Alert variant='standard' color='info'>
                    <Typography
                        variant='h6'
                        style={{
                            marginTop: -6,
                            color: theme.palette.grey[800],
                        }}
                    >
                        {categoryName}
                    </Typography>
                    {categoryDescription}
                </Alert>
            </Box>
            <Box mt={2}>
                {othersDocuments.map((document) => {
                    if (document.segmentationType === SegmentationTypeEnum.single) {
                        const foundSegment = document?.segments?.find(
                            (segment) =>
                                document?.segmentsId?.[0] && segment.id === document.segmentsId[0]
                        );

                        if (foundSegment) document.segments = [foundSegment];
                    }

                    return <ProviderDocumentItem {...{ document }} disabled={disabled} setDoNewRequest={setDoNewRequest} />;
                })}
                {singleDocuments.length > 0 &&
                    singleDocuments.map((documentGroup, index) => (
                        <ProviderSingleDocumentItem
                            key={`single-${index}`}
                            documents={documentGroup}
                            disabled={disabled}
                        />
                    ))}
            </Box>
        </Box>
    );
};

export default DocumentsGroup;
