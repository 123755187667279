export enum LegalSupport {
    _8666_1993 = 0,
    _14133_2021_Art28_I = 1,
    _14133_2021_Art28_II = 2,
    _14133_2021_Art28_III = 3,
    _14133_2021_Art28_IV = 4,
    _14133_2021_Art28_V = 5,
    _14133_2021_Art74_I = 6,
    _14133_2021_Art74_II = 7,
    _14133_2021_Art74_III_a = 8,
    _14133_2021_Art74_III_b = 9,
    _14133_2021_Art74_III_c = 10,
    _14133_2021_Art74_III_d = 11,
    _14133_2021_Art74_III_e = 12,
    _14133_2021_Art74_III_f = 13,
    _14133_2021_Art74_III_g = 14,
    _14133_2021_Art74_III_h = 15,
    _14133_2021_Art74_IV = 16,
    _14133_2021_Art74_V = 17,
    _14133_2021_Art75_I = 18,
    _14133_2021_Art75_II = 19,
    _14133_2021_Art75_III_a = 20,
    _14133_2021_Art75_III_b = 21,
    _14133_2021_Art75_IV_a = 22,
    _14133_2021_Art75_IV_b = 23,
    _14133_2021_Art75_IV_c = 24,
    _14133_2021_Art75_IV_d = 25,
    _14133_2021_Art75_IV_e = 26,
    _14133_2021_Art75_IV_f = 27,
    _14133_2021_Art75_IV_g = 28,
    _14133_2021_Art75_IV_h = 29,
    _14133_2021_Art75_IV_i = 30,
    _14133_2021_Art75_IV_j = 31,
    _14133_2021_Art75_IV_k = 32,
    _14133_2021_Art75_IV_l = 33,
    _14133_2021_Art75_IV_m = 34,
    _14133_2021_Art75_V = 35,
    _14133_2021_Art75_VI = 36,
    _14133_2021_Art75_VII = 37,
    _14133_2021_Art75_VIII = 38,
    _14133_2021_Art75_IX = 39,
    _14133_2021_Art75_X = 40,
    _14133_2021_Art75_XI = 41,
    _14133_2021_Art75_XII = 42,
    _14133_2021_Art75_XIII = 43,
    _14133_2021_Art75_XIV = 44,
    _14133_2021_Art75_XV = 45,
    _14133_2021_Art75_XVI = 46,
    _14133_2021_Art75_XVII = 60,
    _14133_2021_Art78_I = 47,
    _14133_2021_Art78_II = 48,
    _14133_2021_Art78_III = 49,
    _14133_2021_Art79 = 50,
    _14133_2021_Art79_I = 140,
    _14133_2021_Art79_II = 141,
    _14133_2021_Art79_III = 142,
    _10520_2002_Decreto10024_2019 = 999,
    _13303_2016 = 1000,
    _8958_1994_Decreto8241_2014 = 1001,
}
