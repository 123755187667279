import React, { useState } from 'react';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { debounce } from 'lodash';
import MaterialsService from 'modules/material/services/material.service';
import { ListMaterialData } from 'modules/material/interfaces/material.interface';
import SearchMaterialsModalView from './search-materials-modal-view';

interface SearchMaterialsModalProps {
    open: boolean;
    code?: string;
    onChange: (value: ListMaterialData | null) => any;
    onClose?: () => void;
}

const SearchMaterialsModal: React.FC<SearchMaterialsModalProps> = ({
    onClose,
    onChange,
    code,
    open,
}) => {
    const [body, setBody] = useState({ params: { q: '' } });

    const { data: materialsData, loading } = useQueryWithBody(
        MaterialsService.listMaterialsOrganization,
        body
    );
    const options = materialsData?.data ?? [];

    const debounceSearchMaterials = debounce((q: string) => {
        setBody({
            params: {
                q,
            },
        });
    }, 500);

    return (
        <SearchMaterialsModalView
            loading={loading}
            options={options}
            code={code}
            onChange={onChange}
            open={open}
            onClose={onClose}
            debounceSearchMaterials={debounceSearchMaterials}
        />
    );
};

export default SearchMaterialsModal;
