import { Grid, Breadcrumbs, Card, Typography } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import EditGroupAuthorizationTable from './components/EditGroupAuthorizationTable';

const EditGroupAuthorizationView = ({ form, readOnly }) => {
    const { t } = useTranslation();

    return (
        <>
            <PageWrapper>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Breadcrumbs color='primary'>
                        <Link to='/grupo-autorizacao' style={{ textDecoration: 'none' }}>
                            <Typography>
                                {t(
                                    'group-auth.pages.edit-group.components.edit-group-auth.group-auth'
                                )}
                            </Typography>
                        </Link>
                        {form.values.id && <Typography>{form.values.name || '...'}</Typography>}
                        {!form.values.id && (
                            <Typography>
                                {t(
                                    'group-auth.pages.edit-group.components.edit-group-auth.new-group'
                                )}
                            </Typography>
                        )}
                    </Breadcrumbs>

                    {!readOnly && (
                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            type='submit'
                            form='formGeneralData'
                            disabled={form.isSubmitting}
                        >
                            {t('term.save')}
                        </Button>
                    )}
                </Grid>

                <br />

                <Card>
                    <form id='formGeneralData' onSubmit={form.handleSubmit} noValidate>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography variant='h6'>
                                    {t(
                                        'group-auth.pages.edit-group.components.edit-group-auth.general-data'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={t(
                                        'group-auth.pages.edit-group.components.edit-group-auth.group-name'
                                    )}
                                    fullWidth
                                    name='name'
                                    value={form.values.name}
                                    onChange={form.handleChange}
                                    error={form.touched.name && !!form.errors.name}
                                    helperText={form.touched.name && form.errors.name}
                                    disabled={readOnly}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={t(
                                        'group-auth.pages.edit-group.components.edit-group-auth.group-description'
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                    name='description'
                                    value={form.values.description}
                                    onChange={form.handleChange}
                                    error={form.touched.description && !!form.errors.description}
                                    helperText={form.touched.description && form.errors.description}
                                    disabled={readOnly}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Card>

                <br />

                {form.values.categories && (
                    <EditGroupAuthorizationTable
                        categories={form.values.categories}
                        authorizationGroupId={form.values.id}
                        readOnly={readOnly}
                    />
                )}
            </PageWrapper>
        </>
    );
};

export default EditGroupAuthorizationView;
