import { FC, useState } from 'react';
import { useProcessDocumentsStepsContext } from 'modules/process/context/process-document-steps';
import ModalCreateDocument from '../modal-create-document';
import ListDocuments from '../list-documents';
import { useProcessFormContext } from '../../../../context/process-form.context';

interface DefaultDocumentsProps {}

const DefaultDocuments: FC<DefaultDocumentsProps> = () => {
    const [visibleModalCreateDocument, setVisibleModalCreateDocument] = useState(false);
    const { process } = useProcessFormContext();
    const { processDocumentsSteps } = useProcessDocumentsStepsContext();

    const onClickCreateDocument = () => {
        setVisibleModalCreateDocument(true);
    };

    return (
        <>
            {visibleModalCreateDocument && process && (
                <ModalCreateDocument
                    processDocumentsSteps={processDocumentsSteps}
                    onClose={() => setVisibleModalCreateDocument(false)}
                    process={process}
                />
            )}
            {process && (
                <ListDocuments onClickCreateDocument={onClickCreateDocument} process={process} />
            )}
        </>
    );
};

export default DefaultDocuments;
