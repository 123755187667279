import { Skeleton } from '@material-ui/lab';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { StyledProcessCommentAnonymizedComponent } from './styled';

const ProcessCommentAnonymizedComponent = () => (
    <StyledProcessCommentAnonymizedComponent>
        <Skeleton width={60} height={30} />
        <AiFillEyeInvisible color='var(--text-light-color)' />
    </StyledProcessCommentAnonymizedComponent>
);

const ProcessCommentTreatment = ({ children }) => {
    const formatLabel = (label, value) => (
        <p>
            {label.split(value).reduce((prev, current, i) => {
                if (!i) return [current];
                return prev.concat(
                    <ProcessCommentAnonymizedComponent key={value + current} />,
                    current
                );
            }, [])}
            {children?.[1]}
        </p>
    );

    return formatLabel(children[0], '[Anonimizado]');
};

export default ProcessCommentTreatment;
