import { PriceMountType } from 'common/interfaces/price.interface';

const getPriceMountTypeName = (priceMountType: PriceMountType) => {
    switch (priceMountType) {
        case PriceMountType.lowerPrice:
            return 'Menor preço';

        case PriceMountType.media:
            return 'Média';

        case PriceMountType.median:
            return 'Mediana';

        default:
            return '';
    }
};

export { getPriceMountTypeName };
