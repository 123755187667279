import { FC, useCallback } from 'react';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProcessDocumentsStepsStatus } from 'clients/manager/interfaces/process-documents-steps.interface';
import { useStyles } from './styles';

interface ChipDocumentStepStatusProps {
    status: ProcessDocumentsStepsStatus;
}

const ChipDocumentStepStatus: FC<ChipDocumentStepStatusProps> = ({ status }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getLabel = useCallback(() => {
        switch (status) {
            case ProcessDocumentsStepsStatus.awaiting_signatures:
                return t('info.waiting-signatures');

            case ProcessDocumentsStepsStatus.cancelled:
                return t('term.obsolete');

            case ProcessDocumentsStepsStatus.concluded:
                return t('term.concluded');

            case ProcessDocumentsStepsStatus.draft:
                return t('term.draft');

            case ProcessDocumentsStepsStatus.generated:
                return t('term.generated');

            default:
                return '';
        }
    }, [status]);

    const label = getLabel();

    return (
        <Chip className={classes.chip} label={label} color='primary' size='small' title={label} />
    );
};

export default ChipDocumentStepStatus;
