import { FC, useEffect, useState } from 'react';
import { UserFinancialData } from 'clients/manager/interfaces/user-financial-data.interface';
import { userFinancialDataRequests } from 'clients/manager/user-financial-data.requests';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';
import { TypeAccess } from 'common/interfaces/user.interface';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import ProviderBankDataView from './provider-bank-data-view';

export interface ProviderBankDataProps {
    idProvider?: number;
    disabled?: boolean;
}

const rowsPerPageOptions = [10, 20];

const ProviderBankData: FC<ProviderBankDataProps> = ({ idProvider, disabled = false }) => {
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const [financialData, setFinancialData] = useState<UserFinancialData[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [visibleModalFinancialData, setVisibleModalFinancialData] = useState(false);

    const getData = async () => {
        const financialData = await userFinancialDataRequests.listFinancialData(idProvider);
        const { data } = financialData;
        setFinancialData(data);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleClickNew = () => setVisibleModalFinancialData(true);

    const deleteFinancialDataItem = async (data: UserFinancialData) => {
        const resourceId = data.id;
        const providerId = data?.providerId ?? undefined;

        return await userFinancialDataRequests.deleteFinancialData({
            resourceId,
            providerId
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleClickDelete = async (data: UserFinancialData) => {
        const result = await deleteFinancialDataItem(data);
        
        if (!result?.data?.ok) {
            addNotificationError();
            return;
        }
        
        if (authUserTokenData?.typeAccess !== TypeAccess.management) setForceHasChanges(true);

        setFinancialData((prevState) => [
            ...prevState.filter((item) => item.id !== data.id),
        ]);

        addNotificationSuccess();
    };

    return (
        <ProviderBankDataView
            idProvider={idProvider}
            financialData={financialData}
            getData={getData}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClickDelete={handleClickDelete}
            handleClickNew={handleClickNew}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            setVisibleModalFinancialData={setVisibleModalFinancialData}
            visibleModalFinancialData={visibleModalFinancialData}
            disabled={disabled}
        />
    );
};

export default ProviderBankData;
