import autoTable from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { groupBy } from 'lodash';
import {
    getPriceMountTypeName,
    formatCpfOrCnpj,
    formatCurrency,
    getPriceOriginType,
    getExtractionType,
    addNotificationError,
} from 'common/utils';
import i18n from 'i18next';
import { Provider } from 'common/interfaces/provider.interface';
import { PriceList } from 'common/interfaces/price-list.interface';
import { User } from 'common/interfaces/user.interface';
import { RawItems } from 'clients/price-base/interfaces/raw-items.response.interface';
import { addFonts } from './shared/fonts';
import { addTitle } from './shared/title';
import { BottomLine, TopBottomLeftLine, TopBottomRightLine, TopLine } from './shared/lines';
import { addHeaders } from './shared/header';
import { addFooters } from './shared/footer';

export const createCompletePdf = (
    priceList: PriceList,
    authUser: User,
    pricesItem: RawItems[],
    providers: Provider[],
    onGeneratingDoc: (generating: boolean) => void
) => {
    const { t } = i18n;

    const getProviderLocation = (provider?: Provider) => {
        if (provider?.city && provider.uf) {
            return `${provider.city} / ${provider.uf}`;
        }

        if (provider?.city) {
            return provider.city;
        }
        return '-';
    };

    try {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
            format: 'a4',
            orientation: 'portrait',
        });

        addFonts(doc);
        addTitle(doc);
        doc.setFont('Roboto', 'normal');

        const groupedItems = groupBy(pricesItem, 'priceListItemId');

        priceList.priceListItems.forEach((item, i) => {
            const currentGroupedItem = groupedItems[item._id];
            const items = [
                [
                    t('term.prices'),
                    t('term.amount'),
                    t('term.calculation'),
                    t('term.estimated-price'),
                    t('term.total-value'),
                ],
                [
                    item.itemPrices.length,
                    item.amount ?? '-',
                    getPriceMountTypeName(item.priceMountType),
                    formatCurrency(item.referenceValue),
                    formatCurrency((item.amount ?? 1) * item.referenceValue),
                ],
                [item.description],
            ];

            /** @itemTable */
            autoTable(doc, {
                startY: i === 0 ? 38 : undefined,
                styles: {
                    overflow: 'linebreak',
                },
                margin: {
                    bottom: 15,
                },
                theme: 'plain',
                pageBreak: 'avoid',
                rowPageBreak: 'avoid',
                body: items,
                alternateRowStyles: undefined,
                useCss: true,
                columnStyles: {
                    0: { cellWidth: 30 },
                    1: { cellWidth: 33 },
                    2: { cellWidth: 33 },
                    3: { cellWidth: 43 },
                    4: { cellWidth: 43 },
                },
                didDrawCell: (data) => {
                    if (data.cursor) {
                        if (data.row.index === 0) {
                            TopLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        } else if (data.row.index === 1) {
                            BottomLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        } else {
                            TopLine(data);
                            BottomLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        }
                    }
                },
                didParseCell: (data) => {
                    data.cell.styles.font = 'Roboto';
                    data.cell.styles.fontSize = 9;
                    data.cell.styles.textColor = '#444';

                    if (data.row.section === 'body') {
                        data.cell.styles.fillColor = [243, 243, 243];
                        if (data.row.index === 0 || data.row.index === 1) {
                            data.cell.styles.cellPadding = {
                                top: 0.85,
                                bottom: 0.85,
                                left: 1.4,
                                right: 1.4,
                            };
                        }
                        if (data.row.index === 0) {
                            data.cell.styles.fontStyle = 'bold';
                        }
                        if (data.row.index === 1) {
                            data.cell.styles.halign = 'right';
                            if (data.column.index === 3) {
                                data.cell.styles.textColor = '#02AE81';
                                data.cell.styles.fontStyle = 'bold';
                            }
                        }
                        if (data.row.index === 2) {
                            data.cell.colSpan = 5;
                        }
                    }
                },
            });

            /** @referenceTable */
            currentGroupedItem?.forEach((currentItem, currItemIndex) => {
                const companyName =
                    !!currentItem.saleSourceId?.name &&
                    !!currentItem.saleSourceId?.publicCompanyName &&
                    currentItem.saleSourceId?.publicCompanyName !== '-'
                        ? `${currentItem?.saleSourceId.name?.trim()} - ${currentItem?.saleSourceId.publicCompanyName?.trim()}`
                        : currentItem?.saleSourceId.name?.trim() ||
                          currentItem?.saleSourceId.publicCompanyName?.trim();

                const reference = [
                    [
                        t('term.font'),
                        t('term.measure'),
                        t('term.public-entity'),
                        t('term.notice'),
                        t('term.bidding-date'),
                    ],
                    [
                        currentItem?.sourceType ? getExtractionType(currentItem?.sourceType) : '-',
                        currentItem?.unitMeasurement ?? '-',
                        companyName ?? '-',
                        currentItem?.saleSourceId.processNumber?.trim() ?? '-',
                        currentItem?.saleSourceId.saleDate
                            ? moment(currentItem?.saleSourceId.saleDate).format('DD/MM/YYYY')
                            : '-',
                    ],
                    [currentItem?.detailDescription ?? '-'],
                ];

                if (currItemIndex === 0) {
                    reference.unshift([`${t('term.references')}:`, '', '', '', '']);
                }

                autoTable(doc, {
                    styles: {
                        overflow: 'linebreak',
                    },
                    margin: {
                        bottom: 15,
                        left: 20,
                    },
                    theme: 'plain',
                    pageBreak: 'avoid',
                    rowPageBreak: 'avoid',
                    body: reference,
                    alternateRowStyles: undefined,
                    columnStyles: {
                        0: { cellWidth: 28 },
                        1: { cellWidth: 26 },
                        2: { cellWidth: 65 },
                        3: { cellWidth: 32 },
                        4: { cellWidth: 25 },
                    },
                    useCss: true,
                    didDrawCell: (data) => {
                        if (currItemIndex > 0) {
                            if (data.row.index === 0) {
                                TopLine(data);
                                TopBottomRightLine(data);
                                TopBottomLeftLine(data);
                            } else if (data.row.index === 1) {
                                BottomLine(data);
                                TopBottomRightLine(data);
                                TopBottomLeftLine(data);
                            } else {
                                TopLine(data);
                                BottomLine(data);
                                TopBottomRightLine(data);
                                TopBottomLeftLine(data);
                            }
                        } else if (data.row.index === 0) {
                            BottomLine(data);
                        } else if (data.row.index === 1) {
                            TopLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        } else if (data.row.index === 2) {
                            BottomLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        } else {
                            TopLine(data);
                            BottomLine(data);
                            TopBottomRightLine(data);
                            TopBottomLeftLine(data);
                        }
                    },
                    didParseCell: (data) => {
                        data.cell.styles.font = 'Roboto';
                        data.cell.styles.textColor = '#444';
                        data.cell.styles.fontSize = 9;
                        data.cell.styles.cellPadding = {
                            top: 0.85,
                            bottom: 0.85,
                            left: 1.4,
                            right: 1.4,
                        };

                        if (data.row.section === 'body') {
                            if (currItemIndex > 0) {
                                if (data.row.index === 0) {
                                    data.cell.styles.fontStyle = 'bold';
                                } else if (data.row.index === 1) {
                                    if ([0, 1, 2].includes(data.column.index)) {
                                        data.cell.styles.halign = 'left';
                                    } else {
                                        data.cell.styles.halign = 'right';
                                    }
                                } else if (data.row.index === 2) {
                                    data.cell.styles.fillColor = [247, 247, 247];
                                    data.cell.colSpan = 5;
                                }
                            } else if (data.row.index === 0) {
                                data.cell.styles.fontStyle = 'bold';
                                data.cell.styles.cellPadding = {
                                    top: 0.55,
                                    bottom: 0.55,
                                    left: 0,
                                    right: 0,
                                };
                            } else if (data.row.index === 1) {
                                data.cell.styles.fontStyle = 'bold';
                            } else if (data.row.index === 2) {
                                if ([0, 1, 2].includes(data.column.index)) {
                                    data.cell.styles.halign = 'left';
                                } else {
                                    data.cell.styles.halign = 'right';
                                }
                            } else if (data.row.index === 3) {
                                data.cell.colSpan = 5;
                            }
                        }
                    },
                });

                /** @priceTable */
                currentItem?.prices?.forEach((priceItem, priceItemIndex) => {
                    const provider = providers.find(
                        (currProvider) => currProvider._id === priceItem.providerId
                    );

                    const price = [
                        [
                            'CNPJ:',
                            provider?.cpfCnpj ? formatCpfOrCnpj(provider?.cpfCnpj) : '-',
                            `${t('term.value')}:`,
                        ],
                        [
                            `${t('term.supplier')}:`,
                            provider?.name ?? '-',
                            formatCurrency(priceItem.unitValue),
                        ],
                        [`${t('term.brand')}:`, priceItem.itemBrand ?? '-', ''],
                        [
                            `${t('term.description')}:`,
                            priceItem.manufacturerDetailDescription.length > 500
                                ? `${priceItem.manufacturerDetailDescription.slice(0, 500)}..`
                                : priceItem.manufacturerDetailDescription ?? '-',
                            '',
                        ],
                        [
                            `${t('term.manufacturer')}:`,
                            priceItem.manufacturerDescription ?? '-',
                            '',
                        ],
                        [
                            `${t('term.city-uf')}:`,
                            getProviderLocation(provider),
                            `${t('term.type-value')}`,
                        ],
                        [`${t('term.address2')}:`, '-', getPriceOriginType(priceItem.priceType)],
                        ['E-mail:', provider?.emails?.[0] ?? '-', ''],
                        [`${t('term.phone')}:`, provider?.phones?.[0] ?? '-', ''],
                    ];

                    if (priceItemIndex === 0) {
                        price.unshift([`${t('term.prices')}`, '', '']);
                    }

                    const lastAutoTable = (doc as any).lastAutoTable.finalY;

                    autoTable(doc, {
                        styles: {
                            overflow: 'linebreak',
                        },
                        // @TODO: validar se nao vai quebrar as páginas
                        startY: priceItemIndex > 0 ? lastAutoTable + 3 : undefined,
                        margin: {
                            left: 30,
                            bottom: 15,
                        },
                        theme: 'plain',
                        pageBreak: 'avoid',
                        rowPageBreak: 'avoid',
                        body: price,
                        alternateRowStyles: undefined,
                        useCss: true,
                        didDrawCell: (data) => {
                            const firstColumn = () => {
                                if (data.column.index === 0) {
                                    TopLine(data);
                                    TopBottomLeftLine(data);
                                } else if (data.column.index === 1) {
                                    TopLine(data);
                                } else if (data.column.index === 2) {
                                    TopLine(data);
                                    TopBottomLeftLine(data);
                                    TopBottomRightLine(data);
                                }
                            };

                            const secondColumn = () => {
                                if (data.column.index === 0) {
                                    TopBottomLeftLine(data);
                                } else if (data.column.index === 2) {
                                    TopBottomLeftLine(data);
                                    TopBottomRightLine(data);
                                }
                            };

                            const third = () => {
                                if (data.column.index === 0) {
                                    TopBottomLeftLine(data);
                                    BottomLine(data);
                                } else if (data.column.index === 1) {
                                    BottomLine(data);
                                } else if (data.column.index === 2) {
                                    TopBottomLeftLine(data);
                                    BottomLine(data);
                                    TopBottomRightLine(data);
                                }
                            };

                            if (priceItemIndex > 0) {
                                if (data.row.index === 0) {
                                    firstColumn();
                                } else if (data.row.index === 8) {
                                    third();
                                } else {
                                    secondColumn();
                                }
                            } else if (data.row.index === 0) {
                                BottomLine(data);
                            } else if (data.row.index === 1) {
                                firstColumn();
                            } else if (data.row.index === 9) {
                                third();
                            } else {
                                secondColumn();
                            }
                        },
                        columnStyles: {
                            0: { cellWidth: 22 },
                            1: { cellWidth: 104 },
                            4: { cellWidth: 26 },
                        },
                        didParseCell: (data) => {
                            data.cell.styles.font = 'Roboto';
                            data.cell.styles.textColor = '#444';
                            data.cell.styles.fontSize = 9;
                            data.cell.styles.cellPadding = {
                                top: 0.85,
                                bottom: 0.85,
                                left: 1.4,
                                right: 1.4,
                            };

                            if (data.row.section === 'body') {
                                data.cell.styles.fillColor = [247, 247, 247];
                                if (data.column.index === 0 || data.column.index === 2) {
                                    data.cell.styles.fontStyle = 'bold';
                                }
                                if (priceItemIndex === 0) {
                                    if (data.row.index === 0) {
                                        data.cell.styles.fillColor = [255, 255, 255];
                                        data.cell.styles.fontStyle = 'bold';
                                        data.cell.styles.cellPadding = {
                                            top: 0.55,
                                            bottom: 0.55,
                                            left: 0,
                                            right: 0,
                                        };
                                    } else if (data.row.index === 7) {
                                        if (data.column.index === 2) {
                                            data.cell.styles.fontStyle = 'normal';
                                        }
                                    }
                                } else if (data.row.index === 6) {
                                    if (data.column.index === 2) {
                                        data.cell.styles.fontStyle = 'normal';
                                    }
                                }
                            }
                        },
                    });
                });
            });
        });

        // addVerification(doc, priceList._id);
        addHeaders(
            doc,
            {
                name: authUser?.name as string,
            },
            priceList._id
        );
        addFooters(doc);
        doc.save(`${priceList.name}.pdf`);
        onGeneratingDoc(false);
    } catch (error) {
        onGeneratingDoc(false);
        addNotificationError({
            message: t('mkp.user-price-list.error-generate-document'),
        });
    }
};
