import { userRequests } from 'clients/manager/user.requests';
import { useFormik } from 'formik';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotificationApiError, validateCpf } from 'common/utils';
import * as yup from 'yup';
import { parsePhoneNumber } from 'react-phone-number-input';
import { UserLanguages } from 'common/interfaces/user.interface';
import { CONSTANTS } from 'common/constants';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import { useParams } from 'react-router';
import ProviderAddModalView from './ProviderAddModalView';
import { providerRequests } from '../../../../clients/manager/provider.requests';

export default function ProviderAddModal({ handleCloseModal, modalOpen }) {
    const { providerId, legalRepresentative, setLegalRepresentative, setListLegalRepresentative } =
        useContext(SignUpProviderContext);
    const [provider, setProvider] = useState<Provider>();
    const [country, setCountry] = useState(CONSTANTS.ibgeCodes.BRAZIL);
    const [cpfExist, setCpfExist] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [cpfMessageError, setCpfMessageError] = useState('');
    const [hasAlreadySearchedCpf, setHasAlreadySearchedCpf] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModalAllFields, setShowAllModalFields] = useState<boolean>(false);
    const [showCpfFields, setShowCpfFields] = useState<boolean>(false);
    const { t } = useTranslation();
    const { idProvider } = useParams<{ defaultTab: string; idProvider: string }>();

    const initialValues = {
        name: '',
        cpf: '',
        phone: '',
        job: '',
        email: '',
        providerId: providerId,
        identityDoc: '',
        typeOfRepresentative: '',
        country: country,
    };

    const validationSchema = yup.object({
        country: yup.string().required(t('term.required-field')),
        cpf:
            country === CONSTANTS.ibgeCodes.BRAZIL
                ? yup
                      .string()
                      .required(t('please-fill-in-the-CPF', { ns: 'validation' }))
                      .min(11, t('the-CPF-must-contain-at-least-11-digits', { ns: 'validation' }))
                : yup.string().required(t('please-fill-in-the-data', { ns: 'validation' })),
        name: showCpfFields ? yup.string() : yup.string().required(t('term.required-field')),
        phone: showCpfFields
            ? yup.string()
            : yup
                  .string()
                  .required(t('term.required-field'))
                  .phone({
                      message: t('please-enter-a-valid-phone', { ns: 'validation' }),
                  }),
        job: yup.string().required(t('term.required-field')),
        email: showCpfFields
            ? yup.string()
            : yup
                  .string()
                  .email(t('please-enter-a-valid-email', { ns: 'validation' }))
                  .required(t('term.required-field')),
        identityDoc: yup.string().required(t('term.required-field')),
        typeOfRepresentative: yup.string().required(t('term.required-field')),
    });

    const insertLegalRepresentative = async (values) => {
        try {
            setLoading(true);
            const data = await providerRequests.doAddUserAsLegalRepresentative({
                ...values,
                language:
                    country === CONSTANTS.ibgeCodes.BRAZIL
                        ? UserLanguages['pt-BR']
                        : UserLanguages.en,
                providerId: Number(values.providerId),
            });
            setLegalRepresentative(data);
        } catch (error) {
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const resetSearch = () => {
        setCpfExist(false);
        setHasAlreadySearchedCpf(false);
        setShowAllModalFields(false);
        setShowCpfFields(false);
        setHasError(false);
    };

    const setModalAsInitialState = () => {
        resetSearch();
        handleCloseModal();
    };

    const onSubmit = async (values, { resetForm }) => {
        let legalRepresentativeData = {};
        const parsedPhone = parsePhoneNumber(values.phone);

        let providerIdParam = values.providerId;

        if (idProvider) providerIdParam = idProvider;

        if (cpfExist) {
            legalRepresentativeData = {
                providerId: providerIdParam,
                name: 'Cadastrado',
                cpf: values.cpf.replace(/\D/g, ''),
                phone: '00000000000',
                phoneCountryCode: '55',
                country,
                email: 'cadastrado@cadastrado.com.br',
                job: values.job,
                identityDoc: values.identityDoc,
                typeOfRepresentative: values.typeOfRepresentative,
            };
        } else {
            legalRepresentativeData = {
                providerId: providerIdParam,
                name: values.name,
                cpf: values.cpf.replace(/\D/g, ''),
                phone: parsedPhone && parsedPhone.nationalNumber,
                phoneCountryCode: parsedPhone && parsedPhone.countryCallingCode,
                country,
                email: values.email,
                job: values.job,
                identityDoc: values.identityDoc,
                typeOfRepresentative: values.typeOfRepresentative,
            };
        }

        await insertLegalRepresentative(legalRepresentativeData);
        resetForm();
        setModalAsInitialState();
    };

    const legalRepresentativeForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const verifyIfCpfExist = async () => {
        const cpfRawNumber = legalRepresentativeForm.values.cpf.replace(/\D/g, '');
        const isCpfValid = validateCpf(cpfRawNumber);

        if (!isCpfValid && country === CONSTANTS.ibgeCodes.BRAZIL) {
            setCpfMessageError(t('sign-up.provider.registration-error-invalid-document'));
        } else {
            setCpfMessageError('');
            try {
                setLoading(true);
                const result = await userRequests.checkUserExist({
                    cpf: cpfRawNumber,
                    countryCode: country,
                });
                if (result.ok === true) {
                    setCpfExist(true);
                    setShowCpfFields(true);
                } else {
                    setCpfExist(false);
                    setShowAllModalFields(true);
                }
            } catch (error) {
                setHasError(true);
            } finally {
                setLoading(false);
                setHasAlreadySearchedCpf(true);
            }
        }
    };

    const hasCpfCorrectLength =
        country === CONSTANTS.ibgeCodes.BRAZIL
            ? !(legalRepresentativeForm.values.cpf.replace(/\D/g, '').length === 11)
            : !(legalRepresentativeForm.values.cpf.length > 0);

    useEffect(() => {
        const fetchLegalRepresentativeList = async () => {
            try {
                let providerIdParam = Number(providerId);

                if (idProvider) providerIdParam = Number(idProvider);

                setLoading(true);
                const data = await providerRequests.listLegalRepresentatives(providerIdParam);
                setListLegalRepresentative(data);
            } catch (error) {
                setHasError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchLegalRepresentativeList();
    }, [legalRepresentative]);

    return (
        <ProviderAddModalView
            {...{
                provider,
                modalOpen,
                setModalAsInitialState,
                legalRepresentativeForm,
                loading,
                hasAlreadySearchedCpf,
                cpfExist,
                showModalAllFields,
                showCpfFields,
                verifyIfCpfExist,
                hasCpfCorrectLength,
                hasError,
                cpfMessageError,
                setCountry,
                setLoading,
                setHasError,
                setCpfMessageError,
                resetSearch,
                country,
            }}
        />
    );
}
