export enum ProcessTab {
    general = 1,
    documents = 2,
    messages = 3,
    solicitations = 4,
    proposals = 5,
    authorization = 6,
    quantitative = 7,
    lotsOverview = 8,
    contracts = 9,
    integrations = 10,
}
