import { providerDashboardRequests } from 'modules/provider-dashboard/services/provider-dashboard.requests';
import { useQuery } from 'common/hooks/use-query.hook';
import ImportantProcessView from './important-proccess-view';

const ImportantProcess = () => {
    const { data: process, loading } = useQuery(providerDashboardRequests.getImportantProcess);

    return <ImportantProcessView {...{ process, loading }} />;
};

export default ImportantProcess;
