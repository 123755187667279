import { Theme, makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        minHeight: '212px',
        borderRadius: '8px',
        marginBottom: '0.6rem',
    },
    table: {
        backgroundColor: theme.palette.grey[100],
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    headTableCell: {
        fontWeight: 600,
    },
    providerStatusTableCell: {
        display: 'flex',
        gap: formatPxToRem(5),
        alignItems: 'center',
    },
    buttonContainer: {
        padding: '10px',
    },
}));

interface StyleProps {
    disabled: boolean;
}

const useTableRowStyles = makeStyles<Theme, StyleProps>(() => ({
    container: {
        opacity: (props) => (props.disabled ? 0.4 : 1),
        cursor: (props) => (props.disabled ? 'not-allowed;' : 'auto'),
    },
}));

export { useStyles, useTableRowStyles };
