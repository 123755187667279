import { FC } from 'react';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from 'common/components';
import IdentificationNumberTextField from '../identification-number-text-field';
import { FormTechManagerSearhViewProps } from './props';
import FormTechManager from '../form-tech-manager/form-tech-manager';

const FormTechManagerSearhView: FC<FormTechManagerSearhViewProps> = ({
    form,
    providerNotFoundedError,
    tecnicalResponsableForm,
    countries,
    countryOnChange,
    cpfExist,
    showCpfFields,
    hasCpfCorrectLength,
    loading,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        select
                        label={t('term.country')}
                        fullWidth
                        name='country'
                        value={tecnicalResponsableForm.values.country}
                        onChange={countryOnChange}
                        error={
                            tecnicalResponsableForm.touched.country &&
                            !!tecnicalResponsableForm.errors.country
                        }
                        helperText={
                            tecnicalResponsableForm.touched.country &&
                            tecnicalResponsableForm.errors.country
                        }
                    >
                        {countries.map(({ code, name }) => (
                            <MenuItem key={code} value={code}>
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={5}>
                    <IdentificationNumberTextField
                        identificationNumber={tecnicalResponsableForm.values.cpf}
                        country={tecnicalResponsableForm.values.country}
                        fieldName='cpf'
                        type='cpf'
                        {...tecnicalResponsableForm}
                    />
                </Grid>
                <Grid container item xs={3}>
                    <Box width={1} display='flex' justifyContent='flex-start' alignItems='center'>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => tecnicalResponsableForm.submitForm()}
                            disabled={hasCpfCorrectLength || loading}
                        >
                            {t('term.search-')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {cpfExist && (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography color='secondary'>
                            {t('sign-up.provider.registration-sucess-tecnical-responsible')}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {showCpfFields && (
                <FormTechManager form={form} providerNotFounded={providerNotFoundedError} />
            )}
        </>
    );
};

export default FormTechManagerSearhView;
