import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { ProcessDocumentsStepsResume } from 'clients/manager/documents/process-documents-steps.requests';
import { GeneratedDocuments } from 'clients/manager/interfaces/generated-documents.interface';
import { generatedDocumentsRequests } from 'clients/manager/documents/generated-documents.requests';
import { VisibilityIcon } from 'common/icons';
import StepSigners from 'common/components/contracts/components/step-signers';

interface GeneratedRowViewProps {
    step: ProcessDocumentsStepsResume;
}

const GeneratedRowView: FC<GeneratedRowViewProps> = ({ step }) => {
    const [fetchingDocs, setFetchingDocs] = useState(true);
    const [generatedDocuments, setGeneratedDocuments] = useState<GeneratedDocuments[]>([]);

    const { t } = useTranslation();

    const handlePreviewGeneratedClick = (generatedDocument: GeneratedDocuments) => {
        if (!generatedDocument.file?.location) {
            return;
        }

        window.open(`${generatedDocument.file?.location}`, '_blank');
    };

    const listGeneratedDocuments = async () => {
        try {
            const response = await generatedDocumentsRequests.listDocuments({
                params: {
                    auctionId: step.auctionId,
                    processDocumentTemplateId: step.refProcessDocumentTemplateId,
                    processDocumentStepId: step._id,
                },
            });
            ReactDOM.unstable_batchedUpdates(() => {
                setGeneratedDocuments(response.data);
                setFetchingDocs(false);
            });
        } catch (error) {
            setFetchingDocs(false);
        }
    };

    useEffect(() => {
        listGeneratedDocuments();
    }, []);

    const generatedDocumentsCount = step.totalGenerated;

    return (
        <Table size='small' stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>{t('term.name')}</TableCell>
                    <TableCell>{t('term.involved')}</TableCell>
                    <TableCell>{t('info.generated-in')}</TableCell>
                    <TableCell>{t('term.actions')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fetchingDocs &&
                    new Array(generatedDocumentsCount).fill(0).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                            <TableCell colSpan={4}>
                                <Skeleton
                                    variant='text'
                                    width='100%'
                                    height={20}
                                    style={{
                                        marginBottom: 6,
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                {!fetchingDocs &&
                    generatedDocuments.map((generatedDocument) => (
                        <TableRow key={generatedDocument._id}>
                            <TableCell component='th' scope='row'>
                                {generatedDocument.name}
                            </TableCell>
                            <TableCell>
                                <StepSigners signers={generatedDocument.signers ?? []} />
                            </TableCell>
                            <TableCell>
                                {moment(generatedDocument.createdAt).format('LLL')}
                            </TableCell>
                            <TableCell>
                                <IconButton size='small'>
                                    <VisibilityIcon
                                        onClick={() =>
                                            handlePreviewGeneratedClick(generatedDocument)
                                        }
                                    />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

export default GeneratedRowView;
