export function getDeferralLabel(value: number): {
    label: string;
    color: string;
} {
    const mapper = {
        [-1]: {
            label: 'INDEFERIDO',
            color: '#e61919',
        },
        1: {
            label: 'DEFERIDO',
            color: '#006228',
        },
        2: {
            label: 'PARCIALMENTE DEFERIDO',
            color: '#fd7e14',
        },
    };

    return mapper[value] ?? undefined;
}
