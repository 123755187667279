import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        color: 'white',
    },
    box: {
        minWidth: '400px',
        maxWidth: '600px',
    },
    button: {
        marginTop: '20px',
    },
}));

export default useStyles;
