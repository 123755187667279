import { Alert } from '@material-ui/lab';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { publicUsersRequests } from 'modules/public/services/public-users.requests';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const UnsubscribeLgpd = () => {
    const params = useParams<{
        token: string;
    }>();

    const { t } = useTranslation();

    if (!params?.token) return <></>;

    const { loading, error } = useQueryWithBody(
        publicUsersRequests.unsubscribeLgpdLegalBasis,
        params
    );

    return (
        <PageWrapper>
            {!loading && !error && (
                <Alert severity='success' style={{ width: '100%' }}>
                    <strong>{t('public.unsubscribe-lgpd.success-alert-title')}</strong>
                    <br />
                    {t('public.unsubscribe-lgpd.success-alert')}
                </Alert>
            )}
            {loading && (
                <Alert severity='info' style={{ width: '100%' }}>
                    <strong>{t('term.loading')}...</strong>
                </Alert>
            )}
            {!!error && (
                <Alert severity='error' style={{ width: '100%' }}>
                    <strong>{t('public.unsubscribe-lgpd.error-alert-title')}</strong>{' '}
                    {t('public.unsubscribe-lgpd.error-alert')}
                </Alert>
            )}
        </PageWrapper>
    );
};

export default UnsubscribeLgpd;
