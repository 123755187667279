import {
    Box,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Checkbox,
    Typography,
} from '@material-ui/core';
import ReactDOM from 'react-dom';
import {
    DocumentTypesProposal,
    documentTypesRequests,
} from 'clients/manager/document-types.requests';
import { documentsProposalRequests } from 'clients/manager/documents-proposal.requests';
import { groupBy } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'common/components/loading-button';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { processActions } from 'modules/process/process-actions';
import SearchDocumentTypesDialog from 'modules/document-types/pages/search-document-types/components/SearchDocumentTypesDialog';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import ErrorStatus from '../../../error-status';
import ExpandedContentCard from '../../../expand-content-card';
import { ProcessAccreditationDocumentsProps } from './props';

const ProcessAccreditationDocuments: FC<ProcessAccreditationDocumentsProps> = ({
    disabledExpand,
}) => {
    const [documentTypes, setDocumentTypes] = useState<DocumentTypesProposal[]>([]);
    const [initialSelected, setInitialSelected] = useState<number[]>([]);
    const [documentTypeDialog, setDocumentTypeDialog] = useState({ open: false });
    const {
        process,
        setAccreditationDocumentsError,
        requiredDocumentsTypesIds,
        setRequiredDocumentsTypesIds,
    } = useProcessFormContext();

    const haveDifferentDocuments = () => {
        if (initialSelected.length !== requiredDocumentsTypesIds.length) {
            return true;
        }
        const sortedinitialSelected = initialSelected.slice().sort();
        const sortedselected = requiredDocumentsTypesIds.slice().sort();
        for (let i = 0; i < sortedinitialSelected.length; i++) {
            if (sortedinitialSelected[i] !== sortedselected[i]) {
                return true;
            }
        }
        return false;
    };

    const { t } = useTranslation();

    const listDocumentTypes = async () => {
        if (!process) {
            return;
        }

        const response = await documentTypesRequests.listDocumentTypesToAuctionNoticeProposal(
            process.id
        );

        if (response?.data) {
            ReactDOM.unstable_batchedUpdates(() => {
                setDocumentTypes(response.data);
                const docIds = response.data
                    .filter((docType) => !!docType.documentProposal?.id)
                    .map((docType) => docType.id);
                setRequiredDocumentsTypesIds(docIds);
                setInitialSelected(docIds);
            });
        }
    };

    const updateDocumentTypeList = (newDocument: DocumentTypesProposal) => {
        setDocumentTypes((prevDocuments) => [...prevDocuments, newDocument]);
    };

    useEffect(() => {
        listDocumentTypes();
    }, [process?.id]);

    const handleClick = (_, id: number) => {
        const selectedIndex = requiredDocumentsTypesIds.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(requiredDocumentsTypesIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(requiredDocumentsTypesIds.slice(1));
        } else if (selectedIndex === requiredDocumentsTypesIds.length - 1) {
            newSelected = newSelected.concat(requiredDocumentsTypesIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                requiredDocumentsTypesIds.slice(0, selectedIndex),
                requiredDocumentsTypesIds.slice(selectedIndex + 1)
            );
        }

        setRequiredDocumentsTypesIds(newSelected);
    };

    const getFormErrorsLabels = () => (
        <p>
            <div>
                <span>{t('term.documents')}</span>
                {' : '}
                <span>{t('term.select-at-least')} 1</span>
            </div>
        </p>
    );

    const saveDocumentTypes = async () => {
        if (!process?.id) {
            return;
        }

        try {
            const response = await documentsProposalRequests.createDocumentsProposal({
                auctionId: process.id,
                documentTypesIds: requiredDocumentsTypesIds,
            });

            if (response?.data?.ok) {
                addNotificationSuccess();

                setInitialSelected(requiredDocumentsTypesIds);
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };
    const handleNew = () => setDocumentTypeDialog({ open: true });

    const isSelected = (id: number) => requiredDocumentsTypesIds.indexOf(id) !== -1;

    const groupData = groupBy(documentTypes, 'categoryDocument.id');
    const isValidDocuments = !!requiredDocumentsTypesIds.length;

    useEffect(() => {
        if (!isValidDocuments) {
            setAccreditationDocumentsError(true);
        } else {
            setAccreditationDocumentsError(false);
        }
    }, [requiredDocumentsTypesIds.length]);

    const getTableRender = () =>
        Object.keys(groupData).map((category) => {
            const documents = groupData[category];

            return (
                <>
                    <TableHead>
                        <TableCell colSpan={4}>
                            {documents[0].categoryDocument.categoryName}
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {documents.map((documentType) => {
                            const isItemSelected = isSelected(documentType.id);

                            return (
                                <TableRow
                                    role='checkbox'
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={documentType.id}
                                    onClick={(event) => handleClick(event, documentType.id)}
                                >
                                    <TableCell padding='checkbox'>
                                        <Checkbox
                                            checked={isItemSelected}
                                            disabled={processActions.cannotEditUntilDispute(
                                                process
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell title={documentType.documentName}>
                                        <Typography variant='body2'>
                                            {documentType.documentName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell title={documentType.documentDescription || '-'}>
                                        <Typography variant='caption'>
                                            {documentType.documentDescription || '-'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell colSpan={3} />
                        </TableRow>
                    </TableBody>
                </>
            );
        });

    const showDocumentsOrInfoMessage = () => {
        if (!documentTypes.length && !process) {
            return <Typography variant='caption'>{t('info.required-create-process')}</Typography>;
        }
        return (
            <TableContainer>
                <Table stickyHeader size='small'>
                    {getTableRender()}
                </Table>
            </TableContainer>
        );
    };

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <Box
                            width={1}
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Box display='flex'>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant='body1'>
                                        <b>{t('term.documents')}</b>
                                    </Typography>
                                </Box>
                                <Box ml={1} display='flex' alignItems='center'>
                                    <ErrorStatus
                                        isValid={isValidDocuments}
                                        title={getFormErrorsLabels()}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <LoadingButton
                                    style={{ marginLeft: 16 }}
                                    size='xsmall'
                                    variant='contained'
                                    color='primary'
                                    onClick={handleNew}
                                    disabled={!process?.biddingStageId}
                                >
                                    {t('term.create')}
                                </LoadingButton>
                                <LoadingButton
                                    style={{ marginLeft: 16 }}
                                    disabled={
                                        (!isValidDocuments &&
                                            process?.biddingStageId !== BiddingStageEnum.draft) ||
                                        !haveDifferentDocuments()
                                    }
                                    size='xsmall'
                                    variant='contained'
                                    color='primary'
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        saveDocumentTypes();
                                    }}
                                >
                                    {t('term.save')}
                                </LoadingButton>
                            </Box>
                        </Box>
                    }
                >
                    {showDocumentsOrInfoMessage()}
                    {documentTypeDialog.open && (
                        <SearchDocumentTypesDialog
                            setDocumentTypeDialog={setDocumentTypeDialog}
                            updateDocumentTypeList={updateDocumentTypeList}
                        />
                    )}
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessAccreditationDocuments;
