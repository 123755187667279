import { PurgeResume } from 'common/interfaces/purge.interface';
import { Card, CardContent, ModalConfirmTyping, ModalPosition } from '@licitar/ui-kit';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { CardContentContainer } from './styles';
import Resume from '../resume';
import GeneratePurgeData from '../generatePurgeData';
import Purger from '../purger';

interface Props {
    resume: PurgeResume;
    onGeneratePurgeData: (beforeAt: number) => void;
    onPurgeData: (purgeType: string) => void;
    onReload: () => void;
}

const PurgeToken = 'EXPURGAR';

const OverView: FC<Props> = ({ resume, onGeneratePurgeData, onReload, onPurgeData }) => {
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [modalKey, setModalKey] = useState<string>(nanoid());
    const [purgeType, setPurgeType] = useState<string | undefined>(undefined);
    const toggleModal = (open: boolean) => {
        setModalOpened(open);
        setModalKey(nanoid());
    };

    const { t } = useTranslation();

    return (
        <>
            <ModalConfirmTyping
                key={modalKey}
                opened={modalOpened}
                confirmToken={PurgeToken}
                message={`${t('mkp.settings.msg-part-1')} '${PurgeToken}' ${t(
                    'mkp.settings.msg-part-2'
                )}`}
                onClickOutSide={() => {
                    toggleModal(false);
                    setPurgeType(undefined);
                }}
                onConfirm={() => {
                    onPurgeData(purgeType as string);
                    setPurgeType(undefined);
                    toggleModal(false);
                }}
                position={ModalPosition.center}
            />
            <Card>
                <CardContent>
                    <CardContentContainer>
                        <Resume resume={resume} onReload={onReload} />
                        <Purger
                            onPurgeData={(currPurgeType: string) => {
                                setPurgeType(currPurgeType);
                                toggleModal(true);
                            }}
                        />
                        <GeneratePurgeData onGeneratePurgeData={onGeneratePurgeData} />
                    </CardContentContainer>
                </CardContent>
            </Card>
        </>
    );
};

export default OverView;
