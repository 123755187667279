interface DocumentVariables {
    _id: string;
    organizationId: number;
    name: string;
    description: string;
    createdByUserId?: number;
    values?: DocumentVariablesValues[];
    createdAt?: number;
    updatedAt?: number;
    updatedByUserId?: number;
    type: DocumentVariablesValueType;
    deletedAt?: number;
}

export enum DocumentVariablesValueType {
    date = 'date',
    number = 'number',
    text = 'text',
    select = 'select',
}

interface DocumentVariablesValues {
    value: string;
}

export type { DocumentVariables, DocumentVariablesValues };
