import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableRow: {
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#f3f3f3',
        },
    },
    input: {
        width: '100%',
        padding: theme.spacing(0.7),
        fontSize: '14px',
        border: `1px ${theme.palette.grey[400]} solid`,
    },
    textArea: {
        width: '100%',
        padding: theme.spacing(1.3),
        fontSize: '14px',
        border: `1px ${theme.palette.grey[400]} solid`,
    },
    table: {
        '& .MuiTableCell-body': {
            padding: theme.spacing(1),
        },
        '& .MuiTableCell-root': {
            padding: theme.spacing(1),
        },
    },
    tableRowIcon: {
        color: '#6d6d6d',
        fontSize: 17,
        cursor: 'pointer',
    },
    editIcon: {
        color: 'red',
    },
    textAlignRight: {
        textAlign: 'right',
    },
    valuesTextFontSize: {
        fontSize: '0.875rem',
    },
    termDelete: {
        color: '#E74C3C',
    },
    tableRowBorderNone: {
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#f3f3f3',
        },
        border: 'none',
    },
    borderNone: {
        border: 'none',
    },
    loadingButton: {
        marginRight: 16,
    },
}));

export const CustomLabel = withStyles(() =>
    createStyles({
        root: {
            '&:hover': {
                color: '#444',
            },
            '&$active': {
                color: '#444',
            },
        },
        icon: {
            color: 'inherit !important',
        },
    })
)(Box);
