import { Box, IconButton, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { usePlatformContext } from 'routes/platform.context';
import { OptionMenuGroup, OptionMenuGroupSubItem } from '../side-menu/menu.interface';
import { SuspendedOptionsProps } from './props';
import {
    Content,
    OptionGroup,
    OptionGroupItem,
    Option,
    LogoExpanded,
    LogoMinimized,
} from './styled';

const SuspendedOptions: FC<SuspendedOptionsProps> = ({
    menuTypeView,
    options,
    minimized,
    expanded,
    onExpanded,
    mobile,
    onClose,
}) => {
    const history = useHistory();
    const theme = useTheme<any>();

    const selectOption = (group: OptionMenuGroup, option: OptionMenuGroupSubItem) => {
        if (option.action) {
            return option.action();
        }

        if (group.path) {
            return history.push(`/${group.path}/${option.path}`);
        }
        return history.push(`/${option.path}`);
    };

    const { platform } = usePlatformContext();
    const defaultImageLogo = platform?.urlOriginalLogo;
    const defaultSmallImageLogo = platform?.urlOriginalIcon;

    return (
        <Content
            expanded={expanded}
            minimized={minimized}
            bgColor='white'
            onMouseEnter={() => onExpanded(true)}
            onMouseLeave={() => onExpanded(false)}
            mobile={mobile}
        >
            <Box
                display='flex'
                justifyContent={expanded || !minimized || mobile ? 'space-between' : 'center'}
                alignItems='center'
                margin='0 0 16px 0'
            >
                {expanded || !minimized || mobile ? (
                    <LogoExpanded src={defaultImageLogo} />
                ) : (
                    <LogoMinimized src={defaultSmallImageLogo} />
                )}
                {mobile && (
                    <IconButton
                        aria-label='close menu'
                        color={theme?.palette?.platform?.main}
                        className='extra-icons-close'
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                )}
            </Box>
            <Box>
                {options[menuTypeView]
                    .filter((group) => !group.showOnTypeUser)
                    .map((group, groupIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <OptionGroup key={groupIndex}>
                            <OptionGroupItem
                                showBorder={!!group.label}
                                expanded={expanded}
                                minimized={minimized}
                                mobile={mobile}
                            >
                                <span>{group.label}</span>
                            </OptionGroupItem>
                            {group.options
                                .filter(
                                    (groupOption) =>
                                        groupOption.visible ||
                                        (groupOption.visible === undefined &&
                                            !groupOption.showOnTypeUser)
                                )
                                .map((groupOption, groupOptionIndex) => {
                                    const Icon = groupOption.icon as FC<any>;

                                    return (
                                        <Option
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={groupOptionIndex}
                                            title={groupOption.label}
                                            onClick={() => {
                                                if (groupOption.disabled) {
                                                    return;
                                                }

                                                selectOption(group, groupOption);
                                                onClose();
                                                onExpanded(false);
                                            }}
                                            color={
                                                !expanded && minimized && !mobile
                                                    ? theme?.palette?.primary?.main
                                                    : theme?.palette?.grey?.['700']
                                            }
                                            expanded={expanded}
                                            minimized={minimized}
                                            mobile={mobile}
                                            disabled={!!groupOption.disabled}
                                        >
                                            {Icon && <Icon />}
                                            {(expanded || !minimized || mobile) &&
                                                (groupOption.action && !groupOption.disabled ? (
                                                    <span>{groupOption.label}</span>
                                                ) : (
                                                    <span>{groupOption.label}</span>
                                                ))}
                                        </Option>
                                    );
                                })}
                        </OptionGroup>
                    ))}
            </Box>
        </Content>
    );
};

export default SuspendedOptions;
