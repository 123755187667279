import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import { ListMaterialBody, ListMaterialResponse } from '../interfaces/material.interface';

const BASE_PATH = '/materials';

const listMaterialsOrganization = (body?: ListMaterialBody, config?: AxiosRequestConfig<any>) => {
    return doRequest<ListMaterialResponse>(
        managerApiInstance.post(`${BASE_PATH}/listMaterialsOrganization`, body, config)
    );
};

const getInitialData = () => {
    return managerApiInstance.post(`${BASE_PATH}/getInitialData`);
};

const getById = (id: string) => {
    return managerApiInstance.post(`${BASE_PATH}/getById`, { id });
};

const doSave = (values: any) => {
    return managerApiInstance.post(`${BASE_PATH}/doSave`, values);
};

const doDelete = (id: string) => {
    return managerApiInstance.post(`${BASE_PATH}/doDelete`, { id });
};

const MaterialsService = { listMaterialsOrganization, getInitialData, getById, doSave, doDelete };

export default MaterialsService;
