import { FC, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Yup from 'common/utils/yup-extended.utils';
import LoadingButton from 'common/components/loading-button';
import { processInvitesRequests } from 'clients/manager/process-invites.requests';
import { useFormik } from 'formik';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import {
    addNotificationApiError,
    addNotificationSuccess,
    processStageToBiddingStageId,
} from 'common/utils';
import { ProcessStage } from 'clients/manager/interfaces/auction-notice.interface';
import { ProcessInvite } from 'clients/manager/interfaces/process-invite.interface';
import { AddIcon, DeleteIcon } from 'common/icons';
import { ModalProcessInvitesProps } from './props';
import { useStyles } from './styles';
import InvitesTable from './invites-table';

type ProcessInviteForm = Pick<ProcessInvite, 'name' | 'email'>;

const initialInvite: ProcessInviteForm = {
    name: '',
    email: '',
};

const ModalProcessInvites: FC<ModalProcessInvitesProps> = ({ onClose }) => {
    const [visibleSendedInvites, setVisibleSendedInvites] = useState(false);
    const [sendingInvites, setSendingInvites] = useState(false);
    const [sendedInvites, setSendedInvites] = useState<ProcessInvite[]>([]);

    const { processForm, process } = useProcessFormContext();

    const { t } = useTranslation();
    const classes = useStyles();

    const handleSendInvites = async (invites: ProcessInviteForm[]) => {
        if (!process?.id) {
            return;
        }

        setSendingInvites(true);

        ReactDOM.unstable_batchedUpdates(async () => {
            try {
                const response = await processInvitesRequests.createProcessInvites(
                    process.id,
                    invites
                );
                if (response.data?.length) {
                    onClose();
                }
                setSendingInvites(false);
                return addNotificationSuccess();
            } catch (error) {
                setSendingInvites(false);
                addNotificationApiError(error);
            }
        });
    };

    const form = useFormik<ProcessInviteForm[]>({
        initialValues: [initialInvite],
        validationSchema: Yup.array().of(
            Yup.object({
                name: Yup.string().required(t('required-field', { ns: 'validation' })),
                email: Yup.string()
                    .required(t('required-field', { ns: 'validation' }))
                    .email('Email inválido'),
            })
        ),
        onSubmit: handleSendInvites,
    });

    const handleDeleteInvite = (index: number) => {
        if (index === 0 && form.values.length === 1) {
            return;
        }

        return form.setValues(form.values.filter((_, currentIndex) => currentIndex !== index));
    };

    const handleAddInvite = () => form.setValues([...form.values, initialInvite]);

    const listSendedInvites = async () => {
        if (!process?.id) {
            return;
        }

        const response = await processInvitesRequests.listProcessInvites(process.id);
        setSendedInvites(response?.data ?? []);
    };

    const inBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processForm?.values?.biddingStageId &&
            biddingStage.includes(processForm.values.biddingStageId),
        [processForm?.values?.biddingStageId]
    );

    useEffect(() => {
        listSendedInvites();
    }, [process?.id]);

    const getTableRender = () => (
        <>
            <TableContainer>
                <Table size='small' classes={{ root: classes.table }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left' padding='normal'>
                                {t('term.name')}
                            </TableCell>
                            <TableCell align='left' padding='normal'>
                                Email
                            </TableCell>
                            <TableCell width={70} align='center' padding='normal'>
                                {t('term.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {form.values.map((invite, index) => {
                            const key = `${index}`;

                            // eslint-disable-next-line react/no-array-index-key
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Input
                                            className={classes.input}
                                            placeholder={t('term.name')}
                                            fullWidth
                                            color='primary'
                                            name='name'
                                            value={invite.name}
                                            onChange={(event) =>
                                                form.setFieldValue(
                                                    `${key}.name`,
                                                    event.target.value
                                                )
                                            }
                                            autoFocus
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Input
                                            className={classes.input}
                                            placeholder='Email'
                                            fullWidth
                                            color='primary'
                                            name='email'
                                            value={invite.email}
                                            onChange={(event) =>
                                                form.setFieldValue(
                                                    `${key}.email`,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Box display='flex'>
                                            <IconButton
                                                size='small'
                                                onClick={() => handleDeleteInvite(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            {index === form.values.length - 1 && (
                                                <IconButton size='small' onClick={handleAddInvite}>
                                                    <AddIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

    const visibleTableInvites = inBiddingStage(
        processStageToBiddingStageId([ProcessStage.proposals])
    );

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={onClose}
            header={<span>{t('info.sending-invitations')}</span>}
        >
            <Box width={650}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='body2'>
                        {t('info.invites-message', { value: sendedInvites.length })}
                    </Typography>
                    {visibleTableInvites && visibleSendedInvites && (
                        <Button
                            onClick={() => setVisibleSendedInvites(false)}
                            color='primary'
                            size='xsmall'
                            variant='contained'
                        >
                            {t('info.close-view')}
                        </Button>
                    )}
                    {visibleTableInvites && !visibleSendedInvites && (
                        <Button
                            onClick={() => setVisibleSendedInvites(true)}
                            color='primary'
                            size='xsmall'
                            variant='contained'
                            disabled={!sendedInvites.length}
                        >
                            {t('info.view-sent-invitations')}
                        </Button>
                    )}
                </Box>
                {visibleSendedInvites || (!visibleTableInvites && sendedInvites.length) ? (
                    <Box mt={2}>
                        <InvitesTable invites={sendedInvites} />
                    </Box>
                ) : null}
                {visibleTableInvites && !visibleSendedInvites && (
                    <>
                        {getTableRender()}
                        <Box width={1} display='flex' justifyContent='flex-end' mt={2}>
                            <Button
                                onClick={onClose}
                                color='primary'
                                size='small'
                                style={{ marginRight: 8 }}
                            >
                                {t('term.cancel')}
                            </Button>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                onClick={form.submitForm}
                                autoFocus
                                disabled={!form.dirty || !!Object.keys(form.errors).length}
                                size='small'
                                {...(sendingInvites
                                    ? {
                                          loading: {
                                              text: `${t('term.sending')}..`,
                                          },
                                      }
                                    : {})}
                            >
                                {t('term.send')}
                            </LoadingButton>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default ModalProcessInvites;
