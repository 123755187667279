import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    chipStatusPending: {
        backgroundColor: theme.palette.grey[600],
        padding: theme.spacing(2),
    },
    chipStatusRejected: {
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(2),
    },
    chipStatusApproved: {
        backgroundColor: theme.palette.success.main,
        padding: theme.spacing(2),
    },
    chipStatusNotSent: {
        backgroundColor: theme.palette.grey[500],
        padding: theme.spacing(2),
    },
    chipStatusExpired: {
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(2),
        color: theme.palette.common.white,
    },
    chipReference: {
        backgroundColor: theme.palette.warning.main,
        padding: theme.spacing(2),
    },
    gridSegment: {
        paddingRight: theme.spacing(3),
    },
    tooltipSegment: {
        cursor: 'pointer',
    },
    chipSegment: {
        backgroundColor: theme.palette.info.main,
        padding: theme.spacing(1.4),
        marginRight: theme.spacing(0.5),
        maxWidth: '100%',
    },
    rejectedIcon: {
        color: theme.palette.error.main,
    },
    pendingIcon: {
        color: theme.palette.warning.main,
    },
    notSendIcon: {
        color: theme.palette.grey[500],
    },
    awaitAvaliationIcon: {
        color: theme.palette.grey[700],
    },
    expiredIcon: {
        color: theme.palette.error.main,
    },
    approvedIcon: {
        color: theme.palette.success.main,
    },
    updatedFile: {
        width: 100,
        color: theme.palette.grey[600],
        fontSize: 12,
        marginTop: '15px',
    },
    updatedCheck: {
        width: 100,
        color: theme.palette.grey[600],
        fontSize: 12,
        marginLeft: '-30px',
        marginTop: '-10px',
    },
    justificationTooltip: {
        fontSize: 14,
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    gridItemColumn: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    card: {
        padding: '8px 16px',
        position: 'relative',
        marginLeft: 24,
    },
    gridContainer: {
        minHeight: 45,
        justifyContent: 'space-between',
    },
    documentRequiredText: {
        fontSize: 12,
        marginLeft: 4,
        color: theme.palette.error.main,
    },
    viewFileLink: {
        textDecoration: 'none',
        display: 'flex',
        gap: 4,
        color: theme.palette.primary.dark,
    },
    viewFileLinkSimple: {
        textDecoration: 'none',
    },
    viewFileText: {
        width: 80,
        color: theme.palette.primary.dark,
        fontSize: 14,
    },
    uploadFileLabel: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 6px',
    },
    historyText: {
        display: 'flex',
        gap: 4,
        color: theme.palette.primary.dark,
        cursor: 'pointer',
    },
    referenceDescription: {
        fontSize: 12,
        backgroundColor: theme.palette.info.light,
        color: 'white',
        padding: '2px 10px',
        textAlign: 'justify',
        borderRadius: '4px',
    },
    flexEndAlignCenter: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    marginRightIcon: {
        marginRight: 8,
    },
    cloudUploadIcon: {
        fontSize: 22,
        marginRight: 8,
    },
    hiOutlineUserAddIcon: {
        fontSize: 14,
        color: theme.palette.primary.dark,
        marginRight: 4,
    },
    typographyGutterBottom: {
        fontSize: 12,
        marginLeft: 4,
    },
    typoAttachment: {
        display: 'flex',
        gap: 4,
        color: theme.palette.primary.dark,
    },
    palettePrimaryDark: {
        color: theme.palette.primary.dark,
    },
    palettePrimaryLight: {
        color: theme.palette.info.light,
    },
    uploadFile: {
        padding: '4px 6px',
    },
}));
