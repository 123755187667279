import { useEffect, useState } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { useHistory, useParams } from 'react-router';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import {
    AuctionTransaction,
    DetailedAutcionNotice,
} from 'clients/manager/interfaces/auction-notice.interface';
import { auctionNoticeLotRequests } from 'clients/manager/auction-notice-lot.requests';
import { AuctionNoticeLotPartialItems } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { auctionNoticeMessage } from 'clients/manager/auction-notice-message.requests';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'common/components/base/breadcrumb';
import { ExceptionData } from 'clients/manager/interfaces/auction-notice-participate.interface';
import { auctionTypeRulesRequests } from 'clients/manager/auction-type-rules.requests';
import { MessageTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';
import { getAuthUserToken } from 'modules/auth/session.utils';
import { BiddingProcessPageWrapper, ProcessDocumentsContainer } from './styled';
import GeneralInfoContainer, { ProcessParticipation } from './components/GeneralInfoContainer';
import MessagesContainer from './components/messages-container';
import DocsContainer from './components/DocsContainer';
import NoticesContainer from './components/notices-container';
import LotsContainer from './components/LotsContainer';
import ExceptionsAlerts from './components/exceptions-alerts';
import EnabledProvidersContainer from './components/enabled-providers-container';

const BiddingProcess = () => {
    const { t, i18n } = useTranslation();
    const { setTitle } = usePageContext();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [loading] = useState(false);
    const [generalData, setGeneralData] = useState<DetailedAutcionNotice>();
    const [messageTypeRule, setMessageTypeRule] = useState<MessageTypeRule>();
    const [lotsConfig, setLotsConfig] = useState({
        limit: 10,
        offset: 0,
        loading: false,
        count: 0,
    });
    const [lotsData, setLotsData] = useState<AuctionNoticeLotPartialItems[]>([]);
    const [transactions, setTransactions] = useState<AuctionTransaction[]>([]);
    const [noticiesConfig, setNoticiesConfig] = useState({
        limit: 10,
        offset: 0,
        loading: false,
        count: 0,
    });
    const [currentDate, setCurrentDate] = useState({
        serverDate: new Date(),
        localDate: new Date(),
        diference: 0,
    });
    const [exceptions, setExceptions] = useState<ExceptionData[]>([]);
    const [participation, setParticipation] = useState<ProcessParticipation>({
        participate: 'none',
        loading: false,
    });

    useEffect(() => {
        const getMessageRules = async (ruleId: string) => {
            const authUser = getAuthUserToken();
            if (!authUser?.userId) return;

            const { data } = await auctionTypeRulesRequests.getAuctionTypeRulesToMessages(ruleId);

            setMessageTypeRule(data);
        };

        const getData = async () => {
            if (noticiesConfig.loading) return;

            try {
                const getAfterPublished: boolean = true;
                setNoticiesConfig((prev) => ({ ...prev, loading: true }));
                const generalPromise = auctionNoticeRequests
                    .getAuctionNotice(id, getAfterPublished)
                    .catch(() => {});
                const transactionPromise = auctionNoticeMessage
                    .listTransactionsByAuctionId(id, {
                        offset: noticiesConfig.offset < 0 ? 0 : noticiesConfig.offset,
                        limit: noticiesConfig.limit,
                    })
                    .catch(() => {});

                const [general, transactions] = await Promise.all([
                    generalPromise,
                    transactionPromise,
                ]);

                if (transactions) {
                    setTransactions(transactions.data);
                    setNoticiesConfig((prev) => ({
                        ...prev,
                        count: transactions.meta?.count || 0,
                    }));
                }
                if (general) {
                    setGeneralData(general.data);
                    await getMessageRules(general.data.rule.id);
                }
            } finally {
                setNoticiesConfig((prev) => ({ ...prev, loading: false }));
            }
        };

        getData();
    }, [noticiesConfig.limit, noticiesConfig.offset]);

    useEffect(() => {
        const getLots = async () => {
            if (lotsConfig.loading) return;

            try {
                setLotsConfig((prev) => ({ ...prev, loading: true }));
                const { data, meta } = await auctionNoticeLotRequests.listLotsbyAuctionId({
                    params: { auctionId: Number(id) },
                    offset: lotsConfig.offset < 0 ? 0 : lotsConfig.offset,
                    limit: lotsConfig.limit,
                });

                if (data) {
                    setLotsData(data);
                    setLotsConfig((prev) => ({ ...prev, count: meta?.count || 0 }));
                }
            } finally {
                setLotsConfig((prev) => ({ ...prev, loading: false }));
            }
        };

        getLots();
    }, [lotsConfig.limit, lotsConfig.offset]);

    useEffect(() => {
        setTitle(t('bidding.process'));
    }, [i18n.language]);

    return (
        <BiddingProcessPageWrapper>
            <ExceptionsAlerts generalData={generalData} exceptions={exceptions} />
            <Breadcrumb
                customPath='/pesquisa/:id'
                paths={{
                    pesquisa: {
                        value: t('bidding.page.search.title'),
                        onClick: () => history.push('/pesquisa'),
                    },
                }}
                params={{
                    id: {
                        value: generalData?.processNumber || '',
                        disabled: true,
                    },
                }}
            />
            {(!generalData || Object.keys(generalData).length === 0) && !loading && (
                <Alert severity='error' variant='outlined'>
                    <AlertTitle>{t('bidding.process.error.doesnt-exists')}</AlertTitle>
                    <strong>{t('bidding.process.error.doesnt-exists.description')}</strong>
                </Alert>
            )}
            {generalData && Object.keys(generalData).length > 0 && !loading && (
                <>
                    <GeneralInfoContainer
                        data={generalData}
                        {...{
                            id,
                            loading,
                            setCurrentDate,
                            setExceptions,
                            participation,
                            setParticipation,
                            messageTypeRule,
                        }}
                    />
                    <ProcessDocumentsContainer>
                        <MessagesContainer
                            {...{ loading, id, currentDate, generalData, messageTypeRule }}
                        />
                        <DocsContainer {...{ id, processType: generalData.processType }} />
                    </ProcessDocumentsContainer>
                    <LotsContainer
                        data={lotsData}
                        {...{ loading, setLotsConfig, lotsConfig, generalData }}
                    />
                    {generalData.isPhaseReversal === 1 && (
                        <EnabledProvidersContainer processId={Number(id)} />
                    )}
                    <div id='noticesSection'>
                        <NoticesContainer
                            {...{ transactions, loading, setNoticiesConfig, noticiesConfig }}
                        />
                    </div>
                </>
            )}
        </BiddingProcessPageWrapper>
    );
};

export default BiddingProcess;
