import React, { FC, useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';

interface WithoutContractActionsProps {
    createContract: () => void;
}

const WithoutContractActions: FC<WithoutContractActionsProps> = ({ createContract }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                aria-label='more'
                size='small'
                aria-controls='long-menu'
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        createContract();
                    }}
                >
                    {`${t('term.create')} ${t('term.contract')}`}
                </MenuItem>
            </Menu>
        </>
    );
};

export default WithoutContractActions;
