import { FC } from 'react';
import { Switch, useRouteMatch } from 'react-router';
import PrivateRoute from 'routes/components/private-route';
import { TypeAccess } from 'common/interfaces/user.interface';
import { hasCurrentAccessType, hasPermissionToExecute } from 'common/utils';
import SearchProviders from './pages/search-providers';
import { SearchProvidersContextProvider } from './pages/search-providers/context/search-providers-context';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                exact
                path={`${match.path}/`}
                key='/'
                component={() => (
                    <SearchProvidersContextProvider>
                        <SearchProviders />
                    </SearchProvidersContextProvider>
                )}
            />
        </Switch>
    );
};
const ProviderRoutes: JSX.Element[] = [
    <PrivateRoute
        exact
        path='/adminfornecedores'
        key='/adminfornecedores'
        canAccess={() =>
            hasCurrentAccessType([TypeAccess.management]) &&
            hasPermissionToExecute('listarFornecedores')
        }
        component={() => <ChildRoutes />}
    />,
];

export default ProviderRoutes;
