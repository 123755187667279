import { Card, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { MdAccountBalance, MdOutlineBusiness, MdSearch } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from 'routes/platform.context';
import { StyledAvatar } from './ShortcutStyle';

const ShortcutsView = () => {
    const { platform } = usePlatformContext();

    const { t } = useTranslation();

    return (
        <Card>
            <List>
                <ListItem button divider component={RouterLink} to='/pesquisa'>
                    <ListItemAvatar style={{ marginRight: 16 }}>
                        <StyledAvatar color='warning'>
                            <MdSearch size={28} />
                        </StyledAvatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography>Pesquisar</Typography>} />
                </ListItem>
                {!platform?.generalSettings?.general?.hideSignupProvider && (
                    <ListItem button divider component={RouterLink} to='/cadastro-fornecedor'>
                        <ListItemAvatar style={{ marginRight: 16 }}>
                            <StyledAvatar color='secondary'>
                                <MdOutlineBusiness size={28} />
                            </StyledAvatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography>
                                    {t('shared.authenticated-header.cadaster-suppliers')}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {!platform?.generalSettings?.general?.hideSignupOrganization && (
                    <ListItem button component={RouterLink} to='/cadastro-organizacao'>
                        <ListItemAvatar style={{ marginRight: 16 }}>
                            <StyledAvatar color='primary'>
                                <MdAccountBalance size={28} />
                            </StyledAvatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography>
                                    {t('shared.authenticated-header.cadaster-new-ente-public-link')}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
            </List>
        </Card>
    );
};
export default ShortcutsView;
