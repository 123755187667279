import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addressRequest } from 'clients/manager/address.requests';
import { organizationRequests } from 'clients/manager/organization.requests';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { SignUpOrganizationContext } from 'modules/sign-up/pages/sign-up-stepper-organization/context/SignUpOrganizationContext';
import { IRegions } from 'modules/sign-up/pages/sign-up-stepper-organization/props';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotificationWarning, transformTextCapitalize } from 'common/utils';
import * as yup from 'yup';
import { InfoIcon } from 'common/icons';
import { Container } from './styles';

const RegionAutoComplete = () => {
    const { organization, regionOrganization, setOrganization, handleNext, setOrganizationId } =
        useContext(SignUpOrganizationContext);
    const [regions, setRegions] = useState<IRegions[]>([]);

    const [regionsValues, setRegionsValues] = useState<IRegions[]>(regionOrganization);
    const [errorMessage, setErrorMessage] = useState('');

    const initialValues = {};

    const validationSchema = yup.object({});

    const { t } = useTranslation();

    const postOrganization = async () => {
        try {
            // eslint-disable-next-line no-prototype-builtins
            if (organization?.hasOwnProperty('ibgeCodesRegion')) {
                const result = await organizationRequests.createOrganization(organization);
                setOrganizationId(result.id);
                handleNext();
                return result;
            }
        } catch (e: any) {
            setErrorMessage(
                `${t('error.response.could-not-save-information')}, ${e.response.data.message}`
            );
        }
    };

    useEffect(() => {
        if (regionsValues.length > 0) {
            setOrganization((prevOrganization) => ({
                ...prevOrganization,
                ibgeCodesRegion: regionsValues.map((item) => item.ibgeCode).join(','),
            }));
        }
    }, [regionsValues]);

    const getRegions = async (event) => {
        const { value } = event.target;

        if (value.length <= 2) {
            return;
        }

        try {
            const result = await addressRequest.getRegions(value);
            setRegions(result);
        } catch (err) {
            addNotificationWarning({
                title: t('term.err'),
                message: t('mkp.search.not-found-city'),
            });
        }
    };

    const formRegions = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => postOrganization(),
    });

    const onChangeInputRegions = debounce(getRegions, 600);

    return (
        <Container>
            <Grid container alignItems='center' spacing={1}>
                <Grid item md={11} sm={11} xs={11}>
                    <Autocomplete
                        className='input-regions'
                        fullWidth
                        multiple
                        id='search-region'
                        value={regionsValues}
                        onChange={(event, newValue: IRegions[]) => {
                            setRegionsValues(newValue);
                        }}
                        options={regions}
                        getOptionLabel={(option) =>
                            `${transformTextCapitalize(option.name)} - ${option.uf.toUpperCase()}`
                        }
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={`${option.name} - ${option.uf.toUpperCase()}`}
                                    {...getTagProps({ index })}
                                    disabled={regionOrganization.igbeCode === option.ibgeCode}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('sign-up.region.search-regions')}
                                onChange={onChangeInputRegions}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={1} xs={1}>
                    <Tooltip title={t('sign-up.region.tooltip-info') as string} aria-label='info'>
                        <div>
                            <InfoIcon color='secondary' />
                        </div>
                    </Tooltip>
                </Grid>
                {errorMessage && <Typography color='error'>{errorMessage}</Typography>}
            </Grid>
            <div className='stepper-nav'>
                <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={formRegions.submitForm}
                >
                    {t('term.save-and-forward')}
                </Button>
            </div>
        </Container>
    );
};

export default RegionAutoComplete;
