import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Modal } from 'common/components';
import { createStyles, makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneratedDocumentSigner } from 'clients/manager/interfaces/generated-documents.interface';
import SignerAvatarView from './signer-avatar-view';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: 0,
            margin: 0,
            '& .MuiListItemIcon-root': {
                minWidth: 30,
            },
        },
        icon: {
            width: 18,
            height: 18,
        },
        icon2: {
            width: 22,
            height: 22,
        },
    })
);

interface ModalSignersListProps {
    signers: GeneratedDocumentSigner[];
    onClose: () => void;
}

const ModalSignersList: FC<ModalSignersListProps> = ({ signers, onClose }) => {
    const {
        i18n: { language, t },
    } = useTranslation();
    const classes = useStyles();

    moment.locale(language);

    return (
        <Modal header={<span>{t('term.subscribers')}</span>} open onClose={onClose}>
            <Box
                style={{
                    minWidth: '400px',
                    maxWidth: '400px',
                }}
            >
                <List style={{ padding: 0 }}>
                    {signers?.map((signer) => (
                        <>
                            <ListItem classes={{ root: classes.root }} key={signer.userId}>
                                <ListItemIcon>
                                    <IconButton edge='start'>
                                        <SignerAvatarView signer={signer} />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText
                                    primary={signer.name || signer.email}
                                    secondary={
                                        <>
                                            <Typography variant='body2' color='textPrimary'>
                                                {signer.signedAt ? (
                                                    `${t('info.signed-in')} ${moment(
                                                        signer.signedAt
                                                    ).format('LLL')}`
                                                ) : (
                                                    <span>{t('info.not-signer')}</span>
                                                )}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider variant='fullWidth' component='li' />
                        </>
                    ))}
                </List>
            </Box>
        </Modal>
    );
};

export default ModalSignersList;
