import { omit } from 'lodash';
import { CreateProcess, UpdateProcess } from '../../../clients/manager/auction-notice.requests';
import { AuctionNotice } from '../../../clients/manager/interfaces/auction-notice.interface';

const arrayToOmit = ['auctionFinished', 'sessionOpened', 'participantId', 'lastSendAt'];

const getValidFieldsToCompare = (process: AuctionNotice | undefined) => {
    if (!process) {
        return process;
    }

    return omit(process, arrayToOmit);
};

const getValidFieldsToUpdate = (process: CreateProcess | UpdateProcess | undefined) => {
    if (!process) {
        return process;
    }

    return omit(process, arrayToOmit);
};

// Alguns campos tem uma rota especifica para update, então omito do update geral do processo
export { getValidFieldsToCompare, getValidFieldsToUpdate };
