import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import {
    Contract,
    ContractStatus,
    SignatureMethod,
} from 'clients/manager/interfaces/contract.interface';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';
import { GeneratedDocuments } from 'clients/manager/interfaces/generated-documents.interface';
import { Signer } from 'clients/manager/interfaces/document-signers.interface';
import { processUtils } from './process-utils';

const contractConcluded = [ContractStatus.concluded, ContractStatus.obsolete];
const contractBeforeSigner = [ContractStatus.draft, ContractStatus.generated];

const documentsTypesContract = [
    DocumentTemplateType.contract,
    DocumentTemplateType.terminationNotice,
    DocumentTemplateType.addendum,
    DocumentTemplateType.apostilmentTerm,
    // DocumentTemplateType.commitmentNote,
];

const contractUtils = {
    // Utils para contrato no geral (Contrato ou Termo)
    isConcluded: (contract: Contract): boolean => contractConcluded.includes(contract.status),
    isNotConcluded: (contract: Contract): boolean => !contractConcluded.includes(contract.status),
    inProgress: (contract: Contract): boolean =>
        [ContractStatus.signatures, ContractStatus.generated].includes(contract.status),
    isNotDraft: (contract: Contract): boolean => contract.status !== ContractStatus.draft,
    isNotSentPncp: (contract: Contract): boolean => !contract.pncpLink,

    // Utils para contrato somente (Contrato !contract.contractOriginId)
    isContractConcluded: (contract: Contract): boolean =>
        contractConcluded.includes(contract.status) && !contract.contractOriginId,
    isContractSentPncp: (contract: Contract): boolean =>
        !!(!contract.contractOriginId && contract.pncpLink),
    isContractNotSentPncp: (contract: Contract): boolean =>
        !!(!contract.contractOriginId && !contract.pncpLink),

    // Utils para termo somente (Termo contract.contractOriginId)
    isTermConcluded: (contract: Contract): boolean =>
        !!(contractConcluded.includes(contract.status) && contract.contractOriginId),
    isTermSentPncp: (contract: Contract): boolean =>
        !!(contract.contractOriginId && contract.pncpLink),
    allAreSigned: (contract: Contract, signers: Signer[] | undefined): boolean =>
        !!(
            contract.signtureMethod === SignatureMethod.external ||
            (contract.signtureMethod === SignatureMethod.internal &&
                signers?.every((signer) => !!signer.signatureDate))
        ),
};

const contractActions = {
    canSendPncp: (
        process: { legalSupportId?: number; pncpLink?: string },
        contract: Contract,
        signers: Signer[] | undefined,
        fileLocation: string | undefined
    ): boolean =>
        !!(
            fileLocation &&
            processUtils.isNewLaw(process?.legalSupportId) &&
            processUtils.inPncp(process?.pncpLink) &&
            contractUtils.allAreSigned(contract, signers) &&
            !contract.pncpLink
        ),
    canDeletePncp: (contract: Contract): boolean =>
        !!contract.pncpLink &&
        contract.status !== ContractStatus.obsolete &&
        !contract.contractOriginId, // tem que ser contrato
    showPncpLink: (process?: { pncpLink?: string }, contract?: Contract): boolean =>
        !!(contract && process && processUtils.inPncp(process?.pncpLink) && contract.pncpLink),
    canEditSigntureMethod: (contract?: Contract): boolean =>
        !!(contract && contractBeforeSigner.includes(contract.status)),
};

const documentUtils = {
    inPncp: (generated?: GeneratedDocuments): boolean => !!(generated && !!generated.pncpLink),
};

const documentActions = {
    canSendPncp: (
        process: { legalSupportId?: number; pncpLink?: string },
        generated: GeneratedDocuments | undefined
    ): boolean =>
        !!(
            generated &&
            processUtils.isNewLaw(process?.legalSupportId) &&
            processUtils.inPncp(process?.pncpLink) &&
            !generated.contractId
        ),
    showPncpLink: (
        process: AuctionNotice | undefined,
        generated: GeneratedDocuments | undefined
    ): boolean =>
        !!(
            generated &&
            processUtils.inPncp(process?.pncpLink) &&
            generated.pncpLink &&
            !generated.contractId
        ),
};

export {
    contractUtils,
    documentUtils,
    contractConcluded,
    documentsTypesContract,
    documentActions,
    contractActions,
};
