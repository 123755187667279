import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Extraction } from 'common/interfaces/extraction.interface';
import { Button, Card, CardContent } from '@licitar/ui-kit';
import moment from 'moment';
import { getBoolToYesOrNot } from 'common/utils';
import { IoReload } from 'react-icons/io5';
import { ButtonExtractorContainer, ResumeContainer } from './styles';

interface Props {
    extraction: Extraction;
    onExtract: () => void;
    onReload: () => void;
}

type ComponentType = FC<Props>;

const getFormattedDate = (date: any) => (date ? moment(date).format(' DD/MM/YYYY, HH:mm:ss') : '');

const getCardAlertColor = (extraction: Extraction) => {
    if (extraction?.errorAt) return '#dc3545';
    if (!extraction?.errorAt && !extraction?.endedAt && !!extraction?.startedAt) return '#198754';
    if (!extraction?.errorAt && !!extraction?.endedAt && !!extraction?.startedAt) return '#0d6efd';
};

const LastExtractionResume: ComponentType = ({ extraction, onExtract, onReload }) => {
    const { t } = useTranslation();

    return (
        <Card
            style={{
                marginBottom: '10px',
                borderTop: `3px solid ${getCardAlertColor(extraction)}`,
            }}
        >
            <CardContent>
                <ResumeContainer>
                    <div>
                        <div>{`${t('term.extracting')}: ${getBoolToYesOrNot(
                            extraction?.isRunningExtraction
                        )}`}</div>
                        <div>
                            {' '}
                            {t('term.extractor')}: {extraction?.extractor}{' '}
                        </div>
                        <div>
                            {t('term.started-in')}: {getFormattedDate(extraction?.startedAt)}
                        </div>
                        <div>
                            {t('term.start-data')}: {JSON.stringify(extraction?.startData)}
                        </div>
                        <div>
                            {t('term.finished-in')}: {getFormattedDate(extraction?.endData)}
                        </div>
                        <div>
                            {t('term.finalization-data')}: {JSON.stringify(extraction?.endData)}
                        </div>
                        <div>
                            {t('term.error-in')}: {getFormattedDate(extraction?.errorAt)}
                        </div>
                        <div>
                            {t('term.error-data')}: {JSON.stringify(extraction?.errorData)}
                        </div>
                    </div>
                    <div>
                        <ButtonExtractorContainer>
                            <IoReload
                                onClick={onReload}
                                style={{
                                    marginRight: '5px',
                                    cursor: 'pointer',
                                }}
                            />
                            <Button onClick={() => onExtract()} style={{ marginRight: '5px' }}>
                                {t('term.extract')}
                            </Button>
                        </ButtonExtractorContainer>
                    </div>
                </ResumeContainer>
            </CardContent>
        </Card>
    );
};

export default LastExtractionResume as ComponentType;
