import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    TextField,
    Grid,
    Button,
    CircularProgress,
    Typography,
    MenuItem,
} from '@material-ui/core';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { FormikProps } from 'formik';
import { HiringPlansListRequest } from 'clients/manager/interfaces/hiring-plans.interfaces';
import {
    SearchFiltersAsideContainer,
    AdvancedFiltersContainer,
} from './advanced-search-filters-aside-style';

interface AdvancedSearchFiltersAsideViewProps {
    form: FormikProps<HiringPlansListRequest>;
    showAdvancedFilters: boolean;
    isLoadingSubmitForm: boolean;
    handlerResetAllForm: () => void;
}

const AdvancedSearchFiltersAsideView: React.FC<AdvancedSearchFiltersAsideViewProps> = (props) => {
    const { form, showAdvancedFilters, isLoadingSubmitForm, handlerResetAllForm } = props;
    const { t } = useTranslation();
    const { listPcaStatus } = useHiringPlansContext();

    return (
        <SearchFiltersAsideContainer showAdvancedFilters={showAdvancedFilters}>
            <Typography color='primary' variant='subtitle2' style={{ marginBottom: '8px' }}>
                {t('hiring-plans.title.filter-hiring-plans')}
            </Typography>
            <AdvancedFiltersContainer disabled={false}>
                <FormControl fullWidth>
                    <Grid container spacing={2} direction='column'>
                        <Grid item>
                            <TextField
                                fullWidth
                                disabled={isLoadingSubmitForm}
                                type='text'
                                variant='outlined'
                                name='year'
                                label={t('hiring-plans.labels.ano-pca')}
                                value={form.values.year}
                                onChange={form.handleChange}
                                error={Boolean(form.errors.year)}
                                helperText={form.errors.year}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                fullWidth
                                disabled={isLoadingSubmitForm}
                                key='text-field-search-pca-status'
                                variant='outlined'
                                name='status'
                                label={t('hiring-plans.labels.status')}
                                value={form.values.status}
                                onChange={form.handleChange}
                                error={Boolean(form.errors.status)}
                                helperText={form.errors.status}
                            >
                                {listPcaStatus.map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </FormControl>
            </AdvancedFiltersContainer>
            <Box
                style={{
                    marginTop: '16px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '8px',
                }}
            >
                <Button
                    fullWidth
                    disabled={isLoadingSubmitForm || !form.isValid}
                    color='primary'
                    size='medium'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={() => form.handleSubmit()}
                >
                    {!isLoadingSubmitForm ? (
                        t('term.search')
                    ) : (
                        <CircularProgress color='primary' size={25} />
                    )}
                </Button>
                <Button
                    fullWidth
                    disabled={isLoadingSubmitForm}
                    color='default'
                    size='medium'
                    variant='contained'
                    style={{ height: '100%' }}
                    onClick={() => handlerResetAllForm()}
                >
                    {t('hiring-plans.labels.reset-filters')}
                </Button>
            </Box>
        </SearchFiltersAsideContainer>
    );
};

export default AdvancedSearchFiltersAsideView;
