import React from 'react';
import { Button, Input, Typography } from '@material-ui/core';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { useStyles } from './quantity-counter-style';

interface QuantityCounterViewProps {
    quantity: number;
    max?: number;
    isRemoving?: boolean;
    enableMax?: boolean;
    onRemove: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onIncrease: () => void;
    onDecrease: () => void;
}

const QuantityCounterView: React.FC<QuantityCounterViewProps> = ({
    quantity,
    isRemoving,
    max = 0,
    enableMax = false,
    onRemove,
    onChange,
    onIncrease,
    onDecrease,
}) => {
    const styles = useStyles({
        isExceededMaximum: quantity > max,
    });

    const renderSuffix = () => (
        <Typography variant='body2' className={styles.suffix}>
            /{max}
        </Typography>
    );

    return (
        <>
            <div className={styles.container}>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={isRemoving}
                    onClick={onRemove}
                    className={styles.removeButton}
                >
                    <FaRegTrashAlt size={18} />
                </Button>

                <div className={styles.counterContainer}>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={quantity < 2}
                        onClick={onDecrease}
                        className={styles.actionButton}
                    >
                        <FiMinus size={18} />
                    </Button>
                    <Input
                        className={styles.count}
                        value={quantity}
                        type='number'
                        onChange={onChange}
                        renderSuffix={enableMax ? renderSuffix : undefined}
                    />
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onIncrease}
                        className={styles.actionButton}
                    >
                        <FiPlus size={18} />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default QuantityCounterView;
